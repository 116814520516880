import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "react-js-pagination";
import axios from "axios";
import RecipePreview from "../../CoachComponents/Plans/RecipePreview";

const CoachImportedRecipes=(props)=>{

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [NutritionId,SetNutritionId]=useState('')
    const [PerPage,SetPerPage]=useState(50)
    const [TotalRecord,SetTotalRecord]=useState(0)
    const [PageNumber,SetPageNumber]=useState(1)
    const [RecipeList,SetRecipeList]=useState([])
    const [RecipePreviewShow, SetRecipePreviewShow] = useState(false)
    const [RecipePreviewData, SetRecipePreviewData] = useState([])
    const [SearchImportedValue,SetSearchImportedValue]=useState('')
    const SearchByName=(value)=>{
     GetImportedRecipes(1,NutritionId,value)
     SetSearchImportedValue(value)

    }

     
    const GetImportedRecipes=async(pagenumber,NutritionId,search='')=>{

        SetPageNumber(pagenumber)

        const response = await axiosJWT.get('import-recipe/list-imported-recipe-url?limit=' + PerPage + '&offset=' + pagenumber + '&coach_id=' + NutritionId + '&search='+search+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if(response.data!=null)
        {
            if(response.data.list!==null)
            {
                SetRecipeList(response.data.list)
                SetTotalRecord(response.data.count)
            }
        }

      

    }


    const changePageNumberS=(pagenumber)=>{
        GetImportedRecipes(pagenumber,NutritionId,SearchImportedValue)
    }

    const ViewRecipe=async(e)=>{
        const RecipeId = e.target.closest(".navigateclientdashboard").getAttribute("data-id");
        const response = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log('response',response)

        if (response.status === 200) {
            SetRecipePreviewData(response.data.recipe)
            SetRecipePreviewShow(true)
        }
       
    }
    useEffect(()=>{

        if(props.ClientId)
        {
            SetNutritionId(props.ClientId)
            GetImportedRecipes(1,props.ClientId)
        }

    },[props])

    return (
        <div className="admin_coach_dashboard admin_coach_imported_recipe">
            <div className="rightmenu">
            <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => SearchByName(event.target.value)} defaultValue={SearchImportedValue} />

            </div>
            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Recipe uid</Th>
            <Th scope="col">Recipe name</Th>
            <Th scope="col">Recipe image</Th>
            <Th scope="col">Recipe prep time</Th>
            <Th scope="col">Action</Th>
            </Tr>
        </Thead>
        <Tbody className="tbody">

        {RecipeList.map((recipes, index) => (  
            <Tr className="trow navigateclientdashboard" data-id={recipes.recipe_uid} onClick={(e)=>{ViewRecipe(e)}}>
                <Td>{recipes.recipe_uid}</Td>
                <Td>{recipes.recipe_name}</Td>
                <Td><img className="recipe_img" src={recipes.recipe_image}/></Td>
                <Td>{recipes.recipe_time !== '' && recipes.recipe_time + ' mins'}</Td>
                <Td>View</Td>
            </Tr>
                  ))}
            </Tbody>


        </Table>
        {RecipeList.length > 0 &&
                                    <div>
                                        <Pagination
                                            activePage={PageNumber}
                                            itemsCountPerPage={PerPage}
                                            totalItemsCount={TotalRecord}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }
        {
                RecipePreviewShow === true ? <RecipePreview RecipePreviewShow={RecipePreviewShow} SetRecipePreviewShow={SetRecipePreviewShow} RecipePreviewData={RecipePreviewData} /> : null
            }
        </div>
    )
}
export default CoachImportedRecipes
import React, { useEffect, useState } from "react";
import ManagerAccessCheck from "./ManagerAccessPage";
import axios from 'axios';
import { CSVLink } from "react-csv";
import female_icon from '../../images/female.png'
import male_icon from '../../images/male.png'
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagerAccessPayout = () => {

    const side_nav = localStorage.getItem("side_nav");
    const [Access, SetAccess] = useState(false)
    const [ShowData, SetShowData] = useState(false)
    function handleChange(newValue) {
        SetAccess(newValue);
    }
    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [exportPayout, setexportPayout] = useState('');
    const [exportFilename, setexportFilename] = useState('');
    const [payoutsList,SetpayoutsList]=useState([])
   
    const [Pagenumber,SetPagenumber]=useState(1)
    const [PerPage,SetPerPage]=useState(10)
    const [TotalRecord,SetTotalRecord]=useState(0)


    const GetAccessDetails = async () => {

        const response = await axiosJWT.get('/managing-team/get-access-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
      

        if (response.data !== null) {

            var Payoutresult = response.data.filter(obj => {
                return obj.page === 'payment_payout'
              });

            if (Payoutresult[0].read === true) {
                SetAccess(false);
                SetShowData(true);
            }            
            else {
                SetAccess(true);
                SetShowData(false);
            }

        }

    }

    const searchPayout = async (event) => {

     

        GetPayouts(1,event)

    }

    const GetPayouts=async(pagenumber,searchval='')=>{

        SetPagenumber(pagenumber)
        const response = await axiosJWT.get('/manager-access/get-my-team-payouts?offset=' + pagenumber + '&limit=' + PerPage + '&search='+searchval+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.data!==null)
        {
            if(response.data.payout!==null)
            {
                SetpayoutsList(response.data.payout)
                SetTotalRecord(response.data.payoutTotal)

                var exports_list = response.data.payout;
                var exports_data = [];
        
                exports_list.forEach(element => {
                    exports_data.push({ "Expert": element.nutritionist.first_name + " " + element.nutritionist.last_name, "Expert Email": element.nutritionist.email, "Transaction Date": format(new Date(element.createdAt), `PP`), "Status": element.status, "Amount": element.amount, "Total Amount": element.total_amount, "Payment Type": element.payment_type, "Gateway": element.gateway });
                });
                setexportPayout(exports_data);
        
                setexportFilename("Payouts_" + format(new Date(), 'yyyy_MM_dd_t'));
            }
        }        
    }

    const changePageNumber = (pagenumber) => {
        GetPayouts(pagenumber);
    }

    useEffect(() => {
        GetAccessDetails()
        GetPayouts(1)

    }, [])
    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <ManagerAccessCheck allowedstatus={Access} onChange={handleChange} />
            <section className="dashboard">

                <div className="container">

                    {
                        ShowData === true &&

                        <div className="adminpayout container">
                            <div className='menubox'>
                                <div className='leftmenu'>
                                    <h5>Payout</h5>
                                </div>
                                <div className='rightmenu'>
                                    <CSVLink data={exportPayout} filename={exportFilename}><button data-modal="export_payout">Export As CSV</button></CSVLink>

                                    <input type="text" placeholder="&#xF002; Search" onChange={event => searchPayout(event.target.value)} />
                                </div>
                            </div>

                            <div className="row upcoming_appointment_table_row">
                            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                            <Thead className="thead">
                                        <Tr className="trow">
                                            <Th scope="col">Expert</Th>
                                            <Th scope="col">Expert Email</Th>
                                            <Th scope="col">Transaction Date</Th>
                                            <Th scope="col">Status</Th>
                                            <Th scope="col">Amount</Th>
                                            <Th scope="col">Total amount</Th>
                                            <Th scope="col">Payment Type</Th>
                                            <Th scope="col">Gateway</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody className="tbody manager_appointment_list_admin">
                                    {payoutsList.length>0 && payoutsList.map((payout, index) => (
                                        <Tr className='trow' key={payout.id}>
                                            <Td><img className='table_profile_img' src={payout.nutritionist.user_profile_photo ?  payout.nutritionist.user_profile_photo : (payout.gender === 'MALE' ? male_icon : female_icon)} />{payout.nutritionist.first_name} {payout.nutritionist.last_name}</Td>
                                            <Td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{payout.nutritionist.email}</Td>                                    
                                            <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(payout.createdAt), `PP`)}</Td>
                                            <Td>{payout.status==='pending'?<span className="desing_yellow in_page_design_relative">{payout.status}</span>:<span className="desing_purple in_page_design_relative">{payout.status}</span>}</Td>
                                            <Td>${payout.amount}</Td>
                                            <Td>${payout.total_amount}</Td>
                                            <Td>{payout.payment_type}</Td>
                                            <Td><div className='logo'><img src={payout.gateway ? process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + payout.gateway + ".png" : null} alt={payout.gateway} /></div></Td>
                                        </Tr>
                                    ))}
                                    </Tbody>
                                </Table>
                                </div>

                                <div className="row" style={{ float: "right", marginTop: "20px" }}>

{payoutsList.length > 0 ?
    <Pagination
        activePage={Pagenumber}
        itemsCountPerPage={PerPage}
        totalItemsCount={TotalRecord}
        onChange={changePageNumber.bind(this)}
        prevPageText="<"
        nextPageText=">"
        hideFirstLastPages={true}
        linkClassPrev="pagination_prev"
        linkClassNext="pagination_next"
        linkClass="pagenumbers grayfont"
    />
    : null}

</div>

                        </div>

                    }
                </div>
            </section>
        </div>
    )
}

export default ManagerAccessPayout
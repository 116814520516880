import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Country, State, City } from 'country-state-city';
import GoogleCalendarIcon from '../../../images/calendar.png'


const CoachProfile = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const timeZones = moment.tz.names();
    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [OldPassword, SetOldPassword] = useState('');
    const [NewPassword, SetNewPassword] = useState('');
    const [RetypeNewPassword, SetRetypeNewPassword] = useState('');
    const [passerr, Setpasserr] = useState(false);
    const [passerrmsg, Setpasserrmsg] = useState('');
    const [show, setShow] = useState(false);
    const [email, SetEmail] = useState('');
    const [emailpasswordblock, Setemailpasswordblock] = useState('');
    const [emailerr, Setemailerr] = useState(false);
    const [emailerrmsg, Setemailerrmsg] = useState('');
    const [SessionExpiry, SetSessionexpiry] = useState('');
    const [expiryerr, Setexpiryerr] = useState(false);
    const [expiryerrmsg, Setexpiryerrmsg] = useState('');
    const [FirstName, SetFirstname] = useState('');
    const [LastName, SetLastName] = useState('');
    const [Address, SetAddress] = useState('');
    const [userCity, setuserCity] = useState('');
    const [userState, setuserState] = useState('');
    const [userCountry, setuserCountry] = useState('');
    const [cityList, setcityList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [Postal, SetPostal] = useState('');
    const [CoachBio, SetCoachBio] = useState('');

    const [phone, SetPhone] = useState('');
    const [phonepasswordblock, Setphonepasswordblock] = useState('');
    const [phoneerr, Setphoneerr] = useState(false);
    const [phoneerrmsg, Setphoneerrmsg] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [popupheading, Setpopupheading] = useState('');
    const [popupmsg, Setpopupmsg] = useState('');

    const [fscriptrevokeshow, setfscriptrevokeshow] = useState(false);
    const fscriptrevokeclose = () => setfscriptrevokeshow(false);

    const SavePassword = async () => {
        Setpasserr(false);
        Setpasserrmsg('');

        if (OldPassword === '' || NewPassword === '' || RetypeNewPassword === '') {
            Setpasserrmsg('*Please fill all fields.!');
            Setpasserr(true);
        }
        else if (NewPassword !== RetypeNewPassword) {
            Setpasserrmsg("Password doesn't match.!");
            Setpasserr(true);
        }
        else {
            try {
                const response = await axiosJWT.post('/profile-details/password',
                    {
                        oldpassword: OldPassword,
                        password: NewPassword
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setpopupheading('Password');
                    Setpopupmsg(response.data.message);
                    handleShow();
                    SetOldPassword('');
                    SetNewPassword('');
                    SetRetypeNewPassword('');
                }
            }
            catch (error) {
                Setpasserrmsg(error.response.data.msg);
                Setpasserr(true);
            }
        }
    }

    const [showpaymentbutton, Setshowpaymentbutton] = useState(false);
    const [showfailm, Setshowfailm] = useState(false);
    const CloseFailMPopup = () => {
        Setshowfailm(false);
    }

    const [GoogleCalendarAccess, SetGoogleCalendarAccess] = useState(false);
    const [MsCalendarAccess, SetMsCalendarAccess] = useState(false);
    const [FullscriptAccess, SetFullscriptAccess] = useState(false);

    const getProfile = async () => {

        Setshowpaymentbutton(false);
        SetshowpaymentbuttonAdd(true);

        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        if (response.data.profile_details[0].google_code === null  || response.data.profile_details[0].google_code === '') {
            SetGoogleCalendarAccess(false)
        }
        else {
            SetGoogleCalendarAccess(true)
        }



        if (response.data.profile_details[0].withings_code === null  || response.data.profile_details[0].withings_code === '') {
            SetWithingsConnected(false)
        }
        else {
            SetWithingsConnected(true)
        }

        if (response.data.profile_details[0].zoom_code === null || response.data.profile_details[0].zoom_code === '') {
            SetZoomConnect(false)
        }
        else {
            SetZoomConnect(true)
        }

        if (response.data.profile_details[0].ms_code === null || response.data.profile_details[0].ms_code === '') {
            SetMsCalendarAccess(false)
        }
        else {
            SetMsCalendarAccess(true)
        }

        if (response.data.profile_details[0].fscript_code === null || response.data.profile_details[0].fscript_code === '') {
            SetFullscriptAccess(false);
        }
        else {
            SetFullscriptAccess(true);
        }

        if (response.data.profile_details[0].email) {
            SetEmail(response.data.profile_details[0].email);
        }

        if (response.data.profile_details[0].user_timezone !== null && response.data.profile_details[0].user_timezone != '') {
            setuser_timezone(response.data.profile_details[0].user_timezone);
        }

        if (response.data.profile_details[0].user_profile_photo !== null && response.data.profile_details[0].user_profile_photo !== '') {
            document.getElementsByClassName("top_profile_pic")[0].src = response.data.profile_details[0].user_profile_photo;
        }

        if (response.data.profile_details[0].nutritionist_infos !== undefined && response.data.profile_details[0].nutritionist_infos !== null) {
            if (response.data.profile_details[0].nutritionist_infos[0].stripe_payment_id !== null && response.data.profile_details[0].nutritionist_infos[0].stripe_payment_id !== '') {
                Setshowpaymentbutton(true);
                SetshowpaymentbuttonAdd(false);
            }
        }

        if (response.data.profile_details[0].client_infos !== undefined && response.data.profile_details[0].client_infos !== null) {
            if (response.data.profile_details[0].client_infos[0].stripe_payment_id !== null && response.data.profile_details[0].client_infos[0].stripe_payment_id !== '') {
                Setshowpaymentbutton(true);
                SetshowpaymentbuttonAdd(false);
            }
        }

        if (response.data.profile_details[0].first_name !== null) {
            SetFirstname(response.data.profile_details[0].first_name);
        }

        if (response.data.profile_details[0].last_name !== null) {
            SetLastName(response.data.profile_details[0].last_name);
        }

        if (response.data.profile_details[0].user_address !== null) {
            SetAddress(response.data.profile_details[0].user_address);
        }
        if (response.data.profile_details[0].postalCode !== null) {
            //    console.log('postal')
            //    console.log(response.data.profile_details[0])            
            SetPostal(response.data.profile_details[0].postalCode);
        }
        if (response.data.profile_details[0].user_session_expiry !== null) {
            SetSessionexpiry(response.data.profile_details[0].user_session_expiry);
        }

        if (response.data.profile_details[0].role === 'Nutrition') {
            if (response.data.profile_details[0].nutritionist_infos !== undefined && response.data.profile_details[0].nutritionist_infos !== null) {
                if (response.data.profile_details[0].nutritionist_infos[0].coach_bio !== null && response.data.profile_details[0].nutritionist_infos[0].coach_bio !== '') {
                    SetCoachBio(response.data.profile_details[0].nutritionist_infos[0].coach_bio);
                }
            }
        }


        if (response.data.profile_details[0].user_country !== null) {
            setuserCountry(response.data.profile_details[0].user_country);

            var state_list = State.getAllStates();
            var state_list_filtered = state_list.filter((state) => state.countryCode === response.data.profile_details[0].user_country.value);

            var state_list_array = [];

            state_list_filtered.forEach(element => {
                state_list_array.push({ "label": element.name, "value": element.isoCode });
            });

            setstateList(state_list_array);
        }

        if (response.data.profile_details[0].user_state !== null) {
            setuserState(response.data.profile_details[0].user_state);

            var city_list = City.getAllCities();

            var city_list_filtered = city_list.filter((city) => city.countryCode === response.data.profile_details[0].user_country.value && city.stateCode === response.data.profile_details[0].user_state.value);

            var city_list_array = [];

            city_list_filtered.forEach(element => {
                city_list_array.push({ "label": element.name, "value": element.name });
            });

            setcityList(city_list_array);
        }

        if (response.data.profile_details[0].user_city !== null) {
            setuserCity(response.data.profile_details[0].user_city);
        }

        if (response.data.profile_details[0].phone !== null) {
            SetPhone(response.data.profile_details[0].phone);
        }

        country_load();
    }

    const saveemail = async () => {
        Setemailerr(false);
        Setemailerrmsg('');

        if (email === '' && emailpasswordblock === '') {
            Setemailerr(true);
            Setemailerrmsg('*Please enter email and password.!');
        }
        else if (email === '') {
            Setemailerr(true);
            Setemailerrmsg('*Please enter email.!');
        }
        else if (emailpasswordblock === '') {
            Setemailerr(true);
            Setemailerrmsg('*Please enter password.!');
        }
        else {
            try {
                const response = await axiosJWT.post('/profile-details/update-email',
                    {
                        email: email,
                        password: emailpasswordblock
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setpopupheading('Email');
                    Setpopupmsg(response.data.message);
                    handleShow();
                    Setemailpasswordblock('');
                }
            } catch (error) {
                Setemailerrmsg(error.response.data.msg);
                Setemailerr(true);
            }
        }
    }


    const savephone = async () => {
        Setphoneerr(false);
        Setphoneerrmsg('');

        if (phone === '' && phonepasswordblock === '') {
            Setphoneerr(true);
            Setphoneerrmsg('*Please enter phone number and password.!');
        }
        else if (phone === '') {
            Setphoneerr(true);
            Setphoneerrmsg('*Please enter phone number.!');
        }
        else if (phonepasswordblock === '') {
            Setphoneerr(true);
            Setphoneerrmsg('*Please enter password.!');
        }
        else {
            try {
                const response = await axiosJWT.post('/profile-details/update-phone',
                    {
                        phone: phone,
                        password: phonepasswordblock
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setpopupheading('Phone');
                    Setpopupmsg(response.data.message);
                    handleShow();
                    Setphonepasswordblock('');
                }
            } catch (error) {
                Setphoneerr(true);
                Setphoneerrmsg(error.response.data.msg);
            }
        }
    }

    const saveexpiry = async () => {
        Setexpiryerr(false);
        Setexpiryerrmsg('');

        if (SessionExpiry === '') {
            Setexpiryerr(true);
            Setexpiryerrmsg('*Please select the timeline.!');
        }
        else {
            const response = await axiosJWT.post('/profile-details',
                {
                    session_expiry: SessionExpiry
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                Setpopupheading('Automatic logout');
                Setpopupmsg('Logout timeline has been set. Please logout and login again.!');
                handleShow();
            }
        }
    }

    const savepersonal = async () => {

        if (Postal === '' || Postal === null) {
            SetInfoErr(true);
            SetInfoErrMsg('*Please enter postal code.!');
        }
        else if (Address === '' || Address === null) {
            SetInfoErr(true);
            SetInfoErrMsg('*Please enter address.!');
        }
        else if (userCountry === '' || userCountry === null) {
            SetInfoErr(true);
            SetInfoErrMsg('*Please choose country.!');
        }
        else if (userState === '' || userState === null) {
            SetInfoErr(true);
            SetInfoErrMsg('*Please choose state.!');
        }
        else if (userCity === '' || userCity === null) {
            SetInfoErr(true);
            SetInfoErrMsg('*Please choose city.!');
        }
        else {

            SetInfoErr(false);
            const formData = new FormData();

            formData.append('firstname', FirstName);
            formData.append('lastname', LastName);
            formData.append('address', Address);
            formData.append('postal', Postal);
            formData.append('profile_photo', Profilefile);
            formData.append('user_timezone', user_edit_timezone);
            formData.append('coach_bio', CoachBio);
            formData.append('country', JSON.stringify(userCountry));
            formData.append('state', JSON.stringify(userState));
            formData.append('city', JSON.stringify(userCity));

            ShowLoader()

            const response = await axiosJWT.post('/profile-details',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                Setpopupheading('Personal Information');
                Setpopupmsg('Personal information has been updated successfully.!');
                handleShow();
                getProfile();
                HideLoader();
            }
        }
    }

    const fileTypes = ["JPG", "PNG", "JPEG"];
    const [Profilefile, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file);
    };

    const [shows, setShows] = useState(false);

    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);

    const [showsPayment, setShowsPayment] = useState(false);

    const handleClosesPayment = () => setShowsPayment(false);
    const handleShowsPayment = () => setShowsPayment(true);

    const finalDeleteAccount = async () => {
        const response = await axiosJWT.post('/profile-details/delete',
            {
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            try {
                // await axios.delete('/logout');
                navigate("/login", { replace: true });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const finalDeletePayment = async () => {
        const response = await axiosJWT.get('/payments/delete-payment-method', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            handleClosesPayment();
            getProfile();
        }
    }

    const [showpaymentbuttonAdd, SetshowpaymentbuttonAdd] = useState(true);

    const AddPaymentStripe = async () => {
        const response = await axiosJWT.get('/payments/add-payment-method', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        window.location.href = response.data.payment_link;
    }

    const [showsuccesspayment, Setshowsuccesspayment] = useState(false);

    const CloseSuccesspaymentPopup = () => {
        Setshowsuccesspayment(false);
    }

    const [InfoErr, SetInfoErr] = useState(false);
    const [InfoErrMsg, SetInfoErrMsg] = useState('');
    const [timezonesList, settimezonesList] = useState([]);
    const [user_edit_timezone, setuser_timezone] = useState('');

    const [showCalendarPop, SetshowCalendarPop] = useState(false)
    const [calendarpoptitle, Setcalendarpoptitle] = useState('')
    const [calendarpopmsg, Setcalendarpopmsg] = useState('')
    const CloseCalendarPopup = () => {
        SetshowCalendarPop(false)
    }


    useEffect(() => {

        var timezone_list = [];
        timeZones.forEach(element => {
            timezone_list.push({ label: element, value: element });
        });
        // console.log(timezone_list)
        settimezonesList(timezone_list);

        getProfile();

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let paymentconfigured = params.get('configured');
        let google_calendar = params.get('google_calendar');
        let ms_calendar = params.get('ms_calendar');
        let withings_connect = params.get('withings_connect');
        let fullscript_access = params.get('fscript');

        if (fullscript_access === 'failed') {
            Setcalendarpoptitle('Fullscript Access Failed');
            Setcalendarpopmsg('Failed to connect with fullscript.!');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (fullscript_access === 'success') {
            Setcalendarpoptitle('Fullscript Access');
            Setcalendarpopmsg('Fullscript has been connected successfully.!');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        if (withings_connect === 'failed') {
            Setcalendarpoptitle('Withings device connect');
            Setcalendarpopmsg('Failed to connect with withings device');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (withings_connect === 'success') {
            Setcalendarpoptitle('Withings device connect');
            Setcalendarpopmsg('Withings device has been connected successfully');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        if (google_calendar === 'failed') {
            Setcalendarpoptitle('Google Calendar access');
            Setcalendarpopmsg('Failed to sync google calendar');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (google_calendar === 'success') {
            Setcalendarpoptitle('Google Calendar access');
            Setcalendarpopmsg('Google calendar has been synced successfully');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        if (ms_calendar === 'failed') {
            Setcalendarpoptitle('Outlook Calendar access');
            Setcalendarpopmsg('Failed to sync outlook calendar');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (ms_calendar === 'success') {
            Setcalendarpoptitle('Outlook Calendar access');
            Setcalendarpopmsg('Outlook calendar has been synced successfully');
            SetshowCalendarPop(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        if (paymentconfigured === 'success') {
            Setshowsuccesspayment(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        let payment_method = params.get('payment_method');
        if (payment_method === 'failed') {
            Setshowfailm(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }, []);

    const ChangedTimeZone = (ev) => {
        setuser_timezone(ev.value);
    }
    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }


    const country_load = async () => {
        var countries_list = Country.getAllCountries();

        var countries_list_array = [];

        countries_list.forEach(element => {
            countries_list_array.push({ "label": element.name, "value": element.isoCode });
        });

        setcountryList(countries_list_array);
    }

    const filterstate = async (e) => {

        SetInfoErr(false);
        setuserCountry(e);

        var state_list = State.getAllStates();

        var state_list_filtered = state_list.filter((state) => state.countryCode === e.value);

        var state_list_array = [];

        state_list_filtered.forEach(element => {
            state_list_array.push({ "label": element.name, "value": element.isoCode });
        });

        if (state_list_array.length > 0) {
            setstateList(state_list_array);
            setuserState('');
            setuserCity('');
            setcityList([]);
        }
        else {
            setuserState(e);
            setuserCity(e);
            setstateList([]);
            setcityList([]);
        }
    }

    const filtercity = async (e) => {

        SetInfoErr(false);
        setuserState(e);

        var city_list = City.getAllCities();

        var city_list_filtered = city_list.filter((city) => city.countryCode === userCountry.value && city.stateCode === e.value);

        var city_list_array = [];

        city_list_filtered.forEach(element => {
            city_list_array.push({ "label": element.name, "value": element.name });
        });


        if (city_list_array.length > 0) {
            setcityList(city_list_array);
            setuserCity('');
        }
        else {
            setuserCity(e);
            setcityList([]);
        }
    }

    const onchangeCity = async (e) => {
        SetInfoErr(false);
        setuserCity(e);
    }

    const AccessCalendar = async () => {


        const response = await axiosJWT.get('/google/allow-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            window.location.href = response.data.redirect_url;
        }

    }

    const AccessCalendarMicrosoft = async () => {

        const response = await axiosJWT.get('/outlook/allow-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            window.location.href = response.data.redirect_url;
        }

        console.log(response)
    }

    const [RemoveTrigger, SetRemoveTrigger] = useState('')
    const [calendarrevokeshow, Setcalendarrevokeshow] = useState(false)
    const calendarrevokeclose = () => {
        Setcalendarrevokeshow(false)
    }

    const RevokeCalendar = async () => {
        ShowLoader();

        const response = await axiosJWT.get('/profile-details/remove-calendar-access?type=' + RemoveTrigger + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            HideLoader();
            getProfile();
            Setcalendarrevokeshow(false)
        }
    }


    const RemoveCalendar = async (e) => {

        // ShowLoader();

        const target = e.target.getAttribute('data-id');
        SetRemoveTrigger(target)
        Setcalendarrevokeshow(true)


    }



    const RemoveFullscript = async (e) => {

        ShowLoader();

        const response = await axiosJWT.get('/fullscript/remove-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            setfscriptrevokeshow(false);
            HideLoader();
            getProfile();
        }
    }


    const AddZoomVideoAccess = async () => {

        const response = await axiosJWT.get('/zoom/allow-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            window.location.href = response.data.redirect_url;
        }

        console.log(response)

    }


    const AddFullscriptAccess = async () => {

        const response = await axiosJWT.get('/fullscript/allow-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            window.location.href = response.data.redirect_url;
        }

        console.log(response)
    }

    const DisconnectZoom = async () => {

        ShowLoader();

        const response = await axiosJWT.get('/zoom/remove-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            HideLoader();
            getProfile();
            CloseFailPopupMeet();
        }

    }

    const [ZoomConnect, SetZoomConnect] = useState(false)

    const [showfailMeet, SetshowfailMeet] = useState(false)
    const CloseFailPopupMeet = () => {
        SetshowfailMeet(false)
    }


    //withings

    const WithingsDeviceAccess = async () => {


        const response = await axiosJWT.get('/withings/allow-access', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.status === 200) {
            window.location.href = response.data.redirect_url;
        }

    }

    const [WithingsConnected, SetWithingsConnected] = useState(false)

    const WithingsDeviceRevokeAccess = async (e) => {

        try {

            const response = await axiosJWT.get('/withings/revoke-access', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                getProfile()
            }
        } catch (err) {
            console.log(err)
        }




    }




    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge profile_overall" : "Container-shrink profile_overall"}>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <div className="row account_setting_row_top">
                <span className="top_label_account_settings">Account settings</span>
            </div>
            <div className="profile container">
                <div className="row account_setting_row_top">
                    <div className="col inner_col_style_profile">
                        <span className="inner_profile_label_top">Personal Information</span>
                        <button className="top_billing_coach_payment save_button_profile" onClick={savepersonal}>Save</button>
                        <div className="row second_innerprofile_row">
                            {InfoErr === true && <div className="pass_err">{InfoErrMsg}</div>}
                            <div className="col">
                                <label className="inner_profile_label required">First Name</label>
                                <input type='text' className="add_category_inner_input profile_input total_session" defaultValue={FirstName} value={FirstName} onChange={(e) => { SetFirstname(e.target.value) }} />
                                <label className="inner_profile_label required">Last Name</label>
                                <input type='text' className="add_category_inner_input profile_input total_session" defaultValue={LastName} value={LastName} onChange={(e) => { SetLastName(e.target.value) }} />
                            </div>
                            <div className="col profile_photo">
                                <label className="inner_profile_label">profile photo</label>
                                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            </div>
                            {role === 'Nutrition' &&
                                <div className="profile_screen_bio">
                                    <label className='inner_profile_label' style={{ width: "100%", float: "left" }}>Your Bio</label>
                                    <textarea rows={'5'} defaultValue={CoachBio} onChange={(e) => { SetCoachBio(e.target.value) }}></textarea>
                                </div>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="inner_profile_label required">Country</label>
                                <Select
                                    options={countryList}
                                    onChange={filterstate}
                                    className="user_country"
                                    value={userCountry}
                                />
                            </div>
                            <div className="col">
                                <label className='inner_profile_label required' style={{ width: "100%", float: "left" }}>Timezone</label>
                                <Select
                                    options={timezonesList}
                                    onChange={ChangedTimeZone.bind(this)}
                                    className="coachadd_timezone"
                                    value={{ value: user_edit_timezone, label: user_edit_timezone }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="inner_profile_label required">State</label>
                                <Select
                                    options={stateList}
                                    onChange={filtercity}
                                    className="user_state"
                                    value={userState}
                                />
                            </div>
                            <div className="col">
                                <label className="inner_profile_label required">City</label>
                                <Select
                                    options={cityList}
                                    onChange={onchangeCity}
                                    className="user_city"
                                    value={userCity}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="inner_profile_label required">Address</label>
                                <input type='text' className="add_category_inner_input profile_input total_session" defaultValue={Address} value={Address} onChange={(e) => { SetAddress(e.target.value) }} />
                            </div>
                            <div className="col">
                                <label className="inner_profile_label required">Postal</label>
                                <input type='text' className="add_category_inner_input profile_input total_session" defaultValue={Postal} value={Postal} onChange={(e) => { SetPostal(e.target.value) }} />
                            </div>
                        </div>

                    </div>
                    <div className="col inner_col_style_profile">
                        <span className="inner_profile_label_top">Update Password</span>
                        <button className="top_billing_coach_payment save_button_profile" onClick={SavePassword}>Save</button>
                        <div className="row old_password_row">
                            {passerr === true && <div className="pass_err">{passerrmsg}</div>}
                            <label className="inner_profile_label required">Old password</label>
                            <input type='text' className="add_category_inner_input profile_input total_session old_password_input" defaultValue={OldPassword} onChange={(e) => { SetOldPassword(e.target.value) }} value={OldPassword} />
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="inner_profile_label required">New password</label>
                                <input type='text' defaultValue={NewPassword} onChange={(e) => { SetNewPassword(e.target.value) }} value={NewPassword} className="add_category_inner_input total_session profile_input" />
                            </div>
                            <div className="col">
                                <label className="inner_profile_label required">Re-type new password</label>
                                <input type='text' defaultValue={RetypeNewPassword} onChange={(e) => { SetRetypeNewPassword(e.target.value) }} value={RetypeNewPassword} className="add_category_inner_input total_session profile_input" />
                            </div>

                        </div>
                        <div className="profile_calendar_div">
                            <span className="inner_profile_label_top">Calendar access</span>
                            {
                                (role === 'Nutrition' || 'Client') ? <div>

                                    {
                                        GoogleCalendarAccess === false ? <button type="button" className="delete_account_but delete_payment_but google_calendar_access_button" onClick={AccessCalendar}><img className="google_calendar_svg" src={GoogleCalendarIcon} /> Allow Google Calendar Access</button> :
                                            <button type="button" className="delete_account_but delete_payment_but google_calendar_access_button" data-id="google" onClick={(e) => { RemoveCalendar(e) }}><img className="google_calendar_svg" src={GoogleCalendarIcon} /> Remove Google Calendar Access</button>
                                    }


                                    {
                                        MsCalendarAccess === false ? <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" onClick={AccessCalendarMicrosoft}><i className='fab fa-microsoft'></i> Allow Microsoft Calendar Access</button> : <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" data-id="microsoft" onClick={(e) => { RemoveCalendar(e) }}><i className='fab fa-microsoft'></i> Remove Microsoft Calendar Access</button>
                                    }


                                </div> : null
                            }

                            <div>

                            </div>
                        </div>

                        {
                            role === 'Nutrition' &&
                            <div className="profile_calendar_div">
                                <span className="inner_profile_label_top">Video chat access</span>

                                {
                                    ZoomConnect === false ? <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" data-id="microsoft" onClick={(e) => { AddZoomVideoAccess(e) }}><i className='fa fa-play-circle' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Connect with Zoom</button> :
                                        <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" data-id="microsoft" onClick={(e) => { SetshowfailMeet(true) }}><i className='fa fa-play-circle' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Disconnect from Zoom</button>
                                }

                            </div>
                        }

                        {role === 'Nutrition' &&
                            <div className="profile_calendar_div">
                                <span className="inner_profile_label_top">Fullscript Access</span>
                                {
                                    FullscriptAccess === false ? <button type="button" className="delete_account_but delete_payment_but fullscript_access_button" onClick={(e) => { AddFullscriptAccess(e) }}><i className='fa' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Allow Fullscript Access</button> :
                                        <button type="button" className="delete_account_but delete_payment_but fullscript_access_button" onClick={(e) => { setfscriptrevokeshow(true) }}><i className='fa' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Remove Fullscript Access</button>
                                }
                            </div>
                        }

                        {/* {
                            role === 'Client' &&
                            <div className="profile_calendar_div">
                                <span className="inner_profile_label_top">Withings Device Access</span>


                                {
                                    WithingsConnected === false ? <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" data-id="microsoft" onClick={(e) => { WithingsDeviceAccess(e) }}><i className='far fa-heart' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Connect with Withings devices</button> : <button type="button" className="delete_account_but delete_payment_but ms_calendar_access_button" data-id="microsoft" onClick={(e) => { WithingsDeviceRevokeAccess(e) }}><i className='far fa-heart' style={{ marginRight: '5px', color: '#039bf4 !important' }}></i>Disconnect  Withings devices</button>
                                }




                            </div>

                        } */}



                    </div>



                </div>

                <div className="row account_setting_row_top">
                    <div className="col inner_col_style_profile">
                        <span className="inner_profile_label_top">Update Email</span>
                        <button className="top_billing_coach_payment save_button_profile" onClick={saveemail}>Save</button>
                        <div className="row second_innerprofile_row">
                            {emailerr === true && <span className="pass_err">{emailerrmsg}</span>}
                            <div className="row old_password_row">
                                <label className="inner_profile_label required">Email</label>
                                <input type='text' className="add_category_inner_input profile_input total_session old_password_input" defaultValue={email} value={email} onChange={(e) => { SetEmail(e.target.value) }} />
                            </div>
                            <div className="row old_password_row">
                                <label className="inner_profile_label required">Password</label>
                                <input type='text' className="add_category_inner_input profile_input total_session old_password_input" defaultValue={emailpasswordblock} value={emailpasswordblock} onChange={(e) => { Setemailpasswordblock(e.target.value) }} />
                            </div>
                        </div>
                    </div>

                    <div className="col inner_col_style_profile">
                        <span className="inner_profile_label_top">Update Phone Number</span>
                        <button className="top_billing_coach_payment save_button_profile" onClick={savephone}>Save</button>
                        <div className="row second_innerprofile_row">
                            {phoneerr === true && <span className="pass_err">{phoneerrmsg}</span>}
                            <div className="row old_password_row">
                                <label className="inner_profile_label required">Phone Number</label>
                                <PhoneInput
                                    defaultCountry="US"
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={SetPhone} />
                            </div>
                            <div className="row old_password_row">
                                <label className="inner_profile_label required">Password</label>
                                <input type='text' className="add_category_inner_input profile_input total_session old_password_input" defaultValue={phonepasswordblock} value={phonepasswordblock} onChange={(e) => { Setphonepasswordblock(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row account_setting_row_top">
                    <div className="col inner_col_style_profile">
                        <span className="inner_profile_label_top">Automatic Logout<i className="fa fa-info-circle info_profile tooltipinfo" title="Default automatic logout is one day.!" aria-hidden="true"></i></span>
                        <button className="top_billing_coach_payment save_button_profile" onClick={saveexpiry}>Save</button>
                        <div className="row old_password_row">
                            {expiryerr === true && <span className="pass_err">{expiryerrmsg}</span>}
                            <label className="inner_profile_label">Select timeline</label>
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop old_password_input' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={SessionExpiry} value={SessionExpiry} onChange={(e) => { SetSessionexpiry(e.target.value) }}   >
                                <option value={''}>Select timeline</option>
                                <option value={'1'}>1 Day</option>
                                <option value={'2'}>2 Days</option>
                                <option value={'5'}>5 Days</option>
                                <option value={'10'}>10 Days</option>
                                <option value={'20'}>20 Days</option>
                                <option value={'30'}>30 Days</option>
                            </select>
                        </div>
                    </div>

                    {role !== 'Admin' ?
                        <div className="col inner_col_style_profile">
                            <span className="inner_profile_label_top">Account Details <i className="fa fa-info-circle info_profile tooltipinfo" title="Delete your all information by deleting your account.!" aria-hidden="true"></i></span>
                            {role === 'Nutrition' &&
                                <div className="row">
                                    <button type="button" className="btn btn-outline-danger delete_account_but" onClick={handleShows}>Delete My account</button>
                                    {
                                        showpaymentbutton === true &&
                                        <button type="button" className="btn btn-outline-danger delete_account_but delete_payment_but" onClick={handleShowsPayment}>Delete My Payment method</button>
                                    }
                                    {
                                        showpaymentbuttonAdd === true &&
                                        <button type="button" className="btn btn-outline-danger delete_account_but delete_payment_but" onClick={AddPaymentStripe}>Add Payment method</button>
                                    }

                                </div>
                            }

                            {role === 'Client' &&
                                <div className="row">

                                    <button type="button" className="btn btn-outline-danger delete_account_but" onClick={handleShows}>Delete My account</button>
                                    {
                                        showpaymentbutton === true &&
                                        <button type="button" className="btn btn-outline-danger delete_account_but delete_payment_but" onClick={handleShowsPayment}>Delete My Payment method</button>
                                    }
                                    {
                                        showpaymentbuttonAdd === true &&
                                        <button type="button" className="btn btn-outline-danger delete_account_but delete_payment_but" onClick={AddPaymentStripe}>Add Payment method</button>
                                    }

                                </div>
                            }
                        </div>
                        : null}
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{popupheading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{popupmsg}</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button className="modal_action_button float_right btn btn-primary" variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={shows} onHide={handleCloses} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete this account?</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className="modal_close_button" onClick={handleCloses}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="modal_action_button" onClick={finalDeleteAccount} >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showsPayment} onHide={handleClosesPayment} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete your configured payment method?</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleClosesPayment}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="float_right" onClick={finalDeletePayment} >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showsuccesspayment} onHide={CloseSuccesspaymentPopup} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Payment method configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You have successfully configured your payment method. Subscription amount will be deducted from the configured account going forward.
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="primary" className="modal_action_button float_right" onClick={CloseSuccesspaymentPopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showfailm} onHide={CloseFailMPopup} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Method Add Failed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your payment method configuration has been failed!
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailMPopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCalendarPop} onHide={CloseCalendarPopup} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>{calendarpoptitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {calendarpopmsg}
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="primary" className="modal_action_button float_right" onClick={CloseCalendarPopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showfailMeet} onHide={CloseFailPopupMeet} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {'Please be aware before disconnecting from zoom meet.you may have scheduled meeting appointments.'}
                    <br />
                    <p style={{ marginTop: '20px', fontWeight: '600' }}>
                        {'Note: If you have meetings scheduled with zoom meet then client wont be able to join meeting, reschedules, meeting cancels cannot be synced with zoom meet.'}
                    </p>

                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="primary" className="modal_action_button float_right" onClick={DisconnectZoom}>
                        Disconnect
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={fscriptrevokeshow} onHide={fscriptrevokeclose} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Remove Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to remove access of Fullscript?</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className="modal_close_button" onClick={fscriptrevokeclose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="modal_action_button" onClick={RemoveFullscript} >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={calendarrevokeshow} onHide={calendarrevokeclose} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Remove Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to remove access from this calendar?</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className="modal_close_button" onClick={calendarrevokeclose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="modal_action_button" onClick={RevokeCalendar} >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CoachProfile
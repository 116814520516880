import { BrowserRouter, Routes, Route, useNavigate, NavLink, Link, Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Dashboard from "./components/AdminComponents/Dashboard";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";
import HeaderNutrition from "./components/HeaderNutrition";
import ManageUsers from "./components/AdminComponents/ManageUsers";
import AdminBilling from "./components/AdminComponents/AdminBilling";
import PaymentMethods from "./components/AdminComponents/PaymentMethods";
import AdminNotification from "./components/AdminComponents/AdminNotification";
import ManageTeam from "./components/AdminComponents/AdminManageTeam/ManageTeam";
import Marketing from "./components/AdminComponents/Marketing";
import Payout from "./components/AdminComponents/Payout";
import Messages from "./components/AdminComponents/Messages";
import CoachUsers from "./components/CoachComponents/Users";
import CoachDashboard from "./components/CoachComponents/Dashboard";
import CoachService from "./components/CoachComponents/Services/Service";
import CoachBilling from "./components/CoachComponents/Billing/billing";
import CoachPlans from "./components/CoachComponents/Plans";
import CoachPayment from "./components/CoachComponents/Payments/payments";
import Questionnaire from "./components/CoachComponents/Questionnaire/questionnaire";
import AddQuestionnaire from "./components/CoachComponents/Questionnaire/AddQuestionnaire";
import EditQuestionnaire from "./components/CoachComponents/Questionnaire/EditQuestionnaire";
import Foodplans from "./components/CoachComponents/Plans/Foodplans";
import ListQuestionnaire from "./components/CoachComponents/Questionnaire/ListQuestionnaire";
import CoachDocuments from "./components/CoachComponents/Documents/documents";
import CoachProfile from "./components/CoachComponents/Profile/profile";
import ClientFoodDiary from "./components/ClientComponents/FoodDiary/fooddiary";
import ClientProgressDiary from "./components/ClientComponents/ProgressDiary/ProgressDiary";
import CoachBlog from "./components/CoachComponents/Blog";
import ClientMealPlan from "./components/ClientComponents/Mealplan"
import ClientDashboard from "./components/ClientComponents/Dashboard";
import ClientWeekPlan from "./components/ClientComponents/Weekplan";
import KetoBook from "./components/ClientComponents/KetoBook";
import ClientSubscriptions from "./components/ClientComponents/Subscriptions/Subscription"
import ClientBlogs from "./components/ClientComponents/ClientBlogs/ClientBlogs";
import AddMemberByClient from "./components/CoachComponents/AddMember/addmember";
import ClientAppointment from "./components/ClientComponents/Appointment/appointment";
import Availability from "./components/CoachComponents/Availability";
import ClientQuestionnaire from "./components/ClientComponents/Questionnaire/questionnaire";
import ViewSubmittedQuestionnaire from "./components/CoachComponents/Questionnaire/ViewSubmittedQuestionnaire";
import AppointmentCalendar from "./components/CoachComponents/AppointmentCalendar"
import AdminMailTemplates from "./components/AdminComponents/AdminMailTemplates";
import ClientGenerateReport from "./components/ClientComponents/Report/Report";
import AdminManageAppointment from "./components/AdminComponents/AdminManageAppointment";
import AdminAnalytics from "./components/AdminComponents/AdminAnalytics/AdminAnlytics";
import CoachMessages from "./components/CoachComponents/CoachMessages";
import ClientMessages from "./components/ClientComponents/ClientMessages";
import VideoMessages from "./components/VideoMessages";
import Contact from "./components/Contact";
import ContactEnquires from "./components/AdminComponents/ContactEnquires";
import AdminLabTest from "./components/AdminComponents/LabTest"
import ManagerDashboard from "./components/ManagerComponents/Dashboard";
import ManageUsersByManager from "./components/ManagerComponents/ManageUsers";
import ManagerAppointmentAccess from "./components/ManagerComponents/ManageAppointment";
import ManagerAccessPayout from "./components/ManagerComponents/ManagePayout";
import ManagerContactEnquiry from "./components/ManagerComponents/ManagerContactEnquiry";
import ManagerAnalytics from "./components/ManagerComponents/ManageAnalytics";
import ListOfAgreements from "./components/ClientComponents/Agreements/ListOfAgreements";
import SuccessRedirect from "./components/Payments/Redirect";
import CancelRedirect from "./components/Payments/CancelRedirect";
import RegistrationSuccess from './components/Payments/RegistrationSuccess'
import CoachSubscriptions from "./components/CoachComponents/Subscription/Subscription";
import FoodJournalListing from './components/ClientComponents/FoodJournal/Listing'
import ManagerMessages from "./components/ManagerComponents/ManagerMessages";
import ClientConnectedDevices from "./components/ClientComponents/ClientConnecteddevices";
import ExerciseList from "./components/ClientComponents/Exercises/ExerciseList";
import GoogleFitness from "./components/ClientComponents/GoogleFitness";
import AddQuiz from "./components/ClientComponents/Quiz/AddQuiz";
import CoachCat from "./components/AdminComponents/CoachCat";
import DocumentsUpload from "./components/AdminComponents/Documents/documents";
import AdminUserDocs from "./components/AdminComponents/Documents/UserDocs";
import PermissionDocs from "./components/AdminComponents/Documents/PermissionDocs";
import FoodJournalListingCoach from "./components/CoachComponents/FoodJournal/Listing";
import Caloriemama from "./components/Caloriemama";
import IntakeFormsList from "./components/AdminComponents/IntakeForms/List";
import UsersSubmittedForms from "./components/AdminComponents/IntakeForms/SubmittedForms";
import EventsLoad from "./components/ClientComponents/Google/Events";
import MsEventsLoad from "./components/ClientComponents/Outlook/Events";
import ZoomEvents from './components/ClientComponents/Zoom/Events';
import WithingsEventsLoad from "./components/ClientComponents/Withings/Events";
import FScriptEventsLoad from "./components/FullscriptEvents";
import GLPList from "./components/AdminComponents/GLP/List";
import WithingsOrderStatus from "./components/ClientComponents/Withings/OrderStatus";
import BelugaVisits from "./components/ClientComponents/BelugaHealth/Visits";


function App() {
  
var role= localStorage.getItem("role");

return (
    <BrowserRouter>
      <Routes>      
      <Route exact path="/login" element={<Login/>} />
      <Route exact path="/forgot-password" element={<ForgotPassword/>} />
      <Route exact path="/" element={role === 'Admin' ? <><HeaderNutrition/><Dashboard /></> : (role === 'Nutrition' ? <><HeaderNutrition/><CoachDashboard /></> : (role === 'Client' ? <><HeaderNutrition/><ClientDashboard/></> : <Login/>))} />

        {/* <Route path="/register" element={!role ? <Register/> : (role === 'Admin' ? <><HeaderNutrition/><Dashboard /></> : (role === 'Nutrition' ? <><HeaderNutrition/><CoachDashboard /></> : <><HeaderNutrition/><ClientDashboard /></>))} /> */}

        <Route path="/admin/manage-users" element={role === 'Admin' ? <><HeaderNutrition/><ManageUsers/></> : <Login/>} />
        <Route path="/admin/dashboard" element={role === 'Admin' ? <><HeaderNutrition/><Dashboard /></> : <Login/>} />
        <Route path="/admin/billing" element={role === 'Admin' ? <><HeaderNutrition/><AdminBilling /></> : <Login/>} />
        <Route path="/admin/payment-methods" element={role === 'Admin' ? <><HeaderNutrition/><PaymentMethods /></> : <Login/>} />
        <Route path="/admin/notification" element={role === 'Admin' ? <><HeaderNutrition/><AdminNotification /></>: <Login/>} />
        <Route path="/admin/manage-team" element={role === 'Admin' ? <><HeaderNutrition/><ManageTeam /></>: <Login/>} />
        <Route path="/admin/marketing" element={role === 'Admin' ? <><HeaderNutrition/><Marketing /></>: <Login/>} />
        <Route path="/admin/payout" element={role === 'Admin' ? <><HeaderNutrition/><Payout /></>: <Login/>} />
        <Route path="/admin/messages" element={role === 'Admin' ? <><HeaderNutrition/><Messages /></>: <Login/>} />
        <Route path="/admin/mail-templates" element={role === 'Admin' ? <><HeaderNutrition/><AdminMailTemplates /></>: <Login/>} />
        <Route path="/admin/manage-appointment" element={role === 'Admin' ? <><HeaderNutrition/><AdminManageAppointment /></>: <Login/>} />
        <Route path="/admin/analytics" element={role === 'Admin' ? <><HeaderNutrition/><AdminAnalytics /></>: <Login/>} />
        <Route path="/video/:id" element={role === 'Admin' || role === 'Nutrition' || role === 'Client' ? <><HeaderNutrition/><VideoMessages /></>: <Login/>} />
        <Route path="/contact" element={role === 'Nutrition' || role === 'Client' ? <><HeaderNutrition/><Contact /></>: <Login/>} />
        <Route path="/admin/contacts" element={role === 'Admin' ? <><HeaderNutrition/><ContactEnquires /></>: <Login/>} />
        <Route path="/admin/labtest" element={role === 'Admin' ? <><HeaderNutrition/><AdminLabTest /></>: <Login/>} />
        <Route path="/admin/coach-cat" element={role === 'Admin' ? <><HeaderNutrition/><CoachCat /></>: <Login/>} />
        <Route path="/admin/documents" element={role === 'Admin' ? <><HeaderNutrition/><DocumentsUpload /></>: <Login/>} />
        <Route path="/admin/view-docs/users" element={role === 'Admin' ? <><HeaderNutrition/><AdminUserDocs /></>: <Login/>} />
        <Route path="/admin/questionnaire" element={role === 'Admin' ? <><HeaderNutrition/><ListQuestionnaire /></>: <Login/>} />
        <Route path="/admin/add-questionnaire" element={role === 'Admin' ? <><HeaderNutrition/><AddQuestionnaire/></>: <Login/>} />
        <Route path="/admin/edit-questionnaire" element={role === 'Admin' ? <><HeaderNutrition/><EditQuestionnaire/></>: <Login/>} />
        <Route path="/admin/intake-form" element={role === 'Admin' ? <><HeaderNutrition/><IntakeFormsList/></>: <Login/>} />
        <Route path="/admin/view-submitted-forms" element={role === 'Admin' ? <><HeaderNutrition/><UsersSubmittedForms/></>: <Login/>} />
        <Route path="/admin/glp-quiz" element={role === 'Admin' ? <><HeaderNutrition/><GLPList/></>: <Login/>} />

        
        

        
        <Route path="/coach/users" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachUsers /></>: <Login/>} />
        <Route path="/coach/dashboard" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachDashboard /></>: <Login/>} />
        <Route path="/coach/services" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachService /></>: <Login/>} />
        <Route path="/coach/billing" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachBilling /></>: <Login/>} />
        <Route path="/coach/plans" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachPlans /></>: <Login/>} />
        <Route path="/coach/plans/:id" element={role === 'Nutrition' ? <><HeaderNutrition/><Foodplans /></>: <Login/>} />
        <Route path="/coach/payments" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachPayment /></>: <Login/>} />
        <Route path="/coach/questionnaire" element={role === 'Nutrition' ? <><HeaderNutrition/><ListQuestionnaire /></>: <Login/>} />
        <Route path="/coach/add-questionnaire" element={role === 'Nutrition' ? <><HeaderNutrition/><AddQuestionnaire/></>: <Login/>} />
        <Route path="/coach/edit-questionnaire" element={role === 'Nutrition' ? <><HeaderNutrition/><EditQuestionnaire/></>: <Login/>} />
        <Route path="/coach/submitted-list-questionnaire" element={role === 'Nutrition' ? <><HeaderNutrition/><ViewSubmittedQuestionnaire /></>: <Login/>} />
        <Route path="/coach/documents" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachDocuments/></>: <Login/>} />
        <Route path="/coach/directories" element={role === 'Nutrition' ? <><HeaderNutrition/><DocumentsUpload/></>: <Login/>} />
        <Route path="/coach/add-member" element={role === 'Nutrition' ? <><HeaderNutrition/><AddMemberByClient /></>: <Login/>} />
        <Route path="/coach/availability" element={role === 'Nutrition' ? <><HeaderNutrition/><Availability /></>: <Login/>} />
        <Route path="/coach/blog" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachBlog /></>: <Login/>} />
        <Route path="/coach/calendar" element={role === 'Nutrition' ? <><HeaderNutrition/><AppointmentCalendar /></>: <Login/>} />
        <Route path="/coach/messages" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachMessages/></>: <Login/>} /> 
        <Route path="/coach/subscription" element={role === 'Nutrition' ? <><HeaderNutrition/><CoachSubscriptions/></>: <Login/>} /> 
        <Route path="/coach/view-docs/users" element={role === 'Nutrition' ? <><HeaderNutrition/><PermissionDocs /></>: <Login/>} />
        <Route path="/coach/food-journal" element={role === 'Nutrition' ? <><HeaderNutrition/><FoodJournalListingCoach /></>: <Login/>} />
        <Route path="/coach/submit-intake-form" element={role === 'Nutrition' ? <><HeaderNutrition/><ClientQuestionnaire /></>: <Login/>} />


        <Route path="/client/food-diary" element={role === 'Client' ? <><HeaderNutrition/><ClientFoodDiary/></>: <Login/>} />
        <Route path="/client/progress-diary" element={role === 'Client' ? <><HeaderNutrition/><ClientProgressDiary/></>: <Login/>} />
        <Route path="/client/dashboard" element={role === 'Client' ? <><HeaderNutrition/><ClientDashboard/></>: <Login/>} />
        <Route path="/client/keto-book" element={role === 'Client' ? <><HeaderNutrition/><KetoBook/></>: <Login/>} />
        <Route path="/profile" element={<><HeaderNutrition/><CoachProfile /></>} />        
        <Route path="/client/meal-plan" element={role === 'Client' ? <><HeaderNutrition/><ClientMealPlan /></>: <Login/>} />
        <Route path="/client/week-plan" element={role === 'Client' ? <><HeaderNutrition/><ClientWeekPlan /></>: <Login/>} />
        <Route path="/client/subscriptions" element={role === 'Client' ? <><HeaderNutrition/><ClientSubscriptions /></>: <Login/>} />
        <Route path="/client/blogs" element={role === 'Client' ? <><HeaderNutrition/><ClientBlogs /></>: <Login/>} />
        <Route path="/client/appointment" element={role === 'Client' ? <><HeaderNutrition/><ClientAppointment /></>: <Login/>} />        
        <Route path="/client/questionnaire" element={role === 'Client' ? <><HeaderNutrition/><ClientQuestionnaire /></>: <Login/>} />     
        <Route path="/client/report" element={role === 'Client' ? <><HeaderNutrition/><ClientGenerateReport /></>: <Login/>} /> 
        <Route path="/client/messages" element={role === 'Client' ? <><HeaderNutrition/><ClientMessages/></>: <Login/>} /> 
        <Route path="/client/agreements" element={role === 'Client' ? <><HeaderNutrition/><ListOfAgreements/></>: <Login/>} /> 
        <Route path="/client/food-journal" element={role === 'Client' ? <><HeaderNutrition/><FoodJournalListing/></>: <Login/>} />
        <Route path="/client/connected-devices" element={role === 'Client' ? <><HeaderNutrition/><ClientConnectedDevices/></>: <Login/>} />
        <Route path="/client/fitness-videos" element={role === 'Client' ? <><HeaderNutrition/><ExerciseList/></>: <Login/>} />
        <Route path="/client/google-fitness" element={role === 'Client' ? <><HeaderNutrition/><GoogleFitness /></>: <Login/>} />
        {/* <Route path="/client/add-quiz" element={role === 'Client' ? <><AddQuiz /></>: <Login/>} /> */}
        <Route path="/client/directories" element={role === 'Client' ? <><HeaderNutrition/><DocumentsUpload/></>: <Login/>} />
        <Route path="/client/view-docs/users" element={role === 'Client' ? <><HeaderNutrition/><PermissionDocs /></>: <Login/>} />
        <Route path="/client/withings-order" element={role === 'Client' ? <><HeaderNutrition/><WithingsOrderStatus /></>: <Login/>} />


        <Route path="/manager/dashboard" element={role === 'Manager' ? <><HeaderNutrition/><ManagerDashboard/></>: <Login/>} /> 
        <Route path="/manager/messages" element={role === 'Manager' ? <><HeaderNutrition/><ManagerMessages/></>: <Login/>} /> 
        <Route path="manager/manage-users" element={role === 'Manager' ? <><HeaderNutrition/><ManageUsersByManager/></>: <Login/>} /> 
        <Route path="manager/manage-appointment" element={role === 'Manager' ? <><HeaderNutrition/><ManagerAppointmentAccess/></>: <Login/>} /> 
        <Route path="manager/payout" element={role === 'Manager' ? <><HeaderNutrition/><ManagerAccessPayout/></>: <Login/>} /> 
        <Route path="manager/contact-enquiry" element={role === 'Manager' ? <><HeaderNutrition/><ManagerContactEnquiry/></>: <Login/>} /> 
        <Route path="manager/analytics" element={role === 'Manager' ? <><HeaderNutrition/><ManagerAnalytics/></>: <Login/>} /> 

        <Route path="payments/success" element={ <SuccessRedirect/>} /> 
        <Route path="stripe/success" element={ <SuccessRedirect/>} /> 
        <Route path="payments/cancel" element={ <CancelRedirect/>} /> 
        <Route path="payment-method/cancel" element={ <CancelRedirect/>} /> 
        <Route path="registration/success" element={ <RegistrationSuccess />} /> 

        <Route path="/client/caloriemama" element={role === 'Client' ? <><HeaderNutrition/><Caloriemama/></>: <Login/>} /> 
        <Route path="/client/google/events" element={(role === 'Client' || role === 'Nutrition') ? <><EventsLoad/></>: <Login/>} /> 
        <Route path="/client/ms/events" element={(role === 'Client' || role === 'Nutrition') ? <><MsEventsLoad/></>: <Login/>} /> 
        <Route path="/client/zoom/events" element={(role === 'Client' || role === 'Nutrition') ? <><ZoomEvents/></>: <Login/>} /> 
        <Route path="/client/withings/events" element={(role === 'Client') ? <><WithingsEventsLoad/></>: <Login/>} /> 
        <Route path="/fullscript/events" element={(role === 'Nutrition') ? <><FScriptEventsLoad/></>: <Login/>} />
        <Route path="/client/beluga-health" element={(role === 'Client') ? <><HeaderNutrition/><BelugaVisits/></>: <Login/>} />

      </Routes>
    </BrowserRouter>
  );
}
 
export default App;
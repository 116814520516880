import React, { useState, useEffect } from 'react'
import axios from 'axios'
import IframeResizer from 'iframe-resizer-react'

const ClientConnectedDevices = () => {

    const token = localStorage.getItem("token");
    const side_nav = localStorage.getItem("side_nav");
    const [profileData, setprofileData] = useState(''); 
    const [loadtab, setloadtab] = useState(false);
    const [iframeURL, setiframeURL] = useState('');   

    useEffect(() => {
        load_headsup();
    }, []);

    const axiosJWT = axios.create();

    
    const load_headsup = async () => {       

        const user_response = await axiosJWT.get("/user",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        
        console.log(user_response);

        setprofileData(user_response.data);    
        
        var securelink = await getSecureLink(user_response.data);
        
        loadIframe("dashboard",securelink);
    }

    const getSecureLink = async (userdata) => {

        const request = await fetch(process.env.REACT_APP_HEADSUP_URL+"/api/v1/organizations/"+process.env.REACT_APP_HEADSUP_ORGID+"/secure_user_link_data/"+userdata.hu_userid,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_HEADSUP_KEY}`
              },
            }
        );       
      
        const headsup_response = await request.json();

        return headsup_response.secure_user_link_data;

    }

    const ChangeTab = async (e) => {
        const alltabs = document.querySelectorAll(".device_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab');

        console.log(tab)

        var securelink = await getSecureLink(profileData);

        loadIframe(tab,securelink);    
    }

    const loadIframe = async (tab,securelink) => {        

        var iframe_url = process.env.REACT_APP_HEADSUP_VIEW_URL+tab+"?org_uuid="+process.env.REACT_APP_HEADSUP_ORGID+"&sec_p="+securelink;

        setiframeURL(iframe_url);

        setloadtab(true); 
    }
   
    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="client_connected_devices container">
                    <h4>Connected Devices</h4>
                    {profileData ? 
                        <>
                            {/* <div className='menubox'>
                                <div className='device_tabmenu'>
                                    <a className="active" id="service" data-tab={'dashboard'} onClick={(e) => { ChangeTab(e) }}>Dashboard</a>
                                    <a className="" id="category" data-tab={'analyzer'} onClick={(e) => { ChangeTab(e) }}>Reports</a>
                                </div>
                            </div> */}
                            {loadtab === true ?
                            <IframeResizer
                            src={iframeURL} 
                            style={{ width: '1px', minWidth: '100%', minHeight: '1000px'}}
                            />             
                            : null }
                        </>
                        : null }     
                </div>
            </section>
        </div>
    )

}

export default ClientConnectedDevices
import React, { useEffect, useState } from "react";
import axios from 'axios';

const Contact = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token")
    const [FirstName, SetFirstName] = useState('')
    const [LastName, SetLastName] = useState('')
    const [EmailAddress, SetEmailAddress] = useState('')
    const [Subject, SetSubject] = useState('')
    const [Queries, SetQueries] = useState('')
    const axiosJWT = axios.create();

    const [ErrFnameShow, SetErrFnameShow] = useState(false)
    const [ErrFnameMsg, SetErrFnameMsg] = useState('')

    const [ErrLnameShow, SetErrLnameShow] = useState(false)
    const [ErrLnameMsg, SetErrLnameMsg] = useState('')

    const [ErrEmailShow, SetErrEmailShow] = useState(false)
    const [ErrEmailMsg, SetErrEmailMsg] = useState('')

    const [ErrSubjectShow, SetErrSubjectShow] = useState(false)
    const [ErrSubjectMsg, SetErrSubjectMsg] = useState('')

    const [ErrQueryShow, SetErrQueryShow] = useState(false)
    const [ErrQueryMsg, SetErrQueryMsg] = useState('')

    const [SuccessMsgShow,SetSuccessMsgShow]=useState(false);
    const [SuccessMsg,SetSuccessMsg]=useState('')

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const GetProfileDetails = async () => {

        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data !== null) {
            SetFirstName(response.data.profile_details[0].first_name)
            SetLastName(response.data.profile_details[0].last_name)
            SetEmailAddress(response.data.profile_details[0].email)

        }



    }

    const CreateContact = async () => {


        SetErrFnameShow(false)
        SetErrLnameShow(false)
        SetErrEmailShow(false)
        SetErrSubjectShow(false)
        SetErrQueryShow(false)

        var err = false;

        if (FirstName === '') {
            SetErrFnameShow(true)
            SetErrFnameMsg('Pleas enter first name')
            err = true
        }
        if (LastName === '') {
            SetErrLnameShow(true)
            SetErrLnameMsg('Please enter last name')
            err = true
        }
        if (EmailAddress === '') {
            SetErrEmailShow(true)
            SetErrEmailMsg('Please enter email address')
            err = true
        }
        if (Subject === '') {
            SetErrSubjectShow(true)
            SetErrSubjectMsg('Please select subject')
            err = true
        }
        if (Queries === '') {
            SetErrQueryShow(true)
            SetErrQueryMsg('Please enter your query')
            err = true
        }

        if (err === true) {
            return false
        }
        else {

            ShowLoader()

            const response = await axiosJWT.post('/contact',
                {
                    'first_name': FirstName,
                    'last_name': LastName,
                    'email_address': EmailAddress,
                    'subject': Subject,
                    'queries': Queries



                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                HideLoader()
                SetSuccessMsgShow(true)
                SetSuccessMsg('Contact enquiry form has been submitted succesfully.we will get back to you shortly!')

                setTimeout(function () {

                    SetSuccessMsgShow(false)
                  }, 3000);
               
                SetSubject('')
                SetQueries('')
            }

        }


    }

    useEffect(() => {
        GetProfileDetails()
    }, [])





    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                <div className="container">
                    <div className="row over_all_row_contact">
                        <div className="row">
                            <label className="commission_inner_popup_label required">First Name</label>
                            <input type="text" value={FirstName} defaultValue={FirstName} onChange={(e) => { SetFirstName(e.target.value) }} className="add_category_inner_input contact_form_inputs" disabled={true}></input>
                            {ErrFnameShow === true &&
                                <span className="err_msg_add_client">{ErrFnameMsg}</span>
                            }
                        </div>
                        <div className="row">
                            <label className="commission_inner_popup_label required">Last Name</label>
                            <input type="text" value={LastName} defaultValue={LastName} onChange={(e) => { SetLastName(e.target.value) }} className="add_category_inner_input contact_form_inputs" disabled={true}></input>
                            {ErrLnameShow === true &&
                                <span className="err_msg_add_client">{ErrLnameMsg}</span>
                            }
                        </div>
                        <div className="row">
                            <label className="commission_inner_popup_label required">Email Address</label>
                            <input type="text" value={EmailAddress} defaultValue={EmailAddress} onChange={(e) => { SetEmailAddress(e.target.value) }} className="add_category_inner_input contact_form_inputs" disabled={true}></input>
                            {ErrEmailShow === true &&
                                <span className="err_msg_add_client">{ErrEmailMsg}</span>
                            }
                        </div>
                        <div className="row">
                            <label className="commission_inner_popup_label required">Subject</label>
                            <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin contact_form_inputs' style={{ fontWeight: 500 }} onChange={(e) => { SetSubject(e.target.value) }} value={Subject}   >
                                <option value="">Please select subject</option>
                                <option value="Subscription">Subscription</option>
                                <option value="Payment">Payment</option>
                                <option value="Appointments">Appointments</option>
                                <option value="others">Others</option>



                            </select>
                            {ErrSubjectShow === true &&
                                <span className="err_msg_add_client">{ErrSubjectMsg}</span>
                            }

                        </div>
                        <div className="row">

                            <label className="commission_inner_popup_label required">Queries</label>
                            <textarea rows="8" className="add_category_inner_input contact_form_inputs text_area_focus" defaultValue={Queries} onChange={(e) => { SetQueries(e.target.value) }} value={Queries}></textarea>
                            {ErrQueryShow === true &&
                                <span className="err_msg_add_client">{ErrQueryMsg}</span>
                            }
                        </div>
                        <div className="row">
                            {SuccessMsgShow === true &&
                                <span className="err_msg_add_client" style={{color:'green'}}>{SuccessMsg}</span>
                            }

                        </div>
                        <div className="row">
                            <div>
                                <button type="button" className="btn green_button btn-success contact_submit_button" onClick={CreateContact}>Submit</button>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Contact
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import Pagination from "react-js-pagination";
import { ReactFormGenerator } from 'react-form-builder2';

const IntakeFormsList = () => {
  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname

  const [IntakeFormList, SetIntakeFormList] = useState([]);
  const [PageNumber, SetPageNumber] = useState(1);
  const [PerPage, SetPerPage] = useState(50);
  const [TotalRecord, SetTotalRecord] = useState(0);


  const [showsaddintake, setshowsaddintake] = useState(false);
  const handleClosesaddintake = () => { setshowsaddintake(false) }
  const [poppuplabelintake,Setpoppuplabelintake]=useState('')
  const [IntakeFormName,SetIntakeFormName]=useState('')
  const [IntakeFormNameErr,SetIntakeFormNameErr]=useState(false)
  const [IntakeFormNameErrMsg,SetIntakeFormNameErrMsg]=useState('')
  const [questionnaireselectoptions, setquestionnaireselectoptions] = useState([]);
  const [SelectedQuestions, SetSelectedQuestions] = useState('');
  const [questionnaireselectoptionsdefault, Setquestionnaireselectoptionsdefault] = useState([]);
  const [IntakeFormQuestionnaireErr,SetIntakeFormQuestionnaireErr]=useState(false)
  const [IntakeFormQuestionnaireErrMsg,SetIntakeFormQuestionnaireErrMsg]=useState('')
  const [poppupbuttonidintake,Setpoppupbuttonidintake]=useState('')
  const [poppupbuttonlabelintake,Setpoppupbuttonlabelintake]=useState('')

  const [showsaddintakesucess, Setshowsaddintakesucess] = useState(false);
  const handleClosesaddintakesuccess = () => { Setshowsaddintakesucess(false) }
  const [intakeformmsg, Setintakeformmsg] = useState('');

  const [showsdeleteintake,Setshowsdeleteintake]=useState(false)
  const handleClosesdeleteintake=()=>{
    Setshowsdeleteintake(false)
  }
  const [IntakeDeleteFormid,SetIntakeDeleteFormid]=useState('')

  const [showspreviewintake,Setshowspreviewintake]=useState(false)
  const handleClosespreviewintake=()=>{
    Setshowspreviewintake(false)
  }
  const [PreviewFormData,SetPreviewFormData]=useState('')

  const [showssendintake, Setshowssendintake] = useState(false);
  const handleClosessendintake = () => { Setshowssendintake(false) }

  const [Clientuserlist,SetClientuserlist]=useState([])
  const [Coachuserlist,SetCoachuserlist]=useState([])
  const [PickedClients, SetPickedClients] = useState('');
  const [PickedCoach,SetPickedCoach]=useState('');
  const [SendFormId, SetSendFormId] = useState('');


  const GetMyIntakeForms =async (PageNumber) => {

    SetPageNumber(PageNumber)

    const response = await axiosJWT.get('documents/intake-form?offset=' + PageNumber + '&limit=' + PerPage + '', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    if (response.data !== null) {
        if (response.data.list !== null) {
            SetIntakeFormList(response.data.list);
        }
        SetTotalRecord(response.data.total_count);
    }
  };

  const BackToQuestionnaire=()=>{
        navigate("/admin/questionnaire", { replace: true });
  }

  const SetSelectedQuestionnaire = (e) => {
    var PushSelectedValues = [];

    e.forEach((value, index, array) => {
        PushSelectedValues.push(value.value);
    });

    var commavalues = PushSelectedValues.toString();
    SetSelectedQuestions(commavalues);
}
const CreateIntakeForm=async()=>{

    SetIntakeFormNameErr(false)
    SetIntakeFormQuestionnaireErr(false)
   var Err=false

    if(IntakeFormName===null || IntakeFormName==='')
    {
        SetIntakeFormNameErr(true)
        SetIntakeFormNameErrMsg('Please enter intake form name')
        Err=true
    }
    if(SelectedQuestions===null || SelectedQuestions==='')
    {
        SetIntakeFormQuestionnaireErr(true)
        SetIntakeFormQuestionnaireErrMsg('Please select questionnaires')
        Err=true
    }
    if(Err===false)
    {
        if (poppupbuttonidintake === '') {
            const response = await axiosJWT.post('/documents/intake-form',
                {
                    intake_form_name: IntakeFormName,
                    intake_qn_id: SelectedQuestions
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                Setintakeformmsg('You have successfully created an intake form');
                setshowsaddintake(false);
                Setshowsaddintakesucess(true);
                GetMyIntakeForms(1);
            }
        }else{
            const response = await axiosJWT.put('/documents/intake-form',
                    {
                        intake_form_name: IntakeFormName,
                        intake_qn_id: SelectedQuestions,
                        id: poppupbuttonidintake
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setintakeformmsg('You have successfully Updated an intake form');
                    setshowsaddintake(false);
                    Setshowsaddintakesucess(true);
                    GetMyIntakeForms(1);
                }
        }

    }

}
const GetMyQuestionnaires=async()=>{

    Setquestionnaireselectoptionsdefault([]);
    var MyformsPUsh = [];

    const response = await axiosJWT.get('/form', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    if (response.data.data !== null) {

        response.data.data.forEach((value, index, array) => {
            var obj = {};
            obj["value"] = value.id;
            obj["label"] = value.form_name;
            MyformsPUsh.push(obj);
        });

        setquestionnaireselectoptions(MyformsPUsh);
    }

}
const AddIntakeForm=()=>{
    setshowsaddintake(true)
    SetIntakeFormNameErr(false)
    SetIntakeFormQuestionnaireErr(false)
    GetMyQuestionnaires()
    Setpoppuplabelintake('Add intake form')
    Setpoppupbuttonlabelintake('Add')
    SetIntakeFormName('')
    SetSelectedQuestions('')
    Setpoppupbuttonidintake('')
}
const triggereditpopup=async(e)=>{
    const EditId=e.target.getAttribute('data-id')
    Setpoppupbuttonidintake(EditId)
    Setpoppuplabelintake('Update intake form')
    Setpoppupbuttonlabelintake('Update')
    var MyformsPUsh = [];
        const response = await axiosJWT.get('/form', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.data !== null) {
            response.data.data.forEach((value, index, array) => {
                var obj = {};
                obj["value"] = value.id;
                obj["label"] = value.form_name;
                MyformsPUsh.push(obj);
            });

            setquestionnaireselectoptions(MyformsPUsh);
        }
    const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + EditId + '', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    SetIntakeFormName(DetailResponse.data.intake_form_name);

    var DefaultSelectedArray = [];

    var AlreadySelectedId = DetailResponse.data.intake_qn_id;
    SetSelectedQuestions(AlreadySelectedId);
    const myArray = AlreadySelectedId.split(",");

    myArray.forEach((value, index, array) => {
        var found = MyformsPUsh.filter(e => e.value === parseInt(value));

        if (found.length > 0) {
            var obj = {};
            obj["value"] = found[0].value;
            obj["label"] = found[0].label;
            DefaultSelectedArray.push(obj);
        }
    });

    Setquestionnaireselectoptionsdefault(DefaultSelectedArray);

    setshowsaddintake(true);


}
const triggerdeleteintakepopup=(e)=>{
    const DeleteId=e.target.getAttribute('data-id')
    SetIntakeDeleteFormid(DeleteId)
    Setshowsdeleteintake(true)


}
const previewIntakeForm = async (e) => {

    var ArrayFinal = [];
    var PreviewId = e.target.getAttribute('data-id');

    const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + PreviewId + '', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    var QuestionnaireId = DetailResponse.data.intake_qn_id;        
    const myArray = QuestionnaireId.split(",");

    myArray.forEach((value, index, array) => {
        const QuestionnaireResponse = axiosJWT.get('form/form-detail?id=' + value + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((QuestionnaireResponse) => {
            QuestionnaireResponse.data.forEach((value, index, array) => {
                ArrayFinal.push(value);
            });

            setTimeout(() => {
                SetPreviewFormData(ArrayFinal)
                Setshowspreviewintake(true)
            }, 100);
        });
    });
}
const SendIntakeForm = (e) => {
    var SendId = e.target.getAttribute('data-id');
    SetSendFormId(SendId);
    SetPickedClients('');
    SetPickedCoach('');
    Setshowssendintake(true);
    GetClientList();
    GetCoachList();
}
const GetClientList = async () => {

    const response = await axiosJWT.get('/allclient', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => {

        var ClientList = [];

        if (response.data !== null) {
            response.data.Users.forEach((value, index, array) => {
                var obj = {};
                obj["value"] = value.id;
                obj["label"] = value.first_name + ' ' + value.last_name;
                ClientList.push(obj);
            });

            SetClientuserlist(ClientList);
        }
    });
}
const GetCoachList = async () => {

    const response = await axiosJWT.get('/allnutrition', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => {

        var ClientList = [];

        if (response.data !== null) {
            response.data.Users.forEach((value, index, array) => {
                var obj = {};
                obj["value"] = value.id;
                obj["label"] = value.first_name + ' ' + value.last_name;
                ClientList.push(obj);
            });

            SetCoachuserlist(ClientList);
        }
    });
}
const SetSelectedClients = (e) => {

    var PushSelectedValues = [];

    e.forEach((value, index, array) => {
        PushSelectedValues.push(value.value);
    });

    var commavalues = PushSelectedValues.toString();
    SetPickedClients(commavalues);
}
const SetSelectedCoach = (e) => {

    var PushSelectedValues = [];

    e.forEach((value, index, array) => {
        PushSelectedValues.push(value.value);
    });

    var commavalues = PushSelectedValues.toString();
    SetPickedCoach(commavalues);
}
const Sendform = async () => {

    var PickedUsers='';
    var PickedClientlist=[];
    var PickedCoachlist=[];
    if(PickedClients!==null && PickedClients!=='')
    {
      
        PickedClientlist= PickedClients.split(',');
    }
    if(PickedCoach!==null && PickedCoach!=='')
    {
        PickedCoachlist=PickedCoach.split(',');
    }

    const Users = PickedClientlist.concat(PickedCoachlist);
    PickedUsers = Users.toString();



    if (PickedUsers !== null && PickedUsers !== '') {

        const response = await axiosJWT.post('/form/invite-form-user/intake',
            {
                form_id: SendFormId,
                invited_to: PickedUsers
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            Setintakeformmsg('Successfully invited users to submit the form');
            Setshowsaddintakesucess(true);
            handleClosessendintake();
        }
    }
    else {
        Setintakeformmsg('Please select users');
        Setshowsaddintakesucess(true);
    }
}
const DeleteIntake=async()=>{




    const response = await axiosJWT.delete('/documents/intake-form/' + IntakeDeleteFormid + '',
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

if (response) {
    handleClosesdeleteintake();
    GetMyIntakeForms(1);
}

}
const changePageNumberI=(pagenumber)=>{
    GetMyIntakeForms(pagenumber)
}
const ViewSubmittedFormsByUsers=()=>{
    navigate('/admin/view-submitted-forms',{replace:true})
}
  useEffect(() => {
    GetMyIntakeForms(1);
  }, []);

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      <section className="dashboard">
        <div className="coachquestionnaire_list container admin_intake_form">
          <div>
            <div className="row questionnaire_top_row">
              <div className="col">
                <span className="top_span_questionnaire_head">
                  Intake Forms
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
              <button className="questionnaire_top_button top_billing_coach_payment" onClick={BackToQuestionnaire}>{'< '}Back to questionnaire</button>
              <button className="questionnaire_top_button top_billing_coach_payment" onClick={AddIntakeForm}>Add Intake Form</button>
              <button className="questionnaire_top_button top_billing_coach_payment" onClick={ViewSubmittedFormsByUsers}>View submitted Forms</button>
              </div>
            </div>
          </div>
          <div className="intake_form_list">
          <Table className="table is-striped is-fullwidth">
              <Thead className="thead">
                <Tr>
                  <Th>Intake Name</Th>
                  <Th>Last edited</Th>
                  <Th>Action</Th>

                </Tr>
              </Thead>
              <Tbody className="tbody">
              {IntakeFormList.length > 0 && IntakeFormList.map((list, index) => (
             <Tr className='trow'>
             <Td>{list.intake_form_name}</Td>
             <Td >{format(parseISO(list.updatedAt), `PP`)}</Td>
             <Td ><i className="fa fa-edit edit_icon pointer_eff icon_tag_size" data-id={list.id} onClick={(e) => { triggereditpopup(e) }} ></i><i className="fa fa-trash pointer_eff icon_tag_size" data-id={list.id} onClick={(e) => { triggerdeleteintakepopup(e) }}></i><i className="fa fa-eye pointer_eff icon_tag_size eye_icon" data-id={list.id} aria-hidden="true" onClick={(e) => { previewIntakeForm(e) }}></i>
                 <i data-id={list.id} className='fa fa-send billing_edit_i pointer_eff send_qu_icon' onClick={(e) => { SendIntakeForm(e) }}></i>
             </Td>
         </Tr>
                     ))}
                </Tbody>
                </Table>
          </div>
          <div>
                                    {TotalRecord ?
                                        <Pagination
                                            activePage={PageNumber}
                                            itemsCountPerPage={PerPage}
                                            totalItemsCount={TotalRecord}
                                            onChange={changePageNumberI.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                        : null}
                                </div>
        </div>
      </section>


      <Modal show={showsaddintake} onHide={handleClosesaddintake} className="coach_document_delete confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{poppuplabelintake}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label className='commission_inner_popup_label required'>Intake form Name</label><br />
                                        <input type='text' className="add_category_inner_input add_intake_form_input" onChange={(e) => { SetIntakeFormName(e.target.value) }} defaultValue={IntakeFormName}  {...(IntakeFormNameErr===true ? {id: 'err_border'} : {})} />
                                        <div>
                                            {IntakeFormNameErr === true &&
                                                <span className="err service_err_field">  {IntakeFormNameErrMsg}</span>
                                            }
                                        </div>
                                        <label className='commission_inner_popup_label required'>Select Questionnaire</label><br />
                                        <Select options={questionnaireselectoptions} isMulti={true} onChange={(e) => { SetSelectedQuestionnaire(e) }} defaultValue={questionnaireselectoptionsdefault} {...(IntakeFormQuestionnaireErr===true ? {id: 'err_border'} : {})} />
                                        <div>
                                            {IntakeFormQuestionnaireErr === true &&
                                                <span className="err service_err_field">  {IntakeFormQuestionnaireErrMsg}</span>
                                            }
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesaddintake}>
                                            Cancel
                                        </Button>
                                        <Button className="modal_action_button float_right" variant="primary" onClick={CreateIntakeForm} data-update_id={poppupbuttonidintake}>
                                            {poppupbuttonlabelintake}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showsaddintakesucess} onHide={handleClosesaddintakesuccess} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Intake Form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {intakeformmsg}
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosesaddintakesuccess}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showsdeleteintake} onHide={handleClosesdeleteintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Intake form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure want to delete this Intake Form?<br /><strong>{'Note : If you delete this form you may also lose the submitted forms by client with this intake form'}</strong></Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosesdeleteintake}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" data-id={IntakeDeleteFormid} onClick={(e) => { DeleteIntake(e) }}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showspreviewintake} onHide={handleClosespreviewintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Preview form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <ReactFormGenerator data={PreviewFormData}
                                            hide_actions={true}
                                            read_only={true}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosespreviewintake}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showssendintake} onHide={handleClosessendintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>List of clients</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <label className='commission_inner_popup_label'>Select Coach</label><br />
                                        <Select options={Coachuserlist} isMulti={true} onChange={(e) => { SetSelectedCoach(e) }} />
                                        <label className='commission_inner_popup_label'>Select Clients</label><br />
                                        <Select options={Clientuserlist} isMulti={true} onChange={(e) => { SetSelectedClients(e) }} />
                                     
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosessendintake}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" onClick={Sendform}>
                                            Send
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

    </div>
  );
};
export default IntakeFormsList;

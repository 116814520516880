import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const RestSauna = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [Duration,SetDuration]=useState('')
    const [DurationErr,SetDurationErr]=useState(false)

   const [BeforeWeight,SetBeforeWeight]=useState('')
   const [BeforeDegree,SetBeforeDegree]=useState('')
   const [AfterWeight,SetAfterWeight]=useState('')
   const [AfterDegree,SetAfterDegree]=useState('')
   const [WeightChanges,SetWeightChanges]=useState('')
   const [DegreeChanges,SetDegreeChanges]=useState('')

   const SaveRestSauna=async(e)=>{
    const action=e.target.getAttribute('data-action')
    if(action==='cancel')
    {
        props.SetShowRestSaunaField(false)
        props.SetRestOption(true)
        return false
    }
    SetTimeErr(false)
    SetDateErr(false)
    SetDurationErr(false)
    var err=false;
    if (AddDataDate === null) {
        err = true
        SetDateErr(true)
    }
    if (TImePicker === null) {
        err = true
        SetTimeErr(true)
    }
    if(Duration==='')
        {
            err = true
            SetDurationErr(true) 
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
            const response = await axiosJWT.post('add-data/add-rest',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Rest Sauna',
                'duration':Duration,
                'before_temp':BeforeDegree,
                'before_weight':BeforeWeight,
                'after_temp':AfterDegree,
                'after_weight':AfterWeight,
                'temp_change':DegreeChanges,
                'weight_change':WeightChanges,
                'client_id':IsClientid,
                 'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowRestSaunaField(false)
                    props.SetRestOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }

        }
   }

   const CalculateWeightChange=(e)=>{

    const Target=e.target.getAttribute('data-target')
    const value=e.target.value;
 
    if(Target==='before_weight')
    {
        CalculateWeightFinal(value,AfterWeight)
        SetBeforeWeight(value)
    }
    if(Target==='after_weight')
    {
        CalculateWeightFinal(BeforeWeight,value)
        SetAfterWeight(value)
    }
    
   }

   const CalculateWeightFinal=(beforeweight,afterweight)=>{

    var WeightChange=0

    if(beforeweight!=='' && afterweight!=='')
    {

        if(parseFloat(beforeweight)>parseFloat(afterweight))
        {
            WeightChange=parseFloat(afterweight)-parseFloat(beforeweight)
        }
        else if(parseFloat(afterweight)>parseFloat(beforeweight)){
            WeightChange=parseFloat(afterweight)-parseFloat(beforeweight)
        }

        document.querySelector('.weight_change_input').value = WeightChange;
        SetWeightChanges(WeightChange)

    }
    else{
        document.querySelector('.weight_change_input').value = WeightChange;
        SetWeightChanges(WeightChange)

    }

   }


   const CalculateTempChange=(e)=>{
    const Target=e.target.getAttribute('data-target')
    const value=e.target.value;
    if(Target==='before_temp')
    {
        SetBeforeDegree(value)
        CalculateTempFinal(value,AfterDegree)
    }
    if(Target==='after_temp')
    {
        SetAfterDegree(value)
        CalculateTempFinal(BeforeDegree,value)
    }
    
   }

   const CalculateTempFinal=(before_degree,after_degree)=>{

    var TempChange=0

    if(before_degree!=='' && after_degree!=='')
    {

        if(parseFloat(before_degree)>parseFloat(after_degree))
        {
            TempChange=parseFloat(after_degree)-parseFloat(before_degree)
        }
        else if(parseFloat(after_degree)>parseFloat(before_degree)){
            TempChange=parseFloat(after_degree)-parseFloat(before_degree)
        }

        document.querySelector('.temp_change_input').value = TempChange;
        SetDegreeChanges(TempChange)

    }
    else{
        document.querySelector('.temp_change_input').value = TempChange;
        SetDegreeChanges(TempChange)

    }
    

   }




   const ChangeDatePickerDate = (pass) => {
    var passDate = ''
    var yyyy = pass.getFullYear().toString();
    var mm = (pass.getMonth() + 1).toString();
    var dd = pass.getDate().toString();
    passDate = yyyy + '-' + mm + '-' + dd
    return passDate
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var strTime = hours + ':' + minutes;
    return strTime;
}


    return (
        <div className="physical_activity">
            <h6 className="heading_popup">{'Rest & Recovery > Rest sauna'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                    />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Duration (Mins)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetDuration(e.target.value) }}  {...(DurationErr === true ? { id: 'err_border' } : {})} defaultValue={Duration} />
                </div>
            </div>

            <div className="field_top_row">

<div className="two_col_field">
    <div className="col1">
        <div className="">


            <div className="field">
            <div className="label">Before Weight(lbs/kgs)</div>
                <input type={'number'} className="raw_input" data-target="before_weight" onChange={(e) => { CalculateWeightChange(e) }}  defaultValue={BeforeWeight} />
            </div>
        </div>
    </div>
    <div className="col2">

        <div className="field_top_row">
         <div className="field">
         <div className="label">Before Temp.(deg)</div>
                <input type={'number'} className="raw_input" data-target="before_temp" onChange={(e) => { CalculateTempChange(e) }}  defaultValue={BeforeDegree} />
            </div>
        </div>
    </div>
</div>
</div>

<div className="field_top_row">

<div className="two_col_field">
    <div className="col1">
        <div className="">


            <div className="field">
            <div className="label">After Weight(lbs/kgs)</div>
                <input type={'number'} className="raw_input" data-target="after_weight" onChange={(e) => { CalculateWeightChange(e) }}  defaultValue={AfterWeight} />
            </div>
        </div>
    </div>
    <div className="col2">

        <div className="field_top_row">
         <div className="field">
         <div className="label">After Temp.(deg)</div>
                <input type={'number'} className="raw_input" data-target="after_temp" onChange={(e) => { CalculateTempChange(e) }}  defaultValue={AfterDegree} />
            </div>
        </div>
    </div>
</div>
</div>

<div className="field_top_row">

<div className="two_col_field">
    <div className="col1">
        <div className="">


            <div className="field">
            <div className="label">Weig. Changes(lbs/kgs)</div>
                <input type={'number'} className="raw_input weight_change_input" onChange={(e) => { SetWeightChanges(e.target.value) }}  defaultValue={WeightChanges} disabled={true} />
            </div>
        </div>
    </div>
    <div className="col2">

        <div className="field_top_row">
         <div className="field">
         <div className="label">Temp Changes(deg)</div>
                <input type={'number'} className="raw_input temp_change_input" onChange={(e) => { SetDegreeChanges(e.target.value) }}  defaultValue={DegreeChanges} disabled={true} />
            </div>
        </div>
    </div>
</div>
</div>

<div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveRestSauna(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveRestSauna(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveRestSauna(e) }} >
                    {'Save'}
                </Button>

           </div>

           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )

}
export default RestSauna
import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import ImageUploading from 'react-images-uploading';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "react-js-pagination";


const PhotoSubmission=(props)=>{

   
    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
    const [TotalRecord, SetTotalRecord] = useState(0);
    const [CurrentPage, SetCurrentPage] = useState();
    const [PerPage, SetPerPage] = useState(10);
    const [loader,Setloader]=useState(false)



    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
  
    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      console.log(imageList, addUpdateIndex);
      setImages(imageList);
    };

    const SubmitImagesServer=async()=>{
        Setloader(true)
    const ImagesUrl=images.map(obj => obj['data_url']);
    const ImagesFile=images.map(obj => obj['file']);
  
  
    var formData = new FormData();
    formData.append('filelength',ImagesUrl.length)
    formData.append('masterId',props.VisitDetailsData.masterId)
    formData.append('visitId',props.VisitDetailsData.visitId)
    ImagesUrl.forEach((element,index) => {
        formData.append('fileurl'+index,element)
    });

    ImagesFile.forEach((element,index) => {
        formData.append('file'+index,element)
    });

    try{
        const UploadResponse= await axios.post("/beluga/upload-visit-photo-submission", formData, {
    
            headers: {
                Authorization: `Bearer ${token}`,
              },
          },
          );
          Setloader(false)
          if(UploadResponse.status===200)
          {
            GetClientVisitsPhotoLog(1)
         
            setImages([])
          }

    }catch(err)
    {
        Setloader(false)
        console.log(err)
    }
   
      

    }

    const [VisitPhotoLog,SetVisitPhotoLog]=useState([])

    function timetoUtcClientAppointment(passdate) {
        var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");
    
        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);
    
        var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
    
        return stardates;
      }

      const changePageNumber = (pagenumber) => {
        GetClientVisitsPhotoLog(pagenumber);
      };

      const GetClientVisitsPhotoLog = async (pagenumber) => {
        SetCurrentPage(pagenumber);
        Setloader(true)

        var clientid='';
        if(props.clientid && props.clientid!='')
        {
          clientid=props.clientid;
        }
        
        try{
    
            const GetResponse = await axiosJWT.get(
                "beluga/get-client-visits-log-photo?offset=" +
                  pagenumber +
                  "&limit=" +
                  PerPage +
                  "&client_id=" +
                  clientid +
                  "&visit_id=" +
                  props.VisitDetailsData.visitId +
                  "",
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              Setloader(false)
              console.log('phottoosss',GetResponse.data)
          
              if (GetResponse.data !== null) {
                SetTotalRecord(GetResponse.data.total_count);
                SetVisitPhotoLog(GetResponse.data.GetResponse);
                
              }
    
        }catch(err)
        {
            Setloader(false)
            console.log(err)
        }
    
       
      };

      useEffect(()=>{
   
        GetClientVisitsPhotoLog(1)
      },[])

      const ShowError=()=>{

        props.toast.error('You cannot upload photos to the cancelled visit', {
          toastId: 'error1',
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        
          });
        
      }
    

    return (
        <>
       {loader === true && <div id="semiTransparenDivLoader"></div>}
        <div className="photo_submission_wrap">

          {
            (props.VisitDetailsData.visit_status!=='cancelled') ?    <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            maxFileSize={3000000}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
    
              {
                (!props.clientid) ?     <button
                className="view_button_beluga_upload"
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click here to upload
                </button> : null
              }
            
                &nbsp;
                {
                  images.length>0 ?     <button className="view_button_beluga_upload_cancel" onClick={onImageRemoveAll}>Remove all images</button> : null
                }
                   &nbsp;
                {
                  images.length>0 ?     <button className="view_button_beluga_upload_submit" onClick={SubmitImagesServer}>Sumbit all images</button> : null
                }
            <div className="over_all_image_wrapper">
            {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image['data_url']} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button  onClick={() => onImageUpdate(index)} className="view_button_beluga_upload">Update</button>
                      <button className="view_button_beluga_upload_cancel" onClick={() => onImageRemove(index)}>Remove</button>
                    </div>
                  </div>
                ))}
            </div>
              
              
    
                {
                  errors!==null  ?    <div>
                  {errors.maxNumber && <span style={{color:'red'}}>*Number of selected images exceed maxNumber</span>}
                  {errors.acceptType && <span style={{color:'red'}}>*Only images allowed</span>}
                  {errors.maxFileSize && <span style={{color:'red'}}>*Selected file size exceeds max file size{'<3mb'}</span>}
                  {errors.resolution && <span style={{color:'red'}}>*Selected file is not match your desired resolution</span>}
                </div> :null
                }
               
              </div>
            )}
            
          </ImageUploading> : 

            (!props.clientid) ?<button
            className="view_button_beluga_upload" 
            onClick={ShowError}
            
              >
              Click here to upload
            </button>  : null
          
          }
     
      <div className="row beluga_visit_container" style={{ display: "block" }}>
              <Table className="table is-striped is-fullwidth">
                <Thead className="thead ">
                  <Tr className="trow">
                  
                    <Th className="row_name">Photos Submitted</Th>
                    <Th className="row_name">Submitted Date</Th>
                  
                  </Tr>
                </Thead>

                {VisitPhotoLog.length > 0 && (
                  <Tbody className="tbody beluga_visit_tbody_img">
                    {VisitPhotoLog.length > 0 &&
                      VisitPhotoLog.map((visit, index) => (
                        <Tr className="trow" key={visit.id}>

                          <Td>
                            <img src={visit.photo_url}/>
                          </Td>
                         
                         
                          <Td>
                            <span className="">
                              {format(
                                parseISO(
                                  timetoUtcClientAppointment(
                                    visit.submitted_date
                                  )
                                ),
                                `MMM dd, yyyy  hh:mm a`
                              )}
                            </span>
                          </Td>
                          
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
              <div>
                {TotalRecord ? (
                  <Pagination
                    activePage={CurrentPage}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecord}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers color_grey"
                  />
                ) : null}
              </div>
            </div>
        </div>
        </>
    )
}
export default PhotoSubmission
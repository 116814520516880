/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns'
import { CSVLink } from "react-csv";
import female_icon from '../../images/female.png'
import male_icon from '../../images/male.png'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Payout = () => {
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");

    const [payoutsList, setpayoutsList] = useState([]);
    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [exportPayout, setexportPayout] = useState('');
    const [exportFilename, setexportFilename] = useState('');

    useEffect(() => {
        document.title = 'Admin-Payouts';
        getPayouts();
    }, [])

    const axiosJWT = axios.create();

    const getPayouts = async (pagenumber) => {


        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/payments/payout?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

    
        setTotalCount(response.data.totalcount);

        setpayoutsList(response.data.payout);

        var exports_list = response.data.payout;
        var exports_data = [];

        exports_list.forEach(element => {
            exports_data.push({ "Expert": element.nutritionist.first_name + " " + element.nutritionist.last_name, "Expert Email": element.nutritionist.email, "Transaction Date": format(new Date(element.createdAt), `PP`), "Status": element.status, "Amount": element.amount, "Total Amount": element.total_amount, "Payment Type": element.payment_type, "Gateway": element.gateway });
        });
        setexportPayout(exports_data);
        setexportFilename("Payouts_" + format(new Date(), 'yyyy_MM_dd_t'));
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getPayouts(pagenumber);
    }

    const searchPayout = async (event) => {
        var starting_limit = 0;
        var ending_limit = countPerPage

        const response = await axiosJWT.get("/payments/payout?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);
        setTotalCount(response.data.totalcount);
        setpayoutsList(response.data.payout);

        var exports_list = response.data.payout;
        var exports_data = [];

        exports_list.forEach(element => {
            exports_data.push({ "Expert": element.nutritionist.first_name + " " + element.nutritionist.last_name, "Expert Email": element.nutritionist.email, "Transaction Date": element.createdAt, "Status": element.status, "Amount": element.amount, "Total Amount": element.total_amount, "Payment Type": element.payment_type, "Gateway": element.gateway });
        });
        setexportPayout(exports_data);

        setexportFilename("Payouts_" + format(new Date(), 'yyyy_MM_dd_t'));
    }





    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="adminpayout container">
                    <div className='menubox'>
                        <div className='leftmenu'>
                            <h5>Payout</h5>
                        </div>
                        <div className='rightmenu'>
                            <CSVLink data={exportPayout} filename={exportFilename}><button data-modal="export_payout">Export As CSV</button></CSVLink>

                            <input type="text" placeholder="&#xF002; Search" onChange={event => searchPayout(event.target.value)} />
                        </div>
                    </div>

                    <Table className="table is-striped is-fullwidth">
                        <Thead className="thead">
                            <Tr>
                                <Th>Expert</Th>
                                <Th>Expert Email</Th>
                                <Th>Transaction Date</Th>
                                <Th>Status</Th>
                                <Th>Amount</Th>
                                <Th>Total Amount</Th>
                                <Th>Payment Type</Th>
                                <Th>Gateway</Th>
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">
                            {payoutsList.map((payout, index) => (
                                <Tr className='trow' key={payout.id}>
                                    <Td><img className='table_profile_img' src={payout.nutritionist.user_profile_photo ? payout.nutritionist.user_profile_photo : (payout.gender === 'MALE' ? male_icon : female_icon)} />{payout.nutritionist.first_name} {payout.nutritionist.last_name}</Td>
                                    <Td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{payout.nutritionist.email}</Td>
                                    <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(payout.createdAt), `PP`)}</Td>
                                    <Td><p className={payout.status === "pending" ? "orangebox" : null}>{payout.status}</p></Td>
                                    <Td>${payout.amount}</Td>
                                    <Td>${payout.total_amount}</Td>
                                    <Td>{payout.payment_type}</Td>
                                    <Td><div className='logo'><img src={payout.gateway ? process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + payout.gateway + ".png" : null} alt={payout.gateway} /></div></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    {totalCount ?
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={countPerPage}
                        totalItemsCount={totalCount}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                    : null }

                    <div style={{ clear: 'both' }}></div>
                </div>
            </section>



        </div>
    )
}

export default Payout

import React, { useEffect, useState } from "react";

const GastricByPass = (props) => {
  const [GastricPass, SetGastricPass] = useState("");

  const handleOptionChange = (e) => {
    SetGastricPass(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.GastricPassPC) {
        SetGastricPass(props.GastricPassPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetGastricPassPC(GastricPass);

      var Err = 0;
      if (GastricPass === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GastricPass]);

  useEffect(()=>{

    if(props.AnythingApplyPCT.includes('Gallbladder Disease'))
    {
        props.SetPreviousMenu('gallbladdercs')
    }
    else if(props.AnythingApplyPCT.includes('Hypertrigyceridemia (elevated triglycerides)'))
    {
        props.SetPreviousMenu('triglycerides')
    }
    else if(props.AnythingApplyPCT.includes('None of the above'))
    {
        props.SetPreviousMenu('theseapply')
    }
    else{
        props.SetPreviousMenu('tellusmoremc')
    }

    props.SetNextMenu('currentmedication')
   
 
  
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div>
          <label>
         <h6>{props.GastricPassPCLabel}</h6>
          </label>
        </div>

      <div className="input_row">
       
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={GastricPass === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={GastricPass === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

    
    </div>
  );
};
export default GastricByPass;

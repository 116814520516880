import React, { useEffect, useState } from "react";

const GlpExperienceCM = (props) => {
  const [GlpExperience, SetGlpExperience] = useState("");

  const handleOptionChange = (e) => {
    SetGlpExperience(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.GlpExperiencePC) {
        SetGlpExperience(props.GlpExperiencePC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetGlpExperiencePC(GlpExperience);

      var Err = 0;
      if (GlpExperience === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GlpExperience]);

  useEffect(()=>{


    if(props.SexPC==='MALE')
    {
        props.SetPreviousMenu('currentmedication')
    }
    else if (props.SexPC==='FEMALE')
    {
        props.SetPreviousMenu('breastfeeding')
    }
  

    props.SetNextMenu('glpexperiencefree')
   
 
  
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div>
          <label>
         <h6>{props.GlpExperiencePCLabel}</h6>
          </label>
          <span style={{color:'red'}}>*</span>
        </div>

      <div className="input_row">
       
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="It is working well"
            checked={GlpExperience === "It is working well"}
            onChange={handleOptionChange}
          />
          <label>It is working well</label>

          <input
            type="radio"
            value="It is not working well"
            checked={GlpExperience === "It is not working well"}
            onChange={handleOptionChange}
          />
          <label>It is not working well</label>
        </div>
      </div>

    
    </div>
  );
};
export default GlpExperienceCM;

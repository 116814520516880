import React, { useEffect, useState } from "react";
import DexaImg from '../../../images/dexa.jpg'
import BioImg from '../../../images/biomarkers.jpg'
import WithingsImg from '../../../images/withings.jpg'
import axios from "axios";

const SubscriptionAddons =(props)=>{

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [WithingEnabled,SetWithingEnabled]=useState(false)
    const [DexaEnabled,SetDexaEnabled]=useState(false)
    const [BioEnabled,SetBioEnabled]=useState(false)
    const [ClientId,SetClientId]=useState(props.data)

    const GetStatus =async()=>{

        if(ClientId!==undefined && ClientId!==null && ClientId!=='')
            {

                const response = await axiosJWT.get('profile-details/get-oto-kit?client_id='+ClientId+'', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                if(response.data)
                    {
                        if(response.data.withings_enabled)
                            {
                                SetWithingEnabled(response.data.withings_enabled)
                            }
                            if(response.data.dexa_scans_enabled)
                                {
                                    SetDexaEnabled(response.data.dexa_scans_enabled)
                                }
                                if(response.data.bio_markers_enabled)
                                    {
                                        SetBioEnabled(response.data.bio_markers_enabled)
                                    }
                    }

                console.log('responseSubscriptionAddons',response.data)
            }

        }

    useEffect(()=>{
        GetStatus()
    },[ClientId])

    


    return (

        <>
        <div className="subscription_add_ons_container">
            <div className="add_ons_row"> 
                <div className="img_col"><img src={WithingsImg}/></div>
                <div className="name_col">Withings Scale</div>
                <div className="tick_col"><i class={WithingEnabled===true ? "fa fa-check" : "fa fa-close" }></i></div>
            </div>
            <div className="add_ons_row"> 
                <div className="img_col"><img src={DexaImg}/></div>
                <div className="name_col">Dexa Scan</div>
                <div className="tick_col"><i class={DexaEnabled===true ? "fa fa-check" : "fa fa-close" }></i></div>
            </div>
            <div className="add_ons_row"> 
                <div className="img_col"><img src={BioImg}/></div>
                <div className="name_col">Bio Markers lab</div>
                <div className="tick_col"><i class={BioEnabled===true ? "fa fa-check" : "fa fa-close" }></i></div>
            </div>
        </div>
        </>
    )
}
export default SubscriptionAddons
import React, { useEffect, useState } from "react";

const GLpExperienceFreeCM = (props) => {
 
  const [GLpExperienceFreeDesc, SetGLpExperienceFreeDesc] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.GLpExperienceFreeDescPC) {
        SetGLpExperienceFreeDesc(props.GLpExperienceFreeDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetGLpExperienceFreeDescPC(GLpExperienceFreeDesc);
   

      var Err = 0;
      if (GLpExperienceFreeDesc === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GLpExperienceFreeDesc]);

  useEffect(()=>{
    props.SetPreviousMenu('glpexperience')
    props.SetNextMenu('sideeffect')
  
   
 
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row">{props.GLpExperienceFreeDescPCLabel}       <span style={{color:'red'}}>*</span></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={GLpExperienceFreeDesc}
              value={GLpExperienceFreeDesc}
              onChange={(e) => {
                SetGLpExperienceFreeDesc(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default GLpExperienceFreeCM;

import React, { useEffect, useState } from "react";

import moment from 'moment'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const PremiumCheckComponent = () => {

    const [Subscribed, SetSubscribed] = useState(false)
    const subscriptionEdate = localStorage.getItem("subscriptionEdate");
    var utcMoment = moment.utc();
    utcMoment = moment(utcMoment).format("YYYY-MM-DD HH:mm:ss");

    useEffect(() => {

        if (subscriptionEdate !== 'null') {

            if (Date.parse(subscriptionEdate) < Date.parse(utcMoment)) {
                SetSubscribed(true)
            }
        }
        else {
            SetSubscribed(true)
        }

    })



    return (

        <div>
            {Subscribed === true &&

                <div id="overlay">

                    <Modal show={true} className="confirm_delete premim_plan_upgrade">
                        <Modal.Header>
                            <Modal.Title>{'Premium Upgrade'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                            You can access this features by upgrade your account to premium plan.

                            


                        </Modal.Body>

                        <Modal.Footer className="display_block">

                            <a className="btn btn-primary a_href_premium" href="/client/subscriptions">  Yes,Upgrade my plan</a>
                        </Modal.Footer>


                    </Modal>

                </div>
            }
        </div>
    )


}

export default PremiumCheckComponent

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { format } from 'date-fns'
import Pagination from 'react-js-pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const Favorites = (props) => {

    const [PercentageArray, SetPercentageArray] = useState([])
    const [RecipeProtein, SetRecipeProtein] = useState(0)
    const [RecipeFat, SetRecipeFat] = useState(0)
    const [RecipeCarbs, SetRecipeCarbs] = useState(0);
    const [RecipeCalories, SetRecipeCalories] = useState(0)
    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {

                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    // console.log(percentage)
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };

    const userId = props.userId;

    const token = localStorage.getItem("token");
    const [recipeslist, setrecipeslist] = useState([]);
    var [recipetab, setRecipeTabMenu] = useState('ingredients');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [recipeData, setrecipeData] = useState('');

    useEffect(() => {
       
        load_favorites();
    }, []);

    const axiosJWT = axios.create();


    const load_favorites = async (pagenumber) => {
      
        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = starting_limit + countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = starting_limit + countPerPage
        }

        var response = await axiosJWT.get("/week-diet-plan/favorites", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response) {

            if(response.data!==null)
            {

                if(response.data.favorites!==null)
        {
            var recipe_list = [];
            var favorites_key = (response.data.favorites).split(",");

            //console.log(favorites_key.length)
            setTotalCount(favorites_key.length)

            var favorites_key_sub = favorites_key.slice(starting_limit, ending_limit);

            try{

                await favorites_key_sub.forEach(async(element, index, array) => {
                    await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + element + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                        recipe_list.push(res.data);
    
                        if (index === array.length - 1)
                            setTimeout(function () { setrecipeslist(recipe_list) }, 100);
                    });
    
                });

            }catch(err)
            {
             console.log(err)
            }

           

        }

            }
       
        
          

            /* await favorites_key.forEach((element,index,array) => {
                 axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST+"/recipe/steps/"+element+"?api_key="+process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => { 
                        recipe_list.push(res.data)
                    }).then(()=>{
                        if(index===array.length-1)
                        {
                            setTimeout(function() {
                                setrecipeslist(recipe_list)
                            }, 100);
                        }
                    });                     
            });*/

        }

    }

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        load_favorites(pagenumber);
    }

    const showRecipe = async (e) => {
        const recipeId = e.target.getAttribute("data-item");

        try{

           await  axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {

                setShow(true);
                setrecipeData(res.data);
                SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)])
                SetRecipeProtein(res.data.NutritionInfo.Protein)
                SetRecipeFat(res.data.NutritionInfo.TotalFat)
                SetRecipeCarbs(res.data.NutritionInfo.TotalCarbs)
                SetRecipeCalories(res.data.NutritionInfo.TotalCalories)
                //console.log(res.data);
            });

        }catch(err)
        {
            console.log(err)
        }
       
    }

    const removeFavorites = async (e) => {

        var recipe_id = e.target.getAttribute("data-item");


        e.target.classList.remove('fav');

        var response = await axiosJWT.put("/week-diet-plan/favorites",
            {
                recipeId: recipe_id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200)
            load_favorites();

    }

    return (
        <div className='favorites_tab'>
            <div className='leftmenu'>
                <div className='title'>
                    <h4>Favorite Recipes</h4>
                </div>
            </div>
            <div className='rightmenu'>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={countPerPage}
                    totalItemsCount={totalCount}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers grayfont"
                />
            </div>
            <div style={{ "clear": "both" }} />
            <div className='recipes'>
                {recipeslist ?
                    <div>
                        {recipeslist.map((recipe, index) => (
                            <div className='recipe_box' key={index} >
                                <i className='fa fa-ellipsis-v viewdata' data-item={recipe.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                <i className='fa fa-heart viewheart fav' data-item={recipe.RecipeID} onClick={(e) => removeFavorites(e)}></i>
                                <div className='img'><img src={recipe.PhotoUrl} data-item={recipe.RecipeID} /></div>

                                <div className='title'>{recipe.Title}</div>
                            </div>
                        ))}
                    </div>
                    :
                    null
                }
            </div>

            <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.Title}</div>
                            <div className='img'><img src={recipeData.PhotoUrl} /></div>
                            <div className='nutrition_data'>
                                <p className='pie_chart_kcal_data'>Calories: {RecipeCalories.toFixed(2)} Kcal</p>
                                <Pie data={data} options={options} />

                                <hr />
                                <div className="pie_chart_bottom_div">
                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeCarbs.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeFat.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeProtein.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    {/* <span>
<span className="dotcalories"></span>
<span className="pie_chart_bottom_div_label">Calories</span>
<span className="pie_chart_bottom_div_data"><span>{RecipeCalories.toFixed(2)}</span><span>G</span></span>
</span> */}
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <ul>
                                        {(recipeData.Ingredients).map((ing, index) => (
                                            <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                        ))}
                                    </ul>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                    <ol>
                                        {(recipeData.Steps).map((ing, index) => (
                                            <li key={index}>{ing.Text}</li>
                                        ))}
                                    </ol>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button' onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default Favorites
import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const EventsLoad=()=>{

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    useLayoutEffect(()=>{

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let Code = params.get('code');
        let Error=params.get('error');
        if(Code!==null && Code!=='')
        {
             StoreAccessToken(Code)
        }
        if(Error!==null && Error!=='')
        {
            window.location.href=process.env.REACT_APP_FRONTEND_URL+'/profile?google_calendar=failed&msg=access_denied'
        }

       

    },[])

    const StoreAccessToken=async(codetoken)=>{

        try{

            const response = await axiosJWT.post("/google/get-token",
            {
                google_code: codetoken,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if(response.status===200)
            {
              window.location.href=response.data.link+'&msg='+response.data.msg+''
            }

        }catch(err)
        {
            window.location.href=err.response.data.link+'&msg='+err.response.data.msg+''
        }

    }

    return (<div>
        <div id="semiTransparenDivLoader"></div>
    </div>)
}
export default EventsLoad
import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import PhotoSubmission from "./PhotoSubmission";
import ClientChatBeluga from "./ClientChatBeluga";
import AdminChatBeluga from "../../AdminComponents/AdminClientDashboard/BelugaCustomerService";
import DoctorPrescription from "./DoctorPrescription";
import ClientBelugaForm from "./ClientBelugaForm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const VisitDetails = (props) => {
    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [visitId,SetvisitId]=useState('')
    const [CurrentTab,SetCurrentTab]=useState('visit_details')
    const [VisitDetailsData,SetVisitDetailsData]=useState([])

    const role = localStorage.getItem("role");
    const [loader,Setloader]=useState(false)
  

  const changeTab = async (e) => {
    const tab_for = e.target.getAttribute("data-item");
    SetCurrentTab(tab_for)
    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove("active"));

    e.target.setAttribute("class", "active");
  };

  const GetVisitDetails=async(id)=>{
   Setloader(true)
    SetvisitId('')
    SetVisitDetailsData([])
    try{

        const GetResponse = await axiosJWT.get(
            "beluga/get-client-visits-log-id?id=" +
            id+
              "",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          Setloader(false)

          if(GetResponse.data)
          {
            SetvisitId(GetResponse.data.GetResponse.visitId)
            SetVisitDetailsData(GetResponse.data.GetResponse)
          }
    


    }catch(err)
    {
      Setloader(false)
        console.log(err)
    }


  }

  useEffect(()=>{


   if(props.DetailId)
   {
    GetVisitDetails(props.DetailId)
   }
   window.scrollTo(0, 0)
  },[props])


  return (
    <>
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
        {loader === true && <div id="semiTransparenDivLoader"></div>}
      <div className="row beluga_visit_container">
        <p>Visit Id - #{visitId}</p>
        <div className="coach_plans">
          <div className="menubox">
            <div className="tabmenu">
              <a className="active" onClick={changeTab} data-item="visit_details">
                Visit Details
              </a>
              <a className="" data-item="patient_photo_submission" onClick={changeTab}>
                Patient Photo submission
              </a>
              <a className="" data-item="prescription_written" onClick={changeTab}>
                Doctor Prescriptions
              </a>
              {
                role==='Client' ?  <a className="" data-item="chat_with_doctors" onClick={changeTab}>
                Chat With Doctors
              </a> : null
              }

              {
                role==='Admin' ? <a className="" data-item="chat_with_customer" onClick={changeTab}>
                   Connect with Beluga Admin
              </a> : null
              }
             
           
            </div>
          </div>
        </div>

        {CurrentTab==='visit_details' ? <ClientBelugaForm VisitDetailsData={VisitDetailsData} DetailId={props.DetailId} clientid={props.clientid} /> : null}

           {CurrentTab==='patient_photo_submission' ? <PhotoSubmission VisitDetailsData={VisitDetailsData} DetailId={props.DetailId} clientid={props.clientid} toast={toast} /> : null}

           
           {CurrentTab==='chat_with_doctors' ? <ClientChatBeluga VisitDetailsData={VisitDetailsData} DetailId={props.DetailId} toast={toast}  /> : null}

           {
            CurrentTab==='chat_with_customer' ? <AdminChatBeluga VisitDetailsData={VisitDetailsData} DetailId={props.DetailId} /> : null 
           }

           {
            CurrentTab==='prescription_written' ? <DoctorPrescription VisitDetailsData={VisitDetailsData} DetailId={props.DetailId} /> : null 
           }


      </div>



    </>
  );
};
export default VisitDetails;

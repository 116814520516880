import React from "react";
import { NavLink } from 'react-router-dom';
import mainLogo from '../../images/fitpal_logo.png';

const ManagerHamMenu = () => {

  const ShowMobileMenu = () => {

    document.getElementsByClassName("mobile_menu")[0].style.opacity = "1";
    document.getElementsByClassName("mobile_menu")[0].style.display = "inherit";
    document.getElementsByClassName("ham-menu")[0].style.display = "none";
  }
  const CloseMenu = () => {

    document.getElementsByClassName("mobile_menu")[0].style.display = "none";
    document.getElementsByClassName("ham-menu")[0].style.display = "flex";
  }

  return (
    <div className="container-mobile-menu" >      
      <ul className="menu-items mobile_menu">
        <div className="mobile_menu_logo">
          <img id='clickimg ' className="reponsive_logo" src={mainLogo} alt="mainLogo" />
        </div>
        <i className="fa fa-times close_mobile_menu" aria-hidden="true" onClick={CloseMenu}></i>
        <li><NavLink to={"/manager/dashboard"} activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
        <li><NavLink to={"/manager/manage-appointment"} activeclassname="active"><i className="fa fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage appointment</span></NavLink></li>
        <li><NavLink to={"/manager/manage-users"} activeclassname="active"><i className="fa fa-users sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage Users</span></NavLink></li>
        <li><NavLink to={"/manager/payout"} activeclassname="active"><i className="fa fa-usd sidenav_icon" aria-hidden="true"></i><span className="side-navli">Payout</span></NavLink></li>
        <li><NavLink to={"/manager/contact-enquiry"} activeclassname="active"><i className="fa fa-address-book sidenav_icon" aria-hidden="true"></i><span className="side-navli">Contact Enquiries</span></NavLink></li>
        <li><NavLink to='/manager/analytics' activeclassname="active"><i className="fa fa-bar-chart sidenav_icon" aria-hidden="true"></i><span className="side-navli">Analytics</span></NavLink></li>
      </ul>
      <div className="ham-menu" onClick={ShowMobileMenu}>
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
    </div>
  )
}

export default ManagerHamMenu
import { React, useDebugValue, useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagerDashboard = () => {

  const axiosJWT = axios.create();

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
  const pos2inner = useRef(null);
  const bar_name = useRef(null);
  const token = localStorage.getItem("token");
  useLayoutEffect(() => {
    pos2inner.current.style.setProperty("padding-bottom", "25px", "important");

  }, []);
  useLayoutEffect(() => {
    bar_name.current.style.setProperty("font-size", "25px", "important");
  }, []);

  const [ListOfUsers, SetListOfUsers] = useState([])
  const [TeamName, SetTeamName] = useState('')


  //common functions

  function timetoUtcClientAppointment(passdate) {


    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')


    return stardates



  }

  const GetMyTeamDetails = async () => {

    const response = await axiosJWT.get('/manager-access/get-my-team-details', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response.data !== null) {
      SetTeamName(response.data.ManagerDetails.manage_team.team_name)
      SetListOfUsers(response.data.ListofExperts)
    }
  }

  useEffect(() => {
    GetMyTeamDetails();
  }, []);  

  return (

    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>

      <section className="dashboard">

        <div className="manager_dashboard container">

          <div className="row">
            <div className=" colR">

              <div className="colRpos1">
              <h2>Dashboard</h2>
              </div>

              <div className="colRpos2">
                <div className="pos2inner" ref={pos2inner}>
                  <h3 ref={bar_name}>Welcome, {first_name + ' '}{last_name}</h3>
                  <p>Have a nice day at work</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row overall_top_row_manager">


            <div className="row list_team_heading_row_name">
              <div className="teamname">Team Name : {TeamName}</div>
              <div className="teamsize">Team Size : {ListOfUsers.length}</div>

            </div>

            {/* <div className="row list_team_heading_row">

              <span>List of Team Members</span>

            </div> */}

            <div className="row memberslist upcoming_appointment_table_row">


              <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                <Thead className="thead">
                  <Tr className="trow">
                    <Th scope="col">First Name</Th>
                    <Th scope="col">Last Name</Th>
                    <Th scope="col">Email</Th>
                    <Th scope="col">Role</Th>
                    <Th scope="col">Joined Date</Th>
                  </Tr>
                </Thead>
                <Tbody className="tbody">
                  {ListOfUsers.length > 0 && ListOfUsers.map((user, index) => (
                    <Tr className="trow">
                      <Td>{user.user.first_name}</Td>
                      <Td>{user.user.last_name}</Td>
                      <Td >{user.user.email}</Td>
                      <Td>{user.user.role === 'Nutrition' ? 'Coach' : user.user.role}</Td>
                      <Td>{format(parseISO(timetoUtcClientAppointment(user.user.createdAt)), `MMM dd, yyyy  hh:mm a`)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ManagerDashboard
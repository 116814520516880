import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

const GLPList=()=>{

    const side_nav = localStorage.getItem("side_nav");

    return (
        <div
        id="container-adjusts"
        className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
      >
            <section className="dashboard">

                <div>
                fdgfdgfdfgklkll
                </div>
            </section>
            
        </div>
    )
}
export default GLPList
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, set } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Reqchangeplan = () => {

    const token = localStorage.getItem("token");

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const [reqList, setreqList] = useState([]);
    const [reqplanstatus, setreqplanstatus] = useState('');
    const [reqClientName, setreqClientName] = useState('');
    const [reqClientId, setreqClientId] = useState('');
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [reqId, setreqId] = useState('');

    const [changeshow, setchangeshow] = useState(false);
    const handlechangeClose = () => setchangeshow(false);

    const [planOptions, setplanOptions] = useState([]);
    const [changePlan, setchangePlan] = useState('');
    const [changeplanDays, setchangeplanDays] = useState('');

    useEffect(() => {
        getrequestPlans();
    }, []);

    const axiosJWT = axios.create();

    const getrequestPlans = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/meal-plan/reqplan/all?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // console.log(response.data);

        //console.log(response.data.plans)
        setreqList(response.data.reqplans);
        setTotalCount(response.data.totalcount);
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getrequestPlans(pagenumber);
    }

    const handleShow = async (e) => {
        const reqId = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");


        setreqId(reqId);
        /*setModal(datamodal);*/

        const clientName = e.target.getAttribute("data-name");
        const client_id = e.target.getAttribute("data-client");
        setreqClientName(clientName);
        setreqClientId(client_id);
        setchangeplanDays('');

        if (datamodal === 'change_plan') {
            const response_plans = await axiosJWT.get("/meal-plan/", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response_plans)
            var plan_data = response_plans.data.plans;
            var plan_list = [];

            plan_data.forEach(element => {
                plan_list.push({ label: element.meal_plan_name + " (" + element.days + " Days)", value: element.id });
            });

            setplanOptions(plan_list);

            setchangeshow(true);

        }

        if (datamodal === 'delete_confirm') {
            //console.log(response.data)          

            setDeletePopup(true);
        }
    }

    const deleteRequest = async (e) => {

        const reqId = e.target.getAttribute("data-item");

        const response = await axiosJWT.delete("/meal-plan/reqplan/" + reqId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            setDeletePopup(false);
            getrequestPlans();
        }
    }

    const reqplan_status = async (e) => {
        try {
            var status = e.target.value;
            const reqId = e.target.getAttribute("data-item");

            if (status === 'true')
                var updateStatus = 0;
            else
                var updateStatus = 1;

            const response = await axiosJWT.put('/meal-plan/reqplan/' + reqId,
                {
                    status: updateStatus
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response)
                getrequestPlans();

        } catch (error) {
            console.log(error);
        }
    }


    const handleDeleteClose = () => {
        setreqId();
        setDeletePopup(false);
    }

    const updatePlan = async () => {

        var new_plan_label = changePlan.label;
        var position_bracket = (new_plan_label.indexOf("(") - 1);
        var filtered_plan_label = new_plan_label.substring(0, position_bracket);

        var new_plan_id = changePlan.value;

        //    console.log(filtered_plan_label+new_plan_id+reqClientId+changeplanDays);

        if (new_plan_id !== '' && filtered_plan_label !== '' && reqClientId !== '') {
            const response = await axiosJWT.put("/meal-plan/assign/" + new_plan_id,
                {
                    meal_plan_id: new_plan_id,
                    meal_plan_name: filtered_plan_label,
                    client_id: reqClientId,
                    days: changeplanDays
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                const status_change = await axiosJWT.put('/meal-plan/reqplan/' + reqId,
                    {
                        status: 1
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });


                if (status_change.status === 200) {
                    handlechangeClose();
                    getrequestPlans();
                }
            }
        }
    }

    const searchReq = async (event) => {

        var starting_limit = 0;
        var ending_limit = countPerPage

        const response = await axiosJWT.get("/meal-plan/reqplan/all?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);

        //console.log(response.data)
        setreqList(response.data.reqplans);
        setTotalCount(response.data.totalcount);
    }

    return (
        <div>
            <div className='title_box'>
                <div className='leftmenu'>
                    <h5>Requested List</h5><br></br>
                </div>
                <div className='rightmenu'>
                    <input className='search' type="text" placeholder="&#xF002; Search Client" onChange={event => searchReq(event.target.value)} />
                </div>
            </div>

            <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Client Name</Th>
                        <Th>Current Plan</Th>
                        <Th>Reason</Th>
                        <Th>Suggestion</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                    {reqList.map((reqplan, index) => (
                        <Tr className='trow' key={reqplan.id}>
                            <Td>{reqplan.user.first_name + " " + reqplan.user.last_name}</Td>
                            <Td>{reqplan.nutritionist_meal_plan.meal_plan_name}</Td>
                            <Td>{reqplan.reason}</Td>
                            <Td>{reqplan.suggestion}</Td>
                            <Td><div className={reqplan.status === true ? 'orangebox' : ''}>{reqplan.status === true ? "Changed" : ""}</div></Td>
                           
                            {/*<Td><input className='greenbutton' type="button" value="Change Plan" data-modal="change_plan" data-item={reqplan.id} data-client={reqplan.client_id} data-name={reqplan.user.first_name + " " + reqplan.user.last_name} onClick={handleShow} /></Td>*/}

                            <Td><i className="fa fa-check td_icon action" aria-hidden="true" data-modal="change_plan" data-item={reqplan.id} data-client={reqplan.client_id} data-name={reqplan.user.first_name + " " + reqplan.user.last_name} onClick={handleShow}></i><i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-client={reqplan.client_id} data-name={reqplan.user.first_name + " " + reqplan.user.last_name} data-item={reqplan.id} onClick={handleShow}></i></Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {totalCount ? 
            <Pagination
                activePage={activePage}
                itemsCountPerPage={countPerPage}
                totalItemsCount={totalCount}
                onChange={changePageNumber.bind(this)}
                prevPageText="<"
                nextPageText=">"
                hideFirstLastPages={true}
                linkClassPrev="pagination_prev"
                linkClassNext="pagination_next"
                linkClass="pagenumbers grayfont"
            />
            : null }

            <Modal show={changeshow} onHide={handlechangeClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Change Meal Plan To Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Choose Plan:</label><br />
                            <Select
                                options={planOptions}
                                onChange={setchangePlan}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>User:</label> {reqClientName}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label'>Days:</label><br />
                            <input placeholder="Leave it as empty for all days" type='text' className='inner_popup_input' onChange={(e) => { setchangeplanDays(e.target.value) }}></input>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handlechangeClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' onClick={(e) => updatePlan(e)}>
                        Change Plan
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePopup} onHide={handleDeleteClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete change request of "{reqClientName}" ?
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleDeleteClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={reqId} onClick={(e) => { deleteRequest(e) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>



            <div style={{ clear: 'both' }}></div>
        </div>
    )
}

export default Reqchangeplan
import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const CancelRedirect=()=>{
    const [loader,Setloader]=useState(true)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let type = params.get('type');
    let Gateway = params.get('gateway');
 
   

    useLayoutEffect(async()=>{

        if(type==='client_subscription')
        {
            window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/client/subscriptions?payment=failed&type=subscription'
        }
        if(type==='coach_subscription')
        {
            if(Gateway === 'affirm')
            {
                window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/coach/subscription?gateway=affirm&payment=failed&type=subscription'
            }
            else if(Gateway === 'splitit')
            {
                window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/coach/subscription?gateway=splitit&payment=failed&type=subscription'
            }
            else
            {
                window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/coach/subscription?payment=failed&type=subscription'
            }
        }
        if(type==='service_plan_purchase')
        {
            window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/client/appointment?payment=failed&type=purchase'
        }
        if(type==='payment_setup')
        {
            let end_page = params.get('end_page');
            if(end_page==='subscription')
            {
                window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/coach/subscription?payment_method=failed'
            }
        }
        if(type==='payment_add')
        {
            let end_page = params.get('end_page');
            if(end_page==='profile')
            {
                window.location.href=''+process.env.REACT_APP_FRONTEND_URL+'/profile?payment_method=failed'
            }
        }
        if(type==='client_registration')
        {
            window.location.href=''+process.env.REACT_APP_WORDPRESS_URL+'';            
        }
    });
    
    return (
        <div>
   {loader === true && <div id="semiTransparenDivLoader"></div>}
       </div>
    )
}

export default CancelRedirect
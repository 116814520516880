import React, { useEffect } from "react";

const Crave = (props) => {


    const CheckPhysical = (e) => {

        var tabLink = document.querySelectorAll(".option_div");

        tabLink.forEach(box => {
            box.classList.remove('active');
        });

        e.target.closest('.option_div').classList.add("active");

        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");

        if (category === "") {
            props.DisableNextButton();
        } 
        else {
            props.SetCraveData(category);
            props.EnableNextButton();
        }
    }

    useEffect(() => {
        setTimeout(() => {    
          if (props.CraveData) {
            if (props.CraveData !== "") {             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.CraveData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false);
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading" style={{maxWidth:'100%'}}>
                    How often do you have uncontrollable cravings for specific foods?</span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Once a month or less" onClick={CheckPhysical}><p>Once a month or less</p></div>
                <div className="option_div" data-target="On a weekly basis" onClick={CheckPhysical}><p>On a weekly basis</p></div>
                <div className="option_div" data-target="A few time per week/daily" onClick={CheckPhysical}><p>A few time per week/daily</p></div>
            </div>
        </div>
    )
}
export default Crave
import React, { useEffect, useState } from "react";
import Semaglutide from '../../../../src/images/semaglutide-compound.png'
import Tirzepatide from '../../../../src/images/tirzepatide.jpg'
import Ozempic from '../../../../src/images/ozempic.png'
import Wegovy from '../../../../src/images/wegovy.png'
import Mounjaro from '../../../../src/images/mounjaro.png'
import Zepbound from '../../../../src/images/zepbound.png'

const GLPDosePrefCM = (props) => {

  console.log('prefffmedddd',props)
  const [GlpDosePref, SetGlpDosePref] = useState("");
  const [GlpMedicine,SetGlpMedicine]=useState('')
  const [GlpDosage,SetGlpDosage]=useState('')
  const [GlpDosageId,SetGlpDosageId]=useState('')
  const [GlpDosageQuantity,SetGlpDosageQuantity]=useState('')
  const [GlpDosageInstructions,SetGlpDosageInstructions]=useState('')
  const [GlpPharmacy,SetGlpPharmacy]=useState('')

  const [GlpMedicineImg,SetGlpMedicineImg]=useState('')

  useEffect(()=>{
    SetGlpMedicineImg('')

    if(GlpMedicine==='Semaglutide')
    {
      SetGlpMedicineImg(Semaglutide)
    }
    if(GlpMedicine==='Tirzepatide')
    {
      SetGlpMedicineImg(Tirzepatide)
    }
    if(GlpMedicine==='Ozempic')
    {
      SetGlpMedicineImg(Ozempic)
    }
    if(GlpMedicine==='Wegovy')
    {
      SetGlpMedicineImg(Wegovy)
    }
    if(GlpMedicine==='Mounjaro')
    {
      SetGlpMedicineImg(Mounjaro)
    }
    if(GlpMedicine==='Zepbound')
    {
      SetGlpMedicineImg(Zepbound)
    }

   
  },[GlpMedicine])

  const handleOptionChange = (e) => {
    SetGlpDosePref(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
 
    if (props.GlpDosePrefPC) {
      SetGlpDosePref(props.GlpDosePrefPC);
    }
    if (props.GlpMedicinePC) {
     
      SetGlpMedicine(props.GlpMedicinePC);
      
        let filteredMedicationDetails = MedicationDetails.filter(
          (obj) => obj["name"] === props.GlpMedicinePC
        );
      
        SetGlpDosageOptions(filteredMedicationDetails[0].dosage)
      
    }
    if (props.GlpDosagePC) {
      SetGlpDosage(props.GlpDosagePC);
    }
    if (props.GlpDosageQuantityPC) {
      SetGlpDosageQuantity(props.GlpDosageQuantityPC);
    }
    if (props.GlpDosageIdPC) {
      SetGlpDosageId(props.GlpDosageIdPC);
    }
    if(props.GlpPharmacyPC)
    {
      SetGlpPharmacy(props.GlpPharmacyPC)
    }


  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetGlpDosePrefPC(GlpDosePref);

      var Err = 0;
      if (GlpDosePref === "" || GlpDosage==='' || GlpMedicine==='' || GlpPharmacy===''  ) {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);

    props.SetGlpMedicinePC(GlpMedicine)
    props.SetGlpDosagePC(GlpDosage)
    props.SetGlpDosageQuantityPC(GlpDosageQuantity)
    props.SetGlpDosageIdPC(GlpDosageId)
    props.SetGlpPharmacyPC(GlpPharmacy)
   



  }, [GlpDosePref,GlpMedicine,GlpDosage,GlpPharmacy,GlpDosageQuantity,GlpDosageId,GlpPharmacy]);

  useEffect(() => {
    if (props.SideEffectPC === "Yes") {
      props.SetPreviousMenu("sideeffectdesc");
    }
    if (props.SideEffectPC === "No") {
      props.SetPreviousMenu("sideeffect");
    }

    props.SetNextMenu("doctorinfo");
  }, []);
  



  var MedicationDetails = [
    {
      name: "Semaglutide",
      img: "https://fitpal.com/wp-content/themes/fitpal/assets/images/semaglutide-compound.png",
      dosage: [
        {
          name: "Semaglutide 0.25mg",
          medid: "VQMA7gaamuckLTOdiqzCVAWQJqFI027E",
          quantity: "1ml",
          instructions:
            "Inject 25 units subcutaneously once a week for 4 weeks (0.25mg)",
        },
        {
          name: "Semaglutide 0.5mg",
          medid: "25zjSXRZ0nyNoAU8bGxXmYXeWRUEHHKP",
          quantity: "2ml",
          instructions:
            "Inject 50 units subcutaneously once a week for 4 weeks (0.5mg)",
        },
        {
          name: "Semaglutide 1mg",
          medid: "8IbpEzEi72xzOLOlLuXQBXObeFZkVlcy",
          quantity: "1ml",
          instructions:
            "Inject 20 units subcutaneously once a week for 4 weeks (1mg)",
        },
        {
          name: "Semaglutide 1.7mg",
          medid: "yfHn0KdQRz516Rsrk7hS5t7isGwjGSJN",
          quantity: "1ml",
          instructions:
            "Inject 34 units subcutaneously once a week for 4 weeks (1.7mg)",
        },
        {
          name: "Semaglutide 2.5mg",
          medid: "TPLu38iwhQ7DMoL7qROWRN0gAirxw3Yi",
          quantity: "2ml",
          instructions:
            "Inject 50 units subcutaneously once a week for 4 weeks (2.5mg)",
        },
      ],
    },
    {
      name: "Tirzepatide",
      img: "http://fitpal.com/wp-content/uploads/2024/04/shutterstock_2165413479-min-1.jpg",
      dosage: [
        {
          name: "Tirzepatide 2.5mg",
          medid: "vKmVEcHVdIk1p5H12XJuIxyU5vaeEwe1",
          quantity: "1ml",
          instructions:
            "Inject Inject 0.25ml(25 units) once a week for 4 weeks (2.5mg)",
        },
        {
          name: "Tirzepatide 5mg",
          medid: "tlKlDAMlMnSyaLZ9NzorplgySGkgenZH",
          quantity: "1ml",
          instructions:
            "Inject 0.25ml(25 units) once a week for 4 weeks (5 mg)",
        },
        {
          name: "Tirzepatide 7.5mg",
          medid: "AVhaGBKU7CK5zlnI1iG82lPKsrr33bZo",
          quantity: "2ml",
          instructions:
            "Inject 0.375ml(37.5 units) once a week for 4 weeks (7.5mg)",
        },
        {
          name: "Tirzepatide 10mg",
          medid: "E7KVa8cFjCRB0RZbu34sX9WAOLzNBLvO",
          quantity: "2ml",
          instructions:
            "Inject 0.5ml(50 units) once a week for 4 weeks (10 mg)",
        },
        {
          name: "Tirzepatide 12.5mg",
          medid: "dvm9zpZLeCdQ78QOxwKBbB1uPY1bGKVQ",
          quantity: "3ml",
          instructions:
            "Inject 0.625ml(62.5 units) once a week for 4 weeks (12.5mg)",
        },
        {
          name: "Tirzepatide 15mg",
          medid: "ddc1HlmfAGTs46enOUo9tBkyvY82XvRU",
          quantity: "3ml",
          instructions:
            "Inject 0.75ml(75 units) once a week for 4 weeks (15mg)",
        },
      ],
    },
    {
      name: "Ozempic",
      img: "https://fitpal.com/wp-content/themes/fitpal/assets/images/ozempic.png",
      dosage: [
        {
          name: "Ozempic 0.25mg",
          medid: "6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX",
          quantity: "3ml",
          instructions:
            "Administer 0.25mg by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Ozempic 0.5mg",
          medid: "TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs",
          quantity: "3ml",
          instructions:
            "Administer 0.5mg by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Ozempic 1mg",
          medid: "QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl",
          quantity: "3ml",
          instructions:
            "Administer 1mg by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Ozempic 2mg",
          medid: "EZygESBXb2B4IUtZ5nZsCrQPTahmkt3b",
          quantity: "3ml",
          instructions:
            "Administer 2mg by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
      ],
    },
    {
      name: "Wegovy",
      img: "https://fitpal.com/wp-content/themes/fitpal/assets/images/wegovy.png",
      dosage: [
        {
          name: "Wegovy 0.25mg",
          medid: "yrbqXSVX9mrMpth91OEszx7bPGi4hMXH",
          quantity: "2ml",
          instructions: "Inject 0.25mg subcutaneously weekly",
        },
        {
          name: "Wegovy 0.5mg",
          medid: "fV5uaGx2IJ9VeQ0mxoclbYq4bgFZZSxO",
          quantity: "2ml",
          instructions: "Inject 0.5mg subcutaneously weekly",
        },
        {
          name: "Wegovy 1mg",
          medid: "NeEPvtesfrCakHkIhTpkJcihiyZHbyoA",
          quantity: "2ml",
          instructions: "Inject 1mg subcutaneously weekly",
        },
        {
          name: "Wegovy 1.7mg",
          medid: "eeSFJOtlyVrpfiKckXjnFwzEmUrtjXav",
          quantity: "3ml",
          instructions: "Inject 1.7mg subcutaneously weekly",
        },
        {
          name: "Wegovy 2.4mg",
          medid: "DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog",
          quantity: "3ml",
          instructions: "Inject 2.4.mg subcutaneously weekly",
        },
      ],
    },
    {
      name: "Mounjaro",
      img: "https://fitpal.com/wp-content/themes/fitpal/assets/images/mounjaro.png",
      dosage: [
        {
          name: "Mounjaro 2.5mg",
          medid: "dDiQ5EpWuVrtInwmWMqDrYwvLMUMZpbu",
          quantity: "2ml",
          instructions:
            "Administer 2.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Mounjaro 5mg",
          medid: "tdU7U0kcNeTzc1Lqbnu4nyJTRckGPq5k",
          quantity: "2ml",
          instructions:
            "Administer 5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Mounjaro 7.5mg",
          medid: "Yie6q5gUg2MszZLsSZ3tny3V8mqlDul3",
          quantity: "2ml",
          instructions:
            "Administer 7.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Mounjaro 10mg",
          medid: "TWqCUs7KxPsXwetEKdWuQfnmZ4c13XSQ",
          quantity: "2ml",
          instructions:
            "Administer 10 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Mounjaro 12.5mg",
          medid: "i2v09u191e6492O4aVg0dmSaiL0Ghmd9",
          quantity: "2ml",
          instructions:
            "Administer 12.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Mounjaro 15mg",
          medid: "OovZC6RIApK3YUojXKjfnMmun9Bhdwx1",
          quantity: "2ml",
          instructions:
            "Administer 15 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
      ],
    },
    {
      name: "Zepbound",
      img: "https://fitpal.com/wp-content/themes/fitpal/assets/images/zepbound.png",
      dosage: [
        {
          name: "Zepbound 2.5mg",
          medid: "5QGGZmGzuTXLJoBA5NXYlrP4l9dVz0nk",
          quantity: "2ml",
          instructions:
            "Administer 2.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Zepbound 5mg",
          medid: "2zgJdIF4dDzNkfg5jp7mweeOiNzkzzFK",
          quantity: "2ml",
          instructions:
            "Administer 5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Zepbound 7.5mg",
          medid: "Q78M2IPsR10yMAZQpOVOcj5NstwPgGNq",
          quantity: "2ml",
          instructions:
            "Administer 7.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Zepbound 10mg",
          medid: "rBi1AcVA66yvbLmwRqMEYYdJrDuURPb1",
          quantity: "2ml",
          instructions:
            "Administer 10 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Zepbound 12.5mg",
          medid: "blZ7IsnhEKGAW23Gqy0HyETXsd090Asn",
          quantity: "2ml",
          instructions:
            "Administer 12.5 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
        {
          name: "Zepbound 15mg",
          medid: "1of1tGyZEShjGcHgJct0pQBjyd5KagKY",
          quantity: "2ml",
          instructions:
            "Administer 15 mg once weekly for 4 weeks by SUBQ injection into the abdomen, thigh, or upper arm at any time of day on the same day each week, with or without food",
        },
      ],
    },
  ];
  const [GlpDosageOptions,SetGlpDosageOptions]=useState([])

  const Handleglpmedicinechange=(e)=>{

    var glpmedicine=e.target.value;
    SetGlpMedicine(glpmedicine)
    SetGlpDosage('')
    SetGlpDosageOptions([])
    if(glpmedicine!=='')
    {
      let filteredMedicationDetails = MedicationDetails.filter(
        (obj) => obj["name"] === glpmedicine
      );
    
      SetGlpDosageOptions(filteredMedicationDetails[0].dosage)
    }
    
    

  }

  function GetPharmacyId(MedId){

    const SouthEndPharmacy='239252';
    const AmazonPharmacy='247662';
    const ZepboundPharmacy='270842';
  
    const SouthEndPharmacyMedIds=['VQMA7gaamuckLTOdiqzCVAWQJqFI027E',
      '25zjSXRZ0nyNoAU8bGxXmYXeWRUEHHKP',
      '8IbpEzEi72xzOLOlLuXQBXObeFZkVlcy',
      'yfHn0KdQRz516Rsrk7hS5t7isGwjGSJN',
      'TPLu38iwhQ7DMoL7qROWRN0gAirxw3Yi',
      
      'vKmVEcHVdIk1p5H12XJuIxyU5vaeEwe1',
      'tlKlDAMlMnSyaLZ9NzorplgySGkgenZH',
      'AVhaGBKU7CK5zlnI1iG82lPKsrr33bZo',
      'E7KVa8cFjCRB0RZbu34sX9WAOLzNBLvO',
      'dvm9zpZLeCdQ78QOxwKBbB1uPY1bGKVQ',
      'ddc1HlmfAGTs46enOUo9tBkyvY82XvRU'];
      
      const AmazonPharmacyMedIds=[
        '6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX',
  'TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs',
  'QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl',
  'EZygESBXb2B4IUtZ5nZsCrQPTahmkt3b',
  
  'yrbqXSVX9mrMpth91OEszx7bPGi4hMXH',
  'fV5uaGx2IJ9VeQ0mxoclbYq4bgFZZSxO',
  'NeEPvtesfrCakHkIhTpkJcihiyZHbyoA',
  'eeSFJOtlyVrpfiKckXjnFwzEmUrtjXav',
  'DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog',
  'dDiQ5EpWuVrtInwmWMqDrYwvLMUMZpbu',
  'tdU7U0kcNeTzc1Lqbnu4nyJTRckGPq5k',
  'Yie6q5gUg2MszZLsSZ3tny3V8mqlDul3',
  'TWqCUs7KxPsXwetEKdWuQfnmZ4c13XSQ',
  'i2v09u191e6492O4aVg0dmSaiL0Ghmd9',
  'OovZC6RIApK3YUojXKjfnMmun9Bhdwx1',
      ]

    const ZepboundPharmacyMedIds = [
      '5QGGZmGzuTXLJoBA5NXYlrP4l9dVz0nk',
      '2zgJdIF4dDzNkfg5jp7mweeOiNzkzzFK',
      'Q78M2IPsR10yMAZQpOVOcj5NstwPgGNq',
      'rBi1AcVA66yvbLmwRqMEYYdJrDuURPb1',
      'blZ7IsnhEKGAW23Gqy0HyETXsd090Asn',
      '1of1tGyZEShjGcHgJct0pQBjyd5KagKY',
    ]
  
     
         if( SouthEndPharmacyMedIds.includes(MedId))
         {
          return SouthEndPharmacy
         }
        else if (AmazonPharmacyMedIds.includes(MedId))
          {
            return AmazonPharmacy
          }
        else if (ZepboundPharmacyMedIds.includes(MedId))
          {
            return ZepboundPharmacy
          }
  
  }

  const HandleGlpDosage=(e)=>{
    var glpdosage=e.target.value;
    SetGlpDosage('')
    SetGlpDosageQuantity('')
    SetGlpDosageId('')
    SetGlpDosageInstructions('')
    SetGlpPharmacy('')
    if(glpdosage!=='')
    {
      
      var quantity=e.target.selectedOptions[0].getAttribute('data-quantity')
      
      var medid=e.target.selectedOptions[0].getAttribute('data-medid')
      var instructions=e.target.selectedOptions[0].getAttribute('data-instructions')
      SetGlpDosage(glpdosage)
      SetGlpDosageQuantity(quantity)
      SetGlpDosageId(medid)
      SetGlpDosageInstructions(instructions)
      SetGlpPharmacy(GetPharmacyId(medid))
      
    }

  }

  return (
    <div className="daily_activity" id="glpdosepref">
      <div className="head_row"></div>

      <div>
        <label>
          <h6>{props.GlpDosePrefPCLabel}</h6>
        </label>
        <span style={{color:'red'}}>*</span>
      </div>

      <div className="even-columns">

        <div className="left"> <div className="input_row">
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Stay at the same dose or equivalent dose"
            checked={GlpDosePref === "Stay at the same dose or equivalent dose"}
            onChange={handleOptionChange}
          />
          <label>Stay at the same dose or equivalent dose</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Increase dose"
            checked={GlpDosePref === "Increase dose"}
            onChange={handleOptionChange}
          />
          <label>Increase dose</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Decrease dose"
            checked={GlpDosePref === "Decrease dose"}
            onChange={handleOptionChange}
          />
          <label>Decrease dose</label>
        </div>
      </div>

      <div className="input_row_dose">
        <div>
          <h6>Select your preferred medications <span style={{color:'red'}}>*</span></h6>
          
          <div className="glpmedicine_row">
            <select className="glp_medicine" name="glp_medicine" id="glp_medicine" onChange={Handleglpmedicinechange} defaultValue={GlpMedicine} value={GlpMedicine}>
              <option value={''}>Please select medication</option>
             {

props.ClientPlanAllowedMedicine.map((element, index) => (
  <option  value={element}>{element}</option>
))}
              
             
            </select>
          </div>
        </div>
      </div>

      <div className="input_row_dose">
        <div>
          <h6>Select your preferred dosage     <span style={{color:'red'}}>*</span></h6>
          <div className="glpmedicine_row">
            <select className="glp_dosage" name="glp_dosage" id="glp_dosage" defaultValue={GlpDosage}  value={GlpDosage} onChange={(e)=>{HandleGlpDosage(e)}}>
              <option value={''}>Please select medication</option>
             {
                GlpDosageOptions.map((element, index) => (
                  <option data-medid={element.medid} data-quantity={element.quantity} data-instructions={element.instructions}  value={element.name}>{element.name}</option>
                ))}
             
            </select>
          </div>
        </div>
      </div>


      {/* <div className="input_row_dose">
        <div>
          <h6>Select your preferred pharmacy     <span style={{color:'red'}}>*</span></h6>
          <div className="glpmedicine_row">
            <select className="glp_pharmacy" name="glp_pharmacy" id="glp_pharmacy" defaultValue={GlpPharmacy}  onChange={(e)=>{SetGlpPharmacy(e.target.value)}} value={GlpPharmacy}>
              <option value={''}>Please select pharmacy</option>
              <option value={'239252'}>Southend</option>
              <option value={'247662'}>Amazon</option>
             </select>
          </div>
        </div>
      </div> */}
      
      
      </div>

      <div className="right"><img src={GlpMedicineImg}/></div>


      </div>

     



    </div>
  );
};
export default GLPDosePrefCM;

import React, { useEffect, useState } from "react";
import WithingsImage from '../../../images/withings-image.webp';
import axios from "axios";
import moment from 'moment-timezone';

const WithingsOrderStatus=()=>{


    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [OrderDetailsMsg,SetOrderDetailsMsg]=useState('')

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const [WithingsUserId,SetWithingsUserId]=useState('')
    const [WithingsOrderId,SetWithingsOrderId]=useState('')
    const [WithingsOrderedDate,SetWithingsOrderedDate]=useState('')
    const [WithingsOrderedAddress,SetWithingsOrderedAddress]=useState('')

    const GetMyOrderDetails=async()=>{
        ShowLoader()
        try{

            const response = await axiosJWT.get("/withings/order-status", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if(response.status===200)
            {
                HideLoader()
                if(response.data.user_details.withings_user_id)
                {
                    if(response.data.user_details.withings_user_id!==null && response.data.user_details.withings_user_id!=='')
                    {
                        SetWithingsUserId(response.data.user_details.withings_user_id)
                    }
                }
                if(response.data.user_details.withings_device_order_id)
                {
                    if(response.data.user_details.withings_device_order_id!==null && response.data.user_details.withings_device_order_id!=='')
                    {
                        SetWithingsOrderId(response.data.user_details.withings_device_order_id)
                    }
                }
                if(response.data.user_details.withings_device_order_date)
                {
                    if(response.data.user_details.withings_device_order_date!==null && response.data.user_details.withings_device_order_date!=='')
                    {
                    
                        const OrderedDate=moment.unix(response.data.user_details.withings_device_order_date).format('MMM DD, yyyy');
                        SetWithingsOrderedDate(OrderedDate)
                    }
                }
                if(response.data.user_details.withings_device_order_address)
                {
                    if(response.data.user_details.withings_device_order_address!==null && response.data.user_details.withings_device_order_address!=='')
                    {
                        SetWithingsOrderedAddress(response.data.user_details.withings_device_order_address)
                    }
                }


                if(response.data.order_details.status==='SHIPPED')
                {
                    SetOrderDetailsMsg('Your withing devices has been shipped successfully. Your Order Id is '+response.data.order_details.order_id+'.')
                }
                else if(response.data.order_details.status==='VERIFIED')
                {
                    SetOrderDetailsMsg('Your withing devices order has been successfully processed for verfication. Your Order Id is '+response.data.order_details.order_id+'.')
                }
                else{
                    SetOrderDetailsMsg('Your withings devices order has been failed.Please contact us for further details')
                }
                
            }

        }catch(err)
        {
            HideLoader()
            SetOrderDetailsMsg(err.response.data.msg)

        }

      

  

    }

    useEffect(()=>{
        GetMyOrderDetails()
    },[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
               {loader === true && <div id="semiTransparenDivLoader"></div>}
        <section className="container">
     
            <div className="withings_whole_div">
                <div className="withings_image_div">
                    <div>
                        <div className="top_head">Withings Devices</div>
                    <img src={WithingsImage} className="withings_logo" />
                    </div>
                
                </div>
                <div className="order_details_withings">
                  {OrderDetailsMsg}
                </div>
                {
                    WithingsOrderId!=='' && 
                    <div style={{marginTop:'20px'}}>
                    <div className='overall_withings_admin_div'>
                    <div className='overall_withings_admin_row'>
                        <div className='overall_withings_admin_col1'>Customer Ref id:</div>
                        <div className='overall_withings_admin_col2'>{WithingsUserId}</div>
                    </div>
                    <div className='overall_withings_admin_row'>
                        <div className='overall_withings_admin_col1'>Device order id:</div>
                        <div className='overall_withings_admin_col2'>{WithingsOrderId}</div>
                    </div>
                    <div className='overall_withings_admin_row'>
                        <div className='overall_withings_admin_col1'>Device ordered date:</div>
                        <div className='overall_withings_admin_col2'>{WithingsOrderedDate}</div>
                        
                    </div>
                    <div className='overall_withings_admin_row'>
                        <div className='overall_withings_admin_col1'>Device delivery address:</div>
                        <div className='overall_withings_admin_col2'>{WithingsOrderedAddress}</div>
                        
                    </div>
                </div>
                    </div>
                }
              
            </div>
        </section>
       </div>
    )
}
export default WithingsOrderStatus
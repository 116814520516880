import axios from "axios";
import React, { useEffect, useState ,useLayoutEffect} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ExerciseDetail from "./ExerciseDetails";
import ViewAllExercise from "./ExerciseViewAll";
import PremiumCheckComponent from "../PremiumPlanPopup";

const ExerciseList = () => {
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }
    const [CategoriesedVideos, SetCategoriesedVideos] = useState([])
    const [ExerciseDetailShow, SetExerciseDetailShow] = useState(false)
    const [VideoDetailedId, SetVideoDetailedId] = useState('')
    const [InitialAllList, SetInitialAllList] = useState([])
    const [DetailedVideo, SetDetailedVideo] = useState([])
    const [ExerciseViewallShow, SetExerciseViewallShow] = useState(false)
    const [ViewAllSelectedCategory, SetViewAllSelectedCategory] = useState('')
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [VideoLoaded,SetVideoLoaded]=useState(false)
    const [QueryVideoId,SetQueryVideoId]=useState('')

    const GetListOfVideos = async (VideoId='') => {

    

        ShowLoader();

        const VideoListResponse = await axios.get('/fod/list-videos', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (VideoListResponse.status === 200) {
    
            if (VideoListResponse.data) {
                SetInitialAllList(VideoListResponse.data.items)
                const CategoriesedVideosList = await CategorizeVideo(VideoListResponse.data.items)
                SetCategoriesedVideos(CategoriesedVideosList)

                let search = window.location.search;
                let params = new URLSearchParams(search);
                let VideoId = params.get('video_id');
                if(VideoId!==null && VideoId!=='')
                {
                    SetQueryVideoId(VideoId)
                }
                SetVideoLoaded(true)
            }
            HideLoader()
           
        }
    }

    useEffect(()=>{
        if(VideoLoaded===true)
        {
           if(QueryVideoId!==null && QueryVideoId!=='')
           {
            setTimeout(() => {
                document.querySelector('.fitness_thumbnail[data-id="'+QueryVideoId+'"]').click()
                window.history.pushState({}, document.title, window.location.pathname);
               
              }, 500);
           }
        
        }
     
    },[VideoLoaded]) 

    useEffect(() => {

         
        GetListOfVideos()
  
    }, [])

  

  

    const CategorizeVideo = async (VideoListArray) => {
        var FinalArray = {};

        if (VideoListArray.length > 0) {
            VideoListArray.forEach(element => {

                element.categories.forEach(element => {
                    FinalArray[element] = []
                });
            });


            VideoListArray.forEach((Videoelement, index) => {
                Videoelement.categories.forEach((Catelement, index) => {
                    if (FinalArray[Catelement] !== undefined) {
                        FinalArray[Catelement].push(Videoelement)
                    }
                });
            });
        }
        return FinalArray;
    }

    const VideoDetail = (e) => {
   
        const wTile = e.target.parentNode;
        const ClickedId = wTile.getAttribute('data-id');

        const filteredBooks = InitialAllList.filter(val => (val.videoId === parseInt(ClickedId)));
        SetDetailedVideo(filteredBooks);
        SetVideoDetailedId(ClickedId);
        SetExerciseViewallShow(false);
        SetExerciseDetailShow(true);
     
    }

    const FormatSeconds = (milliseconds = '') => {
        var minutes = Math.floor(milliseconds / 60000);
        // var seconds = ((milliseconds % 60000) / 1000).toFixed(0);
        return minutes;
    }

    const ViewAll = (e) => {
        const ClickedCategory = e.target.getAttribute('data-item');
        SetViewAllSelectedCategory(ClickedCategory);
        SetExerciseViewallShow(true);
    }

    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <PremiumCheckComponent />
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                {
                    (ExerciseViewallShow == false && ExerciseDetailShow === false) ? <div className="container exercise_video_container">
                        <div className="row heading_row">List of videos</div>
                        <div className="categorised_row">
                            {Object.keys(CategoriesedVideos).map((item, i) => (
                                <div className="carousel_row" >
                                    <p className="title">{item}<span style={{ color: 'gray' }}> {CategoriesedVideos[item].length} videos</span><button className="view_link" data-item={item} onClick={(e) => { ViewAll(e) }}>View All</button></p>
                                    <Carousel draggable={true} swipeable={true} infinite={false} responsive={responsive} className="card_row">
                                        {CategoriesedVideos[item].length > 0 && CategoriesedVideos[item].map((videos, index) => (
                                            <div className="video_list" data-id={videos.videoId} onClick={(e) => { VideoDetail(e) }}>
                                                <img src={videos.thumbnailUrl} className="fitness_thumbnail" data-id={videos.videoId} onClick={(e)=>{VideoDetail(e)}} />
                                                <div className="video_info">
                                                    <span className="title">{videos.videoTitle}</span>
                                                    <span className="mins"><i className="fa fa-clock-o minsicon" aria-hidden="true"></i>{FormatSeconds(videos.videoDuration)} mins</span>
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            ))
                            }
                        </div>
                    </div> : null
                }

                {
                    (ExerciseViewallShow === false && ExerciseDetailShow === true) ? <ExerciseDetail SetExerciseDetailShow={SetExerciseDetailShow} VideoDetailedId={VideoDetailedId} DetailedVideo={DetailedVideo} /> : null
                }
                {
                    (ExerciseViewallShow === true && ExerciseDetailShow === false) ? <ViewAllExercise ExerciseViewallShow={ExerciseViewallShow} CategoriesedVideos={CategoriesedVideos} ViewAllSelectedCategory={ViewAllSelectedCategory} SetExerciseViewallShow={SetExerciseViewallShow} VideoDetail={VideoDetail} /> : null
                }
            </section>
        </div>
    )
}

export default ExerciseList
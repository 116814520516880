import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Popup from 'reactjs-popup';
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Datepicker from 'react-date-picker';
import ReactToPrint from 'react-to-print';

const AdminPaymentHistory = () => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [PerPage, SetPerPage] = useState(10);
    const [PaymentHistoryList, SetPaymentHistoryList] = useState([])
    const [TotalRecord, SetTotalRecord] = useState(0)
    const [Pagenumber, SetPagenumber] = useState(1)
    const [FromDate, SetFromDate] = useState()
    const [ToDate, SetToDate] = useState()

    const GetAdminPaymentHistory = async (pagenumber, startdate = '', enddate = '') => {
        SetPagenumber(pagenumber)

        const PaymentHistoryResponse = await axiosJWT.get('payments/list-admin-payments-history?offset=' + pagenumber + '&limit=' + PerPage + '&startdate=' + startdate + '&enddate=' + enddate + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (PaymentHistoryResponse.status === 200) {
            SetPaymentHistoryList(PaymentHistoryResponse.data.list)
            SetTotalRecord(PaymentHistoryResponse.data.count)
        }
    }

    function formatString(string) {
        let result = string.replace("_", " ");
        result = result.charAt(0).toUpperCase() + result.slice(1);
        return result
    }

    const changePageNumber = (pagenumber) => {

        GetAdminPaymentHistory(pagenumber, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate))


    }

    const FiterPaymentData = (e) => {


        const action = e.target.getAttribute('data-action')
        if (action === 'reset') {
            SetFromDate('')
            SetToDate('')
            GetAdminPaymentHistory(1)
        }
        else {
            if (FromDate !== '' && ToDate !== '') {

                GetAdminPaymentHistory(1, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate))
            }


        }


    }
    const ChangeDatePickerDate = (pass) => {

     

        if (pass!==undefined && pass!==null  && pass !== '') {
            var passDate = ''
            var yyyy = pass.getFullYear().toString();
            var mm = (pass.getMonth() + 1).toString();
            var dd = pass.getDate().toString();
            passDate = yyyy + '-' + mm + '-' + dd
            return passDate
        }
        else {
            return ''
        }


    }
    const componentRef = useRef();

    useEffect(() => {

        GetAdminPaymentHistory(1)

    }, [])

    return (
        <div className='container admin_billing_payment_history'>
            <div className='row subscriber_div'>

                <div className='filter_div_admin_payment_history'>
                    <div className="col">
                        <div style={{float:"left"}}>
                            <div className="second_section_payment_span">From:</div>
                            <Datepicker
                                value={FromDate}
                                onChange={SetFromDate}
                                format="MM-dd-yyyy"
                                className={'date_picker_payment_coach sdate'}
                            />
                        </div>
                        <div style={{float:"left"}}>
                            <div className="second_section_payment_span">To:</div>
                            <Datepicker
                                value={ToDate}
                                onChange={SetToDate}
                                format="MM-dd-yyyy"
                                className={'date_picker_payment_coach edate'}
                            />
                        </div>
                    </div>
                    <div className="col buttons">
                            <ReactToPrint
                                trigger={() => <button className='payment_coach_filter_button_admin'>Export PDF</button>}
                                content={() => componentRef.current}
                            />
                            <button className='payment_coach_filter_button_admin' data-action="filter" onClick={(e) => { FiterPaymentData(e) }}>Filter</button>
                            <button className='payment_coach_filter_button_admin' data-action="reset" onClick={(e) => { FiterPaymentData(e) }}>Reset</button>
                    </div>
                </div>

            </div>

            <Table className='table is-striped is-fullwidth' ref={componentRef}>

                <Thead className='thead'>
                    <Tr>
                        <Th className='row_name'>Payment From</Th>
                        <Th className='row_name'>Payment Amount</Th>
                        <Th className='row_name'>Payment Date</Th>
                        <Th className='row_name'>User Name</Th>
                        <Th className='row_name'>User Email</Th>

                    </Tr>
                </Thead>

                <Tbody className='tbody'>

                    {PaymentHistoryList.length > 0 && PaymentHistoryList.map((payments, index) => (

                        <Tr key='1'>
                            <Td>{formatString(payments.payment_category)}</Td>
                            <Td>$ {payments.payment_amount}</Td>
                            <Td>{format(parseISO(payments.payment_date), `PP`)}</Td>
                            <Td>{payments.user.first_name + ' ' + payments.user.last_name}</Td>
                            <Td>{payments.user.email}</Td>

                        </Tr>
                    ))}


                </Tbody>


            </Table>



            <div>

                {PaymentHistoryList.length > 0 ?
                    <Pagination
                        activePage={Pagenumber}
                        itemsCountPerPage={PerPage}
                        totalItemsCount={TotalRecord}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                    : null}

            </div>

        </div>
    )
}
export default AdminPaymentHistory
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, createRef } from 'react'
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InfiniteScroll from 'react-infinite-scroller';
import { saveAs } from 'file-saver'
import deliveredicon from "../../images/messages/delivered.svg"
import readicon from "../../images/messages/read.svg"
import imageicon from "../../images/messages/image.svg"
import sendicon from "../../images/messages/Send.svg"
import closeicon from "../../images/messages/Close.svg"


var QB = require('quickblox/quickblox.min');

const CoachMessages = () => {

    const messageEl = useRef(null);

    useEffect(() => {
        document.title = 'Coach-Messages';
        loading_icon('show');

        setTimeout(() => {
            load_messages();
        }, 3000);

        // setInterval(() => {
        //     reconnect_qb_server();
        // }, 600000);
        

        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, []);

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const coach_timezone = localStorage.getItem("user_timezone");
    const loggedin_userid = localStorage.getItem('userId');
    localStorage.setItem("chatDate", '');

    const [usersList, setusersList] = useState([]);
    const [dialogList, setdialogList] = useState([]);
    const [dialogData, setdialogData] = useState([]);
    const [messageList, setmessageList] = useState([]);
    const [activeId, setactiveId] = useState('');
    const [messageText, setmessageText] = useState('');
    const [selectedFile, setselectedFile] = useState('');
    const [Preview, setPreview] = useState('');
    const [totalmsgpageCount, settotalmsgpageCount] = useState('');
    const [showMore, setshowMore] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [showmoreEnable, setshowmoreEnable] = useState(false);
    const [activePage, setactivePage] = useState('');
    const [show, setshow] = useState(false);
    const [loadImg, setloadImg] = useState('');
    const [attachmentFileid, setattachmentFileid] = useState('');
    const [loggedin_userQBId, setloggedin_userQBId] = useState('');
    const [loggedin_userQBname, setloggedin_userQBname] = useState('');
    const [opponent_id, setopponent_id] = useState('');
    const [opponent_name, setopponent_name] = useState('');
    const [dmToggle, setdmToggle] = useState(false);
    const [sessionStart, setsessionStart] = useState(false);

    const handleClose = () => { setshow(false) }

    const axiosJWT = axios.create();    

    const QB_app_connect = async () => {
        var APPLICATION_ID = process.env.REACT_APP_QB_APPLICATION_ID;
        var AUTH_KEY = process.env.REACT_APP_QB_AUTH_KEY;
        var AUTH_SECRET = process.env.REACT_APP_QB_AUTH_SECRET;
        var ACCOUNT_KEY = process.env.REACT_APP_QB_ACCOUNT_KEY;
        var CONFIG = { 
            debug: true,
            webrtc: {
                answerTimeInterval: 60,
                autoReject: true,
                incomingLimit: 1,
                dialingTimeInterval: 5,
                disconnectTimeInterval: 300,
                statsReportTimeInterval: false
            } 
        };

        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG); 
    }

    const reconnect_qb_server = () => {
        window.location.reload();
    }
  
    const load_messages = async () => {

        if (QB && localStorage.getItem('QB_USER_TOKEN') !== '') {
            

            // if(!localStorage.getItem('QB_USER_TOKEN')) 
            // {                               
            //     console.log('new session test')
            //     create_session();
            // }
            // else {
            
                // console.log('mi: exist session')
               // QB_app_connect();   
                
                QB.init(localStorage.getItem('QB_USER_TOKEN'));

                if(QB)
                {
                            var listparams = {
                                order: {
                                    field: 'created_at',
                                    sort: 'asc'
                                },
                                page: 1,
                                per_page: 50
                            };

                            QB.users.listUsers(listparams, function (lu_error, lu_result) {
                                if (lu_error) {
                                    //create_session();
                                    console.log(lu_error);
                                    reconnect_qb_server();
                                }
                                if (lu_result) {
                                    setusersList(lu_result.items);

                                    var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
                                    var userId = session.user_id;
                                    var password = session.token;
                                    var params = { userId, password };
                    
                                    if(userId && password)
                                    {
                                        setsessionStart(true);
                                        
                                        setloggedin_userQBId(session.user_id);
                    
                                        QB.chat.connect(params, function (c_error, contactList) {
                                            if (c_error) {
                                                //create_session();
                                                console.log(c_error);
                                            }
                                            else {   
                                                loaddialogList();
                                                loading_icon('hide');  
                                            }
                                        });
                                    }
                                }
                            });                             
                }
        }
    }

    const create_session = () => {

        QB_app_connect();
        
        if (loggedin_userid) {
            axiosJWT.get('/user',
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    if(response.data.id)
                    {
                        // console.log(response.data)

                        //connect with quickblox
                        // var created_timestamp = (Math.floor(new Date(response.data.createdAt).getTime() / 1000)).toString();
                        // var qb_user_login = "KD" + response.data.id + "_" + created_timestamp.substring(5, 10);
                        // var qb_user_pwd = "Keto" + response.data.id + created_timestamp.substring(1, 6);

                        var qb_user_login = response.data.qb_login;
                        var qb_user_pwd = response.data.qb_password;

                        QB.createSession({ login: qb_user_login, password: qb_user_pwd }, function (error, result) {
                            // callback function
                            if (result) {

                                localStorage.setItem('QB_SESSION_RESPONSE', JSON.stringify(result));
                                localStorage.setItem('QB_USER_TOKEN', result.token);

                                var params = {
                                    order: {
                                        field: 'created_at',
                                        sort: 'asc'
                                    },
                                    page: 1,
                                    per_page: 50
                                };

                                QB.users.listUsers(params, function (lu_error, lu_result) {
                                    
                                    if(lu_result)
                                    {
                                    // console.log(result.items);
                                        setusersList(lu_result.items);
                                    }
                                    if(lu_error)
                                    {   
                                        // console.log("mi: create session userlist")
                                        console.log(lu_error);
                                    }
                                });


                                var session = QB.service.getSession();
                                var userId = session.user_id;
                                var password = session.token;
                                var params = { userId, password };

                                setloggedin_userQBId(session.user_id);

                                setsessionStart(true);

                                QB.chat.connect(params, function (c_error, contactList) {
                                    if(c_error)
                                    {
                                        //create_session();
                                        console.log(c_error);
                                    }
                                    else
                                    {
                                        loaddialogList();
                                        loading_icon('hide');
                                    }
                                });
                            }
                        });
                    }

                });
        }

    }

    function getUserName(senderid) {
        var users = JSON.parse(JSON.stringify(usersList))
        var user = users.find((element) => {
            return element.user.id === senderid
        });
        if (user) {
            return user.user.full_name;
        }
    }

    const loadChat = async (dialogId) => {

        if (QB) {

            setdmToggle(false);

            var dialog = dialogList.find((element) => {
                return element._id === dialogId
            });

            var dialog_users = dialog.occupants_ids;

            dialog_users.forEach(element => {
                if (parseInt(element) !== loggedin_userQBId)
                {
                    setopponent_id(parseInt(element));

                    var searchParams = {filter: { field: 'id', param: 'in', value: [parseInt(element)] }};
    
                    QB.users.listUsers(searchParams, function(error, result) {
                        setopponent_name(result.items[0].user.full_name);
                    });
                }
                else
                {
                    var searchParams = {filter: { field: 'id', param: 'in', value: [parseInt(element)] }};
    
                    QB.users.listUsers(searchParams, function(error, result) {
                        setloggedin_userQBname(result.items[0].user.full_name);
                    });                    
                }
            });

            setdialogData(dialog);

            setactiveId(dialogId);

            QB.chat.message.list({ chat_dialog_id: dialogId, count: 1 }, function (err, messagesCount) {
                if (messagesCount) {

                    var page_count = Math.ceil(messagesCount.items.count / 50);
                    settotalmsgpageCount(page_count);
                    setactivePage(1);

                    if (messagesCount.items.count > 50)
                        setshowmoreEnable(true);
                    else
                        setshowmoreEnable(false);

                } else {
                    console.log(err);
                }
            });

            var params = {
                chat_dialog_id: dialogId,
                sort_desc: 'date_sent',
                limit: 50,
                skip: 0
            };

            QB.chat.message.list(params, function (error, messages) {
                // console.log(messages)
                var messagedata = messages.items;
                var reversedata = messagedata.reverse();
                setmessageList(reversedata);
            });

            closePreview();
            loaddialogList();
        }
    }

    const loaddialogList = async (search) => {
        if (QB) {
            if (search) {
                let params = {
                    occupants_ids: {
                        all: search
                    }
                };

                QB.chat.dialog.list(params, function (error, dialogs) {
                    setdialogList(dialogs.items);
                });
            }
            else {
                let params = {
                    sort_desc: 'last_message_date_sent'
                };

                QB.chat.dialog.list(params, function (error, dialogs) {
                    // console.log(dialogs)
                    setdialogList(dialogs.items);
                });
            }
        }
    }

    function getFileURL(fileid) {
        if (QB) {
            if (fileid !== '') {
                var fileUrl = QB.content.privateUrl(fileid);
                if (fileUrl) {
                    return fileUrl;
                }
            }
        }
    }

    const sendMessage = async () => {
        if (QB) {

            // var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
            //     var userId = session.user_id;
            //     var password = session.token;
            //     var params = { userId, password };

            //     if(userId && password)
            //     {


            //         QB.chat.connect(params, function (c_error, contactList) {

            var dialog_users = dialogData.occupants_ids;

            var opponent_id, messagupdated;

            dialog_users.forEach(element => {
                if (parseInt(element) !== loggedin_userQBId)
                    opponent_id = parseInt(element);
            });

            if (messageText !== '') {
                var message = {
                    type: "chat",
                    body: messageText,
                    extension: {
                        save_to_history: 1,
                        dialog_id: dialogData._id,
                    },
                    markable: 1,
                };                                

                try {

                    // console.log(opponent_id+" "+message.body)

                    QB.chat.send(opponent_id, message);

                    loaddialogList();

                    setmessageText('');

                    messagupdated = true;

                } catch (e) {
                    if (e.name === "ChatNotConnectedError") {
                        console.log("not connected to chat");
                    }
                }

                
            }

            if (selectedFile) {
                var fileParams = {
                    name: selectedFile.name,
                    file: selectedFile,
                    type: selectedFile.type,
                    size: selectedFile.size,
                    public: false
                };
                QB.content.createAndUpload(fileParams, function (error, result) {
                    if (!error) {

                        try {
                            // prepare a message
                            var message = {
                                type: "chat",
                                body: "[attachment]",
                                extension: {
                                    save_to_history: 1,
                                    dialog_id: dialogData._id,
                                    attachments: [{ id: result.uid, type: "image" }]
                                }
                            };

                            // send the message
                            QB.chat.send(opponent_id, message);

                            messagupdated = true;
                        } catch (e) {
                            if (e.name === "ChatNotConnectedError") {
                                console.log("not connected to chat");
                            }
                        }
                    }
                });                
            }

            if (messagupdated === true) {
                var params = {
                    chat_dialog_id: dialogData._id,
                    sort_desc: 'date_sent',
                    limit: 50,
                    skip: 0
                };

                QB.chat.message.list(params, function (error, messages) {
                    //console.log(messages)
                    var messagedata = messages.items;
                    var reversedata = messagedata.reverse();
                    setmessageList(reversedata);
                });
            }
        //}); }
        }        
    }

    const file_select = async (e) => {

        var filedata = e.target.files[0];

        setselectedFile(filedata);

        const objectUrl = URL.createObjectURL(filedata)
        setPreview(objectUrl)

        // console.log(filedata);
    }

    const closePreview = () => { setPreview(''); setselectedFile(''); }

    function getchatDate(timestamp) {

        const chatDate = localStorage.getItem("chatDate");

        var chat_date = moment.tz(moment.unix(timestamp), coach_timezone).format('D MMM');

        var current_date = moment.tz(moment(new Date()), coach_timezone).format('D MMM');

        var yesterday_date = moment.tz((moment().add(-1, 'days')), coach_timezone).format('D MMM');



        if (chatDate !== chat_date) {
            localStorage.setItem("chatDate", chat_date);

            if (current_date === chat_date)
                return "Today";
            else if (yesterday_date === chat_date)
                return "Yesterday";
            else
                return chat_date;
        }
        else
            return '';
    }

    if(sessionStart === true)
    {
    QB.chat.onMessageListener = onMessage;
    }

    function onMessage(userId, message) {
        loaddialogList();

        if (dialogData !== null) {
            loadChat(dialogData._id);
            // console.log(dialogData);
        }
    }

    const loadmore = () => {
        if (QB) {
            var current_page = activePage;
            if (current_page < totalmsgpageCount) {
                if (fetching)
                    return;

                setFetching(true);

                var params = {
                    chat_dialog_id: dialogData._id,
                    sort_desc: 'date_sent',
                    limit: 50,
                    skip: (50 * current_page)
                };

                QB.chat.message.list(params, function (error, messages) {
                    // console.log(messages)
                    var messagedata = messages.items;
                    var reversedata = messagedata.reverse();
                    // console.log(reversedata);

                    setmessageList([...reversedata, ...messageList]);

                    setFetching(false);

                    setshowMore(false);


                    setactivePage((current_page + 1));

                    if ((current_page + 1) < totalmsgpageCount)
                        setshowmoreEnable(true);
                    else
                        setshowmoreEnable(false);
                });
            }
        }
    }

    const setmore = () => { setshowMore(true); }

    const load_image = (fileid) => {
        if (QB) {
            if (fileid) {
                var fileUrl = QB.content.privateUrl(fileid);
                /*if (fileUrl) {
                    return fileUrl;
                }*/

                if (fileUrl) {
                    setshow(true);

                    setloadImg(fileUrl);
                    setattachmentFileid(fileid);

                    // console.log(fileUrl);
                }
            }
        }
    }

    const downloadAttachment = (fileid) => {
        if (QB) {
            if (fileid) {
                var fileUrl = QB.content.privateUrl(fileid);

                const [url, token] = fileUrl.split('?token=');

                fetch(url + '.json', {
                    headers: { 'QB-Token': token }
                }).then(res => res.blob())
                    .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);
                        saveAs(blobUrl, "attachment_" + (Date.now()));
                    });

            }
        }
    }


    const searchUsers = async (name) => {
        if (QB) {
            if (name.length > 3) {
                var searchParams = {
                    full_name: name,
                    order: {
                        field: 'updated_at',
                        sort: 'desc'
                    },
                    page: 1,
                    per_page: 50
                };

                var users_id_list = [];

                QB.users.get(searchParams, function (error, result) {
                    var userslist = result.items;
                    userslist.forEach(element => {
                        users_id_list.push(element.user.id);
                    });

                    // console.log(users_id_list);

                    loaddialogList(users_id_list);
                });
            }
            if (name.length === 0) {
                loaddialogList();
            }
        }
    }

    const updateStatus = () => {

        loaddialogList();

        /*var params = {chat_dialog_ids: [dialogData._id]};
        QB.chat.message.unreadCount(params, function(err, res) {
            if(res){
                console.log(res)

                if(res.total>0)
                {
                    var params = {
                        chat_dialog_id: dialogData._id,
                        sort_desc: 'date_sent',
                        limit: res.total,
                        skip: 0
                    };
            
                    QB.chat.message.list(params, function (error, messages) {
                        var messagedata = messages.items;
                        messagedata.forEach(element => {
                            QB.chat.sendReadStatus({messageId:element._id,userId:loggedin_userQBId,dialogId:dialogData._id});
                        });
                    }); 
                }
            }
        });*/
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {

            e.preventDefault();

            if (messageText.trim() !== '')
                sendMessage();
        }
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    const dialogmenu_toggle = async () => {
        setdmToggle(!dmToggle);
    }

    const delete_dialog = async (e) => {
        if (QB) {
            var dialog_id = e.target.getAttribute("data-item");

            QB.chat.dialog.delete([dialog_id], function (error) {
                loaddialogList();
                setdialogData([]);
            });
        }
    }

    const block_delete_dialog = async (e) => {
        if (QB) {
            var dialog_id = e.target.getAttribute("data-item");

            QB.chat.dialog.delete([dialog_id], { force: 1 }, function (error) {
                loaddialogList();
                setdialogData([]);
            });
        }
    }

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="coachmessages chatmessages container">
                    <div className='menubox'>
                        <div className='leftmenu'>
                            <h4>Messages</h4>
                        </div>
                        {/* <div className='rightmenu'>
                            <input type="text" placeholder="&#xF002; Search" onChange={event => searchUsers(event.target.value)} />
                        </div> */}
                    </div>

                    <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>

                    {/*<button onClick={()=>loaddialogList(135695164)}>Search</button>*/}
                    <div className="sidebar j-sidebar active">
                        <div className="sidebar__inner">
                            <div className="sidebar__content">

                                <ul className="sidebar__dilog_list j-sidebar__dilog_list">
                                    {dialogList ?
                                        dialogList.map((dialog, index) => (
                                            <li className={activeId === dialog._id ? "dialog__item j-dialog__item active" : "dialog__item j-dialog__item"} id={dialog._id} data-name={dialog.name} key={index} onClick={() => loadChat(dialog._id)}>
                                                <a className="dialog__item_link">
                                                     {/* <span className={"dialog__avatar m-user__img_" + ((index % 10) + 1) + " m-type_chat"}>
                                                        <i className="icons">{parseInt(dialog.occupants_ids[0]) === loggedin_userQBId ? (getUserName(parseInt(dialog.occupants_ids[1])).toUpperCase()).substring(0, 1) : (getUserName(parseInt(dialog.occupants_ids[0])).toUpperCase()).substring(0, 1)}</i>
                                                    </span> */}
                                                     <span className={"dialog__avatar m-user__img_" + ((index % 10) + 1) + " m-type_chat"}>
                                                        <i className="icons">{((dialog.name).toUpperCase()).substring(0, 1)}</i>
                                                    </span>
                                                    {/* <span className="dialog__info">
                                                        <span className="dialog__name">{parseInt(dialog.occupants_ids[0]) === loggedin_userQBId ? getUserName(parseInt(dialog.occupants_ids[1])) : getUserName(parseInt(dialog.occupants_ids[0]))}</span>
                                                        <span className={"dialog__last_message j-dialog__last_message " + (dialog.attachment ? 'attachment' : '')}>{dialog.last_message}</span>
                                                    </span> */}
                                                    <span className="dialog__info">
                                                        <span className="dialog__name">{dialog.name}</span>
                                                        <span className={"dialog__last_message j-dialog__last_message " + (dialog.attachment ? 'attachment' : '')}>{dialog.last_message}</span>
                                                    </span>
                                                    <span className="dialog_additional_info">
                                                        <span className="dialog__last_message_date j-dialog__last_message_date">
                                                            {dialog.last_message_date_sent ? moment.unix(dialog.last_message_date_sent).clone().tz(coach_timezone).fromNow() : null}
                                                        </span>
                                                        <span className={"dialog_unread_counter j-dialog_unread_counter " + (!dialog.unread_messages_count ? 'hidden' : '')}>
                                                            {dialog.unread_messages_count ? dialog.unread_messages_count : ''}
                                                        </span>
                                                    </span>
                                                </a>
                                            </li>

                                        ))
                                        : null}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="content j-content">


                        <div className="content__inner j-content__inner">
                            <div className='dialog_title'>
                                <div id="dialogName">{dialogData.length !== 0 ? dialogData.name : ''}</div>
                                {dialogData.length !== 0 && <div><div className='dialog_menu'><i className="fa fa-ellipsis-v viewdata" onClick={dialogmenu_toggle}></i></div>
                                    <div className={dmToggle ? "dialog_menu_content true" : "dialog_menu_content false"}><a data-item={dialogData._id} onClick={delete_dialog}>Delete</a><a data-item={dialogData._id} onClick={block_delete_dialog}>Block {'&'} Delete</a></div></div>
                                }
                            </div>

                            <div className='messages' id='messages' ref={messageEl}>
                                {
                                    dialogData.length === 0 ?

                                        <div className="welcome__message">
                                            <div>Please select a chat to start messaging</div>
                                        </div>
                                        :
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={loadmore}
                                            hasMore={showMore}
                                            loader={<div className="loader" key={0}>Loading ...</div>}
                                            isReverse={true}
                                        >
                                            {messageList.map((message, index) => (
                                                <div key={index}>
                                                    {index === 0 ? (showmoreEnable === true ? <div className='showmore' onClick={setmore}>show more</div> : null) : null}
                                                    <div className="message__wrap">
                                                        <div className="dialog_date_message">
                                                            {getchatDate(message.date_sent)}
                                                        </div>                   </div>
                                                    <div className="message__wrap" id={message._id} data-status={(message.read_ids).includes(opponent_id
                                                    ) ? "read" : "delivered"} >

                                                        <div className={loggedin_userQBId === message.sender_id ? 'message__content message__right type_chat' : 'message__content type_chat'}>
                                                            <div className="message__sender_and_status">
                                                                {/* <div className="message__sender_name">
                                                                    {message.sender_id ? (loggedin_userQBId !== message.sender_id ? getUserName(message.sender_id) : 'You') : 'unknown user (' + message.sender_id + ')'}
                                                                </div> */}

                                                                <div className="message__sender_name">
                                                                    {message.sender_id ? (loggedin_userQBId !== message.sender_id ? opponent_name : 'You') : 'unknown user (' + message.sender_id + ')'}
                                                                </div>

                                                                {loggedin_userQBId === message.sender_id ?
                                                                    <img style={{ "marginRight": "6px" }} className="message__status j-message__status" src={(message.read_ids).includes(opponent_id
                                                                    ) ? readicon : deliveredicon} />
                                                                    :
                                                                    null}

                                                                <div className="message__timestamp">
                                                                    {message.date_sent ? moment.tz(moment.unix(message.date_sent), coach_timezone).format('HH:mm') : null}
                                                                </div>
                                                            </div>
                                                            <div className="message__text_and_date">
                                                                <div className={loggedin_userQBId === message.sender_id ? 'message__text_wrap you' : 'message__text_wrap'}>
                                                                    {/* <div style={{ "height": "1px", "opacity": "0" }}>
                                                                        <div className="message__sender_name">
                                                                            {getUserName(message.sender_id) ? getUserName(message.sender_id) : 'unknown user (' + message.sender_id + ')'}
                                                                            <div className="message__timestamp">
                                                                                {message.date_sent}
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div style={{ "height": "1px", "opacity": "0" }}>
                                                                        <div className="message__sender_name">
                                                                        {message.sender_id ? (loggedin_userQBId !== message.sender_id ? opponent_name : loggedin_userQBname) : 'unknown user (' + message.sender_id + ')'}
                                                                            <div className="message__timestamp">
                                                                                {message.date_sent}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {message.message !== "[attachment]" ?
                                                                        <p className={loggedin_userQBId === message.sender_id ? 'message__text you' : 'message__text'}>
                                                                            {message.message}</p>
                                                                        : null}

                                                                    {message.attachments.length ?
                                                                        <div className="message__attachments_wtap">
                                                                            {(message.attachments).map((element, index) => (
                                                                                <img loading="lazy" src={getFileURL(element.id)} data-id={element.id} alt="attachment" className="message_attachment loaded" key={index} onClick={() => load_image(element.id)}></img>
                                                                            ))}
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </InfiniteScroll>
                                }
                            </div>

                            {dialogData.length !== 0 ?
                                <div name="send_message" className="send_message" autoComplete="off">
                                    <div className="attachments_preview j-attachments_preview" style={{ display: Preview ? 'flex' : 'none' }}>
                                        <div className="attachment_preview__wrap m-loading" id="">
                                            <img className="img-close" src={closeicon} width="24px" height="24px" style={{ display: Preview ? "block" : "none" }} onClick={closePreview} />
                                            <img className="attachment_preview__item" src={Preview} />
                                        </div>
                                    </div>

                                    <div className="message__actions">

                                        <label className="attach_btn">
                                            <i className="fa fa-paperclip material-icons"></i>
                                            <input type="file" id="attach_btn" name="attach_file" className="attach_input" accept="image/*" onChange={file_select} onClick={(event) => { event.target.value = null }} />
                                        </label>

                                    </div>
                                    <button className="send_btn" onClick={sendMessage} style={{ marginTop: Preview ? "95px" : "0" }}>
                                        <img style={{ "width": "28px" }} src={sendicon} alt="send" />
                                    </button>
                                    <textarea name="message_field" className="message_field" id="message_field" placeholder="Send message…" onChange={(e) => setmessageText(e.target.value)} value={messageText} autoFocus onClick={updateStatus} onKeyDown={handleKeyDown}></textarea>
                                </div>
                                :
                                null}
                        </div>
                    </div>


                    <Modal show={show} onHide={handleClose} className='adminmessages_img_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={loadImg} alt="attachment image" style={{ maxWidth: "100%", margin: "0 auto", display: "block" }} />
                        </Modal.Body>
                        <Modal.Footer className='display_block'>
                            <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                                Back
                            </Button>

                            <Button variant="primary" className='modal_action_button float_right' onClick={() => { downloadAttachment(attachmentFileid) }}>
                                Download
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </section>



        </div>
    )
}

export default CoachMessages

import React, { useEffect, useRef, useState } from "react";
import AddGreenIcon from '../../../../src/images/plus.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns';
import Select from "react-select";

const FoodJournalListingCoach = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const [show, Setshow] = useState(false)
    const axiosJWT = axios.create();
    const handleClose = () => {
        Setshow(false)
    }
    const [JournalDate, setJournalDate] = useState(new Date());
    const [ShowAddedImage, SetShowAddedImage] = useState(false)
    const [AddedImageSrc, SetAddedImageSrc] = useState('')
    const [AddedImageName, SetAddedImageName] = useState('')
    const HiddenImageInput = useRef(null)
    const [BlogImgErr, SetBlogImgErr] = useState(false)

    const [BlogImgErrMsg, SetBlogImgErrMsg] = useState('')
    const [BlogImgfile, setBlogImgfile] = useState(null);
    const [BlogType, SetBlogType] = useState('')
    const [BlogDesc, SetBlogDesc] = useState('')


    const [BlogDateErr, SetBlogDateErr] = useState(false)
    const [BlogDateErrMsg, SetBlogDateErrMsg] = useState('')

    const [BlogTypeErr, SetBlogTypeErr] = useState(false)
    const [BlogTypeErrMsg, SetBlogTypeErrMsg] = useState('')

    const [BlogDescErr, SetBlogDescErr] = useState(false)
    const [BlogDescErrMsg, SetBlogDescErrMsg] = useState('')

    const [PopupJournalTitle, SetPopupJournalTitle] = useState('')
    const [PopupJournalButton, SetPopupJournalButton] = useState('')
    const [PopupJournalAction, SetPopupJournalAction] = useState('')
    const [JournalEditId, SetJournalEditId] = useState('')
    const [coachselectedclients, setcoachselectedclients] = useState('')
    

    const [clientoptions, setClientoptions] = useState([]);
    const [DefaultSelectedClientMembers, SetDefaultSelectedClientMembers] =
    useState([]);
    const [selectedOptionsClient, setSelectedoptionsClient] = useState([]);

    const DropdownChangeClient = (e) => {
        setSelectedoptionsClient(e);
        var ClientArray=e.map((s) => s.value);
        let clientids = ClientArray.toString();
        SetClientsString(clientids)
        setcoachselectedclients(clientids)
        GetFoodJournal(PageNumberList, SortValue,clientids)
    
    
      };


    const JournalImageChange = (event) => {
        SetBlogImgErr(false)
        SetShowAddedImage(false)
        setBlogImgfile(null)
        const file = event.target.files[0];
        setBlogImgfile(file)

        // If no image selected, return
        if (!/^image\//.test(file.type)) {
            SetBlogImgErr(true)
            SetBlogImgErrMsg(`File ${file.name} is not an image.`)
            setBlogImgfile(null)

            document.getElementById('img_upload_input_id').value = null;
            return false;
        }
        else {

            var reader = new FileReader();
            SetAddedImageName(file.name)
            reader.onload = function () {

                SetAddedImageSrc(reader.result)


            };
            reader.readAsDataURL(event.target.files[0]);

            SetShowAddedImage(true)

        }

    }
    const clearJournalImg = () => {
        SetBlogImgErr(false)
        SetShowAddedImage(false)
        document.getElementById('img_upload_input_id').value = null
        setBlogImgfile(null)

    }
    const triggerAddpopup = () => {

        SetBlogType('')
        SetBlogDesc('')
        SetShowAddedImage(false)
        setBlogImgfile(null)
        Setshow(true)
        SetBlogImgErr(false)
        SetBlogTypeErr(false)
        SetBlogDateErr(false)
        SetBlogDescErr(false)
        setJournalDate(new Date())
        SetPopupJournalTitle('Add Food Journal')
        SetPopupJournalButton('Add Entry')
        SetPopupJournalAction('add')
    }

    const AddFoodJournal = async (e) => {

        var Action = e.target.getAttribute('data-action')
        if (Action === 'add') {
            var err = false;
            var passDate = ''
            SetBlogImgErr(false)
            SetBlogTypeErr(false)
            SetBlogDateErr(false)
            SetBlogDescErr(false)
           

            if(JournalDate===null)
            {
                err = true
                SetBlogDateErr(true) 
                SetBlogDateErrMsg('Please select date')
            }

            if (JournalDate != null) {
                var yyyy = JournalDate.getFullYear().toString();
                var mm = (JournalDate.getMonth() + 1).toString();
                var dd = JournalDate.getDate().toString();
                passDate = yyyy + '-' + mm + '-' + dd

            }


            if (BlogImgfile === '' || BlogImgfile === null) {
                err = true
                SetBlogImgErr(true)
                SetBlogImgErrMsg('Please upload an image')
            }
            if (BlogType === '' || BlogType === null) {
                err = true
                SetBlogTypeErr(true)
                SetBlogTypeErrMsg('Please select a type')
            }
            if (BlogDesc === '' || BlogDesc === null) {
                err = true
                SetBlogDescErr(true)
                SetBlogDescErrMsg('Please enter a description')
            }
            else if (BlogDesc !== null && BlogDesc !== '') {
                if (BlogDesc.length > 300) {
                    err = true
                    SetBlogDescErr(true)
                    SetBlogDescErrMsg('Please enter a description max of 300 letters')

                }
            }
            if (err === false) {
                const formData = new FormData();
                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('journal_img', BlogImgfile);
                const response = await axiosJWT.post("/food-journal/add-new",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                }


            }

        }
        else if (Action === 'edit') {


            var err = false;
            var passDate = ''
            SetBlogImgErr(false)
            SetBlogTypeErr(false)
            SetBlogDateErr(false)
            SetBlogDescErr(false)
            if(JournalDate===null)
            {
                err = true
                SetBlogDateErr(true) 
                SetBlogDateErrMsg('Please select date')
            }
            if (JournalDate != null) {
                var yyyy = JournalDate.getFullYear().toString();
                var mm = (JournalDate.getMonth() + 1).toString();
                var dd = JournalDate.getDate().toString();
                passDate = yyyy + '-' + mm + '-' + dd

            }

            if (BlogType === '' || BlogType === null) {
                err = true
                SetBlogTypeErr(true)
                SetBlogTypeErrMsg('Please select a type')
            }
            if (BlogDesc === '' || BlogDesc === null) {
                err = true
                SetBlogDescErr(true)
                SetBlogDescErrMsg('Please enter a description')
            }
            else if (BlogDesc !== null && BlogDesc !== '') {
                if (BlogDesc.length > 300) {
                    err = true
                    SetBlogDescErr(true)
                    SetBlogDescErrMsg('Please enter a description max of 300 letters')

                }
            }
            if (ShowAddedImage === false) {
                err = true
                SetBlogImgErr(true)
                SetBlogImgErrMsg('Please upload an image')
            }

            if (err === false && BlogImgfile === null) {
                const formData = new FormData();
                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('id', JournalEditId);


                const response = await axiosJWT.post("/food-journal/edit",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                }


            }
            else if (err === false && BlogImgfile !== null) {

                const formData = new FormData();

                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('id', JournalEditId);
                formData.append('journal_img', BlogImgfile);


                const response = await axiosJWT.post("/food-journal/edit",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                }

            }


        }


    }

    const [GetJournalList, SetGetJournalList] = useState([])
    const [JournalTotal, SetJournalTotal] = useState(0)
    const [PerPage, SetPerPage] = useState(10)
    const [PageNumberList, SetPageNumberList] = useState(1)
    const [SortValue, SetSortValue] = useState('DESC')
    const [DeleteJournalId, SetDeleteJournalId] = useState('')
    const [ClientsString,SetClientsString]=useState('')

    const GetFoodJournal = async (pagenumber, sort,clients='') => {
        SetPageNumberList(pagenumber)
        SetSortValue(sort)
        const response = await axiosJWT.get('/food-journal/my-clients?offset=' + pagenumber + '&limit=' + PerPage + '&sort=' + sort + '&clients='+clients+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        SetGetJournalList(response.data.list)
        SetJournalTotal(response.data.count)


    }

    const changePageNumberS = (pagenumber) => {
        GetFoodJournal(pagenumber, SortValue,ClientsString)


    }
    const SortList = (e) => {

        GetFoodJournal(1, e.target.value,ClientsString)
    }
    const Renderhtmltostring = (content,limit) => {

        var Convertedstring = content.replace(/<[^>]*>?/gm, '');

        if (Convertedstring.length < limit) {
            return Convertedstring
        }
        else {
            return Convertedstring.substring(0, limit-3) + '...'
        }


    }

    const [LoggedUserId, SetLoggedUserId] = useState('')

    const GetProfileDetails = async () => {
        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetLoggedUserId(response.data.profile_details[0].id);
        //console.log(response)
    }

    const DeleteJournal = async (e) => {




        const response = await axiosJWT.post('food-journal/delete',
            {

                'id': DeleteJournalId


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            GetFoodJournal(PageNumberList, SortValue)
            Setshowd(false)
        }


    }


    const [showd, Setshowd] = useState(false)
    const handleClosed = () => {
        Setshowd(false)
    }
    const TriggerDeleteJournal = (e) => {
        var DeleteId = e.target.getAttribute('data-id')
        SetDeleteJournalId(DeleteId)
        Setshowd(true)
    }

    const TriggerEditJournal = async (e) => {
        handleCloseDetail()
        SetBlogImgErr(false)
        SetBlogTypeErr(false)
        SetBlogDateErr(false)
        SetBlogDescErr(false)
        var EditID = e.target.getAttribute('data-id')
        SetJournalEditId(EditID)
        setBlogImgfile(null)
        const response = await axiosJWT.get('/food-journal/detail?id=' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.result !== null) {
            Setshow(true)
            SetAddedImageName(response.data.result.journal_img)
            SetAddedImageSrc( response.data.result.journal_img)
            SetShowAddedImage(true)
            setJournalDate(new Date(response.data.result.journal_date))
            SetBlogType(response.data.result.type)
            SetBlogDesc(response.data.result.desc)
            SetPopupJournalTitle('Edit Food Journal')
            SetPopupJournalButton('Edit Entry')
            SetPopupJournalAction('edit')

        }



    }


    const [AddComment, SetAddComment] = useState('')

    const TriggerAddComment = async (e) => {
        var JournalId = e.target.getAttribute('data-id')

        const elements = document.querySelectorAll('.add_input_comment_div[data-id="' + JournalId + '"]');
        if (elements[0].value) {
            const response = await axiosJWT.post('food-journal/add-comment',
                {

                    'id': JournalId,
                    'comment': elements[0].value

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.status === 200) {
                elements[0].value = ''
                GetFoodJournal(PageNumberList, SortValue,ClientsString)
            }



        }


    }


    const [showDetail, SetshowDetail] = useState(false)
    const [DetailJournalType, SetDetailJournalType] = useState('')
    const [DetailJournalDate, SetDetailJournalDate] = useState(null)
    const [DetailJournalSrc, SetDetailJournalSrc] = useState('')
    const [DetailJournalDesc, SetDetailJournalDesc] = useState('')
    const [DetailJournalId, SetDetailJournalId] = useState('')
    const [DetailJournalOwner, SetDetailJournalOwner] = useState('')
    const [DetailJournalIngredients,SetDetailJournalIngredients]=useState([])

    const handleCloseDetail = () => {
        SetshowDetail(false)
    }
    const viewDetail = async (e) => {
        var JournalId = e.target.getAttribute('data-id')
        SetDetailJournalId(JournalId)
        GetBlogComments(JournalId)
        const response = await axiosJWT.get('/food-journal/detail?id=' + JournalId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {

            SetshowDetail(true)
            SetDetailJournalType(response.data.result.type)
            SetDetailJournalDate(response.data.result.journal_date)
            SetDetailJournalSrc( response.data.result.journal_img)
            SetDetailJournalDesc(response.data.result.desc)
            SetDetailJournalOwner(response.data.result.user_id)
            SetDetailJournalIngredients(JSON.parse(response.data.result.ingredients))
        }

    }

    const [CommentsList, SetCommentsList] = useState([])

    const GetBlogComments = async (CommentsJournalId) => {
        SetCommentsList([])

        const response = await axiosJWT.get('/food-journal/get-comments?id=' + CommentsJournalId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data.Comments != null) {
            SetCommentsList(response.data.Comments)
        }

    }

    const DeleteComment = async (e) => {
        const CommentId = e.target.getAttribute('data-id')

        const response = await axiosJWT.post('food-journal/delete-comment',
            {

                'id': CommentId

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            GetBlogComments(DetailJournalId)
            GetFoodJournal(PageNumberList, SortValue)
        }


    }

    const TriggerEditComment=async(e)=>{
        const CommentId = e.target.getAttribute('data-id')
        SetEditCommentId(CommentId)
        const response = await axiosJWT.get('/food-journal/comment-detail?id=' + CommentId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.CommentInfo!==null)
        {
            SetCommentValue(response.data.CommentInfo.comments)
            SetEditCommentJournal(response.data.CommentInfo.journal_id)
            Setshowed(true)
        }


    }

    const EditComment=async()=>{

       if(CommentValue!=='')
       {

        const response = await axiosJWT.post('food-journal/edit-comment',
        {

            'id': EditCommentId,
            'comment':CommentValue

        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.status===200)
        {
            GetBlogComments(EditCommentJournal)
            Setshowed(false)
        }


       }

       
        

    }

    const [showed,Setshowed]=useState(false)
    const [CommentValue,SetCommentValue]=useState('')
    const [EditCommentId,SetEditCommentId]=useState('')
    const [EditCommentJournal,SetEditCommentJournal]=useState('')
    const handleCloseed=()=>{
        Setshowed(false)
    }


const GetMyClients=async()=>{
    var ClientObj=[]
    try{

        const AllClients = await axiosJWT.get("/allclient", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllClients.status === 200) {
          if (AllClients.data.Users !== null) {
            AllClients.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              obj["client_name"]= data.first_name +" "+ last_name;
              ClientObj.push(obj);
            });
          }
        }
        setClientoptions(ClientObj);
  
      }catch(err)
      {
     console.log(err)
      }
}

    useEffect(() => {
        document.title = 'Client-Food journal';
        GetFoodJournal(1, 'DESC')
        GetProfileDetails()
        GetMyClients()
    }, [])

    

const clientId = coachselectedclients;
const clientIdArray = clientId.split(",");
const clientName = "";




    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <div className="container">
                <section className="coach_food_journal dashboard add_food_journal">
                   <div className="coach_food_journal_outerwrap">
                   <div className="coach_food_journal_leftwrap">
                    <div className="coach_food_journal_top_nav">
                    <div className="add_food_journal_top_head">
                        <span>Food Journal</span>
                        <select onChange={(e) => { SortList(e) }}>
                            <option value="DESC">Sort By (DESC)</option>
                            <option value="ASC">Sort By (ASC)</option>
                        </select>
                    </div>
                    

                    <div className="select_members" style={{marginTop:'10px',marginBottom:'10px'}}>
            <label className="label">Select Clients</label>
            <Select
              options={clientoptions}
              isMulti
              onChange={(e) => {
                DropdownChangeClient(e);
              }}
              defaultValue={DefaultSelectedClientMembers}
        
            />
          </div>
          </div>
                  

                    <div className="food_journal_list_div">


                        {GetJournalList.length > 0 && GetJournalList.map((Journals, index) => (
                             <div className="list_col">
                                {
                                    LoggedUserId === Journals.user_id &&
                                    <i className="fa fa-edit pointer_eff" data-id={Journals.id} onClick={(e) => { TriggerEditJournal(e) }}></i>
                                }
                                {
                                    LoggedUserId === Journals.user_id &&
                                    <i className="fa fa-trash pointer_eff" data-id={Journals.id} onClick={(e) => { TriggerDeleteJournal(e) }}></i>
                                }

                                <div className="img_food_jour">
                                    <img src={Journals.journal_img}></img>
                                </div>
                                <div className="food_journal_details">
                                   <div className="coach_food_journal_topwrap">
                                    <div className="coach_food_journal_topleft">
                                     {/* <p className="client_name desc_head">{Journals.user_id}</p> */}
                                  
                                     <p className="date">@{format(parseISO(Journals.journal_date), `PP`)}</p>
                                    </div>
                                    <div className="coach_food_journal_topright">
                                    <p><i className="fa fa-cutlery"></i><span className="type">{Journals.type}</span></p>
                                    </div>
                                   </div>
                                    <p className="desc">{Renderhtmltostring(Journals.desc,300)}</p>
                                    <p className="comments">Comments({Journals.comment_count})
                                        <span data-id={Journals.id} onClick={(e) => { viewDetail(e) }}>Click here to view details</span>
                                    </p>
                                </div>
                                <div className="add_input_div">
                                    <input type={'text'} placeholder={'Add comment'} className="add_input_comment_div" data-id={Journals.id} />
                                    <i className="fa fa-paper-plane pointer_eff" data-id={Journals.id} onClick={(e) => { TriggerAddComment(e) }}  ></i>

                                </div>


                            </div>
                        ))}




                    </div>
                    </div>
                    <div className="coach_food_journal_rightwrap">
                    <div className="food_journal_right_outerwrap">
                    <ol className="coach_selected_client">   
                            
                                {clientIdArray.map((clientId) => {
                                    const client = clientoptions.find((c) => c.value === parseInt(clientId));
                                    const clientName = client ? client.client_name : 'Client not found';
                                    if (client) {
                                    return (
                                        
                                        <li className="clientName" key={client.value}>
                                        {client.client_name}
                                        <span className="selected_client_email" title={client.label}>{client.label}</span>
                                        </li>
                                       
                                    );
                                    } else {
                                    return (
                                        <p className="clientNameNotfound">Select Your client</p>
                                    );
                                    }
                                })}
                      </ol>
                    </div>

                    </div>
                    </div>

                    



                    <Modal show={show} onHide={handleClose} className='coach_blog_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{PopupJournalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="add_food_journal_popup">

                            <div className="add_image_plus">
                                <img src={AddGreenIcon} onClick={() => { HiddenImageInput.current.click() }} />
                                <input type={'file'} style={{ display: 'none' }} className="" id="img_upload_input_id" ref={HiddenImageInput} onChange={(e) => { JournalImageChange(e) }} />
                                <p>Click Here to Add Image</p>
                                {
                                    BlogImgErr === true &&
                                    <span className="err">{BlogImgErrMsg}</span>
                                }

                            </div>
                            {
                                ShowAddedImage === true &&
                                <div className="image_list">
                                    <img src={AddedImageSrc} />
                                    <span>{Renderhtmltostring(AddedImageName,30)}</span>
                                    <i className="fa fa-trash pointer_eff" onClick={clearJournalImg}></i>
                                </div>
                            }

                            <div className="date_meal_row row">
                                <div className="date">
                                    <label>Date</label>
                                    <Datepicker
                                        value={JournalDate}
                                        onChange={setJournalDate}
                                        format="MM-dd-yyyy"
                                        
                                         />
                                    {
                                        BlogDateErr === true &&
                                        <span className="err">{BlogDateErrMsg}</span>
                                    }
                                </div>
                                <div className="meal_type">
                                    <label>Meal Type</label>
                                    <select onChange={(e) => { SetBlogType(e.target.value) }} defaultValue={BlogType} {...(BlogTypeErr === true ? { id: 'err_border' } : {})} className="meal_type_drop">
                                        <option value="">Select type</option>
                                        <option value="Breakfast">Breakfast</option>
                                        <option value="Lunch">Lunch</option>
                                        <option value="Dinner">Dinner</option>
                                        <option value="Snacks">Snacks</option>
                                    </select>
                                    {
                                        BlogTypeErr === true &&
                                        <span className="err">{BlogTypeErrMsg}</span>
                                    }
                                </div>
                            </div>
                            <div className="desc_row">
                                <label>Description</label>
                                <textarea rows="8" className="food_journal_textarea" placeholder="Write a description" onChange={(e) => { SetBlogDesc(e.target.value) }} defaultValue={BlogDesc} {...(BlogDescErr === true ? { id: 'err_border' } : {})}></textarea>
                                {
                                    BlogDescErr === true &&
                                    <span className="err">{BlogDescErrMsg}</span>
                                }
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='display_block'>
                            <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                                Cancel
                            </Button>

                            <Button variant="primary" className='modal_action_button float_right' data-action={PopupJournalAction} onClick={(e) => { AddFoodJournal(e) }} >
                                {PopupJournalButton}
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showd} onHide={handleClosed}>
                        <Modal.Header>
                            <Modal.Title>Delete Food Journal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are You sure want to delete this food journal</Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleClosed}>
                                Close
                            </Button>
                            <Button variant="primary" className="modal_action_button float_right" onClick={(e) => { DeleteJournal(e) }}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showDetail} onHide={handleCloseDetail} className='coach_blog_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{'Food Journal'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="food_journal_detail">

                            <div className="food_journal_detail_top_row">
                                <span className="type">{DetailJournalType}</span>
                                {
                                    DetailJournalDate!==null &&
                                    <span className="date">{format(parseISO(DetailJournalDate), `PP`)}</span>
                                }
                              

                                {
                                    DetailJournalOwner===LoggedUserId && 
                                    <i className="fa fa-edit pointer_eff edit_icon" data-id={DetailJournalId}onClick={(e) => { TriggerEditJournal(e) }}></i>
                                }
                               
                            </div>

                            <div className="journal_detail_comment_row">

                                <div className="detail">
                                    <img src={DetailJournalSrc} />

                                    <p className="desc">{DetailJournalDesc}</p>

                                </div>
                            </div>
                            <div>
                  {
    DetailJournalIngredients.length>0 && 
    <div className="nutrition_info_food_journal added_ingredient">
<div className="heading">Recipes</div>

{DetailJournalIngredients.length > 0 &&
                          DetailJournalIngredients.map((type, index) => (
                            <li>{type.ingredient_name} ({type.servings}) ({type.calories} Kcal)</li>
                          ))}

</div>
}
                  </div>
                            <div className="journal_comment_row">
                            {CommentsList.length > 0 &&  <div className="comment_label">Comments:</div>}
                               
                                <div className="comments_list_row">
                                    {CommentsList.length > 0 && CommentsList.map((comments, index) => (
                                        <div className="comment_list">
                                            <p className="comment_info">
                                                {LoggedUserId === comments.user_id && <span>You</span>}
                                                {LoggedUserId !== comments.user_id && <span>{comments.user.first_name + ' ' + comments.user.last_name}</span>}


                                                {LoggedUserId === comments.user_id &&
                                                    <i className="fa fa-trash info" data-id={comments.id} aria-hidden="true" onClick={(e) => { DeleteComment(e) }}></i>
                                                }
                                                {LoggedUserId === comments.user_id &&
                                                    <i className="fa fa-edit info" data-id={comments.id} aria-hidden="true" onClick={(e)=>{TriggerEditComment(e)}} ></i>
                                                }

                                                <span className="date">{format(parseISO(comments.createdAt), `PP`)}</span>

                                            </p>
                                            <p> {comments.comments}</p>
                                        </div>

                                    ))}
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='display_block'>


                            <Button variant="primary" className='modal_action_button float_right' onClick={handleCloseDetail} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showed} onHide={handleCloseed}>
                        <Modal.Header>
                            <Modal.Title>Edit Comment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="edit_journal_comment">
                            {/* <input type={'text'} defaultValue={CommentValue} onChange={(e)=>{SetCommentValue(e.target.value)}} className="add_input_comment_div" /> */}

                            <textarea   type={'text'} defaultValue={CommentValue} onChange={(e)=>{SetCommentValue(e.target.value)}} className="add_input_comment_div"></textarea>

                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleCloseed}>
                                Close
                            </Button>   
                            <Button variant="primary" className="modal_action_button float_right" onClick={(e) => { EditComment(e) }}>
                                Edit
                            </Button>
                        </Modal.Footer>
                    </Modal>





                </section>

            </div>
        </div>
    )
}

export default FoodJournalListingCoach
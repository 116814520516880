import { React ,createRef, useState,useRef, useEffect   } from 'react';
// RCE CSS
import 'react-chat-elements/dist/main.css'
// MessageBox component
import { MessageBox } from 'react-chat-elements'
import { MessageList,Input,Button   } from 'react-chat-elements'
import axios from 'axios';
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";



function ClientChatBeluga(props) {

    console.log('VisitDetailsData',props.VisitDetailsData)
	const token = localStorage.getItem("token");
	const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
	const axiosJWT = axios.create();
	const InputFileRef = useRef(null);
	const [loader,Setloader]=useState(false)

    const messageListReferance = createRef();
    const inputReferance = createRef();
    const [inputValue,SetinputValue]=useState('')
	const [VisitLogsChat,SetVisitLogsChat]=useState([])
	const [MsgErr,SetMsgErr]=useState('')

    const CheckInput=(e)=>{
        SetinputValue(e.target.value)
     

    }

	const SendMsgText=async()=>{


		if(props.VisitDetailsData.visit_status!=='cancelled')

		{

			SetMsgErr('')
	

			if(inputValue!=='' && inputValue!==null)
			{
				try{
					Setloader(true)
					const SendBlugaReponse=await axiosJWT.post('beluga/send-client-chat', {
					
						type: 'text',
						msg: inputValue,
						visitId:props.VisitDetailsData.visitId,
						masterId:props.VisitDetailsData.masterId,
						client_id:props.VisitDetailsData.client_id,
						sent_by:'client'
					},{
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
	
					console.log('SendBlugaReponse',SendBlugaReponse)
					SetinputValue('')
					GetVisitChatLog()
					Setloader(false)
				}catch(err)
				{
					Setloader(false)
				console.log(err)
				}
			
	
	   
			}
		
		}
		else{

			props.toast.error('You cannot send messages for the cancelled visit', {
				toastId: 'error1',
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			  
				});

		}

		

	
	}

	const handleFileChange = async (event) => {


		if(props.VisitDetailsData.visit_status!=='cancelled')
		{

			SetMsgErr('')
			const files = event.target.files;
		
			// Validating file type and size
			const allowedTypes = ['image/jpeg']; // Allowed image types
			const maxSize = 3 * 1024 * 1024; // 3 MB in bytes
		
			if (files.length === 0) {
				SetMsgErr('No files selected')
				return;
			}
		
			const file = files[0];
			if (!allowedTypes.includes(file.type)) {
				// File type not allowed
				SetMsgErr('Please select a valid image file (JPEG)')
				
				return;
			}
		
			if (file.size > maxSize) {
				// File size exceeds the limit
				SetMsgErr('File size exceeds the limit of 3 MB')
		
				return;
			}
		
			// Proceed with file upload
			var formData = new FormData();
			var base64File=''

			const result =await fileToBase64(file)
			console.log('sssss',result)
			

		
		
			formData.append('type', 'media')
			formData.append('file', result)
			formData.append('visitId', props.VisitDetailsData.visitId)
			formData.append('masterId', props.VisitDetailsData.masterId)
			formData.append('client_id', props.VisitDetailsData.client_id)
			formData.append('sent_by', 'client');
		
			try {
				Setloader(true);
				const UploadResponse = await axios.post("/beluga/send-client-media", formData, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
		
				console.log('UploadResponse', UploadResponse);
				Setloader(false);
				InputFileRef.current.value = '';
				GetVisitChatLog();
		
			} catch (err) {
				Setloader(false);
				SetMsgErr('Message failed to send!')
				console.log(err);
			}
		
			console.log(files);

		}
		else{

			props.toast.error('You cannot send media files for the cancelled visit', {
				toastId: 'error1',
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			  
				});

		}

	
	};

	 const fileToBase64 = async (fileIn) => {
		return new Promise(resolve => {
		  var file = fileIn
		  var reader = new FileReader();
		  // Read file content on file loaded event
		  reader.onload = function(event) {
			resolve(event.target.result);
		  };
		  
		  // Convert data to base64 
		  reader.readAsDataURL(file);
		});
	  };

	// const handleFileChange = async(event) => {
	// 	const files = event.target.files;
		
	// 	var formData = new FormData();

	// 	formData.append('type','media')
	// 	formData.append('file',files[0])
	// 	formData.append('visitId',props.VisitDetailsData.visitId)
	// 	formData.append('masterId',props.VisitDetailsData.masterId)
	// 	formData.append('client_id',props.VisitDetailsData.client_id)
	// 	formData.append('sent_by','client');

	// 	try{
	// 		Setloader(true)
	// 		const UploadResponse= await axios.post("/beluga/send-client-media", formData, {
    
	// 			headers: {
	// 				Authorization: `Bearer ${token}`,
	// 			  },
	// 		  },
	// 		  );

	// 		  console.log('UploadResponse',UploadResponse)
	// 		  Setloader(false)
	// 		  InputFileRef.current.value = '';
	// 		  GetVisitChatLog()

	// 	}catch(err)
	// 	{
	// 	Setloader(false)
    //      console.log(err)
	// 	}




	// 	console.log(files);
	
	//   };

	  const GetVisitChatLog=async()=>{
    
		try{
            Setloader(true)
			const GetResponse = await axiosJWT.get(
				"beluga/get-all-visit-client-chat?visitId="+props.VisitDetailsData.visitId+"",
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				}
			  );

			  Setloader(false)

			var VisitLogArray=[];
			

			  if(GetResponse.data){
               var DataArray=GetResponse.data
			

			   DataArray.forEach(element => {

				var Object={};
			
				var ConvertedDate=format(
					parseISO(
					  timetoUtcClientAppointment(
						element.sent_date
					  )
					),
					`MMM dd, yyyy hh:mm:ss a`
				  )

				Object.date=ConvertedDate
				
				Object.title=(element.first_name!==null ? element.first_name:'')+' '+(element.last_name!==null ?element.last_name:'' );

				if(element.sent_by==='client')
				{
					Object.position='right';
					
				}
				if(element.sent_by==='doctor')
				{
					Object.position='left';
					
				}
				if(element.type==='text')
				{
					Object.type='text';
					Object.text=element.content;
				}
				if(element.type==='media')
				{
					Object.type='photo';
					Object.data={};
                    Object.data.uri=element.content;
					Object.data.status={
						            click: true,
						            loading: 0,
						         }

				}

			
				VisitLogArray.push(Object)
			   });



			  }
	
			  SetVisitLogsChat(VisitLogArray)
	

		}catch(err)
		{
			Setloader(false)
			console.log(err)
		}

	
	  }
	  useEffect(()=>{
		GetVisitChatLog()
	  },[])

	  function timetoUtcClientAppointment(passdate) {
		var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");
	
		var cutoffString = pushstartime; // in utc
		var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
		var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);
	
		var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
	
		return stardates;
	  }
  return (

	<>
	
    <div>
	{loader === true && <div id="semiTransparenDivLoader"></div>}
        
        <MessageList
	referance={messageListReferance}
	className='message-list'
	lockable={true}
	toBottomHeight={'100%'}
	dataSource={VisitLogsChat} />

    <Input
  referance={inputReferance}
  placeholder='Type here...'
  onChange={CheckInput}
  value={inputValue}
  rightButtons={<> <i class="fa fa-upload beluga_upload_media" onClick={()=>{InputFileRef.current.click()}}></i><Button color='white' onClick={SendMsgText} backgroundColor='black' text='Send' /></>}
/>

<input type='file' accept="image/*" onChange={handleFileChange}  className='beluga_upload_media_input' ref={InputFileRef} name='file_upload'/>
    
    </div>
	<div className='beluga_input err'>{MsgErr}</div>
	</>
  )
}

export default ClientChatBeluga
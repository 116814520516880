import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import female_icon from "../../../images/female.png";
import male_icon from "../../../images/male.png";
const CoachClients = (props) => {
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [CoachClientLists,SetCoachClientLists]=useState([])

  const GetListOfClients = async (NutritionId) => {
    var response = await axiosJWT.get(
      "/nutrition/allclient?id=" + NutritionId + "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(response.data!==null)
    {
        SetCoachClientLists(response.data)  
    }

    console.log(response.data)
  
  };

  useEffect(() => {
    if (props.ClientId) {
      GetListOfClients(props.ClientId);
    }
  }, [props]);


  

  const navigateclientdashboard=(e)=>{
    const clientId = e.target.closest(".navigateclientdashboard").getAttribute("data-id");
    props.NagivateClient(clientId)

  }

  return (
    <div>
      <Table className="table is-striped is-fullwidth upcoming_appointment_table">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Name</Th>
            <Th scope="col">Email</Th>
            <Th scope="col">Actions</Th>
          </Tr>
        </Thead>
        <Tbody className="tbody manager_appointment_list_admin directory_lists_table">

        {CoachClientLists.map((list, index) => (
          <Tr className="trow navigateclientdashboard" data-id={list.id} onClick={(e)=>{navigateclientdashboard(e)}}>
            <Td>
              <img
                className="table_profile_img"
                src={list.user_profile_photo ?  list.user_profile_photo : (list.gender === 'MALE' ? male_icon : female_icon)}
              />
              {list.first_name+' '+list.last_name}
            </Td>
            <Td>{list.email}</Td>
            <Td>View</Td>
          </Tr>
        ))}
        </Tbody>
      </Table>
    </div>
  );
};
export default CoachClients;

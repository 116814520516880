import React, { useEffect, useState } from "react";
import axios from "axios";
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import { useNavigate, Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DummyImg from '../../../../src/images/dummy user.png'


const AvailableCoach = () => {

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const ClientId = localStorage.getItem("userId");
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
  const navigate = useNavigate();

  const axiosJWT = axios.create();
  const [Pagenumber, SetPagenumber] = useState(0);
  const [PerPage, SetPerPage] = useState(10);
  const [TotalRecordCoach, SetTotalRecordCoach] = useState(0);
  const [AvailableCoachList, SetAvailableCoachList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    SetSelectedMeetingSlotsDuration('')
    setShow(false)
  };
  const [showPack, setShowPack] = useState(false);
  const handleClosePack = () => setShowPack(false);
  const [SavebillingButtonLabel, SetSavebillingButtonLabel] = useState('Book appointment');
  const [ActiveServiceList, SetActiveServiceList] = useState([]);
  const [SelectedServiceId, SetSelectedServiceId] = useState('');
  const [SelectedDate, SetSelectedDate] = useState('');
  const [serviceAmount, SetserviceAmount] = useState(0);
  const [SelectedSlot, SetSelectedSlot] = useState('');
  const [SelectedMeetingDescription, SetSelectedMeetingDescription] = useState('');
  const [startDate, setStartDate] = useState();
  const [MinDate, SetMinDate] = useState(new Date());
  const [SlotsAvailable, SetSlotsAvailable] = useState([]);
  const [SlotsUTCAvailable, SetSlotsUTCAvailable] = useState([]);
  const [SelectedNutritionId, SetSelectedNutritionId] = useState('');
  const [ListofServices, SetListOfservices] = useState([]);
  const [AssignNutritionId, SetAssignNutritionId] = useState('');
  const [ShowSuccess, SetShowSuccess] = useState(false);
  const HandleCloseSuccess = () => { SetShowSuccess(false) }

  const [ShowSuccessbook, SetShowSuccessbook] = useState(false);
  const HandleCloseSuccessbook = () => { SetShowSuccessbook(false) }
  const HandleShowSuccessbook = () => { SetShowSuccessbook(true) }

  const [ShowSuccessbookexpired, SetShowSuccessbookexpired] = useState(false);
  const HandleCloseSuccessbookexpired = () => { SetShowSuccessbookexpired(false) }
  const HandleShowSuccessbookexpired = () => { SetShowSuccessbookexpired(true) }
  const [ShowSuccessbookexpiredMsg, SetShowSuccessbookexpiredMsg] = useState('');

  const [ShowSuccessbookalert, SetShowSuccessbookalert] = useState(false);
  const HandleCloseSuccessbookalert = () => { SetShowSuccessbookalert(false) }
  const HandleShowSuccessbookalert = () => { SetShowSuccessbookalert(true) }

  const [ShowSuccessbookcancel, SetShowSuccessbookcancel] = useState(false);
  const HandleCloseSuccessbookcancel = () => { SetShowSuccessbookcancel(false) }
  const HandleShowSuccessbookcancel = () => { SetShowSuccessbookcancel(true) }

  const [ShowSuccessbookcancelsuccess, SetShowSuccessbookcancelsucess] = useState(false);
  const HandleCloseSuccessbookcancelsucess = () => { SetShowSuccessbookcancelsucess(false) }
  const HandleShowSuccessbookcancelsuccess = () => { SetShowSuccessbookcancelsucess(true) }


  const [MeetingCancelId, SetMeetingCancelId] = useState();

  const HandleShowSuccess = () => { SetShowSuccess(true) }
  const [CoachRelationArray, SetCoachRelationArray] = useState([]);
  const [CurrentServiceName, SetCurrentServiceName] = useState('NIL');
  const [CurrentServiceHours, SetCurrentServiceHours] = useState(0);
  const [CurrentServiceExpiryDate, SetCurrentServiceExpiryDate] = useState('');
  const [ClientTimeZonePass, SetClientTimeZone] = useState('');

  const [ClientUpcomingAppointmentList, SetClientUpcomingAppointmentList] = useState([]);
  const [ClientRescheduledAppointmentList, SetClientRescheduledAppointment] = useState([]);

  const [SubscribedList, SetSubscribedList] = useState([]);
  const [SelectedType, SetSelectedType] = useState('');
  const [MeetingSource, SetMeetingSource] = useState('')
  const [RescheduleMeetingID, SetRescheduleMeetingID] = useState('');
  const [MeetingSlotsDuration, SetMeetingSlotsDuration] = useState([]);
  const [SelectedMeetingSlotsDuration, SetSelectedMeetingSlotsDuration] = useState('')

  const [ShowReschedule, SetShowReschedule] = useState(false);

  const HandleCloseShowReschedule = () => {
    SetShowReschedule(false);
    SetSelectedNutritionId('');
    SetRescheduleMeetingID('');
    setStartDate('');
    SetSelectedMeetingSlotsDuration('')
  }

  const GetAllCoach = async (pagenumber) => {

    SetPagenumber(pagenumber + 1);

    const response = await axiosJWT.get('/service-plan/client/get-coach-list?offset=' + pagenumber + '&limit=' + PerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {

      // console.log('res')
      // console.log(response)

      SetCoachRelationArray(response.data.CoachClientrealtion);

      SetTotalRecordCoach(response.data.UsersCount);
      SetAvailableCoachList(response.data.Users);
    });
  }

  const GetAllsubscribedCoach = async () => {
    const response = axiosJWT.get('/service-plan/client/get-subscribed-coach-list', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {

      // var CoachUsers = response.data.Users;
      // var ArrayPush = [];

      // if (response.data.CoachClientrealtion !== null) {

      //   response.data.CoachClientrealtion.forEach((value, index, array) => {

      //     var nutritionist_id = value.nutritionist_id;
      //     var found = CoachUsers.filter(e => e.id === nutritionist_id);

      //     if (found.length > 0) {
      //       found[0].allowedhours = value.allowed_hours;
      //       ArrayPush.push(found[0]);
      //     }

      //     SetSubscribedList(ArrayPush);
      //   });
      // }

      SetSubscribedList(response.data.Users);
    });
  }

  const changePageNumberS = (pagenumber) => {
    GetAllCoach(pagenumber - 1);
  }

  const TriggerPopup = async (e) => {


    SetServicesShowPopupTab(false)
    SetMeetingSourceErr(false)
    SetActiveServiceList([]);
    SetSlotsAvailable([]);
    SetSlotsUTCAvailable([]);
    setStartDate('');
    SetSelectedSlot('');
    SetSelectedMeetingDescription('');
    const UserId = e.target.getAttribute('data-user_id');
    SetSelectedNutritionId(UserId);
    GetCoachDetails(UserId)

    const ServiceDetailsRespose = await axiosJWT.get('/service-plan/client/get-assigned-service-plan?nutritionist_id=' + UserId + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (ServiceDetailsRespose) => {

      if (ServiceDetailsRespose.data.CurrentServiceDetails !== null && ServiceDetailsRespose.data.CurrentServiceDetails !== '') {

        if (ServiceDetailsRespose.data.CurrentServiceDetails.nutritionist_service !== null && ServiceDetailsRespose.data.CurrentServiceDetails.nutritionist_service !== '') {



          if (parseFloat(ServiceDetailsRespose.data.CurrentServiceDetails.allowed_hours) >= 15) {

            SetBasePlanHoursAllowed(0)
            SetBasePlanHours(false)


            SetSelectedServiceId(ServiceDetailsRespose.data.CurrentServiceDetails.nutritionist_service.id);

            SetCurrentServiceName(ServiceDetailsRespose.data.CurrentServiceDetails.nutritionist_service.service_name);

            SetCurrentServiceHours(ServiceDetailsRespose.data.CurrentServiceDetails.allowed_hours);

            const date = new Date(ServiceDetailsRespose.data.CurrentServiceDetails.expires_on.toString());

            var convertedDate = format(new Date(ServiceDetailsRespose.data.CurrentServiceDetails.expires_on.toString()), `PP`);
            SetCurrentServiceExpiryDate(convertedDate);

          }
          else {

            CheckBasePlanHours()

          }


        }
      }
      setShow(true);
    });
  }

  const [MeetingSlotsDurationErr, SetMeetingSlotsDurationErr] = useState(false)
  const SelectedMeetingSlotsDurationfn = (value) => {

    SetSlotsAvailable([]);
    SetSlotsUTCAvailable([]);
    SetSelectedSlot('');
    setStartDate('')
    SetSelectedMeetingSlotsDuration(value)

    console.log(value)


  }
  const ChangedDate = async (date) => {



    SetMeetingSlotsDurationErr(false)

    if (SelectedMeetingSlotsDuration === null || SelectedMeetingSlotsDuration === '') {
      SetMeetingSlotsDurationErr(true)
      SetSlotsAvailable([]);
      SetSlotsUTCAvailable([]);
      SetSelectedSlot('');

    }
    else {
      setStartDate(date);
      ShowLoader();
      var PassingDate = moment(date).format('YYYY-MM-DD');
      const GetSlots = await axiosJWT.get('/appointment/get-available-slots-coach?coach_id=' + SelectedNutritionId + '&date=' + PassingDate + '&duration=' + SelectedMeetingSlotsDuration + '&client_id=' + ClientId + '')

      if (GetSlots.data) {
        SetSlotsAvailable(GetSlots.data.SlotsReturn)
        SetClientTimeZone(GetSlots.data.ClientTimeZone)
      }
      // SetClientTimeZone(ClientTimeZone);
      HideLoader()
      return false




    }


  }

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(formatDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function getNextDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + 1);

    return previous;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const SlotsSelection = (e) => {
    [].forEach.call(document.querySelectorAll('.slots_list_by_client'), function (el) {
      el.classList.remove("clicked_slot_active");
    });

    e.target.classList.add("clicked_slot_active");

    SetSelectedSlot(e.target.getAttribute('data-slot'));
  }

  const [MeetingSourceErr, SetMeetingSourceErr] = useState(false)
  const [MeetingSourceErrMsg, SetMeetingSourceErrMsg] = useState('')
  const SaveAppointment = async () => {

    if (SelectedSlot === '' || SelectedMeetingDescription === '' || MeetingSource === '') {
      SetShowSuccessbookalert(true);
    }
    else {
      if (MeetingSource === 'Zoom meeting' && SelectedMeetingSlotsDuration === '60') {
        SetMeetingSourceErr(true)
        SetMeetingSourceErrMsg('You cannot book 1 hour session with zoom meeting')
      }
      else {
        ShowLoader();
        try {

          const response = await axiosJWT.post('appointment/add-appointment',
            {
              'nutrition_id': SelectedNutritionId,
              'client_id': ClientId,
              'service_id': SelectedServiceId,
              'slot': SelectedSlot,
              'client_timezone': ClientTimeZonePass,
              'meeting_description': SelectedMeetingDescription,
              'meeting_type': SelectedType,
              'booked_with_base_hour': BasePlanHours,
              'meeting_source': MeetingSource
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

          if (response) {

            handleClose();
            HandleShowSuccessbook();
            CheckBasePlanHours()
            GetMyUpcomingAppointments();
            GetAllCoach(0);
            GetAllsubscribedCoach();
            HideLoader()
          }
        } catch (error) {
          handleClose();
          HandleShowSuccessbookexpired();
          CheckBasePlanHours()
          SetShowSuccessbookexpiredMsg(error.response.data.msg);
          GetAllCoach(0);
          GetAllsubscribedCoach();
          HideLoader()
        }

      }

    }
  }

  const ViewServicePackages = async (e) => {
    SetServicesShowPopupTab(false)

    SetListOfservices([]);

    const UserId = e.target.getAttribute('data-user_id');
    SetAssignNutritionId(UserId);
    GetCoachDetails(UserId)

    const response = await axiosJWT.get('/nutrition-service/list-services?data=all_active&coach_id=' + UserId + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      SetListOfservices(response.data.NutritionistService);
      setShowPack(true);
    });
  }

  const ServiceBuy = async (e) => {

    ShowLoader();

    var service_id = e.target.getAttribute("data-service_id");
    var hours = e.target.getAttribute("data-hours");
    var nutrition_id = e.target.getAttribute("data-nutrition_id");

    try {
      const response = await axiosJWT.post('payments/service-plan-checkout',
        {
          nutritionist_id: nutrition_id,
          nutritionist_service_id: service_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response.status === 200) {
        HideLoader();
        window.location.href = response.data.sessionDetails.url;
      }
    } catch (err) {
      HideLoader();
      Setshowfail(true);
    }

    // const response = await axiosJWT.post('service-plan/client/assign-service-plan',
    //   {
    //     nutritionist_id: nutrition_id,
    //     nutritionist_service_id: service_id,

    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   });

    // if (response) {
    //   HideLoader()
    //   GetAllCoach(0)
    //   HandleShowSuccess(true)
    //   GetAllsubscribedCoach()
    //   setShowPack(false)
    // }
  }

  const CheckPlan = (id) => {

    var a = CoachRelationArray;

    var index = a.findIndex(x => x.nutritionist_id === id);

    if (index !== -1) {
      var pluckedArray = CoachRelationArray[index];
      if (pluckedArray.nutritionist_service_id !== null && pluckedArray.nutritionist_service_id !== '' && pluckedArray.allowed_hours !== 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  const CheckRenderPlan = (id) => {

    var a = CoachRelationArray;
    var b = AvailableCoachList;

    var index = a.findIndex(x => x.nutritionist_id === id);
    var data = b.find(x => x.id === id);

    if (data.nutritionist_services.length === 0) {
      return true;
    }
    else if ((data.nutritionist_infos.regular_availability === undefined || data.nutritionist_infos.regular_availability === null || data.nutritionist_infos.regular_availability === '') && (data.nutritionist_day_availabilities.length === 0)) {
      return true;
    }
    else {
      if (index !== -1) {
        var pluckedArray = CoachRelationArray[index];
        if (pluckedArray.nutritionist_service_id !== null && pluckedArray.nutritionist_service_id !== '' && pluckedArray.allowed_hours !== 0) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }

  const GetMyUpcomingAppointments = async () => {

    const response = await axiosJWT.get('/appointment/client-my-appointments', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {
      if (response.data.GetResponse !== null) {
        SetClientUpcomingAppointmentList(response.data.GetResponse);
      }
    }
  }

  function timetoUtcClientAppointment(passdate) {

    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss');

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss');
    return stardates;
  }

  function formatslots(slots) {

    const Myarray = slots.split('$TO');
    var Starttime = Myarray[0].trim();
    var Endtime = Myarray[1].trim();

    Starttime = format(parseISO(Starttime), `hh:mm a`);
    Endtime = format(parseISO(Endtime), `hh:mm a`);
    return Starttime + '-' + Endtime;
  }


  const TriggerCancelPopup = (e) => {
    var CancelMeetingId = e.target.getAttribute("data-meeting_appointment_id");
    CancelMeetingId = parseInt(CancelMeetingId);
    SetMeetingCancelId(CancelMeetingId);
    HandleShowSuccessbookcancel();
  }

  const CancelMeeting = async (e) => {
    var CancelMeetingId = e.target.getAttribute("data-meeting_appointment_id");


    try {
      ShowLoader()
      const response = await axiosJWT.post('appointment/cancel-appointment',
        {
          'meeting_id': CancelMeetingId,

        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        CheckBasePlanHours()
        HandleCloseSuccessbookcancel();
        HandleShowSuccessbookcancelsuccess();
        GetMyUpcomingAppointments();
        GetAllCoach(0);
        GetAllsubscribedCoach();
        HideLoader()
      }

    } catch (err) {
      console.log(err)
      HideLoader()
    }

  }

  const TriggerRescheduleMeetingPopup = async (e) => {
    var MeetingID = e.target.getAttribute('data-meeting_appointment_id');

    const Response = await axiosJWT.get('/appointment/get-appointment-details?id=' + MeetingID + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (Response.data !== null) {

      SetSelectedMeetingSlotsDuration(parseFloat(Response.data.meeting_duration))

      var CoachId = e.target.getAttribute('data-nutrition_id');
      SetRescheduleMeetingID(MeetingID);
      SetSelectedNutritionId(CoachId);
      setStartDate('');
      SetSelectedSlot('');
      SetSlotsAvailable([]);
      SetShowReschedule(true);

    }







  }

  const SaveRescheduleMeeting = async () => {

    if (SelectedSlot !== '') {

      const response = await axiosJWT.post('appointment/reschedule-appointment-request',
        {
          'meeting_id': RescheduleMeetingID,
          'nutrition_id': SelectedNutritionId,
          'slot': SelectedSlot,
          'client_timezone': ClientTimeZonePass
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        HandleCloseShowReschedule();
        GetMyUpcomingAppointments();
        GetRequestedReschedule();
      }
    }
  }

  const GetRequestedReschedule = async () => {
    const response = await axiosJWT.get("/appointment/reschedule-appointment-request/client", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    SetClientRescheduledAppointment(response.data.FinalArray);
  }

  const joinmeeting = (e) => {
    var meeting_id = e.target.getAttribute("data-item");

    navigate("/video/" + meeting_id, { replace: true });
  }

  const [loader, SetloaderShow] = useState(false);
  const ShowLoader = () => { SetloaderShow(true) }
  const HideLoader = () => { SetloaderShow(false) }

  const [showsuccess, Setshowsuccess] = useState(false);
  const CloseSuccessPopup = () => { Setshowsuccess(false) }
  const [showfail, Setshowfail] = useState(false);
  const CloseFailPopup = () => {
    Setshowfail(false);
  }


  const CheckPaymentStatus = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let paymentSuccess = params.get('payment');
    let type = params.get('type');

    if (paymentSuccess == 'success' && type === 'service_plan') {
      Setshowsuccess(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (paymentSuccess == 'failed') {
      Setshowfail(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }

  const [BasePlanHours, SetBasePlanHours] = useState(false)
  const [BasePlanHoursAllowed, SetBasePlanHoursAllowed] = useState(0)

  const CheckBasePlanHours = async () => {

    SetGoogleCalendarAccess(false)
    SetOutlookCalendarAccess(false)

    const response = await axiosJWT.get('/profile-details', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });



    if (response.data.profile_details && response.data.profile_details[0] && response.data.profile_details[0].client_infos && response.data.profile_details[0].client_infos !== null && response.data.profile_details[0].client_infos !== '' && response.data.profile_details[0].client_infos[0].admin_allowed_appointment_hours !== '') {

      if (response.data.profile_details[0].client_infos[0].admin_allowed_appointment_hours >= 15) {

        SetBasePlanHoursAllowed(response.data.profile_details[0].client_infos[0].admin_allowed_appointment_hours)
        SetBasePlanHours(true)
      }
      else {
        SetBasePlanHoursAllowed(0)
        SetBasePlanHours(false)
      }


    }

    if (response.data.profile_details[0].google_code === null || response.data.profile_details[0].google_code === '') {
      SetGoogleCalendarAccess(false)
    }
    else {
      SetGoogleCalendarAccess(true)
    }

    if (response.data.profile_details[0].ms_code === null || response.data.profile_details[0].ms_code === '') {
      SetOutlookCalendarAccess(false)
    }
    else {
      SetOutlookCalendarAccess(true)
    }



  }

  const GetCoachDetails = async (coach_id) => {

    const response = await axiosJWT.get("/appointment/get-nutrition-details?id=" + coach_id + "", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data != null) {
      SetCoachName(response.data.first_name + ' ' + response.data.last_name)
      if (response.data.user_profile_photo !== null && response.data.user_profile_photo !== '') {
        SetCoachImg(response.data.user_profile_photo)
      }
      if (response.data.nutritionist_infos !== null) {
        if (response.data.nutritionist_infos[0].coach_bio) {
          SetCoachInfo(response.data.nutritionist_infos[0].coach_bio)
        }
        if (response.data.nutritionist_infos[0].specialized_in) {
          SetCoachSpl(response.data.nutritionist_infos[0].specialized_in)
        }
      }

      var AllowedCoachDurationSlots = []
      if (response.data.nutritionist_infos[0].allowed_appointment_duration !== null && response.data.nutritionist_infos[0].allowed_appointment_duration !== '') {
        var ParsedData = JSON.parse(response.data.nutritionist_infos[0].allowed_appointment_duration)
        ParsedData.forEach(element => {
          let my_object = {};
          if (element === '"15min"') {

            my_object.label = "15 mins";
            my_object.value = 15;
          }
          else if (element === '"30min"') {
            my_object.label = "30 mins";
            my_object.value = 30;
          }
          else if (element === '"1hour"') {
            my_object.label = "1 hour";
            my_object.value = 60;
          }

          AllowedCoachDurationSlots.push(my_object)

        });

      }
      else {
        let my_object = {};

        my_object.label = "1 hour";
        my_object.value = 60;
        AllowedCoachDurationSlots.push(my_object)
      }
      SetMeetingSlotsDuration(AllowedCoachDurationSlots)




      console.log(response.data)
    }

  }

  const [ServicesShowPopupTab, SetServicesShowPopupTab] = useState(false)
  const [CoachImg, SetCoachImg] = useState('')
  const [CoachName, SetCoachName] = useState('')
  const [CoachSpl, SetCoachSpl] = useState('')
  const [CoachInfo, SetCoachInfo] = useState('')

  const ChangeTab = (e) => {
    const list_for = e.target.getAttribute("data-item");

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));
    e.target.setAttribute("class", "active");
    if (list_for === 'info') {
      SetServicesShowPopupTab(false)
    }
    else {
      SetServicesShowPopupTab(true)
    }
  }


  function timeConvert(n) {
    var num = parseFloat(n);
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rminutes !== 0) {
      return rhours + " hour(s) and " + rminutes + " minute(s)";
    }
    else {
      return rhours + " hour(s)"
    }


  }

  const Numberbetween = (x, min, max, y) => {



    // console.log('x',x)
    // console.log('min',min)
    // console.log('max',max)
    // console.log(x >= min && x <= max)
    // return x >= min && x <= max;

    const BookedStartTimeStamp = x
    const BookedEndTimeStamp = y;
    const SlotStartTime = min;
    const SlotEndTime = max;


    console.log('BookedStartTimeStamp', BookedStartTimeStamp)
    console.log('BookedEndTimeStamp', BookedEndTimeStamp)
    console.log('SlotStartTime', SlotStartTime)
    console.log('SlotEndTime', SlotEndTime)


    if (SlotStartTime >= BookedStartTimeStamp && SlotEndTime <= BookedEndTimeStamp) {
      return true
    }
    else if (BookedStartTimeStamp >= SlotStartTime && BookedEndTimeStamp <= SlotEndTime) {

      return true
    }
    else {
      return false
    }


  }



  const [GoogleCalendarAccess, SetGoogleCalendarAccess] = useState(false)
  const [OutlookCalendarAccess, SetOutlookCalendarAccess] = useState(false)

  const CheckMeeting = async (e) => {
    const item = e.target.getAttribute('data-item')


    const Response = await axiosJWT.get('/appointment/get-appointment-details?id=' + item + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (Response.status === 200) {
      if (Response.data.meeting_source === 'Zoom meeting') {

        try {
          const ZoomResponse = await axiosJWT.get('/zoom/get-meeting?id=' + item + '', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          if (ZoomResponse.status === 200) {
            window.open(ZoomResponse.data.link, '_blank');

          }
        } catch (err) {

          SetshowfailMeetMsg(err.response.data.msg)
          SetshowfailMeet(true)


        }

      }
      else {
        window.location.href = process.env.REACT_APP_FRONTEND_URL + '/video/' + item
      }
    }


  }

  const CheckMeetingSource = async (value) => {

    SetMeetingSource(value);
    SetSelectedMeetingSlotsDuration('');
    
    if (value === 'Zoom meeting') {

      const ZoomResponse = await axiosJWT.get('/zoom/check-zoom-enabled?coach_id=' + SelectedNutritionId + '', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (ZoomResponse.status === 200) {
        if (ZoomResponse.data.status === false) {
          HandleShowSuccessbookexpired();
          SetShowSuccessbookexpiredMsg('You cannot book this coach with zoom meet right now.!');
          SetMeetingSource('');
        }
      }


    }
  }

  const [showfailMeet, SetshowfailMeet] = useState(false)
  const CloseFailPopupMeet = () => {
    SetshowfailMeet(false)
  }
  const [showfailMeetMsg, SetshowfailMeetMsg] = useState('')


  useEffect(() => {
    CheckBasePlanHours()
    GetAllCoach(0);
    GetMyUpcomingAppointments();
    GetAllsubscribedCoach();
    GetRequestedReschedule();
    CheckPaymentStatus();
  }, [])

  return (

    <div>
      {loader === true && <div id="semiTransparenDivLoader"></div>}
      {ClientUpcomingAppointmentList.length > 0 &&
        <div className="row upcoming_appointment_table_row">
          <span className="upcoming_appointment_table_row_span">Upcoming Appointments:</span>
          <Table className="table is-striped is-fullwidth upcoming_appointment_table">
            <Thead className="thead">
              <Tr className="trow">
                <Th scope="col">Coach Name</Th>
                <Th scope="col">Purpose</Th>
                <Th scope="col">Description</Th>
                <Th scope="col">Date {'&'} time</Th>
                <Th scope="col">Duration</Th>
                <Th scope="col">Action</Th>
              </Tr>
            </Thead>
            <Tbody className="tbody">
              {ClientUpcomingAppointmentList.length > 0 && ClientUpcomingAppointmentList.map((upappointment, index) => (
                <Tr className="trow" key={index}>
                  <Td>
                    <span className='c-avatar'>
                      <img className='table_profile_img' src={upappointment.user.hasOwnProperty("user_profile_photo") && upappointment.user.user_profile_photo !== '' && upappointment.user.user_profile_photo !== null ? upappointment.user.user_profile_photo : (upappointment.user.gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                        (upappointment.user.logged_in_status === '1')
                          ? <span className='c-avatar__status'></span>
                          : null
                      }
                      <span className='name_val'>{upappointment.user.first_name + ' ' + upappointment.user.last_name}</span>
                    </span>
                  </Td>
                  <Td className="appointment_available_td"><span className="desing_back">{upappointment.meeting_type}</span></Td>
                  <Td className="appointment_available_td"><span className="">{upappointment.meeting_description}</span></Td>
                  <Td className="date_time_avaialble_td"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.start_time)), `MMM dd, yyyy`)}<br /><i className="fa fa-clock td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.start_time)), `hh:mm a`)}</Td>
                  <Td className="appointment_available_td"><span className="">{upappointment.meeting_duration + ' mins'}</span></Td>
                  <Td>
                    <Button variant="primary keto_link_button action_appointment_button view_appointment_details reschedule_trigger_button" data-meeting_appointment_id={upappointment.id} data-nutrition_id={upappointment.nutritionist_id} onClick={(e) => { TriggerRescheduleMeetingPopup(e) }}>Reschedule</Button>
                    <Button variant="primary keto_link_button action_appointment_button" data-meeting_appointment_id={upappointment.id} onClick={(e) => { TriggerCancelPopup(e) }}>Cancel</Button>
                    {/* <Button variant="primary keto_link_button action_appointment_button join_appointment_button" data-item={upappointment.id} onClick={joinmeeting}>Join</Button> */}
                    {/* <Link to={"/video/" + upappointment.id} target="_blank"></Link> */}
                    <Button variant="primary keto_link_button action_appointment_button join_appointment_button" data-item={upappointment.id} onClick={(e) => { CheckMeeting(e) }}>Join</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      }
      {ClientRescheduledAppointmentList.length > 0 &&
        <div className="row upcoming_appointment_table_row">
          <span className="upcoming_appointment_table_row_span">Rescheduled Appointments:</span>
          <Table className="table is-striped is-fullwidth upcoming_appointment_table">
            <Thead className="thead">
              <Tr className="trow">
                <Th scope="col">Coach Name</Th>
                <Th scope="col">Purpose</Th>
                <Th scope="col">Booked Date {'&'} time</Th>
                <Th scope="col">Rescheduled Date {'&'} time</Th>
                <Th scope="col">Status</Th>
              </Tr>
            </Thead>
            <Tbody className="tbody">
              {ClientRescheduledAppointmentList.length > 0 && ClientRescheduledAppointmentList.map((upappointment, index) => (
                <Tr className="trow" key={index}>
                  <Td>
                    <span className='c-avatar'>
                      <img className='table_profile_img' src={upappointment.user.hasOwnProperty("user_profile_photo") && upappointment.user.user_profile_photo !== '' && upappointment.user.user_profile_photo !== null ? upappointment.user.user_profile_photo : (upappointment.user.gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                        (upappointment.user.logged_in_status === '1')
                          ? <span className='c-avatar__status'></span>
                          : null
                      }
                      <span className='name_val'>{upappointment.user.first_name + ' ' + upappointment.user.last_name}</span>
                    </span>
                  </Td>
                  <Td className="appointment_available_td"><span className="desing_back">{upappointment.Reschedule.nutrition_client_appointment.meeting_description}</span></Td>
                  <Td className="date_time_avaialble_td">{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.nutrition_client_appointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</Td>
                  <Td className="date_time_avaialble_td">{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.start_time)), `MMM dd, yyyy  hh:mm a`)}</Td>
                  <Td className="appointment_available_td"><span style={{ paddingTop: '7px', paddingBottom: '7px' }} className="desing_purple">{'Pending Approval'}</span></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      }


      <div className="row upcoming_appointment_table_row">
        <div className="col subscribed_col">
          <span className="upcoming_appointment_table_row_span">Subscribed Coach</span>
          {SubscribedList.length > 0 &&
            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
              <Thead className="thead">
                <Tr className="trow">
                  <Th scope="col">Name</Th>
                  <Th scope="col">Status</Th>
                  <Th scope="col">Action</Th>
                </Tr>
              </Thead>
              <Tbody className="tbody">
                {SubscribedList.length > 0 && SubscribedList.map((coach, index) => (
                  <Tr className="trow" key={coach.id}>
                    <Td>
                      <div className='c-avatar'>
                        <div className="photo_block">
                          <img className='table_profile_img' src={coach.hasOwnProperty("user_profile_photo") && coach.user_profile_photo !== '' && coach.user_profile_photo !== null ? coach.user_profile_photo : (coach.gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                            (coach.logged_in_status === '1')
                              ? <span className='c-avatar__status'></span>
                              : null
                          }
                        </div>
                        <div><span className='name_val'>{coach.first_name + ' ' + coach.last_name} </span></div>
                      </div>
                    </Td>
                    <Td className="appointment_available_td"><span className="desing_back">Available</span></Td>
                    <Td>
                      {coach.allowedhours >= 15 ?
                        <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { TriggerPopup(e) }} data-user_id={coach.id} >Book appointment</button> : (BasePlanHours === false ? <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { ViewServicePackages(e) }} data-user_id={coach.id}>Buy Plan</button> : <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { TriggerPopup(e) }} data-user_id={coach.id} >Book appointment</button>)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          }
        </div>

        <div className="col available_col">
          <span className="upcoming_appointment_table_row_span">Available Coach</span>
          {AvailableCoachList.length > 0 &&
            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
              <Thead className="thead">
                <Tr className="trow">
                  <Th scope="col">Name</Th>
                  <Th scope="col">Status</Th>
                  <Th scope="col">Action</Th>
                </Tr>
              </Thead>
              <Tbody className="tbody">
                {AvailableCoachList.length > 0 && AvailableCoachList.map((coach, index) => (
                  <Tr className="trow" key={coach.id}>
                    <Td className="name_block_available">
                      <div className='c-avatar'>
                        <div className="photo_block">
                          <img className='table_profile_img' src={coach.hasOwnProperty("user_profile_photo") && coach.user_profile_photo !== '' && coach.user_profile_photo !== null ? coach.user_profile_photo : (coach.gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                            (coach.logged_in_status === '1')
                              ? <span className='c-avatar__status'></span>
                              : null
                          }
                        </div>
                        <div>
                          <span className='name_val'>{coach.first_name + ' ' + coach.last_name} </span>
                        </div>
                      </div>
                    </Td>
                    <Td className="appointment_available_td"><span className="desing_back">Available</span></Td>
                    <Td>
                      {
                        CheckPlan(coach.id) === true ? <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { TriggerPopup(e) }} data-user_id={coach.id}>Book appointment</button> : (BasePlanHours === false ? <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { ViewServicePackages(e) }} data-user_id={coach.id}>View Services</button> : <div>


                          {CheckPlan(coach.id) === false ?
                            <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { ViewServicePackages(e) }} data-user_id={coach.id}>View Services</button> : null}

                          <button type="button" className="btn btn-success add_food_button_progress take_appointment_button" onClick={(e) => { TriggerPopup(e) }} data-user_id={coach.id}>Book appointment</button>
                        </div>)
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          }
          {TotalRecordCoach > 0 ?
            <div className="" style={{ float: 'right' }}>
              <Pagination
                activePage={Pagenumber}
                itemsCountPerPage={PerPage}
                totalItemsCount={TotalRecordCoach}
                onChange={changePageNumberS.bind(this)}
                prevPageText="<"
                nextPageText=">"
                hideFirstLastPages={true}
                linkClassPrev="pagination_prev"
                linkClassNext="pagination_next"
                linkClass="pagenumbers grayfont"
              />
            </div>
            : null}
        </div>

        <Modal show={show} onHide={handleClose} className='commission_popup add_billing_popup book_appointment_popup book_client_popup  show_packages_popup buy_service_popup_client'>
          <Modal.Header closeButton>
            <Modal.Title>

              {SavebillingButtonLabel} <i style={{ cursor: 'pointer' }} className="fa fa-info-circle" title="To add an event to your google & outlook calendar. Provide access under profile screen" aria-hidden="true"></i>


            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (GoogleCalendarAccess === false && OutlookCalendarAccess === false) &&

              <div className="warning-msg-appointment">
                <i className="fa fa-warning"></i> Provide google calendar & outlook calendar access under profile screen to sync appointments with google & outlook calendar
              </div>

            }
            {
              (OutlookCalendarAccess === false && GoogleCalendarAccess === true) &&

              <div className="warning-msg-appointment">
                <i className="fa fa-warning"></i> Provide outlook calendar access under profile screen to sync appointments with outlook calendar
              </div>

            }
            {
              (GoogleCalendarAccess === false && OutlookCalendarAccess === true) &&

              <div className="warning-msg-appointment">
                <i className="fa fa-warning"></i> Provide google calendar access under profile screen to sync appointments with google calendar
              </div>

            }


            <div className="adminmanageusers container admin_user_directory">
              <div className="menubox">
                <div className="tabmenu">
                  <a className="active" data-item="info" onClick={ChangeTab}>
                    Coach Info
                  </a>
                  <a className="" data-item="package" onClick={ChangeTab}>
                    Book appointment
                  </a>
                </div>
              </div>
            </div>
            {
              ServicesShowPopupTab === false &&
              <div className="coach_info_row">

                <div className="img">
                  <img src={CoachImg !== '' ? CoachImg : DummyImg} />
                  <p className="name">{CoachName}</p>
                  <p className="spl">{CoachSpl}</p>
                </div>
                <div className="info">
                  {CoachInfo}
                </div>

              </div>
            }

            {
              ServicesShowPopupTab === true &&
              <div>

                {
                  BasePlanHours === false ? <div className="row small_btm_book_apoinment top_service_appoint_div">
                    <div><span className="service_details_name">Service Name : </span>{CurrentServiceName}</div>
                    <div> <span className="service_details_name">Remaining Hours: </span>{timeConvert(CurrentServiceHours)}</div>
                    <div> <span className="service_details_name">Expires On: </span>{CurrentServiceExpiryDate}</div>
                  </div> :

                    <div className="row small_btm_book_apoinment top_service_appoint_div">

                      <div> <span className="service_details_name_80">Hurray! You can schedule meeting with base plan subscription. Remaining hours: </span>{timeConvert(BasePlanHoursAllowed)}</div>

                    </div>
                }



                <div className="row small_btm_book_apoinment">
                  <label className='commission_inner_popup_label slots_available_label meeting_title_label required'>Meeting Description:</label>

                  <input type='text' className="add_category_inner_input profile_input total_session meeting_description_input" onChange={(e) => { SetSelectedMeetingDescription(e.target.value) }} />
                </div>

                <div className='row select_meeting_type_client'>
                  <div className="col">
                    <label className='commission_inner_popup_label meeting_type_caoch_book_label'>Meeting type:</label>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' onChange={(e) => { SetSelectedType(e.target.value) }}>
                      <option value="">Select meeting type</option>
                      <option value="Initial Interview">Intial Interview</option>
                      <option value="Follow Up">Follow Up</option>

                    </select>
                  </div>
                </div>                

                <div className='row select_meeting_type_client' style={{ marginTop: '10px' }}>
                  <div className="col">
                    <label className='commission_inner_popup_label meeting_type_caoch_book_label required' style={{ width: '16%' }}>Book with:</label>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' onChange={(e) => { CheckMeetingSource(e.target.value) }}>
                      <option value="">Select meeting source</option>
                      <option value="Quickblox">Fitpal</option>
                      <option value="Zoom meeting">Zoom Meet</option>

                    </select>
                  </div>
                  {
                    MeetingSourceErr === true && <span className="err">{MeetingSourceErrMsg}</span>
                  }
                </div>       

                <div className='row select_meeting_type_client' style={{ marginTop: '10px' }}>
                  <div className="col">
                    <label className='commission_inner_popup_label meeting_type_caoch_book_label required' style={{ width: '16%' }}>Slots duration:</label>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' onChange={(e) => { SelectedMeetingSlotsDurationfn(e.target.value) }}>
                      <option value="">Select meeting slots duration</option>

                      {MeetingSlotsDuration.length > 0 && MeetingSlotsDuration.map((slotsduration, index) => (
                        MeetingSource === "Zoom meeting" && slotsduration.value !== 60 ?
                        (<option value={slotsduration.value}>{slotsduration.label}</option>)
                        :
                        MeetingSource === "Quickblox" ?
                        (<option value={slotsduration.value}>{slotsduration.label}</option>) : null
                      ))}


                    </select>
                  </div>
                  {
                    MeetingSlotsDurationErr === true && <span className="err">Please select slots duration</span>
                  }

                </div>       


                <div className="row small_btm_book_apoinment pick_date_row_client">
                  <div className="col">
                    <div className="pick_date_apppointment_by_client">
                      <label className='commission_inner_popup_label pick_date_label_book_appointment required pick_date_client'>Pick the date:</label>

                      <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                        e.preventDefault();
                      }} onChange={(date) => ChangedDate(date)} minDate={MinDate} />

                      <span className="calendar_icon_div">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>

                    </div>
                    {SlotsAvailable.length === 0 && startDate !== '' &&
                      <span className="err">Sorry! No slots available. Try some other date!</span>
                    }
                  </div>
                </div>

                <div className="row small_btm_book_apoinment">
                  {SlotsAvailable.length > 0 &&
                    <label className='commission_inner_popup_label slots_available_label'>Slots available for this date:</label>}<br />
                  {SlotsAvailable.length > 0 && SlotsAvailable.map((slots, index) => (
                    <span className="slots_list_by_client" data-slot={slots} onClick={(e) => { SlotsSelection(e) }} key={index}>{formatslots(slots)} <i className="fa fa-check hide_show_tick_book_a" aria-hidden="true"></i> </span>
                  ))}
                </div>
              </div>
            }

          </Modal.Body>
          {
            ServicesShowPopupTab === true && <Modal.Footer className="display_block">
              <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                Back
              </Button>
              <Button variant="primary" className='save_button_commission float_right'
                onClick={SaveAppointment}>
                {SavebillingButtonLabel}
              </Button>
            </Modal.Footer>
          }

        </Modal>

        <Modal show={showPack} onHide={handleClosePack} className='commission_popup add_billing_popup show_packages_popup buy_service_popup_client'>
          <Modal.Header closeButton>
            <Modal.Title>{'Coach Details'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="adminmanageusers container admin_user_directory">
              <div className="menubox">
                <div className="tabmenu">
                  <a className="active" data-item="info" onClick={ChangeTab}>
                    Coach Info
                  </a>
                  <a className="" data-item="package" onClick={ChangeTab}>
                    Service packages
                  </a>
                </div>
              </div>
            </div>

            {
              ServicesShowPopupTab === false &&
              <div className="coach_info_row">

                <div className="img">
                  <img src={CoachImg !== '' ? CoachImg : DummyImg} />
                  <p className="name">{CoachName}</p>
                  <p className="spl">{CoachSpl}</p>
                </div>
                <div className="info">
                  {CoachInfo}
                </div>

              </div>
            }

            {
              ServicesShowPopupTab === true &&
              <div className="row session_package_list">
                {ListofServices.length > 0 && ListofServices.map((service, index) => (
                  <div className="plan_col" key={index}>
                    <p className="p_tag_label_top_subs">{service.service_name}</p>
                    <div className="amount_row">
                      <span className="amount_value">${service.service_price}</span>
                    </div>
                    <div className="plan_info">
                      <ul className="list-unstyled mb-4">
                        <li>Expiry : {service.service_expiry} months</li>
                        <li>sessions allowed : {service.service_hour_allowed} hours</li>
                      </ul>
                    </div>
                    <div className="low_level_button_subscriptions">
                      <button className="btn btn-primary keto_link_button" data-service_id={service.id} data-hours={service.service_hour_allowed} onClick={(e) => { ServiceBuy(e) }} data-nutrition_id={AssignNutritionId}>Buy Now</button>
                    </div>
                  </div>
                ))}
              </div>
            }

          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccess} onHide={HandleCloseSuccess}>
          <Modal.Header closeButton>
            <Modal.Title>Service Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have successfully purchased this service package.!</Modal.Body>
          <Modal.Footer className="display_block">
            <Button variant="secondary" onClick={HandleCloseSuccess}>
              Close
            </Button>
            <Button variant="primary keto_link_button float_right" onClick={HandleCloseSuccess}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={ShowSuccessbook} onHide={HandleCloseSuccessbook}>
          <Modal.Header closeButton>
            <Modal.Title>Appointment Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have successfully booked an appointment.!</Modal.Body>
          <Modal.Footer className="display_block">
            <Button variant="secondary" onClick={HandleCloseSuccessbook}>
              Close
            </Button>
            <Button variant="primary keto_link_button float_right" onClick={HandleCloseSuccessbook}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookexpired} onHide={HandleCloseSuccessbookexpired}>
          <Modal.Header closeButton>
            <Modal.Title>Failed!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{ShowSuccessbookexpiredMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookexpired}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookalert} onHide={HandleCloseSuccessbookalert} className="alert_book_popup">
          <Modal.Header closeButton>
            <Modal.Title>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body><span className="bold">Please fill Meeting description {'&'} select slot {'&'} select source</span></Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookalert}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookcancel} onHide={HandleCloseSuccessbookcancel} className="alert_book_popup">
          <Modal.Header closeButton>
            <Modal.Title>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body><span className="bold">Are you sure want to cancel this meeting?</span></Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancel}>
              No
            </Button>
            <Button variant="primary keto_link_button" data-meeting_appointment_id={MeetingCancelId} onClick={(e) => { CancelMeeting(e) }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookcancelsuccess} onHide={HandleCloseSuccessbookcancelsucess} className="alert_book_popup">
          <Modal.Header closeButton>
            <Modal.Title>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body><span className="bold">You have successfully cancelled this appointment.!</span></Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancelsucess}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Modal show={ShowReschedule} onHide={HandleCloseShowReschedule} className="'commission_popup add_billing_popup book_appointment_popup book_client_popup reschedule_client_popup">
        <Modal.Header closeButton>
          <Modal.Title>Reschedule Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row small_btm_book_apoinment pick_date_row_client">
            <div className="col">
              <div className="pick_date_apppointment_by_client">
                <label className='commission_inner_popup_label pick_date_label_book_appointment required pick_date_client'>Pick the date:</label>

                <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                  e.preventDefault();
                }} onChange={(date) => ChangedDate(date)} minDate={MinDate} />

                <span className="calendar_icon_div">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </span>

              </div>
              {SlotsAvailable.length === 0 && startDate !== '' &&
                <span className="err">Sorry! No slot available. Try some other date!</span>
              }
            </div>
          </div>

          <div className="row small_btm_book_apoinment">
            {SlotsAvailable.length > 0 &&
              <label className='commission_inner_popup_label slots_available_label'>Slots available for this date:</label>}<br />
            {SlotsAvailable.length > 0 && SlotsAvailable.map((slots, index) => (
              <span className="slots_list_by_client" data-slot={slots} onClick={(e) => { SlotsSelection(e) }} key={index}>{formatslots(slots)} <i className="fa fa-check hide_show_tick_book_a" aria-hidden="true"></i> </span>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn-secondary modal_close_button" onClick={HandleCloseShowReschedule}>
            Cancel
          </Button>
          <Button variant="primary modal_action_button keto_link_button" onClick={SaveRescheduleMeeting}>
            Reschedule
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showsuccess} onHide={CloseSuccessPopup} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Service plan purchase success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have successfully purchased the service plan.!
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CloseSuccessPopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showfail} onHide={CloseFailPopup} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Service plan purchase failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your service plan purchasement has been failed
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailPopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showfailMeet} onHide={CloseFailPopupMeet} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Failed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showfailMeetMsg}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailPopupMeet}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AvailableCoach
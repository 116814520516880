import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const Popup=(props)=>{
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create()

    const [SaveCategoryButtonLabel,SetSaveCategoryButtonLabel]=useState('')
    const [show,Setshow]=useState(false)
    const [DefaultCatName,SetDefaultCatName]=useState('')
    const [ServiceCatNameErr,SetServiceCatNameErr]=useState(false)
    const [ServiceCatNameErrMsg,SetServiceCatNameErrMsg]=useState('')
    const [DefaultCatStatus,SetDefaultCatStatus]=useState(1)
    const [ServiceCatStatusErr,SetServiceCatStatusErr]=useState(false)
    const [ServiceCatStatusErrMsg,SetServiceCatStatusErrMsg]=useState('')
    const [service_cat_err,Setservice_cat_err]=useState(false)
    const handleClose=()=>{
        props.SetShowPopup(false)
        Setshow(false)
    }



    useEffect(()=>{
        if(props.SaveCategoryButtonLabel)
        {
            SetSaveCategoryButtonLabel(props.SaveCategoryButtonLabel)
        }
        if(props.DefaultCatName)
        {
          
            SetDefaultCatName(props.DefaultCatName)
        }
        if(props.DefaultCatStatus)
        {
            SetDefaultCatStatus(props.DefaultCatStatus)
        }
       
        if(props.ShowPopup)
        {
            if(props.ShowPopup===true)
            {
                SetServiceCatNameErr(false)
                SetDefaultCatName('')
                SetDefaultCatStatus(1)
                Setshow(true)
            }
        }
        

    },[props])

    const AddCategory=async(e)=>{

        var Err=false;
        var Target=e.target.getAttribute('data-target')
        

        if(DefaultCatName==='')
        {
            Err=true;
            SetServiceCatNameErr(true)
            SetServiceCatNameErrMsg('Please enter name')
        }

        if(Target==='Add specialized in')
        {

            if(Err===false)
            {
                const response = await axiosJWT.post('/coach-cat/add',
                {
                    category_name: DefaultCatName,
                    category_status:DefaultCatStatus
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                });
                if(response.status===200)
                {
                    props.GetMyLisData(1)
                    handleClose()
                }
            }

        }
       

    }

    return (
        <div>

<Modal show={show} onHide={handleClose} className='commission_popup add_coach_service_category_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{SaveCategoryButtonLabel}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Name</label><br />
                                    <input type='text' className="add_category_inner_input" defaultValue={DefaultCatName} onChange={(e) => { SetDefaultCatName(e.target.value) }}  {...(ServiceCatNameErr===true ? {id: 'err_border'} : {})}  />
                                    {ServiceCatNameErr === true &&
                                        <span className="err service_err_field">  {ServiceCatNameErrMsg}</span>
                                    }
                                </div>
                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Status</label><br />
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultCatStatus} onChange={(e) => { SetDefaultCatStatus(e.target.value) }} {...(ServiceCatStatusErr===true ? {id: 'err_border'} : {})} >
                                        <option value="1">Enable</option>
                                        <option value="0">Disable</option>

                                    </select>
                                    {ServiceCatStatusErr === true &&
                                        <span className="err service_err_field">  {ServiceCatStatusErrMsg}</span>
                                    }
                                </div>

                            </div>
                            {service_cat_err === true &&
                                <div className="row service_cat_err">
                                    Please fill out all fields
                                </div>
                            }


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                                Back
                            </Button>
                            <Button variant="primary" className='save_button_commission float_right' data-target={SaveCategoryButtonLabel} onClick={AddCategory}>
                                {'Add'}
                            </Button>
                        </Modal.Footer>
                    </Modal>

        </div>
    )
}
export default Popup

import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import female_icon from "../../../images/female.png";
import male_icon from "../../../images/male.png";
import moment from 'moment'

const CoachClientAppointments=(props)=>{

    const token = localStorage.getItem("token");
    const user_timezone = localStorage.getItem("user_timezone");
    const axiosJWT = axios.create();
    const [CoachClientAppointments,SetCoachClientAppointments]=useState([])
    const Getcoachappoinments=async(nutritionid)=>{

    const response = await axiosJWT.get("/appointment/get-nutrition-upcoming-appointments?userid=" + nutritionid + "",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if(response.data!==null)
          {
            if(response.data.appointments!==null)
            {
                SetCoachClientAppointments(response.data.appointments)
            }
          }
 }


    useEffect(()=>{

        if(props.ClientId)
        {
            Getcoachappoinments(props.ClientId)
        }

    },[props])
  

    return (
        <div>
            <h1 style={{fontSize:'1.5rem'}}>Upcoming Appointments</h1>
           <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Title/Description</Th>
                        <Th>Date</Th>
                        <Th>Start Time</Th>
                        <Th>End Time</Th>
                        <Th>Client Name</Th>
                        
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {CoachClientAppointments.map((appointment, index) => (
                        <Tr className='trow' key={index}>
                            <Td>{appointment.appointment.meeting_description}</Td>
                            <Td>{moment.utc(appointment.appointment.start_time).clone().tz(user_timezone).format('MMM Do, YYYY')}</Td>
                            <Td>{moment.utc(appointment.appointment.start_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                            <Td>{moment.utc(appointment.appointment.end_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                             <Td>{appointment.client.first_name+' '+appointment.client.last_name}</Td>
                           
                        </Tr>
                    ))}

                </Tbody>
            </Table>
        </div>
    )

}
export default CoachClientAppointments
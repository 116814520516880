import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactFormBuilder } from 'react-form-builder2';
import Questionnaire from "./questionnaire";


const AddQuestionnaire = () => {
  const side_nav = localStorage.getItem("side_nav");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");
  const [NewFormName, SetNewformName] = useState('')
  const [Err, SetErr] = useState(false)

  const items = [
    // Additional standard components, you don't need full definition if no modification is required. 
    {
      key: 'Header',
    }, {
      key: 'TextInput',
    }, {
      key: 'TextArea',
    }, {
      key: 'RadioButtons',
    }, {
      key: 'Checkboxes',
    },
    {
      key: 'NumberInput',
    },
    {
      key: 'Paragraph',
    },
    {
      key: 'Dropdown',
    },
    {
      key: 'Tags',
    },
    {
      key: 'Rating',
    },
    {
      key: 'DatePicker',
    },
    {
      key: 'Range',
    },
    {
      key: 'Signature',
    },

  ];


  const SaveQuestionnaire = async () => {

    try {

      if (NewFormName !== '') {
        SetErr(false)
        const response = await axiosJWT.post('/form',
          {
            form_name: NewFormName,

          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        if (response) {

          if(role==='Nutrition')
          {
            window.location.href = "" + window.location.origin + "/coach/questionnaire";
          }
          else if (role==='Admin')
          {
            window.location.href = "" + window.location.origin + "/admin/questionnaire";
          }


        }

      }
      else {
        SetErr(true)
      }

    } catch (error) {
      SetErr(true)
    }


  }

  const emptyForm = async () => {


    const response = await axiosJWT.get('form/empty-form', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response)

    })
  }

  useEffect(() => {
    emptyForm()
  }, [])

  const ListQuestionnaire = () => {
    if(role==='Nutrition')
    {
      window.location.href = "" + window.location.origin + "/coach/questionnaire";
    }
    else if (role==='Admin')
    {
      window.location.href = "" + window.location.origin + "/admin/questionnaire";
    }

  }



  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"} >
      <section className="dashboard" >
      <div className="coachquestionnaire_add container">
        <Questionnaire />
        <div className="row">
          <div className="questionnaire_but_col col">
              <button className=" top_billing_coach_payment" onClick={SaveQuestionnaire} >Save</button>
              <button className=" top_billing_coach_payment" onClick={ListQuestionnaire} >Back</button>
          </div>
        </div>
        <div className="row top_quesionnaire_row">        
          <div className="questionnaire_name_col col">
            <label className='commission_inner_popup_label'>Questionnaire Name</label><br />
            <input type='text' className="add_category_inner_input total_session" onChange={(e) => { SetNewformName(e.target.value) }} />


          </div>
          
          {Err === true &&
            <span className="add_ques_err">{'Questionnaire name & content is required!'}</span>
          }

        </div>
        <div className="row">
          <ReactFormBuilder
            saveUrl={'' + process.env.REACT_APP_BACKEND_URL + '/form/coach/initial-save-form?user_id='+userId+''} toolbarItems={items} />
        </div>
          </div>
      </section>
    </div>


  )
}

export default AddQuestionnaire
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Inviteuser = () => {

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    var [show, setShow] = useState(false);
    const options = [{ value: "Coach", label: "Coach" }, { value: "Client", label: "Client" }];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const axiosJWT = axios.create();

    const [inviteFor, setinviteFor] = useState('');
    const [mailAddress, setSenderAddress] = useState('');
    const [mailContent, setSenderMessage] = useState('');

    const [invitesList, setinvitesList] = useState([]);
    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const [TypeOfInviteErr, SetTypeOfInviteErr] = useState(false)
    const [TypeOfInviteErrMsg, SetTypeOfInviteErrMsg] = useState('')

    const [EmailAddressErr, SetEmailAddressErr] = useState(false)
    const [EmailAddressErrMsg, SetEmailAddressErrMsg] = useState('')

    const [MessageErr, SetMessageErr] = useState(false)
    const [MessageErrMsg, SetMessageErrMsg] = useState('')

    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const editorChange = (data) => {

        seteditorData(data.blocks);
    }
    const [editorData, seteditorData] = useState('');
    useEffect(() => {
        getInvites();
    }, [])

    const getInvites = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/invite-users?offset=" + starting_limit + "&limit=" + ending_limit,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        setinvitesList(response.data.invited)
        setTotalCount(response.data.totalcount);

    }

    const sendInvite = async () => {

        var err = false;
        SetEmailAddressErr(false);
        SetTypeOfInviteErr(false);
        SetMessageErr(false);

        if (mailAddress === undefined || mailAddress === '') {
            SetEmailAddressErrMsg('Please enter atleat one email address');
            SetEmailAddressErr(true);
            err = true;
        }

        if (editorData === '' || editorData[0].text === '') {
            SetMessageErrMsg('Please enter message content');
            SetMessageErr(true);
            err = true;
        }

        if (inviteFor.value === undefined || inviteFor.value === '') {
            SetTypeOfInviteErrMsg('Please select type of invitation');
            SetTypeOfInviteErr(true);
            err = true;
        }

        if (err == true) {
            return false;
        }


        if (mailAddress != '' && editorData[0].text != '') {

            var mailAddress_list = (mailAddress.replace(/ /g, '')).split(",");

            var unique_mailAddress_list = Array.from(new Set(mailAddress_list));

            var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));

            if (unique_mailAddress_list.length >= 1) {
                unique_mailAddress_list.forEach(element => {
                    if (element != '') {
                        axiosJWT.post('/invite-users',
                            {
                                user_email: element,
                                invited_by: userId,
                                invited_for: inviteFor.value,
                                subject: inviteFor.value + ' Invitation',
                                content: editor_content
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            }).then((response)=>{
                                getInvites();
                                handleClose();
                            }).catch((error)=> {
                                SetEmailAddressErrMsg(error.response.data.message);
                                SetEmailAddressErr(true);
                            });
                    }
                });
            }
           
            
        }
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getInvites(pagenumber);
    }

    return (
        <div>
            <div id="invite_user" className='admin_marketing_invite_user modal-body'>
                <h5 style={{ "float": "left" }}>Invited Users</h5>
                <div className='action_buttons'>
                    <Button className='green_button btn' onClick={handleShow}>Invite New User</Button>
                </div>
                <div style={{ "clear": "both" }} />
                <br></br>
                <table className="table is-striped is-fullwidth">
                    <thead className="thead">
                        <tr>
                            <th>Sent By</th>
                            <th>Message</th>
                            <th>Sent to</th>
                            <th>Used</th>
                            <th>Date Send</th>
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {invitesList.map((invites, index) => (
                            <tr className='trow' key={invites.id}>
                                <td>{invites.user.first_name} {invites.user.last_name}</td>
                                <td dangerouslySetInnerHTML={{ __html: invites.content }}></td>
                                <td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{invites.user_email}</td>
                                <td><p className="">{invites.status === 1 ? 'Yes' : 'No'}</p></td>
                                <td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(invites.createdAt), `PP`)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                
                {totalCount ?
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={countPerPage}
                    totalItemsCount={totalCount}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers grayfont"
                />
                : null }


                <Modal show={show} onHide={handleClose} className='adminmarketing_modal_popup admin_marketing_invite_user'>
                    <Modal.Header closeButton>
                        <Modal.Title>Invite New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col">
                                <p className='color_grey'>Add the email addresses of the people you are inviting to the field below. To add multiple email addresses, separate them with comma.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className='inner_popup_label'>Type of Invitation:</label><br />
                                <Select
                                    options={options}
                                    onChange={setinviteFor}
                                    {...(TypeOfInviteErr===true ? {id: 'err_border'} : {})}
                                />
                            </div>
                        </div>
                        {TypeOfInviteErr === true &&
                            <div className='row err promotion_err_name'>
                                {TypeOfInviteErrMsg}
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label className='inner_popup_label'>Email Address:</label><br />
                                <textarea className='inner_popup_input' onChange={(e) => { setSenderAddress(e.target.value) }} rows="4" placeholder='sample1@mail.com, sample2@mail.com'     {...(EmailAddressErr===true ? {id: 'err_border'} : {})} />
                            </div>
                        </div>
                        {EmailAddressErr === true &&
                            <div className='row err promotion_err_name'>
                                {EmailAddressErrMsg}
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label className='inner_popup_label'>Message:</label><br />

                                <Editor
                                    wrapperClassName="texteditor"
                                    editorState={editorvalue}
                                    onEditorStateChange={seteditorvalue}
                                    onContentStateChange={editorChange}
                                    handlePastedText={() => false}
                                />
                                {/* <textarea className='inner_popup_input' onChange={(e)=>{setSenderMessage(e.target.value)}} rows="4" placeholder='I joined this amazing keto diet. Let you know!' /> */}
                            </div>
                        </div>
                        {MessageErr === true &&
                            <div className='row err promotion_err_name'>
                                {MessageErrMsg}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                            Back
                        </Button>

                        <Button variant="primary" className='modal_action_button float_right' onClick={sendInvite}>
                            Send Invitation
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default Inviteuser
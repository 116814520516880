import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';

const RestAsleep = (props) => {

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [SleepDateErr, SetSleepDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [WakeDateErr, SetWakeDateErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [TimeAsleep, SetTimeAsleep] = useState(0)
    const [AhiValue, SetAhiValue] = useState('')
    const [AddDataWDate, SetAddDataWDate] = useState(new Date())
    const [TImePickerW, SetTimePickerW] = useState(formatAMPM(new Date()))

    const SaveRestAsleep = async (e) => {

        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowRestTimeAsleepField(false)
            props.SetRestOption(true)
            return false
        }
        var err=false
        SetSleepDateErr(false)
        SetWakeDateErr(false)
        if(AddDataDate===null || TImePicker===null)
        {
         err=true;
         SetSleepDateErr(true)
        }
        if(AddDataWDate===null || TImePickerW===null)
        {
            err=true;
            SetWakeDateErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
            var EndDate = ''
            if (AddDataWDate != null) {

                EndDate = ChangeDatePickerDate(AddDataWDate)
            }
            const response = await axiosJWT.post('add-data/add-rest',
            {

                'duration': TimeAsleep,
                'time': TImePicker,
                'type': 'Rest Asleep',
                'start_date':passDate,
                'start_time':TImePicker,
                'end_date':EndDate,
                'end_time':TImePickerW,
                'ahi':AhiValue,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowRestTimeAsleepField(false)
                    props.SetRestOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }


        }

    }

    const SetAddDataDatef = (value) => {

        SetAddDataDate(value)

        if (value !== null) {
            var sleepDate = ChangeDatePickerDate(value)
            if (sleepDate !== null && TImePicker !== null && AddDataWDate !== null && TImePickerW !== null) {
               var Diff= CheckDuration(sleepDate, TImePicker, ChangeDatePickerDate(AddDataWDate), TImePickerW)
               console.log(Diff)
               document.querySelector('.time_asleep_input').value = Diff;
               SetTimeAsleep(Diff)
            }


        }
        else{
            document.querySelector('.time_asleep_input').value = 0;
            SetTimeAsleep(Diff)
        }


    }
    const SetTimePickerf = (value) => {
      
        SetTimePicker(value)
        if(value!==null)
        {
            if(AddDataDate!=null && value!==null && AddDataWDate!==null && TImePickerW!==null)
            {
                var Diff= CheckDuration(ChangeDatePickerDate(AddDataDate), value, ChangeDatePickerDate(AddDataWDate), TImePickerW)
                console.log(Diff)
                document.querySelector('.time_asleep_input').value = Diff;
                SetTimeAsleep(Diff)
            }
           
        }
        else{
            document.querySelector('.time_asleep_input').value = 0;
            SetTimeAsleep(Diff)
        }
    }
    const SetAddDataWDatef = (value) => {
        
        SetAddDataWDate(value)
        if(value!==null)
        {
            var WakeDate = ChangeDatePickerDate(value)
            if(AddDataDate!==null && TImePicker!=null && value!==null && TImePickerW!==null)
            {
                var Diff= CheckDuration(ChangeDatePickerDate(AddDataDate), TImePicker, WakeDate, TImePickerW)
                console.log(Diff)
                document.querySelector('.time_asleep_input').value = Diff;
                SetTimeAsleep(Diff)
            }
        }
        else{
            document.querySelector('.time_asleep_input').value = 0;
            SetTimeAsleep(Diff)
        }
    }
    const SetTimePickerWf = (value) => {
         SetTimePickerW(value)
         if(value!==null)
         {
            if(AddDataDate!==null && TImePicker!==null && AddDataWDate!==null && TImePickerW!==null)
            {
                var Diff= CheckDuration(ChangeDatePickerDate(AddDataDate), TImePicker, ChangeDatePickerDate(AddDataWDate), value)
                console.log(Diff)
                document.querySelector('.time_asleep_input').value = Diff;
                SetTimeAsleep(Diff)
            }
         }
         else{
            document.querySelector('.time_asleep_input').value = 0;
            SetTimeAsleep(Diff)
        }
    }

    const CheckDuration = (startdate, starttime, endate, endtime) => {

        var SleepTime = startdate + ' ' + starttime
        var wakeupTime = endate + ' ' + endtime
        var a = moment(wakeupTime);//now
        var b = moment(SleepTime);
        var DiffMinutes = a.diff(b, 'hours')
        if(DiffMinutes>=0)
        {
            return DiffMinutes
        }
        else{
            return 0
        }
      






    }




    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = ((pass.getMonth() + 1) < 10 ? '0' : '') + (pass.getMonth() + 1)
        var dd = (pass.getDate() < 10 ? '0' : '') + pass.getDate()
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
      <h6 className="heading_popup">{'Rest & Recovery > Time asleep'}</h6>
            <div className="field_top_row">
                <div className="label">Bed Date & time </div>

                <div className="field date_time_same">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDatef.bind(this)}
                        format="MM-dd-yyyy"
                    />
                </div>
                <div className="field">
                    <TimePicker onChange={SetTimePickerf.bind(this)} value={TImePicker} />
                </div>
                {SleepDateErr === true && <span className="err">Please select Sleep date & time</span>}
                
            </div>

            <div className="field_top_row">
                <div className="label">Wake up Date & time </div>

                <div className="field date_time_same">
                    <Datepicker
                        value={AddDataWDate}
                        onChange={SetAddDataWDatef.bind(this)}
                        format="MM-dd-yyyy"
                    />
                </div>
                <div className="field">
                    <TimePicker onChange={SetTimePickerWf.bind(this)} value={TImePickerW} />
                </div>
                {WakeDateErr === true && <span className="err">Please select Wake up date & time</span>}
            </div>

            <div className="field_top_row">

                <div className="two_col_field">
                    <div className="col1">
                        <div className="">


                            <div className="field">
                                <div className="label">Time Asleep(Hrs)</div>
                                <input type={'number'} className="raw_input time_asleep_input" defaultValue={TimeAsleep} disabled={true}  />
                            </div>
                        </div>
                    </div>
                    <div className="col2">

                        <div className="field_top_row">
                            <div className="field">
                                <div className="label">AHI(optional)</div>
                                <input type={'number'} className="raw_input" onChange={(e) => { SetAhiValue(e.target.value) }} defaultValue={AhiValue} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveRestAsleep(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveRestAsleep(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveRestAsleep(e) }} >
                    {'Save'}
                </Button>

            </div>
            {
                saveAddSuccess === true &&
                <div className="field_top_row">
                    <div className="label">Data Saved!</div>
                </div>
            }


        </div>
    )

}
export default RestAsleep
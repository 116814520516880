import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { format, addDays, differenceInDays } from 'date-fns';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import DummyRecipeimg from '../../../images/pexels-photo-1640777.jpeg';
import ImportedRecipePopup from '../Plans/ImportedRecipePopup';

const Mealplan = (props) => {

    // console.log(props)
    const token = localStorage.getItem("token");
    const client_id = props.data;

    var [foodMenu, setfoodMenu] = useState('breakfast');
    var [recipetab, setRecipeTabMenu] = useState('ingredients');
    const [recipeslist, setrecipeslist] = useState([]);
    const [ImportedRecipePopShow,SetImportedRecipePopShow]=useState(false)
    const [ImportedRecipeData,SetImportedRecipeData]=useState([])
   

    const [breakfastId, setbreakfastId] = useState();
    const [lunchId, setlunchId] = useState();
    const [dinnerId, setdinnerId] = useState();
    const [snackId, setsnackId] = useState();
    const [breakfastData, setbreakfastData] = useState('');
    const [lunchData, setlunchData] = useState('');
    const [dinnerData, setdinnerData] = useState('');
    const [snackData, setsnackData] = useState('')
    const [searchrecipeLibrary, setsearchrecipeLibrary] = useState('');
    const [recipedayData, setrecipedayData] = useState([]);

    const [show, setShow] = useState(false);
    const [assignshow, setassignshow] = useState(false);
    const handleClose = () => setShow(false);
    const handleassignClose = () => setassignshow(false);
    const [modalPopup, setModal] = useState();

    const [recipeData, setrecipeData] = useState('');
    const [planId, setplanId] = useState('');
    const [dayId, setdayId] = useState('');
    const [planData, setplanData] = useState([]);
    const [dayList, setdayList] = useState([]);

    const [planOptions, setplanOptions] = useState([]);
    const [assignPlan, setassignPlan] = useState('');
    const [assignplanDays, setassignplanDays] = useState('');
    const [assignedplanId, setassignedplanId] = useState('');
    const [assignedId, setassignedId] = useState('');
    const [assignedplanValue, setassignedplanValue] = useState('');
    const [message, setMessage] = useState('');
    const [PercentageArray, SetPercentageArray] = useState([]);
    const [ShowRecipeLibraryTab, SetShowRecipeLibraryTab] = useState(true)
    const [ShowImportedRecipeTab, SetShowImportedRecipeTab] = useState(false)

    const pie_options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const pie_data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(77, 77, 255)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(77, 77, 255)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };

    const axiosJWT = axios.create();

    useEffect(() => {
        load_plan(client_id);
    }, []);

    const load_plan = async (client_id) => {

        // console.log("mi: "+client_id)
        await axiosJWT.get("/meal-plan/client/" + client_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            if (response.status === 200) {
                // console.log("mi: start")
                // console.log(response.data)

                setplanId(response.data.id);

                if (response.data.breakfast_key || response.data.lunch_key || response.data.dinner_key) {
                    var getData = [];
                    var recipe_key_length = [];
                    var breakfast_exist = response.data.breakfast_key;
                    var lunch_exist = response.data.lunch_key;
                    var dinner_exist = response.data.dinner_key;

                    if (breakfast_exist)
                        recipe_key_length.push({ 'menu': 'breakfast', 'size': breakfast_exist.length });

                    if (lunch_exist)
                        recipe_key_length.push({ 'menu': 'lunch', 'size': lunch_exist.length });

                    if (dinner_exist)
                        recipe_key_length.push({ 'menu': 'dinner', 'size': dinner_exist.length });

                    const menuAscending = [...recipe_key_length].sort((a, b) =>
                        a.size > b.size ? 1 : -1,
                    );



                    if (menuAscending[0].menu === 'breakfast') {
                        breakfast_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: item.recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: dinner_exist[index].recipeid });
                        });
                    }
                    if (menuAscending[0].menu === 'lunch') {
                        lunch_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: item.recipeid, dinner_key: dinner_exist[index].recipeid });
                        });
                    }
                    if (menuAscending[0].menu === 'dinner') {
                        dinner_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: item.recipeid });
                        });
                    }

                    setrecipedayData(getData);
                }

                var meal_plan_days = response.data.days;
                var plan_start_date = response.data.date;

                //var date = format(new Date(plan_start_date),'PP');
                var date = new Date(plan_start_date);

                var day_list = [];
                for (var i = 1; i <= meal_plan_days; i++) {
                    day_list.push({ id: i, date: format(addDays(date, i), 'PP') });
                };

                setdayList(day_list);

                var sDate = new Date(format(new Date(plan_start_date), 'yyyy-M-d'));
                var todayDate = new Date(format(new Date(), 'yyyy-M-d'));
                var diff_days = differenceInDays(todayDate, sDate);


                setTimeout(() => {
                    if (diff_days <= meal_plan_days) {
                        if (diff_days === 0)
                            loadDay(1);
                        else
                            loadDay(diff_days);
                    }
                    else
                        loadDay(1);
                }, 1000);

            }
            if (response.status === 404) {
                console.log('data not found');
            }
        });
    }

    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");
        const alltabs = document.querySelectorAll(".inner_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setfoodMenu(tab_for);
        setMessage('');
        loadRecipes(tab_for, searchrecipeLibrary)
    }

    const showRecipe = async (e) => {


        const recipeId = e.target.getAttribute("data-item");
        const CheckKeyType=String(recipeId)

        if(!CheckKeyType.startsWith('FITPALIR'))
        {
            axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {

                setShow(true);
                setrecipeData(res.data);
    
                SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)]);
    
                //console.log(res.data);
            });

        }
        else{

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + recipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            SetImportedRecipeData(RecipeData.data.recipe)
            SetImportedRecipePopShow(true)

      
        }
       
       
       
        setMessage('');
    }

    const changeRecipeTab = async (e) => {


        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
        setMessage('');
    }

    const loadDay = async (day_id) => {

        const alldays = document.querySelectorAll(".day_box .box .card");
        alldays.forEach((element) => element.classList.remove('active'));


        const alltabs = document.querySelectorAll(".inner_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        document.getElementById("tab_breakfast").setAttribute("class", "active");
        setfoodMenu('breakfast');

        setdayId(day_id);
        setbreakfastData('');
        setlunchData('');
        setdinnerData('');
        setsnackData('')
        setMessage('');

        const response = await axiosJWT.get("/meal-plan/client/" + client_id + "/" + day_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // console.log("mi: ")
        // console.log(response.data)

        if (response.data.breakfast_key && response.data.lunch_key && response.data.dinner_key !== '')
            document.getElementById("day_" + day_id).setAttribute("class", "card active filled");
        else
            document.getElementById("day_" + day_id).setAttribute("class", "card active");


        if (response.data.breakfast_key !== '' && response.data.breakfast_key !== null) {


            var CheckKeyType = String(response.data.breakfast_key)

            if (!CheckKeyType.startsWith('FITPALIR')) {

                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.breakfast_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setbreakfastData(res.data);
                    setbreakfastId(response.data.breakfast_key);
                });

            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.breakfast_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid
                    var recipeImage = ''
                    var recipeTitle = RecipeData.data.recipe.recipe_name
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyRecipeimg
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setbreakfastData(BreakfastData)
                    setbreakfastId(response.data.breakfast_key)

                }





            }


        }

        if (response.data.lunch_key !== '' && response.data.lunch_key !== null) {

            var CheckKeyType = String(response.data.lunch_key)

            if (!CheckKeyType.startsWith('FITPALIR')) {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.lunch_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setlunchData(res.data);
                    setlunchId(response.data.lunch_key);
                });
            }
            else{

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.lunch_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid
                    var recipeImage = ''
                    var recipeTitle = RecipeData.data.recipe.recipe_name
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyRecipeimg
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setlunchData(BreakfastData)
                    setlunchId(response.data.lunch_key)

                }

            }
        }

        if (response.data.dinner_key !== '' && response.data.dinner_key !== null) {

            var CheckKeyType = String(response.data.dinner_key)

            if(!CheckKeyType.startsWith('FITPALIR'))
            {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.dinner_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setdinnerData(res.data);
                    setdinnerId(response.data.dinner_key);
                });

            }
            else{

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.dinner_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid
                    var recipeImage = ''
                    var recipeTitle = RecipeData.data.recipe.recipe_name
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyRecipeimg
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setdinnerData(BreakfastData)
                    setdinnerId(response.data.dinner_key)

                }

            }

            
        }

        if (response.data.snack_key !== '' && response.data.snack_key !== null) {

            var CheckKeyType = String(response.data.snack_key)

            if(!CheckKeyType.startsWith('FITPALIR'))
            {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.snack_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setsnackData(res.data);
                    setsnackId(response.data.snack_key);
                });

            }
            else{

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.snack_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid
                    var recipeImage = ''
                    var recipeTitle = RecipeData.data.recipe.recipe_name
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyRecipeimg
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setsnackData(BreakfastData)
                    setsnackId(response.data.snack_key)

                }

            }

            
        }

        loadRecipes();
    }

    const check_loadDay = (day_id) => {

        // console.log("mi: check loadday")
        // console.log(recipedayData);

        var filled = false;
        recipedayData.forEach(element => {
            if (parseInt(element.day) === parseInt(day_id)) {
                // console.log(element.day+" "+day_id+" "+element.breakfast_key)
                if (element.breakfast_key !== '' && element.lunch_key !== '' && element.dinner_key !== '')
                    filled = true;
            }
        });

        return (filled);
    }

    const LeftArrow = () => {
        const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

        return (
            <div className='left_arrow' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            </div>
        );
    }

    const RightArrow = () => {
        const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

        return (
            <div className='right_arrow' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            </div>
        );
    }

    const recipeUpdate = async (e) => {
      
        setMessage('');
        var recipeId = e.target.getAttribute("data-item");

        if (foodMenu === 'breakfast')
            setbreakfastId(recipeId);
        else if (foodMenu === 'lunch')
            setlunchId(recipeId);
        else if(foodMenu=== 'dinner')
        setdinnerId(recipeId)
        else
            setsnackId(recipeId);

          
        var CheckKeyType=String(recipeId)

        
        if(!CheckKeyType.startsWith('FITPALIR'))
        {
            axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                //console.log(res.data.NutritionInfo)
                if (foodMenu === 'breakfast') {
                    setbreakfastData(res.data);
                }
                else if (foodMenu === 'lunch') {
                    setlunchData(res.data);
                }
                else if (foodMenu==='dinner') {
                    setdinnerData(res.data);
                }
                else{
                    setsnackData(res.data)
                }
    
            });
            
        }
        else{

          

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + recipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(RecipeData.data.recipe!==null)
            {
                var BreakfastData = {};
                var recipeId = RecipeData.data.recipe.recipe_uid
                var recipeImage = ''
                var recipeTitle = RecipeData.data.recipe.recipe_name
                if (RecipeData.data.recipe.recipe_image !== '') {
                    recipeImage =  RecipeData.data.recipe.recipe_image
                }
                else{
                    recipeImage=DummyRecipeimg
                }
                BreakfastData = {
                    RecipeID: recipeId,
                    PhotoUrl: recipeImage,
                    Title: recipeTitle
                }
                if (foodMenu === 'breakfast') {
                    setbreakfastData(BreakfastData);
                }
                else if (foodMenu === 'lunch') {
                    setlunchData(BreakfastData);
                }
                else if (foodMenu==='dinner') {
                    setdinnerData(BreakfastData);
                }
                else{
                    setsnackData(BreakfastData)
                }


            }



        }
      
    }

    const setRecipe = async () => {

     

        if (breakfastId !== null && lunchId !== null && dinnerId !== null && snackId!==null && dayId !== null) {
            const response = await axiosJWT.put("/meal-plan/client/" + client_id + "/addrecipe",
                {
                    breakfast: breakfastId,
                    lunch: lunchId,
                    dinner: dinnerId,
                    snack:snackId,
                    day: dayId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            // console.log(response);

            if (response.status === 200)
                setMessage("Updated successfully!");
        }
        else
            setMessage("All 3 recipes should be choose!");

        //console.log(breakfastId+" "+lunchId+" "+dinnerId+" "+dayId);   
    }

    const loadRecipes = async (menu, search) => {



        if (!menu)
            menu = foodMenu;

        // console.log(menu)
        if (menu === 'breakfast') {


            if (search !== undefined && search !== '' && search !== null) {

                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto breakfast " + search + "&include_primarycat=breakfast&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            }

            else {

                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto&include_primarycat=breakfast&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });

            }
        }
        else if (menu === 'lunch') {
            if (search !== undefined && search !== '' && search !== null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto " + search + "&include_primarycat=maindish&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto&include_primarycat=maindish&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else if (menu === 'dinner') {
            if (search !== undefined && search !== '' && search !== null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto dinner " + search + " &photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto dinner&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else{

            if (search !== undefined && search !== '' && search !== null)
            axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto snacks " + search + " &photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                setrecipeslist(res.data.Results);
            });
        else
            axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto snacks&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                setrecipeslist(res.data.Results);
            });

        }
        ImportedRecipesList(menu, SearchImportedRecipes)


    }

    const handleShow = async (e) => {

        const assigned_Id = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");
        setassignedId(assigned_Id);

        setassignedplanId('');
        setassignedplanValue('');
        setassignplanDays('');
        setModal(datamodal);

        if (datamodal === 'plan_assign') {

            const response_plans = await axiosJWT.get("/meal-plan/", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


            if (response_plans) {
                //console.log(response_plans)
                var plan_data = response_plans.data.plans;
                var plan_list = [];

                plan_data.forEach(element => {
                    plan_list.push({ label: element.meal_plan_name + " (" + element.days + " Days)", value: element.id });
                });

                setplanOptions(plan_list);

                setassignshow(true);
            }
        }

        if (datamodal === 'plan_edit') {

            const response_plans = await axiosJWT.get("/meal-plan/", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // console.log(response_plans)
            var plan_data = response_plans.data.plans;
            var plan_list = [];

            plan_data.forEach(element => {
                plan_list.push({ label: element.meal_plan_name + " (" + element.days + " Days)", value: element.id });
            });


            setplanOptions(plan_list);

            const response = await axiosJWT.get('/meal-plan/assign/' + assigned_Id,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });



            setassignedplanId(response.data.meal_plan_id);
            setassignedplanValue(response.data.meal_plan_name);
            setassignplanDays(response.data.days);
            setassignshow(true);
        }

    }

    const updateassignPlan = async (e) => {

        if (!assignPlan) {
            var filtered_assign_label = assignedplanValue;
            var assign_plan_id = assignedplanId;
        }
        else {
            var assign_label = assignPlan.label;
            var position_bracket = (assign_label.indexOf("(") - 1);
            var filtered_assign_label = assign_label.substring(0, position_bracket);

            var assign_plan_id = assignPlan.value;
        }

        if (assign_plan_id !== '' && filtered_assign_label !== '') {
            const response = await axiosJWT.put("/meal-plan/assign/" + assign_plan_id,
                {
                    meal_plan_id: assign_plan_id,
                    meal_plan_name: filtered_assign_label,
                    client_id: client_id,
                    days: assignplanDays
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleassignClose();
            }
        }
    }

    const createassignPlan = async () => {

        var assign_label = assignPlan.label;
        var position_bracket = (assign_label.indexOf("(") - 1);
        var filtered_assign_label = assign_label.substring(0, position_bracket);

        if (assignPlan.value !== '' && filtered_assign_label !== '') {
            const response = await axiosJWT.post("/meal-plan/assign",
                {
                    meal_plan_id: assignPlan.value,
                    meal_plan_name: filtered_assign_label,
                    client_id: client_id,
                    days: assignplanDays
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleassignClose();
            }
        }
    }

    const ChangeRecipeLibraryTab = (e) => {

        const tab_for = e.target.getAttribute("data-item");


        if (tab_for === 'Imported Recipes') {
            SetShowImportedRecipeTab(true)
            SetShowRecipeLibraryTab(false)
        }
        if (tab_for === 'Recipe Library') {
            SetShowRecipeLibraryTab(true)
            SetShowImportedRecipeTab(false)
        }

        const alltabs = document.querySelectorAll(".recipe_library_tab a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

    }


    const [ImportedRecipes, SetImportedRecipes] = useState([])
    const [SearchImportedRecipes, SetSearchImportedRecipes] = useState('')
    const ImportedRecipesList = async (category = '', search = '') => {

        const response = await axiosJWT.get('import-recipe/list-imported-recipe-url' + '?search=' + search + '&category=' + category + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data !== null) {
            if (response.data.list !== null) {

                SetImportedRecipes(response.data.list)
            }
        }



    }
    const SearchImportedRecipe=(e)=>{
        const SearchRecipe=e.target.value;
        ImportedRecipesList(foodMenu,SearchRecipe)
        

    }

    return (
        <div className='mealplan_tab'>

            <div className='title_block'>
                <div className='title_left'>
                    <h5>Meal Plan</h5>
                </div>
                <div className='title_right'>
                    <div className='action_buttons'>
                        <Button className='green_button btn' data-item={planId} data-modal={planId === '' ? "plan_assign" : "plan_edit"} onClick={handleShow}> {planId === '' ? "Assign A Meal Plan" : "Assign A Different Meal Plan"}</Button>
                    </div>
                </div>
            </div>
            <div style={{ "clear": "both" }} />

            {planId !== '' ?
                <div className='plan_recipe_section'>
                    <div className='day_box'>
                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onScroll={0} transitionDuration='3000' transitionBehavior='smooth'>
                            {dayList.map((element, index) => (
                                <div key={index}>
                                    <div className='box' onClick={() => loadDay(parseInt(element.id))}>
                                        <div className={check_loadDay(parseInt(element.id)) === true ? "card filled" : "card"} id={"day_" + element.id} >
                                            <div>{element.date}</div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </ScrollMenu>

                    </div>

                    <div className='menubox'>
                        <div className='inner_tabmenu'>
                            <a id="tab_breakfast" className="active" onClick={changeTab} data-item="breakfast">Breakfast</a>
                            <a onClick={changeTab} data-item="lunch">Lunch</a>
                            <a onClick={changeTab} data-item="dinner">Dinner</a>
                            <a onClick={changeTab} data-item="snacks">Snacks</a>
                        </div>
                        <div className='action_buttons'>
                            {message && <label className="actionmsg">{message}</label>}
                            <Button className='green_button btn' style={{ "width": "100px" }} onClick={setRecipe}>Set Recipe</Button>
                        </div>
                    </div>

                    <div className='selected_recipe'>
                        <div className='recipes'>
                            {foodMenu === 'breakfast' ?
                                <div className='recipe_box'>
                                    {breakfastData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={breakfastData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={breakfastData.PhotoUrl} /></div>
                                            <div className='title'>{breakfastData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {foodMenu === 'lunch' ?
                                <div className='recipe_box'>
                                    {lunchData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={lunchData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={lunchData.PhotoUrl} /></div>

                                            <div className='title'>{lunchData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {foodMenu === 'dinner' ?
                                <div className='recipe_box'>
                                    {dinnerData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={dinnerData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={dinnerData.PhotoUrl} /></div>

                                            <div className='title'>{dinnerData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                             {foodMenu === 'snacks' ?
                                <div className='recipe_box'>
                                    {snackData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={snackData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={snackData.PhotoUrl} /></div>

                                            <div className='title'>{snackData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>

                    <div style={{ "clear": "both" }} />

                    <div className='recipes_library user_recipe_library'>
                        <div className='menubox'>
                            <div className='recipe_library_tab'>
                                <a className="active" onClick={ChangeRecipeLibraryTab} data-item="Recipe Library">Recipe Library</a>
                                <a data-item="Imported Recipes" onClick={ChangeRecipeLibraryTab}>Imported Recipes</a>

                            </div>



                        </div>

                        {
                            ShowRecipeLibraryTab === true &&

                            <div>
                                <div className='leftmenu'>
                                    <div className='title'>
                                        <h4>Recipe Library <span style={{ "fontSize": "14px", "color": "gray" }}>(choose recipe)</span></h4>
                                    </div>
                                </div>
                                <div className='rightmenu'>
                                    <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => setsearchrecipeLibrary(event.target.value)} />
                                    <input className='greenbutton' type="button" value="Search" onClick={() => loadRecipes('', searchrecipeLibrary)} />
                                </div>
                                <div style={{ "clear": "both" }} />
                                <div className='recipes'>
                                    {recipeslist.map((recipe, index) => (
                                        <div className='recipe_box' key={index} >
                                            <i className='fa fa-ellipsis-v viewdata' data-item={recipe.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={recipe.PhotoUrl} data-item={recipe.RecipeID} onClick={(e) => recipeUpdate(e)} /></div>

                                            <div className='title'>{recipe.Title}</div>
                                        </div>
                                    ))}
                                </div>

                            </div>


                        }
                        {

                            ShowImportedRecipeTab === true &&

                            <div>
                                <div className='leftmenu'>
                                    <div className='title'>
                                        <h4>Imported recipes <span style={{ "fontSize": "14px", "color": "gray" }}>(choose recipe)</span></h4>
                                    </div>
                                </div>
                                <div className='rightmenu'>
                                    <input className='search' type="text" placeholder="&#xF002; Search" onChange={(e)=>{SearchImportedRecipe(e)}} />

                                </div>
                                <div style={{ "clear": "both" }} />
                                <div className='recipes'>
                                    {ImportedRecipes.map((recipe, index) => (
                                        <div className='recipe_box' key={index} >
                                            <i className='fa fa-ellipsis-v viewdata' data-item={recipe.recipe_uid} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'>

                                                {
                                                    recipe.recipe_image !== '' ? <img src={ recipe.recipe_image} data-item={recipe.recipe_uid} onClick={(e) => recipeUpdate(e)} /> : <img src={DummyRecipeimg} data-item={recipe.recipe_uid} onClick={(e) => recipeUpdate(e)} />
                                                }


                                            </div>

                                            <div className='title'>{recipe.recipe_name}</div>
                                        </div>
                                    ))}
                                </div>

                            </div>


                        }

                    </div>
                </div>
                : null}

            <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.Title}</div>
                            <div className='img'><img src={recipeData.PhotoUrl} /></div>
                            <div className='nutrition_data'>
                                <p className='pie_chart_kcal_data'>Calories: {(recipeData.NutritionInfo.TotalCalories).toFixed(2)} Kcal</p>
                                <Pie data={pie_data} options={pie_options} />

                                <hr />
                                <div className="pie_chart_bottom_div">
                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalCarbs).toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalFat).toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.Protein).toFixed(2)}</span><span>G</span></span>
                                    </span>
                                </div>

                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <ul>
                                        {(recipeData.Ingredients).map((ing, index) => (
                                            <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                        ))}
                                    </ul>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                    <ol>
                                        {(recipeData.Steps).map((ing, index) => (
                                            <li key={index}>{ing.Text}</li>
                                        ))}
                                    </ol>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button' data-item={planId} onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={assignshow} onHide={handleassignClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Meal Plan To Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Choose Plan:</label><br />
                            <Select
                                defaultValue={{ label: assignedplanValue, value: assignedplanId }}
                                options={planOptions}
                                onChange={setassignPlan}
                            />
                            {planOptions.length === 0 ?
                                <Link to="/coach/plans" style={{ float: "right" }}>Create a meal plan</Link> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label'>Days:</label><br />
                            <input placeholder="Leave it as empty for all days" type='text' className='inner_popup_input' defaultValue={assignplanDays} onChange={(e) => { setassignplanDays(e.target.value) }}></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleassignClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={assignedId} onClick={(e) => { modalPopup === 'plan_assign' ? createassignPlan(e) : updateassignPlan(e) }}>
                        {modalPopup === 'plan_assign' ? "Assign Plan" : "Change Plan"}
                    </Button>

                </Modal.Footer>
            </Modal>
            {
    ImportedRecipePopShow===true ? <ImportedRecipePopup ImportedRecipeData={ImportedRecipeData}  SetImportedRecipePopShow={SetImportedRecipePopShow} ImportedRecipePopShow={ImportedRecipePopShow}   /> :null
}
        </div>
    )
}

export default Mealplan
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import Datepicker from 'react-date-picker';
import ReactToPrint from 'react-to-print';
import Pagination from "react-js-pagination";

const RefundHistory = () => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [PerPage, SetPerPage] = useState(50);
    const [RefundHistoryList, SetRefundHistoryList] = useState([])
    const [TotalRecord, SetTotalRecord] = useState(0)
    const [Pagenumber, SetPagenumber] = useState(1)
    const componentRef = useRef();
    const [FromDate, SetFromDate] = useState('')
    const [ToDate, SetToDate] = useState('')

    const GetRefundHistory = async (pagenumber, startdate = '', enddate = '') => {
        SetPagenumber(pagenumber)

        const RefundHistoryResponse = await axiosJWT.get('payments/refund-history-list?offset=' + pagenumber + '&limit=' + PerPage + '&startdate=' + startdate + '&enddate=' + enddate + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (RefundHistoryResponse.data !== null) {
            if (RefundHistoryResponse.data.list !== null) {
                SetRefundHistoryList(RefundHistoryResponse.data.list)
            }
            SetTotalRecord(RefundHistoryResponse.data.count)
        }

    }

    const changePageNumber = (pagenumber) => {
        GetRefundHistory(pagenumber, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate))
    }

    const FiterRefundData = (e) => {

        const Target = e.target.getAttribute('data-action')
        if (Target === 'filter') {
            if (FromDate !== null && ToDate !== null && FromDate !== '' && ToDate !== '') {
                GetRefundHistory(1, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate))
            }
        }
        else if (Target === 'reset') {
            SetFromDate('')
            SetToDate('')
            GetRefundHistory(1, '', '')
        }
        //    alert(Target)

    }

    useEffect(() => {
        GetRefundHistory(1, '', '')
    }, [])

    function formatString(string) {
        let result = string.replace("_", " ");
        result = result.charAt(0).toUpperCase() + result.slice(1);
        return result
    }
    const ChangeDatePickerDate = (pass) => {

        if (pass !== '') {
            var passDate = ''
            var yyyy = pass.getFullYear().toString();
            var mm = (pass.getMonth() + 1).toString();
            var dd = pass.getDate().toString();
            passDate = yyyy + '-' + mm + '-' + dd
            return passDate
        }
        else {
            return ''
        }


    }



    return (
        <div className='container admin_billing_refund_history'>
            <div className='row subscriber_div'>

                <div className='filter_div_admin_payment_history'>
                    <div className="col">
                        <div style={{float:"left"}}>
                            <div className="second_section_payment_span">From:</div>
                            <Datepicker
                                value={FromDate}
                                onChange={SetFromDate}
                                format="MM-dd-yyyy"
                                className={'date_picker_payment_coach sdate'}
                            />
                        </div>
                        <div style={{float:"left"}}>
                            <div className="second_section_payment_span">To:</div>
                            <Datepicker
                                value={ToDate}
                                onChange={SetToDate}
                                format="MM-dd-yyyy"
                                className={'date_picker_payment_coach'}
                            />
                        </div>
                    </div>
                    <div className="col buttons">
                        <ReactToPrint
                            trigger={() => <button className='payment_coach_filter_button_admin'>Export PDF</button>}
                            content={() => componentRef.current}
                        />
                        <button className='payment_coach_filter_button_admin' data-action="filter" onClick={(e) => { FiterRefundData(e) }}>Filter</button>
                        <button className='payment_coach_filter_button_admin' data-action="reset" onClick={(e) => { FiterRefundData(e) }}>Reset</button>
                    </div>
                </div>
            </div>

                <Table className='table is-striped is-fullwidth' ref={componentRef}>

                    <Thead className='thead'>
                        <Tr>
                            <Th className='row_name'>Reference id</Th>
                            <Th className='row_name'>User Name</Th>
                            <Th className='row_name'>Refund For</Th>
                            <Th className='row_name'>Refund Amount</Th>
                            <Th className='row_name'>Refund Date</Th>
                            <Th className='row_name'>Refund status</Th>

                        </Tr>
                    </Thead>

                    <Tbody className='tbody'>

                        {RefundHistoryList.length > 0 && RefundHistoryList.map((refund, index) => (

                            <Tr key={index}>
                                <Td>{refund.reference_id}</Td>
                                <Td>{refund.user.first_name + ' ' + refund.user.last_name}</Td>
                                <Td>{formatString(refund.refund_category)}</Td>
                                <Td>$ {refund.refund_amount}</Td>
                                <Td>{format(parseISO(refund.createdAt), `PP`)}</Td>
                                <Td>{refund.refund_status === 1 ? 'Succeeded' : '-'}</Td>

                            </Tr>
                        ))}


                    </Tbody>

                </Table>

            

            <div>

                {RefundHistoryList.length > 0 ?
                    <Pagination
                        activePage={Pagenumber}
                        itemsCountPerPage={PerPage}
                        totalItemsCount={TotalRecord}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                    : null}

            </div>
        </div>
    )
}
export default RefundHistory
import React, { useEffect, useState } from "react";
import axios from 'axios';
const moment = require("moment");



const RecipeOverView = (props) => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const [RecipeName, SetRecipeName] = useState('')
    const [RecipeCategory, SetRecipeCategory] = useState('Breakfast')
    const [RecipeServings, SetRecipeServings] = useState('')
    const [RecipeInstructions, SetRecipeInstructions] = useState('')
    const [RecipeUrl, SetRecipeUrl] = useState('')
    const [RecipeImg, SetRecipeImg] = useState('')
    const [RecipeIngredients, SetRecipeIngredients] = useState('')
    const [RecipeTime, SetRecipeTime] = useState('')
    const [RecipeCalories, SetRecipeCalories] = useState('')
    const [RecipeCarbs, SetRecipeCarbs] = useState('')
    const [RecipeProteins, SetRecipeProteins] = useState('')
    const [RecipeFats, SetRecipeFats] = useState('')
    const [RecipeComments, SetRecipeComments] = useState('')


    const [RecipeImgErr, SetRecipeImgErr] = useState(false)
    const [RecipeImgErrMsg, SetRecipeImgErrMsg] = useState('')


    const LoadData = (data) => {
        console.log('overview')
        console.log(data)
        if (data['@type'] !== undefined) {

            var RecipeType = false

            if (Array.isArray(data['@type'])) {
                if (data['@type'].includes('Recipe')) {
                    RecipeType = true
                }
            }
            else {

                if (data['@type'] === 'Recipe') {
                    RecipeType = true
                }
            }


            if (RecipeType === true) {
                if (data['name'] !== undefined) {
                    SetRecipeName(data['name'])
                }
                // if (data['recipeCategory'] !== undefined) {

                //     if (Array.isArray(data['recipeCategory'])) {
                //         let text = data['recipeCategory'].toString();
                //         SetRecipeCategory(text)
                //     }
                //     else {
                //         SetRecipeCategory(data['recipeCategory'])
                //     }

                // }
                if (data['recipeYield'] !== undefined) {
                    if (Array.isArray(data['recipeYield'])) {
                        SetRecipeServings(data['recipeYield'][0])
                    }
                    else {
                        SetRecipeServings(data['recipeYield'])
                    }
                }
                if (data['recipeInstructions'] != undefined) {
                    if (Array.isArray(data['recipeInstructions'])) {
                        var string = '';
                        data['recipeInstructions'].forEach((element) => {

                            if (element['text'] !== undefined) {
                                string = string + element['text'] + ' \r\n'
                            }
                            else {

                                if (element.itemListElement !== undefined) {


                                    element.itemListElement.forEach(element => {

                                        if (element.text !== undefined) {
                                            string = string + element.text + ' \r\n'
                                        }
                                        else {
                                            string = string + element + ' \r\n'
                                        }

                                    });

                                }
                                else {
                                    string = string + element + ' \r\n'
                                }

                            }


                        });
                        SetRecipeInstructions(string)
                    }
                    else {

                        if (typeof data['recipeInstructions'] === 'object') {

                            if (data['recipeInstructions'].itemListElement !== undefined) {

                                var string = ''

                                data['recipeInstructions'].itemListElement.forEach(element => {


                                    if (typeof element === 'object') {

                                        string = string + element.itemListElement.text + ' \r\n'
                                    }
                                    else {
                                        if (element.text !== undefined) {
                                            string = string + element.text + ' \r\n'
                                        }
                                        else {
                                            string = string + element + ' \r\n'
                                        }
                                    }




                                });
                                SetRecipeInstructions(string)

                            }

                        }
                        else {
                            SetRecipeInstructions(data['recipeInstructions'])
                        }

                    }
                }
                if (data['image'] !== undefined) {
                    if (Array.isArray(data['image'])) {


                        var length = data['image'].length;
                        var ImgSrc = data['image'][length - 1]


                        if (typeof ImgSrc === 'object') {

                            if (Array.isArray(ImgSrc)) {
                                var length = ImgSrc.length
                                var ImgSource = ImgSrc[length - 1]
                                getimgBase64(ImgSource, function (dataUrl) {

                                    SetRecipeImg(dataUrl)

                                });


                            }
                            else {
                                getimgBase64(ImgSrc.url, function (dataUrl) {

                                    SetRecipeImg(dataUrl)

                                });
                            }




                        }
                        else {


                            getimgBase64(ImgSrc, function (dataUrl) {

                                SetRecipeImg(dataUrl)

                            });

                        }



                    }
                    else if (typeof data['image'] === 'object') {

                        getimgBase64(data['image'].url, function (dataUrl) {
                            SetRecipeImg(dataUrl)

                        });
                    }
                    else {

                        getimgBase64(data['image'], function (dataUrl) {
                            SetRecipeImg(dataUrl)

                        });

                    }
                }

                if (data['recipeIngredient'] !== undefined) {

                    if (Array.isArray(data['recipeIngredient'])) {
                        var string = '';
                        data['recipeIngredient'].forEach(element => {

                            string = string + element + ' \r\n'

                        });
                        SetRecipeIngredients(string)
                    }

                }
                if (data['totalTime'] != undefined) {
                    const d = moment.duration(data['totalTime']);
                    SetRecipeTime(d.minutes())
                }
                else if (data['cookTime'] !== undefined) {
                    const d = moment.duration(data['cookTime']);
                    SetRecipeTime(d.minutes())
                }

                if (data['nutrition'] != undefined) {
                    console.log('nutritionnn')
                    console.log(data['nutrition'])
                    if (data['nutrition']['calories'] !== undefined) {
                        SetRecipeCalories(data['nutrition']['calories'])
                    }
                    if (data['nutrition']['proteinContent'] !== undefined) {
                        SetRecipeProteins(data['nutrition']['proteinContent'])
                    }
                    if (data['nutrition']['fatContent'] !== undefined) {
                        SetRecipeFats(data['nutrition']['fatContent'])
                    }
                    if (data['nutrition']['carbohydrateContent'] !== undefined) {
                        SetRecipeCarbs(data['nutrition']['carbohydrateContent'])
                    }
                }



            }
        }
        else {

            if (data['name'] !== undefined) {
                SetRecipeName(data['name'])
            }
            // if (data['recipeCategory'] !== undefined) {
            //     if (Array.isArray(data['recipeCategory'])) {
            //         let text = data['recipeCategory'].toString();
            //         SetRecipeCategory(text)
            //     }
            //     else {
            //         SetRecipeCategory(data['recipeCategory'])
            //     }
            // }
            if (data['recipeYield'] !== undefined) {
                if (Array.isArray(data['recipeYield'])) {
                    SetRecipeServings(data['recipeYield'][0])
                }
                else {
                    SetRecipeServings(data['recipeYield'])
                }
            }
            if (data['recipeInstructions'] != undefined) {

                if (Array.isArray(data['recipeInstructions'])) {
                    var string = '';
                    data['recipeInstructions'].forEach((element) => {

                        if (element['text'] !== undefined) {
                            string = string + element['text'] + ' \r\n'
                        }
                        else {

                            if (element.itemListElement !== undefined) {



                                element.itemListElement.forEach(element => {

                                    if (typeof element === 'object') {

                                        string = string + element.itemListElement.text + ' \r\n'
                                    }
                                    else {
                                        if (element.text !== undefined) {
                                            string = string + element.text + ' \r\n'
                                        }
                                        else {
                                            string = string + element + ' \r\n'
                                        }
                                    }

                                });

                            }
                            else {
                                string = string + element + ' \r\n'
                            }

                        }


                    });
                    SetRecipeInstructions(string)
                }
                else {

                    if (typeof data['recipeInstructions'] === 'object') {

                        if (data['recipeInstructions'].itemListElement !== undefined) {

                            var string = ''

                            data['recipeInstructions'].itemListElement.forEach(element => {


                                if (element.text !== undefined) {
                                    string = string + element.text + ' \r\n'
                                }
                                else {
                                    string = string + element + ' \r\n'
                                }

                            });
                            SetRecipeInstructions(string)

                        }

                    }
                    else {
                        SetRecipeInstructions(data['recipeInstructions'])
                    }
                }
            }
            if (data['image'] !== undefined) {
                if (Array.isArray(data['image'])) {

                    var length = data['image'].length;
                    var ImgSrc = data['image'][length - 1]

                    if (typeof ImgSrc === 'object') {
                        if (Array.isArray(ImgSrc)) {
                            var length = ImgSrc.length
                            var ImgSource = ImgSrc[length - 1]
                            getimgBase64(ImgSource, function (dataUrl) {

                                SetRecipeImg(dataUrl)

                            });


                        }
                        else {
                            getimgBase64(ImgSrc.url, function (dataUrl) {

                                SetRecipeImg(dataUrl)

                            });
                        }


                    }
                    else {

                        getimgBase64(ImgSrc, function (dataUrl) {

                            SetRecipeImg(dataUrl)

                        });

                    }



                }
                else if (typeof data['image'] === 'object') {

                    getimgBase64(data['image'].url, function (dataUrl) {
                        SetRecipeImg(dataUrl)

                    });
                }
                else {

                    getimgBase64(data['image'], function (dataUrl) {
                        SetRecipeImg(dataUrl)

                    });

                }
            }

            if (data['recipeIngredient'] !== undefined) {

                if (Array.isArray(data['recipeIngredient'])) {
                    var string = '';
                    data['recipeIngredient'].forEach(element => {

                        string = string + element + ' \r\n'

                    });
                    SetRecipeIngredients(string)
                }

            }
            if (data['totalTime'] != undefined) {
                const d = moment.duration(data['totalTime']);
                SetRecipeTime(d.minutes())
            }
            else if (data['cookTime'] !== undefined) {
                const d = moment.duration(data['cookTime']);
                SetRecipeTime(d.minutes())
            }

            if (data['nutrition'] != undefined) {
                console.log('nutritionnn')
                console.log(data['nutrition'])
                if (data['nutrition']['calories'] !== undefined) {
                    SetRecipeCalories(data['nutrition']['calories'])
                }
                if (data['nutrition']['proteinContent'] !== undefined) {
                    SetRecipeProteins(data['nutrition']['proteinContent'])
                }
                if (data['nutrition']['fatContent'] !== undefined) {
                    SetRecipeFats(data['nutrition']['fatContent'])
                }
                if (data['nutrition']['carbohydrateContent'] !== undefined) {
                    SetRecipeCarbs(data['nutrition']['carbohydrateContent'])
                }
            }

        }

    }

    function getimgBase64(url, callback) {
        var xhr = new XMLHttpRequest();

        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);

        xhr.responseType = 'blob';
        xhr.send();
    }

    const SetBlogImage = (event) => {
        SetRecipeImgErr(false)
        const file = event.target.files[0];

        if (!/^image\//.test(file.type)) {
            SetRecipeImgErr(true)
            SetRecipeImgErrMsg(`File ${file.name} is not an image.`)
            return false;
        }
        else {

            var reader = new FileReader();
            reader.onloadend = function () {
                SetRecipeImg(reader.result)

            }
            reader.readAsDataURL(file);

        }

    }

    //save recipe


    const SaveRecipe = async () => {

      var Calories=0;
      var Carbs=0;
      var Proteins=0;
      var Fats=0;

      if(RecipeCalories!=='')
      {
        Calories=FetchNum(RecipeCalories)
      }
      if(RecipeCarbs!=='')
      {
        Carbs=FetchNum(RecipeCarbs)
      }
      if(RecipeProteins!=='')
      {
        Proteins=FetchNum(RecipeProteins)
      }
      if(RecipeFats!=='')
      {
        Fats=FetchNum(RecipeFats)
      }
        ShowLoader()
        const response = await axiosJWT.post('/import-recipe/save-import-recipe-url',
            {
                recipe_image: RecipeImg,
                recipe_name: RecipeName,
                recipe_category: RecipeCategory,
                recipe_time: RecipeTime,
                recipe_servings: RecipeServings,
                recipe_instructions: RecipeInstructions,
                recipe_ingredients: RecipeIngredients,
                recipe_calories: Calories,
                recipe_proteins: Proteins,
                recipe_fats: Fats,
                recipe_carbs: Carbs,
                recipe_comments: RecipeComments,
                recipe_source_url: RecipeUrl


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            HideLoader()
            props.ClearSearchValue()
            props.CloseImportRecipeSection()

        }



    }




    //save recipe end


    const FetchNum = (thestring) => {


        if(typeof thestring==='string')
        {
            var txt = thestring
            var numb = txt.match(/\d/g);
            if (numb !== null) {
                numb = numb.join("");
                return parseFloat(numb)
    
            }
            else {
                return 0
            }

        }
        else{
            return thestring 
        }

      


    }





    useEffect(() => {
        if (props.RecipeData !== undefined) {
            LoadData(props.RecipeData)
        }
        if (props.RecipeUrl !== undefined) {
            SetRecipeUrl(props.RecipeUrl)
        }

    }, [props])

    return (
        <div>
                 {loader === true && <div id="semiTransparenDivLoader"></div>}
            <div className="recipe_overview_section">

                <div className="input_row">
                    <span className="title">Recipe Image</span>
                    <input type={'file'} accept="image/*" onChange={SetBlogImage} />
                    <img className="preview_recipe_img" src={RecipeImg} />
                    {
                        RecipeImgErr === true &&
                        <span className="err">{RecipeImgErrMsg}</span>
                    }
                </div>

                <div className="input_row">
                    <span className="title">Recipe Name</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeName} onChange={(e) => { SetRecipeName(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Category</span>
                    <select className="select_drop" defaultValue={RecipeCategory} onChange={(e) => { SetRecipeCategory(e.target.value) }} >
                        <option value={'Breakfast'}>Breakfast</option>
                        <option value={'Lunch'}>Lunch</option>
                        <option value={'Dinner'}>Dinner</option>
                        <option value={'Snacks'}>Snacks</option>
                    </select>
                    {/* <input type={'text'} className={'input_text'} defaultValue={RecipeCategory} onChange={(e) => { SetRecipeCategory(e.target.value) }} /> */}
                </div>
                <div className="input_row">
                    <span className="title">Preparation time(Total minutes)</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeTime} onChange={(e) => { SetRecipeTime(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Servings</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeServings} onChange={(e) => { SetRecipeServings(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Preparation/Cooking Instructions</span>
                    <textarea className="input_textarea" rows={'8'} placeholder={'e.g. 1. heat oven, 2.  boil water, 3. cook for 25 minutes, etc'} defaultValue={RecipeInstructions} onChange={(e) => { SetRecipeInstructions(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Recipe Ingredients</span>
                    <textarea className="input_textarea" rows={'12'} placeholder={'e.g. 1. heat oven, 2.  boil water, 3. cook for 25 minutes, etc'} defaultValue={RecipeIngredients} onChange={(e) => { SetRecipeIngredients(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Calories</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeCalories} onChange={(e) => { SetRecipeCalories(e.target.value) }} placeholder={'value in kcal'} />
                </div>
                <div className="input_row">
                    <span className="title">Proteins</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeProteins} onChange={(e) => { SetRecipeProteins(e.target.value) }} placeholder={'value in g'} />
                </div>
                <div className="input_row">
                    <span className="title">Fats</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeFats} onChange={(e) => { SetRecipeFats(e.target.value) }} placeholder={'value in g'} />
                </div>
                <div className="input_row">
                    <span className="title">Carbs</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeCarbs} onChange={(e) => { SetRecipeCarbs(e.target.value) }} placeholder={'value in g'} />
                </div>
                <div className="input_row">
                    <span className="title">Additional comments</span>
                    <textarea className="input_textarea" rows={'6'} placeholder={'e.g. you can replace cheese with butter if necessary'} defaultValue={RecipeComments} onChange={(e) => { SetRecipeComments(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Source URL</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeUrl} onChange={(e) => { SetRecipeUrl(e.target.value) }} />
                </div>





            </div>
            <div className="recipe_action_section">

                <button className="save_recipe_button" onClick={SaveRecipe}>Save Recipe</button>

            </div>
        </div>
    )

}
export default RecipeOverView
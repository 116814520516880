import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Popup from 'reactjs-popup';
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagePlan = () => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const Numreg = new RegExp('^[0-9]+$');

    const [PackageNameErr, SetPackageNameErr] = useState(false);
    const [PackageNameMsg, SetPackageNameMsg] = useState('');

    const [PackageDurationErr, SetPackageDurationErr] = useState(false);
    const [PackageDurationMsg, SetPackageDurationMsg] = useState('');

    const [PackageAllowedClientsErr, SetPackageAllowedClientsErr] = useState(false);
    const [PackageAllowedClientsMsg, SetPackageAllowedClientsMsg] = useState('');

    const [PackageAmountErr, SetPackageAmountErr] = useState(false);
    const [PackageAmountMsg, SetPackageAmountMsg] = useState('');


    const add_admin_plan = () => {
       SetDisabledAmount(false);
       SetDisabledDuration(false);

        setAddPlanPopup(true);
        setUpdateId('');
        setAddPlanPopuperr(false);
        SetPackageNameErr(false);
        SetPackageDurationErr(false);
        SetPackageAllowedClientsErr(false);
        SetPackageAmountErr(false);
        SetPackageStatus(true)
    }

    const add_package_submit_button = async (e) => {

        SetPackageNameErr(false);
        SetPackageDurationErr(false);
        SetPackageAllowedClientsErr(false);
        SetPackageAmountErr(false);

        PackageNameRef.current.classList.remove('err_border');      
        PackageClientsRef.current.classList.remove('err_border');
        PackageAmountRef.current.classList.remove('err_border');

        if (ManagePopupLabelSubmit === 'Add') {
            var PackageNameFinal = PacakageName;
            var PackageDurationFinal = PacakageDuration;
            var PackageUsersFinal = PacakageUsers;
            var PackageAmountFinal = PacakageAmount;

            if (PackageNameFinal === '' || PackageDurationFinal === '' || PackageUsersFinal === '' || PackageAmountFinal === '') {

                if (PackageNameFinal === '') {
                    SetPackageNameMsg('Please enter package name');
                    PackageNameRef.current.classList.add('err_border');
                    SetPackageNameErr(true);
                }
                if (PackageDurationFinal === '') {
                    SetPackageDurationMsg('Please select package duration');              
                    SetPackageDurationErr(true);
                }
                if (PackageUsersFinal === '') {
                    SetPackageAllowedClientsMsg('Please enter allowed users');
                    PackageClientsRef.current.classList.add('err_border');
                    SetPackageAllowedClientsErr(true);
                }
                if (PackageAmountFinal === '') {
                    SetPackageAmountMsg('Please enter package amount');
                    PackageAmountRef.current.classList.add('err_border');
                    SetPackageAmountErr(true);
                }
                //  setAddPlanPopuperr(true)
            }
            else {

                var err=false;

                if(PackageAmountFinal!=='')
                {                 
                    if (!Numreg.test(PackageAmountFinal)) {
                        SetPackageAmountErr(true);
                        SetPackageAmountMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(PackageUsersFinal!=='')
                {                 
                    if (!Numreg.test(PackageUsersFinal)) {
                        SetPackageAllowedClientsErr(true);
                        SetPackageAllowedClientsMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(err===false)
                {
                    setAddPlanPopuperr(false)
                    const response = await axiosJWT.post('/service-plan',
                        {
                            service_name: PackageNameFinal,
                            service_duration: PackageDurationFinal,
                            allowed_users: PackageUsersFinal,
                            service_amount: PackageAmountFinal    ,
                            plan_status:PackageStatus
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        setAddPlanPopup(false);
                        setPackageName('');
                        setPackageDuration('');
                        setPackageUsers('');
                        setPackageAmount('');
                        setPageNumberManagePlan(1);
                        getManagePlan(1);    
                    }
                }              
            }
        }

        if (ManagePopupLabelSubmit === 'Update') {
            var PackageNameFinal = PacakageName;
            var PackageDurationFinal = PacakageDuration;
            var PackageUsersFinal = PacakageUsers;
            var PackageAmountFinal = PacakageAmount;


            if (PackageNameFinal === '' || PackageDurationFinal === '' || PackageUsersFinal === '' || PackageAmountFinal === '') {

                if (PackageNameFinal === '') {
                    SetPackageNameMsg('Please enter package name');
                    PackageNameRef.current.classList.add('err_border');
                    SetPackageNameErr(true);
                }
                if (PackageDurationFinal === '') {
                    SetPackageDurationMsg('Please enter package duration');              
                    SetPackageDurationErr(true);
                }
                if (PackageUsersFinal === '') {
                    SetPackageAllowedClientsMsg('Please enter allowed users');
                    PackageClientsRef.current.classList.add('err_border');
                    SetPackageAllowedClientsErr(true);
                }
                if (PackageAmountFinal === '') {
                    SetPackageAmountMsg('Please enter package amount');
                    PackageAmountRef.current.classList.add('err_border');
                    SetPackageAmountErr(true);
                }
            }
            else {
                var err=false;
                if(PackageUsersFinal!=='')
                {                 
                    if (!Numreg.test(PackageUsersFinal)) {
                        SetPackageAllowedClientsErr(true);
                        SetPackageAllowedClientsMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(err===false)
                {
                    setAddPlanPopuperr(false);
                    var EditId = e.target.getAttribute("data-id");
    
                    const response = await axiosJWT.post('/service-plan/update-service-plan',
                        {
                            service_name: PackageNameFinal,
                            service_duration: PackageDurationFinal,
                            allowed_users: PackageUsersFinal,
                            service_amount: PackageAmountFinal,
                            plan_status:PackageStatus,
                            id: EditId    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        setAddPlanPopup(false);
                        setPackageName('');
                        setPackageDuration('');
                        setPackageUsers('');
                        setPackageAmount('');
                        DsetPackageName('');
                        DsetPackageDuration('');
                        DsetPackageUsers('');
                        DsetPackageAmount('');
                        setManagePopupLabelSubmit('Add');    
    
                        setPageNumberManagePlan(PageNumberManagePlan);
                        getManagePlan(PageNumberManagePlan);    
                    }
                }
            }
        }
    }

    const deleteManagePlan = async (e) => {
        SetDeleteErr(false);

        try{
            const response = await axiosJWT.post('/service-plan/delete',
            {
                id: DeleteManagePlanId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response) {
                setPageNumberManagePlan(PageNumberManagePlan);
                getManagePlan(PageNumberManagePlan);
                modalCloseDelete();
            }
        }
        catch(err)
        {
            SetDeleteErr(true);
            SetDeleteerrmsg(err.response.data.message);          
        }
    }

    const DeleteManagePlanTrigger=(e)=>{
        var DeleteId = e.target.getAttribute("data-id");
        SetDeleteManagePlanId(DeleteId);       
        SetShowDeletePopup(true);
    }

    const modalCloseDelete=()=>{
        SetShowDeletePopup(false);
    }

    const [ShowDeletePopup,SetShowDeletePopup]=useState(false);
    const [DeleteManagePlanId,SetDeleteManagePlanId]=useState('');
    const [DeleteErr,SetDeleteErr]=useState(false);
    const [Deleteerrmsg,SetDeleteerrmsg]=useState('');
    const [DPacakageName, DsetPackageName] = useState('');
    const [DPacakageDuration, DsetPackageDuration] = useState('');
    const [DPacakageUsers, DsetPackageUsers] = useState('');
    const [DPacakageAmount, DsetPackageAmount] = useState('');

    const editManagePlan = async (e) => {
        setManagePopupLabelSubmit('Update');
        var EditId = e.target.getAttribute("data-id");
        setAddPlanPopuperr(false);
        setUpdateId(EditId);

        SetPackageNameErr(false);
        SetPackageDurationErr(false);
        SetPackageAllowedClientsErr(false);
        SetPackageAmountErr(false);
        SetDisabledAmount(true);
        SetDisabledDuration(true);


        const PlanList = await axiosJWT.get('/service-plan?id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((PlanList) => {
            //console.log(PlanList.data.data[0])

            DsetPackageName(PlanList.data.data[0].service_plan_name);
            DsetPackageDuration(PlanList.data.data[0].service_plan_duration);
            DsetPackageUsers(PlanList.data.data[0].service_plan_allowed_users);
            DsetPackageAmount(PlanList.data.data[0].service_plan_amount);
            setPackageName(PlanList.data.data[0].service_plan_name);
            setPackageDuration(PlanList.data.data[0].service_plan_duration);
            setPackageUsers(PlanList.data.data[0].service_plan_allowed_users);
            setPackageAmount(PlanList.data.data[0].service_plan_amount);
            SetPackageStatus(PlanList.data.data[0].plan_status)
        });

        setAddPlanPopup(true);
    }

    const [ManagePlanList, setManagePlanList] = useState([]);
    const [TotalRecordManagePlan, setTotalRecordManagePlan] = useState();
    const [PageNumberManagePlan, setPageNumberManagePlan] = useState(1);
    var PerPage = 10;

    const getManagePlan = async (pagenumber) => {
        const PlanList = await axiosJWT.get('/service-plan?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((PlanList) => {
            setManagePlanList(PlanList.data.data);
            setTotalRecordManagePlan(PlanList.data.count);
        });
    }

    const changePageNumber = async (pagenumber) => {     
        setPageNumberManagePlan(pagenumber);
        getManagePlan(pagenumber);
    }

    useEffect(() => {
        getManagePlan(1);
    }, []);

    const [Addplanpopup, setAddPlanPopup] = useState(false);
    const [Addplanpopuperr, setAddPlanPopuperr] = useState(false);

    //Manage Plan form states start

    const [PacakageName, setPackageName] = useState('');
    const [PacakageDuration, setPackageDuration] = useState('');
    const [PacakageUsers, setPackageUsers] = useState('');
    const [PacakageAmount, setPackageAmount] = useState('');
    const [PackageStatus,SetPackageStatus]=useState(true)

    const [ManagePopupLabelSubmit, setManagePopupLabelSubmit] = useState('Add');
    const [UpdateId, setUpdateId] = useState('');

    const cancel_package_button = async () => {
        setAddPlanPopup(false);
        setPackageName('');
        setPackageDuration('');
        setPackageUsers('');
        setPackageAmount('');
        DsetPackageName('');
        DsetPackageDuration('');
        DsetPackageUsers('');
        DsetPackageAmount('');
        setManagePopupLabelSubmit('Add');
    }

    const [DisabledDuration,SetDisabledDuration]=useState(false);
    const [DisabledAmount,SetDisabledAmount]=useState(false);

  const PackageNameRef=useRef(null);

  const PackageClientsRef=useRef(null);
  const PackageAmountRef=useRef(null);

    return (
        <div className='container'>
            <div className='row manage_plan_row'>
                <div className='col'>
                    <button className='last_commision_tab_button_add_plan' onClick={add_admin_plan} >Add Plan</button>
                </div>

                <Table className='table is-striped is-fullwidth'>
                    <Thead className='thead'>
                        <Tr>
                            <Th className='row_name'>Package Name</Th>
                            <Th className='row_name'>Duration</Th>
                            <Th className='row_name'>Allowed Users</Th>
                            <Th className='row_name'>Amount</Th>
                            <Th className='row_name'>Status</Th>
                            <Th className='row_name'>Last Edit</Th>
                            <Th className='row_name'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='tbody'>
                        {ManagePlanList.length > 0 && ManagePlanList.map((plan, index) => (
                            <Tr key={plan.id}>
                                <Td>{plan.service_plan_name}</Td>
                                <Td>{plan.service_plan_duration}</Td>
                                <Td>{plan.service_plan_allowed_users}</Td>
                                <Td><span>$ </span><span>{plan.service_plan_amount}</span></Td>
                                <Td>{plan.plan_status==true ? 'Active':'Inactive'}</Td>
                                <Td>{format(parseISO(plan.updatedAt), `PP`)}</Td>
                                <Td className='last_class'><i className="fa fa-edit edit_icon pointer_eff" onClick={(e) => { editManagePlan(e) }} data-id={plan.id}></i><i className="fa fa-trash pointer_eff" onClick={(e) => { DeleteManagePlanTrigger(e) }} data-id={plan.id}></i></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                {TotalRecordManagePlan ?            
                <Pagination
                    activePage={PageNumberManagePlan}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecordManagePlan}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers grayfont"
                />
                : null }
            </div>

            <Popup className="admin_manageusers_add_coach" open={Addplanpopup} position="right center" modal={true} closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="popup_body">
                    <h5 className='add_plan_label'>Add Plan</h5>
                    <form id="coach_add_fields">
                        <label className='add_plan_inner_label'>Package Name:<span style={{ color: "red" }}>*</span></label><input type="text" defaultValue={DPacakageName} name="package_name" className='add_plan_input package_name' onChange={(e) => { setPackageName(e.target.value) }} ref={PackageNameRef} />
                        <label className='add_plan_inner_label'>Duration:<span style={{ color: "red" }}>*</span></label>

                        <select className='add_plan_input package_duration' onChange={(e) => { setPackageDuration(e.target.value) }} defaultValue={DPacakageDuration} disabled={DisabledDuration} {...(PackageDurationErr===true ? {id: 'err_border'} : {})} >
                        <option value={''}>Select Duration</option>
                            <option value={1}>Monthly</option>
                            <option value={3}>Three months</option>
                            <option value={6}>Six months</option>
                            <option value={9}>Nine months</option>
                            <option value={12}>Yearly</option>
                        </select>

                       
                        
                        
                        {/* <input type="number" placeholder='(In months)' defaultValue={DPacakageDuration} name="package_duration" className='add_plan_input package_duration' onChange={(e) => { setPackageDuration(e.target.value) }} /> */}


                        <div className='row fnameerr_admin_coach err'>
                            <div className='col col1'>
                                {PackageNameErr === true &&
                                    <span style={{ paddingLeft: '26px' }}>{PackageNameMsg}</span>
                                }
                            </div>

                            <div className='col col2'>
                                {
                                    PackageDurationErr === true &&
                                    <span >{PackageDurationMsg}</span>
                                }
                            </div>
                        </div>

                        <label className='add_plan_inner_label'>Allowed Clients:<span style={{ color: "red" }}>*</span></label><input type="number" defaultValue={DPacakageUsers} name="package_users" className='add_plan_input package_users' onChange={(e) => { setPackageUsers(e.target.value) }} ref={PackageClientsRef} />
                        <label className='add_plan_inner_label'>Amount:($)<span style={{ color: "red" }}>*</span></label><input type="number" placeholder='Amount in dollars' defaultValue={DPacakageAmount} name="package_amount" className='add_plan_input package_amount' onChange={(e) => { setPackageAmount(e.target.value) }} disabled={DisabledAmount} ref={PackageAmountRef} />

                        <div className='row fnameerr_admin_coach err'>
                            <div className='col col1'>
                                {PackageAllowedClientsErr === true &&
                                    <span style={{ paddingLeft: '26px' }}>{PackageAllowedClientsMsg}</span>
                                }
                            </div>

                            <div className='col col2'>
                                {
                                    PackageAmountErr === true &&
                                    <span >{PackageAmountMsg}</span>
                                }
                            </div>
                        </div>

                        <div>
                        <label className='add_plan_inner_label'>Plan status:<span style={{ color: "red" }}>*</span></label>

<select className='add_plan_input package_duration' onChange={(e) => { SetPackageStatus(e.target.value) }} defaultValue={PackageStatus}>

    <option value={true}>Enable</option>
    <option value={false}>Disable</option>
</select>
                        </div>
                        <br></br>
                        <br></br>
                        {(Addplanpopuperr === true) ? <div className='popup_error_add_plan'>Package Name,Duration,Allowed clients,Amount is required</div> : null}
                        <div className='action_button'><input type="button" data-modal="add_coach" value={ManagePopupLabelSubmit} data-id={UpdateId} onClick={(e) => { add_package_submit_button(e) }} /> <input type="button" value="Cancel" onClick={cancel_package_button} /></div>
                    </form>
                </div>
            </Popup>

            <Popup className="admin_manageusers_delete_confirm" open={ShowDeletePopup} position="right center" modal={true} closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="popup_body">
                    <h5>Delete Plan</h5>
                    <p>Are you sure you want delete this plan package?</p>
                    {
                        DeleteErr===true && 
                        <p className='err'>{Deleteerrmsg}</p>
                    }                  
                    <input type="button" onClick={deleteManagePlan} value="Yes"/> <input type="button" onClick={modalCloseDelete} value="Cancel" />
                </div>
            </Popup>
        </div>
    )
}

export default ManagePlan
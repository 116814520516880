import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const PhysicalActivity = (props) => {
    console.log(props)

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [ExerciseType, SetExerciseType] = useState('')
    const [ExerciseSession, SetExerciseSession] = useState('')
    const [ExercisePreceivedEffort, SetExercisePreceivedEffort] = useState('')
    const [ActivityExerciseErr, SetActivityExerciseErr] = useState(false)
    const [ActivitySessionErr, SetActivitySessionErr] = useState(false)
    const [ActivityPreceivedErr, SetActivityPreceivedErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess,SetsaveAddSuccess]=useState(false)
    const SelectedActivitytype = 'Physical Activity'
    const SaveActivity = async (e) => {
        SetsaveAddSuccess(false)
        const Action = e.target.getAttribute('data-action')

        if(Action==='cancel')
        {
            props.SetShowPhysicalActivityField(false)
            props.SetactivityOption(true)
            return false
        }
        SetActivityExerciseErr(false)
        SetActivitySessionErr(false)
        SetActivityPreceivedErr(false)
        SetDateErr(false)
        SetTimeErr(false)



        if (SelectedActivitytype === 'Physical Activity') {
            var err = false
            if (AddDataDate === null) {
                err = true
                SetDateErr(true)
            }
            if (TImePicker === null) {
                err = true
                SetTimeErr(true)
            }
            if (ExerciseType === '') {
                err = true
                SetActivityExerciseErr(true)

            }
            if (ExerciseSession === '') {
                err = true
                SetActivitySessionErr(true)

            }
            if (ExercisePreceivedEffort === '') {
                err = true
                SetActivityPreceivedErr(true)
            }
            if (err === false) {
                var passDate = ''
                if (AddDataDate != null) {

                    passDate = ChangeDatePickerDate(AddDataDate)


                }
              

                const response = await axiosJWT.post('add-data/add-activity',
                    {

                        'date': passDate,
                        'time': TImePicker,
                        'type': SelectedActivitytype,
                        'exercise_type': ExerciseType,
                        'session_mins': ExerciseSession,
                        'precieved_effort': ExercisePreceivedEffort,
                        'notes': Notes,
                        'client_id':IsClientid


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response.status === 200) {
                    if(Action==='save')
                    {
                        props.SetShowPhysicalActivityField(false)
                        props.SetactivityOption(true)
                  
                    }
                    else if(Action==='save_add')
                    {
                        SetsaveAddSuccess(true)
                        setTimeout(() => {
                            SetsaveAddSuccess(false)   
                        }, 1000);
                    }
                    
                }



            }
        }



    }
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }
    return (

        <div className="physical_activity">

          <h6 className="heading_popup">{'Activity > Physical Activity'}</h6>
               <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Types of exercise</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetExerciseType(e.target.value) }} {...(ActivityExerciseErr === true ? { id: 'err_border' } : {})}>
                        <option value="">Select type</option>
                        <option value="Cycling">Cycling</option>
                        <option value="Walking">Walking</option>
                        <option value="Running">Running</option>
                        <option value="HIIT">HIIT</option>
                        <option value="Hiking">Hiking</option>
                        <option value="Swimming">Swimming</option>
                        <option value="BasketBall">BasketBall</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Session</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetExerciseSession(e.target.value) }} {...(ActivitySessionErr === true ? { id: 'err_border' } : {})} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Preceived Effort</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetExercisePreceivedEffort(e.target.value) }} {...(ActivityPreceivedErr === true ? { id: 'err_border' } : {})}>
                        <option value="">Select Effort</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
            </div>


            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>


            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveActivity(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveActivity(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveActivity(e) }} >
                    {'Save'}
                </Button>

           </div>
            {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }


        </div>
    )
}
export default PhysicalActivity
import React, { useEffect, useState } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import axios from "axios";
import { format, parseISO } from "date-fns";

const BasicInfoForm = (props) => {
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [StatesList, SetStatesList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [userState, setuserState] = useState("");
  const [userCity, setuserCity] = useState("");

  const [FirstName,SetFirstName]=useState('')
  const [LastName,SetLastName]=useState('')
  const [Address,SetAddress]=useState('')
  const [Postal,SetPostal]=useState('')
  const [Gender,SetGender]=useState('')
  const [PhoneNumber,SetPhoneNumber]=useState('')
  const [EmailAddress,SetEmailAddress]=useState('')
  const [MaleGenderChecked,SetMaleGenderChecked]=useState(false)
  const [FemaleGenderChecked,SetFemaleGenderChecked]=useState(false)

  useEffect(()=>{
     var Err=0;
    if(FirstName==='' || FirstName===null)
    {
      Err=1;
    }
    if(LastName==='' || LastName===null)
    {
      Err=1;
    }
    if(Address==='' || Address===null)
    {
      Err=1;
    }
    if(Postal==='' || Postal===null)
    {
      Err=1;
    }
    if(Gender==='' || Gender===null)
    {
      Err=1;
    }
    if(userState==='' || userState===null)
    {
      Err=1;
    }
    if(userCity==='' || userCity===null)
    {
      Err=1;
    }

    if(Err===1)
    {
      props.DisableNextButton();
    }
    else if(Err===0)
    {
      props.EnableNextButton();
    }

 


  },[FirstName,LastName,Address,userState,userCity,Postal,Gender])

  const handleOptionChange = (e) => {
    SetGender(e.target.value);
  };

  function calculateAge(dob) {
    
    var age=0;
    if(dob!=='' && dob!=='0000-00-00')
    {
      age=format(new Date(), "yyyy") -
      format(new Date(dob), "yyyy")
    }
    else{
      age=0;
    }

    return age
    
      
        
     
}

  const LoadBasicProfileInfo = async () => {
    const response = await axiosJWT.get("/profile-details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    SetMaleGenderChecked(false);
    SetFemaleGenderChecked(false)
    props.SetFirstCallBasicInfo(true)
    if (response.data.profile_details[0].first_name !== null) {
        SetFirstName(response.data.profile_details[0].first_name);
        props.SetFirstNamePC(response.data.profile_details[0].first_name)
    }
    if(response.data.profile_details[0].user_dob!==null)
    {
    
      props.SetDobPC(calculateAge(response.data.profile_details[0].user_dob))
    }

    if (response.data.profile_details[0].last_name !== null) {
        SetLastName(response.data.profile_details[0].last_name);
        props.SetLastNamePC(response.data.profile_details[0].last_name)
    }

    if (response.data.profile_details[0].user_address !== null) {
        SetAddress(response.data.profile_details[0].user_address);
        props.SetAddressPC(response.data.profile_details[0].user_address)
    }
    if (response.data.profile_details[0].postalCode !== null) {
             
        SetPostal(response.data.profile_details[0].postalCode);
        props.SetZipPC(response.data.profile_details[0].postalCode)
    }
    if (response.data.profile_details[0].gender !== null) {
    
        SetGender(response.data.profile_details[0].gender);
        props.SetSexPC(response.data.profile_details[0].gender)
    }

    if (response.data.profile_details[0].gender  === 'MALE') {
        SetMaleGenderChecked(true);
    }
    if (response.data.profile_details[0].gender === 'FEMALE') {
        SetFemaleGenderChecked(true);
    }


    if (response.data.profile_details[0].phone !== null) {
             
        SetPhoneNumber(response.data.profile_details[0].phone);
        props.SetPhonePC(response.data.profile_details[0].phone)
    }
    if (response.data.profile_details[0].email !== null) {
             
        SetEmailAddress(response.data.profile_details[0].email);
        props.SetEmailAddressPC(response.data.profile_details[0].email)
    }





    if (response.data.profile_details[0].user_country !== null) {
      var state_list = State.getAllStates();
      var state_list_filtered = state_list.filter(
        (state) =>
          state.countryCode ===
          response.data.profile_details[0].user_country.value
      );

      var state_list_array = [];

      state_list_filtered.forEach((element) => {
        if(  element.isoCode !== 'MS' &&
        element.isoCode !== 'NM' &&
        element.isoCode !== 'LA' &&
        element.isoCode !== 'KS' )
        {
          state_list_array.push({ label: element.name, value: element.isoCode });
        }
    
      });

      SetStatesList(state_list_array);
    }

    if (response.data.profile_details[0].user_state !== null) {
    
       
      var isoCode=response.data.profile_details[0].user_state.value
      if(  isoCode !== 'MS' &&
      isoCode !== 'NM' &&
      isoCode !== 'LA' &&
      isoCode !== 'KS')
      {

        setuserState(response.data.profile_details[0].user_state);
        props.SetStatePC(response.data.profile_details[0].user_state)
  
        var city_list = City.getAllCities();
  
        var city_list_filtered = city_list.filter(
          (city) =>
            city.countryCode ===
              response.data.profile_details[0].user_country.value &&
            city.stateCode === response.data.profile_details[0].user_state.value
        );
  
        var city_list_array = [];
  
        city_list_filtered.forEach((element) => {
          city_list_array.push({ label: element.name, value: element.name });
        });
  
        setcityList(city_list_array);

      }


     
    }

    if (response.data.profile_details[0].user_city !== null) {

      if (response.data.profile_details[0].user_state !== null) {
        var isoCode=response.data.profile_details[0].user_state.value
        if(  isoCode !== 'MS' &&
        isoCode !== 'NM' &&
        isoCode !== 'LA' &&
        isoCode !== 'KS')
        {
          setuserCity(response.data.profile_details[0].user_city);
          props.SetCityPC(response.data.profile_details[0].user_city)
        }
      }
 
    }
  };

  const onchangeCity = async (e) => {
    setuserCity(e);
  };

  const filtercity = async (e) => {
    setuserState(e);

    var city_list = City.getAllCities();

    var city_list_filtered = city_list.filter(
      (city) => city.countryCode === "US" && city.stateCode === e.value
    );

    var city_list_array = [];

    city_list_filtered.forEach((element) => {
      city_list_array.push({ label: element.name, value: element.name });
    });

    if (city_list_array.length > 0) {
      setcityList(city_list_array);
      setuserCity("");
    } else {
      setuserCity(e);
      setcityList([]);
    }
  };

  useEffect(() => {
    setTimeout(() => {

  

    }, 1);
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

  }, []);

  useEffect(() => {
    if(props.FirstCallBasicInfo===false)
    {
      LoadBasicProfileInfo();
    }
    if(props.FirstCallBasicInfo===true)
    {
      if(props.FirstNamePC)
      {
        SetFirstName(props.FirstNamePC)
      }
      if(props.LastNamePC)
      {
        SetLastName(props.LastNamePC)
      }
      if(props.AddressPC)
      {
        SetAddress(props.AddressPC)
      }
      if(props.PhonePC)
      {
        SetPhoneNumber(props.PhonePC)
      }
      if(props.EmailAddressPC)
      {
        SetEmailAddress(props.EmailAddressPC)
      }
      if(props.StatePC)
      {
        var state_list = State.getAllStates();
      var state_list_filtered = state_list.filter(
        (state) =>
          state.countryCode ===
          "US"
      );

      var state_list_array = [];

      state_list_filtered.forEach((element) => {

        if(  element.isoCode !== 'MS' &&
        element.isoCode !== 'NM' &&
        element.isoCode !== 'LA' &&
        element.isoCode !== 'KS' )
        {
          state_list_array.push({ label: element.name, value: element.isoCode });
        }
      
      });

      SetStatesList(state_list_array);
      setuserState(props.StatePC)

      var city_list = City.getAllCities();

      var city_list_filtered = city_list.filter(
        (city) =>
          city.countryCode ===
            'US' &&
          city.stateCode === props.StatePC.value
      );

      var city_list_array = [];

      city_list_filtered.forEach((element) => {
        city_list_array.push({ label: element.name, value: element.name });
      });

      setcityList(city_list_array);
 
      }

      if(props.CityPC)
      {
       setuserCity(props.CityPC)
      }
      if(props.ZipPC)
      {
        SetPostal(props.ZipPC)
      }
      if(props.SexPC)
      {
        SetGender(props.SexPC)
      }

  


    }

  }, []);

  useEffect(()=>{
  props.SetFirstNamePC(FirstName)
  props.SetLastNamePC(LastName)
  props.SetAddressPC(Address)
  props.SetPhonePC(PhoneNumber)
  props.SetEmailAddressPC(EmailAddress)
  props.SetStatePC(userState)
  props.SetCityPC(userCity)
  props.SetZipPC(Postal)
  props.SetSexPC(Gender)
  },[FirstName,LastName,Address,PhoneNumber,EmailAddress,userState,userCity,Postal,Gender])

  useEffect(()=>{
    props.SetPreviousMenu('')
    props.SetNextMenu('bmi_details')
   
    
  },[Gender])
  

  return (
    <div className="daily_activity">
      <div className="head_row">
     
      </div>

      <div className="input_row">
        <div>
          <label>First Name:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <input type="text" defaultValue={FirstName} value={FirstName} onChange={(e) => { SetFirstName(e.target.value) }}></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>Last Name:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <input type="text" defaultValue={LastName} value={LastName} onChange={(e) => { SetLastName(e.target.value) }}></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>Phone Number:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          {" "}
          <input type="text" defaultValue={PhoneNumber} disabled={true}></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>Email Address:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <input type="text"  defaultValue={EmailAddress} disabled={true}></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>Address:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <input type="text" defaultValue={Address} value={Address} onChange={(e) => { SetAddress(e.target.value) }} ></input>
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>State:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <Select
            options={StatesList}
            onChange={filtercity}
            className="user_state"
            value={userState}
          />
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>City:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <Select
            options={cityList}
            onChange={onchangeCity}
            className="user_city"
            value={userCity}
          />
        </div>
      </div>

      <div className="input_row">
        <div>
          <label>Zip Code:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div>
          <input type="text" defaultValue={Postal} value={Postal} onChange={(e) => { SetPostal(e.target.value) }}></input>
        </div>
      </div>

      <div className="input_row basic_info_gender_glp_quiz">
        <div>
          <label>Sex:<span style={{ color: 'red' }}>*</span></label>
        </div>
        <div className="sex_beluga_visit">
        <input
        type="radio"
        value="MALE"
        checked={Gender === 'MALE'}
        onChange={handleOptionChange}
      />
      <label>Male</label>

      <input
        type="radio"
        value="FEMALE"
        checked={Gender === 'FEMALE'}
        onChange={handleOptionChange}
      />
      <label>Female</label>
        </div>
      </div>
    </div>
  );
};
export default BasicInfoForm;

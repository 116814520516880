import React, { useEffect, useState } from "react";

const TriglyceridesCom = (props) => {
  const [Triglycerides, SetTriglycerides] = useState("");

  const handleOptionChange = (e) => {
    SetTriglycerides(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.TriglyceridesPC) {
        SetTriglycerides(props.TriglyceridesPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetTriglyceridesPC(Triglycerides);

      var Err = 0;
      if (Triglycerides === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [Triglycerides]);

  useEffect(()=>{
    props.SetPreviousMenu('theseapply')
   
     
  },[])

  useEffect(()=>{

    if(Triglycerides==='Yes')
    {
        props.toast.error('We are sorry. You dont qualify. You are not eligible to take GLP-1 medication if it is over 600', {
            toastId: 'error1',
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          
            });
    }
    else if (Triglycerides==='No')
    {
    
        props.SetNextMenu('gastricbypass')
    }

  },[Triglycerides])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>
      <div>
          <label>
         <h6>{props.TriglyceridesPCLabel}</h6>
          </label>
        </div>
      <div className="input_row">
       
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={Triglycerides === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={Triglycerides === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

    
    </div>
  );
};
export default TriglyceridesCom;

import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Faq from "react-faq-component";


const GlpDocuments = (props) => {
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [FormLists, SetFormLists] = useState([]);
  const user_timezone = localStorage.getItem("user_timezone");

  const [ShowList,SetShowList]=useState(true)
  const [ShowDetail,SetShowDetail]=useState(false)

  const [FormAnswers,SetFormAnswers]=useState([])
  const config = {
  
    openOnload: [1],
   
   
};

  const client_id = props.client_id;

  const GetFormLists = async (pagenumber) => {

    loading_icon('show')
    SetServiceCatPageNo(pagenumber)
    try {
      const Response = await axiosJWT.get(
        "/healthie/get-document-lists?client_id=" + client_id + "&offset=" + pagenumber + "&limit=" + PerPage + "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      SetFormLists(Response.data.list);
      SetTotalRecordServiceCategory(Response.data.total)
      loading_icon('hide')

      
      console.log(Response.data);
    } catch (err) {
      SetFormLists([]);
      loading_icon('hide')
    }
  };

  const [FormName,SetFormName]=useState('')
  const [rows, setRowsOption] = useState(null);

  useEffect(() => {
    if (rows) {

      rows.forEach(element => {
        element.expand();
      });
      
    }
}, [rows]);

  const ViewForm=async(e)=>{
   
 const Id=e.target.getAttribute('data-form_id')



 const Response = await axiosJWT.get(
  "/healthie/get-document-detail?form_id=" + Id + "",
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
);

if(Response.data.link!==null && Response.data.link!=='')
{
    window.open(Response.data.link,'_blank');

}





  }

  const data = {
    title: FormName,
    rows: FormAnswers,
};

  const CloseDetail=()=>{
    SetShowDetail(false);
    SetShowList(true)
  }

  const changePageNumberSC = async (pagenumber) => {
    GetFormLists(pagenumber);

}

  const [TotalRecordServiceCategory,SetTotalRecordServiceCategory]=useState(0)
  const [ServiceCatPageNo,SetServiceCatPageNo]=useState(1)
  const [PerPage,SetPerPage]=useState(10)

  useEffect(() => {
    GetFormLists(1);
  }, []);

  const loading_icon = async (display) => {
    if (display === 'show')
        document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
    else
        document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
}

  return (
    <div>
              <div style={{ display: "none" }} id="semiTransparenDivLoader"></div> 
      {
        ShowList ==true ? 
        <>
         <Table className="table is-striped is-fullwidth">
        <Thead className="thead">
          <Tr>
            <Th>Document title</Th>
             <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody className="tbody">
          {FormLists.map((data, index) => (
           <Tr className='trow'>
            <Td>{data.display_name}</Td>
            <Td style={{cursor:'pointer'}} data-form_id={data.id} onClick={(e)=>{ViewForm(e)}}>Download</Td>
          </Tr>
          ))}
        </Tbody>
      </Table> 
      <div>

      </div>
        </>
        
       
      
      
      : null
      }
   

      {(ShowDetail===true && FormAnswers.length > 0) ?

      <> 
      <div className="glp_notes_faq_section">
        <button onClick={CloseDetail}>Back</button>
      </div>
      <Faq
      config={config}
      data={data}
          styles={{
              rowTitleColor: "#226920",
              rowContentPaddingLeft: '10px'
          }} 
          getRowOptions={setRowsOption}
      /> </>
                   
                    :null}
    </div>
  );
};
export default GlpDocuments;

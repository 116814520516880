import React, { useEffect, useState } from "react";
import MultiStep from "react-multistep";
import DailyActivityLevel from "./DailyActivityLevel";
import Meat from "./Meat";
import Veggies from "./Veggies";
import OtherFood from "./OtherFood";
import BasicInfo from "./BasicInfo";
import Tired from "./Tired";
import Digestive from "./Digestive";
import Crave from "./Crave";
import CraveMost from "./craveMost";
import Affected from "./Affected";
import Issues from "./Issues";
import RecentActivity from "./Recent";
import Goals from "./Goals";
import IdleSessionLogout from "../../IdleSessionLogout";
import moment from 'moment-timezone';
import axios from "axios";

const QuizForm = () => {
  const [ActivityLevel, SetActivityLevel] = useState("");
  const [MeatData, SetMeatData] = useState([]);
  const [VegData, SetVegData] = useState([]);
  const [OtherFoodData, SetOtherFoodData] = useState([]);
  const [TiredData, SetTiredData] = useState("");
  const [DigestiveData, SetDigestiveData] = useState("");
  const [CraveData, SetCraveData] = useState("");
  const [CraveMostData, SetCraveMostData] = useState("");
  const [AffectedData, SetAffectedData] = useState("");
  const [IssueData, SetIssueData] = useState("");
  const [RecentData, SetRecentData] = useState("");
  const [GoalsData, SetGoalsData] = useState([]);
  const [Dob, SetDob] = useState(new Date());
  const [HeightFt, SetHeightFt] = useState("");
  const [HeightIn, SetHeightIn] = useState("");
  const [WeightData, SetWeightData] = useState("");
  const [TargetWeightData, SetTargetWeightData] = useState("");
  const [ShowSubmitButton, SetShowSubmitButton] = useState(false);
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();

  const DisableNextButton = () => {
    const NextButton = document.querySelectorAll(
      ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:last-child"
    );
    NextButton[0].disabled = true;
  };

  const EnableNextButton = () => {
    const NextButton = document.querySelectorAll(
      ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:last-child"
    );
    NextButton[0].disabled = false;
  };

  const steps = [
    {
      title: "StepOne",
      component: (
        <DailyActivityLevel
          ActivityLevel={ActivityLevel}
          SetActivityLevel={SetActivityLevel}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepTwo",
      component: (
        <Meat
          MeatData={MeatData}
          SetMeatData={SetMeatData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepThree",
      component: (
        <Veggies
          VegData={VegData}
          SetVegData={SetVegData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFour",
      component: (
        <OtherFood
          OtherFoodData={OtherFoodData}
          SetOtherFoodData={SetOtherFoodData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Tired
          TiredData={TiredData}
          SetTiredData={SetTiredData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Digestive
          DigestiveData={DigestiveData}
          SetDigestiveData={SetDigestiveData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Crave
          CraveData={CraveData}
          SetCraveData={SetCraveData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <CraveMost
          CraveMostData={CraveMostData}
          SetCraveMostData={SetCraveMostData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Affected
          AffectedData={AffectedData}
          SetAffectedData={SetAffectedData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Issues
          IssueData={IssueData}
          SetIssueData={SetIssueData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <RecentActivity
          RecentData={RecentData}
          SetRecentData={SetRecentData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <Goals
          GoalsData={GoalsData}
          SetGoalsData={SetGoalsData}
          DisableNextButton={DisableNextButton}
          EnableNextButton={EnableNextButton}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
    {
      title: "StepFive",
      component: (
        <BasicInfo
          Dob={Dob}
          SetDob={SetDob}
          HeightFt={HeightFt}
          SetHeightFt={SetHeightFt}
          HeightIn={HeightIn}
          SetHeightIn={SetHeightIn}
          WeightData={WeightData}
          SetWeightData={SetWeightData}
          TargetWeightData={TargetWeightData}
          SetTargetWeightData={SetTargetWeightData}
          SetShowSubmitButton={SetShowSubmitButton}
        />
      ),
    },
  ];

  useEffect(() => {
    AddClassToButton();
  }, []);

  const AddClassToButton = () => {
    const PreviousButton = document.querySelectorAll(
      ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:first-child"
    );
    PreviousButton[0].classList.add("prev");

    const NextButton = document.querySelectorAll(
      ".main_container_quiz .quiz_form_container > div:first-child >div:last-child button:last-child"
    );
    NextButton[0].classList.add("cf");
    // NextButton[0].addEventListener("click", NextStep);
    NextButton[0].dataset.step = "daily_activity";
    NextButton[0].disabled = true;
  };

  const SubmitData=async()=>{

    var Err=false;   

    if(Dob===null || Dob==='')
    {
        Err=true
    }
    if(HeightFt===null || HeightFt==='')
    {
        Err=true   
    }
    if(HeightIn===null || HeightIn==='')
    {
        Err=true;
    }
    if(WeightData===null || WeightData==='')
    {
        Err=true;
    }
    if(TargetWeightData===null || TargetWeightData==='')
    {
        Err=true;
    }
    if(Err===false)
    {
        var DobPass = moment(Dob).format("YYYY-MM-DD");

        var Meatstring=MeatData.toString();
        var VegString=VegData.toString();
        var OtherString=OtherFoodData.toString();


        const response = await axiosJWT.post('/user/create-quiz',
        {
            activity_level:ActivityLevel,
            meat:Meatstring,
            veg:VegString,
            other:OtherString,
            tired:TiredData,
            digestive:DigestiveData,
            crave:CraveData,
            crave_most:CraveMostData,
            affected:AffectedData,
            issue:IssueData,
            recent:RecentData,
            goals:GoalsData,
            dob: DobPass,
            height_ft:HeightFt,
            height_in:HeightIn,
            weight:WeightData,
            target_weight:TargetWeightData  
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.status===200)
        {
            window.location.href = "" + window.location.origin + "/client/dashboard?quiz=updated";
        }
    }
  }

  return (
    <>
      <div className="quiz_form_container">
        <IdleSessionLogout />
        <MultiStep showNavigation={true} steps={steps} />
      </div>

      {ShowSubmitButton === true && (
        <div className="final_submit_button">
          <button onClick={SubmitData}>Submit</button>
        </div>
      )}
    </>
  );
};
export default QuizForm;

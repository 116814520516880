import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment'
import { useNavigate, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const ClientAppointment = (props) => {

    const client_id = props.data;

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const user_timezone = localStorage.getItem("user_timezone");

    const axiosJWT = axios.create();
    const navigate = useNavigate();

    const [appointmentList, setappointmentList] = useState([]);
    const [MeetingCancelId, SetMeetingCancelId] = useState();

    const [ShowSuccessbookcancel, SetShowSuccessbookcancel] = useState(false)
    const HandleCloseSuccessbookcancel = () => { SetShowSuccessbookcancel(false) }
    const HandleShowSuccessbookcancel = () => { SetShowSuccessbookcancel(true) }

    const [ShowSuccessbookcancelsuccess, SetShowSuccessbookcancelsucess] = useState(false)
    const HandleCloseSuccessbookcancelsucess = () => { SetShowSuccessbookcancelsucess(false) }
    const HandleShowSuccessbookcancelsuccess = () => { SetShowSuccessbookcancelsucess(true) }

    const [IsAdmin,SetIsAdmin]=useState(false)
    const [ClientAppointments,SetClientAppointments]=useState([])

    useEffect(() => {

        if(props.isAdmin===undefined)
        {
            load_appointments();
        }
        else{
            SetIsAdmin(true)
            GetClientAppointments()
        }
        
    }, []);

    const load_appointments = async () => {
        var response = await axiosJWT.get("/appointment/list/" + client_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

     

        setappointmentList(response.data);
    }

    const GetClientAppointments=async()=>{

        const response = await axiosJWT.get('/appointment/client-my-appointments?client_id='+client_id+'', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if(response.data!==null){
    
            SetClientAppointments(response.data.GetResponse)
          }

    }

    const joinmeeting = (e) => {
        var meeting_id = e.target.getAttribute("data-item");
    
        navigate("/video/"+meeting_id, { replace: true });        
    }

    const TriggerCancelPopup = (e) => {
        var CancelMeetingId = e.target.getAttribute("data-item");
        CancelMeetingId = parseInt(CancelMeetingId);
        SetMeetingCancelId(CancelMeetingId);
        HandleShowSuccessbookcancel();
    }

    const CancelMeeting = async (e) => {

        var CancelMeetingId = e.target.getAttribute("data-item");      
    
        const response = await axiosJWT.post('appointment/cancel-appointment',
          {
            'meeting_id': CancelMeetingId    
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
    
        if (response) {        
          HandleCloseSuccessbookcancel();
          HandleShowSuccessbookcancelsuccess();
          load_appointments();
        }
    }

    const [showfailMeet,SetshowfailMeet]=useState(false)
    const CloseFailPopupMeet=()=>{
      SetshowfailMeet(false)
    }
    const [showfailMeetMsg,SetshowfailMeetMsg]=useState('')

    const CheckMeeting=async(e)=>{
        const item=e.target.getAttribute('data-id')
        
        
        const Response=await axiosJWT.get('/appointment/get-appointment-details?id='+item+'',{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        
        if(Response.status===200)
        {
          if(Response.data.meeting_source==='Zoom meeting')
          {
    
            try{
              const ZoomResponse=await axiosJWT.get('/zoom/get-meeting?id='+item+'',{
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })
          
              if(ZoomResponse.status===200)
              {
                window.open(ZoomResponse.data.link, '_blank');
          
              }
            }catch(err)
            {
              SetshowfailMeetMsg(err.response.data.msg)
              SetshowfailMeet(true)
            }
        
            
        
            
           
          }
          else{
              window.location.href=process.env.REACT_APP_FRONTEND_URL+'/video/'+item
          }
        }
           }

    return (
        <div className='appointments_tab'>
            <h5>Upcoming Appointments</h5>

            {
                IsAdmin===false &&  
                <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Title/Description</Th>
                        <Th>Date</Th>
                        <Th>Start Time</Th>
                        <Th>End Time</Th>
                        <Th>Link</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                    {appointmentList.map((appointment, index) => (
                        <Tr className='trow' key={index}>
                            <Td>{appointment.meeting_description}</Td>
                            <Td>{moment.utc(appointment.start_time).clone().tz(user_timezone).format('MMM Do, YYYY')}</Td>
                            <Td>{moment.utc(appointment.start_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                            <Td>{moment.utc(appointment.end_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                            <Td><span className='join_meet' data-id={appointment.id} onClick={(e)=>{CheckMeeting(e)}}>Join meet</span></Td>
                            <Td><a href data-item={appointment.id} onClick={TriggerCancelPopup}>Cancel</a></Td>
                        </Tr>
                    ))}

                </Tbody>
            </Table>
            }

            {
                IsAdmin===true &&  

                <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Title/Description</Th>
                        <Th>Date</Th>
                        <Th>Start Time</Th>
                        <Th>End Time</Th>
                        <Th>Coach Name</Th>
                        
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {ClientAppointments.map((appointment, index) => (
                        <Tr className='trow' key={index}>
                            <Td>{appointment.meeting_description}</Td>
                            <Td>{moment.utc(appointment.start_time).clone().tz(user_timezone).format('MMM Do, YYYY')}</Td>
                            <Td>{moment.utc(appointment.start_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                            <Td>{moment.utc(appointment.end_time).clone().tz(user_timezone).format('h:mm A')}</Td>
                             <Td>{appointment.user.first_name+' '+appointment.user.last_name}</Td>
                           
                        </Tr>
                    ))}

                </Tbody>
            </Table>
            }

            

            <Modal show={ShowSuccessbookcancel} onHide={HandleCloseSuccessbookcancel} className="alert_book_popup">
                <Modal.Header closeButton>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body><span className="bold">Are you sure want to cancel this meeting?</span></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancel}>
                        No
                    </Button>
                    <Button variant="primary keto_link_button" data-item={MeetingCancelId} onClick={CancelMeeting}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={ShowSuccessbookcancelsuccess} onHide={HandleCloseSuccessbookcancelsucess} className="alert_book_popup">
                <Modal.Header closeButton>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body><span className="bold">You have successfully cancelled this meeting</span></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancelsucess}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showfailMeet} onHide={CloseFailPopupMeet} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Failed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {showfailMeetMsg}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailPopupMeet}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

        </div>
    )
}

export default ClientAppointment
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables, ChartOptions } from 'chart.js';
Chart.register(...registerables);


const PaymentChart = ({ Chartlabels, ChartDatas }) => {


   const state = {
      labels: Chartlabels,
      datasets: [
         {
            label: 'Earnings ',
            lineTension: 0.5,
            backgroundColor: '#09c109',
            borderColor: '#226920',
            borderWidth: 4,
            data: ChartDatas,
            // pointRadius: 0,
         }
      ]
   }
   const options = {
      scales: {
         x: {
            grid: {
               display: false,
               drawBorder: false,
            },
         },
         y: {
            grid: {
               display: false,
               drawBorder: false,
            },
            min: 0
         }
      },
      plugins: {
         legend: {
            display: false

         }
      }
   }

   return (
      <div>

         <Line
            data={state}
            options={options}
         />
      </div>
   )


}

export default PaymentChart

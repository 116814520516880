import React, {useEffect, useState} from "react";
import { NavLink } from 'react-router-dom';

var QB = require('quickblox/quickblox.min');

const AdminNav=(props)=>{



    const [messageNew, setmessageNew] = useState();

    useEffect(() => {
        setmessageNew(props.data);        
    }, [props.data]);    
    
    const disable_msgnew = () => {
        setmessageNew(false);
        disable_qb_chat_connect();
    }

    const disable_qb_chat_connect = () =>{
        QB.chat.disconnect();
    }

    return (
        <div>
        <ul className="side_nav_class" style={{paddingLeft:'0rem'}}>
        <li><NavLink to={"/admin/dashboard"} activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
        <li><NavLink to={"/admin/messages"} activeclassname="active"><i className="fa fa-envelope-o sidenav_icon" aria-hidden="true"></i><span className="side-navli" onClick={disable_msgnew}>Messages <sup className="msgnew">{messageNew === true ? "(New)" : null}</sup></span></NavLink></li>
        <li><NavLink to={"/admin/manage-users"} activeclassname="active"><i className="fa fa-users sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage Users</span></NavLink></li>
        <li ><NavLink to={"/admin/manage-appointment"} activeclassname="active"><i className="fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage Appointment</span></NavLink></li>        
        <li ><NavLink to='/admin/billing' activeclassname="active"><i className="fa fa-credit-card sidenav_icon" aria-hidden="true"></i><span className="side-navli">Billing</span></NavLink></li>
        <li ><NavLink to='/admin/payment-methods' activeclassname="active"><i className="fa fa-credit-card sidenav_icon" aria-hidden="true"></i><span className="side-navli">Payment Methods</span></NavLink></li>
        {/* <li ><NavLink to='/' activeclassname="active"><i className="fa fa-bullhorn sid  enav_icon" aria-hidden="true"></i><span className="side-navli">Advertisement</span></NavLink></li> */}
        <li ><NavLink to='/admin/notification'><i className="fa fa-bell sidenav_icon" aria-hidden="true"></i><span className="side-navli">Notification</span></NavLink></li>
        <li ><NavLink to='/admin/marketing' activeclassname="active"><i className="fa fa-line-chart sidenav_icon" aria-hidden="true"></i><span className="side-navli">Marketing</span></NavLink></li>
        <li ><NavLink to='/admin/manage-team' activeclassname="active"><i className="fa fa-tasks sidenav_icon" aria-hidden="true"></i><span className="side-navli">Managers & Team</span></NavLink></li>
        <li ><NavLink to='/admin/payout' activeclassname="active"><i className="fa fa-usd sidenav_icon" aria-hidden="true"></i><span className="side-navli">Payout</span></NavLink></li>
        <li ><NavLink to='/admin/analytics' activeclassname="active"><i className="fa fa-bar-chart sidenav_icon" aria-hidden="true"></i><span className="side-navli">Analytics</span></NavLink></li>
        <li><NavLink to='/admin/mail-templates' activeclassname="active"><i className="fa fa-file-text sidenav_icon" aria-hidden="true"></i><span className="side-navli">Mail Templates</span></NavLink></li>
        <li><NavLink to='/admin/documents' activeclassname="active"><i className="fa fa-file sidenav_icon" aria-hidden="true"></i><span className="side-navli">Documents</span></NavLink></li>
        <li ><NavLink to='/admin/questionnaire' activeclassname="active"><i className="fa fa-question-circle sidenav_icon" aria-hidden="true"></i><span className="side-navli">Questionnaire</span></NavLink></li>
        <li><NavLink to='/admin/contacts' activeclassname="active"><i className="fa fa-address-book sidenav_icon" aria-hidden="true"></i><span className="side-navli">Contact Enquiries</span></NavLink></li>
        <li><NavLink to='/admin/labtest' activeclassname="active"><i className="fa fa-database sidenav_icon" aria-hidden="true"></i><span className="side-navli">Lab Test</span></NavLink></li>
    </ul>

    <div className='addmember-logo' style={{display:'none'}}> <a href><i id="Addmember" className="fa fa-user-plus" aria-hidden="true"></i><span id="addmember">Add Members</span></a> </div>

    </div>

    )
}

export default AdminNav
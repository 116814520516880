import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import PremiumCheckComponent from "../PremiumPlanPopup";
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import female_icon from '../../../images/female.png'
import male_icon from '../../../images/male.png'

const ClientBlogs = () => {


    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const Client_id = localStorage.getItem("userId");
    const axiosJWT = axios.create();
    const [PageNumber, SetPageNumber] = useState(0)
    const [PerPage, SetPerPage] = useState(10)
    const [TotalRecordPage, SetTotalRecordPage] = useState(0)
    const [BlogList, SetBlogList] = useState([])
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");

    const [ShowList, SetShowList] = useState(true)
    const [ShowDetail, SetShowDetail] = useState(false)
    const [BlogTitle, SetBlogTitle] = useState('')
    const [BlogContent, SetBlogContent] = useState('')
    const [BLogImage, SetBlogImage] = useState('')
    const [BlogAuthorImage, SetBlogAuthorImage] = useState('')
    const [BlogAuthorName, SetBlogAuthorName] = useState('')
    const [BlogAuthorDate, SetBlogAuthorDate] = useState('')

    const GetBlogs = async (pagenumber) => {

        SetPageNumber(pagenumber)

        var passPagenumber = pagenumber - 1

        const response = await axiosJWT.get('/blog?offset=' + passPagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetTotalRecordPage(response.data.count);
            SetBlogList(response.data.result);

            // console.log(response.data.result);
        })

    }

    useEffect(() => {
        document.title = 'Client-Blogs';
        GetBlogs(1)
    }, [])

    const changePageNumberS = (pagenumber) => {
        GetBlogs(pagenumber)
    }

    const ViewDetailedView = async (e) => {

        var EditID = e.target.getAttribute('data-id')

        const response = await axiosJWT.get('/blog/' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetBlogTitle(response.data.title);
            SetBlogContent(response.data.content);
            SetShowList(false);
            SetShowDetail(true);            
        })
    }

    const ChangeTab = () => {
        SetShowDetail(false);
        SetShowList(true);
    }

    const Renderhtmltostring = (content) => {

        var Convertedstring = content.replace(/<[^>]*>?/gm, '');

        if (Convertedstring.length < 50) {
            return Convertedstring
        }
        else {
            return Convertedstring.substring(0, 47) + '...'
        }


    }

    function timetoUtcClientAppointment(passdate) {


        var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

        var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')


        return stardates



    }
    const DetailedView = async (e) => {

        var EditId = e.target.closest('.card_view_blog_container').getAttribute('data-id');

        const response = await axiosJWT.get('/blog/' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetBlogTitle(response.data.title);
        SetBlogContent(response.data.content);
        SetBlogImage(response.data.blog_img);
        SetBlogAuthorName(response.data.service_category.user.first_name + ' ' + response.data.service_category.user.last_name);
        SetBlogAuthorDate(format(new Date(timetoUtcClientAppointment(response.data.createdAt)), `MMM dd, yyyy  hh:mm a`));

        if(response.data.service_category.user.user_profile_photo)
        SetBlogAuthorImage(response.data.service_category.user.user_profile_photo);
        else
        {
            if((response.data.service_category.user.gender).toLowerCase() === 'male')
            SetBlogAuthorImage(male_icon);
            else
            SetBlogAuthorImage(female_icon);
        }

        SetShowList(false);
        SetShowDetail(true);

        window.scrollTo({ top: 0, behavior: 'smooth' });






    }

    return (


        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <PremiumCheckComponent />
            <section className="dashboard">

                {ShowList === true &&
                    <div className="container">
                        <div className="row">
                            <span className="notes_by_coach_top_row_heading">Blogs</span>
                        </div>

                        <div className="row card_view_row">

                            {BlogList.length > 0 && BlogList.map((BlogList, index) => (


                                <div className="card_view_blog_container card_view_blog_container1" data-id={BlogList.id} onClick={(e) => { DetailedView(e) }}>
                                    <div className="card_image_blog">
                                        <img src={BlogList.blog_img} alt="a brand new sports car" />
                                    </div>
                                    <div className="blog_info_block">
                                        <div>
                                            <span className="card-badge card-badge-blue">{BlogList.service_category.category_name}</span>
                                            <h1 className="blog_title">
                                                {BlogList.title}
                                            </h1>
                                            <p className="card-subtitle">
                                                {Renderhtmltostring(BlogList.content)}
                                            </p>
                                        </div>
                                        <div>
                                            <div className="card-author">
                                                <img src={BlogList.service_category.user.user_profile_photo ?  BlogList.service_category.user.user_profile_photo : ((BlogList.service_category.user.gender).toLowerCase() === 'male' ? male_icon : female_icon)} alt="author avatar" />
                                                <div className="author-info">
                                                    <p className="author-name">{BlogList.service_category.user.first_name + ' ' + BlogList.service_category.user.last_name} </p>
                                                    <p className="post-timestamp">{format(parseISO(timetoUtcClientAppointment(BlogList.createdAt)), `MMM dd, yyyy  hh:mm a`)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ))}


                        </div>
                        <div style={{ clear: "both" }}></div>

                        {TotalRecordPage > 0 &&
                            <div className="blog_pagination">
                                <Pagination
                                    activePage={PageNumber}
                                    itemsCountPerPage={PerPage}
                                    totalItemsCount={TotalRecordPage}
                                    onChange={changePageNumberS.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                            </div>}
                    </div>
                }

                {
                    ShowDetail === true &&
                    <div className="container">
                        <div ><input className="greenbutton" type="button" value="< Back" onClick={ChangeTab}/></div>
                        <div className="row blog_title_top_row">
                            
                           <div className="title">{BlogTitle}</div>
                            {/* <div className="x_mark" onClick={ChangeTab}><i className="fa fa-times" aria-hidden="true"></i></div> */}
                        </div>

                        <div className="row detailed_row_blog" id="detailed_row_blog_id">

                            
                            <div className="col2 blog_detail_img">
                                <img src={BLogImage} alt="a brand new sports car" />

                                <div className="card-author">
                                    <img src={BlogAuthorImage} alt="author avatar" />
                                    <div className="author-info">
                                        <p className="author-name">{BlogAuthorName} </p>
                                        <p className="post-timestamp">{BlogAuthorDate}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col1 blog_content_row" dangerouslySetInnerHTML={{ __html: BlogContent }}></div>
                        </div>
                    </div>
                }
            </section>

        </div>
    )
}

export default ClientBlogs
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import ImportRecipeUrl from "./ImportRecipeUrl";
import ImportedRecipeList from "./ImportedRecipeList";

const AddRecipe = () => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [loader, Setloader] = useState(false)
    const HideLoader = () => {
        Setloader(false)
    }
    const ShowLoader = () => {
        Setloader(true)
    }
    const [ShowAddRecipePopup, SetShowAddRecipePopup] = useState(false)
    const CloseAddRecipePopup = () => {
        SetShowAddRecipePopup(false)
    }
    const [ShowImportUrlPopup, SetShowImportUrlPopup] = useState(false)
    const CloseImportUrlPopup = () => {
        SetShowImportUrlPopup(false)
    }

    const TriggerAddRecipePopup = () => {
        SetShowAddRecipePopup(true)
    }
    const TriggerImportUrlPopup = () => {
        SetShowAddRecipePopup(false)
        SetImportUrlErr(false)
        SetImportUrlErrMsg('')
        SetShowImportUrlPopup(true)
        SetImportUrlShow(false)
    }

    const [ImportUrlErr, SetImportUrlErr] = useState(false)
    const [ImportUrlValue, SetImportUrlValue] = useState('')
    const [ImportUrlErrMsg,SetImportUrlErrMsg]=useState('')
    const [ImportUrlShow,SetImportUrlShow]=useState(false)
    const [ImportRecipeUrlData,SetImportRecipeUrlData]=useState()
    const [Searchvalue,SetSearchvalue]=useState('')

    const FetchRecipeByUrl = async () => {
        SetImportUrlErr(false)
        SetImportUrlErrMsg('')
        SetImportUrlShow(false)

        if (ImportUrlValue === '') {
            SetImportUrlErr(true)
        }
        else {

            ShowLoader()

            try {

 
                const response = await axiosJWT.post('/import-recipe/import-recipe-url',
                    {
                        url: ImportUrlValue,

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if(response.status===200)
                    {
                        HideLoader()
                        CloseImportUrlPopup()
                        SetImportUrlShow(true)
                        SetImportRecipeUrlData(response.data)
                
                    }

            
            } catch (err) {
                HideLoader()
                SetImportUrlErrMsg(err.response.data.msg)
                console.log(err)
            }







        }

    }

    return (

        <div>
                   {loader === true && <div id="semiTransparenDivLoader"></div>}
            <div className='title_box'>
                {/* <div className='leftmenu'>
                    <h5>Add Recipe</h5><br></br>
                </div> */}
            </div>

            {
                ImportUrlShow===false &&

                <div>
  <div className="import_recipe_div">
  <input className='search' type="text" placeholder="&#xF002; Search recipe" onChange={(e)=>{SetSearchvalue(e.target.value)}}  />
                <button className="add_recipe_button" onClick={TriggerAddRecipePopup}>Add Recipe</button>
            
                </div>
                <ImportedRecipeList Searchvalue={Searchvalue}/>
                </div>
              

            }

           


            <Modal show={ShowAddRecipePopup} onHide={CloseAddRecipePopup} className="coachservice_delete confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Add Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="popup_import_div">
                        <button className="import_url_button" onClick={TriggerImportUrlPopup}>Import From URL</button>
                    </div>


                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button className="modal_close_button float_right" variant="secondary" onClick={CloseAddRecipePopup}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={ShowImportUrlPopup} onHide={CloseImportUrlPopup} className="coachservice_delete confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Import By URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="popup_import_div">
                        <input className="popup_import_input" type={'text'} placeholder={'Paste or enter the url here'} {...(ImportUrlErr === true ? { id: 'err_border' } : {})} onChange={(e) => { SetImportUrlValue(e.target.value) }} />
                    
                    </div>
                       <div className="popup_import_err_div">
                       <span className="err_span">{ImportUrlErrMsg}</span>
                       </div>
                 <div className="popup_import_notes">
             
                    <span>1.Enter calories in (kcal) integer value in case if there is string value</span>
                    <span>2.Enter carbs in (g) integer value in case if there is string value</span>
                    <span>3.Enter fats in (g) integer value in case if there is string value</span>
                    <span>4.Enter proteins in (g) integer value in case if there is string value</span>
                    <span>5.If you see image empty or broken the site might have blocked for fetching data.upload an image in that case</span>
                 </div>
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button className="modal_close_button" variant="secondary" onClick={CloseImportUrlPopup}>
                        Close
                    </Button>

                    <Button className="modal_action_button float_right" variant="secondary" onClick={FetchRecipeByUrl}>
                        Import
                    </Button>

                </Modal.Footer>
            </Modal>

            {
                ImportUrlShow===true && <ImportRecipeUrl ImportRecipeUrlData={ImportRecipeUrlData} ImportUrlShow={ImportUrlShow} SetImportUrlShow={SetImportUrlShow} ImportUrlValue={ImportUrlValue} SetSearchvalue={SetSearchvalue} />
            }

        </div>
    )

}
export default AddRecipe
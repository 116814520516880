import React, { useEffect, useState } from "react";
import axios from "axios";
import BasicInfoForm from "./BasicInfoForm";
import BelugaMedicineDetails from "./BelugaMedicineDetails";
import Pregnant from "./Pregnant";
import PregnantCS from "./PregnantConsent";
import BreastFeeding from "./BreastFeeding";
import TheseApply from "./TheseApply";
import GallBladderCS from "./GallbladderConsent";
import GastricByPass from "./GastricBypass";
import TriglyceridesCom from "./Triglycerides";
import TellUsMoreMCCom from "./TellUsmoreMc";
import CurerntMedicationCM from "./CurrentMedication";
import GLPMedicationCM from "./GLPMedicationHistory";
import DoctorInfoCM from "./DoctorInfo";
import TruthConsentCS from "./TruthConsent";
import SideEffectCM from "./SideEffect";
import SideEffectDescCM from "./SideEffectDesc";
import GLPDoseListCM from "./GLPDoseList";
import GLPDosePrefCM from "./GLPDosePref";
import GlpExperienceCM from "./GlpExperience";
import GLpExperienceFreeCM from "./GlpExperienceFree";
import CurrentMedicalConditionsCM from "./CurrentMedicalConditions";
import AllergiesCM from "./Allergies";
import SelfReportedMedsCM from "./SelfReportedMeds";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BelugaVisitFormNew = (props) => {
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");

  const [FirstNamePC, SetFirstNamePC] = useState("");
  const [LastNamePC, SetLastNamePC] = useState("");
  const [PhonePC, SetPhonePC] = useState("");
  const [EmailAddressPC, SetEmailAddressPC] = useState("");
  const [AddressPC, SetAddressPC] = useState("");
  const [StatePC, SetStatePC] = useState("");
  const [CityPC, SetCityPC] = useState("");
  const [ZipPC, SetZipPC] = useState("");
  const [SexPC, SetSexPC] = useState("");
  const [DobPC, SetDobPC] = useState("");
  const [FirstCallBasicInfo, SetFirstCallBasicInfo] = useState(false);

  const [WeightPC, SetWeightPC] = useState("");
  const [HeightFeetPC,SetHeightFeetPC]=useState('');
  const [HeightInchPC,SetHeightInchPC]=useState('');
  const [BMIPC,SetBMIPC]=useState(0)

  const [PreganantPC,SetPreganantPC]=useState('')
  const [PreganantPCLabel,SetPreganantPCLabel]=useState('Are you currently pregnant or planning to become pregnant soon?')
  const [PregnantConsentPC,SetPregnantConsentPC]=useState(false);

  const [BreastFeedingPC,SetBreastFeedingPC]=useState('')
  const [BreastFeedingPCLabel,SetBreastFeedingPCLabel]=useState('Are you pregnant or breastfeeding?')

  const [AnythingApplyPCT,SetAnythingApplyPCT]=useState([])
  const [AnythingApplyLengthPCT,SetAnythingApplyLengthPCT]=useState(0)
  const [AnythingApplyPCTLabel,SetAnythingApplyPCTLabel]=useState('Have there been any health changes or medical changes, ED visits or hosipitalizations since your last check in?')

  const [GallBladderConsentPC,SetGallBladderConsentPC]=useState(false)

  const [GastricPassPC, SetGastricPassPC] = useState("");
  const [GastricPassPCLabel, SetGastricPassPCLabel] = useState("Have you had a gastric bypass in the past 6 months?");

  const [GlpExperiencePC, SetGlpExperiencePC] = useState("");
  const [GlpExperiencePCLabel, SetGlpExperiencePCLabel] = useState("These medications can reduce food cravings and appetite, how is this medication working for you?");

  const [TriglyceridesPC, SetTriglyceridesPC] = useState("");
  const [TriglyceridesPCLabel, SetTriglyceridesPCLabel] = useState("Have your Triglycerides over 600 at any point?");
  

  const [TellUsMoreMCDescPC, SetTellUsMoreMCDescPC] = useState("");
  const [TellUsMoreMCDescPCLabel, SetTellUsMoreMCDescPCLabel] = useState("Tell us More abour your medical condition that you have selected");

  const [GLpExperienceFreeDescPC, SetGLpExperienceFreeDescPC] = useState("");
  const [GLpExperienceFreeDescPCLabel, SetGLpExperienceFreeDescPCLabel] = useState("Please tell us more about what you’re experiencing");

  const [CurrentMedicationPC, SetCurrentMedicationPC] = useState("");
  const [CurrentMedicationPCLabel,SetCurrentMedicationPCLabel]=useState('Have you started taking any of the following medications?')
  const [CurrentMedicationPCLabelMed,SetCurrentMedicationPCLabelMed]=useState('Insulin, glimepiride(Amaryl), glipizide(GLucotrol and Glucotrol XT), glyburide(Micronase,Glynase and Diabeta), sitagliptin, saxagliptin, linagliptin and alogliptin')

  const [GLPMedicationPC, SetGLPMedicationPC] = useState("");

  const [GLPMedicationPCLabel, SetGLPMedicationPCLabel] = useState("Are you currently or have you in the past two months,taken any of the following medications?");
  

  const [DoctorInfoPC, SetDoctorInfoPC] = useState("");
  const [DoctorInfoPCLabel, SetDoctorInfoPCLabel] = useState("What other information or questions do you have for the doctor?");

  const [CurrentMedicalConditionPC, SetCurrentMedicalConditionPC] = useState("");
  const [CurrentMedicalConditionPCLabel, SetCurrentMedicalConditionPCLabel] = useState("Please describe about your current medical condition");

  const [AllergiesPC, SetAllergiesPC] = useState("");
  const [AllergiesPCLabel, SetAllergiesPCLabel] = useState("Do you have any allergies?");

  const [SelfReportedMedsPc, SetSelfReportedMedsPc] = useState("");
  const [SelfReportedMedsPcLabel, SetSelfReportedMedsPcLabel] = useState("Can you please list all the medications you are currently taking?");

  const [TruthConsentPC,SetTruthConsentPC]=useState(false)

  const [SideEffectPC, SetSideEffectPC] = useState("");
  const [SideEffectPCLabel, SetSideEffectPCLabel] = useState("Have you experienced side effects from your current medications?");

  const [SideEffectDescPC, SetSideEffectDescPC] = useState("");

  const [SideEffectDescPCLabel, SetSideEffectDescPCLabel] = useState("Please tell us more about the side effects you are experiencing");

  const [GlpDoseListPC, SetGlpDoseListPC] = useState("");
  const [GlpDoseListPCLabel, SetGlpDoseListPCLabel] = useState("Which medication and dose closely matches your recent dose?");


  const [GlpDosePrefPC, SetGlpDosePrefPC] = useState("");
  const [GlpDosePrefPCLabel, SetGlpDosePrefPCLabel] = useState("How would you like to continue treatment?");

  const [GlpMedicinePC,SetGlpMedicinePC]=useState('')
  const [GlpDosagePC,SetGlpDosagePC]=useState('')
  const [GlpDosageIdPC,SetGlpDosageIdPC]=useState('')
  const [GlpDosageQuantityPC,SetGlpDosageQuantityPC]=useState('')
  const [GlpDosageInstructionsPC,SetGlpDosageInstructionsPC]=useState('')
  const [GlpPharmacyPC,SetGlpPharmacyPC]=useState('')

  const [FinalObjPass,SetFinalObjPass]=useState({})

  const SubmitVisitForm = async() => {

    ShowLoader()

    var AxFinalObjPass=FinalObjPass;
    AxFinalObjPass['signed_consent']=TruthConsentPC

    try{
      const response = await axiosJWT.post('/beluga/create-visit-backend',
      AxFinalObjPass,
      {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      
      props.SetShowQ(false)
      props.GetClientVisitsLog(1)
      HideLoader()
    }catch(err)
    {
      HideLoader()
      toast.error(err.response.data.msg, {
        toastId: 'error1',
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      
        });
      console.log(err)
    }
 

    
  };

  const PrevStep = () => {

    var FinalObjPassIn=FinalObjPass;
    if(PreviousMenu!='')
    {
        SetCurrentMenu(PreviousMenu)
    }

    delete FinalObjPassIn[PreviousMenu];
 
    console.log('FinalObjPassIn',FinalObjPassIn)

    SetFinalObjPass(FinalObjPassIn)
  

  };
  const NextStep = () => {
    if(NextMenu!='')
    {
        SetCurrentMenu(NextMenu);
    }

    var FinalObjPassIn=FinalObjPass;

    if(CurrentMenu==='basic_info')
    {
      FinalObjPassIn[CurrentMenu]={firstname:FirstNamePC,lastname:LastNamePC,phone:PhonePC,email:EmailAddressPC,address:AddressPC,city:CityPC,state:StatePC,zip:ZipPC,sex:SexPC}
     
    }
    else if (CurrentMenu==='pregnant')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:PreganantPCLabel,
        ans:PreganantPC
      }
    }
    else if (CurrentMenu==='breastfeeding')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:BreastFeedingPCLabel,
        ans:BreastFeedingPC
      }
    }
    else if (CurrentMenu==='pregnantcs')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:'Agreed to the following breastfeeding/pregnancy precaution',
        ans:PregnantConsentPC
      }
    }
    else if (CurrentMenu==='gallbladdercs')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:'Agreed to the following gallbladder consent',
        ans:GallBladderConsentPC
      }
    }
    else if (CurrentMenu==='bmi_details')
    {
      FinalObjPassIn[CurrentMenu]={

        height:{
          ques:'Patient Height(Ft.In)',
          ans:HeightFeetPC+'.'+HeightInchPC
        },
        weight:{
          ques:'Patient Weight(lbs)',
          ans:WeightPC
        },
        bmi:{
          ques:'Patient BMI value',
          ans:BMIPC
        }
    
      }

    }
    else if (CurrentMenu==='theseapply')
    {

      FinalObjPassIn[CurrentMenu]={
        ques:AnythingApplyPCTLabel,
        ans:AnythingApplyPCT.join('; ')
      }

    }
    else if (CurrentMenu==='tellusmoremc')
    {

      FinalObjPassIn[CurrentMenu]={
        ques:TellUsMoreMCDescPCLabel,
        ans:TellUsMoreMCDescPC
      }

    }
    else if (CurrentMenu==='gastricbypass')
    {

      FinalObjPassIn[CurrentMenu]={
        ques:GastricPassPCLabel,
        ans:GastricPassPC
      }

    }
    else if (CurrentMenu==='currentmedication')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:CurrentMedicationPCLabel+'    '+CurrentMedicationPCLabelMed,
        ans:CurrentMedicationPC
      }
    }
    else if (CurrentMenu==='glpmedication')
    {
     
      const possanswers=getRadioValues('glp_medication_possible_answers')
      
      FinalObjPassIn[CurrentMenu]={
        ques:GLPMedicationPCLabel+' '+possanswers,
        ans:GLPMedicationPC
      }
    }
    else if (CurrentMenu==='glpexperience')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:GlpExperiencePCLabel,
        ans:GlpExperiencePC
      }
    }
    else if (CurrentMenu==='glpexperiencefree')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:GLpExperienceFreeDescPCLabel,
        ans:GLpExperienceFreeDescPC
      }
    }
    else if (CurrentMenu==='sideeffect')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:SideEffectPCLabel,
        ans:SideEffectPC
      }
    }
    else if (CurrentMenu==='sideeffectdesc')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:SideEffectDescPCLabel,
        ans:SideEffectDescPC
      }
    }
    else if (CurrentMenu==='glpdoselist')
    {
     
      const possanswers=getRadioValues('glpdoselist')
      
      FinalObjPassIn[CurrentMenu]={
        ques:GlpDoseListPCLabel+' '+possanswers,
        ans:GlpDoseListPC
      }
    }
    else if (CurrentMenu==='glpdosepref')
    {
     
      const possanswers=getRadioValues('glpdosepref')
      
      FinalObjPassIn[CurrentMenu]={
        ques:GlpDosePrefPCLabel+' '+possanswers,
        ans:GlpDosePrefPC,
        dosagename:GlpMedicinePC,
        dosagestrength:GlpDosagePC,
        dosagequantity:GlpDosageQuantityPC,
        dosagemedid:GlpDosageIdPC,
        dosagepharmacy:GlpPharmacyPC
      }
    }
    else if (CurrentMenu==='triglycerides')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:TriglyceridesPCLabel,
        ans:TriglyceridesPC
      }
    }
    else if (CurrentMenu==='doctorinfo')
    {
      FinalObjPassIn[CurrentMenu]={
        ques:DoctorInfoPCLabel,
        ans:DoctorInfoPC
      }
    }
    else if (CurrentMenu==='currentmedicalconditions')
      {
        FinalObjPassIn[CurrentMenu]={
          ques:CurrentMedicalConditionPCLabel,
          ans:CurrentMedicalConditionPC
        }
      }
      else if (CurrentMenu==='allergies')
        {
          FinalObjPassIn[CurrentMenu]={
            ques:AllergiesPCLabel,
            ans:AllergiesPC
          }
        }
        else if (CurrentMenu==='selfreportedmeds')
          {
            FinalObjPassIn[CurrentMenu]={
              ques:SelfReportedMedsPcLabel,
              ans:SelfReportedMedsPc
            }
          }
   
    

    

    

    console.log('FinalObjPassIn',FinalObjPassIn)

    SetFinalObjPass(FinalObjPassIn)

   
   
  };

  const [CurrentMenu, SetCurrentMenu] = useState('basic_info');
  const [NextMenu,SetNextMenu]=useState('')
  const [PreviousMenu,SetPreviousMenu]=useState('')

  const [NextMenuStatus,SetNextMenuStatus]=useState(false)

  

  const DisableNextButton = () => {
     SetNextMenuStatus(true)
  };

  const EnableNextButton = () => {
    SetNextMenuStatus(false)
  };

  const SetShowSubmitButton = () => {





  };

  function getRadioValues(passclasee) {
    var div = document.getElementById(passclasee);
    var radioButtons = div.querySelectorAll('input[type="radio"]');
    var values = [];
    radioButtons.forEach(function(radioButton) {
       
            values.push(radioButton.value);
        
    });
    var result = 'POSSIBLE ANSWERS: ' + values.join('; ');
     return result
}



const [loader, Setloader] = useState(false)

const HideLoader = () => {
    Setloader(false)
}
const ShowLoader = () => {
    Setloader(true)
}


  return (
    <>
         {loader === true && <div id="semiTransparenDivLoader"></div>}
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>

      <div className="quiz_form_container beluga_quiz_form_container">
        {CurrentMenu === 'basic_info' ? (
          <BasicInfoForm
            FirstNamePC={FirstNamePC}
            SetFirstNamePC={SetFirstNamePC}
            LastNamePC={LastNamePC}
            SetLastNamePC={SetLastNamePC}
            PhonePC={PhonePC}
            SetPhonePC={SetPhonePC}
            EmailAddressPC={EmailAddressPC}
            SetEmailAddressPC={SetEmailAddressPC}
            AddressPC={AddressPC}
            SetAddressPC={SetAddressPC}
            StatePC={StatePC}
            SetStatePC={SetStatePC}
            CityPC={CityPC}
            SetCityPC={SetCityPC}
            ZipPC={ZipPC}
            SetZipPC={SetZipPC}
            SexPC={SexPC}
            SetSexPC={SetSexPC}
            DobPC={DobPC}
            SetDobPC={SetDobPC}
            FirstCallBasicInfo={FirstCallBasicInfo}
            SetFirstCallBasicInfo={SetFirstCallBasicInfo}
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}

          />
        ) : null}




        {CurrentMenu === 'bmi_details' ? (
          <BelugaMedicineDetails
          toast={toast}
          SexPC={SexPC}
          WeightPC={WeightPC}
          SetWeightPC={SetWeightPC}
          HeightFeetPC={HeightFeetPC}
          SetHeightFeetPC={SetHeightFeetPC}
          HeightInchPC={HeightInchPC}
          SetHeightInchPC={SetHeightInchPC}
          BMIPC={BMIPC}
          SetBMIPC={SetBMIPC}
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

       {CurrentMenu === 'currentmedicalconditions' ? (
          <CurrentMedicalConditionsCM
       
          CurrentMedicalConditionPCLabel={CurrentMedicalConditionPCLabel}
          CurrentMedicalConditionPC={CurrentMedicalConditionPC}
          SetCurrentMedicalConditionPC={SetCurrentMedicalConditionPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'allergies' ? (
          <AllergiesCM
       
          AllergiesPCLabel={AllergiesPCLabel}
          AllergiesPC={AllergiesPC}
          SetAllergiesPC={SetAllergiesPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'selfreportedmeds' ? (
          <SelfReportedMedsCM
       
          SelfReportedMedsPcLabel={SelfReportedMedsPcLabel}
          SelfReportedMedsPc={SelfReportedMedsPc}
          SetSelfReportedMedsPc={SetSelfReportedMedsPc}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}


          {CurrentMenu === 'pregnant' ? (
          <Pregnant
          PreganantPCLabel={PreganantPCLabel}
          toast={toast}
            PreganantPC={PreganantPC}
            SetPreganantPC={SetPreganantPC}
            PregnantConsentPC={PregnantConsentPC}
            SetPregnantConsentPC={SetPregnantConsentPC}
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

       {CurrentMenu === 'pregnantcs' ? (
          <PregnantCS
          
            PregnantConsentPC={PregnantConsentPC}
            SetPregnantConsentPC={SetPregnantConsentPC}
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

        {CurrentMenu === 'breastfeeding' ? (
          <BreastFeeding
          toast={toast}
          BreastFeedingPC={BreastFeedingPC}
          SetBreastFeedingPC={SetBreastFeedingPC}
          BreastFeedingPCLabel={BreastFeedingPCLabel}
       
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

        {CurrentMenu === 'theseapply' ? (
          <TheseApply
          toast={toast}
          AnythingApplyPCT={AnythingApplyPCT}
          AnythingApplyPCTLabel={AnythingApplyPCTLabel}
          SetAnythingApplyPCT={SetAnythingApplyPCT}
          AnythingApplyLengthPCT={AnythingApplyLengthPCT}
          SetAnythingApplyLengthPCT={SetAnythingApplyLengthPCT}
       
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}



         {CurrentMenu === 'gallbladdercs' ? (
          <GallBladderCS
          GallBladderConsentPC={GallBladderConsentPC}
          SetGallBladderConsentPC={SetGallBladderConsentPC}
    
       
            DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'gastricbypass' ? (
          <GastricByPass
          AnythingApplyPCT={AnythingApplyPCT}
          GastricPassPCLabel={GastricPassPCLabel}
           GastricPassPC={GastricPassPC}
           SetGastricPassPC={SetGastricPassPC}
    
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

        {CurrentMenu === 'glpexperience' ? (
          <GlpExperienceCM
          SexPC={SexPC}
          GlpExperiencePCLabel={GlpExperiencePCLabel}
           GlpExperiencePC={GlpExperiencePC}
           SetGlpExperiencePC={SetGlpExperiencePC}
    
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

          {CurrentMenu === 'triglycerides' ? (
          <TriglyceridesCom
          toast={toast}
            TriglyceridesPC={TriglyceridesPC}
            TriglyceridesPCLabel={TriglyceridesPCLabel}
            SetTriglyceridesPC={SetTriglyceridesPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

          {CurrentMenu === 'tellusmoremc' ? (
          <TellUsMoreMCCom
          AnythingApplyPCT={AnythingApplyPCT}
          TellUsMoreMCDescPC={TellUsMoreMCDescPC}
          SetTellUsMoreMCDescPC={SetTellUsMoreMCDescPC}
          TellUsMoreMCDescPCLabel={TellUsMoreMCDescPCLabel}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'glpexperiencefree' ? (
          <GLpExperienceFreeCM
     
          GLpExperienceFreeDescPC={GLpExperienceFreeDescPC}
          SetGLpExperienceFreeDescPC={SetGLpExperienceFreeDescPC}
          GLpExperienceFreeDescPCLabel={GLpExperienceFreeDescPCLabel}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'currentmedication' ? (
          <CurerntMedicationCM
          SexPC={SexPC}
          DobPC={DobPC}
          toast={toast}
          CurrentMedicationPCLabel={CurrentMedicationPCLabel}
          CurrentMedicationPCLabelMed={CurrentMedicationPCLabelMed}
          CurrentMedicationPC={CurrentMedicationPC}
          SetCurrentMedicationPC={SetCurrentMedicationPC}
          AnythingApplyPCT={AnythingApplyPCT}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

          {CurrentMenu === 'glpmedication' ? (
          <GLPMedicationCM
          GLPMedicationPCLabel={GLPMedicationPCLabel}
          GLPMedicationPC={GLPMedicationPC}
          SetGLPMedicationPC={SetGLPMedicationPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'doctorinfo' ? (
          <DoctorInfoCM
          GLPMedicationPC={GLPMedicationPC}
          DoctorInfoPCLabel={DoctorInfoPCLabel}
          DoctorInfoPC={DoctorInfoPC}
          SetDoctorInfoPC={SetDoctorInfoPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'truthconsent' ? (
          <TruthConsentCS
          TruthConsentPC={TruthConsentPC}
          SetTruthConsentPC={SetTruthConsentPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

          {CurrentMenu === 'sideeffect' ? (
          <SideEffectCM
          SideEffectPCLabel={SideEffectPCLabel}
          SideEffectPC={SideEffectPC}
          SetSideEffectPC={SetSideEffectPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

        {CurrentMenu === 'sideeffectdesc' ? (
          <SideEffectDescCM
        SideEffectDescPC={SideEffectDescPC}
        SideEffectDescPCLabel={SideEffectDescPCLabel}
          SetSideEffectDescPC={SetSideEffectDescPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}

         {CurrentMenu === 'glpdoselist' ? (
          <GLPDoseListCM
          SideEffectPC={SideEffectPC}
        GlpDoseListPC={GlpDoseListPC}
        GlpDoseListPCLabel={GlpDoseListPCLabel}
          SetGlpDoseListPC={SetGlpDoseListPC}
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}
          />
        ) : null}


          {CurrentMenu === 'glpdosepref' ? (
          <GLPDosePrefCM
          GlpMedicinePC={GlpMedicinePC}
          SetGlpMedicinePC={SetGlpMedicinePC}
          GlpDosagePC={GlpDosagePC}
          SetGlpDosagePC={SetGlpDosagePC}
          GlpDosageQuantityPC={GlpDosageQuantityPC}
          SetGlpDosageQuantityPC={SetGlpDosageQuantityPC}
          GlpDosageIdPC={GlpDosageIdPC}
          SetGlpDosageIdPC={SetGlpDosageIdPC}
          GlpPharmacyPC={GlpPharmacyPC}
          SetGlpPharmacyPC={SetGlpPharmacyPC}

          SideEffectPC={SideEffectPC}
          GlpDosePrefPC={GlpDosePrefPC}
          SetGlpDosePrefPC={SetGlpDosePrefPC}
          GlpDosePrefPCLabel={GlpDosePrefPCLabel}
         
           DisableNextButton={DisableNextButton}
            EnableNextButton={EnableNextButton}
            SetShowSubmitButton={SetShowSubmitButton}
            SetNextMenu={SetNextMenu}
            SetPreviousMenu={SetPreviousMenu}

            ClientPlanAllowedMedicine={props.ClientPlanAllowedMedicine}
          />
        ) : null}





      </div>

      <div className="final_submit_button">

        {
          CurrentMenu!=='basic_info' ?      <button className="previous_button" onClick={PrevStep} data-prev={PreviousMenu}>Prev</button>:null
        }
   

        {
          CurrentMenu!=='truthconsent' ?     <button className="next_button" disabled={NextMenuStatus} onClick={NextStep} data-menu={NextMenu}>Next</button> : null
        }
    

        {
          (TruthConsentPC===true && CurrentMenu==='truthconsent') ?        <button onClick={SubmitVisitForm}>Submit</button> : null
        }
 
      </div>
    </>
  );
};
export default BelugaVisitFormNew;

import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';


const RestMeditation = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [MeditationTime,SetMeditationTime]=useState('')
    const [MeditationTypeErr,SetMeditationTypeErr]=useState(false)
    const [MeditationType,SetMeditationType]=useState('')
    const [MeditationTimeErr,SetMeditationTimeErr]=useState(false)

    const SaveRestMeditation=async(e)=>{

        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowRestMeditationField(false)
            props.SetRestOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetMeditationTimeErr(false)
        SetMeditationTypeErr(false)
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(MeditationTime==='')
        {
            err = true
            SetMeditationTimeErr(true)
        }
        if(MeditationType==='')
        {
            err = true
            SetMeditationTypeErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
            const response = await axiosJWT.post('add-data/add-rest',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Rest Meditation',
                'meditation_time':MeditationTime,
                'meditation_type':MeditationType,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowRestMeditationField(false)
                    props.SetRestOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }

        }


    }
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }


    return (
        <div className="physical_activity">
<h6 className="heading_popup">{'Rest & Recovery > Meditation'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                    />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Meditation Time(Mins)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetMeditationTime(e.target.value) }}  {...(MeditationTimeErr === true ? { id: 'err_border' } : {})} defaultValue={MeditationTime} />
                </div>
            </div>

            <div className="field_top_row">

            <div className="label">Meditation Type</div>
                <div className="field">
                    
                    <select className="raw_select" onChange={(e) => { SetMeditationType(e.target.value) }} {...(MeditationTypeErr === true ? { id: 'err_border' } : {})} defaultValue={MeditationType}>
                    <option value="">Select Meditation Type</option>
                        <option value="Open Monitoring">Open Monitoring</option>
                        <option value="Mindfulness">Mindfulness</option>
                        <option value="Transcendent">Transcendent</option>
                        <option value="Other">Other</option>

                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveRestMeditation(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveRestMeditation(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveRestMeditation(e) }} >
                    {'Save'}
                </Button>

           </div>
           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )

}
export default RestMeditation
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const VitalBasalTemp = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [Temp,SetTemp]=useState('')
    const [TempErr,SetTempErr]=useState(false)
    const [TempType,SetTempType]=useState('Fahrenheit')
    const [Location,SetLocation]=useState('')
    const [LocationErr,SetLocationErr]=useState(false)

    const SaveVitalBasalTemp=async(e)=>{
        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowVitalBasalTempfield(false)
            props.SetVitalOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetTempErr(false)
        SetLocationErr(false)
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(Temp==='')
        {
            err = true
            SetTempErr(true) 
        }
        if(Location==='')
        {
            err=true;
            SetLocationErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
            const response = await axiosJWT.post('add-data/add-vitals',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Vital Basal Temperature',
                'temp_value':Temp,
                'temp_type':TempType,
                'location':Location,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowVitalBasalTempfield(false)
                    props.SetVitalOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }

        }



    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Vitals > Basal temperature'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                    />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Temperature</div>
                <div className="two_col_field">
                    <div className="col1">
                        <div className="">


                            <div className="field">
                                <input type={'number'} className="raw_input" onChange={(e) => { SetTemp(e.target.value) }} {...(TempErr === true ? { id: 'err_border' } : {})} defaultValue={Temp} />
                            </div>
                        </div>
                    </div>
                    <div className="col2">

                        <div className="field_top_row">


                            <div className="field">
                                <select className="raw_select" onChange={(e) => { SetTempType(e.target.value) }}  defaultValue={TempType}>

                                    <option value="Fahrenheit">Fahrenheit</option>
                                    <option value="Celcius">Celcius</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Location</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetLocation(e.target.value) }} defaultValue={Location} {...(LocationErr === true ? { id: 'err_border' } : {})}  >
                        <option value="">Select location</option>
                        <option value="Oral">Oral</option>
                        <option value="Armpit">Armpit</option>
                        <option value="Other">Other</option>
                  
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveVitalBasalTemp(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveVitalBasalTemp(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveVitalBasalTemp(e) }} >
                    {'Save'}
                </Button>

           </div>
            {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )
}
export default VitalBasalTemp
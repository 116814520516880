import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const MetabolismQuitEvent = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [AddDataDateEnd, SetAddDataDateEnd] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [DateEndErr, SetDateEndErr] = useState(false)

    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TImePickerEnd, SetTimePickerEnd] = useState(formatAMPM(new Date()))
    const [TimeErr, SetTimeErr] = useState(false)
    const [TimeEndErr, SetTimeEndErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [DisabledEnd, SetDisabledEnd] = useState(false)

    const [GlucoseValue,SetGlucoseValue]=useState('')

    const [QuitType,SetQuitType]=useState('')
    const [QuitTypeErr,SetQuitTypeErr]=useState(false)
    const [QuitTypeDirection,SetQuitTypeDirection]=useState('')
    const [QuitTypeDirectionErr,SetQuitTypeDirectionErr]=useState(false)

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

   
    const SaveMetabolismQuit=async(e)=>{
        const action=e.target.getAttribute('data-action')
        var err=false
        if(action==='cancel')
        {
            props.SetShowMetaQuitField(false)
            props.SetMetabolismOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetDateEndErr(false)
        SetTimeEndErr(false)
        SetQuitTypeErr(false)
        SetQuitTypeDirectionErr(false)

        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if (AddDataDateEnd === null) {
            err = true
            SetDateEndErr(true)
        }
        if (TImePickerEnd === null) {
            err = true
            SetTimeEndErr(true)
        }
        if(QuitType==='')
        {
            err=true;
            SetQuitTypeErr(true)
        }
        if(QuitTypeDirection==='')
        {
            err=true;
            SetQuitTypeDirectionErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            var passDateEnd=''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)
             
            }
            if (AddDataDateEnd != null) {

                passDateEnd = ChangeDatePickerDate(AddDataDateEnd)
             
            }
            const response = await axiosJWT.post('add-data/add-metabolism',
            {

                'type': 'Metabolism Quit Events',
                'quit_type':QuitType,
                'quit_type_direction':QuitTypeDirection,
                'quit_start_at':passDate+' '+TImePicker,
                'quit_end_at':passDateEnd+' '+TImePickerEnd,
                'fasting_glucose':GlucoseValue,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowMetaQuitField(false)
                    props.SetMetabolismOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }
           

        }



    }
    
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }
    return (

        <div className="physical_activity">
<h6 className="heading_popup">{'Metabolism > Quit events'}</h6>
<div className="field_top_row">
                <div className="label">Quit Type</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetQuitType(e.target.value) }} defaultValue={QuitType} {...(QuitTypeErr === true ? { id: 'err_border' } : {})}>
                    <option value="">Select  Quit Type</option>
                                    <option value="Smoking">Smoking</option>
                                    <option value="Alcohol">Alcohol</option>
                                    <option value="Sugar">Sugar</option>
                                    <option value="Other">Other</option>
                                  
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Count Direction</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetQuitTypeDirection(e.target.value) }} defaultValue={QuitTypeDirection} {...(QuitTypeDirectionErr === true ? { id: 'err_border' } : {})}>
                    <option value="">Select  Quit Type Direction</option>
                                    <option value="Count Up">Count Up</option>
                                    <option value="Count Down">Count Down</option>
                                   
                                  
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="centered_label">Start At</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>


            <div className="field_top_row">
                <div className="centered_label">End At</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDateEnd}
                        onChange={SetAddDataDateEnd}
                        format="MM-dd-yyyy"
                         disabled={DisabledEnd} />
                </div>

                {DateEndErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePickerEnd} value={TImePickerEnd} disabled={DisabledEnd} />
                </div>
                {TimeEndErr === true && <span className="err">Please select time</span>}

            </div>
         

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveMetabolismQuit(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveMetabolismQuit(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveMetabolismQuit(e) }} >
                    {'Save'}
                </Button>

                {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

           </div>

        </div>

    )

}
export default MetabolismQuitEvent
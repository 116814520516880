import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Faq from "react-faq-component";
import GlpForm from './GlpForm'

const ClientQuiz = (props) => {

    const client_id = props.data;

    const token = localStorage.getItem("token"); 
    const [noquizError, setnoquizError] = useState(false);
    const [quizArray, setquizArray] = useState([]);

    useEffect(() => {
        load_quiz();
    }, []);

    const axiosJWT = axios.create();   


    const load_quiz = async () => {
        var response = await axiosJWT.get("/user/get-quiz/"+client_id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        console.log(response);

        if(response.status === 200 && response.data !== null)
        {
            setnoquizError(false);

            if(response.data.source === "application")
            {
                load_application_quiz(response.data.quiz_data);
            }
            else
            {
                load_wordpress_quiz(response.data.quiz_data);
            }
        }
        else{
            setnoquizError(true);
        }        
    }


    const load_application_quiz = async (quiz_data) => {
        console.log(quiz_data);
        var quiz_data_json = JSON.parse(quiz_data);

        console.log(quiz_data_json.activity_level)
        var quiz_array = [];

        if(quiz_data_json.activity_level && quiz_data_json.activity_level !== '')
        {
            quiz_array.push({title:"Daily Activity Level: How physically active are you?",content:quiz_data_json.activity_level});
        }

        if(quiz_data_json.meat && quiz_data_json.meat !== '')
        {
            quiz_array.push({title:"Please select which meat you would like to be included",content:quiz_data_json.meat});
        }

        if(quiz_data_json.veg && quiz_data_json.veg !== '')
        {
            quiz_array.push({title:"Please select which veggies you would like to be included",content:quiz_data_json.veg});
        }

        if(quiz_data_json.other && quiz_data_json.other !== '')
        {
            quiz_array.push({title:"Please select which other foods you would like to be included",content:quiz_data_json.other});
        }

        if(quiz_data_json.tired && quiz_data_json.tired !== '')
        {
            quiz_array.push({title:"Which time of the day do you feel most tired?",content:quiz_data_json.tired});
        }

        if(quiz_data_json.digestive && quiz_data_json.digestive !== '')
        {
            quiz_array.push({title:"How often do you experience digestive upsets?",content:quiz_data_json.digestive});
        }

        if(quiz_data_json.crave && quiz_data_json.crave !== '')
        {
            quiz_array.push({title:"How often do you have uncontrollable cravings for specific foods?",content:quiz_data_json.crave});
        }

        if(quiz_data_json.crave_most && quiz_data_json.crave_most !== '')
        {
            quiz_array.push({title:"Which type of foods do you crave the most?",content:quiz_data_json.crave_most});
        }

        if(quiz_data_json.affected && quiz_data_json.affected !== '')
        {
            quiz_array.push({title:"Are you affected by the following?",content:quiz_data_json.affected});
        }

        if(quiz_data_json.issue && quiz_data_json.issue !== '')
        {
            quiz_array.push({title:"Do you experience any of the following problems?",content:quiz_data_json.issue});
        }

        if(quiz_data_json.recent && quiz_data_json.recent !== '')
        {
            quiz_array.push({title:"Experienced any of the following changes recently?",content:quiz_data_json.recent});
        }

        if(quiz_data_json.goals && quiz_data_json.goals !== '')
        {
            quiz_array.push({title:"What are your goals for this meal plan?",content:quiz_data_json.goals});
        }

        console.log(quiz_array)

        setquizArray(quiz_array);
    }

    const load_wordpress_quiz = async (quiz_data) => {
        
        var quiz_data_json = JSON.parse(quiz_data);

        var multichoice_checkbox = Object.entries(JSON.parse(quiz_data_json.multichoiceCheckbox));
        var singlechoice_radio = Object.entries(JSON.parse(quiz_data_json.singleChoiceRadio));
        var twochoice_radio = Object.entries(JSON.parse(quiz_data_json.twoChoiceRadio));
        var threechoice_radio = Object.entries(JSON.parse(quiz_data_json.threeChoiceRadio));

        var quiz_array = [];        

        singlechoice_radio.map(element => {
            quiz_array.push({title:element[0],content:element[1]});
        });

        twochoice_radio.map(element => {
            quiz_array.push({title:element[0],content:element[1]});
        });

        threechoice_radio.map(element => {
            quiz_array.push({title:element[0],content:element[1]});
        });

        multichoice_checkbox.map(element => {
            quiz_array.push({title:element[0],content:element[1]});
        });

        setquizArray(quiz_array);      
    }

    const ChangeTabs=(e)=>{

        const alltabs = document.querySelectorAll(".top_bar_notes .tabs");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class"," tabs active"); 
        const Item=e.target.getAttribute('data-item');
   
        if(Item==='quiz-funnel')
        {
            SetshowNormalQuiz(true) 
        }
        else{
            SetshowNormalQuiz(false)
        }
   
   
           
   
       }

       const [showNormalQuiz,SetshowNormalQuiz]=useState(true)

    return (
        <div className='quiz_tab'>  

<div className='top_bar_notes'>
                <div className='tabs active' data-item={'quiz-funnel'} onClick={ChangeTabs}>Quiz Funnel</div>
                {/* <div className='tabs' data-item={'glp-quiz'} onClick={ChangeTabs}>GLP-Quiz</div> */}
            </div>

        {
            showNormalQuiz===true ? 
            <>
            <div className='menubox'>
                 <div className='leftmenu'>
                   <h4>Quiz Answers</h4>
                 </div>
               </div>         
               {noquizError === true ?
                   <div className='no_quiz_error'> This client still not taking quiz.!</div>
               :
               <div className='quiz_answers_section'>
                       {quizArray.length > 0 ?
                       <Faq
                           data={{title:'',rows:quizArray}}
                           styles={{
                               rowTitleColor: "#226920",
                               rowContentPaddingLeft: '10px'
                           }} 
                       />
                       :null}
               </div>            
               }            
           </> : <GlpForm client_id={client_id}  />
        } 
       
           
        </div>
    )
}

export default ClientQuiz
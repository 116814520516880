import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Select from 'react-select'
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { format } from 'date-fns'
import moment from 'moment';

const Progress = (props) => {

    const token = localStorage.getItem("token");
    const client_id = props.data;
    const loggedin_userid = localStorage.getItem('userId')
   

    var [Blogs, setBlogs] = useState([]);
    const [readCount, setreadCount] = useState(0);
    const [blogCount, setblogCount] = useState(0);
    const [WeightDataset, SetWeightDataset] = useState([])
    const [WaterDataset, SetWaterDataset] = useState([])
    const [TargetWeight, SetTargetWeight] = useState(0)
    const [TargetWater, SetTargetWater] = useState(0)
    const [TodaysWeight, SetTodaysWeight] = useState(0)
    const [TodaysWater, SetTodaysWater] = useState(0)
    const [RegisteredInitialWeight, SetRegisteredInitialWeight] = useState(0)
    const [Changedlbs, SetChanedlbs] = useState(0)
    const [PieChartArray, SetPieChartArray] = useState([])
    const [PiechartKcaldata, SetPiechartKcalData] = useState(0)
    const [TotalCarb, SetTotalCarb] = useState(0)
    const [TotalFat, SetTotalFat] = useState(0)
    const [TotalProtein, SetTotalProtein] = useState(0)
    const [profileData, setprofileData] = useState([]);
    const [FollowingPlanCount, SetFollowingPlanCount] = useState(0)
    const [SkippedPlanCount, SetSkippedPlanCount] = useState(0)
    const [OutsidePlanCount, SetOutsidePlanCount] = useState(0)
    const [MealPlanOverAll, SetMealPlanOverAll] = useState(0)
    const [CurrentMealDay, SetCurrentMealDay] = useState(0)

    const pieoptions = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },

        },
        datalabels: {
            formatter: (value, ctx) => {

                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value * 100 / sum).toFixed(2) + "%";
                return percentage;


            },
            color: '#fff',
        }
    }

    const piedata = {
        labels: ['Carbs', 'Fat', 'Proteins'],
        datasets: [
            {
                label: '# of Votes',
                data: PieChartArray,
                backgroundColor: [
                    '#226920',
                    '#009fe1',
                    '#e91e63',


                ],
                borderColor: [
                    '#226920',
                    '#009fe1',
                    '#e91e63',

                ],

                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {

        getBlogs();
        GetWeeklyData();
        GetClientDetails()
        var startOfWeek = moment().startOf('week').toDate();
        startOfWeek = moment.utc(startOfWeek).format()
        var endOfWeek = moment().endOf('week').toDate();
        endOfWeek = moment.utc(endOfWeek).format()
        var date = format(new Date(startOfWeek), `PP`)
        SetWeekStartDate(date)
        var edate = format(new Date(endOfWeek), `PP`)
        SetWeekEndDate(edate)

    }, [props]);

    const axiosJWT = axios.create();


    const getBlogs = async () => {

        const response = await axiosJWT.get("/blog/user/" + client_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });







        setreadCount(response.data.read_count);
        setblogCount(response.data.total_count);
        setBlogs(response.data.blogs);
    }

    //activities 

    const GetWeeklyData = async (startdate, endate) => {




        var url = '';
        if (startdate !== undefined && startdate !== null && startdate !== '' && endate !== undefined && endate !== null && endate !== '') {

            var enddateAdd = format(new Date(startdate), `PP`)
            SetWeekEndDate(enddateAdd)
            var startdateAdd = format(new Date(endate), `PP`)
            SetWeekStartDate(startdateAdd)

            url = "/progress-diary/weekly/?client_id=" + client_id + "&startweekdate=" + endate + "&endweekdate=" + startdate + ""


        }
        else {
            url = "/progress-diary/weekly/?client_id=" + client_id
        }




        const response = await axiosJWT.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

     
         var Clientresponse;
        try{
             Clientresponse = await axiosJWT.get("/meal-plan/client/" + client_id + "", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(Clientresponse.data!==undefined)
            {
                SetMealPlanOverAll(Clientresponse.data.days);
                var UserPlanStartDate = Clientresponse.data.date
                UserPlanStartDate = UserPlanStartDate.split("T")[0];
                const StartmealDate = new Date(UserPlanStartDate);
                const CurrentDate = new Date();
        
                const diffTimeMeal = Math.abs(StartmealDate - CurrentDate);
                const diffDaysMeal = Math.ceil(diffTimeMeal / (1000 * 60 * 60 * 24));
                SetCurrentMealDay(diffDaysMeal)
        
                var BfObject = Clientresponse.data.breakfast_key
                var LuObject = Clientresponse.data.lunch_key
                var DiObject = Clientresponse.data.dinner_key
                SetFollowingPlanCount(0)
                SetSkippedPlanCount(0)
                SetOutsidePlanCount(0)
            }
    

        }catch(err)
        {
            console.log(err)
        }
       


       
    

     
       

 

        var FollowingCount = 0;
        var SkippedCount = 0;
        var OutsideCount = 0;

        if (startdate !== undefined && startdate !== null && startdate !== '' && endate !== undefined && endate !== null && endate !== '') {
            var startDateWeek = endate;
            var EndDateWeek = startdate
        }
        else {

            var startOfWeek = moment().startOf('week').toDate();
            startOfWeek = moment.utc(startOfWeek).format()
            var endOfWeek = moment().endOf('week').toDate();
            endOfWeek = moment.utc(endOfWeek).format()
            var firstday = startOfWeek
            var lastday = endOfWeek

            var current_date_start = moment(firstday).format('YYYY-MM-DDTHH:mm:ss');
            var current_date_end = moment(lastday).format('YYYY-MM-DDTHH:mm:ss');
            var startDateWeek = current_date_start;
            var EndDateWeek = current_date_end

        }


        var startDatePass = new Date(startDateWeek)
        var EndDatePass = new Date(EndDateWeek)

        var DatesInrange = await getDatesInRange(startDatePass, EndDatePass)

        DatesInrange.forEach((value, index, array) => {

            const date1 = new Date(UserPlanStartDate);
            const date2 = new Date(value);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (Date.parse(date1) <= Date.parse(date2)) {

                var GetIndexValueFromObject = diffDays + 1

                // bf check

                var CoachMelPlan;
                var found = BfObject.filter(e => e.day === GetIndexValueFromObject);

                if (found.length > 0) {

                    CoachMelPlan = found[0].recipeid
                }

                var ProgressDiaryBfArray = []

                if (response.data.data[index].breakfast !== undefined && response.data.data[index].breakfast !== null && response.data.data[index].breakfast !== '') {
                    ProgressDiaryBfArray = response.data.data[index].breakfast.split(',')
                }



                if (ProgressDiaryBfArray.length === 0) {

                    if (index === 0) {
                        SetSundaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 1) {
                        SetMondaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 2) {
                        SetTuesdaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 3) {
                        SetWednesdaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 4) {
                        SetThursdaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 5) {
                        SetFridaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 6) {
                        SetSaturdaybf(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }

                }
                else {

                    if (CoachMelPlan !== undefined) {
                        CoachMelPlan = CoachMelPlan.toString()
                    }


                    if (ProgressDiaryBfArray.includes(CoachMelPlan)) {

                        if (index === 0) {
                            SetSundaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 1) {
                            SetMondaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 2) {
                            SetTuesdaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 3) {
                            SetWednesdaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 4) {
                            SetThursdaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 5) {
                            SetFridaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 6) {
                            SetSaturdaybf(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }



                    }
                    else {

                        if (index === 0) {
                            SetSundaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 1) {
                            SetMondaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 2) {
                            SetTuesdaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 3) {
                            SetWednesdaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 4) {
                            SetThursdaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 5) {
                            SetFridaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 6) {
                            SetSaturdaybf(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }

                    }
                }

                //lunch check 


                var CoachMelPlanLu;
                var foundLu = LuObject.filter(e => e.day === GetIndexValueFromObject);

                if (foundLu.length > 0) {

                    CoachMelPlanLu = foundLu[0].recipeid
                }

                var ProgressDiaryLuArray = []

                if (response.data.data[index].lunch !== undefined && response.data.data[index].lunch !== null && response.data.data[index].lunch !== '') {
                    ProgressDiaryLuArray = response.data.data[index].lunch.split(',')
                }



                if (ProgressDiaryLuArray.length === 0) {

                    if (index === 0) {
                        SetSundaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 1) {
                        SetMondaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 2) {
                        SetTuesdaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 3) {
                        SetWednesdaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 4) {
                        SetThursdaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 5) {
                        SetFridaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 6) {
                        SetSaturdaylu(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }

                }
                else {



                    if (CoachMelPlanLu !== undefined) {
                        CoachMelPlanLu = CoachMelPlanLu.toString()
                    }

                    if (ProgressDiaryLuArray.includes(CoachMelPlanLu)) {

                        if (index === 0) {
                            SetSundaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 1) {
                            SetMondaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 2) {
                            SetTuesdaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 3) {
                            SetWednesdaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 4) {
                            SetThursdaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 5) {
                            SetFridaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 6) {
                            SetSaturdaylu(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }



                    }
                    else {

                        if (index === 0) {
                            SetSundaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 1) {
                            SetMondaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 2) {
                            SetTuesdaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 3) {
                            SetWednesdaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 4) {
                            SetThursdaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 5) {
                            SetFridaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 6) {
                            SetSaturdaylu(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }

                    }
                }

                //dinner check

                var CoachMelPlanDi;
                var foundDi = DiObject.filter(e => e.day === GetIndexValueFromObject);

                if (foundDi.length > 0) {

                    CoachMelPlanDi = foundDi[0].recipeid
                }

                var ProgressDiaryDiArray = []

                if (response.data.data[index].dinner !== undefined && response.data.data[index].dinner !== null && response.data.data[index].dinner !== '') {
                    ProgressDiaryDiArray = response.data.data[index].dinner.split(',')
                }



                if (ProgressDiaryDiArray.length === 0) {

                    if (index === 0) {
                        SetSundaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 1) {
                        SetMondaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 2) {
                        SetTuesdaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 3) {
                        SetWednesdaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 4) {
                        SetThursdaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 5) {
                        SetFridaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }
                    if (index === 6) {
                        SetSaturdaydi(0)
                        SkippedCount = SkippedCount + 1
                        SetSkippedPlanCount(SkippedCount)

                    }

                }
                else {




                    if (CoachMelPlanDi !== undefined) {
                        CoachMelPlanDi = CoachMelPlanDi.toString()
                    }

                    if (ProgressDiaryDiArray.includes(CoachMelPlanDi)) {

                        if (index === 0) {
                            SetSundaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 1) {
                            SetMondaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 2) {
                            SetTuesdaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 3) {
                            SetWednesdaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 4) {
                            SetThursdaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 5) {
                            SetFridaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }
                        if (index === 6) {
                            SetSaturdaydi(1)
                            FollowingCount = FollowingCount + 1
                            SetFollowingPlanCount(FollowingCount)

                        }



                    }
                    else {

                        if (index === 0) {
                            SetSundaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 1) {
                            SetMondaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 2) {
                            SetTuesdaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 3) {
                            SetWednesdaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 4) {
                            SetThursdaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 5) {
                            SetFridaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }
                        if (index === 6) {
                            SetSaturdaydi(2)
                            OutsideCount = OutsideCount + 1;
                            SetOutsidePlanCount(OutsideCount)

                        }

                    }
                }



            }
            else {

                if (index === 0) {
                    SetSundaybf(3)
                    SetSundaylu(3)
                    SetSundaydi(3)
                }
                if (index === 1) {
                    SetMondaybf(3)
                    SetMondaylu(3)
                    SetMondaydi(3)
                }
                if (index === 2) {
                    SetTuesdaybf(3)
                    SetTuesdaylu(3)
                    SetTuesdaydi(3)
                }
                if (index === 3) {
                    SetWednesdaybf(3)
                    SetWednesdaylu(3)
                    SetWednesdaydi(3)
                }
                if (index === 4) {
                    SetThursdaybf(3)
                    SetThursdaylu(3)
                    SetThursdaydi(3)
                }
                if (index === 5) {
                    SetFridaybf(3)
                    SetFridaylu(3)
                    SetFridaydi(3)
                }
                if (index === 6) {
                    SetSaturdaybf(3)
                    SetSaturdaylu(3)
                    SetSaturdaydi(3)
                }

            }

        });




        if (response.data && response.data.client_info && response.data.client_info.weight !== undefined && response.data.client_info.weight !== null && response.data.client_info.weight !== '') {
            SetRegisteredInitialWeight(response.data.client_info.weight)
        }
        else {
            SetRegisteredInitialWeight(0)
        }



        const Todayresponse = await axiosJWT.get("/progress-diary/daily/?client_id=" + client_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });



        if (response.data && response.data.client_info && response.data.client_info.daily_calories !== undefined && response.data.client_info.daily_calories !== null && response.data.client_info.daily_calories !== '') {
            SetPiechartKcalData(response.data.client_info.daily_calories)
        }
        else {
            SetPiechartKcalData(0)
        }

        var FoodKeys = [];
        var bfArray = [];
        var lunchArray = [];
        var SnacksArray = [];
        var DinnerArray = [];

        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].breakfast !== undefined && Todayresponse.data.data[0].breakfast !== null && Todayresponse.data.data[0].breakfast !== '') {
            bfArray = Todayresponse.data.data[0].breakfast.split(',')
        }
        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].lunch !== undefined && Todayresponse.data.data[0].lunch !== null && Todayresponse.data.data[0].lunch !== '') {
            lunchArray = Todayresponse.data.data[0].lunch.split(',')
        }
        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].dinner !== undefined && Todayresponse.data.data[0].dinner !== null && Todayresponse.data.data[0].dinner !== '') {
            DinnerArray = Todayresponse.data.data[0].dinner.split(',')
        }
        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].snacks != undefined && Todayresponse.data.data[0].snacks !== null && Todayresponse.data.data[0].snacks !== '') {
            SnacksArray = Todayresponse.data.data[0].snacks.split(',')
        }
        FoodKeys = bfArray.concat(lunchArray, DinnerArray, SnacksArray);



        var CarbsTotal = 0;
        var FatsTotal = 0;
        var ProteinsTotal = 0;

   


        FoodKeys.forEach(async(value, index, array) => {
       
          
            const response = await GetRecipeDetails(value).then((response) => {



                CarbsTotal = CarbsTotal + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))
                FatsTotal = FatsTotal + Math.round(parseInt(response.data.NutritionInfo.TotalFat))
                ProteinsTotal = ProteinsTotal + Math.round(parseInt(response.data.NutritionInfo.Protein))

            }).then(() => {
                if (index === array.length - 1) {

                    setTimeout(function () {
                        SetTotalCarb(CarbsTotal)
                        SetTotalFat(FatsTotal)
                        SetTotalProtein(ProteinsTotal)
                        SetPieChartArray([CarbsTotal, FatsTotal, ProteinsTotal])
                    }, 100);

                }
            })
        })

        if ((response.data && response.data.client_info && response.data.client_info.weight !== undefined && response.data.client_info.weight !== null && response.data.client_info.weight !== '') && (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].weight !== undefined && Todayresponse.data.data[0].weight !== null && Todayresponse.data.data[0].weight !== '')) {


            if (parseInt(Todayresponse.data.data[0].weight) > parseInt(response.data.client_info.weight)) {
                var changeddata = parseInt(Todayresponse.data.data[0].weight) - parseInt(response.data.client_info.weight)
                SetChanedlbs('+' + changeddata)
            }
            else if (parseInt(Todayresponse.data.data[0].weight) < parseInt(response.data.client_info.weight)) {
                var changeddata = parseInt(Todayresponse.data.data[0].weight) - parseInt(response.data.client_info.weight)
                SetChanedlbs(changeddata)
            }

        }
        else {
            SetChanedlbs(0)
        }



        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].weight !== undefined && Todayresponse.data.data[0].weight !== null && Todayresponse.data.data[0].weight !== '') {
            SetTodaysWeight(Todayresponse.data.data[0].weight)
        }
        else {
            SetTodaysWeight(0)
        }
        if (Todayresponse.data.data && Todayresponse.data.data[0] && Todayresponse.data.data[0].water !== undefined && Todayresponse.data.data[0].water !== null && Todayresponse.data.data[0].water !== '') {
            SetTodaysWater(Todayresponse.data.data[0].water)
        }
        else {
            SetTodaysWater(0)
        }



        if (response.data.client_info && response.data.client_info.target_weight !== undefined && response.data.client_info.target_weight !== null && response.data.client_info.target_weight !== '') {
            SetTargetWeight(response.data.client_info.target_weight)
        }
        else {
            SetTargetWeight(0)
        }

        if (response.data.client_info && response.data.client_info.water_intake !== undefined && response.data.client_info.water_intake !== null && response.data.client_info.water_intake !== '') {
            SetTargetWater(response.data.client_info.water_intake)
        }
        else {
            SetTargetWater(0)
        }




        var WeightArray = [];
        var WaterArray = []

        if (response.data.data[0] && response.data.data[0].weight !== undefined && response.data.data[0].weight !== null && response.data.data[0].weight !== '') {
            WeightArray[0] = parseInt(response.data.data[0].weight)
            SetWeightOne(true)
        }
        else {
            WeightArray[0] = 0
            SetWeightOne(false)
        }
        if (response.data.data[1] && response.data.data[1].weight !== undefined && response.data.data[1].weight !== null && response.data.data[1].weight !== '') {
            WeightArray[1] = parseInt(response.data.data[1].weight)
            SetWeightTwo(true)
        }
        else {
            WeightArray[1] = 0
            SetWeightTwo(false)
        }
        if (response.data.data[2] && response.data.data[2].weight !== undefined && response.data.data[2].weight !== null && response.data.data[2].weight !== '') {
            WeightArray[2] = parseInt(response.data.data[2].weight)
            SetWeightThree(true)
        }
        else {
            WeightArray[2] = 0
            SetWeightThree(false)
        }
        if (response.data.data[3] && response.data.data[3].weight !== undefined && response.data.data[3].weight !== null && response.data.data[3].weight !== '') {
            WeightArray[3] = response.data.data[3].weight
            SetWeightFour(true)
        }
        else {
            WeightArray[3] = 0
            SetWeightFour(false)
        }
        if (response.data.data[4] && response.data.data[4].weight !== undefined && response.data.data[4].weight !== null && response.data.data[4].weight !== '') {
            WeightArray[4] = response.data.data[4].weight
            SetWeightFive(true)
        }
        else {
            WeightArray[4] = 0
            SetWeightFive(false)
        }
        if (response.data.data[5] && response.data.data[5].weight !== undefined && response.data.data[5].weight !== null && response.data.data[5].weight !== '') {
            WeightArray[5] = response.data.data[5].weight
            SetWeightSix(true)
        }
        else {
            WeightArray[5] = 0
            SetWeightSix(false)
        }
        if (response.data.data[6] && response.data.data[6].weight !== undefined && response.data.data[6].weight !== null && response.data.data[6].weight !== '') {
            WeightArray[6] = response.data.data[6].weight

            SetWeightSeven(true)
        }
        else {
            WeightArray[6] = 0
            SetWeightSeven(false)
        }


        if (response.data.data[0] && response.data.data[0].water !== undefined && response.data.data[0].water !== null && response.data.data[0].water !== '') {
            WaterArray[0] = parseInt(response.data.data[0].water)
            SetWaterOne(true)
        }
        else {
            WaterArray[0] = 0
            SetWaterOne(false)
        }
        if (response.data.data[1] && response.data.data[1].water !== undefined && response.data.data[1].water !== null && response.data.data[1].water !== '') {
            WaterArray[1] = parseInt(response.data.data[1].water)
            SetWaterTwo(true)
        }
        else {
            WaterArray[1] = 0
            SetWaterTwo(false)
        }
        if (response.data.data[2] && response.data.data[2].water !== undefined && response.data.data[2].water !== null && response.data.data[2].water !== '') {
          
            WaterArray[2] = parseInt(response.data.data[2].water)
            SetWaterThree(true)
        }
        else {
            WaterArray[2] = 0
            SetWaterThree(false)
        }
        
        if (response.data.data[3] && response.data.data[3].water !== undefined && response.data.data[3].water !== null && response.data.data[3].water !== '') {
            WaterArray[3] = response.data.data[3].water
            SetWaterFour(true)
        }
        else {
            WaterArray[3] = 0
            SetWaterFour(false)
        }
        if (response.data.data[4] && response.data.data[4].water !== undefined && response.data.data[4].water !== null && response.data.data[4].water !== '') {
            WaterArray[4] = response.data.data[4].water
            SetWaterFive(true)
        }
        else {
            WaterArray[4] = 0
            SetWaterFive(false)
        }
        if (response.data.data[5] && response.data.data[5].water !== undefined && response.data.data[5].water != null && response.data.data[5].water !== '') {
            WaterArray[5] = response.data.data[5].water
            SetWaterSix(true)
        }
        else {
            WaterArray[5] = 0
            SetWaterSix(false)
        }
        if (response.data.data[6] && response.data.data[6].water !== undefined && response.data.data[6].water !== null && response.data.data[6].water !== '') {
            WaterArray[6] = response.data.data[6].water
            SetWaterSeven(true)
        }
        else {
            WaterArray[6] = 0
            SetWaterSeven(false)
        }

        if ((response.data.data[0] && response.data.data[0].breakfast !== undefined && response.data.data[0].breakfast !== null && response.data.data[0].breakfast !== '') || (response.data.data[0] && response.data.data[0].lunch != undefined && response.data.data[0].lunch != null && response.data.data[0].lunch !== '') || (response.data.data[0] && response.data.data[0].dinner !== undefined && response.data.data[0].dinner !== null && response.data.data[0].dinner !== '') || (response.data.data[0] && response.data.data[0].snacks !== undefined && response.data.data[0].snacks !== null && response.data.data[0].snacks !== '')) {
            SetMealOne(true)

        }
        else {
            SetMealOne(false)
        }

        if ((response.data.data[1] && response.data.data[1].breakfast !== undefined && response.data.data[1].breakfast !== null && response.data.data[1].breakfast !== '') || (response.data.data[1] && response.data.data[1].lunch !== undefined && response.data.data[1].lunch !== null && response.data.data[1].lunch !== '') || (response.data.data[1] && response.data.data[1].dinner !== undefined && response.data.data[1].dinner !== null && response.data.data[1].dinner !== '') || (response.data.data[1] && response.data.data[1].snacks !== undefined && response.data.data[1].snacks !== null && response.data.data[1].snacks !== '')) {
            SetMealTwo(true)

        }
        else {
            SetMealTwo(false)
        }

        if ((response.data.data[2] && response.data.data[2].breakfast !== undefined && response.data.data[2].breakfast !== null && response.data.data[2].breakfast !== '') || (response.data.data[2] && response.data.data[2].lunch !== undefined && response.data.data[2].lunch !== null && response.data.data[2].lunch !== '') || (response.data.data[2] && response.data.data[2].dinner !== undefined && response.data.data[2].dinner !== null && response.data.data[2].dinner !== '') || (response.data.data[2] && response.data.data[2].snacks !== undefined && response.data.data[2].snacks !== null && response.data.data[2].snacks !== '')) {
            SetMealThree(true)

        }
        else {
            SetMealThree(false)
        }

        if ((response.data.data[3] && response.data.data[3].breakfast !== undefined && response.data.data[3].breakfast !== null && response.data.data[3].breakfast !== '') || (response.data.data[3] && response.data.data[3].lunch !== undefined && response.data.data[3].lunch !== null && response.data.data[3].lunch !== '') || (response.data.data[3] && response.data.data[3].dinner !== undefined && response.data.data[3].dinner !== null && response.data.data[3].dinner !== '') || (response.data.data[3] && response.data.data[3].snacks !== undefined && response.data.data[3].snacks !== null && response.data.data[3].snacks !== '')) {
            SetMealFour(true)

        }
        else {
            SetMealFour(false)
        }

        if ((response.data.data[4] && response.data.data[4].breakfast !== undefined && response.data.data[4].breakfast !== null && response.data.data[4].breakfast !== '') || (response.data.data[4] && response.data.data[4].lunch !== undefined && response.data.data[4].lunch !== null && response.data.data[4].lunch !== '') || (response.data.data[4] && response.data.data[4].dinner !== undefined && response.data.data[4].dinner !== null && response.data.data[4].dinner !== '') || (response.data.data[4] && response.data.data[4].snacks !== undefined && response.data.data[4].snacks !== null && response.data.data[4].snacks !== '')) {
            SetMealFive(true)

        }
        else {
            SetMealFive(false)
        }

        if ((response.data.data[5] && response.data.data[5].breakfast !== undefined && response.data.data[5].breakfast !== null && response.data.data[5].breakfast !== '') || (response.data.data[5] && response.data.data[5].lunch !== undefined && response.data.data[5].lunch !== null && response.data.data[5].lunch !== '') || (response.data.data[5] && response.data.data[5].dinner !== undefined && response.data.data[5].dinner !== null && response.data.data[5].dinner !== '') || (response.data.data[5] && response.data.data[5].snacks !== undefined && response.data.data[5].snacks !== null && response.data.data[5].snacks !== '')) {
            SetMealSix(true)

        }
        else {
            SetMealSix(false)
        }

        if ((response.data.data[6] && response.data.data[6].breakfast !== undefined && response.data.data[6].breakfast !== null && response.data.data[6].breakfast !== '') || (response.data.data[6] && response.data.data[6].lunch !== undefined && response.data.data[6].lunch !== null && response.data.data[6].lunch !== '') || (response.data.data[6] && response.data.data[6].dinner !== undefined && response.data.data[6].dinner !== null && response.data.data[6].dinner !== '') || (response.data.data[6] && response.data.data[6].snacks !== undefined && response.data.data[6].snacks !== null && response.data.data[6].snacks !== '')) {
            SetMealSeven(true)

        }
        else {
            SetMealSeven(false)
        }




        SetWeightDataset(WeightArray)
        SetWaterDataset(WaterArray)








    }


    const Weightdata = {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        datasets: [
            {
                label: "",
                data: WeightDataset,
                backgroundColor: "#4CAF50"
            }
        ]
    };

    const Waterdata = {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        datasets: [
            {
                label: "",
                data: WaterDataset,
                backgroundColor: "#4CAF50"
            }
        ]
    };

    const options = {

        plugins: {
            legend: {
                display: false
            },
            responsive: true,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            borderDash: [3, 3],
                            zeroLineColor: "blue"
                        },
                        categoryPercentage: 0.7,
                        barPercentage: 0.9,
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ],
                yAxes: [
                    {
                        display: false,
                        gridLines: {
                            display: false,
                            zeroLineColor: "transparent"
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        }
    };

    const [MealOne, SetMealOne] = useState(false)
    const [MealTwo, SetMealTwo] = useState(false)
    const [MealThree, SetMealThree] = useState(false)
    const [MealFour, SetMealFour] = useState(false)
    const [MealFive, SetMealFive] = useState(false)
    const [MealSix, SetMealSix] = useState(false)
    const [MealSeven, SetMealSeven] = useState(false)

    const [WeightOne, SetWeightOne] = useState(false)
    const [WeightTwo, SetWeightTwo] = useState(false)
    const [WeightThree, SetWeightThree] = useState(false)
    const [WeightFour, SetWeightFour] = useState(false)
    const [WeightFive, SetWeightFive] = useState(false)
    const [WeightSix, SetWeightSix] = useState(false)
    const [WeightSeven, SetWeightSeven] = useState(false)

    const [WaterOne, SetWaterOne] = useState(false)
    const [WaterTwo, SetWaterTwo] = useState(false)
    const [WaterThree, SetWaterThree] = useState(false)
    const [WaterFour, SetWaterFour] = useState(false)
    const [WaterFive, SetWaterFive] = useState(false)
    const [WaterSix, SetWaterSix] = useState(false)
    const [WaterSeven, SetWaterSeven] = useState(false)




    const [WeekStarteDate, SetWeekStartDate] = useState('')
    const [WeekEndDate, SetWeekEndDate] = useState('')


    const ChangedWeekRanged = (e) => {

        var target = e.target.getAttribute('data-target')
        if (target === 'backward') {

            var curr = new Date(WeekStarteDate); // get current date

            var first = curr.getDate() - 1 - curr.getDay(); // First day is the day of the month - the day of the week

            var last = first - 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first))
            var lastday = new Date(curr.setDate(last))
            firstday = formatDate(firstday)
            lastday = formatDate(lastday)
            GetWeeklyData(firstday, lastday)



        }
        else if (target === 'forward') {

            var curr = new Date(WeekEndDate);
            var first = curr.getDate() + 7 - curr.getDay();
            var last = first + 6;
            var firstday = new Date(curr.setDate(first))
            var lastday = new Date(curr.setDate(last))
            firstday = formatDate(firstday)
            lastday = formatDate(lastday)
            GetWeeklyData(lastday, firstday)

        }
    }

    const GetRecipeDetails = async (RecipeId) => {
      

        var CheckType = String(RecipeId)
        if (!CheckType.startsWith('FITPALIR') && !CheckType.startsWith('FJ')) {
            const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + RecipeId + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

            })
            return response
        }
        else if(CheckType.startsWith('FITPALIR')) {

            var Recipeobj = {};

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (RecipeData.data.recipe !== null) {

                var RecipeImg = '';

                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                    RecipeImg =  RecipeData.data.recipe.recipe_image
                }


                Recipeobj = {

                    data: {
                        RecipeID: RecipeData.data.recipe.recipe_uid,
                        Title: RecipeData.data.recipe.recipe_name,
                        PhotoUrl: RecipeImg,
                        NutritionInfo: {

                            TotalCalories: RecipeData.data.recipe.recipe_calories,
                            TotalFat: RecipeData.data.recipe.recipe_fats,
                            Protein: RecipeData.data.recipe.recipe_proteins,
                            TotalCarbs: RecipeData.data.recipe.recipe_carbs

                        }
                    }


                }

            }


            return Recipeobj
        }
        else if (CheckType.startsWith('FJ'))
        {
         

            RecipeId = RecipeId.replace(/\D/g,'');
            var Recipeobj = {};
            const RecipeData = await axiosJWT.get("/food-journal/detail?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            var RecipeName='Recipe Name not available';
            var Calories=0;
            var Fat=0;
            var Carbs=0;
            var Protein=0;
            var RecipeImage=RecipeData.data.result.journal_img;
            var CreatedRecipeId='FJ'+RecipeData.data.result.id;
    
            if(RecipeData.data.result.ingredients)
            {
     
                if(RecipeData.data.result.ingredients!==null && RecipeData.data.result.ingredients!=='')
                {
                 
                    const RecipeDetailsObj=JSON.parse(RecipeData.data.result.ingredients)
                    if(RecipeDetailsObj[0].ingredient_name)
                    {
                        RecipeName=RecipeDetailsObj[0].ingredient_name
                    }

                  

                    RecipeDetailsObj.forEach(element => {
                       //caloriemama kg to g converting
                       if(element.calories)
                        {
                            Calories=parseFloat(element.calories)+Calories;
                        }
                        if(element.fat)
                        {
                            Fat=(parseFloat(element.fat)*1000)+Fat;
                           
                        }
                        if(element.protein)
                        {
                            Protein=(parseFloat(element.protein)*1000)+Protein;
                        }
                        if(element.carbs)
                        {
                            Carbs=(parseFloat(element.carbs)*1000)+Carbs;
                        }
                  

                    });
                  
                }
            }

           
            Recipeobj = {

                data: {
                    RecipeID: CreatedRecipeId,
                    Title: RecipeName,
                    PhotoUrl: RecipeImage,
                    NutritionInfo: {

                        TotalCalories: Calories,
                        TotalFat: Fat,
                        Protein: Protein,
                        TotalCarbs: Carbs

                    }
                }


            }

            return Recipeobj
          
           
        }


    }

    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const GetClientDetails = async () => {
        const response = await axiosJWT.get("/user/" + client_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
             setprofileData(response.data);
        })
    }

    const [Sundaybf, SetSundaybf] = useState(3)
    const [Mondaybf, SetMondaybf] = useState(3)
    const [Tuesdaybf, SetTuesdaybf] = useState(3)
    const [Wednesdaybf, SetWednesdaybf] = useState(3)
    const [Thursdaybf, SetThursdaybf] = useState(3)
    const [Fridaybf, SetFridaybf] = useState(3)
    const [Saturdaybf, SetSaturdaybf] = useState(3)


    const [Sundaylu, SetSundaylu] = useState(3)
    const [Mondaylu, SetMondaylu] = useState(3)
    const [Tuesdaylu, SetTuesdaylu] = useState(3)
    const [Wednesdaylu, SetWednesdaylu] = useState(3)
    const [Thursdaylu, SetThursdaylu] = useState(3)
    const [Fridaylu, SetFridaylu] = useState(3)
    const [Saturdaylu, SetSaturdaylu] = useState(3)

    const [Sundaydi, SetSundaydi] = useState(3)
    const [Mondaydi, SetMondaydi] = useState(3)
    const [Tuesdaydi, SetTuesdaydi] = useState(3)
    const [Wednesdaydi, SetWednesdaydi] = useState(3)
    const [Thursdaydi, SetThursdaydi] = useState(3)
    const [Fridaydi, SetFridaydi] = useState(3)
    const [Saturdaydi, SetSaturdaydi] = useState(3)


    const getDatesInRange = async (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(formatDate(new Date(date)));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    const GetMyGoal=async(value)=>{



      const response = await axiosJWT.get("/profile-details/get-goal-client/?coach_id="+loggedin_userid+"&client_id="+client_id+"&source="+value+"", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        if (response.data && response.data.daily_calories && response.data.daily_calories !== undefined && response.data.daily_calories !== null && response.data.daily_calories !== '') {
            SetPiechartKcalData(response.data.daily_calories)
        }
        else {
            SetPiechartKcalData(0)
        }

        console.log('goal_client',response)


           
    }




    return (
        <div className='progress_tab'>
            <div className='left_inner_block'>
                <br></br>
                {/* <Select /> */}
                {/* <br></br> */}
                <div style={{ "clear": "both" }}> </div>

                <div className='row activities_main_row'>
                    <div className='top_level_select_week'>
                        <span>Activities</span>
                        <span className='date_pick'>{WeekStarteDate} - {WeekEndDate} <span className='arrow' data-target={'backward'} onClick={(e) => { ChangedWeekRanged(e) }}>{'<'}</span><span className='arrow' data-target={'forward'} onClick={(e) => { ChangedWeekRanged(e) }}>{'>'}</span></span>
                    </div>
                    <div className='top_level_row_week_heading'>
                        <span className='numberCircle'>Sun</span>
                        <span className='numberCircle'>Mon</span>
                        <span className='numberCircle'>Tue</span>
                        <span className='numberCircle'>Wed</span>
                        <span className='numberCircle'>Thu</span>
                        <span className='numberCircle'>Fri</span>
                        <span className='numberCircle'>Sat</span>
                    </div>
                    <div className='row top_level_row_week_heading_data'>
                        <div className='col_activity_label'>
                            <span>Today's Meal</span>
                            <span>Weight</span>
                            <span>Water</span>
                        </div>
                        <div className='col_activity_tick'>
                            <div className='row'>
                                {MealOne === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {MealTwo === true ? <i className="fa fa-check-circle"></i> :
                                    <i className="" ></i>}


                                {MealThree === true ? <i className="fa fa-check-circle"></i> :
                                    <i className="" ></i>}

                                {MealFour === true ? <i className="fa fa-check-circle"></i> :
                                    <i className="" ></i>}

                                {MealFive === true ? <i className="fa fa-check-circle"></i> :
                                    <i className="" ></i>}

                                {MealSix === true ? <i className="fa fa-check-circle"></i> :
                                    <i className="" ></i>}

                                {MealSeven === true ? <i className="fa fa-check-circle lastone"></i> :
                                    <i className="lastone" ></i>}

                            </div>

                            <div className='row second_row'>

                                {WeightOne === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightTwo === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightThree === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightFour === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightFive === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightSix === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WeightSeven === true ? <i className="fa fa-check-circle lastone"></i> :
                                    <i className="lastone" ></i>}
                            </div>


                            <div className='row third_row'>

                                {WaterOne === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterTwo === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterThree === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterFour === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterFive === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterSix === true ? <i className="fa fa-check-circle" ></i> :
                                    <i className="" ></i>}

                                {WaterSeven === true ? <i className="fa fa-check-circle lastone"></i> :
                                    <i className="lastone" ></i>}
                            </div>
                        </div>


                    </div>
                </div>
                

                <div style={{ "clear": "both" }} />
                <div className='left_sub_inner_block'>
                    <div className='meal_box block_box' style={props.Hideplan ? props.Hideplan:null}>
                        <div className='title_left top_meal_label'>
                            <span className='float_left'>MEALS</span>
                            <span className='float_right'>{CurrentMealDay}/{MealPlanOverAll}</span>
                        </div>
                        <div className='top_week_label_meal'>
                            <span>S</span>
                            <span>M</span>
                            <span>T</span>
                            <span>W</span>
                            <span>T</span>
                            <span>F</span>
                            <span>S</span>
                        </div>
                        <div className='tick_meal_block row'>

                            <div className='bf_l_block  col_activity_label'>
                                <span>BF</span>
                                <span>L</span>
                                <span>D</span>

                            </div>

                            <div className='bf_l_block_tick col_activity_tick'>

                                <div className='circle_first'>
                                    {Sundaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Sundaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Sundaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Sundaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Mondaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Mondaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Mondaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Mondaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Tuesdaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Tuesdaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Tuesdaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Tuesdaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Wednesdaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Wednesdaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Wednesdaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Wednesdaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Thursdaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Thursdaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Thursdaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Thursdaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Fridaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Fridaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Fridaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Fridaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Saturdaybf === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Saturdaybf === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Saturdaybf === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Saturdaybf === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }


                                </div>

                                <div className='circle_first second_cir'>

                                    {Sundaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Sundaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Sundaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Sundaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Mondaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Mondaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Mondaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Mondaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Tuesdaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Tuesdaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Tuesdaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Tuesdaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Wednesdaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Wednesdaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Wednesdaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Wednesdaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Thursdaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Thursdaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Thursdaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Thursdaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Fridaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Fridaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Fridaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Fridaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Saturdaylu === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Saturdaylu === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Saturdaylu === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Saturdaylu === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                </div>


                                <div className='circle_first third_cir'>
                                    {Sundaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Sundaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Sundaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Sundaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Mondaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Mondaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Mondaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Mondaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Tuesdaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Tuesdaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Tuesdaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Tuesdaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Wednesdaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Wednesdaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Wednesdaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Wednesdaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Thursdaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Thursdaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Thursdaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Thursdaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Fridaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Fridaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Fridaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Fridaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }

                                    {Saturdaydi === 0 &&
                                        <div className="circle_purple">&#10005;</div>
                                    }
                                    {Saturdaydi === 1 &&
                                        <div className="circle">&#10004;</div>
                                    }
                                    {Saturdaydi === 2 &&
                                        <div className="circle_rose">&#10004;</div>
                                    }
                                    {Saturdaydi === 3 &&
                                        <div className="circle_transparent">&#10004;</div>
                                    }
                                </div>

                            </div>




                        </div>
                        <div className='activity_dot_info meal_plan_dot'>
                            <span>
                                <span className="dotcarbs dotcarbsactivity green_back"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Following plan</span>
                                <span className='activyt_dot_data'>{FollowingPlanCount}</span>
                            </span>
                            <span>
                                <span className="dotfat dotcarbsactivity purple_back"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Skipped or Not updated</span>
                                <span className='activyt_dot_data'>{SkippedPlanCount}</span>
                            </span>
                            <span>
                                <span className="dotprotein dotcarbsactivity rose_back"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Outside plan</span>
                                <span className='activyt_dot_data'>{OutsidePlanCount}</span>
                            </span>
                        </div>

                    </div>
                    {/* <div className='health_box block_box'>
                        <div className='title_left'>HEALTHY HABITS</div>
                    </div> */}
                    <div className='weight_box block_box  weight_bar_block'>
                        <div className='title_left'><span className='activity_left_label'>WEIGHT</span></div>
                        <span className='target_goal_activity'>Today's Goal : {TodaysWeight}/{TargetWeight} {profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}</span>
                        <br />
                        <div className='row changed_activity_row'>
                            <span><span className='label'>Change</span><span className='value'>{Changedlbs} {profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}</span></span>
                        </div>
                        <div className='bar_div'>
                            <Bar width="200" height="200" data={Weightdata} options={options} />
                        </div>
                        <div className='row low_level_activity_info'>
                            <div className='col'>
                                {RegisteredInitialWeight} {profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}
                            </div>
                            <div className='col'>
                                {TodaysWeight} {profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}
                            </div>
                            <div className='col'>
                                {Changedlbs} {profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}
                            </div>
                        </div>
                        <div className='row low_level_activity_value'>
                            <div className='col'>
                                Start
                            </div>
                            <div className='col'>
                                Today
                            </div>
                            <div className='col'>
                                Change
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right_sub_inner_block'>
                    <div className='nutrients_box block_box'>
                       <div style={{display:'flex',gap:'5px'}}>
                        <div style={{fontWeight:'500'}}>Target goal:</div>
                       <select style={{marginBottom:'5px',width:'50%',borderRadius:'4px',cursor:'pointer'}} onChange={(e)=>{GetMyGoal(e.target.value)}}>
                            <option value={'AI'}>AI goal</option>
                            <option value={'My Goal'}>My goal</option>
                        </select>
                       </div>
                       
                        <div className='title_left micro_label'><span className='activity_left_label'>MICRO NUTRIENTS</span><span className='target_goal_activity kcal_activity'>Goal Kcal:{PiechartKcaldata}</span></div>

                        <div className='pie_chart_row_activity'>
                            <Pie data={piedata} options={pieoptions} />
                          
                        </div>
                        <div style={{display:'flex',justifyContent:'center'}}>(Today)</div>
                        <div className='activity_dot_info'>
                            <span>
                                <span className="dotcarbs dotcarbsactivity"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Carbs</span>
                                <span className='activyt_dot_data'>{TotalCarb} g</span>
                            </span>
                            <span>
                                <span className="dotfat dotcarbsactivity"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Fats</span>
                                <span className='activyt_dot_data'>{TotalFat} g</span>
                            </span>
                            <span>
                                <span className="dotprotein dotcarbsactivity"></span>
                                <span className="pie_chart_bottom_div_label dotcarbsactivitylabel">Proteins</span>
                                <span className='activyt_dot_data'>{TotalProtein} g</span>
                            </span>
                        </div>
                    </div>
                    <div className='weight_box block_box  weight_bar_block'>
                        <div className='title_left'><span className='activity_left_label'>WATER</span></div>
                        <span className='target_goal_activity'>Today's Goal : {TodaysWater}/{TargetWater} Oz</span>
                        <br />
                        <div className='row changed_activity_row'>
                            <span><span className='label'>Cups tracked</span><span className='value'>{TodaysWater} Oz</span></span>
                        </div>
                        <div className='bar_div'>
                            <Bar width="200" height="200" data={Waterdata} options={options} />
                        </div>

                    </div>

                </div>
            </div>

            <div className='right_inner_block'>
                <div className='calendar_box block_box'>

                </div>
                <div className='program_box block_box'>
                    {Blogs.map((blog, index) => (
                        <div key={blog.id}>
                            {index === 0 ?
                                <div>
                                    <div className='title_left'><h5>Program</h5></div>
                                    <div className='title_right'>{readCount + "/" + blogCount}</div>
                                    <div style={{ "clear": "both" }} />
                                    <div className='read_box'>
                                        <span className='readcount'>{readCount ? readCount : "None"}</span> Article Read
                                    </div>
                                </div>
                                : null}
                            <div style={{ "clear": "both" }} />
                            <div className='blog_box'>
                                <div className='left_box'>
                                    <div className={blog.blog_reads[0] ? "read" : "unread"}>{blog.blog_reads[0] ? <i className="fa fa-check" aria-hidden="true"></i> : blog.weight === 0 ? "*" : blog.weight}</div>
                                </div>
                                <div className='right_box'>
                                    <div className='day'>{blog.weight === 0 ? "Welcome" : "Day " + blog.weight}</div>
                                    <div className='title'>{blog.title}</div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>

        </div>

    )
}

export default Progress
import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const ZoomEvents = () => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [loader, Setloader] = useState(true);

    useLayoutEffect(() => {
       
        if (document.referrer === 'https://marketplace.zoom.us/' || document.referrer === 'https://zoom.us/' ) {

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let Code = params.get('code');
            let Error = params.get('error');
            if (Code !== null && Code !== '') {
                StoreAccessToken(Code);
            }
            if (Error !== null && Error !== '') {
                window.location.href = process.env.REACT_APP_FRONTEND_URL + '/profile?zoom_video=failed&msg=access_denied';
            }
        } else {
            alert('Webhook not called from zoom marketplace');
            Setloader(false);
            return false;
        }
    }, []);

    const StoreAccessToken = async (codetoken) => {
        try {
            const response = await axiosJWT.post("/zoom/get-token",
                {
                    zoom_code: codetoken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                window.location.href = response.data.link + '&msg=' + response.data.msg + ''
            }

        } catch (err) {
            window.location.href = err.response.data.link + '&msg=' + err.response.data.msg + ''
        }
    }

    return (<div>
        {
            loader === true ? <div id="semiTransparenDivLoader"></div> : null
        }

    </div>)
}
export default ZoomEvents
import React, { useEffect } from "react";

const DailyActivityLevel = (props) => {
  const CheckPhysical = (e) => {
    var tabLink = document.querySelectorAll(".option_div");

    const category = e.target
      .closest(".option_div")
      .getAttribute("data-activity");

    tabLink.forEach((box) => {
      box.classList.remove("active");
    });
    e.target.closest('.option_div').classList.add("active");

    if (category === "") {
      props.DisableNextButton();
    } else {
      props.SetActivityLevel(category);
      props.EnableNextButton();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.ActivityLevel) {
        if (props.ActivityLevel !== "") {
          const el1 = document.querySelector(
            '.option_div[data-activity="' + props.ActivityLevel + '"]'
          );
          el1.classList.add("active");
          props.EnableNextButton();
        }
      }
    }, 1);
    if(props.SetShowSubmitButton)
    {
        props.SetShowSubmitButton(false)
    }
  }, []);

  return (
    <div className="daily_activity">
      <div className="head_row">
        <span className="heading">Daily Activity Level</span>
      </div>
      <div className="sub_head_row">
        <span className="heading">How physically active are you?</span>
      </div>
      <div className="options_row">
        <div
          className="option_div"
          data-activity={"Couch Potato"}
          onClick={CheckPhysical}
        >
          <p>Couch Potato</p>
        </div>
        <div
          className="option_div"
          data-activity={"Somewhat Active"}
          onClick={CheckPhysical}
        >
          <p>Somewhat active</p>
        </div>
        <div
          className="option_div"
          data-activity={"Average Activity"}
          onClick={CheckPhysical}
        >
          <p>Average Activity</p>
        </div>
        <div
          className="option_div"
          data-activity={"Very Active"}
          onClick={CheckPhysical}
        >
          <p>Very active</p>
        </div>
        <div
          className="option_div"
          onClick={CheckPhysical}
          data-activity={"Extremely Active"}
        >
          <p>Extremely Active</p>
        </div>
      </div>
    </div>
  );
};
export default DailyActivityLevel;

import React, { useEffect } from "react";
import EggIcon from '../../../images/Egg-Icon.png'
import NutsIcon from '../../../images/Nuts-Icon.png'
import CheeseIcon from '../../../images/Cheese-Icon.png'
import CottageCheeseIcon from '../../../images/Cottage-Cheese-Icon.png'
import ButterIcon from '../../../images/Butter-Icon.png'
import CoconutIcon from '../../../images/Coconut-Icon.png'


const OtherFood =(props)=>{

    var OtherFoodArray=[];

    const CheckImage=(e)=>{
        var el = e.target;
        var closestParent = el.closest(".options");
    
        const ClickedId = closestParent.getAttribute("data-target");
        const ClosetestImgDiv = el.closest(".img_div");

        if (ClosetestImgDiv.classList.contains("active")) {    
            var filteredArray = OtherFoodArray.filter(function(e) { return e !== ClickedId });
            OtherFoodArray=filteredArray;
            ClosetestImgDiv.classList.remove("active");
          } else {
            OtherFoodArray.push(ClickedId)
            ClosetestImgDiv.classList.add("active");
          }

          if(OtherFoodArray.length>0)
          {       
           props.EnableNextButton();
           props.SetOtherFoodData(OtherFoodArray);
          }
          else{
           props.DisableNextButton();
          }
    }

    useEffect(() => {
        setTimeout(() => {
          if (props.OtherFoodData) {
       
            if (props.OtherFoodData.length !== 0) {
                OtherFoodArray=props.OtherFoodData
              props.OtherFoodData.forEach(element => {
                const el1 = document.querySelector(
                  '.meat .options_div_parent .options_div .options[data-target="'+element+'"] .img_div'
                );

                el1.classList.add("active");                
              });    
    
              props.EnableNextButton();           
            } else {            
              props.DisableNextButton();
            }
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, [props]);


    return (
        <div className="meat">
        <div className="head_row">
            <span className="heading">Other Food</span>
        </div>
        <div className="sub_head_row">
            <span className="heading">Please Select Which Other Foods You Would Like To Be Included</span>
        </div>
        <div className="options_div_parent">
        <div className="options_div">
            <div className="options" data-target="Egg" onClick={CheckImage}>
                <div className="img_div">
                    <img src={EggIcon} />
                    <p className="title">Egg</p>
                </div>
            </div>
            <div className="options" data-target="Nuts" onClick={CheckImage}>
                <div className="img_div">
                    <img src={NutsIcon} />
                    <p className="title">Nuts</p>
                </div>
            </div>
            <div className="options" data-target="Cheese" onClick={CheckImage}>
                <div className="img_div">
                    <img src={CheeseIcon} />
                    <p className="title">Cheese</p>
                </div>
            </div>
            <div className="options" data-target="Cottage-Cheese" onClick={CheckImage}>
                <div className="img_div">
                    <img src={CottageCheeseIcon} />
                    <p className="title">Cottage Cheese</p>
                </div>
            </div>
            <div className="options" data-target="Butter" onClick={CheckImage}>
                <div className="img_div">
                    <img src={ButterIcon}/>
                    <p className="title">Butter</p>
                </div>
            </div>
            <div className="options" data-target="Coconut" onClick={CheckImage}>
                <div className="img_div">
                <img src={CoconutIcon}/>
                    <p className="title">Coconut</p>
                </div>
            </div>
        </div>
        </div>        
    </div>
    )
}
export default OtherFood
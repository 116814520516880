import React, { useEffect, useState, useRef } from "react";
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import axios from "axios";
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SignatureCanvas from "react-signature-canvas";
import ReactToPrint from 'react-to-print';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DocumentsUpload from "../../AdminComponents/Documents/documents";

const ListOfAgreements = () => {

    const [PerPage, SetPerPage] = useState(10);
    const [PageNumber, SetPageNumber] = useState(1);
    const [TotalRecordCount, SetTotalRecordCount] = useState(0);
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const email = localStorage.getItem("email");
    var result = '';


    const axiosJWT = axios.create();
    const [AgreementList, SetAgreementList] = useState([])
    let canvasRef;
    let [cBase64, setBase64] = useState();


    const ListOfAgreementsList = async (pagenumber) => {

        SetPageNumber(pagenumber);

        const response = await axiosJWT.get('documents/list-submit-service-agreement?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetAgreementList(response.data.agreements);
        SetTotalRecordCount(response.data.Totalcount);
    }

    const changePageNumberS = (pagenumber) => {
        ListOfAgreementsList(pagenumber);
    }

    const PreviewAndSign = async (e) => {
        var Id = e.target.getAttribute('data-id');

        SetSubmitAggreementId(Id);

        const response = await axiosJWT.get('documents/list-submit-service-agreement-detail?id=' + Id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response) {
            Setshowp(true);
            var HtmlDoc = response.data.list.service_agreement.service_agreement_template;
            SetSubmitDocData('');
            result = '';
            result = HtmlDoc;
            result = result.replaceAll("[CLIENT_NAME]", first_name + ' ' + last_name);
            result = result.replaceAll("[COACH_NAME]", response.data.list.user.first_name + ' ' + response.data.list.user.last_name);
            result = result.replaceAll("[CLIENT_EMAIL]", email);
            result = result.replaceAll("[COACH_EMAIL]", response.data.list.user.email);
            SetSubmitDocData(result);
            Setpreviewhtml(result);
        }
    }

    const [showp, Setshowp] = useState(false);

    const handleClosep = () => {
        Setshowp(false);
    }

    const [previewhtml, Setpreviewhtml] = useState('');
    const [SubmitAggreementId, SetSubmitAggreementId] = useState('');
    const [SubmitDocData, SetSubmitDocData] = useState('');

    const ClearCanvas = () => {
        canvasRef.clear();
        setBase64('');
    }

    const SubmitAggreement = async () => {

        const response = await axiosJWT.post('documents/submit-service-agreement',
            {
                'id': SubmitAggreementId,
                'signature': cBase64,
                'document_data': SubmitDocData


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleClosep();
            ListOfAgreementsList(PageNumber);
        }
    }

    const ViewSignedAgreement = async (e) => {
        var Id = e.target.getAttribute('data-id');

        const response = await axiosJWT.get('documents/list-submit-service-agreement-detail?id=' + Id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetSubmittedHtml(response.data.list.signed_document_data);
        SetSignedSrc(response.data.list.signed_data);
        Setshowps(true);
    }

    const [showps, Setshowps] = useState(false);
    const handleCloseps = () => {
        Setshowps(false);
    }

    const [SubmittedHtml, SetSubmittedHtml] = useState('');
    const [SignedSrc, SetSignedSrc] = useState('');

    const componentRef = useRef();

    const [ShowAgreementTab, SetShowAgreementTab] = useState(true);
    const [ShowDocumentsTab, SetShowDocumentsTab] = useState(false);

    useEffect(() => {
        document.title = 'Client-Agreements';
        ListOfAgreementsList(1);
    }, [])

    const ChangeTab = (e) => {
        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab')
        SetShowAgreementTab(false)
        SetShowDocumentsTab(false)
        if (tab === 'agreements') {
            SetShowAgreementTab(true)
            ListOfAgreementsList(1)
        }
        else if (tab === 'directories') {
            SetShowDocumentsTab(true)
        }
    }

    return (

        <div>
            {
                ShowAgreementTab === true &&
                <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
                    <section className="dashboard">
                        <div className="container">
                            <div className="row client_agreement_docs">
                                <div className='menubox'>
                                    <div className='tabmenu'>
                                        <a className="" data-tab={'directories'} onClick={(e) => { ChangeTab(e) }}>Directory</a>
                                        <a className="active" data-tab={'agreements'} onClick={(e) => { ChangeTab(e) }}>Agreements</a>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="row">
                                    <h5>List of agreements</h5>
                                </div>
                                <div className="row">
                                    <Table className='table is-striped is-fullwidth'>
                                        <Thead className='thead'>
                                            <Tr className="trow">
                                                <Th className='row_name'>Coach Name</Th>
                                                <Th className='row_name'>Coach Email</Th>
                                                <Th className='row_name'>Service Agreement Name</Th>
                                                <Th className='row_name'>Signed Status</Th>
                                                <Th className='row_name'>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                            {AgreementList.length > 0 && AgreementList.map((AgreementList, index) => (
                                                <Tr className="trow" key={AgreementList.id}>
                                                    <Td><img className='table_profile_img' alt="BigCo Inc. logo" src={AgreementList.user.user_profile_photo ?  AgreementList.user.user_profile_photo : (AgreementList.user.gender === 'MALE' ? male_icon : female_icon)} />{AgreementList.user.first_name + ' ' + AgreementList.user.last_name}</Td>
                                                    <Td>{AgreementList.user.email}</Td>
                                                    <Td>{AgreementList.service_agreement.service_agreement_name}</Td>
                                                    <Td>
                                                        {AgreementList.signed_status === false &&
                                                            <button type="button" className="btn btn-success view_activity_button submitted_client_questionnaire" data-id={AgreementList.id} onClick={(e) => { PreviewAndSign(e) }}>Sign</button>
                                                        }
                                                        {AgreementList.signed_status === true &&
                                                            <button type="button" className="btn btn-success view_activity_button submitted_client_questionnaire">Signed</button>
                                                        }
                                                    </Td>
                                                    <Td className="pdf_download_agreement_td">
                                                        {AgreementList.signed_status === true &&
                                                            <i data-id={AgreementList.id} className="fa fa-eye pointer_pdf_i" aria-hidden="true" onClick={ViewSignedAgreement}></i>
                                                        }
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </div>

                                <div>
                                    <Pagination
                                        activePage={PageNumber}
                                        itemsCountPerPage={PerPage}
                                        totalItemsCount={TotalRecordCount}
                                        onChange={changePageNumberS.bind(this)}
                                        prevPageText="<"
                                        nextPageText=">"
                                        hideFirstLastPages={true}
                                        linkClassPrev="pagination_prev"
                                        linkClassNext="pagination_next"
                                        linkClass="pagenumbers grayfont"
                                    />
                                </div>
                            </div>
                        </div>

                        <Modal show={showp} onHide={handleClosep} className='add_billing_popup preview_service_agreement_popup'>
                            <Modal.Header closeButton>
                                <Modal.Title>{'Agreement'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div dangerouslySetInnerHTML={{ __html: previewhtml }} />
                                <div className="signature_row">
                                    <div>Your signature</div>
                                    <div>
                                        <SignatureCanvas
                                            ref={ref => (canvasRef = ref)}
                                            onEnd={e => {
                                                //console.log(canvasRef.isEmpty());
                                                setBase64(canvasRef.toDataURL());
                                            }}
                                            canvasProps={{
                                                width: 400,
                                                height: 200
                                            }}
                                            backgroundColor={'rgba(63, 245, 39, 0.24)'}
                                        />
                                    </div>
                                    <button onClick={ClearCanvas}>Clear</button>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="display_block">
                                <Button variant="secondary" className='close_button_commission' onClick={handleClosep} >
                                    Back
                                </Button>
                                <Button variant="primary" className='save_button_commission float_right' onClick={SubmitAggreement}
                                >
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showps} onHide={handleCloseps} className='add_billing_popup preview_service_agreement_popup'>
                            <Modal.Header closeButton>
                                <Modal.Title>{'Agreement'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body ref={componentRef}>
                                <div dangerouslySetInnerHTML={{ __html: SubmittedHtml }} />
                                <div>
                                    <img src={SignedSrc} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="display_block">
                                <Button variant="secondary" className='close_button_commission' onClick={handleCloseps} >
                                    Back
                                </Button>
                                <ReactToPrint
                                    trigger={() => <button className='withdraw_but export_doc_button'>Print PDF</button>}
                                    content={() => componentRef.current}
                                />
                            </Modal.Footer>
                        </Modal>
                    </section>
                </div>
            }
            {
                ShowDocumentsTab === true && <DocumentsUpload SetShowAgreementTab={SetShowAgreementTab} SetShowDocumentsTab={SetShowDocumentsTab} ListOfAgreementsList={ListOfAgreementsList} />
            }
        </div>
    )
}

export default ListOfAgreements
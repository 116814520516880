/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Pie } from 'react-chartjs-2';
import MealPlanImportedRecipeList from './MealPlanImportedRecipeList';
import DummyrecipeImg from '../../../images/pexels-photo-1640777.jpeg';
import ImportedRecipePopup from './ImportedRecipePopup';

const Foodplans = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const { id } = useParams();
    const [planId, setplanId] = useState('');
    const [dayId, setdayId] = useState('');
    const [planData, setplanData] = useState([]);
    const [dayList, setdayList] = useState([]);
    const [recipeslist, setrecipeslist] = useState([]);
    const [PercentageArray, SetPercentageArray] = useState([]);
    const [ShowRecipeLibraryTab, SetShowRecipeLibraryTab] = useState(true);
    const [ShowImportedRecipeTab, SetShowImportedRecipeTab] = useState(false);
    const [ImportedRecipeData,SetImportedRecipeData]=useState([]);
    const [ImportedRecipePopShow,SetImportedRecipePopShow]=useState(false);

    const pie_options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const pie_data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };

    var [foodMenu, setfoodMenu] = useState('breakfast');
    var [recipetab, setRecipeTabMenu] = useState('ingredients');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [recipeData, setrecipeData] = useState('');

    const [breakfastId, setbreakfastId] = useState();
    const [lunchId, setlunchId] = useState();
    const [dinnerId, setdinnerId] = useState();
    const [snackId,setsnackId]=useState();
    const [breakfastData, setbreakfastData] = useState('');
    const [lunchData, setlunchData] = useState('');
    const [dinnerData, setdinnerData] = useState('');
    const [snacksData,setsnacksData]=useState('');
    const [searchrecipeLibrary, setsearchrecipeLibrary] = useState('');
    const [recipedayData, setrecipedayData] = useState([]);

    const [goalcarb, setgoalcarb] = useState(0);
    const [goalpro, setgoalpro] = useState(0);
    const [goalfat, setgoalfat] = useState(0);
    const [bfcarb, setbfcarb] = useState(0);
    const [lcarb, setlcarb] = useState(0);
    const [dcarb, setdcarb] = useState(0);
    const [scarb, setscarb] = useState(0);
    const [bfpro, setbfpro] = useState(0);
    const [lpro, setlpro] = useState(0);
    const [dpro, setdpro] = useState(0);
    const [spro, setspro] = useState(0);
    const [bffat, setbffat] = useState(0);
    const [lfat, setlfat] = useState(0);
    const [dfat, setdfat] = useState(0);
    const [sfat, setsfat] = useState(0);
    const [carbEst, setcarbEst] = useState(0);
    const [proEst, setproEst] = useState(0);
    const [fatEst, setfatEst] = useState(0);
    const [message, setMessage] = useState('');


    useEffect(() => {
        load_plan(id);
    }, []);

    const axiosJWT = axios.create();

    const load_plan = async (id) => {
        setplanId(id);

        await axiosJWT.get("/meal-plan/" + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            if (response.status === 200) {
                setplanData(response.data);

                // console.log(response.data)

                if (response.data.breakfast_keys || response.data.lunch_keys || response.data.dinner_keys || response.data.snack_keys) {
                    var getData = [];
                    var recipe_key_length = [];
                    var breakfast_exist = JSON.parse(response.data.breakfast_keys);
                    var lunch_exist = JSON.parse(response.data.lunch_keys);
                    var dinner_exist = JSON.parse(response.data.dinner_keys);
                    var snack_exist = JSON.parse(response.data.snack_keys);

                    if (breakfast_exist)
                        recipe_key_length.push({ 'menu': 'breakfast', 'size': breakfast_exist.length });

                    if (lunch_exist)
                        recipe_key_length.push({ 'menu': 'lunch', 'size': lunch_exist.length });

                    if (dinner_exist)
                        recipe_key_length.push({ 'menu': 'dinner', 'size': dinner_exist.length });

                    if (snack_exist)
                        recipe_key_length.push({ 'menu': 'snack', 'size': snack_exist.length });

                    const menuAscending = [...recipe_key_length].sort((a, b) =>
                        a.size > b.size ? 1 : -1,
                    );

                    if (menuAscending[0].menu === 'breakfast') {
                        breakfast_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: item.recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: snack_exist[index].recipeid });
                        });
                    }
                    if (menuAscending[0].menu === 'lunch') {
                        lunch_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: item.recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: snack_exist[index].recipeid });
                        });
                    }
                    if (menuAscending[0].menu === 'dinner') {
                        dinner_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: item.recipeid, snack_key: snack_exist[index].recipeid });
                        });
                    }
                    if (menuAscending[0].menu === 'snack') {
                        snack_exist.forEach(function (item, index) {
                            getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: item.recipeid });
                        });
                    }

                    setrecipedayData(getData);
                }

                var meal_plan_days = response.data.days;

                var day_list = Array(meal_plan_days).fill(0).map((_, ind) => ({ id: `${ind + 1}` }));

                setdayList(day_list);

                //console.log("carb: "+goal_carb_calc+" "+goal_pro_calc+" "+goal_fat_calc)     

                setTimeout(() => {
                    loadDay(1);
                }, 1000);

            }
            if (response.status === 404) {
                console.log('data not found');
            }
        });
    }


    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setfoodMenu(tab_for);

        loadRecipes(tab_for, '');
        //console.log(tab_for)
        setMessage('');
    }

    const ChangeRecipeLibraryTab = (e) => {

        const tab_for = e.target.getAttribute("data-item");

        if (tab_for === 'Imported Recipes') {
            SetShowImportedRecipeTab(true);
            SetShowRecipeLibraryTab(false);
        }
        if (tab_for === 'Recipe Library') {
            SetShowRecipeLibraryTab(true);
            SetShowImportedRecipeTab(false);
        }

        const alltabs = document.querySelectorAll(".recipe_library_tab a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");
    }

    const LeftArrow = () => {
        const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

        return (
            <div className='left_arrow' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            </div>
        );
    }

    const RightArrow = () => {
        const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

        return (
            <div className='right_arrow' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            </div>
        );
    }


    function check_loadDay(day_id) {

        // console.log(recipedayData);

        var filled = 0;
        recipedayData.forEach(element => {

            if (element.day === day_id) {
                //console.log(element.day+" "+day_id+" "+element.breakfast_key)
                if (element.breakfast_key !== '' && element.lunch_key !== '' && element.dinner_key !== '' && element.snack_key !== '')
                    filled = 4;
            }
        });

        return (filled);

        /* axiosJWT.get("/meal-plan/plan/"+id+"/"+day_id, {
             headers: {
                 Authorization: `Bearer ${token}`
             }
         }).then((response) => {
             return(Object.keys(response.data).length);
         }); */
    }

    const loadDay = async (day_id) => {

        const alldays = document.querySelectorAll(".day_box .box .card");
        alldays.forEach((element) => element.classList.remove('active'));

        // var filled = 0;

        // if(recipedayData)
        // {
        //     recipedayData.forEach(element => {
        //         if (element.day === day_id) {
        //             //console.log(element.day+" "+day_id+" "+element.breakfast_key)
        //             if (element.breakfast_key !== '' && element.lunch_key !== '' && element.dinner_key !== '')
        //                 filled = 3;
        //         }
        //     });
        // }


        // if (filled === 3)
        //     document.getElementById("day_" + day_id).setAttribute("class", "card active filled");
        // else
        // {
        //     document.getElementById("day_" + day_id).setAttribute("class", "card active"); 
        // }

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        document.getElementById("tab_breakfast").setAttribute("class", "active");
        setfoodMenu('breakfast');
        loadRecipes('breakfast','');

        setdayId(day_id);
        setbreakfastData('');
        setlunchData('');
        setdinnerData('');
        setsnacksData('')
        setMessage('');
        setbreakfastId('');
        setlunchId('');        
        setdinnerId('');
        setsnackId('');

        const response = await axiosJWT.get("/meal-plan/plan/" + id + "/" + day_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.breakfast_key && response.data.lunch_key && response.data.dinner_key && response.data.snack_keys)
            document.getElementById("day_" + day_id).setAttribute("class", "card active filled");
        else
            document.getElementById("day_" + day_id).setAttribute("class", "card active");

        //console.log(Object.keys(response.data).length);

        var total_carb = 0;
        var total_pro = 0;
        var total_fat = 0;


        if (response.data.breakfast_key != '' && response.data.breakfast_key != null) {

            var CheckKeyType = response.data.breakfast_key;

            if (!CheckKeyType.startsWith('FITPALIR')) {

                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.breakfast_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setbreakfastData(res.data);
                    setbreakfastId(response.data.breakfast_key);
                    //console.log(res.data.NutritionInfo.TotalCarbs)
                    setbfcarb(res.data.NutritionInfo.TotalCarbs);
                    setbfpro(res.data.NutritionInfo.Protein);
                    setbffat(res.data.NutritionInfo.TotalFat);

                    total_carb = (total_carb + res.data.NutritionInfo.TotalCarbs);
                    total_pro = (total_pro + res.data.NutritionInfo.Protein);
                    total_fat = (total_fat + res.data.NutritionInfo.TotalFat);
                });

            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.breakfast_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;

                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else{
                        recipeImage=DummyrecipeImg;
                    }

                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setbreakfastData(BreakfastData);
                    setbreakfastId(RecipeData.data.recipe.recipe_uid);
                    setbfcarb(RecipeData.data.recipe.recipe_carbs);
                    setbfpro(RecipeData.data.recipe.recipe_proteins);
                    setbffat(RecipeData.data.recipe.recipe_fats);
    
    
                    total_carb = (total_carb + RecipeData.data.recipe.recipe_carbs);
                    total_pro = (total_pro + RecipeData.data.recipe.recipe_proteins);
                    total_fat = (total_fat + RecipeData.data.recipe.recipe_fats);
                }
            }
        }

        if (response.data.lunch_key != '' && response.data.lunch_key != null) {

            var CheckKeyType = response.data.lunch_key;

            if (!CheckKeyType.startsWith('FITPALIR')) {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.lunch_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setlunchData(res.data);
                    setlunchId(response.data.lunch_key);
                    setlcarb(res.data.NutritionInfo.TotalCarbs);
                    setlpro(res.data.NutritionInfo.Protein);
                    setlfat(res.data.NutritionInfo.TotalFat);

                    total_carb = (total_carb + res.data.NutritionInfo.TotalCarbs);
                    total_pro = (total_pro + res.data.NutritionInfo.Protein);
                    total_fat = (total_fat + res.data.NutritionInfo.TotalFat);
                });
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.lunch_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {
                     var LunchData = {};
                var recipeId = RecipeData.data.recipe.recipe_uid;
                var recipeImage = '';
                var recipeTitle = RecipeData.data.recipe.recipe_name;
                if (RecipeData.data.recipe.recipe_image !== '') {
                    recipeImage = RecipeData.data.recipe.recipe_image;
                }
                else{
                    recipeImage=DummyrecipeImg;
                }

                LunchData = {
                    RecipeID: recipeId,
                    PhotoUrl: recipeImage,
                    Title: recipeTitle
                }

                setlunchData(LunchData);
                setlunchId(RecipeData.data.recipe.recipe_uid);
                setlcarb(RecipeData.data.recipe.recipe_carbs);
                setlpro(RecipeData.data.recipe.recipe_proteins);
                setlfat(RecipeData.data.recipe.recipe_fats);

                total_carb = (total_carb + RecipeData.data.recipe.recipe_carbs);
                total_pro = (total_pro + RecipeData.data.recipe.recipe_proteins);
                total_fat = (total_fat + RecipeData.data.recipe.recipe_fats);
                }
            }
        }

        if (response.data.dinner_key != '' && response.data.dinner_key != null) {

            var CheckKeyType = response.data.dinner_key;

            if (!CheckKeyType.startsWith('FITPALIR')) {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.dinner_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setdinnerData(res.data);
                    setdinnerId(response.data.dinner_key);
                    setdcarb(res.data.NutritionInfo.TotalCarbs);
                    setdpro(res.data.NutritionInfo.Protein);
                    setdfat(res.data.NutritionInfo.TotalFat);

                    total_carb = (total_carb + res.data.NutritionInfo.TotalCarbs);
                    total_pro = (total_pro + res.data.NutritionInfo.Protein);
                    total_fat = (total_fat + res.data.NutritionInfo.TotalFat);
                });
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.dinner_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {

                    var DinnerData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid;
                    var recipeImage='';
                    var recipeTitle=RecipeData.data.recipe.recipe_name;

                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image;
                    }
                    else{
                        recipeImage=DummyrecipeImg;
                    }

                    DinnerData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }

                    setdinnerData(DinnerData);
                    setdinnerId(RecipeData.data.recipe.recipe_uid);
                    setdcarb(RecipeData.data.recipe.recipe_carbs);
                    setdpro(RecipeData.data.recipe.recipe_proteins);
                    setdfat(RecipeData.data.recipe.recipe_fats);
                    
                    total_carb = (total_carb + RecipeData.data.recipe.recipe_carbs);
                    total_pro = (total_pro + RecipeData.data.recipe.recipe_proteins);
                    total_fat = (total_fat + RecipeData.data.recipe.recipe_fats);
                }
            }
        }

        //snacks start

        if (response.data.snack_keys != '' && response.data.snack_keys != null) {     

            var CheckKeyType = response.data.snack_keys;

            if (!CheckKeyType.startsWith('FITPALIR')) {
                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.snack_keys + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setsnacksData(res.data);
                    setsnackId(response.data.snack_keys);
                    setscarb(res.data.NutritionInfo.TotalCarbs);
                    setspro(res.data.NutritionInfo.Protein);
                    setsfat(res.data.NutritionInfo.TotalFat);

                    total_carb = (total_carb + res.data.NutritionInfo.TotalCarbs);
                    total_pro = (total_pro + res.data.NutritionInfo.Protein);
                    total_fat = (total_fat + res.data.NutritionInfo.TotalFat);
                });
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.snack_keys, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(RecipeData.data.recipe!==null)
                {

                    var SnackData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid;
                    var recipeImage='';
                    var recipeTitle=RecipeData.data.recipe.recipe_name;

                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image;
                    }
                    else{
                        recipeImage=DummyrecipeImg;
                    }

                    SnackData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }

                    setsnacksData(SnackData);
                    setsnackId(RecipeData.data.recipe.recipe_uid);
                    setscarb(RecipeData.data.recipe.recipe_carbs);
                    setspro(RecipeData.data.recipe.recipe_proteins);
                    setsfat(RecipeData.data.recipe.recipe_fats);
                    
                    total_carb = (total_carb + RecipeData.data.recipe.recipe_carbs);
                    total_pro = (total_pro + RecipeData.data.recipe.recipe_proteins);
                    total_fat = (total_fat + RecipeData.data.recipe.recipe_fats);
                }
            }
        }


        //snacks end

        const response_plan = await axiosJWT.get("/meal-plan/" + id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var goal_carb_calc = ((response_plan.data.calories * (response_plan.data.carbs / 100)) * 0.129598);
        var goal_pro_calc = ((response_plan.data.calories * (response_plan.data.proteins / 100)) * 0.129598);
        var goal_fat_calc = ((response_plan.data.calories * (response_plan.data.fats / 100)) * 0.129598);

        setgoalcarb(goal_carb_calc);
        setgoalpro(goal_pro_calc);
        setgoalfat(goal_fat_calc);
        var est_carb = ((total_carb / goal_carb_calc) * 100);
        var est_pro = ((total_pro / goal_pro_calc) * 100);
        var est_fat = ((total_fat / goal_fat_calc) * 100);

        setcarbEst(est_carb);
        setproEst(est_pro);
        setfatEst(est_fat);

        //console.log(est_fat+" test")

        loadRecipes();
    }

    const loadRecipes = async (menu, search) => {

        if (!menu)
            menu = foodMenu;

        // console.log(menu)
        if (menu === 'breakfast') {
            if (search != '' && search != null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto breakfast " + search + "&include_primarycat=breakfast&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto&include_primarycat=breakfast&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else if (menu === 'lunch') {
            if (search != '' && search != null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto " + search + "&include_primarycat=maindish&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto&include_primarycat=maindish&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else if (menu === 'dinner') {
            if (search != '' && search != null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto dinner " + search + " &photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto dinner&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else if (menu === 'snacks') {
            if (search != '' && search != null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto snacks " + search + " &photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto snacks&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }
        else {
            if (search != '' && search != null)
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto " + search + " &photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
            else
                axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=keto&photos=true&rpp=30&pg=1&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setrecipeslist(res.data.Results);
                });
        }

        setMessage('');

    }

    const showRecipe = async (e) => {
        const recipeId = e.target.getAttribute("data-item");
        if (!recipeId.startsWith('FITPALIR')) {

            axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {

                setShow(true);
                setrecipeData(res.data);
                SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)]);
                //console.log(res.data);
            });

        }
        else{

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + recipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(RecipeData.data.recipe!==null)
            {
                SetImportedRecipeData(RecipeData.data.recipe)
                SetImportedRecipePopShow(true)
            }
        }

        setMessage('');
    }

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }

    const recipeUpdate = async (e) => {   

        setMessage('');
        var recipeId = e.target.getAttribute("data-item");

        if (foodMenu === 'breakfast')
            setbreakfastId(recipeId);
        else if (foodMenu === 'lunch')
            setlunchId(recipeId);
        else if(foodMenu==='snacks')
            setsnackId(recipeId);
        else
            setdinnerId(recipeId);            

        axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
            //console.log(res.data.NutritionInfo)
            if (foodMenu === 'breakfast') {
                setbreakfastData(res.data);
                setbfcarb(res.data.NutritionInfo.TotalCarbs);
                setbfpro(res.data.NutritionInfo.Protein);
                setbffat(res.data.NutritionInfo.TotalFat);

                var total_carb = ((res.data.NutritionInfo.TotalCarbs) + lcarb + dcarb + scarb);
                var total_pro = (res.data.NutritionInfo.Protein + lpro + dpro + spro);
                var total_fat = (res.data.NutritionInfo.TotalFat + lfat + dfat + sfat);
            }
            else if (foodMenu === 'lunch') {
                setlunchData(res.data);
                setlcarb(res.data.NutritionInfo.TotalCarbs);
                setlpro(res.data.NutritionInfo.Protein);
                setlfat(res.data.NutritionInfo.TotalFat);

                var total_carb = (bfcarb + (res.data.NutritionInfo.TotalCarbs) + dcarb + scarb);
                var total_pro = (bfpro + res.data.NutritionInfo.Protein + dpro + spro);
                var total_fat = (bffat + res.data.NutritionInfo.TotalFat + dfat + sfat);
            }
            else if(foodMenu==='snacks')
            {
           
                setsnacksData(res.data);
                setscarb(res.data.NutritionInfo.TotalCarbs);
                setspro(res.data.NutritionInfo.Protein);
                setsfat(res.data.NutritionInfo.TotalFat);

                var total_carb = (bfcarb + lcarb + dcarb + (res.data.NutritionInfo.TotalCarbs));
                var total_pro = (bfpro + lpro + dpro + res.data.NutritionInfo.Protein);
                var total_fat = (bffat + lfat + dfat + res.data.NutritionInfo.TotalFat);
            }
            else {
                setdinnerData(res.data);
                setdcarb(res.data.NutritionInfo.TotalCarbs);
                setdpro(res.data.NutritionInfo.Protein);
                setdfat(res.data.NutritionInfo.TotalFat);

                var total_carb = (bfcarb + lcarb + scarb + (res.data.NutritionInfo.TotalCarbs));
                var total_pro = (bfpro + lpro + spro+ res.data.NutritionInfo.Protein);
                var total_fat = (bffat + lfat + sfat+ res.data.NutritionInfo.TotalFat);
            }

            var est_carb = ((total_carb / goalcarb) * 100);
            var est_pro = ((total_pro / goalpro) * 100);
            var est_fat = ((total_fat / goalfat) * 100);

            setcarbEst(est_carb);
            setproEst(est_pro);
            setfatEst(est_fat);

            /* console.log("carb:"+goalcarb+" "+total_carb+" "+est_carb);
             console.log("pro:"+goalpro+" "+total_pro+" "+est_pro);
             console.log("fat:"+goalfat+" "+total_fat+" "+est_fat); */
        });
    }

    const setRecipe = async (e) => {

        if (breakfastId != null && lunchId != null && dinnerId != null && snackId!==null && dayId != null) {
            const response = await axiosJWT.put("/meal-plan/plan/" + id + "/addrecipe",
                {
                    breakfast: breakfastId,
                    lunch: lunchId,
                    dinner: dinnerId,
                    snacks:snackId,
                    day: dayId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setMessage('Updated successfully!');
                //console.log(breakfastId+" "+lunchId+" "+dinnerId+" "+dayId); 
                document.getElementById("day_" + dayId).setAttribute("class", "card active filled");
            }
        }
        else
            setMessage("All 4 recipes should be choose.!");
    }

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="coach_food_plans container">
                    <div className='leftmenu'>
                        <NavLink to="/coach/plans/"><input type="button" value="< Back To Plan" /></NavLink>
                    </div>
                    <div className='rightmenu'>

                    </div>
                    <div style={{ "clear": "both" }} />

                    <div className='plan_data'>
                        <div className='left_block'>
                            <div className='title'>{planData.meal_plan_name}</div>
                            <div className='description color_grey'>{planData.meal_plan_description}</div>
                        </div>
                        <div className='right_block'>
                            <div className='title'>My Target</div>
                            <div className='goal_chart'>
                                <div className='carb_chart'>
                                    <span className='input' style={{ background: "linear-gradient(to right, green " + `${carbEst}` + "%,white 1%)" }}></span>
                                    <span className='label'>Carbs </span>
                                </div>
                                <div className='pro_chart'>
                                    <span className='input' style={{ background: "linear-gradient(to right, orange " + `${proEst}` + "%,white 1%)" }}></span>
                                    <span className='label'>Protein </span>
                                </div>
                                <div className='fat_chart'>
                                    <span className={fatEst > 100 ? 'input goal_over' : 'input'} style={{ background: "linear-gradient(to right, purple " + `${fatEst}` + "%,white 1%)" }}></span>
                                    <span className='label'>Fat </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ "clear": "both" }} />


                    <div className='day_box'>
                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onScroll={0} transitionDuration='3000' transitionBehavior='smooth'>
                            {dayList.map(({ id }) => (
                                <div key={id}>
                                    <div className='box' onClick={() => loadDay(parseInt(id))}>
                                        {/* <div className={parseInt(id) === 1 ? (check_loadDay(parseInt(id)) === 3 ? "card active filled" : "card active") : (check_loadDay(parseInt(id)) === 3? "card filled" : "card")} id={"day_" + id} > */}
                                        <div className={check_loadDay(parseInt(id)) === 4 ? "card filled" : "card"} id={"day_" + id} >
                                            <div><span className='day'>Day</span> <br /> {id}</div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </ScrollMenu>

                    </div>

                    <div className='menubox'>
                        <div className='tabmenu'>
                            <a id="tab_breakfast" className="active" onClick={changeTab} data-item="breakfast">Breakfast</a>
                            <a onClick={changeTab} data-item="lunch">Lunch</a>
                            <a onClick={changeTab} data-item="dinner">Dinner</a>
                            <a onClick={changeTab} data-item="snacks">Snacks</a>
                        </div>

                        <div className='action_buttons'>
                            {message && <label className="actionmsg">{message}</label>}
                            <Button className='green_button btn' style={{ "width": "100px" }} onClick={setRecipe}>Set Recipe</Button>
                        </div>

                    </div>

                    <div className='selected_recipe'>
                        <h6></h6>
                        <div className='recipes'>
                            {foodMenu === 'breakfast' ?
                                <div className='recipe_box'>
                                    {breakfastData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={breakfastData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={breakfastData.PhotoUrl} /></div>
                                            <div className='title'>{breakfastData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {foodMenu === 'lunch' ?
                                <div className='recipe_box'>
                                    {lunchData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={lunchData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={lunchData.PhotoUrl} /></div>

                                            <div className='title'>{lunchData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {foodMenu === 'dinner' ?
                                <div className='recipe_box'>
                                    {dinnerData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={dinnerData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={dinnerData.PhotoUrl} /></div>

                                            <div className='title'>{dinnerData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {foodMenu === 'snacks' ?
                                <div className='recipe_box'>
                                    {snacksData ?
                                        <div>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={snacksData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <div className='img'><img src={snacksData.PhotoUrl} /></div>

                                            <div className='title'>{snacksData.Title}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>

                    <div style={{ "clear": "both" }} />




                    {/* tab start */}

                    <div className='menubox'>
                        <div className='recipe_library_tab'>
                            <a className="active" onClick={ChangeRecipeLibraryTab} data-item="Recipe Library">Recipe Library</a>
                            <a onClick={ChangeRecipeLibraryTab} data-item="Imported Recipes">Imported Recipes</a>

                        </div>



                    </div>

                    {/* tab end */}

                    {
                        ShowRecipeLibraryTab === true ? <div className='recipes_library'>
                            <div className='leftmenu'>
                                <div className='title'>
                                    <h4>Recipe Library <span style={{ fontSize: "14px", color: "gray" }}>(choose recipe)</span></h4>
                                </div>
                            </div>
                            <div className='rightmenu'>
                                <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => setsearchrecipeLibrary(event.target.value)} />
                                <input className='greenbutton' type="button" value="Search" onClick={() => loadRecipes('', searchrecipeLibrary)} />
                            </div>
                            <div style={{ "clear": "both" }} />
                            <div className='recipes'>
                                {recipeslist.map((recipe, index) => (
                                    <div className='recipe_box' key={index} >
                                        <i className='fa fa-ellipsis-v viewdata' data-item={recipe.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                        <div className='img'><img src={recipe.PhotoUrl} data-item={recipe.RecipeID} onClick={(e) => recipeUpdate(e)} /></div>

                                        <div className='title'>{recipe.Title}</div>
                                    </div>
                                ))}
                            </div>
                        </div> : null
                    }

                    {
                        ShowImportedRecipeTab === true ? <MealPlanImportedRecipeList recipeslist={recipeslist} foodMenu={foodMenu} setMessage={setMessage} setbreakfastId={setbreakfastId} setlunchId={setlunchId} setdinnerId={setdinnerId} setsnackId={setsnackId} setbreakfastData={setbreakfastData} setlunchData={setlunchData} setdinnerData={setdinnerData} setsnacksData={setsnacksData} bfcarb={bfcarb} setbfcarb={setbfcarb} bffat={bffat} setbffat={setbffat} bfpro={bfpro} setbfpro={setbfpro} lcarb={lcarb} setlcarb={setlcarb} lfat={lfat} setlfat={setlfat} lpro={lpro} setlpro={setlpro} dcarb={dcarb} setdcarb={setdcarb} dfat={dfat} setdfat={setdfat} dpro={dpro} setdpro={setdpro} scarb={scarb} setscarb={setscarb} sfat={sfat} setsfat={setsfat} spro={spro} setspro={setspro} goalcarb={goalcarb} goalfat={goalfat} goalpro={goalpro} setcarbEst={setcarbEst} setfatEst={setfatEst} setproEst={setproEst} /> : null
                    }




                    <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>Recipe Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {recipeData ?
                                <div className='recipe_data'>
                                    <div className='title'>{recipeData.Title}</div>
                                    <div className='img'><img src={recipeData.PhotoUrl} /></div>
                                    <div className='nutrition_data'>

                                        <p className='pie_chart_kcal_data'>Calories: {(recipeData.NutritionInfo.TotalCalories).toFixed(2)} Kcal</p>
                                        <Pie data={pie_data} options={pie_options} />

                                        <hr />
                                        <div className="pie_chart_bottom_div">
                                            <span>
                                                <span className="dotcarbs"></span>
                                                <span className="pie_chart_bottom_div_label">Carbs</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalCarbs).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                            <span>
                                                <span className="dotfat"></span>
                                                <span className="pie_chart_bottom_div_label">Fat</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalFat).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                            <span>
                                                <span className="dotprotein"></span>
                                                <span className="pie_chart_bottom_div_label">Protein</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.Protein).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ "clear": "both", "marginBottom": "20px" }} />
                                    <div className='menubox'>
                                        <div className='recipetabmenu'>
                                            <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                            <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                        </div>
                                    </div>
                                    <div style={{ "clear": "both", "marginBottom": "20px" }} />
                                    {recipetab === 'ingredients' ?
                                        <div className='ingredients'>
                                            <ul>
                                                {(recipeData.Ingredients).map((ing, index) => (
                                                    <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                                ))}
                                            </ul>
                                        </div> : null
                                    }
                                    {recipetab === 'steps' ?
                                        <div className='steps'>
                                            <ol>
                                                {(recipeData.Steps).map((ing, index) => (
                                                    <li key={index}>{ing.Text}</li>
                                                ))}
                                            </ol>
                                        </div> : null
                                    }
                                </div>
                                : null}

                        </Modal.Body>
                        <Modal.Footer style={{ "margin": "0 auto" }}>
                            <Button variant="primary" className='modal_action_button' data-item={planId} onClick={handleClose}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
{
    ImportedRecipePopShow===true ? <ImportedRecipePopup ImportedRecipeData={ImportedRecipeData}  SetImportedRecipePopShow={SetImportedRecipePopShow} ImportedRecipePopShow={ImportedRecipePopShow}   /> :null
}

                </div>
            </section>
        </div>
    )
}

export default Foodplans
import React, { useEffect, useState } from "react";

const BreastFeeding = (props) => {




  const [BreastFeeding,SetBreastFeeding]=useState('')



  const handleOptionChangeBreastFeeding=(e)=>{
    SetBreastFeeding(e.target.value)
  }



  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

    if(props.BreastFeedingPC)
    {
      SetBreastFeeding(props.BreastFeedingPC)
    }
  }, []);




  useEffect(()=>{


 
    props.SetBreastFeedingPC(BreastFeeding)
    

  

   if(BreastFeeding==='Yes')
   {
    props.toast.error('These medications cannot be taken in pregnancy or breastfeeding, please see your doctor for weight loss options that are safe for you and your baby', {
      toastId: 'error1',
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    
      });
    props.DisableNextButton()
   }
   else if(BreastFeeding==='')
   {
    props.DisableNextButton()
   }
   else if(BreastFeeding==='No')
   {
    props.EnableNextButton()
   }
   


  },[BreastFeeding])

  useEffect(()=>{
    props.SetPreviousMenu('currentmedication')
    props.SetNextMenu('glpexperience')
   
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <h5>FDA Recommendations for Medications During Pregnancy</h5>

<p>The FDA says that these medications may pose a risk to the developing fetus (baby) if you get pregnant while taking it:</p>

<ul>
  <li><strong>“The pill” (oral contraception):</strong> The pill is not enough because this medication can interfere with its effectiveness. For birth control, you must use condoms, an IUD, or the implant.</li>
</ul>

<p><strong>Official FDA recommendations:</strong></p>

<ol>
  <li>For the first month after first starting a weight loss medication, and for the first month after each dose escalation, continue using your oral contraception and add a barrier method of contraception (such as condoms) to prevent pregnancy.</li>
  <li>Alternatively, you can switch to a non-oral contraceptive method (such as an IUD or implant) before starting your medication.</li>
  <li>When you stop the medication, you must continue using a backup method (like condoms) for two months after stopping to allow the medication to fully leave your system before getting pregnant.</li>
</ol>

<p>Similarly, it is not known to be safe in breastfeeding. If you are breastfeeding, consult your doctor for safe weight loss options.</p>


     
     <div className="SelfMedication_wrap">
        <div className="input_row">
          <div>
            <label>
              {props.BreastFeedingPCLabel}
            </label>
            <span style={{color:'red'}}>*</span>
          </div>
          <div className="radio_input_yes_no">
            <input
              type="radio"
              value="Yes"
              checked={BreastFeeding === "Yes"}
              onChange={handleOptionChangeBreastFeeding}
            />
            <label>Yes</label>

            <input
              type="radio"
              value="No"
              checked={BreastFeeding === "No"}
              onChange={handleOptionChangeBreastFeeding}
            />
            <label>No</label>
          </div>
        </div>

     
      </div> 


 


    </div>
  );
};
export default BreastFeeding;

import React, { useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Upload from "./InputFile";
import axios from "axios";
import DocumentViewer from "./DocumentViewer";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { format, parseISO } from "date-fns";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

const DirectoryList = (props) => {
  const [ishow, Setishow] = useState(false);
  const token = localStorage.getItem("token");
  const Role = localStorage.getItem("role");
  const axiosJWT = axios.create();
  const [loader, SetloaderShow] = useState(false)
  const ShowLoader = () => { SetloaderShow(true) }
  const HideLoader = () => { SetloaderShow(false) }

  const [ShowErrorMsg, SetShowErrorMsg] = useState(false);
  const ihandleClose = () => {
    Setishow(false);
  };
  const [DirectoryId, SetDirectoryId] = useState("");
  const [PermissionType,SetPermissionType]=useState('')

  useEffect(() => {
    if (props.DirectoryId) {
      SetDirectoryId(props.DirectoryId);
    }
    GetMyDirectoryFiles(props.DirectoryId);
  }, [props]);

  const BackToDirectory = () => {
    props.SetFolderListShow(true);
  };

  const AddFilesTrigger = () => {
    Setishow(true);
  };

  const [DirectoryFiles, SetDirectoryFiles] = useState([]);

  const GetMyDirectoryFiles = async (id) => {
    const response = await axios.get(
      "document-directory/my-directory/my-files?id=" + id + "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.list != null) {
      SetDirectoryFiles(response.data.list);
    }
  };

  const [show, Setshow] = useState(false);
  const handleClose = () => {
    Setshow(false);

  };
  const [SaveCategoryButtonLabel, SetSaveCategoryButtonLabel] =
    useState("Update File Name");
  const [DirectoryName, SetDirectoryName] = useState("");
  const [DirectoryNameErr, SetDirectoryNameErr] = useState(false);
  const [PopButtonName, SetPopButtonName] = useState("update");

  const AddCategory = async () => {
    SetDirectoryNameErr(false);
    if (DirectoryName === "") {
      SetDirectoryNameErr(true);
    } else {
      const response = await axiosJWT.post(
        "/document-directory/my-directory/update-file",
        {
          name: DirectoryName,
          id: EditId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        GetMyDirectoryFiles(DirectoryId);
      }
    }
  };

  const [EditId, SetEditId] = useState("");
  const TriggerEditFileName = (e) => {
    const id = e.target.getAttribute("data-id");
    SetDirectoryName(e.target.getAttribute("data-name"));
    SetEditId(id);
    Setshow(true);
  };

  const [DeleteId, SetDeleteId] = useState("");
  const TriggerDeleteFile = (e) => {
    const id = e.target.getAttribute("data-id");
    SetDeleteId(id);
    Setdshow(true);
  };
  const [dshow, Setdshow] = useState(false);
  const dhandleClose = () => {
    Setdshow(false);
  };
  const DeleteDirectoryProceed = async () => {
    ShowLoader()
    const response = await axiosJWT.post(
      "/document-directory/my-directory/delete-file",
      {
        id: DeleteId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      dhandleClose();
      GetMyDirectoryFiles(DirectoryId);
      HideLoader()
    }
  };

  const [ShowDocumentViewer, SetShowDocumentViewer] = useState(false);
  const [DocumentPreviewPath, SetDocumentPreviewPath] = useState("");

  const PreviewFile = (e) => {
    const hasClass = e.target.classList.contains("icon_file");
    if (hasClass === false) {
      const path = e.target.closest(".file_lists_tr").getAttribute("data-path");

      SetDocumentPreviewPath(path);
      SetShowDocumentViewer(true);
    }
  };

  const [PermissionFileId, SetPermissionFileId] = useState("");
  const [pshow, Setpshow] = useState(false);
  const phandleClose = () => {
    Setpshow(false);
    SetDirFilesShow(false)
    
    SetSelectedDirFiles([])
  };
  const [expertoptions, setExpertoptions] = useState([]);
  const [clientoptions, setClientoptions] = useState([]);
  const [DirFilesOptions,SetDirFilesOptions]=useState([])
  const [ExpertOptionsShow, SetExpertOptionsShow] = useState(true);
  const [ClientOptionShow,SetClientOptionShow]=useState(true)
  const [DirFilesShow,SetDirFilesShow]=useState(false)

  const DropdownChange = (e) => {
    setSelectedoptions(e);
  };
  const DropdownChangeClient = (e) => {
    setSelectedoptionsClient(e);
  };
  const [DefaultSelectedTeamMembers, SetDefaultSelectedTeamMembers] = useState(
    []
  );
  const [DefaultSelectedClientMembers, SetDefaultSelectedClientMembers] =
    useState([]);
  const [selectedOptions, setSelectedoptions] = useState([]);
  const [selectedOptionsClient, setSelectedoptionsClient] = useState([]);
  const [ExpertErr, SetExpertErr] = useState(false);
  const [ClientErr, SetClientErr] = useState(false);
  const ProceedPermission = async (e) => {
    

    SetExpertErr(false);
    SetClientErr(false);
    var SelectedMembers = "";

    var SelectedExpertsArry = [];
    var SelectedClientsArry = [];
    var SelectedDirFilesArray=[];
    if (SelectedDirFiles.length > 0) {
      SelectedDirFiles.forEach((element) => {
        SelectedDirFilesArray.push(element.value);
      });
    }
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((element) => {
        SelectedExpertsArry.push(element.value);
      });
    }
    if (selectedOptionsClient.length > 0) {
      selectedOptionsClient.forEach((element) => {
        SelectedClientsArry.push(element.value);
      });
    }
    const FinalArray = SelectedClientsArry.concat(SelectedExpertsArry);
    let users = FinalArray.toString();

    

    
    const Target=e.target.getAttribute('data-type')
    if(Target==='file')
    {

      const response = await axiosJWT.post(
        "/document-directory/directory/file/permission",
        {
          file_id: PermissionFileId,
          user_id: users,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Setpshow(false);
      }

    }
    else if(Target==='dir')
    {
      let dirfiles=SelectedDirFilesArray.toString();

      const response = await axiosJWT.post(
        "/document-directory/assign-all-dirfiles",
        {
          file_ids: dirfiles,
          dir_id:DirectoryId,
          user_id: users,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Setpshow(false);
        SetDirFilesShow(false)
        SetSelectedDirFiles([])
      }

    
    }

 
      
   
     
    
  };

  const [SelectedDirFiles,SetSelectedDirFiles]=useState([])
  const DropdownChangeDirFiles=(e)=>{
    SetSelectedDirFiles(e)
  }

  const GivePermissionForAllFiles=async(e)=>{

    SetPermissionType('dir')
    SetDirFilesShow(true)
    SetDefaultSelectedTeamMembers([]);
    SetDefaultSelectedClientMembers([]);

    if (Role === "Admin" || Role==="Client") {
      SetExpertOptionsShow(true);
    } else {
      SetExpertOptionsShow(false);
    }
    if(Role === "Admin" || Role==="Nutrition")
    {
      SetClientOptionShow(true)
    }
    else{
      SetClientOptionShow(false)
    }
    const Id = e.target.getAttribute("data-id");

    var ExpertObj = [];
    var ClientObj = [];

    if(Role==='Client')
    {

      try {
        const AllNutrition = await axiosJWT.get("/mysubscribedcoaches", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllNutrition.status === 200) {
          if (AllNutrition.data.Users !== null) {
            AllNutrition.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ExpertObj.push(obj);
            });
          }
        }
        setExpertoptions(ExpertObj);
      } catch (err) {
        console.log(err);
      }

    }
    else{
      try {
        const AllNutrition = await axiosJWT.get("/allnutrition", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllNutrition.status === 200) {
          if (AllNutrition.data.Users !== null) {
            AllNutrition.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ExpertObj.push(obj);
            });
          }
        }
        setExpertoptions(ExpertObj);
      } catch (err) {
        console.log(err);
      }
    }

    try{

      const AllClients = await axiosJWT.get("/allclient", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (AllClients.status === 200) {
        if (AllClients.data.Users !== null) {
          AllClients.data.Users.forEach((data, index) => {
            var obj = {};
            obj["value"] = data.id;
            obj["label"] = data.email;
            ClientObj.push(obj);
          });
        }
      }
      setClientoptions(ClientObj);

    }catch(err)
    {
   console.log(err)
    }

    try{
      var FileObj=[]
      const AllDirFiles = await axiosJWT.get("document-directory/my-directory/my-files?id="+DirectoryId+"", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (AllDirFiles.status === 200) {
        if (AllDirFiles.data.list !== null) {
          AllDirFiles.data.list.forEach((data, index) => {
            var obj = {};
            obj["value"] = data.id;
            obj["label"] = data.file_name;
            FileObj.push(obj);
          });
        }
      }
      SetDirFilesOptions(FileObj);

      

    }catch(err)
    {
    console.log(err)
    }

    Setpshow(true);
    
  }

  const GivePermissionForFile = async (e) => {
    SetPermissionType('file')
    if (Role === "Admin" || Role==="Client") {
      SetExpertOptionsShow(true);
    } else {
      SetExpertOptionsShow(false);
    }
    if(Role === "Admin" || Role==="Nutrition")
    {
      SetClientOptionShow(true)
    }
    else{
      SetClientOptionShow(false)
    }
    const Id = e.target.getAttribute("data-id");

    var ExpertObj = [];
    var ClientObj = [];

    if(Role==='Client')
    {

      try {
        const AllNutrition = await axiosJWT.get("/mysubscribedcoaches", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllNutrition.status === 200) {
          if (AllNutrition.data.Users !== null) {
            AllNutrition.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ExpertObj.push(obj);
            });
          }
        }
        setExpertoptions(ExpertObj);
      } catch (err) {
        console.log(err);
      }

    }
    else{
      try {
        const AllNutrition = await axiosJWT.get("/allnutrition", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllNutrition.status === 200) {
          if (AllNutrition.data.Users !== null) {
            AllNutrition.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ExpertObj.push(obj);
            });
          }
        }
        setExpertoptions(ExpertObj);
      } catch (err) {
        console.log(err);
      }
    }

    try{

      const AllClients = await axiosJWT.get("/allclient", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (AllClients.status === 200) {
        if (AllClients.data.Users !== null) {
          AllClients.data.Users.forEach((data, index) => {
            var obj = {};
            obj["value"] = data.id;
            obj["label"] = data.email;
            ClientObj.push(obj);
          });
        }
      }
      setClientoptions(ClientObj);

    }catch(err)
    {
   console.log(err)
    }
 

    var DarrayObj = [];
    var DCarrayObj = [];
    const response = await axiosJWT.get(
      "/document-directory/directory/file/permission?id=" + Id + "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      if (response.data.users !== null) {
        if (response.data.users.length > 0) {
          response.data.users.forEach((data, index) => {
            var obj = {};
            obj["value"] = data.user.id;
            obj["label"] = data.user.email;
            if (data.user.role === "Nutrition") {
              DarrayObj.push(obj);
            } else if (data.user.role === "Client") {
              DCarrayObj.push(obj);
            }
          });
        }
        setSelectedoptions(DarrayObj);
        setSelectedoptionsClient(DCarrayObj);
        SetDefaultSelectedTeamMembers(DarrayObj);
        SetDefaultSelectedClientMembers(DCarrayObj);
      }
    }

    SetPermissionFileId(Id);
    Setpshow(true);
  };

  const DownloadFile=(e)=>{
    var path=e.target.getAttribute('data-path')
  
    window.open(path, '_blank');

  

  }

  return (
    <div className="directory_list_files">
                {loader === true && <div id="semiTransparenDivLoader"></div>}
      {ShowDocumentViewer === false && (
        <div>
          <div className="head_row">
            <button onClick={BackToDirectory}>Back</button>
            <button onClick={AddFilesTrigger}>Add files</button>
            <button onClick={GivePermissionForAllFiles}>Assign files</button>
          </div>

          <div>
            {DirectoryFiles.length > 0 && (
              <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                <Thead className="thead">
                  <Tr className="trow">
                    <Th scope="col">File Name</Th>
                    <Th scope="col">Last Updated</Th>
                    <Th scope="col">Actions</Th>
                  </Tr>
                </Thead>

                <Tbody className="tbody manager_appointment_list_admin directory_lists_table">
                  {DirectoryFiles.map((list, index) => (
                    <Tr
                      className="trow file_lists_tr"
                      data-path={list.file_path}
                      onClick={PreviewFile}
                    >
                      <Td>
                        <span className="file_path">{list.file_name}</span>
                      </Td>
                      <Td>{format(parseISO(list.updatedAt), `PP`)}</Td>
                      <Td>
                        {" "}
                        <span className="icons">
                          <i
                            className="fa fa-edit icon_file"
                            data-id={list.id}
                            data-name={list.file_name}
                            onClick={TriggerEditFileName}
                          ></i>
                          <i
                            className="fa fa-trash icon_file"
                            data-id={list.id}
                            onClick={TriggerDeleteFile}
                          ></i>
                          <i
                            className="fa fa-key icon_file"
                            data-id={list.id}
                            onClick={GivePermissionForFile}
                          ></i>
                           <i
                            className="fa fa-download icon_file"
                            data-path={list.file_path}
                            onClick={DownloadFile}
                          ></i>
                        </span>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}

            {/* {DirectoryFiles.length > 0 &&
              DirectoryFiles.map((list, index) => (
                <p className="file_lists" data-path={list.file_path} onClick={PreviewFile}>
                  <span>{list.file_name}</span>
                  <span className="icons">
                    <i
                      className="fa fa-edit icon_file"
                      data-id={list.id}
                      data-name={list.file_name}
                      onClick={TriggerEditFileName}
                    ></i>
                    <i
                      className="fa fa-trash icon_file"
                      data-id={list.id}
                      onClick={TriggerDeleteFile}
                    ></i>
                  </span>
                </p>
              ))} */}
          </div>
        </div>
      )}

      {ShowDocumentViewer === true && (
        <DocumentViewer
          DocumentPreviewPath={DocumentPreviewPath}
          SetShowDocumentViewer={SetShowDocumentViewer}
        />
      )}

      <Modal
        show={ishow}
        onHide={ihandleClose}
        className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload file</Modal.Title>
        </Modal.Header>
        <Modal.Body className="directory_list_files_popup">
          <Upload
             ShowLoader={ShowLoader}
             HideLoader={HideLoader}
             Setishow={Setishow}
             DirectoryId={DirectoryId}
             GetMyDirectoryFiles={GetMyDirectoryFiles}
          />
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={ihandleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        className="commission_popup add_coach_service_category_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>{SaveCategoryButtonLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row section_1_commission">
            <div className="col">
              <label className="commission_inner_popup_label required">
                Name
              </label>
              <br />
              <input
                type="text"
                className="add_category_inner_input"
                defaultValue={DirectoryName}
                onChange={(e) => {
                  SetDirectoryName(e.target.value);
                }}
                {...(DirectoryNameErr === true ? { id: "err_border" } : {})}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={handleClose}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="save_button_commission float_right"
            data-target={PopButtonName.toLowerCase()}
            onClick={AddCategory}
          >
            {PopButtonName}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={dshow}
        onHide={dhandleClose}
        className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete File</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this file?</Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={dhandleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save_button_commission float_right"
            onClick={DeleteDirectoryProceed}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={pshow}
        onHide={phandleClose}
        className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Allow permission</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allow_permission">
          {
            DirFilesShow===true && 
            (
              <div className="select_members">
                <label className="label">Select Files</label>
                {/* <Select
                  options={DirFilesOptions}
                  isMulti={true}
                  onChange={(e) => {
                    DropdownChangeDirFiles(e);
                  }}
                  /> */}

<MultiSelect
        options={DirFilesOptions}
        value={SelectedDirFiles}

        onChange={(e) => {
          DropdownChangeDirFiles(e);
        }}
        labelledBy="Select"
      />
              </div>
            )
          }
          {ExpertOptionsShow === true && (
            <div className="select_members">
              <label className="label">Select Coach</label>
              <Select
                options={expertoptions}
                isMulti
                onChange={(e) => {
                  DropdownChange(e);
                }}
                defaultValue={DefaultSelectedTeamMembers}
                {...(ExpertErr === true ? { id: "err_border" } : {})}
              />
            </div>
          )}

          {
            ClientOptionShow===true && 
            <div className="select_members">
            <label className="label">Select Clients</label>
            <Select
              options={clientoptions}
              isMulti
              onChange={(e) => {
                DropdownChangeClient(e);
              }}
              defaultValue={DefaultSelectedClientMembers}
              {...(ClientErr === true ? { id: "err_border" } : {})}
            />
          </div>
          }

         
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={phandleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save_button_commission float_right"
            data-type={PermissionType}
            onClick={ProceedPermission}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DirectoryList;

import React, { useEffect, useState,useRef } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { format, parseISO } from "date-fns";
import { TagsInput } from "react-tag-input-component";
import Multiselect from 'multiselect-react-dropdown';

const ClientPlan = () => {
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();

  const [show, Setshow] = useState(false);
  const handleClose = () => {
    Setshow(false);
  };
  const [PopupTarget,SetPopupTarget]=useState('');

  const TriggerAddPlan = () => {
    SetPlanName("");
    SetPlanSlug('')
    SetPlanPrice("");
    SetPlanType("");
    SetPlanStatus("");
    SetPlanDuration("");
    SetPlanContent([]);
    SetPlanImage(null);
    SetPlanImagePreview('')
    SetPopupTarget('add')
    SetSelectedIncludedGLPMedications([])
    SetGLPAllowedRefills(0)
    SetGLPAllowedDose('')
    SetFunnelKitCheckoutUrl('')
    Setshow(true);
  };

  const [PlanName, SetPlanName] = useState("");
  const [PlanSlug,SetPlanSlug]=useState('');
  const [PlanPrice, SetPlanPrice] = useState("");
  const [PlanType, SetPlanType] = useState("");
  const [PlanStatus, SetPlanStatus] = useState("");
  const [PlanDuration, SetPlanDuration] = useState("");
  const [PlanContent, SetPlanContent] = useState([]);
  const [PlanAppointment,SetPlanAppointment]=useState('');

  const [PlanNameErr, SetPlanNameErr] = useState(false);
  const [PlanPriceErr, SetPlanPriceErr] = useState(false);
  const [PlanTypeErr, SetPlanTypeErr] = useState(false);
  const [PlanStatusErr, SetPlanStatusErr] = useState(false);
  const [PlanDurationErr, SetPlanDurationErr] = useState(false);
  const [PlanContentErr, SetPlanContentErr] = useState(false);

  const [ClientPlanList, SetClientPlanList] = useState([]);

  const GetClientPlanList = async () => {
    const response = await axiosJWT.get("/client-plan/list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      if (response.data.List !== null) {
        SetClientPlanList(response.data.List);
      }
    }
  };

  const AddPlan = async (e) => {
    var Err = false;
    SetPlanNameErr(false);
    SetPlanPriceErr(false);
    SetPlanTypeErr(false);
    SetPlanStatusErr(false);
    SetPlanDurationErr(false);
    SetPlanContentErr(false);

    if (PlanName === "") {
      Err = true;
      SetPlanNameErr(true);
    }
    if (PlanPrice === "") {
      Err = true;
      SetPlanPriceErr(true);
    }
    if (PlanType === "") {
      Err = true;
      SetPlanTypeErr(true);
    }
    if (PlanStatus === "") {
      Err = true;
      SetPlanStatusErr(true);
    }
    if (PlanType !== "unlimited" && PlanDuration === "") {
      Err = true;
      SetPlanDurationErr(true);
    }
    if (PlanContent.length === 0) {
      Err = true;
      SetPlanContentErr(true);
    }

    if (Err === false) {

      const Target=e.target.getAttribute('data-target');
      var GLPMedinceArray=[];
      GLPMedinceArray= SelectedIncludedGLPMedications.map(item=>item.id)

      if(Target === 'add')
      {

        var formData = new FormData();

        formData.append('plan_name',PlanName)
        formData.append('plan_image',PlanImage)
        formData.append('plan_type',PlanType);
        formData.append('plan_duration',PlanDuration);
        formData.append('plan_price',PlanPrice);
        formData.append('plan_status',PlanStatus);
        formData.append('plan_content',PlanContent.toString());
        formData.append('plan_appointment',PlanAppointment);
        formData.append('plan_glp_medicine',GLPMedinceArray.toString());
        formData.append('plan_funnel_url',FunnelKitCheckoutUrl)
        formData.append('plan_glp_refills',GLPAllowedRefills)
        formData.append('plan_glp_dose',GLPAllowedDose)
        formData.append('plan_slug',PlanSlug)

  

      
        const response = await axiosJWT.post(
            "/client-plan/add",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 200) {
            GetClientPlanList();
            Setshow(false);
          }
      }
      else{


        var formData = new FormData();
        formData.append('plan_name',PlanName)
        formData.append('plan_type',PlanType)
        formData.append('plan_duration',PlanDuration)
        formData.append('plan_price',PlanPrice)
        formData.append('plan_status',PlanStatus)
        formData.append('plan_content',PlanContent.toString())
        formData.append('plan_appointment',PlanAppointment)
        formData.append('id',EditId)
        formData.append('plan_glp_medicine',GLPMedinceArray.toString())
        formData.append('plan_image',PlanImage)
        formData.append('plan_funnel_url',FunnelKitCheckoutUrl)
        formData.append('plan_glp_refills',GLPAllowedRefills)
        formData.append('plan_glp_dose',GLPAllowedDose)
        formData.append('plan_slug',PlanSlug)


        if(PlanImage===null && PlanImagePreview==='')
          {
            formData.append('plan_image_remove','remove')
          }

        const response = await axiosJWT.post(
            "/client-plan/update",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 200) {
            GetClientPlanList();
            Setshow(false);
          }
      }   
    }
  };

  const [DeleteId, SetDeleteId] = useState("");
  const [DeleteErrMsg, SetDeleteErrMsg] = useState("");
  const TriggerDeletePopup = (e) => {
    const id = e.target.getAttribute("data-id");
    SetDeleteId(id);
    Setdshow(true);
  };

  const [dshow, Setdshow] = useState(false);
  const dhandleClose = () => {
    SetDeleteErrMsg("");
    Setdshow(false);
  };
  const DeletePlan = async () => {
    SetDeleteErrMsg("");

    try {
      const response = await axiosJWT.post(
        "/client-plan/delete",
        {
          id: DeleteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        Setdshow(false);
        GetClientPlanList();
      }
    } catch (err) {
      SetDeleteErrMsg(err.response.data.msg);
    }
  };

  const [EditId, SetEditId] = useState("");
  const TriggerEditPlan =async (e) => {
    const id = e.target.getAttribute("data-id");
    SetEditId(id);
    SetPlanImagePreview('')
    SetPlanImage(null)
    
 
    const response = await axiosJWT.get("/client-plan/detail?id="+id+"", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status===200)
      {
        TriggerAddPlan();
        SetPlanName(response.data.Detail.plan_name);
        SetPlanPrice(response.data.Detail.plan_price);
        SetPlanType(response.data.Detail.plan_type);

        if(response.data.Detail.plan_image!==undefined && response.data.Detail.plan_image!==null && response.data.Detail.plan_image!=='' )
          {
            SetPlanImagePreview(response.data.Detail.plan_image)
          }
          if(response.data.Detail.plan_funnel_url!==undefined && response.data.Detail.plan_funnel_url!==null && response.data.Detail.plan_funnel_url!=='' )
            {
              SetFunnelKitCheckoutUrl(response.data.Detail.plan_funnel_url)
            }

            if(response.data.Detail.plan_glp_refills!==undefined && response.data.Detail.plan_glp_refills!==null && response.data.Detail.plan_glp_refills!=='' )
              {
                SetGLPAllowedRefills(response.data.Detail.plan_glp_refills)
              }

              if(response.data.Detail.plan_glp_dose!==undefined && response.data.Detail.plan_glp_dose!==null && response.data.Detail.plan_glp_dose!=='' )
                {
                  SetGLPAllowedDose(response.data.Detail.plan_glp_dose)
                }
                if(response.data.Detail.plan_slug!==undefined && response.data.Detail.plan_slug!==null && response.data.Detail.plan_slug!=='' )
                  {
                    SetPlanSlug(response.data.Detail.plan_slug)
                  }
     
       
      
        
        
        if(response.data.Detail.plan_status===true)
        {
            SetPlanStatus(1);
        }
        else{
            SetPlanStatus(0);
        }
       
        SetPlanDuration(response.data.Detail.plan_duration);
        SetPlanContent(response.data.Detail.plan_content.split(","));
        SetPlanAppointment(response.data.Detail.appointment_duration);
        if(response.data.Detail.plan_glp_medicine!==null  && response.data.Detail.plan_glp_medicine!=='')
          {
           
            var MedArray=response.data.Detail.plan_glp_medicine.split(',')
        
            var SetMedArray=[]
            MedArray.forEach((element, index) => {
              var Obj = {};
              Obj.name = element;
              Obj.id = element;
              SetMedArray.push(Obj);
          });

         
            SetSelectedIncludedGLPMedications(SetMedArray)
          }
        SetPopupTarget('edit');
      }
  };

  useEffect(() => {
    GetClientPlanList();
  }, []);

  const [IncludedGLPMedications,SetIncludedGLPMedications]=useState([

    {
      name:'Semaglutide',
      id:'Semaglutide'
    },
    {
      name:'Tirzepatide',
      id:'Tirzepatide'
    },
    {
      name:'Ozempic',
      id:'Ozempic'
    },
    {
      name:'Wegovy',
      id:'Wegovy'
    },
    {
      name:'Mounjaro',
      id:'Mounjaro'
    },
    {
      name:'Zepbound',
      id:'Zepbound'
    }
  ])


  const [SelectedIncludedGLPMedications,SetSelectedIncludedGLPMedications]=useState([])
  const [PlanImage,SetPlanImage]=useState(null)
  const PlanImageRef = useRef(null);
  const [PlanImagePreview,SetPlanImagePreview]=useState('')
  const [FunnelKitCheckoutUrl,SetFunnelKitCheckoutUrl]=useState('')
  const [GLPAllowedRefills,SetGLPAllowedRefills]=useState(0)
  const [GLPAllowedDose,SetGLPAllowedDose]=useState('')
  const DropdownChangeGLPMedication=(e)=>{
    SetSelectedIncludedGLPMedications(e)
  }
  const DropdownRemoveGLPMedication=(e)=>{
    SetSelectedIncludedGLPMedications(e)
   }

   const handleFileChangePlanImage = (event) => {
    const file = event.target.files[0];
  
    SetPlanImage(file)
    if (file) {
   
      const reader = new FileReader();
      reader.onloadend = () => {
        SetPlanImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ClearImagePlan=()=>{
    SetPlanImagePreview('')
    SetPlanImage(null)
    PlanImageRef.current.value=null
  }

  
  return (
    <div className="container">
      <div className="row manage_plan_row">
        <div className="col">
          <button
            className="last_commision_tab_button_add_plan"
            onClick={TriggerAddPlan}
          >
            Add Plan
          </button>
        </div>
        <Table className="table is-striped is-fullwidth">
          <Thead className="thead">
            <Tr>
              <Th className="row_name">Package Name</Th>
              <Th className="row_name">Type</Th>
              <Th className="row_name">Duration(Month)</Th>
              <Th className="row_name">Price</Th>
              <Th className="row_name">Status</Th>
              <Th className="row_name">Last Edit</Th>
              <Th className="row_name">Actions</Th>
            </Tr>
          </Thead>
          <Tbody className="tbody">
            {ClientPlanList.length > 0 &&
              ClientPlanList.map((plans, index) => (
                <Tr key={plans.id}>
                  <Td>{plans.plan_name}</Td>
                  <Td>{plans.plan_type}</Td>
                  <Td>
                    {plans.plan_type === "unlimited"
                      ? "-"
                      : plans.plan_duration}
                  </Td>
                  <Td>$ {plans.plan_price}</Td>
                  <Td>{plans.plan_status === true ? "Active" : "In active"}</Td>
                  <Td>{format(parseISO(plans.updatedAt), `PP`)}</Td>
                  <Td className="last_class">
                    <i
                      className="fa fa-edit edit_icon pointer_eff"
                      data-id={plans.id}
                      onClick={TriggerEditPlan}
                    ></i>
                    <i
                      className="fa fa-trash pointer_eff"
                      data-id={plans.id}
                      onClick={TriggerDeletePopup}
                    ></i>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </div>

      <Modal show={show} onHide={handleClose} className="commission_popup">
        <Modal.Header closeButton>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="client_add_plan_popup">
            <div className="input_row">
              <div className="label_div required">Plan Name:</div>
              <div className="input_div">
                <input
                  type={"text"}
                  defaultValue={PlanName}
                  onChange={(e) => {
                    SetPlanName(e.target.value);
                  }}
                  {...(PlanNameErr === true ? { id: "err_border" } : {})}
                />
              </div>
            </div>
            <div className="input_row">
              <div className="label_div required">Plan Type:</div>
              <div className="input_div">
                <select
                  className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
                  style={{ width: "100%" }}
                  defaultValue={PlanType}
                  onChange={(e) => {
                    SetPlanType(e.target.value);
                  }}
                  {...(PlanTypeErr === true ? { id: "err_border" } : {})}
                >
                  <option value="">Select plan type</option>
                  <option value="limited">Limited Plan</option>
                  <option value="unlimited">Unlimited Plan</option>
                </select>
              </div>
            </div>
            <div className="input_row">
              <div className="label_div required">Plan Price:</div>
              <div className="input_div">
                <input
                  type={"number"}
                  defaultValue={PlanPrice}
                  onChange={(e) => {
                    SetPlanPrice(e.target.value);
                  }}
                  {...(PlanPriceErr === true ? { id: "err_border" } : {})}
                />
              </div>
            </div>
            <div className="input_row">
              <div className="label_div">Included GLP-1 Medications:</div>
              <div className="input_div">
              <Multiselect
         options={IncludedGLPMedications}
         selectedValues={SelectedIncludedGLPMedications}
         onSelect={DropdownChangeGLPMedication} // Function will trigger on select event
onRemove={DropdownRemoveGLPMedication} // Function will trigger on remove event
displayValue="name"

       
   
        />

              </div>
              </div>
            <div className="input_row">
              <div className="label_div required">Plan status:</div>
              <div className="input_div">
                <select
                  className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
                  style={{ width: "100%" }}
                  defaultValue={PlanStatus}
                  onChange={(e) => {
                    SetPlanStatus(e.target.value);
                  }}
                  {...(PlanStatusErr === true ? { id: "err_border" } : {})}
                >
                  <option value="">Select plan status</option>
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                </select>
              </div>
            </div>

            {PlanType !== "unlimited" && (
              <div className="input_row">
                <div className="label_div required">Plan Duration:</div>
                <div className="input_div">
                  <input
                    type={"number"}
                    placeholder={"In months"}
                    defaultValue={PlanDuration}
                    onChange={(e) => {
                      SetPlanDuration(e.target.value);
                    }}
                    {...(PlanDurationErr === true ? { id: "err_border" } : {})}
                  />
                </div>
              </div>
            )}



            <div className="input_row">
              <div className="label_div required">Plan Content:</div>
              <div className="input_div">
                <TagsInput
                  value={PlanContent}
                  onChange={SetPlanContent}
                  name="content"
                  placeHolder="press enter or comma to add new tag"              
                />
              </div>
              
            </div>
            <div style={{marginLeft:'23%',marginBottom:'3px'}}>
            {
                PlanContentErr===true && <span className="err">Please enter value and hit enter to add as tag</span>
              }
            </div>

            <div className="input_row">

            <div className="label_div">Plan Image:</div>
            <div className="">
              <input type="file" onChange={handleFileChangePlanImage} ref={PlanImageRef}/>
            </div>
           
            </div>
            {
              PlanImagePreview?  <div className='plan_image_preview'><img src={PlanImagePreview}/><i className="fa fa-close" onClick={()=>{ClearImagePlan()}}></i></div> : null
            }
           
            <div className="input_row">
                <div className="label_div required">Appointment hours:</div>
                <div className="input_div">
                  <input
                    type={"number"}
                    placeholder={"In hours"}
                    defaultValue={PlanAppointment}
                    onChange={(e) => {
                      SetPlanAppointment(e.target.value);
                    }}                  
                  />
                </div>
              </div>

              <div className="input_row">
                <div className="label_div">Funnelkit Checkout URL:</div>
                <div className="input_div">
                  <input
                    type={"text"}
                    placeholder={"Fill this url if you want to enable funnelkit"}
                    defaultValue={FunnelKitCheckoutUrl}
                    onChange={(e) => {
                      SetFunnelKitCheckoutUrl(e.target.value);
                    }}                  
                  />
                </div>
              </div>

              <div className="input_row">
                <div className="label_div">GLP-1 Allowed Refills:</div>
                <div className="input_div">
                  <input
                    type={"number"}
                    placeholder={"No of refills allowed"}
                    defaultValue={GLPAllowedRefills}
                    onChange={(e) => {
                      SetGLPAllowedRefills(e.target.value);
                    }}                  
                  />
                </div>
              </div>

              <div className="input_row">
                <div className="label_div">GLP-1 starter dose:</div>
                <div className="input_div">
                  <input
                    type={"text"}
                    placeholder={""}
                    defaultValue={GLPAllowedDose}
                    onChange={(e) => {
                      SetGLPAllowedDose(e.target.value);
                    }}                  
                  />
                  <p>Fill in the medId dictionary if you want to start with this dose for the plan. This will be sent to Beluga Health as the preferred dose (if not provided by user input/if not a funnel flow).</p>
                </div>
              </div>

              <div className="input_row">
                <div className="label_div">Plan slug:</div>
                <div className="input_div">
                  <input
                    type={"text"}
                    placeholder={""}
                    defaultValue={PlanSlug}
                    onChange={(e) => {
                      SetPlanSlug(e.target.value);
                    }}                  
                  />
                  <p>Fill in the product slug from wordpress product.</p>
                </div>
              </div>
           
          </div>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={handleClose}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="save_button_commission float_right"
            onClick={AddPlan}
            data-target={PopupTarget}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={dshow}
        onHide={dhandleClose}
        className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure want to delete this Plan?</div>
          <div style={{ marginTop: "20px", color: "red" }}>{DeleteErrMsg}</div>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="close_button_commission"
            onClick={dhandleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="save_button_commission float_right"
            onClick={DeletePlan}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ClientPlan;

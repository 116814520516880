import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const StepsActivity = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess,SetsaveAddSuccess]=useState(false)
    const [StepsCount, SetStepsCount] = useState('')
    const [ActivityStepErr,SetActivityStepErr]=useState(false)

    const SaveActivity = async (e) => {

        const Action = e.target.getAttribute('data-action')
        if(Action==='cancel')
        {
            props.SetShowStepsActivityField(false)
            props.SetactivityOption(true)
            return false
        }

        SetActivityStepErr(false)
        SetDateErr(false)
        SetTimeErr(false)
        var err = false;

        if (StepsCount === '') {
            err = true
            SetActivityStepErr(true)
        }
     
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if(TImePicker===null)
        {
            err = true
            SetTimeErr(true) 
        }
        if (err === false) {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }


            const response = await axiosJWT.post('add-data/add-activity',
                {

                    'date': passDate,
                    'time':TImePicker,
                    'type': 'Steps',
                    'steps_count': StepsCount,
                    'notes': Notes,
                    'client_id':IsClientid
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                if(Action==='save')
                    {
                        props.SetShowStepsActivityField(false)
                        props.SetactivityOption(true)
                  
                    }
                    else if(Action==='save_add')
                    {
                        SetsaveAddSuccess(true)
                        setTimeout(() => {
                            SetsaveAddSuccess(false)   
                        }, 1000);
                    }
             
            }


        }

    }
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
      <h6 className="heading_popup">{'Activity > Steps'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Steps</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetStepsCount(e.target.value) }} {...(ActivityStepErr === true ? { id: 'err_border' } : {})} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveActivity(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveActivity(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveActivity(e) }} >
                    {'Save'}
                </Button>

           </div>
            {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )
}
export default StepsActivity
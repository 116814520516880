import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select, { NonceProvider } from 'react-select'

const Referrals = () => {

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    var [show, setShow] = useState(false);
    var [referralType, setReferralType] = useState('');
    var [referralTypesList, setReferralTypesList] = useState('');
    var [referralDiscount, setReferralDiscount] = useState(0);
    var [referralMaxDiscount, setReferralMaxDiscount] = useState(0);
    var [referralMinOrderAmount, setReferralMinOrderAmount] = useState(0);
    var [refereeDiscount, setRefereeDiscount] = useState(0);
    var [refereeMaxDiscount, setRefereeMaxDiscount] = useState(0);
    var [referralDescription, setReferralDescription] = useState('');
    var [refereeDescription, setRefereeDescription] = useState('');
    var [referralId, setReferralId] = useState('');
    var [referralShow, setReferralShow] = useState({ display: "none" });
    var [updateShow, setUpdateShow] = useState({ display: "none" });

    const [ReferralInputErr, SetReferralInputErr] = useState(false)
    const [ReferralInputErrMsg, SetReferralInputErrMsg] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); SetReferralInputErr(false); }

    useEffect(() => {
        getReferralTypes()
    }, [])

    const createReferral = async () => {

        var err = false;

        if (referralType === '') {
            SetReferralInputErrMsg('Please enter referral type')
            SetReferralInputErr(true)
            err = true
        }
        if (err === true) {
            return false
        }

        if (referralType !== '') {
            setReferralShow({ display: "none" });

            const response = await axiosJWT.post('/marketing/referral',
                {
                    type: referralType
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            handleClose();
            getReferralTypes();
        }
    }

    const getReferralTypes = async () => {
        const response = await axiosJWT.get('/marketing/referral',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        var referral_types_list = response.data;

        var referral_type_options = [];

        /*referral_type_options.push({ value: 'Select', label: 'Select'});*/
        referral_types_list.forEach(element => {
            referral_type_options.push({ value: element.id, label: element.type, id: element.id });
        });

        setReferralTypesList(referral_type_options)

    }

    const getReferrals = async (referraltype) => {


        setUpdateShow({ display: "none" });

        setReferralId(0);
        setReferralDiscount(0);
        setReferralMaxDiscount(0);
        setReferralMinOrderAmount(0);
        setRefereeDiscount(0);
        setRefereeMaxDiscount(0);
        setReferralDescription('');
        setRefereeDescription('');

        if (referraltype.value !== 'Select') {

            setReferralShow({ display: "block" });

            const response = await axiosJWT.get('/marketing/referral/' + referraltype.value,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            setReferralId(response.data.id);
            setReferralDiscount(response.data.referral_discount);
            setReferralMaxDiscount(response.data.referral_max_discount_amount);
            setReferralMinOrderAmount(response.data.min_order_amount);
            setRefereeDiscount(response.data.referee_discount);
            setRefereeMaxDiscount(response.data.referee_max_discount_amount);
            setReferralDescription(response.data.referral_description);
            setRefereeDescription(response.data.referee_description);
        }
        else {
            setReferralShow({ display: "none" });
        }
    }

    const updateReferral = async (e) => {

        var referral_id = e.target.getAttribute("data-item");

        const response = await axiosJWT.put('/marketing/referral/' + referral_id,
            {
                referral_discount: referralDiscount,
                referral_max_discount_amount: referralMaxDiscount,
                min_order_amount: referralMinOrderAmount,
                referee_discount: refereeDiscount,
                referee_max_discount_amount: refereeMaxDiscount,
                referral_description: referralDescription,
                referee_description: refereeDescription
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response)
            setUpdateShow({ display: "block", color: "green" })
    }


    const onDeleteItem = async (e) => {
        var referral_id = e.target.getAttribute("data-item")
        /*console.log(referral_id)*/

        const response = await axiosJWT.delete('/marketing/referral/' + referral_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        setReferralShow({ display: "none" });
        getReferralTypes();
    }

    const formatOptionLabel = ({ value, label, id }) => (
        <div style={{ display: "flex" }}>
            <div>{label}</div>
            <div style={{ marginLeft: "30px", color: "#ccc", cursor: "pointer", border: "1px solid #51b848", borderRadius: "15px", paddingLeft: "5px", paddingRight: "5px", color: "#51b848" }} data-item={id} onClick={onDeleteItem}>
                X
            </div>
        </div>
    );


    return (
        <div>
            <div className='intro whitebg'>
                <div className='p_title grayfont'>Configure Referrals</div>
                <div className='description grayfont'><p>This is test description text to referrals details.</p></div>
            </div>
            <div className='addreferral'><input className='greenbutton' type="button" value="Add Referral Type" onClick={handleShow} data-modal="referral_add" /></div>

            <br></br>
            <Select
                options={referralTypesList}
                onChange={getReferrals}
                formatOptionLabel={formatOptionLabel}
                defaultValue="Select"
                value={null}
            />
            <br></br>

            <div style={updateShow}>Updated successfully!</div>

            <div style={referralShow} id="referral_data" className='referral_data modal-body'>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label'>Referral Discount (%):</label><br />
                        <input type='text' className='inner_popup_input' value={referralDiscount} onChange={(e) => { setReferralDiscount(e.target.value) }}></input>
                    </div>
                    <div className="col">
                        <label className='inner_popup_label'>Referral Max. Discount Amount:</label><br />
                        <input type='text' className='inner_popup_input' value={referralMaxDiscount} onChange={(e) => { setReferralMaxDiscount(e.target.value) }}></input>
                    </div>
                    <div className="col">
                        <label className='inner_popup_label'>Minimum Order Amount:</label><br />
                        <input type='text' className='inner_popup_input' value={referralMinOrderAmount} onChange={(e) => { setReferralMinOrderAmount(e.target.value) }}></input>
                    </div>
                    <div className="col">
                        <label className='inner_popup_label'>Referee Discount (%):</label><br />
                        <input type='text' className='inner_popup_input' value={refereeDiscount} onChange={(e) => { setRefereeDiscount(e.target.value) }}></input>
                    </div>
                    <div className="col">
                        <label className='inner_popup_label'>Referee Max. Discount Amount:</label><br />
                        <input type='text' className='inner_popup_input' value={refereeMaxDiscount} onChange={(e) => { setRefereeMaxDiscount(e.target.value) }}></input>
                    </div>
                </div><br></br>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label'>Referral Description:</label><br />
                        <textarea className='inner_popup_input' value={referralDescription} onChange={(e) => { setReferralDescription(e.target.value) }}></textarea>
                    </div>
                    <div className="col">
                        <label className='inner_popup_label'>Referee Description:</label><br />
                        <textarea className='inner_popup_input' value={refereeDescription} onChange={(e) => { setRefereeDescription(e.target.value) }}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <br></br>
                        <Button variant="primary" className='modal_action_button' data-item={referralId} onClick={updateReferral}>
                            Save Info
                        </Button>
                    </div>
                </div>
            </div>

            <div style={{ clear: 'both' }}></div>

            <Modal show={show} onHide={handleClose} className='adminmarketing_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Referral Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <input type='text' className='inner_popup_input' onChange={(e) => { setReferralType(e.target.value) }} {...(ReferralInputErr===true ? {id: 'err_border'} : {})}></input>
                        </div>
                    </div>
                    {ReferralInputErr === true &&
                        <div className="row err">
                            <div className="col">
                                {ReferralInputErrMsg}
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' onClick={(e) => { createReferral(e) }}>
                        Add Referral
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Referrals
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import axios from 'axios';



const AdminNotification = () => {
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create()
    const [iOSPemFileLabel, setiOSPemFile] = useState('Select pem file for iOS app')
    const [androidPemFileLabel, setandroidPemFile] = useState('Select pem file for android app')
    const [iosfcmkey, setiosfcmkey] = useState('')
    const [androidfcmkey, setandroidfcmkey] = useState('')
    const [FinalData, setFileData] = useState();
    const [FinalDataA, setFileDataA] = useState();
    const [labelfile1, setlabelfile1] = useState(' Select a file');
    const [labelfile2, setlabelfile2] = useState(' Select a file');

    const [NotificationMsgShow, SetNotificationMsgShow] = useState(false)


    const inputFileRef = React.useRef();
    const inputFileRefA = React.useRef();





    const GetNotitificationConfigDetails = async () => {


        const iosservekeyresponse = await axiosJWT.get('/admin/config/ios_ser_key', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((iosservekeyresponse) => {

            if (iosservekeyresponse.data !== null) {
                setiosfcmkey(iosservekeyresponse.data.value)
            }
        })
        const androidservekeyresponse = await axiosJWT.get('/admin/config/android_ser_key', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((androidservekeyresponse) => {
       
            if (androidservekeyresponse.data !== null) {

                setandroidfcmkey(androidservekeyresponse.data.value)
            }
        })


        const iosdockeyresponse = await axiosJWT.get('/admin/config/ios_fem_doc', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((iosdockeyresponse) => {

            if (iosdockeyresponse.data !== null && iosdockeyresponse.data !== '') {

                const JsonObj=JSON.parse(iosdockeyresponse.data.value)
                var res = JsonObj.path.substring(0, 20);
                setlabelfile1(res + '..')
                setiOSPemFile(iosdockeyresponse.data.value)
            }
        })

        const androiddockeyresponse = await axiosJWT.get('/admin/config/android_fem_doc', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((androiddockeyresponse) => {

            if (androiddockeyresponse.data !== null && androiddockeyresponse.data !== '') {

                const JsonObj=JSON.parse(androiddockeyresponse.data.value)
                var res = JsonObj.path.substring(0, 20);
                setlabelfile2(res + '..')
                setandroidPemFile(androiddockeyresponse.data.value)
            }
        })





    }

    const SaveSettings = async () => {

    
        SetNotificationMsgShow(false)
        const formData = new FormData();

        formData.append('ios_ser_key', iosfcmkey);
        formData.append('android_ser_key', androidfcmkey);
        formData.append('ios_fem_doc', FinalData);
        formData.append('android_fem_doc', FinalDataA);


        ShowLoader()
        const response = await axiosJWT.post('/admin/config/notification',
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {

            SetNotificationMsgShow(true)
            setTimeout(function () {
                SetNotificationMsgShow(false)
            }, 1000);
            GetNotitificationConfigDetails()
            HideLoader()
        }

    }
    useEffect(() => {
        document.title = 'Admin-Notification';
        GetNotitificationConfigDetails()
    }, [])


    const saveFile = async (e) => {


        var res = e.target.files[0].name.substring(0, 20);
        setlabelfile1(res + '..')
        setFileData(e.target.files[0])



    };

    const saveFileA = async (e) => {


        var res = e.target.files[0].name.substring(0, 20);
        setlabelfile2(res + '..')
        setFileDataA(e.target.files[0])


    };

    const triggerInput = () => {
        inputFileRef.current.click();
    }
    const triggerInputA = () => {
        inputFileRefA.current.click();
    }



    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }


    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
                         {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className='section_ele'>
                <div className='adminnotification container'>
                <div className='row'>
                    <div className='col'>
                        <h6 className='notif_heading'>Notifications</h6>
                    </div>
                    <div className='col'> <button className='save_settings_notifi' onClick={SaveSettings}  >Save settings</button></div>
                </div>

                {NotificationMsgShow === true &&
                    <div className='row notification_settings_success'>

                        Notification settings has been saved
                    </div>
                }
                <div className='row row_1_not'>

                    <span className='notification_heading_label'>IOS Notification Pem File</span>
                    <input className="form-control file_input" type="file" onChange={saveFile} style={{ display: 'none' }} ref={inputFileRef} />
                    <div className="col dummy_class" onClick={triggerInput}>
                        <button> Select a file</button><p className='file_select'>{labelfile1}</p>
                    </div>
                    <div className="col">

                        <span className='notification_info'><span>&#9432;</span> upload pem file for iOS notification App </span>
                    </div>
                </div>
                <div className='row row_1_not'>
                    <span className='notification_heading_label'>Andriod Notification Pem File</span>
                    <input className="form-control file_input" type="file" onChange={saveFileA} style={{ display: 'none' }} ref={inputFileRefA} />
                    <div className="col dummy_class" onClick={triggerInputA}>
                        <button> Select a file</button><p className='file_select' >{labelfile2}</p>
                    </div>
                    <div className="col">

                        <span className='notification_info'><span>&#9432;</span> upload pem file for Android notification App </span>
                    </div>
                </div>
                <div className='row row_1_not'>
                    <span className='notification_heading_label'>Server Key For iOS app</span>
                    <div className="col">
                        <input type="text" name="server_key_ios" defaultValue={iosfcmkey} className='server_key_ios' onChange={(e) => { setiosfcmkey(e.target.value) }} />
                    </div>
                    <div className="col">

                        <span className='notification_info'><span>&#9432;</span> enter server key to send Notification to an iOs app </span>
                    </div>
                </div>
                <div className='row row_1_not'>
                    <span className='notification_heading_label'>Server Key For android app</span>
                    <div className="col">
                        <input type="text" name="server_key_android" defaultValue={androidfcmkey} className='server_key_android ' onChange={(e) => { setandroidfcmkey(e.target.value) }} />
                    </div>
                    <div className="col">

                        <span className='notification_info'><span>&#9432;</span> enter server key to send Notification to an andriod app </span>
                    </div>
                </div>
                </div>
            </section>

        </div>

    )
}

export default AdminNotification
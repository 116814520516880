import React, { useState, useEffect } from "react";
import Select from "react-select";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const AdminLabTest = () => {
  var [optionEditPopup, setoptionEditPopup] = useState({
    open: false,
    id:null,
    catid: null,
    subcatid:null,
    value: null,
    units: null,
    low: null,
    high: null,
    moderate: null
  });
 
  var [categoryEditPopup, setcategoryEditPopup] = useState({
    open: false,
    id: null,
    name: null,
  });

  var [subcategoryEditPopup, setsubcategoryEditPopup] = useState({
    open: false,
    id: null,
    name: null,
  });

  const [cat_editLibrary, setcat_editLibrary] = useState("");
  const [subcat_editLibrary, setsubcat_editLibrary] = useState("");
  const [option_editLibrary, setoption_editLibrary] = useState("");
  const [unit_editLibrary, setunit_editLibrary] = useState("");
  const [low_editLibrary, setlow_editLibrary] = useState("");
  const [high_editLibrary, sethigh_editLibrary] = useState("");
  const [moderate_editLibrary, setmoderate_editLibrary] = useState("");
  const [formErr, setformErr] = useState(false);
  const [formErrMsg, setformErrMsg] = useState("");
  const [successmsg, setsuccessMsg] = useState(false);
  const side_nav = localStorage.getItem("side_nav");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [editVisible, seteditVisible] = useState(false);
  const [editcategoryVisible, seteditcategoryVisible] = useState(false);
  const [editsubcategoryVisible, seteditsubcategoryVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [addPopuptitle, SetaddPopuptitle] = useState("");
  const [unitvalue, setUnitvalue] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [moderate, setModerate] = useState("");
  const [option, setOption] = useState("");
  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedCategoryforList, setselectedCategoryforList] = useState(0);
  const [selectedCategoryLabel, setselectedCategoryLabel] = useState('');
  const [selectedsubCategory, setselectedsubCategory] = useState(null);
  const [newCategory, setnewCategory] = useState("");
  const [newsubCategory, setnewsubCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [CategoryAllList, setCategoryAllList] = useState([]);
  const [Category_NooptionList, setCategory_NooptionList] = useState([]);
  const [subCategoryAllList, setsubCategoryAllList] = useState([]);
  const [CategorylistPageNo, setCategorylistPageNo] = useState(1);
  const [CategorylistTotalRecord, setCategorylistTotalRecord] = useState(0);
  const [subcategoryList, setsubCategoryList] = useState([]);
  const [SubCategorylistPageNo, setSubCategorylistPageNo] = useState(1);
  const [SubCategorylistTotalRecord, setSubCategorylistTotalRecord] =
    useState(0);
  const [success, setsuccess] = useState(false);
  const [catsuccess, setcatsuccess] = useState(false);
  const [catsubsuccess, setcatsubsuccess] = useState(false);
  const [errormsgshow, Seterrormsgshow] = useState(false);
  const [errormsg, Seterrormsg] = useState("");
  const [libraryListing, setlibraryListing] = useState([]);
  const [CategoryPerPage, setCategoryPerPage] = useState(10);
  const [CategoryPageNo, setCategoryPageNo] = useState(1);
  const [CategoryTotalRecord, setCategoryTotalRecord] = useState(0);
  const [paginationFor, setPagination] = useState("library");
  const [loader, SetloaderShow] = useState(false);

  const [showDelete, setshowDelete] = useState(false);
  const closeDelete = () => {setshowDelete(false)};

  const [dataTable, setdataTable] = useState('');
  const [dataId, setdataId] = useState('');
  const [deletePopuptitle, setdeletePopuptitle] = useState('');
  const [dataLabel, setdataLabel] = useState('');

  const [categoryenableOption, setcategoryenableOption] = useState(false);
  const [cat_editenableOption, setcat_editenableOption] = useState(false);

  const [subselectVisible, setsubselectVisible] = useState(false);

  const ShowLoader = () => {
    SetloaderShow(true);
  }

  const HideLoader = () => {
    SetloaderShow(false);
  }

  const handleCloses = () => {
    setselectedCategory(null);
    setselectedsubCategory(null);
    setUnitvalue("");
    setLow("");
    setModerate("");
    setHigh("");
    setOption("");
    setnewCategory("");
    setnewsubCategory("");
    Seterrormsgshow(false);
    setsuccess(false);
    setVisible(false);
    seteditVisible(false);
    seteditcategoryVisible(false);
    seteditsubcategoryVisible(false);
    setVisible1(false);
    setVisible2(false);
    setcatsubsuccess(false);
    setcatsuccess(false);
    setoptionEditPopup({ open: false,
      id:null,
      catid: null,      
      subcatid:null,
      value: null,
      units: null,
      low: null,
      high: null,
      moderate: null });
    setcategoryEditPopup({ open: false, id: null, name: null });
    setsubcategoryEditPopup({ open: false, id: null, name: null }); 
    setformErr(false);
    setformErrMsg("");
    setsuccessMsg(false); 
    setsubselectVisible(false);
  }

  const createNewLabtest = () => {
    SetaddPopuptitle("Create LabTest");
    setselectedCategory(null);
    setselectedsubCategory(null);
    setselectedCategoryforList(null);
    setsubCategoryList('');
    setVisible(true);
  }

  const createNewCategory = () => {
    SetaddPopuptitle("Create Category");
    setVisible1(true);
  }

  const createNewSubcategory = () => {
    SetaddPopuptitle("Create Subcategory");
    setVisible2(true);
  }

  useEffect(() => {    
    setselectedCategory(null);
    setselectedsubCategory(null);
    setUnitvalue("");
    setLow("");
    setModerate("");
    setHigh("");
    setOption("");
    setnewCategory("");
    setnewsubCategory("");
    Seterrormsgshow(false);
    setsuccess(false);
    GetLibraryData(0);
    GetCategory(1);
  }, []);

  const changePageNumber = async (pagenumber) => {
    getFilteredLibrary(pagenumber);
  }

  const changePageNumber1 = async (pagenumber) => {
    GetCategory(pagenumber);
  }

  const changePageNumber2 = async (pagenumber) => {
    GetSubCategory(pagenumber,'');
  }

  const changeTabs = async (e) => {

    setselectedCategory(null);
    setselectedCategoryforList(null);

    const list_for = e.target.getAttribute("data-item");

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove("active"));

    e.target.setAttribute("class", "active");
    //console.log("listfor", list_for);

    setPagination(list_for);

    // if(list_for === 'get-sub-library-cat')
    // {
    //   const response = await axiosJWT.get(
    //     '/labtest/get-sub-library-cat?offset=1&limit='+CategoryPerPage,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );

    //   if (response.data !== null) {        
    //     setSubCategorylistTotalRecord(response.data.LibrarySubCatCount);
    //     setsubCategoryList(response.data.LibrarySubCat);
    //   }    
    // }

    if(list_for === 'library')
    {
      GetLibraryData(0);
    }
}

const GetLibraryData = async (pagenumber) => {
    ShowLoader();
    setCategoryPageNo(pagenumber===0?1:pagenumber)
    const response = await axiosJWT.get(
      "/labtest/library?offset=" +
        pagenumber +
        "&limit=" +
        CategoryPerPage +
        "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data !== null) {
      setCategoryTotalRecord(response.data.lab_library_count);
      setlibraryListing(response.data.lab_library);
      HideLoader();
    } 
    else {
      console.log("errr");
    }
}

const GetCategory = async (pagenumber) => {

    setCategorylistPageNo(!pagenumber?1:pagenumber);
    const response = await axiosJWT.get(
      "/labtest/get-library-cat?offset="+pagenumber+"&limit="+CategoryPerPage,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    //console.log("ressss", response);

    if (response.data !== null) {
      if (response.data.LibraryCat.length > 0) {
        setCategorylistTotalRecord(response.data.LibraryCatCount);
        setCategoryList(response.data.LibraryCat);
      }
    }


    const response_all = await axiosJWT.get(
      "/labtest/get-library-cat?limit=all",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response_all.status === 200) {
    //  console.log("mi test")
  //    console.log(response_all.data)
      var filter_isoption_category = (response_all.data).filter((data)=>data.is_option === false)
//      console.log(filter_isoption_category)
      setCategoryAllList(response_all.data);
      setCategory_NooptionList(filter_isoption_category);
    }
}

useEffect(() => {
    document.title = 'Admin-Lab test';
    //GetSubCategory(0);
}, [selectedCategory || selectedCategoryforList]);

const GetSubCategory = async (pagenumber, category_id) => {
    setSubCategorylistPageNo(!pagenumber?1:pagenumber);   

    if(category_id !== '' && category_id !== null)
    var subcat = category_id;
    else
    var subcat= visible ?selectedCategory: selectedCategoryforList;    

      const response = await axiosJWT.get(
        `/labtest/get-sub-library-cat?cat_id=${subcat}&offset=${pagenumber}&limit=${CategoryPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data !== null) {
        //console.log("response.data", response.data);
        setSubCategorylistTotalRecord(response.data.LibrarySubCatCount);
        setsubCategoryList(response.data.LibrarySubCat);
      }    

      const response_all = await axiosJWT.get(
        `/labtest/get-sub-library-cat?cat_id=${subcat}&limit=all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_all) {
        var array_data = response_all.data;
        var nosubcat_filtered_data = [];
        
        array_data.forEach(element => {
          if(Category_NooptionList.find((data)=>data.id === element.cat_id))
          nosubcat_filtered_data.push(element);
        });

        console.log(array_data)
        console.log("mi: t")
        console.log(nosubcat_filtered_data)
        setSubCategorylistTotalRecord(nosubcat_filtered_data.length);
        setsubCategoryAllList(nosubcat_filtered_data);
      }    
}  

const createCategory = async () => {
    if (newCategory !== "") {

      try {
          const response = await axiosJWT.post(
            "/labtest/add-library-cat",
            {
              cat_label: newCategory,
              cat_value: newCategory.toLowerCase().replaceAll(" ", "_"),
              is_option: categoryenableOption
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response) {
            setcatsuccess(true)
            GetCategory();
            setnewCategory('');
            categoryenableOption(false);
          }
      } catch (error) {
        Seterrormsg("Category already exists.!");
        Seterrormsgshow(true);
      }

    }
    else
    {
      Seterrormsg("Please enter category.!");
      document.getElementById("category_input").classList.add("err_border");

      Seterrormsgshow(true);
    }
}

const createSubCategory = async () => {
    if (newsubCategory !== "" && (selectedCategory !== null || selectedCategoryforList!==0)) {
  //     console.log('cattt',visible ? selectedCategory : selectedCategoryforList )
  //  var subcat= visible ? selectedCategory : selectedCategoryforList 

      var subcat = selectedCategory;
      try {
        const response = await axiosJWT.post(
          "/labtest/add-sub-library-cat",
          {
            cat_id: subcat,
            sub_cat_label: newsubCategory,
            sub_cat_value: newsubCategory.toLowerCase().replaceAll(" ", "_"),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response) {
          setcatsubsuccess(true);
          GetSubCategory(1,'');
          setnewsubCategory('');
        }
      } catch (error) {
        /* if (subcat === null || subcat ===0) {
          alert("please select category");
        } */
        Seterrormsg("Sub category already exists.!");
        Seterrormsgshow(true);
      }
    }
    else
    {
      if(!selectedCategory)
        Seterrormsg("Please choose category from list.!");
      else
      {
        Seterrormsg("Please enter sub category.!");
        document.getElementById("subcategory_input").classList.add("err_border");
      }

      Seterrormsgshow(true);
    }
}

const errRemove = (e) => {
    //console.log('test')
    e.target.classList.remove("err_border");
    Seterrormsg(false);
}

const handleChange = (e) => {
    //console.log(e.target.value)
    // const { name, value } = e.target;
    //  setselectedCategory({ [name]: value });
    setselectedCategory(e.target.value);
    setselectedCategoryforList(e.target.value);
    GetSubCategory(1, parseInt(e.target.value));

    if(Category_NooptionList.find((data)=>data.id === parseInt(e.target.value)))
    {
    setsubselectVisible(true);
    }
    else
    {
      setsubselectVisible(false);
    }

    Seterrormsgshow(false);
    setsuccess(false);
}

const handleChangeforList = (e) => {
    if(parseInt(e.target.value) !== 0)
    {
    setselectedCategoryforList(e.target.value);
    setselectedCategory(null);
    var selected_label = CategoryAllList.find(data=>data.id===parseInt(e.target.value));
    setselectedCategoryLabel(selected_label.cat_label);
    GetSubCategory(1,e.target.value);
    }
    else
    {
      setselectedCategoryforList(0);
      setselectedCategoryLabel('');
    }
}

const handleChangeforListLibrary = async (e) => {
  
  var cat_id = e.target.value;

  setselectedCategory(cat_id);


  setCategoryPageNo(1);

  const response = await axiosJWT.get(
    "/labtest/library-by-category/"+cat_id+"?offset=1&limit="+CategoryPerPage,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data !== null) {
    setCategoryTotalRecord(response.data.lab_library_count);
    setlibraryListing(response.data.lab_library);    
  } 

}

const getFilteredLibrary = async (pagenumber) => {

  setCategoryPageNo(pagenumber===0?1:pagenumber);

  if(selectedCategory)
  {
    const response = await axiosJWT.get(
      "/labtest/library-by-category/"+selectedCategory+"?offset="+pagenumber+"&limit="+CategoryPerPage,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data !== null) {
      setCategoryTotalRecord(response.data.lab_library_count);
      setlibraryListing(response.data.lab_library);    
    } 
  }
  else
  {
    const response = await axiosJWT.get(
      "/labtest/library?offset="+pagenumber+"&limit="+CategoryPerPage,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data !== null) {
      setCategoryTotalRecord(response.data.lab_library_count);
      setlibraryListing(response.data.lab_library);    
    } 
  }

}

const handleChange1 = (e) => {
    // const { name, value } = e.target;
    // setselectedsubCategory({ [name]: value });
    setselectedsubCategory(e.target.value);
}

const modalOpen = async (e) => {
    const Id = e.target.getAttribute("data-item");
    const datamodal = e.target.getAttribute("data-modal");
    const val = e.target.getAttribute("data-value");
    const option = e.target.getAttribute("data-option");
   
    
    if (datamodal === "cat_edit") {
      setcategoryEditPopup({ open: true, id: Id, name: val });
      setcat_editLibrary(val);      

      if(option === 'true')
      setcat_editenableOption(1);
      else
      setcat_editenableOption(0);

      seteditcategoryVisible(true);
    }
    if (datamodal === "subcat_edit") {
      setsubcategoryEditPopup({ open: true, id: Id, name: val });
      setsubcat_editLibrary(val);
      seteditsubcategoryVisible(true);
    }    
}

const catEdit = async () => {
    try {
      if (cat_editLibrary === "" || cat_editLibrary === null) {
        setformErr(true);
        setformErrMsg("*Please fill the name.!");
      } else if (cat_editLibrary !== categoryEditPopup.name) {
        const res = await axiosJWT.post(
          "/labtest/update-library-cat",
          {
            cat_id: categoryEditPopup.id,
            cat_label: cat_editLibrary,
            cat_value: cat_editLibrary.toLowerCase().replaceAll(" ", "_"),
            is_option: cat_editenableOption
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 200) {
          setformErr(false);
          setformErrMsg("");
          setsuccessMsg(true);
          GetCategory(1);
          categoryEditPopup.name = cat_editLibrary;
        }
      } else {
        setformErr(true);
        setsuccessMsg(false);
        setformErrMsg("Category already exists.!");
      }
    } catch (error) {
      console.log(error);
    }
}

const subcatEdit = async () => {
    try {
      if (subcat_editLibrary === "" || subcat_editLibrary === null) {
        setformErr(true);
        setformErrMsg("*Please fill the name.!");
      } else if (subcat_editLibrary !== subcategoryEditPopup.name) {
        const res = await axiosJWT.put(
          "/labtest/update-sub-library-cat/"+subcategoryEditPopup.id,
          {
            cat_id: selectedCategoryforList,
            sub_cat_label: subcat_editLibrary,
            sub_cat_value: subcat_editLibrary.toLowerCase().replaceAll(" ", "_"),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 200) {
          setformErr(false);
          setformErrMsg("");
          setsuccessMsg(true);
          GetSubCategory(1,'');
          subcategoryEditPopup.name = subcat_editLibrary;
        }
      } else {
        setformErr(true);
        setsuccessMsg(false);
        setformErrMsg("Sub category already exists.!");
      }
    } catch (error) {
      console.log(error);
    }
}

const optionEdit = async () => {
    
      if (option_editLibrary === "" || option_editLibrary === null) {
        setformErr(true);
        setformErrMsg("*Please fill the name.!");
      } 
      else
      {
        if(optionEditPopup.value !== option_editLibrary.toLowerCase().replaceAll(" ", "_") || optionEditPopup.catid !== selectedCategory || optionEditPopup.subcatid !== selectedsubCategory || optionEditPopup.units !== unit_editLibrary || optionEditPopup.low !== low_editLibrary || optionEditPopup.high !== high_editLibrary || optionEditPopup.moderate !== moderate_editLibrary)
        {
          const res = await axiosJWT.post(
            "/labtest/update-library",
            {              
              id: optionEditPopup.id,
              cat_id: selectedCategory,
              sub_cat_id: selectedsubCategory,
              testname_value:option_editLibrary.toLowerCase().replaceAll(" ", "_"),
              testname_label: option_editLibrary,              
              units: unit_editLibrary,
              low: low_editLibrary,
              moderate: moderate_editLibrary,
              high: high_editLibrary
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (res.status === 200) {
            setformErr(false);
            setformErrMsg("");
            setsuccessMsg(true);
            GetLibraryData(0);
          }
          else
          {
            setformErr(true);
            setformErrMsg(res.response.data.msg);
          }
        }
      }        
  }

  const createLabtest = async () => {    

    // if (selectedCategory !== null && unitvalue !== "" && low !== "" && moderate != "" && high !== "" && option !== "") 

    if (selectedCategory !== null && option !== "") {      
      try {

        var sub_category = selectedsubCategory;

        const is_exist = Category_NooptionList.find(data=>data.id === parseInt(selectedCategory));

        if(!is_exist)
        {
          var cat_find = categoryList.find(data=>data.id === parseInt(selectedCategory));

          const subcat_create = await axiosJWT.post(
            "/labtest/add-sub-library-cat-direct-option",
            {
              cat_id: selectedCategory,
              sub_cat_label: cat_find.cat_label,
              sub_cat_value: cat_find.cat_value,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          sub_category = subcat_create.data.id;

        }
        

        const response = await axiosJWT.post(
          "/labtest/library",
          { 
            cat_id: selectedCategory,
            sub_cat_id: sub_category,
            testname_label: option,
            testname_value: option.toLowerCase().replaceAll(" ", "_"),
            units: unitvalue,
            low: low,
            moderate: moderate,
            high: high
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // setselectedCategory(null);
          // setselectedsubCategory(null);
          setUnitvalue("");
          setLow("");
          setModerate("");
          setHigh("");
          setOption("");
          setnewCategory("");
          setnewsubCategory("");
          Seterrormsgshow(false);
          setsuccess(true);
          GetLibraryData(0);
        }
      } catch (error) {
        Seterrormsg("This option already exsits for this category");
        setsuccess(false);
        Seterrormsgshow(true);
      }
    } 
    else {
      //console.log('values',selectedCategory,selectedsubCategory,unitvalue,low,high,moderate,option)
      Seterrormsg("*Please fill out all fields.!");
      Seterrormsgshow(true);
    }
  };

  const editLibrary = async (e) => {

    const Id = e.target.getAttribute("data-item");

    const response = await axiosJWT.get("/labtest/library/"+Id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    var info_data = response.data.lab_library;

    const datamodal = e.target.getAttribute("data-modal");
    const val = info_data.testname_label;
    const catIddata=info_data.cat_id;
    const subcatIddata=info_data.sub_cat_id;
    const units = info_data.units;
    const low = info_data.low;
    const high = info_data.high;
    const moderate = info_data.moderate;

      setoptionEditPopup({open:true,id:Id, catid: catIddata,
      subcatid:subcatIddata,
      value: val,
      units: units,
      low: low,
      high: high,
      moderate: moderate
      });

      setoption_editLibrary(val);
      setselectedCategory(catIddata);
      setselectedCategoryforList(catIddata);
      GetSubCategory(1,'');
      setselectedsubCategory(subcatIddata);


      if(Category_NooptionList.find((data)=>data.id === catIddata))
      {
      setsubselectVisible(true);
      }
      else
      {
        setsubselectVisible(false);
      }
      
      var toedit_catval=libraryListing.find(obj => obj.id == Id);
      setunit_editLibrary(toedit_catval.units);
      setlow_editLibrary(toedit_catval.low);
      sethigh_editLibrary(toedit_catval.high);
      setmoderate_editLibrary(toedit_catval.moderate);
    
      seteditVisible(true);
  }  

  const deleteModal = (e) => {
    var data_modal = e.target.getAttribute("data-modal");
    var data_item_id = e.target.getAttribute("data-item");
    var data_item_label = e.target.getAttribute("data-label");

    setshowDelete(true);

    if(data_modal === 'category_delete_confirm')
    {
    setdataTable("category");
    setdeletePopuptitle("Category");
    }
    if(data_modal === 'subcategory_delete_confirm')
    {
    setdataTable("subcategory"); 
    setdeletePopuptitle("Sub Category");
    }
    if(data_modal === 'options_delete_confirm')
    {
    setdataTable("options");
    setdeletePopuptitle("Options");
    }

    setdataLabel(data_item_label);
    setdataId(data_item_id);
  }

  const deleteData = async (e) => {
    var data_id = e.target.getAttribute("data-item"); 
    var data_table = e.target.getAttribute("data-table");

    if(data_table === 'category')
    {
      const response = await axiosJWT.delete("/labtest/category/"+data_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if(response.status === 200)
      {
        closeDelete();
        GetCategory(1);
      }
    }

    if(data_table === 'subcategory')
    {
      const response = await axiosJWT.delete("/labtest/subcategory/"+data_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if(response.status === 200)
      {
        closeDelete();
        GetSubCategory(1,'');
      }
    }

    if(data_table === 'options')
    {
      const response = await axiosJWT.delete("/labtest/options/"+data_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );      

      if(response.status === 200)
      {        
        GetLibraryData(0);
        closeDelete();
      }
    }
  }

  function getCategoryName(category_id)
  {
    var result = CategoryAllList.find(data=>data.id === category_id);

    if(result)
    return result.cat_label;
    else
    return null;
  }

  const navigateTo = (tab) => {
    handleCloses();

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove("active"));

    if(tab === 'get-library-cat')
    document.getElementById("category_tab").setAttribute("class", "active");
    else if(tab === 'get-sub-library-cat')
    document.getElementById("subcategory_tab").setAttribute("class", "active");
    else
    document.getElementById("library_tab").setAttribute("class", "active");    

    setPagination(tab);
  }

  const updateCategoryOption = async (e) => {
    var data_id = e.target.getAttribute("data-id");

    if(e.target.checked === true)
    var option_val = 1;
    else
    var option_val = 0;

    const res = await axiosJWT.put("/labtest/update-library-cat/"+data_id,
      {
        is_option: option_val
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );   
  }

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      {loader === true && <div id="semiTransparenDivLoader"></div>}
      <section className="dashboard">
        <div className="adminlabtest container">
          <div className="menubox">
            <div className="tabmenu">             
              <a id="category_tab" className="" onClick={changeTabs} data-item="get-library-cat">
                Category
              </a>
              <a id="subcategory_tab" className="" onClick={changeTabs} data-item="get-sub-library-cat"
              > Sub Category </a>
              <a id="library_tab" className="active" onClick={changeTabs} data-item="library"> Options </a>
            </div>
            <div className="rightmenu">
              <button
              // disabled={selectedCategoryforList === 0 && paginationFor === "get-sub-library-cat" ? true :false}                
                onClick={() => {
                  paginationFor === "library"
                    ? createNewLabtest()
                    : paginationFor === "get-library-cat"
                    ? createNewCategory()
                    : createNewSubcategory()
                }}
              >
                Create New
              </button>
            </div>
          </div>
         
          <div className="row" style={{ display: "block" }}>
            {paginationFor === "library" ? (
              <>
              <select
                  className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
                  style={{ float: "left", marginTop: "6px", width: "100%" }}
                  value={selectedCategoryforList}
                  onChange={(e) => handleChangeforListLibrary(e)}
                >
                  <option value="0">Please select category</option>
                  {CategoryAllList.length>0 && CategoryAllList.map((itm, inx) => {
                    return (
                      <option key={inx} value={itm.id} data-item={itm.cat_label}>
                        {itm.cat_label}
                      </option>
                    );
                  })}
                </select>
              <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                <Thead className="thead ">
                  <Tr className="trow">                    
                    <Th className="row_name">Category</Th>
                    <Th className="row_name">SubCategory</Th>
                    <Th className="row_name">Options</Th>
                    <Th className="row_name">Units</Th>
                    <Th className="row_name">High</Th>
                    <Th className="row_name">Low</Th>                    
                    <Th className="row_name">Moderate</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>

                {libraryListing.length > 0 && (
                  <Tbody className="tbody">
                    {libraryListing.length > 0 &&
                      libraryListing.map((itm, index) => (                      
                        <Tr className="trow" key={itm.id}>                          
                          <Td>{itm.labtest_cat.cat_label}</Td>
                          {
                          Category_NooptionList.find(data=>data.id === parseInt(itm.cat_id)) ?
                          <Td>{itm.labtest_sub_cat.sub_cat_label}</Td>
                          :<Td>-</Td>
                          }
                          <Td>
                            <span className="">{itm.testname_label}</span>
                          </Td>
                          <Td>{itm.units}</Td>
                          <Td>{itm.high}</Td>
                          <Td>{itm.low}</Td>                          
                          <Td>{itm.moderate}</Td>
                          <Td>
                            <i
                              className="fa fa-edit td_icon action"
                              data-modal="overall_edit"
                              data-item={itm.id}
                              onClick={editLibrary}
                            ></i>
                            <i
                              className="fa fa-trash td_icon action"
                              aria-hidden="true"
                              data-modal="options_delete_confirm"
                              data-label={itm.testname_label}
                              data-item={itm.id}
                              onClick={deleteModal}
                            ></i>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
              </>
            ) : paginationFor === "get-library-cat" ? (
              <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                <Thead className="thead ">
                  <Tr className="trow">
                    <Th className="row_name">Category</Th>
                    <Th className="row_name">Direct Options</Th>
                    <Th className="row_name">Action</Th>
                  </Tr>
                </Thead>
                {categoryList.length > 0 && (
                  <Tbody className="tbody">
                    {categoryList.length > 0 &&
                      categoryList.map((itm, index) => (
                        <Tr className="trow" key={itm.id}>
                          <Td>
                            <span className="">{itm.cat_label}</span>
                          </Td>
                          <Td>
                            <span className=""><input type="checkbox" defaultChecked={itm.is_option} onChange={updateCategoryOption} data-id={itm.id}/></span>
                          </Td>
                          <Td>
                            <i
                              className="fa fa-edit td_icon action"
                              data-modal="cat_edit"
                              data-item={itm.id}
                              data-value={itm.cat_label}
                              data-option={itm.is_option}
                              onClick={modalOpen}
                            ></i>
                            <i
                              className="fa fa-trash td_icon action"
                              aria-hidden="true"
                              data-modal="category_delete_confirm"
                              data-label={itm.cat_label}
                              data-item={itm.id}
                              onClick={deleteModal}
                            ></i>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
            ) : (
              <>
                {" "}
                <select
                  className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
                  style={{ float: "left", marginTop: "6px", width: "100%" }}
                  value={selectedCategoryforList}
                  onChange={(e) => handleChangeforList(e)}
                >
                  <option value="0">Please select category</option>
                  {Category_NooptionList.length>0 && Category_NooptionList.map((itm, inx) => {
                    return (
                      <option key={inx} value={itm.id} data-item={itm.cat_label}>
                        {itm.cat_label}
                      </option>
                    );
                  })}
                </select>
                {/* {selectedCategoryforList !== 0 ? ( */}
                  <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                    <Thead className="thead ">
                      <Tr className="trow">
                        <Th className="row_name">Category</Th>
                        <Th className="row_name">Sub Category</Th>
                        <Th className="row_name">Action</Th>
                      </Tr>
                    </Thead>

                    {subcategoryList.length > 0 && (
                      <Tbody className="tbody">
                        {subcategoryList.length > 0 &&
                          subcategoryList.map((itm, index) => (
                            <Tr className="trow" key={itm.id}>
                              <Td>
                                <span className="">{getCategoryName(itm.cat_id)}</span>
                              </Td>
                              <Td>
                                <span className="">{itm.sub_cat_label}</span>
                              </Td>
                              <Td>
                                <i
                                  className="fa fa-edit td_icon action"
                                  data-modal="subcat_edit"
                                  data-item={itm.id}
                                  data-value={itm.sub_cat_label}
                                  onClick={modalOpen}
                                ></i>
                                <i
                                  className="fa fa-trash td_icon action"
                                  aria-hidden="true"
                                  data-modal="subcategory_delete_confirm"
                                  data-label={itm.sub_cat_label}
                                  data-item={itm.id}
                                  onClick={deleteModal}
                                ></i>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    )}  
                  </Table>
                {/* ) : null} */}
              </>
            )}
            <div>
              {paginationFor === "library" && CategoryTotalRecord ? (
                <Pagination
                  activePage={CategoryPageNo}
                  itemsCountPerPage={CategoryPerPage}
                  totalItemsCount={CategoryTotalRecord}
                  onChange={changePageNumber.bind(this)}
                  prevPageText="<"
                  nextPageText=">"
                  hideFirstLastPages={false}
                  linkClassPrev="pagination_prev"
                  linkClassNext="pagination_next"
                  linkClass="pagenumbers color_grey"
                />
              ) : paginationFor === "get-library-cat" &&
                CategorylistTotalRecord ? (
                <Pagination
                  activePage={CategorylistPageNo}
                  itemsCountPerPage={CategoryPerPage}
                  totalItemsCount={CategorylistTotalRecord}
                  onChange={changePageNumber1.bind(this)}
                  prevPageText="<"
                  nextPageText=">"
                  hideFirstLastPages={false}
                  linkClassPrev="pagination_prev"
                  linkClassNext="pagination_next"
                  linkClass="pagenumbers color_grey"
                />
              ) : (
                paginationFor === "get-sub-library-cat" &&
                SubCategorylistTotalRecord ?
                  <Pagination
                    activePage={SubCategorylistPageNo}
                    itemsCountPerPage={CategoryPerPage}
                    totalItemsCount={SubCategorylistTotalRecord}
                    onChange={changePageNumber2.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={false}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers color_grey"
                  />
                
              :null)}
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={editcategoryVisible}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_cat_edit_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
            <div>
              <label>Category Name:</label>
              <input
                type="text"
                name="first_name"
                value={cat_editLibrary}
                onChange={(e) => setcat_editLibrary(e.target.value)}
              />
            </div>       
            <div>
              <label>Direct Options: </label>
            <input type="checkbox" onChange={(e)=>setcat_editenableOption(e.target.checked)} defaultChecked={cat_editenableOption}/>
            </div>     
            {successmsg === true && (
              <div style={{ color: "green", textAlign: "center" }}>
                Category successfully updated.!{" "}
              </div>
            )}
            {formErr === true && <div className="form_err">{formErrMsg}</div>}
      </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={catEdit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editsubcategoryVisible}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_subcat_edit_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Subcategory</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
            <div>
              <label>Subcategory Name:</label>
              <input
                type="text"
                name="first_name"
                value={subcat_editLibrary}
                onChange={(e) => setsubcat_editLibrary(e.target.value)}
              />
            </div>            
            {successmsg === true && (
              <div style={{ color: "green", textAlign: "center" }}>
                Sub category successfully updated{" "}
              </div>
            )}
            {formErr === true && <div className="form_err">{formErrMsg}</div>}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={()=>subcatEdit()}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editVisible}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_edit_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formErr === true && <div className="form_err">{formErrMsg}</div>}
            <div>
            <label className="commission_inner_popup_label required">Category Name:</label>
            <select
              className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
              style={{ float: "left", marginTop: "6px", width: "100%" }}
              value={selectedCategory}
              onChange={(e) => handleChange(e)}
              //onChange={(e) => e.target.value!=""? handleChange(e): null}
            >
              <option value="0">Please select category</option>
              {CategoryAllList.length>0 && CategoryAllList.map((itm, inx) => {
                return (
                  <option key={inx} value={itm.id}>
                    {itm.cat_label}
                  </option>
                );
              })}
            </select>
                <br/>
              {subselectVisible ?
              <>
              <label className="commission_inner_popup_label required">Subcategory Name:</label>
              <select
              className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
              style={{ float: "left", marginTop: "6px", width: "100%" }}
              value={selectedsubCategory}
              onChange={(e) =>
                e.target.value !== "" ? handleChange1(e) : null
              }>
              <option value="0">Please select subcategory</option>
              {subCategoryAllList && subCategoryAllList.length > 0 ?
              subCategoryAllList.map((itm, inx) => {
                return (
                  <option
                    key={inx}
                    value={itm.id}
                    //disabled={selectedCategory == null ? true : false}
                    onClick={() => {
                      setselectedsubCategory(itm.id);
                    }}
                  >
                    {itm.sub_cat_label}
                  </option>
                );
              }): null }
            </select>
            </>: null }
                <br/>
              <div className="row">
                <div className="col">
                <label className="commission_inner_popup_label required">Option:</label>
              <input
                type="text"
                className="add_category_inner_input add_intake_form_input"
                value={option_editLibrary}
                onChange={(e) => setoption_editLibrary(e.target.value)}
              />
                </div>
                </div>
                <div className="row">
                <div className="col">
                <label className="commission_inner_popup_label">Unit:</label>
              <input
                type="text"
                className="add_category_inner_input add_intake_form_input"
                value={unit_editLibrary}
                onChange={(e) => setunit_editLibrary(e.target.value)}
              />
               </div>
               
                <div className="col">
                <label className="commission_inner_popup_label">High:</label>
              <input
                type="text"
                className="add_category_inner_input add_intake_form_input"
                value={high_editLibrary}
                onChange={(e) => sethigh_editLibrary(e.target.value)}
              />
               </div>
               </div>
               <div className="row">
                <div className="col">
                <label className="commission_inner_popup_label">Low:</label>
              <input
                type="text"
                className="add_category_inner_input add_intake_form_input"
                value={low_editLibrary}
                onChange={(e) => setlow_editLibrary(e.target.value)}
              />
               </div>               
                <div className="col">
                <label className="commission_inner_popup_label">Moderate:</label>
              <input
                type="text"
                className="add_category_inner_input add_intake_form_input"
                value={moderate_editLibrary}
                onChange={(e) => setmoderate_editLibrary(e.target.value)}
              />
              </div>
              </div>
            </div>
            {successmsg === true && (
              <div style={{ color: "green", textAlign: "center" }}>
                Successfully updated.!{" "}
              </div>
            )}          
          </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={optionEdit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={visible}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_add_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addPopuptitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="commission_inner_popup_label required">Select Category:</label>
          <label
            className="commission_inner_popup_label"
            style={{ color: "blue",cursor: "pointer",paddingLeft:"10px" }}
            onClick={()=>navigateTo('get-library-cat')}
          >
            (add newcategory)
          </label>
          <br />
         
            <select
              className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
              style={{ float: "left", marginTop: "6px", width: "100%" }}
              value={selectedCategory}
              onChange={(e) => handleChange(e)}
              //onChange={(e) => e.target.value!=""? handleChange(e): null}
            >
              <option value="">Please select category</option>
              {CategoryAllList.length>0 && CategoryAllList.map((itm, inx) => {
                return (
                  <option key={inx} value={itm.id}>
                    {itm.cat_label}
                  </option>
                );
              })}
            </select>

          {subselectVisible ? 
          <>
          <label className="commission_inner_popup_label required">Select SubCategory:</label>
          <label
            className="commission_inner_popup_label"
            style={{ color: "blue",cursor: "pointer",paddingLeft:"10px" }}
            onClick={()=>navigateTo('get-sub-library-cat')}
          >
            (add newsubcategory)
          </label>           
            <select
              className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
              style={{ float: "left", marginTop: "6px", width: "100%" }}
              onChange={(e) =>
                e.target.value !== "" ? handleChange1(e) : null
              }
            >
              <option value="">Please select subcategory</option>
              {subCategoryAllList && subCategoryAllList.length > 0 ?
              subCategoryAllList.map((itm, inx) => {
                return (
                  <option
                    key={inx}
                    value={itm.id}
                    //disabled={selectedCategory == null ? true : false}
                    onClick={() => {
                      setselectedsubCategory(itm.id);
                    }}
                  >
                    {itm.sub_cat_label}
                  </option>
                );
              }) : null }
            </select>
            </> : null}          
          <br/>
          <div className="row">
            <div className="col">
          <label className="commission_inner_popup_label required">Option:</label>
          <input
            type="text"
            className="add_category_inner_input add_intake_form_input"
            onChange={(e) => {
              setOption(e.target.value);
            }}
            value={option}
          />
          </div>
          </div>
          <div className="row">
            <div className="col">
          <label className="commission_inner_popup_label">Unit:</label>          
          <input
            type="text"
            className="add_category_inner_input add_intake_form_input"
            onChange={(e) => {
              setUnitvalue(e.target.value);
            }}
            value={unitvalue}
          />
          </div>
          <div className="col">
          <label className="commission_inner_popup_label">High:</label>          
          <input
            type="text"
            className="add_category_inner_input add_intake_form_input"
            onChange={(e) => {
              setHigh(e.target.value);
            }}
            value={high}
          />
          </div>
          </div>
          <div className="row">
            <div className="col">
            <label className="commission_inner_popup_label">Low:</label>
          <input
            type="text"
            className="add_category_inner_input add_intake_form_input"
            onChange={(e) => {
              setLow(e.target.value);
            }}
            value={low}
          />          
          </div>
          <div className="col">
          <label className="commission_inner_popup_label">Moderate:</label>          
          <input
            type="text"
            className="add_category_inner_input add_intake_form_input"
            onChange={(e) => {
              setModerate(e.target.value);
            }}
            value={moderate}
          />
          </div>
          </div>
         
          {success === true && (
            <span style={{ color: "green" }}>Successfully Created.!</span>
          )}
          {errormsgshow === true && (
            <span style={{ color: "red" }}>{errormsg}</span>
          )}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={createLabtest}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={visible1}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_add_category_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addPopuptitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {errormsgshow === true && (
            <span style={{ color: "red" }}>{errormsg}</span>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              id="category_input" type="text"
              className="add_category_inner_input add_intake_form_input"
              onChange={(e) => {
                setnewCategory(e.target.value);
              }}
              value={newCategory}
              onClick={(e)=>errRemove(e)}
            />            
          </div>
          <div><label>Direct Options: </label><input type="checkbox" onClick={(e)=>setcategoryenableOption(e.target.value)}/></div>
          {catsuccess===true && <span style={{color:"green"}}>Category created successfully.!</span>}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={createCategory}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={visible2}
        onHide={handleCloses}
        className="admin_mail_template confirm_delete adminlabtest_add_subcategory_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>{addPopuptitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {errormsgshow === true && (
            <span style={{ color: "red" }}>{errormsg}</span>
          )}
          {/* <div className="category_label_subcategory"><label>Category: </label>{selectedCategoryLabel}</div> */}
          <div>
          <select
              className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select"
              style={{ float: "left", marginTop: "6px", width: "100%" }}
              value={selectedCategory}
              onChange={(e) => handleChange(e)}
              //onChange={(e) => e.target.value!=""? handleChange(e): null}
            >
              <option value="">Please select category</option>
              {Category_NooptionList.length>0 && Category_NooptionList.map((itm, inx) => {
                return (
                  <option key={inx} value={itm.id}>
                    {itm.cat_label}
                  </option>
                );
              })}
            </select>
          </div>
          <div>            
            <input
                  id="subcategory_input" type="text"
                  className="add_category_inner_input add_intake_form_input"
                  onChange={(e) => {
                    setnewsubCategory(e.target.value);
                  }}
                  value={newsubCategory}
                  onClick={(e)=>errRemove(e)}
                />  
          </div>
          {catsubsuccess===true && <span style={{color:"green",textAlign:"center"}}>Subcategory created successfully.!</span>}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={handleCloses}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button"
            onClick={createSubCategory}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={closeDelete} className="admin_mail_template confirm_delete"
      >
        <Modal.Header closeButton>
          <Modal.Title>{deletePopuptitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <span style={{color:"#226920",fontWeight:"500"}}>{deletePopuptitle} - {dataLabel}</span> ?
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="secondary" className="btn-secondary close_button" onClick={closeDelete}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primary save_button" data-table={dataTable}
            data-item={dataId} onClick={(e) => { deleteData(e) }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default AdminLabTest;
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Pagination from "react-js-pagination";
import axios from 'axios';
import moment from 'moment'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const ManageTeam = () => {

    const side_nav = localStorage.getItem("side_nav");

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [Mshow, setMShow] = useState({ display: 'inline-table' });
    const [Tshow, setTShow] = useState({ display: 'none' });
    const [show, setShow] = useState(false);
    const [PopupTshow, setpopupTShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);

    const handleTClose = () => setpopupTShow(false);
    const handleTOpen = () => setpopupTShow(true);

    const [FinalErrAddManagerShow, SetFinalErrAddManagerShow] = useState(false)
    const [FinalErrAddManagerMsg, SetFinalErrAddManagerMsg] = useState('')

    const [DeleteTeamId, SetDeleteTeamId] = useState('')
    const [DeleteTeamErrMsg, SetDeleteTeamErrMsg] = useState('')

    const [DeleteManagerId, SetDeleteManagerId] = useState('')
    const [DeleteManagerMsg, SetDeleteManagerMsg] = useState('')

    const [AccessEditMemberId, SetAccessEditMemberId] = useState('')

    const [AppointmentEnabled, SetAppointmentEnabled] = useState(false)
    const [PaymentEnabled, SetPaymentEnabled] = useState(false)
    const [ExpertEnabled, SetExpertEnabled] = useState(false)
    const [EnquiryEnabled, SetEnquiryEnabled] = useState(false)
    const [AnalyticsEnabled, SetAnalyticsEnabled] = useState(false)


    const [AppointmentEnabledEdit, SetAppointmentEnabledEdit] = useState(false)
    const [PaymentEnabledEdit, SetPaymentEnabledEdit] = useState(false)
    const [ExpertEnabledEdit, SetExpertEnabledEdit] = useState(false)
    const [EnquiryEnabledEdit, SetEnquiryEnabledEdit] = useState(false)
    const [AnalyticsEnabledEdit, SetAnalyticsEnabledEdit] = useState(false)

    const [AppointmentEnabledDelete, SetAppointmentEnabledDelete] = useState(false)
    const [PaymentEnabledDelete, SetPaymentEnabledDelete] = useState(false)
    const [ExpertEnabledDelete, SetExpertEnabledDelete] = useState(false)
    const [EnquiryEnabledDelete, SetEnquiryEnabledDelete] = useState(false)
    const [AnalyticsEnabledDelete, SetAnalyticsEnabledDelete] = useState(false)


    const [MaleGenderChecked, SetMaleGenderChecked] = useState(false)
    const [FemaleGenderChecked, SetFemaleGenderChecked] = useState(false)



    const [TImeZoneList, SetTimeZoneList] = useState([])
    const [TimeZone, setTimeZone] = useState('');

    const [showsdmAccessDefault, SetshowsdmAccessDefault] = useState(false)

    const handleClosesdmAccessDefault = () => {
        SetshowsdmAccessDefault(false)
    }
    const [AppointmentEnabledDefault, SetAppointmentEnabledDefault] = useState(false)
    const [PaymentEnabledDefault, SetPaymentEnabledDefault] = useState(false)
    const [ExpertEnabledDefault, SetExpertEnabledDefault] = useState(false)
    const [EnquiryEnabledDefault, SetEnquiryEnabledDefault] = useState(false)
    const [AnalyticsEnabledDefault, SetAnalyticsEnabledDefault] = useState(false)

    const [AppointmentEnabledDefaultEdit, SetAppointmentEnabledDefaultEdit] = useState(false)
    const [PaymentEnabledDefaultEdit, SetPaymentEnabledDefaultEdit] = useState(false)
    const [ExpertEnabledDefaultEdit, SetExpertEnabledDefaultEdit] = useState(false)
    const [EnquiryEnabledDefaultEdit, SetEnquiryEnabledDefaultEdit] = useState(false)
    const [AnalyticsEnabledDefaultEdit, SetAnalyticsEnabledDefaultEdit] = useState(false)

    const [AppointmentEnabledDefaultDelete, SetAppointmentEnabledDefaultDelete] = useState(false)
    const [PaymentEnabledDefaultDelete, SetPaymentEnabledDefaultDelete] = useState(false)
    const [ExpertEnabledDefaultDelete, SetExpertEnabledDefaultDelete] = useState(false)
    const [EnquiryEnabledDefaultDelete, SetEnquiryEnabledDefaultDelete] = useState(false)
    const [AnalyticsEnabledDefaultDelete, SetAnalyticsEnabledDefaultDelete] = useState(false)

    const [ShowSettingsButton, SetShowSettingsButton] = useState(true)

    const [ManagerGenderErr, SetManagerGenderErr] = useState(false)
    const [ManagerGenderErrMsg, SetManagerGenderErrMsg] = useState('')


    const handleChangeAccessDefault = (e) => {




        if (e.target.value === 'appointment') {
            if (e.target.checked === false) {
                SetAppointmentEnabledDefault(false)
            }
            if (e.target.checked === true) {
                SetAppointmentEnabledDefault(true)
            }

        }

        if (e.target.value === 'payment') {
            if (e.target.checked === false) {
                SetPaymentEnabledDefault(false)
            }
            if (e.target.checked === true) {
                SetPaymentEnabledDefault(true)
            }

        }
        if (e.target.value === 'manage_users') {
            if (e.target.checked === false) {
                SetExpertEnabledDefault(false)
            }
            if (e.target.checked === true) {
                SetExpertEnabledDefault(true)
            }

        }
        if (e.target.value === 'manage_users_edit') {
            if (e.target.checked === false) {
                SetExpertEnabledDefaultEdit(false)
            }
            if (e.target.checked === true) {
                SetExpertEnabledDefaultEdit(true)
            }

        }
        if (e.target.value === 'manage_users_delete') {
            if (e.target.checked === false) {
                SetExpertEnabledDefaultDelete(false)
            }
            if (e.target.checked === true) {
                SetExpertEnabledDefaultDelete(true)
            }

        }
        if (e.target.value === 'manage_enquiries') {
            if (e.target.checked === false) {
                SetEnquiryEnabledDefault(false)
            }
            if (e.target.checked === true) {
                SetEnquiryEnabledDefault(true)
            }

        }
        if (e.target.value === 'manage_enquiries_delete') {
            if (e.target.checked === false) {
                SetEnquiryEnabledDefaultDelete(false)
            }
            if (e.target.checked === true) {
                SetEnquiryEnabledDefaultDelete(true)
            }

        }
        if (e.target.value === 'manage_analytics') {
            if (e.target.checked === false) {
                SetAnalyticsEnabledDefault(false)
            }
            if (e.target.checked === true) {
                SetAnalyticsEnabledDefault(true)
            }

        }

    }

    const SaveAccessSettingDefault = async (e) => {



        const response = await axiosJWT.post('/managing-team/save-access-details-default',
            {

                appointment: AppointmentEnabledDefault,
                payment: PaymentEnabledDefault,
                expert: ExpertEnabledDefault,
                expert_edit: ExpertEnabledDefaultEdit,
                expert_delete: ExpertEnabledDefaultDelete,
                enquiry: EnquiryEnabledDefault,
                enquiry_delete: EnquiryEnabledDefaultDelete,
                analytics: AnalyticsEnabledDefault




            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {



            handleClosesdmAccessDefault()


        }

    }



    const [showsdm, Setshowsdm] = useState(false)
    const handleClosesdm = () => {
        Setshowsdm(false)
    }

    const [showsd, Setshowsd] = useState(false)
    const handleClosesd = () => {
        Setshowsd(false)

    }

    const [showsdmAccess, SetshowsdmAccess] = useState(false)
    const handleClosesdmAccess = () => {
        SetshowsdmAccess(false)
    }

    const changeUsers = async (e) => {

        SetShowSettingsButton(false)

        const list_for = e.target.getAttribute("data-item");

        if (list_for === 'manager') {
            SetShowSettingsButton(true)
            document.getElementsByClassName("search_bar_manager_team")[0].setAttribute("data-tab", 'manager');
            document.getElementsByClassName("manager_team_button")[0].setAttribute("data-tab", 'manager');
            document.querySelector('.manager_team_button').innerHTML = 'Add Manager';
            setMShow({ display: 'inline-table' });
            setTShow({ display: 'none' });
            document.getElementsByClassName('search_bar_manager_team')[0].value = '';
            ManagerList(1);
        }
        else if (list_for === 'team') {
            document.getElementsByClassName("search_bar_manager_team")[0].setAttribute("data-tab", 'team');
            document.getElementsByClassName("manager_team_button")[0].setAttribute("data-tab", 'team');
            document.querySelector('.manager_team_button').innerHTML = 'Add Team';
            setMShow({ display: 'none' });
            setTShow({ display: 'inline-table' });
            document.getElementsByClassName('search_bar_manager_team')[0].value = '';
            GetTeamLists(1);
        }

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");
    }

    const addData = (e) => {

        const tab = e.target.getAttribute("data-tab");
        if (tab === 'manager') {
            handleOpen();
            GetTeamLists();
            SetFirstNameManager('');
            SetLastNameManager('');
            SetEmailAddressManager('');
            SetPhoneNoManager('');
            SetTeamIdManager('');
            setTimeZone('');
            SetManagerGender('');
            SetAddress('');
            SetPostal('');
            SetManagerLabel('Add Manager');
            SetAddManagerAction('add');
            SetUpdateManagerId('');
            SetEmailstatus(false);
            SetManagerFnameErr(false);
            SetManagerLnameErr(false);
            SetManagerEmailErr(false);
            SetManagerPhoneErr(false);
            SetManagerTeamErr(false);
            SetManagerPassErr(false);
            SetManagerTimezoneErr(false);
            SetManagerGenderErr(false);
            SetMaleGenderChecked(false);
            SetFemaleGenderChecked(false);
        }
        if (tab === 'team') {
            SetTeamButtonLabel('Add Team');
            SetTeamButtonaction('add_team');
            SetUpdateTeamId('');
            SetDefaultSelectedTeamMembers([]);
            SetDefaultValueTeam('');
            GetExpertsNoteam();
            SetExpertErr(false);
            SetTeamNameErr(false);
            handleTOpen();
        }
    }


    // const [expertoptions, setExpertoptions] = useState([
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    //   ]);
    const [expertoptions, setExpertoptions] = useState([]);
    const [selectedOptions, setSelectedoptions] = useState([]);
    const [NewTeamName, setNewTeamName] = useState('');
    const [TeamList, SetTeamList] = useState([]);


    const GetExpertsNoteam = async () => {
        const response = await axiosJWT.get('/managing-team/get-experts?filter=no_team', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            var arrayObj = [];

            if (response.data.Coach !== null && response.data.Coach.length > 0) {
                response.data.Coach.forEach((data, index) => {
                    var obj = {};
                    obj["value"] = data.id;
                    obj["label"] = data.email;
                    arrayObj.push(obj);
                })
                setExpertoptions(arrayObj);
            }
            else {
                setExpertoptions([]);
            }



            // if (response.data.final_user_object !== undefined) {
            //     if (response.data.final_user_object.length > 0) {
            //         var arrayObj = [];
            //         response.data.final_user_object.forEach((data, index) => {
            //             var obj = {};
            //             obj["value"] = data.id;
            //             obj["label"] = data.email;
            //             arrayObj.push(obj);
            //         });


            //         setExpertoptions(arrayObj)

            //     }
            //     else {
            //         setExpertoptions([])
            //     }

            // }

        });
    }

    const [TeamNameErr, SetTeamNameErr] = useState(false)
    const [TeamNameErrMsg, SetTeamNameErrMsg] = useState('')

    const [ExpertErr, SetExpertErr] = useState(false)
    const [ExpertErrMsg, SetExpertErrMsg] = useState('')

    const add_team_final = async (e) => {

        var err = false;
        SetExpertErr(false);
        SetTeamNameErr(false);

        // if (selectedOptions.length === 0) {
        //     SetExpertErrMsg('Please select experts')
        //     SetExpertErr(true)
        //     err = true
        // }

        if (NewTeamName === '') {
            SetTeamNameErr(true);
            SetTeamNameErrMsg('Please enter team name');
            err = true;
        }

        if (err === true) {
            return false;
        }

        loading_icon('show');

        if (TeamButtonAddaction === 'add_team') {
            var UserId = [];

            //if (selectedOptions.length > 0 && NewTeamName !== '') {
            if (NewTeamName !== '') {

                if (selectedOptions.length > 0) {
                    selectedOptions.forEach((data, index) => {
                        UserId.push(data.value);
                    });

                    var Idtext = UserId.toString();
                }
                else {
                    var Idtext = '';
                }

                const response = await axiosJWT.post('/managing-team/add-team',
                    {
                        team_name: NewTeamName,
                        expert_id: Idtext
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    handleTClose();

                    setTimeout(() => {
                        GetTeamLists(1);
                    }, 100);

                    setNewTeamName('');
                    setSelectedoptions([]);
                    loading_icon('hide');
                }
            }
        }
        else if (TeamButtonAddaction === 'edit_team') {

            var LoadedID = [];
            var DeletedId = [];
            var Exist_oldId = [];

            if (DefaultSelectedTeamMembers.length > 0) {
                DefaultSelectedTeamMembers.forEach((data, index) => {
                    LoadedID.push(data.value);
                })

                LoadedID.forEach((data, index) => {
                    if (userExists(data)) {
                        Exist_oldId.push(data);
                    } else {
                        DeletedId.push(data);
                    }
                });
            }

            var Editingid = e.target.getAttribute("data-update");

            var UserId = []

            // if (selectedOptions.length === 0) {
            //     SetExpertErrMsg('Please select experts')
            //     SetExpertErr(true)
            //     err = true
            // }

            if (NewTeamName === '') {
                SetTeamNameErr(true);
                SetTeamNameErrMsg('Please enter team name');
                err = true;
            }

            if (err === true) {
                return false;
            }

            //if (selectedOptions.length > 0 && NewTeamName !== '') {

            if (NewTeamName !== '') {

                if (selectedOptions.length > 0) {
                    selectedOptions.forEach((data, index) => {
                        if (!Exist_oldId.includes(data.value))
                            UserId.push(data.value);
                    });

                    var Idtext = UserId.toString();
                    var DIdtext = DeletedId.toString();
                }
                else {
                    var Idtext = '';
                    var DIdtext = DeletedId.toString();
                }


                const response = await axiosJWT.post('/managing-team/update-team',
                    {
                        team_name: NewTeamName,
                        add_coach_id: Idtext,
                        team_id: Editingid,
                        removed_coach_id: DIdtext
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    handleTClose();
                    GetTeamLists(TeamPageNo);
                    loading_icon('hide');
                }
            }
        }
    }

    function userExists(name) {
        return selectedOptions.some(function (el) {
            return el.value === name;
        });
    }

    const DropdownChange = (e) => {
        setSelectedoptions(e)
    }

    const [TeamPageNo, SetTeamPageNo] = useState(1)
    const [TeamPerPage, SetTeamPerPage] = useState(10)
    const [TotalRecordTeam, setTotalRecordTeam] = useState('')
    const [FinalSearchvalue, setFinalSearchvalue] = useState('')

    const GetTeamLists = async (pagenumber) => {


        var value = document.getElementsByClassName("search_bar_manager_team")[0].value;

        if (value !== '') {
            const response = await axiosJWT.get('/managing-team/get-team?offset=' + pagenumber + '&limit=' + TeamPerPage + '&search=' + value, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                SetTeamList(response.data.searchlist);
                setTotalRecordTeam(response.data.searchtotalcount);
            });
        }
        else {
            var allstring = '';

            if (pagenumber === undefined) {
                allstring = 'all';
            }

            const response = await axiosJWT.get('/managing-team/get-team?offset=' + pagenumber + '&limit=' + TeamPerPage + '&data=' + allstring, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                SetTeamList(response.data.list);
                setTeamListManageList(response.data.list);
                setTotalRecordTeam(response.data.totalcount);
            });
        }
    }

    const DeleteTeam = async (e) => {
        SetDeleteTeamErrMsg('');

        var DeleteId = DeleteTeamId;

        try {
            const response = await axiosJWT.delete('/managing-team/delete-team/' + DeleteId + '',
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                GetTeamLists(TeamPageNo);
                handleClosesd();
            }

        } catch (error) {
            SetDeleteTeamErrMsg(error.response.data.message);
        }
    }

    const [DefaultSelectedTeamMembers, SetDefaultSelectedTeamMembers] = useState([]);
    const [DefaultValueTeam, SetDefaultValueTeam] = useState('');

    const editTeam = async (e) => {
        SetExpertErr(false);
        SetTeamNameErr(false);
        var EditId = e.target.getAttribute("data-id");
        SetUpdateTeamId(EditId);
        SetTeamButtonLabel('Update Team');
        SetTeamButtonaction('edit_team');
        var arrayObj = [];
        var DarrayObj = [];


        const response = await axiosJWT.get('/managing-team/get-team-details?id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.users.length > 0) {
                response.data.users.forEach((data, index) => {
                    var obj = {};
                    obj["value"] = data.user.id;
                    obj["label"] = data.user.email;
                    DarrayObj.push(obj);
                });
            }

            SetDefaultValueTeam(response.data.ManageTeam[0].team_name);
            setNewTeamName(response.data.ManageTeam[0].team_name);
        });

        const Listexpertresponse = await axiosJWT.get('/managing-team/get-experts?filter=no_team', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((Listexpertresponse) => {

            if (Listexpertresponse.data.Coach !== undefined) {
                if (Listexpertresponse.data.Coach.length > 0) {
                    Listexpertresponse.data.Coach.forEach((data, index) => {
                        var obj = {};
                        obj["value"] = data.id;
                        obj["label"] = data.email;
                        arrayObj.push(obj);
                    });
                }
            }
        }).then(() => {
            setExpertoptions(arrayObj);
            SetDefaultSelectedTeamMembers(DarrayObj);
            setSelectedoptions(DarrayObj);
            handleTOpen();
        });
    }

    const [TeamButtonAddLabel, SetTeamButtonLabel] = useState('Add Team')
    const [TeamButtonAddaction, SetTeamButtonaction] = useState('add_team')
    const [UpdateTeamId, SetUpdateTeamId] = useState()


    //manager functions 

    const [TeamListForManage, setTeamListManageList] = useState([]);
    const [FirstNameManager, SetFirstNameManager] = useState('')
    const [LastNameManager, SetLastNameManager] = useState('')
    const [EmailAddressManager, SetEmailAddressManager] = useState('')
    const [PhoneNoManager, SetPhoneNoManager] = useState('')
    const [TeamIdManager, SetTeamIdManager] = useState('')
    const [AddManagerLabel, SetManagerLabel] = useState('Add Manager')
    const [ActionAddManager, SetAddManagerAction] = useState('add')
    const [GetManagerLists, setManagerLists] = useState([])
    const [UpdateManagerId, SetUpdateManagerId] = useState('')
    const [EmailStatus, SetEmailstatus] = useState(false)
    const [PasswordManager, SetPasswordManager] = useState('')
    const [Address, SetAddress] = useState('')
    const [Postal, SetPostal] = useState('')


    const [ManagerFnameErr, SetManagerFnameErr] = useState(false)
    const [ManagerFnameErrMsg, SetManagerFnameErrMsg] = useState('')

    const [ManagerLnameErr, SetManagerLnameErr] = useState(false)
    const [ManagerLnameErrMsg, SetManagerLnameErrMsg] = useState('')

    const [ManagerEmailErr, SetManagerEmailErr] = useState(false)
    const [ManagerEmailErrMsg, SetManagerEmailErrMsg] = useState('')

    const [ManagerPhoneErr, SetManagerPhoneErr] = useState(false)
    const [ManagerPhoneErrMsg, SetManagerPhoneErrMsg] = useState('')

    const [ManagerTeamErr, SetManagerTeamErr] = useState(false)
    const [ManagerTeamErrMsg, SetManagerTeamErrMsg] = useState('')



    const [ManagerPassErr, SetManagerPassErr] = useState(false)
    const [ManagerPassErrMsg, SetManagerPassErrMsg] = useState('')

    const [ManagerTimezoneErr, SetManagerTimezoneErr] = useState(false)
    const [ManagerTimezoneErrMsg, SetManagerTimezoneErrMsg] = useState('')

    const [ManagerGender, SetManagerGender] = useState('')

    const AddManagerAction = async (e) => {

        var err = false;

        SetManagerFnameErr(false);
        SetManagerLnameErr(false);
        SetManagerEmailErr(false);
        SetManagerPhoneErr(false);
        SetManagerTeamErr(false);
        SetFinalErrAddManagerShow(false);
        SetManagerPassErr(false);
        SetManagerTimezoneErr(false);
        SetManagerGenderErr(false);

        var action = e.target.getAttribute("data-action");
        if (action === 'add') {

            if (FirstNameManager === '' || LastNameManager === '' || EmailAddressManager === '' || PhoneNoManager === '' || TeamIdManager === '' || PasswordManager === '' || TimeZone === '' || ManagerGender === '') {

                if (FirstNameManager === '') {
                    SetManagerFnameErrMsg('Please enter manager first name');
                    SetManagerFnameErr(true);
                    err = true;
                }
                if (LastNameManager === '') {
                    SetManagerLnameErrMsg('Please enter manager last name');
                    SetManagerLnameErr(true);
                    err = true;
                }
                if (EmailAddressManager === '') {
                    SetManagerEmailErrMsg('Please enter manager email address');
                    SetManagerEmailErr(true);
                    err = true;
                }
                if (PhoneNoManager === '') {
                    SetManagerPhoneErrMsg('Please enter manager phone number');
                    SetManagerPhoneErr(true);
                    err = true;
                }
                if (TeamIdManager === '') {
                    SetManagerTeamErrMsg('Please select team');
                    SetManagerTeamErr(true);
                    err = true;
                }
                if (PasswordManager === '') {
                    SetManagerPassErrMsg('Please enter password');
                    SetManagerPassErr(true);
                    err = true;
                }
                if (TimeZone === '') {
                    SetManagerTimezoneErrMsg('Please select timezone');
                    SetManagerTimezoneErr(true);
                    err = true;
                }
                if (ManagerGender === '') {
                    SetManagerGenderErr(true);
                    SetManagerGenderErrMsg('Please select gender');
                    err = true;
                }

                if (err === true) {
                    return false;
                }
            }
            else {

                loading_icon('show');

                if (EmailAddressManager !== '') {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EmailAddressManager) === false) {
                        SetManagerEmailErrMsg('Please enter valid email address');
                        SetManagerEmailErr(true);
                        return false;
                    }
                }

                if (PasswordManager.length < 8) {
                    SetManagerPassErrMsg('Please enter atleast minimum 8 letters');
                    SetManagerPassErr(true);
                    return false;
                }

                try {
                    const response = await axiosJWT.post('/managing-team/add-manager',
                        {
                            firstname: FirstNameManager,
                            lastname: LastNameManager,
                            email: EmailAddressManager,
                            phone: PhoneNoManager,
                            team_id: TeamIdManager,
                            password: PasswordManager,
                            user_timezone: TimeZone,
                            gender: ManagerGender,
                            address: Address,
                            postal: Postal
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                    if (response) {
                        ManagerList(1);
                        handleClose();
                        loading_icon('hide');
                    }
                } catch (error) {
                    loading_icon('hide');
                    SetFinalErrAddManagerShow(true);
                    SetFinalErrAddManagerMsg(error.response.data.message);
                }
            }
        }
        else if (action === 'edit') {

            if (FirstNameManager === '' || LastNameManager === '' || EmailAddressManager === '' || PhoneNoManager === '' || TeamIdManager === '' || ManagerGender === '') {
                if (FirstNameManager === '') {
                    SetManagerFnameErrMsg('Please enter manager first name');
                    SetManagerFnameErr(true);
                    err = true;
                }
                if (LastNameManager === '') {
                    SetManagerLnameErrMsg('Please enter manager last name');
                    SetManagerLnameErr(true);
                    err = true;
                }
                if (EmailAddressManager == '') {
                    SetManagerEmailErrMsg('Please enter manager email address');
                    SetManagerEmailErr(true);
                    err = true;
                }

                if (PhoneNoManager === '') {
                    SetManagerPhoneErrMsg('Please enter manager phone number');
                    SetManagerPhoneErr(true);
                    err = true;
                }
                if (TeamIdManager === '') {
                    SetManagerTeamErrMsg('Please select team');
                    SetManagerTeamErr(true);
                    err = true;
                }
                if (ManagerGender === '') {
                    SetManagerGenderErr(true);
                    SetManagerGenderErrMsg('Please select gender');
                    err = true;
                }

                if (err === true) {
                    return false;
                }
            }
            else {

                loading_icon('show');

                if (EmailAddressManager !== '') {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EmailAddressManager) === false) {
                        SetManagerEmailErrMsg('Please enter valid email address');
                        SetManagerEmailErr(true);
                        return false;
                    }
                    if (PasswordManager !== '') {
                        if (PasswordManager.length < 8) {
                            SetManagerPassErrMsg('Please enter atleast minimum 8 letters');
                            SetManagerPassErr(true);
                            return false;
                        }
                    }
                }

                try {
                    const response = await axiosJWT.post('/managing-team/edit-manager',
                        {
                            firstname: FirstNameManager,
                            lastname: LastNameManager,
                            email: EmailAddressManager,
                            phone: PhoneNoManager,
                            team_id: TeamIdManager,
                            manager_id: UpdateManagerId,
                            password: PasswordManager,
                            user_timezone: TimeZone,
                            gender: ManagerGender,
                            address: Address,
                            postal: Postal
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                    if (response) {
                        handleClose();
                        ManagerList(ManagerPageNo);
                        loading_icon('hide');
                    }
                } catch (error) {
                    loading_icon('hide');
                    console.log(error.response.data.message);
                }
            }
        }
    }

    const [ManagerPageNo, SetManagerPageNo] = useState(1);
    const [ManagerPerPage, SetManagerPerPage] = useState(10);
    const [TotalRecordManager, setTotalRecordManager] = useState('');


    const ManagerList = async (pageNumberM) => {

        var value = document.getElementsByClassName("search_bar_manager_team")[0].value;

        if (value !== '') {

            await axiosJWT.get('/managing-team/all-manager?offset=' + pageNumberM + '&limit=' + ManagerPerPage + '&search=' + value + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((Response) => {
                setTotalRecordManager(Response.data.total_count);
                setManagerLists(Response.data.List);
            });
        }
        else {
            await axiosJWT.get('/managing-team/all-manager?offset=' + pageNumberM + '&limit=' + ManagerPerPage + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((Response) => {
                setTotalRecordManager(Response.data.total_count);
                setManagerLists(Response.data.List);
            });
        }
    }

    const DeleteManagerTrigger = (e) => {
        var editID = e.target.getAttribute("data-id");
        SetDeleteManagerId(editID);
        SetDeleteManagerMsg('');
        Setshowsdm(true);
    }

    const DeleteManager = async (e) => {
        SetDeleteManagerMsg('');

        await axiosJWT.delete('/managing-team/delete-manager/' + DeleteManagerId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((Response) => {
            ManagerList(ManagerPageNo);
            Setshowsdm(false);
        });
    }

    const EditManager = async (e) => {

        SetManagerFnameErr(false);
        SetManagerLnameErr(false);
        SetManagerEmailErr(false);
        SetManagerPhoneErr(false);
        SetManagerTeamErr(false);
        SetManagerPassErr(false);
        SetManagerTimezoneErr(false);
        SetManagerGenderErr(false);
        SetMaleGenderChecked(false);
        SetFemaleGenderChecked(false);

        var editID = e.target.getAttribute("data-id");

        const Response = await axiosJWT.get('/managing-team/get-manager-details?id=' + editID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((Response) => {
            GetTeamLists();
            SetFirstNameManager(Response.data[0].first_name);
            SetLastNameManager(Response.data[0].last_name);
            SetEmailAddressManager(Response.data[0].email);
            SetEmailstatus(true);
            SetPhoneNoManager(Response.data[0].manager_infos[0].phone_number);
            SetManagerGender(Response.data[0].gender);
            SetAddress(Response.data[0].user_address);
            SetPostal(Response.data[0].postalCode);
            if (Response.data[0].gender === 'MALE') {
                SetMaleGenderChecked(true);
            }
            if (Response.data[0].gender === 'FEMALE') {
                SetFemaleGenderChecked(true);
            }
            setTimeZone(Response.data[0].user_timezone)
            var setteamId = '' + Response.data[0].manager_infos[0].team_assigned_to;
            SetTeamIdManager(setteamId);
            SetManagerLabel('Update Manager');
            SetAddManagerAction('edit');
            SetUpdateManagerId(editID);
            handleOpen();
        });
    }

    const changePageNumberM = async (pagenumber) => {
        SetManagerPageNo(pagenumber);
        ManagerList(pagenumber);
    }

    const changePageNumberT = async (pagenumber) => {
        SetTeamPageNo(pagenumber);
        GetTeamLists(pagenumber);
    }

    useEffect(() => {
        document.title = 'Admin-Managers & team';
        ManagerList(1);
        const timeZones = moment.tz.names();
        var timezone_list = [];
        timeZones.forEach(element => {
            timezone_list.push({ label: element, value: element });
        });
        SetTimeZoneList(timezone_list);
    }, []);


    const SearchList = async (e) => {

        var tab = e.target.getAttribute("data-tab");
        if (tab === 'team') {
            var value = e.target.value;
            Setsearchtab(tab);
            setsearchvalue(value);
            if (value === '') {
                GetTeamLists(1);
            }
            else {
                GetTeamLists(1);
            }
        }
        else if (tab === 'manager') {

            var value = e.target.value;
            Setsearchtab(tab);
            setsearchvalue(value);
            if (value === '') {
                ManagerList(1);
            }
            else {
                ManagerList(1);
            }
        }
    }

    const searchData = async (e) => {

        var searchvalue = '';
        if (searchtab === 'team') {
            searchvalue = e.target.getAttribute("data-searchvalue");

            if (searchvalue !== '') {
                setFinalSearchvalue(searchvalue);
                GetTeamLists(1);
            }
        }
        else if (searchtab === 'manager') {
            searchvalue = e.target.getAttribute("data-searchvalue");

            if (searchvalue !== '') {
                ManagerList(1);
            }
        }
    }


    const [searchtab, Setsearchtab] = useState('');
    const [searchvalue, setsearchvalue] = useState('');


    const DeleteTeamTrigger = (e) => {
        var DeleteId = e.target.getAttribute('data-id');
        SetDeleteTeamId(DeleteId);
        SetDeleteTeamErrMsg('');
        Setshowsd(true);
    }

    const getMyaccess = async (e) => {
        SetAppointmentEnabled(false);
        SetPaymentEnabled(false);
        SetExpertEnabled(false);
        SetEnquiryEnabled(false);
        SetAnalyticsEnabled(false);
        SetAppointmentEnabledEdit(false);
        SetAppointmentEnabledDelete(false);
        SetPaymentEnabledEdit(false);
        SetPaymentEnabledDelete(false);
        SetExpertEnabledEdit(false);
        SetExpertEnabledDelete(false);
        SetEnquiryEnabledEdit(false);
        SetEnquiryEnabledDelete(false);
        SetAnalyticsEnabledEdit(false);
        SetAnalyticsEnabledDelete(false);

        var Id = e.target.getAttribute('data-id');
        SetAccessEditMemberId(Id);

        const Response = await axiosJWT.get('/managing-team/get-access-details?id=' + Id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (Response.data !== null) {
            var Appointmentresult = Response.data.filter(obj => {
                return obj.page === 'appointment'
            });

            var Paymentresult = Response.data.filter(obj => {
                return obj.page === 'payment_payout'
            });

            var Expertresult = Response.data.filter(obj => {
                return obj.page === 'manage_users'
            });

            var Enquiryresult = Response.data.filter(obj => {
                return obj.page === 'manage_enquiry'
            });

            var Analyticsresult = Response.data.filter(obj => {
                return obj.page === 'analytics'
            });

            SetAppointmentEnabled(Appointmentresult[0].read);
            SetPaymentEnabled(Paymentresult[0].read);
            SetExpertEnabled(Expertresult[0].read);
            SetExpertEnabledEdit(Expertresult[0].write);
            SetExpertEnabledDelete(Expertresult[0].delete);
            SetEnquiryEnabled(Enquiryresult[0].read);
            SetEnquiryEnabledEdit(Enquiryresult[0].write);
            SetEnquiryEnabledDelete(Enquiryresult[0].delete);
            SetAnalyticsEnabled(Analyticsresult[0].read);
        }

        SetshowsdmAccess(true);
    }

    const handleChangeAccess = (e) => {

        if (e.target.value === 'appointment') {
            if (e.target.checked === false) {
                SetAppointmentEnabled(false);
            }
            if (e.target.checked === true) {
                SetAppointmentEnabled(true);
            }

        }

        if (e.target.value === 'payment') {
            if (e.target.checked === false) {
                SetPaymentEnabled(false);
            }
            if (e.target.checked === true) {
                SetPaymentEnabled(true);
            }

        }
        if (e.target.value === 'manage_users') {
            if (e.target.checked === false) {
                SetExpertEnabled(false);
            }
            if (e.target.checked === true) {
                SetExpertEnabled(true);
            }

        }
        if (e.target.value === 'manage_users_edit') {
            if (e.target.checked === false) {
                SetExpertEnabledEdit(false);
            }
            if (e.target.checked === true) {
                SetExpertEnabledEdit(true);
            }

        }
        if (e.target.value === 'manage_users_delete') {
            if (e.target.checked === false) {
                SetExpertEnabledDelete(false);
            }
            if (e.target.checked === true) {
                SetExpertEnabledDelete(true);
            }

        }
        if (e.target.value === 'manage_enquiries') {
            if (e.target.checked === false) {
                SetEnquiryEnabled(false);
            }
            if (e.target.checked === true) {
                SetEnquiryEnabled(true);
            }

        }
        if (e.target.value === 'manage_enquiries_edit') {
            if (e.target.checked === false) {
                SetEnquiryEnabledEdit(false);
            }
            if (e.target.checked === true) {
                SetEnquiryEnabledEdit(true);
            }

        }
        if (e.target.value === 'manage_enquiries_delete') {
            if (e.target.checked === false) {
                SetEnquiryEnabledDelete(false);
            }
            if (e.target.checked === true) {
                SetEnquiryEnabledDelete(true);
            }

        }
        if (e.target.value === 'manage_analytics') {
            if (e.target.checked === false) {
                SetAnalyticsEnabled(false);
            }
            if (e.target.checked === true) {
                SetAnalyticsEnabled(true);
            }

        }
    }

    const SaveAccessSetting = async () => {

        const response = await axiosJWT.post('/managing-team/save-access-details',
            {
                manager_id: AccessEditMemberId,
                appointment: AppointmentEnabled,
                payment: PaymentEnabled,
                expert: ExpertEnabled,
                enquiry: EnquiryEnabled,
                analytics: AnalyticsEnabled,
                expert_edit: ExpertEnabledEdit,
                expert_delete: ExpertEnabledDelete,
                enquiry_edit: EnquiryEnabledEdit,
                enquiry_delete: EnquiryEnabledDelete
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleClosesdmAccess();
        }
    }

    const ShowDefaultSetting = async () => {

        SetAppointmentEnabledDefault(false);
        SetPaymentEnabledDefault(false);
        SetExpertEnabledDefault(false);
        SetExpertEnabledDefaultEdit(false);
        SetExpertEnabledDefaultDelete(false);
        SetEnquiryEnabledDefault(false);
        SetEnquiryEnabledDefaultDelete(false);
        SetAnalyticsEnabledDefault(false);

        const Response = await axiosJWT.get('/managing-team/get-access-details-default', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (Response.data !== null) {
            if (Response.data.JsonData.appointment !== undefined && Response.data.JsonData.appointment !== null) {
                SetAppointmentEnabledDefault(Response.data.JsonData.appointment);
            }
            if (Response.data.JsonData.payment !== undefined && Response.data.JsonData.payment !== null) {
                SetPaymentEnabledDefault(Response.data.JsonData.payment);
            }
            if (Response.data.JsonData.expert !== undefined && Response.data.JsonData.expert !== null) {
                SetExpertEnabledDefault(Response.data.JsonData.expert);
            }
            if (Response.data.JsonData.expert_edit !== undefined && Response.data.JsonData.expert_edit !== null) {
                SetExpertEnabledDefaultEdit(Response.data.JsonData.expert_edit);
            }
            if (Response.data.JsonData.expert_delete !== undefined && Response.data.JsonData.expert_delete !== null) {
                SetExpertEnabledDefaultDelete(Response.data.JsonData.expert_delete);
            }
            if (Response.data.JsonData.enquiry !== undefined && Response.data.JsonData.enquiry !== null) {
                SetEnquiryEnabledDefault(Response.data.JsonData.enquiry);
            }
            if (Response.data.JsonData.enquiry_delete !== undefined && Response.data.JsonData.enquiry_delete !== null) {
                SetEnquiryEnabledDefaultDelete(Response.data.JsonData.enquiry_delete);
            }
            if (Response.data.JsonData.analytics !== undefined && Response.data.JsonData.analytics !== null) {
                SetAnalyticsEnabledDefault(Response.data.JsonData.analytics);
            }
        }

        SetshowsdmAccessDefault(true);
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>

            <section className="dashboard manage_team_users">
                <div className="manage_team_users_div adminmanageteam container">
                    <div className="manager_team_menubox menubox">
                        <div className="row">
                            <div className='tabmenu manager_team_tab_div top_manage_team_row_admin'>
                                <a className="active" onClick={changeUsers} data-item="manager">Manager</a>
                                <a className="" onClick={changeUsers} data-item="team">Team</a>
                            </div>
                            <div className='rightmenu manager_team_tab_div_right'>
                                <button style={{ display: 'none' }} type="button" className='search_button_manageTeam'><i className="fa fa-search" onClick={(e) => { searchData(e) }} data-searchvalue={searchvalue} data-searchtab={searchtab} ></i></button>
                                <input type="text" data-tab="manager" placeholder="Search" className='search_bar_manager_team' onChange={(e) => SearchList(e)} />
                                <button className='manager_team_button' data-tab="manager" onClick={(e) => { addData(e) }}>Add Manager</button>
                                {ShowSettingsButton === true &&
                                    <button className='manager_team_button default_access_settings_button' data-tab="manager" onClick={(e) => { ShowDefaultSetting(e) }}>Default access settings</button>}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>

                    <div className='row'>
                        <Table className='table is-striped is-fullwidth manager_table' style={Mshow}>
                            {GetManagerLists.length > 0 &&
                                <Thead className='thead '>
                                    <Tr>
                                        <Th className='row_name'>First Name</Th>
                                        <Th className='row_name'>Last Name</Th>
                                        <Th className='row_name'>Email</Th>
                                        <Th className='row_name'>Phone Number</Th>
                                        <Th className='row_name'>Team Name</Th>
                                        <Th className='row_name'>Action</Th>
                                    </Tr>
                                </Thead>
                            }

                            {GetManagerLists.length > 0 &&
                                <Tbody className='tbody' style={{ borderBottom: 'white' }}>
                                    {GetManagerLists.length > 0 && GetManagerLists.map((manager, index) => (
                                        <Tr>
                                            <Td>{manager.first_name}</Td>
                                            <Td>{manager.last_name}</Td>
                                            <Td>{manager.email}</Td>
                                            <Td>{manager.manager_infos[0].phone_number}</Td>
                                            <Td>{manager.manager_infos[0].manage_team.team_name}</Td>
                                            {/* <td>{manager.manager_infos[0].phone_number}</td> */}
                                            <Td className='last_class'><i className="fa fa-edit edit_icon pointer_eff" data-id={manager.id} onClick={(e) => { EditManager(e) }}></i>
                                                <i className="fa fa-key  pointer_eff access_sett_icon" data-id={manager.id} aria-hidden="true" onClick={getMyaccess}></i><i className="fa fa-trash pointer_eff edit_icon" data-id={manager.id} onClick={(e) => DeleteManagerTrigger(e)} ></i></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            }
                        </Table>

                        {TotalRecordManager ?
                            <div style={Mshow}>
                                <Pagination
                                    activePage={ManagerPageNo}
                                    itemsCountPerPage={ManagerPerPage}
                                    totalItemsCount={TotalRecordManager}
                                    onChange={changePageNumberM.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                            </div>
                            : null}

                    </div>
                    <div className='row'>
                        <Table className='table is-striped is-fullwidth team_table' style={Tshow}>
                            {TeamList.length > 0 &&
                                <Thead className='thead' >
                                    <Tr>
                                        <Th className=''>Team Name</Th>
                                        <Th className=''>Team Size</Th>
                                        <Th className=''>Action</Th>
                                    </Tr>
                                </Thead>
                            }
                            <Tbody className='tbody' style={{ borderBottom: 'white' }}>
                                {TeamList.length > 0 && TeamList.map((team, index) => (
                                    <Tr>
                                        <Td>{team.team_name}</Td>
                                        <Td>{team.team_size}</Td>
                                        <Td className='last_class'><i className="fa fa-edit edit_icon pointer_eff" data-id={team.id} onClick={(e) => editTeam(e)}></i><i className="fa fa-trash pointer_eff" data-id={team.id} onClick={(e) => { DeleteTeamTrigger(e) }}></i></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        {TotalRecordTeam ?
                            <div style={Tshow}>
                                <Pagination
                                    activePage={TeamPageNo}
                                    itemsCountPerPage={TeamPerPage}
                                    totalItemsCount={TotalRecordTeam}
                                    onChange={changePageNumberT.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                            </div>
                            : null}
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} className='commission_popup add_manager_popup'>
                    <Modal.Header closeButton>
                        <Modal.Title>{FirstNameManager ? "Edit Manager" : "Add Manager"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>First Name</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' defaultValue={FirstNameManager} onChange={(e) => { SetFirstNameManager(e.target.value) }} {...(ManagerFnameErr === true ? { id: 'err_border' } : {})} ></input>
                            </div>
                        </div>
                        {ManagerFnameErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerFnameErrMsg}
                            </div>
                        }
                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Last Name</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' defaultValue={LastNameManager} onChange={(e) => { SetLastNameManager(e.target.value) }} {...(ManagerLnameErr === true ? { id: 'err_border' } : {})}></input>
                            </div>
                        </div>

                        {ManagerLnameErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerLnameErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Email address</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' disabled={EmailStatus} defaultValue={EmailAddressManager} onChange={(e) => { SetEmailAddressManager(e.target.value) }} {...(ManagerEmailErr === true ? { id: 'err_border' } : {})} ></input>
                            </div>
                        </div>

                        {ManagerEmailErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerEmailErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Gender:</label>
                                <div>
                                    <input type="radio" value="MALE" name="gender" onChange={(e) => { SetManagerGender(e.target.value) }} defaultChecked={MaleGenderChecked} /> <span className='gender_manager_span'>Male</span>
                                    <input type="radio" value="FEMALE" name="gender" onChange={(e) => { SetManagerGender(e.target.value) }} defaultChecked={FemaleGenderChecked} /> <span>Female</span>
                                </div>
                            </div>
                            {ManagerGenderErr === true &&
                                <div className='row err promotion_err_name gender_manager_err'>
                                    {ManagerGenderErrMsg}
                                </div>
                            }
                        </div>

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Password</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' onChange={(e) => { SetPasswordManager(e.target.value) }} {...(ManagerPassErr === true ? { id: 'err_border' } : {})}></input>
                            </div>
                        </div>
                        {ManagerPassErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerPassErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Phone Number</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='number' defaultValue={PhoneNoManager} onChange={(e) => { SetPhoneNoManager(e.target.value) }} {...(ManagerPhoneErr === true ? { id: 'err_border' } : {})}></input>
                            </div>
                        </div>

                        {ManagerPhoneErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerPhoneErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Select Team</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ fontWeight: '600' }} defaultValue={TeamIdManager} onChange={(e) => { SetTeamIdManager(e.target.value) }} {...(ManagerTeamErr === true ? { id: 'err_border' } : {})} >
                                    <option value={''}>{'Select Team'}</option>

                                    {TeamListForManage.length > 0 && TeamListForManage.map((team, index) => (
                                        <option value={team.id}>{team.team_name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>

                        {ManagerTeamErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerTeamErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className=''>Address</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' defaultValue={Address} onChange={(e) => { SetAddress(e.target.value) }} ></input>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className=''>Postal Code</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' defaultValue={Postal} onChange={(e) => { SetPostal(e.target.value) }}></input>
                            </div>
                        </div>

                        <div className='row'>
                            <label className='commission_inner_popup_label'>Manager timezone<span style={{ color: 'red' }}>*</span></label><br />
                            <Select
                                defaultValue={{ value: TimeZone, label: TimeZone }}
                                options={TImeZoneList}
                                onChange={(e) => { setTimeZone(e.value) }}
                                className="clientadd_timezone"
                                {...(ManagerTimezoneErr === true ? { id: 'err_border' } : {})}
                            />
                        </div>
                        {ManagerTimezoneErr === true &&
                            <div className='row err promotion_err_name'>
                                {ManagerTimezoneErrMsg}
                            </div>
                        }

                        {(FinalErrAddManagerShow === true) ? <div className='row err_row'>
                            <div className=''>
                                <label style={{ color: 'red' }}>{FinalErrAddManagerMsg}</label>
                            </div>
                        </div> : null}
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" className='close_button_commission' onClick={handleClose}>
                            Back
                        </Button>
                        <Button variant="primary" className='save_button_commission float_right' data-update={UpdateManagerId}
                            data-action={ActionAddManager} onClick={(e) => { AddManagerAction(e) }}>
                            {AddManagerLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={PopupTshow} onHide={handleTClose} className='commission_popup add_manager_popup add_team_popup'>
                    <Modal.Header closeButton>
                        <Modal.Title>{TeamButtonAddLabel === 'Add Team' ? " Add Team " : " Update Team "}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className='required'>Team Name</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <input type='text' defaultValue={DefaultValueTeam} onChange={(e) => { setNewTeamName(e.target.value) }} {...(TeamNameErr === true ? { id: 'err_border' } : {})}></input>
                            </div>
                        </div>

                        {TeamNameErr === true &&
                            <div className='row err promotion_err_name'>
                                {TeamNameErrMsg}
                            </div>
                        }

                        <div className='row'>
                            <div className='label_add_manager_div'>
                                <label className=''>Select Expert</label>
                            </div>
                            <div className='input_add_manager_div'>
                                <Select options={expertoptions} isMulti onChange={(e) => { DropdownChange(e) }} defaultValue={DefaultSelectedTeamMembers}  {...(ExpertErr === true ? { id: 'err_border' } : {})} />
                            </div>
                        </div>

                        {ExpertErr === true &&
                            <div className='row err promotion_err_name'>
                                {ExpertErrMsg}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" className='close_button_commission' onClick={handleTClose}>
                            Back
                        </Button>
                        <Button variant="primary" className='save_button_commission float_right' data-update={UpdateTeamId} onClick={(e) => { add_team_final(e) }} data-action={TeamButtonAddaction}>
                            {TeamButtonAddLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showsd} onHide={handleClosesd} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Team</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Are you sure want to delete this team?</span>
                        <span className='err flex'>{DeleteTeamErrMsg}</span>
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" onClick={handleClosesd}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { DeleteTeam() }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showsdm} onHide={handleClosesdm} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Manager account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Are you sure want to delete this manager account?</span>
                        <span className='err flex'>{DeleteManagerMsg}</span>
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" onClick={handleClosesdm}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { DeleteManager() }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showsdmAccess} onHide={handleClosesdmAccess} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Manager Access settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='checkbox_div_manager_access'>
                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Appointments</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} defaultChecked={AppointmentEnabled} value='appointment' onChange={handleChangeAccess} />
                                    <label>View</label>
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Payments {'&'} Payout</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='payment' defaultChecked={PaymentEnabled} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>View</label>
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Manage experts</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_users' defaultChecked={ExpertEnabled} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>View</label>

                                    <input type={'checkbox'} value='manage_users_edit' defaultChecked={ExpertEnabledEdit} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>Edit</label>

                                    <input type={'checkbox'} value='manage_users_delete' defaultChecked={ExpertEnabledDelete} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>Delete</label>
                                </div>
                            </div>
                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>contact enquiry</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_enquiries' defaultChecked={EnquiryEnabled} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>View</label>

                                    {/* <input type={'checkbox'} value='manage_enquiries_edit' defaultChecked={EnquiryEnabledEdit} onChange={(e)=>{handleChangeAccess(e)}} />
                        <label>Edit</label> */}

                                    <input type={'checkbox'} value='manage_enquiries_delete' defaultChecked={EnquiryEnabledDelete} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>Delete</label>
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Analytics</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_analytics' defaultChecked={AnalyticsEnabled} onChange={(e) => { handleChangeAccess(e) }} />
                                    <label>View</label>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" onClick={handleClosesdmAccess}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="bootstrp_color_btn float_right" onClick={SaveAccessSetting}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showsdmAccessDefault} onHide={handleClosesdmAccessDefault} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Default Manager Access settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='checkbox_div_manager_access'>
                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Appointments</label>
                                </div>

                                <div className='col_2'>
                                    <input type={'checkbox'} defaultChecked={AppointmentEnabledDefault} value='appointment' onChange={handleChangeAccessDefault} />
                                    <label>View</label>
                                    {/* <input type={'checkbox'} defaultChecked={AppointmentEnabledDefaultEdit} value='appointment_edit' onChange={handleChangeAccessDefault} />
                    <label>Edit</label>
                    <input type={'checkbox'} defaultChecked={AppointmentEnabledDefaultDelete} value='appointment_delete' onChange={handleChangeAccessDefault} />
                    <label>Delete</label> */}
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Payments {'&'} Payout</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='payment' defaultChecked={PaymentEnabledDefault} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>View</label>

                                    {/* <input type={'checkbox'} value='payment_edit' defaultChecked={PaymentEnabledDefaultEdit} onChange={(e)=>{handleChangeAccessDefault(e)}} />
                    <label>Edit</label>

                    <input type={'checkbox'} value='payment_delete' defaultChecked={PaymentEnabledDefaultDelete} onChange={(e)=>{handleChangeAccessDefault(e)}} />
                    <label>Delete</label> */}
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Manage experts</label>
                                </div>

                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_users' defaultChecked={ExpertEnabledDefault} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>View</label>

                                    <input type={'checkbox'} value='manage_users_edit' defaultChecked={ExpertEnabledDefaultEdit} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>Edit</label>

                                    <input type={'checkbox'} value='manage_users_delete' defaultChecked={ExpertEnabledDefaultDelete} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>Delete</label>
                                </div>
                            </div>
                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>contact enquiry</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_enquiries' defaultChecked={EnquiryEnabledDefault} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>View</label>

                                    {/* <input type={'checkbox'} value='manage_enquiries_edit' defaultChecked={EnquiryEnabledDefaultEdit} onChange={(e)=>{handleChangeAccessDefault(e)}} />
                        <label>Edit</label> */}

                                    <input type={'checkbox'} value='manage_enquiries_delete' defaultChecked={EnquiryEnabledDefaultDelete} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>Delete</label>
                                </div>
                            </div>

                            <div className='row row_checkbox_div'>
                                <div className='col_1'>
                                    <label>Analytics</label>
                                </div>
                                <div className='col_2'>
                                    <input type={'checkbox'} value='manage_analytics' defaultChecked={AnalyticsEnabledDefault} onChange={(e) => { handleChangeAccessDefault(e) }} />
                                    <label>View</label>

                                    {/* <input type={'checkbox'} value='manage_analytics_edit' defaultChecked={AnalyticsEnabledDefaultEdit} onChange={(e)=>{handleChangeAccessDefault(e)}} />
                        <label>Edit</label>

                        <input type={'checkbox'} value='manage_analytics_delete' defaultChecked={AnalyticsEnabledDefaultDelete} onChange={(e)=>{handleChangeAccessDefault(e)}} />
                        <label>Delete</label> */}
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" onClick={handleClosesdmAccessDefault}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="bootstrp_color_btn float_right" onClick={SaveAccessSettingDefault}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>



            </section>
        </div>
    )
}

export default ManageTeam
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Commission from './AdminBilling/commission'
import ManagePlan from './AdminBilling/manageplan'
import Subscriber from './AdminBilling/subscriber'
import AdminPaymentHistory from './AdminBilling/PaymentHistory'
import ClientPlan from './AdminBilling/ClientPlans'
import RefundHistory from './AdminBilling/RefundHistory'

const AdminBilling = () => {

  const side_nav = localStorage.getItem("side_nav");
  const [Tabactive, setTabActive] = useState('tab_1')



  const ChangeTab = (e) => {

    var tabLink = document.querySelectorAll(".tab_show")

    tabLink.forEach(box => {

      box.classList.remove('active');
    });
    e.target.classList.add('active');
    var array = e.target.classList
    if (array.value.includes("tab_1")) {
      setTabActive('tab_1')
    }
    if (array.value.includes("tab_2")) {
      setTabActive('tab_2')
    }
    if (array.value.includes("tab_3")) {
      setTabActive('tab_3')
    }
    if (array.value.includes("tab_4")) {
      setTabActive('tab_4')
    }
    if (array.value.includes("tab_5")) {
      setTabActive('tab_5')
    }
    if (array.value.includes("tab_6")) {
      setTabActive('tab_6')
    }


  }
  useEffect(()=>{
    document.title = 'Admin-Billing';
  },[])

  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className='dashboard'>
        <div className='adminbilling container'>
        <div className="row admin_billing_top_label">
          <span className="pointer_eff tab_show active tab_1" onClick={ChangeTab}>Commissions</span>
          <span className="pointer_eff tab_show tab_2" onClick={ChangeTab}>Manage Coach Plan</span>
          <span className="pointer_eff tab_show tab_5" onClick={ChangeTab}>Manage Client Plan</span>
          <span className="pointer_eff tab_show tab_3" onClick={ChangeTab}>Subscribers</span>
          <span className="pointer_eff tab_show tab_4" onClick={ChangeTab}>Payment History</span>
          <span className="pointer_eff tab_show tab_6" onClick={ChangeTab}>Refund History</span>
        </div>


        {
          (Tabactive === 'tab_1')
            ? <Commission />
            : null
        }

        {
          (Tabactive === 'tab_2')
            ? <ManagePlan />
            : null
        }

        {
          (Tabactive === 'tab_3')
            ? <Subscriber />
            : null
        }
        {
          Tabactive==='tab_4'
          ?<AdminPaymentHistory />:null
        }
        {
               Tabactive==='tab_5'
               ?<ClientPlan />:null
          
        }
         {
               Tabactive==='tab_6'
               ?<RefundHistory />:null
          
        }
        </div>
      </section>
    </div>
  )
}

export default AdminBilling
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import female_icon from '../../images/female.png'
import male_icon from '../../images/male.png'
import { format, parseISO } from 'date-fns'
import Progress from './Users/Progress'
import Notes from './Users/Notes'
import Mealplan from './Users/Mealplan';
import ClientAppointment from './Users/Appointment';
import ConnectedDevices from './Users/Connecteddevices';
import LabTest from './Users/Labtest';
import ConnectedClientMessages from './Users/ConnectedClientMessages';
import ClientQuiz from './Users/ClientQuiz';
import AddData from '../ClientComponents/AddData/AddData';
import CoachFullscript from './Users/Fullscript';
import SetGoalPopupComp from './Users/SetGoal';
import ClientBelugaHealth from '../AdminComponents/AdminClientDashboard/ClientBelugaHealth';

const CoachUsers = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");

    var [users, setUsers] = useState([]);
    const [profileName, setprofileName] = useState('');
    const [profileAge, setprofileAge] = useState('');
    const [profileData, setprofileData] = useState('');
    const [profileTab, setprofileTab] = useState('progress');
    const [issidenav, setissidenav] = useState(false);
    const [AddDataClientId,SetAddDataClientId]=useState('');
    const [ClientProfileData,SetClientProfileData]=useState([])
    const [ClientCustomCategoryList,SetClientCustomCategoryList]=useState([])

    useEffect(() => {
        document.title = 'Coach-Users';
        getUsers();
    }, []);

    const axiosJWT = axios.create();

    const getUsers = async () => {
    
        const response = await axiosJWT.get("/allclient", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setUsers(response.data.Users);

        load_profile(response.data.Users[0].id);
    }


    const changeTabs = async (e) => {

        const list_for = e.target.getAttribute("data-item");

        /* const alltabs = document.querySelectorAll(".tabmenu a");
         alltabs.forEach((element) => element.classList.remove('active'));
 
         e.target.setAttribute("class","active"); */

        setprofileTab(list_for);
    }

    const searchUsers = async (event) => {

        const response = await axiosJWT.get("/allclient?search=" + event, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.data.UsersCount > 0 )
        setUsers(response.data.Users);
    }

    const load_profile = async (userId) => {
 
        SetAddDataClientId(userId)

        setprofileTab('');

        const response = await axiosJWT.get("/user/" + userId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetClientProfileData(response.data)
            setprofileData(response.data);

            const allusers = document.querySelectorAll(".users_list .user_data");
            allusers.forEach((element) => element.classList.remove('active'));

            document.getElementById("user_" + userId).setAttribute("class", "user_data active");

            setprofileTab('progress');
        });
       

        const CustomCategoryListResponse = await axiosJWT.get('/add-data/get-custom-category?client_id='+userId+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (CustomCategoryListResponse) {
         
            SetClientCustomCategoryList(CustomCategoryListResponse.data.cat_list)
        }
    }


    const userNav = () => {

        if (issidenav === true) {
            document.getElementById("user-fa-icon").setAttribute("class", "fa fa-chevron-circle-left slide_icon");
            document.getElementById("users_list").setAttribute("class", "users_list show");
        }

        if (issidenav === false) {
            document.getElementById("user-fa-icon").setAttribute("class", "fa fa-chevron-circle-right slide_icon");
            document.getElementById("users_list").setAttribute("class", "users_list hide");
        }

        setissidenav(!issidenav);
    }

    const TriggerAddDataPopup=()=>{
        SetshowAddData(true);
    }
 
    const [showAddData,SetshowAddData]=useState(false);


    //set goal

    const [GoalPopup,SetGoalPopup]=useState(false)
  
    const TriggerSetGoalPopup=()=>{
        SetGoalPopup(true)
    }

    //set goal end

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">

               {users.length>0 &&
                <div className="coach_users container">
                    <div className='title_box'>
                        <div className='leftmenu'>
                            <h4>Users</h4><br></br>
                        </div>
                        <div className='rightmenu'>
                            <input type="text" placeholder="&#xF002; Search Users" onChange={event => searchUsers(event.target.value)} />
                        </div>
                    </div>
                    <div style={{ "clear": "both" }} />

                    <div className='section_box'>
                        <div id='leftblock' className={issidenav ? 'leftblock shrink' : 'leftblock enlarge'}>
                            <div className='user_header'>
                                <div className='p_title color_grey'>All Users</div>
                                <i style={{ "cursor": "pointer" }} id="user-fa-icon" className="fa fa-chevron-circle-left slide_icon" aria-hidden="true" onClick={userNav}></i>
                            </div>

                            <div style={{ "clear": "both" }} />
                            <div id='users_list' className='users_list'>
                                {users.map((user, index) => (
                                    <div id={"user_" + user.id} className='user_data' key={user.id} onClick={() => { load_profile(user.id) }}>
                                        <div className="profile_photo">
                                            <img className='table_profile_img' src={user.user_profile_photo ?  user.user_profile_photo : (user.gender === 'MALE' ? male_icon : female_icon)} />
                                            {
                                                (user.logged_in_status === '1')
                                                ? <span className='c-avatar__status'></span>
                                                : null
                                            }
                                        </div>
                                        <div className='profile_info'>
                                            <span className='name'>{user.first_name} {user.last_name} </span><br />
                                            <span className='name'> {
                                            (user.is_glp_user===true) ? '(GLP eligible)' : ''
                                        }</span><br />
                                            {user.user_dob && user.user_dob !== '' && user.user_dob !== '0000-00-00' ?
                                                <span className='color_grey'>{format(parseISO(user.user_dob), 'MM-dd-yyyy')}</span>
                                                : null}
                                        </div>
                                        <div className='profile_program'>
                                            {user.coach_client_relations[0].service_category ?
                                                <div className={(user.coach_client_relations[0].category_id % 10) === 1 ? "category_box purplebox" : (user.coach_client_relations[0].category_id % 10) === 2 ? "category_box orangebox" : (user.coach_client_relations[0].category_id % 10) === 3 ? "category_box greenbox" : (user.coach_client_relations[0].category_id % 10) === 4 ? "category_box bluebox" : (user.coach_client_relations[0].category_id % 10) === 5 ? "category_box brownbox" : (user.coach_client_relations[0].category_id % 10) === 6 ? "category_box pinkbox" : (user.coach_client_relations[0].category_id % 10) === 7 ? "category_box graybox" : (user.coach_client_relations[0].category_id % 10) === 8 ? "category_box maroonbox" : (user.coach_client_relations[0].category_id % 10) === 9 ? "category_box violetbox" : (user.coach_client_relations[0].category_id % 10) === 4 ? "category_box yellowbox" : null}> {user.coach_client_relations[0].service_category.category_name}
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        {profileData ?
                            <div id='rightblock' className={issidenav ? 'rightblock shrink' : 'rightblock enlarge'}>
                                <div className='topcontent'>
                                    <div className='user_info' data-item={profileData.id}>
                                        <div className='header_box'>
                                            <div className='menubox'>
                                                <div className='leftmenu'>
                                                    <div className="profile_photo">
                                                        <img className='table_profile_img' src={profileData.user_profile_photo ?  profileData.user_profile_photo : (profileData.gender === 'MALE' ? male_icon : female_icon)} />
                                                    </div>
                                                    <div className='profile_info'>
                                                        <span className='name'>{profileData.first_name} {profileData.last_name} </span><br />
                                                        <span className='color_grey' style={{ "fontSize": "12px" }}>Last accessed: {format(parseISO(profileData.updatedAt), `PP p`)}</span>
                                                        <button className='add_data_coach' onClick={TriggerAddDataPopup}>Add Data</button>
                                                        <button className='add_data_coach set_goal_button' onClick={TriggerSetGoalPopup}>Set Goal</button>
                                                        
                                                    </div>
                                                 
                                                </div>
                                                <div className='rightmenu'>

                                                </div>
                                            </div>
                                            <div style={{ "clear": "both" }} />
                                            <div className='input_fields'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <label className='inner_label'>Age</label><br />
                                                        {profileData.user_dob && profileData.user_dob != "0000-00-00" ?
                                                        <div className='input_value'>{(format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy'))} Years</div> : null}
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Height</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Weight</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Target Weight</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='input_fields'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <label className='inner_label'>Activity Level</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].activity}</div>
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Water Intake</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Daily Calories</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                                                    </div>
                                                    <div className='col'>
                                                        <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                                        <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_carb}-{profileData.client_infos[0].daily_fat}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='tabmenu'>
                                        <a className={profileTab === 'progress' ? "active" : null} onClick={changeTabs} data-item="progress">Progress</a>
                                        <a className={profileTab === 'mealplan' ? "active" : null} onClick={changeTabs} data-item="mealplan">Meal Plan</a>
                                        <a className={profileTab === 'notes' ? "active" : null} onClick={changeTabs} data-item="notes">Notes</a>
                                        <a className={profileTab === 'appointments' ? "active" : null} onClick={changeTabs} data-item="appointments">Appointments</a>
                                        <a className={profileTab === 'labtest' ? "active" : null} onClick={changeTabs} data-item="labtest">Lab Test</a>
                                        <a className={profileTab === 'connected' ? "active" : null} onClick={changeTabs} data-item="connected">Connected Devices</a>
                                        <a className={profileTab === 'messages' ? "active" : null} onClick={changeTabs} data-item="messages">Messages</a>
                                        <a className={profileTab === 'quiz' ? "active" : null} onClick={changeTabs} data-item="quiz">Quiz</a>
                                        <a className={profileTab === 'fullscript' ? "active" : null} onClick={changeTabs} data-item="fullscript">Fullscript</a>

                                        {
                                            (profileData.is_glp_user===true || profileData.is_glp_user==='true') ?    <a className={profileTab === 'belugahealth' ? "active" : null} onClick={changeTabs} data-item="belugahealth">Beluga health</a> : null
                                        } 
                                     
                                    </div>

                                    <div style={{ "clear": "both" }} />
                                </div>
                                <div className='tabcontent'>
                                    {profileTab === 'progress' ? <Progress data={profileData.id} /> : null}
                                    {profileTab === 'mealplan' ? <Mealplan data={profileData.id} /> : null}
                                    {profileTab === 'notes' ? <Notes data={profileData.id} /> : null}
                                    {profileTab === 'appointments' ? <ClientAppointment data={profileData.id} /> : null}
                                    {profileTab === 'labtest' ? <LabTest data={profileData.id} /> : null}
                                    {profileTab === 'connected' ? <ConnectedDevices data={profileData.id} /> : null}
                                    {profileTab === 'messages' ? <ConnectedClientMessages data={profileData.id} /> : null}
                                    {profileTab === 'quiz' ? <ClientQuiz data={profileData.id} /> : null}
                                    {profileTab === 'fullscript' ? <CoachFullscript data={profileData.id} /> : null}
                                    {profileTab === 'belugahealth' ? <ClientBelugaHealth data={profileData.id} /> : null}
                                </div>
                            </div>
                            : null}
                    </div>
                    <div style={{ clear: 'both' }}></div>                    
                </div>}
                {
                   users.length===0 ?<div className='coach_user_msg'>You dont have any subscribed clients yet!</div> :null
                }
                    
                <AddData open={showAddData} SetshowAddData={SetshowAddData} client_id={AddDataClientId} ClientProfileData={ClientProfileData} ClientCustomCategoryList={ClientCustomCategoryList} />      

                <SetGoalPopupComp GoalPopup={GoalPopup} SetGoalPopup={SetGoalPopup} client_id={AddDataClientId} ClientProfileData={ClientProfileData} load_profile={load_profile}/>          
            </section>
        </div>
    )
}

export default CoachUsers
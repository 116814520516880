import React, { useEffect, useState } from "react";

const GLPDoseListCM = (props) => {
  const [GlpDoseList, SetGlpDoseList] = useState("");

  const handleOptionChange = (e) => {
    SetGlpDoseList(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.GlpDoseListPC) {
      SetGlpDoseList(props.GlpDoseListPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetGlpDoseListPC(GlpDoseList);

      var Err = 0;
      if (GlpDoseList === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GlpDoseList]);

  useEffect(()=>{
props.SetNextMenu('glpdosepref')

if(props.SideEffectPC==='No')
{
    props.SetPreviousMenu('sideeffect') 
}
if(props.SideEffectPC==='Yes')
{
    props.SetPreviousMenu('sideeffectdesc')
}



  },[])

  return (
    <div className="daily_activity" id="glpdoselist">
      <div className="head_row"></div>

      <div>
          <label>
            <h6>{props.GlpDoseListPCLabel}</h6>
          </label>
        </div>
        <div>
          <label>
            Tirzepatide(Mounjaro,Zepbound); Semaglutide(Ozempic,Wegovy)
          </label>
        </div>
      <div className="input_row">
      
       
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide 0.25mg"
            checked={GlpDoseList === "Semaglutide 0.25mg"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide 0.25mg</label>
        </div>
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide 0.5mg"
            checked={GlpDoseList === "Semaglutide 0.5mg"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide 0.5mg</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide 1mg"
            checked={GlpDoseList === "Semaglutide 1mg"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide 1mg</label>
        </div>


        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide 1.7mg"
            checked={GlpDoseList === "Semaglutide 1.7mg"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide 1.7mg</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide 2.4mg"
            checked={GlpDoseList === "Semaglutide 2.4mg"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide 2.4mg</label>
        </div>

        
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Tirzepatide 2.5mg"
            checked={GlpDoseList === "Tirzepatide 2.5mg"}
            onChange={handleOptionChange}
          />
          <label>Tirzepatide 2.5mg</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Tirzepatide 5mg"
            checked={GlpDoseList === "Tirzepatide 5mg"}
            onChange={handleOptionChange}
          />
          <label>Tirzepatide 5mg</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Tirzepatide 7.5mg"
            checked={GlpDoseList === "Tirzepatide 7.5mg"}
            onChange={handleOptionChange}
          />
          <label>Tirzepatide 7.5mg</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Tirzepatide 10mg"
            checked={GlpDoseList === "Tirzepatide 10mg"}
            onChange={handleOptionChange}
          />
          <label>Tirzepatide 10mg</label>
        </div>


      </div>
    </div>
  );
};
export default GLPDoseListCM;

import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Datepicker from 'react-date-picker';
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Promocode = () => {

    const token = localStorage.getItem("token");
    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [modalPopup, setModal] = useState();

    var [promotionId, setPromotionId] = useState();
    var [promotionApplicable, setPromotionApplicable] = useState();
    var [promotionType, setPromotionType] = useState();
    var [promotionName, setPromotionName] = useState();
    var [promotionCode, setPromotionCode] = useState();
    var [promotionDiscount, setPromotionDiscount] = useState();
    var [promotionDescription, setPromotionDescription] = useState();
    var [promotionSDate, onSDateChange] = useState(new Date());
    var [promotionEDate, onEDateChange] = useState(new Date());
    var [promotionMaxOrder, setPromotionMaxOrder] = useState();
    var [promotionMaxDiscountAmount, setPromotionMaxDiscountAmount] = useState();
    var [promotionMinOrderAmount, setPromotionMinOrderAmount] = useState();
    var [promotionApplicableMode, setPromotionApplicableMode] = useState();
    var [promotionMultipleUse, setPromotionMultipleUse] = useState();
    var [promotionValidFor, setPromotionValidFor] = useState();
    var [promotionisActive, setPromotionActive] = useState(0);

    var [promotions, setPromotions] = useState([]);
    const [show, setShow] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);

    const [editPopup, seteditPopup] = useState(false);

    const [PromoNameErr, SetPromoNameErr] = useState(false)
    const [PromoNameErrMsg, SetPromoNameErrMsg] = useState('')

    const [PromoapplyonErr, SetPromoapplyonErr] = useState(false)
    const [PromoapplyonErrMSg, SetPromoapplyonErrMSg] = useState('')

    const [PromoTypeErr, SetPromoTypeErr] = useState(false)
    const [PromoTypeErrMsg, SetPromoTypeErrMsg] = useState('')

    const [PromoCodeErr, SetPromoCodeErr] = useState(false)
    const [PromoCodeErrMsg, SetPromoCodeErrMsg] = useState('')

    const [PromoDiscountErr, SetPromoDiscountErr] = useState(false)
    const [PromoDiscountErrMsg, SetPromoDiscountErrMsg] = useState('')

    const [PromoDescriptionErr, SetPromoDescriptionErr] = useState(false)
    const [PromoDescriptionErrMsg, SetPromoDescriptionErrMsg] = useState('')

    const [PromoValidityStartErr, SetPromoValidityStartErr] = useState(false)
    const [PromoValidityStartErrMsg, SetPromoValidityStartErrMsg] = useState('')

    const [PromoValidityEndErr, SetPromoValidityEndErr] = useState(false)
    const [PromoValidityEndErrMsg, SetPromoValidityEndErrMsg] = useState('')

    const [PromoAllowMultipleErr, SetPromoAllowMultipleErr] = useState(false)
    const [PromoAllowMultipleErrMsg, SetPromoAllowMultipleErrMsg] = useState('')

    const [PromoAllowOrderErr, SetPromoAllowOrderErr] = useState(false)
    const [PromoAllowOrderErrMsg, SetPromoAllowOrderErrMsg] = useState('')

    const [PromoMinOrderErr, SetPromoMinOrderErr] = useState(false)
    const [PromoMinOrderErrMsg, SetPromoMinOrderErrMsg] = useState('')

    const [PromoMaxdiscountamountErr, SetPromoMaxdiscountamountErr] = useState(false)
    const [PromoMaxdiscountamountErrMsg, SetPromoMaxdiscountamountErrMsg] = useState('')

    const [PromoModeErr, SetPromoModeErr] = useState(false)
    const [PromoModeErrMsg, SetPromoModeErrMsg] = useState('')

    const [PromovalidErr, SetPromovalidErr] = useState(false)
    const [PromovalidErrMsg, SetPromovalidErrMsg] = useState('')





    useEffect(() => {
        getPromocodes();
    }, []);


    const axiosJWT = axios.create();

    const handleClose = () => { setShow(false); seteditPopup(false); }

    const handleDeleteClose = () => {
        setPromotionId();
        setPromotionName();
        setPromotionCode();
        setDeletePopup(false);
    }

    const handleShow = async (e) => {

        SetPromoNameErr(false)
        SetPromoapplyonErr(false)
        SetPromoTypeErr(false)
        SetPromoCodeErr(false)
        SetPromoDiscountErr(false)
        SetPromoDescriptionErr(false)
        SetPromoValidityStartErr(false)
        SetPromoValidityEndErr(false)
        SetPromoAllowMultipleErr(false)
        SetPromoAllowOrderErr(false)
        SetPromoMinOrderErr(false)
        SetPromoMaxdiscountamountErr(false)
        SetPromoModeErr(false)
        SetPromovalidErr(false)
        const promoId = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");

        setPromotionId(promoId);
        setModal(datamodal);

        if (datamodal === 'promo_add') {
            setPromotionName();
            setPromotionCode();
            setPromotionDescription();
            setPromotionDiscount();
            setPromotionType();
            setPromotionApplicable();
            onSDateChange(new Date());
            onEDateChange(new Date());
            setPromotionMaxOrder();
            setPromotionMaxDiscountAmount();
            setPromotionMinOrderAmount();
            setPromotionApplicableMode();
            setPromotionMultipleUse();
            setPromotionValidFor();
            setPromotionId();
            setShow(true);
        }
        if (datamodal === 'promo_edit') {

            const response = await axiosJWT.get("/marketing/promotion/" + promoId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

           

            setPromotionName(response.data.promotions.name);
            setPromotionCode(response.data.promotions.code);
            setPromotionDescription(response.data.promotions.description);
            setPromotionDiscount(response.data.promotions.value);
            setPromotionType(response.data.promotions.type);
            setPromotionApplicable(response.data.promotions.applicable);
            onSDateChange(new Date(response.data.promotions.validity_sdate));
            onEDateChange(new Date(response.data.promotions.validity_edate));
            setPromotionMaxOrder(response.data.promotions.max_allowed);
            setPromotionMaxDiscountAmount(response.data.promotions.max_discount_amount);
            setPromotionMinOrderAmount(response.data.promotions.min_order_amount);
            setPromotionApplicableMode(response.data.promotions.mode);
            setPromotionMultipleUse(response.data.promotions.isAllow_MultiTimes);
            setPromotionValidFor(response.data.promotions.validfor);
            setPromotionActive(response.data.promotions.isActive)
            setShow(true);
            seteditPopup(true);
        }

        if (datamodal === 'delete_confirm') {
            const response = await axiosJWT.get("/marketing/promotion/" + promoId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        
            setPromotionName(response.data.promotions.name);
            setPromotionCode(response.data.promotions.code);
       

            setDeletePopup(true);
        }
    }



    const getPromocodes = async (pagenumber) => {


        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/marketing/promotion?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setTotalCount(response.data.totalcount);

        setPromotions(response.data.promotions);
    }

    const createPromotion = async () => {



        SetPromoNameErr(false)
        SetPromoapplyonErr(false)
        SetPromoTypeErr(false)
        SetPromoCodeErr(false)
        SetPromoDiscountErr(false)
        SetPromoDescriptionErr(false)
        SetPromoValidityStartErr(false)
        SetPromoValidityEndErr(false)
        SetPromoAllowMultipleErr(false)
        SetPromoAllowOrderErr(false)
        SetPromoMinOrderErr(false)
        SetPromoMaxdiscountamountErr(false)
        SetPromoModeErr(false)
        SetPromovalidErr(false)

        var err = false;

        if (promotionName === undefined || promotionName === '') {
            SetPromoNameErrMsg('Please enter promotion name')
            SetPromoNameErr(true)
            err = true;

        }

        if (promotionApplicable === undefined || promotionApplicable === '') {
            SetPromoapplyonErrMSg('Please select apply promotion on')
            SetPromoapplyonErr(true)
            err = true;
        }
    

        if (promotionType === undefined || promotionType === '') {
            SetPromoTypeErrMsg('Please select  promotion type')
            SetPromoTypeErr(true)
            err = true;
        }

        if (promotionCode === undefined || promotionCode === '') {
            SetPromoCodeErrMsg('Please enter promo code')
            SetPromoCodeErr(true)
            err = true;
        }

        if (promotionDiscount === undefined || promotionDiscount === '') {
            SetPromoDiscountErrMsg('Please enter promo discount')
            SetPromoDiscountErr(true)
            err = true;
        }

        if (promotionDescription === undefined || promotionDescription === '') {
            SetPromoDescriptionErrMsg('Please enter promotion description')
            SetPromoDescriptionErr(true)
            err = true;
        }

        if (promotionSDate === undefined || promotionSDate === '') {
            SetPromoValidityStartErrMsg('Please select validity start date')
            SetPromoValidityStartErr(true)
            err = true;
        }

        if (promotionEDate === undefined || promotionEDate === '') {
            SetPromoValidityEndErrMsg('Please select validity end date')
            SetPromoValidityEndErr(true)
            err = true;
        }

        if (promotionMultipleUse === undefined || promotionMultipleUse === '') {
            SetPromoAllowMultipleErrMsg('Please select this field')
            SetPromoAllowMultipleErr(true)
            err = true;
        }

        if (promotionMaxOrder === undefined || promotionMaxOrder === '') {
            SetPromoAllowOrderErrMsg('Please enter max no of order allowed')
            SetPromoAllowOrderErr(true)
            err = true;
        }

        if (promotionMinOrderAmount === undefined || promotionMinOrderAmount === '') {
            SetPromoMinOrderErrMsg('Please enter min order amount')
            SetPromoMinOrderErr(true)
            err = true;
        }

        if (promotionMaxDiscountAmount === undefined || promotionMaxDiscountAmount === '') {
            SetPromoMaxdiscountamountErrMsg('Please enter max discount amount')
            SetPromoMaxdiscountamountErr(true)
            err = true;
        }

        if (promotionApplicableMode === undefined || promotionApplicableMode === '') {
            SetPromoModeErrMsg('Please select promotion applicable mode')
            SetPromoModeErr(true)
            err = true;
        }

        // if (promotionValidFor === undefined || promotionValidFor === '') {
        //     SetPromovalidErrMsg('Please select promotion valid for')
        //     SetPromovalidErr(true)
        //     err = true;
        // }




        if (err === true) {

            return false;
        }




        if (promotionName !== null) {
            const response = await axiosJWT.post('/marketing/promotion/',
                {
                    name: promotionName,
                    code: promotionCode,
                    description: promotionDescription,
                    value: promotionDiscount,
                    type: promotionType,
                    applicable: promotionApplicable,
                    validity_sdate: format(promotionSDate, 'yyyy-MM-dd'),
                    validity_edate: format(promotionEDate, 'yyyy-MM-dd'),
                    max_allowed: promotionMaxOrder,
                    max_discount_amount: promotionMaxDiscountAmount,
                    min_order_amount: promotionMinOrderAmount,
                    mode: promotionApplicableMode,
                    isAllow_MultiTimes: promotionMultipleUse,
                    validfor: promotionValidFor,
                    isActive: promotionisActive
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                handleClose();
            }

            getPromocodes();
        }
    }

    const updatePromotion = async (e) => {



        SetPromoNameErr(false)
        SetPromoapplyonErr(false)
        SetPromoTypeErr(false)
        SetPromoCodeErr(false)
        SetPromoDiscountErr(false)
        SetPromoDescriptionErr(false)
        SetPromoValidityStartErr(false)
        SetPromoValidityEndErr(false)
        SetPromoAllowMultipleErr(false)
        SetPromoAllowOrderErr(false)
        SetPromoMinOrderErr(false)
        SetPromoMaxdiscountamountErr(false)
        SetPromoModeErr(false)
        SetPromovalidErr(false)

        var err = false;

        if (promotionName === undefined || promotionName === '') {
            SetPromoNameErrMsg('Please enter promotion name')
            SetPromoNameErr(true)
            err = true;

        }

        if (promotionApplicable === undefined || promotionApplicable === '') {
            SetPromoapplyonErrMSg('Please select apply promotion on')
            SetPromoapplyonErr(true)
            err = true;
        }

        if (promotionType === undefined || promotionType === '') {
            SetPromoTypeErrMsg('Please select  promotion type')
            SetPromoTypeErr(true)
            err = true;
        }

        if (promotionCode === undefined || promotionCode === '') {
            SetPromoCodeErrMsg('Please enter promo code')
            SetPromoCodeErr(true)
            err = true;
        }

        if (promotionDiscount === undefined || promotionDiscount === '') {
            SetPromoDiscountErrMsg('Please enter promo discount')
            SetPromoDiscountErr(true)
            err = true;
        }

        if (promotionDescription === undefined || promotionDescription === '') {
            SetPromoDescriptionErrMsg('Please enter promotion description')
            SetPromoDescriptionErr(true)
            err = true;
        }

        if (promotionSDate === undefined || promotionSDate === '') {
            SetPromoValidityStartErrMsg('Please select validity start date')
            SetPromoValidityStartErr(true)
            err = true;
        }

        if (promotionEDate === undefined || promotionEDate === '') {
            SetPromoValidityEndErrMsg('Please select validity end date')
            SetPromoValidityEndErr(true)
            err = true;
        }

        if (promotionMultipleUse === undefined || promotionMultipleUse === '') {
            SetPromoAllowMultipleErrMsg('Please select this field')
            SetPromoAllowMultipleErr(true)
            err = true;
        }

        if (promotionMaxOrder === undefined || promotionMaxOrder === '') {
            SetPromoAllowOrderErrMsg('Please enter max no of order allowed')
            SetPromoAllowOrderErr(true)
            err = true;
        }

        if (promotionMinOrderAmount === undefined || promotionMinOrderAmount === '') {
            SetPromoMinOrderErrMsg('Please enter min order amount')
            SetPromoMinOrderErr(true)
            err = true;
        }

        if (promotionMaxDiscountAmount === undefined || promotionMaxDiscountAmount === '') {
            SetPromoMaxdiscountamountErrMsg('Please enter max discount amount')
            SetPromoMaxdiscountamountErr(true)
            err = true;
        }

        if (promotionApplicableMode === undefined || promotionApplicableMode === '') {
            SetPromoModeErrMsg('Please select promotion applicable mode')
            SetPromoModeErr(true)
            err = true;
        }

        // if (promotionValidFor === undefined || promotionValidFor === '') {
        //     SetPromovalidErrMsg('Please select promotion valid for')
        //     SetPromovalidErr(true)
        //     err = true;
        // }




        if (err === true) {

            return false;
        }


        const promoId = e.target.getAttribute("data-item");

 
        const response = await axiosJWT.put('/marketing/promotion/' + promoId,
            {
                name: promotionName,
                code: promotionCode,
                description: promotionDescription,
                value: promotionDiscount,
                type: promotionType,
                applicable: promotionApplicable,
                validity_sdate: format(promotionSDate, 'yyyy-MM-dd'),
                validity_edate: format(promotionEDate, 'yyyy-MM-dd'),
                max_allowed: promotionMaxOrder,
                max_discount_amount: promotionMaxDiscountAmount,
                min_order_amount: promotionMinOrderAmount,
                mode: promotionApplicableMode,
                isAllow_MultiTimes: promotionMultipleUse,
                validfor: promotionValidFor,
                isActive: promotionisActive
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleClose();

            

            
            //inn
        }

        getPromocodes();
    }

    const promo_active = async (e) => {
        try {
            var isActive = e.target.value;
            const promoId = e.target.getAttribute("data-item");

            if (isActive === 'true')
                var updateActive = 0;
            else
                var updateActive = 1;

            const response = await axiosJWT.put('/marketing/promotion/' + promoId,
                {
                    isActive: updateActive
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response)
                getPromocodes();

        } catch (error) {
            console.log(error);
        }
    }


    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getPromocodes(pagenumber);
    }


    const deletePromotion = async (e) => {

        const promoId = e.target.getAttribute("data-item");

        const response = await axiosJWT.delete('/marketing/promotion/' + promoId,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleDeleteClose();
            getPromocodes();
        }
    }
    const [DiscountSign,SetDiscountSign]=useState('')

    const ChangedPromotion=(e)=>{

        setPromotionType(e.target.value)
        if(e.target.value==='Flat Discount')
        {
            SetDiscountSign('$')
        }
        else if(e.target.value==='Percentage Discount')
        {
            SetDiscountSign('%')
        }

    }

    return (
        <div>
            <div className='intro whitebg'>
                <div className='p_title grayfont'>Promo Codes</div>
                <div className='description grayfont'><p>Define promo codes to be used by Customers during checkout. Promo code contains a variety of features: from fixed discount to percentage discount, time till the code is active, maximum discount amount allowed, minimum order amount on which promo code can be applied, whether promotion is public, auto apply or hidden and many other features.</p></div>
            </div>
            <div className='addpromotion'><input className='greenbutton' type="button" value="Add Promotion" onClick={(e) => { handleShow(e) }} data-modal="promo_add" /></div>


            <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Name</Th>
                        <Th>Code</Th>
                        <Th>Applicable On</Th>
                        <Th>Type</Th>
                        <Th>Value</Th>
                        <Th>Maximum Discount</Th>
                        <Th>Minimum Order Amount</Th>
                        <Th>End Date</Th>
                        <Th>Promo application mode</Th>
                        <Th>Active</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                    {promotions.map((promo, index) => (
                        <Tr className='trow' key={promo.id}>
                            <Td>{promo.name}</Td>
                            <Td>{promo.code}</Td>
                            <Td className="color_grey">{promo.applicable}</Td>
                            <Td className="width_big"><p className={promo.type === 'Flat Discount' ? 'orangebox' : 'purplebox'}>{promo.type}</p></Td>
                            <Td className="color_grey">{promo.value}</Td>
                            <Td className="color_grey">{promo.max_discount_amount}</Td>
                            <Td className="color_grey">{promo.min_order_amount}</Td>
                            <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(promo.validity_edate), `PP`)}</Td>
                            <Td className="color_grey">{promo.mode}</Td>
                            <Td><input className='active_checkbox_promo' type="checkbox" data-item={promo.id} onChange={promo_active} value={promo.isActive} defaultChecked={promo.isActive} /></Td>
                            <Td><i className="fa fa-edit td_icon action" data-modal="promo_edit" data-item={promo.id} onClick={handleShow}></i><i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-item={promo.id} onClick={handleShow}></i></Td>
                        </Tr>
                    ))}

                </Tbody>
            </Table>

            {totalCount ? 
            <Pagination
                activePage={activePage}
                itemsCountPerPage={countPerPage}
                totalItemsCount={totalCount}
                onChange={changePageNumber.bind(this)}
                prevPageText="<"
                nextPageText=">"
                hideFirstLastPages={true}
                linkClassPrev="pagination_prev"
                linkClassNext="pagination_next"
                linkClass="pagenumbers grayfont"
            />
            : null }

            <div style={{ clear: 'both' }}></div>

            <Modal show={show} onHide={handleClose} className='adminmarketing_modal_popup'>
                <Modal.Header closeButton>
                    {editPopup === true ? <Modal.Title>Edit Promotion</Modal.Title> : <Modal.Title>Add Promotion</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Promotion Name:</label><br />
                            <input type='text' className='inner_popup_input' defaultValue={promotionName} onChange={(e) => { setPromotionName(e.target.value) }} {...(PromoNameErr===true ? {id: 'err_border'} : {})}></input>
                        </div>
                    </div>
                    {PromoNameErr === true &&
                        <div className='row err promotion_err_name'>
                            {PromoNameErrMsg}
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Apply Promotion On:</label><br />
                            <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' defaultValue={promotionApplicable} onChange={(e) => { setPromotionApplicable(e.target.value) }} {...(PromoapplyonErr===true ? {id: 'err_border'} : {})}>
                                <option value="">Select</option>
                                <option value="Client subscription">Client subscription</option>
                            </select>
                            {PromoapplyonErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoapplyonErrMSg}
                                </div>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Promotion Type:</label><br />
                            <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' defaultValue={promotionType} onChange={(e)=>{ChangedPromotion(e)}} {...(PromoTypeErr===true ? {id: 'err_border'} : {})}>
                                <option value="">Select</option>
                                <option value="Flat Discount">Flat Discount</option>
                                <option value="Percentage Discount">Percentage Discount</option>
                            </select>
                            {PromoTypeErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoTypeErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Code:</label><br />
                            <input type='text' className='inner_popup_input' defaultValue={promotionCode} onChange={(e) => { setPromotionCode(e.target.value) }} {...(PromoCodeErr===true ? {id: 'err_border'} : {})}></input>
                            {PromoCodeErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoCodeErrMsg}
                                </div>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Discount ({DiscountSign}):</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={promotionDiscount} onChange={(e) => { setPromotionDiscount(e.target.value) }} {...(PromoDiscountErr===true ? {id: 'err_border'} : {})}></input>
                            {PromoDiscountErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoDiscountErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Description:</label><br />
                            <textarea className='inner_popup_input' defaultValue={promotionDescription} onChange={(e) => { setPromotionDescription(e.target.value) }} {...(PromoDescriptionErr===true ? {id: 'err_border'} : {})}></textarea>
                            {PromoDescriptionErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoDescriptionErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Validity Start Date:</label><br />

                            {editPopup === true ?
                                <Datepicker
                                    value={promotionSDate}
                                    onChange={onSDateChange}
                                    format="MM-dd-yyyy"
                                    className="inner_popup_input"
                                    clearIcon=""
                                    {...(PromoValidityStartErr===true ? {id: 'err_border'} : {})}
                                /> :
                                <Datepicker
                                    value={promotionSDate}
                                    onChange={onSDateChange}
                                    format="MM-dd-yyyy"
                                    minDate={new Date()}
                                    className="inner_popup_input"
                                    clearIcon=""
                                    {...(PromoValidityStartErr===true ? {id: 'err_border'} : {})}
                                />}
                            {PromoValidityStartErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoValidityStartErrMsg}
                                </div>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Validity End Date:</label><br />
                            {editPopup === true ?
                                <Datepicker
                                    value={promotionEDate}
                                    onChange={onEDateChange}
                                    format="MM-dd-yyyy"
                                    className="inner_popup_input"
                                    clearIcon=""
                                    {...(PromoValidityEndErr===true ? {id: 'err_border'} : {})}
                                /> :
                                <Datepicker
                                    value={promotionEDate}
                                    onChange={onEDateChange}
                                    format="MM-dd-yyyy"
                                    minDate={new Date()}
                                    className="inner_popup_input"
                                    clearIcon=""
                                    {...(PromoValidityEndErr===true ? {id: 'err_border'} : {})}
                                />}
                            {PromoValidityEndErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoValidityEndErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Allow Code To Use Multiple Time:</label><br />
                            <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' defaultValue={promotionMultipleUse} onChange={(e) => { setPromotionMultipleUse(e.target.value) }}          {...(PromoAllowMultipleErr===true ? {id: 'err_border'} : {})}>
                                <option value="">Select</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {PromoAllowMultipleErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoAllowMultipleErrMsg}
                                </div>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Max. Number of Allowed Order:</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={promotionMaxOrder} onChange={(e) => { setPromotionMaxOrder(e.target.value) }} {...(PromoAllowOrderErr===true ? {id: 'err_border'} : {})}></input>
                            {PromoAllowOrderErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoAllowOrderErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Min. Order Amount:</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={promotionMinOrderAmount} onChange={(e) => { setPromotionMinOrderAmount(e.target.value) }} {...(PromoMinOrderErr===true ? {id: 'err_border'} : {})}></input>
                            {PromoMinOrderErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoMinOrderErrMsg}
                                </div>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Max. Discount Amount:</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={promotionMaxDiscountAmount} onChange={(e) => { setPromotionMaxDiscountAmount(e.target.value) }} {...(PromoMaxdiscountamountErr===true ? {id: 'err_border'} : {})}></input>
                            {PromoMaxdiscountamountErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoMaxdiscountamountErrMsg}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Promotion Applicable Mode:</label><br />
                            <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' defaultValue={promotionApplicableMode} onChange={(e) => { setPromotionApplicableMode(e.target.value) }} {...(PromoModeErr===true ? {id: 'err_border'} : {})}>
                                <option value="">Select</option>
                                <option value="Public">Public</option>
                                <option value="Hidden">Hidden</option>
                                <option value="Apply">Auto apply</option>
                            </select>
                            {PromoModeErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromoModeErrMsg}
                                </div>
                            }
                        </div>
                        {/* <div className="col">
                            <label className='inner_popup_label required'>Promotion Valid For:</label><br />
                            <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' defaultValue={promotionValidFor} onChange={(e) => { setPromotionValidFor(e.target.value) }}>
                                <option value="Select">Select</option>
                                <option value="All Listings">All Listings</option>
                                <option value="Listing Categories">Choose Listing Categories</option>
                                <option value="Choose Listings">Choose Listings</option>
                            </select>
                            {PromovalidErr === true &&
                                <div className='row err promotion_err_name'>
                                    {PromovalidErrMsg}
                                </div>
                            }
                        </div> */}
                    </div>

                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={promotionId} onClick={(e) => { modalPopup === 'promo_add' ? createPromotion(e) : updatePromotion(e) }}>
                        {modalPopup === 'promo_add' ? "Create Promotion" : "Update Promotion"}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePopup} onHide={handleDeleteClose} className='adminmarketing_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Promotion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete promotion {promotionName} : {promotionCode} ?
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleDeleteClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={promotionId} onClick={(e) => { deletePromotion(e) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Promocode
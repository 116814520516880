import React, { useEffect } from "react";

const RecentActivity = (props) => {


    const CheckPhysical = (e) => {

        var tabLink = document.querySelectorAll(".option_div")

        tabLink.forEach(box => {

            box.classList.remove('active');
        });
        e.target.closest('.option_div').classList.add("active");
        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");
        if (category === "") {
            props.DisableNextButton();
          } else {
            props.SetRecentData(category);
            props.EnableNextButton();
          }
    }

    useEffect(() => {
        setTimeout(() => {
    
          if (props.RecentData) {
            if (props.RecentData !== "") {
             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.RecentData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{
             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading" style={{ maxWidth: '100%' }}>
                   Experienced any of the following changes recently?</span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Child Birth" onClick={CheckPhysical}><p>Child Birth</p></div>
                <div className="option_div" data-target="Major gain in weight" onClick={CheckPhysical}><p>Major gain in weight</p></div>
                <div className="option_div" data-target="Major loss in weight" onClick={CheckPhysical}><p>Major loss in weight</p></div>
                <div className="option_div" data-target="Menopause" onClick={CheckPhysical}><p>Menopause</p></div>
                <div className="option_div" data-target="None" onClick={CheckPhysical}><p>None</p></div>
            </div>

        </div>
    )
}
export default RecentActivity
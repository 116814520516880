import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import $ from 'jquery'
const videojs = window.videojs;



var myPlayer;

const ExerciseDetail = (props) => {

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

    const CloseDetailedView = () => {

        if(myPlayer.dispose)
        {
            myPlayer.dispose();
        }
      
        props.SetExerciseDetailShow(false);
    }

    const JqueryCode = () => {
        var access_token = "";
        var videoObject = "";
        var videoTitle = "";
        var eventHistoryId = "";

        var previewPlayer;
        var isBillablePlay = false;
        var isPlayInitiated = false;
        var playProgress25 = false;
        var playProgress50 = false;
        var playProgress75 = false;
        var data = new FormData();

        let settings = {
            "url": "" + process.env.REACT_APP_FITNESS_OD_MURL + "/auth/token",
            "method": "POST",
            "data": JSON.stringify({
                "email": process.env.REACT_APP_FITNESS_ADMIN_EMAIL,
                "clientId": process.env.REACT_APP_FITNESS_OD_CLIENT_ID,
                "clientSecret": process.env.REACT_APP_FITNESS_OD_CLIENT_SECRET
            }),
            "contentType": "application/json-patch+json"
        }
        $.ajax(settings).done(function (response) {

            //set access token to call other apis
            access_token = response.idToken;

            if (access_token) {

                videojs.getPlayer('#video-player').ready(function () {
                    myPlayer = this;
                    launchPlayer(props.VideoDetailedId);
                    myPlayer.play();
                    // Playback error Following are standard errors
                    // MEDIA_ERR_ABORTED , MEDIA_ERR_NETWORK , MEDIA_ERR_DECODE,
                    // MEDIA_ERR_SRC_NOT_SUPPORTED, MEDIA_ERR_ENCRYPTED, MEDIA_ERR_UNKNOWN
                    // Reference - https://player.support.brightcove.com
                    // /references/brightcove-player-error-reference.html
                    myPlayer.on("error", function (err) {
                        // myPlayer.error() will return a JSON object and return

                        // type and code
                        let errType = myPlayer.error().type;
                        // playerErrorLog(errType)
                    });
                    // Play event
                    myPlayer.on('play', function () {
                        if (!isPlayInitiated) {
                            isPlayInitiated = true;
                            let elapsedTime = myPlayer.currentTime();
                            let duration = myPlayer.duration();
                            console.log('elapsedTime', elapsedTime)
                            console.log('duration', duration)
                            const new_date = new Date();
                            console.log(new_date.toISOString());
                            let settings = {
                                "url": "" + process.env.REACT_APP_FITNESS_OD_MURL + "/classes/play",
                                "method": "POST",
                                "data": JSON.stringify({
                                    "videoId": props.VideoDetailedId,
                                    "startTime": new_date.toISOString() //"2020-12-14T07:19:52.928Z",
                                }),
                                "contentType": "application/json-patch+json",
                                "headers": {
                                    "Authorization": `Bearer ${access_token}`,
                                    "cache-control": "no-cache"
                                }
                            }
                            $.ajax(settings).done(function (response) {

                                eventHistoryId = response.data.eventHistoryId;

                            });
                        }
                    })

                    myPlayer.on('ended', function () {
                        let elapsedTime = myPlayer.currentTime();
                        const new_date = new Date();

                        let settings = {
                            "url": "" + process.env.REACT_APP_FITNESS_OD_MURL + "/classes/endevent",
                            "method": "POST",
                            "data": JSON.stringify({
                                "eventHistoryId": eventHistoryId,
                                "endTime": new_date.toISOString(), //"2020-12-14T07:19:52.928Z",
                                "stopReason": "Ended",
                                "lastSeekTimeInMs": parseInt(elapsedTime) * 1000
                            }),
                            "contentType": "application/json-patch+json",
                            "headers": {
                                "Authorization": `Bearer ${access_token}`,
                                "cache-control": "no-cache"
                            }
                        }

                        $.ajax(settings).done(function (response) {
                            console.log("end response", response);
                            //$('#showMessage').html('endEvent log api called.');
                            playProgress25 = false;
                            playProgress50 = false;
                            playProgress75 = false;
                            eventHistoryId = null;
                            isPlayInitiated = false;
                        });
                    });

                    myPlayer.on("seeked", event => {
                        let elapsedTime = myPlayer.currentTime();

                        if (eventHistoryId !== null && elapsedTime > 0) {
                            // capturePlayProgressEvent(elapsedTime);
                        }
                    });

                    myPlayer.on("timeupdate", function () {
                        let elapsedTime = myPlayer.currentTime();
                        let totalLength = myPlayer.duration();
                        let coveredProgress = (elapsedTime * 100) / totalLength;
                  
                        const minutes = Math.floor(elapsedTime / 60);
                        SetVideoDurationInput(minutes)
                        console.log('coveredProgress', coveredProgress);

                        switch (parseInt(coveredProgress)) {
                            case 25:
                                if (!playProgress25) {
                                    playProgress25 = true;
                                    // capturePlayProgressEvent(elapsedTime);
                                }
                                break;
                            case 50:

                                if (!playProgress50) {
                                    playProgress50 = true;
                                    // capturePlayProgressEvent(elapsedTime);
                                }
                                break;
                            case 75:
                                if (!playProgress75) {
                                    playProgress75 = true;
                                    // capturePlayProgressEvent(elapsedTime);
                                }
                                break;
                        }
                    });
                });
            }
        });


        let launchPlayer = async (videoId) => {

            await myPlayer.catalog.getVideo(`ref:${videoId}`, function (error,
                video) {
                // Capture video load error
                if (error != null) {
                    // playerErrorLog('Could not find video')
                }
                // Load the video object into the player
                myPlayer.catalog.load(video);
                $('#showMessage').html('Video is ready for the playback.');

                myPlayer.play();
            });
        }
    }

    const [videoTitle, SetvideoTitle] = useState('');
    const [VideoCalories, SetVideoCalories] = useState('');
    const [VideoRating, SetVideoRating] = useState(0);
    const [LoopArray, SetLoopArray] = useState([0, 1, 2, 3, 4]);
    const [VideoDifficulty, SetVideoDifficulty] = useState('');
    const [VideoDesc, SetVideoDesc] = useState('');
    const [VideoInstructor, SetVideoInstructor] = useState('');
    const [VideoLanguage, SetVideoLanguage] = useState('');
    const [VideoEquipment, SetVideoEquipment] = useState([]);
    const [VideoCategories, SetVideoCategories] = useState([]);
    const [VideoDuration, SetVideoDuration] = useState();

    const FormatSeconds = (milliseconds = '') => {
        var minutes = Math.floor(milliseconds / 60000);
        // var seconds = ((milliseconds % 60000) / 1000).toFixed(0);
        return minutes;
    }

    useEffect(() => {
    
        if (props.DetailedVideo[0]) {

            if (props.DetailedVideo[0].videoTitle !== undefined) {
                SetvideoTitle(props.DetailedVideo[0].videoTitle)
                SetEventTitle(props.DetailedVideo[0].videoTitle)
            }
            if (props.DetailedVideo[0].calories !== undefined) {
                SetVideoCalories(props.DetailedVideo[0].calories)
            }
            if (props.DetailedVideo[0].rating !== undefined) {
                SetVideoRating(props.DetailedVideo[0].rating)
            }
            if (props.DetailedVideo[0].difficulty !== undefined) {
                SetVideoDifficulty(props.DetailedVideo[0].difficulty)
            }
            if (props.DetailedVideo[0].videoDescription !== undefined) {
                SetVideoDesc(props.DetailedVideo[0].videoDescription)
                SetEventDescription(props.DetailedVideo[0].videoDescription)
            }
            if (props.DetailedVideo[0].instructor !== undefined) {
                SetVideoInstructor(props.DetailedVideo[0].instructor[0])
            }
            if (props.DetailedVideo[0].language.description !== undefined) {
                SetVideoLanguage(props.DetailedVideo[0].language.description)
            }
            if (props.DetailedVideo[0].equipment !== undefined) {
                SetVideoEquipment(props.DetailedVideo[0].equipment)
            }
            if (props.DetailedVideo[0].categories !== undefined) {
                SetVideoCategories(props.DetailedVideo[0].categories)
            }
            if (props.DetailedVideo[0].videoDuration !== undefined) {
                const Minutes = FormatSeconds(props.DetailedVideo[0].videoDuration)
                SetVideoDuration(Minutes)
              
            }
        }

        const script = document.createElement('script');

        script.src = "https://players.brightcove.net/6057955890001/hmto86W6c_default/index.min.js";
        script.async = true;

        document.body.appendChild(script);
        JqueryCode();

        return () => {
            document.body.removeChild(script);
        }

    }, [props]);

    //Add to Calendar 

    const [ShowAddToCalendar,SetShowAddToCalendar]=useState(false)
    const [EventTitle,SetEventTitle]=useState('')
    const [EventTitleErr,SetEventTitleErr]=useState(false)
    const [EventDescription,SetEventDescription]=useState('')
    const [EventDescriptionErr,SetEventDescriptionErr]=useState(false)
    const [EventTime, SetEventTime] = useState(new Date());
    const [AlertCalendar,SetAlertCalendar]=useState(false);
    const [AlertCalendarMsg,SetAlertCalendarMsg]=useState('');
    const [AlertCalendarDesc,SetAlertCalendarDesc]=useState('');

    const CloseAlertCalendar=()=>{
        SetAlertCalendar(false)
    }
    const CloseAddTocalendar=()=>{
        SetShowAddToCalendar(false)
    }

    const AddToCalendar=()=>{

        SetShowAddToCalendar(true)

    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    const CreateEvent=async()=>{



    

        var Date=ChangeDatePickerDate(AddDataDate)

         try{

            const response = await axiosJWT.post('/google/create-event',
            {
                'event_title': EventTitle,
                'event_description':EventDescription,
                'event_date':Date,
                'event_time': TImePicker,
                'video_duration':VideoDuration,
                'video_link':process.env.REACT_APP_FRONTEND_URL+'/client/fitness-videos?video_id='+props.VideoDetailedId+''
    
    
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            SetAlertCalendarMsg('Calendar Alert!')
            SetAlertCalendarDesc(response.data.msg)
            SetAlertCalendar(true)
            CloseAddTocalendar()

         }catch(err)
         {
            console.log(err)
            SetAlertCalendarMsg('Calendar Alert!')
            SetAlertCalendarDesc(err.response.data.msg)
            SetAlertCalendar(true)
            CloseAddTocalendar()
         }
      
 
    }

    const [AddDataDate,SetAddDataDate]=useState(new Date())
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    //Add to exercise;

    const [ShowAddToExercise,SetShowAddToExercise]=useState(false)
    const CloseAddToExercise=()=>{
        SetShowAddToExercise(false)
    }

    const TriggerShowExercise=()=>{
        SetShowAddToExercise(true)
    }

    const [VideoDurationInput,SetVideoDurationInput]=useState('')
    const [VideoDurationInputErr,SetVideoDurationInputErr]=useState(false)

    const AddExerciseData=async()=>{
        SetVideoDurationInputErr(false)
        if(VideoDurationInput==='')
        {
            SetVideoDurationInputErr(true)
        }
        else{

       

            const response = await axiosJWT.post('/progress-diary/exercise-video-diary',
            {
                'duration_watched': VideoDurationInput,
                'video_duration':VideoDuration,
                'total_calories':VideoCalories,
                'exercise_title':videoTitle,
                'user_date':new Date()
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if(response.status==200)
            {
                CloseAddToExercise()
            }
            else{
                alert('something went wrong')
            }

           
            
        }

    }


    return (

        <div className="container exercise_video_detail_container">
            <div className="top_row">
                <div className="video_info_col">
                    <p className="title">{videoTitle} <i style={{cursor:'pointer'}} className="fa fa-info-circle" title="To add an event to your google & outlook calendar. Provide access under profile screen" aria-hidden="true"></i></p>
                    <div className="inline_info_row">
                        <div className="rating">
                            {LoopArray.length > 0 && LoopArray.map((videos) => (
                                (videos < VideoRating) ? <span className="fa fa-star checked"></span> : <span className="fa fa-star "></span>
                            ))}
                        </div>
                        <div className="time">
                            <i className="fa fa-clock-o minsicon" aria-hidden="true"></i> {VideoDuration} mins
                        </div>
                        <div className="calories">
                            <i className='fas fa-burn'></i> {VideoCalories} Calories
                        </div>
                        <div className=''>
                            <i className="fa fa-bar-chart"></i> {' ' + VideoDifficulty}
                        </div>
                    </div>
                </div>
                
                <div className="button_col"><button className="btn btn-primary" onClick={CloseDetailedView}>Back</button>
                
                <button className="btn btn-primary" style={{marginRight:'10px'}} onClick={AddToCalendar}>Add to Calendar</button>

                <button className="btn btn-primary" style={{marginRight:'10px'}} onClick={TriggerShowExercise}>Add to Exercise</button>
                
                </div>
         
            </div>

            <div className="video_row">
                <video id="video-player"
                    data-account="6057955890001"
                    data-player="hmto86W6c"
                    data-embed="default"
                    controls={true}
                    data-application-id=""
                    class="video-js"
                    height="576"
                    width="1024"></video>
            </div>

            <div className='video_desc_row'>
                <div className='instructor'>
                    <p className='title'>Instructor</p>
                    <p>{VideoInstructor}</p>
                </div>
                <div className='about'>
                    <p className='title'>About the Class</p>
                    <p>{VideoDesc}</p>
                </div>
            </div>

            <div className='video_details_row'>
                <div className='list_div'>
                    <p className='title'>Type</p>
                    <p>Video</p>
                </div>
                <div className='list_div'>
                    <p className='title'>language</p>
                    <p>{VideoLanguage}</p>
                </div>
                <div className='list_div'>
                    <p className='title'>Equipment</p>
                    <ul>
                        {VideoEquipment.length > 0 && VideoEquipment.map((equip, index) => (
                            <li>{equip}</li>
                        ))}
                    </ul>
                </div>
                <div className='list_div'>
                    <p className='title'>Categories</p>
                    <ul>
                        {VideoCategories.length > 0 && VideoCategories.map((cat, index) => (
                            <li>{cat}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <Modal show={ShowAddToCalendar} onHide={CloseAddTocalendar} className="confirm_delete event_modal">
        <Modal.Header closeButton>
          <Modal.Title>Add to Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="row">
            <div className="col">
              <label className="commission_inner_popup_label">
                {"Event title"}
              </label>
              <br />
              <input
                type="text"
                className="add_category_inner_input total_session"
                defaultValue={EventTitle} onChange={(e)=>{SetEventTitle(e.target.value)}}
                {...(EventTitleErr===true ? {id: 'err_border'} : {})}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="commission_inner_popup_label">
                {"Event Description"}
              </label>
              <br />
              <textarea className='inner_popup_input' style={{borderRadius:'5px'}} defaultValue={EventDescription} onChange={(e) => { SetEventDescription(e.target.value) }} rows="4"    {...(EventDescriptionErr===true ? {id: 'err_border'} : {})} />
            
            </div>
          </div>

          <div className="row event_picker_row">

            <div className="col">
            <label className="commission_inner_popup_label">
                {"Event Date"}
              </label>
              <br />

              
              <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                        />
            </div>

          </div>

          <div className="row event_picker_row">

<div className="col">
<label className="commission_inner_popup_label">
    {"Event Time"}
  </label>
  <br />

  
  <TimePicker onChange={SetTimePicker} value={TImePicker} />
</div>

</div>

  
        
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CreateEvent}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={AlertCalendar} onHide={CloseAlertCalendar} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>{AlertCalendarMsg}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {AlertCalendarDesc}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={CloseAlertCalendar}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={ShowAddToExercise} onHide={CloseAddToExercise} className="confirm_delete event_modal">
        <Modal.Header closeButton>
          <Modal.Title>Add this exercise today?</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="row">
            <div className="col">
              <label className="commission_inner_popup_label">
                {"Total Minutes Watched"}
              </label>
              <br />
              <input
                type="number"
                className="add_category_inner_input total_session"
                defaultValue={VideoDurationInput} onChange={(e)=>{SetVideoDurationInput(e.target.value)}}
                {...(VideoDurationInputErr===true ? {id: 'err_border'} : {})}
              />
            </div>
          </div>

        
        
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={AddExerciseData}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      

        </div>
    )
}

export default ExerciseDetail
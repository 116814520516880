
import React, { useEffect, useState } from "react";

import axios from "axios";
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import Pagination from "react-js-pagination";
import { ReactFormGenerator } from 'react-form-builder2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import 'react-form-builder2/dist/app.css';


const ClientListQuestionnaire = () => {


    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const navigate = useNavigate();
    const [PageNumberList, SetPageNumberList] = useState(1)
    const [QuestionnairePerPage, SetQuestionnairePerPage] = useState(10)
    const [TotalRecordList, SetTotalRecordList] = useState(0)
    const [AssignedRecordList, SetAssignedRecordList] = useState([])
    const [PassFormData, SetPassFormData] = useState()
    const [ShowQuestionForm, SetShowQuestionForm] = useState(false)
    const [ShowList, SetshowList] = useState(true)
    const [Formid, SetFormid] = useState()

    const [QuestionnaireName, SetQuestionnaireName] = useState('')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();
    const path = location.pathname
   



    const GetAssignedList = async (pagenumber) => {

        const response = await axiosJWT.get('form/assigned-questionnaire-list/intake?offset=' + pagenumber + '&limit=' + QuestionnairePerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetTotalRecordList(response.data.count)
            SetAssignedRecordList(response.data.List)

        })


    }

    useEffect(() => {

        GetAssignedList(1)

    }, [])

    const changePageNumberS = (pagenumber) => {

        SetPageNumberList(pagenumber)
        GetAssignedList(pagenumber)

    }

    const trigger_submit = async (e) => {




        var indexId = parseInt(e.target.getAttribute('data-form_id'))

        var SubmitId = parseInt(e.target.getAttribute('data-submit_id'))

        const response = await axiosJWT.get('form/assigned-questionnaire-list/intake/detail?intake_form_id=' + indexId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

   
        SetQuestionnaireName(response.data.IntakeFormResponse.intake_form_name)
        var QuestionArrayPush = [];
        if (response.data.FormData !== null) {
            response.data.FormData.forEach((value, index, array) => {

                var Json = JSON.parse(value.form_data)

                Json.task_data.forEach((value, index, array) => {
                    QuestionArrayPush.push(value)

                })



            })


        }

        SetFormid(SubmitId)
        SetPassFormData(QuestionArrayPush)
        SetshowList(false)
        SetShowQuestionForm(true)






    }

    const closeQuestion = () => {
        SetShowQuestionForm(false)
        SetshowList(true)

    }

    const handleUpdate = (data) => {
        
    }
    const handleSubmit = async (data) => {

        var FormData = data;



        const response = await axiosJWT.post('form/client/submit-form',
            {
                'form_data': FormData,
                'id': Formid


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleShow()

            GetAssignedList(PageNumberList)
            SetShowQuestionForm(false)
            SetshowList(true)
        }

    }

    const BackToQuestionnaire=()=>{
        navigate("/coach/questionnaire", { replace: true });
    }


    return (

        <div className="row">

            {
                path==='/coach/submit-intake-form' && 
                <div className="row">
                <div className="col">
                <button className="questionnaire_top_button top_billing_coach_payment" onClick={BackToQuestionnaire}>{'Back'}</button>
                </div>
                </div>
            }

           
                
      

            {ShowList === true &&

                <div className="row">
                    <div className="row">


                        <table className='table is-striped is-fullwidth'>
                            <thead className='thead '>
                                <tr className="trow">
                                    <th className='row_name'>Assigned By</th>
                                    <th className='row_name'>Form Owner Email</th>
                                    <th className='row_name'>Intake form Name</th>
                                    <th className='row_name'>Submitted Status</th>



                                </tr>

                            </thead>

                            {AssignedRecordList.length > 0 &&
                                <tbody className="tbody">

                                    {AssignedRecordList.length > 0 && AssignedRecordList.map((AssignedList, index) => (

                                        <tr className="trow">
                                            <td><img className='table_profile_img' alt="BigCo Inc. logo" src={AssignedList.user.user_profile_photo ?  AssignedList.user.user_profile_photo : (AssignedList.user.gender === 'MALE' ? male_icon : female_icon)} />{AssignedList.user.first_name + ' ' + AssignedList.user.last_name}</td>
                                            <td>{AssignedList.user.email}{' ('+AssignedList.user.role+')'}</td>
                                            <td><span >{AssignedList.intake_form !== null ? AssignedList.intake_form.intake_form_name : 'Form Deleted'}</span></td>

                                            {AssignedList.submitted_status === false &&
                                                <td><button type="button" className="btn btn-success view_activity_button submitted_client_questionnaire" data-submit_id={AssignedList.id} data-form_id={AssignedList.intake_form.id} onClick={(e) => { trigger_submit(e) }} >Submit</button></td>
                                            }

                                            {AssignedList.submitted_status === true &&
                                                <td><button type="button" className="btn btn-success view_activity_button submitted_client_questionnaire" >Submitted</button></td>
                                            }


                                        </tr>


                                    ))}
                                </tbody>
                            }
                        </table>


                    </div>

                    <div className="row">

                        <Pagination
                            activePage={PageNumberList}
                            itemsCountPerPage={QuestionnairePerPage}
                            totalItemsCount={TotalRecordList}
                            onChange={changePageNumberS.bind(this)}
                            prevPageText="<"
                            nextPageText=">"
                            hideFirstLastPages={true}
                            linkClassPrev="pagination_prev"
                            linkClassNext="pagination_next"
                            linkClass="pagenumbers grayfont"
                        />
                    </div>

                </div>

            }

            {ShowQuestionForm === true &&

                <div className="row form_load_div load_form_client_questionnaire">

                    <div className="row top_questionnaire_row_client">
                        <span className="top_questionnaire_row_client_span">{QuestionnaireName}</span>
                        <span className="top_questionnaire_row_client_span_x" onClick={closeQuestion}><i className="fa fa-times" aria-hidden="true"></i></span>
                    </div>

                    <ReactFormGenerator data={PassFormData} onChange={handleUpdate.bind(this)}
                        onSubmit={handleSubmit.bind(this)} form_enctype="multipart/form-data"

                        submitButton={<button type="submit" className="btn btn-primary">Submit</button>}





                    />


                </div>

            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Questionnaire submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have successfully submitted the form</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="float_right" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )


}

export default ClientListQuestionnaire


import React, { useState,useEffect } from "react";
import axios from "axios";
import { ReactFormBuilder } from 'react-form-builder2';
import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'



const Questionnaire = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [FormData, SetFormData] = useState()
    const navigate = useNavigate();

    const location = useLocation();
    const path = location.pathname
  

    const AddQuestionnaire = () => {


       if(role==='Nutrition')
       {
        window.location.href = "" + window.location.origin + "/coach/add-questionnaire";
       }
       else if(role==='Admin')
       {
        window.location.href = "" + window.location.origin + "/admin/add-questionnaire";
       }
   

    }

    const ListViewSubmittedQuestionnaire = () => {

        navigate("/coach/submitted-list-questionnaire", { replace: true });
    }
    const SubmitIntakeForm=()=>{
        navigate("/coach/submit-intake-form", { replace: true }); 
    }

    const NavigateIntakeForms=()=>{
        navigate("/admin/intake-form", { replace: true });
     
    }

useEffect(()=>{
    document.title = 'Coach-Questionnaire';
},[])





    return (
        <div>
            <div className="row questionnaire_top_row">
                <div className="col">
                    <span className="top_span_questionnaire_head">Questionnaire</span>
                </div>            
            </div>
            <div className="row">
                <div className="col">
                        {/* <button className="" onClick={SaveQuestionnaire}>Save Questionnaire</button> */}

                        {path === '/coach/questionnaire' &&
                            <button className="questionnaire_top_button top_billing_coach_payment" onClick={AddQuestionnaire} >Add Questionnaire</button>

                        }
                         {path === '/admin/questionnaire' &&
                            <button className="questionnaire_top_button top_billing_coach_payment" onClick={AddQuestionnaire} >Add Questionnaire</button>

                        }
                        {
                           path === '/admin/questionnaire' && 
                            <button className="questionnaire_top_button top_billing_coach_payment" onClick={NavigateIntakeForms} >Intake Forms</button>
                        }

                        {path === '/coach/questionnaire' &&
                            <button className="questionnaire_top_button top_billing_coach_payment" onClick={ListViewSubmittedQuestionnaire} >Client Submitted Forms</button>

                        }

                          {path === '/coach/questionnaire' &&
                            <button className="questionnaire_top_button top_billing_coach_payment" onClick={SubmitIntakeForm} >Submit Intake form</button>

                        }
                        
                </div>
            </div>
        </div>





    )


}

export default Questionnaire
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

import axios from 'axios';
import jwt_decode from "jwt-decode";
import { NavLink } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import AdminChart from './AdminChartComponent';
import AdminTotalInvited from './AdminDoughnut';
import female_icon from '../../images/female.png'
import male_icon from '../../images/male.png'
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Dashboard = () => {



  const axiosJWT = axios.create();

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  const [total_onboarded_label, setTotalOnboardedLabel] = useState('(Today)');
  const [total_onboarded_val, setTotalOnboardedVal] = useState('today');
  const total_active_label = '(Now)'



  var [userDeletePopup, setuserDeletePopup] = useState({ open: false, id: null });
  var [userEditPopup, setuserEditPopup] = useState({ open: false, id: null, first_name: null, last_name: null, gender: null, email: null, password: null, role: null, isBan: null, user_timezone: null });
  const [user_edit_first_name, setFirst_name] = useState('');
  const [user_edit_last_name, setLast_name] = useState('');
  const [user_edit_email, setEmail] = useState('');
  const [user_edit_password, setPassword] = useState('');
  const [user_edit_role, setRole] = useState('');
  const [user_edit_isBan, setBan] = useState('');
  const [user_edit_gender, setGender] = useState('');
  const [user_edit_timezone, setuser_timezone] = useState('');

  const [EditFNameErr, SetEditFNameErr] = useState(false);
  const [EditFNameMsg, SetEditFNameMsg] = useState('');

  const [EditLNameErr, SetEditLNameErr] = useState(false);
  const [EditLNameMsg, SetEditLNameMsg] = useState('');

  const [EditEmailErr, SetEditEmailErr] = useState(false);
  const [EditEmailMsg, SetEditEmailMsg] = useState('');

  const [EditPassErr, SetEditPassErr] = useState(false);
  const [EditPassMsg, SetEditPassMsg] = useState('');

  const [EditRoleErr, SetEditRoleErr] = useState(false);
  const [EditRoleMsg, SetEditRoleMsg] = useState('');

  const [EditGenderErr, SetEditGenderErr] = useState(false);
  const [EditGenderMsg, SetEditGenderMsg] = useState('');

  const [formErr, setformErr] = useState(false);
  const [formErrMsg, setformErrMsg] = useState('');

  const [TimeZoneErr, SetTimeZoneErr] = useState(false);
  const [TimeZoneMsg, SetTimeZoneMsg] = useState('');

  const [TimeZone, setTimeZone] = useState('');
  const [timezonesList, settimezonesList] = useState('');

  // const dropdown_icon = useRef(null);
  const pos2inner = useRef(null);
  const bar_name = useRef(null);
  useLayoutEffect(() => {
    pos2inner.current.style.setProperty("padding-bottom", "25px", "important");

  }, []);
  useLayoutEffect(() => {
    bar_name.current.style.setProperty("font-size", "25px", "important");
  }, []);

  const info_black_style = {
    color: "black",
    fontSize: "17px"

  };
  const info_black_style_drop = {
    color: "black",
    fontSize: "13px"

  };

  useEffect(() => {
    GetInitialDashboard()
    GetInitialEarningsChart('this_week','over_all')
    document.title = 'Admin-Dashboard';

  }, []);

  //start

  const [TotalOnboarded, setTotalOnboarded] = useState(0)
  const [TotalActive, setTotalActive] = useState(0)
  const [TotalSessions, setTotalSessions] = useState(0)
  const [TotalEarnings, setTotalEarnings] = useState(0)
  const [TotalOnboardedAgainstInivtited, setTotalOnboardedAgainstInivtited] = useState(0)
  const [TotaltInivtited, setTotalInivtited] = useState(0)
  const [NewCoach, setNewCoach] = useState([])
  const [NewClient, setNewClient] = useState([])
  const [Chartlabels, SetNewChartlabels] = useState([])
  const [ChartDatas, SetNewChartDatas] = useState([])
  const [PaymentRangeState,SetPaymentRangeState]=useState('this_week')
  const [TypeEarningState,SetTypeEarningState]=useState('over_all')

  const mainDropChange = async (e) => {

    setTotalOnboardedVal(e.target.value);

    if (e.target.value === 'today') {
      setTotalOnboardedLabel('(Today)')
    }
    else if (e.target.value === 'yesterday') {
      setTotalOnboardedLabel('(Yesterday)')
    }
    else if (e.target.value === 'this_week') {
      setTotalOnboardedLabel('(This week)')
    }
    else if (e.target.value === 'last_week') {
      setTotalOnboardedLabel('(Last week)')
    }
    else if (e.target.value === 'this_month') {
      setTotalOnboardedLabel('(This Month)')
    }
    else if (e.target.value === 'last_month') {
      setTotalOnboardedLabel('(Last Month)')
    }
    else if (e.target.value === 'this_year') {
      setTotalOnboardedLabel('(This Year)')
    }
    else if (e.target.value === 'last_year') {
      setTotalOnboardedLabel('(Last Year)')
    }
    else if (e.target.value === 'all') {
      setTotalOnboardedLabel('(All time)')
    }
    else {
      setTotalOnboardedLabel('(Today)')
    }

    const response = await axiosJWT.get('/dashboard?date=' + e.target.value + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    setTotalOnboarded(response.data.total_onboarded)
    setTotalSessions(response.data.avg_sessions)


   if(response.data.total_earnings!==null)
   {
    setTotalEarnings(parseFloat(response.data.total_earnings).toFixed(2))
   }
   else{
    setTotalEarnings(0)
   }

    setTotalOnboardedAgainstInivtited(response.data.total_onboarded_against_invited)
    setTotalInivtited(response.data.total_invited)
    setNewClient(response.data.new_client)



    setNewCoach(response.data.new_nutritions)
  }

  const GetInitialDashboard = async (e) => {

    var DateInitial = 'today';

    const response = await axiosJWT.get('/dashboard?date=' + DateInitial + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });


    setTotalOnboarded(response.data.total_onboarded)
    setTotalActive(response.data.total_active)
    setTotalSessions(response.data.avg_sessions)
    if(response.data.total_earnings!==null)
    {
      setTotalEarnings(parseFloat(response.data.total_earnings).toFixed(2))
    }
    else{
      setTotalEarnings(0)
    }

    setTotalOnboardedAgainstInivtited(response.data.total_onboarded_against_invited)
    setTotalInivtited(response.data.total_invited)
    setNewClient(response.data.new_client)
    setNewCoach(response.data.new_nutritions)
  }

  const EarningsChart = async (e) => {

    var tabLink = document.querySelectorAll(".tab_show")

    var selectElement = document.querySelector('.earnings_chart');
    var output = selectElement.options[selectElement.selectedIndex].value;
    //alert(output)

    tabLink.forEach(box => {

      box.classList.remove('active');
    });

    if (e.target.innerText === 'Earnings') {
      SetTypeEarningState('over_all')

      GetInitialEarningsChart(PaymentRangeState,'over_all')
    }
    else if (e.target.innerText === 'Coach') {
      SetTypeEarningState('coach_subscription')
      GetInitialEarningsChart(PaymentRangeState,'coach_subscription')

      

    }
    else if (e.target.innerText === 'User') {
      SetTypeEarningState('client_subscription')
      GetInitialEarningsChart(PaymentRangeState,'client_subscription')

 

    }


    e.target.classList.add('active');

  }
  const GetInitialEarningsChart = async (range,type) => {

   

    const response = await axiosJWT.get('/admin-analytics/revenue-chart?type=' + type + '&range=' + range + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })



    if (range === 'this_week' || range === 'last_week') {
      

      var Data = []
      response.data.FinalArray.forEach((value, index, array) => {

        //console.log(value)

        if (value.amount.length > 0) {
          var FinalAmount=0
          value.amount.forEach((value, index, array) => {
            
            FinalAmount=parseFloat(value.amount)+parseFloat(FinalAmount)

          });
          Data.push(parseFloat(FinalAmount))

        }
        else {
          Data.push(0)
        }
      });
      SetNewChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'])
       SetNewChartDatas(Data)
    
    }




       if (range === 'this_month' || range === 'last_month') {

        var DatePush = [];
        var DataPush = [];
  
  
        response.data.FinalArray.forEach((value, index, array) => {
        
  
          if (value.amount.length > 0) {
      

            var FinalAmount=0
            value.amount.forEach((value, index, array) => {
             FinalAmount=parseFloat(value.amount)+parseFloat(FinalAmount)
            
            });
            DataPush.push(parseFloat(FinalAmount))
  
          }
          else {
            DataPush.push(0)
          }
  
          DatePush.push(value.date)
  
        });
  
        SetNewChartlabels(DatePush)
        SetNewChartDatas(DataPush)
  
   
      }

      // if (range === 'this_month' || range === 'last_month') {

      //   var DatePush = [];
      //   var DataPush = [];
  
  
      //   response.data.FinalArray.forEach((value, index, array) => {
  
      //     if (value.amount.length > 0) {
      //       DataPush.push(parseInt(value.amount[0].amount))
  
      //     }
      //     else {
      //       DataPush.push(0)
      //     }
  
      //     DatePush.push(value.date)
  
      //   });
  
      //   SetNewChartlabels(DatePush)
      //   SetNewChartDatas(DataPush)
  
   
      // }

      if (range === 'this_year') {

      
          SetNewChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
        
     
  
  
        var Data = []
        response.data.FinalArray.forEach((value, index, array) => {
  
          if (value.amount.length > 0) {
            var amountFinal = 0;
            value.amount.forEach((value, index, array) => {
              amountFinal += parseInt(value.amount)
  
            })
            Data.push(amountFinal)
          }
          else {
            Data.push(0)
          }
  
  
  
        })
  
           SetNewChartDatas(Data)
      
  
      }

   


  }

  const EarningsDropChange = async (e) => {

    SetPaymentRangeState(e.target.value)

    GetInitialEarningsChart(e.target.value,TypeEarningState)

  }


  const GetProfileUrl = async (user) => {
    var profile_photo = ''
    var bar = new Promise((resolve, reject) => {

      if (user.hasOwnProperty("user_profile_photo")) {

        if (user.user_profile_photo !== null && user.user_profile_photo !== '') {

          if (user.user_profile_photo !== null && user.user_profile_photo !== '') {

            profile_photo = user.user_profile_photo

          }
          else {

            profile_photo = (user.gender === "MALE" ? male_icon : female_icon)

          }

        }

      }
      else {

        profile_photo = (user.gender === "MALE" ? male_icon : female_icon);

      }

      reject()
    })

    await bar.then(() => {

      return profile_photo
    })


  }

  const modalOpen = async (e) => {
    const userId = e.target.getAttribute("data-item");
    const datamodal = e.target.getAttribute("data-modal");

    if (datamodal === 'delete_confirm')
      setuserDeletePopup({ open: true, id: userId });

    if (datamodal === 'user_edit') {
      setformErr(false);
      setformErrMsg('');
      SetTimeZoneErr(false);
      SetEditFNameErr(false);
      SetEditLNameErr(false);
      SetEditEmailErr(false);
      SetEditPassErr(false);
      SetEditGenderErr(false);

      const moment = require('moment-timezone');
      const timeZones = moment.tz.names();

      var timezone_list = [];
      timeZones.forEach(element => {
        timezone_list.push({ label: element, value: element });
      });
      settimezonesList(timezone_list);

      const response = await axiosJWT.get("/user/" + userId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });



      setuserEditPopup({ open: true, id: userId, first_name: response.data.first_name, last_name: response.data.last_name, gender: response.data.gender, email: response.data.email, password: response.data.password, role: response.data.role, isBan: response.data.isBan, user_timezone: response.data.user_timezone });

      setFirst_name(response.data.first_name);
      setLast_name(response.data.last_name);
      setGender(response.data.gender);
      setEmail(response.data.email);
      setRole(response.data.role);
      setBan(response.data.isBan);
      setuser_timezone(response.data.user_timezone);
    }
  }

  const modalClose = async () => {

    setuserDeletePopup({ open: false, id: null })


    setuserEditPopup({ open: false, id: null, first_name: null, last_name: null, gender: null, email: null, password: null, role: null, isBan: null, user_timezone: null })

    setFirst_name('');
    setLast_name('');
    setEmail('');
    setPassword('');
    setRole('');
    setBan('');
    setGender('');
    setuser_timezone('');
  }

  const user_delete = async (e) => {
    try {

      modalClose();

      const userId = e.target.getAttribute("data-item");

      const response = await axiosJWT.delete('/user/' + userId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response) {
        const response = await axiosJWT.get('/dashboard?date=' + total_onboarded_val, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });


        setNewClient(response.data.new_client)
        setNewCoach(response.data.new_nutritions)
      }

    } catch (error) {
      console.log(error);
    }
  }

  const user_edit = async (e) => {
    try {

      e.preventDefault();

      var err = false;

      SetEditFNameErr(false)
      SetEditLNameErr(false)
      SetEditEmailErr(false)
      SetEditPassErr(false)
      SetEditGenderErr(false)
      SetTimeZoneErr(false)

      if (user_edit_first_name === '') {
        SetEditFNameMsg('Please enter first name')
        SetEditFNameErr(true)
        err = true
      }
      if (user_edit_last_name === '') {
        SetEditLNameMsg('Please enter last name')
        SetEditLNameErr(true)
        err = true
      }
      if (user_edit_email === '') {
        SetEditEmailMsg('Please enter email address')
        SetEditEmailErr(true)
        err = true
      }
      if (user_edit_email !== '') {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_edit_email) == false) {
          SetEditEmailMsg('Please enter valid email address')
          SetEditEmailErr(true)
          err = true
        }
      }
      if (user_edit_password !== '') {
        if (user_edit_password.length < 8) {
          SetEditPassMsg('Password: Please enter minimum 8 characters')
          SetEditPassErr(true)
          err = true
        }
      }
      if (user_edit_gender === '') {
        SetEditGenderMsg('Please select gender')
        SetEditGenderErr(true)
        err = true
      }
      if (!user_edit_timezone) {
        SetTimeZoneMsg('Please select timezone')
        SetTimeZoneErr(true)
        err = true
      }

      if (err == true) {
        return false
      }



      const userId = e.target.getAttribute("data-item");

      if (user_edit_first_name !== userEditPopup.first_name) {
        await axiosJWT.put('/user/' + userId,
          {
            first_name: user_edit_first_name
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_last_name !== userEditPopup.last_name) {
        await axiosJWT.put('/user/' + userId,
          {
            last_name: user_edit_last_name
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_gender !== userEditPopup.gender) {
        await axiosJWT.put('/user/' + userId,
          {
            gender: user_edit_gender
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_email !== userEditPopup.email) {
        await axiosJWT.put('/user/' + userId,
          {
            email: user_edit_email
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_password) {
        await axiosJWT.put('/user/' + userId,
          {
            password: user_edit_password
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_role !== userEditPopup.role) {
        await axiosJWT.put('/user/' + userId,
          {
            role: user_edit_role
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_isBan !== userEditPopup.isBan) {
        await axiosJWT.put('/user/' + userId,
          {
            isBan: user_edit_isBan
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
      if (user_edit_timezone.value !== userEditPopup.user_timezone) {
        await axiosJWT.put('/user/' + userId,
          {
            user_timezone: user_edit_timezone.value
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }


      const response = await axiosJWT.get('/dashboard?date=' + total_onboarded_val, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setNewClient(response.data.new_client);
      setNewCoach(response.data.new_nutritions);
      modalClose();

    } catch (error) {
      console.log(error);
    }
  }


  return (

    <div>

      <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
        <section className="dashboard">
          <div className="admindashboard container">
            <div className=" colR">

              <div className="colRpos1">
                <h2>Dashboard</h2>
                <div className="dropdown" style={{ cursor: 'pointer', marginRight: '15px' }}>
                  {/* <button type="button" className="btn drpbtn dropdown-toggle drop_calender" data-toggle="dropdown">
            <span className="calender">18 May 2022  <span  className="chevron"><i className="fa fa-chevron-down " ref={dropdown_icon} aria-hidden="true"></i></span></span>
            </button> */}

                  <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin' style={{ fontWeight: 500 }} onChange={mainDropChange} >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="all">All Time</option>

                  </select>

                </div>
              </div>

              <div className="colRpos2">
                <div className="pos2inner" ref={pos2inner}>
                  <h3 ref={bar_name}>Welcome, {first_name + ' '}{last_name}</h3>
                  <p>Have a nice day at work</p>
                </div>
              </div>

              <div className="colRpos3">
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Total Onboarded <span className='label_small'>{total_onboarded_label}</span></h6><span className="pos3innericon"><i className="fa fa-user-plus" aria-hidden="true"></i></span> </div>
                  <h3 style={info_black_style}>{TotalOnboarded}</h3>
                </div>
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Total Active <span className='label_small'>{total_active_label}</span></h6><span className="pos3innericon"><i className="fa fa-user-o" aria-hidden="true"></i></span> </div>
                  <h3 style={info_black_style}>{TotalActive}</h3>
                </div>
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Avg.Sessions <span className='label_small'>{total_onboarded_label}</span></h6><span className="pos3innericon"><i className="fa fa-calendar-o" aria-hidden="true" style={{ backgroundColor: "#665cff" }}></i></span> </div>
                  <h3 style={info_black_style}>{TotalSessions}</h3>
                </div>
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Earnings <span className='label_small'>{total_onboarded_label}</span></h6><span className="pos3innericon"><i className="fa fa-sticky-note-o" aria-hidden="true" style={{ backgroundColor: "#ffd714" }}></i></span> </div>
                  <h3 style={info_black_style}>$ {TotalEarnings}</h3>
                </div>
              </div>
              <div className='container'>
                <div className='wholesection row'>
                  <div className="section1 col-lg-8 Tob_button">

                    <div className='earning_tab' style={{ marginBottom: '15px' }}>
                      <span className='active pointer_eff tab_show' onClick={EarningsChart}>Earnings</span>
                      <span className='pointer_eff tab_show' onClick={EarningsChart}>Coach</span>
                      <span onClick={EarningsChart} className='pointer_eff tab_show'>User</span>
                      <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={EarningsDropChange}>
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_year">This Year</option>
                      </select>
                    </div>
                    <div style={{ backgroundColor: 'white' }}>
                      <AdminChart Chartlabels={Chartlabels} ChartDatas={ChartDatas} />
                    </div>
                  </div>
                  <div className='section2 col-lg-4 total_invited_div' style={{ position: 'relative' }}>
                    <span style={{ fontWeight: '600', color: 'black', marginBottom: '15px' }}>Total Invited vs Onboarded<span className='label_small'>{total_onboarded_label}</span></span>
                    <div style={{ marginTop: '15px', backgroundColor: 'white' }}>
                      <AdminTotalInvited total_onboarded_dough={TotalOnboardedAgainstInivtited} total_invited_dough={TotaltInivtited} />
                    </div>
                    <div style={{ position: 'absolute', width: '100%', top: '50%', left: 0, textAlign: 'center', marginTop: '-28px', lineHeight: '20px' }}>
                      <span style={{ color: '#226920', fontWeight: 600 }}>{TotalOnboardedAgainstInivtited}</span><br />
                      <span style={{ fontWeight: 600 }}>Onboarded<span className='label_small_block'>{total_onboarded_label}</span></span>
                    </div>
                  </div>
                </div>
                <div className='wholesection1 row'>
                  <div className='col'>
                    <div>
                      <span className='newusers_label'>New Coach<span className='label_small_new'>{total_onboarded_label}</span></span>
                      <span className='viewall'><Link to="/admin/manage-users" >View All</Link></span>
                    </div>
                    <div className="admin_table_div">
                      <Table className='admin_table'>
                        <Thead>
                          <Tr>
                            <Th className='row_name'>Name</Th>
                            <Th className='row_name'>Email</Th>
                            <Th className='row_name'>Actions</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {NewCoach !== undefined && NewCoach.map((user, index) => (
                            <Tr key={index}>
                              <Td>
                                <span className='c-avatar admin_dashboard'>
                                  <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo != '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                    (user.logged_in_status === '1')
                                      ? <span className='c-avatar__status'></span>
                                      : null
                                  }
                                  <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                                </span>
                              </Td>
                              <Td className='table_name_val'>{user.email}</Td>
                              <Td className=''><i className="fa fa-edit edit_icon pointer_eff" style={{ fontSize: '14px !important' }} data-modal="user_edit" data-item={user.id} onClick={modalOpen}></i><i className="fa fa-trash pointer_eff" data-modal="delete_confirm" data-item={user.id} onClick={modalOpen}></i></Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>


                  <div className='col'>
                    <div>
                      <span className='newusers_label'>New client<span className='label_small_new'>{total_onboarded_label}</span></span>
                      <span className='viewall'><Link to="/admin/manage-users" >View All</Link></span>
                    </div>
                    <div className="admin_table_div">
                      <Table className='admin_table'>
                        <Thead>
                          <Tr>
                            <Th className='row_name'>Name</Th>
                            <Th className='row_name'>Email</Th>
                            <Th className='row_name'>Actions</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {NewClient !== undefined && NewClient.map((user, index) => (
                            <Tr key={index}>
                              <Td>
                                <span className='c-avatar admin_dashboard'>
                                  <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />
                                  {
                                    (user.logged_in_status === '1')
                                      ? <span className='c-avatar__status'></span>
                                      : null
                                  }
                                  <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                                </span>
                              </Td>
                              <Td className='table_name_val'>{user.email}</Td>
                              <Td className=''><i className="fa fa-edit edit_icon pointer_eff" style={{ fontSize: '14px !important' }} data-modal="user_edit" data-item={user.id} onClick={modalOpen}></i><i className="fa fa-trash pointer_eff" data-modal="delete_confirm" data-item={user.id} onClick={modalOpen}></i></Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </section>
      </div>

      <Popup className="admin_manageusers_delete_confirm" open={userDeletePopup.open} position="right center" modal={true} closeOnDocumentClick={false} closeOnEscape={false}>
        <div className="popup_body">
          <h5>Delete User</h5>
          <p>Are you sure you want delete the user?</p>
          <input type="button" onClick={user_delete} value="Yes" data-item={userDeletePopup.id} /> <input type="button" onClick={modalClose} value="Cancel" />
        </div>
      </Popup>

      <Popup className="admin_manageusers_user_edit" open={userEditPopup.open} position="right center" modal={true} closeOnDocumentClick={false} closeOnEscape={false}>
        <div className="popup_body">
          <h5>Edit User</h5>

          {formErr === true &&
            <div className='form_err'>{formErrMsg}</div>
          }

          <form id="user_edit_fields">
            <label className='required'>First Name:</label><input type="text" name="first_name" defaultValue={user_edit_first_name} onChange={(e) => setFirst_name(e.target.value)} />
            <label className='required'>Last Name:</label><input type="text" name="last_name" defaultValue={user_edit_last_name} onChange={(e) => setLast_name(e.target.value)} />
            <div className='row fnameerr_admin_coach err'>

              <div className='col col1'>
                {EditFNameErr === true &&
                  <span style={{ paddingLeft: '26px' }}>{EditFNameMsg}</span>
                }
              </div>

              <div className='col col2'>
                {EditLNameErr === true &&
                  <span>  {EditLNameMsg}</span>
                }
              </div>

            </div>

            <label className='required'>Email:</label><input type="text" name="email" defaultValue={user_edit_email} onChange={(e) => setEmail(e.target.value)} />
            <label className='required'>Password:</label><input type="text" name="password" defaultValue='' onChange={(e) => setPassword(e.target.value)} />
            <div className='row fnameerr_admin_coach err'>

              <div className='col col1'>
                {EditEmailErr === true &&
                  <span style={{ paddingLeft: '26px' }}>{EditEmailMsg}</span>
                }
              </div>

              <div className='col col2'>
                {EditPassErr === true &&
                  <span>  {EditPassMsg}</span>
                }
              </div>
            </div>

            <label className='required'>Role:</label><select name="role" disabled={true} value={user_edit_role} onChange={(e) => setRole(e.target.value)}><option value="">Select Role</option><option value="Nutrition">Coach</option><option value="Client">Client</option></select>
            <div className='row fnameerr_admin_coach err'>

              <div className='col col1'>
                {EditRoleErr === true &&
                  <span style={{ paddingLeft: '26px' }}>{EditRoleMsg}</span>
                }
              </div>
            </div>

            <label className='required'>Gender:</label><input type="radio" value="MALE" name="gender" onChange={(e) => setGender(e.target.value)} defaultChecked={user_edit_gender === 'MALE'} /> Male
            <input style={{ "marginLeft": "10px" }} type="radio" value="FEMALE" name="gender" onChange={(e) => setGender(e.target.value)} defaultChecked={user_edit_gender === 'FEMALE'} /> Female
            <div className='row fnameerr_admin_coach err'>
              <div className='col col2'>
                {EditGenderErr === true &&
                  <span>  {SetEditGenderMsg}</span>
                }
              </div>
            </div>

            <div style={{ width: "100%", marginBottom: "30px" }}>
              <label className='required' style={{ width: "100px", float: "left" }}>Timezone:</label>
              <Select
                defaultValue={{ value: user_edit_timezone, label: user_edit_timezone }}
                options={timezonesList}
                onChange={setuser_timezone}
                className="useredit_timezone"
              />
              {
                TimeZoneErr === true &&
                <div className='row  err' style={{ paddingLeft: '26px' }}>
                  {TimeZoneMsg}
                </div>
              }

            </div>
            <div style={{ clear: "both" }} />

            <label>Ban:</label><input type="checkbox" name="isBan" defaultChecked={user_edit_isBan} onChange={(e) => setBan(e.target.checked)} /><br></br>
            <div className='action_button'><input type="button" onClick={user_edit} value="Update" data-item={userEditPopup.id} /> <input type="button" onClick={modalClose} value="Cancel" /></div>
          </form>
        </div>
      </Popup>

    </div>

  )
}

export default Dashboard
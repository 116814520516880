import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Pie } from 'react-chartjs-2';
import DummyrecipeImg from '../../../images/pexels-photo-1640777.jpeg'

const ImportedRecipePopup=(props)=>{



    const [recipeData,SetrecipeData]=useState()
    const [PercentageArray,SetPercentageArray]=useState([])
    const [Show,SetShow]=useState(false)
    const [recipetab,setRecipeTabMenu]=useState('ingredients')
    const [RecipeDataIngredient,SetRecipeDataIngredient]=useState('')
    const [RecipeDataSteps,SetRecipeDataSteps]=useState('')
    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }
    
    const pie_options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const pie_data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };



    const FetchNum = (thestring) => {

        if(typeof thestring==='string')
        {

        var txt = thestring
        var numb = txt.match(/\d/g);
        if (numb !== null) {
            numb = numb.join("");
            return parseFloat(numb)

        }
        else {
            return 0
        }
    }else{
        return  thestring
    }


    }

    const handleClose=()=>{

        props.SetImportedRecipePopShow(false)
    }

    const changeRender=(str)=>{
        str = str.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
        return str
       
       }


    useEffect(()=>{


        if(props.ImportedRecipeData!==undefined)
        {
            SetrecipeData(props.ImportedRecipeData)
            SetRecipeDataIngredient(props.ImportedRecipeData.recipe_ingredients)
            SetRecipeDataSteps(props.ImportedRecipeData.recipe_instructions)
            SetPercentageArray([props.ImportedRecipeData.recipe_proteins, props.ImportedRecipeData.recipe_fats, props.ImportedRecipeData.recipe_carbs]);
        }
        if(props.ImportedRecipePopShow!==undefined)
        {
            SetShow(props.ImportedRecipePopShow)
        }
      

    },[props])


    return(

        <div>
        <Modal show={Show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.recipe_name}</div>
                            {
                                recipeData.recipe_image !== '' ? <div className='img'><img src={recipeData.recipe_image} /></div> : <div className='img'><img src={DummyrecipeImg} /></div>

                            }
                            <div className='nutrition_data'>
                                <p className='pie_chart_kcal_data'>Calories: {FetchNum(recipeData.recipe_calories)} Kcal</p>
                                <Pie data={pie_data} options={pie_options} />
                                <hr />
                                <div className="pie_chart_bottom_div">

                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_carbs)}</span><span>G</span></span>
                                    </span>

                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_fats)}</span><span>G</span></span>
                                    </span>

                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_proteins)}</span><span>G</span></span>
                                    </span>

                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <div dangerouslySetInnerHTML={{__html: changeRender(RecipeDataIngredient)}}></div>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                     <div dangerouslySetInnerHTML={{__html: changeRender(RecipeDataSteps)}}></div>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button'  onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>

    )
   

}
export default ImportedRecipePopup


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Select from 'react-select';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Country, State, City }  from 'country-state-city';

const AddMemberByClient = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

    const [FNameErr, SetFNameErr] = useState(false);
    const [LNameErr, SetLNameErr] = useState(false);
    const [EmailErr, SetEmailErr] = useState(false);
    const [PasswordErr, SetPasswordErr] = useState(false);
    const [GenderErr, SetGenderErr] = useState(false);
    const [TimeZoneErr, SetTimeZoneErr] = useState(false);
    const [PostalErr, SetPostalErr] = useState(false);
    const [PostalErrMsg, SetPostalErrMsg] = useState(false);
    const [EmailErrMsg, SetEmailErrMsg] = useState('');
    const [PhoneErr, SetPhoneErr] = useState(false);
    const [PhoneErrMsg, SetPhoneErrMsg] = useState('');
    const [CountryErr, SetCountryErr] = useState(false);
    const [StateErr, SetStateErr] = useState(false);
    const [CityErr, SetCityErr] = useState(false);
    const [AddressErr, SetAddressErr] = useState(false);

    const [FirstName, SetFirstName] = useState('');
    const [LastName, SetLastName] = useState('');
    const [EmailAddress, SetEmailAddress] = useState('');
    const [Password, SetPassword] = useState('');
    const [Gender, SetGender] = useState('');
    const [PhoneNumber, SetPhoneNumber] = useState('');

    const [PopupMessage, SetPopupMessage] = useState('');
    const [TimeZone, setTimeZone] = useState('');
    const [TImeZoneList, SetTimeZoneList] = useState([]);
    const [Address, SetAddress] = useState('');
    const [Postal, SetPostal] = useState('');
    const [GenderCheck, SetGenderCheck] = useState(false);
    const [userCity, setuserCity] = useState('');
    const [userState, setuserState] = useState('');
    const [userCountry, setuserCountry] = useState('');    
    const [cityList, setcityList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);  

    const EditPhoneRef = useRef(null);

    useEffect(() => {
        document.title = 'Coach-Add member';
        const timeZones = moment.tz.names();
        var timezone_list = [];
        timeZones.forEach(element => {
            timezone_list.push({ label: element, value: element });
        });
        SetTimeZoneList(timezone_list);


        var countries_list = Country.getAllCountries();
        var countries_list_array = [];
        countries_list.forEach(element => {
            countries_list_array.push({"label":element.name,"value":element.isoCode});
        });
        setcountryList(countries_list_array);
    }, [])


    const AddMember = async () => {

        SetFNameErr(false);
        SetLNameErr(false);
        SetEmailErr(false);
        SetPasswordErr(false);
        SetGenderErr(false);
        SetTimeZoneErr(false);
        SetPostalErr(false);
        SetPhoneErr(false);
        SetCountryErr(false);
        SetStateErr(false);
        SetCityErr(false);
        SetAddressErr(false);

        EditPhoneRef.current.classList.remove('err_border');

        if (FirstName !== '' && LastName !== '' && EmailAddress !== '' && Password !== '' && Gender !== '' && TimeZone !== '' && Address !== '' && Postal !== '' && PhoneNumber !== '' && userCountry !== '' && userState !== '' && userCity !== '') {

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EmailAddress) === false) {
                SetEmailErrMsg('Please enter valid email address');
                SetEmailErr(true);
                return false;
            }

            try {
                ShowLoader();
                const response = await axiosJWT.post('/user/',
                    {
                        first_name: FirstName,
                        last_name: LastName,
                        gender: Gender,
                        email: EmailAddress,
                        password: Password,
                        role: 'Client',
                        user_timezone: TimeZone.value,
                        address: Address,
                        postal: Postal,
                        phone: PhoneNumber,
                        user_country: JSON.stringify(userCountry),
                        user_state: JSON.stringify(userState),
                        user_city: JSON.stringify(userCity)
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })

                if (response) {
                    SetFirstName('');
                    SetLastName('');
                    SetEmailAddress('');
                    SetPassword('');
                    SetAddress('');
                    SetPostal('');
                    SetGender('');
                    SetGenderCheck(false);
                    SetPhoneNumber('');
                    setuserCity('');
                    setuserState('');
                    setuserCountry('');                    

                    //    $('input[name="optradio"]').attr('checked', false);

                    HideLoader();
                    SetPopupMessage('You have successfully added the client member.!');
                    SetshowAdd(true);
                }
            } catch (err) {
                HideLoader();
                SetPopupMessage(err.response.data.message);
                SetshowAdd(true);
            }
        }
        else {

            if (FirstName === '') {
                SetFNameErr(true);
            }
            if (LastName === '') {
                SetLNameErr(true);
            }
            if (EmailAddress === '') {
                SetEmailErrMsg('Please enter email address.!');
                SetEmailErr(true);
            }
            if (Password === '') {
                SetPasswordErr(true);
            }
            if (Gender === '') {
                SetGenderErr(true);
            }
            if (TimeZone === '') {
                SetTimeZoneErr(true);
            }
            if (Postal === '') {
                SetPostalErr(true);
                SetPostalErrMsg('*Please enter postal code.!');
            }
            if(!PhoneNumber)
            {
                SetPhoneErr(true);
                SetPhoneErrMsg('*Please enter phone number.!');
                EditPhoneRef.current.classList.add('err_border');
            }
            if(!userCountry)
            {
                SetCountryErr(true);
            }
            if(!userState)
            {
                SetStateErr(true);
            }
            if(!userCity)
            {
                SetCityErr(true);
            }
            if(!Address)
            {
                SetAddressErr(true);
            }
        }
    }

    const filterstate = async (e) => {  

        setuserCountry(e);

        var state_list = State.getAllStates();

        var state_list_filtered = state_list.filter((state)=>state.countryCode===e.value);

        var state_list_array = [];

        state_list_filtered.forEach(element => {
            state_list_array.push({"label":element.name,"value":element.isoCode});
        });

        if(state_list_array.length > 0)
        {
            setstateList(state_list_array);   
            setuserState('');    
            setuserCity('');
            setcityList([]);
        }
        else
        {
            setuserState(e);
            setuserCity(e);
            setstateList([]);
            setcityList([]);
        }
    }

    const filtercity = async (e) => { 
 
        setuserState(e);

        var city_list = City.getAllCities();

        var city_list_filtered = city_list.filter((city)=>city.countryCode===userCountry.value && city.stateCode === e.value);

        var city_list_array = [];

        city_list_filtered.forEach(element => {
            city_list_array.push({"label":element.name,"value":element.name});
        });

        
        if(city_list_array.length > 0)
        {
            setcityList(city_list_array);
            setuserCity('');
        }
        else
        {
            setuserCity(e);
            setcityList([]);
        }
    }


    const [showAdd, SetshowAdd] = useState(false);
    const handleClosesAdd = () => { SetshowAdd(false) }
    const [loader, SetloaderShow] = useState(false);
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                <div className="container">
                    <div className="row">
                        <div className="row add_member_coach_heading_row">
                            <span className="add_member_coach_heading">Add Member</span>
                        </div>

                        <div className="row input_rows_add_client">
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client First Name</label><br />
                                <input type='text' value={FirstName} className="add_category_inner_input billable_amount" onChange={(e) => { SetFirstName(e.target.value) }}  {...(FNameErr === true ? { id: 'err_border' } : {})} />
                                {FNameErr === true &&
                                    <span className="err_msg_add_client">*Please enter first name.!</span>
                                }
                            </div>
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Last Name</label><br />
                                <input type='text' value={LastName} className="add_category_inner_input billable_amount" onChange={(e) => { SetLastName(e.target.value) }} {...(LNameErr === true ? { id: 'err_border' } : {})} />
                                {LNameErr === true &&
                                    <span className="err_msg_add_client">*Please enter last name.!</span>
                                }
                            </div>
                        </div>

                        <div className="row input_rows_add_client">
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Email Address</label><br />
                                <input type='text' value={EmailAddress} className="add_category_inner_input billable_amount" onChange={(e) => { SetEmailAddress(e.target.value) }} {...(EmailErr === true ? { id: 'err_border' } : {})} />
                                {EmailErr === true &&
                                    <span className="err_msg_add_client">{EmailErrMsg}</span>
                                }
                            </div>  

                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Phone Number</label><br />

                                <PhoneInput
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={PhoneNumber}
                                onChange={SetPhoneNumber}
                                ref={EditPhoneRef}/>

                                {PhoneErr === true &&
                                    <span className="err_msg_add_client">{PhoneErrMsg}</span>
                                }
                            </div>                            
                        </div>

                        <div className="row input_rows_add_client">    
                            <div className="col">
                                <label className="commission_inner_popup_label required">Client Gender</label><br />
                                <span>
                                    <label className="radio-inline client_add_member_option">
                                        <input type="radio" name="optradio" value={'MALE'} onChange={(e) => { SetGender(e.target.value) }} />Male
                                    </label>
                                    <label className="radio-inline client_add_member_option">
                                        <input type="radio" name="optradio" value={'FEMALE'} onChange={(e) => { SetGender(e.target.value) }} />Female
                                    </label>
                                </span>
                                <br />
                                {GenderErr === true &&
                                    <span className="err_msg_add_client">*Please select gender.!</span>}
                            </div>

                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Country</label><br />
                                <Select
                                    options={countryList}
                                    onChange={filterstate}
                                    {...(CountryErr === true ? { id: 'err_border' } : {})}
                                    value={userCountry}
                                />
                                {CountryErr === true &&
                                    <span className="err_msg_add_client">*Please select country.!</span>}
                            </div>
                        </div>

                        <div className="row input_rows_add_client">    
                        <div className="col">
                                <label className='commission_inner_popup_label required'>Client State</label><br />
                                <Select
                                    options={stateList}
                                    onChange={filtercity}
                                    {...(StateErr === true ? { id: 'err_border' } : {})}
                                    value={userState}
                                />
                                {StateErr === true &&
                                    <span className="err_msg_add_client">*Please select state.!</span>}
                            </div>

                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client City</label><br />
                                <Select
                                    options={cityList}
                                    onChange={setuserCity}
                                    {...(CityErr === true ? { id: 'err_border' } : {})}
                                    value={userCity}
                                />
                                {CityErr === true &&
                                    <span className="err_msg_add_client">*Please select city.!</span>}
                            </div>
                        </div>

                        <div className="row input_rows_add_client ">
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Address</label><br />
                                <input type='text' value={Address} className="add_category_inner_input billable_amount" onChange={(e) => { SetAddress(e.target.value) }} {...(AddressErr === true ? { id: 'err_border' } : {})}/>
                                {AddressErr === true &&
                                    <span className="err_msg_add_client">*Please enter address.!</span>}
                            </div>

                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Postal Code</label><br />
                                <input type='text' value={Postal} className="add_category_inner_input billable_amount" onChange={(e) => { SetPostal(e.target.value) }} {...(PostalErr === true ? { id: 'err_border' } : {})} />

                                {PostalErr === true &&
                                    <span className="err_msg_add_client">{PostalErrMsg}</span>}
                            </div>
                        </div>

                        <div className="row input_rows_add_client last_row_add_member">
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Timezone</label><br />
                                <Select
                                    options={TImeZoneList}
                                    onChange={setTimeZone}
                                    className="clientadd_timezone"
                                    {...(TimeZoneErr === true ? { id: 'err_border' } : {})}
                                    value={TimeZone}
                                />

                                {TimeZoneErr === true &&
                                    <span className="err_msg_add_client">*Please select timezone.!</span>}
                            </div>
                            <div className="col">
                                <label className='commission_inner_popup_label required'>Client Password</label><br />
                                <input type='text' value={Password} className="add_category_inner_input billable_amount" onChange={(e) => { SetPassword(e.target.value) }} {...(PasswordErr === true ? { id: 'err_border' } : {})} />
                                {PasswordErr === true &&
                                    <span className="err_msg_add_client">*Please enter password.!</span>
                                }
                            </div>
                        </div>                        
                        <div className="row add_member_button_row">
                            <div>
                                <button type="button" className="btn btn-success" onClick={AddMember}>Add Member</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={showAdd} onHide={handleClosesAdd} className="coach_add_member confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{"Alert"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row meal_type_error" >
                            {PopupMessage}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" className="add_button_progress" onClick={handleClosesAdd}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </div>
    )
}

export default AddMemberByClient
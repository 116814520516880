import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "react-js-pagination";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import VisitDetails from "./VisitDetails";
import BelugaVisitForm from "./BelugaVisitForm";
import BelugaVisitFormNew from "./BelugaVisitFormNew";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PremiumCheckGLPComponent from "../PremiumPlanGLP";


const BelugaVisits = () => {
  const side_nav = localStorage.getItem("side_nav");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");

  const [VisitLog, SetVisitLog] = useState([]);
  const [TotalRecord, SetTotalRecord] = useState(0);
  const [CurrentPage, SetCurrentPage] = useState();
  const [PerPage, SetPerPage] = useState(10);
  const [DetailId, SetDetailId] = useState("");
  const [ShowVisitForm,SetShowVisitForm]=useState(false)
  const [ShowQ, SetShowQ] = useState(false);
  const [SearchValue,SetSearchValue]=useState('')
  const [ClientPlanAllowedMedicine,SetClientPlanAllowedMedicine]=useState([])

  const [GLpUser,SetGLpUser]=useState('')
  const [personaId, SetPersonaId] = useState('');

  const GetClientVisitsLog = async (pagenumber) => {
    SetCurrentPage(pagenumber);

    try{

        const GetResponse = await axiosJWT.get(
            "beluga/get-client-visits-log?offset=" +
              pagenumber +
              "&limit=" +
              PerPage +
              "&s=" +
              SearchValue +
              "",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

        const personaData = await axiosJWT.get('beluga/get-personaid', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        // console.log('personaData', personaData);
        if(personaData.status === 200) {
          SetPersonaId(personaData.data.personaId.persona_inquiryId);
        }
        // console.log(personaId);
      
        // console.log('GetResponse', GetResponse);
        if (GetResponse.data !== null) {

    
          SetTotalRecord(GetResponse.data.total_count);
          SetVisitLog(GetResponse.data.GetResponse);
          SetGLpUser(GetResponse.data.is_glp_user.is_glp_user)
        }

    }catch(err)
    {
        console.log(err)
    }

   
  };

  const GetClientAllowedPlanMedicines=async()=>{
    SetClientPlanAllowedMedicine([])

    try{

      const UserDetails = await axiosJWT.get("/profile-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if(UserDetails.data)
        {
          if (UserDetails.data.profile_details?.[0]?.client_infos?.[0]?.client_plan?.plan_glp_medicine) {

            if(UserDetails.data.profile_details[0].client_infos[0].client_plan.plan_glp_medicine!=='')
              {
                var array = UserDetails.data.profile_details[0].client_infos[0].client_plan.plan_glp_medicine.split(",");
                SetClientPlanAllowedMedicine(array)
             
            
              }
           
          }
        }


    }catch(err)
    {
      console.log(err)
    }
 
  }



  const changePageNumber = (pagenumber) => {
    GetClientVisitsLog(pagenumber);
  };

  function timetoUtcClientAppointment(passdate) {
    var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");

    return stardates;
  }

  const ViewDetailsOftheVisit = (e) => {
    var Id = e.target.getAttribute("data-id");
    SetDetailId(Id);
   
  };
  const CloseDetailView=()=>{
    SetDetailId('')
  }

  const SubmitAVisit=()=>{
    SetShowVisitForm(true)
    SetShowQ(true)
  }

  useEffect(() => {
    GetClientVisitsLog(1);
    GetClientAllowedPlanMedicines()
  }, []);

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      <PremiumCheckGLPComponent GLpUser={GLpUser}/>
      <section className="dashboard">
        <div className="client_appointment container">
          <div className="row client_top_appointment_row beluga_visit_Top_heading_row">
            <span className="client_top_appointment_row_span">
              Beluga Health - Visits Log
            </span>
            {
                DetailId!=='' ?   <button className="view_button" onClick={CloseDetailView}>Back</button>:null
            }
            {
                DetailId==='' ?   <button className="view_button" onClick={SubmitAVisit}>Create a follow up</button>:null
            }
          
          </div>

          {DetailId === "" ? (
            <div className="row beluga_visit_container beluga_visit_container_table" style={{ display: "block" }}>

           
              <div className="even-columns">
                <div className="input"><input type="text" defaultValue={SearchValue} onChange={(e)=>{SetSearchValue(e.target.value)}} onBlur={()=>{GetClientVisitsLog(CurrentPage)}} placeholder="Search by visit id..."/></div>
                <div className="button"><button onClick={()=>{GetClientVisitsLog(CurrentPage)}}><i class="fa fa-search"></i></button></div>
                </div>
             

             
              <Table className="table is-striped is-fullwidth">
                <Thead className="thead ">
                  <Tr className="trow">
                    <Th className="row_name">Persona Id</Th>
                    <Th className="row_name">Master Id/Visit Id</Th>
                    <Th className="row_name">Visit Type</Th>
                    <Th className="row_name">Submitted Date</Th>
                    <Th className="row_name">Visit Status</Th>
                    <Th className="row_name">Action</Th>
                  </Tr>
                </Thead>

                {VisitLog.length > 0 && (
                  <Tbody className="tbody">
                    {VisitLog.length > 0 &&
                      VisitLog.map((visit, index) => (
                        <Tr className="trow" key={visit.id}>
                          <Td>
                            <span className="">{personaId}</span>
                          </Td>
                          <Td>
                            <span className="">{visit.visitId}</span>
                          </Td>
                          <Td>
                            <span className="">{visit.visitType}</span>
                          </Td>
                          <Td>
                            <span className="">
                              {format(
                                parseISO(
                                  timetoUtcClientAppointment(
                                    visit.submitted_date
                                  )
                                ),
                                `MMM dd, yyyy  hh:mm a`
                              )}
                            </span>
                          </Td>
                          <Td>
                            <span className="">{(visit.visit_status!==null && visit.visit_status!='') ? visit.visit_status : '-'}</span>
                          </Td>
                          <Td>
                            <button
                              className="view_button"
                              data-id={visit.id}
                              onClick={ViewDetailsOftheVisit}
                            >
                              View
                            </button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
              <div>
                {TotalRecord ? (
                  <Pagination
                    activePage={CurrentPage}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecord}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers color_grey"
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {DetailId !== "" ? <VisitDetails DetailId={DetailId} /> : null}
          

           <Modal show={ShowQ} className="confirm_delete premim_plan_upgrade beluga_health_visit_form">
                <Modal.Header>
                    <Modal.Title>{'Beluga Health Visit Form'}<span className="close_icon" onClick={()=>{SetShowQ(false)}}><i className="fa fa-times-circle"></i></span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main_container_quiz">
                        <div className="top_logo">
                            <img src={''} />
                        </div>
                        <div>
                        {
            ShowVisitForm===true ?  <BelugaVisitFormNew  SetShowQ={SetShowQ} GetClientVisitsLog={GetClientVisitsLog} ClientPlanAllowedMedicine={ClientPlanAllowedMedicine}/> : null
           
          }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>





        </div>
      </section>
    </div>
  );
};
export default BelugaVisits;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { format } from 'date-fns'
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';


const Library = (props) => {

    const [PercentageArray, SetPercentageArray] = useState([])
    const [RecipeProtein, SetRecipeProtein] = useState(0)
    const [RecipeFat, SetRecipeFat] = useState(0)
    const [RecipeCarbs, SetRecipeCarbs] = useState(0);
    const [RecipeCalories, SetRecipeCalories] = useState(0)
    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {

                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    // console.log(percentage)
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };



    function openNav() {
        document.getElementById("mySidepanel").style.display = "block";
    }

    function closeNav() {
        document.getElementById("mySidepanel").style.display = "none";
    }
    
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    var [recipetab, setRecipeTabMenu] = useState('ingredients');

    const [profileData, setprofileData] = useState('');
    const [profileTab, setprofileTab] = useState('progress');

    useEffect(() => {
        load_profile();
        InitialLoadFavourites()
    }, []);

    const axiosJWT = axios.create();
    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const [MealTypErr, SetMealTypErr] = useState(false)
    const [MealTypErrMsg, SetMealTypErrMsg] = useState('')





    const load_profile = async () => {

        setprofileTab('');

        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response.data);
            setprofileData(response.data);

            /*const allusers = document.querySelectorAll(".users_list .user_data");
            allusers.forEach((element) => element.classList.remove('active'));
    
            document.getElementById("user_"+userId).setAttribute("class", "user_data active");        
              
            setprofileTab('progress');*/
        });
    }

    const [recipeslist, Setrecipeslist] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [recipeData, setrecipeData] = useState('');
    const [SearchValue, SetSearchValue] = useState('');
    const [RecordPerPage, SetRecordPerPage] = useState(30)
    const [PageNumber, SetPageNumber] = useState(1)
    const [TotalRecord, SetTotalRecord] = useState(0)
    const [DurationSelected, SetDurationSelected] = useState('')
    const [MealTypeSelected, SetMealTypeSelected] = useState('')
    const [MealIngridientSelected, SetMealIngridientSelected] = useState([])
    const [SpecialDiet, SetSpecialDiet] = useState('')
    const [SearchPagination, SetSearchPagination] = useState(false)
    const [FilterPagination, SetFilterPagination] = useState(false)
    const [PageNumberFilter, SetPageNumberFilter] = useState(1)
    const [RecordPerPageFilter, SetRecordPerPageFilter] = useState(10)
    const [TotalRecordFilter, SetTotalRecordFilter] = useState(0)

    var CheckedMealIngredientArray = [];


    const showRecipe = async (e) => {
        const recipeId = e.target.getAttribute("data-item");
        axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {

            setShow(true);
            setrecipeData(res.data);
            SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)])
            SetRecipeProtein(res.data.NutritionInfo.Protein)
            SetRecipeFat(res.data.NutritionInfo.TotalFat)
            SetRecipeCarbs(res.data.NutritionInfo.TotalCarbs)
            SetRecipeCalories(res.data.NutritionInfo.TotalCalories)
            //console.log(res.data);
        });
    }

    const removeFavorites = async (e) => {

        var recipe_id = e.target.getAttribute("data-item");


        e.target.classList.remove('fav');

        var response = await axiosJWT.put("/week-diet-plan/favorites",
            {
                recipeId: recipe_id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        InitialLoadFavourites()
    }
    const AddFavourites = async (e) => {

        var recipe_id = e.target.getAttribute("data-item");


        e.target.classList.add('fav');

        var response = await axiosJWT.post("/week-diet-plan/favorites",
            {
                recipeId: recipe_id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        InitialLoadFavourites()

    }
    const searchReciepe = async (pagenumber) => {



        Setrecipeslist([])
        var url = '';

        if (pagenumber !== '') {
            url = '' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipes?any_kw=' + SearchValue + '&rpp=' + RecordPerPage + '&pg=' + pagenumber + '&api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '&photos=true'
        }
        else {
            url = '' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipes?any_kw=' + SearchValue + '&rpp=' + RecordPerPage + '&pg=' + PageNumber + '&api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '&photos=true'
        }

        if (SearchValue !== '') {

            ShowLoader()


            SetSearchPagination(true)
            SetFilterPagination(false)

            const response = await axiosJWT.get(url, {

            }).then((response) => {
                var total_record = response.data.ResultCount
                var TempArrayPush = [];

                if (total_record === 0) {
                    HideLoader()
                }

                response.data.Results.forEach((value, index, array) => {
                    const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                    }).then((response) => {

                        if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                            //used for limit the recipe display to particular count
                            TempArrayPush.push(response.data);
                        }

                    }).then(() => {

                        if (index === array.length - 1) {

                            setTimeout(function () {

                                Setrecipeslist(TempArrayPush)
                                SetTotalRecord(total_record / RecordPerPage)
                                HideLoader()
                            }, 1000);
                        }
                        // console.log(TempArrayPush)
                    });
                });
                // Setrecipeslist(response.data.Results) 
            });
        }
    }

    const changePageNumber = (pagenumber) => {

        SetPageNumber(pagenumber)
        searchReciepe(pagenumber)
    }

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }





    const ChangedIngredient = (e) => {



        if (SpecialDiet === '') {

            if (e.target.value === 'No-Meat') {


                if (e.target.checked) {

                    var chks = document.querySelectorAll("#mySidepanel .meatcheckbox  input[type='checkbox']")
                    for (var i = 0; i < chks.length; i++) {
                        var check = chks[i];
                        if (!check.disabled) {
                            check.checked = false;
                        }
                    }

                    var array = MealIngridientSelected; // make a separate copy of the array
                    var index = array.indexOf('Chicken')
                    if (index !== -1) {
                        array.splice(index, 1);
                        SetMealIngridientSelected(array)
                    }
                    index = array.indexOf('Pork')
                    if (index !== -1) {
                        array.splice(index, 1);
                        SetMealIngridientSelected(array)
                    }
                    index = array.indexOf('Beef')
                    if (index !== -1) {
                        array.splice(index, 1);
                        SetMealIngridientSelected(array)
                    }
                    index = array.indexOf('Fish')
                    if (index !== -1) {
                        array.splice(index, 1);
                        SetMealIngridientSelected(array)
                    }
                    index = array.indexOf('Bacon')
                    if (index !== -1) {
                        array.splice(index, 1);
                        SetMealIngridientSelected(array)
                    }


                    document.querySelectorAll(".side_nav_checks .meatcheckbox input[type=checkbox][value='" + e.target.value + "']")[0].checked = true;


                }
            }

            if (e.target.checked) {




                if (e.target.value === 'Chicken' || e.target.value === 'Pork' || e.target.value === 'Beef' || e.target.value === 'Fish' || e.target.value === 'Bacon') {




                    if ((document.querySelectorAll(".side_nav_checks .meatcheckbox input[type=checkbox][value='No-Meat']")[0].checked)) {
                        var array = MealIngridientSelected; // make a separate copy of the array
                        var index = array.indexOf('Chicken')
                        if (index !== -1) {
                            array.splice(index, 1);
                            SetMealIngridientSelected(array)
                        }
                        index = array.indexOf('Pork')
                        if (index !== -1) {
                            array.splice(index, 1);
                            SetMealIngridientSelected(array)
                        }
                        index = array.indexOf('Beef')
                        if (index !== -1) {
                            array.splice(index, 1);
                            SetMealIngridientSelected(array)
                        }
                        index = array.indexOf('Fish')
                        if (index !== -1) {
                            array.splice(index, 1);
                            SetMealIngridientSelected(array)
                        }
                        index = array.indexOf('Bacon')
                        if (index !== -1) {
                            array.splice(index, 1);
                            SetMealIngridientSelected(array)
                        }

                        document.querySelectorAll(".side_nav_checks .meatcheckbox input[type=checkbox][value='" + e.target.value + "']")[0].checked = false;

                        e.preventDefault();
                    }
                    else {
                        CheckedMealIngredientArray.push(e.target.value)
                        SetMealIngridientSelected(CheckedMealIngredientArray => [...CheckedMealIngredientArray, e.target.value]);
                    }

                }
                else {

                    CheckedMealIngredientArray.push(e.target.value)
                    SetMealIngridientSelected(CheckedMealIngredientArray => [...CheckedMealIngredientArray, e.target.value]);
                }






            } else {
                var array = MealIngridientSelected; // make a separate copy of the array
                var index = array.indexOf(e.target.value)
                if (index !== -1) {
                    array.splice(index, 1);
                    SetMealIngridientSelected(array)

                }

            }

        }
        else {
            SetMealIngridientSelected([])
        }







    }


    const ApplyFilter = async (page) => {



        SetMealTypErr(false)

        Setrecipeslist([])
        SetTotalRecord(0)


        SetSearchPagination(false)
        SetFilterPagination(true)



        var Duration = DurationSelected
        var MealType = MealTypeSelected
        var Ingredients = MealIngridientSelected
        if (page !== '') {
            var Pno = page;
        }
        else {
            var Pno = PageNumberFilter;
        }

        SetPageNumberFilter(Pno)

        var InitialArray = [];
        var TempArray = [];
        var InitialPageCountarray = []
        var SplDiet = SpecialDiet




        if (MealType === '') {
            SetMealTypErr(true)
            SetMealTypErrMsg('Please select meal type')

        }
        else {
            closeNav()

            ShowLoader()

            if (MealType !== 'all_meal') {

                if (Ingredients.length === 0 && SplDiet === '') {

                    var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY
                    if (Duration !== '') {
                        url = url + "&totalMins=" + Duration + ""
                    }

                    const response = axiosJWT.get(url, {

                    }).then((response) => {

                        InitialArray = InitialArray.concat(response.data.Results);
                        InitialPageCountarray.push(response.data.ResultCount)

                        if (response.data.ResultCount === 0) {
                            HideLoader()
                        }

                        var i;
                        var largest = 0;

                        for (i = 0; i <= largest; i++) {
                            if (InitialPageCountarray[i] > largest) {

                                largest = InitialPageCountarray[i];
                            }
                        }

                        InitialArray.forEach((value, index, array) => {
                            const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                            }).then((response) => {

                                if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                    //used for limit the recipe display to particular count
                                    TempArray.push(response.data);
                                }

                            }).then(() => {

                                if (index === array.length - 1) {

                                    setTimeout(function () {

                                        Setrecipeslist(TempArray)
                                        SetTotalRecordFilter(largest / RecordPerPage)
                                        HideLoader()

                                    }, 1000);

                                }

                            })

                        })

                    })

                }

                if (Ingredients.length > 0) {

                    Ingredients.forEach((Ingredient, index, array) => {


                        if (Ingredient === 'No-Meat') {

                            var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?coll=292&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '&any_kw=' + MealType + '';

                            if (Duration !== '') {
                                url = url + "&totalMins=" + Duration + ""
                            }

                        }
                        else {

                            var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&include_ing=" + Ingredient;
                            if (Duration !== '') {
                                url = url + "&totalMins=" + Duration + ""
                            }



                        }

                        const response = axiosJWT.get(url, {

                        }).then((response) => {

                            InitialArray = InitialArray.concat(response.data.Results);
                            InitialPageCountarray.push(response.data.ResultCount)

                            if (response.data.ResultCount === 0) {
                                HideLoader()
                            }

                            var i;
                            var largest = 0;

                            for (i = 0; i <= largest; i++) {
                                if (InitialPageCountarray[i] > largest) {

                                    largest = InitialPageCountarray[i];
                                }
                            }

                            InitialArray.forEach((value, index, array) => {
                                const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                }).then((response) => {

                                    if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                        //used for limit the recipe display to particular count
                                        TempArray.push(response.data);
                                    }

                                }).then(() => {

                                    if (index === array.length - 1) {

                                        setTimeout(function () {

                                            Setrecipeslist(TempArray)
                                            SetTotalRecordFilter(largest / RecordPerPage)
                                            HideLoader()

                                        }, 1000);

                                    }

                                })

                            })

                        })
                    })

                }


                if (SplDiet !== '') {
                    if (SplDiet === 'pal') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&coll=298"
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'rmf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&coll=98"
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'sff') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=wheat free&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'slf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Coeliac&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'tnf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Grain Free&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'wmf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Grain Free&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'ntf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=low fodmap&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'ntf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=low fodmap&any_kw=" + MealType + "&photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else {

                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&";
                        url = url + SplDiet + '=1';

                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }

                    }

                    const response = axiosJWT.get(url, {

                    }).then((response) => {

                        InitialArray = InitialArray.concat(response.data.Results);
                        InitialPageCountarray.push(response.data.ResultCount)

                        if (response.data.ResultCount === 0) {
                            HideLoader()
                        }
                    }).then(() => {




                        var i;
                        var largest = 0;

                        for (i = 0; i <= largest; i++) {
                            if (InitialPageCountarray[i] > largest) {

                                largest = InitialPageCountarray[i];
                            }
                        }


                        InitialArray.forEach((value, index, array) => {
                            const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                            }).then((response) => {

                                if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                    //used for limit the recipe display to particular count
                                    TempArray.push(response.data);
                                }

                            }).then(() => {

                                if (index === array.length - 1) {

                                    setTimeout(function () {

                                        Setrecipeslist(TempArray)
                                        SetTotalRecordFilter(largest / RecordPerPageFilter)
                                        HideLoader()
                                    }, 1000);

                                }

                            })

                        })

                    })
                }

            }
            else {


                if (Ingredients.length === 0 && SplDiet === '') {

                    var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPageFilter + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY
                    if (Duration !== '') {
                        url = url + "&totalMins=" + Duration + ""
                    }

                    const response = axiosJWT.get(url, {

                    }).then((response) => {

                        InitialArray = InitialArray.concat(response.data.Results);
                        InitialPageCountarray.push(response.data.ResultCount)

                        if (response.data.ResultCount === 0) {
                            HideLoader()
                        }

                        var i;
                        var largest = 0;

                        for (i = 0; i <= largest; i++) {
                            if (InitialPageCountarray[i] > largest) {

                                largest = InitialPageCountarray[i];
                            }
                        }

                        InitialArray.forEach((value, index, array) => {
                            const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                            }).then((response) => {

                                if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                    //used for limit the recipe display to particular count
                                    TempArray.push(response.data);
                                }

                            }).then(() => {

                                if (index === array.length - 1) {

                                    setTimeout(function () {

                                        Setrecipeslist(TempArray)
                                        SetTotalRecordFilter(largest / RecordPerPage)
                                        HideLoader()

                                    }, 1000);

                                }

                            })

                        })

                    })

                }



                if (Ingredients.length > 0) {

                    Ingredients.forEach((Ingredient, index, array) => {


                        if (Ingredient === 'No-Meat') {

                            var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?coll=292&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '';

                            if (Duration !== '') {
                                url = url + "&totalMins=" + Duration + ""
                            }

                        }
                        else {

                            var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&include_ing=" + Ingredient;
                            if (Duration !== '') {
                                url = url + "&totalMins=" + Duration + ""
                            }



                        }

                        const response = axiosJWT.get(url, {

                        }).then((response) => {

                            InitialArray = InitialArray.concat(response.data.Results);
                            InitialPageCountarray.push(response.data.ResultCount)

                            if (response.data.ResultCount === 0) {
                                HideLoader()
                            }

                            var i;
                            var largest = 0;

                            for (i = 0; i <= largest; i++) {
                                if (InitialPageCountarray[i] > largest) {

                                    largest = InitialPageCountarray[i];
                                }
                            }

                            InitialArray.forEach((value, index, array) => {
                                const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                }).then((response) => {

                                    if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                        //used for limit the recipe display to particular count
                                        TempArray.push(response.data);
                                    }

                                }).then(() => {

                                    if (index === array.length - 1) {

                                        setTimeout(function () {

                                            Setrecipeslist(TempArray)
                                            SetTotalRecordFilter(largest / RecordPerPage)
                                            HideLoader()
                                        }, 1000);

                                    }

                                })

                            })

                        })
                    })

                }


                if (SplDiet !== '') {
                    if (SplDiet === 'pal') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&coll=298"
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'rmf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&coll=98"
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'sff') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=wheat free&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'slf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Coeliac&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }

                    else if (SplDiet === 'tnf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Grain Free&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'wmf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=Grain Free&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'ntf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=low fodmap&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else if (SplDiet === 'ntf') {
                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=low fodmap&photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + ""
                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }
                    }
                    else {

                        var url = "" + process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?photos=true&rpp=" + RecordPerPage + "&pg=" + Pno + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY + "&";
                        url = url + SplDiet + '=1';

                        if (Duration !== '') {
                            url = url + "&totalMins=" + Duration + ""
                        }

                    }

                    const response = axiosJWT.get(url, {

                    }).then((response) => {

                        InitialArray = InitialArray.concat(response.data.Results);
                        InitialPageCountarray.push(response.data.ResultCount)

                        if (response.data.ResultCount === 0) {
                            HideLoader()
                        }
                    }).then(() => {



                        var i;
                        var largest = 0;

                        for (i = 0; i <= largest; i++) {
                            if (InitialPageCountarray[i] > largest) {

                                largest = InitialPageCountarray[i];
                            }
                        }

                        InitialArray.forEach((value, index, array) => {
                            const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/steps/' + value.RecipeID + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                            }).then((response) => {

                                if (response.data.NutritionInfo.TotalCalories !== '' && response.data.NutritionInfo.TotalCalories > 50 && response.data.NutritionInfo.TotalCalories < 1000) {
                                    //used for limit the recipe display to particular count
                                    TempArray.push(response.data);
                                }

                            }).then(() => {

                                if (index === array.length - 1) {

                                    setTimeout(function () {

                                        Setrecipeslist(TempArray)
                                        SetTotalRecordFilter(largest / RecordPerPage)
                                        HideLoader()
                                    }, 1000);

                                }

                            })

                        })
                    })
                }

            }



        }

    }

    const ChangedSplDiet = (e) => {




        var chks = document.querySelectorAll("#mySidepanel .meatcheckbox  input[type='checkbox']")
        for (var i = 0; i < chks.length; i++) {
            var check = chks[i];
            if (!check.disabled) {
                check.checked = false;
            }
        }

        var chksVeg = document.querySelectorAll("#mySidepanel .vegcheckbox   input[type='checkbox']")
        for (var i = 0; i < chksVeg.length; i++) {
            var check = chksVeg[i];
            if (!check.disabled) {
                check.checked = false;
            }
        }

        var chksOt = document.querySelectorAll("#mySidepanel .otherscheckbox    input[type='checkbox']")
        for (var i = 0; i < chksOt.length; i++) {
            var check = chksOt[i];
            if (!check.disabled) {
                check.checked = false;
            }
        }

        SetMealIngridientSelected([])

        SetSpecialDiet(e.target.value)



    }

    const changePageNumberFilter = (pagenumber) => {

        SetPageNumberFilter(pagenumber)
        ApplyFilter(pagenumber)



    }


    const ResetFilter = () => {

        var chks = document.querySelectorAll("#mySidepanel input[type='checkbox']")

        var chksR = document.querySelectorAll("#mySidepanel input[type='radio']")

        for (var i = 0; i < chks.length; i++) {
            var check = chks[i];
            if (!check.disabled) {
                check.checked = false;
            }
        }

        for (var i = 0; i < chksR.length; i++) {
            var check = chksR[i];
            if (!check.disabled) {
                check.checked = false;
            }
        }

        SetSpecialDiet('')
        SetDurationSelected('')
        SetMealIngridientSelected([])
        SetMealTypeSelected('')

    }

    const [showAdd, SetshowAdd] = useState(false)
    const handleClosesAdd = () => { SetshowAdd(false) }
    const [FavoutiesArray, SetFavoutiesArray] = useState([])


    const InitialLoadFavourites = async () => {

        var response = await axiosJWT.get("/week-diet-plan/favorites", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data && response.data !== null && response.data.favorites !== undefined && response.data.favorites !== null && response.data.favorites !== '') {

            let text = response.data.favorites;
            var FavouritesArray = text.split(",");
            SetFavoutiesArray(FavouritesArray);
            // console.log(FavouritesArray)
        }
    }

    return (
        <div className='favorites_tab library_tab'>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            {profileData ?
                <div>
                    <div className='topcontent' style={{ display: 'none' }} >
                        <div className='user_info' data-item={profileData.id}>
                            <div className='header_box'>
                                <div className='input_fields'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='inner_label'>Age</label><br />
                                            <div className='input_value'> {profileData.user_dob && profileData.user_dob != "0000-00-00" ? (format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy')):null} Years</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Height</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Weight</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Target Weight</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='input_fields'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='inner_label'>Activity Level</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].activity}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Water Intake</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Daily Calories</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_carb}-{profileData.client_infos[0].daily_fat}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div style={{ "clear": "both" }} />
                    </div>
                    <div className='tabcontent'>
                        <div className='row library_top_row_meal_plan'>
                            <div className='library_content_button_row'>
                                <button type="button" className="btn btn-success todays_activity_button filter_reciepe_button" onClick={openNav} >☰ Filter reciepe</button>
                                <div className='search_reciepe_div'>
                                    <button type="button" className="btn btn-success" onClick={searchReciepe} >Search recipe</button>
                                    <input type='text' className="add_category_inner_input total_session" onChange={(e) => { SetSearchValue(e.target.value) }} />

                                </div>
                            </div>
                            <div className='recipes'>
                                {recipeslist ?
                                    <div>
                                        {recipeslist.map((recipe, index) => (
                                            <div className='recipe_box' key={index} >
                                                <i className='fa fa-ellipsis-v viewdata' data-item={recipe.RecipeID} onClick={(e) => showRecipe(e)}></i>



                                                {FavoutiesArray.includes(recipe.RecipeID.toString()) ?
                                                    <i className='fa fa-heart viewheart fav' data-item={recipe.RecipeID} onClick={(e) => removeFavorites(e)}></i> : <i className='fa fa-heart viewheart ' data-item={recipe.RecipeID} onClick={(e) => AddFavourites(e)}></i>
                                                }

                                                <div className='img'><img src={recipe.PhotoUrl} data-item={recipe.RecipeID} /></div>

                                                <div className='title'>{recipe.Title}</div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }

            <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.Title}</div>
                            <div className='img'><img src={recipeData.PhotoUrl} /></div>
                            <div className='nutrition_data'>

                                <p className='pie_chart_kcal_data'>Calories: {RecipeCalories.toFixed(2)} Kcal</p>
                                <Pie data={data} options={options} />

                                <hr />
                                <div className="pie_chart_bottom_div">
                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeCarbs.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeFat.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeProtein.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    {/* <span>
                            <span className="dotcalories"></span>
                            <span className="pie_chart_bottom_div_label">Calories</span>
                            <span className="pie_chart_bottom_div_data"><span>{RecipeCalories.toFixed(2)}</span><span>G</span></span>
                            </span> */}
                                </div>

                                {/* <div className='cal'>Calories:<br/>{(recipeData.NutritionInfo.TotalCalories).toFixed(2)}</div>
                                        <div className='carb'>Carbs:<br/>{(recipeData.NutritionInfo.TotalCarbs).toFixed(2)} g</div>
                                        <div className='fat'>Fat:<br/>{(recipeData.NutritionInfo.TotalFat).toFixed(2)} g</div>
                                        <div className='pro'>Protein:<br/>{(recipeData.NutritionInfo.Protein).toFixed(2)} g</div> */}
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <ul>
                                        {(recipeData.Ingredients).map((ing, index) => (
                                            <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                        ))}
                                    </ul>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                    <ol>
                                        {(recipeData.Steps).map((ing, index) => (
                                            <li key={index}>{ing.Text}</li>
                                        ))}
                                    </ol>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button' onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>


            {SearchPagination === true &&
                <div className='row pagination_row_library_search'>
                    <Pagination
                        activePage={PageNumber}
                        itemsCountPerPage={RecordPerPage}
                        totalItemsCount={TotalRecord}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                </div>
            }
            {FilterPagination === true &&
                <div className='row pagination_row_library_search filter_pagination'>
                    <Pagination
                        activePage={PageNumberFilter}
                        itemsCountPerPage={RecordPerPageFilter}
                        totalItemsCount={TotalRecordFilter}
                        onChange={changePageNumberFilter.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                </div>
            }
            <div style={{ clear: 'both' }}></div>

            <div id="mySidepanel" className="sidepanel side_nav_checks" style={{display:"none"}}>
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>×</a>

                <div>
                    <div className="mintypebox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Prep Time</span>
                        <div id="checkboxes">
                            <input type="radio" id="checkbox-2" name="checkbox-2" defaultValue={''} onClick={(e) => { SetDurationSelected(e.target.value) }} /> All Duration</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-2" name="checkbox-2" defaultValue={'15'} onClick={(e) => { SetDurationSelected(e.target.value) }} /> 15 Mins</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-2" name="checkbox-2" defaultValue={'30'} onClick={(e) => { SetDurationSelected(e.target.value) }} /> 30 Mins</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-2" name="checkbox-2" defaultValue={'60'} onClick={(e) => { SetDurationSelected(e.target.value) }} /> 60 Mins</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-2" name="checkbox-2" defaultValue={"300"} onClick={(e) => { SetDurationSelected(e.target.value) }} /> Over 60 Mins</div>

                    </div>

                    <div className="meattypebox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Meal Type</span>
                        <div id="checkboxes"><input type="radio" id="checkbox-1" name="checkbox-1" defaultValue={"all_meal"} onClick={(e) => { SetMealTypeSelected(e.target.value) }} /> All meal types</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-1" name="checkbox-1" defaultValue={"breakfast"} onClick={(e) => { SetMealTypeSelected(e.target.value) }} /> Breakfast</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-1" name="checkbox-1" defaultValue={"lunch"} onClick={(e) => { SetMealTypeSelected(e.target.value) }} /> Lunch</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-1" name="checkbox-1" defaultValue={"snacks"} onClick={(e) => { SetMealTypeSelected(e.target.value) }} /> Snacks</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-1" name="checkbox-1" defaultValue={"dinner"} onClick={(e) => { SetMealTypeSelected(e.target.value) }} /> Dinner</div>

                    </div>
                    <div className="meatcheckbox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Meat</span>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"Chicken"} onChange={(e) => { ChangedIngredient(e) }} /> Chicken</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"Pork"} onChange={(e) => { ChangedIngredient(e) }} /> Pork</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"Beef"} onChange={(e) => { ChangedIngredient(e) }} /> Beef</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"Fish"} onChange={(e) => { ChangedIngredient(e) }} /> Fish</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"Bacon"} onChange={(e) => { ChangedIngredient(e) }} /> Bacon</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-1" name="checkbox-1" defaultValue={"No-Meat"} onChange={(e) => { ChangedIngredient(e) }} /> No Meat</div>
                    </div>
                </div>

                <div>

                    <div className="vegcheckbox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Veggies</span>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Broccoli"} onChange={(e) => { ChangedIngredient(e) }} /> Broccoli</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Mushrooms"} onChange={(e) => { ChangedIngredient(e) }} /> Mushrooms</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Zucchini"} onChange={(e) => { ChangedIngredient(e) }} /> Zucchini</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Cauliflower"} onChange={(e) => { ChangedIngredient(e) }} /> Cauliflower</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Asparagus"} onChange={(e) => { ChangedIngredient(e) }} /> Asparagus</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-2" name="checkbox-2" defaultValue={"Avocad"} onChange={(e) => { ChangedIngredient(e) }} /> Avocad</div>
                    </div>
                </div>

                <div>
                    <div className="otherscheckbox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Other Food</span>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Egg"} onChange={(e) => { ChangedIngredient(e) }} /> Egg</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Nuts"} onChange={(e) => { ChangedIngredient(e) }} /> Nuts</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Cheese"} onChange={(e) => { ChangedIngredient(e) }} /> Cheese</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Cottage-Cheese"} onChange={(e) => { ChangedIngredient(e) }} /> Cottage Cheese</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Butter"} onChange={(e) => { ChangedIngredient(e) }} /> Butter</div>
                        <div id="checkboxes"><input type="checkbox" id="checkbox-3" name="checkbox-3" defaultValue={"Coconut"} onChange={(e) => { ChangedIngredient(e) }} /> Coconut</div>
                    </div>

                    <div className="specificcheckbox two_col_check">
                        <span className="ingtitle" style={{ fontSize: "20px" }}>Specific Diets</span>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"vgn"} onChange={(e) => { ChangedSplDiet(e) }} /> Vegan</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"vtn"} onChange={(e) => { ChangedSplDiet(e) }} /> Vegetarian</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"pal"} onChange={(e) => { ChangedSplDiet(e) }} /> Paleo</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"glf"} onChange={(e) => { ChangedSplDiet(e) }} /> Gluten free</div>

                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"dyf"} onChange={(e) => { ChangedSplDiet(e) }} /> Lactose free</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"rmf"} onChange={(e) => { ChangedSplDiet(e) }} /> Pescetarian</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"sff"} onChange={(e) => { ChangedSplDiet(e) }} /> Wheat free</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"slf"} onChange={(e) => { ChangedSplDiet(e) }} /> Coeliac</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"tnf"} onChange={(e) => { ChangedSplDiet(e) }} /> Grain Free</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"wmf"} onChange={(e) => { ChangedSplDiet(e) }} /> Raw</div>
                        <div id="checkboxes"><input type="radio" id="checkbox-3" name="checkbox-3" defaultValue={"ntf"} onChange={(e) => { ChangedSplDiet(e) }} /> Low Fodmap</div>


                    </div>
                    {MealTypErr === true &&
                        <div className='err'>{MealTypErrMsg}</div>
                    }
                </div>

                <button type="button" className="button reset_filter_button" onClick={() => { closeNav() }}>Cancel</button>
                <button type="button" className="button apply_filter_button apply_filter_buttoncss" onClick={() => { ApplyFilter(1) }}>Apply</button>

                <button type="button" className="button clear_filter_button" onClick={ResetFilter}>Reset</button>

            </div>

            <Modal show={showAdd} onHide={handleClosesAdd} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>{"Alert"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div className="row meal_type_error" >
                        Please select meal type
                    </div>



                </Modal.Body>
                <Modal.Footer className='display_block'>

                    <Button variant="primary" className="add_button_progress" onClick={handleClosesAdd}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default Library
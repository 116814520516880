import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Popup from 'reactjs-popup';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
const Commission = () => {

  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [show, setShow] = useState(false);
  const Numreg = new RegExp('^[0-9]+$');

  const handleClose = () => setShow(false);
  const handleShow = async (e) => {

    if (e.target.innerText === 'Add') {
      setShow(true);
      SetCommissionStatus('1');
      SetCommissionLevel('marketplace');
      SetCommissionType('fixed');
      SetCommissionPayout('fixed');
      SetCommissionVal('');
      SetCommissionMin('');
      SetCommissionMax('');
    }
    else if (e.target.innerText === 'Edit') {

      const response = await axiosJWT.get('/manage-commissions?commission_name=marketplace', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {

        if (response.data[0].commission_status === false) {
          SetCommissionStatus('0');
        }
        else if (response.data[0].commission_status === true) {
          SetCommissionStatus('1');
        }

        SetCommissionLevel(response.data[0].commission_name);
        SetCommissionType(response.data[0].commission_type);
        SetCommissionPayout(response.data[0].commission_payout);
        SetCommissionVal(response.data[0].commission_value);
        SetCommissionMin(response.data[0].commission_min_amount);
        SetCommissionMax(response.data[0].commission_max_value);
        setShow(true);
      });
    }
  }

  const [MarketPlaceCommission, SetMarketPlaceCommission] = useState([]);
  const [commissionbuttonlabel, SetCommissionPlacelabel] = useState('');

  const [CommissionStatus, SetCommissionStatus] = useState('');
  const [CommissionLevel, SetCommissionLevel] = useState('');
  const [CommissionType, SetCommissionType] = useState('');
  const [CommissionPayout, SetCommissionPayout] = useState('');
  const [CommissionVal, SetCommissionVal] = useState('');
  const [CommissionMin, SetCommissionMin] = useState('');
  const [CommissionMax, SetCommissionMax] = useState('');
  const [CommissionUpdate, SetCommissionUpdate] = useState('');
  const [ShowComissionLabel, SetCommissionLabel] = useState('');

  const [CommissionvalueErr, SetComissionvalueErr] = useState(false);
  const [CommissionvalueMsg, SetComissionvalueMsg] = useState('');

  const [MinPayErr, SetMinPayErr] = useState(false);
  const [MinPayMsg, SetMinPayMsg] = useState('');

  const [MaxComErr, SetMaxComErr] = useState(false);
  const [MaxComMsg, SetMaxComMsg] = useState('');

  const GetMarketPlace = async () => {

    const response = await axiosJWT.get('/manage-commissions?commission_name=marketplace', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {

      SetMarketPlaceCommission(response.data);

      if (response.data.length > 0) {
        SetCommissionPlacelabel('Edit');

        if (response.data[0].commission_type === "fixed") {
          SetCommissionLabel('$ ' + response.data[0].commission_value);
        }
        else if (response.data[0].commission_type === "percentage") {
          SetCommissionLabel(response.data[0].commission_value + ' %');
        }
        SetCommissionUpdate('1');
      }
      else {
        SetCommissionPlacelabel('Add');
      }
    });
  }

  useEffect(() => {
    GetMarketPlace();
    GetMyEarningsWithdrawBalance();
  }, []);

  const SaveCommission = async (e) => {

    var EditId = e.target.getAttribute("data-update");

    var err = false;

    SetComissionvalueErr(false);
    SetMinPayErr(false);
    SetMaxComErr(false);

    if (CommissionVal === '') {
      SetComissionvalueMsg('Please enter commission value');
      SetComissionvalueErr(true);
      err = true;
    }
    if (CommissionMin === '') {
      SetMinPayMsg('Please enter minimum payment amount');
      SetMinPayErr(true);
      err = true;
    }
    if (CommissionMax === '') {
      SetMaxComMsg('Please enter maximum commision value');
      SetMaxComErr(true);
      err = true;
    }
    if (CommissionVal !== '') {
      const Numreg = new RegExp('^[0-9]+$');
      if (!Numreg.test(CommissionVal)) {
        SetComissionvalueErr(true);
        SetComissionvalueMsg('Please enter round off value(Commiss.value)');
        err = true;
      }
    }
    if (CommissionMin !== '') {
      const Numreg = new RegExp('^[0-9]+$');
      if (!Numreg.test(CommissionMin)) {
        SetMinPayErr(true);
        SetMinPayMsg('Please enter round off value(Min.amo)');
        err = true;
      }
    }
    if (CommissionMax !== '') {
      const Numreg = new RegExp('^[0-9]+$');
      if (!Numreg.test(CommissionMax)) {
        SetMaxComErr(true);
        SetMaxComMsg('Please enter round off value(Max.com)');
        err = true;
      }
    }

    if (err === true) {
      return false;
    }
    if (e.target.innerText === 'Save Commission' && EditId === '') {

      var FinalCommissionStatus = CommissionStatus;
      var FinalCommissionLevel = CommissionLevel;
      var FinalCommissionType = CommissionType;
      var FinalCommissionPayout = CommissionPayout;
      var FinalCommissionVal = CommissionVal;
      var FinalCommissionMin = CommissionMin;
      var FinalCommissionMax = CommissionMax;

      const response = await axiosJWT.post('/manage-commissions',
        {
          commission_status: FinalCommissionStatus,
          commission_name: FinalCommissionLevel,
          commission_type: FinalCommissionType,
          commission_payout: FinalCommissionPayout,
          commission_value: FinalCommissionVal,
          commission_min_amount: FinalCommissionMin,
          commission_max_value: FinalCommissionMax,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        GetMarketPlace();
        setShow(false);
      }
    }
    else if (e.target.innerText === 'Save Commission' && EditId === '1') {

      var FinalCommissionStatus = CommissionStatus;
      var FinalCommissionLevel = CommissionLevel;
      var FinalCommissionType = CommissionType;
      var FinalCommissionPayout = CommissionPayout;
      var FinalCommissionVal = CommissionVal;
      var FinalCommissionMin = CommissionMin;
      var FinalCommissionMax = CommissionMax;

      const response = await axiosJWT.post('/manage-commissions/update',
        {
          commission_status: FinalCommissionStatus,
          commission_name: FinalCommissionLevel,
          commission_type: FinalCommissionType,
          commission_payout: FinalCommissionPayout,
          commission_value: FinalCommissionVal,
          commission_min_amount: FinalCommissionMin,
          commission_max_value: FinalCommissionMax,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        GetMarketPlace();
        setShow(false);
      }
    }
  }

  const [AvailableAdminWithdrawBalance, SetAvailableAdminWithdrawBalance] = useState(0);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const [TotalEarnings, SetTotalEarnings] = useState(0);
  const [PendingAmount, SetPendingAmount] = useState(0);
  const [WithDrawbleBalance, SetWithDrawbleBalance] = useState(0);
  const [EarningsCurrentMonth, SetEarningsCurrentMonth] = useState(0);

  const d = new Date();

  const GetMyEarningsWithdrawBalance = async () => {

    const response = await axiosJWT.get('/admin-analytics/withdraw-earnings', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {

      if(response.data.OverAllEarnings[0])
      {
        if(response.data.OverAllEarnings[0].payment_amount)
        {
          SetTotalEarnings((response.data.OverAllEarnings[0].payment_amount).toFixed(2));
        }  
      }
   
      SetPendingAmount(response.data.AmountOnhold);
      SetWithDrawbleBalance(response.data.WithDrawbleBalance);

      SetAvailableAdminWithdrawBalance(response.data.AdminInformation.withdrawable_amount);

      if(response.data.CurrentMonthearnings[0])
      {
        if(response.data.CurrentMonthearnings[0].payment_amount)
        {
          SetEarningsCurrentMonth((response.data.CurrentMonthearnings[0].payment_amount).toFixed(2));
        }
      }
    }
  }

  const [ShowWithdraw, SetShowWithdraw] = useState(false);
  const HandleCloseWithdraw = () => {
    SetShowWithdraw(false);
  }
  const [showwithdrawmsg, Setshowwithdrawmsg] = useState('');

  const [loader, Setloader] = useState(false);

  const WithDrawBalance = async () => {

    SetWithDrawAMountInputErr(false);
    if(WithDrawAMountInput==='')
    {
      SetWithDrawAMountInputErr(true);
      return false;
    }

    HandleCloseWithDrawInput();   
    Setloader(true);

    try {
      const response = await axiosJWT.get('/payments/stripe-withdraw-balance?amount='+WithDrawAMountInput+'', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response) {
        Setloader(false);
        Setshowwithdrawmsg(response.data.msg);
        SetShowWithdraw(true);
        GetMyEarningsWithdrawBalance();
      }
    } catch (err) {
      Setloader(false);
      Setshowwithdrawmsg(err.response.data.msg);
      SetShowWithdraw(true);
      GetMyEarningsWithdrawBalance();
    }
  }

  const [ShowWithDrawInput, SetShowWithDrawInput] = useState(false);
  const HandleCloseWithDrawInput = () => {
    SetShowWithDrawInput(false);
  }

  const TriggerWithDrawBalance = () => {
    SetWithDrawAMountInput(0);    
    SetShowWithDrawInput(true);
  }

  const [WithDrawAMountInput,SetWithDrawAMountInput]=useState(0);
  const [WithDrawAMountInputErr,SetWithDrawAMountInputErr]=useState(false);

  return (
    <div className='adminbilling_commission container'>
      {loader === true && <div id="semiTransparenDivLoader"></div>}
      <div className='row commission_tab'>
        <div className="col display_inline avail_bal background_change">
          <span>Total earnings</span><br />
          <span className="money_billing">$ <span>{TotalEarnings}</span></span><br />
        </div>

        <div className="col display_inline background_change">
          <span>Earnings In {monthNames[d.getMonth()]}</span><br />
          <span className='money_billing'>$ <span>{EarningsCurrentMonth}</span></span>
        </div>

        <div className="col-6 last_commision_tab">
          <button className='last_commision_tab_button' onClick={(e) => { handleShow(e) }} >{commissionbuttonlabel}</button>
          {(MarketPlaceCommission.length > 0) ? <span  >You have set the commission for every<br /><span>subscription is <span>{ShowComissionLabel}</span></span></span> : <span >You have not added MarketPlace<br /> commission yet</span>}
        </div>
      </div>

      <div className='row commission_tab onhold_stripe_div_card_row'>
        <div className="display_inline avail_bal background_change onhold_stripe_div_card">
          <span>Amount On hold (Stripe)</span><br />
          <span className="money_billing">$ <span>{PendingAmount}</span></span><br />
        </div>

        <div className="display_inline avail_bal background_change onhold_stripe_div_card">
          <span>withdrawable balance (Stripe)</span><br />
          <span className="money_billing">$ <span>{WithDrawbleBalance}</span></span><br />
          <button className='withdraw_but' onClick={TriggerWithDrawBalance}>WithDraw</button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className='commission_popup'>
        <Modal.Header closeButton>
          <Modal.Title>Manage Commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>use commissions to configure your commission rates to the experts for using the platform.commission rates are applied against each individual payment</p>
          <p>1.Commissions can be on 2 levels:Marketplace (common for all experts) or expert (To manage individual expert's commission,Go to Manage Users {'->'} commission)</p>
          <p>2.Commission can be of 2 types:Fixed or Percentage and can be handled on the platform or offline</p>
          <div className="row section_1_commission">
            <div className="col">
              <label className='commission_inner_popup_label required'>Commission Status</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionStatus} onChange={(e) => { SetCommissionStatus(e.target.value) }}>
                <option value="1">Enable</option>
                <option value="0">Disable</option>
              </select>
            </div>
            <div className="col">
              <label className='commission_inner_popup_label required'>Commission Level</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionLevel} onChange={(e) => { SetCommissionLevel(e.target.value) }}>
                <option value="marketplace">MarketPlace</option>
              </select>
            </div>
          </div>
          <div className="row section_1_commission section_2_commission">
            <div className="col">
              <label className='commission_inner_popup_label required'>Select Commission Type</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionType} onChange={(e) => { SetCommissionType(e.target.value) }}>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </select>
            </div>
            <div className="col">
              <label className='commission_inner_popup_label required'>Select Payout Method</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionPayout} onChange={(e) => { SetCommissionPayout(e.target.value) }}>
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </select>
            </div>
          </div>
          <div className="row section_1_commission section_2_commission">
            <div className="col">
              <label className='commission_inner_popup_label required'>Commission Value</label><br />
              <input type='number' className='commission_inner_input' defaultValue={CommissionVal} onChange={(e) => { SetCommissionVal(e.target.value) }} {...(CommissionvalueErr === true ? { id: 'err_border' } : {})} />
            </div>
            <div className="col">
              <label className='commission_inner_popup_label required'>Min.Pay.Amount</label><br />
              <input type='number' className='commission_inner_input' defaultValue={CommissionMin} onChange={(e) => { SetCommissionMin(e.target.value) }} {...(MinPayErr === true ? { id: 'err_border' } : {})}></input>
            </div>
            <div className="col">
              <label className='commission_inner_popup_label required'>Max.Commiss.value</label><br />
              <input type='number' className='commission_inner_input' defaultValue={CommissionMax} onChange={(e) => { SetCommissionMax(e.target.value) }} {...(MaxComErr === true ? { id: 'err_border' } : {})}></input>
            </div>
          </div>

          <div className='row fnameerr_admin_coach fnameerr_admin_coach_comision err'>
            <div className='col1'>
              {CommissionvalueErr === true &&
                <span >{CommissionvalueMsg}</span>
              }
            </div>

            <div className='col2'>
              {MinPayErr === true &&
                <span>  {MinPayMsg}</span>
              }
            </div>

            <div className='col2'>
              {MaxComErr === true &&
                <span>  {MaxComMsg}</span>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='display_block'>
          <Button variant="secondary" className='close_button_commission' onClick={handleClose}>
            Back
          </Button>
          <Button variant="primary" className='save_button_commission float_right' data-update={CommissionUpdate} onClick={(e) => { SaveCommission(e) }}>
            Save Commission
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={ShowWithdraw} onHide={HandleCloseWithdraw} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Amount withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showwithdrawmsg}
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={HandleCloseWithdraw}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={ShowWithDrawInput} onHide={HandleCloseWithDrawInput} className="confirm_delete">
        <Modal.Header closeButton>
          <Modal.Title>Amount withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type='number' className='commission_inner_input' defaultValue={WithDrawAMountInput} onChange={(e) => { SetWithDrawAMountInput(e.target.value) }} {...(WithDrawAMountInputErr === true ? { id: 'err_border' } : {})} placeholder={'Please enter the amount'}></input>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button variant="primary" className="modal_action_button float_right" onClick={WithDrawBalance}>
            Proceed
          </Button>
          <Button variant="primary" className="modal_action_button float_right" onClick={HandleCloseWithDrawInput}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Commission
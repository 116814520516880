import React, { useEffect, useState ,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import Pagination from "react-js-pagination";
import { ReactFormGenerator } from 'react-form-builder2';
import { format, parseISO } from 'date-fns';
import ReactToPrint from 'react-to-print';
const UsersSubmittedForms=()=>{


    const side_nav = localStorage.getItem("side_nav");
    const navigate=useNavigate()
    const token=localStorage.getItem('token')
    const role=localStorage.getItem('role')
    const [PerPage,SetPerPage]=useState(50)
    const [PageNumber,SetPageNumber]=useState(1)
    const [TotalRecord,SetTotalRecord]=useState(0)
    const [SubmittedIntakeFormList,SetSubmittedIntakeFormList]=useState([])
    const [UserList,SetUserList]=useState([])
    const axiosJWT = axios.create();
    const [Showlistview,SetShowlistview]=useState(true)
    const [ShowViewDetail,SetShowViewDetail]=useState(false)
    const [JSON_ANSWERS, SetJSON_ANSWERS] = useState();
    const [JSON_QUESTION_DATA, SetJSON_QUESTION_DATA] = useState()

    const [FormName,SetFormName]=useState('')
    const [FormSubmittedBy,SetFormSubmittedBy]=useState('')
    const [FormSubmittedOn,SetFormSubmittedOn]=useState('')
    const componentRef = useRef();

    const BackToQuestionnaire=()=>{
        navigate('/admin/intake-form',{replace:true})
    }

    const GetSubmittedFormsByUser=async(pagenumber)=>{
        SetPageNumber(pagenumber)
        const response = await axiosJWT.get('form/user/get-submitted-list?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


         
            SetTotalRecord(response.data.totalcount)
            SetSubmittedIntakeFormList(response.data.List)
            SetUserList(response.data.UsersList)

        })


    }

    const GetClientData = (id, key) => {



        var found = UserList.filter(e => e.id === id);
        if (found.length > 0) {

          
            if (key === 'email') {
                return found[0].email
            }
            if (key === 'name') {
                return found[0].first_name + ' ' + found[0].last_name
            }
            if (key === 'role') {
                return found[0].role
            }
            if (key === 'pic') {

                if (found[0].user_profile_photo !== null && found[0].user_profile_photo !== '') {

                    return  found[0].user_profile_photo

                }
                else {

                    if (found[0].gender === 'MALE') {
                        return male_icon
                    }
                    else {
                        return female_icon
                    }


                }





            }
        }
 
    }

    const viewSubmittedDetail=async(e)=>{

        var List_id = parseInt(e.target.getAttribute('data-list_id'))
        var found = SubmittedIntakeFormList.filter(e => e.id === List_id);

        var submit_id = parseInt(e.target.getAttribute('data-submit_id'))

     


        const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + submit_id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

 
        var ArrayFinal = []

        var QuestionnaireId = DetailResponse.data.intake_qn_id
        const myArray = QuestionnaireId.split(",");

        const Formobj = JSON.parse(found[0].submitted_data)


        if(DetailResponse.data.intake_form_name)
        {
            SetFormName(DetailResponse.data.intake_form_name)
        }
        SetFormSubmittedBy(GetClientData(found[0].invited_to, 'name')+' ('+GetClientData(found[0].invited_to, 'role')+')')
        SetFormSubmittedOn(found[0].updatedAt)
       
       

        SetJSON_ANSWERS(Formobj)

    

        myArray.forEach((value, index, array) => {

            const QuestionnaireResponse = axiosJWT.get('form/form-detail?id=' + value + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((QuestionnaireResponse) => {

    

                // {GetClientData(intakeform.invited_to, 'name')}


                QuestionnaireResponse.data.forEach((value, index, array) => {
                    ArrayFinal.push(value)


                })

                setTimeout(() => {

                    SetJSON_QUESTION_DATA(ArrayFinal)
                    SetShowViewDetail(true)
                    closeList()
               
                }, 100);




            })



        })

    }

    const changePageNumberS=(pagenumber)=>{
        GetSubmittedFormsByUser(pagenumber)
    }
    const closedetail=()=>{
        SetShowViewDetail(false)
        SetShowlistview(true)
    }
    const closeList=()=>{
        SetShowlistview(false)
        SetShowViewDetail(true)
    }

    useEffect(()=>{
        GetSubmittedFormsByUser(1)  
    },[])
    return (
        <div
        id="container-adjusts"
        className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
      >
        <section className="dashboard">
          <div className="coachquestionnaire_list container admin_intake_form">
            {
                Showlistview===true && 
                <div>

                <div>
               <div className="row questionnaire_top_row">
                  <div className="col">
                    <span className="top_span_questionnaire_head">
                    Submitted forms
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                  <button className="questionnaire_top_button top_billing_coach_payment" onClick={BackToQuestionnaire}>{'< '}Back to intake form</button>
                
                  </div>
                </div>
               </div>
               <div className="intake_form_list">
               <Table className="table is-striped is-fullwidth">
                                        <Thead className="thead">
                                            <Tr className="trow">
                                                <Th>User Name</Th>
                                                <Th>User Email</Th>
                                                <Th>Intake form name</Th>
                                                <Th>Action</Th>
    
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                        {SubmittedIntakeFormList.length > 0 && SubmittedIntakeFormList.map((intakeform, index) => (
    
    <Tr className='trow'>
        <Td><img className='table_profile_img' alt="BigCo Inc. logo" src={GetClientData(intakeform.invited_to, 'pic')} /><span className="">{GetClientData(intakeform.invited_to, 'name')}</span></Td>
        <Td >{GetClientData(intakeform.invited_to, 'email')}{' ('+GetClientData(intakeform.invited_to, 'role')+')'}</Td>
        <Td >{intakeform.intake_form.intake_form_name}</Td>
        <Td ><i className="fa fa-eye view_questionnaire" data-list_id={intakeform.id} data-submit_id={intakeform.form_id} aria-hidden="true" onClick={(e) => { viewSubmittedDetail(e) }}></i></Td>
    </Tr>
    ))}
                                        </Tbody>
    
                                        </Table>
               </div>

               <div className="row">
                            {TotalRecord ?
                                <Pagination
                                    activePage={PageNumber}
                                    itemsCountPerPage={PerPage}
                                    totalItemsCount={TotalRecord}
                                    onChange={changePageNumberS.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                                : null}
                        </div>
    
                </div>
            }
             {ShowViewDetail === true &&

<div className="row">

    <div className="row top_questionnaire_row_client">

        <span className="top_questionnaire_row_client_span_x client_close_x"><i className="fa fa-times" aria-hidden="true" onClick={closedetail}></i></span>
    </div>

    <div className="intake_form_export_div">
    <ReactToPrint
                                    trigger={() => <button className="intake_form_export_button">Export PDF</button>}
                                    content={() => componentRef.current}
                                />
    </div>

    <div className="intake_form_overall" ref={componentRef} >

    <div className="intake_form_export_div_form_details">
    <span>Form Name : {FormName}</span>
    <span>Submitted By : {FormSubmittedBy}</span>
    <span>Submitted On : {FormSubmittedOn!=='' && format(parseISO(FormSubmittedOn), `PP`)}</span>
</div>


    <ReactFormGenerator


        answer_data={JSON_ANSWERS}

        data={JSON_QUESTION_DATA}
        hide_actions={true}
        read_only={true}
    />

    </div>




</div>
}
          
            </div>
            </section>
        </div>
    )
}
export default UsersSubmittedForms
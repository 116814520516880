import React, { useEffect, useState } from "react";
import ManagerAccessCheck from "./ManagerAccessPage";
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagerAppointmentAccess = () => {

    const [Access, SetAccess] = useState(false)
    const [ShowData, SetShowData] = useState(false)
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [PageNumber, SetPageNumber] = useState(1)
    const [PerPage, SetPerPage] = useState(10)
    const [TotalRecord, SetTotalRecord] = useState(0)
    const [MyTeamAppointments, SetMyTeamAppointments] = useState([])
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");


    const GetAccessDetails = async () => {

        const response = await axiosJWT.get('/managing-team/get-access-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });




        if (response.data !== null) {

            var Appointmentresult = response.data.filter(obj => {
                return obj.page === 'appointment'
            })
            if (Appointmentresult[0].read === true) {

                SetAccess(false)
                SetShowData(true)

            }
            else {
                SetAccess(true)
                SetShowData(false)
            }

        }

    }

    function handleChange(newValue) {
        SetAccess(newValue);
    }

    const GetMyTeamAppointments = async (pagenumber) => {

        SetPageNumber(pagenumber)

        const response = await axiosJWT.get('/manager-access/get-my-team-appointments?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetMyTeamAppointments(response.data.FinalArray)
        SetTotalRecord(response.data.TotalRecords)



    }

    const changePageNumber = (pagenumber) => {
        GetMyTeamAppointments(pagenumber)


    }

    function timetoUtcClientAppointment(passdate) {


        var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

        var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')


        return stardates



    }



    useEffect(() => {
        GetAccessDetails()
        GetMyTeamAppointments(1)
    }, [])


    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">

                <div className="manager_manageappointments container">
                    <ManagerAccessCheck allowedstatus={Access} onChange={handleChange} />

                    {
                        ShowData === true &&

                        <div>

                            <div className="row admin_appointment_head_row">
                                <span>Manage Appointments</span>
                            </div>


                            <div className="row upcoming_appointment_table_row">


                                <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                                    <Thead className="thead">
                                        <Tr className="trow">
                                            <Th scope="col">Listing</Th>
                                            <Th scope="col">Coach</Th>
                                            <Th scope="col">Coach Email</Th>
                                            <Th scope="col">Subscription</Th>
                                            <Th scope="col">Client</Th>
                                            <Th scope="col">Client Email</Th>
                                            { /*<th scope="col">Purpose</th>*/}
                                            <Th scope="col">Booking Date {'&'} time</Th>
                                            <Th>status</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody className="tbody manager_appointment_list_admin">
                                        {MyTeamAppointments.length > 0 && MyTeamAppointments.map((list, index) => (
                                            <Tr className="trow">
                                                <Td><span className="desing_yellow in_page_design_relative">{list.appointment.booked_by}</span></Td>
                                                <Td>{list.coach.first_name + ' ' + list.coach.last_name}</Td>
                                                <Td>{list.coach.email}</Td>
                                                <Td>Service 1</Td>
                                                <Td>{list.client.first_name + ' ' + list.client.last_name}</Td>
                                                <Td>{list.client.email}</Td>
                                                <Td>{format(parseISO(timetoUtcClientAppointment(list.appointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</Td>
                                                <Td>  {(list.appointment.meeting_status === 0)
                                                    ? 'Not completed'
                                                    : 'Completed'}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <div>
                                    {MyTeamAppointments.length > 0 ?
                                        <Pagination
                                            activePage={PageNumber}
                                            itemsCountPerPage={PerPage}
                                            totalItemsCount={TotalRecord}
                                            onChange={changePageNumber.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}

export default ManagerAppointmentAccess
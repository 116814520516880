import React, { useEffect, useState } from "react";
import axios from 'axios';
import DummyrecipeImg from '../../../images/pexels-photo-1640777.jpeg'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Pie } from 'react-chartjs-2';

const MealPlanImportedRecipeList = (props) => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [SearchImportedValue, SetSearchImportedValue] = useState('')
    const [ImportedRecipeList, SetImportedRecipeList] = useState([])
    const [FoodMenu, SetFoodMenu] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [recipeData, SetrecipeData] = useState()
    const [PercentageArray, SetPercentageArray] = useState([]);
    const [planId, SetplanId] = useState()
    const [recipetab, setRecipeTabMenu] = useState('ingredients');
    const [RecipeDataIngredient,SetRecipeDataIngredient]=useState('')
    const [RecipeDataSteps,SetRecipeDataSteps]=useState('')

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }

    const pie_options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const pie_data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {

        if (props.foodMenu !== undefined) {
            SetFoodMenu(props.foodMenu)

            GetImportedListData(props.foodMenu, SearchImportedValue)

        }

    }, [props])

    const GetImportedListData = async (category, search) => {

        const response = await axiosJWT.get('import-recipe/list-imported-recipe-url' + '?search=' + search + '&category=' + category + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data !== null) {
            if (response.data.list !== null) {
             
                SetImportedRecipeList(response.data.list)
            }
        }
        console.log(response)

    }

    const showRecipe = async (e) => {

        const recipeId = e.target.getAttribute("data-item");
        SetplanId(recipeId)
        SetRecipeDataIngredient('')
        SetRecipeDataSteps('')
        setRecipeTabMenu('ingredients')
        
        const response = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + recipeId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data !== null) {
            if (response.data.recipe !== null) {
                SetrecipeData(response.data.recipe)
                SetRecipeDataIngredient(response.data.recipe.recipe_ingredients)
                SetRecipeDataSteps(response.data.recipe.recipe_instructions)

                var RecipeProtein = 0;
                var RecipeFat = 0;
                var RecipeCarb = 0;
                if (response.data.recipe.recipe_proteins !== '') {
                    RecipeProtein = FetchNum(response.data.recipe.recipe_proteins)
                }
                if (response.data.recipe.recipe_fats !== '') {
                    RecipeFat = FetchNum(response.data.recipe.recipe_fats)
                }
                if (response.data.recipe.recipe_carbs !== '') {
                    RecipeCarb = FetchNum(response.data.recipe.recipe_carbs)
                }

                SetPercentageArray([RecipeProtein, RecipeFat, RecipeCarb]);

                setShow(true)
            }
        }
        console.log(response)


    }

    const SearchByName = (search) => {
        GetImportedListData(FoodMenu, search)
    }

    const FetchNum = (thestring) => {

        if(typeof thestring==='string')
        {

        var txt = thestring
        var numb = txt.match(/\d/g);
        if (numb !== null) {
            numb = numb.join("");
            return parseFloat(numb)

        }
        else {
            return 0
        }
    }else{
        return  thestring
    }


    }

    const changeRender=(str)=>{
        str = str.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
        return str
       
       }

    const AssignRecipe=async(e)=>{

        props.setMessage('')
        const RecipeId=e.target.getAttribute('data-item')
        if(FoodMenu==='breakfast')
        {
             props.setbreakfastId(RecipeId)
        }
        else if(FoodMenu==='lunch')
        {
            props.setlunchId(RecipeId)
        }
        else if (FoodMenu==='dinner')
        {
            props.setdinnerId(RecipeId)
        }
        else if(FoodMenu==='snacks')
        {
            props.setsnackId(RecipeId)
        }

        const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(RecipeData.data!==null)
        {
            if(RecipeData.data.recipe)
            {  
                if(FoodMenu==='breakfast')
                {

                    var BreakfastData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid
                    var recipeImage=''
                    var recipeTitle=RecipeData.data.recipe.recipe_name
                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyrecipeImg
                    }
                    BreakfastData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }
                    props.setbreakfastData(BreakfastData)
                    props.setbfcarb(FetchNum(RecipeData.data.recipe.recipe_carbs))
                    props.setbfpro(FetchNum(RecipeData.data.recipe.recipe_proteins))
                    props.setbffat(FetchNum(RecipeData.data.recipe.recipe_fats))

                    var total_carb = ((FetchNum(RecipeData.data.recipe.recipe_carbs)) + props.lcarb + props.dcarb +props.scarb);
                    var total_pro = (FetchNum(RecipeData.data.recipe.recipe_proteins) + props.lpro + props.dpro + props.spro);
                    var total_fat = (FetchNum(RecipeData.data.recipe.recipe_fats) + props.lfat + props.dfat + props.sfat);
                       
                }
                if(FoodMenu==='lunch')
                {
                    var LunchData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid
                    var recipeImage=''
                    var recipeTitle=RecipeData.data.recipe.recipe_name
                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyrecipeImg
                    }
                    LunchData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }
                    props.setlunchData(LunchData)
                    props.setlcarb(FetchNum(RecipeData.data.recipe.recipe_carbs))
                    props.setlpro(FetchNum(RecipeData.data.recipe.recipe_proteins))
                    props.setlfat(FetchNum(RecipeData.data.recipe.recipe_fats))

                    var total_carb = (props.bfcarb + (FetchNum(RecipeData.data.recipe.recipe_carbs)) + props.dcarb + props.scarb);
                    var total_pro = (props.bfpro + FetchNum(RecipeData.data.recipe.recipe_proteins) + props.dpro + props.spro);
                    var total_fat = (props.bffat + FetchNum(RecipeData.data.recipe.recipe_fats) + props.dfat + props.sfat);


                }
                if(FoodMenu==='dinner')
                {
                    var DinnerData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid
                    var recipeImage=''
                    var recipeTitle=RecipeData.data.recipe.recipe_name
                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyrecipeImg
                    }
                    DinnerData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }
                    props.setdinnerData(DinnerData)
                    props.setdcarb(FetchNum(RecipeData.data.recipe.recipe_carbs))
                    props.setdpro(FetchNum(RecipeData.data.recipe.recipe_proteins))
                    props.setdfat(FetchNum(RecipeData.data.recipe.recipe_fats))

                    var total_carb = (props.bfcarb + props.lcarb + (FetchNum(RecipeData.data.recipe.recipe_carbs)) + props.scarb);
                    var total_pro = (props.bfpro + props.lpro + RecipeData.data.recipe.recipe_proteins + props.spro);
                    var total_fat = (props.bffat + props.lfat + RecipeData.data.recipe.recipe_fats + props.sfat);


                }
                if(FoodMenu==='snacks')
                {
                    var SnackData={};
                    var recipeId=RecipeData.data.recipe.recipe_uid
                    var recipeImage=''
                    var recipeTitle=RecipeData.data.recipe.recipe_name
                    if(RecipeData.data.recipe.recipe_image!=='')
                    {
                        recipeImage=RecipeData.data.recipe.recipe_image
                    }
                    else{
                        recipeImage=DummyrecipeImg
                    }
                    SnackData={
                        RecipeID:recipeId,
                        PhotoUrl:recipeImage,
                        Title:recipeTitle
                    }
                    props.setsnacksData(SnackData)
                    props.setscarb(FetchNum(RecipeData.data.recipe.recipe_carbs))
                    props.setspro(FetchNum(RecipeData.data.recipe.recipe_proteins))
                    props.setsfat(FetchNum(RecipeData.data.recipe.recipe_fats))
                    
                    var total_carb = (props.bfcarb + props.lcarb + props.dcarb + (FetchNum(RecipeData.data.recipe.recipe_carbs)));
                    var total_pro = (props.bfpro + props.lpro + props.dpro + RecipeData.data.recipe.recipe_proteins);
                    var total_fat = (props.bffat + props.lfat + props.dfat+ RecipeData.data.recipe.recipe_fats);


                }



                
                var est_carb = ((total_carb / props.goalcarb) * 100);
                var est_pro = ((total_pro / props.goalpro) * 100);
                var est_fat = ((total_fat / props.goalfat) * 100);
                props.setcarbEst(est_carb);
                props.setproEst(est_pro);
                props.setfatEst(est_fat);
            }
        }




   

      

    }



    return (
        <div>
            <div className="recipes_library">
                <div className='leftmenu'>
                    <div className='title'>
                        <h4>Imported Recipes <span style={{ fontSize: "14px", color: "gray" }}>(choose recipe)</span></h4>
                    </div>
                </div>
                <div className='rightmenu'>
                    <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => SearchByName(event.target.value)} defaultValue={SearchImportedValue} />

                </div>

                <div style={{ "clear": "both" }} />
                <div className='recipes'>
                    {ImportedRecipeList.map((recipe, index) => (
                        <div className='recipe_box' key={index} >
                            <i className='fa fa-ellipsis-v viewdata' data-item={recipe.recipe_uid} onClick={(e) => showRecipe(e)} ></i>
                            <div className='img' data-id={recipe.recipe_uid} >


                                {
                                    recipe.recipe_image !== '' ? <img src={recipe.recipe_image} data-item={recipe.recipe_uid} onClick={(e)=>{AssignRecipe(e)}} /> :
                                        <img src={DummyrecipeImg} data-item={recipe.recipe_uid} onClick={(e)=>{AssignRecipe(e)}} />
                                }


                            </div>

                            <div className='title'>{recipe.recipe_name}</div>
                        </div>
                    ))}
                </div>

            </div>

            <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.recipe_name}</div>
                            {
                                recipeData.recipe_image !== '' ? <div className='img'><img src={recipeData.recipe_image} /></div> : <div className='img'><img src={DummyrecipeImg} /></div>

                            }
                            <div className='nutrition_data'>
                                <p className='pie_chart_kcal_data'>Calories: {FetchNum(recipeData.recipe_calories)} Kcal</p>
                                <Pie data={pie_data} options={pie_options} />
                                <hr />
                                <div className="pie_chart_bottom_div">

                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_carbs)}</span><span>G</span></span>
                                    </span>

                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_fats)}</span><span>G</span></span>
                                    </span>

                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{FetchNum(recipeData.recipe_proteins)}</span><span>G</span></span>
                                    </span>

                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <div dangerouslySetInnerHTML={{__html: changeRender(RecipeDataIngredient)}}></div>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                     <div dangerouslySetInnerHTML={{__html: changeRender(RecipeDataSteps)}}></div>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button' data-item={planId} onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default MealPlanImportedRecipeList
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import male_icon from '../../../images/katie-smith-uQs1802D0CQ-unsplash.jpg'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import PremiumCheckComponent from "../PremiumPlanPopup";
import DummyRecipeImg from '../../../images/pexels-photo-1640777.jpeg'

ChartJS.register(ArcElement, Tooltip, Legend);

const ClientFoodDiary = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const [startDate, setStartDate] = useState(new Date());
    const axiosJWT = axios.create();
    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const [alreadyid, Setalreadyid] = useState('')
    const [RecentlyAddedList, SetRecentlyAddedList] = useState([]);
    const [TotalCaloriesDay, SetTotalCaloriesDay] = useState(0)
    const [TotalFatDay, SetTotalFatDay] = useState(0)
    const [TotalProteinDay, SetTotalProteinDay] = useState(0)
    const [TotalCarbsDay, SetTotalCarbsDay] = useState(0)
    const [PercentageArray, SetPercentageArray] = useState([0, 0, 0])
    const [BillingPageNo, SetBillingPageNo] = useState(1);
    const [BillingPerPage, SetBillingPerPage] = useState(10);
    const [TotalRecordBilling, SetTotalRecordBilling] = useState(0);
    const [NutritionistList, SetNutritionistList] = useState([])
    const datepicker1Ref = useRef(null);
    function handleClickDatepickerIcon1() {
        const datepickerElement = datepicker1Ref.current;

        datepickerElement.setFocus(true);
    }

    const changePageNumberS = async (pagenumber) => {


        Search_library_food(pagenumber)
        SetBillingPageNo(pagenumber)
        SetRecipeAddListID([])

        var uncheck = document.getElementById('library_list_food_diary_ulID').getElementsByTagName('input')
        for (var i = 0; i < uncheck.length; i++) {
            if (uncheck[i].type === 'checkbox') {
                uncheck[i].checked = false;
            }
        }
    }

    const GetNutritionistList = async () => {

        const response = await axiosJWT.get("/meal-plan/", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        SetNutritionistList(response.data)


    }




    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {

                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";

                    return percentage;


                },
                color: '#fff',
            }

        },

    }





    const data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                   '#226920',
                   '#009fe1',
                   '#c71a35'

                ],
                borderColor: [
                    '#226920',
                    '#009fe1',
                    '#c71a35'
                ],

                borderWidth: 1,
            },
        ],
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const trigger_add_food_popup = async (e) => {

        ShowLoader()
        SetshowCoachDietPlanTab(false)
        SetSearchFoodLibraryShow(false)
        SetshowFoodJournalTab(false)
        SetRecentlyAddedList([])
        SetSession(e.target.getAttribute('data-session'))

        Setalreadyid(e.target.getAttribute('data-alreadyid'))
        SetBillingPageNo(1)

        setShow(true)
        SetRecipeAddListID([])
        GetWeekDietPlanList()




    }

    const GetWeekDietPlanList = async () => {


        SetRecentlyAddedList([])
        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });



        if (response.data.week_diet_plans.length > 0 && response.data.week_diet_plans[0].breakfast_keys !== undefined) {
            var breakfastkeys_exist = response.data.week_diet_plans[0].breakfast_keys;
        }

        if (response.data.week_diet_plans.length > 0 && response.data.week_diet_plans[0].lunch_keys !== undefined) {
            var lunchkeys_exist = response.data.week_diet_plans[0].lunch_keys;
        }

        if (response.data.week_diet_plans.length > 0 && response.data.week_diet_plans[0].dinner_keys !== undefined) {
            var dinnerkeys_exist = response.data.week_diet_plans[0].dinner_keys;
        }

        if (response.data.week_diet_plans.length > 0 && response.data.week_diet_plans[0].snack_keys !== undefined) {
            var snackkeys_exist = response.data.week_diet_plans[0].snack_keys;
        }

        var BreakfastArray = []
        var LunchArray = []
        var DinnerArray = []
        var SnacksArray = []



        if (breakfastkeys_exist !== undefined) {

            BreakfastArray = breakfastkeys_exist.split(",");
        }
        if (lunchkeys_exist !== undefined) {

            LunchArray = lunchkeys_exist.split(",");
        }
        if (dinnerkeys_exist !== undefined) {

            DinnerArray = dinnerkeys_exist.split(",");
        }
        if (snackkeys_exist !== undefined) {

            SnacksArray = snackkeys_exist.split(",");
        }


        var FinalArray = SnacksArray.concat(BreakfastArray, LunchArray, DinnerArray);
        FinalArray = FinalArray.filter(function () { return true });

        var uniqueArray = FinalArray.filter(function (item, pos) {
            return FinalArray.indexOf(item) === pos;
        })
        var RecentlyAddedArrayPush = []



        if (uniqueArray.length > 0) {
            var bar = new Promise((resolve, reject) => {


                uniqueArray.forEach(async (value, index, array) => {


                    try {

                        const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {
                        }).then((response) => {
                            RecentlyAddedArrayPush.push(response.data)
                            if (index === array.length - 1) {

                                resolve();
                            }

                        })

                    } catch (err) {
                        console.log(err)
                    }






                })
            })

            bar.then(() => {

                setTimeout(function () {
                    HideLoader()

                    SetRecentlyAddedList(RecentlyAddedArrayPush)
                }, 1000);
            })

        }
        else {
            HideLoader()
        }



    }
    const [LibraryList, SetLibraryList] = useState([])
    const [SearchKeywordLibrary, SetSearchKeywordLibrary] = useState('')
    const [RecordPerpage, SetRecordPerpage] = useState(10)
    const [RecipeAddListID, SetRecipeAddListID] = useState([])
    const [Session, SetSession] = useState('')
    const [BreakfastTableList, SetBreakfastTableList] = useState([])
    const [LuncTableList, SetLunchTableList] = useState([])
    const [snacksTableList, SetsnacksTableList] = useState([])
    const [dinnerTableList, SetdinnerTableList] = useState([])
    const [DailyGoalCalories, SetDailyGoalCalories] = useState('0')
    const [DailyGoalCarbs, SetDailyGoalCarbs] = useState('0')
    const [DailyGoalProteins, SetDailyGoalProteins] = useState('0')
    const [DailyGoalFats, SetDailyGoalFats] = useState('0')
    const [RemainingCalories, SetRemainingCalories] = useState('0')
    const [RemainingCarbs, SetRemainingCarbs] = useState('0')
    const [RemainingFats, SetRemainingFats] = useState('0')
    const [RemainingProtiens, SetRemainingProtiens] = useState('0')
    const [alreadybreakfastId, SetalreadybreakfastId] = useState('')
    const [alreadylunchId, SetalreadylunchId] = useState('')
    const [alreadysnacksId, SetalreadysnacksId] = useState('')
    const [alreadydinnerId, SetalreadydinnerId] = useState('')
    const [showCoachDietPlanTab, SetshowCoachDietPlanTab] = useState(false)
    const [showFoodJournalTab,SetshowFoodJournalTab]=useState(false)
    const [CoachDietPlanList, SetCoachDietPlanList] = useState([])
    const [FoodJournalList,SetFoodJournalList]=useState([])




    const [SearchFoodLibraryShow, SetSearchFoodLibraryShow] = useState(false)

    const ChangePopupTab = (e) => {

        SetshowCoachDietPlanTab(false)
        SetshowFoodJournalTab(false)

        SetSearchFoodLibraryShow(false)
        SetBillingPageNo(1)
        SetRecentlyAddedList([])
        const alltabs = document.querySelectorAll(".food_diary_modal_top_row span");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab')
        if (tab === 'library') {
            SetSearchFoodLibraryShow(true)
            SetLibraryList([])
            SetRecipeAddListID([])
            SetSearchKeywordLibrary('')
        }
        if (tab === 'recent_added') {
            ShowLoader()
            SetRecipeAddListID([])
            recentlyadded()
        }
        if (tab === 'favourite') {
            ShowLoader()
            SetRecipeAddListID([])
            FavouritesAdded()
        }
        if (tab === 'days_diary') {
            ShowLoader()
            SetRecipeAddListID([])
            GetWeekDietPlanList()
        }
        if (tab === 'coach_plan') {
            GetNutritionistList()
            SetCoachDietPlanList([])
            SetshowCoachDietPlanTab(true)
            SetRecipeAddListID([])

        }
        if(tab==='food_journal')
        {
            SetshowFoodJournalTab(true)
            GetFoodJournalList()
        }


    }

    const GetClientPlanKeys = (e) => {

        var SelectedValue = e.target.value;

        if (SelectedValue !== '') {
            SelectedValue = parseInt(e.target.value);
            var found = NutritionistList.filter(e => e.nutritionist_id === SelectedValue);
            if (found.length > 0) {



                if (Session === 'breakfast') {

                    var BreakFastJson = JSON.parse(found[0].breakfast_keys)
                    var BfArray = BreakFastJson.map(function (el) { return el.recipeid; });

                    var finalArray = [];

                    if (BfArray.length > 0) {
                        ShowLoader()
                    }

                    BfArray = removeDuplicates(BfArray)

                    BfArray.forEach(async (value, index, array) => {
                        var CheckKeyType = String(value)

                        if (!CheckKeyType.startsWith('FITPALIR')) {

                            try {

                                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                })
                                finalArray.push(response.data)

                            } catch (err) {
                                console.log(err)
                            }




                        }
                        else {

                            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + value, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            var RecipeObj = {};
                            if (RecipeData.data.recipe !== null) {




                                var RecipeImg = '';

                                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                                    RecipeImg = RecipeData.data.recipe.recipe_image
                                }




                                RecipeObj = {
                                    RecipeID: RecipeData.data.recipe.recipe_uid,
                                    Title: RecipeData.data.recipe.recipe_name,
                                    PhotoUrl: RecipeImg,
                                    NutritionInfo: { "TotalCalories": RecipeData.data.recipe.recipe_calories }

                                };

                                finalArray.push(RecipeObj)


                            }



                        }

                        if (index === array.length - 1) {

                            setTimeout(function () {
                                HideLoader()

                                SetCoachDietPlanList(finalArray)
                            }, 500);

                        }
                    })


                }
                if (Session === 'lunch') {

                    var BreakFastJson = JSON.parse(found[0].lunch_keys)
                    var BfArray = BreakFastJson.map(function (el) { return el.recipeid; });

                    var finalArray = [];
                    if (BfArray.length > 0) {
                        ShowLoader()
                    }
                    BfArray = removeDuplicates(BfArray)
                    BfArray.forEach(async (value, index, array) => {


                        var CheckType = String(value)

                        if (!CheckType.startsWith('FITPALIR')) {

                            try {

                                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                })

                                finalArray.push(response.data)

                            } catch (err) {
                                console.log(err)
                            }



                        }
                        else {

                            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + value, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            var RecipeObj = {};
                            if (RecipeData.data.recipe !== null) {




                                var RecipeImg = '';

                                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                                    RecipeImg = RecipeData.data.recipe.recipe_image
                                }




                                RecipeObj = {
                                    RecipeID: RecipeData.data.recipe.recipe_uid,
                                    Title: RecipeData.data.recipe.recipe_name,
                                    PhotoUrl: RecipeImg,
                                    NutritionInfo: { "TotalCalories": RecipeData.data.recipe.recipe_calories }

                                };

                                finalArray.push(RecipeObj)


                            }

                        }

                        if (index === array.length - 1) {

                            setTimeout(function () {
                                HideLoader()

                                SetCoachDietPlanList(finalArray)
                            }, 500);

                        }

                    })

                }
                if (Session === 'snacks') {

                    var BreakFastJson = JSON.parse(found[0].snack_keys)
                    var BfArray = BreakFastJson.map(function (el) { return el.recipeid; });
                    var finalArray = [];
                    if (BfArray.length > 0) {
                        ShowLoader()
                    }
                    BfArray = removeDuplicates(BfArray)
                    BfArray.forEach(async (value, index, array) => {

                        var CheckType = String(value);

                        if (!CheckType.startsWith('FITPALIR')) {

                            try {

                                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                })
                                finalArray.push(response.data)

                            } catch (err) {
                                console.log(err)
                            }



                        }
                        else {

                            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + value, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            var RecipeObj = {};
                            if (RecipeData.data.recipe !== null) {




                                var RecipeImg = '';

                                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                                    RecipeImg = RecipeData.data.recipe.recipe_image
                                }




                                RecipeObj = {
                                    RecipeID: RecipeData.data.recipe.recipe_uid,
                                    Title: RecipeData.data.recipe.recipe_name,
                                    PhotoUrl: RecipeImg,
                                    NutritionInfo: { "TotalCalories": RecipeData.data.recipe.recipe_calories }

                                };

                                finalArray.push(RecipeObj)


                            }

                        }

                        if (index === array.length - 1) {

                            setTimeout(function () {
                                HideLoader()
                                console.log('inn', finalArray)

                                SetCoachDietPlanList(finalArray)
                            }, 500);

                        }

                    })

                }
                if (Session === 'dinner') {

                    var BreakFastJson = JSON.parse(found[0].dinner_keys)
                    var BfArray = BreakFastJson.map(function (el) { return el.recipeid; });

                    var finalArray = [];
                    if (BfArray.length > 0) {
                        ShowLoader()
                    }
                    BfArray = removeDuplicates(BfArray)
                    BfArray.forEach(async (value, index, array) => {

                        var CheckType = String(value);

                        if (!CheckType.startsWith('FITPALIR')) {

                            try {

                                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                                })
                                finalArray.push(response.data)

                            } catch (err) {
                                console.log(err)
                            }



                        }
                        else {

                            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + value, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            var RecipeObj = {};
                            if (RecipeData.data.recipe !== null) {




                                var RecipeImg = '';

                                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                                    RecipeImg = RecipeData.data.recipe.recipe_image
                                }




                                RecipeObj = {
                                    RecipeID: RecipeData.data.recipe.recipe_uid,
                                    Title: RecipeData.data.recipe.recipe_name,
                                    PhotoUrl: RecipeImg,
                                    NutritionInfo: { "TotalCalories": RecipeData.data.recipe.recipe_calories }

                                };

                                finalArray.push(RecipeObj)


                            }

                        }

                        if (index === array.length - 1) {

                            setTimeout(function () {
                                HideLoader()
                                console.log('inn', finalArray)

                                SetCoachDietPlanList(finalArray)
                            }, 500);

                        }

                    })

                }



            }

        }
        else {
            SetCoachDietPlanList([])
        }
    }



    const Search_library_food = async (pagenumber) => {





        if (SearchKeywordLibrary !== '') {

            ShowLoader()

            try {

                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipes?any_kw=' + SearchKeywordLibrary + '&rpp=' + RecordPerpage + '&pg=' + pagenumber + '&api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '&photos=true', {

                }).then((response) => {
                    SetTotalRecordBilling(response.data.ResultCount)


                    var result = response.data.Results.map(function (a) { return a.RecipeID; });
                    var finalArray = [];


                    result.forEach(async (value, index, array) => {
                        try {
                            const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                            }).then((response) => {

                                finalArray.push(response)


                            }).then(() => {
                                if (index === array.length - 1) {

                                    setTimeout(function () {
                                        HideLoader()
                                        SetLibraryList(finalArray)
                                    }, 500);

                                }
                            })
                        } catch (err) {
                            console.log(err)
                        }


                    })

                })

            } catch (err) {
                console.log(err)
            }



        }
    }

    const SetReciepeAddData = (e) => {
        var Ids = RecipeAddListID
        if (e.target.checked === true) {
            Ids.push(e.target.value)
            SetRecipeAddListID(Ids)
        }
        else {

            const index = Ids.indexOf(e.target.value);
            if (index > -1) {
                Ids.splice(index, 1);
            }
            SetRecipeAddListID(Ids)

        }


    }

    const FinalAddReciepe = async (e) => {

        var finalsession = e.target.getAttribute('data-session')


        if (RecipeAddListID.length > 0) {

            var text = RecipeAddListID.toString();

            if (alreadyid !== 'false' && alreadyid !== '') {

                text = text + ',' + alreadyid

            }
            const Elements=document.querySelectorAll('.drop_nutri_change option[value=AI]')

            Elements.forEach(element => {
                element.selected=true
            });




            if (finalsession === 'breakfast') {

                ShowLoader()

                const response = await axiosJWT.post('/progress-diary',
                    {
                        'breakfast': text,
                        'ddate': startDate


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {

                    setTimeout(() => {
                        GetProgressDiary(startDate)
                        handleClose()
                        SetRecipeAddListID('')
                        HideLoader()
                    }, 500);

                }

            }

            if (finalsession === 'lunch') {
                ShowLoader()
                const response = await axiosJWT.post('/progress-diary',
                    {
                        'lunch': text,
                        'ddate': startDate


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    setTimeout(() => {
                        GetProgressDiary(startDate)
                        handleClose()
                        SetRecipeAddListID('')
                        HideLoader()
                    }, 500);
                }

            }
            if (finalsession === 'snacks') {
                ShowLoader()
                const response = await axiosJWT.post('/progress-diary',
                    {
                        'snacks': text,
                        'ddate': startDate


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    setTimeout(() => {
                        GetProgressDiary(startDate)
                        handleClose()
                        SetRecipeAddListID('')
                        HideLoader()
                    }, 500);
                }

            }
            if (finalsession === 'dinner') {
                ShowLoader()
                const response = await axiosJWT.post('/progress-diary',
                    {
                        'dinner': text,
                        'ddate': startDate


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    setTimeout(() => {
                        GetProgressDiary(startDate)
                        handleClose()
                        SetRecipeAddListID('')
                        HideLoader()
                    }, 500);
                }

            }

        }

    }

    const GetProgressDiary = async (DatePass,value='') => {

        var RemainingCaloriesAdd = 0;
        var RemainingCarbsAdd = 0;
        var RemainingFatsAdd = 0;
        var RemainingProteinsAdd = 0;
        var Dialycalories = 0;
        var Dailycarbs = 0;
        var Dailyfats = 0;
        var Dailyprotiens = 0;
        var TotalCarbsDay = 0;
        var TotalFatsDay = 0;
        var TotalProteinsDay = 0;
        var TotalCaloriesDay = 0;

        SetBreakfastTableList([])
        SetLunchTableList([])
        SetsnacksTableList([])
        SetdinnerTableList([])
        SetPercentageArray([0, 0, 0])

        SetTotalCaloriesDay(0)
        SetTotalFatDay(0)
        SetTotalProteinDay(0)
        SetTotalCarbsDay(0)



        const dateTimeInParts = DatePass.toISOString().split("T");
        const date = dateTimeInParts[0];
        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+value+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            if (response.data.final_data[1] !== null) {

                if (response.data.final_data[1].daily_calories !== undefined) {

                    SetDailyGoalCalories(response.data.final_data[1].daily_calories)
                    SetRemainingCalories(response.data.final_data[1].daily_calories)
                    Dialycalories = response.data.final_data[1].daily_calories

                }
                if (response.data.final_data[1].daily_carb !== undefined) {
                    SetDailyGoalCarbs(response.data.final_data[1].daily_carb)
                    SetRemainingCarbs(response.data.final_data[1].daily_carb)
                    Dailycarbs = response.data.final_data[1].daily_carb
                }
                if (response.data.final_data[1].daily_fat !== undefined) {
                    SetDailyGoalFats(response.data.final_data[1].daily_fat)
                    SetRemainingFats(response.data.final_data[1].daily_fat)
                    Dailyfats = response.data.final_data[1].daily_fat
                }
                if (response.data.final_data[1].daily_protein !== undefined) {
                    SetDailyGoalProteins(response.data.final_data[1].daily_protein)
                    SetRemainingProtiens(response.data.final_data[1].daily_protein)
                    Dailyprotiens = response.data.final_data[1].daily_protein
                }

            }




            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                SetalreadybreakfastId(breakfastId)
                var breakfastArray = breakfastId.split(",");


                var SettingBreakfastArray = [];

                breakfastArray.forEach(async (value, index, array) => {




                    const response = await GetRecipeDetails(value).then((response) => {



                        RemainingCaloriesAdd = RemainingCaloriesAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))



                        if (RemainingCaloriesAdd > Dialycalories) {
                            SetRemainingCalories(0)
                        }
                        else {
                            SetRemainingCalories(Dialycalories - RemainingCaloriesAdd)
                        }


                        RemainingCarbsAdd = RemainingCarbsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))

                        if (RemainingCarbsAdd > Dailycarbs) {
                            SetRemainingCarbs(0)
                        }
                        else {
                            SetRemainingCarbs(Dailycarbs - RemainingCarbsAdd)
                        }

                        RemainingFatsAdd = RemainingFatsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalFat))

                        if (RemainingFatsAdd > Dailyfats) {
                            SetRemainingFats(0)
                        }
                        else {
                            SetRemainingFats(Dailyfats - RemainingFatsAdd)
                        }

                        RemainingProteinsAdd = RemainingProteinsAdd + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        if (RemainingProteinsAdd > Dailyprotiens) {
                            SetRemainingProtiens(0)
                        }
                        else {
                            SetRemainingProtiens(Dailyprotiens - RemainingProteinsAdd)
                        }


                        TotalCaloriesDay = TotalCaloriesDay + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))


                        if (TotalCaloriesDay < Dialycalories) {
                            SetTotalCaloriesDay(TotalCaloriesDay)
                        }
                        else {
                            SetTotalCaloriesDay(Dialycalories)
                        }

                        TotalCarbsDay = TotalCarbsDay + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        if (TotalCarbsDay < Dailycarbs) {
                            SetTotalCarbsDay(TotalCarbsDay)
                        }
                        else {
                            SetTotalCarbsDay(Dailycarbs)
                        }

                        TotalFatsDay = TotalFatsDay + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        if (TotalFatsDay < Dailyfats) {
                            SetTotalFatDay(TotalFatsDay)
                        }
                        else {
                            SetTotalFatDay(Dailyfats)
                        }

                        TotalProteinsDay = TotalProteinsDay + Math.round(parseInt(response.data.NutritionInfo.Protein))


                        if (TotalProteinsDay < Dailyprotiens) {
                            SetTotalProteinDay(TotalProteinsDay)
                        }
                        else {
                            SetTotalProteinDay(Dailyprotiens)
                        }


                        SetPercentageArray([TotalCarbsDay, TotalFatsDay, TotalProteinsDay])




                        SettingBreakfastArray.push(response)


                    }).then(() => {
                        if (index === array.length - 1) {
                            setTimeout(function () {
                                HideLoader()
                                SetBreakfastTableList(SettingBreakfastArray)
                            }, 500);

                        }
                    })
                })

            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== undefined && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var lunchId = response.data.final_data[0][0].lunch
                SetalreadylunchId(lunchId)
                var lunchArray = lunchId.split(",");

                var SettinglunchArray = [];

                lunchArray.forEach(async (value, index, array) => {


                    const response = await GetRecipeDetails(value).then((response) => {

                        RemainingCaloriesAdd = RemainingCaloriesAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        if (RemainingCaloriesAdd > Dialycalories) {
                            SetRemainingCalories(0)
                        }
                        else {
                            SetRemainingCalories(Dialycalories - RemainingCaloriesAdd)
                        }


                        RemainingCarbsAdd = RemainingCarbsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))

                        if (RemainingCarbsAdd > Dailycarbs) {
                            SetRemainingCarbs(0)
                        }
                        else {
                            SetRemainingCarbs(Dailycarbs - RemainingCarbsAdd)
                        }

                        RemainingFatsAdd = RemainingFatsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalFat))

                        if (RemainingFatsAdd > Dailyfats) {
                            SetRemainingFats(0)
                        }
                        else {
                            SetRemainingFats(Dailyfats - RemainingFatsAdd)
                        }

                        RemainingProteinsAdd = RemainingProteinsAdd + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        if (RemainingProteinsAdd > Dailyprotiens) {
                            SetRemainingProtiens(0)
                        }
                        else {
                            SetRemainingProtiens(Dailyprotiens - RemainingProteinsAdd)
                        }

                        TotalCaloriesDay = TotalCaloriesDay + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))


                        if (TotalCaloriesDay < Dialycalories) {
                            SetTotalCaloriesDay(TotalCaloriesDay)
                        }
                        else {
                            SetTotalCaloriesDay(Dialycalories)
                        }

                        TotalCarbsDay = TotalCarbsDay + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        if (TotalCarbsDay < Dailycarbs) {
                            SetTotalCarbsDay(TotalCarbsDay)
                        }
                        else {
                            SetTotalCarbsDay(Dailycarbs)
                        }

                        TotalFatsDay = TotalFatsDay + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        if (TotalFatsDay < Dailyfats) {
                            SetTotalFatDay(TotalFatsDay)
                        }
                        else {
                            SetTotalFatDay(Dailyfats)
                        }

                        TotalProteinsDay = TotalProteinsDay + Math.round(parseInt(response.data.NutritionInfo.Protein))


                        if (TotalProteinsDay < Dailyprotiens) {
                            SetTotalProteinDay(TotalProteinsDay)
                        }
                        else {
                            SetTotalProteinDay(Dailyprotiens)
                        }


                        SetPercentageArray([TotalCarbsDay, TotalFatsDay, TotalProteinsDay])
                        SettinglunchArray.push(response)


                    }).then(() => {
                        if (index === array.length - 1) {

                            setTimeout(function () {
                                HideLoader()
                                SetLunchTableList(SettinglunchArray)
                            }, 500);

                        }
                    })
                })

            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== undefined && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var snacksId = response.data.final_data[0][0].snacks
                SetalreadysnacksId(snacksId)
                var snacksArray = snacksId.split(",");

                var SettingsnacksArray = [];

                snacksArray.forEach(async (value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        RemainingCaloriesAdd = RemainingCaloriesAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        if (RemainingCaloriesAdd > Dialycalories) {
                            SetRemainingCalories(0)
                        }
                        else {
                            SetRemainingCalories(Dialycalories - RemainingCaloriesAdd)
                        }


                        RemainingCarbsAdd = RemainingCarbsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))

                        if (RemainingCarbsAdd > Dailycarbs) {
                            SetRemainingCarbs(0)
                        }
                        else {
                            SetRemainingCarbs(Dailycarbs - RemainingCarbsAdd)
                        }

                        RemainingFatsAdd = RemainingFatsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalFat))

                        if (RemainingFatsAdd > Dailyfats) {
                            SetRemainingFats(0)
                        }
                        else {
                            SetRemainingFats(Dailyfats - RemainingFatsAdd)
                        }

                        RemainingProteinsAdd = RemainingProteinsAdd + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        if (RemainingProteinsAdd > Dailyprotiens) {
                            SetRemainingProtiens(0)
                        }
                        else {
                            SetRemainingProtiens(Dailyprotiens - RemainingProteinsAdd)
                        }

                        TotalCaloriesDay = TotalCaloriesDay + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))


                        if (TotalCaloriesDay < Dialycalories) {
                            SetTotalCaloriesDay(TotalCaloriesDay)
                        }
                        else {
                            SetTotalCaloriesDay(Dialycalories)
                        }

                        TotalCarbsDay = TotalCarbsDay + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        if (TotalCarbsDay < Dailycarbs) {
                            SetTotalCarbsDay(TotalCarbsDay)
                        }
                        else {
                            SetTotalCarbsDay(Dailycarbs)
                        }

                        TotalFatsDay = TotalFatsDay + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        if (TotalFatsDay < Dailyfats) {
                            SetTotalFatDay(TotalFatsDay)
                        }
                        else {
                            SetTotalFatDay(Dailyfats)
                        }

                        TotalProteinsDay = TotalProteinsDay + Math.round(parseInt(response.data.NutritionInfo.Protein))


                        if (TotalProteinsDay < Dailyprotiens) {
                            SetTotalProteinDay(TotalProteinsDay)
                        }
                        else {
                            SetTotalProteinDay(Dailyprotiens)
                        }
                        SetPercentageArray([TotalCarbsDay, TotalFatsDay, TotalProteinsDay])
                        SettingsnacksArray.push(response)


                    }).then(() => {
                        if (index === array.length - 1) {
                            setTimeout(function () {
                                HideLoader()
                                SetsnacksTableList(SettingsnacksArray)
                            }, 500);

                        }
                    })
                })

            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== undefined && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {



                var dinnerId = response.data.final_data[0][0].dinner
                SetalreadydinnerId(dinnerId)
                var dinnerArray = dinnerId.split(",");

                var SettingdinnerArray = [];

                dinnerArray.forEach(async (value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        RemainingCaloriesAdd = RemainingCaloriesAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        if (RemainingCaloriesAdd > Dialycalories) {
                            SetRemainingCalories(0)
                        }
                        else {
                            SetRemainingCalories(Dialycalories - RemainingCaloriesAdd)
                        }


                        RemainingCarbsAdd = RemainingCarbsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))

                        if (RemainingCarbsAdd > Dailycarbs) {
                            SetRemainingCarbs(0)
                        }
                        else {
                            SetRemainingCarbs(Dailycarbs - RemainingCarbsAdd)
                        }

                        RemainingFatsAdd = RemainingFatsAdd + Math.round(parseInt(response.data.NutritionInfo.TotalFat))

                        if (RemainingFatsAdd > Dailyfats) {
                            SetRemainingFats(0)
                        }
                        else {
                            SetRemainingFats(Dailyfats - RemainingFatsAdd)
                        }

                        RemainingProteinsAdd = RemainingProteinsAdd + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        if (RemainingProteinsAdd > Dailyprotiens) {
                            SetRemainingProtiens(0)
                        }
                        else {
                            SetRemainingProtiens(Dailyprotiens - RemainingProteinsAdd)
                        }

                        TotalCaloriesDay = TotalCaloriesDay + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))


                        if (TotalCaloriesDay < Dialycalories) {
                            SetTotalCaloriesDay(TotalCaloriesDay)
                        }
                        else {
                            SetTotalCaloriesDay(Dialycalories)
                        }

                        TotalCarbsDay = TotalCarbsDay + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        if (TotalCarbsDay < Dailycarbs) {
                            SetTotalCarbsDay(TotalCarbsDay)
                        }
                        else {
                            SetTotalCarbsDay(Dailycarbs)
                        }

                        TotalFatsDay = TotalFatsDay + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        if (TotalFatsDay < Dailyfats) {
                            SetTotalFatDay(TotalFatsDay)
                        }
                        else {
                            SetTotalFatDay(Dailyfats)
                        }

                        TotalProteinsDay = TotalProteinsDay + Math.round(parseInt(response.data.NutritionInfo.Protein))


                        if (TotalProteinsDay < Dailyprotiens) {
                            SetTotalProteinDay(TotalProteinsDay)
                        }
                        else {
                            SetTotalProteinDay(Dailyprotiens)
                        }
                        SetPercentageArray([TotalCarbsDay, TotalFatsDay, TotalProteinsDay])
                        SettingdinnerArray.push(response)


                    }).then(() => {
                        if (index === array.length - 1) {
                            setTimeout(function () {
                                HideLoader()
                                SetdinnerTableList(SettingdinnerArray)
                            }, 500);

                        }
                    })
                })

            }




        })
    }

    const GetRecipeDetails = async (RecipeId) => {


        var CheckType = String(RecipeId)
        if (!CheckType.startsWith('FITPALIR') && !CheckType.startsWith('FJ')) {

            try {

                const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + RecipeId + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                })
                return response

            } catch (err) {
                console.log(err)
            }

        }
        else if(CheckType.startsWith('FITPALIR')) {

            var Recipeobj = {};

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (RecipeData.data.recipe !== null) {

                var RecipeImg = '';

                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                    RecipeImg = RecipeData.data.recipe.recipe_image
                }


                Recipeobj = {

                    data: {
                        RecipeID: RecipeData.data.recipe.recipe_uid,
                        Title: RecipeData.data.recipe.recipe_name,
                        PhotoUrl: RecipeImg,
                        NutritionInfo: {

                            TotalCalories: RecipeData.data.recipe.recipe_calories,
                            TotalFat: RecipeData.data.recipe.recipe_fats,
                            Protein: RecipeData.data.recipe.recipe_proteins,
                            TotalCarbs: RecipeData.data.recipe.recipe_carbs

                        }
                    }


                }

            }


            return Recipeobj
        }
        else if (CheckType.startsWith('FJ'))
        {
         

            RecipeId = RecipeId.replace(/\D/g,'');
            var Recipeobj = {};
            const RecipeData = await axiosJWT.get("/food-journal/detail?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            var RecipeName='Recipe Name not available';
            var Calories=0;
            var Fat=0;
            var Carbs=0;
            var Protein=0;
            var RecipeImage=RecipeData.data.result.journal_img;
            var CreatedRecipeId='FJ'+RecipeData.data.result.id;
    
            if(RecipeData.data.result.ingredients)
            {
     
                if(RecipeData.data.result.ingredients!==null && RecipeData.data.result.ingredients!=='')
                {
                 
                    const RecipeDetailsObj=JSON.parse(RecipeData.data.result.ingredients)
                    
                    if(RecipeDetailsObj[0])
                    {
                        if(RecipeDetailsObj[0].ingredient_name)
                        {
                            RecipeName=RecipeDetailsObj[0].ingredient_name
                        }
                    }
                   

                  

                    RecipeDetailsObj.forEach(element => {
                       //caloriemama kg to g converting
                       if(element.calories)
                        {
                            Calories=parseFloat(element.calories)+Calories;
                        }
                        if(element.fat)
                        {
                            Fat=(parseFloat(element.fat)*1000)+Fat;
                           
                        }
                        if(element.protein)
                        {
                            Protein=(parseFloat(element.protein)*1000)+Protein;
                        }
                        if(element.carbs)
                        {
                            Carbs=(parseFloat(element.carbs)*1000)+Carbs;
                        }
                  

                    });
                  
                }
            }

           
            Recipeobj = {

                data: {
                    RecipeID: CreatedRecipeId,
                    Title: RecipeName,
                    PhotoUrl: RecipeImage,
                    NutritionInfo: {

                        TotalCalories: Calories,
                        TotalFat: Fat,
                        Protein: Protein,
                        TotalCarbs: Carbs

                    }
                }


            }

            return Recipeobj
          
           
        }


    }

    useEffect(() => {
        document.title = 'Client-Food diary';
        GetProgressDiary(startDate)
        GetMySubscribedCoaches()
    }, [])


    const ChangedDate = (date) => {

        setStartDate(date)
        SetBreakfastTableList([])
        SetLunchTableList([])
        SetsnacksTableList([])
        SetdinnerTableList([])
        GetProgressDiary(date)




        const Elements=document.querySelectorAll('.drop_nutri_change option[value=AI]')

        Elements.forEach(element => {
            element.selected=true
        });

    }

    const DeleteFood = async (e) => {

        var Deletesession = e.target.getAttribute('data-session')
        var DeleteId = e.target.getAttribute('data-id')
        var deletedateTimeInParts = startDate.toISOString().split("T");
        var deletedate = deletedateTimeInParts[0];

        const response = await axiosJWT.post('progress-diary/delete-food-diary',
            {
                'session': Deletesession,
                'ddate': deletedate,
                'id': DeleteId


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            GetProgressDiary(startDate)
            const Elements=document.querySelectorAll('.drop_nutri_change option[value=AI]')

            Elements.forEach(element => {
                element.selected=true
            });
        }



    }

    const recentlyadded = () => {

        var RecentlyAddedArrayPush = [];

        const response = axiosJWT.get('progress-diary/recently-added-food', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {



            if (response.data.recipe_id !== '') {

                var reciepeaddedstring = response.data.recipe_id;

                var reciepeaddedarray = reciepeaddedstring.split(",");


                var bar = new Promise((resolve, reject) => {

                    reciepeaddedarray = removeDuplicates(reciepeaddedarray)
                    reciepeaddedarray.forEach(async (value, index, array) => {


                        const response = await GetRecipeDetails(value).then((response) => {
                            RecentlyAddedArrayPush.push(response.data)
                            if (index === array.length - 1) {

                                resolve();
                            }

                        })





                    })
                })

                bar.then(() => {

                    setTimeout(function () {
                        HideLoader()

                        SetRecentlyAddedList(RecentlyAddedArrayPush)
                    }, 500);
                })


            }
            else {
                HideLoader()
            }
        })


    }

    const FavouritesAdded = async () => {

        var RecentlyAddedArrayPush = [];

        const response = axiosJWT.get('/week-diet-plan/favorites', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            if(response.data!==null)
            {

                if (response.data.favorites !== null && response.data.favorites !== '') {

                    var reciepeaddedstring = response.data.favorites;
                    var reciepeaddedarray = reciepeaddedstring.split(",");
    
    
                    var bar = new Promise((resolve, reject) => {
    
                        reciepeaddedarray.forEach(async (value, index, array) => {
    
                            try {
                                const response = await axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + value + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {
                                }).then((response) => {
                                    RecentlyAddedArrayPush.push(response.data)
                                    if (index === array.length - 1) {
                                        resolve();
                                    }
                                });
                            } catch (err) {
                                console.log(err)
                            }
                        });
                    });
    
                    bar.then(() => {
                        setTimeout(function () {
                            HideLoader();
                            SetRecentlyAddedList(RecentlyAddedArrayPush)
                        }, 50);
                    });
                }
                else {
                    HideLoader();
                }

            }
            else{
                HideLoader();
            }
           
        });
    }

    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }

    const GetFoodJournalList=async()=>{

        const response = await axiosJWT.get('/food-journal?sort=DESC&type='+Session+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        
        if(response.data!==null)
        {
            if(response.data.list)
            {

                var FinalArray=[]
                response.data.list.forEach(element => {

                    console.log(element)

                    var RecipeName='Recipe Name not available';
                    var Calories=0;
                    var RecipeImage=element.journal_img;
                    var RecipeId='FJ'+element.id
                    if(element.ingredients)
                    {
                        if(element.ingredients!==null && element.ingredients!=='')
                        {
                            const RecipeDetailsObj=JSON.parse(element.ingredients)
                            if(RecipeDetailsObj[0])
                            {
                             if(RecipeDetailsObj[0].ingredient_name)
                            {
                                RecipeName=RecipeDetailsObj[0].ingredient_name
                            }
                            }
                            

                            RecipeDetailsObj.forEach(element => {
                                Calories=parseFloat(element.calories)+Calories
                            });
                          
                        }
                    }

                    var RecipeObj={
                        RecipeID:RecipeId,
                        RecipeImg:RecipeImage,
                        RecipeCalories:Calories,
                        RecipeName:RecipeName

                    }
                    if(Calories!==0)
                    {
                        FinalArray.push(RecipeObj)
                    }

            
                    
                });
                SetFoodJournalList(FinalArray)
            }
        }




    }

       // AI goal 

       const [GoalSource,SetGoalSource]=useState('AI')
       const [Expertoptions,setExpertoptions]=useState([])
       const[CoachId,SetCoachId]=useState('')

       const GetMySubscribedCoaches=async()=>{

        var ExpertObj=[]
        try {
            const AllNutrition = await axiosJWT.get("/mysubscribedcoaches", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (AllNutrition.status === 200) {
              if (AllNutrition.data.Users !== null) {
                AllNutrition.data.Users.forEach((data, index) => {
                  var obj = {};
                  obj["value"] = data.id;
                  obj["label"] = data.first_name+' '+data.last_name;
                  ExpertObj.push(obj);
                });
              }
            }
            setExpertoptions(ExpertObj);
          } catch (err) {
            console.log(err);
          }
    }


    const ChangeGoalSource=(value)=>{

        //inn
       
        SetCoachId(value)
        SetGoalSource(value)

        GetProgressDiary(startDate,value)
   
    }



    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <PremiumCheckComponent />
            {loader === true && <div id="semiTransparenDivLoader"></div>}

            <section className="client_food_diary_section">
                <div className="client_food_diary container">
                    {/* <div className="row client_food_diary_first_row over_div_fif">
                        <span className="client_food_diary_top_label fif_label_progress">Food Diary</span>
                        <span className="calendar_icon_div fif_change_report">
                            <i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                            e.preventDefault();
                        }} onChange={(date: Date) => ChangedDate(date)} />
                    </div> */}
                    {/* <div className="row client_food_diary_first_row over_div_fif">
                        <span className="client_food_diary_top_label fif_label_progress">Food Diary</span>
                        <span className="calendar_icon_div fif_icon">
                            <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                        </span>
                        <DatePicker selected={startDate} ref={datepicker1Ref} dateFormat="MMMM d,yyyy" onKeyDown={(e) => {
                            e.preventDefault();
                        }} onChange={(date) => ChangedDate(date)} />
                    </div> */}

                    <div className="row client_food_diary_first_row over_div_fif">
                        <div className="col_1">
                            <span className="client_food_diary_top_label fif_label_progress">Progress Diary</span>
                        </div>
                        <div className="col_2">
                            <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>

                            <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                e.preventDefault();
                            }} onChange={(date) => ChangedDate(date)} ref={datepicker1Ref} />
                        </div>
                    </div>

                    <div style={{paddingLeft:'0px',marginBottom:'10px',display:'flex',gap:'10px'}}>
                        <div style={{ fontWeight: '500',paddingTop:'5px' }}>Target goal :</div>
                        <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart drop_nutri_change' onChange={(e) => { ChangeGoalSource(e.target.value) }} style={{ fontWeight: '600' }} >

                        
                                <option value="AI">AI generated</option>
                                {Expertoptions.length > 0 && Expertoptions.map((Nutritionist, index) => (
                                            <option value={Nutritionist.value}>{'Coach: '}{Nutritionist.label}</option>
                                        ))}

                            </select>
                        </div>
                    <div className="row client_food_diary_second_row">
                        <div className="col nutrition_pie_chart">

                      
                            <div className="pie_chart_heading">Nutrition summary</div>
                            <p className='pie_chart_kcal_data'>Calories: <span className="rosy_blue">{TotalCaloriesDay}</span><span>/</span><span>{DailyGoalCalories}</span><span> Kcal</span></p>
                            <Pie data={data} options={options} />
                            <hr />
                            <div className="pie_chart_bottom_div">
                                <span>
                                    <span className="dotcarbs"></span>
                                    <span className="pie_chart_bottom_div_label">Carbs</span>
                                    <span className="pie_chart_bottom_div_data"><span className="rosy_blue">{TotalCarbsDay}</span><span>/</span><span>{DailyGoalCarbs}</span><span>G</span></span>
                                </span>
                                <span>
                                    <span className="dotfat"></span>
                                    <span className="pie_chart_bottom_div_label">Fat</span>
                                    <span className="pie_chart_bottom_div_data"><span className="rosy_blue">{TotalFatDay}</span><span>/</span><span>{DailyGoalFats}</span><span>G</span></span>
                                </span>
                                <span>
                                    <span className="dotprotein"></span>
                                    <span className="pie_chart_bottom_div_label">Protein</span>
                                    <span className="pie_chart_bottom_div_data"><span className="rosy_blue">{TotalProteinDay}</span><span>/</span><span>{DailyGoalProteins}</span><span>G</span></span>
                                </span>
                                {/* <span>
                            <span className="dotcalories"></span>
                            <span className="pie_chart_bottom_div_label">Calories</span>
                            <span className="pie_chart_bottom_div_data"><span className="rosy_blue">{TotalCaloriesDay}</span><span>/</span><span>{DailyGoalCalories}</span><span>G</span></span>
                            </span> */}
                            </div>
                        </div>
                        <div className="col food_diary_info_right">
                            <div className="row food_diary_info_right_row">
                                <table className="table is-striped is-fullwidth">
                                    <thead className="thead">
                                        <tr>
                                            <th></th>
                                            <th>Calories</th>
                                            <th>Carbs</th>
                                            <th>Fats</th>
                                            <th>Protiens</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody">


                                        <tr className='trow'>
                                            <td>Your Daily Goal</td>
                                            <td >{DailyGoalCalories}</td>
                                            <td >{DailyGoalCarbs}</td>
                                            <td >{DailyGoalFats}</td>
                                            <td >{DailyGoalProteins}</td>



                                        </tr>
                                        <tr className='trow'>
                                            <td>Remaining</td>
                                            <td >{RemainingCalories}</td>
                                            <td >{RemainingCarbs}</td>
                                            <td >{RemainingFats}</td>
                                            <td >{RemainingProtiens}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row add_food_info">

                                <div className="row food_diary_table">
                                    <div className="row">
                                        <div className="col">
                                            <span>Breakfast</span>
                                        </div>
                                        <div className="col">
                                            <button type="button" className="btn btn-success add_food_button_progress" data-session="breakfast" data-alreadyId={BreakfastTableList.length > 0 && alreadybreakfastId} onClick={(e) => { trigger_add_food_popup(e) }}>Add food</button>
                                        </div>
                                        {BreakfastTableList.length > 0 &&
                                            <div className="row">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Reciepe</th>
                                                            <th scope="col">Calories</th>
                                                            <th scope="col">Fats</th>
                                                            <th scope="col">Protiens</th>
                                                            <th scope="col">Carbs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {BreakfastTableList.length > 0 && BreakfastTableList.map((BreakfastTableList, index) => (
                                                            <tr>
                                                                <td scope="row">

                                                                    {
                                                                        BreakfastTableList.data.PhotoUrl !== '' ? <img src={BreakfastTableList.data.PhotoUrl} alt="recipe" className='reciepe_logo_food_diary' /> :

                                                                            <img src={DummyRecipeImg} alt="recipe" className='reciepe_logo_food_diary' />
                                                                    }



                                                                    <span className="table_list_span">{BreakfastTableList.data.Title}</span></td>
                                                                <td>{BreakfastTableList.data.NutritionInfo.TotalCalories} Kcal</td>
                                                                <td>{Math.floor(BreakfastTableList.data.NutritionInfo.TotalFat)}g</td>
                                                                <td>{Math.floor(BreakfastTableList.data.NutritionInfo.Protein)}g</td>
                                                                <td>{Math.floor(BreakfastTableList.data.NutritionInfo.TotalCarbs)}g</td>
                                                                <td><i data-session="breakfast" data-id={BreakfastTableList.data.RecipeID} onClick={(e) => { DeleteFood(e) }} className="fa fa-trash billing_edit_i pointer_eff"></i></td>
                                                            </tr>

                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div>
                                        }
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col">
                                            <span>Lunch</span>
                                        </div>
                                        <div className="col">
                                            <button type="button" className="btn btn-success add_food_button_progress" onClick={(e) => { trigger_add_food_popup(e) }} data-alreadyId={LuncTableList.length > 0 && alreadylunchId} data-session="lunch">Add food</button>
                                        </div>

                                        {LuncTableList.length > 0 &&

                                            <div className="row">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Reciepe</th>
                                                            <th scope="col">Calories</th>
                                                            <th scope="col">Fats</th>
                                                            <th scope="col">Protiens</th>
                                                            <th scope="col">Carbs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {LuncTableList.length > 0 && LuncTableList.map((LuncTableList, index) => (
                                                            <tr>
                                                                <td scope="row">

                                                                    {
                                                                        LuncTableList.data.PhotoUrl !== '' ? <img src={LuncTableList.data.PhotoUrl} alt="recipe" className='reciepe_logo_food_diary' /> :

                                                                            <img src={DummyRecipeImg} alt="recipe" className='reciepe_logo_food_diary' />
                                                                    }



                                                                    <span className="table_list_span">{LuncTableList.data.Title}</span></td>
                                                                <td>{LuncTableList.data.NutritionInfo.TotalCalories} Kcal</td>
                                                                <td>{Math.round(LuncTableList.data.NutritionInfo.TotalFat)}g</td>
                                                                <td>{Math.round(LuncTableList.data.NutritionInfo.Protein)}g</td>
                                                                <td>{Math.round(LuncTableList.data.NutritionInfo.TotalCarbs)}g</td>
                                                                <td><i data-session="lunch" data-id={LuncTableList.data.RecipeID} onClick={(e) => { DeleteFood(e) }} className="fa fa-trash billing_edit_i pointer_eff"></i></td>
                                                            </tr>

                                                        ))}


                                                    </tbody>
                                                </table>

                                            </div>

                                        }


                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col">
                                            <span>Snacks</span>
                                        </div>
                                        <div className="col">
                                            <button type="button" className="btn btn-success add_food_button_progress" onClick={(e) => { trigger_add_food_popup(e) }} data-alreadyId={snacksTableList.length > 0 && alreadysnacksId} data-session="snacks">Add food</button>
                                        </div>

                                        {snacksTableList.length > 0 &&
                                            <div className="row">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Reciepe</th>
                                                            <th scope="col">Calories</th>
                                                            <th scope="col">Fats</th>
                                                            <th scope="col">Protiens</th>
                                                            <th scope="col">Carbs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        {snacksTableList.length > 0 && snacksTableList.map((snacksTableList, index) => (
                                                            <tr>
                                                                <td scope="row">



                                                                    {
                                                                        snacksTableList.data.PhotoUrl !== '' ? <img src={snacksTableList.data.PhotoUrl} alt="recipe" className='reciepe_logo_food_diary' /> :

                                                                            <img src={DummyRecipeImg} alt="recipe" className='reciepe_logo_food_diary' />
                                                                    }


                                                                    <span className="table_list_span">{snacksTableList.data.Title}</span></td>
                                                                <td>{snacksTableList.data.NutritionInfo.TotalCalories} Kcal</td>
                                                                <td>{Math.round(snacksTableList.data.NutritionInfo.TotalFat)}g</td>
                                                                <td>{Math.round(snacksTableList.data.NutritionInfo.Protein)}g</td>
                                                                <td>{Math.round(snacksTableList.data.NutritionInfo.TotalCarbs)}g</td>
                                                                <td><i data-session="snacks" data-id={snacksTableList.data.RecipeID} onClick={(e) => { DeleteFood(e) }} className="fa fa-trash billing_edit_i pointer_eff"></i></td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>

                                        }
                                    </div>
                                    <hr />


                                    <div className="row">
                                        <div className="col">
                                            <span>Dinner</span>
                                        </div>
                                        <div className="col">
                                            <button type="button" className="btn btn-success add_food_button_progress" onClick={(e) => { trigger_add_food_popup(e) }} data-session="dinner" data-alreadyId={dinnerTableList.length > 0 && alreadydinnerId}>Add food</button>
                                        </div>
                                        {dinnerTableList.length > 0 &&
                                            <div className="row">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Reciepe</th>
                                                            <th scope="col">Calories</th>
                                                            <th scope="col">Fats</th>
                                                            <th scope="col">Protiens</th>
                                                            <th scope="col">Carbs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        {dinnerTableList.length > 0 && dinnerTableList.map((dinnerTableList, index) => (
                                                            <tr>
                                                                <td scope="row">

                                                                    {
                                                                        dinnerTableList.data.PhotoUrl !== '' ? <img src={dinnerTableList.data.PhotoUrl} alt="recipe" className='reciepe_logo_food_diary' /> :

                                                                            <img src={DummyRecipeImg} alt="recipe" className='reciepe_logo_food_diary' />
                                                                    }



                                                                    <span className="table_list_span">{dinnerTableList.data.Title}</span></td>
                                                                <td>{dinnerTableList.data.NutritionInfo.TotalCalories} Kcal</td>
                                                                <td>{Math.round(dinnerTableList.data.NutritionInfo.TotalFat)}g</td>
                                                                <td>{Math.round(dinnerTableList.data.NutritionInfo.Protein)}g</td>
                                                                <td>{Math.round(dinnerTableList.data.NutritionInfo.TotalCarbs)}g</td>
                                                                <td><i data-session="dinner" data-id={dinnerTableList.data.RecipeID} onClick={(e) => { DeleteFood(e) }} className="fa fa-trash billing_edit_i pointer_eff"></i></td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>

                                        }
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </section>

            <Modal show={show} onHide={handleClose} className='commission_popup food_diary_popup food_diary_client_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Food Diary</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row food_diary_modal_top_row">
                        <span className="active" data-tab={"days_diary"} onClick={(e) => { ChangePopupTab(e) }}>Day's Diary</span>
                        <span data-tab={"library"} onClick={(e) => { ChangePopupTab(e) }}>Library</span>
                        <span data-tab={"favourite"} onClick={(e) => { ChangePopupTab(e) }}>Favourites</span>
                        <span data-tab={"recent_added"} onClick={(e) => { ChangePopupTab(e) }}>Recently Added</span>
                        <span data-tab={"coach_plan"} onClick={(e) => { ChangePopupTab(e) }}>Coach Plan</span>
                        <span data-tab={"food_journal"} onClick={(e) => { ChangePopupTab(e) }}>Food Journal</span>
                    </div>

                    {SearchFoodLibraryShow === true &&
                        <div className="row search_library_food_row">
                            <label className="inner_profile_label">Search reciepe</label>
                            <input type='text' defaultValue={SearchKeywordLibrary} value={SearchKeywordLibrary} onChange={(e) => { SetSearchKeywordLibrary(e.target.value) }}
                                className="add_category_inner_input profile_input total_session" />
                            <button type="button" className="btn btn-success add_food_button_progress" data-pno={'1'} onClick={(e) => { Search_library_food(e.target.getAttribute('data-pno')) }}>Search</button>
                        </div>

                    }
                    {SearchFoodLibraryShow === true &&
                        <div className="row recipe_list_row_food_diary">
                            <span className="recipe_list_row_food_diary_heading">Search Results</span>

                            {LibraryList.length > 0 &&
                                <ul className="library_list_food_diary_ul" id="library_list_food_diary_ulID">
                                    {LibraryList.length > 0 && LibraryList.map((reciepe, index) => (
                                        <li>
                                            <input type="checkbox" value={reciepe.data.RecipeID} onChange={(e) => { SetReciepeAddData(e) }} />
                                            <img src={reciepe.data.PhotoUrl} alt="userdrptag" className='reciepe_logo_food_diary_list' />
                                            <span className="library_list_food_diary_ul_span">{reciepe.data.Title}</span>
                                            <span className="library_list_food_diary_ul_kcal"><i className="fa fa-fire library_list_food_diary_ul_kcal_i" aria-hidden="true"></i>Kcal:{reciepe.data.NutritionInfo.TotalCalories}</span>
                                        </li>
                                    ))}

                                </ul>
                            }

                            {LibraryList.length > 0 &&

                                <Pagination
                                    activePage={BillingPageNo}
                                    itemsCountPerPage={BillingPerPage}
                                    totalItemsCount={TotalRecordBilling}
                                    onChange={changePageNumberS.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />

                            }



                        </div>



                    }



                    {RecentlyAddedList.length > 0 &&
                        <ul className="library_list_food_diary_ul">
                            {RecentlyAddedList.length > 0 && RecentlyAddedList.map((reciepe, index) => (
                                <li>
                                    <input type="checkbox" value={reciepe.RecipeID} onChange={(e) => { SetReciepeAddData(e) }} />

                                    {
                                        reciepe.PhotoUrl !== '' ? <img src={reciepe.PhotoUrl} alt="userdrptag" className='reciepe_logo_food_diary_list' /> :
                                            <img src={DummyRecipeImg} alt="userdrptag" className='reciepe_logo_food_diary_list' />
                                    }

                                    <span className="library_list_food_diary_ul_span">{reciepe.Title}</span>
                                    <span className="library_list_food_diary_ul_kcal"><i className="fa fa-fire library_list_food_diary_ul_kcal_i" aria-hidden="true"></i>Kcal:{reciepe.NutritionInfo.TotalCalories}</span>
                                </li>
                            ))}

                        </ul>

                    }

                    {showCoachDietPlanTab === true &&
                        <div>
                            <div className="row coach_meal_plan_food_diary">
                                <div className="col_1">
                                    <select className='btn drpbtn dropdown-toggle color_grey inner_popup_select' onChange={(e) => {
                                        GetClientPlanKeys(e)
                                    }}>
                                        <option value="">Select Coach</option>
                                        {NutritionistList.length > 0 && NutritionistList.map((Nutritionist, index) => (
                                            <option value={Nutritionist.nutritionist_id}>{Nutritionist.nutritionist.first_name + ' ' + Nutritionist.nutritionist.last_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <ul className="library_list_food_diary_ul">
                                {CoachDietPlanList.length > 0 && CoachDietPlanList.map((reciepe, index) => (
                                    <li>
                                        <input type="checkbox" value={reciepe.RecipeID} onChange={(e) => { SetReciepeAddData(e) }} />

                                        {
                                            reciepe.PhotoUrl !== '' ? <img src={reciepe.PhotoUrl} alt="userdrptag" className='reciepe_logo_food_diary_list' /> :
                                                <img src={DummyRecipeImg} alt="userdrptag" className='reciepe_logo_food_diary_list' />
                                        }

                                        <span className="library_list_food_diary_ul_span">{reciepe.Title}</span>
                                        <span className="library_list_food_diary_ul_kcal"><i className="fa fa-fire library_list_food_diary_ul_kcal_i" aria-hidden="true"></i>Kcal:{reciepe.NutritionInfo.TotalCalories}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                    {
                        showFoodJournalTab==true && <div>
                           
                        {FoodJournalList.length > 0 &&
                                <ul className="library_list_food_diary_ul" id="library_list_food_diary_ulID">
                                    {FoodJournalList.length > 0 && FoodJournalList.map((reciepe, index) => (
                                        <li>
                                            <input type="checkbox" value={reciepe.RecipeID} onChange={(e) => { SetReciepeAddData(e) }} />
                                            <img src={reciepe.RecipeImg} alt="userdrptag" className='reciepe_logo_food_diary_list' />
                                            <span className="library_list_food_diary_ul_span">{reciepe.RecipeName}</span>
                                            <span className="library_list_food_diary_ul_kcal"><i className="fa fa-fire library_list_food_diary_ul_kcal_i" aria-hidden="true"></i>Kcal:{reciepe.RecipeCalories}</span>
                                        </li>
                                    ))}

                                </ul>
                            }
                        </div>
                    }


                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className='close_button_commission' onClick={handleClose}>
                        Back
                    </Button>
                    <Button variant="primary" data-session={Session} onClick={(e) => { FinalAddReciepe(e) }} className='save_button_commission float_right' id="final_add_button">
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ClientFoodDiary
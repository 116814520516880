/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';

var QB = require('quickblox/quickblox.min');

// // quickblox admin details
// var APPLICATION_ID = process.env.REACT_APP_QB_APPLICATION_ID;
// var AUTH_KEY = process.env.REACT_APP_QB_AUTH_KEY;
// var AUTH_SECRET = process.env.REACT_APP_QB_AUTH_SECRET;
// var ACCOUNT_KEY = process.env.REACT_APP_QB_ACCOUNT_KEY;
// var CONFIG = { 
//     debug: true,
//     webrtc: {
//         answerTimeInterval: 60,
//         autoReject: true,
//         incomingLimit: 1,
//         dialingTimeInterval: 5,
//         disconnectTimeInterval: 300,
//         statsReportTimeInterval: false
//     }
// };

// QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);


const VideoMessages = () => {

    const { id } = useParams();

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const user_timezone = localStorage.getItem("user_timezone");
    const role = localStorage.getItem("role");
    const loggedin_userid = localStorage.getItem('userId');


    const [clientName, setclientName] = useState('');
    const [nutritionName, setnutritionName] = useState('');
    const [ViewState, setViewState] = useState('');
    const [sessionData, setsessionData] = useState('');
    const [volumemute, setvolumemute] = useState(false);
    const [videomute, setvideomute] = useState(false);
    const [ActionBtn, setActionBtn] = useState(false);
    const [audioButton, setaudioButton] = useState(false);
    const [videoButton, setvideoButton] = useState(false);
    const [callstate, setcallstate] = useState(false);
    const [appointmentEndTime, setappointmentEndTime] = useState('');
    const [chatActive, setchatActive] = useState(false);
    const [RemainAppointmentTime, setRemainAppointmentTime] = useState(0);
    const [authorizeUser, setauthorizeUser] = useState(false);

    const [show, setshow] = useState(false);
    const handleClose = () => { setshow(false) }
    
    const [endshow, setendshow] = useState(false);
    const endClose = () => {setendshow(false)}


    const renderer = ({ hours, minutes, seconds }) => (
        <span>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );

    //firsttime only call
    useEffect(() => {
        appointment_check();
    }, []);

    //call everytime render
    useEffect(() => {
        if (sessionData)
        {
            window.addEventListener('beforeunload', onbeforewindowClose);
            window.addEventListener('unload', onwindowClose);
        }

        if(appointmentEndTime)
        {
          var current_time = moment.utc().format('X');
          var calc_remain_time_ms = ((appointmentEndTime - current_time) * 1000);
    
          if (calc_remain_time_ms > 0 && calc_remain_time_ms <= 3600000)
          setRemainAppointmentTime(calc_remain_time_ms);
        }
    });

    const onbeforewindowClose = async (event) => {
        event.preventDefault();
        event.returnValue = '';
    }  

    const onwindowClose = async (event) => {
        var extension = { sessionId: sessionData.ID };
        await sessionData.stop(extension);
        event.preventDefault();
        event.returnValue = '';
    }  

    const stop_btn = useRef();

    const auto_stop_call = async () => {
        var call_end_time = localStorage.getItem('appointment_call_end');

            setInterval(() => {               

                if (call_end_time !== '') {                    

                    var current_time = moment.utc().format('X');

                    if (call_end_time <= current_time) {
                        stop_btn.current.click();
                    }

                    //For appointment going to end before 10 minutes popup alert
                    var chat_end_alert = (call_end_time - current_time);
                    if (chat_end_alert <= 600 && chat_end_alert > 540)
                    setendshow(true);
                }
            }, 60000);
    }    

    const axiosJWT = axios.create();

    const appointment_check = () => {
        if (loggedin_userid) {

            axiosJWT.get('/appointment/booked/' + id,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {

                    //console.log(response.data);

                    var appointment_data = response.data;

                    if (appointment_data) {
                        setclientName(appointment_data.client_info.first_name + " " + appointment_data.client_info.last_name);
                        setnutritionName(appointment_data.nutrition_info.first_name + " " + appointment_data.nutrition_info.last_name);

                        if (parseInt(appointment_data.GetResponse.client_id) === parseInt(loggedin_userid) || parseInt(appointment_data.GetResponse.nutritionist_id) === parseInt(loggedin_userid)) {

                            setauthorizeUser(true);

                            /*//get opponent quickblox user id
                            if(appointment_data.GetResponse.client_id === loggedin_userid)
                            {                            
                                localStorage.setItem('opponentQBId',appointment_data.nutrition_info.qb_userid);
                            }
                            else
                            {                            
                                localStorage.setItem('opponentQBId',appointment_data.client_info.qb_userid);
                                localStorage.setItem('loggedinQBId',appointment_data.nutrition_info.qb_userid);
                            }*/

                            if (parseInt(appointment_data.GetResponse.nutritionist_id) === parseInt(loggedin_userid)) {
                                localStorage.setItem('opponentQBId', appointment_data.client_info.qb_userid);
                                // console.log('coach login')
                            }


                            //get current unix time
                            var current_time = moment.utc().format('X');


                            //convert appointment booking start time into unix time
                            var appointment_starttime = moment.utc(appointment_data.GetResponse.start_time).format("X");


                            //convert appointment booking end time into unix time
                            var appointment_endtime = moment.utc(appointment_data.GetResponse.end_time).format("X");

                            setappointmentEndTime(appointment_endtime);

                            localStorage.setItem('appointment_call_end', appointment_endtime);

                            //check if current time is within appointment booking time then proceed connect
                            if (appointment_starttime <= current_time && appointment_endtime >= current_time) {
                                // console.log('connect chat');

                                setViewState("Calling.! Please wait..!");

                                setTimeout(() => {
                                    chat_connect();
                                }, 10000);          

                                var calc_remain_time_ms = ((appointment_endtime - current_time) * 1000);

                                if (calc_remain_time_ms > 0 && calc_remain_time_ms <= 3600000)
                                    setRemainAppointmentTime(calc_remain_time_ms);
                            }
                            else {
                                // console.log('not connect');

                                if (appointment_starttime > current_time) {
                                    setViewState("Please wait until your appointment time.. Try again.!");
                                }
                                if (appointment_endtime < current_time) {
                                    if (role === 'Client')
                                        setViewState("Your appointment time was passed.! If you have any queries contact your coach..");
                                    else
                                        setViewState("Your appointment time was passed.! If you have any queries contact support team..");
                                }
                            }
                        }
                        else {
                            // console.log('not authorized error');
                            setViewState("You are not authorized to access this page.!");
                        }
                    }
                    else {
                        setViewState("You don't have any appointment.!");
                    }
                });
        }
    }

    const create_session = (user_credentials) => {

        QB.createSession(user_credentials, function (error, result) {
            // callback function
            if (result) {

                localStorage.setItem('QB_SESSION_RESPONSE', JSON.stringify(result));
                localStorage.setItem('QB_USER_TOKEN', result.token);


                var user_session = QB.service.getSession();
                var userId = user_session.user_id;
                var password = user_session.token;
                var params = { userId, password };


                QB.chat.connect(params, function (c_error, contactList) {
                    var opponentQBId = localStorage.getItem('opponentQBId');
                    if (opponentQBId && role === 'Nutrition')
                        makeCall(opponentQBId);
                    else
                        setViewState('Please wait until your coach connected.!');
                });
            }
        });
    }

    const chat_connect = () => {
        if (QB && localStorage.getItem('QB_USER_TOKEN') !== '') {
            var user_session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
            var userId = user_session.user_id;
            var password = user_session.token;
            var params = { userId, password };

            QB.chat.connect(params, function (error, contactList) {
                if (error) {
                    console.log('mi: chat connect error');
                    console.log(error)
                    //stopCall();
                    QB.chat.disconnect();
                    chat_connect();
                }
                else {
                    var opponentQBId = localStorage.getItem('opponentQBId');

                    if (opponentQBId && role === 'Nutrition')
                        makeCall(opponentQBId);
                    else {
                        qb_func();
                        setViewState('Please wait until your coach connected.!');
                    }
                }
            });
        }
    }

    const makeCall = (opponentQBId) => {

        qb_func();

        // console.log('makecall');

        // console.log(opponentQBId);

        //var opponentQBId = localStorage.getItem('opponentQBId');

        /* var opponentQBId = 135821369;
         console.log(opponentQBId);*/

        QB.webrtc.getMediaDevices("audioinput").then(function (audiodevices) {
            if (audiodevices.length) {
                QB.webrtc.getMediaDevices("videoinput").then(function (videodevices) {
                    if (videodevices.length) {

                        // console.log('video input')
                        var mediaParams = {
                            audio: true,
                            video: true,
                            options: {
                                muted: true,
                                mirror: true
                            },
                            elemId: 'localVideo'
                        };

                        setaudioButton(true);
                        setvideoButton(true);

                        var sessionType = QB.webrtc.CallType.VIDEO; // VIDEO/AUDIO is also possible
                    }
                    else {
                        // console.log('no video input')

                        var mediaParams = {
                            audio: true,
                            video: false,
                            options: {
                                muted: true,
                                mirror: true
                            },
                            elemId: 'localVideo'
                        };

                        setaudioButton(true);
                        var sessionType = QB.webrtc.CallType.VIDEO; // VIDEO/AUDIO is also possible
                    }

                    //var sessionType = QB.webrtc.CallType.AUDIO; // VIDEO/AUDIO is also possible
                    setViewState('Calling... Please wait until your client connected.!');

                    var calleesIds = [opponentQBId];

                    // console.log('calleesIds::', calleesIds);                       

                    try {
                        var session = QB.webrtc.createNewSession(calleesIds, sessionType);
                        setsessionData(session);
                    } catch (error) {
                        setActionBtn(false);
                        setchatActive(false);
                        setViewState('');
                    }


                    // console.log('Session is', session);
                    session.getUserMedia(mediaParams, function (err, stream) {
                        // console.log(stream)

                        if (err) {
                            console.error('Failed to get media tracks', err);
                            session.stop({});
                            setViewState('media device error');
                        } else {

                            // session.attachMediaStream("localVideo", stream);

                            var extension = {};
                            session.call(extension, function (error) {
                                if (error) {
                                    console.warn('session.call.error', error.detail);
                                } else {
                                    console.log('Called successfully.!');

                                    //session.stop({});
                                    //console.log(extension)                                        
                                }
                            });
                        }
                    });
                });
            }
            else {
                setshow(true);
                setViewState('Sorry! Audio device not found. Please attach with any audio devices.!');
                // console.log("no audio devices")
            }
        });
    }

    const stopCall = () => {
        setViewState('Disconnected.!');
        setActionBtn(false);
        setcallstate(false);

        var extension = { sessionId: sessionData.ID };
        sessionData.stop(extension);
        setsessionData('');
        setchatActive(false);
    }

    const qb_func = () => {

        QB.webrtc.onStopCallListener = function (session, userId, extension) {
            setViewState('Disconnected.!');
            setActionBtn(false);
            setcallstate(false);
            session.stop(extension);
            setsessionData('');
            setchatActive(false);
        };

        QB.webrtc.onUserNotAnswerListener = function (session, userId, extension) {
            setViewState('Not Connected.!');
        };

        QB.webrtc.onCallListener = function (session, extension) {
            setsessionData(session);

            QB.webrtc.getMediaDevices("audioinput").then(function (audiodevices) {
                if (audiodevices.length) {
                    QB.webrtc.getMediaDevices("videoinput").then(function (videodevices) {
                        if (videodevices.length) {
                            var mediaParams = {
                                audio: true,
                                video: true,
                                options: {
                                    muted: true,
                                    mirror: true
                                },
                                elemId: 'localVideo'
                            };

                            setaudioButton(true);
                            setvideoButton(true);
                        }
                        else {
                            var mediaParams = {
                                audio: true,
                                video: false,
                                options: {
                                    muted: true,
                                    mirror: true
                                },
                                elemId: 'localVideo'
                            };

                            setaudioButton(true);
                        }


                        // if you are going to take a call  
                        session.getUserMedia(mediaParams, function (error, stream) {
                            if (error) {
                                console.log("mi: on call error")
                            } else {

                                // session.attachMediaStream("localVideo", stream);

                                //run accept function here            
                                session.accept(extension);
                                setActionBtn(true);

                                setcallstate(true);
                                setViewState('');

                                setchatActive(true);
                                auto_stop_call();

                            }
                        });
                    });
                }
                else {
                    setshow(true);
                }
            });

        };


        QB.webrtc.onAcceptCallListener = function (session, userId, extension) {

            //setsessionData(session);
            axiosJWT.put('/appointment/start/' + id,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setActionBtn(true);
                    setchatActive(true);
                    setViewState('');
                    auto_stop_call();
                });
        };

        QB.webrtc.onRemoteStreamListener = function (session, userID, remoteStream) {
            // attach the remote stream to DOM element    
            session.attachMediaStream("remoteVideo", remoteStream);
        };

        QB.webrtc.onRejectCallListener = function (session, userId, extension) {
            setViewState('busy! Try connect again by refresh the page!');
            session.stop(extension);

            setActionBtn(false);
        };

        QB.webrtc.onUserNotAnswerListener = function (session, userId, extension) {
            // console.log("not answer");
            setViewState('Not connect! Try connect again by refresh the page!');
            session.stop(extension);

            setActionBtn(false);
        };

    }

    const muteVideo = () => {
        sessionData.mute('video');
    }

    const unmuteVideo = () => {
        sessionData.unmute('video');
    }

    const muteAudio = () => {
        sessionData.mute('audio');
    }

    const unmuteAudio = () => {
        sessionData.unmute('audio');
    }


    const toggleVideo = () => {
        if (videomute === false)
            muteVideo();
        else
            unmuteVideo();

        setvideomute(!videomute);
    };

    const toggleVolume = () => {

        if (volumemute === false)
            muteAudio();
        else
            unmuteAudio();

        setvolumemute(!volumemute);
    };

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="videomessages container">   

                    <div className="status_msg">{ViewState}</div>

                    <div className={chatActive === true ? "videochat" : "videochat hide"}>

                        <div className='timerinfo'>
                            {RemainAppointmentTime ?
                                <>
                                    <label><i className="fa fa-clock-o" aria-hidden="true"></i>Remaining Time: </label>
                                    <Countdown
                                        date={Date.now() + RemainAppointmentTime}
                                        renderer={renderer}
                                    />
                                </> : null}
                        </div>

                        <div style={{clear:"both"}}></div>

                        {audioButton === true || videoButton === true ?
                            <div className='call_state'>
                                <div className='local'>  
                                    <div className='overlay'>
                                        {videoButton === false ? <div className='opponent_name'>{role === 'Client' ? clientName : nutritionName}</div> : null }  
                                    </div>                                 
                                    <video id="localVideo" className="video_minimize" autoPlay></video>                                
                                </div>
                                <div className='remote'>
                                    <div className='overlay'>
                                        {videoButton === false ? <div className='opponent_name'>{role === 'Client' ? nutritionName : clientName}</div> : null }  
                                    </div>                       
                                    <video id="remoteVideo" autoPlay></video>
                                </div>
                            </div>
                        : null}
                    </div>

                    {chatActive === false && authorizeUser === true ?
                            <div className='call_state'>
                                <div className="local">

                                </div>
                                <div className="remote">

                                </div>                                
                            </div>
                    : null }

                    {ActionBtn === true ?
                        <div className='actionbuttons'>
                            {/*<Button onClick={makeCall}>Call</Button>*/}
                            <Button className='stop_btn' onClick={stopCall} ref={stop_btn}><i className="fa fa-stop" aria-hidden="true" ></i></Button>
                            {videoButton === true ?
                            <Button className='mute_btn' onClick={toggleVideo}><i className={videomute === true ? "fas fa-video-slash" : "fa fa-video-camera"} aria-hidden="true" ></i></Button> : null}
                            {audioButton === true ?
                            <Button className='mute_btn' onClick={toggleVolume}><i className={volumemute === true ? "fas fa-volume-mute" : "fa fa-volume-off"} aria-hidden="true" ></i></Button> : null}
                        </div>
                    : null}
                </div>

                <Modal show={show} onHide={handleClose} className='adminvideomessages_nodevice_modal_popup'>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Sorry! Audio device not found. Please attach with any audio devices.</p>
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={endshow} onHide={endClose} className='adminvideomessages_nodevice_modal_popup'>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Hi! This appointment call will be over in 10 minutes.!</p>
                    </Modal.Body>
                    <Modal.Footer className='display_block'>
                        <Button variant="secondary" className='modal_close_button' onClick={endClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

            </section>
            <style>{'#sidenav .sidenav-inner,.navbar .second_row_notifi span.notif, .navbar .second_row_notifi #open{display: none !important;} '}</style>
        </div>
    )
}

export default VideoMessages

import React, { useEffect, useRef, useState } from "react";
import AddGreenIcon from '../../../../src/images/plus.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns';



const FoodJournalListing = () => {

 
   

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const [show, Setshow] = useState(false)
    const axiosJWT = axios.create();
    const handleClose = () => {
        Setshow(false)
    }
    const [JournalDate, setJournalDate] = useState(new Date());
    const [ShowAddedImage, SetShowAddedImage] = useState(false)
    const [AddedImageSrc, SetAddedImageSrc] = useState('')
    const [AddedImageName, SetAddedImageName] = useState('')
    const HiddenImageInput = useRef(null)
    const [BlogImgErr, SetBlogImgErr] = useState(false)

    const [BlogImgErrMsg, SetBlogImgErrMsg] = useState('')
    const [BlogImgfile, setBlogImgfile] = useState(null);
    const [BlogType, SetBlogType] = useState('')
    const [BlogDesc, SetBlogDesc] = useState('')


    const [BlogDateErr, SetBlogDateErr] = useState(false)
    const [BlogDateErrMsg, SetBlogDateErrMsg] = useState('')

    const [BlogTypeErr, SetBlogTypeErr] = useState(false)
    const [BlogTypeErrMsg, SetBlogTypeErrMsg] = useState('')

    const [BlogDescErr, SetBlogDescErr] = useState(false)
    const [BlogDescErrMsg, SetBlogDescErrMsg] = useState('')
    const [RecipeTypeErr,SetRecipeTypeErr]=useState(false)

    const [PopupJournalTitle, SetPopupJournalTitle] = useState('')
    const [PopupJournalButton, SetPopupJournalButton] = useState('')
    const [PopupJournalAction, SetPopupJournalAction] = useState('')
    const [JournalEditId, SetJournalEditId] = useState('')

    



    

 
    

    const [DishMainIngredient,SetDishMainIngredient]=useState([])
    const [AllDishArray,SetAllDishArray]=useState([])
    const [JournalImgResult,SetJournalImgResult]=useState('')
    const CalorieMamaRecognizeImage=async(fileIn)=>{
         var formData = new FormData();
        formData.append('file', fileIn);
        SetShowServingsCalories(false)
        SetSelectedNutrition('')
        SetJournalImgResult('')
        Setcaloriemamasuccessmsg('')
        Setcaloriemamaerrmsg('')
        SetFetchErrMsg('')
        SetJournalImgResult([])
        SetAllDishArray([])


        try{
            fetch(""+process.env.REACT_APP_CALORIE_MAMA_URL+"/pg?user_key="+process.env.REACT_APP_CALORIE_MAMA_USER_KEY+"",
            {
                method: 'POST',
                body: formData            
            }).then(function(response) {
                return response.json();
            }).then(function(data) {

            
                if(data.results)
                {

                  if(data.is_food===true)
                  {
                  
                    SetJournalImgResult(data.results)
                    SetAllDishArray(data.results)
                    var MainDishIngredient=data.results
                    var Groups = MainDishIngredient.map(value => value.group);
                    SetDishMainIngredient(Groups)
                  }
                  if(data.is_food===false)
                  {
                    SetFetchErrMsg('The given image is not an food')
                  }
               }
               if(data.error)
               {

               console.log('innnnn',data.error)

             

               
               }
         
            });
        }catch(err)
        {
          console.log('innnnn',err)
        }

       
    }

    const [ServingsType,SetServingsType]=useState([])
    const [ShowServingsCalories,SetShowServingsCalories]=useState(false)
    const [AddedIngredients,SetAddedIngredients]=useState([])
    const [Servings,SetServings]=useState('')
    const [FoodCalories,SetFoodCalories]=useState('')
    const [FoodProteins,SetFoodProteins]=useState('')
    const [FoodCarbs,SetFoodCarbs]=useState('')
    const [FoodFats,SetFoodFats]=useState('')
    
    const GetServingtype=(e)=>{
        SetServingsType([])
        SetShowServingsCalories(false)
        SetSelectedNutrition('')
 
        const selectedvalue=e.target.value;
        if(selectedvalue!='')
        {

            var newArray = AllDishArray.filter(function (el) {
           
                return el.group === selectedvalue 
              });
              console.log(newArray)

              SetServingsType(newArray[0].items)
          }
    }
    
    const [SelectedNutrition,SetSelectedNutrition]=useState('')
    const CheckNutrition=(e)=>{
        var SelectedValue=e.target.value;
        SetShowServingsCalories(false)
        SetSelectedNutrition('')

        if(SelectedValue!=='')
        {

            var newArray = ServingsType.filter(function (el) {
           
                return el.name === SelectedValue 
              });
              SetSelectedNutrition(newArray[0])
              SetShowServingsCalories(true)
              console.log(newArray)

        }
      
    

    }

 

    //caloriemamarecognition end

     

    const JournalImageChange = async (event) => {

     
        SetBlogImgErr(false)
        SetShowAddedImage(false)
        setBlogImgfile(null)
        const file = event.target.files[0];
        setBlogImgfile(file)

        ShowLoader()
        SetFetchErrMsg('')

        // If no image selected, return
        if (!/^image\//.test(file.type)) {
            SetBlogImgErr(true)
            SetBlogImgErrMsg(`File ${file.name} is not an image.`)
            setBlogImgfile(null)

            document.getElementById('img_upload_input_id').value = null;
            HideLoader()
            return false;
        }
        else {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            SetAddedImageName(file.name)
            var FileExtension=file.name.split('.').pop()
            FileExtension = FileExtension.toLowerCase();
            reader.onload = function () {

                SetAddedImageSrc(reader.result)

                var image = new Image();
                image.src =reader.result;
                image.onload = async function () {
                    var height = this.height;
                    var width = this.width;
                    if(height<=544 && width<=544 && (FileExtension==='jpg' || FileExtension==='jpeg'))
                    {
                        await CalorieMamaRecognizeImage(event.target.files[0])
                    }
                    else{
                        SetDishMainIngredient([])
                        SetServingsType([])
                        

                       ResizeImageFile(event.target.files[0])
                   
                 
                    }
                }
            };
          
       

  

            SetShowAddedImage(true)
            HideLoader()

        }

    }

    const ResizeImageFile=(file)=>{
      let imageFile =file;
      var FinalSrc=''
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function(event) {
           
                 var canvas = document.createElement("canvas");

              
                 var ctx = canvas.getContext("2d");
                 canvas.width=544;
                 canvas.height=544;
                 ctx.drawImage(img,0,0,img.width,img.height,0,0,canvas.width,canvas.height);

                 var dataurl = canvas.toDataURL(imageFile.type);
                 FinalSrc=canvas.toDataURL(imageFile.type);
                 CalorieMamaRecognizeImageWithBase64(FinalSrc)
              
              
        }
        img.src = e.target.result;
    }
    reader.readAsDataURL(imageFile);



    return FinalSrc

    }

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }

    const CalorieMamaRecognizeImageWithBase64=async(base64='')=>{


     ShowLoader()
      SetDishMainIngredient([])
      SetServingsType([])
      
      Setcaloriemamaerrmsg('')
      Setcaloriemamasuccessmsg('')
      SetFetchErrMsg('')



      var canvas = document.getElementById("canvashiddenn");
      console.log('canvas',canvas)
var ctx = canvas.getContext("2d");

var image = new Image();
image.onload = function() {
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  ctx.drawImage(image, 0, 0);
  const b64jpg = canvas.toDataURL('image/jpeg', 1);
  const file=dataURLtoFile(b64jpg,'checkkkk.jpg')
  var formData = new FormData();
  formData.append('file', file);
  try{

    fetch(""+process.env.REACT_APP_CALORIE_MAMA_URL+"/pg?user_key="+process.env.REACT_APP_CALORIE_MAMA_USER_KEY+"",
    {
      
        method: 'POST',
        body: formData,
            
    }).then(function(response) {
       
    
        return response.json();
    }).then(function(data) {
        HideLoader()
      if(data.is_food===true)
      {
        if(data.results)
        {
          SetJournalImgResult(data.results)
          Setcaloriemamasuccessmsg('Image Recognized successfully.Please check the recipe dropdown')
            SetAllDishArray(data.results)
            var MainDishIngredient=data.results
            var Groups = MainDishIngredient.map(value => value.group);
            SetDishMainIngredient(Groups)
         }
      }

      if(data.is_food===false)
      {
        Setcaloriemamaerrmsg('The given image is not a food')
      }
  
       

        if(data.error)
        {
          Setcaloriemamaerrmsg(data.error.errorDetail)
          
        }
  
       
    });
}catch(err)
{
  HideLoader()
    console.log(err)
}

};
image.src = base64
 return false
     
    }
   
    const [caloriemamaerrmsg,Setcaloriemamaerrmsg]=useState('')
    const [caloriemamasuccessmsg,Setcaloriemamasuccessmsg]=useState('')
    const [FetchErrMsg,SetFetchErrMsg]=useState('')

     
    const clearJournalImg = () => {
        SetBlogImgErr(false)
        SetShowAddedImage(false)
        document.getElementById('img_upload_input_id').value = null
        setBlogImgfile(null)
      
        SetDishMainIngredient([])
        SetServingsType([]);
        Setcaloriemamasuccessmsg('')
        Setcaloriemamaerrmsg('')
        SetFetchErrMsg('')

    }
    const triggerAddpopup = () => {

        SetBlogType('')
        SetBlogDesc('')
        SetShowAddedImage(false)
        setBlogImgfile(null)

        Setshow(true)
        SetBlogImgErr(false)
        SetBlogTypeErr(false)
        SetBlogDateErr(false)
        SetBlogDescErr(false)
        setJournalDate(new Date())
        SetPopupJournalTitle('Add Food Journal')
        SetPopupJournalButton('Add Entry')
        SetPopupJournalAction('add')
        SetDishMainIngredient([])
        SetServingsType([])
        SetAddedIngredients([])

        Setcaloriemamaerrmsg('')
        Setcaloriemamasuccessmsg('')
        SetFetchErrMsg('')
        SetSelectedNutrition('')
        SetShowServingsCalories(false)
        SetRecipeTypeErr(false)
        
    }

    const AddFoodJournal = async (e) => {

        var Action = e.target.getAttribute('data-action')
        if (Action === 'add') {
            var err = false;
            var passDate = ''
            SetBlogImgErr(false)
            SetBlogTypeErr(false)
            SetBlogDateErr(false)
            SetBlogDescErr(false)
            SetRecipeTypeErr(false)
           

            if(JournalDate===null)
            {
                err = true
                SetBlogDateErr(true) 
                SetBlogDateErrMsg('Please select date')
            }
            if(AddedIngredients.length===0)
            {
              err=true;
              SetRecipeTypeErr(true)
            }

            if (JournalDate != null) {
                var yyyy = JournalDate.getFullYear().toString();
                var mm = (JournalDate.getMonth() + 1).toString();
                var dd = JournalDate.getDate().toString();
                passDate = yyyy + '-' + mm + '-' + dd

            }


            if (BlogImgfile === '' || BlogImgfile === null) {
                err = true
                SetBlogImgErr(true)
                SetBlogImgErrMsg('Please upload an image')
            }
            if (BlogType === '' || BlogType === null) {
                err = true
                SetBlogTypeErr(true)
                SetBlogTypeErrMsg('Please select a type')
            }
            if (BlogDesc === '' || BlogDesc === null) {
                err = true
                SetBlogDescErr(true)
                SetBlogDescErrMsg('Please enter a description')
            }
            else if (BlogDesc !== null && BlogDesc !== '') {
                if (BlogDesc.length > 300) {
                    err = true
                    SetBlogDescErr(true)
                    SetBlogDescErrMsg('Please enter a description max of 300 letters')

                }
            }
            if (err === false) {
                const formData = new FormData();
                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('journal_img', BlogImgfile);
                formData.append('ingredients',JSON.stringify(AddedIngredients));
                formData.append('image_result',JSON.stringify(JournalImgResult))
                ShowLoader()
                const response = await axiosJWT.post("/food-journal/add-new",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                    HideLoader()
                }


            }

        }
        else if (Action === 'edit') {


            var err = false;
            var passDate = ''
            SetBlogImgErr(false)
            SetBlogTypeErr(false)
            SetBlogDateErr(false)
            SetBlogDescErr(false)
            SetRecipeTypeErr(false)
            if(JournalDate===null)
            {
                err = true
                SetBlogDateErr(true) 
                SetBlogDateErrMsg('Please select date')
            }
            if(AddedIngredients.length===0)
            {
              err=true;
              SetRecipeTypeErr(true)
            }
            if (JournalDate != null) {
                var yyyy = JournalDate.getFullYear().toString();
                var mm = (JournalDate.getMonth() + 1).toString();
                var dd = JournalDate.getDate().toString();
                passDate = yyyy + '-' + mm + '-' + dd

            }

            if (BlogType === '' || BlogType === null) {
                err = true
                SetBlogTypeErr(true)
                SetBlogTypeErrMsg('Please select a type')
            }
            if (BlogDesc === '' || BlogDesc === null) {
                err = true
                SetBlogDescErr(true)
                SetBlogDescErrMsg('Please enter a description')
            }
            else if (BlogDesc !== null && BlogDesc !== '') {
                if (BlogDesc.length > 300) {
                    err = true
                    SetBlogDescErr(true)
                    SetBlogDescErrMsg('Please enter a description max of 300 letters')

                }
            }
            if (ShowAddedImage === false) {
                err = true
                SetBlogImgErr(true)
                SetBlogImgErrMsg('Please upload an image')
            }

            if (err === false && BlogImgfile === null) {
                const formData = new FormData();
                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('id', JournalEditId);
                formData.append('ingredients', JSON.stringify(AddedIngredients));
                ShowLoader()

                const response = await axiosJWT.post("/food-journal/edit",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                    HideLoader()
                }


            }
            else if (err === false && BlogImgfile !== null) {

                const formData = new FormData();

                formData.append('date', passDate);
                formData.append('desc', BlogDesc);
                formData.append('type', BlogType);
                formData.append('id', JournalEditId);
                formData.append('journal_img', BlogImgfile);
                formData.append('ingredients', JSON.stringify(AddedIngredients));
                formData.append('image_result',JSON.stringify(JournalImgResult))
                


                ShowLoader()
                const response = await axiosJWT.post("/food-journal/edit",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.status === 200) {
                    Setshow(false)
                    GetFoodJournal(PageNumberList, SortValue)
                    HideLoader()
                }

            }


        }


    }

    const [GetJournalList, SetGetJournalList] = useState([])
    const [JournalTotal, SetJournalTotal] = useState(0)
    const [PerPage, SetPerPage] = useState(10)
    const [PageNumberList, SetPageNumberList] = useState(1)
    const [SortValue, SetSortValue] = useState('DESC')
    const [DeleteJournalId, SetDeleteJournalId] = useState('')

    const GetFoodJournal = async (pagenumber, sort) => {
        SetPageNumberList(pagenumber)
        SetSortValue(sort)
        const response = await axiosJWT.get('/food-journal?offset=' + pagenumber + '&limit=' + PerPage + '&sort=' + sort + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        SetGetJournalList(response.data.list)
        SetJournalTotal(response.data.count)


    }

    const changePageNumberS = (pagenumber) => {
        GetFoodJournal(pagenumber, SortValue)


    }
    const SortList = (e) => {

        GetFoodJournal(1, e.target.value)
    }
    const Renderhtmltostring = (content,limit) => {

        var Convertedstring = content.replace(/<[^>]*>?/gm, '');

        if (Convertedstring.length < limit) {
            return Convertedstring
        }
        else {
            return Convertedstring.substring(0, limit-3) + '...'
        }


    }

    const [LoggedUserId, SetLoggedUserId] = useState('')

    const GetProfileDetails = async () => {
        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetLoggedUserId(response.data.profile_details[0].id);
        //console.log(response)
    }

    const DeleteJournal = async (e) => {




        const response = await axiosJWT.post('food-journal/delete',
            {

                'id': DeleteJournalId


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            GetFoodJournal(PageNumberList, SortValue)
            Setshowd(false)
        }


    }


    const [showd, Setshowd] = useState(false)
    const handleClosed = () => {
        Setshowd(false)
    }
    const TriggerDeleteJournal = (e) => {
        var DeleteId = e.target.getAttribute('data-id')
        SetDeleteJournalId(DeleteId)
        Setshowd(true)
    }

    const TriggerEditJournal = async (e) => {
        handleCloseDetail()
        SetBlogImgErr(false)
        SetBlogTypeErr(false)
        SetBlogDateErr(false)
        SetBlogDescErr(false)
        var EditID = e.target.getAttribute('data-id')
        SetJournalEditId(EditID)
        setBlogImgfile(null)
       SetDishMainIngredient([])
       SetServingsType([])
       SetAddedIngredients([])
       SetJournalImgResult([])
      
       Setcaloriemamaerrmsg('')
       Setcaloriemamasuccessmsg('')
       SetFetchErrMsg('')
       SetSelectedNutrition('')
       SetShowServingsCalories(false)
       SetRecipeTypeErr(false)
        const response = await axiosJWT.get('/food-journal/detail?id=' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.result !== null) {

        

          if(response.data.result.image_result!==null  && response.data.result.image_result!=='')
          {
              const obj = JSON.parse(response.data.result.image_result)
              SetJournalImgResult(obj);
              SetAllDishArray(obj)
              var MainDishIngredient=obj
              var Groups = MainDishIngredient.map(value => value.group);
              SetDishMainIngredient(Groups)
          }


            if(response.data.result.ingredients!==null  && response.data.result.ingredients!=='')
            {
                const obj = JSON.parse(response.data.result.ingredients)
                SetAddedIngredients(obj)
            }

         
            Setshow(true)
            SetAddedImageName(response.data.result.journal_img)
            SetAddedImageSrc( response.data.result.journal_img)
            SetShowAddedImage(true)
            setJournalDate(new Date(response.data.result.journal_date))
            SetBlogType(response.data.result.type)
            SetBlogDesc(response.data.result.desc)
            SetPopupJournalTitle('Edit Food Journal')
            SetPopupJournalButton('Edit Entry')
            SetPopupJournalAction('edit')

        }



    }


    const [AddComment, SetAddComment] = useState('')

    const TriggerAddComment = async (e) => {
        var JournalId = e.target.getAttribute('data-id')

        const elements = document.querySelectorAll('.add_input_comment_div[data-id="' + JournalId + '"]');
        if (elements[0].value) {
            const response = await axiosJWT.post('food-journal/add-comment',
                {

                    'id': JournalId,
                    'comment': elements[0].value

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.status === 200) {
                elements[0].value = ''
                GetFoodJournal(PageNumberList, SortValue)
            }



        }


    }


    const [showDetail, SetshowDetail] = useState(false)
    const [DetailJournalType, SetDetailJournalType] = useState('')
    const [DetailJournalDate, SetDetailJournalDate] = useState(null)
    const [DetailJournalSrc, SetDetailJournalSrc] = useState('')
    const [DetailJournalDesc, SetDetailJournalDesc] = useState('')
    const [DetailJournalId, SetDetailJournalId] = useState('')
    const [DetailJournalOwner, SetDetailJournalOwner] = useState('')
    const [DetailJournalIngredients,SetDetailJournalIngredients]=useState([])

    const handleCloseDetail = () => {
        SetshowDetail(false)
    }
    const viewDetail = async (e) => {
        var JournalId = e.target.getAttribute('data-id')
        SetDetailJournalId(JournalId)
        GetBlogComments(JournalId)
        const response = await axiosJWT.get('/food-journal/detail?id=' + JournalId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {

            SetshowDetail(true)
            SetDetailJournalType(response.data.result.type)
            SetDetailJournalDate(response.data.result.journal_date)
            SetDetailJournalSrc( response.data.result.journal_img)
            SetDetailJournalDesc(response.data.result.desc)
            SetDetailJournalOwner(response.data.result.user_id)
            SetDetailJournalIngredients(JSON.parse(response.data.result.ingredients))
        }

    }

    const [CommentsList, SetCommentsList] = useState([])

    const GetBlogComments = async (CommentsJournalId) => {
        SetCommentsList([])

        const response = await axiosJWT.get('/food-journal/get-comments?id=' + CommentsJournalId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data.Comments != null) {
            SetCommentsList(response.data.Comments)
        }

    }

    const DeleteComment = async (e) => {
        const CommentId = e.target.getAttribute('data-id')

        const response = await axiosJWT.post('food-journal/delete-comment',
            {

                'id': CommentId

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            GetBlogComments(DetailJournalId)
            GetFoodJournal(PageNumberList, SortValue)
        }


    }

    const TriggerEditComment=async(e)=>{
        const CommentId = e.target.getAttribute('data-id')
        SetEditCommentId(CommentId)
        const response = await axiosJWT.get('/food-journal/comment-detail?id=' + CommentId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data.CommentInfo!==null)
        {
            SetCommentValue(response.data.CommentInfo.comments)
            SetEditCommentJournal(response.data.CommentInfo.journal_id)
            Setshowed(true)
        }


    }

    const EditComment=async()=>{

       if(CommentValue!=='')
       {

        const response = await axiosJWT.post('food-journal/edit-comment',
        {

            'id': EditCommentId,
            'comment':CommentValue

        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.status===200)
        {
            GetBlogComments(EditCommentJournal)
            Setshowed(false)
        }


       }

       
        

    }

    const [showed,Setshowed]=useState(false)
    const [CommentValue,SetCommentValue]=useState('')
    const [EditCommentId,SetEditCommentId]=useState('')
    const [EditCommentJournal,SetEditCommentJournal]=useState('')
    const handleCloseed=()=>{
        Setshowed(false)
    }


    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const AddIngredient=()=>{
      
        var OldArray=AddedIngredients
        var obj={
            ingredient_name:SelectedNutrition.name,
            servings:SelectedNutrition.servingSizes[0].unit,
            calories:GetCalories(SelectedNutrition),
            fat:SelectedNutrition.nutrition.totalFat,
            protein:SelectedNutrition.nutrition.protein,
            carbs:SelectedNutrition.nutrition.totalCarbs,
        }
        OldArray.push(obj)
        console.log(OldArray)
        SetAddedIngredients(OldArray)
        SetShowServingsCalories(false)
        SetSelectedNutrition('')
    }
  
    const RemoveAddedIngredients=(e)=>{
     var OldArray=AddedIngredients
     var RemoveIndex=parseInt(e.target.getAttribute('data-id'))
     OldArray[RemoveIndex]=null
   

     OldArray=OldArray.filter(function(item){
        return item!=null;
      });
     SetAddedIngredients(OldArray)
     SetShowServingsCalories(false)
     SetSelectedNutrition('')

    
    }

    const GetCalories=(NutritionData)=>{

      var Calories=0;

      if(NutritionData.nutrition.calories)
      {
        if(NutritionData.nutrition.calories!==null && NutritionData.nutrition.calories!=='')
        {
          Calories=NutritionData.nutrition.calories
        }
      }

      if(NutritionData.servingSizes[0])
      {
        if(NutritionData.servingSizes[0].servingWeight)
        {
          Calories=Calories*NutritionData.servingSizes[0].servingWeight
        }
      }
      Calories=Math.round(Calories)
       return Calories

    }

    useEffect(() => {
        document.title = 'Client-Food journal';
        GetFoodJournal(1, 'DESC')
        GetProfileDetails()
    }, [])



    return (
      <div
        id="container-adjusts"
        className={
          side_nav === "true" ? "Container-enlarge" : "Container-shrink"
        }
      >
        {loader === true && <div id="semiTransparenDivLoader"></div>}
        <div className="container">
          <section className="dashboard add_food_journal">
            <div className="client_food_journal food_journal_outerwrap">
              <div className="food_journal_leftwrap">
                <div className="add_food_journal_top_head">
                  <div className="food_journal_top_head_inner">
                    <span>Food Journal</span>
                    <span
                      className="create_new_food_journal"
                      onClick={() => {
                        triggerAddpopup();
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      create new food Journal
                    </span>
                    <select
                      onChange={(e) => {
                        SortList(e);
                      }}
                    >
                      <option value="DESC">Sort By (DESC)</option>
                      <option value="ASC">Sort By (ASC)</option>
                    </select>
                  </div>
                </div>
                <div className="food_journal_list_div">
                  {GetJournalList.length > 0 &&
                    GetJournalList.map((Journals, index) => (
                      <div className="list_col">
                        <div className="img_food_jour">
                          <img src={Journals.journal_img}></img>
                        </div>
                        <div className="food_journal_details">
                          <div className="food_journal_edit">
                            {LoggedUserId === Journals.user_id && (
                              <i
                              className="fa fa-edit pointer_eff"
                                data-id={Journals.id}
                                onClick={(e) => {
                                  TriggerEditJournal(e);
                                }}
                              ></i>
                            )}
                            {LoggedUserId === Journals.user_id && (
                              <i
                              className="fa fa-trash pointer_eff"
                                data-id={Journals.id}
                                onClick={(e) => {
                                  TriggerDeleteJournal(e);
                                }}
                              ></i>
                            )}
                          </div>
                          <p className="type">{Journals.type}</p>
                          <p className="date">
                            @{format(parseISO(Journals.journal_date), `PP`)}
                          </p>
                          <p className="desc">
                            {Renderhtmltostring(Journals.desc, 300)}
                          </p>

                          <p className="comments">
                            Comments({Journals.comment_count})
                            <span
                              data-id={Journals.id}
                              onClick={(e) => {
                                viewDetail(e);
                              }}
                            >
                              Click here to view details
                            </span>
                          </p>
                        </div>
                        <div className="add_input_div">
                          <input
                            type={"text"}
                            placeholder={"Add comment"}
                            className="add_input_comment_div"
                            data-id={Journals.id}
                          />
                          <i
                            className="fa fa-paper-plane pointer_eff"
                            data-id={Journals.id}
                            onClick={(e) => {
                              TriggerAddComment(e);
                            }}
                          ></i>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="food_journal_rightwrap">
                <div className="food_journal_right_outerwrap">
                  {GetJournalList.length > 0 && (
                    <div className="food_journal_right_innerwrap">
                      <h6 className="food_journal_title">Recent food journal</h6>
                      <div className="food_journal_grid">
                        {GetJournalList.sort(
                          (a, b) => new Date(b.date) - new Date(a.date)
                        )
                          .slice(0, 8)
                          .map((Journals, index) => (
                            <img
                              src={Journals.journal_img}
                              alt="recent_uploads"
                            />
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Modal show={show} className="coach_blog_modal_popup">
                <Modal.Header>
                  <Modal.Title>{PopupJournalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="add_food_journal_popup">
                  <div className="add_image_plus">
                    <img
                      src={AddGreenIcon}
                      onClick={() => {
                        HiddenImageInput.current.click();
                      }}
                    />
                    <input
                      type={"file"}
                      style={{ display: "none" }}
                      className=""
                      id="img_upload_input_id"
                      ref={HiddenImageInput}
                      onChange={(e) => {
                        JournalImageChange(e);
                      }}
                    />
                    <input
                      type={"file"}
                      style={{ display: "none" }}
                      id="file_input"
                    ></input>
                    <p>Click Here to Add Image</p>
                    {BlogImgErr === true && (
                      <span className="err">{BlogImgErrMsg}</span>
                    )}
                  </div>
                  {ShowAddedImage === true && (
                    <div className="image_list">
                      <img src={AddedImageSrc} />
                      <span>{Renderhtmltostring(AddedImageName, 30)}</span>
                      <i
                        className="fa fa-trash pointer_eff"
                        onClick={clearJournalImg}
                      ></i>
                    </div>
                  )}

                  <div>
                    <span className="err">{FetchErrMsg}</span>
                  </div>

               

<canvas id="canvashiddenn" style={{display:'none'}}></canvas>

                  <div className="date_meal_row row">
                    <div className="date">
                      <label>Date</label>
                      <Datepicker
                        value={JournalDate}
                        onChange={setJournalDate}
                        format="MM-dd-yyyy"
                      />
                      {BlogDateErr === true && (
                        <span className="err">{BlogDateErrMsg}</span>
                      )}
                    </div>
                    <div className="meal_type">
                      <label>Meal Type</label>
                      <select
                        onChange={(e) => {
                          SetBlogType(e.target.value);
                        }}
                        defaultValue={BlogType}
                        {...(BlogTypeErr === true ? { id: "err_border" } : {})}
                        className="meal_type_drop"
                      >
                        <option value="">Select type</option>
                        <option value="Breakfast">Breakfast</option>
                        <option value="Lunch">Lunch</option>
                        <option value="Dinner">Dinner</option>
                        <option value="Snacks">Snacks</option>
                      </select>
                      {BlogTypeErr === true && (
                        <span className="err">{BlogTypeErrMsg}</span>
                      )}
                    </div>
                  </div>
                  <div className="date_meal_row row">
                    <div className="meal_type">
                      <label>Pick recipe</label>
                      <select
                        className="meal_type_drop"
                        onChange={(e) => {
                          GetServingtype(e);
                        }}
                      >
                        <option value="">Select type</option>

                        {DishMainIngredient.length > 0 &&
                          DishMainIngredient.map((MainIngredients, index) => (
                            <option value={MainIngredients}>
                              {MainIngredients}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="meal_type">
                      <label>Pick recipe type</label>
                      <select className="meal_type_drop" onChange={(e)=>{CheckNutrition(e)}}>
                        <option value="">Select type</option>

                        {ServingsType.length > 0 &&
                          ServingsType.map((type, index) => (
                            <option value={type.name}>{type.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {
                    RecipeTypeErr===true &&<div className="err">Please add atleast on recipe type</div>
                  }
          

                  {ShowServingsCalories === true && (

                    
                    <div className="nutrition_info_food_journal">
                      <div className="heading">Recipe Details  <span className="add_button" onClick={AddIngredient}>(Add)</span></div>
                      <div className="row info_row">
                        <div className="title">Serving size :</div>
                        <div className="content"> {SelectedNutrition!=='' && SelectedNutrition.servingSizes!==undefined && SelectedNutrition.servingSizes[0].unit}</div>
                      </div>
                      <div className="row info_row">
                        <div className="title">Calories:</div>
                        <div className="content">{SelectedNutrition!=='' && SelectedNutrition.nutrition.calories!==undefined && GetCalories(SelectedNutrition)+' Kcal'}</div>
                      </div>
                     
                    </div>
                  )}

{
    AddedIngredients.length>0 && 
    <div className="nutrition_info_food_journal added_ingredient">
<div className="heading">Added recipe</div>

{AddedIngredients.length > 0 &&
                          AddedIngredients.map((type, index) => (
                            <li>{type.ingredient_name} ({type.servings}) ({type.calories} Kcal)<span><i className="fa fa-times-circle remove_icon" data-id={index} onClick={(e)=>{RemoveAddedIngredients(e)}} aria-hidden="true"></i></span></li>
                          ))}

</div>
}

                  

                  <div className="desc_row">
                    <label>Description</label>
                    <textarea
                      rows="8"
                      className="food_journal_textarea"
                      placeholder="Write a description"
                      onChange={(e) => {
                        SetBlogDesc(e.target.value);
                      }}
                      defaultValue={BlogDesc}
                      {...(BlogDescErr === true ? { id: "err_border" } : {})}
                    ></textarea>
                    {BlogDescErr === true && (
                      <span className="err">{BlogDescErrMsg}</span>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer className="display_block">
                  <Button
                    variant="secondary"
                    className="modal_close_button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    className="modal_action_button float_right"
                    data-action={PopupJournalAction}
                    onClick={(e) => {
                      AddFoodJournal(e);
                    }}
                  >
                    {PopupJournalButton}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={showd} onHide={handleClosed}>
                <Modal.Header>
                  <Modal.Title>Delete Food Journal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are You sure want to delete this food journal
                </Modal.Body>
                <Modal.Footer className="display_block">
                  <Button variant="secondary" onClick={handleClosed}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className="modal_action_button float_right"
                    onClick={(e) => {
                      DeleteJournal(e);
                    }}
                  >
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showDetail}
                onHide={handleCloseDetail}
                className="coach_blog_modal_popup"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{"Food Journal"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="food_journal_detail">
                  <div className="food_journal_detail_top_row">
                    <span className="type">{DetailJournalType}</span>
                    {DetailJournalDate !== null && (
                      <span className="date">
                        {format(parseISO(DetailJournalDate), `PP`)}
                      </span>
                    )}

                    {DetailJournalOwner === LoggedUserId && (
                      <i
                        className="fa fa-edit pointer_eff edit_icon"
                        data-id={DetailJournalId}
                        onClick={(e) => {
                          TriggerEditJournal(e);
                        }}
                      ></i>
                    )}
                  </div>

                  <div className="journal_detail_comment_row">
                    <div className="detail">
                      <img src={DetailJournalSrc} />

                      <p className="desc">{DetailJournalDesc}</p>
                    </div>
                  </div>
                  <div>
                  {
    DetailJournalIngredients.length>0 && 
    <div className="nutrition_info_food_journal added_ingredient">
<div className="heading">Recipes:</div>

{DetailJournalIngredients.length > 0 &&
                          DetailJournalIngredients.map((type, index) => (
                            <li>{type.ingredient_name} ({type.servings}) ({type.calories} Kcal)</li>
                          ))}

</div>
}
                  </div>
                  <div className="journal_comment_row">
                    {CommentsList.length > 0 && (
                      <div className="comment_label">Comments:</div>
                    )}

                    <div className="comments_list_row">
                      {CommentsList.length > 0 &&
                        CommentsList.map((comments, index) => (
                          <div className="comment_list">
                            <p className="comment_info">
                              {LoggedUserId === comments.user_id && (
                                <span>You</span>
                              )}
                              {LoggedUserId !== comments.user_id && (
                                <span>
                                  {comments.user.first_name +
                                    " " +
                                    comments.user.last_name}
                                </span>
                              )}

                              {LoggedUserId === comments.user_id && (
                                <i
                                  className="fa fa-trash info"
                                  data-id={comments.id}
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    DeleteComment(e);
                                  }}
                                ></i>
                              )}
                              {LoggedUserId === comments.user_id && (
                                <i
                                  className="fa fa-edit info"
                                  data-id={comments.id}
                                  aria-hidden="true"
                                  onClick={(e) => {
                                    TriggerEditComment(e);
                                  }}
                                ></i>
                              )}

                              <span className="date">
                                {format(parseISO(comments.createdAt), `PP`)}
                              </span>
                            </p>
                            <p> {comments.comments}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="display_block">
                  <Button
                    variant="primary"
                    className="modal_action_button float_right"
                    onClick={handleCloseDetail}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={showed} onHide={handleCloseed}>
                <Modal.Header>
                  <Modal.Title>Edit Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="edit_journal_comment">
                  <textarea   type={"text"}
                    defaultValue={CommentValue}
                    onChange={(e) => {
                      SetCommentValue(e.target.value);
                    }}
                    className="add_input_comment_div"></textarea>
                  {/* <input
                    type={"text"}
                    defaultValue={CommentValue}
                    onChange={(e) => {
                      SetCommentValue(e.target.value);
                    }}
                    className="add_input_comment_div"
                  /> */}
                </Modal.Body>
                <Modal.Footer className="display_block">
                  <Button variant="secondary" onClick={handleCloseed}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className="modal_action_button float_right"
                    onClick={(e) => {
                      EditComment(e);
                    }}
                  >
                    Edit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </section>
        </div>
      </div>
    );
}

export default FoodJournalListing
import React, { useEffect, useState } from "react";

const SideEffectCM = (props) => {
  const [SideEffect, SetSideEffect] = useState("");

  const handleOptionChange = (e) => {
    SetSideEffect(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.SideEffectPC) {
        SetSideEffect(props.SideEffectPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetSideEffectPC(SideEffect);

      var Err = 0;
      if (SideEffect === "") {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [SideEffect]);

  useEffect(()=>{
    props.SetPreviousMenu('glpexperiencefree')
   
     
  },[])

  useEffect(()=>{

    if(SideEffect==='Yes')
    {
        props.SetNextMenu('sideeffectdesc')
    }
    else if (SideEffect==='No')
    {
    
        props.SetNextMenu('glpdosepref')
    }

  },[SideEffect])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

      <div>
          <label>
         <h6>{props.SideEffectPCLabel}</h6> 
          </label>
          <span style={{color:'red'}}>*</span>
        </div>

      <div className="input_row">
      
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={SideEffect === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={SideEffect === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

    
    </div>
  );
};
export default SideEffectCM;

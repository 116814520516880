import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const SuccessRedirect = () => {

    const [loader, Setloader] = useState(true);
    const [PaymentId, setPaymentId] = useState('');
    const [PayerId, SetPayerId] = useState('');
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const navigate = useNavigate();

    useLayoutEffect(async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let Gateway = params.get('gateway');
        let Type = params.get('type');

        if (Gateway === 'paypal' && Type === 'client_subscription') {

            let paymentId = params.get('paymentId');
            let PayerId = params.get('PayerID');

            const response = await axiosJWT.get('payments/success?PayerID=' + PayerId + '&paymentId=' + paymentId + '&gateway=paypal', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                localStorage.setItem("subscriptionID", response.data.ClientData.subscription_plan);
                localStorage.setItem("subscriptionEdate", response.data.ClientData.subscription_e_date);

                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/client/subscriptions?payment=success&type=subscription';
            }
        }
        // else if (Gateway === 'affirm' && Type === 'client_subscription') {

        //     let checkout_id = params.get('checkout_token');

        //     const authorize_data = await axiosJWT.get('payments/affirm-authorize?checkoutid='+checkout_id+'&gateway=affirm', {
        //         headers: {
        //             Authorization: `Bearer ${token}`
        //         }
        //     });

        //     if(authorize_data)
        //     {
        //             const response = await axiosJWT.get('payments/success?checkoutid='+checkout_id+'&transactionid='+authorize_data.id, {
        //                 headers: {
        //                     Authorization: `Bearer ${token}`
        //                 }
        //             });

        //             if (response.status === 200) {
        //                 localStorage.setItem("subscriptionID", response.data.ClientData.subscription_plan);
        //                 localStorage.setItem("subscriptionEdate", response.data.ClientData.subscription_e_date);

        //                 window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/client/subscriptions?payment=success&type=subscription';
        //             }
        //     }
        // }
        if (Gateway === 'splitit' && Type === 'client_subscription') {

            let PaymentId = params.get('InstallmentPlanNumber');
            let ReforderId = params.get('RefOrderNumber');

            const response = await axiosJWT.get('payments/success?payment_id=' + PaymentId + '&gateway=splitit&order_id='+ReforderId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                localStorage.setItem("subscriptionID", response.data.ClientData.subscription_plan);
                localStorage.setItem("subscriptionEdate", response.data.ClientData.subscription_e_date);

                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/client/subscriptions?payment=success&type=subscription';
            }
        }
        if (Gateway === 'stripe' && Type === 'client_subscription') {

            let SessionId = params.get('session_id');
            const response = await axiosJWT.get('payments/success?session_id=' + SessionId + '&gateway=stripe', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                localStorage.setItem("subscriptionID", response.data.ClientData.subscription_plan);
                localStorage.setItem("subscriptionEdate", response.data.ClientData.subscription_e_date);

                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/client/subscriptions?payment=success&type=subscription';
            }
        }
        if (Gateway === 'splitit' && Type === 'client_registration') {
            let PaymentId = params.get('InstallmentPlanNumber');
            let ReforderId = params.get('RefOrderNumber');
            let Email = params.get('email');

            let UserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            
            const response = await axiosJWT.get('payments/success/client-registration?payment_id='+PaymentId+'&gateway=splitit&timezone='+UserTimeZone+'&ReforderId='+ReforderId+'&email='+Email, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/registration/success?type=client';
            }
        }
        if (Gateway === 'stripe' && Type === 'client_registration') {
            let SessionId = params.get('session_id');
            let UserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const response = await axiosJWT.get('payments/success/client-registration?session_id=' + SessionId + '&gateway=stripe&timezone=' + UserTimeZone + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/registration/success?type=client';
            }
        }
        if (Gateway === 'paypal' && Type === 'coach_subscription') {

            let paymentId = params.get('paymentId');
            let PayerId = params.get('PayerID');

            const response = await axiosJWT.get('payments/success/coach?PayerID=' + PayerId + '&paymentId=' + paymentId + '&gateway=paypal', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=success&type=coach_subscription';
            }
        }
        if (Gateway === 'stripe' && Type === 'coach_subscription') {
            let SessionId = params.get('session_id');
            
            const response = await axiosJWT.get('payments/success/coach?session_id=' + SessionId + '&gateway=stripe', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=success&type=coach_subscription';
            }
        }
        if (Gateway === 'splitit' && Type === 'coach_subscription') {
            let PaymentId = params.get('InstallmentPlanNumber');
            let ReforderId = params.get('RefOrderNumber');
            
            const response = await axiosJWT.get('payments/success/coach?payment_id=' + PaymentId + '&gateway=splitit&order_id='+ReforderId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=success&type=coach_subscription';
            }
        }

        if (Type === 'stripe_account_creation') {
            let Straccount_id = params.get('account_id');

            const response = await axiosJWT.post('/payments/update-stripe-account',
                {
                    account_id: Straccount_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                window.location.href = response.data.link;
            }
        }
        if (Type === 'service_plan_purchase') {

            let SessionId = params.get('session_id');
            const response = await axiosJWT.get('payments/service-plan-payment-success?session_id=' + SessionId + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/client/appointment?payment=success&type=service_plan';
            }
        }
        if (Type === 'payment_setup') {
            let SessionId = params.get('session_id');
            let tried_plan_id = params.get('tried_plan');

            try {
                const response = await axiosJWT.get('payments/save-card-session?session_id=' + SessionId + '', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (tried_plan_id !== null && tried_plan_id !== '') {
                    try {
                        const response = await axiosJWT.post('/payments/coach-plan-checkout',
                            {
                                payment_gateway: 'stripe',
                                product_category: 'coach_subscription',
                                product_sku: tried_plan_id
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });

                        if (response.data !== null) {
                            setTimeout(() => {
                                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=success';
                            }, 1000);
                        }
                    } catch (err) {
                        window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=failed';
                    }
                }
                else {
                    if (response.status === 200) {
                        window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?configured=success&type=payment_method';
                    }
                }
            } catch (err) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=failed';
            }
        }

        if (Type === 'payment_add') {
            let SessionId = params.get('session_id');

            const response = await axiosJWT.get('payments/save-card-session?session_id=' + SessionId + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/profile?configured=success&type=payment_method';
            }
        }
    }, []);

    return (
        <div>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
        </div>
    )
}

export default SuccessRedirect
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


const Emailuser = () => {

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    const axiosJWT = axios.create();

    //var [usersList, setUsers] = useState();

    const usersList = [{ label: 'All Users', value: 'allusers' }, { label: 'All Managers', value: 'allmanagers' }, { label: 'All Experts', value: 'allnutrition' }, { label: 'All Customers', value: 'allclient' }];

    const [toMail, settoMail] = useState('');
    const [mailSubject, setmailSubject] = useState('');
    const [mailContent, setmailContent] = useState('');
    var [updateShow, setUpdateShow] = useState({ display: "none" });
    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const editorChange = (data) => {

        seteditorData(data.blocks);
    }
    const [editorData, seteditorData] = useState('');

    const [WhoDoYouErr, SetWhoDoYouErr] = useState(false)
    const [WhoDoYouErrMsg, SetWhoDoYouErrMsg] = useState('')

    const [EmailSubjErr, SetEmailSubjErr] = useState(false)
    const [EmailSubjErrMsg, SetEmailSubjErrMsg] = useState('')

    const [EmailContentErr, SetEmailContentErr] = useState(false)
    const [EmailContentErrMsg, SetEmailContentErrMsg] = useState('')

    /* useEffect(()=>{
        getUsers()
    },[])

    const getUsers = async () => {
        const response_mail = await axiosJWT.get('/allusers',        
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

   

        var users_mails_list = response_mail.data.Users;

        var users_mails = [];

        users_mails_list.forEach(element => {
            users_mails.push({value:element.email,label:element.first_name+" "+element.last_name});
        });

        setUsers(users_mails); 
        setUpdateShow({display:"none"});
    } */

    const selfMail = async () => {



        var err = false;
        SetWhoDoYouErr(false)
        SetEmailSubjErr(false)
        SetEmailContentErr(false)






        if (toMail.value === undefined || toMail.value === '') {


            SetWhoDoYouErrMsg('Please select this field')
            SetWhoDoYouErr(true)
            err = true
        }

        if (mailSubject === undefined || mailSubject === '') {
            SetEmailSubjErrMsg('Please enter mail subject')
            SetEmailSubjErr(true)
            err = true
        }



        if (editorData === '' || editorData[0].text === '') {
            SetEmailContentErrMsg('Please enter email content')
            SetEmailContentErr(true)
            err = true
        }

        if (err === true) {

            return false;
        }



        const from_mail = await axiosJWT.get('/admin/config/site_wide_sender_email_address',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });



        if (from_mail.data.value !== '' && mailSubject !== '' && editorData[0].text !== '') {

            var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));
            const response_mail = await axiosJWT.post('/mail',
                {
                    type: "admin_marketing_mail_user",
                    sentby_userId: userId,
                    to_mail_address: from_mail.data.value,
                    subject: mailSubject,
                    content: editor_content
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response_mail) {
                setUpdateShow({ display: "block", color: "green" });
            }
        }
    }

    const sendMail = async () => {

        setUpdateShow({ display: "none" });

        var err = false;
        SetWhoDoYouErr(false)
        SetEmailSubjErr(false)
        SetEmailContentErr(false)




        if (toMail.value === undefined || toMail.value === '') {


            SetWhoDoYouErrMsg('Please select this field')
            SetWhoDoYouErr(true)
            err = true
        }

        if (mailSubject === undefined || mailSubject === '') {
            SetEmailSubjErrMsg('Please enter mail subject')
            SetEmailSubjErr(true)
            err = true
        }

        if (editorData === '' || editorData[0].text === '') {
            SetEmailContentErrMsg('Please enter email content')
            SetEmailContentErr(true)
            err = true
        }

        if (err === true) {

            return false;
        }



        if (toMail.value !== '' && mailSubject !== '' && editorData[0].text !== '') {
            const response = await axiosJWT.get("/" + toMail.value, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


            var emails_list = response.data.Users;

            var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));

            emails_list.forEach(element => {

                const response_mail = axiosJWT.post('/mail',
                    {
                        type: "admin_marketing_mail_user",
                        sentby_userId: userId,
                        to_mail_address: element.email,
                        subject: mailSubject,
                        content: editor_content
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

            }).then(() => {

                setmailSubject('');
                setmailContent('');

                setUpdateShow({ display: "block", color: "green" });
            });
        }
    }

    const disableStatus = () => {
        setUpdateShow({ display: "none" });
    }

    return (
        <div>
            <h5>Send Email To User</h5><br></br>

            <div style={updateShow}>Mail sent successfully!</div>

            <div id="email_user" className='admin_marketing_email_user modal-body'>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label required'>Who do you want to email?</label><br />
                        <Select
                            options={usersList}
                            onChange={settoMail}
                            onClick={disableStatus}
                            {...(WhoDoYouErr===true ? {id: 'err_border'} : {})}

                        />
                    </div>
                </div>

                {WhoDoYouErr === true &&
                    <div className='row err promotion_err_name'>
                        {WhoDoYouErrMsg}
                    </div>
                }


                <br></br>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label required'>Email subject</label><br />
                        <input type='text' className='inner_popup_input' value={mailSubject} onChange={(e) => { setmailSubject(e.target.value) }}   {...(EmailSubjErr===true ? {id: 'err_border'} : {})}></input>
                    </div>
                </div>

                {EmailSubjErr === true &&
                    <div className='row err promotion_err_name'>
                        {EmailSubjErrMsg}
                    </div>
                }

                <br></br>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label required'>Content:</label><br />

                        <Editor
                            wrapperClassName="texteditor"
                            editorState={editorvalue}
                            onEditorStateChange={seteditorvalue}
                            onContentStateChange={editorChange}
                            handlePastedText={() => false}
                        />
                        {/* <textarea className='inner_popup_input' value={mailContent} rows="4" onChange={(e)=>{setmailContent(e.target.value)}}></textarea> */}
                    </div>
                </div>
                {EmailContentErr === true &&
                    <div className='row err promotion_err_name'>
                        {EmailContentErrMsg}
                    </div>
                }


                <div className='action_buttons'>
                    <Button className='normal_button btn' onClick={selfMail}>Or a test Yourself </Button>       <Button className='green_button btn' onClick={sendMail}>Send Mail </Button>
                </div>

            </div>


        </div>
    )
}

export default Emailuser
import React, { useEffect, useState } from "react";

import moment from 'moment'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const PremiumCheckGLPComponent = (props) => {

  
    const navigate=useNavigate()

    const [Subscribed, SetSubscribed] = useState(false)
    const subscriptionEdate = localStorage.getItem("subscriptionEdate");
    const [SubscribedMsg,SetSubscribedMsg]=useState('')
    var utcMoment = moment.utc();
    utcMoment = moment(utcMoment).format("YYYY-MM-DD HH:mm:ss");

    const [Expirtrigged,SetExpirtrigged]=useState('no')
    const [cantriggerPremium,SetcantriggerPremium]=useState('no')

    const [Href,SetHref]=useState('/client/subscriptions')
    const [HrefText,SetHrefText]=useState('Yes,Upgrade my plan')

    useEffect(()=>{
        SetcantriggerPremium('yes')
    },[])

    useEffect(()=>{
     
if(cantriggerPremium==='yes' && props.GLpUser!==true && Expirtrigged==='no')
{
    SetSubscribedMsg('You are not eligible for GLP-1 medication.')
    SetSubscribed(true)
    SetHref('/')
    SetHrefText('Home')
}

    },[props.GLpUser])

    useEffect(() => {



        if (subscriptionEdate !== 'null') {

            if (Date.parse(subscriptionEdate) < Date.parse(utcMoment)) {
                SetSubscribed(true)
                SetSubscribedMsg('You can access this features by upgrade your account to premium plan.(Only for GLP-1 Users)')
                SetExpirtrigged('yes')
                SetHref('/client/subscriptions')
                SetHrefText('Yes,Upgrade my plan')
            }
            
           
        }
        else {
            SetSubscribed(true)
        }

    })


    const NavigateHome=(e)=>{
        navigate(e.target.getAttribute('href'))
    }

    useEffect(()=>{
      
    },[])



    return (

        <div>
            {Subscribed === true &&

                <div id="overlay">

                    <Modal show={true} className="confirm_delete premim_plan_upgrade glp_enable_popup">
                        <Modal.Header>
                            <Modal.Title>{'GLP-1 Medication'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                           {SubscribedMsg}

                            

                            


                        </Modal.Body>

                        <Modal.Footer className="display_block">

                            <button className="btn btn-primary a_href_premium" href={Href} onClick={NavigateHome}>{HrefText}</button>
                        </Modal.Footer>


                    </Modal>

                </div>
            }
        </div>
    )


}

export default PremiumCheckGLPComponent

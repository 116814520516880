import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import female_icon from "../../images/female.png";
import male_icon from "../../images/male.png";
import Progress from "../CoachComponents/Users/Progress";
import ConnectedDevices from "../CoachComponents/Users/Connecteddevices";
import ClientAppointment from "../CoachComponents/Users/Appointment";
import ClientQuiz from "../CoachComponents/Users/ClientQuiz";
import ClientFoodJournal from "../AdminComponents/AdminClientDashboard/ClientFoodJournal"
import ClientBelugaHealth from "./AdminClientDashboard/ClientBelugaHealth";
import SubscriptionAddons from "./AdminClientDashboard/SubscriptionAddOns";

const AdminClientDashboard = (props) => {
  const BackToManageUsers = () => {
    props.HideDashboardTab();
  };

  const [ClientFirstName, SetClientFirstName] = useState("");
  const [ClientLastName, SetClientLastName] = useState("");
  const [ClientDob, SetClientDob] = useState("");
  const [ClientProfilePic, SetClientProfilePic] = useState("");
  const [ClientGender, SetClientGender] = useState("");
  const [ClientLastAccessed, SetClientLastAccessed] = useState("");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [issidenav, setissidenav] = useState(false);
  const [profileData, SetprofileData] = useState("");
  const [profileTab, setprofileTab] = useState("progress");
  const [ClientId, SetClientId] = useState("");
  const [BMI, setBMI] = useState(0);

  const GetUserDetails = async (Clientid) => {
    const response = await axiosJWT.get("/user/" + Clientid, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    SetprofileData(response.data);
    var height = parseInt(response.data.client_infos[0].height || 1);
    var weight = parseInt(response.data.client_infos[0].weight || 1);
    var totalHeightInches = height * 12;
    var BMI = (weight / totalHeightInches) * (703 / totalHeightInches);
    setBMI(BMI.toFixed(2));

    if (response.data.first_name) {
      SetClientFirstName(response.data.first_name);
    }
    if (response.data.last_name) {
      SetClientLastName(response.data.last_name);
    }
    if (response.data.user_dob) {
      SetClientDob(response.data.user_dob);
    }
    if (response.data.user_profile_photo) {
      SetClientProfilePic(response.data.user_profile_photo);
    }
    if (response.data.gender) {
      SetClientGender(response.data.gender);
    }
    if (response.data.updatedAt) {
      SetClientLastAccessed(response.data.updatedAt);
    }
    console.log("response", response);
  };

  const userNav = () => {
    if (issidenav === true) {
      document
        .getElementById("user-fa-icon")
        .setAttribute("class", "fa fa-chevron-circle-left slide_icon");
      document
        .getElementById("users_list")
        .setAttribute("class", "users_list show");
    }

    if (issidenav === false) {
      document
        .getElementById("user-fa-icon")
        .setAttribute("class", "fa fa-chevron-circle-right slide_icon");
      document
        .getElementById("users_list")
        .setAttribute("class", "users_list hide");
    }

    setissidenav(!issidenav);
  };

  const changeTabs = async (e) => {
    const list_for = e.target.getAttribute("data-item");
    setprofileTab(list_for);
  };

  useEffect(() => {

   
    if (props.ClickedUserId) {
      SetClientId(props.ClickedUserId);
      GetUserDetails(props.ClickedUserId);
    }
  }, [props]);

  return (
    <section className="dashboard admin_client_dashboard">
      <div className="coach_users container ">
        <div className="title_box">
          <div className="leftmenu">
            <h4>Client Dashboard</h4>
            <br></br>
          </div>
          <div className="rightmenu">
            <button className="add_data_coach" onClick={BackToManageUsers}>
              Back
            </button>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div className="section_box">
          <div
            id="leftblock"
            className={issidenav ? "leftblock shrink" : "leftblock enlarge"}
          >
            <div className="user_header">
              <div className="p_title color_grey">Client User:</div>
              <i
                id="user-fa-icon"
                className="fa fa-chevron-circle-left slide_icon"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={userNav}
              ></i>
            </div>
            <div style={{ clear: "both" }} />
            <div id="users_list" className="users_list">
              <div id="user_56" className="user_data active">
                <div className="profile_photo">
                  <img
                    className="table_profile_img"
                    src={
                      ClientProfilePic
                        ? ClientProfilePic
                        : ClientGender === "MALE"
                        ? male_icon
                        : female_icon
                    }
                  />
                </div>
                <div className="profile_info">
                  <span className="name">
                    {ClientFirstName + " " + ClientLastName}
                  </span>
                  <br />
                  {ClientDob &&
                  ClientDob !== "" &&
                  ClientDob !== "0000-00-00" ? (
                    <span className="color_grey">
                      {format(parseISO(ClientDob), "MM-dd-yyyy")}
                    </span>
                  ) : (
                    <span className="color_grey">------</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            id="rightblock"
            className={issidenav ? "rightblock shrink" : "rightblock enlarge"}
          >
            <div className="topcontent">
              <div className="user_info" data-item="56">
                <div className="header_box">
                  <div className="menubox">
                    <div className="leftmenu">
                      <div className="profile_photo">
                        <img
                          className="table_profile_img"
                          src={
                            ClientProfilePic
                              ? ClientProfilePic
                              : ClientGender === "MALE"
                              ? male_icon
                              : female_icon
                          }
                        />
                      </div>
                      <div className="profile_info">
                        <span className="name">
                          {ClientFirstName + " " + ClientLastName}
                        </span>
                        <br />
                        <span className="color_grey" style={{ fontSize: "12px" }}>
                        Last accessed: {ClientLastAccessed !== "" &&
                            format(parseISO(ClientLastAccessed), `PP p`)}
                        </span>
                      </div>
                    </div>
                    <div className="rightmenu"></div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div className="input_fields">
                    <div className="row">
                      <div className="col">
                        <label className="inner_label">Age</label>
                        <br />
                        <div className="input_value">
                          {ClientDob && ClientDob != "0000-00-00" ? (
                            <div className="input_value">
                              {format(new Date(), "yyyy") -
                                format(new Date(ClientDob), "yyyy")}{" "}
                              Years
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">Height</label>
                        <br />
                        <div className="input_value">
                          {profileData && profileData.client_infos[0].height}
                          {profileData &&
                          profileData.client_infos[0].measure_type ===
                            "imperial"
                            ? " ft"
                            : " cm"}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">Weight</label>
                        <br />
                        <div className="input_value">
                          {profileData && profileData.client_infos[0].weight}
                          {profileData &&
                          profileData.client_infos[0].measure_type ===
                            "imperial"
                            ? " lbs"
                            : " kg"}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">BMI</label>
                        <br />
                        <div className="input_value">
                          {BMI}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">Target Weight</label>
                        <br />
                        <div className="input_value">
                          {profileData &&
                            profileData.client_infos[0].target_weight}
                          {profileData &&
                          profileData.client_infos[0].measure_type ===
                            "imperial"
                            ? " lbs"
                            : " kg"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input_fields">
                    <div className="row">
                      <div className="col">
                        <label className="inner_label">Activity Level</label>
                        <br />
                        <div className="input_value">
                          {profileData && profileData.client_infos[0].activity}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">Water Intake</label>
                        <br />
                        <div className="input_value">
                          {profileData &&
                            profileData.client_infos[0].water_intake}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">Daily Calories</label>
                        <br />
                        <div className="input_value">
                          {profileData &&
                            profileData.client_infos[0].daily_calories}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label">
                          Protein-Fats-Carbs
                        </label>
                        <br />
                        <div className="input_value">
                          {profileData &&
                            profileData.client_infos[0].daily_protein}
                          -
                          {profileData &&
                            profileData.client_infos[0].daily_carb}
                          -
                          {profileData && profileData.client_infos[0].daily_fat}
                        </div>
                      </div>
                      <div className="col">
                        <label className="inner_label"></label>
                        <br />
                        <div className="input_value"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tabmenu">
                <a
                  className={profileTab === "progress" ? "active" : null}
                  onClick={changeTabs}
                  data-item="progress"
                >
                  Progress
                </a>
                {/* <a
                  className={profileTab === "mealplan" ? "active" : null}
                  onClick={changeTabs}
                  data-item="mealplan"
                >
                  Meal Plan
                </a> */}
                {/* <a
                  className={profileTab === "notes" ? "active" : null}
                  onClick={changeTabs}
                  data-item="notes"
                >
                  Notes
                </a> */}
                <a
                  className={profileTab === "appointments" ? "active" : null}
                  onClick={changeTabs}
                  data-item="appointments"
                >
                  Appointments
                </a>
                <a
                  className={profileTab === "connected" ? "active" : null}
                  onClick={changeTabs}
                  data-item="connected"
                >
                  Connected Devices
                </a>
                <a
                  className={profileTab === "quiz" ? "active" : null}
                  onClick={changeTabs}
                  data-item="quiz"
                >
                  Quiz
                </a>
                <a
                  className={profileTab === "food_journal" ? "active" : null}
                  onClick={changeTabs}
                  data-item="food_journal"
                >
                  Food Journal
                </a>

                <a
                  className={profileTab === "subscription_add_ons" ? "active" : null}
                  onClick={changeTabs}
                  data-item="subscription_add_ons"
                >
                 Add Ons
                </a>

                {
                (props.ClickedUserIdGlp===true || props.ClickedUserIdGlp==='true') ?    <a
                className={profileTab === "beluga_health" ? "active" : null}
                onClick={changeTabs}
                data-item="beluga_health"
              >
                Beluga Health
              </a> : null
                }
             
              </div>

              <div style={{ clear: "both" }} />
            </div>
            <div className="tabcontent">
              {profileTab === "progress" ? (
                <Progress data={ClientId} Hideplan={{ display: "none" }} />
              ) : null}
              {profileTab === "connected" ? (
                <ConnectedDevices data={ClientId} />
              ) : null}
              {profileTab === "appointments" ? (
                <ClientAppointment data={ClientId} isAdmin={true} />
              ) : null}
              {profileTab === "quiz" ? (
                <ClientQuiz data={profileData.id} />
              ) : null}
              {
               profileTab ==='food_journal' ? (
                  <ClientFoodJournal data={profileData.id}/>
                ) : null
              }
                 {
               profileTab ==='beluga_health' ? (
                  <ClientBelugaHealth data={profileData.id}/>
                ) : null
              }
               {
               profileTab ==='subscription_add_ons' ? (
                  <SubscriptionAddons data={profileData.id}/>
                ) : null
              }



              {/* {profileTab === "mealplan" ? (
                <Mealplan data={profileData.id} />
              ) : null}
              {profileTab === "notes" ? <Notes data={profileData.id} /> : null}
              {profileTab === "appointments" ? (
                <ClientAppointment data={profileData.id} />
              ) : null}
              {profileTab === "labtest" ? (
                <LabTest data={profileData.id} />
              ) : null}
              {profileTab === "connected" ? (
                <ConnectedDevices data={profileData.id} />
              ) : null}
              {profileTab === "messages" ? (
                <ConnectedClientMessages data={profileData.id} />
              ) : null}
              {profileTab === "quiz" ? (
                <ClientQuiz data={profileData.id} />
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AdminClientDashboard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserFiles from "./UserFiles";
import Select from "react-select";

const PermissionDocs = () => {
  const side_nav = localStorage.getItem("side_nav");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const Role = localStorage.getItem("role");
  const [TabMenu,SetTabMenu]=useState('admin')
  const [FolderListShow, SetFolderListShow] = useState(true);
  const [AllowedDirs,SetAllowedDirs]=useState([])
  const [FolderId,SetFolderId]=useState('')
  const [DropDownLabel,SetDropDownLabel]=useState('')
  const [expertoptions, setExpertoptions] = useState([]);
  const [selectedOptions, setSelectedoptions] = useState([]);
  const [ExpertErr, SetExpertErr] = useState(false);


  const ChangeTab = (e) => {
    const list_for = e.target.getAttribute("data-item");

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));
    e.target.setAttribute("class", "active");
    SetTabMenu(list_for)
    

    if(list_for==='admin')
    {
        SetAllowedDirs([])
        GetMyAllowedAdminDir()
    }
    if(list_for==='coach')
    {
        SetAllowedDirs([])
        LoadMyClientOptions()
        GetMyAllowedDirs('')
        SetDropDownLabel('Select Client')
    }
    if(list_for==='client')
    {
        SetAllowedDirs([])
        GetMyAllowedDirs('')
        LoadMyCoachOptions()
        SetDropDownLabel('Select Coach')
    }
  };

  const LoadMyClientOptions=async()=>{

    try{
        var ClientObj=[];

        const AllClients = await axiosJWT.get("/allclient", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllClients.status === 200) {
          if (AllClients.data.Users !== null) {
            AllClients.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ClientObj.push(obj);
            });
          }
        }
      
        setExpertoptions(ClientObj);
  
      }catch(err)
      {
     console.log(err)
      }

  }

  const LoadMyCoachOptions=async()=>{
var ExpertObj=[];
    try {
        const AllNutrition = await axiosJWT.get("/mysubscribedcoaches", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (AllNutrition.status === 200) {
          if (AllNutrition.data.Users !== null) {
            AllNutrition.data.Users.forEach((data, index) => {
              var obj = {};
              obj["value"] = data.id;
              obj["label"] = data.email;
              ExpertObj.push(obj);
            });
          }
        }
        setExpertoptions(ExpertObj);
      } catch (err) {
        console.log(err);
      }

  }

  const GetMyAllowedDirs=async(ids)=>{

    const GetMyAllowedDirsResponse=await axiosJWT.get("/document-directory/allowed-dirs?ids="+ids+"", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); 
      if(GetMyAllowedDirsResponse.data!==null)
      {
      
        SetAllowedDirs(GetMyAllowedDirsResponse.data)
      }

      console.log(GetMyAllowedDirsResponse)


  }

  const DropdownChange = (e) => {
    setSelectedoptions(e);

    var UserId = e.map(s=>s.value);
    let ids = UserId.toString();
    GetMyAllowedDirs(ids)
    
  };

  const BackToDocuments = () => {

    if(Role==='Nutrition')
    {
        navigate("/coach/directories", { replace: true });
    }
    if(Role==='Client')
    {
        navigate("/client/directories", { replace: true });
    }


  };

  const GetMyAllowedAdminDir=async()=>{
   
    const response = await axios.get(
        "document-directory/admin-allowed-dir",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

   
      if(response.data!==null)
      {
      
        SetAllowedDirs(response.data)
      }

    

  }

  const ShowFiles=(e)=>{

    SetFolderId(e.target.getAttribute('data-id'))
    SetFolderListShow(false)

  }

  useEffect(()=>{
    GetMyAllowedAdminDir()
  },[])

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      <section className="dashboard">
        <div className="adminmanageusers container admin_user_directory">
          <div className="menubox">
            <div className="tabmenu">
              <a className="active" data-item="admin" onClick={ChangeTab}>
                Admin Documents
              </a>
              {Role === "Nutrition" && (
                <a className="" data-item="coach" onClick={ChangeTab}>
                  Client Documents
                </a>
              )}

              {Role === "Client" && (
                <a className="" data-item="client" onClick={ChangeTab}>
                  Coach Documents
                </a>
              )}

              <button onClick={BackToDocuments}>Back</button>
            </div>
          </div>
         {
            TabMenu!=='admin' && 
            <div className="select_members" style={{width:'91%',marginBottom:'15px'}}>
            <label className="label">{DropDownLabel}</label>
            <Select
              options={expertoptions}
              isMulti
              onChange={(e) => {
                DropdownChange(e);
              }}
             value={selectedOptions}
        
              {...(ExpertErr === true ? { id: "err_border" } : {})}
            />
          </div>
         }
         
          {FolderListShow === true && (
            <div className="folder_lists">
              {AllowedDirs.length > 0 &&
                AllowedDirs.map((list, index) => (
                  <button
                    type="button"
                    className="collapsible"
                    data-id={list.id}
                    onClick={ShowFiles}
                  >
                    {list.directory_name}
                   
                    <i
                      className="fa fa-folder-open directory_icon pointer_none"
                      aria-hidden="true"
                    ></i>
                  </button>
                ))}
            </div>
          )}
          {
            FolderListShow===false && <UserFiles SetFolderListShow={SetFolderListShow} FolderId={FolderId} CheckAccess={true}/>
            
          }
        </div>
      </section>
    </div>
  );
};
export default PermissionDocs;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useTransition, createRef } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import male_icon from '../../images/male.png'
import female_icon from '../../images/female.png'
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const AppointmentCalendar = () => {

  const token = localStorage.getItem("token");
  const side_nav = localStorage.getItem("side_nav");
  const user_timezone = localStorage.getItem("user_timezone");
  const userId = localStorage.getItem("userId");
  const CoachTimeZoneDefault = localStorage.getItem("user_timezone");
  const [MeetingCancelId, SetMeetingCancelId] = useState();
  const [ShowSuccessbookcancel, SetShowSuccessbookcancel] = useState(false);
  const HandleCloseSuccessbookcancel = () => { SetShowSuccessbookcancel(false) }
  const HandleShowSuccessbookcancel = () => { SetShowSuccessbookcancel(true) }
  const [MinDate, SetMinDate] = useState(new Date());
  const [copyClipboard, setcopyClipboard] = useState(false);

  const navigate = useNavigate();
  const [ShowSuccessbookApprove, SetShowSuccessbookApprove] = useState(false);
  const HandleCloseSuccessbookApprove = () => {
    SetShowSuccessbookApprove(false);
  }
  const [ApproveErrmsg, SetApproveErrmsg] = useState('');

  const [ShowSuccessbookcancelsuccess, SetShowSuccessbookcancelsucess] = useState(false);
  const HandleCloseSuccessbookcancelsucess = () => { SetShowSuccessbookcancelsucess(false) }
  const HandleShowSuccessbookcancelsuccess = () => { SetShowSuccessbookcancelsucess(true) }
  const [CoachUpcomingAppointmentList, SetCoachUpcomingAppointmentList] = useState([]);
  const [RequestReschedule, SetRequestReschedule] = useState([]);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState('');

  const [showBookappointment, SetshowBookappointment] = useState(false);
  const handleCloseBookAppointment = () => {
    SetSelectedMeetingSlotsDuration('')
    SetshowBookappointment(false);
    setnoSlot(false);
  }

  const handleClose = () => {
    setShow(false);
    load_slots();
  }

  const axiosJWT = axios.create();

  const [events, setEvents] = useState([]);
  const [defaultDate, setdefaultDate] = useState(new Date());
  const [scheduleDetails, setscheduleDetails] = useState('');
  const [SlotsAvailable, SetSlotsAvailable] = useState([]);
  const [SelectedSlot, SetSelectedSlot] = useState('');
  const [SelectedDate, SetSelectedDate] = useState('');
  const [SelectedClient, SetSelectedClient] = useState('');
  const [SelectedType, SetSelectedType] = useState('');
  const [SelectedMeetingDescription, SetSelectedMeetingDescription] = useState('');
  const [ListOfclient, SetListOfclient] = useState([]);
  const [RescheduleMeetingID, SetRescheduleMeetingID] = useState('');
  const [ShowReschedule, SetShowReschedule] = useState(false);

  const [Statusoptions, SetStatusoptions] = useState([{ name: 'Upcoming appointment', id: 0 }, { name: 'Completed', id: 1 }, { name: 'Cancelled', id: 2 }, { name: 'Reschedule requests', id: 3 }]);

  const [Serviceoptions, SetServiceoptions] = useState([]);
  const [Clientoptions, SetClientoptions] = useState([]);
  const [SelectedServiceFilter, SetSelectedServiceFilter] = useState([]);
  const [SelectedClientFilter, SetSelectedClientFilter] = useState([]);
  const [SelectedStatusFilter, SetSelectedStatusFilter] = useState([]);

  const [noSlot, setnoSlot] = useState(false);

  var ServiceArraySelect = [];
  var ClientArraySelect = [];
  var StatusArraySelect = [];

  const GetMyclientsDrop = async () => {

    const response = await axiosJWT.get('/nutrition/allclient', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    var ClientLists = response.data;
    var ClientArray = [];

    ClientLists.forEach((value, index, array) => {
      ClientArray.push({ name: value.email + '  (' + value.first_name + ' ' + value.last_name + ')', id: value.id });
    });

    SetClientoptions(ClientArray);
  }

  const GetServiceList = async () => {

    const response = await axiosJWT.get('/nutrition-service/list-services?data=all_active', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    var ServiceList = response.data.NutritionistService;

    var ServiceArray = [];

    ServiceList.forEach((value, index, array) => {
      ServiceArray.push({ name: value.service_name, id: value.id });
    });

    // console.log(ServiceArray);

    SetServiceoptions(ServiceArray);
  }

  const ApplyFilterSlots = async () => {

    setEvents([]);
    var Serviceselected = ServiceDropRef.current.getSelectedItems();
    var ClientSelected = ClientDropRef.current.getSelectedItems();
    var StatusSelected = StatusDropRef.current.getSelectedItems();

    ServiceArraySelect = Serviceselected.map(function (value) {
      return value.id;
    });

    ClientArraySelect = ClientSelected.map(function (value) {
      return value.id;
    });

    StatusArraySelect = StatusSelected.map(function (value) {
      return value.id;
    });

    var servicestring = ServiceArraySelect.toString();

    var clientstring = ClientArraySelect.toString();
    var statusstring = StatusArraySelect.toString();

    var startOfWeek = '';
    var endOfWeek = '';

    var response = await axiosJWT.get("/appointment?startdate=" + startOfWeek + "&endate=" + endOfWeek + '&service=' + servicestring + '&client=' + clientstring + '&status=' + statusstring + '',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    var tempEvents = [];

    var day_slot_list = response.data.FinalArray;

    // console.log(day_slot_list);

    day_slot_list.forEach(element => {
      var converted_start_time = moment.utc(element.appointment.start_time).clone().tz(user_timezone).format('YYYY-MM-DD HH:mm:ss');
      var converted_end_time = moment.utc(element.appointment.end_time).clone().tz(user_timezone).format('YYYY-MM-DD HH:mm:ss');

      // console.log(converted_start_time + " " + converted_end_time)

      const data = {
        'id': element.appointment.id,
        'clientname': element.user[0].first_name + " " + element.user[0].last_name,
        'title': element.user[0].first_name + " " + element.user[0].last_name + " (" + element.appointment.meeting_description + ")",
        'desc': element.appointment.meeting_description,
        'start': new Date(converted_start_time),
        'end': new Date(converted_end_time),
        'meetlink': element.appointment.meeting_link
      };

      tempEvents.push(data);
    });

    setEvents(tempEvents);
  }

  const datepicker1Ref = useRef(null);

  const ServiceDropRef = useRef();
  const ClientDropRef = useRef();
  const StatusDropRef = useRef();

  function handleClickDatepickerIcon1() {
    const datepickerElement = datepicker1Ref.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setFocus(true);
  }

  const HandleCloseShowReschedule = () => {
    SetShowReschedule(false);
    SetRescheduleMeetingID('');
    setStartDate('');
  }

  const [ShowSuccessbook, SetShowSuccessbook] = useState(false);

  const HandleCloseSuccessbook = () => {
    SetShowSuccessbook(false);
  }

  const [successpoptitle, Setsuccesspoptitle] = useState('');
  const [successpopupmsg, Setsuccesspopupmsg] = useState('');

  useEffect(() => {
    document.title = 'Coach-Calendar';
    load_slots();
    GetUpcominAppointments();
    GetMyRescheduleRequests();
    GetMyclientsDrop();
    GetServiceList();
    GetMyRescheduleRequestsList(1);

    // if(copyClipboard === true)
    // {
    //   setTimeout(() => {
    //     setcopyClipboard(false);
    //   }, 3000);
    // }
  }, []);


  //setEvents({id:1,start:(moment.utc(Date()).toDate()),end:(moment.utc(Date()).add(60, 'minutes'))});

  moment.locale("en-gb", {
    week: {
      format: "dddd",
      dow: 1
    }
  });

  const localizer = momentLocalizer(moment);
  const DnDCalendar = withDragAndDrop(Calendar);


  const load_slots = async () => {

    var startOfWeek = '';
    var endOfWeek = '';

    var response = await axiosJWT.get("/appointment?startdate=" + startOfWeek + "&endate=" + endOfWeek,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    var tempEvents = [];

    var day_slot_list = response.data.FinalArray;

    // console.log(day_slot_list)

    day_slot_list.forEach(element => {
      var converted_start_time = moment.utc(element.appointment.start_time).clone().tz(user_timezone).format('YYYY-MM-DD HH:mm:ss');
      var converted_end_time = moment.utc(element.appointment.end_time).clone().tz(user_timezone).format('YYYY-MM-DD HH:mm:ss');

      // console.log(converted_start_time + " " + converted_end_time)

      const data = {
        'id': element.appointment.id,
        'clientname': element.user[0].first_name + " " + element.user[0].last_name,
        'title': element.user[0].first_name + " " + element.user[0].last_name + " (" + element.appointment.meeting_description + ")",
        'desc': element.appointment.meeting_description,
        'start': new Date(converted_start_time),
        'end': new Date(converted_end_time),
        'meetlink': element.appointment.meeting_link,
        'meetstatus': element.appointment.meeting_status
      };

      tempEvents.push(data);
      setEvents(tempEvents);
    });

    //console.log(tempEvents)
  }

  const eventShow = async (data) => {
    // console.log(data);

    var start_time_format = moment(data.start).format('h:mm A');
    var end_time_format = moment(data.end).format('h:mm A');
    //console.log(start_time_format+" "+end_time_format)

    data.date = moment(data.start).format('MMMM Do, YYYY');
    data.start = start_time_format;
    data.end = end_time_format;
    setShow(true);
    setscheduleDetails(data);

    setdefaultDate(data.date);
  }

  const cancelappointment = async (e) => {
    var booking_id = e.target.getAttribute("data-item");
    // console.log(booking_id);
  }

  const weekNavigate = async (date, view) => {
    /* var start_date = moment(date).startOf('week').startOf('week');
     var end_date = moment(date).endOf('week').endOf('week');
 
     console.log(start_date._d);
     console.log(end_date._d);*/

    var startOfWeek = moment(date).startOf('week').format('YYYY-MM-DD');
    var endOfWeek = moment(date).endOf('week').format('YYYY-MM-DD');
  }


  const TriggerCancelPopup = (e) => {
    handleClose();
    var CancelMeetingId = e.target.getAttribute("data-item");
    CancelMeetingId = parseInt(CancelMeetingId);
    SetMeetingCancelId(CancelMeetingId);
    HandleShowSuccessbookcancel();
  }

  const CancelMeeting = async (e) => {

    var CancelMeetingId = e.target.getAttribute("data-meeting_appointment_id");

    try {
      ShowLoader()
      const response = await axiosJWT.post('appointment/cancel-appointment',
        {
          'meeting_id': CancelMeetingId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {

        HandleCloseSuccessbookcancel();
        load_slots();
        HandleShowSuccessbookcancelsuccess();
        GetMyAllappointmentslist(PagenumberAppointment);
        HideLoader()
      }
    } catch (err) {
      HideLoader()
      console.log(err)
    }


  }

  const GetUpcominAppointments = async () => {

    const response = await axiosJWT.get('/appointment/coach-my-appointments', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });


    if (response.data !== null) {
      if (response.data.FinalArray !== null) {
        // console.log(response.data.FinalArray)
        SetCoachUpcomingAppointmentList(response.data.FinalArray);
      }
    }
  }

  function timetoUtcClientAppointment(passdate) {
    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss');

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(CoachTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss');
    return stardates;
  }

  const TriggerBookAppointmentPopup = async () => {

    const response = await axiosJWT.get("/appointment/get-nutrition-details?id=" + userId + "", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    var AllowedCoachDurationSlots = []
    if (response.data !== null) {


      if (response.data.nutritionist_infos[0].allowed_appointment_duration !== null && response.data.nutritionist_infos[0].allowed_appointment_duration !== '') {
        var ParsedData = JSON.parse(response.data.nutritionist_infos[0].allowed_appointment_duration)
        ParsedData.forEach(element => {
          let my_object = {};
          if (element === '"15min"') {

            my_object.label = "15 mins";
            my_object.value = 15;
          }
          else if (element === '"30min"') {
            my_object.label = "30 mins";
            my_object.value = 30;
          }
          else if (element === '"1hour"') {
            my_object.label = "1 hour";
            my_object.value = 60;
          }

          AllowedCoachDurationSlots.push(my_object)

        });

      }
      else {
        let my_object = {};

        my_object.label = "1 hour";
        my_object.value = 60;
        AllowedCoachDurationSlots.push(my_object)
      }

    }
    SetMeetingSlotsDuration(AllowedCoachDurationSlots)
    SetSlotsAvailable([]);
    setStartDate('');
    SetSelectedSlot('');
    SetSelectedClient('');
    SetSelectedType('');
    SetSelectedMeetingSlotsDuration('')

    GetClientLists();
    SetMeetingClientErr(false);
    SetMeetingTypeErr(false);
    SetMeetingDateErr(false);
    SetshowBookappointment(true);
  }

  const ChangedDate = async (date) => {

    SetMeetingSlotsDurationErr(false)


    if (SelectedMeetingSlotsDuration === null || SelectedMeetingSlotsDuration === '') {
      SetMeetingSlotsDurationErr(true)
    }
    else {


      var cdate = moment(date).format('YYYY-MM-DD');

      setStartDate(date);
      SetSlotsAvailable([]);
      SetSelectedSlot('');
      const dateTimeInParts = date.toISOString().split("T");

      var Startresult = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' }).replace(',', '');

      var passdate = cdate;

      var previousDate = getPreviousDay(new Date(passdate));
      var NextDate = getNextDay(new Date(passdate));
      previousDate = formatDate(previousDate);
      NextDate = formatDate(NextDate);

      SetSelectedDate(passdate);
      var ExtremeFinalDayCheck = [];
      var ClientTimeZone = '';
      var CoachTimeZone = '';

      const SlotResponse = await axiosJWT.get('availability/day/' + userId + '?date=' + passdate + '&previousday=' + previousDate + '&nextday=' + NextDate + '&coach_book=yes', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((SlotResponse) => {

        // console.log(SlotResponse)

        CoachTimeZone = user_timezone;
        ClientTimeZone = user_timezone;

        var DayAvailability = [];
        var PDayAvailability = [];
        var NDayAvailability = [];
        var FinalDayAvailability = [];
        var ExtremeDayCheck = [];

        if (SlotResponse.data !== null) {

          if (SlotResponse.data.result !== null) {

            if (SlotResponse.data.result.slots !== null && SlotResponse.data.result.slots !== '') {

              DayAvailability = JSON.parse(SlotResponse.data.result.slots);
              var FromTOArray = [];

              DayAvailability.forEach((value, index, array) => {
                const myArray = value.split("-");
                FromTOArray.push(passdate + ' ' + myArray[0].trim() + ' $TO ' + passdate + ' ' + myArray[1].trim());
              });


              FromTOArray.forEach((value, index, array) => {
                const myArray = value.split("$TO");

                var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
                var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

                FinalDayAvailability.push(Date1 + ' $TO ' + Date2);
              });
            }
          }


          if (SlotResponse.data.prevresult !== null) {

            if (SlotResponse.data.prevresult.slots !== null && SlotResponse.data.prevresult.slots !== '') {

              PDayAvailability = JSON.parse(SlotResponse.data.prevresult.slots);
              var FromTOArray = [];

              PDayAvailability.forEach((value, index, array) => {
                const myArray = value.split("-");
                FromTOArray.push(previousDate + ' ' + myArray[0].trim() + ' $TO ' + previousDate + ' ' + myArray[1].trim());
              });

              FromTOArray.forEach((value, index, array) => {
                const myArray = value.split("$TO");

                var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
                var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

                FinalDayAvailability.push(Date1 + ' $TO ' + Date2);
              });
            }
          }

          if (SlotResponse.data.nextresult !== null) {

            if (SlotResponse.data.nextresult.slots !== null && SlotResponse.data.nextresult.slots !== '') {

              NDayAvailability = JSON.parse(SlotResponse.data.nextresult.slots);
              var FromTOArray = [];

              NDayAvailability.forEach((value, index, array) => {
                const myArray = value.split("-");
                FromTOArray.push(NextDate + ' ' + myArray[0].trim() + ' $TO ' + NextDate + ' ' + myArray[1].trim());
              });

              FromTOArray.forEach((value, index, array) => {
                const myArray = value.split("$TO");

                var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
                var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

                FinalDayAvailability.push(Date1 + ' $TO ' + Date2);
              });
            }
          }
        }

        FinalDayAvailability.forEach((value, index, array) => {

          const myArray = value.split("$TO");

          var Date1 = myArray[0].trim();
          Date1 = Date1.toString().slice(4, 24);

          var Date2 = myArray[1].trim();
          Date2 = Date2.toString().slice(4, 24);

          ExtremeDayCheck.push(Date1 + ' - ' + Date2);
        });

        ExtremeDayCheck.forEach((value, index, array) => {
          if (value.startsWith(Startresult)) {
            ExtremeFinalDayCheck.push(value);
          }
        });

        // if(ExtremeFinalDayCheck.length>0)
        // {

        //    SetSlotsAvailable(ExtremeFinalDayCheck)
        // }
      });


      if (ExtremeFinalDayCheck.length > 0) {



        var Startime = [];

        //durationBasedconvert start
        let DurationBasedSlots = [];
        ExtremeFinalDayCheck.forEach(async (value, index, array) => {

          const CheckArray = value.split('-');
          var pushstartime = moment(CheckArray[0].trim()).format('YYYY-MM-DD HH:mm:ss');



          if (parseFloat(SelectedMeetingSlotsDuration) === 15) {
            var indexLoop = 4;

            for (let index = 0; index < indexLoop; index++) {

              var fifteenstarttime = pushstartime
              var fifteenendtime = moment(fifteenstarttime).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
              var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
              DurationBasedSlots.push(FinalFifteenTime)
              pushstartime = fifteenendtime
            }

          }
          if (parseFloat(SelectedMeetingSlotsDuration) === 30) {

            var indexLoop = 2;

            for (let index = 0; index < indexLoop; index++) {

              var fifteenstarttime = pushstartime
              var fifteenendtime = moment(fifteenstarttime).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
              var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
              DurationBasedSlots.push(FinalFifteenTime)
              pushstartime = fifteenendtime
            }

          }

          if (parseFloat(SelectedMeetingSlotsDuration) === 60) {

            var indexLoop = 1;

            for (let index = 0; index < indexLoop; index++) {

              var fifteenstarttime = pushstartime
              var fifteenendtime = moment(fifteenstarttime).add(60, 'minutes').format('YYYY-MM-DD HH:mm:ss');
              var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
              DurationBasedSlots.push(FinalFifteenTime)
              pushstartime = fifteenendtime
            }

          }

        })

        if (parseFloat(SelectedMeetingSlotsDuration) === 30 || parseFloat(SelectedMeetingSlotsDuration) === 15 || parseFloat(SelectedMeetingSlotsDuration) === 60) {

          ExtremeFinalDayCheck = DurationBasedSlots
        }

        //durationBasedconvert end



        ExtremeFinalDayCheck.forEach((value, index, array) => {
          const CheckArray = value.split('$TO');

          var pushstartime = moment(CheckArray[0].trim()).format('YYYY-MM-DD HH:mm:ss');

          var fmt = 'YYYY-MM-DD HH:mm:ss';  // must match the input
          var zone = ClientTimeZone;

          var startm = moment.tz(pushstartime, fmt, zone);
          startm = startm.utc();

          var stardates = startm.format(fmt);
          Startime.push(stardates);
        });

        Startime.forEach((value, index, array) => {

          var fmt = 'YYYY-MM-DD HH:mm:ss';
          var currenttime = moment().utc();

          var startTime = timetoUtcClientAppointment(value);
          startTime = new Date(startTime);
          var endTime = new Date(currenttime);

          if (startTime < endTime) {
            ExtremeFinalDayCheck[index] = null;
          }
        });

        // var StartimeCheck = Startime.filter(function (el) {
        //   return el != null;
        // });

        // Startime=StartimeCheck


        let moments = Startime.map(d => moment(d)),
          maxDate = moment.max(moments)

        let Endmoments = Startime.map(d => moment(d)),
          minDate = moment.min(Endmoments)

        var PassStartDate = minDate._i;
        var PassEndDate = moment(PassStartDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

        const DayApointtmentCheck = await axiosJWT.get('appointment/appointment-check/' + userId + '?startdate=' + PassStartDate + '&endate=' + PassEndDate + '', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });


        if (DayApointtmentCheck.data !== null) {
          let BookedSLots = []
          BookedSLots = DayApointtmentCheck.data.GetResponse;



          BookedSLots.forEach(element => {

            var BookedStarttimestamp = moment.utc(element.start_time).format('X')
            var BookedEndtimestamp = moment.utc(element.end_time).format('X')

            Startime.forEach((value, index, array) => {
              var SlotsStartTime = moment.utc(value).format('X');
              var SlotsEndtime = moment.utc(value).add(parseFloat(SelectedMeetingSlotsDuration), 'minutes').format('X')

              var SlotsEndtimeCheck = moment.utc(value).add(parseFloat(SelectedMeetingSlotsDuration), 'minutes').format('YYYY-MM-DD HH:mm:ss')


              if (Numberbetween(BookedStarttimestamp, SlotsStartTime, SlotsEndtime, BookedEndtimestamp)) {
                ExtremeFinalDayCheck[index] = null;
              }
            });
          });

          var filtered = ExtremeFinalDayCheck.filter(function (el) {
            return el !== null;
          });

          SetSlotsAvailable(filtered);

          if (filtered.length === 0)
            setnoSlot(true);
          else
            setnoSlot(false);

          console.log("mi1:")
          console.log(filtered)
        }
        else {
          SetSlotsAvailable(ExtremeFinalDayCheck);

          if (ExtremeFinalDayCheck.length === 0)
            setnoSlot(true);
          else
            setnoSlot(false);

          console.log("mi2:")
          console.log(filtered)
        }
      }
      else {
        setnoSlot(true);
      }


      if (ExtremeFinalDayCheck.length === 0) {

        const WeeklySlotResponse = await axiosJWT.get('availability/regular/' + userId + '', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        var WeekDatePush = [];

        var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        var d = new Date(date);

        var index = d.getDay();

        var first = d.getDate() - d.getDay(); // First day is the day of the month - the day of the week
        // first=first;

        var firstday = new Date(d.setDate(first));

        var lastday = moment(firstday, "DD-MM-YYYY").add(6, 'days').format(moment.HTML5_FMT.DATE);
        firstday = moment(firstday).format(moment.HTML5_FMT.DATE);

        var startDate = new Date(firstday);
        var EndDate = new Date(lastday);
        var WeekDatesArray = getDatesInRange(startDate, EndDate);

        //forward and backward date 

        var currprev = new Date(firstday); // get current date

        var firstprev = currprev.getDate() - 1 - currprev.getDay(); // First day is the day of the month - the day of the week

        var lastprev = firstprev - 6; // last day is the first day + 6

        var lastdayprev = new Date(currprev.setDate(firstprev));
        var firstdayprev = new Date(currprev.setDate(lastprev));

        var PrevWeekDatesArray = getDatesInRange(firstdayprev, lastdayprev);

        var currfor = new Date(lastday);
        var firstfor = currfor.getDate() + 7 - currfor.getDay();
        var lastfor = firstfor + 6;
        var firstdayfor = new Date(currfor.setDate(firstfor));
        var lastdayfor = new Date(currfor.setDate(lastfor));

        var ForwardWeekDatesArray = getDatesInRange(firstdayfor, lastdayfor);

        if (WeeklySlotResponse.data !== null && WeeklySlotResponse.data !== '') {

          WeekDatesArray.forEach((value, index, array) => {
            WeekDatePush[value] = WeeklySlotResponse.data[index].slots;
          });

          PrevWeekDatesArray.forEach((value, index, array) => {
            WeekDatePush[value] = WeeklySlotResponse.data[index].slots;
          });

          ForwardWeekDatesArray.forEach((value, index, array) => {
            WeekDatePush[value] = WeeklySlotResponse.data[index].slots;
          });

          var PreviousDaySlots = WeekDatePush[previousDate];
          var NextDaySlots = WeekDatePush[NextDate];
          var CurrentDateSlots = WeekDatePush[passdate];

          var WeeklyFinalAvailability = [];
          var ExtremeWeekCheck = [];
          var ExtremeFinalWeekCheck = [];

          var WeeklyFromTOArrayPrev = [];
          var WeeklyFromTOArrayNext = [];
          var WeeklyFromTOArrayCurr = [];

          PreviousDaySlots.forEach((value, index, array) => {
            const myArray = value.split("-");
            WeeklyFromTOArrayPrev.push(previousDate + ' ' + myArray[0].trim() + ' $TO ' + previousDate + ' ' + myArray[1].trim());
          });

          NextDaySlots.forEach((value, index, array) => {
            const myArray = value.split("-");
            WeeklyFromTOArrayNext.push(NextDate + ' ' + myArray[0].trim() + ' $TO ' + NextDate + ' ' + myArray[1].trim());
          });

          CurrentDateSlots.forEach((value, index, array) => {
            const myArray = value.split("-");
            WeeklyFromTOArrayCurr.push(passdate + ' ' + myArray[0].trim() + ' $TO ' + passdate + ' ' + myArray[1].trim());
          });

          WeeklyFromTOArrayPrev.forEach((value, index, array) => {
            const myArray = value.split("$TO");

            var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
            var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

            WeeklyFinalAvailability.push(Date1 + ' $TO ' + Date2);
          });

          WeeklyFromTOArrayNext.forEach((value, index, array) => {
            const myArray = value.split("$TO");

            var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
            var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

            WeeklyFinalAvailability.push(Date1 + ' $TO ' + Date2);
          });

          WeeklyFromTOArrayCurr.forEach((value, index, array) => {
            const myArray = value.split("$TO");

            var Date1 = parseISO(moment.tz(myArray[0].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));
            var Date2 = parseISO(moment.tz(myArray[1].trim(), CoachTimeZone).tz(ClientTimeZone).format('YYYY-MM-DD HH:mm'));

            WeeklyFinalAvailability.push(Date1 + ' $TO ' + Date2);
          });

          WeeklyFinalAvailability.forEach((value, index, array) => {
            const myArray = value.split("$TO");

            var Date1 = myArray[0].trim();
            Date1 = Date1.toString().slice(4, 24);

            var Date2 = myArray[1].trim();
            Date2 = Date2.toString().slice(4, 24);

            ExtremeWeekCheck.push(Date1 + ' - ' + Date2);
          });

          ExtremeWeekCheck.forEach((value, index, array) => {
            if (value.startsWith(Startresult)) {
              ExtremeFinalWeekCheck.push(value);
            }
          });

          if (ExtremeFinalWeekCheck.length > 0) {


            var Startime = [];
            //durationBasedconvert start
            let DurationBasedSlots = [];
            ExtremeFinalWeekCheck.forEach(async (value, index, array) => {



              const CheckArray = value.split('-');
              var pushstartime = moment(CheckArray[0].trim()).format('YYYY-MM-DD HH:mm:ss');



              if (parseFloat(SelectedMeetingSlotsDuration) === 15) {
                var indexLoop = 4;

                for (let index = 0; index < indexLoop; index++) {

                  var fifteenstarttime = pushstartime
                  var fifteenendtime = moment(fifteenstarttime).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                  var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
                  DurationBasedSlots.push(FinalFifteenTime)
                  pushstartime = fifteenendtime
                }

              }
              if (parseFloat(SelectedMeetingSlotsDuration) === 30) {

                var indexLoop = 2;

                for (let index = 0; index < indexLoop; index++) {

                  var fifteenstarttime = pushstartime
                  var fifteenendtime = moment(fifteenstarttime).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                  var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
                  DurationBasedSlots.push(FinalFifteenTime)
                  pushstartime = fifteenendtime
                }

              }

              if (parseFloat(SelectedMeetingSlotsDuration) === 60) {

                var indexLoop = 1;

                for (let index = 0; index < indexLoop; index++) {

                  var fifteenstarttime = pushstartime
                  var fifteenendtime = moment(fifteenstarttime).add(60, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                  var FinalFifteenTime = fifteenstarttime + ' $TO ' + fifteenendtime
                  DurationBasedSlots.push(FinalFifteenTime)
                  pushstartime = fifteenendtime
                }

              }

            })

            if (parseFloat(SelectedMeetingSlotsDuration) === 30 || parseFloat(SelectedMeetingSlotsDuration) === 15 || parseFloat(SelectedMeetingSlotsDuration) === 60) {

              ExtremeFinalWeekCheck = DurationBasedSlots
            }

            //durationBasedconvert end




            ExtremeFinalWeekCheck.forEach((value, index, array) => {

              const CheckArray = value.split('$TO');

              var pushstartime = moment(CheckArray[0].trim()).format('YYYY-MM-DD HH:mm:ss');
              var fmt = 'YYYY-MM-DD HH:mm:ss';  // must match the input
              var zone = ClientTimeZone;
              var startm = moment.tz(pushstartime, fmt, zone);
              startm = startm.utc();
              var stardates = startm.format(fmt);
              Startime.push(stardates);
            });


            Startime.forEach((value, index, array) => {

              var fmt = 'YYYY-MM-DD HH:mm:ss';
              var current_time = moment.utc().format('X');
              var appointment_starttime = moment.utc(value).format("X");

              if (current_time > appointment_starttime) {
                ExtremeFinalWeekCheck[index] = null;
              }

            });



            let moments = Startime.map(d => moment(d)),
              maxDate = moment.max(moments)

            let Endmoments = Startime.map(d => moment(d)),
              minDate = moment.min(Endmoments)

            var PassStartDate = minDate._i;
            var PassEndDate = moment(PassStartDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
            // var PassEndDate = maxDate._i;

            const DayApointtmentCheck = await axiosJWT.get('appointment/appointment-check/' + userId + '?startdate=' + PassStartDate + '&endate=' + PassEndDate + '', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })


            if (DayApointtmentCheck.data !== null) {
              let BookedSLots = []
              BookedSLots = DayApointtmentCheck.data.GetResponse;



              BookedSLots.forEach(element => {

                var BookedStarttimestamp = moment.utc(element.start_time).format('X')
                var BookedEndtimestamp = moment.utc(element.end_time).format('X')

                Startime.forEach((value, index, array) => {
                  var SlotsStartTime = moment.utc(value).format('X');
                  var SlotsEndtime = moment.utc(value).add(parseFloat(SelectedMeetingSlotsDuration), 'minutes').format('X')

                  var SlotsEndtimeCheck = moment.utc(value).add(parseFloat(SelectedMeetingSlotsDuration), 'minutes').format('YYYY-MM-DD HH:mm:ss')


                  if (Numberbetween(BookedStarttimestamp, SlotsStartTime, SlotsEndtime, BookedEndtimestamp)) {
                    ExtremeFinalWeekCheck[index] = null;
                  }
                });
              });

              var filtered = ExtremeFinalWeekCheck.filter(function (el) {
                return el !== null;
              });


              SetSlotsAvailable(filtered);

              if (filtered.length === 0)
                setnoSlot(true);
              else
                setnoSlot(false);
            }
            else {
              SetSlotsAvailable(ExtremeFinalWeekCheck);

              if (ExtremeFinalDayCheck.length === 0)
                setnoSlot(true);
              else
                setnoSlot(false);
            }
          }
          else {
            setnoSlot(true);
          }
        }
      }

    }


  }

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
  }

  function getNextDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + 1);
    return previous;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  const SlotsSelection = (e) => {
    [].forEach.call(document.querySelectorAll('.slots_list_by_client'), function (el) {
      el.classList.remove("clicked_slot_active");
    });

    e.target.classList.add("clicked_slot_active");

    SetSelectedSlot(e.target.getAttribute('data-slot'));
  }


  function formatslots(slots) {
    const Myarray = slots.split('$TO');
    var Starttime = Myarray[0].trim();
    var Endtime = Myarray[1].trim();

    Starttime = format(parseISO(Starttime), `hh:mm a`);
    Endtime = format(parseISO(Endtime), `hh:mm a`);

    return Starttime + '-' + Endtime;
  }

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(formatDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const GetClientLists = async () => {
    const response = await axiosJWT.get("/allclient", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetListOfclient(response.data.Users);
    // console.log(response)
  }

  const BookAppointmentByClient = async () => {


    SetMeetingClientErr(false);
    SetMeetingTypeErr(false);
    SetMeetingDateErr(false);
    SetMeetingSlotsDurationErr(false)
    SetMeetingSourceErr(false)





    if (SelectedClient !== '' && SelectedType !== '' && SelectedDate !== '' && SelectedSlot !== '' && SelectedMeetingSlotsDuration !== '' && MeetingSource !== '') {

      if (MeetingSource === 'Zoom meeting' && SelectedMeetingSlotsDuration === '60') {
        SetMeetingSourceErr(true)
        SetMeetingSourceErrMsg('You cannot book 1 hour session with zoom meeting');
      }
      else {

        ShowLoader()
        try {

          const response = await axiosJWT.post('appointment/add-appointment',
            {
              'nutrition_id': userId,
              'client_id': SelectedClient,
              'service_id': '',
              'slot': SelectedSlot,
              'client_timezone': user_timezone,
              'meeting_description': SelectedMeetingDescription,
              'book_by_coach': 'yes',
              'meeting_type': SelectedType,
              'meeting_duration': SelectedMeetingSlotsDuration,
              'meeting_source': MeetingSource
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });

          Setsuccesspoptitle('Appointment');
          Setsuccesspopupmsg(response.data.msg);
          SetshowBookappointment(false);
          SetShowSuccessbook(true);
          GetUpcominAppointments();
          HideLoader()
        } catch (error) {
          Setsuccesspoptitle('Failed !');
          Setsuccesspopupmsg(error.response.data.msg);
          SetshowBookappointment(false);
          SetShowSuccessbook(true);
          console.log(error);
          HideLoader()
        }

      }


    }
    else {
      if (SelectedClient === '') {
        SetMeetingClientErr(true);
        SetMeetingClientErrMsg('Please select client');
      }
      if (MeetingSource === '') {
        SetMeetingSourceErr(true);
        SetMeetingSourceErrMsg('Please select meeting source');
      }
      if (SelectedType === '') {
        SetMeetingTypeErr(true);
        SetMeetingTypeErrMsg('Please select meeting type');
      }
      if (SelectedDate === '' || SelectedSlot === '') {
        SetMeetingDateErr(true);
        SetMeetingDateErrMsg('Please select Date & Slot');
      }
      if (SelectedMeetingSlotsDuration === '') {
        SetMeetingSlotsDurationErr(true);

      }


    }
  }

  const [MeetingClientErr, SetMeetingClientErr] = useState(false);
  const [MeetingClientErrMsg, SetMeetingClientErrMsg] = useState('');

  const [MeetingTypeErr, SetMeetingTypeErr] = useState(false);
  const [MeetingTypeErrMsg, SetMeetingTypeErrMsg] = useState('');

  const [MeetingDateErr, SetMeetingDateErr] = useState(false);
  const [MeetingDateErrMsg, SetMeetingDateErrMsg] = useState('');

  const TriggerRescheduleMeetingPopup = (e) => {
    var MeetingID = e.target.getAttribute('data-meeting_appointment_id');
    SetRescheduleMeetingID(MeetingID);
    setStartDate('');
    SetSelectedSlot('');
    SetSlotsAvailable([]);
    SetShowReschedule(true);
  }

  const GetMyRescheduleRequests = async () => {

    const response = await axiosJWT.get("/appointment/reschedule-appointment-request", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetRequestReschedule(response.data.FinalArray);
  }

  const ApproveReschdule = async (e) => {

    var Meeting_id = e.target.getAttribute('data-meeting_id');
    ShowLoader()
    try {
      const response = await axiosJWT.post('appointment/reschedule-appointment-request/approve',
        {
          'meeting_id': Meeting_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        GetUpcominAppointments();
        GetMyRescheduleRequests();
        load_slots();
        GetMyRescheduleRequestsList(PagenumberReschedule);
        HideLoader()
      }
    } catch (err) {
      SetApproveErrmsg(err.response.data.msg);
      SetShowSuccessbookApprove(true);
      HideLoader()
    }
  }

  const DeclineRescheduleRequest = async (e) => {
    var Meeting_id = e.target.getAttribute('data-meeting_id');
    ShowLoader()
    try {
      const response = await axiosJWT.post('appointment/reschedule-appointment-request/decline',
        {
          'meeting_id': Meeting_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        GetUpcominAppointments();
        GetMyRescheduleRequests();
        GetMyRescheduleRequestsList(PagenumberReschedule);
        load_slots();
        HideLoader()
      }
    } catch (err) {
      SetApproveErrmsg(err.response.data.msg);
      SetShowSuccessbookApprove(true);
      HideLoader()
    }
  }

  const CancelRescheduleMeeting = async (e) => {

    var Meeting_id = e.target.getAttribute('data-meeting_id');
    try {
      ShowLoader()
      const response = await axiosJWT.post('appointment/cancel-appointment-and-reschedule',
        {
          'meeting_id': Meeting_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      if (response) {
        GetUpcominAppointments();
        GetMyRescheduleRequests();
        GetMyRescheduleRequestsList(PagenumberReschedule);
        load_slots();
        HideLoader()
      }
    } catch (err) {
      HideLoader()
    }

  }

  const joinmeeting = (e) => {
    var meeting_id = e.target.getAttribute("data-item");
    navigate("/video/" + meeting_id, { replace: true });
  }

  const ChangeTab = (e) => {

    SetShowCalendarTab(false);
    SetShowAppointmentsTab(false);
    SetShowRescheduleTab(false);

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));
    e.target.setAttribute("class", "active");
    var Tab = e.target.getAttribute('data-tab');
    if (Tab === 'calendar') {
      SetShowCalendarTab(true);
    }
    if (Tab === 'appointments') {
      SetShowAppointmentsTab(true);
      GetMyAllappointmentslist(1);
    }
    if (Tab === 'reschedule_requests') {
      SetShowRescheduleTab(true);
      GetMyRescheduleRequestsList(1);
    }
  }
  const [ShowCalendarTab, SetShowCalendarTab] = useState(true);
  const [ShowAppointmentsTab, SetShowAppointmentsTab] = useState(false);
  const [ShowRescheduleTab, SetShowRescheduleTab] = useState(false);

  const [MyAllappointments, SetMyAllappointments] = useState([]);
  const [PerPage, SetPerPage] = useState(10);
  const [PagenumberAppointment, SetPagenumberAppointment] = useState(1);
  const [TotalRecordAppointment, SetTotalRecordAppointment] = useState(0);

  const [MyRescheduleList, SetMyRescheduleList] = useState([]);
  const [PagenumberReschedule, SetPagenumberReschedule] = useState(1);
  const [TotalRecordReschedule, SetTotalRecordReschedule] = useState(0);

  const GetMyAllappointmentslist = async (pagenumber) => {

    SetPagenumberAppointment(pagenumber);

    const response = await axiosJWT.get("/appointment/coach-my-appointments?status=all&offset=" + pagenumber + "&limit=" + PerPage + "", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {
      // console.log('resss', response.data)

      SetMyAllappointments(response.data.FinalArray);
      SetTotalRecordAppointment(response.data.TotalRecord);
    }
  }

  const changePageNumberSA = async (pagenumber) => {
    GetMyAllappointmentslist(pagenumber);
  }

  const changePageNumberSR = async (pagenumber) => {
    GetMyRescheduleRequestsList(pagenumber);
  }

  function CheckMeetAccessTime(passdate) {
    var current_time = moment.utc().format('X');
    var appointment_starttime = moment.utc(passdate).format("X");

    if (appointment_starttime > current_time) {
      return true;
    }
    else {
      return false;
    }
  }


  const GetMyRescheduleRequestsList = async (pagenumber) => {

    SetPagenumberReschedule(pagenumber);
    const response = await axiosJWT.get("/appointment/reschedule-appointment-request?range=limit&offset=" + pagenumber + "&limit=" + PerPage + "", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {
      SetMyRescheduleList(response.data.FinalArray);
      SetTotalRecordReschedule(response.data.ListOfRescheduleRequestsTotal);
    }
  }

  const joincall = (e) => {
    var Id = e.target.getAttribute('data-id');
    navigate('/video/' + Id);
  }

  const copy_clipboard = () => {
    setcopyClipboard(true);

    setTimeout(() => {
      setcopyClipboard(false);
    }, 3000);
  }

  const [MeetingSlotsDurationErr, SetMeetingSlotsDurationErr] = useState(false)
  const [MeetingSlotsDuration, SetMeetingSlotsDuration] = useState([])
  const [SelectedMeetingSlotsDuration, SetSelectedMeetingSlotsDuration] = useState('')


  const SelectedMeetingSlotsDurationfn = (value) => {

    SetSelectedMeetingSlotsDuration(value)
    SetSlotsAvailable([]);
    setStartDate('');
    SetSelectedSlot('')
  }

  const Numberbetween = (x, min, max, y) => {



    // console.log('x',x)
    // console.log('min',min)
    // console.log('max',max)
    // console.log(x >= min && x <= max)
    // return x >= min && x <= max;

    const BookedStartTimeStamp = x
    const BookedEndTimeStamp = y;
    const SlotStartTime = min;
    const SlotEndTime = max;


    console.log('BookedStartTimeStamp', BookedStartTimeStamp)
    console.log('BookedEndTimeStamp', BookedEndTimeStamp)
    console.log('SlotStartTime', SlotStartTime)
    console.log('SlotEndTime', SlotEndTime)


    if (SlotStartTime >= BookedStartTimeStamp && SlotEndTime <= BookedEndTimeStamp) {
      return true
    }
    else if (BookedStartTimeStamp >= SlotStartTime && BookedEndTimeStamp <= SlotEndTime) {
      return true
    }
    else {
      return false
    }


  }

  const CheckMeeting = async (e) => {
    const item = e.target.getAttribute('data-id')


    const Response = await axiosJWT.get('/appointment/get-appointment-details?id=' + item + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (Response.status === 200) {
      if (Response.data.meeting_source === 'Zoom meeting') {

        try {
          const ZoomResponse = await axiosJWT.get('/zoom/get-meeting?id=' + item + '', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          if (ZoomResponse.status === 200) {
            window.open(ZoomResponse.data.link, '_blank');

          }
        } catch (err) {
          SetshowfailMeetMsg(err.response.data.msg)
          SetshowfailMeet(true)
        }





      }
      else {
        window.location.href = process.env.REACT_APP_FRONTEND_URL + '/video/' + item
      }
    }
  }

  const [MeetingSource, SetMeetingSource] = useState('')
  const [MeetingSourceErr, SetMeetingSourceErr] = useState(false)
  const [MeetingSourceErrMsg, SetMeetingSourceErrMsg] = useState('')


  const [loader, SetloaderShow] = useState(false);
  const ShowLoader = () => { SetloaderShow(true) }
  const HideLoader = () => { SetloaderShow(false) }

  const CheckMeetingSource = async (value) => {
    SetMeetingSource(value)
    SetSelectedMeetingSlotsDuration('');

    if (value === 'Zoom meeting') {

      const ZoomResponse = await axiosJWT.get('/zoom/check-zoom-enabled', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (ZoomResponse.status === 200) {
        if (ZoomResponse.data.status === false) {
          Setsuccesspoptitle('Failed !');
          Setsuccesspopupmsg('Please connect with zoom under profile screen to book appointment.!');
          SetShowSuccessbook(true);
          SetMeetingSource('');
        }
      }

    }

  }

  const [showfailMeet, SetshowfailMeet] = useState(false)
  const CloseFailPopupMeet = () => {
    SetshowfailMeet(false)
  }
  const [showfailMeetMsg, SetshowfailMeetMsg] = useState('')
  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      {loader === true && <div id="semiTransparenDivLoader"></div>}
      <section className="dashboard">
        <div className="top_coach_label service_over_div coach_plans coach_calendar container">
          <div className='menubox'>
            <div className='tabmenu '>
              <a className="active" data-tab={'calendar'} onClick={(e) => { ChangeTab(e) }}> Calendar</a>
              <a className="" data-tab={'appointments'} onClick={(e) => { ChangeTab(e) }}>Appointments History</a>
              <a className="" id="calendar_caoch_tab_menu" data-tab={'reschedule_requests'} onClick={(e) => { ChangeTab(e) }}>Reschedule requests
                {TotalRecordReschedule > 0 &&
                  <span className="num">{TotalRecordReschedule}</span>}
              </a>
            </div>

          </div>
          {ShowCalendarTab === true &&
            <div>
              <div className='row top_row_calendar'>
                <div className='col col1_top_row_book'>
                  <h4>Appointment Calendar</h4>
                </div>
                <div className='col'>
                  {/* <button className='last_commision_tab_button_add_plan service_top_row_button background_green'>Calendar sync</button> */}
                  <button className='last_commision_tab_button_add_plan service_top_row_button background_green' onClick={TriggerBookAppointmentPopup}>Book appointment</button>
                </div>
              </div>
              <div className='over_all_calendar_div'>
                <div className="coach_calendar_col1">
                  <div className='row top_row_filter_calendar'>
                    <div className='col'>
                      <span className='filter_calendar_label'>Service</span>
                      <Multiselect
                        options={Serviceoptions}
                        hidePlaceholder={true}
                        showArrow={true}
                        showCheckbox={true}
                        avoidHighlightFirstOption={true}
                        displayValue="name" // Property name to display in the dropdown options
                        ref={ServiceDropRef}
                      />
                    </div>
                    <div className='col'>
                      <span className='filter_calendar_label'>Client</span>
                      <Multiselect
                        options={Clientoptions}
                        hidePlaceholder={true}
                        showArrow={true}
                        showCheckbox={true}
                        avoidHighlightFirstOption={true}
                        displayValue="name" // Property name to display in the dropdown options
                        ref={ClientDropRef}
                      />
                    </div>
                    <div className='col'>
                      <span className='filter_calendar_label'>Status</span>
                      <Multiselect
                        options={Statusoptions}
                        hidePlaceholder={true}
                        showArrow={true}
                        showCheckbox={true}
                        avoidHighlightFirstOption={true}
                        displayValue="name" // Property name to display in the dropdown options
                        ref={StatusDropRef}
                      />
                    </div>

                    <div className='col apply_filter_calendar_col'>
                      <button className='apply_filter_calendar' onClick={ApplyFilterSlots}>Apply</button>
                    </div>
                  </div>

                  <DnDCalendar
                    localizer={localizer}
                    defaultView="week"
                    selectable={true}
                    timeslots={1}
                    step={60}
                    onSelectEvent={eventShow}
                    events={events}
                    className="appointments"
                    views={['day', 'week', 'month']}
                    resizableAccessor={() => false}
                    defaultDate={defaultDate}
                  //onNavigate={(date, view) => weekNavigate(date, view)}
                  />
                </div>

                <div className='coach_calendar_col2'>
                  {RequestReschedule.length > 0 &&
                    <span className='upcoming_apointment_top_label_calendar'>Reschedule Requests</span>}
                  {RequestReschedule.length > 0 && RequestReschedule.map((upappointment, index) => (
                    <div className='appointment_calendar_card' key={index}>
                      <div className='c-avatar'>
                        <div className='photo_block'>
                          <img className='table_profile_img calendar_profile_img' src={upappointment.user[0].hasOwnProperty("user_profile_photo") && upappointment.user[0].user_profile_photo !== '' && upappointment.user[0].user_profile_photo !== null ? upappointment.user[0].user_profile_photo : (upappointment.user[0].gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                            (upappointment.user[0].logged_in_status === '1')
                              ? <span className='c-avatar__status list_card_calendar_status'></span>
                              : null
                          }
                        </div>
                        <div className='name_block'>
                          <div className='name_val'>{upappointment.user[0].first_name + ' ' + upappointment.user[0].last_name}</div>
                          <div className='program'>{upappointment.serviceDetails && upappointment.serviceDetails.service_category.category_name}</div>
                        </div>
                      </div>

                      <span className='type'><i className="fa fa-comment-alt td_icon" aria-hidden="true"></i>{upappointment.Reschedule.nutrition_client_appointment.meeting_type}</span>

                      <span className='date'>Booked time :{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.nutrition_client_appointment.start_time)), `MMM dd, yyyy hh:mm a`)}-{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.nutrition_client_appointment.end_time)), `hh:mm a`)}</span>
                      <span className='date'>Rescheduled time :{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.start_time)), `MMM dd, yyyy hh:mm a`)}-{format(parseISO(timetoUtcClientAppointment(upappointment.Reschedule.end_time)), `hh:mm a`)}</span>

                      <span className='type'><i className="fa fa-quote-right td_icon" aria-hidden="true"></i>{upappointment.Reschedule.nutrition_client_appointment.meeting_description}</span>

                      <div style={{ display: "contents" }}>
                        <button className='last_commision_tab_button_add_plan service_top_row_button join_call_button_calendar' data-meeting_id={upappointment.Reschedule.meeting_id} onClick={(e) => { ApproveReschdule(e) }}>Approve</button>
                        <button className='last_commision_tab_button_add_plan service_top_row_button join_call_button_calendar' data-meeting_id={upappointment.Reschedule.meeting_id} onClick={(e) => { DeclineRescheduleRequest(e) }}>Decline</button>
                        <button className='last_commision_tab_button_add_plan service_top_row_button join_call_button_calendar' data-meeting_id={upappointment.Reschedule.meeting_id} onClick={(e) => { CancelRescheduleMeeting(e) }}>Cancel</button>
                      </div>
                    </div>
                  ))}


                  {CoachUpcomingAppointmentList.length > 0 &&
                    <span className='upcoming_apointment_top_label_calendar'>Upcoming appointment</span>}
                  {CoachUpcomingAppointmentList.length > 0 && CoachUpcomingAppointmentList.map((upappointment, index) => (
                    <div className='appointment_calendar_card' key={index}>
                      <div className='c-avatar'>
                        <div className='photo_block'>
                          <img className='table_profile_img calendar_profile_img' src={upappointment.user[0].hasOwnProperty("user_profile_photo") && upappointment.user[0].user_profile_photo !== '' && upappointment.user[0].user_profile_photo !== null ? upappointment.user[0].user_profile_photo : (upappointment.user[0].gender === "MALE" ? male_icon : female_icon)} alt="" />   {
                            (upappointment.user[0].logged_in_status === '1')
                              ? <span className='c-avatar__status list_card_calendar_status'></span>
                              : null
                          }
                        </div>
                        <div className='name_block'>
                          <div className='name_val'>{upappointment.user[0].first_name + ' ' + upappointment.user[0].last_name}</div>
                          <div className='program'>{upappointment.serviceDetails && upappointment.serviceDetails.service_category.category_name}</div>
                        </div>
                      </div>

                      <span className='type'><i className="fa fa-comment-alt td_icon" aria-hidden="true"></i>{upappointment.appointment.meeting_type}</span>
                      <span className='date'><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.appointment.start_time)), `MMM dd, yyyy`)}</span>
                      <span className='date'><i className="fa fa-clock td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.appointment.start_time)), `hh:mm a`)}-{format(parseISO(timetoUtcClientAppointment(upappointment.appointment.end_time)), `hh:mm a`)}</span>
                      <span className='type'><i className="fa fa-quote-right td_icon" aria-hidden="true"></i>{upappointment.appointment.meeting_description}</span>

                      <span className='join_button_appointment_span'>
                        <span className='inner_copy_appointment'>
                          <CopyToClipboard text={process.env.REACT_APP_FRONTEND_URL + "/video/" + upappointment.appointment.id} onCopy={copy_clipboard}>
                            <i className="fa fa-clone" aria-hidden="true"></i>
                          </CopyToClipboard>
                          {copyClipboard === true ? <span>Copied!</span> : null}
                        </span>

                        {/* <button className='last_commision_tab_button_add_plan service_top_row_button join_call_button_calendar' data-id={upappointment.appointment.id} onClick={(e) => { joincall(e) }}>Join call</button> */}

                        <button className='last_commision_tab_button_add_plan service_top_row_button join_call_button_calendar' data-id={upappointment.appointment.id} onClick={(e) => { CheckMeeting(e) }}> Join call</button>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
          {ShowAppointmentsTab === true &&
            <div>
              <div className="upcoming_appointment_table_row">

                <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                  <Thead className="thead">
                    <Tr className="trow">
                      <Th scope="col">Client Name</Th>
                      <Th scope="col">Client Email</Th>
                      <Th scope="col">Booking Date {'&'} time</Th>
                      <Th scope="col">Duration</Th>
                      <Th scope="col">Status</Th>
                      <Th scope="col">Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="tbody manager_appointment_list_admin coach_calendar_table_appointment_history">
                    {MyAllappointments.length > 0 && MyAllappointments.map((list, index) => (
                      <Tr className='trow' key={index}>
                        <Td>
                          <span className='c-avatar'>
                            <div className='photo_block'>
                              <img className='table_profile_img' src={list.user[0].hasOwnProperty("user_profile_photo") && list.user[0].user_profile_photo !== '' && list.user[0].user_profile_photo !== null ? list.user[0].user_profile_photo : (list.user[0].gender === "MALE" ? male_icon : female_icon)} alt="" />
                              {
                                (list.user[0].logged_in_status === '1')
                                  ? <span className='c-avatar__status'></span>
                                  : null
                              }
                            </div>
                            <div className='name_block'>
                              <span className='name_val'>{list.user[0].first_name + ' ' + list.user[0].last_name}</span>
                            </div>
                          </span>
                        </Td>
                        <Td>{list.user[0].email}</Td>
                        <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(list.appointment.start_time)), `MMM dd, yyyy hh:mm a`)}</Td>
                        <Td>{list.appointment.meeting_duration + ' mins'}</Td>
                        <Td>
                          {list.appointment.meeting_status === 0 ? "Not Completed" : null}
                          {list.appointment.meeting_status === 1 ? "Completed" : null}
                          {list.appointment.meeting_status === 2 ? "Cancelled" : null}
                          {list.appointment.meeting_status === 3 ? "Rescheduled" : null}
                        </Td>

                        <Td>
                          {list.appointment.meeting_status === 0 && CheckMeetAccessTime(list.appointment.end_time) === true ?
                            <Button variant="primary keto_link_button action_appointment_button join_appointment_button" data-id={list.appointment.id} onClick={(e) => { CheckMeeting(e) }}>Join</Button>



                            // <Link to={"/video/"+list.appointment.id} target="_blank"><button className='primary keto_link_button action_appointment_button join_appointment_button' data-id={list.appointment.id}>Join</button></Link>

                            : null
                          }
                          {list.appointment.meeting_status === 0 && CheckMeetAccessTime(list.appointment.end_time) === true ?
                            <Button variant="primary keto_link_button action_appointment_button join_appointment_button" data-item={list.appointment.id} onClick={(e) => { TriggerCancelPopup(e) }} >Cancel</Button>
                            : null
                          }
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
              {MyAllappointments.length > 0 &&
                <Pagination
                  activePage={PagenumberAppointment}
                  itemsCountPerPage={PerPage}
                  totalItemsCount={TotalRecordAppointment}
                  onChange={changePageNumberSA.bind(this)}
                  prevPageText="<"
                  nextPageText=">"
                  hideFirstLastPages={true}
                  linkClassPrev="pagination_prev"
                  linkClassNext="pagination_next"
                  linkClass="pagenumbers grayfont"
                />
              }
            </div>
          }

          {
            ShowRescheduleTab === true &&
            <div className='row'>
              <div className="row upcoming_appointment_table_row">
                <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                  <Thead className="thead">
                    <Tr className="trow">
                      <Th scope="col">Client Name</Th>
                      <Th scope="col">Client Email</Th>
                      <Th scope="col">Booking Date {'&'} time</Th>
                      <Th scope="col">Rescheduled Date time</Th>
                      <Th scope="col">Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="tbody manager_appointment_list_admin coach_calendar_table_appointment_history">
                    {MyRescheduleList.length > 0 && MyRescheduleList.map((list, index) => (
                      <Tr className='trow' key={index}>
                        <Td>
                          <span className='c-avatar'>
                            <div className='photo_block'>
                              <img className='table_profile_img' src={list.user[0].hasOwnProperty("user_profile_photo") && list.user[0].user_profile_photo !== '' && list.user[0].user_profile_photo !== null ? list.user[0].user_profile_photo : (list.user[0].gender === "MALE" ? male_icon : female_icon)} alt="" />
                              {
                                (list.user[0].logged_in_status === '1')
                                  ? <span className='c-avatar__status'></span>
                                  : null
                              }
                            </div>
                            <div className='name_block'>
                              <span className='name_val'>{list.user[0].first_name + ' ' + list.user[0].last_name}</span>
                            </div>
                          </span>
                        </Td>
                        <Td>{list.user[0].email}</Td>
                        <Td>{format(parseISO(timetoUtcClientAppointment(list.Reschedule.nutrition_client_appointment.start_time)), `MMM dd, yyyy hh:mm a`)} </Td>
                        <Td>{format(parseISO(timetoUtcClientAppointment(list.Reschedule.start_time)), `MMM dd, yyyy hh:mm a`)} </Td>
                        <Td>
                          <i className="fa fa-check td_icon action visible" title="Accept" aria-hidden="true" data-meeting_id={list.Reschedule.meeting_id} onClick={(e) => { ApproveReschdule(e) }}></i>
                          <i className="fa fa-times td_icon action" data-meeting_id={list.Reschedule.meeting_id} aria-hidden="true" title="Decline" onClick={(e) => { DeclineRescheduleRequest(e) }}></i>
                          <i className="fa fa-ban td_icon action" data-meeting_id={list.Reschedule.meeting_id} aria-hidden="true" title="Cancel" onClick={(e) => { CancelRescheduleMeeting(e) }}></i>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                {MyRescheduleList.length > 0 &&
                  <div>
                    <Pagination
                      activePage={PagenumberReschedule}
                      itemsCountPerPage={PerPage}
                      totalItemsCount={TotalRecordReschedule}
                      onChange={changePageNumberSR.bind(this)}
                      prevPageText="<"
                      nextPageText=">"
                      hideFirstLastPages={true}
                      linkClassPrev="pagination_prev"
                      linkClassNext="pagination_next"
                      linkClass="pagenumbers grayfont"
                    />
                  </div>
                }
              </div>
            </div>
          }
        </div>

        <Modal show={showBookappointment} onHide={handleCloseBookAppointment} className='commission_popup create_appointment_popup'>
          <Modal.Header closeButton>
            <Modal.Title className='create_appointment_h4'>New Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className='row input_fields_coach_create_appointment'>
                <label className='commission_inner_popup_label required'>Select type</label><br />
                <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '90%' }}>

                  <option value="individual">Individual</option>

                </select>
              </div> */}
            <div className='row input_fields_coach_create_appointment'>
              <label className='commission_inner_popup_label'>Meeting Description</label><br />
              <input type='text' className='add_category_inner_input meeting_description_coach_input' onChange={(e) => { SetSelectedMeetingDescription(e.target.value) }}></input>
            </div>
            <div className='row input_fields_coach_create_appointment'>
              <label className='commission_inner_popup_label required'>Select Client</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '90%' }} onChange={(e) => { SetSelectedClient(e.target.value) }}  {...(MeetingClientErr === true ? { id: 'err_border' } : {})}>
                <option value="">Select</option>
                {ListOfclient.length > 0 && ListOfclient.map((users, index) => (
                  <option value={users.id} key={index}>{users.first_name + ' ' + users.last_name}</option>
                ))}
              </select>
              {
                MeetingClientErr === true && <span className='err'>{MeetingClientErrMsg}</span>
              }
            </div>

            <div className='row input_fields_coach_create_appointment'>
              <label className='commission_inner_popup_label required'>Select Apppintment Type</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '90%' }} onChange={(e) => { SetSelectedType(e.target.value) }} {...(MeetingTypeErr === true ? { id: 'err_border' } : {})}>
                <option value="">Select</option>
                <option value="Initial Interview">Initial interview</option>
                <option value="Follow Up">Follow Up</option>
              </select>
              {
                MeetingTypeErr === true && <span className='err'>{MeetingTypeErrMsg}</span>
              }
            </div>

            <div className='row input_fields_coach_create_appointment'>
              <label className='commission_inner_popup_label required'>Book with:</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' onChange={(e) => { CheckMeetingSource(e.target.value) }} style={{ float: 'left', marginTop: '6px', width: '90%' }}>
                <option value="">Select meeting source</option>
                <option value="Quickblox">Fitpal</option>
                <option value="Zoom meeting">Zoom Meet</option>

              </select>
              {
                MeetingSourceErr === true && <span className='err'>{MeetingSourceErrMsg}</span>
              }
            </div>

            <div className='row input_fields_coach_create_appointment'>
              <label className='commission_inner_popup_label required'>Select Slots Duration</label><br />
              <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' onChange={(e) => { SelectedMeetingSlotsDurationfn(e.target.value) }} style={{ float: 'left', marginTop: '6px', width: '90%' }} {...(MeetingSlotsDurationErr === true ? { id: 'err_border' } : {})}>
                <option value="">Select</option>

                {
                  MeetingSlotsDuration.length > 0 && MeetingSlotsDuration.map((slotsduration, index) => (
                    MeetingSource === "Zoom meeting" && slotsduration.value !== 60 ?
                      (<option value={slotsduration.value}>{slotsduration.label}</option>)
                      : 
                      MeetingSource === "Quickblox" ? 
                      (<option value={slotsduration.value}>{slotsduration.label}</option>): null
                  ))}
              </select>
              {
                MeetingSlotsDurationErr === true && <span className="err">Please select slots duration</span>
              }
            </div>

            <div className='row input_fields_coach_create_appointment input_fields_coach_create_appointment_date over_div_fif'>
              <label className='commission_inner_popup_label required select_date_time_appoint_label'>Select Date {'&'} Time</label><br />

              <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                e.preventDefault();
              }} onChange={(date) => ChangedDate(date)} minDate={MinDate} ref={datepicker1Ref}  {...(MeetingDateErr === true ? { id: 'err_border' } : {})} />

              <span className="calendar_icon_div fif_icon calendar_icon_coach_appointment">
                <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
              </span>

              {noSlot === true &&
                <span className="err">Sorry! No slot available. Try some other date!</span>
              }
              {
                MeetingDateErr === true && <span className='err'>{MeetingDateErrMsg}</span>
              }
            </div>

            {SlotsAvailable.length > 0 &&
              <div className="row small_btm_book_apoinment">
                <label className='commission_inner_popup_label slots_available_label'>Slots available for this date:</label><br />
                {SlotsAvailable.length > 0 && SlotsAvailable.map((slots, index) => (
                  <span className="slots_list_by_client" data-slot={slots} onClick={(e) => { SlotsSelection(e) }} key={index}>{formatslots(slots)} <i className="fa fa-check hide_show_tick_book_a" aria-hidden="true"></i> </span>
                ))}
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className='close_button_commission' onClick={handleCloseBookAppointment}>
              Reset
            </Button>
            <Button variant="primary" className='save_button_commission background_green' onClick={BookAppointmentByClient}>
              Book appointment
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbook} onHide={HandleCloseSuccessbook}>
          <Modal.Header closeButton>
            <Modal.Title>{successpoptitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{successpopupmsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbook}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookApprove} onHide={HandleCloseSuccessbookApprove}>
          <Modal.Header closeButton>
            <Modal.Title>Failed!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{ApproveErrmsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={HandleCloseSuccessbookApprove}>
              Close
            </Button>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookApprove}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose} className='coach_appointment_modal_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Appointment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <label>Meet with :</label>{scheduleDetails.clientname}
            </div>
            <div className="row">
              <label>Meet Date :</label>{scheduleDetails.date}
            </div>
            <div className="row">
              <label>Start Time :</label> <span className='timeval'> {scheduleDetails.start} </span>
            </div>
            <div className="row">
              <label>End Time :</label> <span className='timeval'>{scheduleDetails.end}</span>
            </div>
            <div className="row">
              <label>Description :</label>{scheduleDetails.desc}
            </div>
            {scheduleDetails.meetstatus === 0 ?
              <div className="row">
                <div style={{ paddingLeft: "0" }}>
                  <label style={{ paddingLeft: "10px" }}>Meet Link :</label>
                  {/* <a className='joinlink' href onClick={joinmeeting} data-item={scheduleDetails.id}>Join Meet</a> */}
                  <span className='join_meet' data-id={scheduleDetails.id} onClick={(e) => { CheckMeeting(e) }}>Join meet</span>
                  {/* <Link to={"/video/"+scheduleDetails.id} target="_blank">Join Meet</Link> */}
                </div>
              </div>
              : null}
            {scheduleDetails.meetstatus > 0 ?
              <div className="row">
                <label>Status :</label> <span className='timeval'>{scheduleDetails.meetstatus === 1 ? "Completed" : (scheduleDetails.meetstatus === 2 ? "Cancelled" : "Rescheduled")}</span>
              </div>
              :
              null}
            <br /><br />
            {/* <div className="row">
                    <input className='cancel_btn' type="button" onClick={(e) => { TriggerRescheduleMeetingPopup(e) }} value="Reschedule Appointment" data-item={scheduleDetails.id} data-meeting_appointment_id={scheduleDetails.id} />
                  </div> */}
            {moment(scheduleDetails.date) > moment() && scheduleDetails.meetstatus !== 2 ?
              <div className="row">
                <input className='cancel_btn' type="button" onClick={(e) => { TriggerCancelPopup(e) }} value="Cancel Appointment" data-item={scheduleDetails.id} />
              </div>
              : null}
          </Modal.Body>
          <Modal.Footer>
            {/*<Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                  OK
                </Button>*/}
          </Modal.Footer>
        </Modal>


        <Modal show={ShowSuccessbookcancel} onHide={HandleCloseSuccessbookcancel} className="alert_book_popup">
          <Modal.Header closeButton>
            <Modal.Title>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body><span className="bold">Are you sure want to cancel this meeting?</span></Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancel}>
              No
            </Button>
            <Button variant="primary keto_link_button" data-meeting_appointment_id={MeetingCancelId} onClick={(e) => { CancelMeeting(e) }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={ShowSuccessbookcancelsuccess} onHide={HandleCloseSuccessbookcancelsucess} className="alert_book_popup">
          <Modal.Header closeButton>
            <Modal.Title>Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body><span className="bold">You have successfully cancelled this appointment.!</span></Modal.Body>
          <Modal.Footer>
            <Button variant="primary keto_link_button" onClick={HandleCloseSuccessbookcancelsucess}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showfailMeet} onHide={CloseFailPopupMeet} className="confirm_delete">
          <Modal.Header closeButton>
            <Modal.Title>Failed!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showfailMeetMsg}
          </Modal.Body>
          <Modal.Footer className="display_block">
            <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailPopupMeet}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </section>
    </div>
  )
}

export default AppointmentCalendar
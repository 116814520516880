import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Datepicker from 'react-date-picker';
import axios from "axios";
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";

const CoachMealPlan=(props)=>{

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [PerPage,SetPerPage]=useState(50);
    const [TotalRecord,SetTotalRecord]=useState(0);
    const [PageNumber,SetPageNumber]=useState(0);
    const [MealPlanList,SetMealPlanList]=useState([]);
    const [NutritionId,SetNutritionId]=useState('')
    const [Searchvalue,SetSearchvalue]=useState('')
    const GetMealPlans=async(pagenumber,NutritionId,search='')=>{

        SetPageNumber(parseFloat(pagenumber)+1)

        const response=await axiosJWT.get("/meal-plan?coach_id="+NutritionId+"&offset="+pagenumber+"&limit="+PerPage+"&search="+search+"", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(response.data!==null)
        {
            if(response.data.plans)
            {
                SetMealPlanList(response.data.plans)
                SetTotalRecord(response.data.totalcount)
            }
        }
      
    }

    const changePageNumberS=(pagenumber)=>{

      GetMealPlans(parseFloat(pagenumber-1),NutritionId,Searchvalue)
      
    }

 
    const SearchByName=(value)=>{
        SetSearchvalue(value)
        GetMealPlans(0,NutritionId,value)

    }

    useEffect(()=>{


        if(props.ClientId)
        {
            SetNutritionId(props.ClientId)
            GetMealPlans(0,props.ClientId,'')
        }


    },[props])


    return (
        <div className="admin_coach_dashboard admin_coach_imported_recipe">
             <div className="rightmenu">
            <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => SearchByName(event.target.value)} defaultValue={Searchvalue} />

            </div>
            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Plan Name</Th>
            <Th scope="col">Plan description</Th>
            <Th scope="col">Program</Th>
            <Th scope="col">Days</Th>
            <Th scope="col">Calories(%)</Th>
            <Th scope="col">Carbs(%)</Th>
            <Th scope="col">Fats(%)</Th>
            <Th scope="col">Proteins(%)</Th>
           </Tr>
        </Thead>
       <Tbody className="tbody">
       {MealPlanList.map((plans, index) => (  
        <Tr className="trow" data-id={plans.id}>
         <Td>{plans.meal_plan_name}</Td>
         <Td>{plans.meal_plan_description}</Td>
         <Td>{plans.service_category.category_name}</Td>
         <Td>{plans.days}</Td>
         <Td>{plans.calories}</Td>
         <Td>{plans.carbs}</Td>
         <Td>{plans.fats}</Td>
         <Td>{plans.proteins}</Td>
        </Tr>
                ))}
       </Tbody>
        </Table>

        {MealPlanList.length > 0 &&
                                    <div>
                                        <Pagination
                                            activePage={PageNumber}
                                            itemsCountPerPage={PerPage}
                                            totalItemsCount={TotalRecord}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }
            
        </div>
    )

}
export default CoachMealPlan
import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import { ReactFormGenerator } from 'react-form-builder2';
import { useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { format, parseISO } from 'date-fns';
import ReactToPrint from 'react-to-print';

const ViewSubmittedQuestionnaire = () => {


    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const navigate = useNavigate();
    const [JSON_ANSWERS, SetJSON_ANSWERS] = useState();
    const [JSON_QUESTION_DATA, SetJSON_QUESTION_DATA] = useState()
    const [ShowViewDetail, SetShowViewDetail] = useState(false)
    const closedetail = () => { SetShowViewDetail(false); openlist() }

    const [Showlistview, SetShowlistview] = useState(true)
    const closeList = () => { SetShowlistview(false) }
    const openlist = () => { SetShowlistview(true) }


    const [PagenumberList, SetPagenumberList] = useState(1)
    const [RecordPerPage, SetRecordPerPage] = useState(10)
    const [TotalRecordList, SetTotalRecordList] = useState(0)
    const [ListOfQuestionnaire, SetListOfQuestionnaire] = useState([])
    const [ClientList, SetClientList] = useState([])

    const [FormName,SetFormName]=useState('')
    const [FormSubmittedBy,SetFormSubmittedBy]=useState('')
    const [FormSubmittedOn,SetFormSubmittedOn]=useState('')
    const componentRef = useRef();
    

    const GetSubmittedListQuestionnaire = async (pagenumber) => {

        const response = await axiosJWT.get('form/user/get-submitted-list?offset=' + pagenumber + '&limit=' + RecordPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


         
            SetTotalRecordList(response.data.totalcount)
            SetListOfQuestionnaire(response.data.List)
            SetClientList(response.data.UsersList)

        })


    }

    const GetClientData = (id, key) => {



        var found = ClientList.filter(e => e.id === id);
        if (found.length > 0) {

          
            if (key === 'email') {
                return found[0].email
            }
            if (key === 'role') {
                return found[0].role
            }
            if (key === 'name') {
                return found[0].first_name + ' ' + found[0].last_name
            }
            if (key === 'pic') {

                if (found[0].user_profile_photo !== null && found[0].user_profile_photo !== '') {

                    return  found[0].user_profile_photo

                }
                else {

                    if (found[0].gender === 'MALE') {
                        return male_icon
                    }
                    else {
                        return female_icon
                    }


                }





            }
        }




    }

    const viewSubmittedDetail = async (e) => {

        var List_id = parseInt(e.target.getAttribute('data-list_id'))
        var found = ListOfQuestionnaire.filter(e => e.id === List_id);

        var submit_id = parseInt(e.target.getAttribute('data-submit_id'))


        const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + submit_id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        var ArrayFinal = []

        var QuestionnaireId = DetailResponse.data.intake_qn_id
        const myArray = QuestionnaireId.split(",");

        const Formobj = JSON.parse(found[0].submitted_data)

        if(DetailResponse.data.intake_form_name)
        {
            SetFormName(DetailResponse.data.intake_form_name)
        }
        SetFormSubmittedBy(GetClientData(found[0].invited_to, 'name')+' ('+GetClientData(found[0].invited_to, 'role')+')')
        SetFormSubmittedOn(found[0].updatedAt)

        SetJSON_ANSWERS(Formobj)

    

        myArray.forEach((value, index, array) => {

            const QuestionnaireResponse = axiosJWT.get('form/form-detail?id=' + value + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((QuestionnaireResponse) => {


                QuestionnaireResponse.data.forEach((value, index, array) => {
                    ArrayFinal.push(value)


                })

                setTimeout(() => {

                    SetJSON_QUESTION_DATA(ArrayFinal)
                    SetShowViewDetail(true)
                    closeList()

                    // SetPreviewFormData(ArrayFinal) 
                    // Setshowspreviewintake(true)
                }, 100);




            })



        })


        


    }

    const changePageNumberS = (pagenumber) => {
        SetPagenumberList(pagenumber)
        GetSubmittedListQuestionnaire(pagenumber)
    }

    useEffect(() => {

        GetSubmittedListQuestionnaire(1)

    }, [])

    const backList = () => {



        window.location.href = "" + window.location.origin + "/coach/questionnaire";

    }


    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"} >
            <section className="dashboard">

                <div className="container">


                    <div className="row">
                        <div className="col_but">
                            <button className=" top_billing_coach_payment" onClick={backList}>Back</button>
                        </div>
                    </div>

                    {Showlistview === true &&

                        <div className="row">
{ListOfQuestionnaire.length ===0 && <span>There are no submitted forms!..</span>}

                            {ListOfQuestionnaire.length > 0 &&

                                <Table className="table is-striped is-fullwidth">
                                    <Thead className="thead">
                                        <Tr>
                                            <Th>Client Name</Th>
                                            <Th>Client Email</Th>
                                            <Th>Questionnaire name</Th>
                                            <Th>Action</Th>

                                        </Tr>
                                    </Thead>
                                    <Tbody className="tbody">
                                        {ListOfQuestionnaire.length > 0 && ListOfQuestionnaire.map((questionnaire, index) => (

                                            <Tr className='trow'>
                                                <Td><img className='table_profile_img' alt="BigCo Inc. logo" src={GetClientData(questionnaire.invited_to, 'pic')} /><span className="">{GetClientData(questionnaire.invited_to, 'name')}</span></Td>
                                                <Td >{GetClientData(questionnaire.invited_to, 'email')}</Td>
                                                <Td >{questionnaire.intake_form.intake_form_name}</Td>
                                                <Td ><i className="fa fa-eye view_questionnaire" data-list_id={questionnaire.id} data-submit_id={questionnaire.form_id} aria-hidden="true" onClick={(e) => { viewSubmittedDetail(e) }}></i></Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            }
                        </div>
                    }

                    {Showlistview === true && ListOfQuestionnaire.length > 0 &&
                        <div className="row">
                            {TotalRecordList ?
                                <Pagination
                                    activePage={PagenumberList}
                                    itemsCountPerPage={RecordPerPage}
                                    totalItemsCount={TotalRecordList}
                                    onChange={changePageNumberS.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                                : null}
                        </div>

                    }

                    {ShowViewDetail === true &&

                        <div className="row">

                            <div className="row top_questionnaire_row_client">

                                <span className="top_questionnaire_row_client_span_x client_close_x"><i className="fa fa-times" aria-hidden="true" onClick={closedetail}></i></span>
                            </div>

                            <div className="intake_form_export_div">
    <ReactToPrint
                                    trigger={() => <button className="intake_form_export_button">Export PDF</button>}
                                    content={() => componentRef.current}
                                />
    </div>

    <div className="intake_form_overall" ref={componentRef} >

    <div className="intake_form_export_div_form_details">
    <span>Form Name : {FormName}</span>
    <span>Submitted By : {FormSubmittedBy}</span>
    <span>Submitted On : {FormSubmittedOn!=='' && format(parseISO(FormSubmittedOn), `PP`)}</span>
</div>


    <ReactFormGenerator


        answer_data={JSON_ANSWERS}

        data={JSON_QUESTION_DATA}
        hide_actions={true}
        read_only={true}
    />

    </div>


                        </div>
                    }
                </div>



            </section>
        </div>
    )
}

export default ViewSubmittedQuestionnaire
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import axios from "axios";

const WithingsCheckoutForm = (props) => {
  const [ShowPop, SetShowPop] = useState(props.show);
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [Name, SetName] = useState("");
  const [Email, SetEmail] = useState("");
  const [Gender, SetGender] = useState("");
  const [IsMaleChecked, SetIsMaleChecked] = useState(false);
  const [IsFeMaleChecked, SetIsFeMaleChecked] = useState(false);
  const [Address, SetAddress] = useState("");
  const [ZipCode,SetZipCode]=useState('')
  const [countryList, setcountryList] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [userCountry, setuserCountry] = useState("");
  const [userState, setuserState] = useState("");
  const [userCity, setuserCity] = useState("");
  const [cityList, setcityList] = useState([]);
  const [Err,SetErr]=useState(false)
  const [ErrMsg,SetErrMsg]=useState('')

  const country_load = async (countrydefault='') => {
    var countries_list = Country.getAllCountries();

    var countries_list_array = [];

    countries_list.forEach((element) => {
      countries_list_array.push({
        label: element.name,
        value: element.isoCode,
      });
    });

    setcountryList(countries_list_array);

if(countrydefault!=='' && countrydefault!==null)
{
    setuserCountry(JSON.parse(countrydefault))
}


  };

  const handleCloseswithings = () => {
    SetShowPop(false);
  };

  const filterstate = async (e) => {
    setuserCountry(e);

    var state_list = State.getAllStates();

    var state_list_filtered = state_list.filter(
      (state) => state.countryCode === e.value
    );

    var state_list_array = [];

    state_list_filtered.forEach((element) => {
      state_list_array.push({ label: element.name, value: element.isoCode });
    });

    if (state_list_array.length > 0) {
      setstateList(state_list_array);
      setuserState("");
      setuserCity("");
      setcityList([]);
    } else {
      setuserState(e);
      setuserCity(e);
      setstateList([]);
      setcityList([]);
    }
  };

  const filtercity = async (e) => {
    setuserState(e);

    var city_list = City.getAllCities();

    var city_list_filtered = city_list.filter(
      (city) =>
        city.countryCode === userCountry.value && city.stateCode === e.value
    );

    var city_list_array = [];

    city_list_filtered.forEach((element) => {
      city_list_array.push({ label: element.name, value: element.name });
    });

    if (city_list_array.length > 0) {
      setcityList(city_list_array);
      setuserCity("");
    } else {
      setuserCity(e);
      setcityList([]);
    }
  };

  const onchangeCity = async (e) => {

    setuserCity(e);
}

const SubmitWithingsDeviceOrder=async()=>{

    SetErr(false)

    if(Address==='' || ZipCode==='' || userCountry==='' || userState==='' || userCity==='')
    {
        SetErr(true)
        SetErrMsg('Please fill out all fields')

    }
    else{

      try{

        props.ShowLoader()

        const OrderResponse = await axiosJWT.post('/withings/create-user-order',
        {
            'address': Address,
            'zip': ZipCode,
            'country':userCountry,
            'state':userState,
            'city':userCity
    
    
    
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(OrderResponse.status===200)
        {
          props.HideLoader()
          SetWithingsOrderHeading('Withings Order Success!')
          SetWithingsOrderMsg('Your withings device has been ordered successfully.You can track your order details under profile menu.')
          SetshowAddc(true)
          SetShowPop(false)
        }

      }catch(err)
      {
        props.HideLoader()
        SetWithingsOrderHeading('Withings Order Failed!')
        SetWithingsOrderMsg(err.response.data.msg)
        SetshowAddc(true)
        SetShowPop(false)

      }

       
    

    }


}

const [showAddc,SetshowAddc]=useState(false)
const handleClosesAddc=()=>{
  SetshowAddc(false)
}
const [WithingsOrderHeading,SetWithingsOrderHeading]=useState('')
const [WithingsOrderMsg,SetWithingsOrderMsg]=useState('')

  useEffect(() => {
    // if (
    //   props.userdata.first_name !== undefined &&
    //   props.userdata.last_name !== undefined
    // ) {
    //   SetName(props.userdata.first_name + " " + props.userdata.last_name);
    // }
    // if (props.userdata.email !== undefined) {
    //   SetEmail(props.userdata.email);
    // }
    // if (props.userdata.gender !== undefined) {
    //   SetGender(props.userdata.gender);
    //   if (
    //     props.userdata.gender === "MALE" ||
    //     props.userdata.gender === "male"
    //   ) {
    //     SetIsMaleChecked(true);
    //   } else {
    //     SetIsMaleChecked(false);
    //   }
    //   if (
    //     props.userdata.gender === "FEMALE" ||
    //     props.userdata.gender === "female"
    //   ) {
    //     SetIsFeMaleChecked(true);
    //   } else {
    //     SetIsFeMaleChecked(false);
    //   }
    // }
    // if (props.userdata.user_address !== undefined) {
    //   SetAddress(props.userdata.user_address);
    // }

    country_load(props.userdata.user_country);
  }, []);

  return (
    <div>
      <Modal
        show={ShowPop}
        onHide={handleCloseswithings}
        className="clientdashboard_weight_update confirm_delete withings_device_form"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Withings devices delivery details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="withings_input_row">
            <div className="withings_label_div required">Name:</div>
            <div className="withings_input_div">
              <input
                defaultValue={Name}
                onChange={(e) => {
                  SetName(e.target.value);
                }}
                type={"text"}
              />
            </div>
          </div>
          <div className="withings_input_row">
            <div className="withings_label_div required">Email:</div>
            <div className="withings_input_div">
              <input
                defaultValue={Email}
                onChange={(e) => {
                  SetEmail(e.target.value);
                }}
                type={"text"}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Gender:</div>
            <div className="withings_input_div">
              <input
                type={"radio"}
                name={"gender"}
                value={"MALE"}
                defaultChecked={IsMaleChecked}
                onChange={(e) => {
                  SetGender(e.target.value);
                }}
              />
              <label className="radio_label">Male</label>
              <input
                type={"radio"}
                name={"gender"}
                value={"FEMALE"}
                onChange={(e) => {
                  SetGender(e.target.value);
                }}
                defaultChecked={IsFeMaleChecked}
              />
              <label className="radio_label">Female</label>
            </div>
          </div> */}

          <div className="withings_input_row">
            <div className="withings_label_div required">Address:</div>
            <div className="withings_input_div">
              <input
                defaultValue={Address}
                onChange={(e) => {
                  SetAddress(e.target.value);
                }}
                type={"text"}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Country:</div>
            <div className="withings_input_div">
              <Select
                options={countryList}
                onChange={filterstate}
                className="user_country"
                value={userCountry}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">State:</div>
            <div className="withings_input_div">
              <Select
                options={stateList}
                onChange={filtercity}
                className="user_state"
                value={userState}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">City:</div>
            <div className="withings_input_div">
              <Select
                options={cityList}
                onChange={onchangeCity}
                className="user_city"
                value={userCity}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Zip Code:</div>
            <div className="withings_input_div">
              <input
                defaultValue={ZipCode}
                onChange={(e) => {
                    SetZipCode(e.target.value);
                }}
                type={"text"}
              />
            </div>
          </div>
          {
            Err===true &&  <div className='err'>
            {ErrMsg}
          </div>
          }
         
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            className="modal_close_button"
            variant="secondary"
            onClick={handleCloseswithings}
          >
            Cancel
          </Button>
          <Button variant="primary" className="modal_action_button float_right"  onClick={SubmitWithingsDeviceOrder}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showAddc} onHide={handleClosesAddc} className="clientdashboard_weight_update confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{WithingsOrderHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div className="row" style={{padding:'10px'}}>
                        {WithingsOrderMsg}
                        </div>



                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesAddc}>
                            Close
                        </Button>
                     
                    </Modal.Footer>
                </Modal>

    </div>
  );
};
export default WithingsCheckoutForm;

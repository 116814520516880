import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertFromRaw, EditorState, convertFromHTML, convertToRaw, ContentFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import axios from "axios";
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import Select from 'react-select';
import { ReactFormGenerator } from 'react-form-builder2';
import male_icon from '../../../images/male.png'
import female_icon from '../../../images/female.png'
import ReactToPrint from 'react-to-print';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DocumentsUpload from "../../AdminComponents/Documents/documents";

const CoachDocuments = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [TopButtonLabel, SetTopButtonLabel] = useState('Create New');
    const [ServiceAgreementTab, SetServiceAgreementTab] = useState(false);
    const [Intakeformtab, SetIntakeformtab] = useState(true);
    const [DirectoriesTab,SetDirectoriesTab]=useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [SaveagreementButtonLabel, SetSaveagreementButtonLabel] = useState('');
    const [serviceagreementUpdateId, SetserviceagreementUpdateId] = useState('');
    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const [editorData, seteditorData] = useState('');
    const [AgreementName, SetAgreementName] = useState('');
    const [DocumentPerPage, SetDocumentPerPage] = useState(10);
    const [DocumentPageNo, SetDocumentPageNo] = useState(1);
    const [TotalDocumentRecord, SetTotalDocumentRecord] = useState(1);
    const [ServiceAgreementList, SetServiceAgreementList] = useState([]);
    const [addbuttonshow, Setaddbuttonshow] = useState(false);
    const [addintakebuttonshow, Setaddintakebuttonshow] = useState(true);

    const [questionnaireselectoptions, setquestionnaireselectoptions] = useState([]);
    const [SelectedQuestions, SetSelectedQuestions] = useState('');
    const [IntakeFormName, SetIntakeFormName] = useState('');
    const [questionnaireselectoptionsdefault, Setquestionnaireselectoptionsdefault] = useState([]);

    const [showsaddintake, setshowsaddintake] = useState(false);
    const handleClosesaddintake = () => { setshowsaddintake(false) }

    const [showsaddintakesucess, Setshowsaddintakesucess] = useState(false);
    const handleClosesaddintakesuccess = () => { Setshowsaddintakesucess(false) }
    const [intakeformmsg, Setintakeformmsg] = useState('');

    const [ListOfIntake, SetListOfIntake] = useState([]);
    const [IntakePageNo, SetIntakePageNo] = useState(1);
    const [TotalIntakeRecord, SetTotalIntakeRecord] = useState(0);

    const [poppuplabelintake, Setpoppuplabelintake] = useState('');
    const [poppupbuttonlabelintake, Setpoppupbuttonlabelintake] = useState('');
    const [poppupbuttonidintake, Setpoppupbuttonidintake] = useState('');

    const [showsdeleteintake, Setshowsdeleteintake] = useState(false);
    const handleClosesdeleteintake = () => { Setshowsdeleteintake(false) }
    const [IntakeDeleteFormid, SetIntakeDeleteFormid] = useState('');

    const [PreviewFormData, SetPreviewFormData] = useState([]);

    const [showspreviewintake, Setshowspreviewintake] = useState(false);
    const handleClosespreviewintake = () => { Setshowspreviewintake(false) }

    const [showssendintake, Setshowssendintake] = useState(false);
    const handleClosessendintake = () => { Setshowssendintake(false) }

    const [SendFormId, SetSendFormId] = useState('');
    const [Clientuserlist, SetClientuserlist] = useState([]);

    const [PickedClients, SetPickedClients] = useState('');

    const [IntakeFormNameErr, SetIntakeFormNameErr] = useState(false);
    const [IntakeFormNameErrMsg, SetIntakeFormNameErrMsg] = useState('');

    const [IntakeFormQuestionnaireErr, SetIntakeFormQuestionnaireErr] = useState(false);
    const [IntakeFormQuestionnaireErrMsg, SetIntakeFormQuestionnaireErrMsg] = useState('');

    const [ServiceAgreementNameErr, SetServiceAgreementNameErr] = useState(false);
    const [ServiceAgreementNameErrMsg, SetServiceAgreementNameErrMsg] = useState('');

    const [ServiceAgreementContentErr, SetServiceAgreementContentErr] = useState(false);
    const [ServiceAgreementContentErrMsg, SetServiceAgreementContentErrMsg] = useState('');


    const DeleteIntake = async () => {
        // /intake-form/:id'

        const response = await axiosJWT.delete('/documents/intake-form/' + IntakeDeleteFormid + '',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleClosesdeleteintake();
            GetListofIntakes(1);
        }
    }

    const triggerdeleteintakepopup = (e) => {
        var DeleteId = e.target.getAttribute('data-id');
        SetIntakeDeleteFormid(DeleteId);
        Setshowsdeleteintake(true);
    }


    const ChangeTab = (e) => {
        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab');
        SetSignedAgreementTab(false);
        SetDirectoriesTab(false);


        if (tab === 'service_agreement') {
            SetIntakeformtab(false);
            SetServiceAgreementTab(true);
            GetServiceAgreement(1);
            Setaddbuttonshow(true);
            Setaddintakebuttonshow(false);
        }
        else if (tab === 'signed_agreement') {
            SetIntakeformtab(false);
            SetServiceAgreementTab(false);
            Setaddbuttonshow(false);
            Setaddintakebuttonshow(false);
            SetSignedAgreementTab(true);
            GetSignedAgreementsByclient(1);
        }
        else if (tab==='directories')
        {
            Setaddbuttonshow(false);
            SetDirectoriesTab(true);
        }
        else {
            SetServiceAgreementTab(false);
            Setaddbuttonshow(false);
            Setaddintakebuttonshow(true);
            GetListofIntakes(1);
            SetIntakeformtab(true);
        }
    }

    const Addserviceagreement = async () => {

        SetServiceAgreementNameErr(false);
        SetServiceAgreementContentErr(false);

        if (serviceagreementUpdateId === '') {

            var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));
            var err = false;

            if (editorData === '') {
                SetServiceAgreementContentErrMsg('Please enter agreement content');
                SetServiceAgreementContentErr(true);
                err = true;
            }

            if (editorData !== '') {
                if (editorData[0].text === '') {
                    SetServiceAgreementNameErrMsg('Please enter agreement content');
                    SetServiceAgreementContentErr(true);
                    err = true;
                }
            }

            if (AgreementName === '') {
                SetServiceAgreementNameErr(true);
                SetServiceAgreementNameErrMsg('Please enter agreement name');
                err = true;
            }

            if (err === true) {
                return false;
            }

            const response = await axiosJWT.post('/documents/service-agreement',
                {
                    service_name: AgreementName,
                    service_template: editor_content,
                    template_for: 'service_agreement'
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                handleClose();
                GetServiceAgreement(DocumentPageNo);
            }
        }
        else {

            var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));
            var err = false;

            if (editorData !== '') {
                if (editorData[0].text === '') {
                    SetServiceAgreementNameErrMsg('Please enter agreement content');
                    SetServiceAgreementContentErr(true);
                    err = true;
                }
            }

            if (AgreementName === '') {
                SetServiceAgreementNameErr(true);
                SetServiceAgreementNameErrMsg('Please enter agreement name');
                err = true;
            }

            if (err === true) {
                return false;
            }

            const response = await axiosJWT.put('/documents/service-agreement',
                {
                    service_name: AgreementName,
                    service_template: editor_content,
                    template_for: 'service_agreement',
                    service_id: serviceagreementUpdateId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                handleClose();
                GetServiceAgreement(DocumentPageNo);
            }
        }
    }

    const triggerServiceAgreementpopup = () => {
        SetSaveagreementButtonLabel('Add Agreement');
        SetAgreementName('');
        SetserviceagreementUpdateId('');
        seteditorvalue(EditorState.createEmpty());
        setShow(true);
    }

    const editorChange = (data) => {
        seteditorData(data.blocks);      
    }

    const GetServiceAgreement = async (pagenumber) => {
        SetDocumentPageNo(pagenumber);
        const response = await axiosJWT.get('documents/service-agreement?offset=' + pagenumber + '&limit=' + DocumentPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetTotalDocumentRecord(response.data.total_count);
            SetServiceAgreementList(response.data.data);
        });
    }

    const changePageNumberS = async (pagenumber) => {
        GetServiceAgreement(pagenumber);
        SetDocumentPageNo(pagenumber);
    }

    const changePageNumberI = async (pagenumber) => {
        GetListofIntakes(pagenumber);
        SetIntakePageNo(pagenumber);
    }

    const DeleteServiceAgreement = async (e) => {
        var DeleteID = e.target.getAttribute('data-id');
        SetAgreementDeleteErr(false);
        try {

            const response = await axiosJWT.delete('documents/service-agreement/' + DeleteID + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                GetServiceAgreement(DocumentPageNo);
                handleCloses();
            });
        } catch (err) {
            SetAgreementDeleteErr(true);
            SetAgreementDeleteErrMsg(err.response.data.message);
        }
    }

    const [shows, setShows] = useState(false);

    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);

    const [showp, setShowp] = useState(false);

    const handleClosep = () => setShowp(false);
    const handleShowp = () => setShowp(true);
    const [ServiceDeleteId, SetServiceDeleteId] = useState('')

    const TriggerDeleteServiceAgreement = (e) => {
        var DeleteID = e.target.getAttribute('data-id');
        SetServiceDeleteId(DeleteID);
        handleShows();
    }

    const EditServiceAgreement = async (e) => {
        var EditID = e.target.getAttribute('data-id');

        const response = await axiosJWT.get('documents/service-agreement/detail?service_id=' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {      

            SetSaveagreementButtonLabel('Update Agreement');
            SetAgreementName(response.data.service_agreement_name);
            SetserviceagreementUpdateId(EditID);

            if (response.data.service_agreement_template !== '') {
                var editor_content = ContentState.createFromBlockArray(convertFromHTML(response.data.service_agreement_template));
                seteditorvalue(EditorState.createWithContent(editor_content));
            }
            else {
                seteditorvalue(EditorState.createEmpty());
            }

            setShow(true);
        });
    }

    const [previewhtml, Setpreviewhtml] = useState('');

    const preview = async (e) => {

        var EditID = e.target.getAttribute('data-id')
        const response = await axiosJWT.get('documents/service-agreement/detail?service_id=' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            Setpreviewhtml(response.data.service_agreement_template);
        });

        handleShowp();
    }

    const TriggerAddIntakeFormPopup = async () => {

        SetIntakeFormNameErr(false);
        SetIntakeFormQuestionnaireErr(false);

        setshowsaddintake(true);
        Setpoppuplabelintake('Create Intake form');
        Setpoppupbuttonlabelintake('Create');
        Setpoppupbuttonidintake('');
        SetIntakeFormName('');
        SetSelectedQuestions('');
        Setquestionnaireselectoptionsdefault([]);
        var MyformsPUsh = [];

        const response = await axiosJWT.get('/form', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data.data !== null) {

            response.data.data.forEach((value, index, array) => {
                var obj = {};
                obj["value"] = value.id;
                obj["label"] = value.form_name;
                MyformsPUsh.push(obj);
            });

            setquestionnaireselectoptions(MyformsPUsh);
        }
    }

    const triggereditpopup = async (e) => {

        SetIntakeFormNameErr(false);
        SetIntakeFormQuestionnaireErr(false);

        var EditId = e.target.getAttribute('data-id');

        Setpoppuplabelintake('Edit Intake form');
        Setpoppupbuttonlabelintake('Update');
        Setpoppupbuttonidintake(EditId);

        var MyformsPUsh = [];
        const response = await axiosJWT.get('/form', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.data !== null) {
            response.data.data.forEach((value, index, array) => {
                var obj = {};
                obj["value"] = value.id;
                obj["label"] = value.form_name;
                MyformsPUsh.push(obj);
            });

            setquestionnaireselectoptions(MyformsPUsh);
        }

        const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        SetIntakeFormName(DetailResponse.data.intake_form_name);

        var DefaultSelectedArray = [];

        var AlreadySelectedId = DetailResponse.data.intake_qn_id;
        SetSelectedQuestions(AlreadySelectedId);
        const myArray = AlreadySelectedId.split(",");

        myArray.forEach((value, index, array) => {
            var found = MyformsPUsh.filter(e => e.value === parseInt(value));

            if (found.length > 0) {
                var obj = {};
                obj["value"] = found[0].value;
                obj["label"] = found[0].label;
                DefaultSelectedArray.push(obj);
            }
        });

        Setquestionnaireselectoptionsdefault(DefaultSelectedArray);

        setshowsaddintake(true);
    }


    const SetSelectedQuestionnaire = (e) => {
        var PushSelectedValues = [];

        e.forEach((value, index, array) => {
            PushSelectedValues.push(value.value);
        });

        var commavalues = PushSelectedValues.toString();
        SetSelectedQuestions(commavalues);
    }

    const SetSelectedClients = (e) => {

        var PushSelectedValues = [];

        e.forEach((value, index, array) => {
            PushSelectedValues.push(value.value);
        });

        var commavalues = PushSelectedValues.toString();
        SetPickedClients(commavalues);
    }

    const Sendform = async () => {

        if (PickedClients !== null && PickedClients !== '') {

            const response = await axiosJWT.post('/form/invite-form-user/intake',
                {
                    form_id: SendFormId,
                    invited_to: PickedClients
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                Setintakeformmsg('Successfully invited clients to submit the form');
                Setshowsaddintakesucess(true);
                handleClosessendintake();
            }
        }
        else {
            Setintakeformmsg('Please fill out all fields');
            Setshowsaddintakesucess(true);
        }
    }

    const CreateIntakeForm = async () => {

        SetIntakeFormNameErr(false);
        SetIntakeFormQuestionnaireErr(false);

        if (IntakeFormName !== null && SelectedQuestions !== null && IntakeFormName !== '' && SelectedQuestions !== '') {

            if (poppupbuttonidintake === '') {
                const response = await axiosJWT.post('/documents/intake-form',
                    {
                        intake_form_name: IntakeFormName,
                        intake_qn_id: SelectedQuestions
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setintakeformmsg('You have successfully created an intake form');
                    setshowsaddintake(false);
                    Setshowsaddintakesucess(true);
                    GetListofIntakes(1);
                }
            }
            else {

                const response = await axiosJWT.put('/documents/intake-form',
                    {
                        intake_form_name: IntakeFormName,
                        intake_qn_id: SelectedQuestions,
                        id: poppupbuttonidintake
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    Setintakeformmsg('You have successfully Updated an intake form');
                    setshowsaddintake(false);
                    Setshowsaddintakesucess(true);
                    GetListofIntakes(1);
                }
            }
        }
        else {

            var err = false;

            if (IntakeFormName === '') {
                SetIntakeFormNameErr(true);
                SetIntakeFormNameErrMsg('Please enter intake form name');
                err = true;
            }
            if (SelectedQuestions === '') {
                SetIntakeFormQuestionnaireErr(true);
                SetIntakeFormQuestionnaireErrMsg('Please select questionnaire');
                err = true;
            }
            if (err === true) {
                return false;
            }


            // Setintakeformmsg('Please fill out all fields')
            // Setshowsaddintakesucess(true)
        }
    }

    const GetListofIntakes = async (pagenumber) => {

        SetIntakePageNo(pagenumber);

        const response = await axiosJWT.get('documents/intake-form?offset=' + pagenumber + '&limit=' + DocumentPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data !== null) {
            if (response.data.list !== null) {
                SetListOfIntake(response.data.list);
            }
            SetTotalIntakeRecord(response.data.total_count);
        }
    }

    const previewIntakeForm = async (e) => {

        var ArrayFinal = [];
        var PreviewId = e.target.getAttribute('data-id');

        const DetailResponse = await axiosJWT.get('documents/intake-form/detail?intake_form_id=' + PreviewId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var QuestionnaireId = DetailResponse.data.intake_qn_id;        
        const myArray = QuestionnaireId.split(",");

        myArray.forEach((value, index, array) => {
            const QuestionnaireResponse = axiosJWT.get('form/form-detail?id=' + value + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((QuestionnaireResponse) => {
                QuestionnaireResponse.data.forEach((value, index, array) => {
                    ArrayFinal.push(value);
                });

                setTimeout(() => {
                    SetPreviewFormData(ArrayFinal)
                    Setshowspreviewintake(true)
                }, 100);
            });
        });
    }

    const SendIntakeForm = (e) => {
        var SendId = e.target.getAttribute('data-id');
        SetSendFormId(SendId);
        SetPickedClients('');
        Setshowssendintake(true);
        GetClientList();
    }

    const GetClientList = async () => {

        const response = await axiosJWT.get('/nutrition/allclient', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            var ClientList = [];

            if (response.data !== null) {
                response.data.forEach((value, index, array) => {
                    var obj = {};
                    obj["value"] = value.id;
                    obj["label"] = value.first_name + ' ' + value.last_name;
                    ClientList.push(obj);
                });

                SetClientuserlist(ClientList);
            }
        });
    }

    const [SendAgreementId, SetSendAgreementId] = useState('');
    const [showssendAgreement, SetshowssendAgreement] = useState(false);
    const handleClosessendAgreement = () => {
        SetshowssendAgreement(false);
    }

    const SendAgreement = (e) => {
        SetAgreementSendErr(false);
        const AgreementId = e.target.getAttribute('data-id');
        SetSendAgreementId(AgreementId);
        SetPickedClients('');
        GetClientList();
        SetshowssendAgreement(true);
    }

    const [showsagreementsucess, Setshowsagreementsucess] = useState(false);
    const handleClosesagreementsuccess = () => {
        Setshowsagreementsucess(false);
    }

    const [ServiceAgreementMsg, SetServiceAgreementMsg] = useState('');
    const [AgreementSendErr, SetAgreementSendErr] = useState(false);
    const [AgreementSendErrMsg, SetAgreementSendErrMsg] = useState('');

    const [AgreementDeleteErr, SetAgreementDeleteErr] = useState(false);
    const [AgreementDeleteErrMsg, SetAgreementDeleteErrMsg] = useState('');

    const [SignedAgreementTab, SetSignedAgreementTab] = useState(false);

    const SendAgreementFinal = async () => {
        SetAgreementSendErr(false);

        if (PickedClients === '') {
            SetAgreementSendErrMsg('Please select clients');
            SetAgreementSendErr(true);
        }
        else {
            const response = await axiosJWT.post('/documents/send-service-agreement',
                {
                    agreement_id: SendAgreementId,
                    clients: PickedClients
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                SetshowssendAgreement(false);
                SetServiceAgreementMsg(response.data.message);
                Setshowsagreementsucess(true);
            }
        }
    }

    const [TotalRecordSignedAgreement, SetTotalRecordSignedAgreement] = useState(0);
    const [SignedAgreementList, SetSignedAgreementList] = useState([]);
    const [AgreementPageno, SetAgreementPageno] = useState(1);

    const GetSignedAgreementsByclient = async (pagenumber) => {
        SetAgreementPageno(pagenumber);

        const response = await axiosJWT.get('/documents/list-submitted-service-agreement-coach?offset=' + pagenumber + '&limit=' + DocumentPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetSignedAgreementList(response.data.FinalResult);
        SetTotalRecordSignedAgreement(response.data.Totalcount);
    }

    const changePageNumberSA = (pagenumber) => {
        GetSignedAgreementsByclient(pagenumber);
    }

    const ViewSignedAgreement = async (e) => {
        var Id = e.target.getAttribute('data-id');
        const response = await axiosJWT.get('documents/list-submit-service-agreement-detail?id=' + Id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetSubmittedHtml(response.data.list.signed_document_data);
        SetSignedSrc(response.data.list.signed_data);
        Setshowps(true);
    }

    const [showps, Setshowps] = useState(false);

    const handleCloseps = () => {
        Setshowps(false);
    }

    const [SubmittedHtml, SetSubmittedHtml] = useState('');
    const [SignedSrc, SetSignedSrc] = useState('');
    const componentRef = useRef();

    useEffect(() => {
        document.title = 'Coach-Documents';
        GetListofIntakes(1);
    }, [])

    return (
        <div>
        {
            DirectoriesTab===false &&
       
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="coachdocuments container">
                    <div className="row top_coach_label ">
                        <div className="row agreement_top coach_plans">

                            {/* <span className="top_coach_span caoch_doc_span active" data-tab={'intake'} onClick={(e)=>{ChangeTab(e)}} >Intake Forms</span>
       <span className="top_coach_span caoch_doc_span" data-tab={'service_agreement'} onClick={(e)=>{ChangeTab(e)}}>Service aggreement</span> */}

                            <div className='menubox'>
                                <div className='tabmenu'>                                   
                                    <a className="active" data-tab={'intake'} onClick={(e) => { ChangeTab(e) }}>Intake Forms</a>
                                    <a className="" data-tab={'service_agreement'} onClick={(e) => { ChangeTab(e) }}>Service Agreements</a>
                                    <a className="" data-tab={'signed_agreement'} onClick={(e) => { ChangeTab(e) }}>Signed Agreements</a>  
                                    <a className="" data-tab={'directories'} onClick={(e) => { ChangeTab(e) }}>Directory</a>                                  
                                </div>
                                {addbuttonshow === true &&
                                    <button className='last_commision_tab_button_add_plan'
                                        onClick={triggerServiceAgreementpopup}>{TopButtonLabel}</button>}

                                {addintakebuttonshow === true &&
                                    <button className='last_commision_tab_button_add_plan'
                                        onClick={TriggerAddIntakeFormPopup} >{TopButtonLabel}</button>}
                            </div>
                        </div>

                        {ServiceAgreementTab === true &&
                            <div className="row serviceagreement_table">
                                {ServiceAgreementList.length > 0 &&
                                    <Table className="table is-striped is-fullwidth">
                                        <Thead className="thead">
                                            <Tr>
                                                <Th>Service</Th>
                                                <Th>Last edit</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                            {ServiceAgreementList.length > 0 && ServiceAgreementList.map((serviceagreement, index) => (
                                                <Tr className='trow'>
                                                    <Td>{serviceagreement.service_agreement_name}</Td>
                                                    <Td >{format(parseISO(serviceagreement.updatedAt), `PP`)}</Td>
                                                    <Td ><i className="fa fa-edit edit_icon pointer_eff icon_tag_size" data-id={serviceagreement.id} onClick={(e) => { EditServiceAgreement(e) }} ></i><i className="fa fa-trash pointer_eff icon_tag_size" data-id={serviceagreement.id} onClick={(e) => { TriggerDeleteServiceAgreement(e) }}></i><i className="fa fa-eye pointer_eff icon_tag_size eye_icon" aria-hidden="true" data-id={serviceagreement.id} onClick={(e) => { preview(e) }}></i>
                                                        <i data-id={serviceagreement.id} className='fa fa-send billing_edit_i pointer_eff send_qu_icon' onClick={(e) => { SendAgreement(e) }}></i>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                }
                                <div>
                                    {TotalDocumentRecord ?
                                        <Pagination
                                            activePage={DocumentPageNo}
                                            itemsCountPerPage={DocumentPerPage}
                                            totalItemsCount={TotalDocumentRecord}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                        : null}
                                </div>

                                <Modal show={show} onHide={handleClose} className='commission_popup add_billing_popup add_service_agreement_popup add_agreement_popup'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{SaveagreementButtonLabel}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label className='commission_inner_popup_label required'>Agreement Name</label><br />
                                        <input type='text' className="add_category_inner_input" defaultValue={AgreementName} onChange={(e) => { SetAgreementName(e.target.value) }}  {...(ServiceAgreementNameErr===true ? {id: 'err_border'} : {})} />
                                        <div>
                                            {ServiceAgreementNameErr === true &&
                                                <span className="err service_err_field">  {ServiceAgreementNameErrMsg}</span>
                                            }
                                        </div>
                                        <br />
                                        <label className='commission_inner_popup_label required'>Agreement Content</label><br />
                                        <Editor wrapperClassName="texteditor" editorState={editorvalue} onEditorStateChange={seteditorvalue}
                                            onContentStateChange={editorChange} handlePastedText={() => false} />
                                        <div>
                                            {ServiceAgreementContentErr === true &&
                                                <span className="err service_err_field">  {ServiceAgreementContentErrMsg}</span>
                                            }
                                        </div>
                                        <div className="service_agreement_available_tags">
                                            <span>{'[CLIENT_NAME]=> TO Use Client Name In service Agreement'}</span>
                                            <span>{'[COACH_NAME]=> TO Use  Coach Name In service Agreement'}</span>
                                            <span>{'[CLIENT_EMAIL]=> TO Use Client Email In service Agreement'}</span>
                                            <span>{'[COACH_EMAIL]=> TO Use  Coach Email In service Agreement'}</span>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                                            Back
                                        </Button>
                                        <Button variant="primary" className='save_button_commission float_right' data-update={serviceagreementUpdateId}
                                            onClick={Addserviceagreement}  >
                                            {SaveagreementButtonLabel}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <Modal show={shows} onHide={handleCloses} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete service agreement</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure want to delete this service agreement?
                                        {
                                            AgreementDeleteErr === true &&
                                            <p className="err">{AgreementDeleteErrMsg}</p>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleCloses}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" data-id={ServiceDeleteId} onClick={(e) => { DeleteServiceAgreement(e) }}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <Modal show={showp} onHide={handleClosep} className='add_billing_popup preview_service_agreement_popup'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{'Preview agreement'}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div dangerouslySetInnerHTML={{ __html: previewhtml }} />
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" className='close_button_commission' onClick={handleClosep} >
                                            Back
                                        </Button>
                                        <Button variant="primary" className='save_button_commission float_right' onClick={handleClosep}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showssendAgreement} onHide={handleClosessendAgreement} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>List of clients</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label className='commission_inner_popup_label'>Select Clients</label><br />
                                        <Select options={Clientuserlist} isMulti={true} onChange={(e) => { SetSelectedClients(e) }} />
                                        {AgreementSendErr == true &&
                                            <span className="err">{AgreementSendErrMsg}</span>}
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosessendAgreement}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" onClick={SendAgreementFinal}>
                                            Send
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <Modal show={showsagreementsucess} onHide={handleClosesagreementsuccess} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Service Agreement</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {ServiceAgreementMsg}
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosesagreementsuccess}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>}

                        {Intakeformtab === true &&
                            <div className="row serviceagreement_table">
                                <Modal show={showsaddintake} onHide={handleClosesaddintake} className="coach_document_delete confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{poppuplabelintake}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label className='commission_inner_popup_label required'>Intake form Name</label><br />
                                        <input type='text' className="add_category_inner_input add_intake_form_input" onChange={(e) => { SetIntakeFormName(e.target.value) }} defaultValue={IntakeFormName}  {...(IntakeFormNameErr===true ? {id: 'err_border'} : {})} />
                                        <div>
                                            {IntakeFormNameErr === true &&
                                                <span className="err service_err_field">  {IntakeFormNameErrMsg}</span>
                                            }
                                        </div>
                                        <label className='commission_inner_popup_label required'>Select Questionnaire</label><br />
                                        <Select options={questionnaireselectoptions} isMulti={true} onChange={(e) => { SetSelectedQuestionnaire(e) }} defaultValue={questionnaireselectoptionsdefault} {...(IntakeFormQuestionnaireErr===true ? {id: 'err_border'} : {})} />
                                        <div>
                                            {IntakeFormQuestionnaireErr === true &&
                                                <span className="err service_err_field">  {IntakeFormQuestionnaireErrMsg}</span>
                                            }
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesaddintake}>
                                            Cancel
                                        </Button>
                                        <Button className="modal_action_button float_right" variant="primary" onClick={CreateIntakeForm} data-update_id={poppupbuttonidintake}>
                                            {poppupbuttonlabelintake}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>



                                <Modal show={showsaddintakesucess} onHide={handleClosesaddintakesuccess} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Intake Form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {intakeformmsg}
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosesaddintakesuccess}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showsdeleteintake} onHide={handleClosesdeleteintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Intake form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure want to delete this Intake Form?<br /><strong>{'Note : If you delete this form you may also lose the submitted forms by client with this intake form'}</strong></Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosesdeleteintake}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" data-id={IntakeDeleteFormid} onClick={(e) => { DeleteIntake(e) }}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                {ListOfIntake.length > 0 &&
                                    <Table className="table is-striped is-fullwidth">
                                        <Thead className="thead">
                                            <Tr>
                                                <Th>Intake form name</Th>
                                                <Th>Last edit</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                            {ListOfIntake.length > 0 && ListOfIntake.map((list, index) => (
                                                <Tr className='trow'>
                                                    <Td>{list.intake_form_name}</Td>
                                                    <Td >{format(parseISO(list.updatedAt), `PP`)}</Td>
                                                    <Td ><i className="fa fa-edit edit_icon pointer_eff icon_tag_size" data-id={list.id} onClick={(e) => { triggereditpopup(e) }} ></i><i className="fa fa-trash pointer_eff icon_tag_size" data-id={list.id} onClick={(e) => { triggerdeleteintakepopup(e) }}></i><i className="fa fa-eye pointer_eff icon_tag_size eye_icon" data-id={list.id} aria-hidden="true" onClick={(e) => { previewIntakeForm(e) }}></i>
                                                        <i data-id={list.id} className='fa fa-send billing_edit_i pointer_eff send_qu_icon' onClick={(e) => { SendIntakeForm(e) }}></i>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                }

                                <div>
                                    {TotalIntakeRecord ?
                                        <Pagination
                                            activePage={IntakePageNo}
                                            itemsCountPerPage={DocumentPerPage}
                                            totalItemsCount={TotalIntakeRecord}
                                            onChange={changePageNumberI.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                        : null}
                                </div>


                                <Modal show={showspreviewintake} onHide={handleClosespreviewintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Preview form</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <ReactFormGenerator data={PreviewFormData}
                                            hide_actions={true}
                                            read_only={true}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosespreviewintake}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <Modal show={showssendintake} onHide={handleClosessendintake} className="confirm_delete">
                                    <Modal.Header closeButton>
                                        <Modal.Title>List of clients</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label className='commission_inner_popup_label'>Select Clients</label><br />
                                        <Select options={Clientuserlist} isMulti={true} onChange={(e) => { SetSelectedClients(e) }} />
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" onClick={handleClosessendintake}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" className="float_right" onClick={Sendform}>
                                            Send
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        }
                        {SignedAgreementTab === true &&
                            <div className="row">
                                <div className="row">
                                    <Table className='table is-striped is-fullwidth'>
                                        <Thead className='thead'>
                                            <Tr className="trow">
                                                <Th className='row_name'>Client Name</Th>
                                                <Th className='row_name'>Client Email</Th>
                                                <Th className='row_name'>Service Agreement Name</Th>
                                                <Th className='row_name'>Action</Th>
                                            </Tr>
                                        </Thead>
                                        {SignedAgreementList.length > 0 && SignedAgreementList.map((AgreementList, index) => (
                                            <Tbody className="tbody">
                                                <Tr className="trow" key={AgreementList.agreement.id}>
                                                    <Td><img className='table_profile_img' alt="BigCo Inc. logo" src={AgreementList.client[0].user_profile_photo ?  AgreementList.client[0].user_profile_photo : (AgreementList.client[0].gender === 'MALE' ? male_icon : female_icon)} />{AgreementList.client[0].first_name + ' ' + AgreementList.client[0].last_name}</Td>
                                                    <Td>{AgreementList.client[0].email}</Td>
                                                    <Td>{AgreementList.agreement.service_agreement.service_agreement_name}</Td>
                                                    <Td><i className="fa fa-eye pointer_eff icon_tag_size eye_icon" aria-hidden="true" data-id={AgreementList.agreement.id} onClick={ViewSignedAgreement}></i></Td>
                                                </Tr>
                                            </Tbody>
                                        ))}
                                    </Table>
                                </div>
                                {SignedAgreementList.length > 0 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={AgreementPageno}
                                            itemsCountPerPage={DocumentPerPage}
                                            totalItemsCount={TotalRecordSignedAgreement}
                                            onChange={changePageNumberSA.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }


                                <Modal show={showps} onHide={handleCloseps} className='add_billing_popup preview_service_agreement_popup'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{'Agreement'}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body ref={componentRef}>
                                        <div dangerouslySetInnerHTML={{ __html: SubmittedHtml }} />
                                        <div>
                                            <img src={SignedSrc} />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="display_block">
                                        <Button variant="secondary" className='close_button_commission' onClick={handleCloseps} >
                                            Back
                                        </Button>
                                        <ReactToPrint
                                            trigger={() => <button className='withdraw_but export_doc_button'>Print PDF</button>}
                                            content={() => componentRef.current}
                                        />
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        }
                    </div>
                </div>
            </section>           
        </div>
    }
    {
        DirectoriesTab===true &&  <DocumentsUpload SetDirectoriesTab={SetDirectoriesTab} GetListofIntakes={GetListofIntakes} Setaddintakebuttonshow={Setaddintakebuttonshow} SetIntakeformtab={SetIntakeformtab}/>            
    }
      </div>
    )
}

export default CoachDocuments
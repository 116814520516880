import axios from "axios";
import React, { useEffect, useState } from "react";

const ViewAllExercise = (props) => {



    const [VideoList, SetVideoList] = useState([])
    let PropsArray = {};
    if (props.CategoriesedVideos !== undefined) {

        PropsArray = props.CategoriesedVideos
    }



    const ReturnToList = () => {

        props.SetExerciseViewallShow(false)
    }

    useEffect(() => {

        if (props.ViewAllSelectedCategory !== undefined) {

            var clickedArray = []
            clickedArray[0] = props.ViewAllSelectedCategory
            var chks = document.querySelectorAll(".category_inputs[value='" + props.ViewAllSelectedCategory + "']");
            chks[0].checked = true


        }
        if (props.CategoriesedVideos !== undefined) {

            GetFilteredVideoList(clickedArray, '', [])

        }

    }, [])

    const GetFilteredVideoList = async (category = [], duration = '', difficulty = []) => {


        console.log('prospsarray', PropsArray)

        var FilterArray = PropsArray
        var DiffDummyObj = {};
        var DurDummyObj = {}

        if (category.length > 0) {
            var CategoryBasedVideo = {};
            category.forEach(element => {



                CategoryBasedVideo[element] = FilterArray[element]
            });
            FilterArray = CategoryBasedVideo
        }
        if (difficulty.length > 0) {
            let Keys = Object.keys(FilterArray);


            Keys.forEach(element => {

                var LoopArray = FilterArray[element];
                var CatName = element
                DiffDummyObj[CatName] = [];
                LoopArray.forEach((element, index) => {
                    if (difficulty.includes(FilterArray[CatName][index].difficulty) === true) {

                        var length = DiffDummyObj[CatName].length
                        DiffDummyObj[CatName][length] = element


                    }
                });


            });
            FilterArray = DiffDummyObj
        }
        if (duration !== '') {
            let Keys = Object.keys(FilterArray);

            Keys.forEach(element => {
                var LoopArray = FilterArray[element];
                var CatName = element
                DurDummyObj[CatName] = [];
                LoopArray.forEach((element, index) => {

                    if (duration === 'less_20_mins') {
                        if (FilterArray[CatName][index].videoDuration <= 1200000) {

                            var length = DurDummyObj[CatName].length
                            DurDummyObj[CatName][length] = element


                        }
                    } else if (duration === '20_40_mins') {
                        if (FilterArray[CatName][index].videoDuration >= 1200000 && FilterArray[CatName][index].videoDuration <= 2400000) {

                            var length = DurDummyObj[CatName].length
                            DurDummyObj[CatName][length] = element


                        }

                    }
                    else if (duration === 'more_40_mins') {

                        if (FilterArray[CatName][index].videoDuration >= 2400000) {

                            var length = DurDummyObj[CatName].length
                            DurDummyObj[CatName][length] = element


                        }


                    }
                });


            });




            FilterArray = DurDummyObj

        }


        console.log('filterarray', FilterArray)

        SetVideoList(FilterArray)


    }

    const FormatSeconds = (milliseconds = '') => {

        var minutes = Math.floor(milliseconds / 60000);
        // var seconds = ((milliseconds % 60000) / 1000).toFixed(0);
        return minutes
    }
    const FilterShow = () => {

        if (document.getElementById('over_lay_filter_id').className.includes("over_lay_filter_show")) {
            document.getElementById('over_lay_filter_id').className = ''
            document.getElementById('over_lay_filter_id').className += 'over_lay_filter over_lay_filter_none';
        }
        else {
            document.getElementById('over_lay_filter_id').className = ''
            document.getElementById('over_lay_filter_id').className += 'over_lay_filter over_lay_filter_show';
        }


    }

    const ResetFilter = () => {
        document.querySelectorAll('.category_inputs').forEach(el => el.checked = false);
        document.querySelectorAll('.difficulty_inputs').forEach(el => el.checked = false);
        document.querySelectorAll('.duration_inputs').forEach(el => el.checked = false);
        SetVideoList([])
    }
    const ApplyFilter = () => {
        var CategoryArray = [];
        var DifficultyArray = [];
        var Duration = '';
        var checkboxes = document.querySelectorAll('.category_inputs:checked')
        var Diffcheckboxes = document.querySelectorAll('.difficulty_inputs:checked')

        if (document.querySelectorAll('.duration_inputs:checked').length > 0) {
            Duration = document.querySelectorAll('.duration_inputs:checked')[0].value
        }


        for (var i = 0; i < checkboxes.length; i++) {
            CategoryArray.push(checkboxes[i].value)
        }
        for (var i = 0; i < Diffcheckboxes.length; i++) {
            DifficultyArray.push(Diffcheckboxes[i].value)
        }
        GetFilteredVideoList(CategoryArray, Duration, DifficultyArray)

    }

    const FormatTitle = (string) => {

        if (string.length > 30) {
            var str = string.substring(0, 30) + '...'
            return str;

        }
        else {
            return string;
        }



    }


    return (
        <div className="container view_all_videos_container">
            <div className="top_div_row">
                <button className="btn btn-primary" onClick={ReturnToList}>{'< Return to list'}</button>
                <button className="btn btn-primary right_but" onClick={FilterShow}>Filter</button>
            </div>
            <div className="over_lay_filter_parent">
                <div className="over_lay_filter over_lay_filter_none" id="over_lay_filter_id">
                    <div className="filter_types">
                        <div className="category_div">
                            <div>Category</div>
                            <div className="category_list">
                                {Object.keys(props.CategoriesedVideos).map((item, i) => (
                                    <p className="p_tag"><input className="video_input category_inputs" type={'checkbox'} value={item} />{item}</p>
                                ))
                                }
                            </div>
                        </div>
                        <div className="difficulty">
                            <div>Difficulty</div>
                            <div>
                                <input className="video_input difficulty_inputs" type={'checkbox'} value={'Easy'} /><label>Easy</label>
                            </div>
                            <div>
                                <input className="video_input difficulty_inputs" type={'checkbox'} value={'Standard'} /><label>Standard</label>
                            </div>
                            <div>
                                <input className="video_input difficulty_inputs" type={'checkbox'} value={'Advanced'} /><label>Advanced</label>
                            </div>
                            <div>
                                <input className="video_input difficulty_inputs" type={'checkbox'} value={'Flex'} /><label>Flex</label>
                            </div>
                        </div>
                        <div>
                            <div>Duration</div>
                            <div>
                                <input className="video_input duration_inputs" name="duration_inputs" type={'radio'} value={'less_20_mins'} /><label>{'< 20 Minutes'}</label>
                            </div>
                            <div>
                                <input className="video_input duration_inputs" name="duration_inputs" type={'radio'} value={'20_40_mins'} /><label>{'20-40 Minutes'}</label>
                            </div>
                            <div>
                                <input className="video_input duration_inputs" name="duration_inputs" type={'radio'} value={'more_40_mins'} /><label>{'+40 Minutes'}</label>
                            </div>
                        </div>
                    </div>
                    <div className="button_div">
                        <button className="btn btn-primary" onClick={ResetFilter}>Reset Filters</button>
                        <button className="btn btn-primary right" onClick={ApplyFilter}>Apply Filters</button>
                    </div>
                </div>
            </div>

            {Object.keys(VideoList).map((item, i) => (
                <div>
                    <div className="head_row">
                        <span className="cat_title">{item}</span>
                        <span className="video_count">{VideoList[item].length} videos</span>
                    </div>
                    <div className="video_all_list">
                        {VideoList[item].length > 0 && VideoList[item].map((videos, index) => (
                            <div className="video_list" onClick={(e) => { props.VideoDetail(e) }} data-id={videos.videoId}><img src={videos.thumbnailUrl} className="fitness_thumbnail" /><div className="video_info"><span className="title">{FormatTitle(videos.videoTitle)}</span><span className="mins"><i className="fa fa-clock-o minsicon" aria-hidden="true"></i>{FormatSeconds(videos.videoDuration)} mins</span></div></div>
                        ))}
                    </div>
                </div>
            ))
            }
        </div>
    )
}
export default ViewAllExercise
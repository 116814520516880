import { React ,createRef, useState,useRef, useEffect   } from 'react';
// RCE CSS
import 'react-chat-elements/dist/main.css'
// MessageBox component
import { MessageBox } from 'react-chat-elements'
import { MessageList,Input,Button   } from 'react-chat-elements'
import axios from 'axios';
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";



function AdminChatBeluga(props) {

    console.log('VisitDetailsData',props.VisitDetailsData)
	const token = localStorage.getItem("token");
	const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
	const axiosJWT = axios.create();
	const InputFileRef = useRef(null);
	const [loader,Setloader]=useState(false)

    const messageListReferance = createRef();
    const inputReferance = createRef();
    const [inputValue,SetinputValue]=useState('')
	const [VisitLogsChat,SetVisitLogsChat]=useState([])

    const CheckInput=(e)=>{
        SetinputValue(e.target.value)
     

    }

	const SendMsgText=async()=>{


	

		if(inputValue!=='' && inputValue!==null)
		{
            try{
                Setloader(true)
				const SendBlugaReponse=await axiosJWT.post('beluga/send-admin-chat', {
				
				
					msg: inputValue,
					visitId:props.VisitDetailsData.visitId,
				    masterId:props.VisitDetailsData.masterId,
					sent_by:'admin'
				},{
					headers: {
						Authorization: `Bearer ${token}`
					}
				});

				console.log('SendBlugaReponse',SendBlugaReponse)
				SetinputValue('')
				GetVisitChatLog()
				Setloader(false)
			}catch(err)
			{
				Setloader(false)
            console.log(err)
			}
		

   
		}
	}

	const handleFileChange = async(event) => {
		const files = event.target.files;
		var formData = new FormData();

		formData.append('type','media')
		formData.append('file',files[0])
		formData.append('visitId',props.VisitDetailsData.visitId)
		formData.append('masterId',props.VisitDetailsData.masterId)
		formData.append('client_id',props.VisitDetailsData.client_id)
		formData.append('sent_by','client');

		try{
			Setloader(true)
			const UploadResponse= await axios.post("/beluga/send-client-media", formData, {
    
				headers: {
					Authorization: `Bearer ${token}`,
				  },
			  },
			  );

			  console.log('UploadResponse',UploadResponse)
			  Setloader(false)
			  InputFileRef.current.value = '';
			  GetVisitChatLog()

		}catch(err)
		{
		Setloader(false)
         console.log(err)
		}




		console.log(files);
	
	  };

	  const GetVisitChatLog=async()=>{
    
		try{
            Setloader(true)
			const GetResponse = await axiosJWT.get(
				"beluga/get-all-visit-admin-chat?visitId="+props.VisitDetailsData.visitId+"",
				{
				  headers: {
					Authorization: `Bearer ${token}`,
				  },
				}
			  );

			  Setloader(false)

			var VisitLogArray=[];
			

			  if(GetResponse.data){
               var DataArray=GetResponse.data
			

			   DataArray.forEach(element => {

				var Object={};
			
				var ConvertedDate=format(
					parseISO(
					  timetoUtcClientAppointment(
						element.sent_date
					  )
					),
					`MMM dd, yyyy hh:mm:ss a`
				  )

				Object.date=ConvertedDate
				
				

				if(element.sent_by==='admin')
				{
					Object.position='right';
                    Object.title='Customer Service'
					
				}
				if(element.sent_by==='belugaadmin')
				{
					Object.position='left';
                    Object.title='Beluga Admin'
					
				}
				
					Object.type='text';
					Object.text=element.content;
				
				

			
				VisitLogArray.push(Object)
			   });



			  }
	
			  SetVisitLogsChat(VisitLogArray)
	

		}catch(err)
		{
			Setloader(false)
			console.log(err)
		}

	
	  }
	  useEffect(()=>{
		GetVisitChatLog()
	  },[])

	  function timetoUtcClientAppointment(passdate) {
		var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");
	
		var cutoffString = pushstartime; // in utc
		var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
		var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);
	
		var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
	
		return stardates;
	  }
  return (
    <div className='beluga_admin_chat'>
	{loader === true && <div id="semiTransparenDivLoader"></div>}
        
        <MessageList
	referance={messageListReferance}
	className='message-list'
	lockable={true}
	toBottomHeight={'100%'}
	dataSource={VisitLogsChat} />

    <Input
  referance={inputReferance}
  placeholder='Type here...'
  onChange={CheckInput}
  value={inputValue}
  rightButtons={<> <Button color='white' onClick={SendMsgText} backgroundColor='black' text='Send' /></>}
/>


    
    </div>
  )
}

export default AdminChatBeluga
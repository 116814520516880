import React, { useEffect, useState } from "react";

const CurerntMedicationCM = (props) => {
  const [CurrentMedication, SetCurrentMedication] = useState("");

  const handleOptionChange = (e) => {
    SetCurrentMedication(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.CurrentMedicationPC) {
        SetCurrentMedication(props.CurrentMedicationPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetCurrentMedicationPC(CurrentMedication);

      var Err = 0;
      if (CurrentMedication === "") {
        Err = 1;
      }
      if(CurrentMedication==='Yes')
      {
        Err = 1;
        props.toast.error('We are sorry. You dont qualify. You are not eligible to take GLP-1 medication along with others', {
            toastId: 'error1',
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          
            });
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [CurrentMedication]);

  useEffect(()=>{

 
     if(props.SexPC==='MALE')
     {
      props.SetNextMenu('glpexperience')
     }
     else if(props.SexPC==='FEMALE' && props.DobPC<60)
     {
      props.SetNextMenu('breastfeeding')
     }
     else{
      props.SetNextMenu('glpexperience')
     }

    

    if(props.AnythingApplyPCT.includes('None of the above'))
    {
      props.SetPreviousMenu('theseapply')
    }
    else{
      props.SetPreviousMenu('tellusmoremc')
    }
   
     
  },[])



  return (
    <div className="daily_activity">
      <div className="head_row"></div>
      <div>
          <label>
          <h6>{props.CurrentMedicationPCLabel}</h6>
          </label>
          <span style={{color:'red'}}>*</span>
          <p>{props.CurrentMedicationPCLabelMed}</p>
        </div>
      <div className="input_row">
      
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Yes"
            checked={CurrentMedication === "Yes"}
            onChange={handleOptionChange}
          />
          <label>Yes</label>

          <input
            type="radio"
            value="No"
            checked={CurrentMedication === "No"}
            onChange={handleOptionChange}
          />
          <label>No</label>
        </div>
      </div>

    
    </div>
  );
};
export default CurerntMedicationCM;

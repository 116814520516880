import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import ImageUploading from 'react-images-uploading';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "react-js-pagination";

const DoctorPrescription =(props)=>{

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");

    const [PrescribedMedicine,SetPrescribedMedicine]=useState([])
    const [TotalRecord, SetTotalRecord] = useState(0);
    const [CurrentPage, SetCurrentPage] = useState();
    const [PerPage, SetPerPage] = useState(10);
    const [loader,Setloader]=useState(false)

    function timetoUtcClientAppointment(passdate) {
        var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");
    
        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);
    
        var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
    
        return stardates;
      }

    const changePageNumber = (pagenumber) => {
        GetClientVisitsMedicineLog(pagenumber);
      };
      
      const GetClientVisitsMedicineLog=async(pagenumber)=>{

        SetCurrentPage(pagenumber);
        Setloader(true)

        var clientid='';
        if(props.clientid && props.clientid!='')
        {
          clientid=props.clientid;
        }
        
        try{
    
            const GetResponse = await axiosJWT.get(
                "beluga/get-client-visits-log-medicine?offset=" +
                  pagenumber +
                  "&limit=" +
                  PerPage +
                  "&visit_id=" +
                  props.VisitDetailsData.visitId +
                  "",
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              Setloader(false)
              console.log('medicineee',GetResponse.data)
          
              if (GetResponse.data !== null) {
                SetTotalRecord(GetResponse.data.total_count);
                SetPrescribedMedicine(GetResponse.data.GetResponse);
                
              }
    
        }catch(err)
        {
            Setloader(false)
            console.log(err)
        }
    

      }

      useEffect(()=>{
        GetClientVisitsMedicineLog(1)
      },[])

    return (
        <>
       {loader === true && <div id="semiTransparenDivLoader"></div>}
<div className="row beluga_visit_container" style={{ display: "block" }}>
              <Table className="table is-striped is-fullwidth">
                <Thead className="thead ">
                  <Tr className="trow">
                  
                    <Th className="row_name">Medicine Name</Th>
                    <Th className="row_name">Medicine Strength</Th>
                    <Th className="row_name">Medicine Refills</Th>
                    <Th className="row_name">Medicine Quantity</Th>
                    <Th className="row_name">Prescribed Date</Th>
                  
                  </Tr>
                </Thead>

                {PrescribedMedicine.length > 0 && (
                  <Tbody className="tbody beluga_visit_tbody_img">
                    {PrescribedMedicine.length > 0 &&
                      PrescribedMedicine.map((medicine, index) => (
                        <Tr className="trow" key={medicine.id}>

                          <Td>
                           {medicine.medicine_name}
                          </Td>
                          <Td>
                           {medicine.medicine_strength}
                          </Td>
                          <Td>
                           {medicine.medicine_refills}
                          </Td>
                          <Td>
                           {medicine.medicine_quantity}
                          </Td>
                          <Td>
                          
                           <span className="">
                              {format(
                                parseISO(
                                  timetoUtcClientAppointment(
                                    medicine.prescribed_date
                                  )
                                ),
                                `MMM dd, yyyy  hh:mm a`
                              )}
                            </span>
                          </Td>
                         
                         
                       
                          
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
              <div>
                {TotalRecord ? (
                  <Pagination
                    activePage={CurrentPage}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecord}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers color_grey"
                  />
                ) : null}
              </div>
            </div>
        </>
    )


}

export default DoctorPrescription
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import tickimg from '../../../images/rsz_filled-tick.jpg';
import untickimg from '../../../images/rsz_not-filled-untick.jpg';

const Addplans = () => {

    const token = localStorage.getItem("token");

    var [plans, setPlans] = useState([]);

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const [show, setShow] = useState(false);
    const [modalPopup, setModal] = useState();
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [planId, setplanId] = useState('');
    const [planName, setplanName] = useState('');
    const [planDescription, setplanDescription] = useState('');
    const [planDays, setplanDays] = useState('');
    const [planCalories, setplanCalories] = useState('');
    const [planCarbs, setplanCarbs] = useState('');
    const [planProtein, setplanProtein] = useState('');
    const [planFat, setplanFat] = useState('');
    const [planProgram, setplanProgram] = useState('');
    const [programOptions, setprogramOptions] = useState([]);

    const [AddPlanNameErr, SetAddPlanNameErr] = useState(false);
    const [AddPlanNameErrMsg, SetSetAddPlanNameErrMsg] = useState('');

    const [AddPlanDescErr, SetAddPlanDescErr] = useState(false);
    const [AddPlanDescErrMsg, SetAddPlanDescErrMsg] = useState('');

    const [AddPlanProgramErr, SetAddPlanProgramErr] = useState(false);
    const [AddPlanProgramErrMsg, SetAddPlanProgramErrMsg] = useState('');

    const [AddPlanDaysErr, SetAddPlanDaysErr] = useState(false);
    const [AddPlanDaysErrMsg, SetAddPlanDaysErrMsg] = useState('');

    const [AddPlanCaloriesErr, SetAddPlanCaloriesErr] = useState(false);
    const [AddPlanCaloriesErrMsg, SetAddPlanCaloriesErrMsg] = useState('');

    const [AddPlanCarbsErr, SetAddPlanCarbsErr] = useState(false);
    const [AddPlanCarbsErrMsg, SetAddPlanCarbsErrMsg] = useState('');

    const [AddPlanProteinErr, SetAddPlanProteinErr] = useState(false);
    const [AddPlanProteinErrMsg, SetAddPlanProteinErrMsg] = useState('');

    const [AddPlanFatErr, SetAddPlanFatErr] = useState(false);
    const [AddPlanFatErrMsg, SetAddPlanFatErrMsg] = useState('');


    useEffect(() => {
        getPlans();
    }, []);

    const handleClose = () => setShow(false);

    const axiosJWT = axios.create();

    const getPlans = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage);
            var ending_limit = countPerPage;
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage;
        }

        const response = await axiosJWT.get("/meal-plan?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setPlans(response.data.plans);
        setTotalCount(response.data.totalcount);
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getPlans(pagenumber);
    }

    const handleShow = async (e) => {
        const planId = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");

        setplanId(planId);
        setModal(datamodal);

        SetAddPlanNameErr(false);
        SetAddPlanDescErr(false);
        SetAddPlanProgramErr(false);
        SetAddPlanDaysErr(false);
        SetAddPlanCaloriesErr(false);
        SetAddPlanCarbsErr(false);
        SetAddPlanProteinErr(false);
        SetAddPlanFatErr(false);

        if (datamodal === 'plan_add') {
            setplanName('');
            setplanDescription('');
            setplanProgram('');
            setplanDays('');
            setplanCalories('');
            setplanCarbs('');
            setplanProtein('');
            setplanFat('');

            const response = await axiosJWT.get("/nutrition-service/list-service-categories", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            var category_data = response.data.final_list;
            var category_list = [];

            category_data.forEach(element => {
                //console.log(element.category_id)
                category_list.push({ label: element.category_name, value: element.category_id });
            });

            //console.log(response.data.final_list)
            setprogramOptions(category_list);
            setShow(true);
        }

        if (datamodal === 'plan_edit') {
            const response = await axiosJWT.get("/meal-plan/" + planId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response.data)
            setplanName(response.data.meal_plan_name);
            setplanDescription(response.data.meal_plan_description);
            setplanProgram(response.data.category_id);
            setplanDays(response.data.days);
            setplanCalories(response.data.calories);
            setplanCarbs(response.data.carbs);
            setplanProtein(response.data.proteins);
            setplanFat(response.data.fats);

            var category_id = response.data.category_id;

            const response_category = await axiosJWT.get("/nutrition-service/list-service-categories", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            var category_data = response_category.data.final_list;
            var category_list = [];

            category_data.forEach(element => {
                //console.log(element.category_id)
                if (category_id === element.category_id)
                    setplanProgram({ label: element.category_name, value: element.category_id });

                category_list.push({ label: element.category_name, value: element.category_id });
            });

            setprogramOptions(category_list);
            setShow(true);
        }

        if (datamodal === 'delete_confirm') {
            const response = await axiosJWT.get("/meal-plan/" + planId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response.data)

            setplanName(response.data.meal_plan_name);
            setDeletePopup(true);
        }
    }


    const deletePlan = async (e) => {

        const planId = e.target.getAttribute("data-item");

        const response = await axiosJWT.delete('/meal-plan/' + planId,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleDeleteClose();
            getPlans();
        }
    }

    const handleDeleteClose = () => {
        setplanId();
        setDeletePopup(false);
    }

    const createPlan = async () => {

        SetAddPlanNameErr(false);
        SetAddPlanDescErr(false);
        SetAddPlanProgramErr(false);
        SetAddPlanDaysErr(false);
        SetAddPlanCaloriesErr(false);
        SetAddPlanCarbsErr(false);
        SetAddPlanProteinErr(false);
        SetAddPlanFatErr(false);

        if (planName !== '' && planDescription !== '' && planProgram.value !== '' && planDays !== '' && planCalories !== '' && planCarbs !== '' && planProtein !== '' && planFat !== '') {
            const response = await axiosJWT.post("/meal-plan/",
                {
                    meal_plan_name: planName,
                    meal_plan_description: planDescription,
                    category_id: planProgram.value,
                    days: planDays,
                    calories: planCalories,
                    carbs: planCarbs,
                    proteins: planProtein,
                    fats: planFat
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleClose();
                getPlans();
            }
        }
        else {

            var err = false;

            if (planName === '') {
                SetAddPlanNameErr(true);
                SetSetAddPlanNameErrMsg('Please enter plan name');
                err = true;
            }

            if (planDescription === '') {
                SetAddPlanDescErr(true);
                SetAddPlanDescErrMsg('Please enter plan description');
                err = true;
            }

            if (planProgram.value === undefined || planProgram.value === '') {
                SetAddPlanProgramErr(true);
                SetAddPlanProgramErrMsg('Please select program');
                err = true;
            }

            if (planDays === '') {
                SetAddPlanDaysErr(true);
                SetAddPlanDaysErrMsg('Please enter days');
                err = true;
            }

            if (planCalories === '') {
                SetAddPlanCaloriesErr(true);
                SetAddPlanCaloriesErrMsg('Please enter calories');
                err = true;
            }

            if (planCarbs === '') {
                SetAddPlanCarbsErr(true);
                SetAddPlanCarbsErrMsg('Please enter carbs');
                err = true;
            }

            if (planProtein === '') {
                SetAddPlanProteinErr(true);
                SetAddPlanProteinErrMsg('Please enter protein');
                err = true;
            }

            if (planFat === '') {
                SetAddPlanFatErr(true);
                SetAddPlanFatErrMsg('Please enter fat');
                err = true;
            }

            if (err === true) {
                return false;
            }
        }
    }

    const updatePlan = async (e) => {

        const planId = e.target.getAttribute("data-item");

        var err = false;

        SetAddPlanNameErr(false);
        SetAddPlanDescErr(false);
        SetAddPlanProgramErr(false);
        SetAddPlanDaysErr(false);
        SetAddPlanCaloriesErr(false);
        SetAddPlanCarbsErr(false);
        SetAddPlanProteinErr(false);
        SetAddPlanFatErr(false);

        if (planName === '') {
            SetAddPlanNameErr(true);
            SetSetAddPlanNameErrMsg('Please enter plan name');
            err = true;
        }

        if (planDescription === '') {
            SetAddPlanDescErr(true);
            SetAddPlanDescErrMsg('Please enter plan description');
            err = true;
        }

        if (planProgram.value === undefined || planProgram.value === '') {
            SetAddPlanProgramErr(true);
            SetAddPlanProgramErrMsg('Please select program');
            err = true;
        }

        if (planDays === '') {
            SetAddPlanDaysErr(true);
            SetAddPlanDaysErrMsg('Please enter days');
            err = true;
        }

        if (planCalories === '') {
            SetAddPlanCaloriesErr(true);
            SetAddPlanCaloriesErrMsg('Please enter calories');
            err = true;
        }

        if (planCarbs === '') {
            SetAddPlanCarbsErr(true);
            SetAddPlanCarbsErrMsg('Please enter carbs');
            err = true;
        }

        if (planProtein === '') {
            SetAddPlanProteinErr(true);
            SetAddPlanProteinErrMsg('Please enter protein');
            err = true;
        }

        if (planFat === '') {
            SetAddPlanFatErr(true);
            SetAddPlanFatErrMsg('Please enter fat');
            err = true;
        }

        if (err === true) {
            return false;
        }

        //console.log(planProgram.value)
        const response = await axiosJWT.put("/meal-plan/" + planId,
            {
                meal_plan_name: planName,
                meal_plan_description: planDescription,
                category_id: planProgram.value,
                days: planDays,
                calories: planCalories,
                carbs: planCarbs,
                proteins: planProtein,
                fats: planFat
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            handleClose();
            getPlans();
        }
    }

    const searchPlans = async (event) => {

        var starting_limit = 0;
        var ending_limit = countPerPage;

        const response = await axiosJWT.get("/meal-plan?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);

        //console.log(response.data)
        setPlans(response.data.plans);
        setTotalCount(response.data.totalcount);
    }

    const check_recipeset = (plan) => {
        if(plan.breakfast_keys && plan.lunch_keys && plan.dinner_keys && plan.snack_keys)
        {
            var breakfast_exist = JSON.parse(plan.breakfast_keys);
            var lunch_exist = JSON.parse(plan.lunch_keys);
            var dinner_exist = JSON.parse(plan.dinner_keys); 
            var snack_exist = JSON.parse(plan.snack_keys);               

            if(plan.days === breakfast_exist.length && plan.days === lunch_exist.length && plan.days === dinner_exist.length && plan.days === snack_exist.length)
            return true;
            else 
            return false;
        }
        else
        return false;
    }

    return (
        <div>
            <div className='title_box'>
                <div className='leftmenu'>
                    <h5>My Plans</h5><br></br>
                </div>
                <div className='rightmenu'>
                    <input className='search' type="text" placeholder="&#xF002; Search Plan" onChange={event => searchPlans(event.target.value)} />
                    <input className='greenbutton' type="button" value="Create New Plan" onClick={(e) => { handleShow(e) }} data-modal="plan_add" />
                </div>
            </div>

            <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Name</Th>
                        <Th>Descriptions</Th>
                        <Th>Program</Th>
                        <Th>Days</Th>
                        <Th>Calories</Th>
                        <Th>Carbs (%)</Th>
                        <Th>Protein (%)</Th>
                        <Th>Fat (%)</Th>
                        <Th></Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                    {plans.map((plan, index) => (
                        <Tr className='trow' key={plan.id}>
                            <Td><img style={{width:"20px"}} src={check_recipeset(plan) === true ? tickimg : untickimg}></img>{plan.meal_plan_name}</Td>
                            <Td className="color_grey">{plan.meal_plan_description}</Td>
                            <Td><span className={plan.category_id !== null ? (plan.service_category.category_name === "Weight And Wellness" ? "purplebox colorbox" : "orangebox colorbox") : null}>{plan.category_id !== null ? plan.service_category.category_name : null}</span></Td>
                            <Td>{plan.days}</Td>
                            <Td>{plan.calories}</Td>
                            <Td>{plan.carbs}</Td>
                            <Td>{plan.proteins}</Td>
                            <Td>{plan.fats}</Td>
                            <Td><NavLink to={"/coach/plans/" + plan.id}><input className='greenbutton' type="button" value="Setup Foods Plan" /></NavLink></Td>
                            <Td>
                                <i className="fa fa-edit td_icon action" data-modal="plan_edit" data-item={plan.id} onClick={handleShow}></i><i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-item={plan.id} onClick={handleShow}></i></Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>          

            {totalCount ? 
            <Pagination
                activePage={activePage}
                itemsCountPerPage={countPerPage}
                totalItemsCount={totalCount}
                onChange={changePageNumber.bind(this)}
                prevPageText="<"
                nextPageText=">"
                hideFirstLastPages={true}
                linkClassPrev="pagination_prev"
                linkClassNext="pagination_next"
                linkClass="pagenumbers grayfont"
            />
            : null }

            <div className='color_explanation'>
                        <img src={tickimg} style={{width:"20px"}}></img> - Setup Foods Plan Completed.<br/>
                        <span style={{display:"inherit",paddingTop:"5px"}}></span>
                        <img src={untickimg} style={{width:"20px"}}></img> - Setup Foods Plan Not Complete.
            </div>

            <Modal show={show} onHide={handleClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Meal Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Plan Name:</label><br />
                            <input type='text' className='inner_popup_input' defaultValue={planName} onChange={(e) => { setplanName(e.target.value) }}  {...(AddPlanNameErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanNameErr === true &&
                                <span className="err service_err_field">  {AddPlanNameErrMsg}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Plan Description:</label><br />
                            <textarea className='inner_popup_input' defaultValue={planDescription} onChange={(e) => { setplanDescription(e.target.value) }} rows="4" {...(AddPlanDescErr===true ? {id: 'err_border'} : {})} />
                            {AddPlanDescErr === true &&
                                <span className="err service_err_field">  {AddPlanDescErrMsg}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Program:</label><br />
                            <Select
                                defaultValue={{ label: planProgram.label, value: planProgram.value }}
                                options={programOptions}
                                onChange={setplanProgram}
                                {...(AddPlanProgramErr===true ? {id: 'err_border'} : {})}
                            />
                            {AddPlanProgramErr === true &&
                                <span className="err service_err_field">  {AddPlanProgramErrMsg}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Days:</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={planDays} onChange={(e) => { setplanDays(e.target.value) }}   {...(AddPlanDaysErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanDaysErr === true &&
                                <span className="err service_err_field">  {AddPlanDaysErrMsg}</span>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Calories:</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={planCalories} onChange={(e) => { setplanCalories(e.target.value) }} {...(AddPlanCaloriesErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanCaloriesErr === true &&
                                <span className="err service_err_field">  {AddPlanCaloriesErrMsg}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Carbs (%):</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={planCarbs} onChange={(e) => { setplanCarbs(e.target.value) }} {...(AddPlanCarbsErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanCarbsErr === true &&
                                <span className="err service_err_field">  {AddPlanCarbsErrMsg}</span>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Protein (%):</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={planProtein} onChange={(e) => { setplanProtein(e.target.value) }}  {...(AddPlanProteinErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanProteinErr === true &&
                                <span className="err service_err_field">  {AddPlanProteinErrMsg}</span>
                            }
                        </div>
                        <div className="col">
                            <label className='inner_popup_label required'>Fat (%):</label><br />
                            <input type='number' className='inner_popup_input' defaultValue={planFat} onChange={(e) => { setplanFat(e.target.value) }} {...(AddPlanFatErr===true ? {id: 'err_border'} : {})}></input>
                            {AddPlanFatErr === true &&
                                <span className="err service_err_field">  {AddPlanFatErrMsg}</span>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={planId} onClick={(e) => { modalPopup === 'plan_add' ? createPlan(e) : updatePlan(e) }}>
                        {modalPopup === 'plan_add' ? "Create Plan" : "Update Plan"}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePopup} onHide={handleDeleteClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete plan - {planName}?
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleDeleteClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={planId} onClick={(e) => { deletePlan(e) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <div style={{ clear: 'both' }}></div>

        </div>
    )
}

export default Addplans
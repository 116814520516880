import React, { useEffect } from "react";

const Issues = (props) => {
    const CheckPhysical = (e) => {
        var tabLink = document.querySelectorAll(".option_div")

        tabLink.forEach(box => {
            box.classList.remove('active');
        });

        e.target.closest('.option_div').classList.add("active");

        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");

        if (category === "") {
            props.DisableNextButton();
        } 
        else {
            props.SetIssueData(category);
            props.EnableNextButton();
        }
    }

    useEffect(() => {
        setTimeout(() => {
    
          if (props.IssueData) {
            if (props.IssueData !== "") {             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.IssueData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading" style={{ maxWidth: '100%' }}>
                    Do you experience any of the following problems?
                </span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Swollen Legs, Ankles, Or Feet" onClick={CheckPhysical}><p>Swollen Legs, Ankles, Or Feet</p></div>
                <div className="option_div" data-target="Skin Rashes Or Flare Ups" onClick={CheckPhysical}><p>Skin Rashes Or Flare Ups</p></div>
                <div className="option_div" data-target="Depression Or Feeling Isolated" onClick={CheckPhysical}><p>Depression Or Feeling Isolated</p></div>
                <div className="option_div" data-target="None" onClick={CheckPhysical}><p>None</p></div>
            </div>
        </div>
    )
}
export default Issues
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const MetabolismFasting = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [AddDataDateEnd, SetAddDataDateEnd] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [DateEndErr, SetDateEndErr] = useState(false)

    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TImePickerEnd, SetTimePickerEnd] = useState(formatAMPM(new Date()))
    const [TimeErr, SetTimeErr] = useState(false)
    const [TimeEndErr, SetTimeEndErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [DisabledEnd, SetDisabledEnd] = useState(true)
    const [EnableEnd, SetEnableEnd] = useState(false)
    const [GlucoseValue,SetGlucoseValue]=useState('')

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    const ChangedCheckboxBloodDaily = (e) => {
        var Checkboytype = e.target.getAttribute('data-id')

        if (Checkboytype === 'enable_end_at') {
            if (e.target.checked === true) {
                SetDisabledEnd(false)
            }
            else {
                SetDisabledEnd(true)
            }

        }

    }
    const SaveMetabolismFast=async(e)=>{
        const action=e.target.getAttribute('data-action')
        var err=false
        if(action==='cancel')
        {
            props.SetShowMetaFastingField(false)
            props.SetMetabolismOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetDateEndErr(false)
        SetTimeEndErr(false)
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if (AddDataDateEnd === null) {
            err = true
            SetDateEndErr(true)
        }
        if (TImePickerEnd === null) {
            err = true
            SetTimeEndErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            var passDateEnd=''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)
             
            }
            if (AddDataDateEnd != null) {

                passDateEnd = ChangeDatePickerDate(AddDataDateEnd)
             
            }
            const response = await axiosJWT.post('add-data/add-metabolism',
            {

                'type': 'Metabolism Fasting',
                'fasting_start_at':passDate+' '+TImePicker,
                'fasting_end_at':passDateEnd+' '+TImePickerEnd,
                'fasting_glucose':GlucoseValue,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowMetaFastingField(false)
                    props.SetMetabolismOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }
           

        }



    }
    
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }
    return (

        <div className="physical_activity">
 <h6 className="heading_popup">{'Metabolism > Fasting'}</h6>
            <div className="field_top_row">
                <div className="centered_label">Start At</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>


            <div className="field_top_row">
                <div className="centered_label">End At</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDateEnd}
                        onChange={SetAddDataDateEnd}
                        format="MM-dd-yyyy"
                         disabled={DisabledEnd} />
                </div>

                {DateEndErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePickerEnd} value={TImePickerEnd} disabled={DisabledEnd} />
                </div>
                {TimeEndErr === true && <span className="err">Please select time</span>}

            </div>
            <div className="field_top_row">
                <div className="field checkbox_field_in_line">

                    <div className="checkbox">
                        <input type="checkbox" name="enable_end_at" data-id='enable_end_at' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={EnableEnd} />
                        <label className="checkbox_add_data_label">Enable End at fields</label>
                    </div>
                </div>

            </div>

            <div className="field_top_row">
                <div className="label">Glucose (mg/DL)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetGlucoseValue(e.target.value) }}   defaultValue={GlucoseValue} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveMetabolismFast(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveMetabolismFast(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveMetabolismFast(e) }} >
                    {'Save'}
                </Button>

                {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

           </div>

        </div>

    )

}
export default MetabolismFasting
import React, { useState, useEffect, useNa } from 'react'
import { format } from 'date-fns'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

var QB = require('quickblox/quickblox.min');

const IdleSessionLogout =()=>{
    const timeout = 30 * 60000
   
    const [remaining, setRemaining] = useState(timeout);
    const [elapsed, setElapsed] = useState(0);
    const [lastActive, setLastActive] = useState(+new Date());
    const [isIdle, setIsIdle] = useState(false);
  
    const handleOnActive = () => {
       setIsIdle(false);
    }

    const axiosJWT = axios.create();
    const navigate = useNavigate();
    const handleOnIdle =async () =>{
        // var QB = require('quickblox/quickblox.min');
        // QB.init(localStorage.getItem('QB_USER_TOKEN'));
        QB.destroySession(localStorage.getItem('QB_USER_TOKEN'));
        QB.chat.disconnect();
        QB.chat.onDisconnectedListener = onDisconnectedListener;

        localStorage.setItem('token', '')
        localStorage.setItem("first_name", '');
        localStorage.setItem("last_name", '');
        localStorage.setItem("gender", '');
        localStorage.setItem("expire", '');
        localStorage.setItem("role", '');
        localStorage.setItem("userId", '');
        localStorage.setItem("user_timezone", '');
        localStorage.setItem('QB_SESSION_RESPONSE', '');
        localStorage.setItem('QB_USER_TOKEN', '');
        localStorage.setItem("email",'');
        localStorage.setItem('subscriptionID', '');
        localStorage.setItem('subscriptionSdate', '');
        localStorage.setItem('subscriptionEdate', '');
        localStorage.setItem('AUTOLOGOUTSESSION', 'YES');        

        window.location.reload(true);

        const RefreshToken=localStorage.getItem('Refreshtoken');
        localStorage.setItem('Refreshtoken', '');
        const res = await axios.delete('logout', { data: { refreshtoken: RefreshToken } });
        navigate("/login", { replace: true });
        
    } 

    function onDisconnectedListener() {
      console.log("onDisconnected");
    }
    
    const {
        reset,
        pause,
        resume,
        getRemainingTime,
        getLastActiveTime,
        getElapsedTime
      } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
      })

      
  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 1000)
  }, []);
}

export default IdleSessionLogout
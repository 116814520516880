import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Subscriber = () => {
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create()

    const [SubscribersList, setSubscribersList] = useState([])

    const GetSubscribersList = async (pagenumber) => {
        

        const SubscribersListFetch = await axiosJWT.get('/service-plan/subscribers?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((SubscribersListFetch) => {

            setSubscribersList(SubscribersListFetch.data.data)
            setTotalRecordManagePlan(SubscribersListFetch.data.count)

        })

    }

    useEffect(() => {
        GetSubscribersList(1)
    }, [])

    const [PageNumberManagePlan, setPageNumberManagePlan] = useState(1)
    var PerPage = 10;
    const [TotalRecordManagePlan, setTotalRecordManagePlan] = useState()

    const changePageNumber = async (pagenumber) => {


        setPageNumberManagePlan(pagenumber);

        GetSubscribersList(pagenumber);

    }

    return (
        <div className='container'>
            <div className='row subscriber_div'>

                <Table className='table is-striped is-fullwidth'>
                    <Thead className='thead'>
                        <Tr>
                            <Th className='row_name'>Name</Th>
                            <Th className='row_name'>Email</Th>
                            <Th className='row_name'>Plan Name</Th>
                            <Th className='row_name'>Amount</Th>
                            <Th className='row_name'>Purchased Date</Th>
                            <Th className='row_name last_class'>Expiry Date</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='tbody'>
                        {SubscribersList.length > 0 && SubscribersList.map((users, index) => (

                            <Tr>
                                <Td>{users.user.first_name + ' ' + users.user.last_name}</Td>
                                <Td>{users.user.email}</Td>
                                <Td>{users.service_plan !== null && users.service_plan.service_plan_name}</Td>
                                <Td><span>$</span><span>{users.service_plan !== null && users.service_plan.service_plan_amount}</span></Td>
                                <Td>{format(parseISO(users.user_plan_activation_date), `PP`)}</Td>
                                <Td className='last_class'>{format(parseISO(users.user_plan_expiry_date), `PP`)}</Td>

                            </Tr>

                        ))}





                    </Tbody>
                </Table>

                {TotalRecordManagePlan ?
                <Pagination
                    activePage={PageNumberManagePlan}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecordManagePlan}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers grayfont"
                />
                : null }
            </div>
        </div>

    )
}

export default Subscriber
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
//import "bulma/css/bulma.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from "axios";
 
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
//axios.defaults.baseURL = "http://192.168.10.160:5000";
axios.defaults.withCredentials = true;


 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <App />
);

// ReactDOM.render(

//     <App />, 
//     document.getElementById("root")
// );
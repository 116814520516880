import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { format, parseISO } from "date-fns";
import axios from "axios";
import DocumentViewer from "./DocumentViewer";
const UserFiles=(props)=>{



    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

    const [ShowDocumentViewer,SetShowDocumentViewer]=useState(false)
    const [DocumentPreviewPath, SetDocumentPreviewPath] = useState("");
    const [DirectoryFiles,SetDirectoryFiles]=useState([])
    const [DirectoryOwnerName,SetDirectoryOwnerName]=useState('')
    const [DirectoryOwnerEmail,SetDirectoryOwnerEmail]=useState('')
    const BackToDirectory=()=>{
        props.SetFolderListShow(true)
    }

    const TriggerViewFile=(e)=>{

    }
    const PreviewFile=(e)=>{

        if(e.target.classList.contains('download_document_file')===false)
        {
          const path = e.target.closest(".file_lists_tr").getAttribute("data-path");
          SetDocumentPreviewPath(path);
          SetShowDocumentViewer(true);
        }
        else{
          var Path=e.target.getAttribute('data-path')
          window.open(Path, '_blank');
         
        }
       

    }
    
    const GetMyDirectoryFiles = async (id) => {
        const response = await axios.get(
          "document-directory/my-directory/my-files?id=" + id + "",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.list != null) {
          SetDirectoryFiles(response.data.list);
          SetDirectoryOwnerEmail(response.data.directorydetails.user.email)
          SetDirectoryOwnerName(response.data.directorydetails.user.first_name+' '+response.data.directorydetails.user.last_name)
        }
      };

      const GetAccessedFiles=async(id)=>{

        const response = await axios.get(
          "document-directory/admin-allowed-files?id=" + id + "",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.list != null) {
          SetDirectoryFiles(response.data.list);
          SetDirectoryOwnerEmail(response.data.directorydetails.user.email)
          SetDirectoryOwnerName(response.data.directorydetails.user.first_name+' '+response.data.directorydetails.user.last_name)
        }

      }
    useEffect(()=>{

      if(props.CheckAccess)
      {
        if(props.CheckAccess===true)
        { 
          GetAccessedFiles(props.FolderId)
        }
      }
      else{

        if(props.FolderId)
        {
            GetMyDirectoryFiles(props.FolderId)
            
        }

      }

       
    },[props])

    return(
        <div className="directory_list_files">
                  {ShowDocumentViewer === false && 

                  <div>
                         <div className="head_row">
       
            <button onClick={BackToDirectory}>Back to directories</button>
        
          </div>
          <div className="directory_owner">Directory Owner : {DirectoryOwnerName} ({DirectoryOwnerEmail})</div>
          <div>
            {DirectoryFiles.length > 0 && (
              <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                <Thead className="thead">
                  <Tr className="trow">
                    <Th scope="col">File Name</Th>
                    <Th scope="col">Last Updated</Th>
                    <Th scope="col">Actions</Th>
                  </Tr>
                </Thead>

                <Tbody className="tbody manager_appointment_list_admin directory_lists_table">
                  {DirectoryFiles.map((list, index) => (
                    <Tr
                      className="trow file_lists_tr"
                      data-path={list.file_path}
                      onClick={PreviewFile}
                    >
                      <Td>
                        <span className="file_path">{list.file_name}</span>
                      </Td>
                      <Td>{format(parseISO(list.updatedAt), `PP`)}</Td>
                      <Td>
                        {" "}
                        <span className="icons">
                          <i
                            className="fa fa-eye icon_file"
                            data-id={list.id}
                            data-name={list.file_name}
                            onClick={TriggerViewFile}
                          ></i>

<i className="fa fa-download download_document_file" data-path={list.file_path}></i>

                       
                         
                        </span>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}

          </div>
                  </div>
            
            }
             {ShowDocumentViewer === true && (
        <DocumentViewer
          DocumentPreviewPath={DocumentPreviewPath}
          SetShowDocumentViewer={SetShowDocumentViewer}
        />
      )}
        </div>
    )
}
export default UserFiles
import React, { useEffect, useState } from "react";
import axios from "axios";

const KetoBook = () => {


  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();

  useEffect(()=>{
    document.title = 'Client-Keto Ebook';
  },[])

  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className="dashboard">
        <div className="client_ebook container">
          <div className="row">
            <span className="keto_book_top_label">Keto 101</span>
          </div>

          <div className="row keto_book_img_info_row">
            <div className="keto_book_image_col">
                <img src={process.env.REACT_APP_BACKEND_URL + '/ketoebook/summer-4366643_640.jpg'}></img>
              </div>
            <div className="keto_book_info_col">
              <span className="keto_book_info_col_head">Keto Diet 101 eBook</span>
              <p className="keto_e_book_paragraph">
                Everything you need to know about the Ketogenic diet! What to expect when you’re in ketosis and how to maximise your results.

                Bust through fat loss plateaus, boost your brain power and have unlimited amounts of energy when you manipulate your metabolism with the keto diet.
              </p>
              <a className="btn btn-primary keto_link_button" target={'_blank'} href={process.env.REACT_APP_BACKEND_URL + '/ketoebook/The-Beginners-Guide-To-The-Ketogenic-Diet.pdf'} role="button">Download Now</a>
            </div>
            
          </div>

        </div>

      </section>
    </div>
  )

}

export default KetoBook
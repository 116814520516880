import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const ClientBelugaForm = (props) => {

  const [quizArray, SetquizArray] = useState([]);

  const [prescriptionimage,Setprescriptionimage]=useState('')

  const [rows, setRowsOption] = useState(null);

 

  useEffect(() => {
      if (rows && rows.length>0) {
          setTimeout(() => {

            rows.forEach((element,index) => {
                rows[index].expand();  
            });
           
            
          }, 500);

        
      }
  }, [rows]);


  
  useEffect(()=>{


    if(props.VisitDetailsData.quiz_data)
    {
        var JsonQuiz=JSON.parse(props.VisitDetailsData.quiz_data)
        FrameQuiz(JsonQuiz)
        
    }



//    FrameQuiz(JsonQuiz)
  },[props.VisitDetailsData])

 
  const FrameQuiz=(quiz)=>{

    console.log('ssssss',quiz)

    var QuizRenderArray=[];

    if(quiz['formObj']['firstName'])
    {
        QuizRenderArray.push({title:'Name',content:quiz['formObj']['firstName']+' '+quiz['formObj']['lastName']})
    }
    if(quiz['formObj']['email'])
    {
        QuizRenderArray.push({title:'Email',content:quiz['formObj']['email']})
    }
    if(quiz['formObj']['dob'])
    {
        QuizRenderArray.push({title:'Date of birth',content:quiz['formObj']['dob']})
    }
    if(quiz['formObj']['phone'])
    {
        QuizRenderArray.push({title:'Phone number',content:formatPhoneNumber(quiz['formObj']['phone'])})
    }
    if(quiz['formObj']['address'])
    {
        QuizRenderArray.push({title:'Address',content:quiz['formObj']['address']})
    }
    if(quiz['formObj']['city'])
    {
        QuizRenderArray.push({title:'City',content:quiz['formObj']['city']})
    }
    if(quiz['formObj']['state'])
    {
        QuizRenderArray.push({title:'State',content:quiz['formObj']['state']})
    }
    if(quiz['formObj']['zip'])
    {
        QuizRenderArray.push({title:'Zip',content:quiz['formObj']['zip']})
    }
    if(quiz['formObj']['sex'])
    {
        QuizRenderArray.push({title:'Sex',content:quiz['formObj']['sex']})
    }
    if(quiz['formObj']['allergies'])
    {
        QuizRenderArray.push({title:'Do you have any allergies?',content:quiz['formObj']['allergies']})
    }
    if(quiz['formObj']['consentsSigned'])
    {
        if(quiz['formObj']['consentsSigned'])
        {
            QuizRenderArray.push({title:'Consent signed',content:'Yes'})
        }
        else{
            QuizRenderArray.push({title:'Consent signed',content:'No'}) 
        }

    }
    if(quiz['formObj']['selfReportedMeds'])
    {
        QuizRenderArray.push({title:'Can you please list all the medications you are currently taking?',content:quiz['formObj']['selfReportedMeds']})
    }
    if(quiz['formObj']['medicalConditions'])
    {
        QuizRenderArray.push({title:'Please describe about your current medical condition',content:quiz['formObj']['medicalConditions']})
    }
    if(quiz['formObj']['patientPreference'])
    {
        var String='Name: '+quiz['formObj']['patientPreference'][0]['name']+', Strength: '+quiz['formObj']['patientPreference'][0]['strength']+', Quantity: '+quiz['formObj']['patientPreference'][0]['quantity']+', medId: '+quiz['formObj']['patientPreference'][0]['medId']

        QuizRenderArray.push({title:'Preferred Dose',content:String})
    }
    if(quiz['masterId'])
    {
        QuizRenderArray.push({title:'Master Id',content:quiz['masterId']})
    }
    if(quiz['pharmacyId'])
    {
        QuizRenderArray.push({title:'Pharmacy Id',content:formatPharmacy(quiz['pharmacyId'])})
    }
    if(quiz['visitType'])
    {
        QuizRenderArray.push({title:'Type of visit',content:quiz['visitType']})
    }
    if(quiz['prescriptionimage'])
    {
        Setprescriptionimage(quiz['prescriptionimage'])
    }

    const questionsAndAnswers = [];

    const ExtraQuiz=quiz['formObj']


// Iterate through the keys of the object
for (const key in ExtraQuiz) {
    // Check if the key starts with "Q"
    if (key.startsWith("Q")) {
        const questionNumber = key.slice(1); // Extract the question number
        const question = ExtraQuiz[key];
        const answerKey = "A" + questionNumber;
        const answer = ExtraQuiz[answerKey];
        questionsAndAnswers.push({ question, answer });
    }
}
console.log(questionsAndAnswers);

questionsAndAnswers.forEach(element => {
    QuizRenderArray.push({title:element.question,content:element.answer})
});

    SetquizArray(QuizRenderArray)


  }

 

  function formatPhoneNumber(number) {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

function formatPharmacy(id){

    if(id==='239252' || id===239252)
    {
      return ('Southend pharmacy (239252)')
    }
    if(id==='247662' || id===247662)
    {
      return ('Amazon pharmacy (247662)')
    }

}


  return (
    <>
      {quizArray.length > 0 ? (
        <Faq
          data={{ title: "", rows: quizArray }}
          getRowOptions={setRowsOption}
          styles={{
            rowTitleColor: "#226920",
            rowContentPaddingLeft: "10px",
          }}
        />
      ) : null}
{
    prescriptionimage!=='' ? <div className="presription_vial_row">
    <h6>Photo of prescription or vial</h6>
    <img src={prescriptionimage}/>
  </div> : null
}
      
    </>
  );
};
export default ClientBelugaForm;
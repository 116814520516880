import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Faq from "react-faq-component";
import Pagination from "react-js-pagination";

const GlpForm = (props) => {
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [FormLists, SetFormLists] = useState([]);
  const user_timezone = localStorage.getItem("user_timezone");

  const [ShowList,SetShowList]=useState(true)
  const [ShowDetail,SetShowDetail]=useState(false)

  const [FormAnswers,SetFormAnswers]=useState([])
  const config = {
  
    openOnload: [1],
   
   
};

  const client_id = props.client_id;

  const GetFormLists = async (pagenumber) => {
    SetServiceCatPageNo(pagenumber)
    try {
      const Response = await axiosJWT.get(
        "/healthie/get-form-lists?client_id=" + client_id + "?offset=" + pagenumber + "&limit=" + PerPage + "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      SetFormLists(Response.data.list);
      SetTotalRecordServiceCategory(Response.data.total)
      

      
      console.log(Response.data);
    } catch (err) {
      SetFormLists([]);
    }
  };

  const [FormName,SetFormName]=useState('')
  const [rows, setRowsOption] = useState(null);

  useEffect(() => {
    if (rows) {

      rows.forEach(element => {
        element.expand();
      });
      
    }
}, [rows]);

  const ViewForm=async(e)=>{
    SetShowList(false)
 const Id=e.target.getAttribute('data-form_id')

 const Response = await axiosJWT.get(
  "/healthie/get-form-detail?form_id=" + Id + "",
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
);

if(Response.data.form_answers)
{
  const obj = JSON.parse(Response.data.form_answers);

  const QuizArrayIn=[];

  obj.forEach(element => {

    if(element.answer!==null && element.answer!=='')
    {
   QuizArrayIn.push({title:element.label,content:element.answer})
    }
 
    
  });
  SetFormAnswers(QuizArrayIn)
  SetFormName(Response.data.data_type)
 
}
SetShowDetail(true)



console.log(Response)


  }

  const data = {
    title: FormName,
    rows: FormAnswers,
};

  const CloseDetail=()=>{
    SetShowDetail(false);
    SetShowList(true)
  }

  const changePageNumberSC = async (pagenumber) => {
    GetFormLists(pagenumber);

}

  const [TotalRecordServiceCategory,SetTotalRecordServiceCategory]=useState(0)
  const [ServiceCatPageNo,SetServiceCatPageNo]=useState(1)
  const [PerPage,SetPerPage]=useState(10)

  useEffect(() => {
    GetFormLists(1);
  }, []);

  return (
    <div>
      {
        ShowList ==true ? 
        <>
         <Table className="table is-striped is-fullwidth">
        <Thead className="thead">
          <Tr>
            <Th>Title/ID</Th>
            <Th>Submission Date</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody className="tbody">
          {FormLists.map((data, index) => (
           <Tr className='trow'>
           <Td>{(data.data_type!=='' && data.data_type!==null) ? data.data_type :  data.form_id }</Td>
           <Td>{moment.utc(data.form_submitted_date).clone().tz(user_timezone).format('YYYY-MM-DD HH:mm:ss')}</Td>
           <Td style={{cursor:'pointer'}} data-form_id={data.id} onClick={(e)=>{ViewForm(e)}}>View</Td>
         
  
       </Tr>
          ))}
        </Tbody>
      </Table> 
      <div>
      {TotalRecordServiceCategory ?
                                <Pagination
                                    activePage={ServiceCatPageNo}
                                    itemsCountPerPage={PerPage}
                                    totalItemsCount={TotalRecordServiceCategory}
                                    onChange={changePageNumberSC.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                                : null}
      </div>
        </>
        
       
      
      
      : null
      }
   

      {(ShowDetail===true && FormAnswers.length > 0) ?

      <> 
      <div className="glp_notes_faq_section">
        <button onClick={CloseDetail}>Back</button>
      </div>
      <Faq
      config={config}
      data={data}
          styles={{
              rowTitleColor: "#226920",
              rowContentPaddingLeft: '10px'
          }} 
          getRowOptions={setRowsOption}
      /> </>
                   
                    :null}
    </div>
  );
};
export default GlpForm;

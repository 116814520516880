import React, { useEffect } from "react";

const CraveMost = (props) => {

    const CheckPhysical = (e) => {

        var tabLink = document.querySelectorAll(".option_div");

        tabLink.forEach(box => {
            box.classList.remove('active');
        });

        e.target.closest('.option_div').classList.add("active");

        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");

        if (category === "") {
            props.DisableNextButton();
        } 
        else {
            props.SetCraveMostData(category);
            props.EnableNextButton();
        }
    }

    useEffect(() => {
        setTimeout(() => {    
          if (props.CraveMostData) {
            if (props.CraveMostData !== "") {             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.CraveMostData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading" style={{ maxWidth: '100%' }}>
                    Which type of foods do you crave the most?</span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Carbs" onClick={CheckPhysical}><p>Carbs</p></div>
                <div className="option_div" data-target="Sweets" onClick={CheckPhysical}><p>Sweets</p></div>
                <div className="option_div" data-target="Red Meat" onClick={CheckPhysical}><p>Red Meat</p></div>
                <div className="option_div" data-target="No Carvings" onClick={CheckPhysical}><p>No Carvings</p></div>
            </div>
        </div>
    )
}
export default CraveMost
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables, ChartOptions } from 'chart.js';

const VitalBasalTemp=(props)=>{

    // console.log(props)


const [ChartDataArray,SetChartDataArray]=useState()
const [Chartlabel, SetChartlabel] = useState()



const titleTooltip = ctx => {

    const ArrayIndex=ctx[0].dataIndex

    var ReturnArray=[]

    ReturnArray[0]='Vital Basal Temperature-(Manual)'
    ReturnArray[1]='Temprature(f) :'+props.VitalBasalTempData[ArrayIndex].temp_value
    ReturnArray[2]='Location:'+props.VitalBasalTempData[ArrayIndex].location
    ReturnArray[3]='Notes :'+props.VitalBasalTempData[ArrayIndex].notes

    return ReturnArray
  }

    const Weightstate = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Vital Basal Temperature',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: ChartDataArray,
                // pointRadius: 0,
            }
        ]
    }
    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },

            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },


            }
        },
        plugins: {
            legend: {
                display: false

            },
            tooltip: {
                
                callbacks: {
                  title: titleTooltip,
                  label: ctx => ctx.label + '- ' + ctx.parsed.y
                }
              }
            }
            
        }

        useEffect(()=>{
            SetChartDataArray(props.DataValue)
            SetChartlabel(props.DataLabel)
        },[props])
    

    return (
        <div className="row chart_row report_chart_row">
     
         <div className="single_col">
            <span className="chart_top_label_progres_diary">Vital Basal Temperature</span>
            <Line
                data={Weightstate}
                options={options}
            />
        </div>
    </div>
    )
}
export default VitalBasalTemp
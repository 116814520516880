import React, { useEffect, useState } from "react";

const GallBladderCS = (props) => {



  const [GallBladderConsent,SetGallBladderConsent]=useState(false)




 

  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

  
  
    if(props.GallBladderConsentPC)
    {
        SetGallBladderConsent(props.GallBladderConsentPC)
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
  

      var Err = 0;
    
      if (GallBladderConsent===false)
      {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GallBladderConsent]);

  useEffect(()=>{



    props.SetGallBladderConsentPC(GallBladderConsent)
  
    


  },[GallBladderConsent])


  useEffect(()=>{
    props.SetPreviousMenu('theseapply')
    props.SetNextMenu('gastricbypass')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row">GALLBLADDER CONSENT</div>

   

    

        <div>
          <h5>Things to know about your gallbladder</h5>
        </div>
        <div>
          <h6>Read the following for more information about this product and its potential side effects.:</h6>
        </div>
        <p>
          
        Gallbladder disease information
You noted that you have had gallbladder stones, problems, or your gallbladder removed. This medication is still a good option however the primary concern is that this medication can affect the way the body handles fats and bile. Without a gallbladder, the body's ability to store and release bile is altered. Bile is crucial for the digestion and absorption of fats. This medication may increase the likelihood of gastrointestinal side effects in these individuals because it can alter fat metabolism and bile flow. This can lead to symptoms such as diarrhea and stomach pain.
Risks of gallbladder disease and weight loss medication.
Additionally, medications that affect digestion and appetite, like this medication, might alter the absorption and metabolism of other nutrients (like fat soluble vitamins such as vitamin A, D, E, and K) and medications. This is particularly important for those without a gallbladder, as their digestive system already operates differently from those with a functioning gallbladder. If you wish to move forward, it is important to eat smaller and more frequent meals. In addition, to ensure that you're receiving enough vitamins, you should avoid processed foods and eat lots of vegetables, as well as considering the use of a multi-vitamin unless told by your provider to avoid these for other reasons.
        </p>
        <input type="checkbox" className="consent_checkbox" checked={GallBladderConsent===true} onChange={(e)=>{SetGallBladderConsent(e.target.checked)}}/>By checking this box, you acknowledge the above information
      

     








    </div>
  );
};
export default GallBladderCS;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Fullscript } from "@fullscript/fullscript-js";
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const CoachFullscript = (props) => {

    const token = localStorage.getItem("token");

    const navigate = useNavigate();

    const [fscriptAccess, setfscriptAccess] = useState(false);
    const [loadtab, setloadtab] = useState(false);

    const client_id = props.data;

    useEffect(() => {
        loading_icon('show');
        connect_fullscript();
    }, []);


    const axiosJWT = axios.create();
    
    const connect_fullscript = async () => {       

        //get client fullscript user id
        const client_response = await axiosJWT.get("/user/"+client_id,
        {
            headers: {
               Authorization: `Bearer ${token}`
            }
        });        
        
        const fscript_user_id = client_response.data.coach_client_relations[0].fscript_user_id;


        //get coach access token
        const user_response = await axiosJWT.get("/user",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


        if(user_response.data.fscript_token_expiry)
        {
                const session_grant = await axiosJWT.get("/fullscript/session-grant",
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });            

                var fscript_practitioner_data = session_grant.data.practitioner;

                if(fscript_practitioner_data)
                {
                    var fullscriptClient = Fullscript({
                        publicKey: process.env.REACT_APP_FSCRIPT_CLIENT_ID,
                        env: process.env.REACT_APP_FSCRIPT_ENV,
                    });

                    var treatmentPlanFeature = fullscriptClient.create("treatmentPlan", {
                            secretToken: fscript_practitioner_data.secret_token,
                            patient: {
                           // id: "6d1b1ec1-6ca7-4760-a024-c9899ced53e8",
                            //id: "0fd9117e-e502-4643-bd38-31d9a6a33aac"
                            //id: "a71ae0da-dfe6-4b19-a8fe-df81f9e21a68"
                            id: fscript_user_id
                            },
                        });   
                    
                    loading_icon('hide');
                    setfscriptAccess(true);
                    
                    treatmentPlanFeature.mount("treatment-plan-container");
                }
        }
    }

    const profile_redirect = () => {
        navigate("/profile", { replace: true });
    }

    const backTo = () => {
        setfscriptAccess(false);
        loading_icon('show');
        connect_fullscript();
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }
   
    return (
        <div className='fullscript_tab'>
            <div style={{ display: "none" }} id="semiTransparenDivLoader"></div> 
            {fscriptAccess === true ? 
            <>
                <div className='menubox'>
                    <Button className='green' onClick={backTo}>Home</Button>
                </div>
                <div style={{width:"100%",height:"700px"}} id="treatment-plan-container"></div>    
            </>
            : 
            <>
            <p style={{marginTop: "50px",textAlign: "center"}}>You must provide access of Fullscript.! Please check in your <a className='link' onClick={(e)=>profile_redirect()}>account page</a> for fullscript access.</p>
            </> }                     
        </div>
    )
}

export default CoachFullscript
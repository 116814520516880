import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const BodyWeightPounds=(props)=>{

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess,SetsaveAddSuccess]=useState(false)
    const [BodyCompValue, SetBodyCompValue] = useState('')
    const [BodyCompValErr, SetBodyCompValErr] = useState(false)
    const [BloodDailyValue, SetBloodDailyValue] = useState('')
    const [BodyFatTypeValue, SetBodyFatTypeValue] = useState('')

    const SaveBodyComp = async (e) => {
        const action = e.target.getAttribute('data-action')

        if(action==='cancel')
        {
            props.SetShowBodyCompWeightPoundsField(false)
            props.SetBodyCompOption(true)
            return false
        }

        SetBodyCompValErr(false)

        if (BodyCompValue !== '') {

            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
         
            const response = await axiosJWT.post('add-data/add-body-com',
                {

                    'date': passDate,
                    'time': TImePicker,
                    'type': 'Weight',
                    'value': BodyCompValue,
                    'fat_type': BodyFatTypeValue,
                    'client_id':IsClientid,
                    'notes': Notes
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowBodyCompWeightPoundsField(false)
                    props.SetBodyCompOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }


        }
        else {

            SetBodyCompValErr(true)
        }




    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }


    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Body composition > weight'}</h6>
<div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Weight(Pounds):</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetBodyCompValue(e.target.value) }}  {...(BodyCompValErr === true ? { id: 'err_border' } : {})} defaultValue={BloodDailyValue} />
                </div>
            </div>

          

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>


            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveBodyComp(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveBodyComp(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveBodyComp(e) }} >
                    {'Save'}
                </Button>

           </div>

           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

         </div>

    )
}
export default BodyWeightPounds
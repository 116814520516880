import React, { useEffect, useState } from "react";
import axios from "axios";

import AvailableCoach from "./availablecoach";


const ClientAppointment = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

    useEffect(()=>{
        document.title = 'Client-Appointments';
    },[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>

            <section className="dashboard">
                <div className="client_appointment container">
                    <div className="row client_top_appointment_row">
                        <span className="client_top_appointment_row_span">Appointment</span>
                    </div>
                    <AvailableCoach />

                </div>
            </section>

        </div>
    )
}

export default ClientAppointment
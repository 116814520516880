import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import ClientListQuestionnaire from "./list-questionnaire";


const ClientQuestionnaire = (formData) => {

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();

  return (

    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className="dashboard">
        <div className="container">
          <ClientListQuestionnaire />



        </div>
      </section>
    </div>
  )
}


export default ClientQuestionnaire

import React, { useEffect, useLayoutEffect, useState } from "react";
import TickIcon from '../../images/tick.png'
import mainLogo from '../../images/fitpal_logo.png';
import { useNavigate } from 'react-router-dom';


const RegistrationSuccess = () => {
    const navigate = useNavigate();

const NavigateLogin=()=>{
    navigate("/login", { replace: true });
}

    return (
        <div className="payment_success_page">            
            <div className="payment_success_card">
                <div className="logo_row">
                <img id='clickimg' className='login_logo_img' src={mainLogo} alt="mainLogo" />
                </div> 
                    <div className="success_tick_row"><img src={TickIcon}/></div>
                    <div className="heading_row">
                        <h3>Registration Success!</h3>
                    </div>
                    <div className="content_row">
                        You have successfully registered as a client, We have mailed a login credentials to a given mail. Please login and enjoy the diet journey here.!
                    </div>
                    <div className="button_row">
                    <button className="button is-success is-fullwidth btn btn-success login_but" onClick={NavigateLogin}>Login</button>
                    </div>
            </div>
        </div>
    )
}
export default RegistrationSuccess
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Dietplan from './Weekplan/Dietplan';
import Favorites from './Weekplan/Favorites';
import Library from './Weekplan/Library';

const ClientWeekPlan = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    const [TabMenu, setTabMenu] = useState('dietplan');

    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setTabMenu(tab_for);

    }

useEffect(()=>{
    document.title = 'Client-AI meal plan';
},[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="client_weekplan container">
                    <div className='menubox'>
                        <div className='tabmenu'>
                            <a className="active" onClick={changeTab} data-item="dietplan">Diet Plan</a>
                            <a onClick={changeTab} data-item="favorites">Favorites</a>
                            <a onClick={changeTab} data-item="library">Library</a>
                        </div>
                    </div>

                    {(TabMenu === 'dietplan') ? <Dietplan data={userId} /> : null}

                    {(TabMenu === 'favorites') ? <Favorites data={userId} /> : null}

                    {(TabMenu === 'library') ? <Library data={userId} /> : null}
                </div>
            </section>
        </div>
    )
}

export default ClientWeekPlan
import React, { useEffect, useState } from "react";

const PregnantCS = (props) => {



  const [PregnantConsent,SetPregnantConsent]=useState(false)




 

  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

  
  
    if(props.PregnantConsentPC)
    {
      SetPregnantConsent(props.PregnantConsentPC)
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
  

      var Err = 0;
    
      if (PregnantConsent===false)
      {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [PregnantConsent]);

  useEffect(()=>{



    props.SetPregnantConsentPC(PregnantConsent)
  
    


  },[PregnantConsent])


  useEffect(()=>{
    props.SetPreviousMenu('breastfeeding')
    props.SetNextMenu('bmi_details')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

   

    

        <div>
          <h5>Please review and agree to the following breastfeeding/pregnancy precaution</h5>
        </div>
        <div>
          <h6>Read the following for more information about the product and it's potential side effects:</h6>
        </div>
        <p>
          
Pregnancy and Breastfeeding precaution
The FDA says that these medications may pose a risk to the developing fetus (baby) if you get pregnant while taking it. "The pill" (oral contraception) is not enough because this medication can interfere with the effectiveness. For birth control you must use condoms, an IUD, or an implanted device. Official FDA
recommendation: For the first month after first starting a weight loss medication, and for the first month after each dose escalation, continue using your oral contraception and add a barrier method of contraception (such as condoms) to prevent pregnancy.
Alternatively, you can switch to a non-oral contraceptive method (such as an IUD or implant) before starting your medication. When you stop the medication, you must continue using a backup method (like condoms) for two months after stopping to allow the medication to fully leave your system before getting pregnant. Similarly, it is not known to be safe in breastfeeding. If you are breastfeeding, consult your doctor for safe weight loss options
        </p>
        <input className="consent_checkbox" type="checkbox" checked={PregnantConsent===true} onChange={(e)=>{SetPregnantConsent(e.target.checked)}}/>By checking this box, you acknowledge the above information
      

     








    </div>
  );
};
export default PregnantCS;

import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DummyrecipeImg from '../../../images/pexels-photo-1640777.jpeg'
import ReactToPrint from 'react-to-print';
import { Pie } from 'react-chartjs-2';

const RecipePreview = (props) => {
    const componentRef = useRef();

    const [RecipeIngredient, SetRecipeIngredient] = useState('')
    const [RecipeInstruction, SetRecipeInstruction] = useState('')
    const [RecipeComments, SetRecipeComments] = useState('')
    const [RecipeUid, SetRecipeUid] = useState('')
    const [RecipeName, SetRecipeName] = useState('')
    const [RecipeImg, SetRecipeImg] = useState('')
    const [RecipeCategory, SetRecipeCategory] = useState('')
    const [RecipePrepTIme, SetRecipePrepTIme] = useState('')
    const [RecipeServings, SetRecipeServings] = useState('')
    const [RecipeCalories, SetRecipeCalories] = useState('')
    const [RecipeFats, SetRecipeFats] = useState('')
    const [RecipeProteins, SetRecipeProteins] = useState('')
    const [RecipeCarbs, SetRecipeCarbs] = useState('')
    const [RecipeSourceURL, SetRecipeSourceURL] = useState('')
    const [recipetab, setRecipeTabMenu] = useState('ingredients')
    const [PercentageArray, SetPercentageArray] = useState([])
    const pie_options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const pie_data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '# of Votes',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };


    const ClosePreviewRecipe = () => {
        props.SetRecipePreviewShow(false)
    }
    const changeRender = (str) => {
        str = str.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
        return str

    }

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
    }

    useEffect(() => {

        if (props.RecipePreviewData.recipe_ingredients !== null) {
            SetRecipeIngredient(props.RecipePreviewData.recipe_ingredients)
        }
        if (props.RecipePreviewData.recipe_instructions) {
            SetRecipeInstruction(props.RecipePreviewData.recipe_instructions)
        }
        if (props.RecipePreviewData.recipe_comments !== null) {
            SetRecipeComments(props.RecipePreviewData.recipe_comments)
        }
        if (props.RecipePreviewData.recipe_uid !== null) {
            SetRecipeUid(props.RecipePreviewData.recipe_uid)
        }
        if (props.RecipePreviewData.recipe_name !== null) {
            SetRecipeName(props.RecipePreviewData.recipe_name)
        }
        if (props.RecipePreviewData.recipe_image !== null) {
            SetRecipeImg(props.RecipePreviewData.recipe_image)
        }
        if (props.RecipePreviewData.recipe_category !== null) {
            SetRecipeCategory(props.RecipePreviewData.recipe_category)
        }
        if (props.RecipePreviewData.recipe_time !== null) {
            SetRecipePrepTIme(props.RecipePreviewData.recipe_time)
        }
        if (props.RecipePreviewData.recipe_servings !== null) {
            SetRecipeServings(props.RecipePreviewData.recipe_servings)
        }
        if (props.RecipePreviewData.recipe_calories !== null) {
            SetRecipeCalories(props.RecipePreviewData.recipe_calories)
        }
        if (props.RecipePreviewData.recipe_proteins !== null) {
            SetRecipeProteins(props.RecipePreviewData.recipe_proteins)
        }
        if (props.RecipePreviewData.recipe_fats !== null) {
            SetRecipeFats(props.RecipePreviewData.recipe_fats)
        }
        if (props.RecipePreviewData.recipe_carbs !== null) {
            SetRecipeCarbs(props.RecipePreviewData.recipe_carbs)
        }
        if (props.RecipePreviewData.recipe_source_url !== null) {
            SetRecipeSourceURL(props.RecipePreviewData.recipe_source_url)
        }
        if (props.RecipePreviewData.recipe_carbs !== null && props.RecipePreviewData.recipe_fats !== null && props.RecipePreviewData.recipe_proteins !== null) {
            SetPercentageArray([props.RecipePreviewData.recipe_proteins, props.RecipePreviewData.recipe_fats, props.RecipePreviewData.recipe_carbs])
        }


    }, [props.RecipePreviewData])

    return (

        <div>

            <Modal show={props.RecipePreviewShow} onHide={ClosePreviewRecipe} className="coach_plan_recipe_modal_popup">
                <Modal.Header closeButton>
                    <Modal.Title>Preview Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={componentRef}>

                 
                    <div className='recipe_data'>
                        <div className='title'>{RecipeName}</div>
                        {
                            RecipeImg !== '' ? <img src={RecipeImg} /> : <img src={DummyrecipeImg} />
                        }
                        <div className='nutrition_data'>
                            <p className='pie_chart_kcal_data'>Calories: {RecipeCalories} Kcal</p>
                            <Pie data={pie_data} options={pie_options} />
                            <hr />
                            <div className="pie_chart_bottom_div">

                                <span>
                                    <span className="dotcarbs"></span>
                                    <span className="pie_chart_bottom_div_label">Carbs</span>
                                    <span className="pie_chart_bottom_div_data"><span>{RecipeCarbs}</span><span>G</span></span>
                                </span>

                                <span>
                                    <span className="dotfat"></span>
                                    <span className="pie_chart_bottom_div_label">Fat</span>
                                    <span className="pie_chart_bottom_div_data"><span>{RecipeFats}</span><span>G</span></span>
                                </span>

                                <span>
                                    <span className="dotprotein"></span>
                                    <span className="pie_chart_bottom_div_label">Protein</span>
                                    <span className="pie_chart_bottom_div_data"><span>{RecipeProteins}</span><span>G</span></span>
                                </span>

                            </div>
                        </div>
                        <div style={{ "clear": "both", "marginBottom": "20px" }} />
                        <div className='menubox'>
                            <div className='recipetabmenu'>
                                <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                <a onClick={changeRecipeTab} data-item="overview">Overview</a>
                            </div>
                        </div>
                        <div style={{ "clear": "both", "marginBottom": "20px" }} />
                        {recipetab === 'ingredients' ?
                            <div className='ingredients'>
                                <div dangerouslySetInnerHTML={{ __html: changeRender(RecipeIngredient) }}></div>
                            </div> : null
                        }
                        {recipetab === 'steps' ?
                            <div className='steps'>
                                <div dangerouslySetInnerHTML={{ __html: changeRender(RecipeInstruction) }}></div>
                            </div> : null
                        }
                        {
                            recipetab === 'overview' ?
                                <div className="overview">

                                    <div className="preview_imported_recipe_row">
                                        <span className="title">Recipe UID: </span>
                                        <span>{RecipeUid}</span>
                                    </div>

                                    <div className="preview_imported_recipe_row">
                                        <span className="title">Recipe Category: </span>
                                        <span>{RecipeCategory}</span>
                                    </div>

                                    <div className="preview_imported_recipe_row">
                                        <span className="title">Recipe Prep.time: </span>
                                        <span>{RecipePrepTIme !== '' ? RecipePrepTIme + ' mins' : ''}</span>
                                    </div>

                                    <div className="preview_imported_recipe_column">
                                        <div className="title">Additional Comments :</div>
                                        <div dangerouslySetInnerHTML={{ __html: changeRender(RecipeComments) }}>
                                        </div>
                                    </div>

                                    <div className="preview_imported_recipe_row">
                                        <span className="title">Source URL: </span>
                                        <a href={RecipeSourceURL} target={'_blank'}>{RecipeSourceURL}</a>
                                    </div>

                                </div> : null
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer className="display_block">

                    <ReactToPrint
                        trigger={() => <button className='withdraw_but export_doc_button'>Download PDF</button>}
                        content={() => componentRef.current}
                    />

                    <Button className="modal_close_button float_right" data-action="confirm" variant="primary" onClick={ClosePreviewRecipe}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default RecipePreview
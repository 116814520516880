/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const CoachBlog = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");

    var [Blogs, setBlogs] = useState([]);

    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const [editorData, seteditorData] = useState('');

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const [show, setShow] = useState(false);
    const [modalPopup, setModal] = useState();
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [blogId, setblogId] = useState('');
    const [blogTitle, setblogTitle] = useState('');
    const [blogcategoryId, setblogcategoryId] = useState('');
    const [blogWeight, setblogWeight] = useState(0);
    const [categoryOptions, setcategoryOptions] = useState([]);

    const [BlogTitleErr, SetBlogTitleErr] = useState(false)
    const [BlogTitleErrMsg, SetBlogTitleErrMsg] = useState('')

    const [BlogContentErr, SetBlogContentErr] = useState(false);
    const [BlogContentErrMsg, SetBlogContentErrMsg] = useState('');

    const [BlogCatErr, SetBlogCatErr] = useState(false);
    const [BlogCatErrMsg, SetBlogCatErrMsg] = useState('');





    useEffect(() => {
        document.title = 'Coach-Blogs';
        getBlogs();
    }, []);

    const handleClose = () => setShow(false);

    const axiosJWT = axios.create();

    const getBlogs = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/blog?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setBlogs(response.data.blogs);
        setTotalCount(response.data.totalcount);
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getBlogs(pagenumber);
    }

    const handleShow = async (e) => {



        const blogId = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");
        SetBlogTitleErr(false)
        SetBlogCatErr(false)
        SetBlogContentErr(false)
        SetBlogImgErr(false)


        setblogId(blogId);
        setModal(datamodal);

        if (datamodal === 'blog_add') {
            setblogTitle('');
            seteditorvalue(EditorState.createEmpty());
            setblogcategoryId('');
            setblogWeight(0);
            SetBlogImgSrc('')
            setBlogImgfile(null)
            SetshowCrossIcon(false)
            SetImageShowDiv(false)

            const response = await axiosJWT.get("/nutrition-service/list-service-categories", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            var category_data = response.data.final_list;
            var category_list = [];

            category_data.forEach(element => {
            
                category_list.push({ label: element.category_name, value: element.category_id });
            });

          
            setcategoryOptions(category_list);
            setShow(true);
        }

        if (datamodal === 'blog_edit') {
            const response = await axiosJWT.get("/blog/" + blogId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
          

          

            SetBlogImgSrc(response.data.blog_img)
            SetshowCrossIcon(true)
            SetImageShowDiv(true)

            if (response.data.content !== '') {
                var editor_content = ContentState.createFromBlockArray(convertFromHTML(response.data.content));
                seteditorvalue(EditorState.createWithContent(editor_content));
            }
            else {
                seteditorvalue(EditorState.createEmpty());
            }

    
            setblogTitle(response.data.title);
            setblogWeight(response.data.weight);

            const response_category = await axiosJWT.get("/nutrition-service/list-service-categories", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            var category_data = response_category.data.final_list;
            var category_list = [];

            category_data.forEach(element => {
              
                category_list.push({ label: element.category_name, value: element.category_id });

                if (response.data.category_id === element.category_id)
                    setblogcategoryId({ label: element.category_name, value: element.category_id })

            });


            setcategoryOptions(category_list);
            setShow(true);
            var output = document.getElementById('output')
           
        }

        if (datamodal === 'delete_confirm') {
            const response = await axiosJWT.get("/blog/" + blogId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

         

            setblogTitle(response.data.title);
            setDeletePopup(true);
        }
    }


    const editorChange = (data) => {
        seteditorData(data.blocks);
    }

    const deleteBlog = async (e) => {

        const blogId = e.target.getAttribute("data-item");

        const response = await axiosJWT.delete('/blog/' + blogId,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleDeleteClose();
            getBlogs();
        }
    }

    const handleDeleteClose = () => {
        setblogId();
        setDeletePopup(false);
    }

    const createBlog = async () => {

        SetBlogTitleErr(false)
        SetBlogCatErr(false)
        SetBlogContentErr(false)
        SetBlogImgErr(false)
    
   

        var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));
      

        var err = false;
      

        if (blogTitle === '') {
            SetBlogTitleErr(true)
            SetBlogTitleErrMsg('Please enter blog title')
            err = true
        }
        if (blogcategoryId === '') {
            SetBlogCatErr(true)
            SetBlogCatErrMsg('Please select category')
            err = true
        }
        if (editorData === '') {
            SetBlogContentErr(true)
            SetBlogContentErrMsg('Please enter blog content')
            err = true
        }
        if (editorData !== '') {
            if (editorData[0].text === '') {
                SetBlogContentErr(true)
                SetBlogContentErrMsg('Please enter blog content')
                err = true

            }

        }
        if(BlogImgfile===null || BlogImgfile==='')
        {
            SetBlogImgErr(true)
            SetBlogImgErrMsg('Please upload blog image')
            err = true

        }


        const formData = new FormData();

        formData.append('title', blogTitle);
        formData.append('content', editor_content);
        formData.append('category_id', blogcategoryId.value);
        formData.append('blog_img', BlogImgfile);
        formData.append('weight', blogWeight);
        
       
        if (err === true) {
            return false
        }


  
        if (blogTitle !== '' && editor_content !== '' && blogcategoryId !== '') {
            ShowLoader()
            const response = await axiosJWT.post("/blog/",
            formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleClose();
                getBlogs();
                HideLoader()
            }
        }
    }

    const updateBlog = async (e) => {

        const blogId = e.target.getAttribute("data-item");

        var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));


        var err = false;

        if (blogTitle === '') {
            SetBlogTitleErr(true)
            SetBlogTitleErrMsg('Please enter blog title')
            err = true
        }
        if (blogcategoryId === '') {
            SetBlogCatErr(true)
            SetBlogCatErrMsg('Please select category')
            err = true
        }
        if (editorData !== '') {
            if (editorData[0].text === '') {
                SetBlogContentErr(true)
                SetBlogContentErrMsg('Please enter blog content')
                err = true

            }

        }

        if(BlogImgSrc===null || BlogImgSrc==='')
        {

            SetBlogImgErr(true)
            SetBlogImgErrMsg('Please upload blog img')
            err=true

        }
        const formData = new FormData();
        if(BlogImgfile!==null)
        {

         

            formData.append('title', blogTitle);
            formData.append('content', editor_content);
            formData.append('category_id', blogcategoryId.value);
            formData.append('blog_img', BlogImgfile);
            formData.append('weight', blogWeight);

        }
        else{


            formData.append('title', blogTitle);
            formData.append('content', editor_content);
            formData.append('category_id', blogcategoryId.value);
            formData.append('weight', blogWeight);


        }
      

        if (err === true) {
            return false
        }

        if (blogTitle !== '' && editor_content !== '' && blogcategoryId !== '') {

            ShowLoader()
          
            const response = await axiosJWT.put("/blog/" + blogId,
            formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleClose();
                getBlogs();
                HideLoader()
            }
        }
    }

    const searchBlogs = async (event) => {

        var starting_limit = 0;
        var ending_limit = countPerPage

        const response = await axiosJWT.get("/blog?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);

    
        setBlogs(response.data.blogs);
        setTotalCount(response.data.totalcount);
    }

    const SetBlogImage=(event)=>{
        SetBlogImgErr(false)


        const file = event.target.files[0];
        setBlogImgfile(event.target.files[0])

        // If no image selected, return
        if (!/^image\//.test(file.type)) {
            SetBlogImgErr(true)
            SetBlogImgErrMsg(`File ${file.name} is not an image.`)
        
            document.getElementById('img_upload_input_id').value= null;
            return false;
        }
        else{

            var reader = new FileReader();
            reader.onload = function(){
                var output = document.getElementById('output');
                SetBlogImgSrc(reader.result)
                
              };
              reader.readAsDataURL(event.target.files[0]);
              SetshowCrossIcon(true)
              SetImageShowDiv(true)

        }



    }
    const ClearBlogImageInput=()=>{

        document.getElementById('img_upload_input_id').value= null;
        var output = document.getElementById('output');
        SetBlogImgSrc('')
       
        setBlogImgfile(null)
        SetshowCrossIcon(false)
        SetImageShowDiv(false)

    }

    const [showCrossIcon,SetshowCrossIcon]=useState(false)
    const [BlogImgErr,SetBlogImgErr]=useState(false)
    const [BlogImgErrMsg,SetBlogImgErrMsg]=useState('')
    const [BlogImgfile, setBlogImgfile] = useState(null);
    const [BlogImgSrc,SetBlogImgSrc]=useState('')
    const [ImageShowDiv,SetImageShowDiv]=useState(false)

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
                  {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                <div className="coach_blog container">


                    <div className='title_box'>
                        <div className='leftmenu'>
                            <div className='title'>
                                <h4>Blog</h4>
                            </div>
                        </div>
                        <div className='rightmenu action_buttons'>
                            <input className='search' type="text" placeholder="&#xF002; Search" onChange={event => searchBlogs(event.target.value)} />
                            <input className='greenbutton' type="button" value="Create New Blog" onClick={(e) => { handleShow(e) }} data-modal="blog_add" />
                        </div>

                        <div style={{ clear: "both" }} />
                    </div>

                    <Table className="table is-striped is-fullwidth">
                        <Thead className="thead">
                            <Tr>
                                <Th>Title</Th>
                                <Th>Program</Th>
                                <Th>Weightage</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">
                            {Blogs.map((blog, index) => (
                                <Tr className='trow' key={blog.id}>
                                    <Td>{blog.title}</Td>
                                    <Td className={blog.category_id !== null ? (blog.service_category.category_id === 1 ? "purplebox colorbox" : "orangebox colorbox") : null}>{blog.category_id !== null ? blog.service_category.category_name : null}</Td>
                                    <Td>{blog.weight}</Td>
                                    <Td>
                                        <i className="fa fa-edit td_icon action" data-modal="blog_edit" data-item={blog.id} onClick={handleShow}></i><i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-item={blog.id} onClick={handleShow}></i></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    
                    {totalCount ?
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={countPerPage}
                        totalItemsCount={totalCount}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                    : null }

                    <Modal show={show} onHide={handleClose} className='coach_blog_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Blog</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>Title:</label><br />
                                    <input type='text' className='inner_popup_input' defaultValue={blogTitle} onChange={(e) => { setblogTitle(e.target.value) }}  {...(BlogTitleErr===true ? {id: 'err_border'} : {})}></input>

                                    {BlogTitleErr === true &&
                                        <span className="err service_err_field">  {BlogTitleErrMsg}</span>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                <label className='inner_popup_label required'>Blog Image:</label><br />

                              
                                <div className='image_upload_blog_div'>
                                <input type="file" className='img_upload_input' id="img_upload_input_id" accept="image/*" onChange={SetBlogImage} ></input><br />
                                {BlogImgErr === true &&
                                        <span className="err service_err_field">  {BlogImgErrMsg}</span>
                                    }
                                      {ImageShowDiv===true &&
                                <div className='image_show_div'>
                                    {showCrossIcon===true && 
                                <i className="fa fa-times cross_image_blog_show" onClick={ClearBlogImageInput} aria-hidden="true"></i>}
                                <img id="output" className='img_upload_show' src={BlogImgSrc}/>
                                </div>
                                }
                                
                                </div>


                               
                               
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>Content:</label><br />
                                    <Editor
                                        wrapperClassName="texteditor"
                                        editorState={editorvalue}
                                        onEditorStateChange={seteditorvalue}
                                        onContentStateChange={editorChange}
                                    />
                                    {BlogContentErr === true &&
                                        <span className="err service_err_field">  {BlogContentErrMsg}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>Category:</label><br />
                                    <Select
                                        defaultValue={{ label: blogcategoryId.label, value: blogcategoryId.value }}
                                        options={categoryOptions}
                                        onChange={setblogcategoryId}
                                        {...(BlogCatErr===true ? {id: 'err_border'} : {})}
                                    />
                                    {BlogCatErr === true &&
                                        <span className="err service_err_field">  {BlogCatErrMsg}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label'>Weightage:</label><br />
                                    <input type='text' className='inner_popup_input' defaultValue={blogWeight} onChange={(e) => { setblogWeight(e.target.value) }}></input>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='display_block'>
                            <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                                Back
                            </Button>

                            <Button variant="primary" className='modal_action_button float_right' data-item={blogId} onClick={(e) => { modalPopup === 'blog_add' ? createBlog(e) : updateBlog(e) }}>
                                {modalPopup === 'blog_add' ? "Create Blog" : "Update Blog"}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDeletePopup} onHide={handleDeleteClose} className='coach_blog_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Blog</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete Blog - {blogTitle}?
                        </Modal.Body>
                        <Modal.Footer className='display_block'>
                            <Button variant="secondary" className='modal_close_button' onClick={handleDeleteClose}>
                                Cancel
                            </Button>

                            <Button variant="primary" className='modal_action_button float_right' data-item={blogId} onClick={(e) => { deleteBlog(e) }}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div style={{ clear: 'both' }}></div>



                </div>
            </section>
        </div>
    )
}

export default CoachBlog
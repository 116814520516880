import React, { useEffect, useState } from "react";

const BelugaMedicineDetails = (props) => {
    
  const [Weight, SetWeight] = useState("");
  const [HeightFeet,SetHeightFeet]=useState('');
  const [HeightInch,SetHeightInch]=useState('');
  const [BMI,SetBMI]=useState(0)



  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }


  
  }, []);

  useEffect(() => {
    props.DisableNextButton()
 
  

  
  }, [Weight,HeightFeet,HeightInch]);

  useEffect(() => {
    props.DisableNextButton()
    HandlecalculateBMI()
  

  
  }, []);
  const HandlecalculateBMI=()=>{
    calculateBMI(parseInt(Weight),parseInt(HeightFeet),parseInt(HeightInch))
  }

  useEffect(()=>{
    props.SetPreviousMenu('basic_info')
     
    setTimeout(() => {
      if(props.BMIPC!=='' && parseInt(props.BMIPC)>=27)
      {
       
        props.EnableNextButton()
      }
    }, 500);
 
     
  },[])

  const calculateBMI=(Weight,HeightFeet,HeightInch)=>{
   SetBMI(0)
   
   if(Weight!=='' && HeightFeet!=='' && HeightInch!=='')
   {
         // Convert height to inches
    var totalHeightInches = (HeightFeet * 12) + HeightInch;
    
    // Calculate BMI using the provided formula
    var bmi = (Weight / (totalHeightInches * totalHeightInches)) * 703;
    
    // Round BMI to two decimal places
    bmi = Math.round(bmi * 100) / 100;
    
    SetBMI(bmi)

    if(bmi<27)
    {
      
     
        props.toast.error('We are sorry. Your bmi value should be above 27 to take GLP-1 Medications', {
          toastId: 'error1',
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        
          });
    
      
      props.DisableNextButton()
    }
    else if (bmi>=27){
      props.EnableNextButton()
    }
 
   }

   }


   useEffect(()=>{

  
      props.SetPreviousMenu('basic_info')
      props.SetNextMenu('currentmedicalconditions')
     
  },[])



  useEffect(()=>{



    props.SetWeightPC(Weight)
    props.SetHeightFeetPC(HeightFeet)
    props.SetHeightInchPC(HeightInch)
    props.SetBMIPC(BMI)
 
  },[Weight,HeightFeet,HeightInch,BMI])

  useEffect(()=>{

    if(props.WeightPC)
    {
      SetWeight(props.WeightPC)
    }
    if(props.HeightFeetPC)
    {
      SetHeightFeet(props.HeightFeetPC)
    }
    if(props.HeightInchPC)
    {
      SetHeightInch(props.HeightInchPC)
    }
    if(props.BMIPC)
    {
      SetBMI(props.BMIPC)
    }

   

  },[])

  
 

  return (
    <div className="daily_activity beluga_medicine_details">
      <div className="head_row"></div>

      <div className="input_row">
        <div>
          <label>
         What's your Height?
          </label>
          <span style={{color:'red'}}>*</span>
        </div>
        <div className="input_row feet_inch_row">
        <input type="text"  placeholder={'Ft'} defaultValue={HeightFeet} value={HeightFeet} onChange={(e) => { SetHeightFeet(e.target.value) }} onBlur={HandlecalculateBMI} ></input>
        <input type="text" placeholder={'In'} defaultValue={HeightInch} value={HeightInch} onChange={(e) => { SetHeightInch(e.target.value) }} onBlur={HandlecalculateBMI}></input>
        </div>
        
      </div>

      <div className="input_row">
        <div>
          <label>
         What's your Weight?
          </label>
          <span style={{color:'red'}}>*</span>
        </div>
        <div className="input_row weight_input_row">
        <input type="text" placeholder={'lbs'} defaultValue={Weight} value={Weight} onChange={(e) => { SetWeight(e.target.value) }} onBlur={HandlecalculateBMI}></input>
        </div>
        
      </div>

      <div className="input_row bmi_input_row">
        <div>
          <label>
         Your BMI value:
          </label>
        </div>
        <div className="input_row weight_input_row">
        <input type="text" disabled={true} defaultValue={BMI} value={BMI} onChange={(e) => { SetBMI(e.target.value) }}></input>
        </div>
        
      </div>

    
    </div>
  );
};
export default BelugaMedicineDetails;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';

const Caloriemama = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");

    const axiosJWT = axios.create();

    const generateCalorie = (e) => {

        const file = e.target.files[0];

        console.log(file)

        var formData = new FormData();
        formData.append('file', file);

        fetch("https://api-2445582032290.production.gw.apicast.io/v1/foodrecognition?user_key=3e87a2c2ecf8dc3bc1d9c209343fc7d4",
        {
            method: 'POST',
            body: formData            
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            console.log(data);
            // $('#output').text(JSON.stringify(data, null, 2));
        });

        
        console.log("hai")
    }

    // var _affirm_config = {
    //     public_api_key:process.env.REACT_APP_AFFIRM_PUBLIC_KEY,
    //     script:process.env.REACT_APP_AFFIRM_JS_URL
    //   };
    
    //   var affirm = (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=
    //     !0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");

    // const testaffirm = async () => {
    //     affirm.checkout({
    //         "merchant":{"user_confirmation_url":"https://app.fitpal.com:3000/payments/success?gateway=affirm&type=client_subscription","user_cancel_url":"https://app.fitpal.com/payments/cancel?gateway=affirm&type=client_subscription","public_api_key":"M61VXLMGWA43FE7S","user_confirmation_url_action":"POST","name":"Fitpal"},"shipping":{"name":{"first":"Randy","last":"ordan"},"address":{"line1":"te","line2":"st","city":"Adams","state":"NY","zipcode":"600008","country":"USA"},"phone_number":"+911234567890","email":"randyordan@test.com"},"billing":{"name":{"first":"Randy","last":"ordan"},"address":{"line1":"te","line2":"st","city":"Adams","state":"NY","zipcode":"600008","country":"USA"},"phone_number":"+911234567890","email":"randyordan@test.com"},"items":[{"display_name":",l,l","sku":"1","unit_price":90,"qty":1,"item_image_url":"","item_url":""}],"metadata":{"shipping_type":"","mode":"modal"},"order_id":12,"currency":"USD","shipping_amount":0,"tax_amount":0,"total":90
    //     });
    //     affirm.checkout.open();
    // }
    
    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="container">   
                    
                    <input type={'file'} className="" id="img_upload_input_id" onChange={(e) => { generateCalorie(e) }} />

                    {/* <Button onClick={testaffirm}>Test Affirm</Button> */}
                    
                </div>
            </section>            
        </div>
    )
}

export default Caloriemama

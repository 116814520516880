import { Doughnut } from 'react-chartjs-2';

const AdminTotalInvited = ({ total_onboarded_dough, total_invited_dough }) => {


    const data = {
        labels: ['Onboarded', 'Invited'],
        innerRadius: 10,
        datasets: [
            {
                label: 'Attendance for Week 1',
                data: [total_onboarded_dough, total_invited_dough],
                borderColor: ['rgba(255,206,86,0.2)'],
                backgroundColor: ['#51cd51',
                    '#FFAF8C3F',
                ],
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
                hoverBackgroundColor: ['#51cd51',
                    '#FFAF8C3F',
                ],

            },



        ]

    }

    const options = {
        plugins: {


            title: {

                display: false,
            },
            legend: {
                display: false //This will do the task
            },


        }


    }




    return (
        <Doughnut data={data} options={options} />
    )
}

export default AdminTotalInvited;
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const CustomValAdd = (props) => {

 useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [CustomValue,SetCustomValue]=useState('')
    const [CustomvalueErr,SetCustomvalueErr]=useState(false)
    const [CustomCatId,SetCustomCatId]=useState(props.SelectedCustomId)
    const [CustomCatName,SetCustomCatName]=useState(props.SelectedCustomName)
    

    const SaveCustomVal=async(e)=>{
        const action = e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowCustomValfield(false)
            props.SetCustomOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetCustomvalueErr(false)
        
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(CustomValue==='')
        {
            err=true;
            SetCustomvalueErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
           
            const response = await axiosJWT.post('add-data/add-custom-data',
                {

                    'date': passDate,
                    'time': TImePicker,
                    'custom_cat_id':CustomCatId,
                    'custom_value':CustomValue,
                    'client_id':IsClientid,
                    
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.status===200)
                {
                    if(action==='save')
                    {
                        props.SetShowCustomValfield(false)
                        props.SetCustomOption(true)
                  
                    }
                    else if(action==='save_add')
                    {
                        SetsaveAddSuccess(true)
                        setTimeout(() => {
                            SetsaveAddSuccess(false)   
                        }, 1000);
                    }
                    
                }

        }
        

    }
    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Custom > '+props.SelectedCustomName}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                        />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Value{'('+props.SelectedCustomUnit+')'}</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetCustomValue(e.target.value) }}  {...(CustomvalueErr === true ? { id: 'err_border' } : {})} defaultValue={CustomValue} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveCustomVal(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveCustomVal(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveCustomVal(e) }} >
                    {'Save'}
                </Button>

           </div>

           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )

}
export default CustomValAdd
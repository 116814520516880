import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import mainLogo from '../images/fitpal_logo.png';
import ReCAPTCHA from "react-google-recaptcha";
import fitnessimg from '../images/action-1850677_960_720.jpg'
//import axios from "../http-common";
import AuthCode from 'react-auth-code-input';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [IdleErr, SetIdleErr] = useState('');
    const navigate = useNavigate();

    const inputRef = useRef();

    const [twofactorAuth, settwofactorAuth] = useState(false);
    const [loginHide, setloginHide] = useState(false);    
    const [tokenRole, settokenRole] = useState('');
    const [accessToken, setaccessToken] = useState('');
    const [refreshToken, setrefreshToken] = useState('');
    const [tfaMsg, settfaMsg] = useState('');

    const Auth = async (e) => {

        SetEmailAddressErr(false);
        SetPasswordErr(false);
        SetEmailAddressBErr(false);
        SetPasswordBErr(false);
        setMsg('');
        e.preventDefault();
        var err = false;

        if (email === '') {
            err = true;
            SetEmailAddressErr(true);
            SetEmailAddressErrMsg('Please enter email address.!');
        }
        if (password === '') {
            err = true;
            SetPasswordErr(true);
            SetPasswordErrMsg('Please enter password.!');
        }
        if (ReCaptchaErr === true) {
            err = true;
            setMsg('Please verify recaptcha.!');
        }
        
        if (err === false) {
            try {
                await axios.post('/login', {
                    email: email,
                    password: password
                }).then((response) => {
                    if(localStorage.getItem('fitpal_'+email)===null)
                    {                        
                        SetIdleErr('');
                        settwofactorAuth(true);
                        setloginHide(true);

                        const decoded = jwt_decode(response.data.accessToken);
                        settokenRole(decoded.role);
                        setaccessToken(response.data.accessToken);
                        setrefreshToken(response.data.refreshToken);                        

                        axios.post('/password/get-otp-2fa', {
                            email: email
                        }).then((getotp)=>{
                            //settfaMsg(getotp.data.msg);                            
                        });
                    }
                    else
                    {
                        const decoded = jwt_decode(response.data.accessToken);
                        localStorage.setItem("role", decoded.role);
                        localStorage.setItem("token", response.data.accessToken);
                        localStorage.setItem("Refreshtoken", response.data.refreshToken);
                        localStorage.setItem("fitpal_"+email, true);

                        if (response.data.role === 'Admin') {
                            navigate("/admin/dashboard", { replace: true });
                        }
                        else if (response.data.role === 'Nutrition') {
                            navigate("/coach/dashboard", { replace: true });
                        }
                        else if (response.data.role === 'Client') {
                            navigate("/client/dashboard", { replace: true });
                        }
                        else if (response.data.role === 'Manager') {
                            navigate("/manager/dashboard", { replace: true });
                        }

                        window.location.reload(true);
                    }
                });
            } catch (error) {
                if (error.response) {
                    if (error.response.data.type === 'email_err') {
                        SetEmailAddressBErr(true);
                    }
                    if (error.response.data.type === 'pass_err') {
                        SetPasswordBErr(true);
                    }
                    setMsg(error.response.data.msg);
                }
            }
        }
    }

    useEffect(() => {

        // if(localStorage.getItem('token')!=='')
        // {

        //          if(localStorage.getItem('role')==='Admin')
        //           {
        //             navigate("admin/dashboard", { replace: true });
        //           }
        //           else if(localStorage.getItem('role')==='Nutrition'){
        //               navigate("coach/dashboard", { replace: true });
        //           }
        //           else if(localStorage.getItem('role')==='Client')
        //           {
        //               navigate("client/dashboard", { replace: true }); 
        //           }

        // }      

        if (localStorage.getItem("AUTOLOGOUTSESSION") === 'YES') {
            SetIdleErr('You have been idle for a long time. Hence you are logged out!');
            localStorage.setItem('AUTOLOGOUTSESSION', 'NO');
        }
    }, []);

    const [EmailAddressErr, SetEmailAddressErr] = useState(false);
    const [EmailAddressBErr, SetEmailAddressBErr] = useState(false);
    const [EmailAddressErrMsg, SetEmailAddressErrMsg] = useState('');
    const [PasswordErr, SetPasswordErr] = useState(false);
    const [PasswordBErr, SetPasswordBErr] = useState(false);
    const [PasswordErrMsg, SetPasswordErrMsg] = useState('');
    const [ReCaptchaErr, SetReCaptchaErr] = useState(true);

    const handleVerify = (data) => {
        if (data === null) {
            SetReCaptchaErr(true);
        }
        else if (data !== '') {
            SetReCaptchaErr(false);
        }
    }

    const handleTFA = async (data) => {

        settfaMsg('');

        if(data.length === 6)
        {
            try {
                const response = await axios.post('/password/verify-otp', {
                    email: email,
                    otp: data
                });

                if (response) {
                        localStorage.setItem("role", tokenRole);
                        localStorage.setItem("token", accessToken);
                        localStorage.setItem("Refreshtoken", refreshToken);
                        localStorage.setItem("fitpal_"+email, true);

                        if (tokenRole === 'Admin') {
                            navigate("/admin/dashboard", { replace: true });
                        }
                        else if (tokenRole === 'Nutrition') {
                            navigate("/coach/dashboard", { replace: true });
                        }
                        else if (tokenRole === 'Client') {
                            navigate("/client/dashboard", { replace: true });
                        }
                        else if (tokenRole === 'Manager') {
                            navigate("/manager/dashboard", { replace: true });
                        }

                        window.location.reload(true);
                }
            } catch (error) {
                settfaMsg(error.response.data.msg);
                //inputRef.current.clear();
            }
        }
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth login_screen_section">

            <div className='top_login_row'>

                <div className='initial_row'>

                    <div className='img_div'>
                        {/* <img src={'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img3.webp'}></img> */}
                        <img src={fitnessimg}></img>
                    </div>

                    <div className="hero-body login_card">
                        <div className="container login_inner_card">
                            <img id='clickimg' className='login_logo_img' src={mainLogo} alt="mainLogo" />
                            <p className='err'>{IdleErr}</p>
                            <div className="columns is-centered">
                                <div className="column is-4-desktop">
                                    {!loginHide ?
                                    <form onSubmit={Auth} className="box">

                                        <div className="field mt-5">
                                            <label className="label">Email Address</label>
                                            <div className="controls">
                                                <input type="text" className="commission_inner_input input login_input " placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} {...(EmailAddressErr === true ? { id: 'err_border' } : {})} {...(EmailAddressBErr === true ? { id: 'err_border' } : {})} />
                                            </div>
                                            {
                                                EmailAddressErr === true && <span className='err'>{EmailAddressErrMsg}</span>
                                            }
                                        </div>
                                        <div className="field mt-5">
                                            <label className="label">Password</label>
                                            <div className="controls">
                                                <input type="password" className="commission_inner_input input login_input " placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} {...(PasswordErr === true ? { id: 'err_border' } : {})} {...(PasswordBErr === true ? { id: 'err_border' } : {})} />
                                            </div>
                                            {
                                                PasswordErr === true &&
                                                <span className='err'>{PasswordErrMsg}</span>
                                            }
                                        </div>

                                        <div className='login_reacptcha'>
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                onChange={handleVerify.bind(this)}
                                                onExpired={() => { SetReCaptchaErr(true) }}
                                                onErrored={() => { SetReCaptchaErr(true) }}
                                            />
                                        </div>

                                        <div className='row'>
                                            <a href='/forgot-password' target={'_blank'}>Forgot Password?</a>
                                        </div>
                                        <p className="has-text-centered err">{msg}</p>
                                        <div className="field login_but_div">
                                            <button className="button is-success is-fullwidth btn btn-success login_but">Login</button>
                                        </div>
                                    </form>
                                    :null}
                                    {twofactorAuth ?
                                        <div className='tfa_section'>
                                            <p>You have tried to access from the new device.</p>
                                            <h4> Two-Factor<br/> Authentication Required.!</h4>
                                            <AuthCode onChange={handleTFA} ref={inputRef}/><br/>     
                                            <p className="has-text-centered err">{tfaMsg}</p>                                       
                                            <p>A message with a verification code has been sent to <br/>your registered Email address and Phone Number. The OTP valid for 5 minutes only.<br/> Enter the code to continue.</p>                                            
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import PaymentChart from "./PaymentChart";
import ReactToPrint from 'react-to-print';
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Datepicker from 'react-date-picker';

const CoachPayment = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [PaymentTab, SetPaymentTab] = useState(true)
    const [PaymentMethodTab, SetPaymentMethodTab] = useState(false)
    const [Chartlabels, SetNewChartlabels] = useState([])
    const [ChartDatas, SetNewChartDatas] = useState([])
    const [WithdrawableBalance, SetWithdrawableBalance] = useState('0')
    const [EarningsCurrentMonth, SetEarningsCurrentMonth] = useState('0')
    const [PaymentHistoryList, SetPaymentHistoryList] = useState([])
    const [PaymnentPageNo, SetPaymnentPageNo] = useState(1)
    const [PaymentPerPage, SetPaymentPerPage] = useState(10)
    const [TotalRecordPayment, SetTotalRecordPayment] = useState(0)
    const [paymentmethods, setPaymentMethods] = useState([]);

    const [ShowPaymentSuccess, SetShowPaymentSuccess] = useState(false)
    const [ShowPaymentSuccessMsg, SetShowPaymentSuccessMsg] = useState('')
    const [StripeButtonName, SetStripeButtonName] = useState('Connect with stripe')
    const [StripeButtonEnable, SetStripeButtonEnable] = useState(false)
    const [FromDate, SetFromDate] = useState()
    const [ToDate, SetToDate] = useState()


    const HandleClosePaymentSuccess = () => {
        SetShowPaymentSuccess(false)
    }


    const changePageNumberS = async (pagenumber) => {


        GetPaymentHistory(pagenumber, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate))
        SetPaymnentPageNo(pagenumber)
    }


    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();


    const ChangeTab = (e) => {


        const alltabs = document.querySelectorAll(".coach_payments_top_row span");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "coach_payments_top_span active");
        var tab = e.target.getAttribute('data-tab')
        if (tab === 'payment') {
            SetPaymentTab(true)
            SetPaymentMethodTab(false)

        }
        else if (tab === 'payment_method') {
            SetPaymentTab(false)
            SetPaymentMethodTab(true)
        }


    }

    const [TotalEarnings, SetTotalEarnings] = useState(0)
    const [AmountOnhold, SetAmountOnhold] = useState(0)
    const [StripeWithDrawBalance, SetStripeWithDrawBalance] = useState(0)

    const GetPaymentInfo = async () => {
        const response = await axiosJWT.get('payments/get-payments-info', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


            if (response.data.TotalEarnings[0]) {
                if (response.data.TotalEarnings[0].billing_amount !== undefined) {
                    SetTotalEarnings((response.data.TotalEarnings[0].billing_amount).toFixed(2))
                }
            }




            SetAmountOnhold(response.data.AmountOnhold)
            SetStripeWithDrawBalance(response.data.WithDrawbleBalance)

            if (response.data.NutritionistBillingPayment.length > 0) {


                if (response.data.NutritionistBillingPayment[0].billing_amount !== undefined) {
                    SetEarningsCurrentMonth((response.data.NutritionistBillingPayment[0].billing_amount).toFixed(2))
                }

            }
            SetWithdrawableBalance(response.data.amount_can_be_withdrwan)
        })
    }


    const [CommissionMsg, SetCommissionMsg] = useState('')

    const GetCommissionDetails = async () => {

        try {
            const response = await axiosJWT.get('/nutrition-service/commission-details', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                if (response.data.type === 'fixed') {
                    SetCommissionMsg('Admin has set the commission value of ' + response.data.value + '$ per service plan purchase minimum amount of ' + response.data.min_payment_amount + '$ by client')
                }
                else if (response.data.type === 'percentage') {
                    SetCommissionMsg('Admin has set the commission value of ' + response.data.value + '% per service plan purchase minimum amount of ' + response.data.min_payment_amount + '$ by client')
                }
            }
        } catch (err) {
            SetCommissionMsg(err.response.data.msg)
        }


    }


    useEffect(() => {

        document.title = 'Coach-Payments';
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let type = params.get('type');

        if (type === 'stripe_account_connected') {
            SetShowPaymentSuccessMsg('Stripe Account Connected Successfully')
            SetShowPaymentSuccess(true)
            window.history.pushState({}, document.title, window.location.pathname);
        }
        GetProfileDetails()

        GetPaymentInfo()
        GetchartDetails('this_week')
        GetPaymentHistory(1)
        GetCommissionDetails()
    }, [])


    const GetProfileDetails = async () => {
        SetStripeButtonEnable(false)
        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data.profile_details[0].nutritionist_infos[0].stripe_account_id !== null && response.data.profile_details[0].nutritionist_infos[0].stripe_account_id !== '') {
            SetStripeButtonEnable(true)
            SetStripeButtonName('Stripe Account Connected.!')
        }
    }

    const GetchartDetails = async (range) => {

        Setloader(true);

        var DataPush = [];
        var DatePush = [];

        const response = await axiosJWT.get('payments/get-payments-report?range=' + range + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            Setloader(false);

            if (range === 'this_week' || range === 'last_week') {

                response.data.final_data.forEach((value, index, array) => {
                    DataPush.push(value.amount);
                });

                SetNewChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
                SetNewChartDatas(DataPush);
            }
            else if (range === 'this_month' || range === 'last_month') {
                response.data.final_data.forEach((value, index, array) => {
                    DataPush.push(value.amount);
                    DatePush.push(value.date);
                });

                SetNewChartlabels(DatePush);
                SetNewChartDatas(DataPush);
            }
            else if (range === 'this_year' || range === 'last_year') {

                response.data.final_data.forEach((value, index, array) => {
                    DataPush.push(value.amount);
                    DatePush.push(value.date);
                });

                SetNewChartlabels(DatePush);
                SetNewChartDatas(DataPush);
            }
        });
    }

    const GetPaymentHistory = async (pagenumber, fromdate = '', todate = '') => {
        Setloader(true);

        SetPaymnentPageNo(pagenumber)
        const response = await axiosJWT.get('payments/list-payments-history?offset=' + pagenumber + '&limit=' + PaymentPerPage + '&startdate=' + fromdate + '&todate=' + todate + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            Setloader(false);
            SetPaymentHistoryList([]);
            SetTotalRecordPayment(response.data.total_count);

            if (response.data.NutritionistBillingPayment.length > 0) {
                SetPaymentHistoryList(response.data.NutritionistBillingPayment);
            }
        });
    }

    const componentRef = useRef();

    const ChangeDrop = (value) => {
        GetchartDetails(value);
    }

    const getPaymentMethods = async (currency) => {

        const response = await axiosJWT.get("/payments/paymentmethods?currency=" + currency, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setPaymentMethods(response.data);
    }

    const [loader, Setloader] = useState(false);

    const HideLoader = () => {
        Setloader(false);
    }

    const ShowLoader = () => {
        Setloader(true);
    }

    const ConnectWithStripeAccount = async () => {

        ShowLoader();

        const response = await axiosJWT.post('/payments/connect-stripe-account',
            {

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            HideLoader();
            window.location.href = response.data.account_link;
        }
    }

    const [ShowWithdraw, SetShowWithdraw] = useState(false);
    const HandleCloseWithdraw = () => {
        SetShowWithdraw(false);
    }

    const [ShowWithDrawInput, SetShowWithdrawinput] = useState(false);
    const HandleCloseWithDrawInput = () => {
        SetShowWithdrawinput(false);
    }

    const [WithDrawAMountInput, SetWithDrawAMountInput] = useState(0);
    const [WithDrawAMountInputErr, SetWithDrawAMountInputErr] = useState(false);

    const [showwithdrawmsg, Setshowwithdrawmsg] = useState('');

    const TiggerWithDrawInput = () => {
        SetWithDrawAMountInput(0);
        SetShowWithdrawinput(true);
    }

    const WithDrawBalance = async () => {

        SetWithDrawAMountInputErr(false);

        if (WithDrawAMountInput === '') {
            SetWithDrawAMountInputErr(true);
            return false;
        }

        HandleCloseWithDrawInput();
        ShowLoader();

        try {
            const response = await axiosJWT.get('/payments/stripe-withdraw-balance?amount=' + WithDrawAMountInput + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response) {
                await GetPaymentInfo();
                HideLoader();
                Setshowwithdrawmsg(response.data.msg);
                SetShowWithdraw(true);
            }
        } catch (err) {
            HideLoader();
            Setshowwithdrawmsg(err.response.data.msg);
            SetShowWithdraw(true);
        }
    }

    const FiterPaymentData = (e) => {
        const action = e.target.getAttribute('data-action');

        if (action === 'reset') {
            SetFromDate('');
            SetToDate('');
            GetPaymentHistory(1);
        }
        else {
            if (FromDate !== '' && ToDate !== '') {
                GetPaymentHistory(1, ChangeDatePickerDate(FromDate), ChangeDatePickerDate(ToDate));
            }
        }
    }

    const ChangeDatePickerDate = (pass) => {
        if (pass !== '') {
            var passDate = '';
            var yyyy = pass.getFullYear().toString();
            var mm = (pass.getMonth() + 1).toString();
            var dd = pass.getDate().toString();
            passDate = yyyy + '-' + mm + '-' + dd;
            return passDate;
        }
        else {
            return '';
        }
    }


    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">

                <div className="coachpayments container">
                    <p style={{ fontWeight: '600' }}>{CommissionMsg}</p>
                    <div className="row coach_payments_top_row">
                        <span className="coach_payments_top_span active" onClick={(e) => { ChangeTab(e) }} data-tab='payment'>Payments</span>
                        <span className="coach_payments_top_span" onClick={(e) => { ChangeTab(e) }} data-tab='payment_method'>Payment methods</span>
                    </div>

                    {PaymentTab === true &&
                        <section>
                            <div className="row payment_inner_top_row container">

                                <div className="col_50">
                                    <div className=" display_inline avail_bal  payment_section_avail">
                                        <div className="payment_earning_top">
                                            <div>Total Earnings</div>
                                            <div className="money_billing">$ <span>{TotalEarnings}</span></div>

                                        </div>
                                        <div className="payment_earning_top end_payment_earning">
                                            <span>Earnings In {monthNames[d.getMonth()]}</span><br />
                                            <span className='money_billing'>$ <span>{EarningsCurrentMonth}</span></span>
                                        </div>
                                    </div>

                                    <div className=" display_inline avail_bal  payment_section_avail stripe_avail_row">
                                        <div className="payment_earning_top">
                                            <div>Amount On hold (Stripe)</div>
                                            <div className="money_billing">$ <span>{AmountOnhold}</span></div>

                                        </div>
                                        <div className="payment_earning_top end_payment_earning">
                                            <div>Withdrawable balance (Stripe)</div>
                                            <div className="money_billing">$ <span>{StripeWithDrawBalance}</span></div>
                                            <button className='withdraw_but' onClick={TiggerWithDrawInput}>WithDraw</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col_50 chart_payment_col">
                                    <div className="chart_top_label">
                                        <span>Earnings</span>
                                        <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin payment_select_drop' style={{ fontWeight: 500 }} onChange={(e) => { ChangeDrop(e.target.value) }}  >
                                            <option value="this_week">This Week</option>
                                            <option value="last_week">Last Week</option>
                                            <option value="this_month">This Month</option>
                                            <option value="last_month">Last Month</option>
                                            <option value="this_year">This Year</option>
                                            <option value="last_year">Last Year</option>
                                        </select>
                                    </div>
                                    <PaymentChart Chartlabels={Chartlabels} ChartDatas={ChartDatas} />
                                </div>

                            </div>
                            <div className="clearfix"></div>
                            <div className="row second_section_table container">
                                    <span className="second_section_payment_span">Payment history</span>
                            </div>
                            <div className="row filter_section container">
                                <div className="col">
                                    <div style={{float:"left"}}>
                                    <div className="second_section_payment_span" >From Date:</div>
                                    <Datepicker
                                        value={FromDate}
                                        onChange={SetFromDate}
                                        format="MM-dd-yyyy"
                                        className={'date_picker_payment_coach sdate'}
                                        style={{float:"left"}}
                                    />
                                    </div>
                                    <div style={{float:"left"}}>
                                    <div className="second_section_payment_span">To Date:</div>
                                    <Datepicker
                                        value={ToDate}
                                        onChange={SetToDate}
                                        format="MM-dd-yyyy"
                                        className={'date_picker_payment_coach edate'}
                                    />
                                    </div>
                                </div>
                                <div className="col buttons">
                                    <ReactToPrint
                                        trigger={() => <button className='payment_coach_filter_button'>Export PDF</button>}
                                        content={() => componentRef.current}
                                    />
                                    <button className='payment_coach_filter_button' data-action="reset" onClick={(e) => { FiterPaymentData(e) }}>Reset</button>
                                    <button className='payment_coach_filter_button' data-action="filter" onClick={(e) => { FiterPaymentData(e) }}>Filter</button>
                                </div>
                            </div>
                            <div className="row container table_row_payment_history">
                                {PaymentHistoryList.length > 0 &&
                                    <Table className="table is-striped is-fullwidth" id="payment_history_table" ref={componentRef}>
                                        <Thead className="thead">
                                            <Tr>
                                                <Th>Plan Name</Th>
                                                <Th>Plan Amount</Th>
                                                <Th>Gateway fee</Th>
                                                <Th>Admin Commission</Th>
                                                <Th>Earnings</Th>
                                                <Th>Client Name</Th>
                                                <Th>Client Email</Th>
                                                <Th>Payment Date</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                            {PaymentHistoryList.length > 0 && PaymentHistoryList.map((payment, index) => (
                                                <Tr className='trow'>
                                                    <Td><span className="desing_purple">{payment.nutritionist_service.service_name}</span></Td>
                                                    <Td className="second_section_payment_span">{'$ ' + payment.nutritionist_service.service_price}</Td>
                                                    <Td className="second_section_payment_span">{'$ ' + payment.fee}</Td>
                                                    <Td className="second_section_payment_span">{'$ ' + payment.admin_commission}</Td>
                                                    <Td className="second_section_payment_span">{'$ ' + payment.billing_amount}</Td>
                                                    <Td className="second_section_payment_span">{payment.user.first_name + ' ' + payment.user.last_name}</Td>
                                                    <Td className="second_section_payment_span">{payment.user.email}</Td>
                                                    <Td className="second_section_payment_span">{format(parseISO(payment.payment_date), `PP`)}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                }

                                {PaymentHistoryList.length > 0 &&
                                    <div>
                                        <Pagination
                                            activePage={PaymnentPageNo}
                                            itemsCountPerPage={PaymentPerPage}
                                            totalItemsCount={TotalRecordPayment}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }
                            </div>
                        </section>
                    }

                    {PaymentMethodTab === true &&
                        <div className="row">
                            <div className="adminpaymentmethods container">
                                <div className="connect_stripe_coach_div">
                                    <h5>Stripe Account</h5>
                                    <button className='btn btn-success connect_with_stripe_button'
                                        onClick={ConnectWithStripeAccount} disabled={StripeButtonEnable}>{StripeButtonName}</button>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </div>
                        </div>
                    }


                    <Modal show={ShowPaymentSuccess} onHide={HandleClosePaymentSuccess} className="confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {ShowPaymentSuccessMsg}
                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="primary" className="modal_action_button float_right" onClick={HandleClosePaymentSuccess}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ShowWithdraw} onHide={HandleCloseWithdraw} className="confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Amount withdraw</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {showwithdrawmsg}
                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="primary" className="modal_action_button float_right" onClick={HandleCloseWithdraw}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={ShowWithDrawInput} onHide={HandleCloseWithDrawInput} className="confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Amount withdraw</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <input type='number' className='commission_inner_input' defaultValue={WithDrawAMountInput} onChange={(e) => { SetWithDrawAMountInput(e.target.value) }} {...(WithDrawAMountInputErr === true ? { id: 'err_border' } : {})} placeholder={'Please enter the amount'}></input>

                        </Modal.Body>
                        <Modal.Footer className="display_block">

                            <Button variant="primary" className="modal_action_button float_right" onClick={WithDrawBalance}>
                                Proceed
                            </Button>

                            <Button variant="primary" className="modal_action_button float_right" onClick={HandleCloseWithDrawInput}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </section>
        </div>
    )
}

export default CoachPayment
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import Addplans from './Plans/Addplans';
import Assignplans from './Plans/Assignplans';
import Requestchangeplan from './Plans/Requestchangeplan';
import AddRecipe from './Plans/AddRecipe';

const CoachPlans = () => {

    const side_nav = localStorage.getItem("side_nav");

    var [tabpage, setTabMenu] = useState('addplan');

    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setTabMenu(tab_for);

    }
    useEffect(()=>{
        document.title = 'Coach-Plans';
    },[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="coach_plans container">
                    <div className='menubox'>
                        <div className='tabmenu'>
                            <a className="active" onClick={changeTab} data-item="addplan">Plans</a>
                            <a className="" onClick={changeTab} data-item="assignplan">Assign Plan</a>
                            <a className="" onClick={changeTab} data-item="reqchangeplan">Change Request</a>
                            <a className="" onClick={changeTab} data-item="addrecipe">Add Recipe</a>
                        </div>
                    </div>

                    {(tabpage === 'addplan') ? <Addplans /> : null}

                    {(tabpage === 'assignplan') ? <Assignplans /> : null}

                    {(tabpage === 'reqchangeplan') ? <Requestchangeplan /> : null}

                    {(tabpage==='addrecipe') ? <AddRecipe/> : null}
                </div>
            </section>
        </div>
    )
}

export default CoachPlans
import React, { useEffect } from "react";
import BrocolliIcon from '../../../images/Brocoli-Icon.png';
import MushroomIcon from '../../../images/Mushroom-Icon.png';
import ZucchiniIcon from '../../../images/Zucchini-Icon.png';
import CauliIcon from '../../../images/Cauliflower-Icon.png';
import AspargusIcon from '../../../images/Aspargus-Icon.png';
import AvacadoIcon from '../../../images/Avocad-Icon.png';


const Veggies =(props)=>{

    var VegArray=[];

    const CheckImage=(e)=>{
        var el = e.target;
        var closestParent = el.closest(".options");
    
        const ClickedId = closestParent.getAttribute("data-target");
        const ClosetestImgDiv = el.closest(".img_div");

        if (ClosetestImgDiv.classList.contains("active")) {    
            var filteredArray = VegArray.filter(function(e) { return e !== ClickedId })
            VegArray=filteredArray;
            ClosetestImgDiv.classList.remove("active");
          } else {
            VegArray.push(ClickedId)
            ClosetestImgDiv.classList.add("active");
          }

          if(VegArray.length>0)
          {       
           props.EnableNextButton();
           props.SetVegData(VegArray);
          }
          else{
           props.DisableNextButton();
          }
    }

    useEffect(() => {
        setTimeout(() => {
          if (props.VegData) {       
            if (props.VegData.length !== 0) {
                VegArray=props.VegData;

              props.VegData.forEach(element => {    
                const el1 = document.querySelector(
                  '.meat .options_div_parent .options_div .options[data-target="'+element+'"] .img_div'
                );

                el1.classList.add("active");                
              });   
    
              props.EnableNextButton();           
            } else {            
              props.DisableNextButton();
            }
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, [props]);
    
  



    return (
        <div className="meat">
        <div className="head_row">
            <span className="heading">Veggies</span>
        </div>
        <div className="sub_head_row">
            <span className="heading">Please Select Which Veggies You Would Like To Be Included</span>
        </div>
        <div className="options_div_parent">
            <div className="options_div">
                <div className="options" data-target="Broccoli" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={BrocolliIcon} />
                        <p className="title">Broccoli</p>
                    </div>
                </div>
                <div className="options" data-target="Mushroom" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={MushroomIcon} />
                        <p className="title">Mushroom</p>
                    </div>
                </div>
                <div className="options" data-target="Zucchini" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={ZucchiniIcon} />
                        <p className="title">Zucchini</p>
                    </div>
                </div>
                <div className="options" data-target="Cauliflower" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={CauliIcon} />
                        <p className="title">Cauliflower</p>
                    </div>
                </div>
                <div className="options" data-target="Asparagus" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={AspargusIcon}/>
                        <p className="title">Asparagus</p>
                    </div>
                </div>
                <div className="options" data-target="Avocad" onClick={CheckImage}>
                    <div className="img_div">
                    <img src={AvacadoIcon}/>
                        <p className="title">Avocad</p>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    )
}
export default Veggies
import React, { useEffect, useState } from "react";
import { useRef, useLayoutEffect } from "react";
import { Link } from 'react-router-dom';
import male_icon from '../../images/male.png'
import female_icon from '../../images/female.png'
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const CoachDashboard = () => {

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const CoachTimeZoneDefault = localStorage.getItem("user_timezone");
  const axiosJWT = axios.create();
  const pos2inner = useRef(null);
  const bar_name = useRef(null);
  useLayoutEffect(() => {
    pos2inner.current.style.setProperty("padding-bottom", "4px", "important");

  }, []);
  useLayoutEffect(() => {
    bar_name.current.style.setProperty("font-size", "25px", "important");
  }, []);


  const info_black_style = {
    color: "black",
    fontSize: "17px"

  };

  useEffect(() => {
    document.title = 'Coach-Dashboard';
    var e = document.getElementsByClassName("nutri_top_select")[0];
    var value = e.value;
    var text = e.options[e.selectedIndex].text;
    setDateRangeLabel('(' + text + ')')
    GetNutriDashboard(value)
    GetMyUpcomingAppointments()


  }, [])

  const GetMyUpcomingAppointments = async () => {



    const response = await axiosJWT.get('/appointment/coach-my-appointments', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {

      if (response.data.FinalArray !== null) {

        SetCoachUpcomingAppointmentList(response.data.FinalArray)
      }
    }
  }

  const [dateRangeLabel, setDateRangeLabel] = useState('')
  const [TotalInvited, SetTotalInvited] = useState('0')
  const [TotalOnboardedAgainstInvited, setTotalOnboardedAgainstInvited] = useState('0')
  const [ActiveClientList, SetActiveClientList] = useState([])
  const [ActiveClientListCount, SetActiveClientListCount] = useState('0')
  const [CoachUpcomingAppointmentList, SetCoachUpcomingAppointmentList] = useState([])
  const [AvgSession, SetAvgSession] = useState()

  function timetoUtcClientAppointment(passdate) {


    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(CoachTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')


    return stardates



  }



  const GetNutriDashboard = async (range) => {


    const response = await axiosJWT.get('/dashboard?date=' + range + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {

      SetTotalInvited(response.data.total_invited)
      setTotalOnboardedAgainstInvited(response.data.total_onboarded_against_invited)
      SetAvgSession(response.data.avg_sessions)
      SetActiveClientList(response.data.Nutrition_client_list)
      SetActiveClientListCount(response.data.Nutrition_client_list.length)

    })

  }

  const DashboardDropDateRange = async () => {
    var e = document.getElementsByClassName("nutri_top_select")[0];
    var value = e.value;
    var text = e.options[e.selectedIndex].text;
    setDateRangeLabel('(' + text + ')')
    GetNutriDashboard(value)
  }

  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className="dashboard">
        <div className="container">
       
          <div className="row">
            <div className=" colR nutrition_dashboard_col">

              <div className="colRpos1">
                <h2>Dashboard</h2>
                <div className="dropdown" style={{ cursor: 'pointer', marginRight: '15px' }}>
                  {/* <button type="button" className="btn drpbtn dropdown-toggle drop_calender" data-toggle="dropdown">
       <span className="calender">18 May 2022  <span  className="chevron"><i className="fa fa-chevron-down " ref={dropdown_icon} aria-hidden="true"></i></span></span>
       </button> */}

                  <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin nutri_top_select' style={{ fontWeight: 500 }} onChange={DashboardDropDateRange}   >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this_week">This Week</option>
                    <option value="last_week">Last Week</option>
                    <option value="this_month">This Month</option>
                    <option value="last_month">Last Month</option>
                    <option value="this_year">This Year</option>
                    <option value="all">All Time</option>

                  </select>

                </div>
              </div>

              <div className="colRpos2">
                <div className="pos2inner" ref={pos2inner}>
                  <h3 ref={bar_name}>Welcome, {first_name + ' '}{last_name}</h3>
                  <p>Have a nice day at work</p>
                </div>
              </div>

              <div className="colRpos3">
                {/* <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Invited <span className='label_small'>{dateRangeLabel}</span></h6><span className="pos3innericon"><i className="fa fa-user-plus" aria-hidden="true"></i></span> </div>
                  <h3 style={info_black_style}>{TotalInvited}</h3>
                </div> */}
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}> Onboarded <span className='label_small'>{dateRangeLabel}</span></h6><span className="pos3innericon"><i className="fa fa-handshake-o" aria-hidden="true"></i></span> </div>
                  <h3 style={info_black_style}>{TotalOnboardedAgainstInvited}</h3>
                </div>
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Active <span className='label_small'>{'(Now)'}</span></h6><span className="pos3innericon"><i className="fa fa-user-o" aria-hidden="true" style={{ backgroundColor: "#665cff" }}></i></span> </div>
                  <h3 style={info_black_style}>{ActiveClientListCount}</h3>
                </div>
                <div className="pos3inner">
                  <div className="pos3innertop"> <h6 style={info_black_style}>Avg Session <span className='label_small'>{dateRangeLabel}</span></h6><span className="pos3innericon"><i className="fa fa-sticky-note-o" aria-hidden="true" style={{ backgroundColor: "#ffd714" }}></i></span> </div>
                  <h3 style={info_black_style}>{AvgSession}</h3>
                </div>
              </div>

              <h6>Next Appointment</h6>
              <Table className='table is-striped is-fullwidth next_appointment_table_coach'>
                <Thead className='thead '>
                  <Tr>
                    <Th className='row_name'>Name</Th>
                    <Th className='row_name'>Purpose</Th>
                    <Th className='row_name'>Date {'&'} Time</Th>
                  </Tr>
                </Thead>
                <Tbody>

                  {CoachUpcomingAppointmentList.length > 0 && CoachUpcomingAppointmentList.map((upappointment, index) => (
                    <Tr key={index}>
                      <Td>

                        <div className='c-avatar'>
                          <div className='photo_block'>
                            <img className='table_profile_img' src={upappointment.user[0].hasOwnProperty("user_profile_photo") && upappointment.user[0].user_profile_photo !== '' && upappointment.user[0].user_profile_photo !== null ?  upappointment.user[0].user_profile_photo : ((upappointment.user[0].gender).toLowerCase() === "male" ? male_icon : female_icon)} alt="profile image" />   {
                              (upappointment.user[0].logged_in_status === '1')
                                ? <span className='c-avatar__status coach_dash_my_appointment'></span>
                                : null
                            }
                          </div>
                          <div className='name_block'>
                            <span className='name_val'>{upappointment.user[0].first_name + ' ' + upappointment.user[0].last_name}</span>
                          </div>
                        </div>

                      </Td>
                      <Td><span className="desing_back">{upappointment.appointment.meeting_description}</span></Td>
                      <Td><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.appointment.start_time)), `hh:mm a, MMM dd, yyyy`)}</Td>
                    </Tr>


                  ))}

                </Tbody>
              </Table>


            </div>
            <div className=" col nutrition_dashboard_col2">
              <div className="active_users_list">
                <h5>Active Users</h5>
                <div className="nutrition_dashboard_active_users_div">

                  {ActiveClientList.length > 0 &&

                    ActiveClientList.map((user, index) => (
                      <div className="nutrition_dashboard_active_users_div_span" key={index}>

                        <div className='photo_block'>
                          <img className='table_profile_img' alt="" src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ? user.user_profile_photo : ((user.gender).toLowerCase() === "male" ? male_icon : female_icon)} />
                          {
                            (user.logged_in_status === '1')
                              ? <span className='c-avatar__status list_card_calendar_status'></span>
                              : null
                          }
                        </div>
                        <div className='name_block'>
                          <span className="nutrition_dashboard_active_users_div_span_name">{user.first_name + ' ' + user.last_name}</span> <i className="fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><i className="fa fa-envelope sidenav_icon" aria-hidden="true"></i>
                        </div>
                      </div>
                    ))


                  }







                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CoachDashboard
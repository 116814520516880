import React, {useEffect, useState} from "react";
import { NavLink } from 'react-router-dom';

var QB = require('quickblox/quickblox.min');

const ManagerNav=(props)=>{

    const [messageNew, setmessageNew] = useState();

    useEffect(() => {
        setmessageNew(props.data);        
    }, [props.data]);    
    
    const disable_msgnew = () => {
        setmessageNew(false);
        disable_qb_chat_connect();
    }

    const disable_qb_chat_connect = () =>{
        QB.chat.disconnect();
    }

    return (
        <div>
        <ul className="side_nav_class" style={{paddingLeft:'0rem'}}>
        <li><NavLink to={"/manager/dashboard"} activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
        <li ><NavLink to='/manager/messages' activeclassname="active"><i className="fa fa-envelope sidenav_icon" aria-hidden="true"></i><span className="side-navli" onClick={disable_msgnew}>Messages <sup className="msgnew">{messageNew === true ? "(New)" : null}</sup></span></NavLink></li>
        <li><NavLink to={"/manager/manage-appointment"} activeclassname="active"><i className="fa fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage appointment</span></NavLink></li>
        <li><NavLink to={"/manager/manage-users"} activeclassname="active"><i className="fa fa-users sidenav_icon" aria-hidden="true"></i><span className="side-navli">Manage Users</span></NavLink></li>
        <li><NavLink to={"/manager/payout"} activeclassname="active"><i className="fa fa-usd sidenav_icon" aria-hidden="true"></i><span className="side-navli">Payout</span></NavLink></li>
        <li><NavLink to={"/manager/contact-enquiry"} activeclassname="active"><i className="fa fa-address-book sidenav_icon" aria-hidden="true"></i><span className="side-navli">Contact Enquiries</span></NavLink></li>
        <li><NavLink to='/manager/analytics' activeclassname="active"><i className="fa fa-bar-chart sidenav_icon" aria-hidden="true"></i><span className="side-navli">Analytics</span></NavLink></li>        
    </ul>

    <div className='addmember-logo' style={{display:'none'}}> <a href><i id="Addmember" className="fa fa-user-plus" aria-hidden="true"></i><span id="addmember">Add Members</span></a> </div>

    </div>

    )
}

export default ManagerNav
import React, { useEffect, useState } from "react";

const TruthConsentCS = (props) => {



  const [TruthConsent,SetTruthConsent]=useState(false)




 

  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

  
  
    if(props.TruthConsentPC)
    {
        SetTruthConsent(props.TruthConsentPC)
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
  

      var Err = 0;
    
      if (TruthConsent===false)
      {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [TruthConsent]);

  useEffect(()=>{



    props.SetTruthConsentPC(TruthConsent)
  
    


  },[TruthConsent])


  useEffect(()=>{
    props.SetPreviousMenu('doctorinfo')
    props.SetNextMenu('basic_info')
     
  },[])

  return (
    <div className="daily_activity beluga_consent">
      <div className="head_row"><h2>Consent Information</h2></div>

   

    

       
        <p>
          
        Consent: I have reviewed, understand, and attest that the information I have provided above is true to the best of my knowledge. I understand that it is critical to my health to share complete health information with my doctor. I will not hold the doctor or affiliated medical practice responsible for any oversights or omissions, whether intentional or not in the information that I provided above.
        </p>
        <input type="checkbox" className="consent_checkbox" checked={TruthConsent===true} onChange={(e)=>{SetTruthConsent(e.target.checked)}}/><span>By checking this box, you acknowledge the above information       <span style={{color:'red'}}>*</span></span>
        <p className="remember">
          
        Remember that these medications are most effective when used in combination with a well balanced diet and regular exercise. We wish you great success with this program and thanks for being part of the program.
          </p>

     








    </div>
  );
};
export default TruthConsentCS;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, set } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Assignplans = () => {

    const token = localStorage.getItem("token");

    var [assignedplans, setassignedplans] = useState([]);

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);


    const [modalPopup, setModal] = useState();
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [assignedId, setassignedId] = useState('');
    const [assignedplanValue, setassignedplanValue] = useState('');

    const [assignshow, setassignshow] = useState(false);
    const [planOptions, setplanOptions] = useState([]);
    const [userOptions, setuserOptions] = useState([]);
    const [assignPlan, setassignPlan] = useState('');
    const [assignplanUser, setassignplanUser] = useState('');
    const [assignplanDays, setassignplanDays] = useState('');
    const [assignedplanUserName, setassignedplanUserName] = useState('');
    const [assignedplanId, setassignedplanId] = useState('');

    const [ChoosePlanErr, SetChoosePlanErr] = useState(false);
    const [ChoosePlanErrMsg, SetChoosePlanErrMsg] = useState('');

    const [ChooseUserErr, SetChooseUserErr] = useState(false);
    const [ChooseUserErrMsg, SetChooseUserErrMsg] = useState('');

    useEffect(() => {
        getassignedPlans();
    }, []);

    const handleassignClose = () => setassignshow(false);

    const axiosJWT = axios.create();

    const getassignedPlans = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage);
            var ending_limit = countPerPage;
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage;
        }

        const response = await axiosJWT.get("/meal-plan/assign/all?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        // console.log(response.data)
        setassignedplans(response.data.assigned);
        setTotalCount(response.data.totalcount);
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getassignedPlans(pagenumber);
    }

    const handleShow = async (e) => {
        const assigned_Id = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");
        setassignedId(assigned_Id);

        setassignedplanId('');
        setassignedplanValue('');
        setassignplanUser('');
        setassignedplanUserName('');
        setassignplanDays('');
        setModal(datamodal);

        SetChoosePlanErr(false);
        SetChooseUserErr(false);

        if (datamodal === 'plan_assign') {

            const response_plans = await axiosJWT.get("/meal-plan/", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // console.log(response_plans)
            var plan_data = response_plans.data.plans;
            var plan_list = [];

            plan_data.forEach(element => {                     
                if(element.breakfast_keys && element.lunch_keys && element.dinner_keys && element.snack_keys)
                {
                    var breakfast_exist = JSON.parse(element.breakfast_keys);
                    var lunch_exist = JSON.parse(element.lunch_keys);
                    var dinner_exist = JSON.parse(element.dinner_keys);
                    var snack_exist = JSON.parse(element.snack_keys);                

                    if(element.days === breakfast_exist.length && element.days === lunch_exist.length && element.days === dinner_exist.length && element.days === snack_exist.length)
                    plan_list.push({ label: element.meal_plan_name + " (" + element.days + " Days)", value: element.id });
                }
            });


            //generate already assigned users list
            var existing_assigned_users_list = [];
            assignedplans.forEach(element => {
                existing_assigned_users_list.push(element.client_id);
            });

            const response_user = await axiosJWT.get("/allclient", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response_user)
            var users_data = response_user.data.Users;
            var users_list = [];

            users_data.forEach(element => {
                if (!existing_assigned_users_list.includes(element.id))
                    users_list.push({ label: element.first_name + " " + element.last_name, value: element.id });
            });

            setuserOptions(users_list);
            setplanOptions(plan_list);

            setassignshow(true);
        }

        if (datamodal === 'plan_edit') {

            const response_plans = await axiosJWT.get("/meal-plan/", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response_plans)
            var plan_data = response_plans.data.plans;
            var plan_list = [];

            plan_data.forEach(element => {
                plan_list.push({ label: element.meal_plan_name + " (" + element.days + " Days)", value: element.id });
            });

            const response_user = await axiosJWT.get("/allclient", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            //console.log(response_user)
            var users_data = response_user.data.Users;
            var users_list = [];

            users_data.forEach(element => {
                users_list.push({ label: element.first_name + " " + element.last_name, value: element.id });
            });

            setuserOptions(users_list);
            setplanOptions(plan_list);

            const response = await axiosJWT.get('/meal-plan/assign/' + assigned_Id,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            //console.log(assigned_clientname);

            setassignedplanId(response.data.meal_plan_id);
            setassignedplanValue(response.data.meal_plan_name);
            setassignplanUser({ label: response.data.user.first_name + " " + response.data.user.last_name, value: response.data.client_id });
            setassignedplanUserName(response.data.user.first_name + " " + response.data.user.last_name);
            setassignplanDays(response.data.days);
            setassignshow(true);
        }

        if (datamodal === 'delete_confirm') {
            const response = await axiosJWT.get('/meal-plan/assign/' + assigned_Id,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            setassignedplanUserName(response.data.user.first_name + " " + response.data.user.last_name);
            setDeletePopup(true);
        }
    }


    const deleteAssigned = async (e) => {

        const assigned_Id = e.target.getAttribute("data-item");

        const response = await axiosJWT.delete('/meal-plan/assign/' + assigned_Id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleDeleteClose();
            getassignedPlans();
        }
    }

    const handleDeleteClose = () => {
        setassignedId('');
        setassignedplanUserName('');
        setDeletePopup(false);
    }


    const updateassignPlan = async () => {

        SetChoosePlanErr(false);
        SetChooseUserErr(false);

        if (!assignPlan) {
            var filtered_assign_label = assignedplanValue;
            var assign_plan_id = assignedplanId;
        }
        else {
            var assign_label = assignPlan.label;
            var position_bracket = (assign_label.indexOf("(") - 1);
            var filtered_assign_label = assign_label.substring(0, position_bracket);

            var assign_plan_id = assignPlan.value;
        }

        var err = false;

        if (filtered_assign_label === undefined || filtered_assign_label === '') {
            SetChoosePlanErr(true);
            SetChoosePlanErrMsg('Please choose plan');
            err = true;
        }

        if (err === true) {
            return false;
        }

        //console.log(assign_plan_id+" "+filtered_assign_label+" "+assignplanUser.value+" "+assignplanDays)
        if (assign_plan_id !== '' && filtered_assign_label !== '' && assignplanUser.value !== '') {
            const response = await axiosJWT.put("/meal-plan/assign/" + assign_plan_id,
                {
                    meal_plan_id: assign_plan_id,
                    meal_plan_name: filtered_assign_label,
                    client_id: assignplanUser.value,
                    days: assignplanDays
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleassignClose();
                getassignedPlans();
            }
        }
    }

    const createassignPlan = async () => {

        SetChoosePlanErr(false);
        SetChooseUserErr(false);

        var err = false;

        if (assignPlan.label === undefined || assignPlan.label === '') {
            SetChoosePlanErr(true)
            SetChoosePlanErrMsg('Please choose plan')
            err = true;
        }

        if (assignplanUser.value === undefined || assignplanUser.value === '') {
            SetChooseUserErr(true)
            SetChooseUserErrMsg('Please choose user')
            err = true;
        }

        if (err === true) {
            return false;
        }

        var assign_label = assignPlan.label;
        var position_bracket = (assign_label.indexOf("(") - 1);
        var filtered_assign_label = assign_label.substring(0, position_bracket);

        if (assignPlan.value !== '' && filtered_assign_label !== '' && assignplanUser !== '') {
            const response = await axiosJWT.post("/meal-plan/assign",
                {
                    meal_plan_id: assignPlan.value,
                    meal_plan_name: filtered_assign_label,
                    client_id: assignplanUser.value,
                    days: assignplanDays
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                handleassignClose();
                getassignedPlans();
            }
        }
    }


    const searchAssign = async (event) => {

        var starting_limit = 0;
        var ending_limit = countPerPage

        const response = await axiosJWT.get("/meal-plan/assign/all?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);

        //console.log(response.data)
        setassignedplans(response.data.assigned);
        setTotalCount(response.data.totalcount);
    }

    return (
        <div>
            <div className='title_box'>
                <div className='leftmenu'>
                    <h5>Assigned Client List</h5><br></br>
                </div>
                <div className='rightmenu'>
                    <input className='search' type="text" placeholder="&#xF002; Search Client" onChange={event => searchAssign(event.target.value)} />
                    <input className='greenbutton' type="button" value="Assign Plan" onClick={(e) => { handleShow(e) }} data-modal="plan_assign" />
                </div>
            </div>

            <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Plan Name</Th>
                        <Th>Client Name</Th>
                        <Th>Days</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                    {assignedplans.map((assigned, index) => (
                        <Tr className='trow' key={assigned.id}>
                            <Td>{assigned.meal_plan_name}</Td>
                            <Td>{assigned.user.first_name + " " + assigned.user.last_name}</Td>
                            <Td>{assigned.days}</Td>
                            <Td><i className="fa fa-edit td_icon action" data-modal="plan_edit" data-item={assigned.id} onClick={handleShow}></i><i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-name={assigned.user.first_name + " " + assigned.user.last_name} data-item={assigned.id} onClick={handleShow}></i></Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {totalCount ? 
            <Pagination
                activePage={activePage}
                itemsCountPerPage={countPerPage}
                totalItemsCount={totalCount}
                onChange={changePageNumber.bind(this)}
                prevPageText="<"
                nextPageText=">"
                hideFirstLastPages={true}
                linkClassPrev="pagination_prev"
                linkClassNext="pagination_next"
                linkClass="pagenumbers grayfont"
            />
            : null }

            <Modal show={assignshow} onHide={handleassignClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Meal Plan To Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label required'>Choose Plan:</label><br />
                            <Select
                                defaultValue={{ label: assignedplanValue, value: assignedplanId }}
                                options={planOptions}
                                onChange={setassignPlan}
                                {...(ChoosePlanErr===true ? {id: 'err_border'} : {})}
                            />
                            {ChoosePlanErr === true &&
                                <span className="err service_err_field">  {ChoosePlanErrMsg}</span>
                            }
                            <a href="/coach/plans" style={{float:"right"}} >Create plan</a>                            
                        </div>
                    </div>
                    {modalPopup === 'plan_assign' ?
                        <div className="row">
                            <div className="col">
                                <label className='inner_popup_label required'>Choose User:</label><br />
                                <Select
                                    defaultValue={{ label: assignplanUser.label, value: assignplanUser.value }}
                                    options={userOptions}
                                    onChange={setassignplanUser}
                                    {...(ChooseUserErr===true ? {id: 'err_border'} : {})}
                                />
                                {ChooseUserErr === true &&
                                    <span className="err service_err_field">  {ChooseUserErrMsg}</span>
                                }
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col">
                                <label className='inner_popup_label required'>User:</label> {assignplanUser.label}
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            <label className='inner_popup_label'>Days:</label><br />
                            <input placeholder="Leave it as empty for all days" type='number' className='inner_popup_input' defaultValue={assignplanDays} onChange={(e) => { setassignplanDays(e.target.value) }}></input>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleassignClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={assignedId} onClick={(e) => { modalPopup === 'plan_assign' ? createassignPlan(e) : updateassignPlan(e) }}>
                        {modalPopup === 'plan_assign' ? "Assign Plan" : "Update Plan"}
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePopup} onHide={handleDeleteClose} className='coach_plan_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Assigned</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete assigned plan to {assignedplanUserName} ?
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleDeleteClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' data-item={assignedId} onClick={(e) => { deleteAssigned(e) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <div style={{ clear: 'both' }}></div>
        </div>
    )
}

export default Assignplans
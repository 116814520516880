import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import mainLogo from '../images/fitpal_logo.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";
import fitnessimg from '../images/action-1850677_960_720.jpg'
//import axios from "../http-common";

const ForgotPassword = () => {



    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [VerifyotpShow, SetVerifyotpShow] = useState(false);
    const [VerifytotpButtonshow, SetVerifytotpButtonshow] = useState(false);
    const [Getotpbuttonshow, SetGetotpbuttonshow] = useState(true);
    const [emaildisabled, Setemaildisabled] = useState(false);
    const [Otpcode, Setotpcode] = useState('');
    const [showotpform, Setshowotpform] = useState(true);
    const [NewpasswordshowForm, SetNewpasswordshowForm] = useState(false);
    const [Newpassword, SetNewpassword] = useState('');
    const [ConfirmPassword, SetConfirmPassword] = useState('');

    const [showsd, Setshowsd] = useState(false);
    const handleClosesd = () => { Setshowsd(false) }
    const [PopupMsg, SetPopupMsg] = useState('');
    const [PopupMsgl, SetPopupMsgl] = useState('');
    const [Passmsg, SetPassmsg] = useState('');

    const [showsdl, Setshowsdl] = useState(false);

    const [loader, SetloaderShow] = useState(false);
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    const handleClosesdl = () => {
        navigate("/login", { replace: true });
    }

    const [EmailAddressErr, SetEmailAddressErr] = useState(false);
    const [RecaptchaMsg, SetRecaptchaMsg] = useState('');

    const navigate = useNavigate();

    const GetOTP = async () => {
        SetEmailAddressErr(false);
        setMsg('');
        SetRecaptchaMsg('');

        if (email === '') {
            SetEmailAddressErr(true);
            setMsg('Please enter your registered email address.!');
        }
        else if (ReCaptchaErr === true) {
            SetRecaptchaMsg('Please verify recaptcha.!');
        }
        else {
            ShowLoader()
            try {
                const response = await axios.post('/password/get-otp', {
                    email: email
                });

                if (response) {
                    HideLoader();
                    SetPopupMsg(response.data.msg);
                    SetGetotpbuttonshow(false);
                    SetVerifyotpShow(true);
                    SetVerifytotpButtonshow(true);
                    Setemaildisabled(true);
                    Setshowsd(true);
                }
            } catch (error) {
                HideLoader();
                SetEmailAddressErr(true);
                setMsg(error.response.data.msg);
            }
        }
    }

    const [VerifyOtpErr, SetVerifyOtpErr] = useState(false);

    const verifyOTP = async () => {
        SetVerifyOtpErr(false);
        setMsg('');

        if (Otpcode === '') {
            SetVerifyOtpErr(true);
            setMsg('Please enter your access code.!');
        }
        else {
            try {
                const response = await axios.post('/password/verify-otp', {
                    email: email,
                    otp: Otpcode
                });

                if (response) {
                    SetPopupMsg(response.data.msg);
                    Setshowsd(true);
                    Setshowotpform(false);
                    SetNewpasswordshowForm(true);
                }
            } catch (error) {
                SetVerifyOtpErr(true);
                setMsg(error.response.data.msg);
            }
        }
    }

    const [NewPassErr, setNewPassErr] = useState(false);
    const [CNewPassErr, setCNewPassErr] = useState(false);

    const ChangePassword = async () => {
        SetPassmsg();
        setNewPassErr(false);
        setCNewPassErr(false);

        if (Newpassword === '') {
            setNewPassErr(true);
            SetPassmsg('Please enter new password.!');
        }
        else if (Newpassword.length < 8) {
            setNewPassErr(true);
            SetPassmsg('Password should be minimum 8 letters.!');
        }
        else if (ConfirmPassword === '') {
            setCNewPassErr(true);
            SetPassmsg('Please enter confirm password.!');
        }
        else if (Newpassword !== ConfirmPassword) {
            setCNewPassErr(true);
            setNewPassErr(true);
            SetPassmsg('Password does not match.!');
        }
        else {
            try {
                const response = await axios.post('/password/change', {
                    email: email,
                    password: Newpassword
                });

                SetPopupMsgl(response.data.msg);
                Setshowsdl(true);
            } catch (error) {
                SetPassmsg(error.response.data.msg);
            }
        }
    }

    const [ReCaptchaErr, SetReCaptchaErr] = useState(true);

    const handleVerify = (data) => {
        if (data === null) {
            SetReCaptchaErr(true);
        }
        else if (data !== '') {
            SetReCaptchaErr(false);
        }
    }


    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth login_screen_section">
            {loader === true && <div id="semiTransparenDivLoader"></div>}
            <div className='top_login_row'>
            <div className='initial_row'>
            <div className='img_div'>
{/* <img src={'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img3.webp'}></img> */}
<img src={fitnessimg}></img>
</div>
            <div className="hero-body login_card">
                <div className="container login_inner_card">
                    <img id='clickimg' className='login_logo_img' src={mainLogo} alt="mainLogo" />
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">

                            {showotpform === true &&
                                <form className="box">

                                    <div className="field mt-5">
                                        <label className="label">Email Address</label>
                                        <div className="controls">
                                            <input type="text" className="commission_inner_input input login_input " placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} disabled={emaildisabled} {...(EmailAddressErr === true ? { id: 'err_border' } : {})} />
                                        </div>
                                    </div>

                                    {VerifyotpShow === true &&
                                        <div className="field mt-5">
                                            <label className="label">One-time-password</label>
                                            <div className="controls">
                                                <input type="number" maxLength="6" className="commission_inner_input input login_input " placeholder="6 Digit Code" onChange={(e) => { Setotpcode(e.target.value) }}  {...(VerifyOtpErr === true ? { id: 'err_border' } : {})} />
                                            </div>
                                        </div>
                                    }

                                    <p className="has-text-centered err">{msg}</p>
                                    {VerifyotpShow === false &&
                                        <div className='login_reacptcha'>
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                onChange={handleVerify.bind(this)}
                                                onExpired={() => { SetReCaptchaErr(true) }}
                                                onErrored={() => { SetReCaptchaErr(true) }}
                                            />                                          
                                        </div>
                                    }
                                      <div className="has-text-centered err">{RecaptchaMsg}</div>
                                    {VerifytotpButtonshow === true &&
                                        <div className="field mt-5 login_but_div">
                                            <button type='button' className="button is-success is-fullwidth btn btn-success login_but" onClick={verifyOTP}>Verify OTP</button>
                                        </div>
                                    }

                                    {Getotpbuttonshow === true &&
                                        <div className="field mt-5 login_but_div">
                                            <button type='button' className="button is-success is-fullwidth btn btn-success login_but" onClick={GetOTP}>Generate OTP</button>
                                        </div>
                                    }
                                </form>
                            }

                            { NewpasswordshowForm === true &&
                                <form className="box">
                                    <div className="field mt-5">
                                        <label className="label">New Password</label>
                                        <div className="controls">
                                            <input type="password" className="commission_inner_input input login_input " placeholder="New password" onChange={(e) => { SetNewpassword(e.target.value) }} {...(NewPassErr == true ? { id: 'err_border' } : {})} />
                                        </div>
                                    </div>

                                    <div className="field mt-5">
                                        <label className="label">Re-type New password</label>
                                        <div className="controls">
                                            <input type="password" className="commission_inner_input input login_input " placeholder="Confirm new password" onChange={(e) => { SetConfirmPassword(e.target.value) }}  {...(CNewPassErr == true ? { id: 'err_border' } : {})} />
                                        </div>
                                    </div>

                                    <p className="has-text-centered err">{Passmsg}</p>

                                    <div className="field mt-5 login_but_div change_pass_div">
                                        <button type='button' className="button is-success is-fullwidth btn btn-success login_but" onClick={ChangePassword}>Change password</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>           
            </div>

            <Modal show={showsd} onHide={handleClosesd} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>{PopupMsg}</Modal.Body>
                <Modal.Footer className='display_block'>

                    <Button variant="primary" className="bootstrp_color_btn float_right" onClick={handleClosesd} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showsdl} onHide={handleClosesdl} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>{PopupMsgl}</Modal.Body>
                <Modal.Footer className='display_block'>

                    <Button variant="primary" className="bootstrp_color_btn float_right" onClick={handleClosesdl} >
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ForgotPassword
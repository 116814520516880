import React, { useEffect, useState } from "react";

const DoctorInfoCM = (props) => {
 
  const [DoctorInfo, SetDoctorInfo] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.DoctorInfoPC) {
        SetDoctorInfo(props.DoctorInfoPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetDoctorInfoPC(DoctorInfo);
   

      var Err = 0;
      if (DoctorInfo === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [DoctorInfo]);

  useEffect(()=>{

    if(props.GLPMedicationPC.includes('None of these'))
    {
        props.SetPreviousMenu('glpmedication')
    }
    else{
        props.SetPreviousMenu('glpdosepref')
    }
  
    props.SetNextMenu('truthconsent')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"><h6>{props.DoctorInfoPCLabel}</h6>       <span style={{color:'red'}}>*</span></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={DoctorInfo}
              value={DoctorInfo}
              onChange={(e) => {
                SetDoctorInfo(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default DoctorInfoCM;

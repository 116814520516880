/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';



const Dayspecific = () => {

  const token = localStorage.getItem("token");

  const axiosJWT = axios.create();

  const [events, setEvents] = useState([]);
  const [defaultDate, setdefaultDate] = useState('');

  useEffect(() => {
    load_slots();
  }, []);


  //setEvents({id:1,start:(moment.utc(Date()).toDate()),end:(moment.utc(Date()).add(60, 'minutes'))});

  moment.locale("en-gb", {
    week: {
      format: "dddd",
      dow: 1
    }
  });

  const localizer = momentLocalizer(moment)
  const DnDCalendar = withDragAndDrop(Calendar);


  const load_slots = async () => {

    var tempEvents = [];

    var response = await axiosJWT.get("/availability/day/",
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    var day_slot_list = response.data;

    console.log("mi")
    console.log(day_slot_list)

    var slot_cont;
    var start_time = '';
    var end_time = '';

    day_slot_list.forEach(element => {

      var day = element.date;
      var slots = JSON.parse(element.slots);

      var prev_slot = 0;      

      if (slots.length > 1) {
        for (var i = 0; i < slots.length; i++) {
          if (i < ((slots.length) - 1)) {
            var current_slot = slots[i].substr(0, slots[i].indexOf(":"));
            var next_slot = slots[i + 1].substr(0, slots[i + 1].indexOf(":"));

            if ((next_slot - current_slot) === 1) {
              slot_cont = true;

              if (start_time === '') {
                start_time = (slots[i].substr(0, slots[i].indexOf(":")) * 60);
              }
            }
            else {
              slot_cont = false;

              if (start_time === '') {
                start_time = (slots[i].substr(0, slots[i].indexOf(":")) * 60);
              }

              if (slots[i].substr(0, slots[i].indexOf(":")) === "23")
                end_time = ((slots[i].substr(0, slots[i].indexOf(":")) * 60) + 59.59);
              else
                end_time = ((slots[i].substr(0, slots[i].indexOf(":")) * 60) + 60);
            }
          }
          else {
            slot_cont = false;

            if (slots[i].substr(0, slots[i].indexOf(":")) === "23")
              end_time = ((slots[i].substr(0, slots[i].indexOf(":")) * 60) + 59.59);
            else
              end_time = ((slots[i].substr(0, slots[i].indexOf(":")) * 60) + 60);
          }


          if (slot_cont === false) {
            const data = {
              start: moment(day).add(start_time, 'minutes').toDate(),
              end: moment(day).add(end_time, 'minutes').toDate(),
            };

            tempEvents.push(data);

            slot_cont = true;
            start_time = '';
          }

        }
      }
      else {
        start_time = (slots[0].substr(0, slots[0].indexOf(":")) * 60);

        if (slots[0].substr(0, slots[0].indexOf(":")) === "23")
          end_time = ((slots[0].substr(0, slots[0].indexOf(":")) * 60) + 59.59);
        else
          end_time = ((slots[0].substr(0, slots[0].indexOf(":")) * 60) + 60);

        const data = {
          start: moment(day).add(start_time, 'minutes').toDate(),
          end: moment(day).add(end_time, 'minutes').toDate(),
        };

        tempEvents.push(data);
      }
    });

    //console.log(tempEvents)
    setEvents(tempEvents);

  }

  const selectedslot = async ({ start, end, resourceId }) => {

    var slot_date = moment(start).format("YYYY-MM-DD")
    var slot_start_time = parseInt(moment(start).format("H"));
    var slot_end_time = parseInt(moment(end).format("H"));

    if (slot_end_time === 23) {
      var end_time_check = moment(end).format("H:m");
      if (end_time_check === "23:59")
        slot_end_time = 24;
      else
        slot_end_time = 23;
    }


    var slots = [];
    for (var i = slot_start_time; i < slot_end_time; i++) {
      if (i < 9)
        slots.push("0" + i + ":00 - 0" + (parseInt(i) + 1) + ":00");
      else if (i === 9)
        slots.push("0" + i + ":00 - " + (parseInt(i) + 1) + ":00");
      else if (i === 23)
        slots.push(i + ":00 - 23:59");
      else
        slots.push(i + ":00 - " + (parseInt(i) + 1) + ":00");
    }

    var exist_check = await axiosJWT.get("/availability/day/?date=" + slot_date,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    console.log("mi")
    console.log(exist_check.data)

    if ((exist_check.data).length > 0) {
      // console.log('exist');

      var exist_slots = [];

      exist_slots = JSON.parse(exist_check.data[0].slots);



      exist_slots.forEach(element => {
        if (!slots.includes(element))
          slots.push(element);
      });

      slots.sort();

      //console.log(slots)

      var response = await axiosJWT.put("/availability/day",
        {
          date: slot_date,
          slots: slots
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    }
    else {
      // console.log('not_exist');

      slots.sort();

      //console.log(slots)

      var response = await axiosJWT.post("/availability/day",
        {
          date: slot_date,
          slots: slots
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    }


    setdefaultDate(slot_date);

    const data = {
      start: start,
      end: end
    };

    setEvents([...events, data]);

    //load_slots();

  }

  const unselectedslot = async ({ start, end, resourceId }) => {
    // console.log(start+" "+end)

    var slot_date = moment(start).format("YYYY-MM-DD")
    var slot_start_time = parseInt(moment(start).format("H"));
    var slot_end_time = parseInt(moment(end).format("H"));

    if (slot_end_time === 23) {
      var end_time_check = moment(end).format("H:m");
      if (end_time_check === "23:59")
        slot_end_time = 24;
      else
        slot_end_time = 23;
    }

    var remove_slots = [];
    for (var i = slot_start_time; i < slot_end_time; i++) {
      if (i < 9)
        remove_slots.push("0" + i + ":00 - 0" + (parseInt(i) + 1) + ":00");
      else if (i === 9)
        remove_slots.push("0" + i + ":00 - " + (parseInt(i) + 1) + ":00");
      else if (i === 23)
        remove_slots.push(i + ":00 - 23:59");
      else
        remove_slots.push(i + ":00 - " + (parseInt(i) + 1) + ":00");
    }

    //slot_date = '2022-07-12';

    var exist_check = await axiosJWT.get("/availability/day/?date=" + slot_date,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    var date_row_id = exist_check.data[0].id;

    if ((exist_check.data).length > 0) {
      // console.log('exist');

      var exist_slots = [];
      var new_slots = [];

      exist_slots = JSON.parse(exist_check.data[0].slots);

      exist_slots.forEach(element => {
        if (!remove_slots.includes(element))
          new_slots.push(element);
      });

      if (new_slots.length !== 0) {
        var response = await axiosJWT.put("/availability/day",
          {
            date: slot_date,
            slots: new_slots
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
      }
      else //if slot is empty then delete date row 
      {
        var response = await axiosJWT.delete("/availability/day/" + date_row_id,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        // console.log("test" + new_slots.length);
      }

    }

    setdefaultDate(slot_date);

    load_slots();
  }

  return (
    <div>

      <DnDCalendar
        localizer={localizer}
        defaultView="week"
        selectable={true}
        timeslots={1}
        step={60}
        onSelectSlot={selectedslot}
        onSelectEvent={unselectedslot}
        events={events}
        className="day_availability"
        views={['week']}
        resizableAccessor={() => false}
        startAccessor="start"
        endAccessor="end"
        defaultDate={defaultDate}
      />

    </div>
  )
}

export default Dayspecific
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const CheckConnectedAccount=()=>{

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [ValidAccount,SetValidAccount]=useState(true)
    const [ValidAccountMsg,SetValidAccountMsg]=useState('')
    const [loader, Setloader] = useState(false)
    const [ButtonShow,SetButtonShow]=useState(true)
    const HideLoader = () => {
        Setloader(false)
    }
    const ShowLoader = () => {
        Setloader(true)
    }

    const CheckAccount=async()=>{
        SetButtonShow(true)
        try {

            const response = await axiosJWT.get("/payments/check-account-capability", {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              if(response.status==200)
              {
               
                SetValidAccount(true)
              }

        }catch(err)
        {
             console.log('innnn',err)
             SetValidAccountMsg(err.response.data.msg)
             if(err.response.data.msg==='Seems like your connected account with stripe is under pending verification.Please wait')
             {
                SetButtonShow(false)
             }
             SetValidAccount(false)
             
         
        }

     

      
       

        //   if(response.status===500)
        //   {
        //     SetValidAccountMsg(response.error.data.msg)
        //     SetValidAccount(false)
        //   }
        //   else if(response.status===200)
        //   {
        //     SetValidAccount(true)
        //   }

        //   console.log(response)

    }

    
        const NavigateStripeAccount=async()=>{

            ShowLoader()
    
            const response = await axiosJWT.post('/payments/connect-stripe-account',
            {
    
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
        if (response) {
            HideLoader()
            window.location.href = response.data.account_link
        }
           
        }
    

    useEffect(()=>{
        CheckAccount()
    },[])

    return (
        <div>
  {loader === true && <div id="semiTransparenDivLoader"></div>}
            {
                ValidAccount===false && 
                <div id="overlay">

                    <Modal show={true} className="confirm_delete premim_plan_upgrade">
                        <Modal.Header>
                            <Modal.Title>{'Connected Account!'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                         {ValidAccountMsg}


                        </Modal.Body>

                        <Modal.Footer className="display_block">
{
    ButtonShow===true &&
    <Button className="modal_action_button float_right" variant="primary" onClick={(e) => { NavigateStripeAccount(e) }}>
                                Connect Now
                            </Button>
}
                        
                        </Modal.Footer>


                    </Modal>

                </div>
            }

        </div>
    )

}
export default CheckConnectedAccount
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import female_icon from "../../../images/female.png";
import male_icon from "../../../images/male.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CoachBlogs=(props)=>{


    const token = localStorage.getItem("token");
    const user_timezone = localStorage.getItem("user_timezone");
    const axiosJWT = axios.create();
    const [BlogList,SetBlogList]=useState([])
    const [show,Setshow]=useState(false)
    const [BlogData,SetBlogData]=useState()
    const [NutritionId,SetNutritionId]=useState('')
    const handleClose=()=>{
        Setshow(false)
    }


    const GetBlogs=async(NutritionId,pagenumber)=>{

        const response = await axiosJWT.get("/blog?userid=" + NutritionId + "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response.data!==null)
      {
        if(response.data.blogs!==null)
        {
            SetBlogList(response.data.blogs)
        }
      }


    }

    const ViewBlog=async(e)=>{
        const BlogId = e.target.closest(".navigateclientdashboard").getAttribute("data-id");
        const response = await axiosJWT.get("/blog/"+BlogId+"?userid=" + NutritionId + "",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response.data!=null)
      {
        console.log(response.data.content)
        SetBlogData(response.data)
        Setshow(true)
      }



    }

    useEffect(()=>{
   if(props.ClientId)
   {
    SetNutritionId(props.ClientId)
    GetBlogs(props.ClientId,1)
   }
    },[props])

    return(
        <div>
  <Table className="table is-striped is-fullwidth">
                <Thead className="thead">
                    <Tr>
                        <Th>Title</Th>
                        <Th>Program</Th>
                        <Th>Action</Th>
                      
                        
                    </Tr>
                </Thead>
                <Tbody className="tbody">
                {BlogList.map((list, index) => (
                        <Tr className='trow navigateclientdashboard' key={index} data-id={list.id} onClick={(e)=>{ViewBlog(e)}}>
                            <Td>{list.title}</Td>
                            <Td>{list.service_category.category_name}</Td>
                            <Td>View</Td>
                         </Tr>
                    ))}
       
                </Tbody>
            </Table>

            <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>Blog Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {BlogData ?
                                <div className='recipe_data'>
                                    <div className='title'>{BlogData.title}</div>
                                    <div className='title'>Program: {BlogData.service_category.category_name}</div>
                                    <div className='img'><img src={BlogData.blog_img} /></div>
                                    <div className='nutrition_data' dangerouslySetInnerHTML={{ __html: BlogData.content}}>

                                     
                              
                                
                                        

                                    </div>
                                  
                                </div>
                                : null}

                        </Modal.Body>
                        <Modal.Footer style={{ "margin": "0 auto" }}>
                            <Button variant="primary" className='modal_action_button'  onClick={handleClose}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
            
        </div>
    )

}
export default CoachBlogs
import React, { useEffect, useState } from "react";

const Pregnant = (props) => {


  const [Pregnant,SetPregnant]=useState('')

 
  const handleOptionChangePregnant = (e) => {
    SetPregnant(e.target.value);
  };

  

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }
    

  
    if(props.PreganantPC)
    {
      SetPregnant(props.PreganantPC)
    }
  
   
  }, []);

  useEffect(() => {
    setTimeout(() => {
  

      var Err = 0;
    
      if (Pregnant==='Yes')
      {
        props.toast.error('We are sorry. You dont qualify. Pregnancy women cant take these medication', {
          toastId: 'error1',
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        
          });
        Err = 1;
      }
      else if (Pregnant==='')
      {
        Err = 1;
      }

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [Pregnant]);

  useEffect(()=>{


    props.SetPreganantPC(Pregnant)
  
  
    


  },[Pregnant])


  useEffect(()=>{
    props.SetPreviousMenu('basic_info')
    props.SetNextMenu('breastfeeding')
   
  
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>

   

   
        <div className="WomenSelfMedication_wrap">
          <div className="input_row">
            <div>
              <label>
               {props.PreganantPCLabel}
              </label>
            </div>
            <div className="radio_input_yes_no">
              <input
                type="radio"
                value="Yes"
                checked={Pregnant === "Yes"}
                onChange={handleOptionChangePregnant}
              />
              <label>Yes</label>

              <input
                type="radio"
                value="No"
                checked={Pregnant === "No"}
                onChange={handleOptionChangePregnant}
              />
              <label>No</label>
            </div>
          </div>
        </div>
     

    

     








    </div>
  );
};
export default Pregnant;

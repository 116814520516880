import React, { useEffect, useState } from "react";

const GLPMedicationCM = (props) => {
  const [GLPMedication, SetGLPMedication] = useState("");

  const handleOptionChange = (e) => {
    SetGLPMedication(e.target.value);
  };

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.GLPMedicationPC) {
        SetGLPMedication(props.GLPMedicationPC);
    }
   
  }, []);

  useEffect(() => {
    setTimeout(() => {

      props.SetGLPMedicationPC(GLPMedication);

      var Err = 0;
      if (GLPMedication === "") {
        Err = 1;
      }
     

      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [GLPMedication]);



  useEffect(()=>{
    props.SetPreviousMenu('currentmedication')
  
   
     
  },[])

  useEffect(()=>{

    if(GLPMedication.includes('None of these'))
    {
        props.SetNextMenu('doctorinfo')
    }

    if(GLPMedication.includes('Tirzepatide (Zepbound,Mounjaro)') || GLPMedication.includes('Semaglutide (Ozempic,Wegovy,Rybelsus)') )
    {
        props.SetNextMenu('sideeffect')
    }

  },[GLPMedication])



  return (
    <div className="daily_activity" id="glp_medication_possible_answers">
      <div className="head_row"></div>
      <div>
          <label>
          <h6>{props.GLPMedicationPCLabel}</h6>
          </label>
     
        </div>
      <div className="input_row">
    
        <div className="radio_input_yes_no">
          <input
            type="radio"
            value="Semaglutide (Ozempic,Wegovy,Rybelsus)"
            checked={GLPMedication === "Semaglutide (Ozempic,Wegovy,Rybelsus)"}
            onChange={handleOptionChange}
          />
          <label>Semaglutide (Ozempic,Wegovy,Rybelsus)</label>

  
        
        </div>


        <div className="radio_input_yes_no">
        <input
            type="radio"
            value="Tirzepatide (Zepbound,Mounjaro)"
            checked={GLPMedication === "Tirzepatide (Zepbound,Mounjaro)"}
            onChange={handleOptionChange}
          />
          <label>Tirzepatide (Zepbound,Mounjaro)</label>
            </div>

            <div className="radio_input_yes_no">
            <input
            type="radio"
            value="None of these"
            checked={GLPMedication === "None of these"}
            onChange={handleOptionChange}
          />
          <label>None of these</label>
            </div>



      </div>

    
    </div>
  );
};
export default GLPMedicationCM;

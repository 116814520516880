import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import female_icon from "../../images/female.png";
import male_icon from "../../images/male.png";
import CoachClients from "./AdminCoachDashboard/CoachClients";
import CoachClientAppointments from "./AdminCoachDashboard/CoachClientAppointments";
import CoachBlogs from "./AdminCoachDashboard/CoachBlogs";
import CoachServices from "./AdminCoachDashboard/CoachServices";
import CoachPayments from "./AdminCoachDashboard/CoachPayments";
import CoachEarnings from "./AdminCoachDashboard/CoachEarnings";
import CoachImportedRecipes from "./AdminCoachDashboard/CoachImportedRecipes";
import CoachMealPlan from "./AdminCoachDashboard/CoachMealPlan";


const AdminCoachDashboard = (props) => {
  const BackToManageUsers = () => {
    props.HideDashboardTab();
  };
  const NagivateClient=(Userid)=>{
    props.ShowClientDashboardTrigger(Userid)
  }
  const [issidenav, setissidenav] = useState(false);
  const [ClientFirstName, SetClientFirstName] = useState("");
  const [ClientLastName, SetClientLastName] = useState("");
  const [ClientDob, SetClientDob] = useState("");
  const [ClientProfilePic, SetClientProfilePic] = useState("");
  const [ClientGender, SetClientGender] = useState("");
  const [ClientLastAccessed, SetClientLastAccessed] = useState("");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [ClientId, SetClientId] = useState("");
  const [profileData, SetprofileData] = useState("");
  const [CoachExpertIn, SetCoachExpertIn] = useState("");
  const [CoachBio,SetCoachBio]=useState('')
  const [profileTab, setprofileTab] = useState("clients");
  const userNav = () => {
    if (issidenav === true) {
      document
        .getElementById("user-fa-icon")
        .setAttribute("class", "fa fa-chevron-circle-left slide_icon");
      document
        .getElementById("users_list")
        .setAttribute("class", "users_list show");
    }

    if (issidenav === false) {
      document
        .getElementById("user-fa-icon")
        .setAttribute("class", "fa fa-chevron-circle-right slide_icon");
      document
        .getElementById("users_list")
        .setAttribute("class", "users_list hide");
    }

    setissidenav(!issidenav);
  };

  const GetUserDetails = async (Clientid) => {
    const response = await axiosJWT.get("/user/" + Clientid, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    SetprofileData(response.data);

    if (response.data.first_name) {
      SetClientFirstName(response.data.first_name);
    }
    if (response.data.last_name) {
      SetClientLastName(response.data.last_name);
    }
    if (response.data.user_dob) {
      SetClientDob(response.data.user_dob);
    }
    if (response.data.user_profile_photo) {
      SetClientProfilePic(response.data.user_profile_photo);
    }
    if (response.data.gender) {
      SetClientGender(response.data.gender);
    }
    if (response.data.updatedAt) {
      SetClientLastAccessed(response.data.updatedAt);
    }
    if (
      response.data.nutritionist_infos[0].specialized_in !== undefined &&
      response.data.nutritionist_infos[0].specialized_in !== null &&
      response.data.nutritionist_infos[0].specialized_in !== ""
    ) {
      SetCoachExpertIn(response.data.nutritionist_infos[0].specialized_in);
    }
    if (
        response.data.nutritionist_infos[0].coach_bio !== undefined &&
        response.data.nutritionist_infos[0].coach_bio !== null &&
        response.data.nutritionist_infos[0].coach_bio !== ""
      ) {
        SetCoachBio(response.data.nutritionist_infos[0].coach_bio);
      }
    console.log("response", response);
  };

  const changeTabs = async (e) => {
    const list_for = e.target.getAttribute("data-item");
    setprofileTab(list_for);
  };

  useEffect(() => {
    if (props.ClickedUserId) {
      SetClientId(props.ClickedUserId);
      GetUserDetails(props.ClickedUserId);
    }
  }, [props]);

  return (
    <section className="dashboard admin_client_dashboard">
      <div className="coach_users container ">
        <div className="title_box">
          <div className="leftmenu">
            <h4>Coach Dashboard</h4>
            <br></br>
          </div>
          <div className="rightmenu">
            <button className="add_data_coach" onClick={BackToManageUsers}>
              Back
            </button>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <div className="section_box">
          <div
            id="leftblock"
            className={issidenav ? "leftblock shrink" : "leftblock enlarge"}
          >
            <div className="user_header">
              <div className="p_title color_grey">Coach User:</div>
              <i
                id="user-fa-icon"
                className="fa fa-chevron-circle-left slide_icon"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={userNav}
              ></i>
            </div>
            <div style={{ clear: "both" }} />
            <div id="users_list" className="users_list">
              <div id="user_56" className="user_data active">
                <div className="profile_photo">
                  <img
                    className="table_profile_img"
                    src={
                      ClientProfilePic
                        ? ClientProfilePic
                        : ClientGender === "MALE"
                        ? male_icon
                        : female_icon
                    }
                  />
                </div>
                <div className="profile_info">
                  <span className="name">
                    {ClientFirstName + " " + ClientLastName}
                  </span>
                  <br />
                  {CoachExpertIn && CoachExpertIn !== "" ? (
                    <span className="color_grey">{CoachExpertIn}</span>
                  ) : (
                    <span className="color_grey">------</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            id="rightblock"
            className={issidenav ? "rightblock shrink" : "rightblock enlarge"}
          >
                 <div className="topcontent">
                 <div className="user_info" data-item="56">
                 <div className="header_box">
                 <div className="menubox">
                    <div className="leftmenu">
                      <div className="profile_photo">
                        <img
                          className="table_profile_img"
                          src={
                            ClientProfilePic
                              ? ClientProfilePic
                              : ClientGender === "MALE"
                              ? male_icon
                              : female_icon
                          }
                        />
                      </div>
                      <div className="profile_info">
                        <span className="name">
                          {ClientFirstName + " " + ClientLastName}
                        </span>
                        <br />
                        <span className="color_grey" style={{ fontSize: "12px" }}>
                        Last accessed: {ClientLastAccessed !== "" &&
                            format(parseISO(ClientLastAccessed), `PP p`)}
                        </span>
                      </div>
                    </div>
                    <div className="rightmenu"></div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div className="input_fields">
                  <div className="row">
                  <div className="col">
                        <label className="inner_label">Specialized In</label>
                        <br />
                        <div className="input_value">
                          {CoachExpertIn && CoachExpertIn != "" ? (
                            <div className="input_value">
                              {CoachExpertIn}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                  <div className="col">
                        <label className="inner_label">Coach Bio</label>
                        <br />
                        <div className="input_value">
                          {CoachBio && CoachBio != "" ? (
                            <div className="input_value">
                              {CoachBio}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style={{ clear: "both" }} />
                    <div className="tabmenu">
                    <a
                  className={profileTab === "clients" ? "active" : null}
                  onClick={changeTabs}
                  data-item="clients"
                >
                  Clients
                </a>
                <a
                  className={profileTab === "appointments" ? "active" : null}
                  onClick={changeTabs}
                  data-item="appointments"
                >
                  Appointments
                </a>
                <a
                  className={profileTab === "blogs" ? "active" : null}
                  onClick={changeTabs}
                  data-item="blogs"
                >
                  Blogs
                </a>
                <a
                  className={profileTab === "services" ? "active" : null}
                  onClick={changeTabs}
                  data-item="services"
                >
                  Services
                </a>
                <a
                  className={profileTab === "payments" ? "active" : null}
                  onClick={changeTabs}
                  data-item="payments"
                >
                  Payments
                </a>
                <a
                  className={profileTab === "earnings" ? "active" : null}
                  onClick={changeTabs}
                  data-item="earnings"
                >
                  Earnings
                </a>
                <a
                  className={profileTab === "meal_plan" ? "active" : null}
                  onClick={changeTabs}
                  data-item="meal_plan"
                >
                  Meal plan
                </a>
                <a
                  className={profileTab === "imported_recipes" ? "active" : null}
                  onClick={changeTabs}
                  data-item="imported_recipes"
                >
                  Imported recipes
                </a>
               
                        </div>
                        <div className="tabcontent">
                            {
                                profileTab==='clients' && <CoachClients ClientId={ClientId} NagivateClient={NagivateClient}/>
                            }
                            {
                                profileTab==='appointments' && <CoachClientAppointments ClientId={ClientId}/>
                            }
                            {
                                profileTab==='blogs' && <CoachBlogs ClientId={ClientId}/>
                            }
                            {
                              profileTab==='services' && <CoachServices ClientId={ClientId}/>
                            }
                            {
                              profileTab==='payments' && <CoachPayments ClientId={ClientId}   />
                            }
                            {
                              profileTab==='earnings' && <CoachEarnings ClientId={ClientId}/>
                            }
                            {
                             profileTab==='imported_recipes' && <CoachImportedRecipes ClientId={ClientId}/>
                            }
                            {
                             profileTab==='meal_plan' && <CoachMealPlan ClientId={ClientId}/>
                            }
                            </div>
                 </div>
            </div>
        </div>
      </div>
    </section>
  );
};
export default AdminCoachDashboard;

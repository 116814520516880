import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Datepicker from 'react-date-picker';
import axios from "axios";
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";

const CoachPayments=(props)=>{

    const [FromDate, SetFromDate] = useState('')
    const [ToDate, SetToDate] = useState('')
    const [PaymnentPageNo,SetPaymnentPageNo]=useState(1)
    const [TotalRecordPayment,SetTotalRecordPayment]=useState(0)
    const [PaymentPerPage,SetPaymentPerPage]=useState(50)
    const [PaymentHistoryList,SetPaymentHistoryList]=useState([])
    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [NutritionId,SetNutritionId]=useState('')

    const FilterPayments=()=>{
        GetPaymentHistory(1,ChangeDatePickerDate(FromDate),ChangeDatePickerDate(ToDate),NutritionId)

    }

    const ResetPayments=()=>{
        SetFromDate('')
        SetToDate('')
        GetPaymentHistory(1,'','',NutritionId)
    }

    const GetPaymentHistory = async (pagenumber, fromdate = '', todate = '',coachid='') => {
      

        SetPaymnentPageNo(pagenumber)
        const response = await axiosJWT.get('payments/list-payments-history?offset=' + pagenumber + '&limit=' + PaymentPerPage + '&startdate=' + fromdate + '&todate=' + todate + '&coach_id='+coachid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetPaymentHistoryList([]);
            SetTotalRecordPayment(response.data.total_count);
            if (response.data.NutritionistBillingPayment.length > 0) {
                SetPaymentHistoryList(response.data.NutritionistBillingPayment);
            }
        });
    }

    const changePageNumberS=(pagenumber)=>{
      
        GetPaymentHistory(pagenumber,ChangeDatePickerDate(FromDate),ChangeDatePickerDate(ToDate),NutritionId)
    }
    const ChangeDatePickerDate = (pass) => {
        if (pass !== '') {
            var passDate = '';
            var yyyy = pass.getFullYear().toString();
            var mm = (pass.getMonth() + 1).toString();
            var dd = pass.getDate().toString();
            passDate = yyyy + '-' + mm + '-' + dd;
            return passDate;
        }
        else {
            return '';
        }
    }

    useEffect(()=>{
     
    if(props.ClientId)
    {
        SetNutritionId(props.ClientId)
        GetPaymentHistory(1,'','',props.ClientId)
    }
    },[props])

    return(
      <div className="admin_coach_dashboard">

        <div className="filter_options_payment">
            <div>
                <span>From Date : </span>
            <Datepicker
                                    value={FromDate}
                                    onChange={SetFromDate}
                                    format="MM-dd-yyyy"
                                    className={'date_picker_payment_coach'}
                                />
            </div>
            <div>
            <span>To Date : </span>
            <Datepicker
                                    value={ToDate}
                                    onChange={SetToDate}
                                    format="MM-dd-yyyy"
                                    className={'date_picker_payment_coach'}
                                />
            </div>
            <div>
                <button className="action_buttons" onClick={FilterPayments}>Filter</button>
            </div>
            <div>
                <button className="action_buttons" onClick={ResetPayments}>Reset</button>
            </div>

        </div>
        
<Table className="table is-striped is-fullwidth upcoming_appointment_table">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Plan Name</Th>
            <Th scope="col">Plan Amount</Th>
            <Th scope="col">Gateway fee</Th>
            <Th scope="col">Admin commission</Th>
            <Th scope="col">Earnings</Th>
            <Th scope="col">Client</Th>
            <Th>Payment Date</Th>
          </Tr>
        </Thead>
        <Tbody className="tbody">
        {PaymentHistoryList.map((payment, index) => (  
        <Tr className="trow">
            <Td>{payment.nutritionist_service.service_name}</Td>
            <Td>{'$ ' + payment.nutritionist_service.service_price}</Td>
            <Td>{'$ ' + payment.fee}</Td>
            <Td>{'$ ' + payment.admin_commission}</Td>
            <Td>{'$ ' + payment.billing_amount}</Td>
            <Td>{payment.user.first_name + ' ' + payment.user.last_name}({payment.user.email})</Td>
              <Td className="second_section_payment_span">{format(parseISO(payment.payment_date), `PP`)}</Td>
            </Tr>
              ))}
            </Tbody>
        </Table>
        {PaymentHistoryList.length > 0 &&
                                    <div>
                                        <Pagination
                                            activePage={PaymnentPageNo}
                                            itemsCountPerPage={PaymentPerPage}
                                            totalItemsCount={TotalRecordPayment}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }
      </div>
    )
}
export default CoachPayments
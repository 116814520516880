import React, { useEffect, useState,useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Line } from "react-chartjs-2";
import { Chart, registerables, ChartOptions } from 'chart.js';
import PremiumCheckComponent from "../PremiumPlanPopup";
import ExerciseDiary from './ExerciseDiary' 
Chart.register(...registerables);



const ClientProgressDiary = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [startDate, setStartDate] = useState(new Date());
    const [activityshow, Setactivityshow] = useState(false)
    const [ShowWeightValue, SetShowWeightValue] = useState('')
    const [ShowCaloriesValue,SetShowCaloriesValue]=useState('')
    const [ShowWaterValue, SetShowWaterValue] = useState('')
    const [ShowActivityValue, SetShowActivityValue] = useState('')
    const [ShowSleepValue, SetShowSleepValue] = useState('')
    const [ShowWasitValue, SetShowWaistValue] = useState('')
    const [ShowNeckValue, SetShowNeckValue] = useState('')
    const [ShowArmsValue, SetShowArmsValue] = useState('')
    const [ShowThighsValue, SetShowThighsValue] = useState('')
    const [ShowHipValue, SetShowHipValue] = useState('')
    const [Chartlabel, SetChartlabel] = useState([])
    const [WeightChartArray, SetWeightChartArray] = useState([])
    const [WaterChartArray, SetWaterChartArray] = useState([])
    const [CaloriesChartArray,SetCaloriesChartArray]=useState([])
    const [ActivityChartArray, SetActivityChartArray] = useState([])
    const [SleepChartArray, SetSleepChartArray] = useState([])
    const [WaistChartArray, SetWaistChartArray] = useState([])
    const [NeckChartArray, SetNeckChartArray] = useState([])
    const [ArmsChartArray, SetArmsChartArray] = useState([])
    const [ThighsChartArray, SetThighsChartArray] = useState([])
    const [HipsChartArray, SetHipsChartArray] = useState([])
    const [profileData, setprofileData] = useState([]);

    const [ValueErr, SetValueErr] = useState(false)
    const [ValueErrMsg, SetValueErrMsg] = useState('')
    const datepicker1Ref = useRef(null);
    function handleClickDatepickerIcon1() {
        const datepickerElement = datepicker1Ref.current;
     
        datepickerElement.setFocus(true);
      }



    const load_profile = async () => {


        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


            setprofileData(response.data);



        });


    }

    const ChangedDate = (date) => {

        GetProgressDiary(date)
        GetExerciseDiaryData(date)

        setStartDate(date)
    }





    const Weightstate = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Weight ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: WeightChartArray,
                // pointRadius: 0,
            }
        ]
    }
    const Waterstate = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Water ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: WaterChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const Caloriesstate = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Calories ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: CaloriesChartArray,
                // pointRadius: 0,
            }
        ]
    }

    var yactivityLabels = { 0: 'Not Active', 1: 'Somewhat Active', 2: 'Average Activity', 3: 'Very Active', 4: 'Extremely Active' }

    const ActivityState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Activity ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: ActivityChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const SleepState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Sleep ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: SleepChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const WaistState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Waist ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: WaistChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const NeckState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Neck ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: NeckChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const ArmsState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Arms ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: ArmsChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const ThighsState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Thighs ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: ThighsChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const HipsState = {
        labels: Chartlabel,
        datasets: [
            {
                label: 'Hip ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#226920',
                borderWidth: 4,
                data: HipsChartArray,
                // pointRadius: 0,
            }
        ]
    }


    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },

            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },


            }
        },
        plugins: {
            legend: {
                display: false

            }
        }
    }

    const ActivityOption = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },

            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return yactivityLabels[value];
                    }
                    , min: 0, max: 4
                },



            }
        },
        plugins: {
            legend: {
                display: false

            }
        }
    }



    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        GetProgressDiaryChart(tab_for)



    }

    //add-diary

    const [showAdd, setShowAdd] = useState(false);

    const handleClosesAdd = () => setShowAdd(false);
    const handleShowsAdd = () => setShowAdd(true);
    const [Addmodallabel, SetAddmodallabel] = useState('')
    const [inputAddlabel, SetinputAddlabel] = useState('')
    const [DefaultAddValue, SetDefaultAddValue] = useState('')
    const [FinalAddtarget, SetFinalAddtarget] = useState('')

    const trigger_pop_add = async (e) => {

        SetValueErr(false)

        Setactivityshow(false)
        SetDefaultAddValue('')
        var target = e.target.getAttribute('data-target')
        SetFinalAddtarget(target)

        const dateTimeInParts = startDate.toISOString().split("T");
        const date = dateTimeInParts[0];

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

          


            if (target === 'activity') {

                if (response.data.final_data[0][0] && response.data.final_data[0][0].activity !== 'undefined' && response.data.final_data[0][0].activity !== null && response.data.final_data[0][0].activity !== '') {
                    SetDefaultAddValue(response.data.final_data[0][0].activity)
                }
                SetAddmodallabel(target.charAt(0).toUpperCase() + target.slice(1))
                Setactivityshow(true)

                handleShowsAdd()
            }
            else {
                SetAddmodallabel(target.charAt(0).toUpperCase() + target.slice(1))
                if (target === 'weight') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].weight !== 'undefined' && response.data.final_data[0][0].weight !== null && response.data.final_data[0][0].weight !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].weight)
                    }

                    if (profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial') {

                        SetinputAddlabel('Your current weight ("lbs")')
                    }
                    else {
                        SetinputAddlabel('Your current weight ("kg")')
                    }



                }
                else if (target === 'calories') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].calories !== 'undefined' && response.data.final_data[0][0].calories !== null && response.data.final_data[0][0].calories !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].calories)
                    }
                    SetinputAddlabel('Burnt Calories (Kcal)')
                }
                else if (target === 'water') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].water !== 'undefined' && response.data.final_data[0][0].water !== null && response.data.final_data[0][0].water !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].water)
                    }
                    SetinputAddlabel('Water Intake (Oz)')
                }
                else if (target === 'sleep') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].sleep !== 'undefined' && response.data.final_data[0][0].sleep !== null && response.data.final_data[0][0].sleep !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].sleep)
                    }
                    SetinputAddlabel('Sleep hours (hr)')
                }
                else if (target === 'waist') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].waist !== 'undefined' && response.data.final_data[0][0].waist !== null && response.data.final_data[0][0].waist !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].waist)
                    }
                    SetinputAddlabel('Your current waist value (inch)')
                }
                else if (target === 'neck') {

                    if (response.data.final_data[0][0] && response.data.final_data[0][0].neck !== 'undefined' && response.data.final_data[0][0].neck !== null && response.data.final_data[0][0].neck !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].neck)
                    }
                    SetinputAddlabel('Your current neck value (inch)')
                }
                else if (target === 'arms') {
                    if (response.data.final_data[0][0] && response.data.final_data[0][0].arms !== 'undefined' && response.data.final_data[0][0].arms !== null && response.data.final_data[0][0].arms !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].arms)
                    }
                    SetinputAddlabel('Your current arms value (inch)')
                }
                else if (target === 'thighs') {

                    if (response.data.final_data[0][0] && response.data.final_data[0][0].thighs !== 'undefined' && response.data.final_data[0][0].thighs !== null && response.data.final_data[0][0].thighs !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].thighs)
                    }
                    SetinputAddlabel('Your current thighs value (inch)')
                }
                else if (target === 'hips') {


                    if (response.data.final_data[0][0] && response.data.final_data[0][0].hips !== 'undefined' && response.data.final_data[0][0].hips !== null && response.data.final_data[0][0].hips !== '') {
                        SetDefaultAddValue(response.data.final_data[0][0].hips)
                    }
                    SetinputAddlabel('Your current hip value (inch)')
                }

                handleShowsAdd()
            }

        })






    }

    const FinalAddButton = async (e) => {

        var err = false;
        var target = e.target.getAttribute('data-target')
        SetValueErr(false)
        if (DefaultAddValue === '') {
            if (target !== 'activity') {
                SetValueErr(true)
                SetValueErrMsg('Please enter value')
                err = true
            }
            else {
                SetValueErr(true)
                SetValueErrMsg('Please select value')
                err = true
            }
            if (err === true) {
                return false
            }
        }


        if (DefaultAddValue !== '') {


            var err=false;
            // if(target==='weight' || target==='water' || target==='sleep' || target==='waist' || target==='neck' || target==='arms' || target==='thighs' || target==='hips')
            // {

            //     const Numreg = new RegExp('^[0-9]+$');
            //     if (!Numreg.test(DefaultAddValue)) {
            //         SetValueErr(true)
            //         SetValueErrMsg('Please enter round off value')
            //         err = true
            //      }


            // }
            if(err===false)
            {
                if (target === 'weight') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'weight': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
                if (target === 'water') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'water': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'calories') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'calories': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'activity') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'activity': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'sleep') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'sleep': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'waist') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'waist': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'neck') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'neck': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'arms') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'arms': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'thighs') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'thighs': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
    
                if (target === 'hips') {
                    const response = await axiosJWT.post('/progress-diary',
                        {
                            'hips': DefaultAddValue,
                            'ddate': startDate
    
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        GetProgressDiary(startDate)
                        GetProgressDiaryChart('week')
                        clearFunction()
                    }
    
                }
                handleClosesAdd()
            }
            



        }
       
    }

    const GetProgressDiary = async (DatePass) => {

        SetShowWeightValue('')
        SetShowWaterValue('')
        SetShowCaloriesValue('')
        SetShowActivityValue('')
        SetShowSleepValue('')
        SetShowWaistValue('')
        SetShowNeckValue('')
        SetShowArmsValue('')
        SetShowThighsValue('')
        SetShowHipValue('')


        const dateTimeInParts = DatePass.toISOString().split("T");
        const date = dateTimeInParts[0];

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


            if (response.data.final_data[0][0] && response.data.final_data[0][0].weight !== 'undefined' && response.data.final_data[0][0].weight !== null && response.data.final_data[0][0].weight !== '') {
                SetShowWeightValue(response.data.final_data[0][0].weight)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].water !== 'undefined' && response.data.final_data[0][0].water !== null && response.data.final_data[0][0].water !== '') {
                SetShowWaterValue(response.data.final_data[0][0].water)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].calories !== 'undefined' && response.data.final_data[0][0].calories !== null && response.data.final_data[0][0].calories !== '') {
                SetShowCaloriesValue(response.data.final_data[0][0].calories)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].activity !== 'undefined' && response.data.final_data[0][0].activity !== null && response.data.final_data[0][0].activity !== '') {
                SetShowActivityValue(response.data.final_data[0][0].activity)
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].sleep !== 'undefined' && response.data.final_data[0][0].sleep !== null && response.data.final_data[0][0].sleep !== '') {
                SetShowSleepValue(response.data.final_data[0][0].sleep)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].waist !== 'undefined' && response.data.final_data[0][0].waist !== null && response.data.final_data[0][0].waist !== '') {
                SetShowWaistValue(response.data.final_data[0][0].waist)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].neck !== 'undefined' && response.data.final_data[0][0].neck !== null && response.data.final_data[0][0].neck !== '') {
                SetShowNeckValue(response.data.final_data[0][0].neck)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].arms !== 'undefined' && response.data.final_data[0][0].arms !== null && response.data.final_data[0][0].arms !== '') {
                SetShowArmsValue(response.data.final_data[0][0].arms)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].thighs !== 'undefined' && response.data.final_data[0][0].thighs !== null && response.data.final_data[0][0].thighs !== '') {
                SetShowThighsValue(response.data.final_data[0][0].thighs)
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].hips !== 'undefined' && response.data.final_data[0][0].hips !== null && response.data.final_data[0][0].hips !== '') {
                SetShowHipValue(response.data.final_data[0][0].hips)
            }


        })

    }

    const DeleteProgressDiary = async (e) => {
        var target = e.target.getAttribute('data-target')


        const response = await axiosJWT.post('/progress-diary/delete',
            {
                'key': target,
                'ddate': startDate


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            GetProgressDiary(startDate)
            GetProgressDiaryChart('week')
            clearFunction()
        }

    }

    const GetProgressDiaryChart = async (range) => {

        

        var WeightArray = [];
        var Waterarray = [];
        var CaloriesArray=[];
        var Activityarray = [];
        var SleepArray = [];
        var WaistArray = [];
        var NeckArray = [];
        var ArmsArray = [];
        var thighsArray = [];
        var HipArray = [];

        if (range === 'week') {
            SetChartlabel(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'])
        }


        const response = await axiosJWT.get('/progress-diary/chart?range=' + range + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

        

            if (range === 'month') {
                let result = response.data.final_data.map(a => a.date);
                SetChartlabel(result)
            }

            if (range === 'year') {
                let result = response.data.final_data.map(a => a.udate);
                SetChartlabel(result)
            }

            if (range === 'all_time') {
                let result = response.data.final_data.map(a => a.udate);
                SetChartlabel(result)
            }

            if (response.data.final_data.length > 0) {
                response.data.final_data.forEach((value, index, array) => {

                    if (range === 'month' || range === 'week') {

                        if (value.data && value.data.weight !== undefined && value.data.weight !== null && value.data.weight !== '') {

                            WeightArray[index] = parseFloat(value.data.weight)
                        }
                        else {
                            WeightArray[index] = 0
                        }


                        if (value.data && value.data.water !== undefined && value.data.water !== null && value.data.water !== '') {

                            Waterarray[index] = parseFloat(value.data.water)
                        }
                        else {
                            Waterarray[index] = 0
                        }

                        if (value.data && value.data.calories !== undefined && value.data.calories !== null && value.data.calories !== '') {

                            CaloriesArray[index] = parseFloat(value.data.calories)
                        }
                        else {
                            CaloriesArray[index] = 0
                        }

                        if (value.data && value.data.activity !== undefined && value.data.activity !== null && value.data.activity !== '') {

                            if (value.data.activity === 'not_active') {
                                Activityarray[index] = 0
                            }
                            else if (value.data.activity === 'somewhat_active') {
                                Activityarray[index] = 1
                            }
                            else if (value.data.activity === 'average_activity') {
                                Activityarray[index] = 2
                            }
                            else if (value.data.activity === 'very_active') {
                                Activityarray[index] = 3
                            }
                            else if (value.data.activity === 'extremely_active') {
                                Activityarray[index] = 4
                            }
                            else {
                                Activityarray[index] = 0
                            }


                        }
                        else {
                            Activityarray[index] = 0
                        }

                        if (value.data && value.data.sleep !== undefined && value.data.sleep !== null && value.data.sleep !== '') {

                            SleepArray[index] = parseFloat(value.data.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value.data && value.data.sleep !== undefined && value.data.sleep !== null && value.data.sleep !== '') {

                            SleepArray[index] = parseFloat(value.data.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value.data && value.data.waist !== undefined && value.data.waist !== null && value.data.waist !== '') {

                            WaistArray[index] = parseFloat(value.data.waist)
                        }
                        else {
                            WaistArray[index] = 0
                        }

                        if (value.data && value.data.neck !== undefined && value.data.neck !== null && value.data.neck !== '') {

                            NeckArray[index] = parseFloat(value.data.neck)
                        }
                        else {
                            NeckArray[index] = 0
                        }


                        if (value.data && value.data.arms !== undefined && value.data.arms !== null && value.data.arms !== '') {

                            ArmsArray[index] = parseFloat(value.data.arms)
                        }
                        else {
                            ArmsArray[index] = 0
                        }

                        if (value.data && value.data.thighs !== undefined && value.data.thighs !== null && value.data.thighs !== '') {

                            thighsArray[index] = parseFloat(value.data.thighs)
                        }
                        else {
                            thighsArray[index] = 0
                        }

                        if (value.data && value.data.hips !== undefined && value.data.hips !== null && value.data.hips !== '') {

                            HipArray[index] = parseFloat(value.data.hips)
                        }
                        else {
                            HipArray[index] = 0
                        }

                    } else {

                        if (value && value.weight !== undefined && value.weight !== null && value.weight !== '') {

                            WeightArray[index] = parseFloat(value.weight)
                        }
                        else {
                            WeightArray[index] = 0
                        }


                        if (value && value.water !== undefined && value.water !== null && value.water !== '') {

                            Waterarray[index] = parseFloat(value.water)
                        }
                        else {
                            Waterarray[index] = 0
                        }

                        if (value && value.calories !== undefined && value.calories !== null && value.calories !== '') {

                            CaloriesArray[index] = parseFloat(value.calories)
                        }
                        else {
                            CaloriesArray[index] = 0
                        }

                        if (value && value.activity !== undefined && value.activity !== null && value.activity !== '') {

                            if (value.activity === 'not_active') {
                                Activityarray[index] = 0
                            }
                            else if (value.activity === 'somewhat_active') {
                                Activityarray[index] = 1
                            }
                            else if (value.activity === 'average_activity') {
                                Activityarray[index] = 2
                            }
                            else if (value.activity === 'very_active') {
                                Activityarray[index] = 3
                            }
                            else if (value.activity === 'extremely_active') {
                                Activityarray[index] = 4
                            }
                            else {
                                Activityarray[index] = 0
                            }


                        }
                        else {
                            Activityarray[index] = 0
                        }

                        if (value && value.sleep !== undefined && value.sleep !== null && value.sleep !== '') {

                            SleepArray[index] = parseFloat(value.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value && value.sleep !== undefined && value.sleep !== null && value.sleep !== '') {

                            SleepArray[index] = parseFloat(value.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value && value.waist !== undefined && value.waist !== null && value.waist !== '') {

                            WaistArray[index] = parseFloat(value.waist)
                        }
                        else {
                            WaistArray[index] = 0
                        }

                        if (value && value.neck !== undefined && value.neck !== null && value.neck !== '') {

                            NeckArray[index] = parseFloat(value.neck)
                        }
                        else {
                            NeckArray[index] = 0
                        }


                        if (value && value.arms !== undefined && value.arms !== null && value.arms !== '') {

                            ArmsArray[index] = parseFloat(value.arms)
                        }
                        else {
                            ArmsArray[index] = 0
                        }

                        if (value && value.thighs !== undefined && value.thighs !== null && value.thighs !== '') {

                            thighsArray[index] = parseFloat(value.thighs)
                        }
                        else {
                            thighsArray[index] = 0
                        }

                        if (value && value.hips !== undefined && value.hips !== null && value.hips !== '') {

                            HipArray[index] = parseFloat(value.hips)
                        }
                        else {
                            HipArray[index] = 0
                        }

                    }





                })


                SetWeightChartArray(WeightArray)
                SetWaterChartArray(Waterarray)
                SetCaloriesChartArray(CaloriesArray)
                SetActivityChartArray(Activityarray)
                SetSleepChartArray(SleepArray)
                SetWaistChartArray(WaistArray)
                SetNeckChartArray(NeckArray)
                SetArmsChartArray(ArmsArray)
                SetThighsChartArray(thighsArray)
                SetHipsChartArray(HipArray)



            }







        })

    }

     
    const clearFunction = () => {
        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        alltabs[0].classList.add('active')
    }

    const SyncWithings=()=>{
       

        // const Notresponse =axiosJWT.get('/withings/get-data?type=weight', {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // });
    
    }
    useEffect(() => {
        document.title = 'Client-Progress diary';
        GetProgressDiary(startDate)
        GetExerciseDiaryData(startDate)
        GetProgressDiaryChart('week')
        load_profile()
        SyncWithings()
    }, [])

    //exerciseDiary Function Added 
    
    const [ExerciseDiaryShow,SetExerciseDiaryShow]=useState(false)

    const CloseExerciseDiary=()=>{
        SetExerciseDiaryShow(false)
    }
    const OpenExerciseDiary=()=>{
        SetExerciseDiaryShow(true)
    }

    const trigger_pop_add_exercise=()=>{
        OpenExerciseDiary()
    }

    const [ExerciseDiaryArray,SetExerciseDiaryArray]=useState([])
    const GetExerciseDiaryData=async(Datapass='')=>{
        SetExerciseDiaryArray([])

        const dateTimeInParts = Datapass.toISOString().split("T");
        const date = dateTimeInParts[0];

        const response = await axiosJWT.get('/progress-diary/exercise-diary?udate=' + date + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if(response.data!==null)
        {
            SetExerciseDiaryArray(response.data)
        }
  }

  const DeleteExerciseDiary=async(e)=>{
    const id=e.target.getAttribute('data-id')

    const response = await axiosJWT.post('/progress-diary/delete-exercise-diary',
    {
        'id': id,
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    if(response.status===200)
    {
        GetProgressDiary(startDate)
        GetExerciseDiaryData(startDate)
        GetProgressDiaryChart('week')
    }


  }






    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <PremiumCheckComponent />
            <section className="dashboard">
                <div className="container">
                    <div className="row client_food_diary_first_row over_div_fif">

                        <div className="col_1">
                        <span className="client_food_diary_top_label fif_label_progress">Progress Diary</span>
                        </div>
                        <div className="col_2">
                        <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                       
                       <DatePicker selected={startDate} dateFormat="MMMM d,yyyy" onKeyDown={(e) => {
                           e.preventDefault();
                       }} onChange={(date) => ChangedDate(date)} ref={datepicker1Ref} />

                        </div>
                       
                     
                    
                    </div>
                    <div className="row second_row_progress_diary">
                        <div className="add_list_col">
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Daily weight</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'weight'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowWeightValue !== '' &&
                                    <span className="inner_progress_data">Weight: {ShowWeightValue} <i data-target="weight" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row" style={{display:'none'}}>
                                <span className="add_list_col_span">Daily Calories Burnt</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'calories'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowCaloriesValue !== '' &&
                                    <span className="inner_progress_data">Calories Burnt: {ShowCaloriesValue} <i data-target="calories" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Daily water</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'water'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowWaterValue !== '' &&
                                    <span className="inner_progress_data">Water: {ShowWaterValue} <i data-target="water" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Daily activity</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'activity'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowActivityValue !== '' &&
                                    <span className="inner_progress_data">Activity: {ShowActivityValue.replace("_", " ")} <i data-target="activity" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Daily sleep hours</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'sleep'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowSleepValue !== '' &&
                                    <span className="inner_progress_data">Sleep: {ShowSleepValue} <i data-target="sleep" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">waist</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'waist'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowWasitValue !== '' &&
                                    <span className="inner_progress_data">Waist: {ShowWasitValue} <i data-target="waist" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Neck</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'neck'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowNeckValue !== '' &&
                                    <span className="inner_progress_data">Neck: {ShowNeckValue} <i data-target="neck" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Arms</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'arms'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowArmsValue !== '' &&
                                    <span className="inner_progress_data">Arms: {ShowArmsValue}<i data-target="arms" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Thighs</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'thighs'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowThighsValue !== '' &&
                                    <span className="inner_progress_data">Thighs: {ShowThighsValue} <i data-target="thighs" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>
                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Hip</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'hips'} onClick={(e) => { trigger_pop_add(e) }}>
                                    Add
                                </Button>
                                {ShowHipValue !== '' &&
                                    <span className="inner_progress_data">Hip: {ShowHipValue} <i data-target="hips" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" onClick={(e) => { DeleteProgressDiary(e) }}></i></span>}
                            </div>

                            <div className="row add_list_col_row">
                                <span className="add_list_col_span">Exercise</span>
                                <Button variant="primary" className='save_button_commission add_progress_button' id="final_add_button" data-target={'exercise'} onClick={(e) => { trigger_pop_add_exercise(e) }}>
                                    Add
                                </Button>

                                {ExerciseDiaryArray.map((data, index) => (
 <span style={{fontSize:'12px'}} className="inner_progress_data">{data.activity_name}: {data.calories_burnt+' Kcal'} <i style={{fontSize:'12px'}} data-target="hips" className="fa fa-trash billing_edit_i pointer_eff trash_icon_inner_progress" data-id={data.id} onClick={(e)=>{DeleteExerciseDiary(e)}}></i></span>
))}

                             
                            </div>
                        </div>
                        <div className="chart_col">
                            <div className="coach_plans">
                                <div className='menubox'>
                                    <div className='tabmenu'>
                                        <a className="active" onClick={changeTab} data-item="week">Week</a>
                                        <a className="" data-item="month" onClick={changeTab}>Month</a>
                                        <a className="" data-item="year" onClick={changeTab}>Year</a>
                                        <a className="" data-item="all_time" onClick={changeTab}>All time</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Weight Chart</span>
                                <Line
                                    data={Weightstate}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Exercise Chart</span>
                                <Line
                                    data={Caloriesstate}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Water Chart</span>
                                <Line
                                    data={Waterstate}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Activity Chart</span>
                                <Line
                                    data={ActivityState}
                                    options={ActivityOption}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Sleep hours Chart</span>
                                <Line
                                    data={SleepState}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Waist Chart</span>
                                <Line
                                    data={WaistState}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Neck Chart</span>
                                <Line
                                    data={NeckState}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Arms Chart</span>
                                <Line
                                    data={ArmsState}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Thighs Chart</span>
                                <Line
                                    data={ThighsState}
                                    options={options}
                                />
                            </div>
                            <div className="row chart_row">
                                <span className="chart_top_label_progres_diary">Hip Chart</span>
                                <Line
                                    data={HipsState}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={showAdd} onHide={handleClosesAdd} className="progressdiary_input_fields confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{Addmodallabel}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {activityshow === false &&
                            <div className="row">
                                <div className="col">
                                    <label className='commission_inner_popup_label'>{inputAddlabel}</label><br />
                                    <input type='text' defaultValue={DefaultAddValue} onChange={(e) => { SetDefaultAddValue(e.target.value) }} className="add_category_inner_input total_session" {...(ValueErr===true ? {id: 'err_border'} : {})} />
                                </div>
                            </div>
                        }

                        {activityshow === true &&
                            <div className="row">
                                <div className="col">
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultAddValue} onChange={(e) => { SetDefaultAddValue(e.target.value) }}  {...(ValueErr===true ? {id: 'err_border'} : {})} >

                                        <option value="">Select activity level</option>
                                        <option value="not_active">Not active</option>
                                        <option value="somewhat_active">Somewhat active</option>
                                        <option value="average_activity">Average activity</option>
                                        <option value="very_active">Very active</option>
                                        <option value="extremely_active">Extremely active</option>


                                    </select>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {ValueErr === true &&
                                <span className="err service_err_field">  {ValueErrMsg}</span>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesAdd}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="modal_action_button add_button_progress float_right" data-target={FinalAddtarget} onClick={(e) => { FinalAddButton(e) }}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
                    ExerciseDiaryShow===true && <ExerciseDiary CloseExerciseDiary={CloseExerciseDiary} OpenExerciseDiary={OpenExerciseDiary} startDate={startDate} GetExerciseDiaryData={GetExerciseDiaryData} ExerciseDiaryShow={ExerciseDiaryShow} GetProgressDiaryChart={GetProgressDiaryChart}/>
                }
            </section>
        </div>
    )
}

export default ClientProgressDiary
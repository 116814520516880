import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserFiles from "./UserFiles";
import Select from 'react-select';

const AdminUserDocs = () => {
  const side_nav = localStorage.getItem("side_nav");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [FolderListShow, SetFolderListShow] = useState(true);
  const [CoachDirectories, SetCoachDirectories] = useState([]);
  const [expertoptions, setExpertoptions] = useState([]);
  const [selectedOptions, setSelectedoptions] = useState([]);
  const [ExpertErr, SetExpertErr] = useState(false);
  const [DropDownLabel,SetDropDownLabel]=useState('Select Coach')
  const DropdownChange = (e) => {
    setSelectedoptions(e);

    var UserId = e.map(s=>s.value);
    let ids = UserId.toString();
    GetDirectories(TabMenu,ids)
    
  };

  const BackToDocuments = () => {
    navigate("/admin/documents", { replace: true });
  };

  const GetDirectories = async (role,ids='') => {
    SetCoachDirectories([])
    var url=''
    if(role==='coach')
    {
        url='coach-directories'
    }
    else if (role==='client')
    {
        url='client-directories'
    }
    const response = await axiosJWT.get(
      "/document-directory/directory/"+url+"?id="+ids+"",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    if (response.data.list !== null) {
      SetCoachDirectories(response.data.list);
    }
  };

  const GetDropDownOptions=async(role)=>{

    setExpertoptions([])
    setSelectedoptions([])
    
    var ExpertObj = [];

    var PassUrl='';
    if(role==='coach')
    {
      PassUrl='allnutrition'
    }
    else if(role==='client')
    {
      PassUrl='allclient'
    }

    const GetUsers = await axiosJWT.get("/"+PassUrl+"", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (GetUsers.status === 200) {
      if (GetUsers.data.Users !== null) {
        GetUsers.data.Users.forEach((data, index) => {
          var obj = {};
          obj["value"] = data.id;
          obj["label"] = data.email;
          ExpertObj.push(obj);
        });
      }
    }
    setExpertoptions(ExpertObj);
    

  }

  const [FolderId,SetFolderId]=useState('')
  const ShowFiles = (e) => {
    SetFolderId(e.target.getAttribute('data-id'))
    SetFolderListShow(false)
  };
  const [TabMenu,SetTabMenu]=useState('coach')
  const ChangeTab=(e)=>{

    const list_for = e.target.getAttribute("data-item");

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));

    e.target.setAttribute("class", "active");
    SetTabMenu(list_for)
    if(list_for==='coach')
    {
      SetDropDownLabel('Select Coach')
        GetDirectories('coach')
        GetDropDownOptions('coach')
    }
    else if(list_for==='client')
    {
      SetDropDownLabel('Select Client')
        GetDirectories('client')
        GetDropDownOptions('client')
    }
    
  }

  useEffect(() => {
    GetDirectories('coach');
    GetDropDownOptions('coach')
  }, []);

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      <section className="dashboard">
        <div className="adminmanageusers container admin_user_directory">
          <div className="menubox">
            <div className="tabmenu">
              <a className="active" data-item="coach" onClick={ChangeTab}>
                Coach Documents
              </a>
              <a className="" data-item="client" onClick={ChangeTab}>
                Client Documents
              </a>
              <button onClick={BackToDocuments}>Back</button>
            </div>
          </div>
          <div className="select_members" style={{width:'91%',marginBottom:'15px'}}>
              <label className="label">{DropDownLabel}</label>
              <Select
                options={expertoptions}
                isMulti
                onChange={(e) => {
                  DropdownChange(e);
                }}
               value={selectedOptions}
          
                {...(ExpertErr === true ? { id: "err_border" } : {})}
              />
            </div>

          {FolderListShow === true && (
            <div className="folder_lists">
              {CoachDirectories.length > 0 &&
                CoachDirectories.map((list, index) => (
                  <button
                    type="button"
                    className="collapsible"
                    data-id={list.id}
                    onClick={ShowFiles}
                  >
                    {list.directory_name}
                   
                    <i
                      className="fa fa-folder-open directory_icon pointer_none"
                      aria-hidden="true"
                    ></i>
                  </button>
                ))}
            </div>
          )}
          {
            FolderListShow===false && <UserFiles SetFolderListShow={SetFolderListShow} FolderId={FolderId}/>
            
          }
        </div>
      </section>
    </div>
  );
};
export default AdminUserDocs;

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CheckConnectedAccount from "../ConnectedAccountCheck";
const CoachService = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const Numreg = new RegExp('^[0-9]+$');
    const axiosJWT = axios.create();
    const [ServicesShow, SetServicesShow] = useState(true);
    const [ServicesCategory, SetServiceCategoryShow] = useState(false);
    const [TopButtonLabel, SetTopButtonLable] = useState('Add Service')
    const [TopButtonAction, SetTopButtonAction] = useState('service')
    const [SaveCategoryButtonLabel, setSaveCategoryButtonLabel] = useState('Add Category')
    const [SaveCategoryUpdateId, setSaveCategoryUpdateId] = useState('')
    const [DefaultCatName, SetDefaultCatName] = useState('')
    const [DefaultCatStatus, SetDefaultCatStatus] = useState('1')
    const [service_cat_err, Setservice_cat_err] = useState(false)
    const [ServiceCatList, SetServiceCatList] = useState([])
    const [ServiceCatPageNo, SetServiceCatPageNo] = useState(1)
    const [TotalRecordServiceCategory, SetTotalRecordServiceCategory] = useState(0)
    const [PerPage, SetPerPage] = useState(10)
    const [show, setShow] = useState(false);
    const [showservice, setShowservice] = useState(false)
    const handleClose = () => setShow(false);
    const handleCloseS = () => setShowservice(false);
    const [Servicetoplabel, SetServicetoplabel] = useState('Add Service')
    const [SaveserviceUpdateId, SetSaveserviceUpdateId] = useState('')
    const [ActiveCategoryList, SetActivecategoryList] = useState([])
    const [DefaultServiceName, SetDefaultServiceName] = useState('')
    const [DefaultServicecategory, SetDefaultServicecategory] = useState('')
    const [DefaultServiceexpiry, SetDefaultServiceexpiry] = useState('')
    const [DefaultHourAllowed, SetDefaultHourAllowed] = useState('')
    const [DefaultServicehourlyrate, SetDefaultServicehourlyrate] = useState('')
    const [DefaultServicestatus, SetDefaultServicestatus] = useState('')
    const [service_err, SetServiceerr] = useState(false)
    const [ServicePageNo, SetServicePageNo] = useState(0)
    const [TotalRecordService, SetTotalRecordservice] = useState(0)
    const [ServiceList, SetServiceList] = useState([])
    const [IsAccountConnected,SetIsAccountConnected]=useState(true)
    const [showsaccountalert,Setshowsaccountalert]=useState(false)
    const handleClosesaccountalert=()=>{
        Setshowsaccountalert(false)
    }

    const [ServiceNameErr, SetServiceNameErr] = useState(false)
    const [ServiceNameErrMsg, SetServiceNameErrMsg] = useState('')

    const [ServiceCatErr, SetServiceCatErr] = useState(false)
    const [ServiceCatErrMsg, SetServiceCatErrMsg] = useState('')

    const [ServiceExpiryErr, SetServiceExpiryErr] = useState(false)
    const [ServiceExpiryErrMsg, SetServiceExpiryErrMsg] = useState('')

    const [ServiceHoursErr, SetServiceHoursErr] = useState(false)
    const [ServiceHoursErrMsg, SetServiceHoursErrMsg] = useState('')

    const [ServiceCostErr, SetServiceCostErr] = useState(false)
    const [ServiceCostErrMsg, SetServiceCostErrMsg] = useState('')

    const [ServiceEnableErr, SetServiceEnableErr] = useState(false)
    const [ServiceEnableErrMsg, SetServiceEnableErrMsg] = useState('')


    const [ServiceCatNameErr, SetServiceCatNameErr] = useState(false)
    const [ServiceCatNameErrMsg, SetServiceCatNameErrMsg] = useState('')

    const [ServiceCatStatusErr, SetServiceCatStatusErr] = useState(false);
    const [ServiceCatStatusErrMsg, SetServiceCatStatusErrMsg] = useState('')


    var navigate = useNavigate();

    const ChangeTab = (e) => {
        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab');


        if (tab === 'services') {
            SetServicesShow(true);
            SetServiceCategoryShow(false);
            SetTopButtonLable('Add Service');
            SetTopButtonAction('service');
            GetServicesList(1);
        }
        else if (tab === 'categories') {
            SetServicesShow(false);
            SetServiceCategoryShow(true);
            SetTopButtonLable('Add Category');
            SetTopButtonAction('category');
            GetCategoryList(1);
        }
    }

    const trigger_popup_add = (e) => {

      

            SetServiceerr(false);
            SetServiceNameErr(false);
            SetServiceCatErr(false);
            SetServiceExpiryErr(false);
            SetServiceHoursErr(false);
            SetServiceCostErr(false);
            SetServiceEnableErr(false);
            SetServiceCatNameErr(false);
            SetServiceCatStatusErr(false);
    
            const action = e.target.getAttribute('data-action')
            if (action === 'service') {
                setShowservice(true);
                GetActiveCategoryList();
                SetDefaultServiceName('');
                SetDefaultServicecategory('');
                SetDefaultServiceexpiry('');
                SetDefaultHourAllowed('');
                SetDefaultServicehourlyrate('');
                SetDefaultServicestatus('1');
                SetServicetoplabel('Add Service');
                SetSaveserviceUpdateId('');
                SetServiceerr(false);
            }
            else if (action === 'category') {
                setSaveCategoryUpdateId('');
                setSaveCategoryButtonLabel('Add category');
                SetDefaultCatName('');
                SetDefaultCatStatus('1');
                Setservice_cat_err(false);
                setShow(true);
            }

        
       
    }

    const AddCategory = async () => {
        Setservice_cat_err(false);
        SetServiceCatNameErr(false);
        SetServiceCatStatusErr(false);

        if (SaveCategoryUpdateId === '') {

            if (DefaultCatName === '' || DefaultCatStatus === '') {

                var err = false;
                if (DefaultCatName === '') {
                    SetServiceCatNameErr(true);
                    SetServiceCatNameErrMsg('Please enter category name');
                    err = true;
                }
                if (DefaultCatStatus === '') {
                    SetServiceCatStatusErr(true);
                    SetServiceCatStatusErrMsg('Please select category status');
                    err = true;
                }

                if (err === true) {
                    return false;
                }
                // Setservice_cat_err(true)
            }
            else {
                Setservice_cat_err(false)
                const response = await axiosJWT.post('/nutrition-service/add-new-service-categories',
                    {
                        category_name: DefaultCatName,
                        category_status: DefaultCatStatus,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {                  
                    GetCategoryList(1);
                    setShow(false);
                }
            }
        }
        else {
            if (DefaultCatName === '' || DefaultCatStatus === '') {
                var err = false;
                if (DefaultCatName === '') {
                    SetServiceCatNameErr(true);
                    SetServiceCatNameErrMsg('Please enter category name');
                    err = true;
                }
                if (DefaultCatStatus === '') {
                    SetServiceCatStatusErr(true);
                    SetServiceCatStatusErrMsg('Please select category status');
                    err = true;
                }

                if (err === true) {
                    return false;
                }
                // Setservice_cat_err(true)
            }
            else {
                Setservice_cat_err(false);

                const response = await axiosJWT.post('/nutrition-service/edit-service-categories',
                    {
                        category_name: DefaultCatName,
                        category_status: DefaultCatStatus,
                        id: SaveCategoryUpdateId
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    GetCategoryList(ServiceCatPageNo);
                    setShow(false);
                }
            }
        }
    }

    const GetCategoryList = async (pagenumber) => {
        const response = await axiosJWT.get('/nutrition-service/list-service-categories?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetTotalRecordServiceCategory(response.data.service_categories_list_count);
            SetServiceCatList(response.data.final_list);
            SetServiceCatPageNo(pagenumber);
        });
    }

    const changePageNumberSC = async (pagenumber) => {
        GetCategoryList(pagenumber);
        SetServiceCatPageNo(pagenumber);
    }

    // const GetProfileDetails=async()=>{

    //     const response = await axiosJWT.get('/profile-details', {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
    //     if(response.data!=null)
    //     {
    //         // console.log(response.data)
    //         if(response.data.profile_details!=null && response.data.profile_details[0].nutritionist_infos!=undefined)
    //         {            
    //             if(response.data.profile_details[0].nutritionist_infos[0].stripe_account_id!==undefined)
    //             {
    //                 // console.log('Nutri info')
    //                 if(response.data.profile_details[0].nutritionist_infos[0].stripe_account_id===null || response.data.profile_details[0].nutritionist_infos[0].stripe_account_id==='')
    //                 {
    //                     SetIsAccountConnected(false)
    //                 }else{
    //                     SetIsAccountConnected(true)
    //                 }        
    //             }            
    //         }        
    //     }    
    // }

    const NavigateStripeAccount=async()=>{

        ShowLoader()

        const response = await axiosJWT.post('/payments/connect-stripe-account',
        {

        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

    if (response) {
        HideLoader()
        window.location.href = response.data.account_link
    }
       
    }

    useEffect(() => {
        document.title = 'Coach-Services';
        // GetProfileDetails()
        // GetCategoryList(1)
    }, [])

    const [loader, Setloader] = useState(false)
    const HideLoader = () => {
        Setloader(false)
    }
    const ShowLoader = () => {
        Setloader(true)
    }

    const editServicecategory = async (e) => {
        SetServiceCatNameErr(false);
        SetServiceCatStatusErr(false);

        var EditID = e.target.getAttribute('data-id')

        const response = await axiosJWT.get('/nutrition-service/service-categories-details?id=' + EditID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            // SetDefaultCatName(response.data)

            SetDefaultCatName(response.data[0].category_name)
            if (response.data[0].category_status === true) {
                SetDefaultCatStatus('1');
            }
            else if (response.data[0].category_status === false) {
                SetDefaultCatStatus('0');
            }
            setSaveCategoryUpdateId(EditID);
            setSaveCategoryButtonLabel('Update category');
            setShow(true);
        });
    }

    const [DeleteServiceCatId, SetDeleteServiceCatId] = useState('')
    const [showsdeletecat, Setshowsdeletecat] = useState(false)
    const [CatDeleteErrShow, SetCatDeleteErrShow] = useState(false)
    const [CatDeleteErrMsg, SetCatDeleteErrMsg] = useState('')
    const [SerDeleteErrShow, SetSerDeleteErrShow] = useState(false)
    const [SerDeleteErrMsg, SetSerDeleteErrMsg] = useState('')
    const handleClosesdeletecat = () => {
        Setshowsdeletecat(false);
        SetCatDeleteErrShow(false);
    }

    const deleteservicecategoryTrigger = (e) => {
        var DeleteID = e.target.getAttribute('data-id');
        SetDeleteServiceCatId(DeleteID);
        Setshowsdeletecat(true);
    }

    const deleteservicecategory = async (e) => {
        try {
            const response = await axiosJWT.delete('/nutrition-service/delete-service-categories/' + DeleteServiceCatId + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                GetCategoryList(ServiceCatPageNo);
                handleClosesdeletecat();
            });

        } catch (err) {
            SetCatDeleteErrMsg(err.response.data.msg);
            SetCatDeleteErrShow(true);
        }
    }

    const GetActiveCategoryList = async () => {
        const response = await axiosJWT.get('/nutrition-service/list-service-categories?status=active', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetActivecategoryList(response.data);
        })
    }

    const AddService = async () => {

        SetServiceerr(false);
        SetServiceNameErr(false);
        SetServiceCatErr(false);
        SetServiceExpiryErr(false);
        SetServiceHoursErr(false);
        SetServiceCostErr(false);
        SetServiceEnableErr(false);

        if (SaveserviceUpdateId === '') {
            if (DefaultServiceName === '' || DefaultServicecategory === '' || DefaultServiceexpiry === '' || DefaultServicehourlyrate === '' || DefaultServicestatus === '' || DefaultHourAllowed === '') {

                var err = false;

                if (DefaultServiceName === '') {
                    SetServiceNameErr(true);
                    SetServiceNameErrMsg('Please enter service name');
                    err = true;
                }

                if (DefaultServicecategory === '') {
                    SetServiceCatErr(true);
                    SetServiceCatErrMsg('Please select service category');
                    err = true;
                }
                if (DefaultServiceexpiry === '') {
                    SetServiceExpiryErr(true);
                    SetServiceExpiryErrMsg('Please enter service expiry');
                    err = true;
                }
                if (DefaultHourAllowed === '') {
                    SetServiceHoursErr(true);
                    SetServiceHoursErrMsg('Please enter hourly allowed');
                    err = true;
                }
                if (DefaultServicehourlyrate === '') {
                    SetServiceCostErr(true);
                    SetServiceCostErrMsg('Please enter service cost');
                    err = true;
                }
                if (DefaultServicestatus === '') {
                    SetServiceEnableErr(true);
                    SetServiceEnableErrMsg('Please select service status');
                    err = true;
                }
                if(DefaultServiceexpiry!=='')
                {
                    if (!Numreg.test(DefaultServiceexpiry)) {
                        SetServiceExpiryErr(true);
                        SetServiceExpiryErrMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(DefaultHourAllowed!=='')
                {
                    if (!Numreg.test(DefaultHourAllowed)) {
                        SetServiceHoursErr(true);
                        SetServiceHoursErrMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(DefaultServicehourlyrate!=='')
                {
                    if (!Numreg.test(DefaultServicehourlyrate)) {
                        SetServiceCostErr(true);
                        SetServiceCostErrMsg('Please enter round off value');
                        err = true;
                     }
                }

                if (err === true) {
                    return false;
                }
                // SetServiceerr(true)
            }
            else {
                SetServiceerr(false);
                const response = await axiosJWT.post('/nutrition-service/add-new-service',
                    {
                        service_name: DefaultServiceName,
                        service_category: DefaultServicecategory,
                        service_expiry: DefaultServiceexpiry,
                        service_price: DefaultServicehourlyrate,
                        service_status: DefaultServicestatus,
                        service_hour_allowed: DefaultHourAllowed

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                    GetServicesList(1);
                    setShowservice(false);
                }
            }
        }
        else {
            if (DefaultServiceName === '' || DefaultServicecategory === '' || DefaultServiceexpiry === '' || DefaultServicehourlyrate === '' || DefaultServicestatus === '' || DefaultHourAllowed === '') {

                var err = false;

                if (DefaultServiceName === '') {
                    SetServiceNameErr(true)
                    SetServiceNameErrMsg('Please enter service name')
                    err = true;
                }

                if (DefaultServicecategory === '') {
                    SetServiceCatErr(true)
                    SetServiceCatErrMsg('Please select service category')
                    err = true;
                }
                if (DefaultServiceexpiry === '') {
                    SetServiceExpiryErr(true)
                    SetServiceExpiryErrMsg('Please enter service expiry')
                    err = true;
                }
                if (DefaultHourAllowed === '') {
                    SetServiceHoursErr(true)
                    SetServiceHoursErrMsg('Please enter hourly allowed')
                    err = true;
                }
                if (DefaultServicehourlyrate === '') {
                    SetServiceCostErr(true)
                    SetServiceCostErrMsg('Please enter service cost')
                    err = true
                }
                if (DefaultServicestatus === '') {
                    SetServiceEnableErr(true)
                    SetServiceEnableErrMsg('Please select service status')
                    err = true
                }

                if (err === true) {
                    return false;
                }
                // SetServiceerr(true)
            }
            else {
                var err=false;
                if(DefaultServiceexpiry!=='')
                {
                    if (!Numreg.test(DefaultServiceexpiry)) {
                        SetServiceExpiryErr(true);
                        SetServiceExpiryErrMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(DefaultHourAllowed!=='')
                {
                    if (!Numreg.test(DefaultHourAllowed)) {
                        SetServiceHoursErr(true);
                        SetServiceHoursErrMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(DefaultServicehourlyrate!=='')
                {
                    if (!Numreg.test(DefaultServicehourlyrate)) {
                        SetServiceCostErr(true);
                        SetServiceCostErrMsg('Please enter round off value');
                        err = true;
                     }
                }
                if(err===false)
                {
                    SetServiceerr(false);

                    const response = await axiosJWT.post('/nutrition-service/edit-service',
                        {
                            service_name: DefaultServiceName,
                            service_category: DefaultServicecategory,
                            service_expiry: DefaultServiceexpiry,
                            service_price: DefaultServicehourlyrate,
                            service_status: DefaultServicestatus,
                            service_hour_allowed: DefaultHourAllowed,
                            id: SaveserviceUpdateId
    
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {    
                        GetServicesList(ServicePageNo);
                        setShowservice(false);
                    }
                }
            }
        }
    }

    const GetServicesList = async (pagenumber) => {
        const response = await axiosJWT.get('/nutrition-service/list-services?offset=' + pagenumber + '&limit=' + PerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetServicePageNo(pagenumber);
            SetTotalRecordservice(response.data.total_count);
            SetServiceList(response.data.NutritionistService);
        });
    }

    useEffect(() => {
        GetServicesList(1)
        GetCommissionDetails()
    }, []);

    const changePageNumberS = async (pagenumber) => {
        GetServicesList(pagenumber);
        SetServicePageNo(pagenumber);
    }

    const editService = async (e) => {
        SetServiceerr(false);
        SetServiceNameErr(false);
        SetServiceCatErr(false);
        SetServiceExpiryErr(false);
        SetServiceHoursErr(false);
        SetServiceCostErr(false);
        SetServiceEnableErr(false);

        var EditId = e.target.getAttribute('data-id');
        SetServicetoplabel('Update Service');
        SetSaveserviceUpdateId(EditId);

        GetActiveCategoryList();

        const response = await axiosJWT.get('/nutrition-service/service-details?id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            SetDefaultServiceName(response.data[0].service_name);
            SetDefaultServicecategory(response.data[0].service_category_id);
            SetDefaultServiceexpiry(response.data[0].service_expiry);
            SetDefaultHourAllowed(response.data[0].service_hour_allowed);
            SetDefaultServicehourlyrate(response.data[0].service_price);
            SetDefaultServicestatus(response.data[0].service_status);
            setShowservice(true);
        });
    }


    const [DeleteServiceId, SetDeleteServiceId] = useState('');

    const [showsdeleteservice, Setshowsdeleteservice] = useState(false);

    const handleClosesdeleteservice = () => {
        Setshowsdeleteservice(false)
        SetSerDeleteErrShow(false)
    }

    const deleteservicetrigger = (e) => {
        var DeleteId = e.target.getAttribute('data-id')
        SetDeleteServiceId(DeleteId)
        Setshowsdeleteservice(true)

    }

    const deleteservice = async (e) => {
        try {

            const response = await axiosJWT.delete('/nutrition-service/delete-service/' + DeleteServiceId + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                handleClosesdeleteservice()
                GetServicesList(ServicePageNo)
            })

        } catch (err) {

            SetSerDeleteErrMsg(err.response.data.msg)
            SetSerDeleteErrShow(true)

        }

    }

    const [CommissionMsg,SetCommissionMsg]=useState('')

    const GetCommissionDetails=async()=>{

        try{
            const response = await axiosJWT.get('/nutrition-service/commission-details', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if(response.status===200)
            {
                if(response.data.type==='fixed')
                {
                    SetCommissionMsg('Admin has set the commission value of '+response.data.value+'$ per service plan purchase minimum amount of '+response.data.min_payment_amount+'$ by client') 
                }
                else if(response.data.type==='percentage')
                {
                    SetCommissionMsg('Admin has set the commission value of '+response.data.value+'% per service plan purchase minimum amount of '+response.data.min_payment_amount+'$ by client') 
                }
            }
        }catch(err){
            SetCommissionMsg(err.response.data.msg) 
        }

        
    }

    const navigateCategory = () => {
        setShowservice(false);
        SetServicesShow(false);
        SetServiceCategoryShow(true);
        SetTopButtonLable('Add Category');
        SetTopButtonAction('category');
        GetCategoryList(1);
        navigate("/coach/services");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        document.getElementById("category").classList.add("active");
    }

    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
              {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                <div className="coachservices container">
                    <CheckConnectedAccount />
                    <p style={{fontWeight:600,fontWeight:'600',paddingLeft:'35px'}}>{CommissionMsg}</p>
                    <div className="row top_coach_label service_over_div coach_plans">
           
                        <div className='menubox'>
                            <div className='tabmenu'>
                                <a className="active" id="service" data-tab={'services'} onClick={(e) => { ChangeTab(e) }}>All services</a>
                                <a className="" id="category" data-tab={'categories'} onClick={(e) => { ChangeTab(e) }}>Categories</a>
                            </div>
                            <button className='last_commision_tab_button_add_plan service_top_row_button' data-action={TopButtonAction}
                                onClick={(e) => { trigger_popup_add(e) }}  >{TopButtonLabel}</button>
                        </div>
                      
                    </div>
               

                    {ServicesShow === true && <div className="row coach_service_div ">

                        {ServiceList.length > 0 &&
                            <div>
                                <Table className="table is-striped is-fullwidth">
                                    <Thead className="thead">
                                        <Tr>
                                            <Th>Service</Th>
                                            <Th>Category</Th>
                                            <Th>Expiry{' (In months)'}</Th>
                                            <Th>Hours Allowed</Th>
                                            <Th>Package Cost</Th>
                                            <Th>Status</Th>
                                            <Th>Action</Th>

                                        </Tr>
                                    </Thead>
                                    <Tbody className="tbody">

                                        {ServiceList.length > 0 && ServiceList.map((services, index) => (

                                            <Tr className='trow' key={services.id}>
                                                <Td>{services.service_name}</Td>
                                                <Td >{services.service_category !== null && <span className="desing_purple">{services.service_category.category_name}</span>}</Td>
                                                <Td >{services.service_expiry}</Td>
                                                <Td >{services.service_hour_allowed}</Td>
                                                <Td >{'$ '}{services.service_price}</Td>
                                                <Td >{services.service_status === '1' ? <span className="desing_back">Active</span> : <span className="desing_blue">Non-active</span>}</Td>
                                                <Td ><i className="fa fa-edit edit_icon pointer_eff" onClick={(e) => { editService(e) }} data-id={services.id}></i><i className="fa fa-trash pointer_eff" onClick={(e) => { deleteservicetrigger(e) }} data-id={services.id}></i></Td>
                                            </Tr>
                                        ))}

                                    </Tbody>
                                </Table>
                            </div>
                        }

                        <div>
                            {TotalRecordService ?
                                <Pagination
                                    activePage={ServicePageNo}
                                    itemsCountPerPage={PerPage}
                                    totalItemsCount={TotalRecordService}
                                    onChange={changePageNumberS.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                                : null}
                        </div>
                    </div>

                    }
                    {ServicesCategory === true && <div className="row coach_service_div ">

                        {ServiceCatList.length > 0 &&
                            <Table className="table is-striped is-fullwidth">
                                <Thead className="thead">
                                    <Tr>
                                        <Th>Category Name</Th>
                                        <Th>Services</Th>
                                        <Th>Status</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody className="tbody">
                                    {ServiceCatList.length > 0 && ServiceCatList.map((servicecat, index) => (
                                        <Tr className='trow' key={index}>
                                            <Td>{servicecat.category_name !== undefined && <span className="desing_purple">{servicecat.category_name}</span>}</Td>
                                            <Td >{servicecat.service_count}</Td>
                                            <Td >{servicecat.category_status === true ? <span className="desing_back">Active</span> : <span className="desing_blue">Non-active</span>}</Td>
                                            <Td ><i className="fa fa-edit edit_icon pointer_eff" onClick={(e) => { editServicecategory(e) }} data-id={servicecat.category_id}></i><i className="fa fa-trash pointer_eff" onClick={(e) => { deleteservicecategoryTrigger(e) }} data-id={servicecat.category_id}></i></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        }
                        <div>
                            {TotalRecordServiceCategory ?
                                <Pagination
                                    activePage={ServiceCatPageNo}
                                    itemsCountPerPage={PerPage}
                                    totalItemsCount={TotalRecordServiceCategory}
                                    onChange={changePageNumberSC.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers grayfont"
                                />
                                : null}
                        </div>
                    </div>}

                    <Modal show={show} onHide={handleClose} className='commission_popup add_coach_service_category_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{SaveCategoryButtonLabel}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Category Name</label><br />
                                    <input type='text' className="add_category_inner_input" defaultValue={DefaultCatName} onChange={(e) => { SetDefaultCatName(e.target.value) }}  {...(ServiceCatNameErr===true ? {id: 'err_border'} : {})}  />
                                    {ServiceCatNameErr === true &&
                                        <span className="err service_err_field">  {ServiceCatNameErrMsg}</span>
                                    }
                                </div>
                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Category Status</label><br />
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultCatStatus} onChange={(e) => { SetDefaultCatStatus(e.target.value) }} {...(ServiceCatStatusErr===true ? {id: 'err_border'} : {})} >
                                        <option value="1">Enable</option>
                                        <option value="0">Disable</option>

                                    </select>
                                    {ServiceCatStatusErr === true &&
                                        <span className="err service_err_field">  {ServiceCatStatusErrMsg}</span>
                                    }
                                </div>

                            </div>
                            {service_cat_err === true &&
                                <div className="row service_cat_err">
                                    Please fill out all fields
                                </div>
                            }


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                                Back
                            </Button>
                            <Button variant="primary" className='save_button_commission float_right' onClick={AddCategory} data-update={SaveCategoryUpdateId} >
                                {SaveCategoryButtonLabel}
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showservice} onHide={handleCloseS} className='commission_popup coach add_service_pop'>
                        <Modal.Header closeButton>
                            <Modal.Title>{Servicetoplabel}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Service Name</label><br />
                                    <input type='text' className="add_category_inner_input" defaultValue={DefaultServiceName} onChange={(e) => { SetDefaultServiceName(e.target.value) }} {...(ServiceNameErr===true ? {id: 'err_border'} : {})}  />

                                    {ServiceNameErr === true &&
                                        <span className="err service_err_field">  {ServiceNameErrMsg}</span>
                                    }

                                </div>
                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Category</label><br />
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultServicecategory} onChange={(e) => { SetDefaultServicecategory(e.target.value) }} {...(ServiceCatErr===true ? {id: 'err_border'} : {})} >
                                        <option value="">Select Category</option>
                                        {ActiveCategoryList.length > 0 && ActiveCategoryList.map((servicecat, index) => (
                                            <option value={servicecat.id} key={index}>{servicecat.category_name}</option>
                                        ))}
                                    </select>
                                    {ServiceCatErr === true &&
                                        <span className="err service_err_field">  {ServiceCatErrMsg}</span>
                                    }
                                    {/* <Link to="/coach/services" style={{ float: "right" }}>Create category</Link> */}
                                    <a onClick={navigateCategory} className="navigation_category">Create category</a> 
                                </div>

                            </div>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Expiry{' (In months)'}</label><br />
                                    <input type='number' className="add_category_inner_input" defaultValue={DefaultServiceexpiry} onChange={(e) => { SetDefaultServiceexpiry(e.target.value) }} {...(ServiceExpiryErr===true ? {id: 'err_border'} : {})} />
                                    {ServiceExpiryErr === true &&
                                        <span className="err service_err_field">  {ServiceExpiryErrMsg}</span>
                                    }
                                </div>

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Hours Allowed</label><br />
                                    <input type='number' className="add_category_inner_input" defaultValue={DefaultHourAllowed} onChange={(e) => { SetDefaultHourAllowed(e.target.value) }} {...(ServiceHoursErr===true ? {id: 'err_border'} : {})}  />
                                    {ServiceHoursErr === true &&
                                        <span className="err service_err_field">  {ServiceHoursErrMsg}</span>
                                    }
                                </div>
                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Package Cost{'($)'}</label><br />
                                    <input type='number' className="add_category_inner_input" defaultValue={DefaultServicehourlyrate} onChange={(e) => { SetDefaultServicehourlyrate(e.target.value) }} {...(ServiceCostErr===true ? {id: 'err_border'} : {})} />
                                    {ServiceCostErr === true &&
                                        <span className="err service_err_field">  {ServiceCostErrMsg}</span>
                                    }
                                </div>

                            </div>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Status</label><br />
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultServicestatus} onChange={(e) => { SetDefaultServicestatus(e.target.value) }} {...(ServiceEnableErr===true ? {id: 'err_border'} : {})} >
                                        <option value="1">Enable</option>
                                        <option value="0">Disable</option>

                                    </select>
                                    {ServiceEnableErr === true &&
                                        <span className="err service_err_field">  {ServiceEnableErrMsg}</span>
                                    }
                                </div>


                            </div>
                            {service_err === true &&
                                <div className="row service_cat_err">
                                    Please fill out all fields
                                </div>
                            }


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" className='close_button_commission' onClick={handleCloseS} >
                                Back
                            </Button>
                            <Button variant="primary" className='save_button_commission float_right' onClick={AddService} data-update={SaveserviceUpdateId} >
                                {Servicetoplabel}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showsdeletecat} onHide={handleClosesdeletecat} className="coachservice_category_delete confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            Are you sure want to delete this category?<br /><br />

                            {CatDeleteErrShow === true &&
                                <span style={{ color: 'red' }}>{CatDeleteErrMsg}</span>
                            }


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_close_button" variant="secondary" onClick={handleClosesdeletecat}>
                                Cancel
                            </Button>
                            <Button className="modal_action_button float_right" variant="primary" onClick={(e) => { deleteservicecategory(e) }}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showsdeleteservice} onHide={handleClosesdeleteservice} className="coachservice_delete confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Service</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            Are you sure want to delete this service?<br />
                            <br />
                            {SerDeleteErrShow === true &&
                                <span style={{ color: 'red' }}>{SerDeleteErrMsg}</span>
                            }

                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_close_button" variant="secondary" onClick={handleClosesdeleteservice}>
                                Cancel
                            </Button>
                            <Button className="modal_action_button float_right" variant="primary" onClick={(e) => { deleteservice(e) }}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showsaccountalert} onHide={handleClosesaccountalert} className="coachservice_delete confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Connect Stripe</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            
                        Oops!.  Seems like you have not configured your account with stripe.  Please connect now to add your services.

                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_close_button" variant="secondary" onClick={handleClosesaccountalert}>
                                Cancel
                            </Button>
                            <Button className="modal_action_button float_right" variant="primary" onClick={(e) => { NavigateStripeAccount(e) }}>
                                Connect Now
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>            
        </div>

    )

}

export default CoachService
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables, ChartOptions } from 'chart.js';
Chart.register(...registerables);


const AdminChart = ({ Chartlabels, ChartDatas, datasetpass }) => {
   const state = {
      labels: Chartlabels,
      datasets: [
         {
            label: datasetpass === undefined ? 'Earnings ' : datasetpass,
            lineTension: 0.5,
            backgroundColor: '#226920',
            borderColor: '#226920',
            borderWidth: 4,
            data: ChartDatas,
            // pointRadius: 0,
         }
      ]
   }
   const options = {
      scales: {
         x: {
            grid: {
               display: false,
               drawBorder: false,
            },
         },
         y: {
            grid: {
               display: false,
               drawBorder: false,
            },
         }
      },
      plugins: {
         legend: {
            display: false
         }
      }
   }

   return (
      <div>
         <Line
            data={state}
            options={options}
         />
      </div>
   )


}

export default AdminChart

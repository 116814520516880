import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import mainLogo from '../../images/fitpal_logo.png';
import axios from 'axios';
import moment from 'moment';

const ClientHamMenu = (props) => {
 


  const ShowMobileMenu = () => {

    document.getElementsByClassName("mobile_menu")[0].style.opacity = "1";
    document.getElementsByClassName("mobile_menu")[0].style.display = "inherit";
    document.getElementsByClassName("ham-menu")[0].style.display = "none";
  }
  const CloseMenu = () => {

    document.getElementsByClassName("mobile_menu")[0].style.display = "none";
    document.getElementsByClassName("ham-menu")[0].style.display = "flex";
  }


  const token = localStorage.getItem("token");
  const [Subscribed, SetSubscribed] = useState(false)
  const subscriptionEdate = localStorage.getItem("subscriptionEdate");

  var utcMoment = moment.utc();
  utcMoment = moment(utcMoment).format("YYYY-MM-DD HH:mm:ss");

  const axiosJWT = axios.create();

  const SyncWithings=()=>{
       

    // const Notresponse =axiosJWT.get('/withings/get-data?type=weight', {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // });

}

  useEffect(() => {

      if (subscriptionEdate !== 'null') {

          if (Date.parse(subscriptionEdate) < Date.parse(utcMoment)) {
              SetSubscribed(true)
          }
      }
      else {
          SetSubscribed(true)
      }

      SyncWithings()

  })

  const [messageNew, setmessageNew] = useState();

  useEffect(() => {
    setmessageNew(props.data);
  }, [props.data]);


  const disable_msgnew = () => {
    setmessageNew(false);
  }

  return (
    <div className="container-mobile-menu">
      <ul className="menu-items mobile_menu">
        <div className="mobile_menu_logo">
          <img id='clickimg ' className="reponsive_logo" src={mainLogo} alt="mainLogo" />
        </div>
        <i className="fa fa-times close_mobile_menu" aria-hidden="true" onClick={CloseMenu}></i>
        <li ><NavLink to='/client/dashboard' activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
        <li ><NavLink to='/client/appointment' activeclassname="active"><i className="fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Appointment</span></NavLink></li>

        <li ><NavLink to='/client/report' activeclassname="active"><i className="fa fa-file-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Report</span></NavLink></li>

        {(Subscribed === false && props.glpuser===true) ?
         <li ><NavLink to='/client/beluga-health' activeclassname="active"><i className="fa fa-user-md sidenav_icon" aria-hidden="true"></i><span className="side-navli">Beluga Health</span></NavLink></li>:
         <li ><NavLink to='/client/beluga-health' activeclassname="active"><i className="fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Beluga Health</span></NavLink></li>}

  

        <li ><NavLink to='/client/agreements' activeclassname="active"><i className="fa fa-clipboard sidenav_icon" aria-hidden="true"></i><span className="side-navli">Documents</span></NavLink></li>

        <li ><NavLink to='/' activeclassname="active"><i className="fa fa-envelope sidenav_icon" aria-hidden="true"></i><span className="side-navli" onClick={disable_msgnew}>Messages <sup className="msgnew">{messageNew === true ? "(New)" : null}</sup></span></NavLink></li>
        <li ><NavLink to='/client/meal-plan' activeclassname="active"><i className="fa fa-calendar-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Coach Meal Plan</span></NavLink></li>
        <li ><NavLink to='/client/week-plan' activeclassname="active"><i className="fa fa-calendar-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">AI-Meal Plan</span></NavLink></li>
        <li ><NavLink to='/client/food-journal' activeclassname="active"><i className="fa fa-cutlery sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food Journal</span></NavLink></li>

        {Subscribed === false ?
        <li ><NavLink to='/client/food-diary' activeclassname="active"><i className="fa fa-newspaper-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food diary</span></NavLink></li>:
        <li ><NavLink to='/client/food-diary' activeclassname="active"><i className="fa fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food diary</span></NavLink></li>}

{Subscribed === false ?

        <li ><NavLink to='/client/progress-diary' activeclassname="active"><i className="fa fa-file-text-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Progress Diary</span></NavLink></li>:
        <li ><NavLink to='/client/progress-diary' activeclassname="active"><i className="fa fa fa-lock  sidenav_icon" aria-hidden="true"></i><span className="side-navli">Progress Diary</span></NavLink></li>
}


{Subscribed === false ?
        <li ><NavLink to='/client/blogs' activeclassname="active"><i className="fa fa-file-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Blogs</span></NavLink></li>:
        <li ><NavLink to='/client/blogs' activeclassname="active"><i className="fa fa fa-lock  sidenav_icon" aria-hidden="true"></i><span className="side-navli">Blogs</span></NavLink></li>

}

{Subscribed === false ?
        <li ><NavLink to='/client/fitness-videos' activeclassname="active"><i className="fa fa fa-play sidenav_icon" aria-hidden="true"></i><span className="side-navli">Fitness Videos</span></NavLink></li>:
        <li ><NavLink to='/client/fitness-videos' activeclassname="active"><i className="fa fa fa-lock  sidenav_icon" aria-hidden="true"></i><span className="side-navli">Fitness Videos</span></NavLink></li>

}

{/* <li ><NavLink to='/client/directories' activeclassname="active"><i className="fa fa-file sidenav_icon" aria-hidden="true"></i><span className="side-navli">Files</span></NavLink></li> */}

        <li ><NavLink to='/client/keto-book' activeclassname="active"><i className="fa fa-book sidenav_icon" aria-hidden="true"></i><span className="side-navli">Keto 101</span></NavLink></li>
        <li ><NavLink to='/client/subscriptions' activeclassname="active"><i className="fa fa-rocket sidenav_icon" aria-hidden="true"></i><span className="side-navli">subscriptions</span></NavLink></li>
      </ul>
      <div className="ham-menu" onClick={ShowMobileMenu}>
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
    </div>
  )
}

export default ClientHamMenu
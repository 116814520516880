import React, { useEffect, useState } from "react";

const TheseApply = (props) => {
  const [AnythingApplyT,SetAnythingApplyT]=useState([])
  const [AnythingApplyLengthT,SetAnythingApplyLengthT]=useState(0)

  const handleOptionChange = (e) => {
    
    var AnythingApplyInArrayT=AnythingApplyT;
    if(e.target.checked===true)
    {
      if(e.target.value==='None of the above')
      {
        AnythingApplyInArrayT=[];
        AnythingApplyInArrayT.push(e.target.value)
      }
      else{
        AnythingApplyInArrayT = AnythingApplyInArrayT.filter(item => item !== 'None of the above');
        AnythingApplyInArrayT.push(e.target.value)
      }
    
    }
    else if(e.target.checked===false)
    {
      AnythingApplyInArrayT = AnythingApplyInArrayT.filter(item => item !== e.target.value);
    }

    console.log('AnythingApplyInArray',AnythingApplyInArrayT)
    SetAnythingApplyLengthT(AnythingApplyInArrayT.length)

    if (!AnythingApplyInArrayT.includes('None of the above')  )
    {
      props.EnableNextButton()
      props.SetNextMenu('tellusmoremc')
  
    }

    if (AnythingApplyInArrayT.includes('None of the above') )
    {
      props.EnableNextButton()
      props.SetNextMenu('currentmedication')
    
    }



    
    SetAnythingApplyT(AnythingApplyInArrayT)
  };
  

  useEffect(() => {

    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    if (props.AnythingApplyPCT) {
      SetAnythingApplyT(props.AnythingApplyPCT);
    }
    if (props.AnythingApplyLengthPCT) {
      SetAnythingApplyLengthT(props.AnythingApplyLengthPCT);
    }



  }, []);

  useEffect(()=>{

 

    setTimeout(() => {
      var Err=0;
    
      if(AnythingApplyLengthT===0)
      {
       Err=1;
      }
      
      if (Err == 0) {
       props.EnableNextButton();
     } else {

       props.DisableNextButton();
     }

    }, 10);

    props.SetAnythingApplyPCT(AnythingApplyT)
    props.SetAnythingApplyLengthPCT(AnythingApplyLengthT)


  },[AnythingApplyLengthT,AnythingApplyT])


  useEffect(()=>{
    props.SetPreviousMenu('selfreportedmeds')
    
     
  },[])


  useEffect(()=>{

     props.DisableNextButton()
     setTimeout(() => {

 

      if (AnythingApplyT.length>0 && !AnythingApplyT.includes('None of the above')  )
      {
       
        props.EnableNextButton()
        props.SetNextMenu('tellusmoremc')
    
      }

      if (AnythingApplyT.length>0 &&  AnythingApplyT.includes('None of the above') )
      {
        props.EnableNextButton()
        props.SetNextMenu('currentmedication')
      
      }


      
      
     }, 100);

  },[AnythingApplyT])

  return (
    <div className="daily_activity">
      <div className="head_row"></div>
      <div>
          <label><h6>{props.AnythingApplyPCTLabel}</h6></label> <span style={{color:'red'}}>*</span>
        </div>
      <div className="input_row">
        
        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Gastroparesis"
            checked={AnythingApplyT.includes('Gastroparesis')}
            onChange={handleOptionChange}
          />
          <label>
          Gastroparesis
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Inflammatory Bowel Disease"
            checked={AnythingApplyT.includes('Inflammatory Bowel Disease')}
            onChange={handleOptionChange}
          />
          <label>Inflammatory Bowel Disease</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Other gastrointestinal diseases"
            checked={AnythingApplyT.includes('Other gastrointestinal diseases')}
            onChange={handleOptionChange}
          />
          <label>Other gastrointestinal diseases</label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Pancreatitis"
            checked={AnythingApplyT.includes('Pancreatitis')}
            onChange={handleOptionChange}
          />
          <label>
          Pancreatitis
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Pancreatic Cancer"
            checked={AnythingApplyT.includes('Pancreatic Cancer')}
            onChange={handleOptionChange}
          />
          <label>
          Pancreatic Cancer
          </label>
        </div>

    

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Hypertriglyceridemia"
            checked={AnythingApplyT.includes('Hypertriglyceridemia')}
            onChange={handleOptionChange}
          />
          <label>
          Hypertriglyceridemia
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Kidney injury or kidney disease"
            checked={AnythingApplyT.includes('Kidney injury or kidney disease')}
            onChange={handleOptionChange}
          />
          <label>
          Kidney injury or kidney disease
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Insulin-dependent Diabetes"
            checked={AnythingApplyT.includes('Insulin-dependent Diabetes')}
            onChange={handleOptionChange}
          />
          <label>
          Insulin-dependent Diabetes
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Thyroid cancer"
            checked={AnythingApplyT.includes('Thyroid cancer')}
            onChange={handleOptionChange}
          />
          <label>
          Thyroid cancer
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Family history of thyroid cancer"
            checked={AnythingApplyT.includes('Family history of thyroid cancer')}
            onChange={handleOptionChange}
          />
          <label>
          Family history of thyroid cancer
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Family history of MEN-2 syndrome"
            checked={AnythingApplyT.includes('Family history of MEN-2 syndrome')}
            onChange={handleOptionChange}
          />
          <label>
          Family history of MEN-2 syndrome
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="PCOS with fertility problems"
            checked={AnythingApplyT.includes('PCOS with fertility problems')}
            onChange={handleOptionChange}
          />
          <label>
          PCOS with fertility problems
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Eating Disorder(past or present)"
            onChange={handleOptionChange}
            checked={AnythingApplyT.includes('Eating Disorder(past or present)')}
          />
          <label>
          Eating Disorder(past or present)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Suicidal thoughts (past or present)"
            checked={AnythingApplyT.includes('Suicidal thoughts (past or present)')}
            onChange={handleOptionChange}
          />
          <label>
          Suicidal thoughts (past or present)
          </label>
        </div>

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="Allergy / Hypersensitivity to GLP-1 agonists"
            checked={AnythingApplyT.includes('Allergy / Hypersensitivity to GLP-1 agonists')}
            onChange={handleOptionChange}
          />
          <label>Allergy / Hypersensitivity to GLP-1 agonists</label>

        </div>

        <div><label>(Ozempic (Semaglutide), Wegovy (Semaglutide), Zepbound (Tirzepatide), Mounjaro (Tirzepatide), Saxenda (Liraglutide)
) </label></div>

      
 
  

        <div className="radio_input_yes_no">
          <input
            type="checkbox"
            name="these_apply"
            value="None of the above"
            checked={AnythingApplyT.includes('None of the above')}
            onChange={handleOptionChange}
          />
          <label>
          None of the above
          </label>
        </div>




      </div>
    </div>
  );
};
export default TheseApply

import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { parse } from "date-fns";
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";


const CoachBilling = () => {

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [SavebillingButtonLabel, SetSavebillingButtonLabel] = useState('')
    const [billing_err, setbilling_err] = useState(false)
    const [ActiveServiceList, SetActiveServiceList] = useState([])
    const [ServiceAmount, SetServicehourlyamount] = useState('')
    const [billableAmount, SetbillableAmount] = useState('')
    const [SessionValue, SetSessionValue] = useState('')
    const [Clientuserslist, SetClientuserlist] = useState([])
    const [ClientId, SetClientId] = useState('')
    const [ServiceId, SetServiceId] = useState('')
    const [BillingList, SetBillingList] = useState([])
    const [BillingPerPage, SetBillingPerpage] = useState(10)
    const [TotalRecordBilling, SetTotalRecordBilling] = useState(0)
    const [BillingPageNo, SetBillingPageNo] = useState(0)
    const [BillingDeleteId, SetBillingDeleteId] = useState('')
    const [BillingUpdateId, SetBillingUpdateId] = useState('')


    const trigger_billing_pop = () => {


        SetSavebillingButtonLabel('Add billing')
        SetServicehourlyamount('')
        SetbillableAmount('')
        SetSessionValue('')
        SetServiceId('')
        SetClientId('')
        SetBillingUpdateId('')
        setShow(true)
        GetServiceList()
        GetClientList()
    }

    const GetClientList = async () => {



        const response = await axiosJWT.get('/nutrition/allclient', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetClientuserlist(response.data)

        })
    }

    const GetServiceList = async () => {

        const response = await axiosJWT.get('/nutrition-service/list-services?data=all_active', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetActiveServiceList(response.data.NutritionistService)
        })

    }

    const SetServiceamount = async (value) => {
        SetServiceId(value)
        SetServicehourlyamount('')
        SetbillableAmount('')
        const input = document.querySelector('.total_session')
        input.value = ''





        if (value !== '') {
            const response = await axiosJWT.get('/nutrition-service/service-details?id=' + value + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {

                SetServicehourlyamount(response.data[0].service_price)

                const input = document.querySelector('.service_amount_input')
                input.value = response.data[0].service_price
                SetbillableAmount('')
            })

        }
        else {
            SetServicehourlyamount('')
            SetbillableAmount('')

        }

    }

    const setBillableamount = (value) => {

        SetSessionValue(value)
        if (value !== '') {
            var totalSession = parseInt(value)
            var billableAMount = parseInt(ServiceAmount) * totalSession;
            //in
            const input = document.querySelector('.billable_amount')
            input.value = billableAMount

            SetbillableAmount(billableAMount)

        }
        else {
            const input = document.querySelector('.billable_amount')
            input.value = ''
            SetbillableAmount('')
        }

    }



    const AddBilling = async () => {


        if (BillingUpdateId === '') {

            if (ClientId === '' || billableAmount === '' || ServiceId === '' || SessionValue === '') {
                setbilling_err(true)
            }
            else {

                setbilling_err(false)

                const response = await axiosJWT.post('/payments/add-payments',
                    {
                        client_id: ClientId,
                        service_plan_id: ServiceId,
                        billing_amount: billableAmount,
                        total_session: SessionValue

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                 
                    setShow(false)
                    GetBillingList(1)
                }





            }

        }
        else {



            if (ClientId === '' || billableAmount === '' || ServiceId === '' || SessionValue === '' || BillingUpdateId === '') {
                setbilling_err(true)
            }
            else {

                setbilling_err(false)


                const response = await axiosJWT.post('payments/nutrition/billing',
                    {
                        client_id: ClientId,
                        service_plan_id: ServiceId,
                        billing_amount: billableAmount,
                        total_session: SessionValue,
                        billing_id: BillingUpdateId


                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response) {
                

                    GetBillingList(BillingPageNo)
                    setShow(false)

                }





            }



        }


    }

    const GetBillingList = async (pagenumber) => {

        SetBillingPageNo(pagenumber)

        const response = await axiosJWT.get('payments/nutrition/get-billing-list?offset=' + pagenumber + '&limit=' + BillingPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetTotalRecordBilling(response.data.billingListCount)
            SetBillingList(response.data.billingList)

        })


    }
    useEffect(() => {
        GetBillingList(1)
    }, [])

    const Deletebilling = (e) => {

        var DeleteID = e.target.getAttribute('data-id')
        SetBillingDeleteId(DeleteID)
        setShows(true);

    }

    const FinalDelete = async (e) => {
        var DeleteID = e.target.getAttribute('data-id')


        const response = await axiosJWT.delete('payments/nutrition/delete-billing-list/' + DeleteID + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            GetBillingList(BillingPageNo)
            setShows(false)
        })
    }

    const editbilling = async (e) => {



        var EditId = e.target.getAttribute('data-id')
        SetBillingUpdateId(EditId)
        GetServiceList()
        GetClientList()
        const response = await axiosJWT.get('payments/billing-list-details?id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {



            SetSavebillingButtonLabel('Update billing')
            SetClientId(response.data.client_id)
            SetServiceId(response.data.service_plan_id)
            SetSessionValue(response.data.total_session)
            SetbillableAmount(response.data.billing_amount)
            SetServicehourlyamount(response.data.nutritionist_service.service_price)

            setShow(true)
        })


    }

    const changePageNumberS = async (pagenumber) => {


        GetBillingList(pagenumber)
        SetBillingPageNo(pagenumber)
    }




    const [shows, setShows] = useState(false);

    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);



    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="row billing_row">
                    <div className="col top_billing_col">
                        <span className="top_coach_span active" data-tab={'services'}>Billing</span>
                    </div>
                    <div className="col">
                        <button className="top_billing_coach_payment" onClick={trigger_billing_pop}>Add Billing</button>
                    </div>

                </div>

                <div className="row billing_row">
                    {BillingList.length > 0 &&
                        <table className="table is-striped is-fullwidth">
                            <thead className="thead">
                                <tr>
                                    <th>Service Name</th>

                                    <th>Total Session{'( In hr)'}</th>
                                    <th>Billable amount</th>
                                    <th>Client Name</th>
                                    <th>Client Email</th>
                                    <th>Updated At</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {BillingList.length > 0 && BillingList.map((billing, index) => (

                                    <tr className='trow' key={billing.id}>
                                        <td><span className="desing_purple">{billing.nutritionist_service.service_name}</span></td>
                                        <td >{billing.total_session}</td>
                                        <td >$ {billing.billing_amount}</td>
                                        <td >{billing.user.first_name + ' ' + billing.user.last_name}</td>
                                        <td >{billing.user.email}</td>
                                        <td >{format(parseISO(billing.updatedAt), `PP`)}</td>

                                        <td ><i className="fa fa-edit edit_icon billing_edit_i pointer_eff" data-id={billing.id} onClick={(e) => { editbilling(e) }}></i><i data-id={billing.id} className="fa fa-trash billing_edit_i pointer_eff" onClick={(e) => { Deletebilling(e) }}></i></td>


                                    </tr>


                                ))}









                            </tbody>
                        </table>


                    }
                    {BillingList.length > 0 &&
                        <Pagination
                            activePage={BillingPageNo}
                            itemsCountPerPage={BillingPerPage}
                            totalItemsCount={TotalRecordBilling}
                            onChange={changePageNumberS.bind(this)}
                            prevPageText="<"
                            nextPageText=">"
                            hideFirstLastPages={true}
                            linkClassPrev="pagination_prev"
                            linkClassNext="pagination_next"
                            linkClass="pagenumbers grayfont"
                        />
                    }
                </div>

                <Modal show={show} onHide={handleClose} className='commission_popup add_billing_popup'>
                    <Modal.Header closeButton>
                        <Modal.Title>{SavebillingButtonLabel}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row section_1_commission">

                            <div className="col">
                                <label className='commission_inner_popup_label'>Select service</label><br />
                                <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} onChange={(e) => { SetServiceamount(e.target.value) }} defaultValue={ServiceId}  >
                                    <option value={''}>Select service</option>

                                    {ActiveServiceList.length > 0 && ActiveServiceList.map((services, index) => (

                                        <option value={services.id}>{services.service_name}</option>


                                    ))}

                                </select>
                            </div>
                            <div className="col">
                                <label className='commission_inner_popup_label'>Select client</label><br />
                                <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' defaultValue={ClientId} style={{ float: 'left', marginTop: '6px', width: '100%' }} onChange={(e) => { SetClientId(e.target.value) }}  >

                                    <option value="">Select Client</option>
                                    {Clientuserslist.length > 0 && Clientuserslist.map((client, index) => (
                                        <option value={client.id}>{client.first_name + ' ' + client.first_name + '(' + client.email + ')'}</option>
                                    ))}

                                </select>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col">
                                <label className='commission_inner_popup_label'>Total session{'( In hr)'}</label><br />
                                <input type='text' className="add_category_inner_input total_session" defaultValue={SessionValue} onChange={(e) => { setBillableamount(e.target.value) }} />
                            </div>
                            <div className="col">
                                <label className='commission_inner_popup_label'>Service Amount per hour{' ($)'}</label><br />
                                <input type='text' defaultValue={ServiceAmount} readOnly className="add_category_inner_input service_amount_input" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className='commission_inner_popup_label'>Billable Amount{' ($)'}</label><br />
                                <input type='text' defaultValue={billableAmount} className="add_category_inner_input billable_amount" readOnly />
                            </div>
                        </div>
                        {billing_err === true &&
                            <div className="row service_cat_err">
                                Please fill out all fields
                            </div>
                        }


                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                            Back
                        </Button>
                        <Button variant="primary" className='save_button_commission float_right' data-update={BillingUpdateId}
                            onClick={AddBilling}  >
                            {SavebillingButtonLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={shows} onHide={handleCloses} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete billing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete this billing?</Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" onClick={handleCloses}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="float_right" data-id={BillingDeleteId} onClick={(e) => { FinalDelete(e) }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </div>
    )
}

export default CoachBilling
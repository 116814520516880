import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useFileUpload from "react-use-file-upload";

const Upload = (props) => {



    const [DirectoryId,SetDirectoryId]=useState('')

    useEffect(()=>{

        if(props.DirectoryId)
        {
            SetDirectoryId(props.DirectoryId)
        }

    },[props])


  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();
  const [Err, SetErr] = useState(false);
  const [ErrMsg, SetErrMsg] = useState("");
  const token = localStorage.getItem("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = createFormData();
    props.ShowLoader()
    formData.append('directoryid', DirectoryId);
    try {
     const UploadResponse= await axios.post("/document-directory/my-directory/add-files", formData, {
        "content-type": "multipart/form-data",
        headers: {
            Authorization: `Bearer ${token}`,
          },
      },
      );
      if(UploadResponse.status===200)
      {
        props.GetMyDirectoryFiles(DirectoryId)
        props.HideLoader()
        props.Setishow(false)
   
    
      }
    } catch (error) {
      props.HideLoader()
      console.error("Failed to submit files.");
    }
  };

  const ValidateFile = (e, type) => {

    var Allowed = ["PDF", "DOC", "DOCX", "TXT"];
    SetErr(false);
    if (type === "drop") {

     

    var FileLists=e.dataTransfer.files;
    handleDragDropEvent(e);
    SetErr(false);
    console.log(FileLists)

    for (var i = 0; i < FileLists.length; i++) {
        var file = FileLists[i];
        var FileName = file.name;
        var FileExt = FileName.substr(FileName.lastIndexOf('.') + 1)
        FileExt=FileExt.toUpperCase()

        if(fileNames.includes(FileName)===false)
        {
              if (Allowed.includes(FileExt) === true) {
                setFiles(e, "a");
                inputRef.current.value = null;
              } else {
                SetErrMsg("Please upload only pdf,doc,docx,txt files");
                SetErr(true);
                inputRef.current.value = null;
              }
        }
        else{
                  SetErrMsg("Please make sure you dont upload the same file again");
                  SetErr(true);  
                  inputRef.current.value = null;
        }
        
      

    }
   
     
 

    //   console.log(inputRef.current.value)
    }

    if (type === "change") {

        var control = document.getElementsByClassName("input_ref");
 
        SetErr(false);
        for (var i = 0; i < control[0].files.length; i++) {
            var file = control[0].files[i];
            var FileName = file.name;
            var FileExt = FileName.substr(FileName.lastIndexOf('.') + 1)
            FileExt=FileExt.toUpperCase()
 
            if(fileNames.includes(FileName)===false)
            {
                  if (Allowed.includes(FileExt) === true) {
                    setFiles(e, "a");
                    inputRef.current.value = null;
                  } else {
                    SetErrMsg("Please upload only pdf,doc,docx,txt files");
                    SetErr(true);
                    inputRef.current.value = null;
                  }
            }
            else{
                      SetErrMsg("Please make sure you dont upload the same file again");
                      SetErr(true);  
                      inputRef.current.value = null;
            }
            
          

        }
 
    }
  };

  return (
    <div>
      <div className="form-container">
        {/* Display the files to be uploaded */}
        <div>
          <ul className="file_list_upload_directory">
            {fileNames.map((name) => (
              <li key={name}>
                <span className="title">{name}</span>

                <span onClick={() => removeFile(name)}>
                  <i className="fa fa-times" />
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Provide a drop zone and an alternative button inside it to upload files. */}
        <div
          onDragEnter={handleDragDropEvent}
          onDragOver={handleDragDropEvent}
          onDrop={(e) => {
            ValidateFile(e, "drop");
          }}
        >
          <p className="drag_drop_css">Drag and drop files here</p>
          <div>
            {Err === true && <span style={{ color: "red" }}>{ErrMsg}</span>}
          </div>

          <div className="submit">
            <button
              className="drag_drop_css_button"
              onClick={() => inputRef.current.click()}
            >
              select files
            </button>
            <button className="drag_drop_css_button" onClick={handleSubmit}>
              Submit
            </button>
          </div>

          {/* Hide the crappy looking default HTML input */}
          <input
            ref={inputRef}
            accept=".pdf,.doc,.docx,.txt"
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
              ValidateFile(e, "change");
            }}
            className="input_ref"
          />
        </div>
      </div>
    </div>
  );
};
export default Upload;

import React, { useState, useRef, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import PersonalInfoData from "./PersonalInforeport";
import ReactToPrint from 'react-to-print';
import axios from "axios";
import moment from 'moment-timezone';
import PhysicalActivityreport from "./PhysicalActivtyreport";
import StepsActivityreport from './StepsActivityreport';
import BloodHematocritReport from "./BloodHematocritReport";
import BloodHemogloblinReport from "./BloodHemogloblinReport";
import BodyCompFatReport from "./BodyCompFat";
import BodyCompFatPoundsReport from "./BodyCompFatPounds";
import BodyCompLeanMass from "./BodyCompLeanMass";
import BodyCompWeight from "./BodyCompWeight";
import CardioPressure from "./CardioPressure";
import Cardiov2Max from "./CardioV2Max";
import LifestyleAlcoholIntakes from "./LifestyleAlcohol";
import LifestyleCaffineIntakes from "./LifeStyleCaffine";
import LifestyleHydration from "./LifeStyleHydration";
import MetabolismCarbTolerance from "./MetabolismCarbTolerance";
import MetabolismGlucose from "./MetabolismGlucose";
import MetabolismKetoneIndex from "./MetabolismKetoneIndex";
import MetabolismKetoMojo from "./MetabolismKetoMojo";
import MetabolismKetoneBlood from "./MetabolismKetoneBlood";
import MetabolismKetoneBreath from "./MetabolismKetoneBreathe";
import MetabolismKetoneUrine from "./MetabolismKetoneUrine";
import NutritionBristolScore from "./NutritionBristolScore";
import NutritionMacroNutrient from "./NutritionMacroNutrient";
import RestAmReadiness from './RestAmReadiness'
import RestCryoTherapy from "./RestCryotherapy";
import RestHrv from "./RestHrv";
import RestHyperBaricOxygen from "./RestHyperbaricoxygen";
import RestIceBath from "./RestIcebath";
import RestMeditation from "./RestMeditation";
import RestPhotoBioModulation from "./RestPhotoBiomodulation";
import RestSauna from "./RestSauna";
import RestAsleep from "./RestAsleep";
import VitalBasalTemp from "./VitalBasalTemp";
import VitalBloodOxy from "./VitalBloodOxy";
import VitalBodyTemp from "./VitalBodyTemp";
import VitalHeartrate from "./VitalHeartRate";
import VitalRestingHeartrate from "./VItalRestingHeartRate";
import CustomDataReport from "./CustomData";
import { CSVLink } from "react-csv";

const GenerateAddData = (props) => {



    const token = localStorage.getItem("token");
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
    const axiosJWT = axios.create();
    const [selectoptions, Setselectoptions] = useState([])
    const [IsClientid,SetIsClientid]=useState('')
    const [profileData, SetprofileData] = useState()
    const datepicker1Ref = useRef(null);
    const datepicker2Ref = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [ShowFromToDate, SetShowFromToDate] = useState(false)
    const [ShowFromDate, SetShowFromDate] = useState(false)
    const [SelectedCategory, SetSelectedCategory] = useState('')
    const [SelectedCategoryText, SetSelectedCategoryText] = useState('')
    const [CustomCategorySelectedId, SetCustomCategorySelectedId] = useState('')
    const [showsdprogress, Setshowsdprogress] = useState(false)
    const [DocumentTitleValue, SetDocumentTitleValue] = useState('')
    const [ExportFileName,SetExportFileName]=useState('')
    const [ExportData,SetExportData]=useState([])
    const handleClosesdprogress = () => { Setshowsdprogress(false) }
    const [showerr, Setshowerr] = useState(false)
    const componentRef = useRef();
    function handleClickDatepickerIcon1() {
        const datepickerElement = datepicker1Ref.current;
        datepickerElement.setFocus(true);
    }
    function handleClickDatepickerIcon2() {
        const datepickerElement = datepicker2Ref.current;
        datepickerElement.setFocus(true);
    }
    const ChangedDate = (date) => {
        setStartDate(date)

    }
    const ChangedEndDate = (date) => {
        setEndDate(date)
    }


    useEffect(() => {
        SetprofileData(props.profileData)
        SetCustomCatList(props.CustomCategoryList)
    
        SetIsClientid(props.IsClientid)
    }, [props.profileData, props.CustomCategoryList,props.IsClientid])


    const [DataLabel, SetDataLabel] = useState([])
    const [DataValue, SetDataValue] = useState([])

    const [PhysicalActivityData, SetPhysicalActivityData] = useState([])
    const [StepsActivityData, SetStepsActivityData] = useState([])
    const [BloodHematocritData, SetBloodHematocritData] = useState([])
    const [BloodHemogloblinData, SetBloodHemogloblinData] = useState([])
    const [BodyCompBodyFatData, SetBodyCompBodyFatData] = useState([])
    const [BodyCompBodyFatPoundsData, SetBodyCompBodyFatPoundsData] = useState([])
    const [BodyCompLeanData, SetBodyCompLeanData] = useState([])
    const [BodyCompWeightData, SetBodyCompWeightData] = useState([])
    const [CardioPressureData, SetCardioPressureData] = useState([])
    const [CardioVo2MaxData, SetCardioVo2MaxData] = useState([])
    const [LifestyleAlcoholData, SetLifestyleAlcoholData] = useState([])
    const [LifestyleCaffineData, SetLifestyleCaffineData] = useState([])
    const [LifestyleHydrationData, SetLifestyleHydrationData] = useState([])
    const [MetabolismCarbToleranceData, SetMetabolismCarbToleranceData] = useState([])
    const [MetabolismGlucoseData, SetMetabolismGlucoseData] = useState([])
    const [MetabolismKetoneIndexData, SetMetabolismKetoneIndexData] = useState([])
    const [MetabolismKetoMojoData, SetMetabolismKetoMojoData] = useState([])
    const [MetabolismKetoneBloodData, SetMetabolismKetoneBloodData] = useState([])
    const [NutritionBristolData, SetNutritionBristolData] = useState([])
    const [NutritionMacroNutrientData, SetNutritionMacroNutrientData] = useState([])
    const [NutritionMacroNutrientLabel, SetNutritionMacroNutrientLabel] = useState('')
    const [RestAmReadinessData, SetRestAmReadinessData] = useState([])
    const [RestCryotherapyData, SetRestCryotherapyData] = useState([])
    const [RestHrvData, SetRestHrvData] = useState([])
    const [RestHrvSleepData, SetRestHrvSleepData] = useState([])
    const [RestHyperbaricData, SetRestHyperbaricData] = useState([])
    const [RestIceData, SetRestIceData] = useState([])
    const [RestMeditationData, SetRestMeditationData] = useState([])
    const [RestPhotoBioData, SetRestPhotoBioData] = useState([])
    const [RestSaunaData, SetRestSaunaData] = useState([])
    const [RestTimeAsleepData, SetRestTimeAsleepData] = useState([])
    const [VitalBasalTempData, SetVitalBasalTempData] = useState([])
    const [VitalBloodOxyData, SetVitalBloodOxyData] = useState([])
    const [VitalBodyTempData, SetVitalBodyTempData] = useState([])
    const [HeartRateData, SetHeartRateData] = useState([])
    const [RestingHeartRateData, SetRestingHeartRateData] = useState([])
    const [CustomData,SetCustomData]=useState([])
    const [CustomCatList, SetCustomCatList] = useState([])






    const [PhysicalActivityShow, SetPhysicalActivityShow] = useState(false)
    const [StepsActivityShow, SetStepsActivityShow] = useState(false)
    const [BloodHematocritshow, SetBloodHematocritshow] = useState(false)
    const [BloodHemogloblinshow, SetBloodHemogloblinshow] = useState(false)
    const [BodyCompBodyFatShow, SetBodyCompBodyFatShow] = useState(false)
    const [BodyCompBodyFatPoundsShow, SetBodyCompBodyFatPoundsShow] = useState(false)
    const [BodyCompLeanShow, SetBodyCompLeanShow] = useState(false)
    const [BodyCompWeightShow, SetBodyCompWeightShow] = useState(false)
    const [CardioPressureShow, SetCardioPressureShow] = useState(false)
    const [CardioVo2Show, SetCardioVo2Show] = useState(false)
    const [LifestyleAlcoholShow, SetLifestyleAlcoholShow] = useState(false)
    const [LifestyleCaffineShow, SetLifestyleCaffineShow] = useState(false)
    const [LifestyleHydrationShow, SetLifestyleHydrationShow] = useState(false)
    const [MetabolismCarbToleranceShow, SetMetabolismCarbToleranceShow] = useState(false)
    const [MetabolismGlucoseShow, SetMetabolismGlucoseShow] = useState(false)
    const [MetabolismKetoneIndexShow, SetMetabolismKetoneIndexShow] = useState(false)
    const [MetabolismKetoMojoShow, SetMetabolismKetoMojoShow] = useState(false)
    const [MetabolismKetoneBloodShow, SetMetabolismKetoneBloodShow] = useState(false)
    const [MetabolismKetoneBreatheShow, SetMetabolismKetoneBreatheShow] = useState(false)
    const [MetabolismKetoneUrineShow, SetMetabolismKetoneUrineShow] = useState(false)
    const [NutritionBristolShow, SetNutritionBristolShow] = useState(false)
    const [NutritionMacroNutrientShow, SetNutritionMacroNutrientShow] = useState(false)
    const [RestAmReadinessShow, SetRestAmReadinessShow] = useState(false)
    const [RestCryotherapyShow, SetRestCryotherapyShow] = useState(false)
    const [RestHrvShow, SetRestHrvShow] = useState(false)
    const [RestHrvSleepShow, SetRestHrvSleepShow] = useState(false)
    const [RestHyperbaricShow, SetRestHyperbaricShow] = useState(false)
    const [RestIceShow, SetRestIceShow] = useState(false)
    const [RestMeditationShow, SetRestMeditationShow] = useState(false)
    const [RestPhotoBioShow, SetRestPhotoBioShow] = useState(false)
    const [RestSaunaShow, SetRestSaunaShow] = useState(false)
    const [RestTimeAsleepShow, SetRestTimeAsleepShow] = useState(false)
    const [VitalBasalTempShow, SetVitalBasalTempShow] = useState(false)
    const [VitalBloodOxyShow, SetVitalBloodOxyShow] = useState(false)
    const [VitalBodyTempShow, SetVitalBodyTempShow] = useState(false)
    const [VitalHeartRateShow, SetVitalHeartRateShow] = useState(false)
    const [VitalRestingHeartRateShow, SetVitalRestingHeartRateShow] = useState(false)
    const [CustomReportShow,SetCustomReportShow]=useState(false)







    const LoadPopup = (e) => {
        const value = e.target.value
        SetShowFromToDate(false)
        SetShowFromDate(false)
        SetSelectedCategory(value)

        const select = e.target;
        const desc = select.selectedOptions[0].text;
        const id = select.selectedOptions[0].getAttribute('data-custom_id');
        SetCustomCategorySelectedId(id)
        SetSelectedCategoryText(desc)
        SetPhysicalActivityShow(false)
        SetStepsActivityShow(false)
        SetBloodHematocritshow(false)
        SetBloodHemogloblinshow(false)
        SetBodyCompBodyFatShow(false)
        SetBodyCompBodyFatPoundsShow(false)
        SetBodyCompLeanShow(false)
        SetBodyCompWeightShow(false)
        SetCardioPressureShow(false)
        SetCardioVo2Show(false)
        SetLifestyleAlcoholShow(false)
        SetLifestyleCaffineShow(false)
        SetLifestyleHydrationShow(false)
        SetMetabolismCarbToleranceShow(false)
        SetMetabolismGlucoseShow(false)
        SetMetabolismKetoneIndexShow(false)
        SetMetabolismKetoMojoShow(false)
        SetMetabolismKetoneBloodShow(false)
        SetMetabolismKetoneBreatheShow(false)
        SetMetabolismKetoneUrineShow(false)
        SetNutritionBristolShow(false)
        SetNutritionMacroNutrientShow(false)
        SetRestAmReadinessShow(false)
        SetRestCryotherapyShow(false)
        SetRestHrvShow(false)
        SetRestHrvSleepShow(false)
        SetRestHyperbaricShow(false)
        SetRestIceShow(false)
        SetRestMeditationShow(false)
        SetRestPhotoBioShow(false)
        SetRestSaunaShow(false)
        SetRestTimeAsleepShow(false)
        SetVitalBasalTempShow(false)
        SetVitalBloodOxyShow(false)
        SetVitalBodyTempShow(false)
        SetVitalHeartRateShow(false)
        SetVitalRestingHeartRateShow(false)
        SetCustomReportShow(false)


        if (value === 'Physical Activity') {
            SetShowFromToDate(true)
        }
        if (value === 'Steps') {
            SetShowFromToDate(true)
        }
        if (value === 'Hematocrit') {
            SetShowFromToDate(true)
        }
        if (value === 'Hemoglobin') {
            SetShowFromToDate(true)
        }
        if (value === 'Body Fat') {
            SetShowFromToDate(true)
        }
        if (value === 'Body Fat Pounds') {
            SetShowFromToDate(true)
        }
        if (value === 'Lean Body Mass') {
            SetShowFromToDate(true)
        }
        if (value === 'Weight') {
            SetShowFromToDate(true)
        }
        if (value === 'Cardio Blood Pressure') {
            SetShowFromToDate(true)
        }
        if (value === 'Cardio Vo2 Max') {
            SetShowFromToDate(true)
        }
        if (value === 'Alcohol') {
            SetShowFromToDate(true)
        }
        if (value === 'Caffine') {
            SetShowFromToDate(true)
        }
        if (value === 'Hydration') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Carb Tolerance') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Glucose') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Ketone Index') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Ketone Mojo') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Ketones Blood') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Ketones Breath') {
            SetShowFromToDate(true)
        }
        if (value === 'Metabolism Ketones Urine') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Bristol Score') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Protein') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Fat') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Carbohydrates') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Calories') {
            SetShowFromToDate(true)
        }
        if (value === 'Nutrition Fiber') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest AM Readiness') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Cryotherapy') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest HRV') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Hyperbaric oxygen') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Icebath') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Meditation') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest PhotoBioModulation') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Sauna') {
            SetShowFromToDate(true)
        }
        if (value === 'Rest Asleep') {
            SetShowFromDate(true)
        }
        if (value === 'Vital Basal Temperature') {
            SetShowFromToDate(true)
        }
        if (value === 'Vital Blood Oxygen') {
            SetShowFromToDate(true)
        }
        if (value === 'Vital Body Temperature') {
            SetShowFromToDate(true)
        }
        if (value === 'Vital Heart Rate') {
            SetShowFromToDate(true)
        }
        if (value === 'Vital Rest Heart Rate') {
            SetShowFromToDate(true)
        }

        if (value.startsWith("custom_data")) {
            // const myArray = value.split("custom_data");
            SetShowFromToDate(true)
        }



    }

    const GenerateDataReport = () => {
        SetPhysicalActivityShow(false)
        SetStepsActivityShow(false)
        SetBloodHematocritshow(false)
        SetBloodHemogloblinshow(false)
        SetBodyCompBodyFatShow(false)
        SetBodyCompBodyFatPoundsShow(false)
        SetBodyCompLeanShow(false)
        SetBodyCompWeightShow(false)
        SetCardioPressureShow(false)
        SetLifestyleAlcoholShow(false)
        SetLifestyleCaffineShow(false)
        SetLifestyleHydrationShow(false)
        SetMetabolismCarbToleranceShow(false)
        SetMetabolismGlucoseShow(false)
        SetMetabolismKetoneIndexShow(false)
        SetMetabolismKetoMojoShow(false)
        SetMetabolismKetoneBloodShow(false)
        SetMetabolismKetoneBreatheShow(false)
        SetMetabolismKetoneUrineShow(false)
        SetNutritionBristolShow(false)
        SetNutritionMacroNutrientShow(false)
        SetRestAmReadinessShow(false)
        SetRestCryotherapyShow(false)
        SetRestHrvShow(false)
        SetRestHrvSleepShow(false)
        SetRestHyperbaricShow(false)
        SetRestIceShow(false)
        SetRestMeditationShow(false)
        SetRestPhotoBioShow(false)
        SetRestSaunaShow(false)
        SetRestTimeAsleepShow(false)
        SetVitalBasalTempShow(false)
        SetVitalBloodOxyShow(false)
        SetVitalBodyTempShow(false)
        SetVitalHeartRateShow(false)
        SetVitalRestingHeartRateShow(false)
        SetCustomReportShow(false)
        SetExportData([])
        SetExportFileName('')


        if (SelectedCategory === 'Physical Activity') {
            SetDocumentTitleValue('Report(Physical Activity)')
            Setshowerr(false)

            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetPhysicalReportData(startdatepass, enddatepass, SelectedCategory)
                SetPhysicalActivityShow(true)
                Setshowsdprogress(true)
            }

        }
        if (SelectedCategory === 'Steps') {
            SetDocumentTitleValue('Report(Steps)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {

                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetStepsActivityReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetStepsActivityShow(true)

            }
        }
        if (SelectedCategory === 'Hematocrit') {
            SetDocumentTitleValue('Report(Hematocrit)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {

                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetHematoCritReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBloodHematocritshow(true)

            }

        }
        if (SelectedCategory === 'Hemoglobin') {
            SetDocumentTitleValue('Report(Hemoglobin)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {

                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetHemogloblinReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBloodHemogloblinshow(true)


            }


        }
        if (SelectedCategory === 'Body Fat') {
            SetDocumentTitleValue('Report(Body fat)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {

                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetBodyFatreport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBodyCompBodyFatShow(true)
            }

        }
        if (SelectedCategory === 'Body Fat Pounds') {
            SetDocumentTitleValue('Report(Body fat pounds)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetBodyFatPoundsreport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBodyCompBodyFatPoundsShow(true)

            }

        }
        if (SelectedCategory === 'Lean Body Mass') {
            SetDocumentTitleValue('Report(Lean body mass)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetBodyLeanMassReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBodyCompLeanShow(true)
            }

        }
        if (SelectedCategory === 'Weight') {
            SetDocumentTitleValue('Report(Body Weight)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetBodyWeightReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetBodyCompWeightShow(true)
            }

        }
        if (SelectedCategory === 'Cardio Blood Pressure') {
            SetDocumentTitleValue('Report(Cardiovascular-Blood Pressure)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetCardioPressureReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetCardioPressureShow(true)
            }

        }
        if (SelectedCategory === 'Cardio Vo2 Max') {
            SetDocumentTitleValue('Report(Cardiovascular-Vo2 Max)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetCardioVo2Report(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetCardioVo2Show(true)

            }
        }
        if (SelectedCategory === 'Alcohol') {
            SetDocumentTitleValue('Report(Lifestyle-Alcohol)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetLifestyleAlcholReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetLifestyleAlcoholShow(true)
            }
        }
        if (SelectedCategory === 'Caffine') {
            SetDocumentTitleValue('Report(Lifestyle-Caffeine)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetLifestyleCaffineReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetLifestyleCaffineShow(true)
            }

        }
        if (SelectedCategory === 'Hydration') {
            SetDocumentTitleValue('Report(Lifestyle-Hydration)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetLifestyleHydrationReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetLifestyleHydrationShow(true)
            }

        }
        if (SelectedCategory === 'Metabolism Carb Tolerance') {
            SetDocumentTitleValue('Report(Metabolism Carb Tolerance)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaCarbToleranceReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismCarbToleranceShow(true)
            }
        }
        if (SelectedCategory === 'Metabolism Glucose') {
            SetDocumentTitleValue('Report(Metabolism Glucose)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaGlucoseReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismGlucoseShow(true)

            }

        }
        if (SelectedCategory === 'Metabolism Ketone Index') {
            SetDocumentTitleValue('Report(Metabolism Ketone Index)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaGlucoseKetoneindexReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismKetoneIndexShow(true)

            }

        }
        if (SelectedCategory === 'Metabolism Ketone Mojo') {
            SetDocumentTitleValue('Report(Metabolism Keto Mojo)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaGlucoseKetomojoReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismKetoMojoShow(true)

            }

        }
        if (SelectedCategory === 'Metabolism Ketones Blood') {
            SetDocumentTitleValue('Report(Metabolism Ketones Blood)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {

                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaKetoBBCReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismKetoneBloodShow(true)
            }
        }
        if (SelectedCategory === 'Metabolism Ketones Breath') {
            SetDocumentTitleValue('Report(Metabolism Ketones Breath)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaKetoBBCReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismKetoneBreatheShow(true)
            }
        }
        if (SelectedCategory === 'Metabolism Ketones Urine') {
            SetDocumentTitleValue('Report(Metabolism Ketones Urine)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetMetaKetoBBCReportData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetMetabolismKetoneUrineShow(true)
            }
        }

        if (SelectedCategory === 'Nutrition Bristol Score') {
            SetDocumentTitleValue('Report(Nutrition Bristol Score)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetNutritionBristolScore(startdatepass, enddatepass, 'Bristol Score')
                Setshowsdprogress(true)
                SetNutritionBristolShow(true)
            }
        }
        if (SelectedCategory === 'Nutrition Protein' || SelectedCategory === 'Nutrition Fat' || SelectedCategory === 'Nutrition Carbohydrates' || SelectedCategory === 'Nutrition Calories' || SelectedCategory === 'Nutrition Fiber') {

            var PassQuery = '';
            if (SelectedCategory === 'Nutrition Protein') {
                SetNutritionMacroNutrientLabel('Protein')
                PassQuery = 'Macro Nutrient Protein'
            }
            if (SelectedCategory === 'Nutrition Fat') {
                SetNutritionMacroNutrientLabel('Fat')
                PassQuery = 'Macro Nutrient Fat'
            }
            if (SelectedCategory === 'Nutrition Carbohydrates') {
                SetNutritionMacroNutrientLabel('Carbohydrate')
                PassQuery = 'Macro Nutrient Carbohydrate'
            }
            if (SelectedCategory === 'Nutrition Calories') {
                SetNutritionMacroNutrientLabel('Calories')
                PassQuery = 'Macro Nutrient Calories'
            }
            if (SelectedCategory === 'Nutrition Fiber') {
                SetNutritionMacroNutrientLabel('Fiber')
                PassQuery = 'Macro Nutrient Fiber'
            }

            SetDocumentTitleValue('Report(Nutrition ' + SelectedCategory + ')')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetNutritionMacroNutrient(startdatepass, enddatepass, PassQuery)
                Setshowsdprogress(true)
                SetNutritionMacroNutrientShow(true)
            }

        }
        if (SelectedCategory === 'Rest AM Readiness') {
            SetDocumentTitleValue('Report(Rest & Recovery (Am Readiness)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestAmReadinessReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestAmReadinessShow(true)
            }

        }
        if (SelectedCategory === 'Rest Cryotherapy') {
            SetDocumentTitleValue('Report(Rest & Recovery (Rest Cryotherapy)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            } else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestCryotherapyReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestCryotherapyShow(true)
            }


        }
        if (SelectedCategory === 'Rest HRV') {
            SetDocumentTitleValue('Report(Rest & Recovery (Rest HRV)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestHRVReport(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestHrvShow(true)

            }
        }
        if (SelectedCategory === 'Rest Hyperbaric oxygen') {
            SetDocumentTitleValue('Report(Rest & Recovery (Hyperbaric oxygen)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestHyperBaricOxygen(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestHyperbaricShow(true)

            }
        }
        if (SelectedCategory === 'Rest Icebath') {
            SetDocumentTitleValue('Report(Rest & Recovery (Ice bath)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestIceBath(startdatepass, enddatepass, 'Rest Ice Bath')
                Setshowsdprogress(true)
                SetRestIceShow(true)

            }

        }
        if (SelectedCategory === 'Rest Meditation') {
            SetDocumentTitleValue('Report(Rest & Recovery (Meditation)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestMeditation(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestMeditationShow(true)
            }

        }
        if (SelectedCategory === 'Rest PhotoBioModulation') {
            SetDocumentTitleValue('Report(Rest & Recovery (Photo Bio Modulation)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestPhotoBioModulation(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestPhotoBioShow(true)

            }

        }
        if (SelectedCategory === 'Rest Sauna') {
            SetDocumentTitleValue('Report(Rest & Recovery (Sauna)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestSauna(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestSaunaShow(true)

            }
        }
        if (SelectedCategory === 'Rest Asleep') {
            SetDocumentTitleValue('Report(Rest & Recovery (Time asleep)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestAsleep(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetRestTimeAsleepShow(true)
            }

        }
        if (SelectedCategory === 'Vital Basal Temperature') {
            SetDocumentTitleValue('Report (Vital Basal Temperature)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetVitalBasalTemperature(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetVitalBasalTempShow(true)
            }
        }
        if (SelectedCategory === 'Vital Blood Oxygen') {
            SetDocumentTitleValue('Report (Vital Blood Oxygen)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetVitalBloodOxygen(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetVitalBloodOxyShow(true)
            }
        }
        if (SelectedCategory === 'Vital Body Temperature') {
            SetDocumentTitleValue('Report (Vital Body Temperature)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetBodyTempData(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetVitalBodyTempShow(true)
            }

        }
        if (SelectedCategory === 'Vital Heart Rate') {
            SetDocumentTitleValue('Report (Vital Heart Rate)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetHeartRate(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetVitalHeartRateShow(true)

            }
        }
        if (SelectedCategory === 'Vital Rest Heart Rate') {
            SetDocumentTitleValue('Report (Vital Resting Heart Rate)')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                var startdatepass = moment(startDate).format("YYYY-MM-DD");
                var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                GetRestingHeartRate(startdatepass, enddatepass, SelectedCategory)
                Setshowsdprogress(true)
                SetVitalRestingHeartRateShow(true)

            }
        }

        if (SelectedCategory.startsWith("custom_data")) {
            SetDocumentTitleValue('Report (' + SelectedCategoryText + ')')
            Setshowerr(false)
            if (Date.parse(startDate) > Date.parse(EndDate)) {
                Setshowerr(true)
            }
            else {
                if (CustomCategorySelectedId !== null && CustomCategorySelectedId !== '') {
                    var startdatepass = moment(startDate).format("YYYY-MM-DD");
                    var enddatepass = moment(EndDate).format("YYYY-MM-DD");
                    GetCustomReportData(startdatepass,enddatepass,CustomCategorySelectedId)
                    Setshowsdprogress(true)
                    SetCustomReportShow(true)

                }
               
            }


        }


    }

    const GetCustomReportData=async(datestart,dateend,category_id)=>{

        const response = await axiosJWT.get('add-data/custom-data?date_start=' + datestart + '&date_end=' + dateend + '&custom_cat_id=' + category_id + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]
            var CustomName=response.data.ResponseDataCat[0].custom_name
            var CustomUnit=response.data.ResponseDataCat[0].custom_unit
            var Name=CustomName+'('+CustomUnit+')'
            

            

            if (response.data.responseData != null) {
                

                SetCustomData(response.data)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.custom_value)
                    exports_data.push({"S.no":index,'Date & time':time,'Value':element.custom_value,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Custom-'+Name+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetRestingHeartRate = async (datestart, dateend, type) => {

   
        const response = await axiosJWT.get('add-data/vital/rest-heart-rate?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetRestingHeartRateData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.heart_rate)
                    exports_data.push({"S.no":index,'Date & time':time,'Heart Rate(bbm)':element.heart_rate,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vital-Resting Heart Rate'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetHeartRate = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/vital/heart-rate?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetHeartRateData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.heart_rate)
                    exports_data.push({"S.no":index,'Date & time':time,'Heart Rate(bbm)':element.heart_rate,'Tag':element.rate_type,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vital-Heart Rate'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetBodyTempData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/vital/body-temp?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetVitalBodyTempData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.temp_value)
                    exports_data.push({"S.no":index,'Date & time':time,'Body Temp(F)':element.temp_value,'Location':element.location,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vital-Body Temperature'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetVitalBloodOxygen = async (datestart, dateend, type) => {
        const response = await axiosJWT.get('add-data/vital/blood-oxy?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetVitalBloodOxyData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.oxygen_val)
                    exports_data.push({"S.no":index,'Date & time':time,'Oxygen(%)':element.oxygen_val,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vital-Blood oxygen'+Date.now())
                SetExportData(exports_data)
            }

        }
    }

    const GetVitalBasalTemperature = async (datestart, dateend, type) => {


        const response = await axiosJWT.get('add-data/vital/basal-temp?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetVitalBasalTempData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.temp_value)
                    exports_data.push({"S.no":index,'Date & time':time,'Temp(F)':element.temp_value,'Location':element.location,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vital-Basal Temperature'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetRestAsleep = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/time-asleep?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {

                SetRestTimeAsleepData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    // console.log(element)
                    var time = timetoUtcClientAppointment(element.start_date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.duration)
                    exports_data.push({"S.no":index,'Date & time':time,'Duration(hrs)':element.duration,'Ahi':element.ahi,'Notes':element.notes});


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-TIme asleep'+Date.now())
                SetExportData(exports_data)
            }

        }



    }


    const GetRestSauna = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/sauna?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestSaunaData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.duration)
                    exports_data.push({"S.no":index,'Date & time':time,'Duration(Mins)':element.duration,'Temp Changes':element.temp_change,'Weight changes':element.weight_change,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Sauna'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetRestPhotoBioModulation = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/photo-bio-modulation?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestPhotoBioData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.duration)
                    exports_data.push({"S.no":index,'Date & time':time,'Duration(Mins)':element.duration,'Pulse(hz)':element.pulse,'Protocol':element.protocol,'Notes':element.notes});
                 })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Photobiomodulation'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetRestMeditation = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/meditation?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestMeditationData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {   
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.meditation_time)
                    exports_data.push({"S.no":index,'Date & time':time,'Meditation Time(Mins)':element.meditation_time,'Meditation type':element.meditation_type,'Notes':element.notes});


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Meditation'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetRestIceBath = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/ice-bath?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestIceData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.session_length)
                    exports_data.push({"S.no":index,'Date & time':time,'Duration(Mins)':element.session_length,'Water Temp(deg)':element.water_temp,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Icebath'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetRestHyperBaricOxygen = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/hyperbaric-oxygen?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestHyperbaricData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.duration)
                    var oxygen;
                    if(element.oxygen===true)
                    {
                        oxygen='Yes'
                    }
                    else{
                        oxygen='No'
                    }
                    exports_data.push({"S.no":index,'Date & time':time,'Duration(Mins)':element.duration,'Pressure(PSI)':element.pressure,'Supplemental Oxygen':oxygen,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Hyperbaric Oxygen'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetRestHRVReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/hrv?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestHrvData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.hrv)
                    exports_data.push({ "S.no":index,'Date & time':time,'HRV(score)':element.hrv,'HR(bbm)':element.hr,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-HRV'+Date.now())
                SetExportData(exports_data)
            }

        }






    }


    const GetRestCryotherapyReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/cryotherapy?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestCryotherapyData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    // console.log(element)
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.duration)
                    var fasting;
                    if(element.fasting===true)
                    {
                        fasting='Yes';
                    }
                    else{
                        fasting='No'
                    }
                    exports_data.push({ "S.no":index,'Date & time':time,'Temperature(F)':element.temp_value,'Duration(Mins)':element.duration,'Notes':element.notes,'Fasting':fasting});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Cryotherapy'+Date.now())
                SetExportData(exports_data)
            }

        }

    }

    const GetRestAmReadinessReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/rest-recovery/am-readiness?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetRestAmReadinessData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.am_readiness)

                    exports_data.push({ "S.no":index,'Date & time':time,'Am readiness':element.am_readiness,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Rest & Recovery-Am readiness'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetNutritionMacroNutrient = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/nutrition-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetNutritionMacroNutrientData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,type:element.type,'Value(grams)':element.value,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Macronutrients'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetNutritionBristolScore = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/nutrition-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]
            if (response.data.responseData != null) {
                SetNutritionBristolData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Bristol Score':element.value,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Bristol Score'+Date.now())
                SetExportData(exports_data)
            }

        }


    }


    const GetMetaKetoBBCReportData = async (datestart, dateend, type) => {
      
        const response = await axiosJWT.get('add-data/metabolism/ketone-bbc?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
              var exports_data=[]

            if (response.data.responseData != null) {
                SetMetabolismKetoneBloodData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)

                    var obj={};
                    var s_no=index;
                    // console.log(element)
                    var eve_night;
                    var exercise=element.exercise;
                    var fasting;
                    var meal_timing=element.meal_timing;
                    var medication=element.medication;
                    var notes=element.notes;
                    var random_reading;
                    var wakeup;
                    if(element.wakeup===true)
                    {
                        wakeup='Yes'
                    }
                    else{
                        wakeup='No' 
                    }
                    if(element.random_reading===true)
                    {
                        random_reading='Yes'
                    }
                    else{
                        random_reading='No'
                    }
                    if(element.fasting===true)
                    {
                        fasting='Yes';
                    }
                    else{
                        fasting='No';
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes';
                    }
                    else{
                        eve_night='No';
                    }

                    if(type==='Metabolism Ketones Blood')
                    {
                        obj={
                            'S.no':s_no,
                            'Glucose(mg/dL)':element.value,
                            'Evening/Bedtime':eve_night,
                            'Exercise':exercise,
                            'Fasting':fasting,
                            'Meal timing':meal_timing,
                            'Medication/supplement':medication,
                            'Notes':notes,
                            'Random reading':random_reading,
                            'Wakeup':wakeup
                        }
                        
                        exports_data.push(obj);
                    }
                    else if(type==='Metabolism Ketones Breath')
                    {
                        obj={
                            'S.no':s_no,
                            'Breath(PPM)':element.value,
                            'Evening/Bedtime':eve_night,
                            'Exercise':exercise,
                            'Fasting':fasting,
                            'Meal timing':meal_timing,
                            'Medication/supplement':medication,
                            'Notes':notes,
                            'Random reading':random_reading,
                            'Wakeup':wakeup
                        }
                        exports_data.push(obj);
                    }
                    else if (type==='Metabolism Ketones Urine')
                    {
                        obj={
                            'S.no':s_no,
                            'Urine(value)':element.value,
                            'Evening/Bedtime':eve_night,
                            'Exercise':exercise,
                            'Fasting':fasting,
                            'Meal timing':meal_timing,
                            'Medication/supplement':medication,
                            'Notes':notes,
                            'Random reading':random_reading,
                            'Wakeup':wakeup
                        }
                        exports_data.push(obj);
                    }
            


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                if(type==='Metabolism Ketones Blood')
                {
                    SetExportFileName('Ketones Blood'+Date.now()) 
                }
                else if(type==='Metabolism Ketones Breath')
                {
                    SetExportFileName('Ketones Breath'+Date.now()) 
                }
                else if (type==='Metabolism Ketones Urine')
                {
                    SetExportFileName('Ketones Urine'+Date.now()) 
                }

                SetExportData(exports_data)
            }

        }
    }


    const GetMetaGlucoseKetomojoReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/metabolism/keto-mojo?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetMetabolismKetoMojoData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    // console.log(element)
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.glucose)

                    var obj={};
                    var s_no=index;
                    var eve_night;
                    var exercise=element.exercise;
                    var fasting;
                    var glucose=element.glucose;
                    var hematocrit=element.hematocrit;
                    var hemogloblin=element.hemogloblin;
                    var ketones=element.ketones;
                    var meal_timing=element.meal_timing;
                    var medication_timing=element.medication;
                    var notes=element.notes;
                    var random_reading;
                    var wakeup;
                    if(element.wakeup===true)
                    {
                        wakeup='Yes'
                    }
                    else{
                        wakeup='No'
                    }
                    if(element.random_reading===true)
                    {
                        random_reading='Yes';
                    }
                    else{
                        random_reading='No';
                    }


                    if(element.fasting===true)
                    {
                        fasting='Yes';
                    }
                    else{
                        fasting='No'
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes'
                    }
                    else{
                        eve_night='No'
                    }
                    
                    obj={
                        'S.no':s_no,
                        'Ketones(mmol/L)':ketones,
                        'glucose(mg/dL)':glucose,
                        'Evening/Bedtime':eve_night,
                        'Exercise':exercise,
                        'Fasting':fasting,
                        'Hematocrit(%)':hematocrit,
                        'Hemogloblin(g/dL)':hemogloblin,
                        'Meal timing':meal_timing,
                        'Medication/supplement':medication_timing,
                        'Notes':notes,
                        'Random Reading':random_reading,
                        'Wakeup':wakeup
                    }

                    exports_data.push(obj);

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Keto Mojo'+Date.now())
                SetExportData(exports_data)
            }

        }
    }

    const GetMetaGlucoseKetoneindexReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/metabolism/ketone-index?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetMetabolismKetoneIndexData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
               
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.ketone_index)

                    var obj={};
                    var s_no=index
                    var ketone_index=element.ketone_index;
                    var eve_night;
                    var exercise=element.exercise;
                    var fasting;
                    var meal_timing=element.meal_timing;
                    var medication_timing=element.medication;
                    var random_reading;
                    var wakeup;
                    var notes=element.notes
                    if(element.wakeup===true)
                    {
                        wakeup='Yes'
                    }
                    else{
                        wakeup='No'; 
                    }
                    if(element.random_reading===true)
                    {
                        random_reading='Yes';
                    }
                    else{
                        random_reading='No';
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes'; 
                    }
                    else{
                        eve_night='No'
                    }
                    if(element.fasting===true)
                    {
                        fasting='Yes';
                    }
                    else{
                        fasting='No';
                    }
                    obj={
                        'S.no':s_no,
                        'Keton Index':ketone_index,
                        'Evening/Bedtime':eve_night,
                        'Exercise':exercise,
                        'Fasting':fasting,
                        'Meal timing':meal_timing,
                        'Medication/supplement':medication_timing,
                        'Random reading':random_reading,
                        'Wake up':wakeup,
                        'Notes':notes
                    }


                    exports_data.push(obj);

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Ketone Index'+Date.now())
                SetExportData(exports_data)
             
            }

        }
    }

    const GetMetaGlucoseReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/metabolism/glucose?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetMetabolismGlucoseData(response.data.responseData)
                response.data.responseData.forEach((element,index)=> {
                    
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.glucose)

                    var obj={};
                    var eve_night;
                    var s_no=index
                    var exercise_timing=element.exercise;
                    var fasting;
                    var meal_timing=element.meal_timing
                    var medication_timing=element.medication;
                    var random_reading;
                    var wakeup;
                    var notes=element.notes;
                    if(element.wakeup===true)
                    {
                        wakeup='Yes' 
                    }
                    else{
                        wakeup='No'  
                    }
                    if(element.random_reading===true)
                    {
                        random_reading='Yes';
                    }
                    else{
                        random_reading='No';
                    }
                    if(element.fasting===true)
                    {
                        fasting='Yes';
                    }
                    else{
                        fasting='No';
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes';
                    }
                    else{
                        eve_night='No'
                    }

                    obj={
                        'S.no':s_no,
                        'Glucose value(mg/dL)':element.glucose,
                        'Exercise':exercise_timing,
                        'Fasting':fasting,
                        'Meal timing':meal_timing,
                        'Medication/supplement':medication_timing,
                        'Random reading':random_reading,
                        'Wake up':wakeup,
                        'Notes':notes,
                        
                    }
                 
                    exports_data.push(obj);


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Glucose'+Date.now())
                SetExportData(exports_data)
            }

        }
    }

    const GetMetaCarbToleranceReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/metabolism/carb-tolerance?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetMetabolismCarbToleranceData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    // console.log(element)
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Sugar readings value(mg/dL)':element.value,'Cognitive energy':element.cognitive_energy,'Physical energy':element.physical_energy,'Safety Duration':element.safety_duration,'Digestion':element.digestion,'Name of the test':element.test_name,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Carb tolerance test'+Date.now())
                SetExportData(exports_data)
            }

        }
    }

    const GetLifestyleHydrationReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/lifestyle-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetLifestyleHydrationData(response.data.responseData)

                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Hydration(oz)':element.value,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Lifestyle-Hydration'+Date.now())
                SetExportData(exports_data)
            }

        }

    }


    const GetLifestyleCaffineReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/lifestyle-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetLifestyleCaffineData(response.data.responseData)

                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Caffeine(mg)':element.value,'Notes':element.notes});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Lifestyle-Caffeine'+Date.now())
                SetExportData(exports_data)
            }

        }


    }

    const GetLifestyleAlcholReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/lifestyle-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetLifestyleAlcoholData(response.data.responseData)

                response.data.responseData.forEach((element,index)=> {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Alcohol Drinks':element.value,'Notes':element.notes});


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Lifestyle-Alcohol'+Date.now())
                SetExportData(exports_data)
            }

        }


    }



    const GetPhysicalReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/activity-data-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];

            if (response.data.responseData != null) {
                SetPhysicalActivityData(response.data.responseData)
                SetExportFileName('Physical Report'+Date.now())

                var exports_data=[]

                response.data.responseData.forEach((element,index) => {
          
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.precieved_effort)
                    exports_data.push({ "S.no":index,'Date & time':time,'Preceived Effort':element.precieved_effort,'Exercise type':element.exercise_type,'Session (mins)':element.session_mins});

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportData(exports_data)
            }

        }


    }

    const GetStepsActivityReportData = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/activity-data-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]
            if (response.data.responseData != null) {
                SetStepsActivityData(response.data.responseData)
                response.data.responseData.forEach((element ,index)=> {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.steps_count)
                    exports_data.push({ "S.no":index,'Date & time':time,'Steps Count':element.steps_count});


                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Steps Activity'+Date.now())
                SetExportData(exports_data)
            }
        }


    }

    const GetHematoCritReport = async (datestart, dateend, type) => {


        const response = await axiosJWT.get('add-data/blood-data-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]
            if (response.data.responseData != null) {

                SetBloodHematocritData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    // console.log(element)
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)

                    var obj={};
                    var s_no=index;
                    var Date_time=time;
                    var eve_night;
                    var exercise_time=element.exercise;
                    var fasting;
                    var meal_time=element.meal_timing;
                    var medication_time=element.medication;
                    var random_reading;
                    if(element.random_reading===true)
                    {
                        random_reading='Yes'
                    }
                    else{
                        random_reading='No'
                    }

                    if(element.fasting===true)
                    {
                        fasting='Yes'
                    }
                    else{
                        fasting='No'
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes'
                    }
                    else{
                        eve_night='No'
                    }

                    obj={
                        'S.No':s_no,
                        'Date & time':Date_time,
                        'Evening/Bedtime':eve_night,
                        'Exercise':exercise_time,
                        'Fasting':fasting,
                        'Meal Timing':meal_time,
                        'Medication/Supplement':medication_time,
                        'Random Reading':random_reading,
                        'Hematocrit(%)':element.value,
                        'Notes':element.notes,

                    }

                    exports_data.push(obj);

  
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Blood Hematocrit'+Date.now())
                SetExportData(exports_data)

            }
        }

    }


    const GetHemogloblinReport = async (datestart, dateend, type) => {


        const response = await axiosJWT.get('add-data/blood-data-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]
            if (response.data.responseData != null) {

                SetBloodHemogloblinData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    var obj={};
                    var s_no=index;
                    var Date_time=time;
                    var eve_night;
                    var exercise_time=element.exercise;
                    var fasting;
                    var meal_time=element.meal_timing;
                    var medication_time=element.medication;
                    var random_reading;
                    if(element.random_reading===true)
                    {
                        random_reading='Yes'
                    }
                    else{
                        random_reading='No'
                    }

                    if(element.fasting===true)
                    {
                        fasting='Yes'
                    }
                    else{
                        fasting='No'
                    }
                    if(element.eve_night===true)
                    {
                        eve_night='Yes'
                    }
                    else{
                        eve_night='No'
                    }

                    obj={
                        'S.No':s_no,
                        'Date & time':Date_time,
                        'Evening/Bedtime':eve_night,
                        'Exercise':exercise_time,
                        'Fasting':fasting,
                        'Meal Timing':meal_time,
                        'Medication/Supplement':medication_time,
                        'Random Reading':random_reading,
                        'Hemogloblin(g/dL)':element.value,
                        'Notes':element.notes,

                    }
                    exports_data.push(obj);

                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Blood Hemogloblin'+Date.now())
                SetExportData(exports_data)

            }
        }

    }

    const GetBodyFatreport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/body-comp-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetBodyCompBodyFatData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Body Fat(%)':element.value,'Type':element.fat_type,'Notes':element.notes});

            
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Body Fat'+Date.now())
                SetExportData(exports_data)

            }


        }

    }

    const GetBodyFatPoundsreport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/body-comp-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetBodyCompBodyFatPoundsData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Body Fat(pounds)':element.value,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Body Fat'+Date.now())
                SetExportData(exports_data)

            }


        }

    }

    const GetBodyLeanMassReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/body-comp-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetBodyCompLeanData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Lean Body Mass(pounds)':element.value,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Lean Body Mass'+Date.now())
                SetExportData(exports_data)

            }


        }

    }

    const GetBodyWeightReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/body-comp-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[]

            if (response.data.responseData != null) {
                SetBodyCompWeightData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Body Weight(pounds)':element.value,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Body Weight'+Date.now())
                SetExportData(exports_data)

            }


        }

    }

    const GetCardioPressureReport = async (datestart, dateend, type) => {

        const response = await axiosJWT.get('add-data/cardio-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[];

            if (response.data.responseData != null) {
                SetCardioPressureData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.heart_rate)
                    exports_data.push({ "S.no":index,'Date & time':time,'Heart Rate':element.heart_rate,'Systolic':element.systolic,'Diastolic':element.diastolic,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Cardio Pressure'+Date.now())
                SetExportData(exports_data)

            }


        }

    }
    const GetCardioVo2Report = async (datestart, dateend, type) => {
        const response = await axiosJWT.get('add-data/cardio-report?date_start=' + datestart + '&date_end=' + dateend + '&type=' + type + '&client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data != null) {
            var DataLabelPush = [];
            var DataValuePush = [];
            var exports_data=[];

            if (response.data.responseData != null) {
                SetCardioVo2MaxData(response.data.responseData)
                response.data.responseData.forEach((element,index) => {
                    var time = timetoUtcClientAppointment(element.date_time);
                    DataLabelPush.push(time)
                    DataValuePush.push(element.vo2_value)
                    exports_data.push({ "S.no":index,'Date & time':time,'Vo2':element.vo2_value,'Notes':element.notes});
                })
                SetDataLabel(DataLabelPush)
                SetDataValue(DataValuePush)
                SetExportFileName('Vo2 Max Pressure'+Date.now())
                SetExportData(exports_data)

            }


        }
    }




    function timetoUtcClientAppointment(passdate) {


        var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

        var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')
        return stardates



    }




    return (
        <div>
            <Modal show={props.showsdr} onHide={props.handleClosesdr} className="confirm_delete generate_food_diary_report_pop generate_data_report_pop">
                <Modal.Header closeButton>
                    <Modal.Title>Generate Data report</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="field_top_row">
                        <div className="label">Data Type</div>
                        <div className="field">
                            <select className="raw_select" onChange={(e) => { LoadPopup(e) }}>
                                <option value="">Select Data Type</option>
                                <option value="Physical Activity">Physical Activity</option>
                                <option value="Steps">Steps</option>
                                <option value="Hematocrit">Hematocrit(Daily)</option>
                                <option value="Hemoglobin">Hemoglobin(Daily)</option>
                                <option value="Body Fat">Body Fat</option>
                                <option value="Body Fat Pounds">Body Fat(Pounds)</option>
                                <option value="Lean Body Mass">Lean Body Mass</option>
                                <option value="Weight">Body Weight</option>
                                <option value="Cardio Blood Pressure">Cardiovascular-Blood Pressure</option>
                                <option value="Cardio Vo2 Max">Cardiovascular-Vo2 max</option>
                                <option value="Alcohol">Alcohol Intakes</option>
                                <option value="Caffine">Caffeine Intakes</option>
                                <option value="Hydration">Hydration</option>
                                <option value="Metabolism Carb Tolerance">Carb tolerance test</option>
                                <option value="Metabolism Glucose">Metabolism-Glucose</option>
                                <option value="Metabolism Ketone Index">Metabolism-Glucose:Ketone Index</option>
                                <option value="Metabolism Ketone Mojo">Metabolism-Keto:Mojo</option>
                                <option value="Metabolism Ketones Blood">Metabolism-Ketones Blood</option>
                                <option value="Metabolism Ketones Breath">Metabolism-Ketones Breath</option>
                                <option value="Metabolism Ketones Urine">Metabolism-Ketones Urine</option>
                                <option value="Nutrition Bristol Score">Bristol Score</option>
                                <option value="Nutrition Protein">Macronutrient-Protein</option>
                                <option value="Nutrition Fat">Macronutrient-Fat</option>
                                <option value="Nutrition Carbohydrates">Macronutrient-Carbohydrates</option>
                                <option value="Nutrition Calories">Macronutrient-Calories</option>
                                <option value="Nutrition Fiber">Macronutrient-Fiber</option>
                                <option value="Rest AM Readiness">Rest AM Readiness</option>
                                <option value="Rest Cryotherapy">Rest Cryotherapy</option>
                                <option value="Rest HRV">Rest HRV</option>
                                <option value="Rest Hyperbaric oxygen">Rest Hyperbaric oxygen</option>
                                <option value="Rest Icebath">Rest Icebath</option>
                                <option value="Rest Meditation">Rest Meditation</option>
                                <option value="Rest PhotoBioModulation">Rest Photo Bio Modulation</option>
                                <option value="Rest Sauna">Rest Sauna</option>
                                <option value="Rest Asleep">Rest Asleep</option>
                                <option value="Vital Basal Temperature">Vital Basal Temperature</option>
                                <option value="Vital Blood Oxygen">Vital Blood Oxygen</option>
                                <option value="Vital Body Temperature">Vital Body Temperature</option>
                                <option value="Vital Heart Rate">Vital Heart Rate</option>
                                <option value="Vital Rest Heart Rate">Vital Resting Heart Rate</option>

                                {CustomCatList.length > 0 && CustomCatList.map((list, index) => (
                                    <option value={"custom_data" + list.id + ""} data-custom_id={list.id}>{list.custom_name}</option>
                                ))}




                            </select>
                        </div>
                    </div>
                    {
                        ShowFromToDate === true &&

                        <div className="from_date_div_top_row">
                            <div className="from_date_div">
                                <label className='commission_inner_popup_label'>From date<span style={{ color: 'red' }}>*</span></label>
                                <span className="calendar_icon_div fif_change_report">
                                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                                </span>
                                <span className="report_date">
                                    <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} onChange={(date) => ChangedDate(date)} ref={datepicker1Ref} />
                                </span>

                            </div>

                            <div className="to_date_div">
                                <label className='commission_inner_popup_label'>To date<span style={{ color: 'red' }}>*</span></label>
                                <span className="calendar_icon_div fif_change_report to_fif_change">
                                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon2()} aria-hidden="true"></i>
                                </span>
                                <span className="report_date">
                                    <DatePicker selected={EndDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} onChange={(date) => ChangedEndDate(date)} ref={datepicker2Ref} />
                                </span>
                            </div>

                        </div>
                    }
                    {
                        ShowFromDate === true &&
                        <div className="from_date_div_top_row">

                            <div className="from_date_div">
                                <label className='commission_inner_popup_label'>Pick date<span style={{ color: 'red' }}>*</span></label>
                                <span className="calendar_icon_div fif_change_report">
                                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                                </span>
                                <span className="report_date">
                                    <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} onChange={(date) => ChangedDate(date)} ref={datepicker1Ref} />
                                </span>

                            </div>

                        </div>

                    }
                    {showerr === true &&
                        <span style={{ color: 'red' }}>Please select valid date</span>}
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button className="modal_close_button" variant="secondary" onClick={props.handleClosesdr}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="modal_action_button bootstrp_color_btn" onClick={GenerateDataReport}>
                        Generate
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showsdprogress} onHide={handleClosesdprogress} className="confirm_delete generate_food_diary_report_pop generate_food_diary_report_pop_pdf remove_modal_width">
                <Modal.Header closeButton>
                    <Modal.Title className="top_label_title_report">Data Report</Modal.Title>
                </Modal.Header>
                <Modal.Body className="progress_diary_export_div" ref={componentRef}>

                    <PersonalInfoData profileData={profileData} />
                    {
                        PhysicalActivityShow === true &&
                        <PhysicalActivityreport DataLabel={DataLabel} DataValue={DataValue} PhysicalActivityData={PhysicalActivityData} />
                    }
                    {
                        StepsActivityShow === true &&
                        <StepsActivityreport DataLabel={DataLabel} DataValue={DataValue} StepsActivityData={StepsActivityData} />
                    }
                    {
                        BloodHematocritshow === true &&
                        <BloodHematocritReport DataLabel={DataLabel} DataValue={DataValue} BloodHematocritData={BloodHematocritData} />
                    }
                    {
                        BloodHemogloblinshow === true &&
                        <BloodHemogloblinReport DataLabel={DataLabel} DataValue={DataValue} BloodHemogloblinData={BloodHemogloblinData} />
                    }
                    {
                        BodyCompBodyFatShow === true &&
                        <BodyCompFatReport DataLabel={DataLabel} DataValue={DataValue} BodyCompBodyFatData={BodyCompBodyFatData} />
                    }
                    {
                        BodyCompBodyFatPoundsShow === true &&
                        <BodyCompFatPoundsReport DataLabel={DataLabel} DataValue={DataValue} BodyCompBodyFatPoundsData={BodyCompBodyFatPoundsData} />
                    }
                    {
                        BodyCompLeanShow === true &&
                        <BodyCompLeanMass DataLabel={DataLabel} DataValue={DataValue} BodyCompLeanData={BodyCompLeanData} />
                    }
                    {
                        BodyCompWeightShow === true &&
                        <BodyCompWeight DataLabel={DataLabel} DataValue={DataValue} BodyCompWeightData={BodyCompWeightData} />
                    }
                    {
                        CardioPressureShow === true &&
                        <CardioPressure DataLabel={DataLabel} DataValue={DataValue} CardioPressureData={CardioPressureData} />
                    }
                    {
                        CardioVo2Show === true &&
                        <Cardiov2Max DataLabel={DataLabel} DataValue={DataValue} CardioVo2MaxData={CardioVo2MaxData} />
                    }
                    {
                        LifestyleAlcoholShow === true &&
                        <LifestyleAlcoholIntakes DataLabel={DataLabel} DataValue={DataValue} LifestyleAlcoholData={LifestyleAlcoholData} />
                    }
                    {
                        LifestyleCaffineShow === true &&
                        <LifestyleCaffineIntakes DataLabel={DataLabel} DataValue={DataValue} LifestyleCaffineData={LifestyleCaffineData} />
                    }
                    {
                        LifestyleHydrationShow === true &&
                        <LifestyleHydration DataLabel={DataLabel} DataValue={DataValue} LifestyleHydrationData={LifestyleHydrationData} />
                    }
                    {
                        MetabolismCarbToleranceShow === true &&
                        <MetabolismCarbTolerance DataLabel={DataLabel} DataValue={DataValue} MetabolismCarbToleranceData={MetabolismCarbToleranceData} />
                    }
                    {
                        MetabolismGlucoseShow === true &&
                        <MetabolismGlucose DataLabel={DataLabel} DataValue={DataValue} MetabolismGlucoseData={MetabolismGlucoseData} />
                    }
                    {
                        MetabolismKetoneIndexShow === true &&
                        <MetabolismKetoneIndex DataLabel={DataLabel} DataValue={DataValue} MetabolismKetoneIndexData={MetabolismKetoneIndexData} />
                    }
                    {
                        MetabolismKetoMojoShow === true &&
                        <MetabolismKetoMojo DataLabel={DataLabel} DataValue={DataValue} MetabolismKetoMojoData={MetabolismKetoMojoData} />
                    }
                    {
                        MetabolismKetoneBloodShow === true &&
                        <MetabolismKetoneBlood DataLabel={DataLabel} DataValue={DataValue} MetabolismKetoneBloodData={MetabolismKetoneBloodData} />
                    }
                    {
                        MetabolismKetoneBreatheShow === true &&
                        <MetabolismKetoneBreath DataLabel={DataLabel} DataValue={DataValue} MetabolismKetoneBloodData={MetabolismKetoneBloodData} />
                    }
                    {
                        MetabolismKetoneUrineShow === true &&
                        <MetabolismKetoneUrine DataLabel={DataLabel} DataValue={DataValue} MetabolismKetoneBloodData={MetabolismKetoneBloodData} />
                    }
                    {
                        NutritionBristolShow === true &&
                        <NutritionBristolScore DataLabel={DataLabel} DataValue={DataValue} NutritionBristolData={NutritionBristolData} />
                    }

                    {
                        NutritionMacroNutrientShow === true &&
                        <NutritionMacroNutrient DataLabel={DataLabel} DataValue={DataValue} NutritionMacroNutrientData={NutritionMacroNutrientData} NutritionMacroNutrientLabel={NutritionMacroNutrientLabel} />
                    }
                    {
                        RestAmReadinessShow === true &&
                        <RestAmReadiness DataLabel={DataLabel} DataValue={DataValue} RestAmReadinessData={RestAmReadinessData} />
                    }
                    {
                        RestCryotherapyShow === true &&
                        <RestCryoTherapy DataLabel={DataLabel} DataValue={DataValue} RestCryotherapyData={RestCryotherapyData} />
                    }
                    {
                        RestHrvShow === true &&
                        <RestHrv DataLabel={DataLabel} DataValue={DataValue} RestHrvData={RestHrvData} />
                    }
                    {
                        RestHyperbaricShow === true &&
                        <RestHyperBaricOxygen DataLabel={DataLabel} DataValue={DataValue} RestHyperbaricData={RestHyperbaricData} />
                    }
                    {
                        RestIceShow === true &&
                        <RestIceBath DataLabel={DataLabel} DataValue={DataValue} RestIceData={RestIceData} />
                    }
                    {
                        RestMeditationShow === true &&
                        <RestMeditation DataLabel={DataLabel} DataValue={DataValue} RestMeditationData={RestMeditationData} />
                    }
                    {
                        RestPhotoBioShow === true &&
                        <RestPhotoBioModulation DataLabel={DataLabel} DataValue={DataValue} RestPhotoBioData={RestPhotoBioData} />
                    }
                    {
                        RestSaunaShow === true &&
                        <RestSauna DataLabel={DataLabel} DataValue={DataValue} RestSaunaData={RestSaunaData} />
                    }
                    {
                        RestTimeAsleepShow === true &&
                        <RestAsleep DataLabel={DataLabel} DataValue={DataValue} RestTimeAsleepData={RestTimeAsleepData} />
                    }
                    {
                        VitalBasalTempShow === true &&
                        <VitalBasalTemp DataLabel={DataLabel} DataValue={DataValue} VitalBasalTempData={VitalBasalTempData} />
                    }
                    {
                        VitalBloodOxyShow === true &&
                        <VitalBloodOxy DataLabel={DataLabel} DataValue={DataValue} VitalBloodOxyData={VitalBloodOxyData} />
                    }
                    {
                        VitalBodyTempShow === true &&
                        <VitalBodyTemp DataLabel={DataLabel} DataValue={DataValue} VitalBodyTempData={VitalBodyTempData} />
                    }
                    {
                        VitalHeartRateShow === true &&
                        <VitalHeartrate DataLabel={DataLabel} DataValue={DataValue} HeartRateData={HeartRateData} />
                    }
                    {
                        VitalRestingHeartRateShow === true &&
                        <VitalRestingHeartrate DataLabel={DataLabel} DataValue={DataValue} RestingHeartRateData={RestingHeartRateData} />
                    }
                    {
                        CustomReportShow===true &&
                          <CustomDataReport DataLabel={DataLabel} DataValue={DataValue}  CustomData={CustomData} />
                    }

                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleClosesdprogress}>
                        Cancel
                    </Button>

                    <ReactToPrint documentTitle={DocumentTitleValue}
                        trigger={() => <Button variant="primary" className="bootstrp_color_btn">
                            Export PDF
                        </Button>}
                        content={() => componentRef.current}
                    />
                          <CSVLink data={ExportData} filename={ExportFileName}><button data-modal="export_payout" className="bootstrp_color_btn btn btn-primary">Export as CSV</button></CSVLink>
                </Modal.Footer>
            </Modal>
        </div>
    )

}
export default GenerateAddData
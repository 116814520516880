import React, { useEffect, useState } from "react";

const AllergiesCM = (props) => {
 
  const [Allergies, SetAllergies] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.AllergiesPC) {
        SetAllergies(props.AllergiesPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetAllergiesPC(Allergies);
   

      var Err = 0;
      if (Allergies === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [Allergies]);

  useEffect(()=>{

   
    props.SetPreviousMenu('currentmedicalconditions')
  
    props.SetNextMenu('selfreportedmeds')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"><h6>{props.AllergiesPCLabel}</h6>       <span style={{color:'red'}}>*</span></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={Allergies}
              value={Allergies}
              onChange={(e) => {
                SetAllergies(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default AllergiesCM;

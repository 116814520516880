import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Datepicker from 'react-date-picker';
import axios from "axios";
import TimePicker from 'react-time-picker';
import PhysicalActivity from "./PhysicalActivty";
import StepsActivity from "./StepsActivity";
import BloodHematocrit from './BloodHematocrit'
import BloodHemoglobin from "./BloodHemoglobin";
import BodyFatType from "./BodyFatType";
import BodyFatPounds from "./BodyFatPounds";
import BodyLeanPounds from "./BodyLeanPounds";
import BodyWeightPounds from "./BodyWeightPounds";
import CardioPressure from "./CardioPressure";
import CardioVo2 from "./CardioVo";
import LifeAlcohol from "./LifeAlcohol";
import LifeCaffine from "./LifeCaffine";
import LifeHydration from "./LifeHydration";
import LifeJournal from "./LifeJournal";
import NutritionBristol from "./NutritionBristol";
import NutritionMacro from "./NutritionMacro";
import MetabolismCarb from "./MetabolismCarb";
import MetabolismFasting from "./MetabolismFasting";
import MetabolismGlucose from "./MetabolismGlucose";
import MetabolismKetoneBlood from "./MetabolismKetoneBlood";
import MetabolismKetoneBreathe from "./MetabolismKetoneBreathe";
import MetabolismKetoneUrine from "./MetabolismKetoneUrine";
import MetabolismKetoneIndex from "./MetabolismKetoneIndex";
import MetabolismKetoneMojo from "./MetabolismKetoMojo";
import MetabolismQuitEvent from "./MetabolismQuitEvent";
import RestAmReadiness from "./RestAmReadiness";
import RestCryotherapy from "./RestCryotherapy";
import RestHRV from "./RestHRV";
import RestHRVSleep from "./RestHRVSleep";
import RestHyperBaricOxygen from "./RestHyperBaricOxygen";
import RestIceBath from "./RestIceBath";
import RestMeditation from "./RestMeditation";
import RestPhotoBioModulation from "./RestPhotoBioModulation";
import RestSauna from "./RestSauna";
import RestAsleep from "./RestAsleep";
import VitalBasalTemp from "./VitalBasalTemp";
import VitalBloodOxygen from "./VitalBloodOxygen";
import VitalBodyTemp from "./VItalBodyTemp";
import VitalHeartRate from "./VitalHeartRate";
import VitalRestHeartRate from "./VitalRestHeartRate";
import CustomCatAdd from "./CustomCatAdd";
import CustomValAdd from "./CustomValAdd";
import GenerateAddData from "../Report/GenerateAddData";

const AddData = (props) => {

  //console.log(props)

    const Closefunc = () => {
        props.SetshowAddData(false)
       
    }

    useEffect(()=>{
        SetactivityOption(false)
        SetBloodDailyOption(false)
        SetBodyCompOption(false)
        SetCardioOption(false)
        SetLifeStyleOption(false)
        SetNutritionOption(false)
        SetMetabolismOption(false)
        SetRestOption(false)
        SetVitalOption(false)
        SetCustomOption(false)
        SetShowPhysicalActivityField(false)
        SetShowStepsActivityField(false)
        SetShowBloodHemaField(false)
        SetShowBloodHemoField(false)
        SetShowBodyCompFatField(false)
        SetShowBodyCompFatPoundsField(false)
        SetShowBodyCompLeanPoundsField(false)
        SetShowBodyCompWeightPoundsField(false)
        SetShowCardioBloodPressureField(false)
        SetShowCardioVoField(false)
        SetShowLifeAlcoholField(false)
        SetShowLifeCaffineField(false)
        SetShowLifeHydrationField(false)
        SetShowLifeJournalField(false)
        SetShowNutritionBristolField(false)
        SetShowNutritionMacroField(false)
        SetShowMetaCarbField(false)
        SetShowMetaFastingField(false)
        SetShowMetaGlucoseField(false)
        SetShowMetaGlucoseIndexField(false)
        SetShowMetaKetoMojoField(false)
        SetShowMetaKetoBloodField(false)
        SetShowMetaKetoBreathField(false)
        SetShowMetaKetoUrineField(false)
        SetShowMetaQuitField(false)
        SetShowRestAMField(false)
        SetShowRestCryoField(false)
        SetShowRestHRVField(false)
        SetShowRestHRVSleepField(false)
        SetShowRestHyperbaricField(false)
        SetShowRestIceField(false)
        SetShowRestMeditationField(false)
        SetShowRestPhotoBioField(false)
        SetShowRestSaunaField(false)
        SetShowRestTimeAsleepField(false)
        SetShowVitalBasalTempfield(false)
        SetShowVitalBloodOxyfield(false)
        SetShowVitalBodyTempfield(false)
        SetShowVitalHeartRatefield(false)
        SetShowVitalRestHeartRatefield(false)
        SetShowCustomCatfield(false)
        SetShowCustomValfield(false)
        SetIsClientid(props.client_id)
        SetClientProfileData(props.ClientProfileData)
        SetClientCustomCategoryList(props.ClientCustomCategoryList)

    },[props.ClientProfileData,props.client_id,props.ClientCustomCategoryList])
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const axiosJWT = axios.create();
    const [IsClientid,SetIsClientid]=useState('')
    const [ClientProfileData,SetClientProfileData]=useState([])
    const [ClientCustomCategoryList,SetClientCustomCategoryList]=useState([])

    const [activityOption, SetactivityOption] = useState(false)
    const [BloodDailyOption, SetBloodDailyOption] = useState(false)
    const [BodyCompOption, SetBodyCompOption] = useState(false)
    const [CardioOption, SetCardioOption] = useState(false)
    const [LifeStyleOption, SetLifeStyleOption] = useState(false)
    const [NutritionOption, SetNutritionOption] = useState(false)
    const [MetabolismOption, SetMetabolismOption] = useState(false)
    const [RestOption, SetRestOption] = useState(false)
    const [VitalOption, SetVitalOption] = useState(false)
    const [CustomOption, SetCustomOption] = useState(false)

    const [showsdr,Setshowsdr]=useState(false)
    const [PopShow,SetPopShow]=useState(false)
    const handleClosesdr=()=>{
        Setshowsdr(false)
        SetPopShow(false)
    }
    const triggerReport=()=>{
        props.SetshowAddData(false)
        Setshowsdr(true)   
    }



    const [ShowPhysicalActivityField, SetShowPhysicalActivityField] = useState(false)
    const [ShowStepsActivityField, SetShowStepsActivityField] = useState(false)
    const [ShowBloodHemaField, SetShowBloodHemaField] = useState(false)
    const [ShowBloodHemoField, SetShowBloodHemoField] = useState(false)
    const [ShowBodyCompFatField, SetShowBodyCompFatField] = useState(false)
    const [ShowBodyCompFatPoundsField, SetShowBodyCompFatPoundsField] = useState(false)
    const [ShowBodyCompLeanPoundsField, SetShowBodyCompLeanPoundsField] = useState(false)
    const [ShowBodyCompWeightPoundsField, SetShowBodyCompWeightPoundsField] = useState(false)
    const [ShowCardioBloodPressureField, SetShowCardioBloodPressureField] = useState(false)
    const [ShowCardioVoField, SetShowCardioVoField] = useState(false)
    const [ShowLifeAlcoholField, SetShowLifeAlcoholField] = useState(false)
    const [ShowLifeCaffineField, SetShowLifeCaffineField] = useState(false)
    const [ShowLifeHydrationField, SetShowLifeHydrationField] = useState(false)
    const [ShowLifeJournalField, SetShowLifeJournalField] = useState(false)
    const [ShowNutritionBristolField, SetShowNutritionBristolField] = useState(false)
    const [ShowNutritionMacroField, SetShowNutritionMacroField] = useState(false)
    const [ShowMetaCarbField, SetShowMetaCarbField] = useState(false)
    const [ShowMetaFastingField, SetShowMetaFastingField] = useState(false)
    const [ShowMetaGlucoseField, SetShowMetaGlucoseField] = useState(false)
    const [ShowMetaGlucoseIndexField, SetShowMetaGlucoseIndexField] = useState(false)
    const [ShowMetaKetoMojoField, SetShowMetaKetoMojoField] = useState(false)
    const [ShowMetaKetoBloodField, SetShowMetaKetoBloodField] = useState(false)
    const [ShowMetaKetoBreathField, SetShowMetaKetoBreathField] = useState(false)
    const [ShowMetaKetoUrineField, SetShowMetaKetoUrineField] = useState(false)
    const [ShowMetaQuitField, SetShowMetaQuitField] = useState(false)
    const [ShowRestAMField, SetShowRestAMField] = useState(false)
    const [ShowRestCryoField, SetShowRestCryoField] = useState(false)
    const [ShowRestHRVField, SetShowRestHRVField] = useState(false)
    const [ShowRestHRVSleepfield, SetShowRestHRVSleepField] = useState(false)
    const [ShowRestHyperbaricfield, SetShowRestHyperbaricField] = useState(false)
    const [ShowRestIcefield, SetShowRestIceField] = useState(false)
    const [ShowRestMeditationfield, SetShowRestMeditationField] = useState(false)
    const [ShowRestPhotoBiofield, SetShowRestPhotoBioField] = useState(false)
    const [ShowRestSaunafield, SetShowRestSaunaField] = useState(false)
    const [ShowRestTimeAsleepfield, SetShowRestTimeAsleepField] = useState(false)
    const [ShowVitalBasalTempfield, SetShowVitalBasalTempfield] = useState(false)
    const [ShowVitalBloodOxyfield, SetShowVitalBloodOxyfield] = useState(false)
    const [ShowVitalBodyTempfield, SetShowVitalBodyTempfield] = useState(false)
    const [ShowVitalHeartRatefield, SetShowVitalHeartRatefield] = useState(false)
    const [ShowVitalRestHeartRatefield, SetShowVitalRestHeartRatefield] = useState(false)
    const [ShowCustomCatfield, SetShowCustomCatfield] = useState(false)
    const [ShowCustomValfield,SetShowCustomValfield]=useState(false)
    


    const triggerCatPop = (e) => {

    



        const alltabs = document.querySelectorAll(".list_row_add_data p");
        alltabs.forEach((element) => element.classList.remove('active_button'));
        e.target.closest('.add_data_button').setAttribute("class", "add_data_button active_button");
        SetactivityOption(false)
        SetBloodDailyOption(false)
        SetBodyCompOption(false)
        SetCardioOption(false)
        SetLifeStyleOption(false)
        SetNutritionOption(false)
        SetMetabolismOption(false)
        SetRestOption(false)
        SetVitalOption(false)
        SetCustomOption(false)

        SetShowPhysicalActivityField(false)
        SetShowStepsActivityField(false)
        SetShowBloodHemaField(false)
        SetShowBloodHemoField(false)
        SetShowBodyCompFatField(false)
        SetShowBodyCompFatPoundsField(false)
        SetShowBodyCompLeanPoundsField(false)
        SetShowBodyCompWeightPoundsField(false)
        SetShowCardioBloodPressureField(false)
        SetShowCardioVoField(false)
        SetShowLifeAlcoholField(false)
        SetShowLifeCaffineField(false)
        SetShowLifeHydrationField(false)
        SetShowLifeJournalField(false)
        SetShowNutritionBristolField(false)
        SetShowNutritionMacroField(false)
        SetShowMetaCarbField(false)
        SetShowMetaFastingField(false)
        SetShowMetaGlucoseField(false)
        SetShowMetaGlucoseIndexField(false)
        SetShowMetaKetoMojoField(false)
        SetShowMetaKetoBloodField(false)
        SetShowMetaKetoBreathField(false)
        SetShowMetaKetoUrineField(false)
        SetShowMetaQuitField(false)
        SetShowRestAMField(false)
        SetShowRestCryoField(false)
        SetShowRestHRVField(false)
        SetShowRestHRVSleepField(false)
        SetShowRestHyperbaricField(false)
        SetShowRestIceField(false)
        SetShowRestMeditationField(false)
        SetShowRestPhotoBioField(false)
        SetShowRestSaunaField(false)
        SetShowRestTimeAsleepField(false)
        SetShowVitalBasalTempfield(false)
        SetShowVitalBloodOxyfield(false)
        SetShowVitalBodyTempfield(false)
        SetShowVitalHeartRatefield(false)
        SetShowVitalRestHeartRatefield(false)
        SetShowCustomCatfield(false)
        SetShowCustomValfield(false)

        const category = e.target.closest('.add_data_button').getAttribute('data-category')

        if (category === 'activity') {
            SetactivityOption(true)


        }
        if (category === 'blood_dialy') {
            SetBloodDailyOption(true)

        }
        if (category === 'body_composition') {
            SetBodyCompOption(true)

        }
        if (category === 'cardiovascular') {
            SetCardioOption(true)

        }
        if (category === 'lifestyle') {
            SetLifeStyleOption(true)

        }
        if (category === 'nutrition') {
            SetNutritionOption(true)

        }
        if (category === 'metabolism') {
            SetMetabolismOption(true)
        }
        if (category === 'rest_recovery') {
            SetRestOption(true)
        }
        if (category === 'vitals') {
            SetVitalOption(true)
        }
        if (category === 'custom') {
            SetCustomOption(true)
            GetMyCustomCategoryList()
        }





    }



    const triggerInnerFieldPop = (e) => {
        const category = e.target.closest('.add_data_button').getAttribute('data-category')
        if (category === 'activity_physical_activity') {
            SetactivityOption(false)
            SetShowPhysicalActivityField(true)
        }
        if (category === 'activity_steps') {
            SetactivityOption(false)
            SetShowStepsActivityField(true)
        }
        if (category === 'blood_Hematocrit') {
            SetBloodDailyOption(false)
            SetShowBloodHemaField(true)
        }
        if (category === 'blood_Hemoglobin') {
            SetBloodDailyOption(false)
            SetShowBloodHemoField(true)
        }
        if (category === 'body_comp_fat') {
            SetBodyCompOption(false)
            SetShowBodyCompFatField(true)
        }
        if (category === 'body_comp_fat_pounds') {
            SetBodyCompOption(false)
            SetShowBodyCompFatPoundsField(true)
        }
        if (category === 'body_comp_lean_pounds') {
            SetBodyCompOption(false)
            SetShowBodyCompLeanPoundsField(true)
        }
        if (category === 'body_comp_weight_pounds') {
            SetBodyCompOption(false)
            SetShowBodyCompWeightPoundsField(true)
        }
        if (category === 'cardio_pressure') {
            SetCardioOption(false)
            SetShowCardioBloodPressureField(true)
        }
        if (category === 'cardio_vo') {
            SetCardioOption(false)
            SetShowCardioVoField(true)
        }
        if (category === 'life_alcohol') {
            SetLifeStyleOption(false)
            SetShowLifeAlcoholField(true)
        }
        if (category === 'life_caffine') {
            SetLifeStyleOption(false)
            SetShowLifeCaffineField(true)
        }
        if (category === 'life_hydration') {
            SetLifeStyleOption(false)
            SetShowLifeHydrationField(true)
        }
        if (category === 'life_journal') {
            SetLifeStyleOption(false)
            SetShowLifeJournalField(true)
        }
        if (category === 'nutrition_bristol') {
            SetNutritionOption(false)
            SetShowNutritionBristolField(true)
        }
        if (category === 'nutrition_macro') {
            SetNutritionOption(false)
            SetShowNutritionMacroField(true)
        }
        if (category === 'meta_carb') {
            SetMetabolismOption(false)
            SetShowMetaCarbField(true)
        }
        if (category === 'meta_fasting') {
            SetMetabolismOption(false)
            SetShowMetaFastingField(true)
        }
        if (category === 'meta_glucose') {
            SetMetabolismOption(false)
            SetShowMetaGlucoseField(true)
        }
        if (category === 'meta_glucose_index') {
            SetMetabolismOption(false)
            SetShowMetaGlucoseIndexField(true)
        }
        if (category === 'meta_keto_mojo') {
            SetMetabolismOption(false)
            SetShowMetaKetoMojoField(true)
        }
        if (category === 'meta_keto_blood') {
            SetMetabolismOption(false)
            SetShowMetaKetoBloodField(true)
        }
        if (category === 'meta_keto_breathe') {
            SetMetabolismOption(false)
            SetShowMetaKetoBreathField(true)
        }
        if (category === 'meta_keto_urine') {
            SetMetabolismOption(false)
            SetShowMetaKetoUrineField(true)
        }
        if (category === 'meta_quit_events') {
            SetMetabolismOption(false)
            SetShowMetaQuitField(true)
        }
        if (category === 'rest_am_readiness') {
            SetRestOption(false)
            SetShowRestAMField(true)
        }
        if (category === 'rest_cryotherapy') {
            SetRestOption(false)
            SetShowRestCryoField(true)

        }
        if (category === 'rest_hrv') {
            SetRestOption(false)
            SetShowRestHRVField(true)
        }
        if (category === 'rest_hrv_sleep') {
            SetRestOption(false)
            SetShowRestHRVSleepField(true)
        }
        if (category === 'rest_hyperbaric') {
            SetRestOption(false)
            SetShowRestHyperbaricField(true)
        }
        if (category === 'rest_ice_bath') {
            SetRestOption(false)
            SetShowRestIceField(true)
        }
        if (category === 'rest_meditation') {
            SetRestOption(false)
            SetShowRestMeditationField(true)
        }
        if (category === 'rest_photo_bio') {
            SetRestOption(false)
            SetShowRestPhotoBioField(true)
        }
        if (category === 'rest_sauna') {
            SetRestOption(false)
            SetShowRestSaunaField(true)
        }
        if (category === 'rest_time_asleep') {
            SetRestOption(false)
            SetShowRestTimeAsleepField(true)
        }
        if (category === 'vital_basal_temp') {
            SetVitalOption(false)
            SetShowVitalBasalTempfield(true)
        }
        if (category === 'vital_blood_oxy') {
            SetVitalOption(false)
            SetShowVitalBloodOxyfield(true)
        }
        if (category === 'vital_body_temp') {
            SetVitalOption(false)
            SetShowVitalBodyTempfield(true)
        }
        if (category === 'vital_heart_rate') {
            SetVitalOption(false)
            SetShowVitalHeartRatefield(true)
        }
        if (category === 'vital_rest_heart_rate') {
            SetVitalOption(false)
            SetShowVitalRestHeartRatefield(true)
        }
        if (category === 'custom_add_new') {
            SetCustomOption(false)
            SetShowCustomCatfield(true)

        }
        if(category==='custom_add_value')
        {
            const customcatName = e.target.closest('.add_data_button').getAttribute('data-cat')
            const customcatId = e.target.closest('.add_data_button').getAttribute('data-id')
            const customcatUnit = e.target.closest('.add_data_button').getAttribute('data-unit')
            SetCustomOption(false)
            SetShowCustomValfield(true)
            SetSelectedCustomName(customcatName)
            SetSelectedCustomId(customcatId)
            SetSelectedCustomUnit(customcatUnit)

           
        }



    }
    const [MyCustomCategoryList, SetMyCustomCategoryList] = useState([])
    const [SelectedCustomName,SetSelectedCustomName]=useState('')
    const [SelectedCustomId,SetSelectedCustomId]=useState('')
    const [SelectedCustomUnit,SetSelectedCustomUnit]=useState('')
    const GetMyCustomCategoryList = async () => {

        const response = await axiosJWT.get('/add-data/get-custom-category?client_id='+IsClientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response) {
            SetMyCustomCategoryList(response.data.cat_list)
        }


    }


    return (
        <div>


            <Modal show={props.open} onHide={Closefunc} className='coach_blog_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>{'Add Data'}</Modal.Title>
              
                </Modal.Header>
                {
                   role==='Nutrition' &&
                   <div className="generate_report_button_div">
                <button className="generate_report_button" onClick={triggerReport}>Generate report</button>
                </div>

                }
                
            
                <Modal.Body className="add_data_popup">
          
                    <div className="list_row_add_data">
                        <p className="add_data_button" data-category={'activity'} onClick={(e) => { triggerCatPop(e) }}><span >Activity</span><i className="fa fa-angle-right"></i></p>

                        <p className="add_data_button" data-category={'blood_dialy'} onClick={(e) => { triggerCatPop(e) }}><span >Blood (Daily)</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'body_composition'} onClick={(e) => { triggerCatPop(e) }}><span >Body composition</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'cardiovascular'} onClick={(e) => { triggerCatPop(e) }}><span >Cardiovascular</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'lifestyle'} onClick={(e) => { triggerCatPop(e) }}><span >Lifestyle</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'metabolism'} onClick={(e) => { triggerCatPop(e) }}><span >Metabolism</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'nutrition'} onClick={(e) => { triggerCatPop(e) }}><span >Nutrition</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'rest_recovery'} onClick={(e) => { triggerCatPop(e) }}><span >Rest & Recovery</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'vitals'} onClick={(e) => { triggerCatPop(e) }}><span >Vitals</span><i className="fa fa-angle-right"></i></p>
                        <p className="add_data_button" data-category={'custom'} onClick={(e) => { triggerCatPop(e) }}><span >Custom</span><i className="fa fa-angle-right"></i></p>
                    </div>

                    <div className="second_tab_add_data">


                        {
                            activityOption === true &&
                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'activity_physical_activity'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Physical activity</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'activity_steps'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Steps</span></p>
                            </div>
                        }

                        {
                            BloodDailyOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'blood_Hematocrit'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Hematocrit (Daily)</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'blood_Hemoglobin'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Hemoglobin (Daily)</span></p>
                            </div>

                        }
                        {
                            BodyCompOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'body_comp_fat'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Body Fat</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'body_comp_fat_pounds'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Body Fat (Pounds)</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'body_comp_lean_pounds'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Lean Body Mass (Pounds)</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'body_comp_weight_pounds'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Weight (Pounds)</span></p>
                            </div>

                        }

                        {
                            CardioOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'cardio_pressure'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Blood Pressure</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'cardio_vo'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Vo2 Max</span></p>

                            </div>

                        }

                        {
                            LifeStyleOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'life_alcohol'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Alcoholic Intakes</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'life_caffine'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Caffeine Intakes</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'life_hydration'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Hydration</span></p>
                                {/* <p className="add_data_button choose_type_tab_option" data-category={'life_journal'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Journal Entry</span></p> */}

                            </div>

                        }

                        {
                            NutritionOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'nutrition_bristol'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Bristol score</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'nutrition_macro'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Macro Nutrient</span></p>


                            </div>

                        }

                        {
                            MetabolismOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_carb'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Carb Tolerance Test</span></p>
                                {/* <p className="add_data_button choose_type_tab_option" data-category={'meta_fasting'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Fasting</span></p> */}
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_glucose'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Glucose</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_glucose_index'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Glucose:Ketone index</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_keto_mojo'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Keto-Mojo</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_keto_blood'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Ketones-Blood</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_keto_breathe'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Ketones-Breathe</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'meta_keto_urine'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Ketones-Urine</span></p>
                                {/* <p className="add_data_button choose_type_tab_option" data-category={'meta_quit_events'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Quit events</span></p> */}


                            </div>

                        }

                        {
                            RestOption === true &&

                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_am_readiness'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Am Readiness</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_cryotherapy'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Cryotherapy</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_hrv'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >HRV</span></p>
                                {/* <p className="add_data_button choose_type_tab_option" data-category={'rest_hrv_sleep'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >HRV-Sleep</span></p> */}
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_hyperbaric'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Hyperbaric Oxygen</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_ice_bath'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Ice bath</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_meditation'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Meditation</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_photo_bio'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Photo Biomodulation</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_sauna'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Sauna</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'rest_time_asleep'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Time asleep</span></p>


                            </div>

                        }
                        {
                            VitalOption === true &&
                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'vital_basal_temp'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Basal Temperature</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'vital_blood_oxy'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Blood Oxygen</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'vital_body_temp'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Body Temperature</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'vital_heart_rate'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Heart Rate</span></p>
                                <p className="add_data_button choose_type_tab_option" data-category={'vital_rest_heart_rate'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Resting Heart Rate</span></p>


                            </div>

                        }
                        {
                            CustomOption === true &&
                            <div className="choose_type_tab">
                                <p className="add_data_button choose_type_tab_option" data-category={'custom_add_new'} onClick={(e) => { triggerInnerFieldPop(e) }}><span >Add New Data</span></p>
                                {MyCustomCategoryList.length > 0 && MyCustomCategoryList.map((category, index) => (
                                    <p className="add_data_button choose_type_tab_option" data-category={'custom_add_value'} data-cat={category.custom_name} data-id={category.id} data-unit={category.custom_unit} onClick={(e) => { triggerInnerFieldPop(e) }}><span >{category.custom_name}</span></p>
))}

                            </div>
                        }






                        {
                            ShowPhysicalActivityField === true &&
                            <PhysicalActivity SetactivityOption={SetactivityOption} SetShowPhysicalActivityField={SetShowPhysicalActivityField} IsClientid={IsClientid} />
                        }
                        {
                            ShowStepsActivityField === true &&
                            <StepsActivity SetShowStepsActivityField={SetShowStepsActivityField} SetactivityOption={SetactivityOption} IsClientid={IsClientid} />
                        }
                        {
                            ShowBloodHemaField === true &&
                            <BloodHematocrit SetShowBloodHemaField={SetShowBloodHemaField} SetBloodDailyOption={SetBloodDailyOption} IsClientid={IsClientid} />
                        }
                        {
                            ShowBloodHemoField === true &&
                            <BloodHemoglobin SetShowBloodHemoField={SetShowBloodHemoField} SetBloodDailyOption={SetBloodDailyOption} IsClientid={IsClientid} />
                        }
                        {
                            ShowBodyCompFatField === true &&
                            <BodyFatType SetBodyCompOption={SetBodyCompOption} SetShowBodyCompFatField={SetShowBodyCompFatField} IsClientid={IsClientid} />
                        }
                        {
                            ShowBodyCompFatPoundsField === true &&
                            <BodyFatPounds SetBodyCompOption={SetBodyCompOption} SetShowBodyCompFatPoundsField={SetShowBodyCompFatPoundsField} IsClientid={IsClientid} />
                        }
                        {
                            ShowBodyCompLeanPoundsField === true &&
                            <BodyLeanPounds SetBodyCompOption={SetBodyCompOption} SetShowBodyCompLeanPoundsField={SetShowBodyCompLeanPoundsField} IsClientid={IsClientid} />
                        }
                        {
                            ShowBodyCompWeightPoundsField === true &&
                            <BodyWeightPounds SetBodyCompOption={SetBodyCompOption} SetShowBodyCompWeightPoundsField={SetShowBodyCompWeightPoundsField} IsClientid={IsClientid} />
                        }
                        {
                            ShowCardioBloodPressureField === true &&
                            <CardioPressure SetCardioOption={SetCardioOption} SetShowCardioBloodPressureField={SetShowCardioBloodPressureField} IsClientid={IsClientid} />
                        }
                        {
                            ShowCardioVoField === true &&
                            <CardioVo2 SetCardioOption={SetCardioOption} SetShowCardioVoField={SetShowCardioVoField} IsClientid={IsClientid} />
                        }
                        {
                            ShowLifeAlcoholField === true &&
                            <LifeAlcohol SetLifeStyleOption={SetLifeStyleOption} SetShowLifeAlcoholField={SetShowLifeAlcoholField} IsClientid={IsClientid} />
                        }
                        {
                            ShowLifeCaffineField === true &&
                            <LifeCaffine SetLifeStyleOption={SetLifeStyleOption} SetShowLifeCaffineField={SetShowLifeCaffineField}  IsClientid={IsClientid}/>
                        }
                        {
                            ShowLifeHydrationField === true &&
                            <LifeHydration SetLifeStyleOption={SetLifeStyleOption} SetShowLifeHydrationField={SetShowLifeHydrationField} IsClientid={IsClientid} />
                        }
                        {
                            ShowLifeJournalField === true &&
                            <LifeJournal SetLifeStyleOption={SetLifeStyleOption} SetShowLifeJournalField={SetShowLifeJournalField} IsClientid={IsClientid} />
                        }
                        {
                            ShowNutritionBristolField === true &&
                            <NutritionBristol SetNutritionOption={SetNutritionOption} SetShowNutritionBristolField={SetShowNutritionBristolField} IsClientid={IsClientid} />
                        }
                        {
                            ShowNutritionMacroField === true &&
                            <NutritionMacro SetNutritionOption={SetNutritionOption} SetShowNutritionMacroField={SetShowNutritionMacroField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaCarbField === true &&
                            <MetabolismCarb SetMetabolismOption={SetMetabolismOption} SetShowMetaCarbField={SetShowMetaCarbField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaFastingField === true &&
                            <MetabolismFasting SetMetabolismOption={SetMetabolismOption} SetShowMetaFastingField={SetShowMetaFastingField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaGlucoseField === true &&
                            <MetabolismGlucose SetMetabolismOption={SetMetabolismOption} SetShowMetaGlucoseField={SetShowMetaGlucoseField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaKetoBloodField === true &&
                            <MetabolismKetoneBlood SetMetabolismOption={SetMetabolismOption} SetShowMetaKetoBloodField={SetShowMetaKetoBloodField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaKetoBreathField === true &&
                            <MetabolismKetoneBreathe SetMetabolismOption={SetMetabolismOption} SetShowMetaKetoBreathField={SetShowMetaKetoBreathField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaKetoUrineField === true &&
                            <MetabolismKetoneUrine SetMetabolismOption={SetMetabolismOption} SetShowMetaKetoUrineField={SetShowMetaKetoUrineField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaGlucoseIndexField === true &&
                            <MetabolismKetoneIndex SetMetabolismOption={SetMetabolismOption} SetShowMetaGlucoseIndexField={SetShowMetaGlucoseIndexField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaKetoMojoField === true &&
                            <MetabolismKetoneMojo SetMetabolismOption={SetMetabolismOption} SetShowMetaKetoMojoField={SetShowMetaKetoMojoField} IsClientid={IsClientid} />
                        }
                        {
                            ShowMetaQuitField === true &&
                            <MetabolismQuitEvent SetMetabolismOption={SetMetabolismOption} SetShowMetaQuitField={SetShowMetaQuitField} IsClientid={IsClientid} />
                        }
                        {
                            ShowRestAMField === true &&
                            <RestAmReadiness SetRestOption={SetRestOption} SetShowRestAMField={SetShowRestAMField} IsClientid={IsClientid} />
                        }
                        {
                            ShowRestCryoField === true &&
                            <RestCryotherapy SetRestOption={SetRestOption} SetShowRestCryoField={SetShowRestCryoField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestHRVField === true &&
                            <RestHRV SetRestOption={SetRestOption} SetShowRestHRVField={SetShowRestHRVField} IsClientid={IsClientid} />
                        }
                        {
                            ShowRestHRVSleepfield === true &&
                            <RestHRVSleep SetRestOption={SetRestOption} SetShowRestHRVSleepField={SetShowRestHRVSleepField} IsClientid={IsClientid} />
                        }
                        {
                            ShowRestHyperbaricfield === true &&
                            <RestHyperBaricOxygen SetRestOption={SetRestOption} SetShowRestHyperbaricField={SetShowRestHyperbaricField}  IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestIcefield === true &&
                            <RestIceBath SetRestOption={SetRestOption} SetShowRestIceField={SetShowRestIceField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestMeditationfield === true &&
                            <RestMeditation SetRestOption={SetRestOption} SetShowRestMeditationField={SetShowRestMeditationField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestPhotoBiofield === true &&
                            <RestPhotoBioModulation SetRestOption={SetRestOption} SetShowRestPhotoBioField={SetShowRestPhotoBioField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestSaunafield === true &&
                            <RestSauna SetRestOption={SetRestOption} SetShowRestSaunaField={SetShowRestSaunaField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowRestTimeAsleepfield === true &&
                            <RestAsleep SetRestOption={SetRestOption} SetShowRestTimeAsleepField={SetShowRestTimeAsleepField} IsClientid={IsClientid}/>
                        }
                        {
                            ShowVitalBasalTempfield === true &&
                            <VitalBasalTemp SetVitalOption={SetVitalOption} SetShowVitalBasalTempfield={SetShowVitalBasalTempfield} IsClientid={IsClientid}/>
                        }
                        {
                            ShowVitalBloodOxyfield === true &&
                            <VitalBloodOxygen SetVitalOption={SetVitalOption} SetShowVitalBloodOxyfield={SetShowVitalBloodOxyfield} IsClientid={IsClientid}/>
                        }
                        {
                            ShowVitalBodyTempfield === true &&
                            <VitalBodyTemp SetVitalOption={SetVitalOption} SetShowVitalBodyTempfield={SetShowVitalBodyTempfield} IsClientid={IsClientid}/>
                        }
                        {
                            ShowVitalHeartRatefield === true &&
                            <VitalHeartRate SetVitalOption={SetVitalOption} SetShowVitalHeartRatefield={SetShowVitalHeartRatefield} IsClientid={IsClientid}/>
                        }
                        {
                            ShowVitalRestHeartRatefield === true &&
                            <VitalRestHeartRate SetVitalOption={SetVitalOption} SetShowVitalRestHeartRatefield={SetShowVitalRestHeartRatefield} IsClientid={IsClientid} />
                        }
                        {
                            ShowCustomCatfield === true &&
                            <CustomCatAdd SetCustomOption={SetCustomOption} SetShowCustomCatfield={SetShowCustomCatfield} GetMyCustomCategoryList={GetMyCustomCategoryList} IsClientid={IsClientid} />
                        }
                        {
                            ShowCustomValfield===true && 
                            <CustomValAdd SetCustomOption={SetCustomOption} SetShowCustomValfield={SetShowCustomValfield}  SelectedCustomId={SelectedCustomId} SelectedCustomName={SelectedCustomName} SelectedCustomUnit={SelectedCustomUnit} IsClientid={IsClientid}  />
                        }










                    </div>



                </Modal.Body>

            </Modal>

            
            {
                role==='Nutrition' &&
                <GenerateAddData profileData={ClientProfileData} CustomCategoryList={ClientCustomCategoryList} showsdr={showsdr} handleClosesdr={handleClosesdr} IsClientid={IsClientid}  />
            }
            
        

        </div>
    )
}

export default AddData
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";

const CoachServices=(props)=>{

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [ActiveServicesList,SetActiveServicesList]=useState([])

    const GetServices=async (NutritionId)=>{
        const response = await axiosJWT.get('/nutrition-service/list-services?data=all_active&coach_id=' + NutritionId + '', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          if(response.data!==null)
          {
            if(response.data.NutritionistService!==null)
            {
                SetActiveServicesList(response.data.NutritionistService)
            }

          }

         
    }

    useEffect(()=>{
      if(props.ClientId)
      {
        GetServices(props.ClientId)
      }
    },[props])

    return (
        <div>

<Table className="table is-striped is-fullwidth upcoming_appointment_table">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Service Name</Th>
            <Th scope="col">Category</Th>
            <Th scope="col">Expiry</Th>
            <Th scope="col">Allowed Hours</Th>
            <Th scope="col">Package Cost</Th>
          </Tr>
        </Thead>
        <Tbody className="tbody">
        {ActiveServicesList.map((list, index) => (
            <Tr className="trow">
                <Td>{list.service_name}</Td>
                <Td>{list.service_category.category_name}</Td>

                {
                    parseFloat(list.service_expiry)<=1 ? <Td>{list.service_expiry+' month'}</Td> : <Td>{list.service_expiry+' months'}</Td>
                }      
                  <Td>{list.service_hour_allowed}</Td>
                <Td>{'$ '+list.service_price}</Td>
             
            </Tr>
         ))}

        </Tbody>

        </Table>
        </div>
    )
}
export default CoachServices
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { format, parseISO } from 'date-fns'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Pagination from 'react-js-pagination';
import GlpNotes from './GlpNotes';
import GlpDocuments from './GLPDocuments';

const Notes = (props) => {

   
    const token = localStorage.getItem("token");

    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const [editorData, seteditorData] = useState('');

    var [notes, setNotes] = useState([]);
    const [noteId, setNoteId] = useState('');

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);


    const client_id = props.data;

    useEffect(() => {
        getNotes();
    }, []);

    const axiosJWT = axios.create();

    const createNote = async () => {
        const response = await axiosJWT.post("/notes",
            {
                client_id: client_id,
                content: ''
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        getNotes();
    }

    const getNotes = async (pagenumber) => {

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage
        }

        const response = await axiosJWT.get("/notes/" + client_id + "?offset=" + starting_limit + "&limit=" + ending_limit,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.data.totalcount === 0) {
            createNote();
        }
        else {
            setNotes(response.data.notes);
            loadNote(response.data.notes[0].id);
            setTotalCount(response.data.totalcount);
        }
    }

    const changePageNumber = async (pagenumber) => {
        setactivepage(pagenumber);
        getNotes(pagenumber);
    }

    const loadNote = async (note_Id) => {

        setNoteId(note_Id);

        const response = await axiosJWT.get("/notes/" + client_id + "/" + note_Id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

  

        if (response.data.content !== '') {
            var editor_content = ContentState.createFromBlockArray(convertFromHTML(response.data.content));
            seteditorvalue(EditorState.createWithContent(editor_content));
        }
        else {
            seteditorvalue(EditorState.createEmpty());
        }

        const allnotes = document.querySelectorAll(".notes_list .note_info");
        allnotes.forEach((element) => element.classList.remove('active'));

        document.getElementById("note_" + note_Id).setAttribute("class", "note_info active");

    }

    const editorChange = (data) => {
        seteditorData(data.blocks);
    }

    const updateNote = async () => {

        var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));
        const response = await axiosJWT.put("/notes/" + client_id + "/" + noteId,
            {
                content: editor_content
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });



        getNotes();

    };

    const [ShowNotesTab,SetShowNotesTab]=useState(true)
    const [ShowGLpNotesTab,SetShowGLpNotesTab]=useState(false)
    const [ShowGlpDocumentsTab,SetShowGlpDocumentsTab]=useState(false)

    const ChangeTabs=(e)=>{

     const alltabs = document.querySelectorAll(".top_bar_notes .tabs");
     alltabs.forEach((element) => element.classList.remove('active'));
     e.target.setAttribute("class"," tabs active"); 
     const Item=e.target.getAttribute('data-item');
     SetShowNotesTab(false)
     SetShowGLpNotesTab(false)
     SetShowGlpDocumentsTab(false)

     if(Item==='notes')
     {
        SetShowNotesTab(true) 
     }
     else if(Item==='glp-forms'){
        SetShowGLpNotesTab(true)
     }
     else if(Item==='glp-documents'){
        SetShowGlpDocumentsTab(true)
     }


        

    }


    return (
        <div className='notes_tab'>

            <div className='top_bar_notes'>
                <div className='tabs active' data-item={'notes'} onClick={ChangeTabs}>Notes</div>
                {/* <div className='tabs' data-item={'glp-forms'} onClick={ChangeTabs}>GLP-Notes</div>
                <div className='tabs' data-item={'glp-documents'} onClick={ChangeTabs}>GLP-Documents</div> */}
            </div>

{
ShowNotesTab===true &&
<div>

<div className='left_inner_block'>
    <div className='title_block'>
        <div className='title_left'>
            <h5>Notes</h5>
        </div>
        <div className='title_right'>
            <div className='action_buttons'>
                <Button className='green_button btn' style={{ "width": "100px" }} onClick={createNote}>New</Button>
            </div>
        </div>
    </div>
    <div style={{ "clear": "both" }} />
    <div className='notes_list'>

        <Pagination
            activePage={activePage}
            itemsCountPerPage={countPerPage}
            totalItemsCount={totalCount}
            onChange={changePageNumber.bind(this)}
            prevPageText="<"
            nextPageText=">"
            hideFirstLastPages={true}
            linkClassPrev="pagination_prev"
            linkClassNext="pagination_next"
            linkClass="pagenumbers grayfont"
        />


        {notes.map((note, index) =>
        (
            <div id={"note_" + note.id} className='note_info' onClick={() => { loadNote(note.id) }} key={note.id}>
                <div className='note_by purplebox'>By {note.nutritionist.first_name} {note.nutritionist.last_name}</div>
                <div className='note_msg' dangerouslySetInnerHTML={{ __html: note.content }}></div>
                <div className='note_edit_date'>{format(parseISO(note.updatedAt), `PP p`)}</div>
                <div className='note_edit_by'>Last Edit {note.nutritionist.first_name} {note.nutritionist.last_name}</div>
                <div style={{ "clear": "both" }} />
            </div>
        ))
        }
    </div>
</div>

<div className='right_inner_block'>
    <Editor
        wrapperClassName="texteditor"
        editorState={editorvalue}
        onEditorStateChange={seteditorvalue}
        onContentStateChange={editorChange}
    />
    <div className='action_buttons' style={{ "marginTop": "20px" }}>
        <Button className='green_button btn' style={{ "width": "100px" }} onClick={updateNote} >Update</Button>
    </div>
</div>

</div>

}
{
   ShowGLpNotesTab===true &&  <GlpNotes client_id={client_id} />
}
{
    ShowGlpDocumentsTab===true && <GlpDocuments client_id={client_id}/>
}


           
          
        </div>
    )
}

export default Notes
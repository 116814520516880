import React, { useEffect, useState } from "react";

const SideEffectDescCM = (props) => {
 
  const [SideEffectDesc, SetSideEffectDesc] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.SideEffectDescPC) {
        SetSideEffectDesc(props.SideEffectDescPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetSideEffectDescPC(SideEffectDesc);
   

      var Err = 0;
      if (SideEffectDesc === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [SideEffectDesc]);

  useEffect(()=>{
    props.SetPreviousMenu('sideeffect')
    props.SetNextMenu('glpdosepref')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"><h6>{props.SideEffectDescPCLabel}</h6></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={SideEffectDesc}
              value={SideEffectDesc}
              onChange={(e) => {
                SetSideEffectDesc(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default SideEffectDescCM;

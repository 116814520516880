import React, { useEffect, useState } from "react";
import Questionnaire from "./questionnaire";
import axios from "axios";
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ListQuestionnaire = () => {
  const side_nav = localStorage.getItem("side_nav");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const role=localStorage.getItem("role");
  const [QuestionnaireList, SetQuestionnaireList] = useState([])
  const [QuestionnairePageNo, SetQuestionnairePageNo] = useState(1)
  const [QuestionnairePerPage, SetQuestionnairePerPage] = useState(10)
  const [TotalRecordQuestionnaire, SetTotalRecordQuestionnaire] = useState(10)
  const [DeleteId, SetDeleteId] = useState('')
  const [shows, setShows] = useState(false)
  const [showsend, setshowsend] = useState(false)
  const [SendFormId, SetFormID] = useState('')
  const [SendUserID, SetUserID] = useState('')
  const [SendId, SetSendId] = useState('')
  const [Clientuserslist, SetClientuserlist] = useState([])

  const GetClientList = async () => {



    const response = await axiosJWT.get('/nutrition/allclient', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {

      SetClientuserlist(response.data)

    })
  }

  const Finalsend = async (e) => {

    var FormID = e.target.getAttribute('data-id')
    var UserId = e.target.getAttribute('data-user_id')
    Setsucc(false)
    SetErr(false)
    if (UserId === '') {
      SetErrMsg("Please select client")
      SetErr(true)

    }
    else {
      SetErr(false)
      try {

        const response = await axiosJWT.post('/form/invite-form-user',
          {
            form_id: FormID,
            invited_to: UserId


          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

        if (response) {

          Setsucc(true)
          Setsuccmsg("Successfully invited the user")

        }

      }
      catch (error) {

        SetErrMsg("You have already invited this user with this questionnaire")
        SetErr(true)

      }

    }

  }


  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);
  const handleClosesend = () => setshowsend(false);
  const handleShowsend = () => setshowsend(true);
  const [Err, SetErr] = useState(false)
  const [ErrMsg, SetErrMsg] = useState('')
  const [succ, Setsucc] = useState(false)
  const [succmsg, Setsuccmsg] = useState('')


  const GetListOfQuestionnaire = async (pagenumber) => {

    SetQuestionnairePageNo(pagenumber)
    const response = await axiosJWT.get('form?offset=' + pagenumber + '&limit=' + QuestionnairePerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      SetQuestionnaireList(response.data.data)
      SetTotalRecordQuestionnaire(response.data.totalcount)

    })

  }

  useEffect(() => {
    GetListOfQuestionnaire(1)
  }, [])

  const changePageNumberS = async (pagenumber) => {


    GetListOfQuestionnaire(pagenumber)
    SetQuestionnairePageNo(pagenumber)
  }

  const FinalDelete = async (e) => {
    var DeleteId = e.target.getAttribute('data-id')
    const response = await axiosJWT.delete('form/' + DeleteId + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      GetListOfQuestionnaire(QuestionnairePageNo)
      handleCloses()
    })


  }

  const DeleteQuestionnaire = (e) => {
    var DeleteId = e.target.getAttribute('data-id')
    SetDeleteId(DeleteId)
    handleShows()
  }

  const EditQuestionnaire = (e) => {

    var EditId = e.target.getAttribute('data-id')
    if(role==='Nutrition')
    {
      window.location.href = "" + window.location.origin + "/coach/edit-questionnaire?id=" + EditId + "";
    }
    else if(role==='Admin')
    {
      window.location.href = "" + window.location.origin + "/admin/edit-questionnaire?id=" + EditId + "";
    }

  }

  const SendQuestionaire = (e) => {
    var FormId = e.target.getAttribute('data-id')
    SetFormID(FormId)
    GetClientList()
    handleShowsend()
    SetErr(false)

  }


  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"} >
      <section className="dashboard">
      <div className="coachquestionnaire_list container">
        <Questionnaire />

        <div className="row list_questionnaire_row">

          {QuestionnaireList.length > 0 &&
            <Table className="table is-striped is-fullwidth">
              <Thead className="thead">
                <Tr>
                  <Th>Questionarrie Name</Th>
                  <Th>Number of fields</Th>
                  <Th>Last edited</Th>
                  <Th>Action</Th>

                </Tr>
              </Thead>
              <Tbody className="tbody">
                {QuestionnaireList.length > 0 && QuestionnaireList.map((questionnaire, index) => (

                  <Tr className='trow' key={questionnaire.id}>
                    <Td><span className="">{questionnaire.form_name}</span></Td>
                    <Td >{JSON.parse(questionnaire.form_data).task_data.length}</Td>
                    <Td >{format(parseISO(questionnaire.updatedAt), `PP`)}</Td>

                    <Td ><i className="fa fa-edit edit_icon billing_edit_i pointer_eff" data-id={questionnaire.id} onClick={(e) => { EditQuestionnaire(e) }}></i><i data-id={questionnaire.id} className="fa fa-trash billing_edit_i pointer_eff" onClick={(e) => { DeleteQuestionnaire(e) }} ></i><i data-id={questionnaire.id} className='fa fa-send billing_edit_i pointer_eff send_qu_icon' onClick={(e) => { SendQuestionaire(e) }} style={{ display: 'none' }}></i></Td>


                  </Tr>

                ))}

              </Tbody>
            </Table>
          }

          <div>
            {TotalRecordQuestionnaire ?
            <Pagination
            activePage={QuestionnairePageNo}
            itemsCountPerPage={QuestionnairePerPage}
            totalItemsCount={TotalRecordQuestionnaire}
            onChange={changePageNumberS.bind(this)}
            prevPageText="<"
            nextPageText=">"
            hideFirstLastPages={true}
            linkClassPrev="pagination_prev"
            linkClassNext="pagination_next"
            linkClass="pagenumbers grayfont"
          />
          : null }
          </div>

        </div>

        <Modal show={shows} onHide={handleCloses} className="coach_questionnaire_delete confirm_delete">
          <Modal.Header closeButton>
            <Modal.Title>Delete Questionnaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete this questionnaire?{'(In case if client has submitted form with this questionnaire it might look empty)'}<br /><br /><span style={{ fontWeight: 500, display: 'none' }}>Note: All invited form with this questionnaire will also be deleted</span></Modal.Body>
          <Modal.Footer className="display_block">
            <Button className="modal_close_button" variant="secondary" onClick={handleCloses}>
              Cancel
            </Button>
            <Button className="modal_action_button float_right" variant="primary" data-id={DeleteId} onClick={(e) => { FinalDelete(e) }}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showsend} onHide={handleClosesend} className="confirm_delete">
          <Modal.Header closeButton>
            <Modal.Title>Send Questionnaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} onChange={(e) => { SetUserID(e.target.value) }}>
              <option value={''}>Select Client</option>
              {Clientuserslist.length > 0 && Clientuserslist.map((client, index) => (
                <option value={client.id}>{client.first_name + ' ' + client.first_name + '(' + client.email + ')'}</option>
              ))}
            </select>
            {Err === true &&
              <span className="add_ques_err">{ErrMsg}</span>
            }

            {succ === true &&
              <span className="add_ques_succ">{succmsg}</span>
            }
          </Modal.Body>
          <Modal.Footer className="display_block">
            <Button variant="secondary" onClick={handleClosesend}>
              Cancel
            </Button>
            <Button variant="primary" className="float_right" data-id={SendFormId} data-user_id={SendUserID} onClick={(e) => { Finalsend(e) }}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
      </section>
    </div>
  )
}

export default ListQuestionnaire
import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import EmailEditor from 'react-email-editor';


const AdminMailTemplates = () => {

    const axiosJWT = axios.create();
    const emailEditorRef = useRef(null);

    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const [Updateid, SetUpdateid] = useState('');
    const [addPopuptitle, SetaddPopuptitle] = useState('');
    const [addPopupbuttonlabel, SetaddPopupbuttonlabel] = useState('');
    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const [editorData, seteditorData] = useState('');
    const [MailTemplateName, SetMailTemplateName] = useState('');
    const [MailTemplateKey, SetMailTemplateKey] = useState('');
    const [MailTemplateSubject, SetMailTemplateSubject] = useState('');
    const [MailTemplateList, SetMailTemplateList] = useState([]);
    const [TemplatePerPage, SetTemplatePerPage] = useState(10);
    const [TemplatePageNo, SetTemplatePageNo] = useState(1);
    const [TemplateTotalRecord, SetTemplateTotalRecord] = useState(0);
    const [DisableDrop, SetDisableDrop] = useState(false);
    const [DeleteIdFianl, SetDeleteIdFinal] = useState('');
    const [showsd, Setshowsd] = useState(false);
    const handleClosesd = () => { Setshowsd(false) }
    const [errormsgshow, Seterrormsgshow] = useState(false);
    const [errormsg, Seterrormsg] = useState('');

    const [ShowTagLabel, SetShowTagLabel] = useState(false);
    const [FirstNameTag, SetFirstNameTag] = useState(false);
    const [LastNameTag, SetLastNameTag] = useState(false);
    const [EmailTag, SetEmailTag] = useState(false);
    const [ContactSubjectTag, SetContactSubjectTag] = useState(false);
    const [ContactQueryTag, SetContactQueryTag] = useState(false);
    const [PasswordTag, SetPasswordTag] = useState(false);
    const [RoleTag, SetRoleTag] = useState(false);
    const [PaymentDateTag, SetPaymentDateTag] = useState(false);
    const [PaymentCostTag, SetPaymentCostTag] = useState(false);
    const [PlanNameTag, SetPlanNameTag] = useState(false);
    const [OtpTag, SetOtpTag] = useState(false);
    const [TestMailId, SetTestMailId] = useState('');
    const [TestMailAddress, SetTestMailAddress] = useState('');
    const [showsdtest, Setshowsdtest] = useState(false);
    const handleClosesdtest = () => { Setshowsdtest(false) }
    const [ShowTest, SetShowTest] = useState(false);

    const [ClientFirstNameTag, SetClientFirstNameTag] = useState(false);
    const [ClietLastNameTag, SetClietLastNameTag] = useState(false);
    const [CoachFirstNameTag, SetCoachFirstNameTag] = useState(false);
    const [CoachLastNameTag, SetCoachLastNameTag] = useState(false);
    const [ClientEmailAddressTag, SetClientEmailAddressTag] = useState(false);
    const [AdminCommissionAmountTag, SetAdminCommissionAmountTag] = useState(false);
    const [GatewayFeeTag, SetGatewayFeeTag] = useState(false);
    const [FinalEarningsTag, SetFinalEarningsTag] = useState(false);
    

    //appointment tags

    const [BookedTimeTag,SetBookedTimeTag]=useState(false)
    const [RescheduleTimeTag,SetRescheduleTimeTag]=useState(false)
    const [MeetingTypeTag,SetMeetingTypeTag]=useState(false)
    const [MeetingDescTag,SetMeetingDescTag]=useState(false)


    //refund or charge tags
    
    const [OldPlanNameTag,SetOldPlanNameTag]=useState(false)
    const [ChargeAmountTag,SetChargeAmountTag]=useState(false)
    const [RefundAmountTag,SetRefundAmountTag]=useState(false)

    const [shows, Setshows] = useState(false);

    const handleCloses = () => {
        Setshows(false);
    }

    const editorChange = (data) => {
        seteditorData(data.blocks);
    }


    const createNewMailTemplate = async() => {

        SetaddPopuptitle('Create Template');
        SetaddPopupbuttonlabel('Create');
        SetUpdateid('');

        if (emailEditorRef.current && emailEditorRef.current.editor)
        await emailEditorRef.current.editor.loadDesign();
    
        SetMailTemplateName('');
        SetMailTemplateSubject('');
        SetMailTemplateKey('');
        SetDisableDrop(false);
        Seterrormsg('');
        Seterrormsgshow(false);
        SetShowTagLabel(false);
        SetFirstNameTag(false);
        SetLastNameTag(false);
        SetEmailTag(false);
        SetPasswordTag(false);
        SetRoleTag(false);
        SetPaymentDateTag(false);
        SetPaymentCostTag(false);
        SetPlanNameTag(false);
        SetContactSubjectTag(false);
        SetContactQueryTag(false);
        SetClientFirstNameTag(false);
        SetClietLastNameTag(false);
        SetCoachFirstNameTag(false)
        SetCoachLastNameTag(false)
        SetClientEmailAddressTag(false);
        SetAdminCommissionAmountTag(false);
        SetGatewayFeeTag(false);
        SetFinalEarningsTag(false);
        SetBookedTimeTag(false)
        SetRescheduleTimeTag(false)
        SetMeetingDescTag(false)
        SetMeetingTypeTag(false)
        SetOtpTag(false);
        SetOldPlanNameTag(false)
        SetChargeAmountTag(false)
        SetRefundAmountTag(false)

        Setshows(true);
    }

    const [HtmlContent, SetHtmlContent] = useState('');

    const FinalAdd = async () => {        

        await emailEditorRef.current.editor.exportHtml(async(data) => {
            
            if (Updateid === '') {

                if (data.html !== '' && MailTemplateName !== '' && MailTemplateSubject !== '' && MailTemplateKey !== '') {
                    try {    
                        const response = await axiosJWT.post('/template/create-template',
                            {
                                content: data.html,
                                template_name: MailTemplateName,
                                template_subject: MailTemplateSubject,
                                template_key: MailTemplateKey,
                                json_data:data
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
    
                        if (response) {
                            Setshows(false);
                            GetTemplateList(1);
                        }    
                    } catch (error) {    
                        Seterrormsg('Email Template already exists for this category.!');
                        Seterrormsgshow(true);    
                    }
                }
                else {    
                    Seterrormsg('Please fill out all fields.!');
                    Seterrormsgshow(true);    
                }    
            }
            else {
          
                if (data.html!=='' && MailTemplateName !== '' && MailTemplateSubject !== '' && MailTemplateKey !== '') {    
    
                    const response = await axiosJWT.post('/template/update-template',
                        {
                            content: data.html,
                            template_name: MailTemplateName,
                            template_subject: MailTemplateSubject,
                            template_key: MailTemplateKey,
                            id: Updateid,
                            json_data:data
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
    
                    if (response) {
                        Setshows(false);
                        GetTemplateList(TemplatePageNo);
                    }
                }
                else {
                    Seterrormsg('Please fill out all fields.!');
                    Seterrormsgshow(true);
                }    
            }
        });
    }

    const GetTemplateList = async (pagenumber) => {

        SetTemplatePageNo(pagenumber);

        const GetResponse = await axiosJWT.get('/template?offset=' + pagenumber + '&limit=' + TemplatePerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (GetResponse.data !== null) {
            SetTemplateTotalRecord(GetResponse.data.total_count);
            SetMailTemplateList(GetResponse.data.GetResponse);
        }
    }

    const changePageNumber = async (pagenumber) => {
        GetTemplateList(pagenumber);
    }

    const [JsonData,SetJsonData]=useState();

    const EditMailTemplate = async (e) => {
        var EditId = e.target.getAttribute('data-id');
        SetUpdateid(EditId);

        const GetResponse = await axiosJWT.get('/template/detail?id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetaddPopuptitle('Update Template');
        SetaddPopupbuttonlabel('Update');
        Seterrormsg('');
        Seterrormsgshow(false);
        SetDisableDrop(true);
        SetMailTemplateName(GetResponse.data.GetResponse.mail_template_name);
        SetMailTemplateKey(GetResponse.data.GetResponse.mail_template_key);
        SetMailTemplateSubject(GetResponse.data.GetResponse.mail_template_subject);   
        SetJsonData(JSON.parse(GetResponse.data.GetResponse.mail_template_json));  

      
        SetShowTagLabel(false);
        SetFirstNameTag(false);
        SetLastNameTag(false);
        SetEmailTag(false);
        SetPasswordTag(false);
        SetRoleTag(false);
        SetPaymentDateTag(false);
        SetPaymentCostTag(false);
        SetPlanNameTag(false);
        SetOtpTag(false);
        SetClientFirstNameTag(false);
        SetClietLastNameTag(false);
        SetCoachFirstNameTag(false)
        SetCoachLastNameTag(false)
        SetClientEmailAddressTag(false);
        SetAdminCommissionAmountTag(false);
        SetGatewayFeeTag(false);
        SetFinalEarningsTag(false);
        SetBookedTimeTag(false)
        SetRescheduleTimeTag(false)
        SetMeetingDescTag(false)
        SetMeetingTypeTag(false)
        SetOldPlanNameTag(false)
        SetChargeAmountTag(false)
        SetRefundAmountTag(false)
        
        Setshows(true);

        var value = GetResponse.data.GetResponse.mail_template_key;

        if (value === 'coach_plan_subscription' || value === 'client_plan_subscription' || value === 'coach_plan_renewal' || value === 'service_plan_client') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
            SetPlanNameTag(true);
        }

        if (value === 'service_plan_coach') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
            SetPlanNameTag(true);
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetClientEmailAddressTag(true);
            SetAdminCommissionAmountTag(true);
            SetGatewayFeeTag(true);
            SetFinalEarningsTag(true);
        }

        if (value === 'coach_welcome_email' || value === 'client_welcome_email' || value === 'manager_welcome_email') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPasswordTag(true);
            SetRoleTag(true);
        }

        if (value === 'coach_payout') {
            SetShowTagLabel(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
        }

        if (value === 'contact_admin_email' || value === 'contact_user_email') 
        {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetContactSubjectTag(true);
            SetContactQueryTag(true);
        }

        if (value === 'forgot_password_email' || value==='two_factor_authentication') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetOtpTag(true);
        }
        if (value === 'booked_appointment_coach_side' || value === 'created_appointment_coach_side' || value === 'cancel_appointment_coach_side' || value==='reminder_appointment_coach_side') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)
            // SetCoachFirstNameTag(true)
            // SetCoachLastNameTag(true)
        }
        if (value === 'booked_appointment_client_side' || value === 'created_appointment_client_side' || value === 'cancel_appointment_client_side' || value==='reminder_appointment_client_side') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            // SetClientFirstNameTag(true);
            // SetClietLastNameTag(true);
            SetCoachFirstNameTag(true);
            SetCoachLastNameTag(true)
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)
        }
        if(value==='reschedule_request_appointment_coach_side')
        {

            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetRescheduleTimeTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)

        }
        if(value==='reschedule_request_approve_appointment_client_side' || value==='reschedule_request_decline_appointment_client_side')
        {

            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetCoachFirstNameTag(true);
            SetCoachLastNameTag(true);
            SetRescheduleTimeTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)

        }

        if(value==='client_plan_upgrade_client_side' || value==='client_plan_downgrade_client_side' || value==='client_plan_upgrade_charge' || value==='client_plan_downgrade_refund')
        {
            SetClientFirstNameTag(true)
            SetClietLastNameTag(true)
            SetPlanNameTag(true)
            // SetOldPlanNameTag(true)
            SetChargeAmountTag(true)
            SetRefundAmountTag(true)

        }

        if(value==='coach_plan_update_coach_side' || value==='coach_plan_cancel_coach_side')
        {
            SetCoachFirstNameTag(true)
            SetCoachLastNameTag(true)
            SetPlanNameTag(true)
            // SetOldPlanNameTag(true)
            SetChargeAmountTag(true)
            SetRefundAmountTag(true)

        }


        



    }

    const DeleteMailTemplate = async (e) => {
        var DeleteId = DeleteIdFianl;

        const response = await axiosJWT.post('/template/delete-template',
            {
                id: DeleteId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            Setshows(false);
            Setshowsd(false);
            GetTemplateList(TemplatePageNo);
        }
    }

    const TriggerDelete = async (e) => {
        var DeleteId = e.target.getAttribute('data-id');
        SetDeleteIdFinal(DeleteId);
        Setshowsd(true);
    }

    const SetAvailableTag = async (value) => {
        SetShowTagLabel(false);
        SetFirstNameTag(false);
        SetLastNameTag(false);
        SetEmailTag(false);
        SetPasswordTag(false);
        SetRoleTag(false);
        SetPaymentDateTag(false);
        SetPaymentCostTag(false);
        SetPlanNameTag(false);
        SetContactSubjectTag(false);
        SetContactQueryTag(false);
        SetOtpTag(false);
        SetClientFirstNameTag(false);
        SetClietLastNameTag(false);
        SetCoachFirstNameTag(false);
        SetCoachLastNameTag(false);
        SetClientEmailAddressTag(false);
        SetAdminCommissionAmountTag(false);
        SetGatewayFeeTag(false);
        SetFinalEarningsTag(false);
        SetBookedTimeTag(false)
        SetRescheduleTimeTag(false)
        SetMeetingDescTag(false)
        SetMeetingTypeTag(false)
        SetPlanNameTag(false)
        SetOldPlanNameTag(false)
        SetChargeAmountTag(false)
        SetRefundAmountTag(false)

        SetMailTemplateKey(value);

        if (value === 'coach_plan_subscription' || value === 'client_plan_subscription' || value === 'coach_plan_renewal' || value === 'service_plan_client') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
            SetPlanNameTag(true);
        }

        if (value === 'service_plan_coach') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
            SetPlanNameTag(true);
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetClientEmailAddressTag(true);
            SetAdminCommissionAmountTag(true);
            SetGatewayFeeTag(true);
            SetFinalEarningsTag(true);
        }

        if (value === 'coach_welcome_email' || value === 'client_welcome_email' || value === 'manager_welcome_email') {
            SetShowTagLabel(true);
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetPasswordTag(true);
            SetRoleTag(true);
        }

        if (value === 'coach_payout') {
            SetShowTagLabel(true);
            SetPaymentDateTag(true);
            SetPaymentCostTag(true);
        }

        if (value === 'contact_admin_email' || value === 'contact_user_email') 
        {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetEmailTag(true);
            SetContactSubjectTag(true);
            SetContactQueryTag(true);
        }

        if (value === 'forgot_password_email' || value === 'two_factor_authentication') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetOtpTag(true);
        }

        if (value === 'booked_appointment_coach_side' || value === 'created_appointment_coach_side' || value === 'cancel_appointment_coach_side' || value === 'reminder_appointment_coach_side') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)
            // SetCoachFirstNameTag(true);
            // SetCoachLastNameTag(true)
        }
        if (value === 'booked_appointment_client_side' || value === 'created_appointment_client_side' || value === 'cancel_appointment_client_side' || value==='reminder_appointment_client_side') {
            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)
            // SetClientFirstNameTag(true);
            // SetClietLastNameTag(true);
            SetCoachFirstNameTag(true);
            SetCoachLastNameTag(true)
        }
        if(value==='reschedule_request_appointment_coach_side')
        {

            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetClientFirstNameTag(true);
            SetClietLastNameTag(true);
            SetRescheduleTimeTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)

        }
        if(value==='reschedule_request_approve_appointment_client_side' |value==='reschedule_request_decline_appointment_client_side')
        {

            SetFirstNameTag(true);
            SetLastNameTag(true);
            SetBookedTimeTag(true)
            SetCoachFirstNameTag(true);
            SetCoachLastNameTag(true);
            SetRescheduleTimeTag(true);
            SetMeetingDescTag(true)
            SetMeetingTypeTag(true)

        }
        if(value==='client_plan_upgrade_client_side' || value==='client_plan_downgrade_client_side' || value==='client_plan_upgrade_charge' || value==='client_plan_downgrade_refund')
        {
            SetClientFirstNameTag(true)
            SetClietLastNameTag(true)
            SetPlanNameTag(true)
            // SetOldPlanNameTag(true)
            SetChargeAmountTag(true)
            SetRefundAmountTag(true)

        }
        if(value==='coach_plan_update_coach_side' || value==='coach_plan_cancel_coach_side')
        {
            SetCoachFirstNameTag(true)
            SetCoachLastNameTag(true)
            SetPlanNameTag(true)
            // SetOldPlanNameTag(true)
            SetChargeAmountTag(true)
            SetRefundAmountTag(true)

        }
    }

    const TriggerTestMail = (e) => {
        SetShowTest(false);
        var TestId = e.target.getAttribute('data-id');
        SetTestMailId(TestId);
        Setshowsdtest(true);
    }

    const TestMailTemplate = async () => {
        const response = await axiosJWT.post('/template/test-mail-template',
            {
                template_id: TestMailId,
                test_address: TestMailAddress
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            SetShowTest(true);
        }
    }

    useEffect(() => {
        document.title = 'Admin-Mail templates';
        GetTemplateList(1);
    }, []);
 

    const onLoad =async () => {
        if (emailEditorRef.current && emailEditorRef.current.editor)
        await emailEditorRef.current.editor.loadDesign(JsonData.design);
    };

    const onDesignLoad=()=>{
        console.log('Design onDesignLoad');
    }

    const onReady = async () => {
        // editor is ready
        if (emailEditorRef.current && emailEditorRef.current.editor)
        await emailEditorRef.current.editor.loadDesign(JsonData.design);
    };



    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="container">
                    <div className="row">
                        <div className="first_col_mail_temp">
                            <span>Mail Templates</span>
                        </div>

                        <div className="second_col_mail_temp">
                            <button type="button" className="btn btn-success view_activity_button create_new_template_button" onClick={createNewMailTemplate}>Create New</button>
                        </div>
                    </div>

                    <div className="row" style={{ display: "block" }}>

                        <Table className='table is-striped is-fullwidth upcoming_appointment_table'>
                            <Thead className='thead '>
                                <Tr className="trow">
                                    <Th className='row_name'>Template Name</Th>
                                    <Th className='row_name'>Template Key (or) category</Th>
                                    <Th className='row_name'>Action</Th>
                                </Tr>
                            </Thead>

                            {MailTemplateList.length > 0 &&

                                <Tbody className="tbody">

                                    {MailTemplateList.length > 0 && MailTemplateList.map((template, index) => (

                                        <Tr className='trow' key={template.id}>
                                            <Td><span className="">{template.mail_template_name}</span></Td>
                                            <Td >{template.mail_template_key}</Td>
                                            <Td ><i className="fa fa-edit edit_icon billing_edit_i pointer_eff" data-id={template.id} onClick={(e) => { EditMailTemplate(e) }} ></i><i className="fa fa-trash billing_edit_i pointer_eff" data-id={template.id} onClick={(e) => { TriggerDelete(e) }}></i><i style={{ marginLeft: '6px' }} className="fa fa-paper-plane  billing_edit_i pointer_eff" aria-hidden="true" data-id={template.id} onClick={(e) => { TriggerTestMail(e) }}></i></Td>
                                        </Tr>

                                    ))}
                                </Tbody>
                            }
                        </Table>
                        <div>
                            {TemplateTotalRecord ?
                                <Pagination
                                    activePage={TemplatePageNo}
                                    itemsCountPerPage={TemplatePerPage}
                                    totalItemsCount={TemplateTotalRecord}
                                    onChange={changePageNumber.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers color_grey"
                                />
                                : null}
                        </div>
                    </div>
                </div>
            </section>


            <Modal show={shows} onHide={handleCloses} className="admin_mail_template confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>{addPopuptitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='commission_inner_popup_label'>Mail template Name<span style={{ color: 'red' }}>*</span></label><br />
                    <input type='text' className="add_category_inner_input add_intake_form_input" onChange={(e) => { SetMailTemplateName(e.target.value) }} defaultValue={MailTemplateName} />

                    <label className='commission_inner_popup_label'>Mail Subject<span style={{ color: 'red' }}>*</span></label><br />
                    <input type='text' className="add_category_inner_input add_intake_form_input" onChange={(e) => { SetMailTemplateSubject(e.target.value) }} defaultValue={MailTemplateSubject} />


                    <label className='commission_inner_popup_label'>Mail Category<span style={{ color: 'red' }}>*</span></label><br />
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={MailTemplateKey} onChange={(e) => { SetAvailableTag(e.target.value) }} disabled={DisableDrop}>
                        <option value="">Please select mail category</option>
                        <option value="common_mail_header">Mail header</option>
                        <option value="common_mail_footer">Mail footer</option>
                        <option value="coach_plan_subscription">Coach Plan Subscription</option>
                        <option value="coach_plan_renewal">Coach Plan Renewal</option>
                        <option value="client_plan_subscription">Client Plan Subscription</option>
                        <option value="service_plan_coach">Service Plan Purchase-Coach Side</option>
                        <option value="service_plan_client">Service Plan Purchase-Client Side</option>
                        <option value="coach_welcome_email">Coach Welcome Email</option>
                        <option value="client_welcome_email">Client Welcome Email</option>
                        <option value="manager_welcome_email">Manager Welcome Email</option>
                        <option value="coach_payout">Coach Payout</option>
                        <option value="contact_admin_email">Contact Admin Email</option>
                        <option value="contact_user_email">Contact User Email</option>
                        <option value="forgot_password_email">Forgot Password Email</option>
                        <option value="two_factor_authentication">Two Factor Authentication</option>
                        <option value="booked_appointment_coach_side">Booked appointment-Coach Side</option>
                        <option value="booked_appointment_client_side">Booked appointment-Client Side</option>
                        <option value="created_appointment_coach_side">Created appointment-Coach Side</option>
                        <option value="created_appointment_client_side">Created appointment-Client Side</option>
                        <option value="cancel_appointment_coach_side">Cancel appointment-Coach Side</option>
                        <option value="cancel_appointment_client_side">Cancel appointment-Client Side</option>
                        <option value="reminder_appointment_coach_side">Reminder appointment-Coach Side</option>
                        <option value="reminder_appointment_client_side">Reminder appointment-Client Side</option>
                        <option value="reschedule_request_appointment_coach_side">Reschedule request appointment-Coach Side</option>
                        <option value="reschedule_request_approve_appointment_client_side">Reschedule request approve appointment-Client Side</option>
                        <option value="reschedule_request_decline_appointment_client_side">Reschedule request decline appointment-Client Side</option>
                        <option value="client_plan_upgrade_client_side">Client Plan Upgrade-Client side</option>
                        <option value="client_plan_downgrade_client_side">Client Plan Downgrade-Client side</option>
                        <option value="client_plan_upgrade_charge">Client Plan Upgrade Charge</option>
                        <option value="client_plan_downgrade_refund">Client Plan Downgrade Refund</option>
                        <option value="coach_plan_update_coach_side">Coach Plan Upgrade-Coach side</option>
                        <option value="coach_plan_cancel_coach_side">Coach Plan Cancel-Coach side</option>
                        <option value="glp1_intake_form_remainder">GLP-1 Intakeform Remainder</option>
                        <option value="beluga_doctor_chat_notification">Beluga Doctor Chat Notification</option>
                        <option value="beluga_customer_service_chat_notification">Beluga Customer Service Chat Notification</option>
                        <option value="beluga_doctor_prescription_chat_notification">Beluga Doctor Prescription Notification</option>
                        <option value="beluga_consult_cancelled_chat_notification">Beluga Consult Cancelled Notification</option>
                        <option value="beluga_consult_concluded_chat_notification">Beluga Consult Concluded Notification</option>
                    </select>

                    <label className='commission_inner_popup_label'>Content:<span style={{ color: 'red' }}>*</span></label><br />
                    <div className="border_content">
                        {/* <Editor
                            wrapperClassName="texteditor"
                            editorState={editorvalue}
                            onEditorStateChange={seteditorvalue}
                            onContentStateChange={editorChange}
                            handlePastedText={() => false}
                        /> */}

                        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
                    </div>

                    <div className="tag_info_template">
                        {ShowTagLabel === true &&
                            <span>Available Tags:</span>}
                        {FirstNameTag === true &&
                            <span className="font_weight_500">{'[FIRST_NAME] =>To use user first name in mail template'}</span>}
                        {LastNameTag === true &&
                            <span className="font_weight_500">{'[LAST_NAME] =>To use user last name in mail template'}</span>}
                        {EmailTag === true &&
                            <span className="font_weight_500">{'[EMAIL] =>To use user email in mail template'}</span>}
                        {ClientFirstNameTag === true &&
                            <span className="font_weight_500">{'[CLIENT_FIRST_NAME] =>To use client first name in mail template'}</span>}
                        {ClietLastNameTag === true &&
                            <span className="font_weight_500">{'[CLIENT_LAST_NAME] =>To use client last name in mail template'}</span>}
                        {ClientEmailAddressTag === true &&
                            <span className="font_weight_500">{'[CLIENT_EMAIL] =>To use client email address in mail template'}</span>}
                        {AdminCommissionAmountTag === true &&
                            <span className="font_weight_500">{'[ADMIN_COM_AMOUNT] =>To use client email address in mail template'}</span>}
                        {
                            GatewayFeeTag === true &&
                            <span className="font_weight_500">{'[GATEWAY_FEE] =>To use Gateway fee amount in mail template'}</span>
                        }
                        {
                            FinalEarningsTag === true &&
                            <span className="font_weight_500">{'[FINAL_EARNINGS] =>To use final earnings in mail template'}</span>
                        }
                        {PasswordTag === true &&
                            <span className="font_weight_500">{'[PASSWORD] =>To use user passsword in mail template'}</span>}
                        {RoleTag === true &&
                            <span className="font_weight_500">{'[ROLE] =>To use user role in mail template'}</span>}
                        {PaymentDateTag === true &&
                            <span className="font_weight_500">{'[PAYMENT_DATE] =>To use  payment date in mail template'}</span>}
                        {PaymentCostTag === true &&
                            <span className="font_weight_500">{'[PAYMENT_COST] =>To use  payment cost in mail template'}</span>}
                        {PlanNameTag === true &&
                            <span className="font_weight_500">{'[PLAN_NAME] =>To use  plan name in mail template'}</span>}
                        {ContactSubjectTag === true && <span className="font_weight_500">{'[CONTACT_SUBJECT] =>To use subject in mail template'}</span>}
                        {ContactQueryTag === true && <span className="font_weight_500">{'[CONTACT_QUERY] =>To use query in mail template'}</span>}
                        {OtpTag === true && <span className="font_weight_500">{'[OTP] =>To use OTP in mail template'}</span>}
                        {
                            BookedTimeTag===true && <span className="font_weight_500">{'[BOOKED_TIME] =>To use booked time in mail template'}</span>
                        }
                           {CoachFirstNameTag === true &&
                            <span className="font_weight_500">{'[COACH_FIRST_NAME] =>To use coach first name in mail template'}</span>}
                        {CoachLastNameTag === true &&
                            <span className="font_weight_500">{'[COACH_LAST_NAME] =>To use coach last name in mail template'}</span>}

{RescheduleTimeTag === true &&
                            <span className="font_weight_500">{'[RESCHEDULE_TIME] =>To use reschedule time in mail template'}</span>}
                            {MeetingTypeTag === true &&
                            <span className="font_weight_500">{'[MEETING_TYPE] =>To use meeting type in mail template'}</span>}
                              {MeetingDescTag === true &&
                            <span className="font_weight_500">{'[MEETING_DESC] =>To use meeting desc in mail template'}</span>}
                            {
                                OldPlanNameTag===true && 
                                <span className="font_weight_500">{'[OLD_PLAN_NAME] =>To use old plan name  in mail template'}</span>
                            }
                            {
                                ChargeAmountTag===true && 
                                <span className="font_weight_500">{'[CHARGE_AMOUNT] =>To use charge amount in mail template'}</span>

                            }
                            {
                                RefundAmountTag===true && 
                                <span className="font_weight_500">{'[REFUND_AMOUNT] =>To use refund amount in mail template'}</span>

                            }
                        
                        <span className="font_weight_500">{'[SITE_URL] =>To use site url in mail template'}</span>
                    </div>
                    {errormsgshow === true &&
                        <span style={{ color: 'red' }}>{errormsg}</span>
                    }
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleCloses}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="modal_action_button bootstrp_color_btn float_right" data-id={Updateid} onClick={(e) => { FinalAdd(e) }}>
                        {addPopupbuttonlabel}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showsd} onHide={handleClosesd} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Mail template</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete this mail template?</Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleClosesd}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { DeleteMailTemplate() }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showsdtest} onHide={handleClosesdtest} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Test Mail template</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <input type='text' className="add_category_inner_input add_intake_form_input" placeholder="Enter test mail (test@gmail.com)" onChange={(e) => { SetTestMailAddress(e.target.value) }} />
                    {ShowTest === true &&
                        <span style={{ color: 'green' }}>Test mail sent successfully</span>}
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" onClick={handleClosesdtest}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { TestMailTemplate() }}>
                        Test
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminMailTemplates
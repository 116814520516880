import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import axios from 'axios';

var QB = require('quickblox/quickblox.min');

const NutritionNav=(props)=>{



    const [messageNew, setmessageNew] = useState();

    useEffect(() => {
        setmessageNew(props.data);     
    }, [props.data]);

    const axiosJWT = axios.create();

    const token = localStorage.getItem("token");
    const loggedin_userid = localStorage.getItem('userId');    

    const disable_msgnew = () => {
        setmessageNew(false);
    }

    return (
        <div>
        <ul className="side_nav_class" style={{paddingLeft:'0rem'}}>
        <li ><NavLink to='/coach/dashboard' activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
        <li ><NavLink to='/coach/users' activeclassname="active"><i className="fa fa-user sidenav_icon" aria-hidden="true"></i><span className="side-navli">Users</span></NavLink></li>
        <li ><NavLink to='/coach/messages' activeclassname="active"><i className="fa fa-envelope sidenav_icon" aria-hidden="true"></i><span className="side-navli" onClick={disable_msgnew}>Messages <sup className="msgnew">{messageNew === true ? "(New)" : null}</sup></span></NavLink></li>       
        <li ><NavLink to='/coach/availability' activeclassname="active"><i className="fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Availability</span></NavLink></li>
        <li ><NavLink to='/coach/services' activeclassname="active"><i className="fa fa-cogs sidenav_icon" aria-hidden="true"></i><span className="side-navli">Service</span></NavLink></li>
        <li ><NavLink to='/coach/documents' activeclassname="active"><i className="fa fa-clipboard sidenav_icon" aria-hidden="true"></i><span className="side-navli">Documents</span></NavLink></li>
        {/* <li ><NavLink to='/coach/directories' activeclassname="active"><i className="fa fa-file sidenav_icon" aria-hidden="true"></i><span className="side-navli">Files</span></NavLink></li> */}
        <li ><NavLink to='/coach/plans' activeclassname="active"><i className="fa fa-file-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Meal Plans</span></NavLink></li>
        {/* <li ><NavLink to='/coach/billing' activeclassname="active"><i className="fa fa-rocket sidenav_icon" aria-hidden="true"></i><span className="side-navli">Billing</span></NavLink></li>*/}
        <li ><NavLink to='/coach/payments' activeclassname="active"><i className="fa fa-usd sidenav_icon" aria-hidden="true"></i><span className="side-navli">Payments</span></NavLink></li>
        <li ><NavLink to='/coach/calendar' activeclassname="active"><i className="fa fa-calendar sidenav_icon" aria-hidden="true"></i><span className="side-navli">Calendar</span></NavLink></li>
        <li ><NavLink to='/coach/questionnaire' activeclassname="active"><i className="fa fa-question-circle sidenav_icon" aria-hidden="true"></i><span className="side-navli">Questionnaire</span></NavLink></li>
        <li ><NavLink to='/coach/blog' activeclassname="active"><i className="fa fa-pencil sidenav_icon" aria-hidden="true"></i><span className="side-navli">Blogs</span></NavLink></li>
        <li ><NavLink to='/coach/food-journal' activeclassname="active"><i className="fa fa-cutlery sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food Journal</span></NavLink></li>
        <li ><NavLink to='/coach/subscription' activeclassname="active"><i className="fa fa-rocket sidenav_icon" aria-hidden="true"></i><span className="side-navli">Subscription</span></NavLink></li>

    </ul>

    <div className='addmember-logo'> <NavLink to='/coach/add-member'><i id="Addmember" className="fa fa-user-plus" aria-hidden="true"></i><span id="addmember" style={{color:'white'}}> Add Members</span></NavLink> </div>

    </div>

    )
}

export default NutritionNav
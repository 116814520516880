/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { endOfISOWeekYear, format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import TableDragSelect from "react-table-drag-select";
import "react-table-drag-select/style.css";

const Regular = () => {

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

    const [requiredError, setrequiredError] = useState(false);
    const [AllowedBookingDuration, setAllowedBookingDuration] = useState(null);

    useEffect(() => {
        loadcells();
    }, []);    
    
    const dayval = ['1','2','3','4','5','6','7'];

    const [cells, setcells] = useState([
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false]  
    ]);    

    const cellval = ['12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'];

    const cell_24hrval = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'];

    const [colmval, setcolmval] = useState('');

    const loadcells = async () => {

        var allowed_duration = await axiosJWT.get("/availability/allowed-appointment-duration/",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

       
        if(allowed_duration.data !== null)
            setAllowedBookingDuration(JSON.stringify(allowed_duration.data));  
        

        var response = await axiosJWT.get("/availability/regular/", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var day_slots_data = response.data;

        if(day_slots_data !== null)
        {
                setrequiredError(false);                   

                day_slots_data.forEach(element => {
                    var day = element.dayindex;
                    var slots = element.slots;

                    if (day === 0)
                        day = 7;

                    slots.forEach(slot => {                        
                        var cell_start_val = slot.substring(0,(slot.indexOf("-")-1));
                        var slot_position = (parseInt(cell_24hrval.indexOf(cell_start_val)) + 1);
                        
                        cells[slot_position][day] = true;
                    });
                });

                var time_val = {};
                var settag, true_checked, endtag, until_last;
                var l = 0;

                for (var d = 1; d <= dayval.length; d++) {               
                
                    until_last = false;                                 

                    for (var hm = 1; hm <= cellval.length; hm++) {

                        if (time_val[hm] === undefined)
                        time_val[hm] = {};
                        if (time_val[hm][d] === undefined)
                        time_val[hm][d] = [];

                        if (time_val[hm-1] === undefined)
                        time_val[hm-1] = {};  
                        if (time_val[hm-1][d] === undefined)
                        time_val[hm-1][d] = [];

                        if (cells[hm][d] === true) {
                            
                            if (l === 0)
                                settag = hm-1;

                            l++;

                            true_checked = 1;

                            //for update until last cell
                            if (hm === cellval.length) {
                                l = 0;
                                until_last = true;
                            }
                        }
                        else {
                            l = 0;
                        }

                        if (l === 0 && true_checked === 1) {
                            if (until_last === true)
                                endtag = 0;
                            else
                                endtag = hm-1;
                            
                            if (until_last === true)
                            time_val[hm][d].push(cellval[settag] + " - " + cellval[endtag]);                   
                            else
                            time_val[hm-1][d].push(cellval[settag] + " - " + cellval[endtag]);                   

                            true_checked = 0;
                        }
                        else
                        {
                            time_val[hm][d].push('');
                        }
                    }
                }

                setcells(cells);
                setcolmval(time_val);
        }
        else
        setrequiredError(true);   
    }

    const handleChange = async (cells) => {

        var settag, true_checked, until_last = false, endtag;
        var l = 0;
        var time_val = {};

        var monday_slots = [];
        var tuesday_slots = [];
        var wednesday_slots = [];
        var thursday_slots = [];
        var friday_slots = [];
        var saturday_slots = [];
        var sunday_slots = [];
        
        for (var d = 1; d <= dayval.length; d++) {       
            
            for (var hm = 1; hm <= cellval.length; hm++) {

                if (time_val[hm] === undefined)
                time_val[hm] = {};
                if (time_val[hm][d] === undefined)
                time_val[hm][d] = [];

                if (time_val[hm-1] === undefined)
                time_val[hm-1] = {};  
                if (time_val[hm-1][d] === undefined)
                time_val[hm-1][d] = [];

                if (cells[hm][d] === true) {

                    if (l === 0)
                        settag = hm-1;

                    l++;

                    true_checked = 1;

                    //for update until last cell
                    if (hm === cellval.length) {
                        l = 0;
                        until_last = true;
                    }

                    if(d === 1)
                    {          
                        monday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 2)
                    {
                        tuesday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 3)
                    {
                        wednesday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 4)
                    {
                        thursday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 5)
                    {
                        friday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 6)
                    {
                        saturday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                    else if(d === 7)
                    {
                        sunday_slots.push(cell_24hrval[hm-1]+" - "+cell_24hrval[hm]);
                    }
                }
                else {
                    l = 0;
                }

                if (l === 0 && true_checked === 1) {
                    if (until_last === true)
                        endtag = 0;
                    else
                        endtag = hm-1;

                    if (until_last === true)
                    time_val[hm][d].push(cellval[settag] + " - " + cellval[endtag]);                   
                    else
                    time_val[hm-1][d].push(cellval[settag] + " - " + cellval[endtag]); 

                    true_checked = 0;
                }
                else
                {
                    time_val[hm][d].push('');
                }
            }
        }

        setcells(cells);
        setcolmval(time_val);      


        if(sunday_slots.length === 0 && monday_slots.length === 0 && tuesday_slots.length === 0 && wednesday_slots.length === 0 && thursday_slots.length === 0 && friday_slots.length === 0 && saturday_slots.length === 0)
        var data_array = null;
        else
        var data_array = [{ "dayindex": 0, "day": "sunday", slots: sunday_slots },{ "dayindex": 1, "day": "monday", slots: monday_slots }, { "dayindex": 2, "day": "tuesday", slots: tuesday_slots }, { "dayindex": 3, "day": "wednesday", slots: wednesday_slots }, { "dayindex": 4, "day": "thursday", slots: thursday_slots }, { "dayindex": 5, "day": "friday", slots: friday_slots }, { "dayindex": 6, "day": "saturday", slots: saturday_slots }];

        var response = await axiosJWT.put("/availability/regular/",
            {
                regular_availability: data_array
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


        window.location.reload(false);
    }

    const resetCells = async () => {

        const reset_cells = [
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false]  
        ];    

        setcells(reset_cells);

        handleChange(reset_cells);
    }

    const bookingAllowedTimes = async (e) => {
        console.log("hai");

        var duration_val = e.target.value; 

        if(e.target.checked === true)
        {
            var response = await axiosJWT.put("/availability/allowed-appointment-duration/",
            {
                allowed_duration: duration_val
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        else
        {
            var response = await axiosJWT.put("/availability/remove-appointment-duration/",
            {
                allowed_duration: duration_val
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
    }    

    return (

        <div className='regular_availability'>

            {requiredError === true ? <div className='requiredError'><span style={{color:"#333",fontWeight:"bold"}}>Important: </span>*You must choose atleast one slot for listing to client! </div> : null }

            {AllowedBookingDuration !== null?
            <div className='booking_allow'>
                <label>Choose Appointment Duration <span style={{fontWeight:"normal"}}>(default 1 Hour)</span>:</label>
                <div id="checkboxes"><input type="checkbox" value="15min" onClick={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("15min") > -1 ? true : false}  />&nbsp;15 Min</div>
                <div id="checkboxes"><input type="checkbox" value="30min" onClick={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("30min") > -1 ? true : false}  />&nbsp;30 Min</div>
                <div id="checkboxes"><input type="checkbox" value="1hour" onClick={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("1hour") > -1 ? true : false}  />&nbsp;1 Hour</div>                            
            </div>
            : 
            <div className='booking_allow'>
                <label>Choose Appointment Duration <span style={{fontWeight:"normal"}}>(default 1 Hour)</span>:</label>
                <div id="checkboxes"><input type="checkbox" value="15min" onClick={bookingAllowedTimes} />&nbsp;15 Min</div>
                <div id="checkboxes"><input type="checkbox" value="30min" onClick={bookingAllowedTimes} />&nbsp;30 Min</div>
                <div id="checkboxes"><input type="checkbox" value="1hour" onClick={bookingAllowedTimes} />&nbsp;1 Hour</div>                            
            </div>}

            
            {/* {AllowedBookingDuration.length > 0 ?
            <div className='booking_allow'>
                <label>Choose Appointment Duration <span style={{fontWeight:"normal"}}>(default 1 Hour)</span>:</label>
                <div id="checkboxes"><input type="checkbox" value="15min" onChange={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("15min") > -1 ? true : false}  />&nbsp;15 Min</div>
                <div id="checkboxes"><input type="checkbox" value="30min" onChange={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("30min") > -1 ? true : false}  />&nbsp;30 Min</div>
                <div id="checkboxes"><input type="checkbox" value="1hour" onChange={bookingAllowedTimes} defaultChecked={AllowedBookingDuration.indexOf("1hour") > -1 ? true : false}  />&nbsp;1 Hour</div>                            
            </div>
            : null }

            {AllowedBookingDuration.length === 0 ?
            <div className='booking_allow'>
                <label>Choose Appointment Duration <span style={{fontWeight:"normal"}}>(default 1 Hour)</span>:</label>
                <div id="checkboxes"><input type="checkbox" value="15min" onChange={bookingAllowedTimes} />&nbsp;15 Min</div>
                <div id="checkboxes"><input type="checkbox" value="30min" onChange={bookingAllowedTimes} />&nbsp;30 Min</div>
                <div id="checkboxes"><input type="checkbox" value="1hour" onChange={bookingAllowedTimes} />&nbsp;1 Hour</div>                            
            </div>
            : null } */}

            {colmval && colmval[1][1].length > 0 ?
            <TableDragSelect
                value={cells}
                onChange={handleChange}
            >
                <tr>
                    <td disabled />
                    <td disabled>Monday</td>
                    <td disabled>Tuesday</td>
                    <td disabled>Wednesday</td>
                    <td disabled>Thursday</td>
                    <td disabled>Friday</td>
                    <td disabled style={{ "backgroundColor": "#fbe7cc" }}>Saturday</td>
                    <td disabled style={{ "backgroundColor": "#fbe7cc" }}>Sunday</td>
                </tr>               

                {cellval.map((hr,hr_index)=>(
                        <tr key={hr_index}>
                            <td disabled>{hr}</td>
                            {dayval.map((day,day_index)=>(
                            <td key={day_index}>
                                {colmval[hr_index+1][day] ? colmval[hr_index+1][day] : null}
                            </td>                        
                            ))}
                        </tr>
                    )) }
            </TableDragSelect>
            : 
            null
            }

            {!colmval?
            <TableDragSelect
                value={cells}
                onChange={handleChange}
            >
                <tr>
                    <td disabled />
                    <td disabled>Monday</td>
                    <td disabled>Tuesday</td>
                    <td disabled>Wednesday</td>
                    <td disabled>Thursday</td>
                    <td disabled>Friday</td>
                    <td disabled style={{ "backgroundColor": "#fbe7cc" }}>Saturday</td>
                    <td disabled style={{ "backgroundColor": "#fbe7cc" }}>Sunday</td>
                </tr>               

                { cellval.map((hr,hr_index)=>(
                        <tr key={hr_index}>
                            <td disabled>{hr}</td>
                            {dayval.map((day,day_index)=>(
                            <td key={day_index}></td>                        
                            ))}
                        </tr>
                    )) }
            </TableDragSelect>
            : 
            null
            }

            <div className='reset_btn'>
                <button onClick={resetCells} className='greenbutton'>Reset</button>
            </div>

            <div style={{ "clear": "both" }} />
        </div>
    )
}

export default Regular
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const List=(props)=>{

    const [ListData,SetListData]=useState([])
    const [ListCount,SetListCount]=useState(0)


    const [PerPage,SetPerPage]=useState(0)
    const [Pagenumber,SetPagenumber]=useState(0)
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create()
    const [DeleteId,SetDeleteId]=useState('')
    const [EditId,SetEditId]=useState('')

    const [SaveCategoryButtonLabel,SetSaveCategoryButtonLabel]=useState('Edit specialized in')
    const [show,Setshow]=useState(false)
    const [DefaultCatName,SetDefaultCatName]=useState('')
    const [ServiceCatNameErr,SetServiceCatNameErr]=useState(false)
    const [ServiceCatNameErrMsg,SetServiceCatNameErrMsg]=useState('')
    const [DefaultCatStatus,SetDefaultCatStatus]=useState(1)
    const [ServiceCatStatusErr,SetServiceCatStatusErr]=useState(false)
    const [ServiceCatStatusErrMsg,SetServiceCatStatusErrMsg]=useState('')
    const [service_cat_err,Setservice_cat_err]=useState(false)
    const handleClose=()=>{
   
        Setshow(false)
    }

    useEffect(()=>{

        if(props.ListData)
        {
            SetListData(props.ListData)
        }
        if(props.ListCount)
        {
            SetListCount(props.ListCount)

        }
        if(props.PageLimit)
        {
            SetPerPage(props.PageLimit) 
        }
        if(props.Pagenumber)
        {
            SetPagenumber(props.Pagenumber)
        }




    },[props])

    const changePageNumber = async (pagenumber) => {

     
        props.GetMyLisData(pagenumber)

    }

    const EditCat=(e)=>{
        const Id=e.target.getAttribute('data-id')
 
        // props.TriggerEditCategory(Id)

    }
    const DeleteCat=async(e)=>{
        const Id=e.target.getAttribute('data-id')
        await DeleteCategory(Id)
 
 
    }


    const TriggerDeletePopup=(e)=>{
        var id=e.target.getAttribute('data-id')
        SetDeleteId(id)
        Setdshow(true)
    }


    const DeleteCategory=async()=>{


        const response = await axiosJWT.post('/coach-cat/delete',
        {
            id: DeleteId,
            
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(response.status===200)
        {
            Setdshow(false)
            props.GetMyLisData(Pagenumber)
        
        }

    }


    const [dshow,Setdshow]=useState(false)
    const dhandleClose=()=>{
        Setdshow(false)
    }

    const EditCategory=async(e)=>{


        const response = await axiosJWT.post('/coach-cat/update',
        {
            id: EditId,
            category_name:DefaultCatName,
            category_status:DefaultCatStatus

            
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(response.status===200)
        {
            Setshow(false)
            props.GetMyLisData(Pagenumber)
        
        }


     
    }

    const TriggerEditPopup=async(e)=>{
        var EditId=e.target.getAttribute('data-id')
        SetEditId(EditId)
        const DetailFetch = await axiosJWT.get('/coach-cat/detail?id=' + EditId +'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        SetDefaultCatName(DetailFetch.data.Detail.category_name)
        if(DetailFetch.data.Detail.category_status===true)
        {
            SetDefaultCatStatus(1)
        }
        else{
            SetDefaultCatStatus(0) 
        }
     
        Setshow(true)
    }



    return(
 
           
            <div>
            <Table className="table is-striped is-fullwidth">
                        <Thead className="thead">
                            <Tr>
                                <Th>Specialized In</Th>
                                <Th>status</Th>
                                <Th>Action</Th>
                           
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">

                        {ListData.length > 0 && ListData.map((cat, index) => (
                         
                                <Tr className='trow' key={cat.id}>
                                    <Td>{cat.category_name}</Td>
                                    <Td>{cat.category_status===true && 'Active'}{cat.category_status===false && 'In active'}</Td>
                                    <Td><i className="fa fa-edit edit_icon pointer_eff" data-id={cat.id} onClick={TriggerEditPopup}></i><i className="fa fa-trash edit_icon pointer_eff" onClick={TriggerDeletePopup} data-id={cat.id}></i></Td>
                                   
                                </Tr>

))}
                     
                        </Tbody>
                    </Table>

                    {ListData.length > 0  ?
                <Pagination
                    activePage={Pagenumber}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={ListCount}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers grayfont"
                />
                : null }

<Modal show={dshow} onHide={dhandleClose} className='commission_popup add_coach_service_category_popup delete_specialized_in_pop' >
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                          
 Are you sure want to delete this category?

                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" className='close_button_commission' onClick={dhandleClose} >
                                Cancel
                            </Button>
                            <Button variant="primary" className='save_button_commission float_right' onClick={DeleteCategory}>
                               Proceed
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={show} onHide={handleClose} className='commission_popup add_coach_service_category_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{SaveCategoryButtonLabel}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="row section_1_commission">

                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Name</label><br />
                                    <input type='text' className="add_category_inner_input" defaultValue={DefaultCatName} onChange={(e) => { SetDefaultCatName(e.target.value) }}  {...(ServiceCatNameErr===true ? {id: 'err_border'} : {})}  />
                                    {ServiceCatNameErr === true &&
                                        <span className="err service_err_field">  {ServiceCatNameErrMsg}</span>
                                    }
                                </div>
                                <div className="col">
                                    <label className='commission_inner_popup_label required'>Status</label><br />
                                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={DefaultCatStatus} onChange={(e) => { SetDefaultCatStatus(e.target.value) }} {...(ServiceCatStatusErr===true ? {id: 'err_border'} : {})} >
                                        <option value="1">Enable</option>
                                        <option value="0">Disable</option>

                                    </select>
                                    {ServiceCatStatusErr === true &&
                                        <span className="err service_err_field">  {ServiceCatStatusErrMsg}</span>
                                    }
                                </div>

                            </div>
                            {service_cat_err === true &&
                                <div className="row service_cat_err">
                                    Please fill out all fields
                                </div>
                            }


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" className='close_button_commission' onClick={handleClose} >
                                Back
                            </Button>
                            <Button variant="primary" className='save_button_commission float_right' data-target={SaveCategoryButtonLabel} onClick={EditCategory}>
                                {'Update'}
                            </Button>
                        </Modal.Footer>
                    </Modal>

            </div>
   
    )
}
export default List
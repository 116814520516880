import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ManagerAccessCheck=(props)=>{
    const [ShowPopup,SetShowPopup]=useState()

    function handleChange() {
        // Here, we invoke the callback with the new value
        props.onChange(false);
    }

    useEffect(()=>{
        SetShowPopup(props.allowedstatus)
    },[props.allowedstatus])
    return (

        <Modal show={ShowPopup} onHide={handleChange} className="confirm_delete">
<Modal.Header closeButton>
    <Modal.Title>Access Denied</Modal.Title>
</Modal.Header>
<Modal.Body>You dont have permission to view this page</Modal.Body>
<Modal.Footer className="display_block">
    <Button variant="secondary" onClick={handleChange}>
        Okay
    </Button>
   
</Modal.Footer>
</Modal>
 
    )
}

export default ManagerAccessCheck
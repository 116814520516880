import React, { useEffect, useState } from "react";

const CurrentMedicalConditionsCM = (props) => {
 
  const [CurrentMedicalCondition, SetCurrentMedicalCondition] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.CurrentMedicalConditionPC) {
        SetCurrentMedicalCondition(props.CurrentMedicalConditionPC);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetCurrentMedicalConditionPC(CurrentMedicalCondition);
   

      var Err = 0;
      if (CurrentMedicalCondition === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [CurrentMedicalCondition]);

  useEffect(()=>{

   
    props.SetPreviousMenu('bmi_details')
  
    props.SetNextMenu('allergies')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"><h6>{props.CurrentMedicalConditionPCLabel}</h6>       <span style={{color:'red'}}>*</span></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={CurrentMedicalCondition}
              value={CurrentMedicalCondition}
              onChange={(e) => {
                SetCurrentMedicalCondition(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default CurrentMedicalConditionsCM;

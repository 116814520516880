import React, { useState, useRef, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Chart, registerables, ChartOptions } from 'chart.js';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';
import { ReactFormGenerator } from 'react-form-builder2';
import { format } from 'date-fns'
import GenerateAddData from './GenerateAddData'

const ClientGenerateReport = () => {

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [showsd, Setshowsd] = useState(false)
    const handleClosesd = () => { Setshowsd(false) }
    const [popuptoplabel, Setpopuptoplabel] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [showerr, Setshowerr] = useState(false)
    const [showsdprogress, Setshowsdprogress] = useState(false)
    const handleClosesdprogress = () => { Setshowsdprogress(false) }
    const [Chartlabel, SetChartlabel] = useState([])
    const [WeightChartArray, SetWeightChartArray] = useState([])
    const [WaterChartArray, SetWaterChartArray] = useState([])
    const [ActivityChartArray, SetActivityChartArray] = useState([])
    const [SleepChartArray, SetSleepChartArray] = useState([])
    const [WaistChartArray, SetWaistChartArray] = useState([])
    const [NeckChartArray, SetNeckChartArray] = useState([])
    const [ArmsChartArray, SetArmsChartArray] = useState([])
    const [ThighsChartArray, SetThighsChartArray] = useState([])
    const [HipsChartArray, SetHipsChartArray] = useState([])
    const componentRef = useRef();
    const componentRef1 = useRef()
    const [ClientName, SetClientName] = useState('')
    const [ClientGender, SetClientGender] = useState('')
    const [ClientDob, SetClientDob] = useState('')
    const [ClientWeight, SetClientWeight] = useState('')
    const [TargetClientWeight, SetTargetClientWeight] = useState('')
    const [ClientHeight, SetClientHeight] = useState('')
    const [TargetWaterIntake, SetTargetWaterIntake] = useState('')
    const [WeightTillSelectedDate, SetWeightTillSelectedDate] = useState('')
    const [waterTillSelectedDate, SetwaterTillSelectedDate] = useState('')
    const [ActivityTillSelectedDate, SetActivtyTillSelectedDate] = useState('')
    const [SleepTillSelectedDate, SetSleepTillSelectedDate] = useState('')
    const [WaistTillSelectedDate, SetWaistTillSelectedDate] = useState('')
    const [NeckTillSelectedDate, SetNeckTillSelectedDate] = useState('')
    const [ArmsTillSelectedDate, SetArmsTillSelectedDate] = useState('')
    const [ThigsTillSelectedDate, SetThigsTillSelectedDate] = useState('')
    const [HipTillSelectedDate, SetHipTillSelectedDate] = useState('')
    const [showsq, Setshowsq] = useState(false)
    const handleClosesq = () => { Setshowsq(false) }
    const [questionnaireselectoptions, setquestionnaireselectoptions] = useState([])
    const [SelectedQuestions, SetSelectedQuestions] = useState('')
    const [showsql, Setshowsql] = useState(false);
    const handleClosesql = () => { Setshowsql(false) }
    const [QnAnsArray, SetQnAnsArray] = useState([])
    const [profileData, setprofileData] = useState();
    const [showTopContent, SetshowTopContent] = useState(false)
    const [FoodDiaryErr, SetFoodDiaryErr] = useState(false)
    const [FoodDiaryErrMsg, SetFoodDiaryErrMsg] = useState('')
    const [showsdr,Setshowsdr]=useState(false)
    const [PopShow,SetPopShow]=useState(false)
    const handleClosesdr=()=>{
        Setshowsdr(false)
        SetPopShow(false)
    }

    const datepicker1Ref = useRef(null);
    const datepicker2Ref = useRef(null);

    function handleClickDatepickerIcon1() {
        const datepickerElement = datepicker1Ref.current;

        datepickerElement.setFocus(true);
    }
    function handleClickDatepickerIcon2() {
        const datepickerElement = datepicker2Ref.current;

        datepickerElement.setFocus(true);
    }




    const load_profile = async (req) => {

        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            setprofileData(response.data);
            SetshowTopContent(true)


        });

    }

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },

            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },


            }
        },
        plugins: {
            legend: {
                display: false

            }
        }
    }
    const ActivityOption = {
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },

            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return yactivityLabels[value];
                    }
                    , min: 0, max: 4
                },



            }
        },
        plugins: {
            legend: {
                display: false

            }
        }
    }

    const Weightstate = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Weight ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: WeightChartArray,
                // pointRadius: 0,
            }
        ]
    }
    const Waterstate = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Water ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: WaterChartArray,
                // pointRadius: 0,
            }
        ]
    }

    var yactivityLabels = { 0: 'Not Active', 1: 'Somewhat Active', 2: 'Average Activity', 3: 'Very Active', 4: 'Extremely Active' }

    const ActivityState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Activity ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: ActivityChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const SleepState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Sleep ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: SleepChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const WaistState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Waist ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: WaistChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const NeckState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Neck ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: NeckChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const ArmsState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Arms ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: ArmsChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const ThighsState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Thighs ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: ThighsChartArray,
                // pointRadius: 0,
            }
        ]
    }

    const HipsState = {
        labels: Chartlabel,
        datasets: [
            {


                label: 'Hip ',
                lineTension: 0.5,
                backgroundColor: '#09c109',
                borderColor: '#09c109',
                borderWidth: 4,
                data: HipsChartArray,
                // pointRadius: 0,
            }
        ]
    }
    const GenerateReport = async (e) => {

        var target = e.target.getAttribute('data-target')
     
        load_profile()

        if (target === 'food_diary') {
            Setpopuptoplabel('Generate Progress diary report')
            Setshowsd(true)
        }
        if (target === 'questionnaire') {
            SetFoodDiaryErr(false)
            var MyformsPUsh = []

            const response = await axiosJWT.get('form/submitted-questionnaire-list/intake', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })



            if (response.data.List !== null) {
                response.data.List.forEach((value, index, array) => {



                    var obj = {};
                    obj["value"] = value.id;
                    obj["label"] = value.intake_form.intake_form_name;
                    MyformsPUsh.push(obj);

                })

                setquestionnaireselectoptions(MyformsPUsh)
            }



            Setshowsq(true)
        }
        if(target==='data_report')
        {
            GetMyCustomReportList()
            SetPopShow(true)
            Setshowsdr(true)
        }


    }

    const [CustomCategoryList,SetCustomCategoryList]=useState([])

    const GetMyCustomReportList=async()=>{

        const response = await axiosJWT.get('/add-data/get-custom-category', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response) {
            SetCustomCategoryList(response.data.cat_list)
        }


    }

    const ChangedDate = (date) => {

        setStartDate(date)

    }
    const ChangedEndDate = (date) => {
        setEndDate(date)
    }
    const GenerateFoodDiary = async () => {

        var WeightArray = [];
        var Waterarray = [];
        var Activityarray = [];
        var SleepArray = [];
        var WaistArray = [];
        var NeckArray = [];
        var ArmsArray = [];
        var thighsArray = [];
        var HipArray = [];

        Setshowerr(false)

        if (Date.parse(startDate) > Date.parse(EndDate)) {
            Setshowerr(true)
        } else {

            var startdatepass = moment(startDate).format("YYYY-MM-DD");
            var enddatepass = moment(EndDate).format("YYYY-MM-DD");

            const response = await axiosJWT.get('/progress-diary/custom-date?startdate=' + startdatepass + '&enddate=' + enddatepass + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response) {


                SetClientName(response.data.UserData.first_name + ' ' + response.data.UserData.last_name)
                SetClientGender(response.data.UserData.gender)
                SetClientDob(response.data.UserData.user_dob)
                SetClientHeight(response.data.client_info.height + ' (ft)')
                SetTargetWaterIntake(response.data.client_info.water_intake + ' (oz)')



                let lastItem = response.data.final_data[response.data.final_data.length - 1];
                if (lastItem.data.weight !== undefined && lastItem.data.weight !== '') {


                    if (response.data.client_info !== undefined && response.data.client_info !== undefined && (response.data.client_info.measure_type).toLowerCase() === 'imperial') {

                        SetWeightTillSelectedDate(lastItem.data.weight + ' lbs')

                    }
                    else {
                        SetWeightTillSelectedDate(lastItem.data.weight + ' kg')
                    }



                }

                if (lastItem.data.water !== undefined && lastItem.data.water !== '') {
                    SetwaterTillSelectedDate(lastItem.data.water + ' oz')

                }
                if (lastItem.data.activity !== undefined) {
                    var activity = lastItem.data.activity.replace('_', ' ')
                    SetActivtyTillSelectedDate(activity)

                }
                if (lastItem.data.sleep !== undefined && lastItem.data.sleep !== '') {

                    SetSleepTillSelectedDate(lastItem.data.sleep)

                }
                if (lastItem.data.waist !== undefined && lastItem.data.waist !== '') {

                    SetWaistTillSelectedDate(lastItem.data.waist + ' inch')

                }
                if (lastItem.data.neck !== undefined && lastItem.data.neck !== '') {

                    SetNeckTillSelectedDate(lastItem.data.neck + ' inch')

                }
                if (lastItem.data.arms !== undefined && lastItem.data.arms !== '') {

                    SetArmsTillSelectedDate(lastItem.data.arms + ' inch')

                }
                if (lastItem.data.thighs !== undefined && lastItem.data.thighs !== '') {

                    SetThigsTillSelectedDate(lastItem.data.thighs + ' inch')

                }
                if (lastItem.data.hips !== undefined && lastItem.data.hips !== '') {

                    SetHipTillSelectedDate(lastItem.data.hips + ' inch')

                }


                if (response.data.client_info !== undefined && response.data.client_info !== undefined && (response.data.client_info.measure_type).toLowerCase() === 'imperial') {
                    SetClientWeight(response.data.client_info.weight + ' lbs')
                }
                else {
                    SetClientWeight(response.data.client_info.weight + ' kg')
                }

                if (response.data.client_info !== undefined && response.data.client_info !== undefined && (response.data.client_info.measure_type).toLowerCase() === 'imperial') {
                    SetTargetClientWeight(response.data.client_info.target_weight + ' lbs')
                }
                else {
                    SetTargetClientWeight(response.data.client_info.target_weight + ' kg')
                }




                let result = response.data.final_data.map(a => a.udate);


                SetChartlabel(result)



                if (response.data.final_data.length > 0) {
                    response.data.final_data.forEach((value, index, array) => {


                        value = value.data


                        if (value && value.weight !== undefined && value.weight !== null && value.weight !== '') {

                            WeightArray[index] = parseInt(value.weight)
                        }
                        else {
                            WeightArray[index] = 0
                        }


                        if (value && value.water !== undefined && value.water !== null && value.water !== '') {

                            Waterarray[index] = parseInt(value.water)
                        }
                        else {
                            Waterarray[index] = 0
                        }

                        if (value && value.activity !== undefined && value.activity !== null && value.activity !== '') {

                            if (value.activity === 'not_active') {
                                Activityarray[index] = 0
                            }
                            else if (value.activity === 'somewhat_active') {
                                Activityarray[index] = 1
                            }
                            else if (value.activity === 'average_activity') {
                                Activityarray[index] = 2
                            }
                            else if (value.activity === 'very_active') {
                                Activityarray[index] = 3
                            }
                            else if (value.activity === 'extremely_active') {
                                Activityarray[index] = 4
                            }
                            else {
                                Activityarray[index] = 0
                            }


                        }
                        else {
                            Activityarray[index] = 0
                        }

                        if (value && value.sleep !== undefined && value.sleep !== null && value.sleep !== '') {

                            SleepArray[index] = parseInt(value.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value && value.sleep !== undefined && value.sleep !== null && value.sleep !== '') {

                            SleepArray[index] = parseInt(value.sleep)
                        }
                        else {
                            SleepArray[index] = 0
                        }

                        if (value && value.waist !== undefined && value.waist !== null && value.waist !== '') {

                            WaistArray[index] = parseInt(value.waist)
                        }
                        else {
                            WaistArray[index] = 0
                        }

                        if (value && value.neck !== undefined && value.neck !== null && value.neck !== '') {

                            NeckArray[index] = parseInt(value.neck)
                        }
                        else {
                            NeckArray[index] = 0
                        }


                        if (value && value.arms !== undefined && value.arms !== null && value.arms !== '') {

                            ArmsArray[index] = parseInt(value.arms)
                        }
                        else {
                            ArmsArray[index] = 0
                        }

                        if (value && value.thighs !== undefined && value.thighs !== null && value.thighs !== '') {

                            thighsArray[index] = parseInt(value.thighs)
                        }
                        else {
                            thighsArray[index] = 0
                        }

                        if (value && value.hips !== undefined && value.hips !== null && value.hips !== '') {

                            HipArray[index] = parseInt(value.hips)
                        }
                        else {
                            HipArray[index] = 0
                        }







                    })


                    SetWeightChartArray(WeightArray)
                    SetWaterChartArray(Waterarray)
                    SetActivityChartArray(Activityarray)
                    SetSleepChartArray(SleepArray)
                    SetWaistChartArray(WaistArray)
                    SetNeckChartArray(NeckArray)
                    SetArmsChartArray(ArmsArray)
                    SetThighsChartArray(thighsArray)
                    SetHipsChartArray(HipArray)



                }
                Setshowsd(false)
                Setshowsdprogress(true)
            }


        }



    }

    const SetSelectedQuestionnaire = (e) => {
        var PushSelectedValues = []

        e.forEach((value, index, array) => {
            PushSelectedValues.push(value.value)

        })

        var commavalues = PushSelectedValues.toString();
        SetSelectedQuestions(commavalues)

    }

    const GenerateReportForm = async () => {

        SetFoodDiaryErr(false)

        if (SelectedQuestions !== '') {
            const response = await axiosJWT.get('form/submitted-qn-ans/intake?form_id=' + SelectedQuestions + '', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            var SubmittedAns = response.data.SubmittedFormData

            var Qns = response.data.FinalQuestionnaire
            var filtered = Qns.filter(function (el) {
                return el !== null;
            });
            Qns = filtered


            var QnsANs = []

            SubmittedAns.forEach((value, index, array) => {



                var IntialSubmittedQns = JSON.parse(value.submitted_data)
                var FormName = value.intake_form.intake_form_name



                var IntakeQn = value.intake_form.intake_qn_id.split(',').map(function (item) {
                    return parseInt(item, 10);
                });

                var IntialObjPush = [];
                IntakeQn.forEach((value, index, array) => {


                    var found = Qns.filter(e => e.id === value);
                    if (found.length > 0) {

                        const Formobj = JSON.parse(found[0].form_data)

                        Formobj.task_data.forEach((value, index, array) => {
                            IntialObjPush.push(value)

                        })



                    }
                })





                QnsANs.push({ 'ans': IntialSubmittedQns, 'qn': IntialObjPush, 'FormName': FormName });


            })


            SetQnAnsArray(QnsANs)


            Setshowsq(false)
            Setshowsql(true)



        }
        else {
            SetFoodDiaryErr(true)
            SetFoodDiaryErrMsg('Please select intake forms')

        }




    }

    useEffect(()=>{
        document.title = 'Client-Reports';
        load_profile()
    },[])




    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>

            <section className="dashboard">

                <div className="clientreport container">

                    <div className="row list_div_report_top_row">

                        <div className="list_div_report">

                            <span className="label_tag_report">Progress Diary</span>
                            <button type="button" className="btn btn-success view_activity_button generate_report_button" data-target="food_diary" onClick={(e) => { GenerateReport(e) }}>Generate Report</button>
                        </div>

                        <div className="list_div_report">

                            <span className="label_tag_report">Questionnaire</span>
                            <button type="button" className="btn btn-success view_activity_button generate_report_button1" data-target="questionnaire" onClick={(e) => { GenerateReport(e) }}>Generate Report</button>
                        </div>

                        <div className="list_div_report">

                            <span className="label_tag_report">Data Report</span>
                            <button type="button" className="btn btn-success view_activity_button generate_report_button1" data-target="data_report" onClick={(e) => { GenerateReport(e) }}>Generate Report</button>
                        </div>

                    </div>


                    <Modal show={showsd} onHide={handleClosesd} className="confirm_delete generate_food_diary_report_pop">
                        <Modal.Header closeButton>
                            <Modal.Title>{popuptoplabel}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="from_date_div">
                                <label className='commission_inner_popup_label'>From date<span style={{ color: 'red' }}>*</span></label>
                                <span className="calendar_icon_div fif_change_report">
                                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                                </span>
                                <span className="report_date">
                                    <DatePicker selected={startDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} onChange={(date) => ChangedDate(date)} maxDate={new Date()} ref={datepicker1Ref} />
                                </span>

                            </div>

                            <div className="to_date_div">
                                <label className='commission_inner_popup_label'>To date<span style={{ color: 'red' }}>*</span></label>
                                <span className="calendar_icon_div fif_change_report to_fif_change">
                                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon2()} aria-hidden="true"></i>
                                </span>
                                <span className="report_date">
                                    <DatePicker selected={EndDate} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} onChange={(date) => ChangedEndDate(date)} maxDate={new Date()} ref={datepicker2Ref} />
                                </span>
                            </div>

                            {showerr === true &&
                                <span style={{ color: 'red' }}>Please select valid date</span>}
                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_close_button" variant="secondary" onClick={handleClosesd}>
                                Cancel
                            </Button>
                            <Button variant="primary" className="modal_action_button bootstrp_color_btn float_right" onClick={GenerateFoodDiary}>
                                Generate
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    <Modal show={showsdprogress} onHide={handleClosesdprogress} className="confirm_delete generate_food_diary_report_pop generate_food_diary_report_pop_pdf remove_modal_width">
                        <Modal.Header closeButton>
                            <Modal.Title className="top_label_title_report">Progress Diary report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="progress_diary_export_div" ref={componentRef}>
                            {showTopContent === true &&
                                <div className="info_details_progress">


                                    <div className='topcontent'>
                                        <h5 className="h5_report">Personal Info:</h5>
                                        <div className='user_info report_user_info' data-item={profileData.id}>
                                            <div className='header_box'>
                                                <div className='input_fields'>
                                                    <div className='row personal_info_report_row'>
                                                        <span className="top_span">
                                                            <label className='inner_label'>First Name:  </label>
                                                            <span className='input_value'>{profileData.first_name}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label'>Last Name:  </label>
                                                            <span className='input_value'>{profileData.first_name}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '43px' }}>Gender:  </label>
                                                            <span className='input_value'>{profileData.gender}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '38px' }}>Address:  </label>
                                                            <span className='input_value'>{profileData.user_address}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '13px' }}>Postal code:  </label>
                                                            <span className='input_value'>{profileData.postalCode}</span>
                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                        <div style={{ "clear": "both" }} />
                                    </div>


                                    <div className='topcontent additional_info_report_div'>
                                        <h5 className="h5_report">Additional Info:</h5>
                                        <div className='user_info additional_info_row' data-item={profileData.id}>
                                            <div className='header_box'>
                                                <div className='input_fields'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label className='inner_label'>Age</label><br />
                                                            <div className='input_value'> {profileData.user_dob && profileData.user_dob != "0000-00-00" ? (format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy')): null} Years</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Height</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Weight</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Goal Weight</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input_fields'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label className='inner_label'>Activity Level</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].activity}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Daily Calories</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Water Intake</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_fat}-{profileData.client_infos[0].daily_carb}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div style={{ "clear": "both" }} />
                                    </div>





                                </div>
                            }
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Weight:</div>
                                <div className="col_1">

                                    <span style={{ display: 'inherit', marginBottom: '2px' }}>Target weight:{TargetClientWeight}</span>
                                    <span>Current weight:{WeightTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Weight Chart</span>
                                    <Line
                                        data={Weightstate}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Water:</div>
                                <div className="col_1">

                                    <span>Current water intake:{waterTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Water Chart</span>
                                    <Line
                                        data={Waterstate}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Activity:</div>
                                <div className="col_1">

                                    <span>Current activity:{ActivityTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Activity Chart</span>
                                    <Line
                                        data={ActivityState}
                                        options={ActivityOption}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Sleep:</div>
                                <div className="col_1">

                                    <span>Current sleep hours: {SleepTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Sleep hours Chart</span>
                                    <Line
                                        data={SleepState}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Waist:</div>
                                <div className="col_1">

                                    <span>Current waist : {WaistTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Waist Chart</span>
                                    <Line
                                        data={WaistState}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Neck:</div>
                                <div className="col_1">

                                    <span>Current Neck : {NeckTillSelectedDate}</span></div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Neck Chart</span>
                                    <Line
                                        data={NeckState}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Arms:</div>
                                <div className="col_1">

                                    <span>Current Arms : {ArmsTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Arms Chart</span>
                                    <Line
                                        data={ArmsState}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Thighs:</div>
                                <div className="col_1">

                                    <span>Current Thighs : {ThigsTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Thighs Chart</span>
                                    <Line
                                        data={ThighsState}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row chart_row report_chart_row">
                                <div className="row top_report_chart_row_head">Hip:</div>
                                <div className="col_1">

                                    <span>Current Hip : {HipTillSelectedDate}</span>
                                </div>
                                <div className="col_2">
                                    <span className="chart_top_label_progres_diary">Hip Chart</span>
                                    <Line
                                        data={HipsState}
                                        options={options}
                                    />
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleClosesdprogress}>
                                Cancel
                            </Button>

                            <ReactToPrint documentTitle={'Progress Diary'}
                                trigger={() => <Button variant="primary" className="bootstrp_color_btn">
                                    Export PDF
                                </Button>}
                                content={() => componentRef.current}
                            />
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showsq} onHide={handleClosesq} className="client_report_questionnaire confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Generate Questionnaire report</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Select options={questionnaireselectoptions} placeholder={'Select Intake form'} isMulti={true} onChange={(e) => { SetSelectedQuestionnaire(e) }}  {...(FoodDiaryErr === true ? { id: 'err_border' } : {})} />
                            {
                                FoodDiaryErr == true && <span className="err">{FoodDiaryErrMsg}</span>
                            }



                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_close_button" variant="secondary" onClick={handleClosesq}>
                                Cancel
                            </Button>
                            <Button variant="primary" className="modal_action_button bootstrp_color_btn" onClick={GenerateReportForm}>
                                Generate
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={showsql} onHide={handleClosesql} className="confirm_delete remove_modal_width submitted_qns_pop">
                        <Modal.Header closeButton>
                            <Modal.Title className="top_label_title_report">Submitted Questionnaires</Modal.Title>
                        </Modal.Header>
                        <Modal.Body ref={componentRef1}>

                            {showTopContent === true &&
                                <div className="info_details_progress">


                                    <div className='topcontent'>
                                        <h5 className="h5_report">Personal Info:</h5>
                                        <div className='user_info report_user_info' data-item={profileData.id}>
                                            <div className='header_box'>
                                                <div className='input_fields'>
                                                    <div className='row personal_info_report_row'>
                                                        <span className="top_span">
                                                            <label className='inner_label'>First Name:  </label>
                                                            <span className='input_value'>{profileData.first_name}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label'>Last Name:  </label>
                                                            <span className='input_value'>{profileData.first_name}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '43px' }}>Gender:  </label>
                                                            <span className='input_value'>{profileData.gender}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '38px' }}>Address:  </label>
                                                            <span className='input_value'>{profileData.user_address}</span>
                                                        </span>
                                                        <span className="top_span">
                                                            <label className='inner_label' style={{ marginRight: '13px' }}>Postal code:  </label>
                                                            <span className='input_value'>{profileData.postalCode}</span>
                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                        <div style={{ "clear": "both" }} />
                                    </div>


                                    <div className='topcontent additional_info_report_div'>
                                        <h5 className="h5_report">Additional Info:</h5>
                                        <div className='user_info additional_info_row' data-item={profileData.id}>
                                            <div className='header_box'>
                                                <div className='input_fields'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label className='inner_label'>Age</label><br />
                                                            <div className='input_value'> {profileData.user_dob && profileData.user_dob != "0000-00-00" ? (format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy')) : null} Years</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Height</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Weight</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Goal Weight</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input_fields'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <label className='inner_label'>Activity Level</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].activity}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Daily Calories</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Water Intake</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                                                        </div>
                                                        <div className='col'>
                                                            <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                                            <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_fat}-{profileData.client_infos[0].daily_carb}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div style={{ "clear": "both" }} />
                                    </div>





                                </div>
                            }

                            {QnAnsArray.length > 0 && QnAnsArray.map((qnsans, index) => (
                                <div className="form_row_report">
                                    <span className="top_label_qn">Form Name: {qnsans.FormName}</span>

                                    <ReactFormGenerator


                                        answer_data={qnsans.ans}

                                        data={qnsans.qn}
                                        hide_actions={true}
                                        read_only={true}
                                    />

                                </div>

                            ))}


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleClosesql}>
                                Cancel
                            </Button>
                            <ReactToPrint documentTitle={'Intake Forms'}
                                trigger={() => <Button variant="primary" className="bootstrp_color_btn">
                                    Export PDF
                                </Button>}
                                content={() => componentRef1.current}
                            />
                        </Modal.Footer>
                    </Modal>

                </div>
                {
                    PopShow===true && 
                    <GenerateAddData showsdr={showsdr} handleClosesdr={handleClosesdr} profileData={profileData} CustomCategoryList={CustomCategoryList}  />
                }

            </section>





        </div>

    )

}

export default ClientGenerateReport
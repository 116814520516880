//import React from 'react'
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import mainLogo from '../images/fitpal_logo.png';
import mainLogoh from '../images/fitpal_half.png';
import { useLocation } from 'react-router-dom'
import IdleSessionLogout from './IdleSessionLogout';

import '../css/style.css';


import 'font-awesome/css/font-awesome.min.css';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Link, useNavigate, NavLink } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import moment from 'moment';
import Dashboard from './AdminComponents/Dashboard';
import AdminNav from './AdminNav';
import ClientNav from './ClientNav';
import NutritionNav from './NutritionNav';
import female_icon from '../images/female.png'
import male_icon from '../images/male.png'
import Background from '../images/notification_icon.png'
import AdminHamMenu from './ResponsiveComponents/AdminNavHam';
import CoachHamMenu from './ResponsiveComponents/CoachNavHam';
import ClientHamMenu from './ResponsiveComponents/ClientNavHam';
import ManagerHamMenu from './ResponsiveComponents/ManagerNavHam';

import ManagerNav from './ManagerNav';

var QB = require('quickblox/quickblox.min');

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}


const HeaderNutrition = () => {

    const [first_name, setFName] = useState('');
    const [last_name, setLName] = useState('');
    const [gender, setGender] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [profileDropdown, setprofileDropdown] = useState(false);
    const [open, setOpen] = useState(true);
    const [profilephoto, Setprofilephoto] = useState('');
    const [NotificationCount, SetNotificationCount] = useState(0);
    const [NotificationListShow, SetNotificationList] = useState([]);
    const [messageNew, setmessageNew] = useState(false);
    const [glpuser,Setglpuser]=useState('')

    var socketconnection;

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();   
        
        localStorage.setItem("side_nav", false);

        var element = document.getElementById("userDropdown");
        element.classList.remove('true');
        element.classList.add('false');
    }, []);

    const refreshToken = async () => {
        try {
            const refreshToken=localStorage.getItem('Refreshtoken')
            // const response = await axios.get('/token?refreshtoken='+refreshToken+'',{withCredentials: true, credentials: 'include'});

            const response =await axiosJWT.post('/token',
            {
                refreshtoken: refreshToken

            }
           );
             if(response.status === 200)
            {
                
                setToken(response.data.accessToken);

                const decoded = jwt_decode(response.data.accessToken);

                socketconnection = socketIOClient.connect("" + process.env.REACT_APP_BACKEND_URL + "?UserId=" + decoded.userId + "")

                socketconnection.on("notification", (data) => {

                    var LoggedUserid = data.user_id.toString()

                    if (localStorage.getItem('userId') === LoggedUserid) {
                        SetNotificationCount(data.NotCount)
                        SetNotificationList(data.NotResponse)
                    }

                });

                setFName(decoded.first_name);
                setLName(decoded.last_name);
                setExpire(decoded.exp);
                setRole(decoded.role);
                setGender(decoded.gender);
                localStorage.setItem("first_name", decoded.first_name);
                localStorage.setItem("last_name", decoded.last_name);
                localStorage.setItem("gender", decoded.gender);
                localStorage.setItem("email", decoded.email);
                //localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("expire", decoded.exp);
                localStorage.setItem("role", decoded.role);
                localStorage.setItem("userId", decoded.userId);
                localStorage.setItem("user_timezone", decoded.user_timezone);

                getProfile();
                load_quickblox();
            }
            else
            {
             
                localStorage.setItem("first_name", '');
                localStorage.setItem("last_name", '');
                localStorage.setItem("gender", '');
                localStorage.setItem("token", '');
                localStorage.setItem("expire", '');
                localStorage.setItem("role", '');
                localStorage.setItem("userId", '');
                localStorage.setItem("user_timezone", '');
                localStorage.setItem('QB_SESSION_RESPONSE', '');
                localStorage.setItem('QB_USER_TOKEN', '');
                localStorage.setItem("email",'');
                localStorage.setItem('subscriptionID', '');
                localStorage.setItem('subscriptionSdate', '');
                localStorage.setItem('subscriptionEdate', '');
                navigate("/login", { replace: true });
                // QB.destroySession();           
            }
        } catch (error) {
          
            localStorage.setItem("first_name", '');
            localStorage.setItem("last_name", '');
            localStorage.setItem("gender", '');
            localStorage.setItem("token", '');
            localStorage.setItem("expire", '');
            localStorage.setItem("role", '');
            localStorage.setItem("userId", '');
            localStorage.setItem("user_timezone", '');
            localStorage.setItem('QB_SESSION_RESPONSE', '');
            localStorage.setItem('QB_USER_TOKEN', '');
            localStorage.setItem("email",'');
            localStorage.setItem('subscriptionID', '');
            localStorage.setItem('subscriptionSdate', '');
            localStorage.setItem('subscriptionEdate', '');

            // QB.destroySession();
            //if (error.response) {
                navigate("/login", { replace: true });
            //}
        }
    }


    const axiosJWT = axios.create();

    const getProfile = async () => {
        const token = localStorage.getItem("token");
       
        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.data.profile_details)
        {
            if(response.data.profile_details[0])
            {
                if(response.data.profile_details[0].is_glp_user)
                {
                     Setglpuser(response.data.profile_details[0].is_glp_user)
                }

            }
        }

        console.log('glppproofff',response)

        const Notresponse = await axiosJWT.get('/trigger-notification', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetNotificationCount(Notresponse.data.NotCount);
        SetNotificationList(Notresponse.data.NotResponse);


        if (response.data.profile_details && response.data.profile_details[0] && response.data.profile_details[0].client_infos && response.data.profile_details[0].client_infos !== null && response.data.profile_details[0].client_infos !== '' && response.data.profile_details[0].client_infos[0].subscription_plan !== '') {

            localStorage.setItem("subscriptionID", response.data.profile_details[0].client_infos[0].subscription_plan);
        }
        if (response.data.profile_details && response.data.profile_details[0] && response.data.profile_details[0].client_infos && response.data.profile_details[0].client_infos !== null && response.data.profile_details[0].client_infos !== '' && response.data.profile_details[0].client_infos[0].subscription_s_date !== '') {

            localStorage.setItem("subscriptionSdate", response.data.profile_details[0].client_infos[0].subscription_s_date);
        }
        if (response.data.profile_details && response.data.profile_details[0] && response.data.profile_details[0].client_infos && response.data.profile_details[0].client_infos !== null && response.data.profile_details[0].client_infos !== '' && response.data.profile_details[0].client_infos[0].subscription_e_date !== '') {
      
            localStorage.setItem("subscriptionEdate", response.data.profile_details[0].client_infos[0].subscription_e_date);
        }
        if (response.data.profile_details[0].user_profile_photo !== null && response.data.profile_details[0].user_profile_photo !== '') {
            Setprofilephoto(response.data.profile_details[0].user_profile_photo);
        }
    }

    axiosJWT.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response && error.response.data) {
            navigate("/login", { replace: true });
        }
        return Promise.reject(error.message);
    });


    /*axiosJWT.interceptors.request.use(async (config) => {  
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
         const response = await axios.get('/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setFName(decoded.first_name);
            setLName(decoded.last_name);
            setExpire(decoded.exp);
            localStorage.setItem("first_name", decoded.first_name);
            localStorage.setItem("role", decoded.role); 
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });  */

    const logout = async () => {

        // QB.init(localStorage.getItem('QB_USER_TOKEN'));
        QB.destroySession(localStorage.getItem('QB_USER_TOKEN'));
        QB.chat.disconnect();
        QB.logout();
        // QB.chat.onDisconnectedListener = onDisconnectedListener;

        localStorage.setItem('token', '')
        localStorage.setItem("first_name", '');
        localStorage.setItem("last_name", '');
        localStorage.setItem("gender", '');
        localStorage.setItem("expire", '');
        localStorage.setItem("role", '');
        localStorage.setItem("userId", '');
        localStorage.setItem("user_timezone", '');
        localStorage.setItem('QB_SESSION_RESPONSE', '');
        localStorage.setItem('QB_USER_TOKEN', '');
        localStorage.setItem("email",'');
        localStorage.setItem('subscriptionID', '');
        localStorage.setItem('subscriptionSdate', '');
        localStorage.setItem('subscriptionEdate', '');

        const RefreshToken=localStorage.getItem('Refreshtoken')
        localStorage.setItem('Refreshtoken', '');       
        
        window.location.reload(true);

        setRole('');

        try {
            const res = await axios.delete('logout', { data: { refreshtoken: RefreshToken } });
            navigate("/login", { replace: true });           
        } catch (error) {
            console.log(error);
        }
    }

    function onDisconnectedListener() {
        console.log("onDisconnected");
    }


    // const Openuserdrptag = () => {  


    //      if (profileDropdown) {
    //         document.addEventListener("click", closeuserdrptag)

    //       } else {
    //         document.removeEventListener("click", Openuserdrptag)

    //       } 
    // }

    // const closeuserdrptag = () => {           

    //     if (profileDropdown) {
    //         document.addEventListener("click", closeuserdrptag)

    //       } else {
    //         document.removeEventListener("click", Openuserdrptag)

    //       } 
    // }


    const closeOpenNav = () => {
        var i = 0;
        if (open === true) {

            document.getElementById("sidenav").setAttribute("class", "sidenav-outer closeNavwidth");

            document.getElementById("fa-icon").setAttribute("class", "fa fa-chevron-circle-right sidenav_topicon");
            document.getElementById("container-adjust").setAttribute("class", "Container-enlarge");
            document.getElementById("container-adjusts").setAttribute("class", "Container-enlarge");
            document.getElementById("clickimg").setAttribute("src", mainLogoh);
            document.getElementById("clickimg").style.cssText = ` width: 40%; margin: 29px 27px; `;
            var x = document.getElementById("change-a-tag").querySelectorAll(".side-navli");

            for (i = 0; i < x.length; i++) {

                x[i].style.display = "none"
            }

            // document.getElementById("addmember").style.display = "none";
            // document.getElementById('Addmember').style.left = "29px";
            document.getElementById('Forscroll').style.textAlign = "center";
            setOpen(false);

            localStorage.setItem("side_nav", true);
        }
        else if (open === false) {

            document.getElementById("sidenav").setAttribute("class", "sidenav-outer openNavwidth");

            document.getElementById("fa-icon").setAttribute("class", "fa fa-chevron-circle-left sidenav_topicon");
            document.getElementById("container-adjust").setAttribute("class", "Container-shrink");
            document.getElementById("container-adjusts").setAttribute("class", "Container-shrink");
            document.getElementById("clickimg").setAttribute("src", mainLogo);
            document.getElementById("clickimg").style.cssText = ` width: 90%; margin: 13px 0px; `;
            var y = document.getElementById("change-a-tag").querySelectorAll(".side-navli");

            for (i = 0; i < y.length; i++) {
                y[i].style.display = "initial";
            }

            // document.getElementById("addmember").style.display = "initial";
            // document.getElementById('Addmember').style.left = "7px";
            document.getElementById('Forscroll').style.textAlign = "initial";
            setOpen(true);

            localStorage.setItem("side_nav", false);
        }

    }

    const TriggerDrop = () => {

        var element = document.getElementById("userDropdown")
        if (element.classList.contains('true')) {
            element.classList.remove('true');
            element.classList.add('false');
        }
        else {
            element.classList.remove('false');
            element.classList.add('true');
        }
    }

    const TriggerDropNot = () => {

        var element = document.getElementById("userDropdownnot")
        if (element.classList.contains('true')) {
            element.classList.remove('true');
            element.classList.add('false');
        }
        else {
            element.classList.remove('false');
            element.classList.add('true');
        }
    }

    const e = document.getElementById('root');


    e.addEventListener('click', function handleClick(event) {

        if ((event.target.classList[0] !== 'top_profile_pic') && (event.target.classList[0] !== 'drop_check') && (event.target.classList[0] !== 'notif')) {
            var element = document.getElementById("userDropdown");
            var notelement = document.getElementById("userDropdownnot");

            if(notelement)
            notelement.classList.remove('true');

            if(element)
            element.classList.remove('true');
        }


        if (event.target.classList[0] === 'side-navli' && event.target.classList[0] !== 'check') {
            document.getElementsByClassName("mobile_menu")[0].style.display = "none";
            document.getElementsByClassName("ham-menu")[0].style.display = "flex";
        }
    });

    const checkRender = (data) => {

        var datarender;

        if (data.path === '') {
            datarender = <p onClick={(e) => { ClearThisNot(e) }} id={data.id}>{data.category}</p>;
        }
        else {
            datarender = <a onClick={(e) => { ClearThisNot(e) }} id={data.id} href={data.path}>{data.category}</a>
        }

        return datarender;
    }

    const ClearThisNot = (e) => {

        const response = axiosJWT.post('trigger-notification/clear-one-notification',
            {
                id: e.target.getAttribute('id')

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            RefreshNotification();
        }
    }

    const clearAllNotification = async () => {

        const response = await axiosJWT.post('trigger-notification/clear-all-notification',
            {


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            RefreshNotification();
        }
    }

    const RefreshNotification = async () => {

        const Notresponse = await axiosJWT.get('/trigger-notification', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        SetNotificationCount(Notresponse.data.NotCount);
        SetNotificationList(Notresponse.data.NotResponse);
    }


    const QB_app_connect = async () => {
        var APPLICATION_ID = process.env.REACT_APP_QB_APPLICATION_ID;
        var AUTH_KEY = process.env.REACT_APP_QB_AUTH_KEY;
        var AUTH_SECRET = process.env.REACT_APP_QB_AUTH_SECRET;
        var ACCOUNT_KEY = process.env.REACT_APP_QB_ACCOUNT_KEY;
        var CONFIG = { 
            debug: true,
            webrtc: {
                answerTimeInterval: 60,
                autoReject: true,
                incomingLimit: 1,
                dialingTimeInterval: 5,
                disconnectTimeInterval: 300,
                statsReportTimeInterval: false
            }
        };

        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);        
    }


    const load_quickblox = async () => {
        if (QB) {
            
            const qb_ctime = localStorage.getItem("QB_SESSION_CTIME");
            const current_unix_time = moment().format("X");
            var check_qb_token_expire = (current_unix_time-qb_ctime);

            // console.log('mi:')

            var qb_session_expired = false;
            if(check_qb_token_expire >= 7200)
            qb_session_expired = true;

            if(!localStorage.getItem('QB_USER_TOKEN') || localStorage.getItem('QB_USER_TOKEN') === '' || qb_session_expired === true) 
            {      
                // console.log('mi: header new session');

                QB_app_connect();

                create_session();
            }
            else {

                // console.log('mi: header exist session');

                await QB_app_connect();                
                
                var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
                var userId = session.user_id;
                var password = session.token;
                var params = { userId, password };
        
                if(userId && password)
                {
                    QB.chat.connect(params, function (error, contactList) {
                        if(error)
                        {                            
                            // console.log('mi: header error connect');
                            //console.log(error)
                           
                            create_session();                            
                        }
                        else
                        {
                            // console.log('mi: header connect success'); 
                           loaddialogList();                                     
                        }
                    });
                }
            }
        }
    }

    const create_session = () => {

        // console.log("mi: header create session entered");

        const loggedin_role = localStorage.getItem("role");
        const loggedin_token = localStorage.getItem("token");        
        
        if (loggedin_role === 'Admin') {

            // console.log("mi: header create session admin");

            QB.createSession({ login: process.env.REACT_APP_QB_ADMIN_LOGIN, password: process.env.REACT_APP_QB_ADMIN_PASSWORD }, function (error, result) {
                // callback function
                if (result) {    
                    localStorage.setItem('QB_SESSION_RESPONSE', JSON.stringify(result));
                    localStorage.setItem('QB_USER_TOKEN', result.token); 
                    localStorage.setItem('QB_SESSION_CTIME',moment().format('X'));

                    loaddialogList();
                }
            });
        }
        if (loggedin_role === 'Nutrition' || loggedin_role === 'Client' || loggedin_role === 'Manager') 
        {
            // console.log("mi: header create session non admin");

            axiosJWT.get('/user',
                {
                    headers: {
                        Authorization: `Bearer ${loggedin_token}`
                    }
                }).then((response) => {
                    console.log(response.data);
                    console.log("mi: header-new session-user data");
                    if(response.data.id)
                    {
                        //connect with quickblox
                        // var created_timestamp = (Math.floor(new Date(response.data.createdAt).getTime() / 1000)).toString();
                        // var qb_user_login = "KD" + response.data.id + "_" + created_timestamp.substring(5, 10);
                        // var qb_user_pwd = "Keto" + response.data.id + created_timestamp.substring(1, 6);
                        var qb_user_login = response.data.qb_login;
                        var qb_user_pwd = response.data.qb_password;



                        QB.createSession({ login: qb_user_login, password: qb_user_pwd }, function (error, result) {
                            if(error)
                            {
                                // console.log("mi: header-create-session error");
                                // console.log(error);
                                if(error.status === 'error')
                                QB.destroySession();
                            }
                            if (result) {
                                // console.log("mi: header-create-session success");

                                localStorage.setItem('QB_SESSION_RESPONSE', JSON.stringify(result));
                                localStorage.setItem('QB_USER_TOKEN', result.token);
                                localStorage.setItem('QB_SESSION_CTIME',moment().format('X'));

                                loaddialogList();
                            }
                        });
                    }
                });
        }
    }

    
    const loaddialogList = async () => {
        if (QB) {            

                // console.log("mi: header-loaddialog");
                
               QB.init(localStorage.getItem('QB_USER_TOKEN'));

                let params = {
                    sort_desc: 'last_message_date_sent',
                    limit: 1
                };

                QB.chat.dialog.list(params, function (error, dialogs) {
                    //console.log(dialogs.items)

                    var uncread_count = dialogs.items[0].unread_messages_count;                   
                    
                    if(uncread_count > 0)
                    setmessageNew(true);
                    else
                    setmessageNew(false);

                    QB.chat.disconnect();
                });  
        }
    }

    


    return (
        <div>
            
            <IdleSessionLogout />
            <header className='header' >
                <div id="sidenav" className='sidenav-outer openNavwidth'>
                    <img id='clickimg' src={mainLogo} alt="mainLogo" />
                    <a className='closebtn' onClick={closeOpenNav} id='open'><i id='fa-icon' className='fa fa-chevron-circle-left sidenav_topicon' aria-hidden="true"></i>.</a>

                    <div className='sidenav-inner' id="change-a-tag">
                        <div className='forscroll' id='Forscroll'>

                            {
                                (role === 'Admin')
                                    ? <AdminNav data={messageNew}/>
                                    : null
                            }

                            {
                                (role === 'Client')
                                    ? <ClientNav data={messageNew} glpuser={glpuser}/>
                                    : null
                            }

                            {
                                (role === 'Nutrition')
                                    ? <><NutritionNav data={messageNew}/></>
                                    : null
                            }

                            {
                                (role === 'Manager')
                                    ? <ManagerNav data={messageNew}/>
                                    : null
                            }

                        </div>
                    </div>

                    {/* {(role === 'Nutrition') ?
                    <div className='addmember-logo'> <NavLink to='/coach/add-member' style={{"position":"absolute","bottom":"0"}}><i id="Addmember" className="fa fa-user-plus" aria-hidden="true"></i><span id="addmember" style={{color:'white'}}>Add Members</span></NavLink> </div> 
                    : null } */}
                </div>
            </header>


            <div id="container-adjust" className="Container-shrink">
                <div className="row common">
                    {
                        (role === 'Admin')
                            ? <AdminHamMenu data={messageNew}/>
                            : null
                    }
                    {
                        (role === 'Nutrition')
                            ? <CoachHamMenu data={messageNew}/>
                            : null
                    }

                    {
                        (role === 'Client')
                            ? <ClientHamMenu data={messageNew} glpuser={glpuser}/>
                            : null
                    }
                    {
                         (role === 'Manager')
                         ?<ManagerHamMenu data={messageNew}/>
                         : null                        
                    }

                    <nav className="navbar navbar-light  justify-content-between reponsive_change_top_nav">
                        <div className="col-sm first_row_notifi">
                            {/* <form className="form-inline">
                                <input className="form-control mr-sm-2" type="search" placeholder="&#xF002;  Search" aria-label="Search" style={{ font: "Arial, FontAwesome" }} />
                            </form> */}
                        </div>
                        <div className="col-sm second_row_notifi">

                            <div className="userdrptag">

                                <span className='top_header_name'>Hi, {first_name + ' ' + last_name}</span>

                                <span className="notif" onClick={TriggerDropNot} style={{ backgroundImage: `url(${Background})` }}>{NotificationCount !== 0 && <span className="num">{NotificationCount}</span>}</span>

                                <div id="userDropdownnot" className={"userdrp-content notification_drop_changes false"} >

                                {NotificationListShow && NotificationListShow.length > 0 ?
                                    <div className='notification_list'>
                                        {NotificationListShow && NotificationListShow.length > 0 && NotificationListShow.map((notification, index) => (

                                            <div key={index}>
                                            {checkRender(notification)}
                                            </div>

                                        ))}
                                    </div>
                                    : null }

                                    {NotificationListShow.length === 0 &&
                                        <span>No Notifications found</span>
                                    }


                                    {NotificationCount > 0 &&

                                        <span className='mark_as_read' onClick={clearAllNotification}>Clear All</span>
                                    }

                                </div>

                                <a onClick={TriggerDrop} id='open'><img src={profilephoto !== '' && profilephoto !== null ? profilephoto : (gender === "MALE" ? male_icon : female_icon)} alt="userdrptag" className='top_profile_pic' /><span id="Userdrptag-icon" className="userdrptag-icon"><i className="drop_check fa fa-chevron-down" aria-hidden="true"></i></span> </a>
                                <div id="userDropdown" className={"userdrp-content false"} >
                                    {role === "Admin" ?
                                        <Link to="/admin/dashboard">Home</Link>
                                        :
                                        (role === "Nutrition" ?
                                            <Link to="/coach/dashboard">Home</Link>
                                            :
                                            <Link to="/client/dashboard">Home</Link>)
                                    }
                                    <Link to="/profile">My account</Link>
                                    {role !== "Admin" ?
                                        <Link to="/contact">Contact</Link>
                                        : null}
                                          {role === "Client" ?
                                        <Link to="/client/withings-order">Withings Order</Link>
                                        : null}
                                    <a onClick={()=>logout()}>Logout</a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default HeaderNutrition
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import IframeResizer from 'iframe-resizer-react'

const ConnectedDevices = (props) => {

    const token = localStorage.getItem("token");
    const [profileData, setprofileData] = useState('');
    const [loadtab, setloadtab] = useState(false);
    const [iframeURL, setiframeURL] = useState('');

    useEffect(() => {
        loading_icon('show');
        getDashboard();
    }, []);

    const axiosJWT = axios.create();

    
    const getDashboard = async () => {       

        var user_response = await axiosJWT.get("/user",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        
        //console.log(user_response);

        if(user_response)
        {
            if(!user_response.data.hu_userid)
            {

                if(user_response.data.headsup_enable === true)
                {
                    const user_headsup_response = await axiosJWT.get("/update-headsup-practitioner",
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if(user_headsup_response.status === 200)
                    {
                        user_response = await axiosJWT.get("/user",
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                        setprofileData(user_response.data);            
                        var securelink = await getSecureLink(user_response.data);
                    
                        loading_icon('hide');
                        loadIframe("practitioner",securelink);
                    }
                    else
                    {
                        loading_icon('hide');
                    }
                }
                else
                {
                   loading_icon('hide');
                }
            }
            else
            {
                if(user_response.data.headsup_enable === true)
                {
                    setprofileData(user_response.data);            
                    var securelink = await getSecureLink(user_response.data);
                    
                    loading_icon('hide');
                    loadIframe("practitioner",securelink);
                }
                else
                {
                   loading_icon('hide');
                }
            }
        }
        else
        {
            loading_icon('hide');
        }
    }

    const getSecureLink = async (userdata) => {
 
        const request = await fetch(process.env.REACT_APP_HEADSUP_URL+"/api/v1/organizations/"+process.env.REACT_APP_HEADSUP_ORGID+"/secure_user_link_data/"+userdata.hu_userid,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_HEADSUP_KEY}`
              },
            }
        );       
      
        const headsup_response = await request.json();

        return headsup_response.secure_user_link_data;
    }

    const ChangeTab = async (e) => {
        const alltabs = document.querySelectorAll(".device_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        e.target.setAttribute("class", "active");
        var tab = e.target.getAttribute('data-tab');

        console.log(tab)

        var securelink = await getSecureLink(profileData);

        loadIframe(tab,securelink);    
    }

    const loadIframe = async (tab,securelink) => {        

        //var iframe_url = process.env.REACT_APP_HEADSUP_URL+"/"+tab+"?org_uuid="+profileData.hu_usertoken+"&sec_p="+securelink;

        var iframe_url = process.env.REACT_APP_HEADSUP_VIEW_URL+tab+"?org_uuid="+process.env.REACT_APP_HEADSUP_ORGID+"&sec_p="+securelink;
   
        setiframeURL(iframe_url);

         setloadtab(true); 
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    return (
        <div className='connected_devices_tab'>
            <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
            {profileData ? 
            <>
                {/* <div className='menubox'>
                    <div className='device_tabmenu'>
                        <a className="active" id="service" data-tab={'dashboard'} onClick={(e) => { ChangeTab(e) }}>Dashboard</a>
                        <a className="" id="category" data-tab={'analyzer'} onClick={(e) => { ChangeTab(e) }}>Reports</a>
                    </div>
                </div> */}
                {loadtab === true ?
                <IframeResizer
                src={iframeURL} 
                style={{ width: '1px', minWidth: '100%', minHeight: '1000px'}}
                />             
                : null }
            </>
            : 
            <div className='headsup_connect_access'>
                <p>If you do not receive a heads-up invitation from the site administrator. Please contact the site administrator to request an invite.!</p>
                <p>If you accepted the site administrator's invitation and completed the signup process. Please request that the site administrator grant you access to the headsup dashboard.</p>
            </div>
            }             
        </div>
    )
}

export default ConnectedDevices
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import Promocode from './Marketing/Promocode'
import Referrals from './Marketing/Referrals'
import Welcomemail from './Marketing/Welcomemail'
import Emailuser from './Marketing/Emailuser'
import Inviteuser from './Marketing/Inviteuser'

const Marketing = () => {

    const side_nav = localStorage.getItem("side_nav");
    const [tabpage, setTabMenu] = useState('promocode');

    const changeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setTabMenu(tab_for);

    }

    useEffect(()=>{
        document.title = 'Admin-Marketing';
    },[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="adminmarketing container">
                    <div className='menubox'>
                        <div className='tabmenu'>
                            <a className="active" onClick={changeTab} data-item="promocode">Promo Codes</a>
                            <a className='' onClick={changeTab} data-item="referrals">Referrals</a>
                            <a className='' onClick={changeTab} data-item="welcomemail">Welcome Email</a>
                            <a className='' onClick={changeTab} data-item="emailuser">Email User</a>
                            <a className='' onClick={changeTab} data-item="inviteuser">Invite User</a>
                        </div>
                    </div>


                    {(tabpage === 'promocode') ? <Promocode /> : null}

                    {(tabpage === 'referrals') ? <Referrals /> : null}

                    {(tabpage === 'welcomemail') ? <Welcomemail /> : null}

                    {(tabpage === 'emailuser') ? <Emailuser /> : null}

                    {(tabpage === 'inviteuser') ? <Inviteuser /> : null}

                </div>
            </section>
        </div>
    )
}

export default Marketing
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, createRef } from 'react'
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InfiniteScroll from 'react-infinite-scroller';
import { saveAs } from 'file-saver'
import deliveredicon from "../../../images/messages/delivered.svg"
import readicon from "../../../images/messages/read.svg"
import imageicon from "../../../images/messages/image.svg"
import sendicon from "../../../images/messages/Send.svg"
import closeicon from "../../../images/messages/Close.svg"


var QB = require('quickblox/quickblox.min');

const ConnectedClientMessages = (props) => {

    const client_id = props.data;
    const messageEl = useRef(null);

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const coach_timezone = localStorage.getItem("user_timezone");
    const loggedin_userid = localStorage.getItem('userId');
    const coach_first_name = localStorage.getItem("first_name");
    const coach_last_name = localStorage.getItem("last_name");

    localStorage.setItem("chatDate", '');

    const [dialogData, setdialogData] = useState([]);
    const [messageList, setmessageList] = useState([]);
    const [messageText, setmessageText] = useState('');
    const [selectedFile, setselectedFile] = useState('');
    const [Preview, setPreview] = useState('');
    const [totalmsgpageCount, settotalmsgpageCount] = useState('');
    const [showMore, setshowMore] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [showmoreEnable, setshowmoreEnable] = useState(false);
    const [activePage, setactivePage] = useState('');
    const [show, setshow] = useState(false);
    const [loadImg, setloadImg] = useState('');
    const [attachmentFileid, setattachmentFileid] = useState('');
    const [loggedin_userQBId, setloggedin_userQBId] = useState('');
    const [loggedin_userQBname, setloggedin_userQBname] = useState('');
    const [opponent_id, setopponent_id] = useState('');
    const [opponent_name, setopponent_name] = useState('');
    const [dmToggle, setdmToggle] = useState(false);
    const [sessionStart, setsessionStart] = useState(false);
    const [clientQBId, setclientQBId] = useState('');
    const [clientName, setclientName] = useState('');
    const [coachName, setcoachName] = useState(coach_first_name+' '+coach_last_name);

    useEffect(() => {
        loading_icon('show');

        setTimeout(() => {
            load_messages();
        }, 3000);

    }, []);

    const axiosJWT = axios.create();

    const handleClose = () => { setshow(false) }
      
    const load_messages = async () => {       

        if (QB && localStorage.getItem('QB_USER_TOKEN') !== '') 
        {
            QB.init(localStorage.getItem('QB_USER_TOKEN'));

            var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
            var userId = session.user_id;
            var password = session.token;
            var params = { userId, password };

            if(userId && password)
            {
                setsessionStart(true);
                                        
                setloggedin_userQBId(session.user_id);
                    
                QB.chat.connect(params, function (c_error, contactList) {
                    if (c_error) {
                        //create_session();
                        console.log(c_error);
                    }
                    else {   
                        getdialogId(session.user_id);
                        loading_icon('hide');  
                    }
                });
            }
        }        
    }

    const getdialogId = async (coach_qb_id) => {        

        var client_details = await axiosJWT.get("/user/" + client_id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var client_qbid = client_details.data.qb_userid;
        setclientQBId(client_qbid);
        setclientName(client_details.data.first_name+" "+client_details.data.last_name)

        if (QB) {
            if (coach_qb_id) {
                let params = {
                    occupants_ids: {
                        all: [coach_qb_id,client_qbid]
                    }
                };

                QB.chat.dialog.list(params, function (error, dialogs) {
                    if(dialogs.items.length>0)
                    {
                        setdialogData(dialogs.items[0]);                   
                        console.log(dialogs.items)
                        loadChat(dialogs.items[0]._id);
                    }
                    else
                    {
                        setdialogData([]);
                    }
                });
            }
        }
    }

    const loadChat = async (dialogId) => {
        console.log(dialogId);
        if (QB) {

            setdmToggle(false);

            QB.chat.message.list({ chat_dialog_id: dialogId, count: 1 }, function (err, messagesCount) {
                if (messagesCount) {

                    var page_count = Math.ceil(messagesCount.items.count / 50);
                    settotalmsgpageCount(page_count);
                    setactivePage(1);

                    if (messagesCount.items.count > 50)
                        setshowmoreEnable(true);
                    else
                        setshowmoreEnable(false);

                } else {
                    console.log(err);
                }
            });

            var params = {
                chat_dialog_id: dialogId,
                sort_desc: 'date_sent',
                limit: 50,
                skip: 0
            };

            QB.chat.message.list(params, function (error, messages) {
                // console.log(messages)
                var messagedata = messages.items;
                var reversedata = messagedata.reverse();
                setmessageList(reversedata);
            });

            closePreview();
        }
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    function getFileURL(fileid) {
        if (QB) {
            if (fileid !== '') {
                var fileUrl = QB.content.privateUrl(fileid);
                if (fileUrl) {
                    return fileUrl;
                }
            }
        }
    }

    const sendMessage = async () => {
        if (QB) {

            // var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
            //     var userId = session.user_id;
            //     var password = session.token;
            //     var params = { userId, password };

            //     if(userId && password)
            //     {


            //         QB.chat.connect(params, function (c_error, contactList) {

            var dialog_users = dialogData.occupants_ids;

            var opponent_id, messagupdated;

            dialog_users.forEach(element => {
                if (parseInt(element) !== loggedin_userQBId)
                    opponent_id = parseInt(element);
            });

            if (messageText !== '') {
                var message = {
                    type: "chat",
                    body: messageText,
                    extension: {
                        save_to_history: 1,
                        dialog_id: dialogData._id,
                    },
                    markable: 1,
                };                                

                try {
                    QB.chat.send(opponent_id, message);
                    setmessageText('');
                    messagupdated = true;
                } catch (e) {
                    if (e.name === "ChatNotConnectedError") {
                        console.log("not connected to chat");
                    }
                }                
            }

            if (selectedFile) {
                var fileParams = {
                    name: selectedFile.name,
                    file: selectedFile,
                    type: selectedFile.type,
                    size: selectedFile.size,
                    public: false
                };
                QB.content.createAndUpload(fileParams, function (error, result) {
                    if (!error) {

                        try {
                            // prepare a message
                            var message = {
                                type: "chat",
                                body: "[attachment]",
                                extension: {
                                    save_to_history: 1,
                                    dialog_id: dialogData._id,
                                    attachments: [{ id: result.uid, type: "image" }]
                                }
                            };

                            // send the message
                            QB.chat.send(opponent_id, message);

                            messagupdated = true;
                        } catch (e) {
                            if (e.name === "ChatNotConnectedError") {
                                console.log("not connected to chat");
                            }
                        }
                    }
                });                
            }

            if (messagupdated === true) {
                var params = {
                    chat_dialog_id: dialogData._id,
                    sort_desc: 'date_sent',
                    limit: 50,
                    skip: 0
                };

                QB.chat.message.list(params, function (error, messages) {
                    //console.log(messages)
                    var messagedata = messages.items;
                    var reversedata = messagedata.reverse();
                    setmessageList(reversedata);
                });
            }
        //}); }
        }        
    }

    const file_select = async (e) => {

        var filedata = e.target.files[0];

        setselectedFile(filedata);

        const objectUrl = URL.createObjectURL(filedata)
        setPreview(objectUrl)

        // console.log(filedata);
    }

    const closePreview = () => { setPreview(''); setselectedFile(''); }

    function getchatDate(timestamp) {

        const chatDate = localStorage.getItem("chatDate");

        var chat_date = moment.tz(moment.unix(timestamp), coach_timezone).format('D MMM');

        var current_date = moment.tz(moment(new Date()), coach_timezone).format('D MMM');

        var yesterday_date = moment.tz((moment().add(-1, 'days')), coach_timezone).format('D MMM');



        if (chatDate !== chat_date) {
            localStorage.setItem("chatDate", chat_date);

            if (current_date === chat_date)
                return "Today";
            else if (yesterday_date === chat_date)
                return "Yesterday";
            else
                return chat_date;
        }
        else
            return '';
    }

    if(sessionStart === true)
    {
    QB.chat.onMessageListener = onMessage;
    }

    function onMessage(userId, message) {
        if (dialogData !== null) {
            loadChat(dialogData._id);
        }
    }

    const loadmore = () => {
        if (QB) {
            var current_page = activePage;
            if (current_page < totalmsgpageCount) {
                if (fetching)
                    return;

                setFetching(true);

                var params = {
                    chat_dialog_id: dialogData._id,
                    sort_desc: 'date_sent',
                    limit: 50,
                    skip: (50 * current_page)
                };

                QB.chat.message.list(params, function (error, messages) {
                    var messagedata = messages.items;
                    var reversedata = messagedata.reverse();

                    setmessageList([...reversedata, ...messageList]);

                    setFetching(false);

                    setshowMore(false);

                    setactivePage((current_page + 1));

                    if ((current_page + 1) < totalmsgpageCount)
                        setshowmoreEnable(true);
                    else
                        setshowmoreEnable(false);
                });
            }
        }
    }

    const setmore = () => { setshowMore(true); }

    const load_image = (fileid) => {
        if (QB) {
            if (fileid) {
                var fileUrl = QB.content.privateUrl(fileid);
                /*if (fileUrl) {
                    return fileUrl;
                }*/

                if (fileUrl) {
                    setshow(true);

                    setloadImg(fileUrl);
                    setattachmentFileid(fileid);

                    // console.log(fileUrl);
                }
            }
        }
    }

    const downloadAttachment = (fileid) => {
        if (QB) {
            if (fileid) {
                var fileUrl = QB.content.privateUrl(fileid);

                const [url, token] = fileUrl.split('?token=');

                fetch(url + '.json', {
                    headers: { 'QB-Token': token }
                }).then(res => res.blob())
                    .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);
                        saveAs(blobUrl, "attachment_" + (Date.now()));
                    });

            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {

            e.preventDefault();

            if (messageText.trim() !== '')
                sendMessage();
        }
    }

    const dialogmenu_toggle = async () => {
        setdmToggle(!dmToggle);
    }

    const delete_dialog = async (e) => {
        if (QB) {
            var dialog_id = e.target.getAttribute("data-item");

            QB.chat.dialog.delete([dialog_id], function (error) {
                setdialogData([]);
            });
        }
    }

    const block_delete_dialog = async (e) => {
        if (QB) {
            var dialog_id = e.target.getAttribute("data-item");

            QB.chat.dialog.delete([dialog_id], { force: 1 }, function (error) {
                setdialogData([]);
            });
        }
    }
    
    const updateStatus = () => {
        load_messages();
    }

    return (
        <div className='messages_tab chatmessages'>
            <div className='menubox'>
              <div className='leftmenu'>
                <h4>Messages</h4>
              </div>
            </div>

            <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>                    

            <div className="content j-content">
            <div className="content__inner j-content__inner">
                            <div className='dialog_title'>
                                <div id="dialogName">{dialogData && dialogData.length !== 0 ? clientName : ''}</div>
                                {dialogData && dialogData.length !== 0 && <div><div className='dialog_menu'><i className="fa fa-ellipsis-v viewdata" onClick={dialogmenu_toggle}></i></div>
                                    <div className={dmToggle ? "dialog_menu_content true" : "dialog_menu_content false"}><a data-item={dialogData._id} onClick={delete_dialog}>Delete</a><a data-item={dialogData._id} onClick={block_delete_dialog}>Block {'&'} Delete</a></div></div>
                                }
                            </div>

                            <div className='messages' id='messages' ref={messageEl}>
                                {dialogData.length === 0 ?
                                        <div className="welcome__message">
                                            <div>This client chat has been deleted by you.!</div>
                                        </div>
                                        :
                                        <InfiniteScroll
                                        pageStart={0}
                                        loadMore={loadmore}
                                        hasMore={showMore}
                                        loader={<div className="loader" key={0}>Loading ...</div>}
                                        isReverse={true}
                                    >
                                        {messageList.map((message, index) => (
                                            <div key={index}>
                                                {index === 0 ? (showmoreEnable === true ? <div className='showmore' onClick={setmore}>show more</div> : null) : null}
                                                <div className="message__wrap">
                                                    <div className="dialog_date_message">
                                                        {getchatDate(message.date_sent)}
                                                    </div>                   </div>
                                                <div className="message__wrap" id={message._id} data-status={(message.read_ids).includes(opponent_id
                                                ) ? "read" : "delivered"} >

                                                    <div className={loggedin_userQBId === message.sender_id ? 'message__content message__right type_chat' : 'message__content type_chat'}>
                                                        <div className="message__sender_and_status">
                   
                                                            <div className="message__sender_name">
                                                                {message.sender_id ? (loggedin_userQBId !== message.sender_id ? opponent_name : 'You') : 'unknown user (' + message.sender_id + ')'}
                                                            </div>

                                                            {loggedin_userQBId === message.sender_id ?
                                                                <img style={{ "marginRight": "6px" }} className="message__status j-message__status" src={(message.read_ids).includes(opponent_id
                                                                ) ? readicon : deliveredicon} />
                                                                :
                                                                null}

                                                            <div className="message__timestamp">
                                                                {message.date_sent ? moment.tz(moment.unix(message.date_sent), coach_timezone).format('HH:mm') : null}
                                                            </div>
                                                        </div>
                                                        <div className="message__text_and_date">
                                                            <div className={loggedin_userQBId === message.sender_id ? 'message__text_wrap you' : 'message__text_wrap'}>
                                                                <div style={{ "height": "1px", "opacity": "0" }}>
                                                                    <div className="message__sender_name">
                                                                    {message.sender_id ? (loggedin_userQBId !== message.sender_id ? opponent_name : loggedin_userQBname) : 'unknown user (' + message.sender_id + ')'}
                                                                        <div className="message__timestamp">
                                                                            {message.date_sent}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {message.message !== "[attachment]" ?
                                                                    <p className={loggedin_userQBId === message.sender_id ? 'message__text you' : 'message__text'}>
                                                                        {message.message}</p>
                                                                    : null}

                                                                {message.attachments.length ?
                                                                    <div className="message__attachments_wtap">
                                                                        {(message.attachments).map((element, index) => (
                                                                            <img loading="lazy" src={getFileURL(element.id)} data-id={element.id} alt="attachment" className="message_attachment loaded" key={index} onClick={() => load_image(element.id)}></img>
                                                                        ))}
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </InfiniteScroll>                                        
                                }
                            </div>

                            {dialogData && dialogData.length !== 0 ?
                                <div name="send_message" className="send_message" autoComplete="off">
                                    <div className="attachments_preview j-attachments_preview" style={{ display: Preview ? 'flex' : 'none' }}>
                                        <div className="attachment_preview__wrap m-loading" id="">
                                            <img className="img-close" src={closeicon} width="24px" height="24px" style={{ display: Preview ? "block" : "none" }} onClick={closePreview} />
                                            <img className="attachment_preview__item" src={Preview} />
                                        </div>
                                    </div>

                                    <div className="message__actions">
                                        <label className="attach_btn">
                                            <i className="fa fa-paperclip material-icons"></i>
                                            <input type="file" id="attach_btn" name="attach_file" className="attach_input" accept="image/*" onChange={file_select} onClick={(event) => { event.target.value = null }} />
                                        </label>
                                    </div>
                                    <button className="send_btn" onClick={sendMessage} style={{ marginTop: Preview ? "95px" : "0" }}>
                                        <img style={{ "width": "28px" }} src={sendicon} alt="send" />
                                    </button>
                                    <textarea name="message_field" className="message_field" id="message_field" placeholder="Send message…" onChange={(e) => setmessageText(e.target.value)} value={messageText} autoFocus onClick={updateStatus} onKeyDown={handleKeyDown}></textarea>
                                </div>
                                :
                                null}

                            
                        </div>
            </div> 

            <Modal show={show} onHide={handleClose} className='adminmessages_img_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={loadImg} alt="attachment image" style={{ maxWidth: "100%", margin: "0 auto", display: "block" }} />
                        </Modal.Body>
                        <Modal.Footer className='display_block'>
                            <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                                Back
                            </Button>

                            <Button variant="primary" className='modal_action_button float_right' onClick={() => { downloadAttachment(attachmentFileid) }}>
                                Download
                            </Button>
                        </Modal.Footer>
                    </Modal>

        </div>
    )
}

export default ConnectedClientMessages

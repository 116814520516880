import React, { useEffect, useState } from "react";
import ManagerAccessCheck from "./ManagerAccessPage";
import axios from 'axios';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagerContactEnquiry = () => {

    const [Access, SetAccess] = useState(false)
    const [ShowData, SetShowData] = useState(false)
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [ContactPerPage, SetContactPerPage] = useState(1)
    const [ContactEnquiryList, SetContactEnquiryList] = useState([])
    const [ContactTotalRecord, SetContactTotalRecord] = useState(0)
    const [ContactPageno, SetContactPageno] = useState(1)


    const [showsv, Setshowsv] = useState(false)
    const handleClosesv = () => { Setshowsv(false) }

    const [ViewName, SetViewName] = useState('')
    const [ViewEmailAddress, SetViewEmailAddress] = useState('')
    const [ViewSubject, SetViewSubject] = useState('')
    const [ViewQuery, SetViewQuery] = useState('')
    const [DeleteIdFinal, SetDeleteIdFinal] = useState()
    const [DeleteAccess, SetDeleteAccess] = useState(false)

    const [showsd, Setshowsd] = useState(false)
    const handleClosesd = () => { Setshowsd(false) }


    function handleChange(newValue) {
        SetAccess(newValue);
    }

    const GetAccessDetails = async () => {

        const response = await axiosJWT.get('/managing-team/get-access-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        if (response.data !== null) {

            var Enquiryresult = response.data.filter(obj => {
                return obj.page === 'manage_enquiry'
            })

            if (Enquiryresult[0].delete === true) {
                SetDeleteAccess(true)
            }


            if (Enquiryresult[0].read === true) {

                SetAccess(false)
                SetShowData(true)

            }
            else {
                SetAccess(true)
                SetShowData(false)
            }

        }

    }

    const GetMyTeamContactEnquiries = async (pagenumber) => {

        SetContactPageno(pagenumber)


        const response = await axiosJWT.get('/manager-access/get-my-team-contact-enquiry?offset=' + pagenumber + '&limit=' + ContactPerPage + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data !== null) {
            if (response.data.GetResponse) {
                SetContactEnquiryList(response.data.GetResponse)
                SetContactTotalRecord(response.data.TotalCount)

            }
        }

    }

    const changePageNumber = (pagenumber) => {
        GetMyTeamContactEnquiries(pagenumber)
    }

    const ViewcontactEnquiry = async (e) => {

        var Id = e.target.getAttribute('data-id')

        const response = await axiosJWT.post('/contact/detail',
            {
                'id': Id,

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {

            if (response.data !== null) {
                SetViewName(response.data.first_name + ' ' + response.data.last_name)
                SetViewEmailAddress(response.data.email_address)
                SetViewSubject(response.data.subject)
                SetViewQuery(response.data.queries)
                Setshowsv(true)
            }



        }




    }

    const TriggerDelete = (e) => {
        var DeleteId = e.target.getAttribute('data-id')
        SetDeleteIdFinal(DeleteId)
        Setshowsd(true)
    }

    const DeleteContactEnquiry = async () => {

        const response = await axiosJWT.post('/contact/delete',
            {
                'id': DeleteIdFinal,

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            GetMyTeamContactEnquiries(ContactPageno);
            handleClosesd();
        }
    }

    useEffect(() => {
        GetAccessDetails();
        GetMyTeamContactEnquiries(1);
    }, []);


    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="container">
                    <ManagerAccessCheck allowedstatus={Access} onChange={handleChange} />
                    {ShowData === true &&
                        <div className="row">
                            <div className="row">
                                <div className="first_col_mail_temp">
                                    <span>Contact Enquiries</span>
                                </div>
                            </div>
                            <div className="row" style={{ display: "block" }}>
                                <Table className='table is-striped is-fullwidth upcoming_appointment_table'>
                                    <Thead className='thead '>
                                        <Tr className="trow">
                                            <Th className='row_name'>User Name</Th>
                                            <Th className='row_name'>Email Address</Th>
                                            <Th className='row_name'>Subject</Th>
                                            <Th className='row_name'>Query</Th>
                                            <Th className='row_name'>Action</Th>
                                        </Tr>
                                    </Thead>
                                    {ContactEnquiryList.length > 0 &&
                                        <Tbody className="tbody">
                                            {ContactEnquiryList.length > 0 && ContactEnquiryList.map((template, index) => (
                                                <Tr className='trow' key={template.id}>
                                                    <Td><span className="">{template.first_name + ' ' + template.last_name}</span></Td>
                                                    <Td >{template.email_address}</Td>
                                                    <Td >{template.subject}</Td>
                                                    <Td >{template.queries.length < 15 ? template.queries : template.queries.substring(0, 11) + '...'}</Td>
                                                    <Td ><i className="fa fa-eye edit_icon billing_edit_i pointer_eff" data-id={template.id} onClick={(e) => { ViewcontactEnquiry(e) }} ></i>
                                                        {DeleteAccess === true &&
                                                            <i className="fa fa-trash billing_edit_i pointer_eff" data-id={template.id} onClick={(e) => { TriggerDelete(e) }}></i>}
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    }
                                </Table>
                                <div>
                                    {ContactTotalRecord > 0 ?
                                        <Pagination
                                            activePage={ContactPageno}
                                            itemsCountPerPage={ContactPerPage}
                                            totalItemsCount={ContactTotalRecord}
                                            onChange={changePageNumber.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers color_grey"
                                        />
                                        : null}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Modal show={showsv} onHide={handleClosesv} className="confirm_delete manager_contact_enquiry_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Contact Enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="top_span_contact_enquiry">
                            <span className="label">Name: </span>

                            <span>{ViewName}</span>
                        </div>
                        <br />
                        <div className="top_span_contact_enquiry">
                            <span className="label">Email: </span>
                            <span>{ViewEmailAddress}</span>
                        </div>
                        <br />
                        <div className="top_span_contact_enquiry">
                            <span className="label">Subject: </span>
                            <span>{ViewSubject}</span>
                        </div>
                        <br />
                        <div className="top_span_contact_enquiry">
                            <span className="label">Query: </span>
                            <div style={{ overflowWrap: 'break-word' }}>{ViewQuery}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="bootstrp_color_btn" onClick={(e) => { handleClosesv() }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showsd} onHide={handleClosesd} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Contact enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete this Contact enquiry?</Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" onClick={handleClosesd}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { DeleteContactEnquiry() }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </div>
    )
}

export default ManagerContactEnquiry
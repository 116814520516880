import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const RestCryotherapy = (props) => {

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [CryoDuration, SetCryoDuration] = useState('')
    const [CryoDurationErr, SetCryoDurationErr] = useState(false)
    const [CryoTemp, SetCryoTemp] = useState('')
    const [CryoTempErr, SetCryoTempErr] = useState(false)
    const [CryoTempType, SetCryoTempType] = useState('Fahrenheit')
    const [CryoTempTypeErr, SetCryoTempTypeErr] = useState(false)
    const [Fasting, SetFasting] = useState(false)

    const ChangedCheckboxBloodDaily = (e) => {
        var Checkboytype = e.target.getAttribute('data-id')

        if (Checkboytype === 'fasting') {
            if (e.target.checked === true) {
                SetFasting(true)
            }
            else {
                SetFasting(false)
            }

        }


    }

    const SaveRestCryo=async (e)=>{
        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowRestCryoField(false)
            props.SetRestOption(true)
            return false
        }
        SetCryoTempErr(false)
     
        SetTimeErr(false)
        SetDateErr(false)
        SetCryoDurationErr(false)
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(CryoTemp==='')
        {
            err = true
            SetCryoTempErr(true)
        }
        if(CryoTempType==='')
        {
            err = true
            SetCryoTempTypeErr(true)
        }
        if(CryoDuration==='')
        {
            err=true;
            SetCryoDurationErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }

            const response = await axiosJWT.post('add-data/add-rest',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Rest Cryotherapy',
                'duration':CryoDuration,
                'temp_value':CryoTemp,
                'temp_type':CryoTempType,
                'client_id':IsClientid,
                'fasting':Fasting,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowRestCryoField(false)
                    props.SetRestOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }

        }


    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
<h6 className="heading_popup">{'Rest & Recovery > Cryotherapy'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                       />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Duration</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetCryoDuration(e.target.value) }} {...(CryoDurationErr === true ? { id: 'err_border' } : {})} defaultValue={CryoDuration}>
                        <option value="">Select Duration</option>
                        <option value="120">2:00</option>
                        <option value="135">2:15</option>
                        <option value="150">2:30</option>
                        <option value="165">2:45</option>
                        <option value="180">3:00</option>
                        <option value="195">3:15</option>
                        <option value="210">3:30</option>
                        <option value="225">3:45</option>
                        <option value="240">4:00</option>
                        <option value="255">4:15</option>
                    </select>
                </div>
            </div>


            <div className="field_top_row">
                <div className="label">Skin Temperature</div>
                <div className="two_col_field">
                    <div className="col1">
                        <div className="">


                            <div className="field">
                                <input type={'number'} className="raw_input" onChange={(e) => { SetCryoTemp(e.target.value) }} {...(CryoTempErr === true ? { id: 'err_border' } : {})} defaultValue={CryoTemp} />
                            </div>
                        </div>
                    </div>
                    <div className="col2">

                        <div className="field_top_row">


                            <div className="field">
                                <select className="raw_select" onChange={(e) => { SetCryoTempType(e.target.value) }} {...(CryoTempType === true ? { id: 'err_border' } : {})} defaultValue={CryoTempType}>

                                    <option value="Fahrenheit">Fahrenheit</option>
                                    <option value="Celcius">Celcius</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field_top_row">

                <div className="field checkbox_field_in_line">

                    <div className="checkbox">
                        <input type="checkbox" name="fasting" data-id='fasting' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={Fasting} />
                        <label className="checkbox_add_data_label">Fasting (No food for atleast 8 hours)</label>
                    </div>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveRestCryo(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveRestCryo(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveRestCryo(e) }} >
                    {'Save'}
                </Button>

           </div>
           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )


}
export default RestCryotherapy  
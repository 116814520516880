import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import female_icon from "../../../images/female.png";
import male_icon from "../../../images/male.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns';

const ClientFoodJournal=(props)=>{
 

    const [ClientId,SetClientId]=useState('')
    const [Pagenumber,SetPagenumber]=useState(1)
    const [PerPage,SetPerPage]=useState(50)
    const [TotalRecord,SetTotalRecord]=useState(0)
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [FoodJournalList,SetFoodJournalList]=useState([])
    const [SortValue,SetSortValue]=useState('')

    const [showDetail,SetshowDetail]=useState(false)
    const handleCloseDetail=()=>{
        SetshowDetail(false)
    }
    const [DetailJournalType,SetDetailJournalType]=useState('')
    const [DetailJournalDate,SetDetailJournalDate]=useState(null);
    const [DetailJournalSrc,SetDetailJournalSrc]=useState('');
    const [DetailJournalDesc,SetDetailJournalDesc]=useState('');
    const [CommentsList,SetCommentsList]=useState([])
    



    const GetFoodJournal=async(pagenumber,clientid,sort)=>{

        SetSortValue(sort)
        SetPagenumber(pagenumber)
        const response = await axiosJWT.get('/food-journal?offset=' + pagenumber + '&limit=' + PerPage + '&sort=' + sort + '&client_id='+clientid+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        console.log('response',response)

      

        if(response.data!==null)
        {
            
            

            
            SetTotalRecord(response.data.count)
      
            if(response.data.list!==null)
            {
                SetFoodJournalList(response.data.list)
            }
        }

    }

    const changePageNumberS=(pagenumber)=>{
        
        GetFoodJournal(pagenumber,ClientId,SortValue)
    }

    const ViewFoodJournal=async(e)=>{
        const id = e.target.closest(".navigateclientdashboard").getAttribute("data-id");
        const response = await axiosJWT.get('/food-journal/detail?id='+id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        console.log('dfnbsdg',response)

        const Commentresponse = await axiosJWT.get('/food-journal/get-comments?id=' + id + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (Commentresponse.data.Comments != null) {
            SetCommentsList(Commentresponse.data.Comments)
        }

        if(response.data!==null)
        {
            if(response.data.result!==null)
            {
                SetshowDetail(true)
                SetDetailJournalType(response.data.result.type)
                SetDetailJournalDate(response.data.result.journal_date)
                SetDetailJournalSrc( response.data.result.journal_img)
                SetDetailJournalDesc(response.data.result.desc)
               
            }
        }

    }


    useEffect(()=>{

        if(props.data)
        {
            SetClientId(props.data)
            GetFoodJournal(1,props.data,'ASC')
            
        }

    },[props])

    const Renderhtmltostring = (content,limit) => {

        var Convertedstring = content.replace(/<[^>]*>?/gm, '');

        if (Convertedstring.length < limit) {
            return Convertedstring
        }
        else {
            return Convertedstring.substring(0, limit-3) + '...'
        }


    }

    return (
        <div>

<Table className="table is-striped is-fullwidth upcoming_appointment_table admin_coach_client_food_journal">
        <Thead className="thead">
          <Tr className="trow">
            <Th scope="col">Meal Type</Th>
            <Th scope="col">Date</Th>
            <Th scope="col">Description</Th>
            <Th scope="col">Image</Th>
            <Th scope="col">Action</Th>
            </Tr>
        </Thead>
        <Tbody className='tbody'>
        {FoodJournalList.map((list, index) => (  
            <Tr className='trow navigateclientdashboard' data-id={list.id} onClick={(e)=>{ViewFoodJournal(e)}}>
             <Td>{list.type}</Td>
             <Td>{list.journal_date}</Td>
             <Td>{Renderhtmltostring(list.desc,50)}</Td>
             <Td className="food_journal_img_td"><img src={list.journal_img}/></Td>
             <Td>View</Td>
            </Tr>
                    ))}
        </Tbody>
        </Table>

        {FoodJournalList.length > 0 &&
                                    <div>
                                        <Pagination
                                            activePage={Pagenumber}
                                            itemsCountPerPage={PerPage}
                                            totalItemsCount={TotalRecord}
                                            onChange={changePageNumberS.bind(this)}
                                            prevPageText="<"
                                            nextPageText=">"
                                            hideFirstLastPages={true}
                                            linkClassPrev="pagination_prev"
                                            linkClassNext="pagination_next"
                                            linkClass="pagenumbers grayfont"
                                        />
                                    </div>
                                }

<Modal show={showDetail} onHide={handleCloseDetail} className='coach_blog_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>{'Food Journal'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="food_journal_detail">

                            <div className="food_journal_detail_top_row">
                                <span className="type">{DetailJournalType}</span>
                                {
                                    DetailJournalDate!==null &&
                                    <span className="date">{format(parseISO(DetailJournalDate), `PP`)}</span>
                                }
                              

                              
                               
                            </div>

                            <div className="journal_detail_comment_row">

                                <div className="detail">
                                    <img src={DetailJournalSrc} />

                                    <p className="desc">{DetailJournalDesc}</p>

                                </div>
                            </div>
                            <div className="journal_comment_row">
                            {CommentsList.length > 0 &&  <div className="comment_label">Comments:</div>}
                               
                                <div className="comments_list_row">
                                    {CommentsList.length > 0 && CommentsList.map((comments, index) => (
                                        <div className="comment_list">
                                            <p className="comment_info">
                                               
                                                {<span>{comments.user.first_name + ' ' + comments.user.last_name}</span>}


                                             

                                                <span className="date">{format(parseISO(comments.createdAt), `PP`)}</span>

                                            </p>
                                            <p> {comments.comments}</p>
                                        </div>

                                    ))}
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='display_block'>


                            <Button variant="primary" className='modal_action_button float_right' onClick={handleCloseDetail} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
            
        </div>
    )
}
export default ClientFoodJournal
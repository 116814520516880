import React, { useEffect } from "react";

const Tired = (props) => {

    var TiredValue='';
    
    const CheckPhysical=(e)=>{
      
        var tabLink = document.querySelectorAll(".option_div");

        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");
    
        tabLink.forEach(box => {    
          box.classList.remove('active');
        });

        e.target.closest('.option_div').classList.add("active");

        if (category === "") {
            props.DisableNextButton();
          } else {
            props.SetTiredData(category);
            props.EnableNextButton();
          }
        }

        useEffect(() => {
            setTimeout(() => {        
              if (props.TiredData) {
                if (props.TiredData !== "") {                 
                  const el1 = document.querySelector(
                    '.option_div[data-target="' + props.TiredData + '"]'
                  );

                  el1.classList.add("active");
                  props.EnableNextButton();
                }
                else{                 
                    props.DisableNextButton(); 
                }
              }
              else{
                props.DisableNextButton(); 
              }
            }, 1);

            if(props.SetShowSubmitButton)
            {
              props.SetShowSubmitButton(false);
            }
          }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading">
                    Which time of the day do you feel most tired?</span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Early Morning" onClick={CheckPhysical}><p>Early Morning</p></div>
                <div className="option_div" data-target="After lunch" onClick={CheckPhysical}><p>After Lunch</p></div>
                <div className="option_div" data-target="After Dinner" onClick={CheckPhysical}><p>After Dinner</p></div>
                <div className="option_div" data-target="Not until bed time" onClick={CheckPhysical}><p>Not until bed time</p></div>
            </div>
        </div>
    )
}
export default Tired
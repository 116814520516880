import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment-timezone";
import SubscriptionAddons from "../../AdminComponents/AdminClientDashboard/SubscriptionAddOns";


const ClientSubscriptions = () => {

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const axiosJWT = axios.create();
  const SubscriptionId = localStorage.getItem("subscriptionID");
  const subscriptionSdate = localStorage.getItem("subscriptionSdate");
  const subscriptionEdate = localStorage.getItem("subscriptionEdate");
  const [Check, SetCheck] = useState(true);

  const [PlanIdOne, SetPlanIdOne] = useState(false);
  const [PlanIdTwo, SetPlanIdTwo] = useState(false);
  const [PlanIdThree, SetPlanIdThree] = useState(false);
  const [PlanIdFour, SetPlanIdFour] = useState(false);
  const [ExpiryDate, SetExpiryDate] = useState("");

  const [LifeTimeYes, SetLifeTimeYes] = useState(false);
  const [ShowLifetime, SetShowLifetime] = useState(false);
  const [ShowAddOns, SetShowAddOns] = useState(false);

  const [AffirmError, setAffirmError] = useState(false);
  const [AffirmErrorMsg, setAffirmErrorMsg] = useState('');

  const [SplititError, setSplititError] = useState(false);
  const [SplititErrorMsg, setSplititErrorMsg] = useState('');

  const CloseLifeTimePopup = () => {
    SetShowLifetime(false);
  }
  const CloseAddOns = () => {
    SetShowAddOns(false);
  }

  const CheckPlanExpiry = async () => {
    var current_time = moment.utc().format("X");
    var SubEndDate = localStorage.getItem("subscriptionEdate");
    var appointment_starttime = moment.utc(SubEndDate).format("X");
    var SubscriptionIdCheck = localStorage.getItem("subscriptionID");

    var formatteedDate = format(new Date(SubEndDate), `PP`);
    SetExpiryDate(formatteedDate);
    // if (SubscriptionIdCheck !== '' && SubEndDate!=='null') {

    //     if (SubscriptionIdCheck === 'omp_client' && (current_time < appointment_starttime)) {
    //         SetPlanIdOne(false)

    //         SetExpiryDate(formatteedDate)
    //     }
    //     else {
    //         SetPlanIdOne(true)
    //     }
    //     if (SubscriptionIdCheck === 'smp_client' && (current_time < appointment_starttime)) {
    //         SetPlanIdTwo(false)
    //         var formatteedDate = format(new Date(SubEndDate), `PP`)
    //         SetExpiryDate(formatteedDate)
    //     }
    //     else {
    //         SetPlanIdTwo(true)
    //     }
    //     if (SubscriptionIdCheck === 'oyp_client' && (current_time < appointment_starttime)) {
    //         SetPlanIdThree(false)
    //         var formatteedDate = format(new Date(SubEndDate), `PP`)
    //         SetExpiryDate(formatteedDate)
    //     }
    //     else {
    //         SetPlanIdThree(true)
    //     }
    //     if (SubscriptionIdCheck === 'ltp_client' && (current_time < appointment_starttime)) {
    //         SetPlanIdFour(false)
    //         var formatteedDate = format(new Date(SubEndDate), `PP`)
    //         SetExpiryDate('Life time')
    //     } else {
    //         SetPlanIdFour(true)
    //     }

    // }
    // else {
    //     SetPlanIdOne(true)
    //     SetPlanIdTwo(true)
    //     SetPlanIdThree(true)
    //     SetPlanIdFour(true)
    // }
  };

  const GetCurrentPlanDetails = async () => {

    const response = await axiosJWT.get("/profile-details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (
      response.data.profile_details &&
      response.data.profile_details[0] &&
      response.data.profile_details[0].client_infos &&
      response.data.profile_details[0].client_infos !== null &&
      response.data.profile_details[0].client_infos !== "" &&
      response.data.profile_details[0].client_infos[0].subscription_s_date !==
      ""
    ) {
      localStorage.setItem(
        "subscriptionSdate",
        response.data.profile_details[0].client_infos[0].subscription_s_date
      );
    }
    if (
      response.data.profile_details &&
      response.data.profile_details[0] &&
      response.data.profile_details[0].client_infos &&
      response.data.profile_details[0].client_infos !== null &&
      response.data.profile_details[0].client_infos !== "" &&
      response.data.profile_details[0].client_infos[0].subscription_e_date !==
      ""
    ) {
      localStorage.setItem(
        "subscriptionEdate",
        response.data.profile_details[0].client_infos[0].subscription_e_date
      );
    }
    if (
      response.data.profile_details &&
      response.data.profile_details[0] &&
      response.data.profile_details[0].client_infos &&
      response.data.profile_details[0].client_infos !== null &&
      response.data.profile_details[0].client_infos !== "" &&
      response.data.profile_details[0].client_infos[0].subscription_plan !== ""
    ) {
      localStorage.setItem(
        "subscriptionID",
        response.data.profile_details[0].client_infos[0].subscription_plan
      );
    }



    if (response.data.profile_details && response.data.profile_details[0] && response.data.profile_details[0].client_infos && response.data.profile_details[0].client_infos[0].client_plan && response.data.profile_details[0].client_infos[0].client_plan !== null && response.data.profile_details[0].client_infos[0].client_plan.plan_type === 'unlimited') {
      SetLifeTimeYes(true);
    }

  };

  useEffect(() => {
    GetActivePlanList();

    document.title = "Client-Subscriptions";

    GetCurrentPlanDetails();
    CheckPlanExpiry();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let paymentSuccess = params.get("payment");

    if (paymentSuccess === "success") {
      Setshowsuccess(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (paymentSuccess === "failed") {
      Setshowfail(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  const [shows, Setshows] = useState(false);
  const handleCloses = () => {
    Setshows(false);
  };

  const [ShowPaypalGateway, SetShowPaypalGateway] = useState(false);
  const [ShowStripeGateway, SetShowStripeGateway] = useState(false);
  const [SetPlanId, SetSetPlanId] = useState("");
  const [PayPalGatewaySrc, SetPayPalGatewaySrc] = useState("");
  const [StripeGatewaySrc, SetStripeGatewaySrc] = useState("");
  const [StripeGatewayDesc, SetStripeGatewayDesc] = useState("");
  const [StripeGatewayName, SetStripeGatewayName] = useState("");
  const [loader, ShowLoader] = useState(false);
  const [paymentErr, SetpaymentErr] = useState(false);
  const [paymenterrmsg, Setpaymenterrmsg] = useState("");
  const [SelectedPlanName, SetSelectedPlanName] = useState("");
  const [SelectedPlanAmount, SetSelectedPlanAmount] = useState("");
  const [FinalAmount, SetFinalAmount] = useState("");
  const [ShowAffirmGateway, SetShowAffirmGateway] = useState(false);
  const [AffirmGatewaySrc, SetAffirmGatewaySrc] = useState('');
  const [AffirmGatewayDesc, SetAffirmGatewayDesc] = useState('');
  const [AffirmGatewayName, SetAffirmGatewayName] = useState('');
  const [ShowSplititGateway, SetShowSplititGateway] = useState(false);
  const [SplititGatewaySrc, SetSplititGatewaySrc] = useState('');
  const [SplititGatewayDesc, SetSplititGatewayDesc] = useState('');
  const [SplititGatewayName, SetSplititGatewayName] = useState('');

  const BuyPlanSubscription = async (e) => {

    if (LifeTimeYes === true) {
      SetShowLifetime(true);
      return false;
    }

    SetShowPaypalGateway(false);
    SetShowStripeGateway(false);
    SetShowAffirmGateway(false);
    SetShowSplititGateway(false);
    SetChosenGateway("");
    SetHiddenPromoErr(false);
    SetGivenPromoCode("");
    setAffirmError(false);
    setSplititError(false);

    const response = await axiosJWT.get(
      "payments/paymentmethods?currency=USD",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data !== null) {
      if (response.data.length > 0) {
        var PayPalArray = response.data.filter(function (el) {
          return el.gateway === "Paypal";
        });
        if (PayPalArray.length > 0) {
          if (PayPalArray[0].isEnable === true) {
            SetShowPaypalGateway(true);
            SetPayPalGatewaySrc(PayPalArray[0].logopath);
          }
        }

        var StripeArray = response.data.filter(function (el) {
          return el.gateway === "Stripe";
        });
        if (StripeArray.length > 0) {
          if (StripeArray[0].isEnable === true) {
            SetShowStripeGateway(true);
            SetStripeGatewaySrc(StripeArray[0].logopath);
            SetStripeGatewayDesc(StripeArray[0].description);
            SetStripeGatewayName(StripeArray[0].title);
          }
        }

        var AffirmArray = response.data.filter(function (el) {
          return el.gateway === 'Affirm';
        });
        if (AffirmArray.length > 0) 
        {
          if (AffirmArray[0].isEnable === true) 
          {
            var affirm_enable = true;
            SetAffirmGatewaySrc(AffirmArray[0].logopath);
            SetAffirmGatewayDesc(AffirmArray[0].description);
            SetAffirmGatewayName(AffirmArray[0].title);
          }
        }

        var SplititArray = response.data.filter(function (el) {
          return el.gateway === 'Splitit';
        });        
        if (SplititArray.length > 0) 
        {
          if (SplititArray[0].isEnable === true) 
          {
            var splitit_enable = true;
            SetSplititGatewaySrc(SplititArray[0].logopath);
            SetSplititGatewayDesc(SplititArray[0].description);
            SetSplititGatewayName(SplititArray[0].title);
          }
        }


      }
    }
    SetSetPlanId(e.target.getAttribute("data-id"));

    // const ProductArray = ClientSubscriptionDetails.filter(function (el) {
    //     return el.sku === e.target.getAttribute('data-id')
    // })

    const GetProductDetails = await axiosJWT.get(
      "client-plan/detail?id=" + e.target.getAttribute("data-id") + "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );


    if (affirm_enable === true) {

      SetShowAffirmGateway(true);
      // if (GetProductDetails.data.Detail.plan_type === 'unlimited' || GetProductDetails.data.Detail.plan_duration >= 6) {
      
      // }
    }

    if (splitit_enable === true) {
      SetShowSplititGateway(true);
      // if (GetProductDetails.data.Detail.plan_type === 'unlimited' || GetProductDetails.data.Detail.plan_duration >= 6) {
     
      // }
    }

    SetSelectedPlanName(GetProductDetails.data.Detail.plan_name);
    SetSelectedPlanAmount(GetProductDetails.data.Detail.plan_price);
    SetFinalAmount(GetProductDetails.data.Detail.plan_price);

    AvailablePublicPromoCodeList();
    checkAutoApply(e.target.getAttribute("data-id"));

    Setshows(true);
    SetIsPromoAppliedStatus(false);
    SetpaymentErr(false);
    SetDisableHiddenPromo(false);
  };

  const [ChosenGateway, SetChosenGateway] = useState("");

  const ProceedToCheckout = async () => {

    SetpaymentErr(false);

    if (ChosenGateway === "") {
      SetpaymentErr(true);
      Setpaymenterrmsg("Please select any payment method.!");
    } else {
      ShowLoader(true);
      try {
        const response = await axiosJWT.post(
          "/payments/checkout",
          {
            payment_gateway: ChosenGateway,
            product_category: "client_subscription",
            product_sku: SetPlanId,
            promo_applied: IsPromoAppliedStatus,
            promo_created_id: PromoAppliedId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );


        if (response.data !== null) {
            setAffirmError(false);          
            ShowLoader(false);

            if(response.data.checkout_url==='' && ChosenGateway==='splitit')
              {
            setSplititError(true);
            setSplititErrorMsg("*Please fill all the required fields in your profile account for using splitit payment.!");
            return false
              }
            window.location.href = response.data.checkout_url;
        }

      } catch (err) {
        ShowLoader(false);

        if (ChosenGateway === 'affirm' || ChosenGateway === 'splitit') {

          if (ChosenGateway === 'affirm')
          {
            setAffirmError(true);
            if (err.response.data.msg === 'Fields Required')
            {
              setAffirmErrorMsg("*Please fill all the required fields in your profile account for using affirm payment.!");
            }
             
            else if (err.response.data.msg=='Invalid Request: Must be valid Amount')
            {
              setAffirmErrorMsg("*You cannot purchase this plan with affirm payment menthod. Invalid Amount for EMI option");
            }
            else
              setAffirmErrorMsg("Something went wrong.! Contact the site administrator.!");
          }
          if (ChosenGateway === 'splitit')
          {
            setSplititError(true);
            setSplititErrorMsg("Something went wrong.! Contact the site administrator.!");
          }         
        }
      }
    }
  };

  const [showsuccess, Setshowsuccess] = useState(false);
  const CloseSuccessPopup = () => {
    Setshowsuccess(false);
  };

  const [showfail, Setshowfail] = useState(false);
  const CloseFailPopup = () => {
    Setshowfail(false);
  };

  //promo functions

  const [DisableHiddenPromo, SetDisableHiddenPromo] = useState(true);
  const [AvailablePublicPromoCodesList, SetAvailablePublicPromoCodesList] =
    useState([]);
  const [IsPromoAppliedStatus, SetIsPromoAppliedStatus] = useState(false);
  const [PromoAppliedId, SetPromoAppliedId] = useState();
  const AvailablePublicPromoCodeList = async () => {
    const response = await axiosJWT.get(
      "payments/get-available-promo-code?type=public&category=client_subscription",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data !== null) {
      if (response.data.result.length > 0) {
        SetAvailablePublicPromoCodesList(response.data.result);
      } else {
        SetAvailablePublicPromoCodesList([]);
      }
    }
  };

  const ApplyPromoDiscount = async (e) => {
    SetDiscountAmount(0);
    SetDisableHiddenPromo(false);

    SetPromoAppliedId("");
    SetIsPromoAppliedStatus(false);

    const AllPromoButtons = document.querySelectorAll(
      ".apply_public_promo_btn"
    );
    const Action = e.target.getAttribute("data-action");
    const PromoId = e.target.getAttribute("data-id");

    AllPromoButtons.forEach((element) => {
      const DefaultAction = element.dataset.action;
      const DefaultId = element.dataset.id;
      if (DefaultAction === "remove" && DefaultId != PromoId) {
        element.innerHTML = "Apply";
        element.dataset.action = "apply";
      }
    });

    var ClickedElement = document.querySelectorAll(
      ".apply_public_promo_btn[data-id='" + PromoId + "']"
    );
    if (Action === "apply") {
      try {
        const response = await axiosJWT.post(
          "payments/apply-promo-code",
          {
            promo_id: PromoId,
            plan_id: SetPlanId,
            plan_category: "client_subscription",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response) {
          SetDisableHiddenPromo(true);
          const ManualPromo = document.querySelectorAll(".apply_promo_button");
          ManualPromo[0].innerHTML = "Apply";
          ManualPromo[0].dataset.action = "apply";

          SetGivenPromoCode("");
          SetFinalAmount(response.data.data.discounted_amount);
          ClickedElement[0].innerHTML = "Remove";
          ClickedElement[0].dataset.action = "remove";

          SetPromoAppliedId(response.data.data.id);
          SetIsPromoAppliedStatus(true);

          var DiscountAmounReduced =
            parseFloat(response.data.data.initial_amount) -
            parseFloat(response.data.data.discounted_amount);
          SetDiscountAmount(DiscountAmounReduced.toFixed(1));
        }
      } catch (err) {
        SetPromoApplyErrmsg(err.response.data.msg);
        SetshowPromoApplyfail(true);
        SetFinalAmount(SelectedPlanAmount);
      }
    } else if (Action === "remove") {
      ClickedElement[0].innerHTML = "Apply";
      ClickedElement[0].dataset.action = "apply";
      SetPromoAppliedId("");
      SetIsPromoAppliedStatus(false);
      SetFinalAmount(SelectedPlanAmount);
    }
  };

  const [HiddenPromoErr, SetHiddenPromoErr] = useState(false);
  const [HiddenPromoErrMsg, SetHiddenPromoErrMsg] = useState("");
  const [GivenPromoCode, SetGivenPromoCode] = useState("");
  const [DiscountAmount, SetDiscountAmount] = useState(0);

  const ApplyHiddenPromo = async (e) => {
    SetDiscountAmount(0);
    SetHiddenPromoErr(false);
    SetPromoAppliedId("");
    SetIsPromoAppliedStatus(false);

    const Action = e.target.getAttribute("data-action");
    var ClickedElement = document.querySelectorAll(".apply_promo_button");

    if (Action === "apply") {
      if (GivenPromoCode === "") {
        SetHiddenPromoErr(true);
        SetHiddenPromoErrMsg("Please enter promo code");
      } else {
        try {
          const response = await axiosJWT.post(
            "payments/apply-hidden-promo-code",
            {
              promo_code: GivenPromoCode,
              plan_id: SetPlanId,
              plan_category: "client_subscription",
              type: "hidden",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          SetPromoAppliedId(response.data.data.id);
          SetIsPromoAppliedStatus(true);
          SetFinalAmount(response.data.data.discounted_amount);
          ClickedElement[0].innerHTML = "Remove";
          ClickedElement[0].dataset.action = "remove";
          var DiscountAmounReduced =
            parseFloat(response.data.data.initial_amount) -
            parseFloat(response.data.data.discounted_amount);
          SetDiscountAmount(DiscountAmounReduced.toFixed(1));
        } catch (err) {
          ClickedElement[0].innerHTML = "Apply";
          ClickedElement[0].dataset.action = "apply";
          SetGivenPromoCode("");
          SetFinalAmount(SelectedPlanAmount);
          SetHiddenPromoErr(true);
          SetHiddenPromoErrMsg(err.response.data.msg);
        }
      }
    } else if (Action === "remove") {
      ClickedElement[0].innerHTML = "Apply";
      ClickedElement[0].dataset.action = "apply";
      SetPromoAppliedId("");
      SetIsPromoAppliedStatus(false);
      SetGivenPromoCode("");
      SetFinalAmount(SelectedPlanAmount);
    }
  };

  const ClosePromoFailPopup = () => {
    SetshowPromoApplyfail(false);
  };
  const [showPromoApplyfail, SetshowPromoApplyfail] = useState(false);
  const [PromoApplyErrmsg, SetPromoApplyErrmsg] = useState("");
  const [OverAllPromoApply, SetOverAllPromoApply] = useState(false);

  const checkAutoApply = async (plan_sku) => {
    SetDiscountAmount(0);

    SetOverAllPromoApply(false);
    SetDisableHiddenPromo(false);
    try {
      const response = await axiosJWT.post(
        "payments/check-auto-apply-promo",
        {
          plan_id: plan_sku,
          plan_category: "client_subscription",
          type: "apply",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data !== null) {
        const AvailableAutoApplyPromo = response.data.result;
        if (AvailableAutoApplyPromo.length > 0) {
          const ChosenPromoLatest = AvailableAutoApplyPromo[0];
          const response = await axiosJWT.post(
            "payments/apply-promo-code",
            {
              promo_id: ChosenPromoLatest.id,
              plan_id: plan_sku,
              plan_category: "client_subscription",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 200) {
            // console.log('auto apply')
            // console.log(response.data.data)
            SetOverAllPromoApply(true);
            SetDisableHiddenPromo(true);
            SetPromoAppliedId(response.data.data.id);
            SetIsPromoAppliedStatus(true);
            SetFinalAmount(response.data.data.discounted_amount);
            var DiscountAmounReduced =
              parseFloat(response.data.data.initial_amount) -
              parseFloat(response.data.data.discounted_amount);
            SetDiscountAmount(DiscountAmounReduced.toFixed(1));
            // console.log(response)
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [ActivePlanList, SetActivePlanList] = useState([]);
  const [CurrentPlanDetails, SetCurrentPlanDetails] = useState();

  const GetActivePlanList = async () => {
    const response = await axiosJWT.get("client-plan/active-list?user_id=" + userId + "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.List != null) {
      SetActivePlanList(response.data.List);
    }
    if (response.data.CurrentPlan) {
      SetCurrentPlanDetails(response.data.CurrentPlan)
    }
  };

  const FormatTags = (string) => {
    const myArray = string.split(",");
    return myArray;
  };

  const CheckExpired = (planstatus = true) => {

    var current_time = moment.utc().format("X");
    var SubEndDate = localStorage.getItem("subscriptionEdate");
    var subscription_endtime = moment.utc(SubEndDate).format("X");
    var SubscriptionIdCheck = localStorage.getItem("subscriptionID");

    if (current_time < subscription_endtime) {
      if (LifeTimeYes === true) {
        return <div className="due_date active">Subscription Ends in : Life time</div>;
      }
      else {
        return <div className="due_date active">Subscription Ends in : {ExpiryDate}</div>;
      }
    } else {
      if (planstatus === true) {
        return (
          <div className="low_level_button_subscriptions">
            <div style={{ color: 'red', fontWeight: '500' }}>Current Plan Expired!</div>
            <button
              className="btn btn-success keto_link_button"
              data-id={SubscriptionIdCheck}
              onClick={BuyPlanSubscription}
            >
              Buy Now
            </button>
          </div>
        );
      }
      else if (planstatus === false) {
        return (
          <div className="low_level_button_subscriptions">
            <div style={{ color: 'red', fontWeight: '500' }}>Current Plan Expired.!</div>
          </div>
        )
      }
    }
  };

  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      {loader === true && <div id="semiTransparenDivLoader"></div>}
      <section className="dashboard">
        <div className="clientsubscriptions container client_subscription_checkout">
          <div className="row subscription_add_ons">
            <span className="top_row_heading_subscription">Subscription</span>
            <button className="btn btn-success keto_link_button" onClick={()=>{SetShowAddOns(true)}}>Add Ons</button>
          </div>
          <div className="row subscription_plan_row">
            <div>Available Plans</div>
            {ActivePlanList.map((plans, index) => (
              <div className={parseInt(SubscriptionId) === plans.id ? "plan_col active" : "plan_col"}>
                <p className="p_tag_label_top_subs">{plans.plan_name}</p>
                <div className="amount_row">
                  <span className="amount_value">${plans.plan_price}</span>
                </div>

                <div className="plan_info">
                  <ul className="list-unstyled mb-4">
                    {FormatTags(plans.plan_content).map((plans, index) => (
                      <li>{plans}</li>
                    ))}
                  </ul>
                </div>

                {parseInt(SubscriptionId) !== plans.id ? (
                  <div className="low_level_button_subscriptions">
                    <button
                      className="btn btn-success keto_link_button"
                      data-id={plans.id}
                      onClick={BuyPlanSubscription}
                    >
                      Buy Now
                    </button>
                  </div>
                ) : (
                  CheckExpired()
                )}
              </div>
            ))}

            {ActivePlanList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                Currently no available plans yet.!
              </p>
            )}
          </div>
          {
            (CurrentPlanDetails && CurrentPlanDetails.plan_status === false) &&
            <div>
              <div className="row subscription_plan_row">
                <div>Current Plan:</div>
                <div className={parseInt(SubscriptionId) === CurrentPlanDetails.id ? "plan_col active" : "plan_col"} style={{ width: '30%' }}>
                  <p className="p_tag_label_top_subs">{CurrentPlanDetails.plan_name}</p>
                  <div className="amount_row">
                    <span className="amount_value">${CurrentPlanDetails.plan_price}</span>
                  </div>

                  <div className="plan_info">
                    <ul className="list-unstyled mb-4">
                      {FormatTags(CurrentPlanDetails.plan_content).map((plans, index) => (
                        <li>{plans}</li>
                      ))}
                    </ul>
                  </div>

                  {parseInt(SubscriptionId) !== CurrentPlanDetails.id ? (
                    <div className="low_level_button_subscriptions">
                      <button
                        className="btn btn-success keto_link_button"
                        data-id={CurrentPlanDetails.id}
                        onClick={BuyPlanSubscription}
                      >
                        Buy Now
                      </button>
                    </div>
                  ) : (
                    CheckExpired(CurrentPlanDetails.plan_status)
                  )}
                </div>
              </div>
            </div>
          }
        </div>

        <Modal show={shows} onHide={handleCloses} className="confirm_delete">
          <Modal.Header closeButton>
            <Modal.Title>Checkout Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="choose_payement_gateway_div">
              <div className="plan_details_div_client_sub">
                <span className="plan_span">
                  <span className="plan_label">Plan Name :</span>{" "}
                  <span>{SelectedPlanName}</span>
                </span>
                {/* <span className="plan_span"><span className="plan_label">Plan Cost :</span> <span>${SelectedPlanAmount}</span></span>
                                <span className="plan_span"><span className="plan_label">Final Amount to be paid :</span> <span>${FinalAmount}</span></span> */}
              </div>

              <div className="order_details client_sub_order_details">
                <div className="order_details_row1">
                  <div className="order_details_col1">Plan Amount:</div>
                  <div className="order_details_col2">
                    ${SelectedPlanAmount}
                  </div>
                </div>
                <div className="order_details_row1">
                  <div className="order_details_col1">Discount Amount:</div>
                  <div className="order_details_col2">${DiscountAmount}</div>
                </div>
                <div className="order_details_row1 final_amount_row">
                  <div className="order_details_col1">Final Total:</div>
                  <div className="order_details_col2">${FinalAmount}</div>
                </div>
              </div>

              {/* {ShowPaypalGateway === true &&
                                <div className="gateway_row gateway_row1">
                                    <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + PayPalGatewaySrc} />
                                    <span className="title">Paypal</span>
                                    <input type={'radio'} name="gateway" value={'paypal'} className="gateway_choose_radio" onChange={(e)=>{SetChosenGateway(e.target.value)}} />
                                </div>} */}
              {ShowStripeGateway === true && (
                <div className="">
                  <div className="available_promo_list_container client_sub_promo_list_container_top">
                    {AvailablePublicPromoCodesList.length > 0 &&
                      AvailablePublicPromoCodesList.map(
                        (activePublicPromo, index) => (
                          <div className="row promo_details_public_row client_sub_promo_list_container">
                            <div className="promo_details_public">
                              {/* <span>{activePublicPromo.name}</span> */}
                              <p>{activePublicPromo.description}</p>
                              <p className="short_info">
                                Min Order: ${activePublicPromo.min_order_amount}
                                , Max Discount: $
                                {activePublicPromo.max_discount_amount}
                              </p>
                              {/* 
                                                    <span>Min Order: $ {activePublicPromo.min_order_amount}</span>
                                                    {activePublicPromo.type === 'Flat Discount' &&
                                                        <span>Discount amount:$ {activePublicPromo.value}</span>
                                                    }

                                                    {activePublicPromo.type === 'Percentage Discount' &&
                                                        <span>Discount precentage: {activePublicPromo.value} % </span>
                                                    }
                                                    <span>Max amount:$ {activePublicPromo.max_discount_amount}</span> */}
                            </div>

                            <div className="promo_details_public_button">
                              <button
                                className="btn btn-success keto_link_button apply_public_promo_btn"
                                disabled={OverAllPromoApply}
                                data-action="apply"
                                data-id={activePublicPromo.id}
                                onClick={(e) => {
                                  ApplyPromoDiscount(e);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )
                      )}
                  </div>

                  <div className="client_subscription_promo_div">
                    <label className="promo_title">Promo Code:</label>
                    <br />
                    <input
                      className="inner_popup_input promo_code_input"
                      type={"text"}
                      disabled={DisableHiddenPromo}
                      value={GivenPromoCode}
                      onChange={(e) => {
                        SetGivenPromoCode(e.target.value);
                      }}
                    ></input>

                    <button
                      className="btn btn-success apply_promo_button"
                      disabled={DisableHiddenPromo}
                      onClick={(e) => {
                        ApplyHiddenPromo(e);
                      }}
                      data-action="apply"
                    >
                      Apply
                    </button>
                  </div>
                  <div>
                    {HiddenPromoErr === true && (
                      <span className="err">{HiddenPromoErrMsg}</span>
                    )}
                  </div>
                  <div className="client_sub_pay_choose">
                    <span className="promo_title">
                      <span className="plan_label">Payment method :</span>
                    </span>
                    {ShowStripeGateway === true &&
                      <div className="gateway_row gateway_row2">
                        <div className="gateway_image_div">
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "/images/paymentgateways/" +
                              StripeGatewaySrc
                            }
                          />
                        </div>

                        <div className="gateway_title_desc_div">
                          <span className="title">{StripeGatewayName}</span>
                          <p className="desc">
                            {" (" + StripeGatewayDesc + ") "}
                          </p>
                        </div>

                        <div className="gateway_radio_div">
                          <input
                            type={"radio"}
                            name="gateway"
                            value={"stripe"}
                            className="gateway_choose_radio"
                            onChange={(e) => {
                              SetChosenGateway(e.target.value);
                            }}
                          />
                        </div>
                      </div>}
                    {ShowAffirmGateway === true &&
                      <>
                        <div className="gateway_row gateway_row2">
                          <div className="gateway_image_div">
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "/images/paymentgateways/" +
                                AffirmGatewaySrc
                              }
                            />
                          </div>

                          <div className="gateway_title_desc_div">
                            <span className="title">{AffirmGatewayName}</span>
                            <p className="desc">
                              {" (" + AffirmGatewayDesc + ") "}
                            </p>
                          </div>

                          <div className="gateway_radio_div">
                            <input
                              type={"radio"}
                              name="gateway"
                              value={"affirm"}
                              className="gateway_choose_radio"
                              onChange={(e) => {
                                SetChosenGateway(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {AffirmError === true ?
                          <div className="err">{AffirmErrorMsg}</div> : null}
                      </>
                    }
                    {ShowSplititGateway === true &&
                      <>
                        <div className="gateway_row gateway_row2">
                          <div className="gateway_image_div">
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "/images/paymentgateways/" +
                                SplititGatewaySrc
                              }
                            />
                          </div>

                          <div className="gateway_title_desc_div">
                            <span className="title">{SplititGatewayName}</span>
                            <p className="desc">
                              {" (" + SplititGatewayDesc + ") "}
                            </p>
                          </div>

                          <div className="gateway_radio_div">
                            <input
                              type={"radio"}
                              name="gateway"
                              value={"splitit"}
                              className="gateway_choose_radio"
                              onChange={(e) => {
                                SetChosenGateway(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {SplititError === true ?
                          <div className="err">{SplititErrorMsg}</div> : null}
                      </>
                    }
                  </div>
                </div>
              )}
            </div>
            {paymentErr === true && (
              <span className="err">{paymenterrmsg}</span>
            )}
          </Modal.Body>
          <Modal.Footer className="display_block">
            <Button variant="secondary" className="modal_close_button" onClick={handleCloses}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={ProceedToCheckout}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showsuccess}
          onHide={CloseSuccessPopup}
          className="confirm_delete"
        >
          <Modal.Header closeButton>
            <Modal.Title>Plan Subscription Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have successfully purchased the plan.</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseSuccessPopup}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showfail}
          onHide={CloseFailPopup}
          className="confirm_delete"
        >
          <Modal.Header closeButton>
            <Modal.Title>Plan Subscription Failed.!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your payment has been failed.!</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseFailPopup}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPromoApplyfail}
          onHide={ClosePromoFailPopup}
          className="confirm_delete"
        >
          <Modal.Header closeButton>
            <Modal.Title>Promo Apply Failed!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{PromoApplyErrmsg}</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={ClosePromoFailPopup}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={ShowLifetime}
          onHide={CloseLifeTimePopup}
          className="confirm_delete"
        >
          <Modal.Header closeButton>
            <Modal.Title>Plan Subscription!</Modal.Title>
          </Modal.Header>
          <Modal.Body>You are already in a life time plan</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseLifeTimePopup}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={ShowAddOns}
          onHide={CloseAddOns}
          className="confirm_delete inpagesubaddons"
        >
          <Modal.Header closeButton>
            <Modal.Title>Subscription Add Ons!</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <SubscriptionAddons data={userId}/>
          </Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseAddOns}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
};

export default ClientSubscriptions;

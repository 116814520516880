import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';


const MetabolismCarb = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)

    const [TestName, SetTestName] = useState('')
    const [TestNameErr, SetTestNameErr] = useState(false)
    const [PhysicalSlidervalue, SetPhysicalSlidervalue] = useState(0)
    const [CognitiveSliderValue, SetCognitiveSliderValue] = useState(0)
    const [SafetySliderValue, SetSafetySliderValue] = useState(0)
    const [DigestionSliderValue, SetDigestionSliderValue] = useState(0)

    const [SugarBefore,SetSugarBefore]=useState('')
    const [Sugar_1,SetSugar_1]=useState('')
    const [Sugar_2,SetSugar_2]=useState('')
    const [Sugar_3,SetSugar_3]=useState('')
    


    const ChangedSliderPhysical = (value) => {
        SetPhysicalSlidervalue(value)

    }
    const ChangedSliderCognitive = (value) => {
        SetCognitiveSliderValue(value)

    }
    const ChangedSliderSafety = (value) => {
        SetSafetySliderValue(value)
    }
    const ChangedSliderDigestion = (value) => {
        SetDigestionSliderValue(value)
    }

    const SaveMetabolismCarb=async(e)=>{
        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowMetaCarbField(false)
            props.SetMetabolismOption(true)
            return false
        }
        SetTestNameErr(false)
        SetTimeErr(false)
        SetDateErr(false)
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(TestName==='')
        {
            err = true
            SetTestNameErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }

            const response = await axiosJWT.post('add-data/add-metabolism',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Metabolism Carb Tolerance',
                'test_name':TestName,
                'sugar_before':SugarBefore,
                'sugar_1_after':Sugar_1,
                'sugar_2_after':Sugar_2,
                'sugar_3_after':Sugar_3,
                'physical_energy':PhysicalSlidervalue,
                'cognitive_energy':CognitiveSliderValue,
                'safety_duration':SafetySliderValue,
                'digestion':DigestionSliderValue,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowMetaCarbField(false)
                    props.SetMetabolismOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }
           
        }

       

    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (

        <div className="physical_activity">
 <h6 className="heading_popup">{'Metabolism > Carb Tolerance Test'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                        />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Name This test</div>
                <div className="field">
                    <input type={'text'} className="raw_input" onChange={(e) => { SetTestName(e.target.value) }}  {...(TestNameErr === true ? { id: 'err_border' } : {})} defaultValue={TestName} />
                </div>
            </div>

            <div className="field_top_row">

                <div className="centered_label">Blood Sugar Readings</div>
            </div>

            <div className="field_top_row">
                <div className="label">Before (mg/DL)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetSugarBefore(e.target.value) }}   defaultValue={SugarBefore} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">1 hr after (mg/DL)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetSugar_1(e.target.value) }}   defaultValue={Sugar_1} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">2 hr after (mg/DL)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetSugar_2(e.target.value) }}   defaultValue={Sugar_2} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">3 hr after (mg/DL)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetSugar_3(e.target.value) }}   defaultValue={Sugar_3} />
                </div>
            </div>


            <div className="field_top_row">

                <div className="centered_label">How do you feel right now?</div>
            </div>
            <div className="field_top_row">
                <div className="left_right_label">
                    <span>No symptoms</span>
                    <span className="right">Several symptoms</span>
                </div>
            </div>
            <div className="field_top_row">
                <div className="centered_label">Physical reading</div>
                <div className="slider_input">
                    <InputRange
                        maxValue={5}
                        minValue={0}
                        value={PhysicalSlidervalue}
                        onChange={(value) => { ChangedSliderPhysical(value) }}

                    />

                </div>
            </div>
            <div className="field_top_row">
                <div className="centered_label">Cognitive Energy</div>
                <div className="slider_input">
                    <InputRange
                        maxValue={5}
                        minValue={0}
                        value={CognitiveSliderValue}
                        onChange={(value) => { ChangedSliderCognitive(value) }}

                    />

                </div>
            </div>

            <div className="field_top_row">
                <div className="centered_label">Safety Duration</div>
                <div className="slider_input">
                    <InputRange
                        maxValue={5}
                        minValue={0}
                        value={SafetySliderValue}
                        onChange={(value) => { ChangedSliderSafety(value) }}

                    />

                </div>
            </div>

            <div className="field_top_row">
                <div className="centered_label">Digestion</div>
                <div className="slider_input">
                    <InputRange
                        maxValue={5}
                        minValue={0}
                        value={DigestionSliderValue}
                        onChange={(value) => { ChangedSliderDigestion(value) }}

                    />

                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveMetabolismCarb(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveMetabolismCarb(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveMetabolismCarb(e) }} >
                    {'Save'}
                </Button>

           </div>

           
           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>

    )
}
export default MetabolismCarb
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import DirectoryList from "./DirectoryList";

const DocumentsUpload = (props) => {
  const side_nav = localStorage.getItem("side_nav");
  const token = localStorage.getItem("token");
  const Role = localStorage.getItem("role");
  const axiosJWT = axios.create();
  const navigate = useNavigate();

  const [DirectoryName, SetDirectoryName] = useState("");
  const [DirectoryNameErr, SetDirectoryNameErr] = useState(false);
  const [show, Setshow] = useState(false);
  const handleClose = () => {
    Setshow(false);
  };
  const [SaveCategoryButtonLabel, SetSaveCategoryButtonLabel] =
    useState("Add directory");

  const AddCategory = async (e) => {
    SetDirectoryNameErr(false);
    var Err = false;
    if (DirectoryName === "") {
      SetDirectoryNameErr(true);
      Err = true;
    }

    const Target = e.target.getAttribute("data-target");

    if (Target === "add") {
      if (Err === false) {
        const response = await axiosJWT.post(
          "/document-directory/add-directory",
          {
            directory_name: DirectoryName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          handleClose();
          GetMyDirectories();
        }
      }
    } else if (Target === "update") {
      if (Err === false) {
        const response = await axiosJWT.post(
          "/document-directory/update-directory",
          {
            directory_name: DirectoryName,
            id: EditId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          handleClose();
          GetMyDirectories();
        }
      }
    }
  };

  const TriggerAddDirectoryPopup = () => {
    SetPopButtonName("Add");
    SetDirectoryName("");
    SetDirectoryNameErr(false);
    Setshow(true);
  };

  const [MyDirectories, SetMyDirectories] = useState([]);

  const GetMyDirectories = async () => {
    const ListFetch = await axiosJWT.get("/document-directory/my-directory", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (ListFetch.data.List !== null) {
      SetMyDirectories(ListFetch.data.List);
    }
  };
  const [DeleteId, SetDeleteId] = useState("");
  const DeleteDirectory = (e) => {
    const TargetId = e.target.getAttribute("data-id");
    SetDeleteId(TargetId);
    Setdshow(true);
  };

  const DeleteDirectoryProceed = async () => {
    const response = await axiosJWT.post(
      "/document-directory/delete-directory",
      {
        id: DeleteId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      dhandleClose();
      GetMyDirectories();
    }
  };

  const [dshow, Setdshow] = useState(false);
  const dhandleClose = () => {
    Setdshow(false);
  };

  const [EditId, SetEditId] = useState("");
  const [PopButtonName, SetPopButtonName] = useState("Add");
  const TriggerEditCategory = async (e) => {
    SetPopButtonName("Update");
    const Id = e.target.getAttribute("data-id");
    const DetailFetch = await axiosJWT.get(
      "/document-directory/my-directory/detail?id=" + Id + "",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    SetDirectoryName(DetailFetch.data.detail.directory_name);
    SetEditId(Id);
    Setshow(true);
  };

  const [FolderListShow, SetFolderListShow] = useState(true);
  const [DirectoryId, SetDirectoryId] = useState("");

  const ShowFiles = (e) => {
    const id = e.target.getAttribute("data-id");
    SetDirectoryId(id);
    if (e.target.classList.contains("directory_icon") === false) {
      SetFolderListShow(false);
    }
  };

  const NavigateCoachClientDoc = () => {
    navigate("/admin/view-docs/users", { replace: true });
  };
  const NavigateAdminClientDoc=()=>{
    navigate("/coach/view-docs/users", { replace: true });
  }
  const NavigateAdminCoachDoc=()=>{
    navigate("/client/view-docs/users", { replace: true });
  }

  const BackToDocuments=()=>{

    if(Role==='Nutrition')
    {
      props.SetDirectoriesTab(false)
   props.GetListofIntakes(1)
   props.Setaddintakebuttonshow(true)
   props.SetIntakeformtab(true)

    }
    else if(Role==='Client')
    {
      props.SetShowDocumentsTab(false)
      props.SetShowAgreementTab(true)
      props.ListOfAgreementsList(1)
    
    }
   
  }

  useEffect(() => {
    GetMyDirectories();
  }, []);



  return (
    <div
      id="container-adjusts"
      className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}
    >
      <section className="dashboard">
        <div className="container documents_directory">
          <div className="head_row">
            <span className="title">Document Directory</span>

            {(Role === "Nutrition"  || Role==='Client') && (
              <button onClick={BackToDocuments}>
               Back
              </button>
            )}
            <button onClick={TriggerAddDirectoryPopup}>Add Directory</button>
            {Role === "Admin" && (
              <button onClick={NavigateCoachClientDoc}>
                Coach & Client Documents
              </button>
            )}
             {Role === "Nutrition" && (
              <button onClick={NavigateAdminClientDoc}>
                Admin & Client Documents
              </button>
            )}
             {Role === "Client" && (
              <button onClick={NavigateAdminCoachDoc}>
                Admin & Coach Documents
              </button>
            )}
          </div>

          {FolderListShow === true && (
            <div className="folder_lists">
              {MyDirectories.length > 0 &&
                MyDirectories.map((list, index) => (
                  <button
                    type="button"
                    className="collapsible"
                    data-id={list.id}
                    onClick={ShowFiles}
                  >
                    {list.directory_name}
                    <i
                      className="fa fa-folder-open directory_icon pointer_none"
                      aria-hidden="true"
                    ></i>
                    <i
                      className="fa fa-trash directory_icon"
                      data-id={list.id}
                      onClick={DeleteDirectory}
                      aria-hidden="true"
                    ></i>
                    <i
                      className="fa fa-edit directory_icon"
                      data-id={list.id}
                      onClick={TriggerEditCategory}
                      aria-hidden="true"
                    ></i>
                  </button>
                ))}
            </div>
          )}
          {FolderListShow === false && (
            <DirectoryList
              SetFolderListShow={SetFolderListShow}
              DirectoryId={DirectoryId}
            />
          )}
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="commission_popup add_coach_service_category_popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{SaveCategoryButtonLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row section_1_commission">
              <div className="col">
                <label className="commission_inner_popup_label required">
                  Name
                </label>
                <br />
                <input
                  type="text"
                  className="add_category_inner_input"
                  defaultValue={DirectoryName}
                  onChange={(e) => {
                    SetDirectoryName(e.target.value);
                  }}
                  {...(DirectoryNameErr === true ? { id: "err_border" } : {})}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="secondary"
              className="close_button_commission"
              onClick={handleClose}
            >
              Back
            </Button>
            <Button
              variant="primary"
              className="save_button_commission float_right"
              data-target={PopButtonName.toLowerCase()}
              onClick={AddCategory}
            >
              {PopButtonName}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={dshow}
          onHide={dhandleClose}
          className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Directory</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete this directory?</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="secondary"
              className="close_button_commission"
              onClick={dhandleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="save_button_commission float_right"
              onClick={DeleteDirectoryProceed}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
};
export default DocumentsUpload;

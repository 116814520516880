import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


const Welcomemail = () => {

    const token = localStorage.getItem("token");

    const tags = "Available Tags: [FIRST_NAME]  [LAST_NAME]  [EMAIL]  [PASSWORD]  [ROLE] ";

    const axiosJWT = axios.create();
    var [mailshow, setMailShow] = useState(false);
    var [messageshow, setMessageShow] = useState(false);
    const [senderAddress, setsenderAddress] = useState();
    const [mailContent, setmailContent] = useState();
    var [updatedsenderAddress, setupdatedsenderAddress] = useState();
    var [updatedmailContent, setupdatedmailContent] = useState();

    const mailshowClose = () => setMailShow(false);
    const mailshowOpen = () => {setMailShow(true);SetEmailAddressErr(false)};

    const [EmailAddressErr, SetEmailAddressErr] = useState(false)
    const [EmailAddressErrMsg, SetEmailAddressErrMsg] = useState('')

    const messagehowClose = () => setMessageShow(false);
    const messageshowOpen = async () => {

        const response_message = await axiosJWT.get('/admin/config/marketing_welcome_mail_content',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response_message.data !== null) {
            var editor_content = ContentState.createFromBlockArray(convertFromHTML(response_message.data.value));
         
            seteditorvalue(EditorState.createWithContent(editor_content));

        }



        setMessageShow(true);
    }



    const [editorvalue, seteditorvalue] = useState(EditorState.createEmpty());
    const editorChange = (data) => {

        seteditorData(data.blocks);
    }
    const [editorData, seteditorData] = useState('');

    useEffect(() => {
        getwelcomemail()
    }, [])

    const getwelcomemail = async () => {
        const response_mail = await axiosJWT.get('/admin/config/marketing_welcome_sender_mail_address',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });



        //setsenderAddress(response.data.value)
        setupdatedsenderAddress(response_mail.data.value);


        const response_message = await axiosJWT.get('/admin/config/marketing_welcome_mail_content',
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        setupdatedmailContent(response_message.data.value);

    }

    const updateSenderAddress = async () => {



        var err = false;

        if (senderAddress === undefined || senderAddress === '') {
            SetEmailAddressErrMsg('Please enter email address')
            SetEmailAddressErr(true)
            err = true
        }

        if (senderAddress !== '') {

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(senderAddress) === false) {
                SetEmailAddressErrMsg('Please enter valid email address')
                SetEmailAddressErr(true)
                err = true
            }


        }

        if (err === true) {
            return false
        }



        const response = await axiosJWT.post('/admin/config',
            {
                key: 'marketing_welcome_sender_mail_address',
                value: senderAddress
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        setupdatedsenderAddress(senderAddress);
        mailshowClose();
        getwelcomemail();

    }

    const updateMessage = async () => {
        var editor_content = draftToHtml(convertToRaw(editorvalue.getCurrentContent()));

        const response = await axiosJWT.post('/admin/config',
            {
                key: 'marketing_welcome_mail_content',
                value: editor_content
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });



        setupdatedmailContent(editor_content);
        messagehowClose();
        getwelcomemail();
    }

    return (
        <div>
            <div className='intro whitebg'>
                <div className='p_title grayfont'>Outgoing Email Address</div>
                <div className='description grayfont'><p>This is test description text to welcome mail.</p></div>
            </div>


            <div id="referral_data" className='referral_data modal-body'>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label required'>Sender Address:</label><label className='inner_popup_label' style={{ "color": "#226920", "float": "right", "cursor": "pointer" }} onClick={mailshowOpen}>Change Address</label><br />
                        <input type='text' className='inner_popup_input' value={updatedsenderAddress} disabled={true}></input>
                    </div>
                </div><br></br>
                <div className="row">
                    <div className="col">
                        <label className='inner_popup_label'>Welcome mail content:</label><label className='inner_popup_label' style={{ "color": "#226920", "float": "right", "cursor": "pointer" }} onClick={messageshowOpen}>Edit Message</label><br />

                        <div className='welcome_mail_div' dangerouslySetInnerHTML={{ __html: updatedmailContent }}></div>
                        {/* <textarea className='inner_popup_input' value={updatedmailContent} disabled={true} rows="10"></textarea> */}


                        <label className='inner_popup_label'>{tags}</label>
                    </div>
                </div>
            </div>

            <div style={{ clear: 'both' }}></div>

            <Modal show={mailshow} onHide={mailshowClose} className='adminmarketing_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Sender Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <input type='text' className='inner_popup_input' defaultValue={updatedsenderAddress} onChange={(e) => { setsenderAddress(e.target.value) }} {...(EmailAddressErr===true ? {id: 'err_border'} : {})}></input>
                        </div>
                    </div>
                    {EmailAddressErr === true &&
                        <div className="row err">
                            <div className="col">
                                {EmailAddressErrMsg}
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={mailshowClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' onClick={(e) => { updateSenderAddress(e) }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={messageshow} onHide={messagehowClose} className='adminmarketing_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">


                            <Editor
                                wrapperClassName="texteditor"
                                editorState={editorvalue}
                                onEditorStateChange={seteditorvalue}
                                onContentStateChange={editorChange}
                                handlePastedText={() => false}
                            />


                            {/* <textarea className='inner_popup_input' defaultValue={updatedmailContent} onChange={(e)=>{setmailContent(e.target.value)}} rows="8"></textarea>
                            <label className='inner_popup_label'>{tags}</label> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={messagehowClose}>
                        Back
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' onClick={(e) => { updateMessage(e) }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Welcomemail
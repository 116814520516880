import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import moment from 'moment-timezone';


const GoogleFitness = () => {
    const token = localStorage.getItem("token");
    const side_nav = localStorage.getItem("side_nav");   


    const [isgoogleLoggedIn, setisgoogleLoggedIn] = useState(false);

    const [stepcount, setstepcount] = useState('');
    const [sleepcount, setsleepcount] = useState('');


    const axiosJWT = axios.create();

    useEffect(() => {
        load_user();
    }, []);

    const timeselect = [
      { value: 'today', label: 'Today' },
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'this_week', label: 'This Week' },
      { value: 'last_week', label: 'Last Week' },
      { value: 'this_month', label: 'This Month' },
      { value: 'last_month', label: 'Last Month' },
      { value: 'this_year', label: 'This Year' },
      { value: 'all_time', label: 'All Time' }
    ];

    const load_user = async () => {
        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.data.google_token !== '' & response.data.google_token !== null)
        {
            localStorage.setItem("google_refreshToken", response.data.google_token);
            setisgoogleLoggedIn(true);
            getFitness();
        }
        else
        {
            setisgoogleLoggedIn(false);
            handleTokenFromQueryParams();
        }        
    }

    const createGoogleAuthLink = async () => {
        try {
          const response = await axiosJWT.get(process.env.REACT_APP_BACKEND_URL+"/connected-devices/google-auth-link",
          {
            headers: {
                Authorization: `Bearer ${token}`
              }
          });
          window.location.href = response.data.url;          
        } catch (error) {
          throw new Error("Issue with Login", error.message);
        }
    }
    
    const getToken = async () => {
        if (tokenExpired()) {
          const refreshtoken = localStorage.getItem("google_refreshToken");
          const google_token = await getValidTokenFromServer(refreshtoken);
          localStorage.setItem("google_accessToken", google_token.accessToken);
          localStorage.setItem("google_expirationDate", newExpirationDate());
          return google_token.accessToken;
        } else {          
          return localStorage.getItem("google_accessToken");
        }
    }
      
    const newExpirationDate = () => {
        var expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        return expiration;
    }
      
    const tokenExpired = () => {
        const now = Date.now();
      
        const expirationDate = localStorage.getItem("google_expirationDate");
        const expDate = new Date(expirationDate);
      
        if (now > expDate.getTime()) {
          return true; // token expired
        }
      
        return false; // valid token
    }
      
    const getValidTokenFromServer = async (refreshToken) => {
        // get new token from server with refresh token
        try {
         
          const response = await axiosJWT.post(process.env.REACT_APP_BACKEND_URL+"/connected-devices/google-generate-token",
          {
            refreshToken: refreshToken,
          },
          {
            headers: {
                Authorization: `Bearer ${token}`
              }
          });

          if(response)
          return response.data;
        } catch (error) {
          throw new Error("Issue getting new token", error.message);
        }
    }

    const getFitness = async (timeselected) => {

        if(!timeselected)
        var timeselected = 'today';

        try {
          const google_token = await getToken();          

          if(timeselected === 'today')
          {
            var start_date = moment().format("MM-DD-YYYY");
            var start_time = ((moment(start_date).format("X"))*1000);
            var end_time = ((moment().format("X"))*1000);
          }
          else if(timeselected === 'yesterday')
          {
            var start_date = moment().subtract(1, 'days').format("MM-DD-YYYY");
            var start_time = ((moment(start_date).format("X"))*1000);
            var end_time = (((parseInt(moment(start_date).format("X")))+86400)*1000);
          }
          else if(timeselected === 'this_week')
          {
            var start_date = moment().startOf('week').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().endOf('week').format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }
          else if(timeselected === 'last_week')
          {
            var start_date = moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }
          else if(timeselected === 'this_month')
          {
            var start_date = moment().startOf('months').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().endOf('months').format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }
          else if(timeselected === 'last_month')
          {
            var start_date = moment().subtract(1,'months').startOf('months').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().subtract(1,'months').endOf('months').format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }
          else if(timeselected === 'this_year')
          {
            var start_date = moment().startOf('year').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().endOf('year').format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }
          else
          {
            var start_date = moment().subtract(10,'year').startOf('year').format('YYYY-MM-DD'); 
            var start_time = ((moment(start_date).format("X"))*1000);

            var end_date = moment().format('YYYY-MM-DD'); 
            var end_time = ((moment(end_date).format("X"))*1000);
          }

          // const request = await fetch(
          //   `https://www.googleapis.com/fitness/v1/users/me/dataSources`,           
          //   {
          //     headers: {
          //       Authorization: `Bearer ${google_token}`,
          //     }             
          //   }
          // );

          // const data = await request.json();

          const step_request = await fetch(
              'https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate',  
              {
                method: 'POST',
                body: JSON.stringify({
                  "aggregateBy": [{
                      "dataTypeName": "com.google.step_count.delta",
                          "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
                  }],
                  "bucketByTime": { "durationMillis": 86400000 }, 
                  "startTimeMillis": start_time, 
                  "endTimeMillis": end_time
                }),    
                headers: {
                  Authorization: `Bearer ${google_token}`,
                }      
              });
         
          console.log("step data", step_request);

          if(step_request)
          {
            var step_count = step_request.bucket.dataset.point.value.intVal;
            setstepcount(step_count)
          }

          const sleep_request = await fetch(
            'https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate',  
            {
              method: 'POST',
              body: JSON.stringify({
                "aggregateBy": [{
                    "dataTypeName": "com.google.sleep.segment"
                }],
                "bucketByTime": { "durationMillis": 86400000 }, 
                "startTimeMillis": start_time, 
                "endTimeMillis": end_time
              }),    
              headers: {
                Authorization: `Bearer ${google_token}`,
              }      
            });
       
          console.log("sleep data", sleep_request);

          if(sleep_request)
          {
            var sleep_count = sleep_request.bucket.dataset.point.value.intVal;
            setsleepcount(sleep_count)
          }

        } catch (error) {
          console.log("error getting fitness data", error);
          return error.message;
        }
    }


    const handleTokenFromQueryParams = async () => {
        const query = new URLSearchParams(window.location.search);
        const accessToken = query.get("at");
        const refreshToken = query.get("rt");
        const expirationDate = newExpirationDate();
        
        if (accessToken && refreshToken) {            
            const response = await axiosJWT.post(process.env.REACT_APP_BACKEND_URL+"/connected-devices/google-token",
            {
                refresh_token: refreshToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            storeTokenData(accessToken, refreshToken, expirationDate);
            setisgoogleLoggedIn(true);
        }
    }

    const storeTokenData = async (accessToken, refreshToken, expirationDate) => {
        localStorage.setItem("google_accessToken", accessToken);
        localStorage.setItem("google_refreshToken", refreshToken);
        localStorage.setItem("google_expirationDate", expirationDate);
    }

    const googleLogout = async () => {
      await axiosJWT.post(process.env.REACT_APP_BACKEND_URL+"/connected-devices/google-token",
            {
                refresh_token: '',
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

      load_user();
    }

    const timeChange = async (e) =>{
      var timeselected = e.value;
      getFitness(timeselected);
    }
    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="google_fitness container">                        
                        <div className='title_block'>
                            <div className='title_left'>
                                <div className="title">
                                    <h3>Google Fitness</h3>
                                </div>
                            </div>
                            <div className='title_right'>
                                <div className='action_buttons'>
                                {isgoogleLoggedIn ? <Button className='green_button btn' onClick={googleLogout}>Logout</Button> : null}                           
                                </div>
                            </div>
                        </div>
                        <div style={{clear:"both"}}></div>
                        {!isgoogleLoggedIn ? 
                        <div className='google_signin action_buttons'>
                        <Button className='green_button btn' onClick={createGoogleAuthLink}>Login</Button>
                        </div> : null } 
                        {isgoogleLoggedIn ? 
                        <div className='google_fitness_dashboard'>
                          <div className='timeselect'>
                            <i className="fa fa-calendar-o" aria-hidden="true"></i><Select defaultValue={{value: 'today', label: 'Today'}} options={timeselect} onChange={timeChange} />
                          </div>
                          <div style={{clear:"both"}}></div>
                          <div className='sections row'>
                            <div className='col steps'>
                              <div className='title'> Steps</div>
                              <div className='value'>{stepcount ? stepcount : "0"}</div>
                            </div>
                            <div className='col sleep'>
                              <div className='title'> Sleep</div>
                              <div className='value'>{sleepcount ? sleepcount : "0"}</div>
                            </div>
                          </div>
                        </div>
                        : null
                        }
                </div>
            </section>
        </div>
    )

}

export default GoogleFitness
import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import male_icon from '../../images/male.png'
import female_icon from '../../images/female.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AddData from "./AddData/AddData";
import QuizSuccesForm from "./Quiz/SuccessPopup";
import WithingsCheckoutForm from './Withings/Form'

var QB = require('quickblox/quickblox.min');


const ClientDashboard = () => {

    const [ShowSuccessQuizForm,SetShowSuccessQuizForm]=useState(false)


    useEffect(() => {  


        let search = window.location.search;
        let params = new URLSearchParams(search);
        let paymentSuccess = params.get('quiz');
        if(paymentSuccess==='updated')
        {
            SetShowSuccessQuizForm(true)
 
        }
        window.history.pushState({}, document.title, window.location.pathname);



        setTimeout(() => {
            if(localStorage.getItem('QB_SESSION_RESPONSE') && localStorage.getItem('QB_SESSION_RESPONSE') !== '')
            loadmessages();   
        }, 5000);
                
    },[]);

    const navigate = useNavigate()
    const side_nav = localStorage.getItem("side_nav");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const loggedin_userid = localStorage.getItem('userId');
    const client_timezone = localStorage.getItem("user_timezone");
    

    const axiosJWT = axios.create();
    const percentage = 66;
    const [startDate, setStartDate] = useState(new Date());
    const [TargetGoalCalories, SetTargetGoalCalories] = useState(0)
    const [TargetGoalFats, SetTargetGoalFats] = useState(0)
    const [TargetGoalCarbs, SetTargetGoalCarbs] = useState(0)
    const [TargteGoalProteins, SetTargteGoalProteins] = useState(0)
    const [AchievedGoalCalories, SetAchievedGoalCalories] = useState(0)
    const [AchievedGoalFats, SetAchievedGoalFats] = useState(0)
    const [AchievedGoalCarbs, SetAchievedGoalCarbs] = useState(0)
    const [AchievedGoalProteins, SetAchievedGoalProteins] = useState(0)
    const [AchievedGoalCaloriesPercentage, SetAchievedGoalCaloriesPercentage] = useState(0)
    const [AchievedGoalFatsPercentage, SetAchievedGoalFatsPercentage] = useState(0)
    const [AchievedGoalCarbsPercentage, SetAchievedGoalCarbsPercentage] = useState(0)
    const [AchievedGoalProteinsPercentage, SetAchievedGoalProteinsPercentage] = useState(0)
    const [TotalReport, SetTotalReport] = useState('0')
    const [TotalMealPlan, SetTotalMealPlan] = useState('0')
    const [TotalAppointment, SetTotalAppointment] = useState('0')
    const [TotalQuestionnaire, SetTotalQuestionnaire] = useState('0')
    const [TopDailyGoalCalories, SetTopDailyGoalCalories] = useState(0)
    const [AchivedByFoodCalories, SetAchivedByFoodCalories] = useState(0)
    const [CaloriesReducedByExercise, SetCaloriesReducedByExercise] = useState(0)
    const [ProgressDiaryPercentage, SetPrgoressDiaryPercentage] = useState(0)
    const [NetCalories, SetNetCalories] = useState(0)
    const [profileData, setprofileData] = useState([]);
    const [ClientUpcomingAppointmentList, SetClientUpcomingAppointmentList] = useState([])
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
    const [dialogList, setdialogList] = useState([]);
    const [usersList, setusersList] = useState([]);
    const [loggedin_userQBId, setloggedin_userQBId] = useState('');


    var prev_date = new Date();
    prev_date.setDate(prev_date.getDate() - 1);


    const load_profile = async () => {
        await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(async (response) => {
            setprofileData(response.data);
      
            
            //loadmessages();
        });
    }


    const GetProgressDairyData = async (DatePass,coach_id='') => {

   
        SetTargetGoalCalories(0)
        SetTargetGoalFats(0)
        SetTargetGoalCarbs(0)
        SetTargteGoalProteins(0)
        SetAchievedGoalCalories(0)
        SetAchievedGoalFats(0)
        SetAchievedGoalCarbs(0)
        SetAchievedGoalProteins(0)
        SetAchievedGoalCaloriesPercentage(0)
        SetAchievedGoalFatsPercentage(0)
        SetAchievedGoalCarbsPercentage(0)
        SetAchievedGoalProteinsPercentage(0)

        var AchievedCalories = 0;
        var AchievedFats = 0;
        var AchievedCarbs = 0;
        var AchievedProteins = 0;
        var TargetCalories = 0;
        var TargetFats = 0;
        var TargetCarbs = 0;
        var TargetProteins = 0
        var Netcalories = 0;
        var InnerCaloriesReduced=0;





        const dateTimeInParts = DatePass.toISOString().split("T");
        const date = dateTimeInParts[0];


        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+coach_id+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

    
            if (response.data.final_data[1] !== null) {
                if (response.data.final_data[1].daily_calories !== undefined) {
                    TargetCalories = response.data.final_data[1].daily_calories
                    SetTargetGoalCalories(response.data.final_data[1].daily_calories)
                    SetTopDailyGoalCalories(response.data.final_data[1].daily_calories)
                }
                if (response.data.final_data[1].daily_fat !== undefined) {
                    TargetFats = response.data.final_data[1].daily_fat
                    SetTargetGoalFats(response.data.final_data[1].daily_fat)
                }
                if (response.data.final_data[1].daily_carb !== undefined) {
                    TargetCarbs = response.data.final_data[1].daily_carb
                    SetTargetGoalCarbs(response.data.final_data[1].daily_carb)
                }
                if (response.data.final_data[1].daily_protein !== undefined) {
                    TargetProteins = response.data.final_data[1].daily_protein
                    SetTargteGoalProteins(response.data.final_data[1].daily_protein)
                }

            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].calories !== 'undefined' && response.data.final_data[0][0].calories !== null && response.data.final_data[0][0].calories !== '') {
                InnerCaloriesReduced=parseFloat(response.data.final_data[0][0].calories)
                SetCaloriesReducedByExercise(InnerCaloriesReduced)
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                var breakfastArray = breakfastId.split(",");
          
                breakfastArray.forEach(async(value, index, array) => {


                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))
                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))
                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))
                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))
                        SetAchievedGoalCalories(AchievedCalories)


                        Netcalories = AchievedCalories - InnerCaloriesReduced
                        SetNetCalories(Netcalories)

                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentageBar = (AchievedCalories / TargetCalories) * 100
                            SetPrgoressDiaryPercentage(Math.round(CaloriesPercentageBar) + '%')

                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetPrgoressDiaryPercentage(100 + '%')
                        }
                        else {
                            SetPrgoressDiaryPercentage(0 + '%')
                        }

                        SetAchievedGoalFats(AchievedFats)
                        SetAchievedGoalCarbs(AchievedCarbs)
                        SetAchievedGoalProteins(AchievedProteins)
                        SetAchivedByFoodCalories(AchievedCalories)

                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))

                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }
                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats >= TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }
                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs >= TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }
                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins >= TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== 'undefined' && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var breakfastId = response.data.final_data[0][0].lunch
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))
                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))
                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))
                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))
                        SetAchievedGoalCalories(AchievedCalories)

                        Netcalories = AchievedCalories - InnerCaloriesReduced
                        SetNetCalories(Netcalories)

                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentageBar = (AchievedCalories / TargetCalories) * 100
                            SetPrgoressDiaryPercentage(Math.round(CaloriesPercentageBar) + '%')

                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetPrgoressDiaryPercentage(100 + '%')
                        }
                        else {
                            SetPrgoressDiaryPercentage(0 + '%')
                        }

                        SetAchievedGoalFats(AchievedFats)
                        SetAchievedGoalCarbs(AchievedCarbs)
                        SetAchievedGoalProteins(AchievedProteins)
                        SetAchivedByFoodCalories(AchievedCalories)
                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }
                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats >= TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }
                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs >= TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }
                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins >= TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== 'undefined' && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var breakfastId = response.data.final_data[0][0].snacks
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async (value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))
                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))
                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))
                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))
                        SetAchievedGoalCalories(AchievedCalories)

                        Netcalories = AchievedCalories - InnerCaloriesReduced
                        SetNetCalories(Netcalories)
                        

                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentageBar = (AchievedCalories / TargetCalories) * 100
                            SetPrgoressDiaryPercentage(Math.round(CaloriesPercentageBar) + '%')

                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetPrgoressDiaryPercentage(100 + '%')
                        }
                        else {
                            SetPrgoressDiaryPercentage(0 + '%')
                        }

                        SetAchievedGoalFats(AchievedFats)
                        SetAchievedGoalCarbs(AchievedCarbs)
                        SetAchievedGoalProteins(AchievedProteins)
                        SetAchivedByFoodCalories(AchievedCalories)
                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }
                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats >= TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }
                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs >= TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }
                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins >= TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== 'undefined' && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {

                var breakfastId = response.data.final_data[0][0].dinner
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))
                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))
                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))
                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))
                        SetAchievedGoalCalories(AchievedCalories)

                        Netcalories = AchievedCalories - InnerCaloriesReduced
                        SetNetCalories(Netcalories)

                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentageBar = (AchievedCalories / TargetCalories) * 100
                            SetPrgoressDiaryPercentage(Math.round(CaloriesPercentageBar) + '%')

                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetPrgoressDiaryPercentage(100 + '%')
                        }
                        else {
                            SetPrgoressDiaryPercentage(0 + '%')
                        }

                        SetAchievedGoalFats(AchievedFats)
                        SetAchievedGoalCarbs(AchievedCarbs)
                        SetAchievedGoalProteins(AchievedProteins)
                        SetAchivedByFoodCalories(AchievedCalories)
                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories >= TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }
                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats >= TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }
                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs >= TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }
                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                          
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins >= TargetProteins) {
                        
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }



                    })
                })
            }
        })





    }

    const GetRecipeDetails = async (RecipeId) => {
      

        var CheckType = String(RecipeId)
        if (!CheckType.startsWith('FITPALIR') && !CheckType.startsWith('FJ')) {


            try{

                const response = axiosJWT.get('' + process.env.REACT_APP_RECIPE_PROVIDER_HOST + '/recipe/' + RecipeId + '?api_key=' + process.env.REACT_APP_RECIPE_PROVIDER_KEY + '', {

                })
                return response

            }catch(err)
            {
                console.log(err)
            }
           
        }
        else if(CheckType.startsWith('FITPALIR')) {

            var Recipeobj = {};

            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (RecipeData.data.recipe !== null) {

                var RecipeImg = '';

                if (RecipeData.data.recipe.recipe_image !== null && RecipeData.data.recipe.recipe_image !== '') {
                    RecipeImg =RecipeData.data.recipe.recipe_image
                }


                Recipeobj = {

                    data: {
                        RecipeID: RecipeData.data.recipe.recipe_uid,
                        Title: RecipeData.data.recipe.recipe_name,
                        PhotoUrl: RecipeImg,
                        NutritionInfo: {

                            TotalCalories: RecipeData.data.recipe.recipe_calories,
                            TotalFat: RecipeData.data.recipe.recipe_fats,
                            Protein: RecipeData.data.recipe.recipe_proteins,
                            TotalCarbs: RecipeData.data.recipe.recipe_carbs

                        }
                    }


                }

            }


            return Recipeobj
        }
        else if (CheckType.startsWith('FJ'))
        {
         

            RecipeId = RecipeId.replace(/\D/g,'');
            var Recipeobj = {};
            const RecipeData = await axiosJWT.get("/food-journal/detail?id=" + RecipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            var RecipeName='Recipe Name not available';
            var Calories=0;
            var Fat=0;
            var Carbs=0;
            var Protein=0;
            var RecipeImage=RecipeData.data.result.journal_img;
            var CreatedRecipeId='FJ'+RecipeData.data.result.id;
    
            if(RecipeData.data.result.ingredients)
            {
     
                if(RecipeData.data.result.ingredients!==null && RecipeData.data.result.ingredients!=='')
                {
                 
                    const RecipeDetailsObj=JSON.parse(RecipeData.data.result.ingredients)
                    if(RecipeDetailsObj[0].ingredient_name)
                    {
                        RecipeName=RecipeDetailsObj[0].ingredient_name
                    }

                  

                    RecipeDetailsObj.forEach(element => {
                       //caloriemama kg to g converting
                       if(element.calories)
                        {
                            Calories=parseFloat(element.calories)+Calories;
                        }
                        if(element.fat)
                        {
                            Fat=(parseFloat(element.fat)*1000)+Fat;
                           
                        }
                        if(element.protein)
                        {
                            Protein=(parseFloat(element.protein)*1000)+Protein;
                        }
                        if(element.carbs)
                        {
                            Carbs=(parseFloat(element.carbs)*1000)+Carbs;
                        }
                  

                    });
                  
                }
            }

           
            Recipeobj = {

                data: {
                    RecipeID: CreatedRecipeId,
                    Title: RecipeName,
                    PhotoUrl: RecipeImage,
                    NutritionInfo: {

                        TotalCalories: Calories,
                        TotalFat: Fat,
                        Protein: Protein,
                        TotalCarbs: Carbs

                    }
                }


            }

            return Recipeobj
          
           
        }


    }

    const ChangeCaloriesDate = async (data) => {



        SetTargetGoalCalories(0)
        SetAchievedGoalCalories(0)
        SetAchievedGoalCaloriesPercentage(0)


        var AchievedCalories = 0;

        var TargetCalories = 0;

        var date = ''
        if (data === 'today') {
            const dateTimeInParts = startDate.toISOString().split("T");
            date = dateTimeInParts[0];

        }
        else if (data === 'yesterday') {
            const dateTimeInParts = prev_date.toISOString().split("T");
            date = dateTimeInParts[0];

        }

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+GoalSource+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


            if (response.data.final_data[1] !== null) {
                if (response.data.final_data[1].daily_calories !== undefined) {
                    TargetCalories = response.data.final_data[1].daily_calories
                    SetTargetGoalCalories(response.data.final_data[1].daily_calories)
                }


            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        SetAchievedGoalCalories(AchievedCalories)


                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories > TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }

                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== 'undefined' && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var breakfastId = response.data.final_data[0][0].lunch
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        SetAchievedGoalCalories(AchievedCalories)


                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories > TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }



                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== 'undefined' && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var breakfastId = response.data.final_data[0][0].snacks
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        SetAchievedGoalCalories(AchievedCalories)


                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories > TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }




                    })
                })
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== 'undefined' && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {

                var breakfastId = response.data.final_data[0][0].dinner
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCalories = AchievedCalories + Math.round(parseInt(response.data.NutritionInfo.TotalCalories))

                        SetAchievedGoalCalories(AchievedCalories)


                        if (AchievedCalories < TargetCalories && AchievedCalories !== 0) {

                            var CaloriesPercentage = (AchievedCalories / TargetCalories) * 100
                            SetAchievedGoalCaloriesPercentage(Math.round(CaloriesPercentage))
                        }
                        else if (AchievedCalories > TargetCalories) {
                            SetAchievedGoalCaloriesPercentage(100)
                        }
                        else {
                            SetAchievedGoalCaloriesPercentage(0)
                        }



                    })
                })
            }
        })
    }

    const ChangedFatDate = async (data) => {


        SetTargetGoalFats(0)
        SetAchievedGoalFats(0)
        SetAchievedGoalFatsPercentage(0)



        var AchievedFats = 0;
        var TargetFats = 0;

        var date = ''
        if (data === 'today') {
            const dateTimeInParts = startDate.toISOString().split("T");
            date = dateTimeInParts[0];

        }
        else if (data === 'yesterday') {
            const dateTimeInParts = prev_date.toISOString().split("T");
            date = dateTimeInParts[0];

        }
        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+GoalSource+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response)
            if (response.data.final_data[1] !== null) {
                if (response.data.final_data[1].daily_fat !== undefined) {
                    TargetFats = response.data.final_data[1].daily_fat
                    SetTargetGoalFats(response.data.final_data[1].daily_fat)
                }
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        SetAchievedGoalFats(AchievedFats)



                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats > TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }





                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== 'undefined' && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var breakfastId = response.data.final_data[0][0].lunch
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        SetAchievedGoalFats(AchievedFats)



                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats > TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }





                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== 'undefined' && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var breakfastId = response.data.final_data[0][0].snacks
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        SetAchievedGoalFats(AchievedFats)



                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats > TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }





                    })
                })
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== 'undefined' && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {

                var breakfastId = response.data.final_data[0][0].dinner
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedFats = AchievedFats + Math.round(parseInt(response.data.NutritionInfo.TotalFat))


                        SetAchievedGoalFats(AchievedFats)



                        if (AchievedFats < TargetFats && AchievedFats !== 0) {
                            var FatsPercentage = (AchievedFats / TargetFats) * 100
                            SetAchievedGoalFatsPercentage(Math.round(FatsPercentage))
                        }
                        else if (AchievedFats > TargetFats) {
                            SetAchievedGoalFatsPercentage(100)
                        }
                        else {
                            SetAchievedGoalFatsPercentage(0)
                        }




                    })
                })
            }
        })
    }

    const ChangedCarbDate = async (data) => {


        SetTargetGoalCarbs(0)
        SetAchievedGoalCarbs(0)
        SetAchievedGoalCarbsPercentage(0)



        var AchievedCarbs = 0;
        var TargetCarbs = 0;

        var date = ''
        if (data === 'today') {
            const dateTimeInParts = startDate.toISOString().split("T");
            date = dateTimeInParts[0];

        }
        else if (data === 'yesterday') {
            const dateTimeInParts = prev_date.toISOString().split("T");
            date = dateTimeInParts[0];

        }

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+GoalSource+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            // console.log(response)
            if (response.data.final_data[1] !== null) {
                if (response.data.final_data[1].daily_carb !== undefined) {
                    TargetCarbs = response.data.final_data[1].daily_carb
                    SetTargetGoalCarbs(response.data.final_data[1].daily_carb)
                }
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        SetAchievedGoalCarbs(AchievedCarbs)



                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs > TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }





                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== 'undefined' && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var breakfastId = response.data.final_data[0][0].lunch
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        SetAchievedGoalCarbs(AchievedCarbs)




                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs > TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }


                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== 'undefined' && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var breakfastId = response.data.final_data[0][0].snacks
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {

                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))

                        SetAchievedGoalCarbs(AchievedCarbs);

                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs > TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }


                    })
                })
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== 'undefined' && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {

                var breakfastId = response.data.final_data[0][0].dinner
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedCarbs = AchievedCarbs + Math.round(parseInt(response.data.NutritionInfo.TotalCarbs))


                        SetAchievedGoalCarbs(AchievedCarbs)



                        if (AchievedCarbs < TargetCarbs && AchievedCarbs !== 0) {
                            var CarbsPercentage = (AchievedCarbs / TargetCarbs) * 100
                            SetAchievedGoalCarbsPercentage(Math.round(CarbsPercentage))
                        }
                        else if (AchievedCarbs > TargetCarbs) {
                            SetAchievedGoalCarbsPercentage(100)
                        }
                        else {
                            SetAchievedGoalCarbsPercentage(0)
                        }




                    })
                })
            }
        })

    }

    const ChangedProteinDate = async (data) => {


        SetTargteGoalProteins(0)

        SetAchievedGoalProteins(0)

        SetAchievedGoalProteinsPercentage(0)


        var AchievedProteins = 0;

        var TargetProteins = 0
        var date = ''
        if (data === 'today') {
            const dateTimeInParts = startDate.toISOString().split("T");
            date = dateTimeInParts[0];

        }
        else if (data === 'yesterday') {
            const dateTimeInParts = prev_date.toISOString().split("T");
            date = dateTimeInParts[0];

        }

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '&coach_id='+GoalSource+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            // console.log(response)
            if (response.data.final_data[1] !== null) {

                if (response.data.final_data[1].daily_protein !== undefined) {
                    TargetProteins = response.data.final_data[1].daily_protein
                    SetTargteGoalProteins(response.data.final_data[1].daily_protein)
                }

            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].breakfast !== 'undefined' && response.data.final_data[0][0].breakfast !== null && response.data.final_data[0][0].breakfast !== '') {

                var breakfastId = response.data.final_data[0][0].breakfast
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        SetAchievedGoalProteins(AchievedProteins)

                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins > TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].lunch !== 'undefined' && response.data.final_data[0][0].lunch !== null && response.data.final_data[0][0].lunch !== '') {

                var breakfastId = response.data.final_data[0][0].lunch
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        SetAchievedGoalProteins(AchievedProteins)




                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins > TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }

            if (response.data.final_data[0][0] && response.data.final_data[0][0].snacks !== 'undefined' && response.data.final_data[0][0].snacks !== null && response.data.final_data[0][0].snacks !== '') {

                var breakfastId = response.data.final_data[0][0].snacks
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        SetAchievedGoalProteins(AchievedProteins)




                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins > TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }




                    })
                })
            }
            if (response.data.final_data[0][0] && response.data.final_data[0][0].dinner !== 'undefined' && response.data.final_data[0][0].dinner !== null && response.data.final_data[0][0].dinner !== '') {

                var breakfastId = response.data.final_data[0][0].dinner
                var breakfastArray = breakfastId.split(",");
                // console.log(breakfastArray)
                breakfastArray.forEach(async(value, index, array) => {
                    const response = await GetRecipeDetails(value).then((response) => {


                        AchievedProteins = AchievedProteins + Math.round(parseInt(response.data.NutritionInfo.Protein))

                        SetAchievedGoalProteins(AchievedProteins)




                        if (AchievedProteins < TargetProteins && AchievedProteins !== 0) {
                            var ProteinsPercentage = (AchievedProteins / TargetProteins) * 100
                            SetAchievedGoalProteinsPercentage(Math.round(ProteinsPercentage))
                        }
                        else if (AchievedProteins > TargetProteins) {
                            SetAchievedGoalProteinsPercentage(100)
                        }
                        else {
                            SetAchievedGoalProteinsPercentage(0)
                        }



                    })
                })
            }
        })

    }

    const [showAdd, setShowAdd] = useState(false);

    const handleClosesAdd = () => setShowAdd(false);
    const handleShowsAdd = () => setShowAdd(true);
    const [DefaultAddValue, SetDefaultAddValue] = useState('')

    const [TodayWeightErr, SetTodayWeightErr] = useState(false)
    const [TodayWeightErrMsg, SetTodayWeightErrMsg] = useState(false)


    const FinalAddButton = async () => {

        var err = false;
        SetTodayWeightErr(false)

        if (DefaultAddValue === '') {
            SetTodayWeightErr(true)
            SetTodayWeightErrMsg('Please enter today weight')
            err = true
        }
        if (err === true) {
            return false
        }
        const response = await axiosJWT.post('/progress-diary',
            {
                'weight': DefaultAddValue,
                'ddate': startDate


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            handleClosesAdd()

        }



    }
    const FinalAddButtonc = async () => {

        var err = false;
        SetTodayCaloriesErr(false)

        if (DefaultAddValuec === '') {
            SetTodayCaloriesErr(true)
            SetTodayCaloriesErrMsg('Please enter today weight')
            err = true
        }
        if (err === true) {
            return false
        }
        const response = await axiosJWT.post('/progress-diary',
            {
                'calories': DefaultAddValuec,
                'ddate': startDate


            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            GetProgressDairyData(startDate);
            handleClosesAddc()

        }



    }
    const trigger_popup = async () => {
        SetDefaultAddValue('')
        SetTodayWeightErr(false)
        var date = ''
        const dateTimeInParts = startDate.toISOString().split("T");
        date = dateTimeInParts[0];

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response)

            if (response.data.final_data[0][0] && response.data.final_data[0][0].weight !== 'undefined' && response.data.final_data[0][0].weight !== null && response.data.final_data[0][0].weight !== '') {
                SetDefaultAddValue(response.data.final_data[0][0].weight)
            }

        })
        handleShowsAdd()

    }

    const Redirect_view_activity = () => {


        navigate("/client/progress-diary", { replace: true });
    }

    const GetClientDashboard = async () => {
        const response = await axiosJWT.get('/dashboard?date=all', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            SetTotalReport(response.data.report)
            SetTotalMealPlan(response.data.meal_plan)
            SetTotalAppointment(response.data.appointment)
            SetTotalQuestionnaire(response.data.questionnaire)
        })
    }

    const NavigateQuestionnare = () => {
        navigate("/client/questionnaire", { replace: true });
    }


    const GetMyUpcomingAppointments = async () => {



        const response = await axiosJWT.get('/appointment/client-my-appointments', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data !== null) {
            if (response.data.GetResponse !== null) {
                // console.log('check', response.data.GetResponse)
                SetClientUpcomingAppointmentList(response.data.GetResponse);
            }
        }
    }

    function timetoUtcClientAppointment(passdate) {


        var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
        var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

        var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')


        return stardates



    }

    useEffect(() => {
        document.title = 'Client-Dashboard';
        GetProgressDairyData(startDate);
        GetClientDashboard();
        load_profile();
        GetMyUpcomingAppointments();
        CheckWithingsDevicesDelivery()
        GetMySubscribedCoaches()
    }, []);

    const QB_app_connect = () => {
        var APPLICATION_ID = process.env.REACT_APP_QB_APPLICATION_ID;
        var AUTH_KEY = process.env.REACT_APP_QB_AUTH_KEY;
        var AUTH_SECRET = process.env.REACT_APP_QB_AUTH_SECRET;
        var ACCOUNT_KEY = process.env.REACT_APP_QB_ACCOUNT_KEY;
        var CONFIG = { debug: true };

        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
    }

    const loadmessages = async () => {
        if (QB) 
        {              
                    var session = JSON.parse(localStorage.getItem('QB_SESSION_RESPONSE'));
                    var userId = session.user_id;
                    var password = session.token;
                    var params = { userId, password };

                    if(userId && password)
                    {
                        setloggedin_userQBId(session.user_id);

                        QB.chat.connect(params, function (c_error, c_contactList) {
                            if (c_error) {
                                // console.log("mi: error connect");
                                console.log(c_error)
                                //create_session();
                            }
                            else {

                                // console.log("mi: success connect");

                                QB.init(localStorage.getItem('QB_USER_TOKEN'));

                                var listparams = {
                                    order: {
                                        field: 'created_at',
                                        sort: 'asc'
                                    },
                                    page: 1,
                                    per_page: 500
                                };

                                QB.users.listUsers(listparams, function (lu_error, lu_result) {
                                    if (lu_error) {
                                        // console.log("mi: error list user");
                                        console.log(lu_error);
                                    }
                                    if (lu_result) {
                                        // console.log("mi: exist session with list users");

                                        setusersList(lu_result.items);

                                        // console.log("mi: chat connect success");
                                        loaddialogList();
                                    }
                                });
                            }
                        });    
                    }
        }
    }

    const create_session = () => {

        // console.log("mi: create session: ");

       // QB_app_connect();

        // console.log("mi: create session: loggedin userid"+loggedin_userid);
        
        // if (loggedin_userid) {
            // console.log("mi: create session: entered");
            axiosJWT.get('/user',
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    if(response.data.id)
                    {
                        // console.log("mi: create session: user data success");

                        //connect with quickblox
                        // var created_timestamp = (Math.floor(new Date(response.data.createdAt).getTime() / 1000)).toString();
                        // var qb_user_login = "KD" + response.data.id + "_" + created_timestamp.substring(5, 10);
                        // var qb_user_pwd = "Keto" + response.data.id + created_timestamp.substring(1, 6);

                        var qb_user_login = response.data.qb_login;
                        var qb_user_pwd = response.data.qb_password;
                        
                        QB.createSession({ login: qb_user_login, password: qb_user_pwd }, function (cs_error, cs_result) {
                            if(cs_error)
                            {
                                // console.log("mi: create session: error");
                                console.log(cs_error);
                            }
                            if (cs_result) {

                                // console.log("mi: create session: success");

                                localStorage.setItem('QB_SESSION_RESPONSE', JSON.stringify(cs_result));
                                localStorage.setItem('QB_USER_TOKEN', cs_result.token);

                                var list_params = {
                                    order: {
                                        field: 'created_at',
                                        sort: 'asc'
                                    },
                                    page: 1,
                                    per_page: 500
                                };

                                QB.users.listUsers(list_params, function (lu_error, lu_result) {
                                    if(lu_error)
                                    {
                                        // console.log("mi: create session: list user error");
                                        console.log(lu_error);
                                    }
                                    if(lu_result)
                                    {
                                        // console.log("mi: create session: list user success");

                                        // console.log(result.items);
                                        setusersList(lu_result.items);

                                        var session = QB.service.getSession();
                                        var userId = session.user_id;
                                        var password = session.token;
                                        var user_params = { userId, password };

                                        setloggedin_userQBId(session.user_id);

                                        QB.chat.connect(user_params, function (c_error, c_contactList) {
                                            if(c_error)
                                            {
                                                // console.log("mi: create session: chat connect error");
                                                console.log(c_error);
                                            }
                                            else
                                            {
                                                // console.log("mi: create session: chat connect success");
                                                loaddialogList();
                                            }
                                        });
                                    }                                
                                });                            
                            }
                        });
                    }
                });
        //}
    }

    const loaddialogList = async () => {

        if (QB) {
            let params = {
                sort_desc: 'last_message_date_sent',
                limit: 5
            };

            QB.chat.dialog.list(params, function (error, dialogs) {
                if(error)
                {
                    // console.log("mi: load dialog: chat dialog error")
                    console.log(error);
                }
                else
                {
                    // console.log("mi: load dialog: chat dialog success")
                    // console.log(dialogs)
                    setdialogList(dialogs.items);
                }
            });
        }
    }

    function getUserName(senderid) {
        var users = JSON.parse(JSON.stringify(usersList))

        var user = users.find((element) => {
            
            return element.user.id === senderid
        });
        if (user) {
            return user.user.full_name;
        }
    }

    //Add Data Function

    const [showAddData,SetshowAddData]=useState(false);
    const [showAddc,SetshowAddDatac]=useState(false);
    const handleClosesAddc=()=>{
        SetshowAddDatac(false)
    }
    const [DefaultAddValuec,SetDefaultAddValuec]=useState('')
    const [TodayCaloriesErr,SetTodayCaloriesErr]=useState(false)
    const [TodayCaloriesErrMsg,SetTodayCaloriesErrMsg]=useState('')
   
    const TriggerAddDataPop=()=>{
        SetshowAddData(true);
    }

    const TriggerAddCaloriesPopup=async()=>{
        SetDefaultAddValuec('')
        SetTodayCaloriesErr(false)
        var date = ''
        const dateTimeInParts = startDate.toISOString().split("T");
        date = dateTimeInParts[0];

        const response = await axiosJWT.get('/progress-diary?ddate=' + date + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response)

            if (response.data.final_data[0][0] && response.data.final_data[0][0].calories !== 'undefined' && response.data.final_data[0][0].calories !== null && response.data.final_data[0][0].calories !== '') {
                SetDefaultAddValuec(response.data.final_data[0][0].calories)
            }

        })
        SetshowAddDatac(true)
    }

    const redirect_connecteddevices = () => {
        navigate("/client/connected-devices", { replace: true });
    }

  
    //withings delivery status

    const [showwithings,Setshowwithings]=useState(false)
    const [WithingsUserData,SetWithingsUserData]=useState([])


const CheckWithingsDevicesDelivery=async()=>{

        const UserDetails = await axiosJWT.get("/profile-details", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log('UserDetails', UserDetails);
        if(
            UserDetails.data.profile_details[0].withings_device_deliver_init===null || 
            UserDetails.data.profile_details[0].withings_device_deliver_init==='' || 
            UserDetails.data.profile_details[0].withings_device_deliver_init===false
        )
        {

            if(
                UserDetails.data.profile_details[0].client_infos[0].subscription_plan!==null && 
                UserDetails.data.profile_details[0].client_infos[0].subscription_plan!=='' &&
                UserDetails.data.profile_details[0].client_infos[0].withings_enabled === true
            )
            {
                Setshowwithings(true)
                SetWithingsUserData(UserDetails.data)
            }
      
           
        }
        
  

    }

    // AI goal 

    const [GoalSource,SetGoalSource]=useState('AI')
    const [Expertoptions,setExpertoptions]=useState([])
    const[CoachId,SetCoachId]=useState('')

    const ChangeGoalSource=(value)=>{

        //inn
        const Elements=document.querySelectorAll('.drop_nutri_change option[value=today]')

        Elements.forEach(element => {
            element.selected=true
        });




        SetCoachId(value)
        SetGoalSource(value)

        GetProgressDairyData(startDate,value)
   
    }

    const GetMySubscribedCoaches=async()=>{

        var ExpertObj=[]
        try {
            const AllNutrition = await axiosJWT.get("/mysubscribedcoaches", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            if (AllNutrition.status === 200) {
              if (AllNutrition.data.Users !== null) {
                AllNutrition.data.Users.forEach((data, index) => {
                  var obj = {};
                  obj["value"] = data.id;
                  obj["label"] = data.first_name+' '+data.last_name;
                  ExpertObj.push(obj);
                });
              }
            }
            setExpertoptions(ExpertObj);
          } catch (err) {
            console.log(err);
          }
    }

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    return (

        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
    {/* <button onClick={SyncWithGoogle}>Sync with google</button> */}
    {loader === true && <div id="semiTransparenDivLoader"></div>}
            <section className="dashboard">
                <div className="clientdashboard container">
                    <div className="row">
                        <div className="col">
                            <span className="top_row_heading_client_dashboard">
                                Dashboard
                            </span>
                        </div>
                        <div className="col top_button_col">
                            {/* <button type="button" className="btn btn-success view_activity_button" onClick={Redirect_view_activity}>View activity</button> */}
                            <button type="button" className="btn btn-success view_activity_button client_dash_top_butt" onClick={redirect_connecteddevices}>Connected devices</button>
                            <button type="button" className="btn btn-success view_activity_button connected_devices_button client_dash_top_butt" onClick={TriggerAddDataPop}>Add data</button>
                            <button type="button" className="btn btn-success todays_activity_button client_dash_top_butt" onClick={trigger_popup}>Todays weight</button>
                            {/* <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart client_top_select_date' style={{float:'right',fontWeight:'600'}} >
                                <option  value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                </select> */}
                        </div>
                    </div>
                    <div className="row client_dashboard_bar_row">
                        <div className="colRpos2 client_dashboard_bar">
                            <div className="pos2inner client_dashboard_bar_inner" >
                                <h3>Welcome, {first_name}{' '}{last_name !== 'null' && last_name !== null && last_name} </h3>

                            </div>
                        </div>
                        <div className="col calories_info_client_dashboard">
                            <div className="row calories_info_client_dashboard_frow">
                                <span className="calories_remaining">Calories remaining</span>
                                <a className="change_a_tag" href='/client/food-diary'>Change</a>
                                <span className="calories_remaining_number">{NetCalories}</span>
                            </div>
                            <div className="row calories_info_client_dashboard_srow">
                                <span className="label rosy_goal">Daily goal <br /><span className="black">{TopDailyGoalCalories}</span></span>
                                <span className="food label">Food <br /><span className="sky_blue">{AchivedByFoodCalories}</span></span>
                                <span className="equal">-</span>
                                <span className="label">Exercise<span className="add_exercise" onClick={TriggerAddCaloriesPopup} style={{display:'none'}}>(Add)</span> <br /><span className="sky_blue">{CaloriesReducedByExercise}</span></span>
                                <span className="equal">=</span>
                                <span className="label">Net <br /><span className="green">{NetCalories}</span></span>
                            </div>
                            <div id="progressbar">
                                <div style={{ width: ProgressDiaryPercentage }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="row report_info_row">
                        <div className="colRpos3">
                            <div className="pos3inner">
                                <div className="pos3innertop"> <h6>Report </h6><span className="pos3innericon"><i style={{ backgroundColor: "#009FE1" }} className="fa fa-file-o" aria-hidden="true"></i></span> </div>
                                <h3 >{TotalReport}</h3>
                            </div>
                            <div className="pos3inner">
                                <div className="pos3innertop"> <h6 >Meal Plans </h6><span className="pos3innericon"><i className="fa fa-file-o" aria-hidden="true"></i></span> </div>
                                <h3 >{TotalMealPlan}</h3>
                            </div>
                            <div className="pos3inner">
                                <div className="pos3innertop"> <h6 >Total appointment </h6><span className="pos3innericon"><i className="fa fa-calendar-o" aria-hidden="true"></i></span> </div>
                                <h3 >{TotalAppointment}</h3>
                            </div>
                            <div className="pos3inner">
                                <div className="pos3innertop"> <h6 >Questionnaire </h6><span className="pos3innericon"><i className="fa fa-sticky-note-o NavigateQuestionnare" aria-hidden="true" style={{ backgroundColor: "#009FE1" }} onClick={NavigateQuestionnare}></i></span> </div>
                                <span className="client_question_span_client"> <h3>{TotalQuestionnaire}</h3><a href='/client/questionnaire' style={{ color: 'rgb(0, 159, 225)', textDecoration: 'none' }}>{'(View all)'}</a></span>

                            </div>
                        </div>
                    </div>
                    <div className="row circle_info_progress_info">
                       
                        <div style={{paddingLeft:'0px',marginBottom:'10px',display:'flex',gap:'10px'}}>
                        <div style={{ fontWeight: '500' }}>Target goal :</div>
                        <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' onChange={(e) => { ChangeGoalSource(e.target.value) }} style={{ fontWeight: '600' }} >

                        
                                <option value="AI">AI generated</option>
                                {Expertoptions.length > 0 && Expertoptions.map((Nutritionist, index) => (
                                            <option value={Nutritionist.value} key={index}>{'Coach: '}{Nutritionist.label}</option>
                                        ))}

                            </select>
                        </div>
                        <div className="col circle_info_progress_info_col">
                            <span className="label_top">Calories</span>
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart drop_nutri_change' onChange={(e) => { ChangeCaloriesDate(e.target.value) }} style={{ float: 'right', fontWeight: '600' }} >
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>

                            </select>

                            <div className="client_circle_progress calories_progress">
                                <CircularProgressbarWithChildren value={AchievedGoalCaloriesPercentage}>
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                        <strong style={{ paddingLeft: 27 }}>{AchievedGoalCaloriesPercentage}%</strong> <br /> Completed
                                    </div>
                                </CircularProgressbarWithChildren>
                                <div className="row progress_low_info">
                                    <span className="target">Target Goal: {TargetGoalCalories} Kcal</span>
                                    <span className="yellow">Achieved Goal: {AchievedGoalCalories} Kcal</span>
                                </div>
                            </div>

                        </div>
                        <div className="col circle_info_progress_info_col">
                            <span className="label_top"> Fats</span>
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart drop_nutri_change' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { ChangedFatDate(e.target.value) }} >
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                            </select>
                            <div className="client_circle_progress fat_progress">
                                <CircularProgressbarWithChildren value={AchievedGoalFatsPercentage}>
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                        <strong style={{ paddingLeft: 27 }}>{AchievedGoalFatsPercentage}%</strong> <br /> Completed
                                    </div>
                                </CircularProgressbarWithChildren>
                                <div className="row progress_low_info">
                                    <span className="target">Target Goal: {TargetGoalFats} g</span>
                                    <span className="light_blue">Achieved Goal: {AchievedGoalFats} g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col circle_info_progress_info_col">
                            <span className="label_top"> Carbs</span>
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart drop_nutri_change' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { ChangedCarbDate(e.target.value) }} >
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                            </select>
                            <div className="client_circle_progress carb_progress">
                                <CircularProgressbarWithChildren value={AchievedGoalCarbsPercentage}>
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                        <strong style={{ paddingLeft: 27 }}>{AchievedGoalCarbsPercentage}%</strong> <br /> Completed
                                    </div>
                                </CircularProgressbarWithChildren>
                                <div className="row progress_low_info">
                                    <span className="target">Target Goal: {TargetGoalCarbs} g</span>
                                    <span className="green">Achieved Goal: {AchievedGoalCarbs} g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col circle_info_progress_info_col">
                            <span className="label_top">Protein</span>
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart drop_nutri_change' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { ChangedProteinDate(e.target.value) }} >
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                            </select>
                            <div className="client_circle_progress protein_progress">
                                <CircularProgressbarWithChildren value={AchievedGoalProteinsPercentage}>
                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                        <strong style={{ paddingLeft: 27 }}>{AchievedGoalProteinsPercentage}%</strong> <br /> Completed
                                    </div>
                                </CircularProgressbarWithChildren>
                                <div className="row progress_low_info">
                                    <span className="target">Target Goal: {TargteGoalProteins} g</span>
                                    <span className="dark_rose">Achieved Goal: {AchievedGoalProteins} g</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row client_dash_last_row">
                        <div className="client_next_appointment_dashboard"><h5>Next Appointment</h5>
                            <Table className='table is-striped is-fullwidth next_appointment_table_coach'>
                                <Thead className='thead '>
                                    <Tr>
                                        <Th className='row_name'>Name</Th>
                                        <Th className='row_name'>Purpose</Th>
                                        <Th className='row_name'>Date {'&'} Time</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {ClientUpcomingAppointmentList.length > 0 && ClientUpcomingAppointmentList.map((upappointment, index) => (
                                        <Tr className="trow" key={index}>
                                            <Td>                                             
                                                <div className='photo_block client_dash_profile'>
                                                    <img className='table_profile_img' src={upappointment.user.hasOwnProperty("user_profile_photo") && upappointment.user.user_profile_photo !== '' && upappointment.user.user_profile_photo !== null ?  upappointment.user.user_profile_photo : (upappointment.user.gender === "MALE" ? male_icon : female_icon)} alt="img" />
                                                    {
                                                        (upappointment.user.logged_in_status === '1')
                                                            ? <span className='c-avatar__status online_client_dash'></span>
                                                            : null
                                                    }
                                                    </div>  
                                               
                                                 <div className='name_block'>
                                                    <span className='name_val'>{upappointment.user.first_name + ' ' + upappointment.user.last_name}</span>
                                                    </div>                                                    
                                            </Td>
                                            <Td className="appointment_available_td">
                                                {upappointment.meeting_type === 'Initial Interview' &&
                                                    <span className="design_yellow">{upappointment.meeting_type}</span>}

                                                {upappointment.meeting_type === 'Follow Up' &&
                                                    <span className="design_skyblue">{upappointment.meeting_type}</span>}
                                            </Td>
                                            <Td>   <i className="fa fa-calendar client_dash_upcoming_cal" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(upappointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table></div>
                        <div className="client_message_dashboard">
                            <div className="client_message_dashboard_inner">
                                <span className="titlebox"><h5>Messages</h5></span>
                                <span className="viewall"> <a href='/client/messages'>See All</a></span>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="row">
                                {/* {dialogList ?
                                    dialogList.map((dialog, index) => (
                                        <div className="client_msg_div" key={index}>
                                            
                                            <span className={"dialog__avatar m-user__img_" + ((index % 10) + 1) + " m-type_chat"}>
                                                        <i className="icons">{parseInt(dialog.occupants_ids[0]) === loggedin_userQBId ? (getUserName(parseInt(dialog.occupants_ids[1])).toUpperCase()).substring(0, 1) : (getUserName(parseInt(dialog.occupants_ids[0])).toUpperCase()).substring(0, 1)}</i>
                                                    </span>

                                            <span className="inner_msg_div">
                                                <span className="name">{parseInt(dialog.occupants_ids[0]) === loggedin_userQBId ? getUserName(parseInt(dialog.occupants_ids[1])) : getUserName(parseInt(dialog.occupants_ids[0]))}</span>
                                                <span className="fromhours">{dialog.last_message_date_sent ? moment.unix(dialog.last_message_date_sent).clone().tz(client_timezone).fromNow() : null}</span>
                                            </span>
                                            <span className="msg" >{dialog.last_message}</span>
                                        </div>
                                    )) : null
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showAdd} onHide={handleClosesAdd} className="clientdashboard_weight_update confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{"Today's Weight"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div className="row">
                            <div className="col">
                                <label className='commission_inner_popup_label'>{"Today's Weight"}{profileData.client_infos !== undefined && profileData.client_infos[0] !== undefined && (profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? "(lbs)" : "(kg)"}</label><br />
                                <input type='number' defaultValue={DefaultAddValue} onChange={(e) => { SetDefaultAddValue(e.target.value) }} className="add_category_inner_input total_session"  {...(TodayWeightErr===true ? {id: 'err_border'} : {})} />
                                {TodayWeightErr === true &&
                                    <span className="err service_err_field">  {TodayWeightErrMsg}</span>
                                }
                            </div>
                        </div>



                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesAdd}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="modal_action_button float_right" onClick={(e) => { FinalAddButton(e) }}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAddc} onHide={handleClosesAddc} className="clientdashboard_weight_update confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{"Today's Burnt Calories"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div className="row">
                            <div className="col">
                                <label className='commission_inner_popup_label'>{"Today's Burnt Calories(Kcal)"}</label><br />
                                <input type='number' defaultValue={DefaultAddValuec} onChange={(e) => { SetDefaultAddValuec(e.target.value) }} className="add_category_inner_input total_session"  {...(TodayCaloriesErr===true ? {id: 'err_border'} : {})} />
                                {TodayCaloriesErr === true &&
                                    <span className="err service_err_field">  {TodayCaloriesErrMsg}</span>
                                }
                            </div>
                        </div>



                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button className="modal_close_button" variant="secondary" onClick={handleClosesAddc}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="modal_action_button float_right" onClick={(e) => { FinalAddButtonc(e) }}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>



      <AddData open={showAddData} SetshowAddData={SetshowAddData} />

      <QuizSuccesForm ShowSuccessQuizForm={ShowSuccessQuizForm} SetShowSuccessQuizForm={SetShowSuccessQuizForm} />

      {
        showwithings===true && 
        <WithingsCheckoutForm show={showwithings} userdata={WithingsUserData} ShowLoader={ShowLoader} HideLoader={HideLoader}/>
      }


     

            </section>
        </div>
    )
}

export default ClientDashboard
import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { format } from 'date-fns'

const CoachSubscriptions = () => {
    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [CoachPlanList, SetCoachPlanList] = useState([]);
    const [shows, Setshows] = useState(false);

    const handleCloses = () => {
        Setshows(false);
    }

    const [SelectedPlanName, SetSelectedPlanName] = useState('');
    const [SelectedPlanAmount, SetSelectedPlanAmount] = useState('');
    const [SelectedPlanId, SetSelectedPlanId] = useState('');
    const [ShowPaypalGateway, SetShowPaypalGateway] = useState(false);
    const [PayPalGatewaySrc, SetPayPalGatewaySrc] = useState('');
    const [ChosenGateway, SetChosenGateway] = useState('');
    const [ShowStripeGateway, SetShowStripeGateway] = useState(false);
    const [StripeGatewaySrc, SetStripeGatewaySrc] = useState('');
    const [StripeGatewayDesc, SetStripeGatewayDesc] = useState('');
    const [StripeGatewayName, SetStripeGatewayName] = useState('');
    const [AllowedClients, SetAllowedClients] = useState(false);
    const [paymentErr, SetpaymentErr] = useState(false);
    const [paymenterrmsg, Setpaymenterrmsg] = useState('');
    const [loader, ShowLoader] = useState(false);
    const [CurrentPlanId, SetCurrentPlanId] = useState();
    const [CurrentPlanExpiry, SetCurrentPlanExpiry] = useState();
    const [CurrentPlanStatus, SetCurrentPlanStatus] = useState();
    const [CurrentTime, SetCurrentTime] = useState();
    const [CurrentPlanDetails, SetCurrentPlanDetails] = useState()
    const user_timezone = localStorage.getItem("user_timezone");
    const [ShowAffirmGateway, SetShowAffirmGateway] = useState(false);
    const [AffirmGatewaySrc, SetAffirmGatewaySrc] = useState('');
    const [AffirmGatewayDesc, SetAffirmGatewayDesc] = useState('');
    const [AffirmGatewayName, SetAffirmGatewayName] = useState('');
    const [AffirmError, setAffirmError] = useState(false);
    const [AffirmErrorMsg, setAffirmErrorMsg] = useState('');
    const [responseGateway, setresponseGateway] = useState('');
    const [LastPaymentGateway, setLastPaymentGateway] = useState('');
    const [ShowSplititGateway, SetShowSplititGateway] = useState(false);
    const [SplititGatewaySrc, SetSplititGatewaySrc] = useState('');
    const [SplititGatewayDesc, SetSplititGatewayDesc] = useState('');
    const [SplititGatewayName, SetSplititGatewayName] = useState('');
    const [SplititError, setSplititError] = useState(false);
    const [SplititErrorMsg, setSplititErrorMsg] = useState('');

    const [showcancelsub, Setshowcancelsub] = useState(false);
    const Closecancelsub = () => {
        Setshowcancelsub(false);
    }

    const [showsuccesssub, Setshowsuccesssub] = useState(false);
    const Closesuccesssub = () => {
        Setshowsuccesssub(false);
    }

    const ProceedToCheckout = async () => {

        SetpaymentErr(false);

        if (ChosenGateway === '') {
            SetpaymentErr(true);
            Setpaymenterrmsg('Please select any one payment method');
        }
        else {
            ShowLoader(true);

            try {
                const response = await axiosJWT.post('/payments/coach-plan-checkout',
                    {
                        payment_gateway: ChosenGateway,
                        product_category: 'coach_subscription',
                        product_sku: SelectedPlanId
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                if (response.data !== null) {

                    setAffirmError(false);
                    setSplititError(false);

                    if (ChosenGateway === 'affirm' || ChosenGateway === 'splitit') {
                        ShowLoader(false);
                        window.location.href = response.data.checkout_url;
                    }
                    else {
                        setTimeout(() => {
                            handleCloses();
                            ShowLoader(false);
                            Setshowsuccesssub(true);
                            GetProfileDetails();
                            GetAvailablePlans();
                        }, 1000);
                    }

                    // window.location.href = response.data.checkout_url;
                }
            } catch (err) {
                ShowLoader(false);

                if (ChosenGateway === 'affirm') {
                    setAffirmError(true);
                    if (err.response.data.msg === 'Fields Required')
                        setAffirmErrorMsg("*Please fill all the required fields in your profile account for using affirm payment.!");
                    else
                        setAffirmErrorMsg("Something went wrong.! Contact the site administrator.!");
                }
                else if (ChosenGateway === 'splitit') {
                    setSplititError(true);
                    setSplititErrorMsg("Something went wrong.! Contact the site administrator.!");
                }
                else {
                    if (err.response.data.payment_link) {
                        window.location.href = err.response.data.payment_link;
                    }
                    else if (err.response.data.msg) {
                        window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?payment=failed'
                    }
                    else if (err.response.data.planerr) {
                        window.location.href = '' + process.env.REACT_APP_FRONTEND_URL + '/coach/subscription?plan_err=failed'
                    }
                }
            }
        }
    }

    const GetAvailablePlans = async () => {
        const PlanList = await axiosJWT.get('/service-plan?type=active', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (PlanList.data !== null) {
            if(PlanList.data.AllCoachPlans)
            SetCoachPlanList(PlanList.data.AllCoachPlans);

            if(PlanList.data.CurrentPlan)
            SetCurrentPlanDetails(PlanList.data.CurrentPlan);

            if(PlanList.data.Paymentinfo)
            setLastPaymentGateway(PlanList.data.Paymentinfo.payment_method);
        }
    }

    const CheckoutPlan = async (e) => {
        SetChosenGateway('');
        SetpaymentErr(false);
        SetShowPaypalGateway(false);
        SetShowStripeGateway(false);
        SetShowAffirmGateway(false);
        setAffirmError(false);
        SetShowSplititGateway(false);
        setSplititError(false);

        const response = await axiosJWT.get('payments/paymentmethods?currency=USD', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data !== null) {

            if (response.data.length > 0) {

                var PayPalArray = response.data.filter(function (el) {
                    return el.gateway === 'Paypal';
                });
                if (PayPalArray.length > 0) {
                    if (PayPalArray[0].isEnable === true) {
                        SetShowPaypalGateway(true);
                        SetPayPalGatewaySrc(PayPalArray[0].logopath);
                    }
                }

                var StripeArray = response.data.filter(function (el) {
                    return el.gateway === 'Stripe';
                });
                if (StripeArray.length > 0) {
                    if (StripeArray[0].isEnable === true) {
                        SetShowStripeGateway(true);
                        SetStripeGatewaySrc(StripeArray[0].logopath);
                        SetStripeGatewayDesc(StripeArray[0].description);
                        SetStripeGatewayName(StripeArray[0].title);
                    }
                }

                var AffirmArray = response.data.filter(function (el) {
                    return el.gateway === 'Affirm';
                });
                if (AffirmArray.length > 0) {
                    if (AffirmArray[0].isEnable === true) {
                        var affirm_enable = true;
                        SetAffirmGatewaySrc(AffirmArray[0].logopath);
                        SetAffirmGatewayDesc(AffirmArray[0].description);
                        SetAffirmGatewayName(AffirmArray[0].title);
                    }
                }

                var SplititArray = response.data.filter(function (el) {
                    return el.gateway === 'Splitit';
                });
                if (SplititArray.length > 0) {
                    if (SplititArray[0].isEnable === true) {
                        var splitit_enable = true;
                        SetSplititGatewaySrc(SplititArray[0].logopath);
                        SetSplititGatewayDesc(SplititArray[0].description);
                        SetSplititGatewayName(SplititArray[0].title);
                    }
                }
            }
        }

        const PlanId = e.target.getAttribute('data-id');
        SetSelectedPlanId(PlanId);

        const PlanDetail = await axiosJWT.get('/service-plan?id=' + PlanId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (PlanDetail.data != null) {
            const PlanDetailed = PlanDetail.data.data;
            SetSelectedPlanName(PlanDetailed[0].service_plan_name);
            SetSelectedPlanAmount(PlanDetailed[0].service_plan_amount);

            if (affirm_enable === true && parseInt(PlanDetailed[0].service_plan_duration) >= 6)
            SetShowAffirmGateway(true);

            if (splitit_enable === true && parseInt(PlanDetailed[0].service_plan_duration) >= 6)
            SetShowSplititGateway(true);
        }

        Setshows(true);
    }

    const [showsuccess, Setshowsuccess] = useState(false);

    const CloseSuccessPopup = () => {
        Setshowsuccess(false);
    }

    const [showsuccesspayment, Setshowsuccesspayment] = useState(false);

    const CloseSuccesspaymentPopup = () => {
        Setshowsuccesspayment(false);
    }

    const [showfail, Setshowfail] = useState(false);

    const CloseFailPopup = () => {
        Setshowfail(false);
    }

    const [showfailm, Setshowfailm] = useState(false);

    const CloseFailMPopup = () => {
        Setshowfailm(false);
    }

    const [showconfigurepayment, Setshowconfigurepayment] = useState(false);

    const CloseConfigurepaymentPopup = () => {
        Setshowconfigurepayment(false);
    }

    useEffect(() => {
        document.title = 'Coach-Subscription';
        GetProfileDetails();
        GetAvailablePlans(1);
        SetCurrentTime(moment.utc().format('X'));

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let paymentSuccess = params.get('payment');
        let responsegateway = params.get('gateway');
        let payment_method = params.get('payment_method');
        let plan_err = params.get('plan_err');

        if (plan_err === 'failed') {
            SetErrhandleTitle('Plan subscription failed');
            SetErrhandleMsg('You cannot subscribe to this plan right now!');
            SetErrhandle(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        if (payment_method === 'failed') {
            Setshowfailm(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }

        let paymentconfigured = params.get('configured');

        if (paymentSuccess === 'success') {
            Setshowsuccess(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (paymentSuccess === 'failed') {
            setresponseGateway(responsegateway);
            Setshowfail(true);
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (paymentconfigured === 'success') {
            Setshowsuccesspayment(true)
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }, []);


    const GetProfileDetails = async () => {

        const response = await axiosJWT.get('/profile-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data !== null) {
            SetCurrentPlanId(response.data.profile_details[0].nutritionist_infos[0].user_plan_id);
            SetCurrentPlanStatus(response.data.profile_details[0].nutritionist_infos[0].user_plan_status);

            var PlanExpiry = response.data.profile_details[0].nutritionist_infos[0].user_plan_expiry_date;

            SetCurrentPlanExpiry(PlanExpiry);

            if (response.data.profile_details[0].nutritionist_infos[0].stripe_subscription_id !== null) {
                if (response.data.profile_details[0].nutritionist_infos[0].stripe_payment_id === null) {
                    Setshowconfigurepayment(true);
                }
            }

            if (response.data.profile_details[0].nutritionist_infos[0].allowed_clients !== '') {
                SetAllowedClients(response.data.profile_details[0].nutritionist_infos[0].allowed_clients);
            }
        }
    }

    const CheckPlanStatus = (id) => {

        if (CurrentPlanId !== id) {
            return (
                <div>
                    <button className="btn btn-success keto_link_button" data-id={id} onClick={(e) => { CheckoutPlan(e) }}>Buy Now</button>
                </div>
            )
        }
        else if (CurrentPlanId === id) {
            if (CurrentPlanStatus === true) {
                if (LastPaymentGateway === 'stripe') {
                    return (
                        <div>
                            <p className="plan_expires_coach active">Next billing cycle : {moment.utc(CurrentPlanExpiry).clone().tz(user_timezone).format('MMM Do, YYYY')}</p>
                            <button className="btn btn-success keto_link_button cancel_subscription_but" data-id={id} onClick={(e) => { CancelSubscriptionTrigger(e) }}>Cancel Subscription</button>
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            <p className="plan_expires_coach active">Expire Date : {moment.utc(CurrentPlanExpiry).clone().tz(user_timezone).format('MMM Do, YYYY')}</p>
                        </div>
                    )
                }
            }
            else {
                const CurrentTime = moment.utc().format('X');
                const Planexpires = moment.utc(CurrentPlanExpiry).format('X');

                return (
                    <div>
                        {
                            Planexpires < CurrentTime &&
                            <div>
                                <span className="plan_expires_coach">Plan expired on : {moment.utc(CurrentPlanExpiry).clone().tz(user_timezone).format('MMM Do, YYYY')}</span>
                            </div>
                        }

                        {
                            Planexpires > CurrentTime &&
                            <div>
                                <span className="plan_expires_coach">Allowed clients expires on : {moment.utc(CurrentPlanExpiry).clone().tz(user_timezone).format('MMM Do, YYYY')}</span>
                            </div>
                        }


                        <button className="btn btn-success keto_link_button" data-id={id} onClick={(e) => { CheckoutPlan(e) }}>Buy Now</button>
                    </div>
                )
            }

            // if(CurrentTime>CurrentPlanExpiry)
            // {
            //      return (

            //         <button className="btn btn-success keto_link_button" data-id={id} onClick={(e) => { CheckoutPlan(e) }}>Buy Now</button>

            //      )
            // }
            // else{

            //     return (
            //         <span className="plan_expiry_coach">


            //         Plans Ends in :<br/> {moment.unix(CurrentPlanExpiry).format("D,MMM YYYY")}
            //     </span>
            //     )

            // }
        }
    }

    const CancelSubscription = async () => {
        ShowLoader(true);
        const response = await axiosJWT.get('/payments/coach-cancel-subscription', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.data !== null) {
            ShowLoader(false);
            CloseCancelConfirm(false);
            Setshowcancelsub(true);
            GetProfileDetails();
            GetAvailablePlans();
        }
    }

    const [CancelConfirm, SetCancelConfirm] = useState(false);
    const CloseCancelConfirm = () => {
        SetCancelConfirm(false);
    }

    const CancelSubscriptionTrigger = () => {
        SetCancelConfirm(true);
    }

    const Configurepayment = async () => {

        const response = await axiosJWT.get('/payments/add-payment-method?page=subscription', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        window.location.href = response.data.payment_link;
    }

    const ChangeCardDetails = async () => {

        const response = await axiosJWT.get('/payments/add-payment-method', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        window.location.href = response.data.payment_link;
    }

    const [Errhandle, SetErrhandle] = useState(false);

    const CloseErrhandle = () => {
        SetErrhandle(false);
    }

    const [ErrhandleTitle, SetErrhandleTitle] = useState('');
    const [ErrhandleMsg, SetErrhandleMsg] = useState('');

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>

            {loader === true && <div id="semiTransparenDivLoader"></div>}

            <section className="dashboard">
                <div className="container">
                    <div className="row">
                        <span className="top_row_heading_subscription">Subscription</span>
                    </div>
                    <div className="row allowed_clients_sub_row">
                        <span>Allowed Clients can be added : {AllowedClients}</span>
                    </div>

                    {CoachPlanList.length > 0 &&
                        <div className="row subscription_plan_row">
                            <div>Available Plans:</div>
                            {CoachPlanList.length > 0 && CoachPlanList.map((services, index) => (
                                <div className={services.id === CurrentPlanId ? "plan_col active" : "plan_col"} key={services.id} data-plan_id={services.id}>
                                    <p className="p_tag_label_top_subs">{services.service_plan_name}</p>
                                    <div className="amount_row">
                                        <span className="amount_value">${services.service_plan_amount}</span>
                                    </div>
                                    <div className="plan_info">
                                        <ul className="list-unstyled mb-4">
                                            {
                                                (parseInt(services.service_plan_duration) === 12) &&
                                                <li>Billing: ${services.service_plan_amount} / Yearly</li>
                                            }
                                            {
                                                (parseInt(services.service_plan_duration) === 1) &&
                                                <li>Billing: ${services.service_plan_amount} / Monthly</li>
                                            }
                                            {
                                                (parseInt(services.service_plan_duration) !== 1 && parseInt(services.service_plan_duration) !== 12) &&
                                                <li>Billing: ${services.service_plan_amount} /every {services.service_plan_duration} Months</li>
                                            }
                                            <li>Allowed Clients : {services.service_plan_allowed_users}</li>
                                            <li>Manage Users</li>
                                            <li>Meal Plan Suggestions</li>
                                            <li>Online Consultation</li>
                                        </ul>
                                    </div>

                                    <div className="low_level_button_subscriptions centered_subscription">
                                        {CheckPlanStatus(services.id)}
                                        {/* <button className="btn btn-success keto_link_button" data-id={services.id} onClick={(e) => { CheckoutPlan(e) }}>Buy Now</button> */}
                                    </div>
                                </div>
                            ))}

                        </div>
                    }

                    {
                        (CurrentPlanDetails && CurrentPlanDetails.plan_status === false) && <div className="row subscription_plan_row">
                            <div>Your Current Plan:</div>
                            <div className={CurrentPlanDetails.id === CurrentPlanId ? "plan_col active" : "plan_col"} key={CurrentPlanDetails.id} data-plan_id={CurrentPlanDetails.id} style={{ width: '30%' }}>
                                <p className="p_tag_label_top_subs">{CurrentPlanDetails.service_plan_name}</p>
                                <div className="amount_row">
                                    <span className="amount_value">${CurrentPlanDetails.service_plan_amount}</span>
                                </div>
                                <div className="plan_info">
                                    <ul className="list-unstyled mb-4">
                                        {
                                            (parseInt(CurrentPlanDetails.service_plan_duration) === 12) &&
                                            <li>Billing: ${CurrentPlanDetails.service_plan_amount} / Yearly</li>
                                        }
                                        {
                                            (parseInt(CurrentPlanDetails.service_plan_duration) === 1) &&
                                            <li>Billing: ${CurrentPlanDetails.service_plan_amount} / Monthly</li>
                                        }
                                        {
                                            (parseInt(CurrentPlanDetails.service_plan_duration) !== 1 && parseInt(CurrentPlanDetails.service_plan_duration) !== 12) &&
                                            <li>Billing: ${CurrentPlanDetails.service_plan_amount} /every {CurrentPlanDetails.service_plan_duration} Months</li>
                                        }
                                        <li>Allowed Clients : {CurrentPlanDetails.service_plan_allowed_users}</li>
                                        <li>Manage Users</li>
                                        <li>Meal Plan Suggestions</li>
                                        <li>Online Consultation</li>
                                    </ul>
                                </div>

                                <div className="low_level_button_subscriptions centered_subscription">
                                    {CheckPlanStatus(CurrentPlanDetails.id)}
                                    {/* <button className="btn btn-success keto_link_button" data-id={services.id} onClick={(e) => { CheckoutPlan(e) }}>Buy Now</button> */}
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <Modal show={shows} onHide={handleCloses} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Checkout Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="choose_payement_gateway_div">

                            <div className="plan_details_div_client_sub">
                                <span className="plan_span"><span className="plan_label">Plan Name :</span> <span>{SelectedPlanName}</span></span>
                                <span className="plan_span"><span className="plan_label">Amount to be paid :</span> <span>${SelectedPlanAmount}/per cycle</span></span>
                                <span className="plan_span"><span className="plan_label">Payment method :</span></span>
                            </div>

                            {ShowPaypalGateway === true &&
                                <div className="gateway_row gateway_row1">
                                    <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + PayPalGatewaySrc} alt="paypal" />
                                    <span className="title">Paypal</span>
                                    <input type={'radio'} name="gateway" value={'paypal'} className="gateway_choose_radio" onChange={(e) => { SetChosenGateway(e.target.value) }} />
                                </div>}
                            {ShowStripeGateway === true &&
                                // <div className="gateway_row gateway_row2">
                                //     <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + StripeGatewaySrc} />
                                //     <span className="title">Stripe</span>
                                //     <input type={'radio'} name="gateway" value={'stripe'} className="gateway_choose_radio" onChange={(e) => { SetChosenGateway(e.target.value) }} />
                                // </div>

                                <div className="gateway_row gateway_row2">

                                    <div className="gateway_image_div">
                                        <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + StripeGatewaySrc} alt="stripe" />
                                    </div>

                                    <div className="gateway_title_desc_div">
                                        <span className="title">{StripeGatewayName}</span>
                                        <p className="desc">{' (' + StripeGatewayDesc + ') '}</p>
                                    </div>

                                    <div className="gateway_radio_div">
                                        <input type={'radio'} name="gateway" value={'stripe'} className="gateway_choose_radio" onChange={(e) => { SetChosenGateway(e.target.value) }} />
                                    </div>
                                </div>
                            }
                            {ShowAffirmGateway === true &&
                                <>
                                    <div className="gateway_row gateway_row2">

                                        <div className="gateway_image_div">
                                            <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + AffirmGatewaySrc} alt={AffirmGatewayName} />
                                        </div>

                                        <div className="gateway_title_desc_div">
                                            <span className="title">{AffirmGatewayName}</span>
                                            <p className="desc">{' (' + AffirmGatewayDesc + ') '}</p>
                                        </div>

                                        <div className="gateway_radio_div">
                                            <input type={'radio'} name="gateway" value={'affirm'} className="gateway_choose_radio" onChange={(e) => { SetChosenGateway(e.target.value) }} />
                                        </div>
                                    </div>
                                    {AffirmError === true ?
                                        <div className="err">{AffirmErrorMsg}</div> : null}
                                </>
                            }
                            {ShowSplititGateway === true &&
                                <>
                                    <div className="gateway_row gateway_row2">
                                         <div className="gateway_image_div">
                                            <img src={process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + SplititGatewaySrc} alt={SplititGatewayName} />
                                        </div>

                                        <div className="gateway_title_desc_div">
                                            <span className="title">{SplititGatewayName}</span>
                                            <p className="desc">{' (' + SplititGatewayDesc + ') '}</p>
                                        </div>

                                        <div className="gateway_radio_div">
                                            <input type={'radio'} name="gateway" value={'splitit'} className="gateway_choose_radio" onChange={(e) => { SetChosenGateway(e.target.value) }} />
                                        </div>                                    
                                    </div>
                                    {SplititError === true ?
                                        <div className="err">{SplititErrorMsg}</div> : null}
                                </>
                            }
                        </div>
                        {
                            paymentErr === true && <span className="err">{paymenterrmsg}</span>
                        }
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" className="modal_close_button" onClick={handleCloses}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="modal_action_button" onClick={ProceedToCheckout}>
                            Subscribe
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showsuccess} onHide={CloseSuccessPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Subscription Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You have successfully purchased the plan.!
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={CloseSuccessPopup}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showfail} onHide={CloseFailPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Subscription Failed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {responseGateway === 'stripe' ? "Your plan purchase has been failed.! Please check your configured payment method and make sure the given card details are correct and active." : "Your plan purchase has been failed.!"
                        }
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        {responseGateway === 'stripe' ?
                            <Button variant="primary" className="modal_action_button float_right" onClick={ChangeCardDetails}>
                                Change Card Details
                            </Button>
                            :<Button variant="primary" className="modal_action_button float_right" onClick={CloseFailPopup}>
                            OK
                            </Button>}
                    </Modal.Footer>
                </Modal>

                <Modal show={showfailm} onHide={CloseFailMPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Method Add Failed!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Your payment method configuration has been failed.!
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={CloseFailMPopup}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showsuccesspayment} onHide={CloseSuccesspaymentPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment method configuration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You have successfully configured your payment method. Subscription amount will be deducted from the configured account going forward.
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={CloseSuccesspaymentPopup}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showconfigurepayment} onHide={CloseConfigurepaymentPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment method configuration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Seems like there's no payment method configured for current subscription. Please add payment method for uninterrrupted services.
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={Configurepayment}>
                            Configure
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showsuccesssub} onHide={Closesuccesssub} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Subscription Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You have successfully subscribed this plan.!
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={Closesuccesssub}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showcancelsub} onHide={Closecancelsub} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You have successfully cancelled your current subscription plan.!
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_action_button float_right" onClick={Closecancelsub}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={CancelConfirm} onHide={CloseCancelConfirm} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure want to cancel the current subscription plan?
                    </Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="primary" className="modal_close_button" onClick={CloseCancelConfirm}>
                            Close
                        </Button>
                        <Button variant="primary" className="modal_action_button" onClick={CancelSubscription}>
                            Unsubscribe
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={Errhandle} onHide={CloseErrhandle} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>{ErrhandleTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ErrhandleMsg}
                    </Modal.Body>
                    <Modal.Footer className="display_block">

                        <Button variant="primary" className="modal_action_button" onClick={CloseErrhandle}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </section>
        </div>
    )
}

export default CoachSubscriptions
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "react-js-pagination";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import VisitDetails from "../../ClientComponents/BelugaHealth/VisitDetails";

const ClientBelugaHealth = (props) => {
  const CloseDetailView = () => {
    SetDetailId("");
  };

  const [ClientId,SetClientId]=useState('')
  const [personaId, SetPersonaId] = useState('');

  function timetoUtcClientAppointment(passdate) {
    var pushstartime = moment(passdate.trim()).format("YYYY-MM-DD HH:mm:ss");

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, "YYYY-MM-DD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format("YYYY-MM-DD HH:mm:ss");

    return stardates;
  }

  const ViewDetailsOftheVisit = (e) => {
    var Id = e.target.getAttribute("data-id");
    SetDetailId(Id);
   
  };

  const changePageNumber = (pagenumber) => {
    GetClientVisitsLog(pagenumber,ClientId);
  };


  const GetClientVisitsLog = async (pagenumber,clientid) => {
    SetCurrentPage(pagenumber);

    SetClientId(clientid)

    try{

        const GetResponse = await axiosJWT.get(
            "beluga/get-client-visits-log?offset=" +
              pagenumber +
              "&limit=" +
              PerPage +
              "&s=" +
              SearchValue +
              "&client_id=" +
              clientid +
              "",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log('GetResponse', GetResponse);

          const personaData = await axiosJWT.get('beluga/get-personaid?clientid=' + clientid + '', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          // console.log('personaData', personaData);
          if(personaData.status === 200) {
            SetPersonaId(personaData.data.personaId.persona_inquiryId);
          }

          if (GetResponse.data !== null) {
            SetTotalRecord(GetResponse.data.total_count);
            SetVisitLog(GetResponse.data.GetResponse);
          }

    }catch(err)
    {
        console.log(err)
    }

   
  };

  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");

  const [VisitLog, SetVisitLog] = useState([]);
  const [TotalRecord, SetTotalRecord] = useState(0);
  const [CurrentPage, SetCurrentPage] = useState();
  const [PerPage, SetPerPage] = useState(10);
  const [DetailId, SetDetailId] = useState("");
  const [SearchValue,SetSearchValue]=useState('')


  useEffect(()=>{
    GetClientVisitsLog(1,props.data)
  },[props])

  return (
    <>
      <section className="dashboard">
        <div className="client_appointment container">
          <div className="row client_top_appointment_row beluga_visit_Top_heading_row">
            <span className="client_top_appointment_row_span">
              Beluga Health - Visits Log
            </span>
            {DetailId !== "" ? (
              <button className="view_button" onClick={CloseDetailView}>
                Back
              </button>
            ) : null}
          </div>

          {DetailId === "" ? (
            <div
              className="row beluga_visit_container beluga_visit_container_table"
              style={{ display: "block" }}
            >
                <div className="even-columns">
                <div className="input"><input type="text" defaultValue={SearchValue} onChange={(e)=>{SetSearchValue(e.target.value)}} onBlur={()=>{GetClientVisitsLog(CurrentPage,ClientId)}} placeholder="Search by visit id..."/></div>
                <div className="button"><button onClick={()=>{GetClientVisitsLog(CurrentPage,ClientId)}}><i class="fa fa-search"></i></button></div>
                </div>
              <Table className="table is-striped is-fullwidth">
                <Thead className="thead ">
                  <Tr className="trow">
                    <Th className="row_name">Persona Id</Th>
                    <Th className="row_name">Master Id/Visit Id</Th>
                    <Th className="row_name">Visit Type</Th>
                    <Th className="row_name">Submitted Date</Th>
                    <Th className="row_name">Visit Status</Th>
                    <Th className="row_name">Action</Th>
                  </Tr>
                </Thead>

                {VisitLog.length > 0 && (
                  <Tbody className="tbody">
                    {VisitLog.length > 0 &&
                      VisitLog.map((visit, index) => (
                        <Tr className="trow" key={visit.id}>
                          <Td>
                            <span className="">{personaId}</span>
                          </Td>
                          <Td>
                            <span className="">{visit.visitId}</span>
                          </Td>
                          <Td>
                            <span className="">{visit.visitType}</span>
                          </Td>
                          <Td>
                            <span className="">
                              {format(
                                parseISO(
                                  timetoUtcClientAppointment(
                                    visit.submitted_date
                                  )
                                ),
                                `MMM dd, yyyy  hh:mm a`
                              )}
                            </span>
                          </Td>
                          <Td>
                            <span className="">{(visit.visit_status!==null && visit.visit_status!='') ? visit.visit_status : '-'}</span>
                          </Td>
                          <Td>
                            <button
                              className="view_button view_beluga_log_butt"
                              data-id={visit.id}
                              onClick={ViewDetailsOftheVisit}
                            >
                              View
                            </button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                )}
              </Table>
              <div>
                {TotalRecord ? (
                  <Pagination
                    activePage={CurrentPage}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecord}
                    onChange={changePageNumber.bind(this)}
                    prevPageText="<"
                    nextPageText=">"
                    hideFirstLastPages={true}
                    linkClassPrev="pagination_prev"
                    linkClassNext="pagination_next"
                    linkClass="pagenumbers color_grey"
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {DetailId !== "" ? <VisitDetails DetailId={DetailId} clientid={props.data} /> : null}
        </div>
      </section>
    </>
  );
};
export default ClientBelugaHealth;

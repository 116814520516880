/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Pagination from 'react-js-pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import ReactToPrint, { useReactToPrint, PrintContextConsumer } from 'react-to-print';
import { Pie } from 'react-chartjs-2';
import Datepicker from 'react-date-picker';
import { format } from 'date-fns'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const DietPlan = (props) => {

    //console.log(props.data);

    const [PercentageArray, SetPercentageArray] = useState([]);
    const [RecipeProtein, SetRecipeProtein] = useState(0);
    const [RecipeFat, SetRecipeFat] = useState(0);
    const [RecipeCarbs, SetRecipeCarbs] = useState(0);
    const [RecipeCalories, SetRecipeCalories] = useState(0);
    const [showinputError, setshowinputError] = useState(false);

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {

                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    // console.log(percentage)
                    return percentage;
                },
                color: '#fff',
            }
        },
    }

    const data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],

                borderWidth: 1,
            },
        ],
    };

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");

    const [profileData, setprofileData] = useState('');
    const [profileTab, setprofileTab] = useState('dietplan');

    const [show, setshow] = useState(false);
    const handleClose = () => setshow(false);
    const [inputField, setinputField] = useState('');
    const [heightVal, setheightVal] = useState('');
    const [feetVal, setfeetVal] = useState(0);
    const [inchVal, setinchVal] = useState(0);
    const [weightVal, setweightVal] = useState(0);
    const [goalweightVal, setgoalweightVal] = useState(0);
    const [dailycalVal, setdailycalVal] = useState(0);
    const [scaleType, setscaleType] = useState('');
    const [activityVal, setactivityVal] = useState('');
    const [clientGender, setclientGender] = useState('');
    const [clientAge, setclientAge] = useState('');
    const [dailywaterVal, setdailywaterVal] = useState(0);
    const [dailyproVal, setdailyproVal] = useState(0);
    const [dailyfatVal, setdailyfatVal] = useState(0);
    const [dailycarbVal, setdailycarbVal] = useState(0);
    var [clientDob, setclientDob] = useState(new Date());


    const weekOptions = [{ label: "Week 1", value: 1 }, { label: "Week 2", value: 2 }, { label: "Week 3", value: 3 }, { label: "Week 4", value: 4 }];
    const weekDays = [1, 2, 3, 4, 5, 6, 7];

    const [ingshow, setingshow] = useState(false);
    const [IngList, setIngList] = useState([]);
    const [ResetIngList, setResetIngList] = useState([]);
    const [breakfastKeys, setbreakfastKeys] = useState('');
    const [lunchKeys, setlunchKeys] = useState('');
    const [dinnerKeys, setdinnerKeys] = useState('');
    const [snackKeys, setsnackKeys] = useState('');
    const [favoriteKeys, setfavoriteKeys] = useState([]);
    const [BreakfastPlan, setBreakfastPlan] = useState('');
    const [LunchPlan, setLunchPlan] = useState('');
    const [DinnerPlan, setDinnerPlan] = useState('');
    const [SnackPlan, setSnackPlan] = useState('');
    const [recipeShow, setrecipeShow] = useState(false);
    const handlerecipeClose = () => setrecipeShow(false);
    const [recipeData, setrecipeData] = useState('');
    const [recipetab, setrecipetab] = useState('ingredients');
    //const [weekDays, setweekDays] = useState([]);
    const [swapshow, setswapshow] = useState(false);
    const handleswapClose = () => setswapshow(false);
    const [swapdayIndex, setswapdayIndex] = useState('');
    const [currentWeek, setcurrentWeek] = useState(1);
    const [swapTab, setswapTab] = useState('library');
    const [searchLibrary, setsearchLibrary] = useState('');
    const [countPerPage, setcountPerPage] = useState(10);
    const [activePage, setactivepage] = useState(0);
    const [totalCount, settotalCount] = useState(0);
    const [favactivePage, setfavactivepage] = useState(0);
    const [favtotalCount, setfavtotalCount] = useState(0);
    const [swaprecipeslist, setswaprecipeslist] = useState('');
    const [swapfavrecipeslist, setswapfavrecipeslist] = useState('');
    const [swapsearchFound, setswapsearchFound] = useState(true);
    const [swapRecipeId, setswapRecipeId] = useState('');
    const [swapfoodMenu, setswapfoodMenu] = useState('');
    const [shoppingrecipeList, setshoppingrecipeList] = useState([]);
    const [vegshoppingList, setvegshoppingList] = useState([]);
    const [meatshoppingList, setmeatshoppingList] = useState([]);
    const [dairyshoppingList, setdairyshoppingList] = useState([]);
    const [spiceshoppingList, setspiceshoppingList] = useState([]);
    const [othershoppingList, setothershoppingList] = useState([]);
    const shoppingPrint = useRef();
    const mealplanPrint = useRef();
    const [IngredientError, setIngredientError] = useState(false);

    useEffect(() => {
        load_data();
    }, []);

    const axiosJWT = axios.create();



    const activityoptions = [{ label: 'Couch Potato', value: 'Couch Potato' }, { label: 'Somewhat Active', value: 'Somewhat Active' }, { label: 'Average Activity', value: 'Average Activity' }, { label: 'Very Active', value: 'Very Active' }, { label: 'Extremely Active', value: 'Extremely Active' }];

    const scaleoptions = [{label: 'Imperial', value: 'imperial'},{label: 'Metric', value: 'metric'}];

    const load_data = async () => {

        loading_icon('show');

        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            
            console.log("mi: ing")
            console.log(response.data.client_infos[0].ingredients);  
            var ingredients_list = (response.data.client_infos[0].ingredients).split(",");
            console.log(ingredients_list);  
            setIngList(ingredients_list);
            setResetIngList(ingredients_list);

            var scale_type = (response.data.client_infos[0].measure_type).toLowerCase();
            setscaleType(scale_type);

            if ((scale_type).toLowerCase() === 'imperial') {
                var height_data = (response.data.client_infos[0].height + "").split(".");
                setfeetVal(height_data[0]);
                setinchVal(height_data[1]);
            }
            else {
                setheightVal(response.data.client_infos[0].height);
            }


            var client_age = '';

            if(response.data.user_dob !== null && response.data.user_dob !== '' && response.data.user_dob !== '0000-00-00')
            {                
                client_age = (format(new Date(), 'yyyy')) - (format(new Date(response.data.user_dob), 'yyyy'));
                setclientAge(client_age);
                // console.log(moment(response.data.user_dob).format());

                // var dob_format_date = moment(response.data.user_dob).format('MMMM Do YYYY');

                setclientDob(new Date(response.data.user_dob));
            }

            setclientGender(response.data.gender);
            setweightVal(response.data.client_infos[0].weight);
            setgoalweightVal(response.data.client_infos[0].target_weight);
            setactivityVal({ label: response.data.client_infos[0].activity, value: response.data.client_infos[0].activity });
            setdailycalVal(response.data.client_infos[0].daily_calories);

            if (response.data.client_infos[0].daily_calories === 0 || response.data.client_infos[0].daily_calories === null || response.data.client_infos[0].daily_calories === '') {

                if(client_age != '' && response.data.gender !== '' && response.data.client_infos[0].height !== 0 && response.data.client_infos[0].weight !== 0 && response.data.client_infos[0].target_weight !== 0 && response.data.client_infos[0].activity !== '')
                {
                calorie_calc();
                setshowinputError(false);
                }
                else
                {
                    loading_icon('hide');
                    setprofileData(response.data);
                    setshowinputError(true);
                    // console.log("input fields missing");
                }
            }
            else {

                if(client_age !== '' && response.data.gender !== '' && response.data.client_infos[0].height !== 0 && response.data.client_infos[0].weight !== 0 && response.data.client_infos[0].target_weight !== 0 && response.data.client_infos[0].activity !== '')
                {
                load_profile();
                setshowinputError(false);
                }
                else
                {
                    loading_icon('hide');
                    setprofileData(response.data);
                    setshowinputError(true);
                    // console.log("input fields missing");
                }
            }
        });

    }

    const load_profile = async (req) => {

        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response.data);
            setprofileData(response.data);

            if (req === 'update')
            {                
                load_recipes(1, 'update');
            }
            else
                load_recipes(1, '');
        });

    }

    const handleshow = async (e) => {
        var input_field = e.target.getAttribute("data-item");

        setinputField(input_field);

        setshow(true);
    }

    const updateInputs = async () => {       

        if (inputField === 'Age') {
            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    user_dob: format(clientDob, 'yyyy-MM-dd')
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setshow(false);
                calorie_calc();
            }
        }

        if (inputField === 'Height') {

            var new_input_val;

            if ((scaleType).toLowerCase() === 'imperial')
                new_input_val = feetVal + "." + inchVal;
            else
                new_input_val = heightVal;

            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    height: new_input_val,
                    measure_type: scaleType.charAt(0).toUpperCase()+scaleType.slice(1)
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                // console.log(response)
                setshow(false);
                calorie_calc();
            }
        }
        if (inputField === 'Weight') {
            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    weight: weightVal
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setshow(false);
                calorie_calc();
            }
        }
        if (inputField === 'Goal Weight') {
            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    target_weight: goalweightVal
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setshow(false);
                calorie_calc();
            }
        }
        if (inputField === 'Activity Level') {
            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    activity: activityVal.value
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setshow(false);
                calorie_calc();
            }
        }
        if (inputField === 'Daily Calories') {

            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    daily_calories: dailycalVal
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });


            if (response.status === 200) {
                calorie_manual_change_calc();
                setshow(false);
            }
        }
    }

    const calorie_manual_change_calc = async () => {

        var daily_fat, daily_carb;
        var user_activity = activityVal.value;

        if (user_activity === 'Couch Potato') {
            daily_fat = Math.ceil(((dailycalVal * 0.2) / 9) * 1.2);
            daily_carb = Math.ceil(((dailycalVal * 0.45) / 9) * 1.2);
        }
        else if (user_activity === 'Somewhat Active') {
            daily_fat = Math.ceil(((dailycalVal * 0.2) / 9) * 1.375);
            daily_carb = Math.ceil(((dailycalVal * 0.45) / 9) * 1.375);
        }
        else if (user_activity === 'Average Activity') {
            daily_fat = Math.ceil(((dailycalVal * 0.2) / 9) * 1.55);
            daily_carb = Math.ceil(((dailycalVal * 0.45) / 9) * 1.55);
        }
        else if (user_activity === 'Very Active') {
            daily_fat = Math.ceil(((dailycalVal * 0.2) / 9) * 1.725);
            daily_carb = Math.ceil(((dailycalVal * 0.45) / 9) * 1.725);
        }
        else {
            daily_fat = Math.ceil(((dailycalVal * 0.2) / 9) * 1.9);
            daily_carb = Math.ceil(((dailycalVal * 0.45) / 9) * 1.9);
        }

        const response = await axiosJWT.put("/week-diet-plan/inputs",
            {
                daily_fat: daily_fat,
                daily_carb: daily_carb
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200)
            load_profile('update');
    }

    const calorie_calc = async () => {

        var client_details = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var clientdata = client_details.data;

        if(clientdata.user_dob !== '' && clientdata.gender !== '' && clientdata.client_infos[0].height !== 0 && clientdata.client_infos[0].weight !== 0 && clientdata.client_infos[0].target_weight !== 0 && clientdata.client_infos[0].activity !== '')
        {

            setshowinputError(false);
            // console.log(clientdata.user_dob+"test");

            var scale_type = (clientdata.client_infos[0].measure_type).toLowerCase();
            if ((scale_type).toLowerCase() === 'imperial') {
                var height_data = (clientdata.client_infos[0].height + "").split(".");

                if (height_data.length === 2) {
                    var height_data_ft = height_data[0];
                    var height_data_inch = height_data[1];
                }
                else {
                    var height_data_ft = height_data[0];
                    var height_data_inch = 0;
                }

                var height_data_cm = 0;
            }
            else {
                var height_data_cm = clientdata.client_infos[0].height;
                var height_data_ft = 0;
                var height_data_inch = 0;
            }

            var client_age = (format(new Date(), 'yyyy')) - (format(new Date(clientdata.user_dob), 'yyyy'));

            var user_gender = (clientdata.gender).toLowerCase();
            var user_input_scale = scale_type;
            var user_age = client_age;
            var user_activity = clientdata.client_infos[0].activity;
            var heightcm = height_data_cm;
            var heightft = height_data_ft;
            var heightin = height_data_inch;
            var weightkglb = clientdata.client_infos[0].weight;
            var tweightkglb = clientdata.client_infos[0].target_weight;

            //console.log(user_gender+" "+user_input_scale+" "+user_age+" "+user_activity+" "+heightcm+" "+heightft+" "+heightin+" "+weightkglb+" "+tweightkglb);

            if ((user_input_scale).toLowerCase() === 'metric') {
                //height in cm
                if (heightcm !== null && heightcm !== '')
                    var user_height_cm = heightcm;
                else
                    var user_height_cm = 180; //default assign

                //weight in kg
                if (weightkglb !== null && weightkglb !== '')
                    var user_weight_kg = weightkglb;
                else
                    var user_weight_kg = 80; //default assign

                var user_weight_lbs = (user_weight_kg * 2.205);


                //target weight in kg
                if (tweightkglb !== null && tweightkglb !== '')
                    var user_tweight_kg = tweightkglb;
                else
                    var user_tweight_kg = user_weight_kg; //default assign

                var bmi_value = Math.round((user_weight_kg / ((user_height_cm / 100) * (user_height_cm / 100))));
            }
            if ((user_input_scale).toLowerCase() === 'imperial') {
                //height in inch
                if (heightft !== null && heightft !== '' && heightin !== null && heightin !== '')
                    var user_height = (parseInt(heightft * 12) + parseInt(heightin));
                else if (heightft !== null && heightft !== '')
                    var user_height = (parseInt(heightft * 12));
                else
                    var user_height = 6; //default assign

                var user_height_cm = (user_height * 2.54);


                //weight in lbs
                if (weightkglb !== null && weightkglb !== '')
                    var user_weight_lbs = weightkglb;
                else
                    var user_weight_lbs = 200; //default assign

                var user_weight_kg = (user_weight_lbs / 2.205);


                //target weight in lbs
                if (tweightkglb !== null && tweightkglb !== '')
                    var user_tweight_lbs = tweightkglb;
                else
                    var user_tweight_lbs = user_weight_lbs; //default assign

                var user_tweight_kg = (user_tweight_lbs / 2.205);

                var bmi_value = Math.round(((user_weight_lbs / (user_height * user_height)) * 703));
            }
            if (user_gender === 'male') {
                var bmr_value = (((10 * user_weight_kg) + (6.25 * user_height_cm) + 5) - (5 * user_age));
            }
            if (user_gender === 'female') {
                var bmr_value = (((10 * user_weight_kg) + (6.25 * user_height_cm)) - (5 * user_age) - 161);
            }

            //activity calculation
            if (user_activity === 'Couch Potato') {
                var daily_calories = Math.ceil(bmr_value * 1.2);
                /* 5% weight loss calculation code start */
                //7,700 calories = 1 kg
                //min calorie required per day
                var calorie_limit = 2000;
                var achievable_weight_loss_daily_calorie = Math.ceil(((user_weight_kg * 0.05) * 7700) / 56);
                //check if daily calories have enough weight loss
                if ((daily_calories - achievable_weight_loss_daily_calorie) > calorie_limit) {
                    var daily_calories = (daily_calories - achievable_weight_loss_daily_calorie);
                    var achievable_weight = Math.ceil(user_weight_kg - (user_weight_kg * 0.05));
                }
                else if (daily_calories > calorie_limit) //check if daily calories have more than limit
                {
                    var balance_calorie_available = (daily_calories - calorie_limit);
                    var daily_calories = (daily_calories - balance_calorie_available);
                    var achievable_weight = Math.ceil(user_weight_kg - ((balance_calorie_available * 56) / 7700));
                }
                else //if daily calories don't have enough calories to weight loss then simply leave it as.
                {
                    var achievable_weight = user_weight_kg;
                }
                /* 5% weight loss calculation code end */
                var daily_fat_from = Math.ceil(((daily_calories * 0.2) / 9) * 1.2);
                var daily_fat_to = Math.ceil(((daily_calories * 0.35) / 9) * 1.2);
                var daily_protein_from = Math.ceil((user_weight_kg * 0.8) * 1.2);
                var daily_protein_to = Math.ceil((user_weight_kg * 1.0) * 1.2);
                var daily_carb_from = Math.ceil(((daily_calories * 0.45) / 9) * 1.2);
                var daily_carb_to = Math.ceil(((daily_calories * 0.65) / 9) * 1.2);
                var daily_water = (user_weight_lbs * (2 / 3));
            }
            else if (user_activity === 'Somewhat Active') {
                var daily_calories = Math.ceil(bmr_value * 1.375);
                /* 5% weight loss calculation code start */
                //min calorie required per day
                var calorie_limit = 2000;
                var achievable_weight_loss_daily_calorie = Math.ceil(((user_weight_kg * 0.05) * 7700) / 56);
                //check if daily calories have enough weight loss
                if ((daily_calories - achievable_weight_loss_daily_calorie) > calorie_limit) {
                    var daily_calories = (daily_calories - achievable_weight_loss_daily_calorie);
                    var achievable_weight = Math.ceil(user_weight_kg - (user_weight_kg * 0.05));
                }
                else if (daily_calories > calorie_limit) //check if daily calories have more than limit
                {
                    var balance_calorie_available = (daily_calories - calorie_limit);
                    var daily_calories = (daily_calories - balance_calorie_available);
                    var achievable_weight = Math.ceil(user_weight_kg - ((balance_calorie_available * 56) / 7700));
                }
                else //if daily calories don't have enough calories to weight loss then simply leave it as.
                {
                    var achievable_weight = user_weight_kg;
                }
                /* 5% weight loss calculation code end */
                var daily_fat_from = Math.ceil(((daily_calories * 0.2) / 9) * 1.375);
                var daily_fat_to = Math.ceil(((daily_calories * 0.35) / 9) * 1.375);
                var daily_protein_from = Math.ceil((user_weight_kg * 0.8) * 1.375);
                var daily_protein_to = Math.ceil((user_weight_kg * 1.0) * 1.375);
                var daily_carb_from = Math.ceil(((daily_calories * 0.45) / 9) * 1.375);
                var daily_carb_to = Math.ceil(((daily_calories * 0.65) / 9) * 1.375);
                var daily_water = ((user_weight_lbs * (2 / 3)) + 6);
            }
            else if (user_activity === 'Average Activity') {
                var daily_calories = Math.ceil(bmr_value * 1.55);
                /* 5% weight loss calculation code start */
                //min calorie required per day
                var calorie_limit = 2000;
                var achievable_weight_loss_daily_calorie = Math.ceil(((user_weight_kg * 0.05) * 7700) / 56);
                //check if daily calories have enough weight loss
                if ((daily_calories - achievable_weight_loss_daily_calorie) > calorie_limit) {
                    var daily_calories = (daily_calories - achievable_weight_loss_daily_calorie);
                    var achievable_weight = Math.ceil(user_weight_kg - (user_weight_kg * 0.05));
                }
                else if (daily_calories > calorie_limit) //check if daily calories have more than limit
                {
                    var balance_calorie_available = (daily_calories - calorie_limit);
                    var daily_calories = (daily_calories - balance_calorie_available);
                    var achievable_weight = Math.ceil(user_weight_kg - ((balance_calorie_available * 56) / 7700));
                }
                else //if daily calories don't have enough calories to weight loss then simply leave it as.
                {
                    var achievable_weight = user_weight_kg;
                }
                /* 5% weight loss calculation code end */
                var daily_fat_from = Math.ceil(((daily_calories * 0.2) / 9) * 1.55);
                var daily_fat_to = Math.ceil(((daily_calories * 0.35) / 9) * 1.55);
                var daily_protein_from = Math.ceil((user_weight_kg * 0.8) * 1.55);
                var daily_protein_to = Math.ceil((user_weight_kg * 1.0) * 1.55);
                var daily_carb_from = Math.ceil(((daily_calories * 0.45) / 9) * 1.55);
                var daily_carb_to = Math.ceil(((daily_calories * 0.65) / 9) * 1.55);
                var daily_water = ((user_weight_lbs * (2 / 3)) + 12);
            }
            else if (user_activity === 'Very Active') {

                var daily_calories = Math.ceil(bmr_value * 1.725);
                /* 5% weight loss calculation code start */
                //min calorie required per day
                var calorie_limit = 2000;
                var achievable_weight_loss_daily_calorie = Math.ceil(((user_weight_kg * 0.05) * 7700) / 56);
                //check if daily calories have enough weight loss
                if ((daily_calories - achievable_weight_loss_daily_calorie) > calorie_limit) {
                    var daily_calories = (daily_calories - achievable_weight_loss_daily_calorie);
                    var achievable_weight = Math.ceil(user_weight_kg - (user_weight_kg * 0.05));
                }
                else if (daily_calories > calorie_limit) //check if daily calories have more than limit
                {
                    var balance_calorie_available = (daily_calories - calorie_limit);
                    var daily_calories = (daily_calories - balance_calorie_available);
                    var achievable_weight = Math.ceil(user_weight_kg - ((balance_calorie_available * 56) / 7700));
                }
                else //if daily calories don't have enough calories to weight loss then simply leave it as.
                {
                    var achievable_weight = user_weight_kg;
                }
                /* 5% weight loss calculation code end */
                var daily_fat_from = Math.ceil(((daily_calories * 0.2) / 9) * 1.725);
                var daily_fat_to = Math.ceil(((daily_calories * 0.35) / 9) * 1.725);
                var daily_protein_from = Math.ceil((user_weight_kg * 0.8) * 1.725);
                var daily_protein_to = Math.ceil((user_weight_kg * 1.0) * 1.725);
                var daily_carb_from = Math.ceil(((daily_calories * 0.45) / 9) * 1.725);
                var daily_carb_to = Math.ceil(((daily_calories * 0.65) / 9) * 1.725);
                var daily_water = ((user_weight_lbs * (2 / 3)) + 18);
            }
            else {
                var daily_calories = Math.ceil(bmr_value * 1.9);
                /* 5% weight loss calculation code start */
                //min calorie required per day
                var calorie_limit = 2000;
                var achievable_weight_loss_daily_calorie = Math.ceil(((user_weight_kg * 0.05) * 7700) / 56);
                //check if daily calories have enough weight loss
                if ((daily_calories - achievable_weight_loss_daily_calorie) > calorie_limit) {
                    var daily_calories = (daily_calories - achievable_weight_loss_daily_calorie);
                    var achievable_weight = Math.ceil(user_weight_kg - (user_weight_kg * 0.05));
                }
                else if (daily_calories > calorie_limit) //check if daily calories have more than limit
                {
                    var balance_calorie_available = (daily_calories - calorie_limit);
                    var daily_calories = (daily_calories - balance_calorie_available);
                    var achievable_weight = Math.ceil(user_weight_kg - ((balance_calorie_available * 56) / 7700));
                }
                else //if daily calories don't have enough calories to weight loss then simply leave it as.
                {
                    var achievable_weight = user_weight_kg;
                }
                /* 5% weight loss calculation code end */
                var daily_fat_from = Math.ceil(((daily_calories * 0.2) / 9) * 1.9);
                var daily_fat_to = Math.ceil(((daily_calories * 0.35) / 9) * 1.9);
                var daily_protein_from = Math.ceil((user_weight_kg * 0.8) * 1.9);
                var daily_protein_to = Math.ceil((user_weight_kg * 1.0) * 1.9);
                var daily_carb_from = Math.ceil(((daily_calories * 0.45) / 9) * 1.9);
                var daily_carb_to = Math.ceil(((daily_calories * 0.65) / 9) * 1.9);
                var daily_water = ((user_weight_lbs * (2 / 3)) + 24);
            }
            //water ounce to litre calculation
            var daily_water = Math.round((daily_water / 33.814), 1);
            if (bmi_value < 16.0)
                var bmi_text = "Severely Underweight";
            else if (bmi_value > 16.0 && bmi_value < 18.4)
                var bmi_text = "Underweight";
            else if (bmi_value > 18.5 && bmi_value < 25)
                var bmi_text = "Normal";
            else if (bmi_value > 25.0 && bmi_value < 30)
                var bmi_text = "Overweight";
            else if (bmi_value > 30.0 && bmi_value < 35)
                var bmi_text = "Moderately Obese";
            else if (bmi_value > 35.0 && bmi_value < 40)
                var bmi_text = "Severely Obese";
            else
                var bmi_text = "Morbidly Obese";

            setdailycalVal(daily_calories);
            setdailywaterVal(daily_water);
            setdailyproVal(daily_protein_to);
            setdailyfatVal(daily_fat_to);
            setdailycarbVal(daily_carb_to);

            if ((user_input_scale).toLowerCase() === 'metric') {
                setgoalweightVal(achievable_weight);
                var target_achieve = achievable_weight;
            }
            else {
                var conv_to_imperial = Math.floor(achievable_weight * 2.205);
                setgoalweightVal(conv_to_imperial);
                var target_achieve = conv_to_imperial;
            }

            const response = await axiosJWT.put("/week-diet-plan/inputs",
                {
                    daily_calories: daily_calories,
                    target_weight: target_achieve,
                    water_intake: daily_water,
                    daily_protein: daily_protein_to,
                    daily_fat: daily_fat_to,
                    daily_carb: daily_carb_to
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200)
                load_profile('update');
        }
        else
        {
            loading_icon('hide');
            load_data();
        }
    }

    const handleFilters = async (e) => {
        var filter_for = e.target.getAttribute("data-item");

        //console.log('hai'+filter_for)

        if (filter_for === 'change_ingredients') {
            setingshow(true);
        }

        /*if(filter_for === 'download_shopping')
        {
            downloadshopping();
        }*/
    }

    const handleingClose = async () => {
        axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            // console.log(response.data);  
            var ingredients_list = (response.data.client_infos[0].ingredients).split(",");
            setIngList(ingredients_list);
            setingshow(false);
        });
    }


    const load_recipes = async (week, req) => {

        loading_icon('show');

        setcurrentWeek(week);

        var ing_error = false;

        const response = await axiosJWT.get("/user", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });


        if((response.data.week_diet_plans).length > 0)
        {
            var breakfastkeys_exist = response.data.week_diet_plans[0].breakfast_keys;
            var lunchkeys_exist = response.data.week_diet_plans[0].lunch_keys;
            var dinnerkeys_exist = response.data.week_diet_plans[0].dinner_keys;
            var snackkeys_exist = response.data.week_diet_plans[0].snack_keys;
            var favoritekeys_exist = response.data.week_diet_plans[0].favorites;

            setbreakfastKeys(breakfastkeys_exist);
            setlunchKeys(lunchkeys_exist);
            setdinnerKeys(dinnerkeys_exist);
            setsnackKeys(snackkeys_exist);
            setfavoriteKeys(favoritekeys_exist);
        }
        else
        {
            var breakfastkeys_exist = null;
        }

        // console.log(breakfastkeys_exist+" "+lunchkeys_exist+" "+dinnerkeys_exist+" "+snackkeys_exist+" "+favoritekeys_exist+" "+req);

        if (breakfastkeys_exist === null || breakfastkeys_exist === '' || req === 'update') {
          

            var all_categories = ['Chicken', 'Pork', 'Beef', 'Fish', 'Bacon', 'Broccoli', 'Mushroom', 'Zucchini', 'Cauliflower', 'Asparagus', 'Avocad', 'Egg', 'Nuts', 'Cheese', 'Cottage-Cheese', 'Butter', 'Coconut', 'No-Meat'];


            //if client don't have any ingredients in table then load all category recipes
            if(response.data.client_infos[0].ingredients === null || response.data.client_infos[0].ingredients === '')
            var ingredients_list_array = ['Chicken', 'Pork', 'Beef', 'Fish', 'Bacon', 'Broccoli', 'Mushroom', 'Zucchini', 'Cauliflower', 'Asparagus', 'Avocad', 'Egg', 'Nuts', 'Cheese', 'Cottage-Cheese', 'Butter', 'Coconut'];
            else
            var ingredients_list_array = response.data.client_infos[0].ingredients;

            var ingredients_list = ingredients_list_array.toString();
            var daily_calories_val = response.data.client_infos[0].daily_calories;

            var unselected_categories = [];
            all_categories.forEach(element => {
                if (ingredients_list_array.indexOf(element) === -1) {
                    if (element === 'No-Meat')
                        unselected_categories.push('Meat');
                    else
                        unselected_categories.push(element);
                }
            });
            var unselected_categories_list = unselected_categories.toString();

            //for adding other names of ingredients
            var title_unselected_categories = unselected_categories;

            if (ingredients_list_array.indexOf('No-Meat') === -1) {
                title_unselected_categories.push('Meat');
                title_unselected_categories.push('Mutton');
                title_unselected_categories.push('Steak');
                title_unselected_categories.push('Sausage');
                title_unselected_categories.push('Lamb');
                title_unselected_categories.push('Buffalo');
                title_unselected_categories.push('Ham');
                title_unselected_categories.push('Filet');
                title_unselected_categories.push('Salmon');
                title_unselected_categories.push('Tuna');
                title_unselected_categories.push('Halibut');
                title_unselected_categories.push('Mahi');
                title_unselected_categories.push('Tilapia');
                title_unselected_categories.push('Shrimp');
                title_unselected_categories.push('Prawn');
                title_unselected_categories.push('Lobster');
                title_unselected_categories.push('Scallop');
                title_unselected_categories.push('Turkey');
                title_unselected_categories.push('Rabit');
                title_unselected_categories.push('Duck');
                title_unselected_categories.push('Pigeon');
                title_unselected_categories.push('Hen');
                title_unselected_categories.push('Pot Roast');
                title_unselected_categories.push('Dog');
            }
            else {
                //if fish not in list
                if (ingredients_list_array.indexOf('Fish') === -1) {
                    title_unselected_categories.push('Salmon');
                    title_unselected_categories.push('Tuna');
                    title_unselected_categories.push('Halibut');
                    title_unselected_categories.push('Mahi');
                    title_unselected_categories.push('Tilapia');
                    title_unselected_categories.push('Shrimp');
                    title_unselected_categories.push('Prawn');
                    title_unselected_categories.push('Lobster');
                    title_unselected_categories.push('Scallop');
                    title_unselected_categories.push('Filet');
                }

                //if Beef not in list
                if (ingredients_list_array.indexOf('Beef') === -1) {
                    title_unselected_categories.push('Buffalo');
                    title_unselected_categories.push('Meat');
                    title_unselected_categories.push('Mutton');
                    title_unselected_categories.push('Steak');
                    title_unselected_categories.push('Sausage');
                    title_unselected_categories.push('Lamb');
                    title_unselected_categories.push('Dog');
                    title_unselected_categories.push('Ham');
                    title_unselected_categories.push('Filet');
                }

                //if pork not in list
                if (ingredients_list_array.indexOf('Pork') === -1) {
                    title_unselected_categories.push('Ham');
                    title_unselected_categories.push('Filet');
                }

                if (ingredients_list_array.indexOf('Bacon') === -1) {
                    title_unselected_categories.push('Ham');
                    title_unselected_categories.push('Filet');
                }

                //if Chicken not in list
                if (ingredients_list_array.indexOf('Chicken') === -1) {
                    title_unselected_categories.push('Turkey');
                    title_unselected_categories.push('Rabit');
                    title_unselected_categories.push('Duck');
                    title_unselected_categories.push('Pigeon');
                    title_unselected_categories.push('Hen');
                    title_unselected_categories.push('Ham');
                }
            }




            var breakfaststore = [];

            const breakfast_recipes_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=breakfast&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + daily_calories_val);

            var breakfast_recipes = breakfast_recipes_api.data.recipeid;

            // console.log('mi: test')
            // console.log(breakfast_recipes.length)

            if(breakfast_recipes!==undefined && breakfast_recipes.length>20)
            {

                breakfast_recipes.forEach(element => {
                    //console.log(breakfast_recipes_api.data[element]['title']);
                    var matching = 0;
                    for (var k = 0; k < title_unselected_categories.length; k++) {
                        var title_match = (breakfast_recipes_api.data[element]['title']).toLowerCase().indexOf(title_unselected_categories[k].toLowerCase());
    
                        if (title_match !== -1) {
                            matching++;
                        }
                    }
    
                    //storing into array
                    if (matching === 0) {
                        breakfaststore.push({ 'RecipeID': element, 'Calories': breakfast_recipes_api.data[element]['calories'], 'Title': breakfast_recipes_api.data[element]['title'] });
                    }
                });
            }
            else
            {
                loading_icon('hide');
                ing_error = true;
                setIngredientError(ing_error);                
            }

           

            // console.log("--breakfast--");
            // console.log(breakfaststore.length);


            var lunchstore = [];

            const lunch_recipes_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=lunch&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + daily_calories_val);

            var lunch_recipes = lunch_recipes_api.data.recipeid;

            //console.log(breakfast_recipes_api.data)

            if(lunch_recipes!==undefined && lunch_recipes.length>20)
            {

                lunch_recipes.forEach(element => {
                    //console.log(breakfast_recipes_api.data[element]['title']);
                    var matching = 0;
                    for (var k = 0; k < title_unselected_categories.length; k++) {
                        var title_match = (lunch_recipes_api.data[element]['title']).toLowerCase().indexOf(title_unselected_categories[k].toLowerCase());
    
                        if (title_match !== -1) {
                            matching++;
                        }
                    }
    
                    //storing into array
                    if (matching === 0) {
                        lunchstore.push({ 'RecipeID': element, 'Calories': lunch_recipes_api.data[element]['calories'], 'Title': lunch_recipes_api.data[element]['title'] });
                    }
                });
            }
            else
            {
                loading_icon('hide');
                ing_error = true;
                setIngredientError(ing_error);            
            }          


            // console.log("--lunch--");
            // console.log(lunchstore.length);

            var dinnerstore = [];

            const dinner_recipes_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=dinner&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + daily_calories_val);

            var dinner_recipes = dinner_recipes_api.data.recipeid;

            //console.log(breakfast_recipes_api.data)

            if(dinner_recipes!==undefined && dinner_recipes.length>20)
            {

                dinner_recipes.forEach(element => {
                    //console.log(breakfast_recipes_api.data[element]['title']);
                    var matching = 0;
                    for (var k = 0; k < title_unselected_categories.length; k++) {
                        var title_match = (dinner_recipes_api.data[element]['title']).toLowerCase().indexOf(title_unselected_categories[k].toLowerCase());
    
                        if (title_match !== -1) {
                            matching++;
                        }
                    }
    
                    //storing into array
                    if (matching === 0) {
                        dinnerstore.push({ 'RecipeID': element, 'Calories': dinner_recipes_api.data[element]['calories'], 'Title': dinner_recipes_api.data[element]['title'] });
                    }
                });
            }
            else
            {
                loading_icon('hide');
                ing_error = true;
                setIngredientError(ing_error);               
            }     


            // console.log("--dinner--");
            // console.log(dinnerstore.length);

            var snackstore = [];

            const snack_recipes_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=snack&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + daily_calories_val);

            var snack_recipes = snack_recipes_api.data.recipeid;

            //console.log(breakfast_recipes_api.data)

            if(snack_recipes!==undefined && snack_recipes.length>20)
            {
                snack_recipes.forEach(element => {
                    //console.log(breakfast_recipes_api.data[element]['title']);
                    var matching = 0;
                    for (var k = 0; k < title_unselected_categories.length; k++) {
                        var title_match = (snack_recipes_api.data[element]['title']).toLowerCase().indexOf(title_unselected_categories[k].toLowerCase());
    
                        if (title_match !== -1) {
                            matching++;
                        }
                    }
    
                    //storing into array
                    if (matching === 0) {
                        snackstore.push({ 'RecipeID': element, 'Calories': snack_recipes_api.data[element]['calories'], 'Title': snack_recipes_api.data[element]['title'] });
                    }
                });
            }
            else
            {
                loading_icon('hide');
                ing_error = true;
                setIngredientError(ing_error);                 
            }          


            if(ing_error === false)
            {
                // console.log("--snack--");
                // console.log(snackstore.length);

                // console.log('recipekey not exist');

                var snackkeys = [];
                var snackindex = [];
                var breakfastkeys = [];
                var breakfastindex = [];
                var lunchkeys = [];
                var lunchindex = [];
                var dinnerkeys = [];
                var dinnerindex = [];

                //storing recipe keys
                while (breakfastindex.length < 28) {

                    // console.log(breakfastindex.length);

                    var daycals = [];

                    var r = Math.floor(Math.random() * (breakfaststore.length - 1)) + 1;
                    breakfastindex.push(r);
                    breakfastkeys.push(breakfaststore[r].RecipeID)
                    daycals.push(parseInt(breakfaststore[r].Calories));


                    var r = Math.floor(Math.random() * (lunchstore.length - 1)) + 1;
                    lunchindex.push(r);
                    lunchkeys.push(lunchstore[r].RecipeID)
                    daycals.push(parseInt(lunchstore[r].Calories));

                    var r = Math.floor(Math.random() * (dinnerstore.length - 1)) + 1;
                    dinnerindex.push(r);
                    dinnerkeys.push(dinnerstore[r].RecipeID)
                    daycals.push(parseInt(dinnerstore[r].Calories));

                    var r = Math.floor(Math.random() * (snackstore.length - 1)) + 1;
                    snackindex.push(r);
                    snackkeys.push(snackstore[r].RecipeID)
                    daycals.push(parseInt(snackstore[r].Calories));

                    //validate with daily calorie limit if exceeded then replace recipe by within limit
                    var total_dayscal = 0;
                    daycals.forEach(element => {
                        total_dayscal += parseInt(element);
                    });

                    // console.log(daycals)
                    // console.log("total daily calories: " + total_dayscal);


                    //If total sum of calories for per day less than to goal daily calorie value
                    if ((total_dayscal - daily_calories_val) < -100) {
                        var breakfast_all_index = [];
                        var lunch_all_index = [];
                        var dinner_all_index = [];
                        var snack_all_index = [];


                        while (daily_calories_val > total_dayscal) {
                            // console.log("total calorie less than daily calorie");

                            //calculate length of array until find new recipe
                            var length_loop = breakfastindex.length;

                            //calculate balance calorie value
                            var balance_cal_val = (daily_calories_val - total_dayscal);

                            //store into temp
                            var temp_daycals = daycals.slice(0, 3);

                            //find position of highest calorie
                            var find_low_cal = Math.min.apply(Math, temp_daycals);
                            var low_cal_recipe_position = daycals.indexOf(find_low_cal)
                            // console.log("low_cal_recipe_position: " + low_cal_recipe_position);


                            //balanced calorie value
                            var expected_cal_val = (daycals[low_cal_recipe_position] + balance_cal_val);
                            // console.log("expected calorie: " + expected_cal_val);


                            //replace recipe in breakfastkeys
                            if (low_cal_recipe_position === 0) {
                                //delete the last element from array
                                breakfastindex.pop();
                                breakfastkeys.pop();


                                breakfaststore.sort((a, b) => b.Calories - a.Calories);
                                var r = 1;
                                //searching new recipe with restrict calorie value & add in array
                                while (breakfastindex.length < length_loop) {
                                    //var r = Math.floor(Math.random() * (breakfaststore.length-1)) + 1;
                                    //if(parseInt(breakfaststore[r].Calories) >= expected_cal_val && parseInt(breakfaststore[r].Calories) <= (expected_cal_val+100))
                                    if (parseInt(breakfaststore[r].Calories) <= (expected_cal_val + 100)) {
                                        breakfastindex.push(r);
                                        breakfastkeys.push(breakfaststore[r].RecipeID)
                                        daycals[low_cal_recipe_position] = parseInt(breakfaststore[r].Calories);
                                    }
                                    else {
                                        //storing all index value for ending the alternate recipe search process
                                        if (breakfast_all_index.indexOf(r) === -1) {
                                            breakfast_all_index.push(r);
                                        }

                                        //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                        if (breakfast_all_index.length === (breakfaststore.length - 1)) {
                                            var breakfast_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=breakfast&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + expected_cal_val + "&recipe_limit=1");

                                            var recipes = breakfast_api.data;

                                            //console.log(recipes+"test")
                                            if (recipes.length === 1) {
                                                breakfastindex.push(0);
                                                breakfastkeys.push((recipes.recipeid[0]).toString());
                                                daycals[low_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                            }
                                            else {
                                                var m = Math.floor(Math.random() * (breakfaststore.length - 1)) + 1;
                                                breakfastindex.push(m);
                                                breakfastkeys.push(breakfaststore[m].RecipeID);

                                                //store as default restrict cal val. so that end this process
                                                daycals[low_cal_recipe_position] = expected_cal_val;
                                            }
                                        }
                                    }
                                    r++;
                                }
                            }


                            //replace recipe in lunchkeys
                            if (low_cal_recipe_position === 1) {
                                //delete the last element from array
                                lunchindex.pop();
                                lunchkeys.pop();

                                lunchstore.sort((a, b) => b.Calories - a.Calories);
                                var r = 1;
                                //searching new recipe with restrict calorie value & add in array
                                while (lunchindex.length < length_loop) {
                                    //var r = Math.floor(Math.random() * (lunchstore.length-1)) + 1;
                                    //if(parseInt(lunchstore[r].Calories) >= expected_cal_val && parseInt(lunchstore[r].Calories) <= (expected_cal_val+100))
                                    if (parseInt(lunchstore[r].Calories) <= (expected_cal_val + 100)) {
                                        lunchindex.push(r);
                                        lunchkeys.push(lunchstore[r].RecipeID);
                                        daycals[low_cal_recipe_position] = parseInt(lunchstore[r].Calories);
                                    }
                                    else {
                                        //storing all index value for ending the alternate recipe search process
                                        if (lunch_all_index.indexOf(r) === -1) {
                                            lunch_all_index.push(r);
                                        }

                                        //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                        if (lunch_all_index.length === (lunchstore.length - 1)) {
                                            var lunch_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=lunch&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + expected_cal_val + "&recipe_limit=1");

                                            var recipes = lunch_api.data;
                                            // console.log(recipes)
                                            if (recipes.length === 1) {
                                                lunchindex.push(0);

                                                lunchkeys.push((recipes.recipeid[0]).toString());
                                                daycals[low_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                            }
                                            else {
                                                var m = Math.floor(Math.random() * (lunchstore.length - 1)) + 1;
                                                lunchindex.push(m);
                                                lunchkeys.push(lunchstore[m].RecipeID);

                                                //store as default restrict cal val. so that end this process
                                                daycals[low_cal_recipe_position] = expected_cal_val;
                                            }
                                        }
                                    }
                                    r++;
                                }
                            }

                            //replace recipe in dinnerkeys
                            if (low_cal_recipe_position === 2) {
                                //delete the last element from array
                                dinnerindex.pop();
                                dinnerkeys.pop();

                                dinnerstore.sort((a, b) => b.Calories - a.Calories);
                                var r = 1;
                                //searching new recipe with restrict calorie value & add in array
                                while (dinnerindex.length < length_loop) {
                                    //var r = Math.floor(Math.random() * (dinnerstore.length-1)) + 1;
                                    //if(parseInt(dinnerstore[r].Calories) >= expected_cal_val && parseInt(dinnerstore[r].Calories) <= (expected_cal_val+100))
                                    if (parseInt(dinnerstore[r].Calories) <= (expected_cal_val + 100)) {
                                        dinnerindex.push(r);
                                        dinnerkeys.push(dinnerstore[r].RecipeID);
                                        daycals[low_cal_recipe_position] = parseInt(dinnerstore[r].Calories);
                                    }
                                    else {
                                        //storing all index value for ending the alternate recipe search process
                                        if (dinner_all_index.indexOf(r) === -1) {
                                            dinner_all_index.push(r);
                                        }

                                        //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                        if (dinner_all_index.length === (dinnerstore.length - 1)) {
                                            var dinner_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=dinner&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + expected_cal_val + "&recipe_limit=1");

                                            var recipes = dinner_api.data;
                                            // console.log(recipes)
                                            if (recipes.length === 1) {
                                                dinnerindex.push(0);

                                                dinnerkeys.push((recipes.recipeid[0]).toString());
                                                daycals[low_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                            }
                                            else {
                                                var m = Math.floor(Math.random() * (dinnerstore.length - 1)) + 1;
                                                dinnerindex.push(m);
                                                dinnerkeys.push(dinnerstore[m].RecipeID);

                                                //store as default restrict cal val. so that end this process
                                                daycals[low_cal_recipe_position] = expected_cal_val;
                                            }
                                        }
                                    }
                                    r++;
                                }
                            }


                            //replace recipe in snackkeys
                            if (low_cal_recipe_position === 3) {
                                //delete the last element from array
                                snackindex.pop();
                                snackkeys.pop();

                                snackstore.sort((a, b) => b.Calories - a.Calories);
                                var r = 1;
                                //searching new recipe with restrict calorie value & add in array
                                while (snackindex.length < length_loop) {
                                    if (parseInt(snackstore[r].Calories) <= (expected_cal_val + 100)) {
                                        snackindex.push(r);
                                        snackkeys.push(snackstore[r].RecipeID);
                                        daycals[low_cal_recipe_position] = parseInt(snackstore[r].Calories);
                                    }
                                    else {
                                        //storing all index value for ending the alternate recipe search process
                                        if (snack_all_index.indexOf(r) === -1) {
                                            snack_all_index.push(r);
                                        }

                                        //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                        if (snack_all_index.length === (snackstore.length - 1)) {
                                            var snack_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=snack&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + expected_cal_val + "&recipe_limit=1");

                                            var recipes = snack_api.data;
                                            // console.log(recipes)
                                            if (recipes.length === 1) {
                                                snackindex.push(0);

                                                snackkeys.push((recipes.recipeid[0]).toString());
                                                daycals[low_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                            }
                                            else {
                                                var m = Math.floor(Math.random() * (snackstore.length - 1)) + 1;
                                                snackindex.push(m);
                                                snackkeys.push(snackstore[m].RecipeID);

                                                //store as default restrict cal val. so that end this process
                                                daycals[low_cal_recipe_position] = expected_cal_val;
                                            }
                                        }
                                    }
                                    r++;
                                }
                            }

                            // console.log("New greater day calorie values: " + daycals);

                            //validate with daily calorie limit if exceeded then replace recipe by within limit
                            var total_dayscal = 0;
                            daycals.forEach(element => {
                                total_dayscal += parseInt(element);
                            });

                            // console.log("new greater total day calories: " + total_dayscal);
                        }
                    }


                    //If total sum of calories for per day exceeded to goal daily calorie value
                    if ((total_dayscal - daily_calories_val) > 150) {
                        var breakfast_all_index = [];
                        var lunch_all_index = [];
                        var dinner_all_index = [];
                        var snack_all_index = [];

                        //while(dailycal < total_dayscal)
                        //{								
                        // console.log("daily calorie limit exceeded!");

                        //calculate length of array until find new recipe
                        length_loop = breakfastindex.length;

                        //find position of highest calorie
                        var find_high_cal = Math.max.apply(Math, daycals);
                        // console.log(daycals);
                        // console.log("high cal in array: " + find_high_cal);
                        var high_cal_recipe_position = daycals.indexOf(find_high_cal)
                        // console.log("high_cal_recipe_position: " + high_cal_recipe_position);

                        //restrict calorie value
                        var sum_of_three_meals_cal = (total_dayscal - find_high_cal);
                        var restrict_cal_val = (daily_calories_val - sum_of_three_meals_cal);
                        // console.log("restrict calorie: " + restrict_cal_val);

                        //replace recipe in breakfastkeys
                        if (high_cal_recipe_position === 0) {
                            //delete the last element from array
                            breakfastindex.pop();
                            breakfastkeys.pop();

                            breakfaststore.sort((a, b) => b.Calories - a.Calories);
                            var r = 1;
                            //searching new recipe with restrict calorie value & add in array
                            while (breakfastindex.length < length_loop) {
                                //var r = Math.floor(Math.random() * (breakfaststore.length-1)) + 1;
                                if (parseInt(breakfaststore[r].Calories) <= restrict_cal_val) {
                                    breakfastindex.push(r);
                                    breakfastkeys.push(breakfaststore[r].RecipeID)
                                    daycals[high_cal_recipe_position] = parseInt(breakfaststore[r].Calories);
                                }
                                else {
                                    //storing all index value for ending the alternate recipe search process
                                    if (breakfast_all_index.indexOf(r) === -1) {
                                        breakfast_all_index.push(r);
                                    }

                                    //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                    if (breakfast_all_index.length === (breakfaststore.length - 1)) {
                                        var breakfast_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=breakfast&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + restrict_cal_val + "&recipe_limit=1");

                                        var recipes = breakfast_api.data;
                                        // console.log(recipes)
                                        if (recipes.length === 1) {
                                            breakfastindex.push(0);

                                            breakfastkeys.push((recipes.recipeid[0]).toString());
                                            daycals[high_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                        }
                                        else {
                                            var m = Math.floor(Math.random() * (breakfaststore.length - 1)) + 1;
                                            breakfastindex.push(m);
                                            breakfastkeys.push(breakfaststore[m].RecipeID);

                                            //store as default restrict cal val. so that end this process
                                            daycals[high_cal_recipe_position] = restrict_cal_val;
                                        }
                                    }
                                }
                                r++;
                            }
                        }


                        //replace recipe in lunchkeys
                        if (high_cal_recipe_position === 1) {
                            //delete the last element from array
                            lunchindex.pop();
                            lunchkeys.pop();

                            lunchstore.sort((a, b) => b.Calories - a.Calories);
                            var r = 1;
                            //searching new recipe with restrict calorie value & add in array
                            while (lunchindex.length < length_loop) {
                                //var r = Math.floor(Math.random() * (lunchstore.length-1)) + 1;
                                if (parseInt(lunchstore[r].Calories) <= restrict_cal_val) {
                                    lunchindex.push(r);
                                    lunchkeys.push(lunchstore[r].RecipeID);
                                    daycals[high_cal_recipe_position] = parseInt(lunchstore[r].Calories);
                                }
                                else {
                                    //storing all index value for ending the alternate recipe search process
                                    if (lunch_all_index.indexOf(r) === -1) {
                                        lunch_all_index.push(r);
                                    }

                                    //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                    if (lunch_all_index.length === (lunchstore.length - 1)) {
                                        var lunch_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=lunch&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + restrict_cal_val + "&recipe_limit=1");

                                        var recipes = lunch_api.data;
                                        // console.log(recipes)
                                        if (recipes.length === 1) {
                                            lunchindex.push(0);

                                            lunchkeys.push((recipes.recipeid[0]).toString());
                                            daycals[high_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                        }
                                        else {
                                            var m = Math.floor(Math.random() * (lunchstore.length - 1)) + 1;
                                            lunchindex.push(m);
                                            lunchkeys.push(lunchstore[m].RecipeID);

                                            //store as default restrict cal val. so that end this process
                                            daycals[high_cal_recipe_position] = restrict_cal_val;
                                        }
                                    }
                                }
                                r++;
                            }
                        }

                        //replace recipe in dinnerkeys
                        if (high_cal_recipe_position === 2) {
                            //delete the last element from array
                            dinnerindex.pop();
                            dinnerkeys.pop();

                            dinnerstore.sort((a, b) => b.Calories - a.Calories);
                            var r = 1;
                            //searching new recipe with restrict calorie value & add in array
                            while (dinnerindex.length < length_loop) {
                                //var r = Math.floor(Math.random() * (dinnerstore.length-1)) + 1;
                                if (parseInt(dinnerstore[r].Calories) <= restrict_cal_val) {
                                    dinnerindex.push(r);
                                    dinnerkeys.push(dinnerstore[r].RecipeID);
                                    daycals[high_cal_recipe_position] = parseInt(dinnerstore[r].Calories);
                                }
                                else {
                                    //storing all index value for ending the alternate recipe search process
                                    if (dinner_all_index.indexOf(r) === -1) {
                                        dinner_all_index.push(r);
                                    }

                                    //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                    if (dinner_all_index.length === (dinnerstore.length - 1)) {

                                        var dinner_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=dinner&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + restrict_cal_val + "&recipe_limit=1");

                                        var recipes = dinner_api.data;
                                        // console.log(recipes)
                                        if (recipes.length === 1) {
                                            dinnerindex.push(0);

                                            dinnerkeys.push((recipes.recipeid[0]).toString());
                                            daycals[high_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                        }
                                        else {
                                            var m = Math.floor(Math.random() * (dinnerstore.length - 1)) + 1;
                                            dinnerindex.push(m);
                                            dinnerkeys.push(dinnerstore[m].RecipeID);

                                            //store as default restrict cal val. so that end this process
                                            daycals[high_cal_recipe_position] = restrict_cal_val;
                                        }
                                    }
                                }
                                r++;
                            }
                        }

                        //replace recipe in snackkeys
                        if (high_cal_recipe_position === 3) {
                            //delete the last element from array
                            snackindex.pop();
                            snackkeys.pop();

                            snackstore.sort((a, b) => b.Calories - a.Calories);
                            var r = 1;
                            //searching new recipe with restrict calorie value & add in array
                            while (snackindex.length < length_loop) {
                                //var r = Math.floor(Math.random() * (snackstore.length-1)) + 1;
                                if (parseInt(snackstore[r].Calories) <= restrict_cal_val) {
                                    snackindex.push(r);
                                    snackkeys.push(snackstore[r].RecipeID);
                                    daycals[high_cal_recipe_position] = parseInt(snackstore[r].Calories);
                                }
                                else {
                                    //storing all index value for ending the alternate recipe search process
                                    if (snack_all_index.indexOf(r) === -1) {
                                        snack_all_index.push(r);
                                    }

                                    //if all recipe values searched and still we don't find any matching recipe then finish the search and leave it as
                                    if (snack_all_index.length === (snackstore.length - 1)) {
                                        var snack_api = await axiosJWT.get(process.env.REACT_APP_WEEK_RECIPE_HOST + "/wp-json/wp/v1/get-ai-recipe?mealtype=snack&inglist=" + ingredients_list + "&unlist=" + unselected_categories_list + "&dailycal=" + restrict_cal_val + "&recipe_limit=1");

                                        var recipes = snack_api.data;
                                        // console.log(recipes)
                                        if (recipes.length === 1) {
                                            snackindex.push(0);

                                            snackkeys.push((recipes.recipeid[0]).toString());
                                            daycals[high_cal_recipe_position] = parseInt(recipes[recipes.recipeid[0]]['calories']);
                                        }
                                        else {
                                            var m = Math.floor(Math.random() * (snackstore.length - 1)) + 1;
                                            snackindex.push(m);
                                            snackkeys.push(snackstore[m].RecipeID);

                                            //store as default restrict cal val. so that end this process
                                            daycals[high_cal_recipe_position] = restrict_cal_val;
                                        }
                                    }
                                }
                                r++;
                            }
                        }

                        // console.log("New lower day calorie values: " + daycals);

                        //validate with daily calorie limit if exceeded then replace recipe by within limit
                        var total_dayscal = 0;
                        daycals.forEach(element => {
                            total_dayscal += parseInt(element);
                        });

                        // console.log("new lower total day calories: " + total_dayscal);
                    }

                }


                /*console.log(breakfastkeys.toString())*/
                var key_response = await axiosJWT.post("/week-diet-plan",
                    {
                        breakfast_keys: breakfastkeys.toString(),
                        lunch_keys: lunchkeys.toString(),
                        dinner_keys: dinnerkeys.toString(),
                        snack_keys: snackkeys.toString()
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (key_response.status === 200)
                {
                    console.log("key generated successfully!");
                    setIngredientError(false);
                    weekmealplan(breakfastkeys, lunchkeys, dinnerkeys, snackkeys, week);
                }
            }
        }
        else {
            // console.log('recipekey exist');
            setIngredientError(false);

            var breakfastkeys = breakfastkeys_exist.split(",");
            var lunchkeys = lunchkeys_exist.split(",");
            var dinnerkeys = dinnerkeys_exist.split(",");
            var snackkeys = snackkeys_exist.split(",");

            weekmealplan(breakfastkeys, lunchkeys, dinnerkeys, snackkeys, week);
        }


        /*  var week_days = [1,2,3,4,5,6,7];
          setweekDays(week_days);*/       

    }

    const weekmealplan = async (breakfastkeys, lunchkeys, dinnerkeys, snackkeys, week) => {

        var recipe_end = (week * 7);
        var recipe_start = (recipe_end - 7);

        var breakfast_plan = [];
        var lunch_plan = [];
        var dinner_plan = [];
        var snack_plan = [];

        var shopping_recipe_list = [];
        var meatshopping = [];
        var dairyshopping = [];
        var vegshopping = [];
        var spiceshopping = [];
        var otheringshopping = [];

        var k;
        var l = 7;

        for (k = recipe_start; k < recipe_end; k++) {


            try
            {
                var bf_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + breakfastkeys[k] + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (bf_response.status === 200) {
                    breakfast_plan.push(bf_response.data);
                    var bf_recipeinfo = bf_response.data.Ingredients;
                }
            }
            catch
            {            
                var response_status = false;

                while (response_status === false) {
                    var key = breakfastkeys[Math.floor(Math.random() * breakfastkeys.length)];

                    try{
                        var response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                        if(response.status === 200)
                        {
                            response_status = true;
                            var bf_recipeinfo = response.data.Ingredients;
                            breakfast_plan.push(response.data);
                        }

                    }catch(err)
                    {
                    console.log(err)
                    }

                   
                }
            }

            if (bf_recipeinfo) {
                //shopping list generate
                var recipeinfo = bf_recipeinfo;

                for (var i = 0; i < recipeinfo.length; ++i) {
                    if (recipeinfo[i]['IngredientInfo'] !== null) {
                        if (recipeinfo[i]['IngredientInfo']['Department'] !== null) {
                            if (recipeinfo[i]['Unit'] === null)
                                recipeinfo[i]['Unit'] = '';

                            if (recipeinfo[i]['IngredientInfo']['Department'] === 'Poultry' || recipeinfo[i]['IngredientInfo']['Department'] === 'Seafood' || recipeinfo[i]['IngredientInfo']['Department'] === 'Meats') {
                                meatshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Dairy' || recipeinfo[i]['IngredientInfo']['Department'] === 'Cheeses') {
                                dairyshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Produce') {
                                vegshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Spices') {
                                spiceshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else {
                                otheringshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                        }
                    }
                }
            }

            try
            {
                var l_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + lunchkeys[k] + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (l_response.status === 200) {
                    lunch_plan.push(l_response.data);
                    var l_recipeinfo = l_response.data.Ingredients;
                }
            }
            catch
            {            
                var response_status = false;

                while (response_status === false) {
                    var key = lunchkeys[Math.floor(Math.random() * lunchkeys.length)];

                    try{

                        var response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                        if(response.status === 200)
                        {
                            response_status = true;
                            var l_recipeinfo = response.data.Ingredients;
                            lunch_plan.push(response.data);
                        }

                    }catch(err)
                    {
                     console.log(err)
                    }

                   
                }
            }

            if (l_recipeinfo) {

                //shopping list generate
                var recipeinfo = l_recipeinfo;

                for (var i = 0; i < recipeinfo.length; ++i) {
                    if (recipeinfo[i]['IngredientInfo'] !== null) {
                        if (recipeinfo[i]['IngredientInfo']['Department'] !== null) {
                            if (recipeinfo[i]['Unit'] === null)
                                recipeinfo[i]['Unit'] = '';

                            if (recipeinfo[i]['IngredientInfo']['Department'] === 'Poultry' || recipeinfo[i]['IngredientInfo']['Department'] === 'Seafood' || recipeinfo[i]['IngredientInfo']['Department'] === 'Meats') {
                                meatshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Dairy' || recipeinfo[i]['IngredientInfo']['Department'] === 'Cheeses') {
                                dairyshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Produce') {
                                vegshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Spices') {
                                spiceshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else {
                                otheringshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                        }
                    }
                }
            }

            try
            {
                var d_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + dinnerkeys[k] + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (d_response.status === 200) {
                    dinner_plan.push(d_response.data);
                    var d_recipeinfo = d_response.data.Ingredients;
                }
            }
            catch
            {            
                var response_status = false;

                while (response_status === false) {
                    var key = dinnerkeys[Math.floor(Math.random() * dinnerkeys.length)];

                    try{

                        var response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                        if(response.status === 200)
                        {
                            var d_recipeinfo = response.data.Ingredients;
                            dinner_plan.push(response.data);
                            response_status = true;
                        }

                    }catch(err)
                    {
                        console.log(err)
                    }

                   
                }
            }

            if (d_recipeinfo) {
                //shopping list generate
                var recipeinfo = d_recipeinfo;

                for (var i = 0; i < recipeinfo.length; ++i) {
                    if (recipeinfo[i]['IngredientInfo'] !== null) {
                        if (recipeinfo[i]['IngredientInfo']['Department'] !== null) {
                            if (recipeinfo[i]['Unit'] === null)
                                recipeinfo[i]['Unit'] = '';

                            if (recipeinfo[i]['IngredientInfo']['Department'] == 'Poultry' || recipeinfo[i]['IngredientInfo']['Department'] === 'Seafood' || recipeinfo[i]['IngredientInfo']['Department'] === 'Meats') {
                                meatshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Dairy' || recipeinfo[i]['IngredientInfo']['Department'] === 'Cheeses') {
                                dairyshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Produce') {
                                vegshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Spices') {
                                spiceshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else {
                                otheringshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                        }
                    }
                }
            }

            try
            {
                var s_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + snackkeys[k] + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (s_response.status === 200) {    
                    snack_plan.push(s_response.data);
                    var s_recipeinfo = s_response.data.Ingredients;
                }
            }
            catch
            {            
                var response_status = false;

                while (response_status === false) {

                    var key = snackkeys[Math.floor(Math.random() * snackkeys.length)];


                    try{

                        const response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                        if(response.status === 200)
                        {
                        response_status = true;
                        var s_recipeinfo = response.data.Ingredients;
                        snack_plan.push(response.data);
                        }

                    }catch(err)
                    {
                    console.log(err)
                    }

                   
                }          
                
                // console.log(s_recipeinfo);
            }

            

            if (s_recipeinfo) {
                //shopping list generate
                var recipeinfo = s_recipeinfo;

                for (var i = 0; i < recipeinfo.length; ++i) {
                    if (recipeinfo[i]['IngredientInfo'] !== null) {
                        if (recipeinfo[i]['IngredientInfo']['Department'] !== null) {
                            if (recipeinfo[i]['Unit'] === null)
                                recipeinfo[i]['Unit'] = '';

                            if (recipeinfo[i]['IngredientInfo']['Department'] === 'Poultry' || recipeinfo[i]['IngredientInfo']['Department'] === 'Seafood' || recipeinfo[i]['IngredientInfo']['Department'] === 'Meats') {
                                meatshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Dairy' || recipeinfo[i]['IngredientInfo']['Department'] === 'Cheeses') {
                                dairyshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Produce') {
                                vegshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else if (recipeinfo[i]['IngredientInfo']['Department'] === 'Spices') {
                                spiceshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                            else {
                                otheringshopping.push({ 'Quantity': recipeinfo[i]['Quantity'], 'DisplayQuantity': recipeinfo[i]['DisplayQuantity'], 'Unit': recipeinfo[i]['Unit'], 'Name': recipeinfo[i]['Name'] });
                            }
                        }
                    }
                }
            }

            shopping_recipe_list.push(breakfastkeys[k]);
            shopping_recipe_list.push(lunchkeys[k]);
            shopping_recipe_list.push(dinnerkeys[k]);
            shopping_recipe_list.push(snackkeys[k]);

        }

        setBreakfastPlan(breakfast_plan);
        setLunchPlan(lunch_plan);
        setDinnerPlan(dinner_plan);
        setSnackPlan(snack_plan);

        // console.log(breakfast_plan)
        // console.log(lunch_plan)
        // console.log(dinner_plan)
        // console.log(snack_plan)

        setshoppingrecipeList(shopping_recipe_list);
        //Sum quantity of each ingredients

        //vegetables shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};

        vegshopping.forEach(function (d) {
            d.Name = ((d.Name).toLowerCase()).trim();
            if (holder.hasOwnProperty(d.Name)) {
                holder[d.Name] = holder[d.Name] + d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            } else {
                holder[d.Name] = d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            }
        });

        var vegshopping_list = [];
        for (var prop in holder) {
            vegshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }

        setvegshoppingList(vegshopping_list);

        //meat shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};

        meatshopping.forEach(function (d) {
            d.Name = ((d.Name).toLowerCase()).trim();
            if (holder.hasOwnProperty(d.Name)) {
                holder[d.Name] = holder[d.Name] + d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            } else {
                holder[d.Name] = d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            }
        });

        var meatshopping_list = [];
        for (var prop in holder) {
            meatshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setmeatshoppingList(meatshopping_list);

        //dairyshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};

        dairyshopping.forEach(function (d) {
            d.Name = ((d.Name).toLowerCase()).trim();
            if (holder.hasOwnProperty(d.Name)) {
                holder[d.Name] = holder[d.Name] + d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            } else {
                holder[d.Name] = d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            }
        });

        var dairyshopping_list = [];
        for (var prop in holder) {
            dairyshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setdairyshoppingList(dairyshopping_list);

        //spiceshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};

        spiceshopping.forEach(function (d) {
            d.Name = ((d.Name).toLowerCase()).trim();
            if (holder.hasOwnProperty(d.Name)) {
                holder[d.Name] = holder[d.Name] + d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            } else {
                holder[d.Name] = d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            }
        });

        var spiceshopping_list = [];
        for (var prop in holder) {
            spiceshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setspiceshoppingList(spiceshopping_list);

        //otheringshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};

        otheringshopping.forEach(function (d) {
            d.Name = ((d.Name).toLowerCase()).trim();
            if (holder.hasOwnProperty(d.Name)) {
                holder[d.Name] = holder[d.Name] + d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            } else {
                holder[d.Name] = d.Quantity;
                unitholder[d.Name] = d.Unit;
                quantityholder[d.Name] = d.DisplayQuantity;
            }
        });

        var otheringshopping_list = [];
        for (var prop in holder) {
            otheringshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setothershoppingList(otheringshopping_list);


        loading_icon('hide');
    }

    const updateIngredients = async (e) => {

        loading_icon('show');

        //console.log(newingList.toString());

        const response = await axiosJWT.put("/week-diet-plan/inputs",
            {
                ingredients: IngList.toString()
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            load_profile('update');
            setingshow(false);
        }
    }

    const add_ing = async (e) => {
        if (e.target.checked) {
            var ing_data = e.target.value;

            if (ing_data === 'No-Meat') {
                var other_options = ['Chicken', 'Pork', 'Beef', 'Fish', 'Bacon'];

                other_options.forEach(element => {
                    if (IngList.indexOf(element) > -1)
                        IngList.splice(IngList.indexOf(element), 1);
                });

                //IngList.push('No-Meat');
                //setIngList(IngList);

                setIngList([...IngList, ing_data]);

                //console.log(IngList)
            }
            else {
                if (!IngList.some((val) => ing_data === val))
                    setIngList([...IngList, ing_data]);
            }
        }
        else {
            var ing_data = e.target.value;
            setIngList(IngList.filter((val) => val !== ing_data));
        }
    }

    const showRecipe = async (e) => {

        setrecipetab('ingredients');

        const recipeId = e.target.getAttribute("data-item");

        try{

           await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {



                setrecipeShow(true);
                setrecipeData(res.data);
                SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)])
                SetRecipeProtein(res.data.NutritionInfo.Protein)
                SetRecipeFat(res.data.NutritionInfo.TotalFat)
                SetRecipeCarbs(res.data.NutritionInfo.TotalCarbs)
                SetRecipeCalories(res.data.NutritionInfo.TotalCalories)
                //console.log(res.data);
            });

        }catch(err)
        {
            console.log(err)
        }

     
    }

    const changeRecipeTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setrecipetab(tab_for);
    }

    const actionFavorites = async (e) => {
        var recipe_id = e.target.getAttribute("data-item");
        var action = e.target.getAttribute("data-action");

        if (action === 'remove') {
            e.target.classList.remove('fav');
            e.target.setAttribute("data-action", "add");

            var response = await axiosJWT.put("/week-diet-plan/favorites",
                {
                    recipeId: recipe_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            /* if(response.status === 200)
             setfavoriteKeys([...favoriteKeys,recipe_id]);*/
        }

        if (action === 'add') {
            e.target.classList.add('fav');
            e.target.setAttribute("data-action", "remove");

            var response = await axiosJWT.post("/week-diet-plan/favorites",
                {
                    recipeId: recipe_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            /*if(response.status === 200)
            setfavoriteKeys([...favoriteKeys,recipe_id]);*/
        }
    }


    const swapRecipe = async (e) => {
        var day_index = parseInt(e.target.getAttribute("data-day"));
        var food_menu = e.target.getAttribute("data-menu");
        //var recipe_id = e.target.getAttribute("data-item");

        setswapshow(true);
        setswapTab('library');
        setswaprecipeslist('');
        setswapdayIndex(day_index);
        setswapfoodMenu(food_menu);
    }

    const changeswapTab = async (e) => {

        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".swaptabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setswapTab(tab_for);

        if (tab_for === 'favorites')
            load_favorites();

        /*if(tab_for === 'library')
        {
            setswaprecipeslist('');
        }*/
    }

    const searchRecipes = async (pagenumber) => {

        if (searchLibrary !== '') {
            // console.log(pagenumber + "test")
            setswapTab('library');

            //check if page number is passed else assign it
            if (pagenumber) {
                pagenumber = pagenumber;
                setactivepage(pagenumber);
            }
            else {
                pagenumber = 1;
                setactivepage(1);
            }

            var recipe_list = [];

            try{

               await  axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipes?any_kw=" + searchLibrary + " keto&photos=true&rpp=" + countPerPage + "&pg=" + pagenumber + "&api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then((response) => {

                    if (response.data.ResultCount === 0) {
                        setswapsearchFound(false);
                    }
                    else {
                        setswapsearchFound(true);
    
                        settotalCount(response.data.ResultCount);
    
                        var recipedata = response.data.Results;
    
                        recipedata.forEach(async(element, index, array) => {

                            try{

                              await  axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + element.RecipeID + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
    
                                    if (res.data.NutritionInfo.TotalCalories > 100) {
                                        recipe_list.push(res.data);
                                    }
        
                                    if (index === array.length - 1)
                                        setTimeout(function () { setswaprecipeslist(recipe_list) }, 100);
                                });

                            }catch(err)
                            {
                                console.log(err)
                            }
    
                          
    
                        });
                    }
    
                });

            }catch(err)
            {
                console.log(err)
            }

      
        }
        else {
            setswaprecipeslist('');
        }

        setswapTab('library');
    }

    const load_favorites = async (pagenumber) => {

        loading_icon('show');

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setfavactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage)
            var ending_limit = starting_limit + countPerPage
        }
        else {
            var starting_limit = 0;
            var ending_limit = starting_limit + countPerPage
        }

        var response = await axiosJWT.get("/week-diet-plan/favorites", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response) {
            var recipe_list = [];

            if(response.data.favorites)
            {
                var favorites_key = (response.data.favorites).split(",");

                //console.log(favorites_key.length)
                setfavtotalCount(favorites_key.length)

                var favorites_key_sub = favorites_key.slice(starting_limit, ending_limit);

                await favorites_key_sub.forEach(async(element, index, array) => {


                    try{

                       await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + element + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                            recipe_list.push(res.data);
    
                            if (index === array.length - 1)
                                setTimeout(function () { setswapfavrecipeslist(recipe_list) }, 100);
                        });

                    }catch(err)
                    {
                        console.log(err)
                    }
                   

                });

                loading_icon('hide');
            }
            else
            {
                loading_icon('hide');
            }

            

        }

    }

    const changePageNumber = async (pagenumber) => {

        if (swapTab === 'library') {
            searchRecipes(pagenumber);
            setactivepage(pagenumber);
        }

        if (swapTab === 'favorites') {
            load_favorites(pagenumber);
            setfavactivepage(pagenumber);
        }

    }

    const selectRecipe = async (e) => {

        const alltabs = document.querySelectorAll(".recipelist_box li");
        alltabs.forEach((element) => element.classList.remove('selected'));

        e.currentTarget.classList.add('selected');

        var recipe_id = e.currentTarget.getAttribute("data-item");

        // console.log(recipe_id);

        setswapRecipeId(recipe_id);
    }

    const changeRecipe = async (e) => {

        if (swapfoodMenu !== '' && swapRecipeId !== '' && swapdayIndex !== '') {
            var response = await axiosJWT.put("/week-diet-plan/swap",
                {
                    food_menu: swapfoodMenu,
                    recipe_id: swapRecipeId,
                    day_index: swapdayIndex
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                setswapshow(false);
                load_data();
            }
        }
    }

    /* const downloadshopping = async () => {
        var meatshopping = [];
        var dairyshopping = [];
        var vegshopping = [];
        var spiceshopping = [];
        var otheringshopping = [];    
    
        for(var k=0;k<shoppingrecipeList.length;k++) {      
        
            const response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST+"/recipe/steps/"+shoppingrecipeList[k]+"?api_key="+process.env.REACT_APP_RECIPE_PROVIDER_KEY);
          
            //console.log(response.data)
            var recipeinfo = response.data.Ingredients;
    
            for (var i = 0; i < recipeinfo.length; ++i) 
            {
                    if(recipeinfo[i]['IngredientInfo'] != null)
                    {
                        if(recipeinfo[i]['IngredientInfo']['Department'] != null)	
                        {								
                            if(recipeinfo[i]['Unit'] == null)
                            recipeinfo[i]['Unit'] = '';
    
                            if(recipeinfo[i]['IngredientInfo']['Department'] == 'Poultry' || recipeinfo[i]['IngredientInfo']['Department'] == 'Seafood' || recipeinfo[i]['IngredientInfo']['Department'] == 'Meats')	
                            {
                                meatshopping.push({'Quantity':recipeinfo[i]['Quantity'],'DisplayQuantity':recipeinfo[i]['DisplayQuantity'],'Unit':recipeinfo[i]['Unit'],'Name':recipeinfo[i]['Name']});
                            }
                            else if(recipeinfo[i]['IngredientInfo']['Department'] == 'Dairy' || recipeinfo[i]['IngredientInfo']['Department'] == 'Cheeses')
                            {
                                dairyshopping.push({'Quantity':recipeinfo[i]['Quantity'],'DisplayQuantity':recipeinfo[i]['DisplayQuantity'],'Unit':recipeinfo[i]['Unit'],'Name':recipeinfo[i]['Name']});
                            }
                            else if(recipeinfo[i]['IngredientInfo']['Department'] == 'Produce')
                            {
                                vegshopping.push({'Quantity':recipeinfo[i]['Quantity'],'DisplayQuantity':recipeinfo[i]['DisplayQuantity'],'Unit':recipeinfo[i]['Unit'],'Name':recipeinfo[i]['Name']});
                            }
                            else if(recipeinfo[i]['IngredientInfo']['Department'] == 'Spices')
                            {
                                spiceshopping.push({'Quantity':recipeinfo[i]['Quantity'],'DisplayQuantity':recipeinfo[i]['DisplayQuantity'],'Unit':recipeinfo[i]['Unit'],'Name':recipeinfo[i]['Name']});
                            }
                            else
                            {
                                otheringshopping.push({'Quantity':recipeinfo[i]['Quantity'],'DisplayQuantity':recipeinfo[i]['DisplayQuantity'],'Unit':recipeinfo[i]['Unit'],'Name':recipeinfo[i]['Name']});
                            }	
                        }
                    }
            }
        }
    
        //Sum quantity of each ingredients
    
        //vegetables shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};
    
        vegshopping.forEach(function(d) {
            d.Name = ((d.Name).toLowerCase()).trim();
        if (holder.hasOwnProperty(d.Name)) {
            holder[d.Name] = holder[d.Name] + d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        } else {
            holder[d.Name] = d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        }
        });
    
        var vegshopping_list = [];
        for (var prop in holder) {
            vegshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
    
        setvegshoppingList(vegshopping_list); 
    
        //meat shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};
    
        meatshopping.forEach(function(d) {
            d.Name = ((d.Name).toLowerCase()).trim();
        if (holder.hasOwnProperty(d.Name)) {
            holder[d.Name] = holder[d.Name] + d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        } else {
            holder[d.Name] = d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        }
        });
    
        var meatshopping_list = [];
        for (var prop in holder) {
            meatshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }  
        setmeatshoppingList(meatshopping_list);
    
        //dairyshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};
    
        dairyshopping.forEach(function(d) {
            d.Name = ((d.Name).toLowerCase()).trim();
        if (holder.hasOwnProperty(d.Name)) {
            holder[d.Name] = holder[d.Name] + d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        } else {
            holder[d.Name] = d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        }
        });
    
        var dairyshopping_list = [];
        for (var prop in holder) {
            dairyshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setdairyshoppingList(dairyshopping_list);
    
        //spiceshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};
    
        spiceshopping.forEach(function(d) {
            d.Name = ((d.Name).toLowerCase()).trim();
        if (holder.hasOwnProperty(d.Name)) {
            holder[d.Name] = holder[d.Name] + d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        } else {
            holder[d.Name] = d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        }
        });
    
        var spiceshopping_list = [];
        for (var prop in holder) {
            spiceshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setspiceshoppingList(spiceshopping_list);
    
        //otheringshopping shopping
        var holder = {};
        var unitholder = {};
        var quantityholder = {};
    
        otheringshopping.forEach(function(d) {
            d.Name = ((d.Name).toLowerCase()).trim();
        if (holder.hasOwnProperty(d.Name)) {
            holder[d.Name] = holder[d.Name] + d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        } else {
            holder[d.Name] = d.Quantity;
            unitholder[d.Name] = d.Unit;
            quantityholder[d.Name] = d.DisplayQuantity;
        }
        });
    
        var otheringshopping_list = [];
        for (var prop in holder) {
            otheringshopping_list.push({ Name: prop, Quantity: holder[prop], DisplayQuantity: quantityholder[prop], Unit: unitholder[prop] });
        }
        setothershoppingList(otheringshopping_list); 
    }*/

    /*const handlePrint = useReactToPrint({
        content: () => shoppingPrint.current,
      });*/

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    const changescaleType = (data) => {
        // console.log(data)
        setscaleType(data.value);
    }

    return (
        <div className='dietplan_tab'>

            <div id="semiTransparenDivLoader"></div>

            {profileData ?
                <div>

                    <div className='topcontent'>
                        
                        <h5>My Info </h5> {showinputError === true ? 
                            (<div className='headerError'>*Please update all the required input fields!</div>)
                            : null
                        }
                        <div className='user_info' data-item={profileData.id}>
                            <div className='header_box'>
                                <div className='input_fields'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='inner_label required'>Age<i className="fa fa-pencil td_icon action" data-item="Age" onClick={handleshow}></i></label><br />
                                            {profileData.user_dob !== null && profileData.user_dob !== '' && profileData.user_dob != "0000-00-00"?
                                            <div className='input_value'> {(format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy'))} Years</div>
                                            : null }
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label required'>Height<i className="fa fa-pencil td_icon action" data-item="Height" onClick={handleshow}></i></label><br />
                                            <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label required'>Weight<i className="fa fa-pencil td_icon action" data-item="Weight" onClick={handleshow}></i></label><br />
                                            <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label required'>Goal Weight<i className="fa fa-pencil td_icon action" data-item="Goal Weight" onClick={handleshow}></i></label><br />
                                            <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='input_fields'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='inner_label required'>Activity Level<i className="fa fa-pencil td_icon action" data-item="Activity Level" onClick={handleshow}></i></label><br />
                                            <div className='input_value'>{profileData.client_infos[0].activity}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Daily Calories<i className="fa fa-pencil td_icon action" data-item="Daily Calories" onClick={handleshow}></i></label><br />
                                            <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Water Intake</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                                        </div>
                                        <div className='col'>
                                            <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                            <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_fat}-{profileData.client_infos[0].daily_carb}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div style={{ "clear": "both" }} />
                    </div>

                </div>
                :
                null
            }

            {IngredientError === true ?
            <div className='ing_more_Error'> Sorry.! The number of recipes for the chosen ingredients is insufficient to create a meal plan. Add additional <a onClick={(e) => { handleFilters(e) }} data-item="change_ingredients" style={{color:"#226920",textDecoration:"underline"}}>ingredients.</a></div>
            : null }

            {showinputError === false ?
            <div className='plan_filter_box'>
                <div className='row'>
                    <div className="col"><h6 style={{ "paddingTop": "7px" }}>Your Custom Meal Plan</h6></div>
                    <div className="col">
                        <Select
                            defaultValue={{ label: 'Week 1', value: 1 }}
                            options={weekOptions}
                            onChange={(e) => load_recipes(e.value, '')}
                            className="weekselect"
                        />
                    </div>

                    <div className="col">
                        <input className='greenbutton' type="button" value="Change Ingredients" onClick={(e) => { handleFilters(e) }} data-item="change_ingredients" />
                    </div>

                    <div className="col">
                        <ReactToPrint
                            trigger={() => <input className='greenbutton' type="button" value="Print My Meal Plan" />}
                            content={() => mealplanPrint.current}
                        />
                    </div>

                    <div className="col">
                        <ReactToPrint
                            trigger={() => <input className='greenbutton' type="button" value="Download Shopping List" />}
                            content={() => shoppingPrint.current}
                        />
                    </div>

                </div>
            </div>
            : null 
            }   


            <div className='week_plan_recipes' ref={mealplanPrint}>
                {SnackPlan.length > 6 ?
                    <div>
                    <div className='recipes desktop'>
                        <div className='row'>
                            <div className='col header dayheader'></div>
                            <div className='col header'>
                                Breakfast
                            </div>
                            <div className='col header'>
                                Lunch
                            </div>
                            <div className='col header'>
                                Dinner
                            </div>
                            <div className='col header'>
                                Snack
                            </div>
                        </div>
                        {(weekDays.map((element, index) =>
                            <div className='row' key={index}>
                                <div className='col daycol'>
                                    {"Day " + (index + 1)}
                                </div>
                                <div className='col'>
                                    <div className='recipe_box'>
                                        <i className='fa fa-ellipsis-v viewdata' data-item={BreakfastPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                        <i className={favoriteKeys ? favoriteKeys.includes(BreakfastPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(BreakfastPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={BreakfastPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                        <div className='img'><img src={BreakfastPlan[index].PhotoUrl} data-item={BreakfastPlan[index].RecipeID} /></div>

                                        <div className='title'>{BreakfastPlan[index].Title}</div>

                                        <div className='kcal'>kcal: {BreakfastPlan[index].NutritionInfo.TotalCalories}</div>
                                        <div className='swaprecipe'><a href data-day={index} data-item={BreakfastPlan[index].RecipeID} data-menu="breakfast" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='recipe_box'>
                                        <i className='fa fa-ellipsis-v viewdata' data-item={LunchPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                        <i className={favoriteKeys ? favoriteKeys.includes(LunchPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(LunchPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={LunchPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                        <div className='img'><img src={LunchPlan[index].PhotoUrl} data-item={LunchPlan[index].RecipeID} /></div>

                                        <div className='title'>{LunchPlan[index].Title}</div>

                                        <div className='kcal'>kcal: {LunchPlan[index].NutritionInfo.TotalCalories}</div>
                                        <div className='swaprecipe'><a href data-day={index} data-item={LunchPlan[index].RecipeID} data-menu="lunch" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='recipe_box'>
                                        <i className='fa fa-ellipsis-v viewdata' data-item={DinnerPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                        <i className={favoriteKeys ? favoriteKeys.includes(DinnerPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(DinnerPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={DinnerPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                        <div className='img'><img src={DinnerPlan[index].PhotoUrl} data-item={DinnerPlan[index].RecipeID} /></div>

                                        <div className='title'>{DinnerPlan[index].Title}</div>

                                        <div className='kcal'>kcal: {DinnerPlan[index].NutritionInfo.TotalCalories}</div>
                                        <div className='swaprecipe'><a href data-day={index} data-item={DinnerPlan[index].RecipeID} data-menu="dinner" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='recipe_box'>
                                        <i className='fa fa-ellipsis-v viewdata' data-item={SnackPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                        <i className={favoriteKeys ? favoriteKeys.includes(SnackPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(SnackPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={SnackPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                        <div className='img'><img src={SnackPlan[index].PhotoUrl} data-item={SnackPlan[index].RecipeID} /></div>

                                        <div className='title'>{SnackPlan[index].Title}</div>

                                        <div className='kcal'>kcal: {SnackPlan[index].NutritionInfo.TotalCalories}</div>
                                        <div className='swaprecipe'><a href data-day={index} data-item={SnackPlan[index].RecipeID} data-menu="snack" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                    </div>
                                </div>
                            </div>
                        ))}                        
                    </div>
                        <Table className="recipes mob_recipes">
                        <Thead>
                        <Tr>
                            <Th>Day</Th>
                            <Th>Breakfast</Th>
                            <Th>Lunch</Th>
                            <Th>Dinner</Th>
                            <Th>Snack</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                            {(weekDays.map((element, index) =>
                                <Tr key={index}>
                                    <Td>
                                        {"Day " + (index + 1)}
                                    </Td>
                                    <Td>         
                                    <div className='recipe_box'>                           
                                            <i className='fa fa-ellipsis-v viewdata' data-item={BreakfastPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                            <i className={favoriteKeys ? favoriteKeys.includes(BreakfastPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(BreakfastPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={BreakfastPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                            <div className='img'><img src={BreakfastPlan[index].PhotoUrl} data-item={BreakfastPlan[index].RecipeID} /></div>

                                            <div className='title'>{BreakfastPlan[index].Title}</div>

                                            <div className='kcal'>kcal: {BreakfastPlan[index].NutritionInfo.TotalCalories}</div>
                                            <div className='swaprecipe'><a href data-day={index} data-item={BreakfastPlan[index].RecipeID} data-menu="breakfast" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                        </div>
                                    </Td>
                                    <Td>                    
                                    <div className='recipe_box'>                
                                            <i className='fa fa-ellipsis-v viewdata' data-item={LunchPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <i className={favoriteKeys ? favoriteKeys.includes(LunchPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(LunchPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={LunchPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                            <div className='img'><img src={LunchPlan[index].PhotoUrl} data-item={LunchPlan[index].RecipeID} /></div>

                                            <div className='title'>{LunchPlan[index].Title}</div>

                                            <div className='kcal'>kcal: {LunchPlan[index].NutritionInfo.TotalCalories}</div>
                                            <div className='swaprecipe'><a href data-day={index} data-item={LunchPlan[index].RecipeID} data-menu="lunch" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                            </div>
                                    </Td>
                                    <Td>                   
                                    <div className='recipe_box'>                 
                                            <i className='fa fa-ellipsis-v viewdata' data-item={DinnerPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <i className={favoriteKeys ? favoriteKeys.includes(DinnerPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(DinnerPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={DinnerPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                            <div className='img'><img src={DinnerPlan[index].PhotoUrl} data-item={DinnerPlan[index].RecipeID} /></div>

                                            <div className='title'>{DinnerPlan[index].Title}</div>

                                            <div className='kcal'>kcal: {DinnerPlan[index].NutritionInfo.TotalCalories}</div>
                                            <div className='swaprecipe'><a href data-day={index} data-item={DinnerPlan[index].RecipeID} data-menu="dinner" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                            </div>
                                    </Td>
                                    <Td>
                                    <div className='recipe_box'>
                                            <i className='fa fa-ellipsis-v viewdata' data-item={SnackPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>
                                            <i className={favoriteKeys ? favoriteKeys.includes(SnackPlan[index].RecipeID.toString()) ? "fa fa-heart viewheart fav" : "fa fa-heart viewheart" : "fa fa-heart viewheart"} data-action={favoriteKeys ? favoriteKeys.includes(SnackPlan[index].RecipeID.toString()) ? "remove" : "add" : "add"} data-item={SnackPlan[index].RecipeID} onClick={(e) => actionFavorites(e)}></i>
                                            <div className='img'><img src={SnackPlan[index].PhotoUrl} data-item={SnackPlan[index].RecipeID} /></div>

                                            <div className='title'>{SnackPlan[index].Title}</div>

                                            <div className='kcal'>kcal: {SnackPlan[index].NutritionInfo.TotalCalories}</div>
                                            <div className='swaprecipe'><a href data-day={index} data-item={SnackPlan[index].RecipeID} data-menu="snack" onClick={(e) => swapRecipe(e)}>Swap This Recipe</a></div>
                                            </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    </div>
                    :
                    null
                }
            </div>

            <Modal show={ingshow} onHide={handleingClose} className='client_weekplan_ing_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Change Ingredients</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="changeingredients">
                        <div className="meatcheckbox">
                            <span className="ingtitle">Meat:</span>
                            <div id="checkboxes"><input type="checkbox" value="Chicken" onChange={add_ing} defaultChecked={IngList.indexOf("Chicken") > -1 ? true : false} disabled={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;Chicken</div>
                            <div id="checkboxes"><input type="checkbox" value="Pork" onChange={add_ing} defaultChecked={IngList.indexOf("Pork") > -1 ? true : false} disabled={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;Pork</div>
                            <div id="checkboxes"><input type="checkbox" value="Beef" onChange={add_ing} defaultChecked={IngList.indexOf("Beef") > -1 ? true : false} disabled={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;Beef</div>
                            <div id="checkboxes"><input type="checkbox" value="Fish" onChange={add_ing} defaultChecked={IngList.indexOf("Fish") > -1 ? true : false} disabled={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;Fish</div>
                            <div id="checkboxes"><input type="checkbox" value="Bacon" onChange={add_ing} defaultChecked={IngList.indexOf("Bacon") > -1 ? true : false} disabled={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;Bacon</div>
                            <div id="checkboxes"><input type="checkbox" value="No-Meat" onChange={add_ing} defaultChecked={IngList.indexOf("No-Meat") > -1 ? true : false} />&nbsp;No Meat</div>
                        </div>
                        <div className="vegcheckbox">
                            <span className="ingtitle">Veggies:</span>
                            <div id="checkboxes"><input type="checkbox" value="Broccoli" onChange={add_ing} defaultChecked={IngList.indexOf("Broccoli") > -1 ? true : false} />&nbsp;Broccoli</div>
                            <div id="checkboxes"><input type="checkbox" value="Mushroom" onChange={add_ing} defaultChecked={IngList.indexOf("Mushroom") > -1 ? true : false} />&nbsp;Mushroom</div>
                            <div id="checkboxes"><input type="checkbox" value="Zucchini" onChange={add_ing} defaultChecked={IngList.indexOf("Zucchini") > -1 ? true : false} />&nbsp;Zucchini</div>
                            <div id="checkboxes"><input type="checkbox" value="Cauliflower" onChange={add_ing} defaultChecked={IngList.indexOf("Cauliflower") > -1 ? true : false} />&nbsp;Cauliflower</div>
                            <div id="checkboxes"><input type="checkbox" value="Asparagus" onChange={add_ing} defaultChecked={IngList.indexOf("Asparagus") > -1 ? true : false} />&nbsp;Asparagus</div>
                            <div id="checkboxes"><input type="checkbox" value="Avocad" onChange={add_ing} defaultChecked={IngList.indexOf("Avocad") > -1 ? true : false} />&nbsp;Avocad</div>
                        </div>
                        <div className="otherscheckbox">
                            <span className="ingtitle">Other Food:</span>
                            <div id="checkboxes"><input type="checkbox" value="Egg" onChange={add_ing} defaultChecked={IngList.indexOf("Egg") > -1 ? true : false} />&nbsp;Egg</div>
                            <div id="checkboxes"><input type="checkbox" value="Nuts" onChange={add_ing} defaultChecked={IngList.indexOf("Nuts") > -1 ? true : false} />&nbsp;Nuts</div>
                            <div id="checkboxes"><input type="checkbox" value="Cheese" onChange={add_ing} defaultChecked={IngList.indexOf("Cheese") > -1 ? true : false} />&nbsp;Cheese</div>
                            <div id="checkboxes"><input type="checkbox" value="Cottage-Cheese" onChange={add_ing} defaultChecked={IngList.indexOf("Cottage-Cheese") > -1 ? true : false} />&nbsp;Cottage Cheese</div>
                            <div id="checkboxes"><input type="checkbox" value="Butter" onChange={add_ing} defaultChecked={IngList.indexOf("Butter") > -1 ? true : false} />&nbsp;Butter</div>
                            <div id="checkboxes"><input type="checkbox" value="Coconut" onChange={add_ing} defaultChecked={IngList.indexOf("Coconut") > -1 ? true : false} />&nbsp;Coconut</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleingClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button' onClick={(e) => { updateIngredients(e) }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} className='client_weekplan_inputs_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>{inputField}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {inputField === 'Age' ?
                        <div>
                            <label className='inner_popup_label required' style={{marginRight:"20px"}}>Date of Birth:</label>
                            
                            <Datepicker 
                            value={clientDob}
                            onChange={setclientDob}
                            format="MM-dd-yyyy"
                            clearIcon="" />
                        </div>
                        :null
                    }
                    {inputField === 'Height' ?
                        <div>
                            <label className='inner_popup_label required'>Scale Type:</label><br />
                            <Select 
                            options={scaleoptions}
                            defaultValue={{ label: scaleType.charAt(0).toUpperCase()+scaleType.slice(1), value: scaleType }}
                            onChange={changescaleType}
                            />
                            {(scaleType).toLowerCase() === 'imperial' &&
                                <div>
                                    <label className='inner_popup_label required'>Ft:</label><br /><input type='text' className='inner_popup_input' defaultValue={feetVal} onChange={(e) => { setfeetVal(e.target.value) }}></input>
                                    <label className='inner_popup_label'>Inch:</label><br /><input type='text' className='inner_popup_input' defaultValue={inchVal} onChange={(e) => { setinchVal(e.target.value) }}></input>
                                </div>}
                            {(scaleType).toLowerCase() === 'metric' &&
                                <div>
                                    <label className='inner_popup_label required'>Cm:</label><br /><input type='text' className='inner_popup_input' defaultValue={heightVal} onChange={(e) => { setheightVal(e.target.value) }}></input>
                                    </div>}
                        </div>
                    :null
                    }

                    {inputField === 'Weight' ?
                        <div>
                            <label className='inner_popup_label required'>{(scaleType).toLowerCase() === 'imperial' ? "Lbs:" : "Kg:"}</label><br /><input type='number' className='inner_popup_input' defaultValue={weightVal} onChange={(e) => { setweightVal(e.target.value) }}></input>
                        </div>
                        :
                        null
                    }

                    {inputField === 'Goal Weight' ?
                        <div>
                            <label className='inner_popup_label required'>{(scaleType).toLowerCase() === 'imperial' ? "Lbs:" : "Kg:"}</label><br /><input type='number' className='inner_popup_input' defaultValue={goalweightVal} onChange={(e) => { setgoalweightVal(e.target.value) }}></input>
                        </div>
                        :
                        null
                    }

                    {inputField === 'Activity Level' ?
                        <div>
                            <Select
                                defaultValue={activityVal}
                                options={activityoptions}
                                onChange={setactivityVal}
                            />
                        </div>
                        :
                        null
                    }

                    {inputField === 'Daily Calories' ?
                        <div>
                            <label className='inner_popup_label required'>Kcal:</label><br /><input type='text' className='inner_popup_input' defaultValue={dailycalVal} onChange={(e) => { setdailycalVal(e.target.value) }}></input></div>
                        :
                        null
                    }
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button' data-item={inputField} onClick={(e) => { updateInputs(e) }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={swapshow} onHide={handleswapClose} className='client_weekplan_swap_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Swap Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='swap_box'>
                        <div className="search_box">
                            Search food <input type="text" onChange={(e) => setsearchLibrary(e.target.value)} />
                            <input type="button" value="Search" onClick={() => searchRecipes(1)} />
                        </div>
                        <div className='menubox'>
                            <div className='swaptabmenu row'>
                                <div className='col'>
                                    <a className="active" onClick={changeswapTab} data-item="library">Library</a>
                                </div>
                                <div className='col'>
                                    <a onClick={changeswapTab} data-item="favorites">Favorites</a>
                                </div>
                            </div>
                        </div>
                        <div style={{ "clear": "both" }} />
                        {swapTab === 'library' ?
                            <div className="searchresult_box">
                                <h6>Search Results</h6>
                                {swapsearchFound === false ?
                                    <div id="notfound">Sorry! No matching found. Try again!</div>
                                    : null
                                }
                                <ul className="recipelist_box">
                                    {swaprecipeslist ?
                                        <div>
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={countPerPage}
                                                totalItemsCount={totalCount}
                                                onChange={changePageNumber.bind(this)}
                                                prevPageText="<"
                                                nextPageText=">"
                                                hideFirstLastPages={true}
                                                linkClassPrev="pagination_prev"
                                                linkClassNext="pagination_next"
                                                linkClass="pagenumbers grayfont"
                                            />
                                            {swaprecipeslist.map((recipe, index) => (
                                                <li className='recipe' key={recipe.RecipeID} data-item={recipe.RecipeID} onClick={(e) => selectRecipe(e)}>
                                                    <div className='img'><img src={recipe.PhotoUrl} alt={recipe.Title} /></div>
                                                    <div className='title'>{recipe.Title}</div>
                                                    <div className='kcal'>kcal: {recipe.NutritionInfo.TotalCalories}</div>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                    }
                                </ul>
                            </div>
                            :
                            null
                        }
                        {swapTab === 'favorites' ?
                            <div id="favorites_box">
                                <h6>Your Favorite Recipes</h6>
                                <ul className="recipelist_box">
                                    {swapfavrecipeslist ?
                                        <div>
                                            <Pagination
                                                activePage={favactivePage}
                                                itemsCountPerPage={countPerPage}
                                                totalItemsCount={favtotalCount}
                                                onChange={changePageNumber.bind(this)}
                                                prevPageText="<"
                                                nextPageText=">"
                                                hideFirstLastPages={true}
                                                linkClassPrev="pagination_prev"
                                                linkClassNext="pagination_next"
                                                linkClass="pagenumbers grayfont"
                                            />
                                            {swapfavrecipeslist.map((recipe, index) => (
                                                <li className='recipe' key={recipe.RecipeID} data-item={recipe.RecipeID} onClick={(e) => selectRecipe(e)}>
                                                    <div className='img'><img src={recipe.PhotoUrl} alt={recipe.Title} /></div>
                                                    <div className='title'>{recipe.Title}</div>
                                                    <div className='kcal'>kcal: {recipe.NutritionInfo.TotalCalories}</div>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                    }
                                </ul>
                            </div>
                            :
                            null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='modal_close_button' onClick={handleswapClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" className='modal_action_button float_right' onClick={(e) => { changeRecipe(e) }}>
                        Swap Recipe
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={recipeShow} onHide={handlerecipeClose} className='coach_plan_recipe_modal_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Recipe Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipeData ?
                        <div className='recipe_data'>
                            <div className='title'>{recipeData.Title}</div>
                            <div className='img'><img src={recipeData.PhotoUrl} /></div>
                            <div className='nutrition_data'>

                                <p className='pie_chart_kcal_data'>Calories: {RecipeCalories.toFixed(2)} Kcal</p>
                                <Pie data={data} options={options} />

                                <hr />
                                <div className="pie_chart_bottom_div">
                                    <span>
                                        <span className="dotcarbs"></span>
                                        <span className="pie_chart_bottom_div_label">Carbs</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeCarbs.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotfat"></span>
                                        <span className="pie_chart_bottom_div_label">Fat</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeFat.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    <span>
                                        <span className="dotprotein"></span>
                                        <span className="pie_chart_bottom_div_label">Protein</span>
                                        <span className="pie_chart_bottom_div_data"><span>{RecipeProtein.toFixed(2)}</span><span>G</span></span>
                                    </span>
                                    {/* <span>
<span className="dotcalories"></span>
<span className="pie_chart_bottom_div_label">Calories</span>
<span className="pie_chart_bottom_div_data"><span>{RecipeCalories.toFixed(2)}</span><span>G</span></span>
</span> */}
                                </div>

                                {/* <div className='cal'>Calories:<br/>{(recipeData.NutritionInfo.TotalCalories).toFixed(2)}</div>
    <div className='carb'>Carbs:<br/>{(recipeData.NutritionInfo.TotalCarbs).toFixed(2)} g</div>
    <div className='fat'>Fat:<br/>{(recipeData.NutritionInfo.TotalFat).toFixed(2)} g</div>
    <div className='pro'>Protein:<br/>{(recipeData.NutritionInfo.Protein).toFixed(2)} g</div> */}
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            <div className='menubox'>
                                <div className='recipetabmenu'>
                                    <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                    <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                </div>
                            </div>
                            <div style={{ "clear": "both", "marginBottom": "20px" }} />
                            {recipetab === 'ingredients' ?
                                <div className='ingredients'>
                                    <ul>
                                        {(recipeData.Ingredients).map((ing, index) => (
                                            <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                        ))}
                                    </ul>
                                </div> : null
                            }
                            {recipetab === 'steps' ?
                                <div className='steps'>
                                    <ol>
                                        {(recipeData.Steps).map((ing, index) => (
                                            <li key={index}>{ing.Text}</li>
                                        ))}
                                    </ol>
                                </div> : null
                            }
                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer style={{ "margin": "0 auto" }}>
                    <Button variant="primary" className='modal_action_button' onClick={handlerecipeClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <div style={{ clear: 'both' }}></div>

            <div style={{ "display": "none" }}>
                <div className='shopping_print' ref={shoppingPrint}>
                    {vegshoppingList ?
                        <div className='shopping_box'>
                            <h6>Veggies:</h6>
                            {vegshoppingList.map((veg, index) =>
                                <div key={index}>
                                    {veg.Name} - {Math.round(veg.Quantity)}&nbsp;{veg.Unit}
                                </div>
                            )}
                        </div>
                        : null}
                    {meatshoppingList ?
                        <div className='shopping_box'>
                            <h6>Meat:</h6>
                            {
                                meatshoppingList.map((meat, index) =>
                                    <div key={index}>
                                        {meat.Name} - {Math.round(meat.Quantity)}&nbsp;{meat.Unit}
                                    </div>
                                )}</div>
                        : null}
                    {dairyshoppingList ?
                        <div className='shopping_box'>
                            <h6>Dairy:</h6>
                            {dairyshoppingList.map((dairy, index) =>
                                <div key={index}>
                                    {dairy.Name} - {Math.round(dairy.Quantity)}&nbsp;{dairy.Unit}
                                </div>
                            )}</div>
                        : null}
                    {spiceshoppingList ?
                        <div className='shopping_box'>
                            <h6>Spcies:</h6>
                            {spiceshoppingList.map((spice, index) =>
                                <div key={index}>
                                    {spice.Name} - {Math.round(spice.Quantity)}&nbsp;{spice.Unit}
                                </div>
                            )}</div>
                        : null}
                    {othershoppingList ?
                        <div className='shopping_box'>
                            <h6>Others:</h6>
                            {othershoppingList.map((others, index) =>
                                <div>
                                    {others.Name} - {Math.round(others.Quantity)}&nbsp;{others.Unit}
                                </div>
                            )}</div>
                        : null}
                </div>
            </div>
        </div>

    )
}

export default DietPlan
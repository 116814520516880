import React, { useEffect, useState } from "react";
import List from "./List";
import Popup from "./popup";
import axios from 'axios';

const CoachCat=()=>{

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create()
    const [SaveCategoryButtonLabel,SetSaveCategoryButtonLabel]=useState('Add specialized in')
    const [ShowPopup,SetShowPopup]=useState(false)
    const [DefaultCatName,SetDefaultCatName]=useState('')
    const [DefaultCatStatus,SetDefaultCatStatus]=useState(1)
    const [EditId,SetEditId]=useState('')



    const TriggerAddCategory=()=>{
        SetSaveCategoryButtonLabel('Add specialized in')
        SetShowPopup(true)
    }

    const TriggerEditCategory=async(id)=>{
        SetSaveCategoryButtonLabel('Edit Category')

     

        const ListFetch = await axiosJWT.get('/coach-cat/detail?id=' + id +'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })


        if(ListFetch.status===200)
        {
            SetDefaultCatName(ListFetch.data.Detail.category_name)
            if(ListFetch.data.Detail.category_status===true)
            {
                SetDefaultCatStatus(1) 
            }
            else{
                SetDefaultCatStatus(0)  
            }
            SetShowPopup(true)
        }




     
    }

    const [ListData,SetListData]=useState([])
    const [ListCount,SetListCount]=useState(0)
    const [PageLimit,SetPageLimit]=useState(50)
    const [Pagenumber,SetPagenumber]=useState(1)

    const GetMyLisData=async(pagenumber)=>{

        SetPagenumber(pagenumber)

        const ListFetch = await axiosJWT.get('/coach-cat/list?offset=' + pagenumber + '&limit=' + PageLimit + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if(ListFetch.status===200)
        {
            if(ListFetch.data.list!==null)
            {
                SetListData(ListFetch.data.list)
            }
            if(ListFetch.data.total_count!==null)
            {
                SetListCount(ListFetch.data.total_count)
            }
        }

    

    }

    useEffect(()=>{
        GetMyLisData(1)
    },[])
    

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
              <section className="dashboard">
              <div className="container coach_cat_container">
              <div className="coach_cat_headrow">
                <span className="heading">Coach-Specialized In</span>
                <button onClick={TriggerAddCategory} className="">Add specialized in</button>
            </div>
                <List ListData={ListData} ListCount={ListCount} PageLimit={PageLimit} GetMyLisData={GetMyLisData} TriggerEditCategory={TriggerEditCategory} EditId={EditId} SetEditId={SetEditId} Pagenumber={Pagenumber} />
                <Popup SaveCategoryButtonLabel={SaveCategoryButtonLabel} ShowPopup={ShowPopup} SetShowPopup={SetShowPopup} GetMyLisData={GetMyLisData} DefaultCatName={DefaultCatName} DefaultCatStatus={DefaultCatStatus} Pagenumber={Pagenumber} />
              </div>
              </section>
         
        </div>
    )

}
export default CoachCat
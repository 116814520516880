import React, { useEffect } from "react";
import EnergyIcon from '../../../images/Energy-Icon.png';
import BetterSleepIcon from '../../../images/Better-Sleep-Icon.png';
import LeanIcon from '../../../images/Lean-Toned-Icon.png';
import IncreaseStrengthIcon from '../../../images/Increase-Strength-Icon.png';
import DigestionIcon from '../../../images/Improve-Digestion-Icon.png';
import MetabolismIcon from '../../../images/Improve-Metabolism-Icon.png';
import BurnIcon from '../../../images/Burn-Fat-Energy-Icon.png';
import ChronicIcon from '../../../images/Help-Improve-Chronic-Icon.png';
import KetoisisIcon from '../../../images/Ketolsis-Icon.png';


const Goals =(props)=>{
    var GoalsArray=[];

    const CheckImage=(e)=>{
        var el = e.target;
        var closestParent = el.closest(".options");
    
        const ClickedId = closestParent.getAttribute("data-target");
        const ClosetestImgDiv = el.closest(".img_div");

        if (ClosetestImgDiv.classList.contains("active")) {    
            var filteredArray = GoalsArray.filter(function(e) { return e !== ClickedId })
            GoalsArray=filteredArray;
            ClosetestImgDiv.classList.remove("active");
          } else {
            GoalsArray.push(ClickedId);
            ClosetestImgDiv.classList.add("active");
          }

          if(GoalsArray.length>0)
          {       
           props.EnableNextButton();
           props.SetGoalsData(GoalsArray);
          }
          else{
           props.DisableNextButton();
          }
    }

    useEffect(() => {
        setTimeout(() => {
          if (props.GoalsData) {       
            if (props.GoalsData.length !== 0) {
                GoalsArray=props.GoalsData;

              props.GoalsData.forEach(element => {    
                const el1 = document.querySelector(
                  '.meat .options_div_parent .options_div .options[data-target="'+element+'"] .img_div'
                );

                el1.classList.add("active");                
              });
              props.EnableNextButton();           
            } else {            
              props.DisableNextButton();
            }
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false);
        }
      }, [props]);

    return (
        <div className="meat">
        <div className="head_row">
            <span className="heading">
                What are your goals for this meal plan?
            </span>
        </div>
        {/* <div className="sub_head_row">
            <span className="heading">Please Select Which Other Foods You Would Like To Be Included</span>
        </div> */}
        <div className="options_div_parent">
            <div className="options_div">
                <div className="options" data-target="More energy" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={EnergyIcon} />
                        <p className="title">More energy</p>
                    </div>
                </div>
                <div className="options" data-target="Better sleep" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={BetterSleepIcon} />
                        <p className="title">Better sleep</p>
                    </div>
                </div>
                <div className="options" data-target="Become lean & toned" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={LeanIcon} style={{height:'35px'}} />
                        <p className="title">Become lean & toned</p>
                    </div>
                </div>
                <div className="options" data-target="Increase strength" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={IncreaseStrengthIcon} />
                        <p className="title">Increase strength</p>
                    </div>
                </div>
                <div className="options" data-target="Improve digestion" onClick={CheckImage}>
                    <div className="img_div">
                        <img src={DigestionIcon}/>
                        <p className="title">Improve digestion</p>
                    </div>
                </div>
                <div className="options" data-target="Improve metabolism" onClick={CheckImage}>
                    <div className="img_div">
                    <img src={MetabolismIcon}/>
                        <p className="title">Improve metabolism</p>
                    </div>
                </div>
                <div className="options" data-target="Burn fat energy" onClick={CheckImage}>
                    <div className="img_div">
                    <img src={BurnIcon}/>
                        <p className="title">Burn fat energy</p>
                    </div>
                </div>
                <div className="options" data-target="Improve chronic conditions" onClick={CheckImage}>
                    <div className="img_div">
                    <img src={ChronicIcon} style={{height:'20px'}}/>
                        <p className="title">Improve chronic conditions</p>
                    </div>
                </div>
                <div className="options" data-target="Get ketosis quickly" onClick={CheckImage}>
                    <div className="img_div">
                    <img src={KetoisisIcon}/>
                        <p className="title">Get ketosis quickly</p>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    )
}
export default Goals
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { format, addDays, differenceInDays, set } from 'date-fns';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Select from 'react-select';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import DummyrecipeImg from '../../images/pexels-photo-1640777.jpeg';
import ImportedRecipePopup from '../CoachComponents/Plans/ImportedRecipePopup';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ClientMealPlan = () => {


    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const client_id = localStorage.getItem("userId");

    var navigate = useNavigate();

    const [PercentageArray, SetPercentageArray] = useState([]);
    var [foodMenu, setfoodMenu] = useState('breakfast');
    var [recipetab, setRecipeTabMenu] = useState('ingredients');
    const [recipedayData, setrecipedayData] = useState([]);

    const [breakfastData, setbreakfastData] = useState('');
    const [lunchData, setlunchData] = useState('');
    const [dinnerData, setdinnerData] = useState('');
    const [snackData, setsnackData] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [reqplanShow, setreqplanShow] = useState(false);
    const handlereqplanClose = () => setreqplanShow(false);
    const [modalPopup, setModal] = useState();

    const [recipeData, setrecipeData] = useState('');
    const [planId, setplanId] = useState('');
    const [dayId, setdayId] = useState('');
    const [planData, setplanData] = useState([]);
    const [dayList, setdayList] = useState([]);

    const [coachOptions, setcoachOptions] = useState([]);
    const [selectedCoach, setselectedCoach] = useState('');
    const [showData, setshowData] = useState({ "display": "none" })

    const [reqplanReason, setreqplanReason] = useState('');
    const [reqplanSuggestion, setreqplanSuggestion] = useState('');

    const [message, setMessage] = useState('');
    const [ImportedRecipePopShow, SetImportedRecipePopShow] = useState(false);
    const [ImportedRecipeData, SetImportedRecipeData] = useState('');


    const [breakfastKey, setbreakfastKey] = useState('');
    const [lunchKey, setlunchKey] = useState('');
    const [dinnerKey, setdinnerKey] = useState('');
    const [snackKey, setsnackKey] = useState('');
    const [BreakfastPlan, setBreakfastPlan] = useState('');
    const [LunchPlan, setLunchPlan] = useState('');
    const [DinnerPlan, setDinnerPlan] = useState('');
    const [SnackPlan, setSnackPlan] = useState('');
    const [weekselect, setweekselect] = useState(false);
    const [weekOptions, setweekOptions] = useState([]);
    const [mealDays, setmealDays] = useState('');
    const weekDays = [1, 2, 3, 4, 5, 6, 7];

    const axiosJWT = axios.create();

    useEffect(() => {
        document.title = 'Client-Coach meal plan';
        load_coach(client_id);
    }, []);

    const load_coach = async (client_id) => {

        loading_icon('hide');

        await axiosJWT.get("/meal-plan", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            // console.log(response.data)
            var coach_data = response.data;
            var coach_list = [];

            coach_data.forEach(element => {
                coach_list.push({ label: element.nutritionist.first_name + " " + element.nutritionist.last_name, value: element.nutritionist_id });
            });

            //console.log(coach_list)
            setcoachOptions(coach_list);

        });
    }

    const load_plan = async (e) => {
        //console.log(e.label)

        var coach_id = e.value;
        setselectedCoach(e);

        await axiosJWT.get("/meal-plan/client/" + coach_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {

            setplanId(response.data.meal_plan_id);

            if (response.data.breakfast_key || response.data.lunch_key || response.data.dinner_key || response.data.snack_key) {

                var breakfast_exist = response.data.breakfast_key;
                var lunch_exist = response.data.lunch_key;
                var dinner_exist = response.data.dinner_key;
                var snack_exist = response.data.snack_key;


                // var getData = [];
                // var recipe_key_length = [];

                // if (breakfast_exist)
                //     recipe_key_length.push({ 'menu': 'breakfast', 'size': breakfast_exist.length });

                // if (lunch_exist)
                //     recipe_key_length.push({ 'menu': 'lunch', 'size': lunch_exist.length });

                // if (dinner_exist)
                //     recipe_key_length.push({ 'menu': 'dinner', 'size': dinner_exist.length });

                // if (snack_exist)
                //     recipe_key_length.push({ 'menu': 'snack', 'size': snack_exist.length });

                // const menuAscending = [...recipe_key_length].sort((a, b) =>
                //     a.size > b.size ? 1 : -1,
                // );


                // if (menuAscending[0].menu === 'breakfast') {
                //     breakfast_exist.forEach(function (item, index) {
                //         getData.push({ day: item.day, breakfast_key: item.recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: snack_exist[index].recipeid });
                //     });
                // }
                // if (menuAscending[0].menu === 'lunch') {
                //     lunch_exist.forEach(function (item, index) {
                //         getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: item.recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: snack_exist[index].recipeid });
                //     });
                // }
                // if (menuAscending[0].menu === 'dinner') {
                //     dinner_exist.forEach(function (item, index) {
                //         getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: item.recipeid, snack_key: snack_exist[index].recipeid });
                //     });
                // }
                // if (menuAscending[0].menu === 'snack') {
                //     snack_exist.forEach(function (item, index) {
                //         getData.push({ day: item.day, breakfast_key: breakfast_exist[index].recipeid, lunch_key: lunch_exist[index].recipeid, dinner_key: dinner_exist[index].recipeid, snack_key: item.recipeid });
                //     });
                // }

                // setrecipedayData(getData);

                setbreakfastKey(breakfast_exist);
                setlunchKey(lunch_exist);
                setdinnerKey(dinner_exist);
                setsnackKey(snack_exist);

                weekmealplan(breakfast_exist, lunch_exist, dinner_exist, snack_exist, 1, response.data.days);

                if (response.data.days > 7) {
                    generateweek_select(response.data.days);
                    setmealDays(response.data.days);
                }
            }

            // var meal_plan_days = response.data.days;
            // var plan_start_date = response.data.date;

            // //var date = format(new Date(plan_start_date),'PP');
            // var date = new Date(plan_start_date);

            // var day_list = [];
            // for (var i = 1; i <= meal_plan_days; i++) {
            //     day_list.push({ id: i, date: format(addDays(date, i), 'PP') });
            // };

            // setdayList(day_list);

            // var sDate = new Date(format(new Date(plan_start_date), 'yyyy-M-d'));
            // var todayDate = new Date(format(new Date(), 'yyyy-M-d'));
            // var diff_days = differenceInDays(todayDate, sDate);

            // setTimeout(() => {
            //     if (diff_days <= meal_plan_days) {
            //         if (parseInt(diff_days) === 0)
            //             loadDay(coach_id, 1);
            //         else
            //             loadDay(coach_id, diff_days);
            //     }
            //     else
            //         loadDay(coach_id, 1);

            //     setshowData({ "display": "block" });            

            // }, 1000);
        });
    }


    const weekmealplan = async (breakfastkeys, lunchkeys, dinnerkeys, snackkeys, week, totaldays) => {

        loading_icon('show');

        if ((totaldays % 7) === 0) {
            var recipe_end = (week * 7);
            var recipe_start = (recipe_end - 7);
        }
        else {
            if ((week * 7) <= totaldays) {
                var recipe_end = (week * 7);
                var recipe_start = (recipe_end - 7);
            }
            else {
                var excess_days = (totaldays % 7);
                var recipe_start = ((week - 1) * 7);
                var recipe_end = (((week - 1) * 7) + excess_days);
            }
        }

        var breakfast_plan = [];
        var lunch_plan = [];
        var dinner_plan = [];
        var snack_plan = [];

        var k;


        for (k = recipe_start; k < recipe_end; k++) {

            if (!(breakfastkeys[k].recipeid).startsWith('FITPALIR')) {

                try{

                    var bf_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + breakfastkeys[k].recipeid + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                    if (bf_response.status === 200) {
                        breakfast_plan.push(bf_response.data);
                    }

                }catch(err)
                {
                    console.log(err)
                }
               
            }
            else {
                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + breakfastkeys[k].recipeid, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (RecipeData.data.recipe !== null) {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    breakfast_plan.push(BreakfastData);
                }
            }

            if (!(lunchkeys[k].recipeid).startsWith('FITPALIR')) {

                try{

                    var l_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + lunchkeys[k].recipeid + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (l_response.status === 200) {
                    lunch_plan.push(l_response.data);
                }

                }catch(err)
                {
                    console.log(err)
                }
                
            }
            else {
                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + lunchkeys[k].recipeid, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var LunchData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    LunchData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }

                    lunch_plan.push(LunchData);
                }
            }


            if (!(dinnerkeys[k].recipeid).startsWith('FITPALIR')) {

                try{

                    var d_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + dinnerkeys[k].recipeid + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                    if (d_response.status === 200) {
                        dinner_plan.push(d_response.data);
                    }

                }catch(err)
                {
                    console.log(err)
                }
               
            }
            else {
                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + dinnerkeys[k].recipeid, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var DinnerData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    DinnerData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }

                    dinner_plan.push(DinnerData);
                }
            }

            if (!(snackkeys[k].recipeid).startsWith('FITPALIR')) {

                try{

                    var s_response = await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + snackkeys[k].recipeid + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY);

                if (s_response.status === 200) {
                    snack_plan.push(s_response.data);
                }

                }catch(err)
                {
                    console.log(err)
                }
                
            }
            else {
                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + snackkeys[k].recipeid, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var SnackData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }

                    SnackData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }

                    snack_plan.push(SnackData);
                }
            }
        }

        setBreakfastPlan(breakfast_plan);
        setLunchPlan(lunch_plan);
        setDinnerPlan(dinner_plan);
        setSnackPlan(snack_plan);

        loading_icon('hide');
    }

    const generateweek_select = async (days) => {
        setweekselect(true);

        if (days > 7) {
            var totalweeks = (days / 7);

            if ((days % 7) > 0)
                totalweeks = (totalweeks + 1);
        }

        var weekoptions = [];
        for (var i = 1; i <= totalweeks; i++) {
            weekoptions.push({ label: 'Week ' + i, value: i });
        }

        setweekOptions(weekoptions);
    }

    const change_week = async (week) => {
        weekmealplan(breakfastKey, lunchKey, dinnerKey, snackKey, week, mealDays);
    }

    const loadDay = async (coach_id, day_id) => {

        const alldays = document.querySelectorAll(".day_box .box .card");
        alldays.forEach((element) => element.classList.remove('active'));

        document.getElementById("day_" + day_id).setAttribute("class", "card active");


        const alltabs = document.querySelectorAll(".inner_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));
        document.getElementById("tab_breakfast").setAttribute("class", "active");
        setfoodMenu('breakfast');

        setdayId(day_id);
        setbreakfastData('');
        setlunchData('');
        setdinnerData('');
        setsnackData('')
        setMessage('');

        const response = await axiosJWT.get("/meal-plan/client/" + coach_id + "/" + day_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.breakfast_key !== '' && response.data.breakfast_key !== null) {
            var CheckKeyType = String(response.data.breakfast_key);

            if (!CheckKeyType.startsWith('FITPALIR')) {

                try{

                    await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.breakfast_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                        setbreakfastData(res.data);
                    });

                }catch(err)
                {
                    console.log(err)
                }
               
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.breakfast_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (RecipeData.data.recipe !== null) {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage = RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setbreakfastData(BreakfastData);
                }
            }
        }

        if (response.data.lunch_key !== '' && response.data.lunch_key !== null) {
            var CheckKeyType = String(response.data.lunch_key);

            if (!CheckKeyType.startsWith('FITPALIR')) {

                try{

                    await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.lunch_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                        setlunchData(res.data);
                    });

                }
                catch(err)
                {
                    console.log(err)
                }
                
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.lunch_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setlunchData(BreakfastData);
                }
            }
        }

        if (response.data.dinner_key !== '' && response.data.dinner_key !== null) {
            var CheckKeyType = String(response.data.dinner_key);

            if (!CheckKeyType.startsWith('FITPALIR')) {
                try{

                    await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.dinner_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                        setdinnerData(res.data);
                    });

                }catch(err)
                {
                    console.log(err)
                }
               
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.dinner_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage =  RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setdinnerData(BreakfastData);
                }
            }
        }

        if (response.data.snack_key !== '' && response.data.snack_key !== null) {
            var CheckKeyType = String(response.data.snack_key);

            if (!CheckKeyType.startsWith('FITPALIR')) {

                try{

                    await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + response.data.snack_key + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                        setsnackData(res.data);
                    });

                }catch(err)
                {
                    console.log(err)
                }
              
            }
            else {

                const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + response.data.snack_key, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (RecipeData.data.recipe !== null) {
                    var BreakfastData = {};
                    var recipeId = RecipeData.data.recipe.recipe_uid;
                    var recipeImage = '';
                    var recipeTitle = RecipeData.data.recipe.recipe_name;
                    if (RecipeData.data.recipe.recipe_image !== '') {
                        recipeImage = RecipeData.data.recipe.recipe_image;
                    }
                    else {
                        recipeImage = DummyrecipeImg;
                    }
                    BreakfastData = {
                        RecipeID: recipeId,
                        PhotoUrl: recipeImage,
                        Title: recipeTitle
                    }
                    setsnackData(BreakfastData);
                }
            }
        }
    }

    const changeTab = async (e) => {
        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".inner_tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setfoodMenu(tab_for);
        setMessage('');
    }

    const showRecipe = async (e) => {
        const recipeId = e.target.getAttribute("data-item");

        if (!recipeId.startsWith('FITPALIR')) {

            try{

                await axios.get(process.env.REACT_APP_RECIPE_PROVIDER_HOST + "/recipe/steps/" + recipeId + "?api_key=" + process.env.REACT_APP_RECIPE_PROVIDER_KEY).then(res => {
                    setShow(true);
                    setrecipeData(res.data);
    
                    SetPercentageArray([res.data.NutritionInfo.Protein.toFixed(2), res.data.NutritionInfo.TotalFat.toFixed(2), res.data.NutritionInfo.TotalCarbs.toFixed(2)]);
    
                    //console.log(res.data);
                });

            }catch(err)
            {
                console.log(err)
            }

           
        }
        else {
            const RecipeData = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + recipeId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            SetImportedRecipeData(RecipeData.data.recipe);
            SetImportedRecipePopShow(true);
        }

        setRecipeTabMenu('ingredients');
        setMessage('');
    }

    const changeRecipeTab = async (e) => {
        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".recipetabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setRecipeTabMenu(tab_for);
        setMessage('');
    }

    const LeftArrow = () => {
        const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

        return (
            <div className='left_arrow' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            </div>
        );
    }

    const RightArrow = () => {
        const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

        return (
            <div className='right_arrow' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            </div>
        );
    }

    const handleShow = async (e) => {
        setreqplanReason('');
        setreqplanSuggestion('');
        /*const assigned_Id = e.target.getAttribute("data-item");       
        const datamodal  = e.target.getAttribute("data-modal");*/

        setreqplanShow(true);
    }

    const create_request = async () => {
        //console.log(planId)
        if (selectedCoach.value !== '' && client_id !== '' && planId !== '') {
            const response = await axiosJWT.post("/meal-plan/reqplan",
                {
                    nutritionist_id: selectedCoach.value,
                    client_id: client_id,
                    current_plan_id: planId,
                    reason: reqplanReason,
                    suggestion: reqplanSuggestion
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            // console.log(response)
            if (response.status === 200) {
                handlereqplanClose();
                setMessage(response.data);

                // console.log(response)
            }
        }
    }


    const check_loadDay = (day_id) => {

        var filled = false;
        recipedayData.forEach(element => {
            if (parseInt(element.day) === parseInt(day_id)) {
                // console.log(element.day+" "+day_id+" "+element.breakfast_key)
                if (element.breakfast_key !== '' && element.lunch_key !== '' && element.dinner_key !== '')
                    filled = true;
            }
        });

        return (filled);
    }

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: { enabled: false },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;
                },
                color: '#fff',
            }
        },
    };

    const data = {
        labels: ['Protein', 'Fat', 'Carbs'],
        datasets: [
            {
                label: '',
                data: PercentageArray,
                backgroundColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',

                ],
                borderColor: [
                    'rgb(179, 0, 59)',
                    'rgb(13, 202, 240)',
                    'rgb(0, 179, 0)',
                    'rgb(0, 0, 0)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const navigateAppointmentPage = () => {
        navigate("/client/appointment");
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="client_mealplan container">
                    <div id="semiTransparenDivLoader"></div>
                    <div className='menubox'>
                        <div className='leftmenu'>
                            <h5>Meal Plan</h5>
                        </div>
                        <div className='rightmenu'>
                            {selectedCoach ?
                                <div className='action_buttons'>
                                    {message && <label className="actionmsg">{message}</label>}
                                    <Button className='green_button btn' data-item={planId} data-modal="change_plan" onClick={handleShow}>Request New Plan</Button>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div style={{ "clear": "both" }} />

                    {coachOptions.length > 0 ?
                        <div className='coach_box'>
                            <label>Choose Coach:</label>
                            <Select
                                options={coachOptions}
                                onChange={load_plan}
                            />
                        </div>
                        :
                        <div style={{ marginTop: "10%", textAlign: "center" }}>Hey! You don't have any meal plan now.! If you have subscribed to any coach please ask to get meal plean. If not subscribe, Please check appointment page <a onClick={navigateAppointmentPage}>(click here).</a></div>}


                    {/* <div className='coach_meal_plan' style={showData}>
                        <div className='day_box'>
                            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onScroll={0} transitionDuration='3000' transitionBehavior='smooth'>
                                {dayList.map(({ id, date }) => (
                                    <div key={id}>
                                        <div className='box' onClick={() => loadDay(selectedCoach.value, id)}>
                                            <div className={check_loadDay(parseInt(id)) === true ? "card filled" : "card"} id={"day_" + id} >
                                                <div>{date}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </ScrollMenu>
                        </div>

                        <div style={{ "clear": "both" }} />

                        <div className='inner_menubox'>
                            <div className='inner_tabmenu'>
                                <a id="tab_breakfast" className="active" onClick={changeTab} data-item="breakfast">Breakfast</a>
                                <a onClick={changeTab} data-item="lunch">Lunch</a>
                                <a onClick={changeTab} data-item="dinner">Dinner</a>
                                <a onClick={changeTab} data-item="snack">Snack</a>
                            </div>
                        </div>

                        <div className='selected_recipe'>
                            <div className='recipes'>
                                {foodMenu === 'breakfast' ?
                                    <div className='recipe_box'>
                                        {breakfastData ?
                                            <div>
                                                <i className='fa fa-ellipsis-v viewdata' data-item={breakfastData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                                <div className='img'><img src={breakfastData.PhotoUrl} /></div>
                                                <div className='title'>{breakfastData.Title}</div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                {foodMenu === 'lunch' ?
                                    <div className='recipe_box'>
                                        {lunchData ?
                                            <div>
                                                <i className='fa fa-ellipsis-v viewdata' data-item={lunchData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                                <div className='img'><img src={lunchData.PhotoUrl} /></div>

                                                <div className='title'>{lunchData.Title}</div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                {foodMenu === 'dinner' ?
                                    <div className='recipe_box'>
                                        {dinnerData ?
                                            <div>
                                                <i className='fa fa-ellipsis-v viewdata' data-item={dinnerData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                                <div className='img'><img src={dinnerData.PhotoUrl} /></div>

                                                <div className='title'>{dinnerData.Title}</div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                {foodMenu === 'snack' ?
                                    <div className='recipe_box'>
                                        {snackData ?
                                            <div>
                                                <i className='fa fa-ellipsis-v viewdata' data-item={snackData.RecipeID} onClick={(e) => showRecipe(e)}></i>
                                                <div className='img'><img src={snackData.PhotoUrl} /></div>

                                                <div className='title'>{snackData.Title}</div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div> */}
                    <div style={{ "clear": "both" }} />

                    {weekselect === true ?
                        <div className='week_box'>
                            <Select
                                defaultValue={{ label: 'Week 1', value: 1 }}
                                options={weekOptions}
                                onChange={(e) => change_week(e.value, '')}
                                className="weekselect"
                            />
                            <div style={{ "clear": "both" }} />
                        </div>
                        : null}
                    <div className='week_plan_recipes'>
                        {SnackPlan.length > 0 ?
                            <div>
                                <div className='recipes desktop'>
                                    <div className='row'>
                                        <div className='col header dayheader'></div>
                                        <div className='col header'>
                                            Breakfast
                                        </div>
                                        <div className='col header'>
                                            Lunch
                                        </div>
                                        <div className='col header'>
                                            Dinner
                                        </div>
                                        <div className='col header'>
                                            Snack
                                        </div>
                                    </div>
                                    {(weekDays.map((element, index) =>
                                        SnackPlan[index] ?
                                            <div className='row' key={index}>
                                                <div className='col daycol'>
                                                    {"Day " + (index + 1)}
                                                </div>
                                                <div className='col'>
                                                    <div className='recipe_box'>
                                                        <i className='fa fa-ellipsis-v viewdata' data-item={BreakfastPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                        <div className='img'><img src={BreakfastPlan[index].PhotoUrl} data-item={BreakfastPlan[index].RecipeID} /></div>

                                                        <div className='title'>{BreakfastPlan[index].Title}</div>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='recipe_box'>
                                                        <i className='fa fa-ellipsis-v viewdata' data-item={LunchPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                        <div className='img'><img src={LunchPlan[index].PhotoUrl} data-item={LunchPlan[index].RecipeID} /></div>

                                                        <div className='title'>{LunchPlan[index].Title}</div>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='recipe_box'>
                                                        <i className='fa fa-ellipsis-v viewdata' data-item={DinnerPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                        <div className='img'><img src={DinnerPlan[index].PhotoUrl} data-item={DinnerPlan[index].RecipeID} /></div>

                                                        <div className='title'>{DinnerPlan[index].Title}</div>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className='recipe_box'>
                                                        <i className='fa fa-ellipsis-v viewdata' data-item={SnackPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                        <div className='img'><img src={SnackPlan[index].PhotoUrl} data-item={SnackPlan[index].RecipeID} /></div>

                                                        <div className='title'>{SnackPlan[index].Title}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    ))}
                                </div>
                                <Table className="recipes mob_recipes">
                                    <Thead>
                                        <Tr>
                                            <Th>Day</Th>
                                            <Th>Breakfast</Th>
                                            <Th>Lunch</Th>
                                            <Th>Dinner</Th>
                                            <Th>Snack</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {(weekDays.map((element, index) =>
                                            SnackPlan[index] ?
                                                <Tr key={index}>
                                                    <Td>
                                                        {"Day " + (index + 1)}
                                                    </Td>
                                                    <Td>
                                                        <div className='recipe_box'>
                                                            <i className='fa fa-ellipsis-v viewdata' data-item={BreakfastPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                            <div className='img'><img src={BreakfastPlan[index].PhotoUrl} data-item={BreakfastPlan[index].RecipeID} /></div>

                                                            <div className='title'>{BreakfastPlan[index].Title}</div>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        <div className='recipe_box'>
                                                            <i className='fa fa-ellipsis-v viewdata' data-item={LunchPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                            <div className='img'><img src={LunchPlan[index].PhotoUrl} data-item={LunchPlan[index].RecipeID} /></div>

                                                            <div className='title'>{LunchPlan[index].Title}</div>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        <div className='recipe_box'>
                                                            <i className='fa fa-ellipsis-v viewdata' data-item={DinnerPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                            <div className='img'><img src={DinnerPlan[index].PhotoUrl} data-item={DinnerPlan[index].RecipeID} /></div>

                                                            <div className='title'>{DinnerPlan[index].Title}</div>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        <div className='recipe_box'>
                                                            <i className='fa fa-ellipsis-v viewdata' data-item={SnackPlan[index].RecipeID} onClick={(e) => showRecipe(e)}></i>

                                                            <div className='img'><img src={SnackPlan[index].PhotoUrl} data-item={SnackPlan[index].RecipeID} /></div>

                                                            <div className='title'>{SnackPlan[index].Title}</div>
                                                        </div>
                                                    </Td>
                                                </Tr>
                                                : null
                                        ))}
                                    </Tbody>
                                </Table>
                            </div>
                            :
                            null
                        }
                    </div>


                    <Modal show={show} onHide={handleClose} className='coach_plan_recipe_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>Recipe Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {recipeData ?
                                <div className='recipe_data'>
                                    <div className='title'>{recipeData.Title}</div>
                                    <div className='img'><img src={recipeData.PhotoUrl} /></div>
                                    <div className='nutrition_data'>

                                        <p className='pie_chart_kcal_data'>Calories: {(recipeData.NutritionInfo.TotalCalories).toFixed(2)} Kcal</p>
                                        <Pie data={data} options={options} />

                                        <hr />
                                        <div className="pie_chart_bottom_div">
                                            <span>
                                                <span className="dotcarbs"></span>
                                                <span className="pie_chart_bottom_div_label">Carbs</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalCarbs).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                            <span>
                                                <span className="dotfat"></span>
                                                <span className="pie_chart_bottom_div_label">Fat</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.TotalFat).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                            <span>
                                                <span className="dotprotein"></span>
                                                <span className="pie_chart_bottom_div_label">Protein</span>
                                                <span className="pie_chart_bottom_div_data"><span>{(recipeData.NutritionInfo.Protein).toFixed(2)}</span><span>G</span></span>
                                            </span>
                                        </div>

                                    </div>
                                    <div style={{ "clear": "both", "marginBottom": "20px" }} />
                                    <div className='menubox'>
                                        <div className='recipetabmenu'>
                                            <a className="active" onClick={changeRecipeTab} data-item="ingredients">Ingredients</a>
                                            <a onClick={changeRecipeTab} data-item="steps">Steps</a>
                                        </div>
                                    </div>
                                    <div style={{ "clear": "both", "marginBottom": "20px" }} />
                                    {recipetab === 'ingredients' ?
                                        <div className='ingredients'>
                                            <ul>
                                                {(recipeData.Ingredients).map((ing, index) => (
                                                    <li key={index}>{ing.Name} - {(ing.Quantity).toFixed(2)} {ing.Unit}</li>
                                                ))}
                                            </ul>
                                        </div> : null
                                    }
                                    {recipetab === 'steps' ?
                                        <div className='steps'>
                                            <ol>
                                                {(recipeData.Steps).map((ing, index) => (
                                                    <li key={index}>{ing.Text}</li>
                                                ))}
                                            </ol>
                                        </div> : null
                                    }
                                </div>
                                : null}

                        </Modal.Body>
                        <Modal.Footer style={{ "margin": "0 auto" }}>
                            <Button variant="primary" className='modal_action_button' data-item={planId} onClick={handleClose}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={reqplanShow} onHide={handlereqplanClose} className='client_reqplan_modal_popup'>
                        <Modal.Header closeButton>
                            <Modal.Title>New Plan Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>Choose Coach:</label><br />
                                    <Select
                                        defaultValue={{ label: selectedCoach.label, value: selectedCoach.value }}
                                        options={coachOptions}
                                        onChange={setselectedCoach}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>Reason:</label><br />
                                    <textarea className='inner_popup_input' defaultValue={reqplanReason} onChange={(e) => { setreqplanReason(e.target.value) }} rows="4" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className='inner_popup_label required'>suggestion:</label><br />
                                    <textarea className='inner_popup_input' defaultValue={reqplanSuggestion} onChange={(e) => { setreqplanSuggestion(e.target.value) }} rows="4" />
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='display_block'>

                            <Button variant="secondary" className='modal_close_button' data-item={planId} onClick={handlereqplanClose}>
                                Back
                            </Button>
                            <Button variant="primary" className='modal_action_button float_right' data-item={planId} onClick={create_request}>
                                Create Request
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                {
                    ImportedRecipePopShow === true ? <ImportedRecipePopup ImportedRecipeData={ImportedRecipeData} SetImportedRecipePopShow={SetImportedRecipePopShow} ImportedRecipePopShow={ImportedRecipePopShow} /> : null
                }
            </section>
        </div>

    )
}

export default ClientMealPlan
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import InputColor from 'react-input-color';

const CustomCatAdd = (props) => {

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [CustomCatName,SetCustomCatName]=useState('')
    const [CustomCatErr,SetCustomCatErr]=useState(false)
    const [CustomUnit,SetCustomUnit]=useState('')
    const [CustomUnitErr,SetCustomUnitErr]=useState(false)
    const [color, setColor] = useState()
    const [ColorHex,SetColorHex]=useState('#5e72e4')
    const [errmsg,Seterrmsg]=useState('')
    const SetColorPicker=(value)=>{
        setColor(value)
        SetColorHex(value.hex)

    }
    const SaveCustomCat=async(e)=>{
        const action = e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowCustomCatfield(false)
            props.SetCustomOption(true)
            return false
        }
        var err=false;
        SetCustomCatErr(false)
        SetCustomUnitErr(false)
        Seterrmsg('')
        

        if(CustomCatName==='')
        {
            err=true;
            SetCustomCatErr(true)
        }
        if(CustomUnit==='')
        {
            err=true;
            SetCustomUnitErr(true)
        }
        if(err===false)
        {

            try{
                const response = await axiosJWT.post('add-data/add-custom-category',
                {
    
                    'custom_name': CustomCatName,
                    'custom_unit': CustomUnit,
                    'custom_color': ColorHex,
                    'client_id':IsClientid,
                    
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    props.GetMyCustomCategoryList()
    
                    if(action==='save')
                    {
                        props.SetShowCustomCatfield(false)
                        props.SetCustomOption(true)
                  
                    }
                   
                    
                }

            }catch(err){
                Seterrmsg(err.response.data.msg)
              
            }

           
            


            
        }

    }
  

    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Custom > Add New data'}</h6>
            <div className="field_top_row">
                <div className="label">Name</div>
                <div className="field">
                    <input type={'text'} className="raw_input" onChange={(e) => { SetCustomCatName(e.target.value) }}  {...(CustomCatErr === true ? { id: 'err_border' } : {})} defaultValue={CustomCatName} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Unit</div>
                <div className="field">
                    <input type={'text'} className="raw_input" onChange={(e) => { SetCustomUnit(e.target.value) }}  {...(CustomUnitErr === true ? { id: 'err_border' } : {})} defaultValue={CustomUnit} />
                </div>
            </div>
            <div className="field_top_row">
            <div className="label">Color</div>
            <InputColor
        initialValue={ColorHex}
        onChange={SetColorPicker.bind(this)}
    
      
        placement="left"
      />
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveCustomCat(e) }} >
                    {'Cancel'}
                </Button>
               
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveCustomCat(e) }} >
                    {'Save'}
                </Button>

           </div>
           <div className="field_top_row">
           <span className="err">{errmsg}</span>
           </div>
          

        </div>
    )

}
export default CustomCatAdd
import React, { useEffect, useState, useRef } from "react";
import ManagerAccessCheck from "./ManagerAccessPage";
import axios from 'axios';
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import female_icon from '../../images/female.png'
import male_icon from '../../images/male.png'
import AdminChart from '../AdminComponents/AdminChartComponent';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManagerAnalytics = () => {

  const side_nav = localStorage.getItem("side_nav");
  const [Access, SetAccess] = useState(false);
  const [ShowData, SetShowData] = useState(false);
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [ShowRealTime, SetShowRealTime] = useState(true);
  const [ShowUserTab, SetShowUserTab] = useState(false);
  const [ShowAnalyticsTab, SetShowAnalyticsTab] = useState(false);
  const [RealTimeConsulation, SetRealTimeConsulation] = useState([]);
  const [CoachActiveUsers, SetCoachActiveUsers] = useState([]);
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
  const [Chartlabels, SetNewChartlabels] = useState([]);
  const [ChartDatas, SetNewChartDatas] = useState([]);
  const [FilterName, SetFilterName] = useState('');
  const [FilterRole, SetFilterRole] = useState('Nutrition');
  const [FilterDate, SetFilterDate] = useState('');
  const [FilterStatus, SetFilterStatus] = useState('');
  const [ListOfUsers, SetListOfUsers] = useState([]);
  const [Pagenumber, SetPagenumber] = useState(1);
  const [PerPage, SetPerPage] = useState(10);
  const [TotalRecordUsersList, SetTotalRecordUsersList] = useState(0);
  const [CurrentTabUsers, SetCurrentTabUsers] = useState('coach');
  const datepicker1Ref = useRef(null);

  function handleClickDatepickerIcon1() {
    const datepickerElement = datepicker1Ref.current;
    datepickerElement.setFocus(true);
  }
  const [startDate, SetstartDate] = useState(false);

  const ChangedDate = async (date) => {

    var n = date.toUTCString();

    SetstartDate(date);
    SetFilterDate(n);
  }

  const FilterUserLists = async (pagenumber) => {

    SetPagenumber(pagenumber);

    const response = await axiosJWT.get('/manager-access/filter-users?name=' + FilterName + '&role=' + FilterRole + '&date=' + FilterDate + '&status=' + FilterStatus + '&offset=' + pagenumber + '&limit=' + PerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {
      if (response.data.AllUSers) {
        SetListOfUsers(response.data.AllUSers);
      }
      SetTotalRecordUsersList(response.data.count);
    }
  }

  const FilterUserListsTrigger = () => {
    FilterUserLists(1);
  }

  const ResetFilterUserList = async () => {
    SetFilterName('');
    SetFilterDate('');
    SetstartDate('');
    SetFilterRole('');
    SetFilterStatus('');

    document.getElementById('filter_name').value = '';
    document.getElementById('filter_role').value = 'Nutrition';
    document.getElementById('filter_status').value = '';

    SetPagenumber(1);

    const response = await axiosJWT.get('/manager-access/filter-users?name=&role=&date=&status=&offset=1' + '&limit=' + PerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetListOfUsers(response.data.AllUSers);
    SetTotalRecordUsersList(response.data.count);
  }

  const changePageNumber = (pagenumber) => {
    FilterUserLists(pagenumber);
  }


  function handleChange(newValue) {
    SetAccess(newValue);
  }

  function timetoUtcClientAppointment(passdate) {

    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss');

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss');
    return stardates;
  }

  const GetAccessDetails = async () => {

    const response = await axiosJWT.get('/managing-team/get-access-details', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });


    if (response.data !== null) {
      var Analyticsresult = response.data.filter(obj => {
        return obj.page === 'analytics'
      })
      if (Analyticsresult[0].read === true) {
        SetAccess(false);
        SetShowData(true);
      }
      else {
        SetAccess(true);
        SetShowData(false);
      }
    }
  }

  const changeUsers = async (e) => {
    SetShowRealTime(false);
    SetShowUserTab(false);
    SetShowAnalyticsTab(false);
    const list_for = e.target.getAttribute("data-item");


    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));
    e.target.setAttribute("class", "active");
    if (list_for === 'real_time') {
      SetShowRealTime(true);
      GetLiveConsulation();
      GetRealTimeAnalytics();
    }
    if (list_for === 'users') {
      SetShowUserTab(true);
      GetUsersChart('coach', 'this_week');
      FilterUserLists(1);
    }

  }

  const GetLiveConsulation = async () => {
    const response = await axiosJWT.get('/manager-access/get-my-team-live-appointments', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetRealTimeConsulation(response.data.FinalArray);
  }

  const GetRealTimeAnalytics = async () => {
    const response = await axiosJWT.get('/manager-access/get-my-team-real-time-analytics', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data !== null) {
      if (response.data.coachActive !== null) {
        SetCoachActiveUsers(response.data.coachActive);
      }
    }
  }




  const EarningsDropChange = async (e) => {
    GetUsersChart(CurrentTabUsers, e.target.value);
  }

  const GetUsersChart = async (type, range) => {

    var Usertype = type.toLowerCase();
    var Daterange = range.toLowerCase();

    const response = await axiosJWT.get('/manager-access/get-my-team-user-chart?type=' + Usertype + '&range=' + Daterange + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (Daterange === 'this_week' || Daterange === 'last_week') {
      SetNewChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);

      var Data = []
      response.data.FinalArray.forEach((value, index, array) => {
        Data.push(value.count);
      });
      SetNewChartDatas(Data);
    }

    if (Daterange === 'this_month' || Daterange === 'last_month') {
      var DatePush = [];
      var DataPush = [];

      response.data.FinalArray.forEach((value, index, array) => {
        DataPush.push(value.count);
        DatePush.push(value.date);
      });

      SetNewChartlabels(DatePush);
      SetNewChartDatas(DataPush);
    }

    if (Daterange === 'this_year') {
      SetNewChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      var Data = []
      response.data.FinalArray.forEach((value, index, array) => {
        Data.push(value.count);
      });
      SetNewChartDatas(Data);
    }
  }

  useEffect(() => {
    GetAccessDetails();
    GetLiveConsulation();
    GetRealTimeAnalytics();
  }, []);

  return (

    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className="dashboard">
        <ManagerAccessCheck allowedstatus={Access} onChange={handleChange} />

        {ShowData === true &&
          <div className="admin_analytics container">
            <div className="row top_row_analytics_div">
              <span className="analytics_top_label">FitPal Analytics</span>
              <p className="analytics_top_description">Here,You can will able to view the live consulations going on between coach and client ,live experts of your team members and so on </p>
            </div>

            <div className='menubox'>
              <div className='tabmenu'>
                <a className="active" onClick={changeUsers} data-item="real_time">Real time</a>
                <a className="" onClick={changeUsers} data-item="users">Users</a>
                <a className="" onClick={changeUsers} data-item="advance">Advance analytics</a>
              </div>
            </div>


            {ShowRealTime === true &&
              <div className="row">
                <div className="row card_top_row">
                  <div className="col card_view_analytics">
                    <div className="row top_row_head_analytics">
                      <span>Live Consultations</span>
                    </div>

                    <div className="row admin_table_div_analytics">
                      {RealTimeConsulation.length > 0 && RealTimeConsulation.map((liveconsult, index) => (
                        <div className="list_live_consulation">
                          <span>Coach: {liveconsult.coach.first_name + ' ' + liveconsult.coach.last_name}</span>
                          <span>Client: {liveconsult.client.first_name + ' ' + liveconsult.client.last_name}</span>
                          <span>Meeting title: {liveconsult.appointment.meeting_description}</span>
                          <span>Started Time: {format(parseISO(timetoUtcClientAppointment(liveconsult.appointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</span>
                          <span>End Time: {format(parseISO(timetoUtcClientAppointment(liveconsult.appointment.end_time)), `MMM dd, yyyy  hh:mm a`)}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col card_view_analytics">
                    <div className="row top_row_head_analytics">
                      <span> Experts Live</span>
                    </div>
                    <div className="admin_table_div admin_table_div_analytics">
                      <table className='admin_table'>
                        <thead>
                          <tr>
                            <th className='row_name'>Name</th>
                            <th className='row_name'>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CoachActiveUsers.length > 0 && CoachActiveUsers.map((user, index) => (
                            <tr>
                              <td>
                                <span className='c-avatar'>
                                  <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                    (user.logged_in_status === '1')
                                      ? <span className='c-avatar__status'></span>
                                      : null
                                  }
                                  <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                                </span>
                              </td>
                              <td className='table_name_val'>{user.email}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }


            {ShowUserTab === true &&
              <div className="row usertab">
                <div className="chart_row_analytics">
                  <div className="row chart_row_analytics_top_head">
                    Users Onboarded
                  </div>

                  <div className='wholesection'>
                    <div className="section1 Tob_button">
                      <div className='earning_tab'>
                        <span className='active pointer_eff tab_show'>Coach</span>
                      </div>
                      <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={EarningsDropChange}>
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_year">This Year</option>
                      </select>
                    </div>

                    <div className="users_chart">
                      <AdminChart Chartlabels={Chartlabels} ChartDatas={ChartDatas} datasetpass={'Users onboarded'} />
                    </div>
                  </div>
                </div>


                <div className="row filter_row_analytics">
                  <div className="col">
                    <input type="text" className='commission_inner_input' id="filter_name" placeholder="By Name ,email" onChange={(e) => { SetFilterName(e.target.value) }} />
                  </div>
                  <div className="col">
                    <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin' onChange={(e) => { SetFilterRole(e.target.value) }} id="filter_role"   >
                      <option value="Nutrition">Coach</option>
                    </select>
                  </div>
                  <div className="col date_filter_analytics_col" style={{ display: 'inherit' }}>
                    <label style={{ fontSize: '12px', paddingRight: '9px' }}>Joined date</label>
                    <span className="calendar_icon_div analytics_fif">
                      <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                    </span>
                    <DatePicker selected={startDate} ref={datepicker1Ref} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                      e.preventDefault();
                    }} onChange={(date) => ChangedDate(date)} />
                  </div>
                  <div className="col">
                    <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin' onChange={(e) => { SetFilterStatus(e.target.value) }} defaultValue={FilterStatus} id="filter_status">
                      <option value="">Select status</option>
                      <option value="1">Banned</option>
                      <option value="0">Not Banned</option>
                    </select>
                  </div>

                  <div className="col">
                    <button className='filter_analytics_button' onClick={(FilterUserListsTrigger)}>Filter</button>
                    <button className='filter_analytics_button' onClick={ResetFilterUserList} style={{ right: '78px' }}>Reset</button>
                  </div>
                </div>

                <div className="row upcoming_appointment_table_row">
                  <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                    <Thead className="thead">
                      <Tr className="trow">
                        <Th scope="col">First Name</Th>
                        <Th scope="col">Last Name</Th>
                        <Th scope="col">Email</Th>
                        <Th scope="col">Role</Th>
                        <Th scope="col">Joined Date</Th>
                        <Th scope="col">Is Banned</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="tbody">
                      {ListOfUsers.length > 0 && ListOfUsers.map((user, index) => (
                        <Tr className="trow">
                          <Td>{user.first_name}</Td>
                          <Td>{user.last_name}</Td>
                          <Td >{user.email}</Td>
                          <Td>{user.role === 'Nutrition' ? 'Coach' : user.role}</Td>
                          <Td>{format(parseISO(timetoUtcClientAppointment(user.createdAt)), `MMM dd, yyyy  hh:mm a`)}</Td>
                          <Td>{user.isBan === 1 ? 'Yes' : 'No'}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  <div>
                    <Pagination
                      activePage={Pagenumber}
                      itemsCountPerPage={PerPage}
                      totalItemsCount={TotalRecordUsersList}
                      onChange={changePageNumber.bind(this)}
                      prevPageText="<"
                      nextPageText=">"
                      hideFirstLastPages={true}
                      linkClassPrev="pagination_prev"
                      linkClassNext="pagination_next"
                      linkClass="pagenumbers grayfont"
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </section>
    </div>
  )
}
export default ManagerAnalytics
import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import QuizPlanPopup from "./ClientComponents/QuizPlanPopup";

var QB = require('quickblox/quickblox.min');

const ClientNav = (props) => {   



    const [messageNew, setmessageNew] = useState();

    const SyncWithings=()=>{
       

        // const Notresponse =axiosJWT.get('/withings/get-data?type=weight', {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // });

    }

    useEffect(() => {
        setmessageNew(props.data);
        SyncWithings()
    }, [props.data]);   

    const token = localStorage.getItem("token");
    const [Subscribed, SetSubscribed] = useState(false)
    const subscriptionEdate = localStorage.getItem("subscriptionEdate");
    const loggedin_userid = localStorage.getItem('userId');

    var utcMoment = moment.utc();
    utcMoment = moment(utcMoment).format("YYYY-MM-DD HH:mm:ss");

    const axiosJWT = axios.create();

    useEffect(() => {
        if (subscriptionEdate !== 'null') {
            if (Date.parse(subscriptionEdate) < Date.parse(utcMoment)) {
                SetSubscribed(true);
            }
        }
        else {
            SetSubscribed(true);
        }
    });    

    const disable_msgnew = () => {
        setmessageNew(false);
        disable_qb_chat_connect();
    }

    const disable_qb_chat_connect = () =>{
        QB.chat.disconnect();
    }

    return (
        <div>
            <QuizPlanPopup />
            <ul className="side_nav_class" style={{ paddingLeft: '0rem', paddingBottom: '100px' }}>
                <li ><NavLink to='/client/dashboard' activeclassname="active"><i className="fa fa-home sidenav_icon" aria-hidden="true"></i><span className="side-navli">Dashboard</span></NavLink></li>
                <li ><NavLink to='/client/appointment' activeclassname="active"><i className="fa fa-calendar-check-o sidenav_icon" aria-hidden="true"></i><span className=" ">Appointment</span></NavLink></li>


                {(Subscribed === false && props.glpuser===true) ?
                        <li ><NavLink to='/client/beluga-health' activeclassname="active"><i className="fa fa-user-md sidenav_icon" aria-hidden="true"></i><span className="side-navli">Beluga Health</span></NavLink></li> :
                    <li ><NavLink to='/client/beluga-health' activeclassname="active"><i className="fa fa-lock sidenav_icon sidenav_icon" aria-hidden="true"></i><span className="side-navli">Beluga Health</span></NavLink></li>
                }

            

                <li ><NavLink to='/client/report' activeclassname="active"><i className="fa fa-file-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Report</span></NavLink></li>
                <li ><NavLink to='/client/agreements' activeclassname="active"><i className="fa fa-clipboard sidenav_icon" aria-hidden="true"></i><span className="side-navli">Documents</span></NavLink></li>
                <li ><NavLink to='/client/messages' activeclassname="active"><i className="fa fa-envelope sidenav_icon" aria-hidden="true"></i><span className="side-navli" onClick={disable_msgnew}>Messages <sup className="msgnew">{messageNew === true ? "(New)" : null}</sup></span></NavLink></li>
                <li ><NavLink to='/client/meal-plan' activeclassname="active"><i className="fa fa-calendar-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Coach Meal Plan</span></NavLink></li>
                <li ><NavLink to='/client/week-plan' activeclassname="active"><i className="fa fa-calendar-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">AI-Meal Plan</span></NavLink></li>

                {Subscribed === false ?
                    <li ><NavLink to='/client/food-diary' activeclassname="active"><i className="fa fa-newspaper-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food diary</span></NavLink></li> :
                    <li ><NavLink to='/client/food-diary' activeclassname="active"><i className="fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food diary</span></NavLink></li>
                }

                {Subscribed === false ?
                    <li ><NavLink to='/client/progress-diary' activeclassname="active"><i className="fa fa-file-text-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Progress Diary</span></NavLink></li> :
                    <li ><NavLink to='/client/progress-diary' activeclassname="active"><i className="fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Progress Diary</span></NavLink></li>
                }

                {Subscribed === false ?
                    <li ><NavLink to='/client/blogs' activeclassname="active"><i className="fa fa-file-o sidenav_icon" aria-hidden="true"></i><span className="side-navli">Blogs</span></NavLink></li> :
                    <li ><NavLink to='/client/blogs' activeclassname="active"><i className="fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Blogs</span></NavLink></li>                    
                }

                <li ><NavLink to='/client/food-journal' activeclassname="active"><i className="fa fa-cutlery sidenav_icon" aria-hidden="true"></i><span className="side-navli">Food Journal</span></NavLink></li>

                {Subscribed === false ?
                    <li ><NavLink to='/client/fitness-videos' activeclassname="active"><i className="fa fa-play sidenav_icon" aria-hidden="true"></i><span className="side-navli">Fitness videos</span></NavLink></li> :
                    <li ><NavLink to='/client/fitness-videos' activeclassname="active"><i className="fa fa-lock sidenav_icon" aria-hidden="true"></i><span className="side-navli">Fitness videos</span></NavLink></li>                    
                }

                  {/* <li ><NavLink to='/client/directories' activeclassname="active"><i className="fa fa-file sidenav_icon" aria-hidden="true"></i><span className="side-navli">Files</span></NavLink></li> */}

                <li ><NavLink to='/client/keto-book' activeclassname="active"><i className="fa fa-book sidenav_icon" aria-hidden="true"></i><span className="side-navli">Keto 101</span></NavLink></li>
                <li ><NavLink to='/client/subscriptions' activeclassname="active"><i className="fa fa-rocket sidenav_icon" aria-hidden="true"></i><span className="side-navli">Subscriptions</span></NavLink></li>
            </ul>
        </div>
    )
}

export default ClientNav
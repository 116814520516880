import React, { useEffect, useState } from "react";
import axios from 'axios';

const EditImportedRecipe = (props) => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");

    const [RecipeImg, SetRecipeImg] = useState('')
    const [RecipeName, SetRecipeName] = useState('')
    const [RecipeCategory, SetRecipeCategory] = useState('')
    const [RecipeTime, SetRecipeTime] = useState('')
    const [RecipeServings, SetRecipeServings] = useState('')
    const [RecipeInstructions, SetRecipeInstructions] = useState('')
    const [RecipeIngredients, SetRecipeIngredients] = useState('')
    const [RecipeCalories, SetRecipeCalories] = useState('')
    const [RecipeProteins, SetRecipeProteins] = useState('')
    const [RecipeFats, SetRecipeFats] = useState('')
    const [RecipeCarbs, SetRecipeCarbs] = useState('')
    const [RecipeComments, SetRecipeComments] = useState('')
    const [RecipeUrl, SetRecipeUrl] = useState('')
    const [Recipeuid,SetRecipeuid]=useState('')


    const [RecipeImgErr, SetRecipeImgErr] = useState(false)
    const [RecipeImgErrMsg, SetRecipeImgErrMsg] = useState('')
    const [ChangedImgStatus,SetChangedImgStatus]=useState(false)


    const CloseImportRecipeSection = () => {
        props.HandleCloseEditRecipe()
    }
   

    const LoadData = (data) => {
        console.log(data)
        if (data.recipe_name !== null) {
            SetRecipeName(data.recipe_name)
        }
        if (data.recipe_category !== null) {
          
            SetRecipeCategory(data.recipe_category)
        }
        if (data.recipe_time !== null) {
            SetRecipeTime(data.recipe_time)
        }
        if (data.recipe_servings !== null) {
            SetRecipeServings(data.recipe_servings)
        }
        if (data.recipe_instructions !== null) {
            SetRecipeInstructions(data.recipe_instructions)
        }
        if (data.recipe_ingredients !== null) {
            SetRecipeIngredients(data.recipe_ingredients)
        }
        if (data.recipe_calories !== null) {
            SetRecipeCalories(data.recipe_calories)
        }
        if (data.recipe_proteins !== null) {
            SetRecipeProteins(data.recipe_proteins)
        }
        if (data.recipe_fats !== null) {
            SetRecipeFats(data.recipe_fats)
        }
        if (data.recipe_carbs !== null) {
            SetRecipeCarbs(data.recipe_carbs)
        }
        if (data.recipe_comments !== null) {
            SetRecipeComments(data.recipe_comments)
        }
        if (data.recipe_source_url !== null) {
            SetRecipeUrl(data.recipe_source_url)
        }
        if(data.recipe_image!==null && data.recipe_image!=='')
        {
            SetRecipeImg(data.recipe_image)
        }
        if(data.recipe_uid!==null && data.recipe_uid!=='')
        {
            SetRecipeuid(data.recipe_uid)
        }
        
    }

    const SetBlogImage=(event)=>{
        SetRecipeImgErr(false)
        const file = event.target.files[0];

        if (!/^image\//.test(file.type)) {
            SetRecipeImgErr(true)
            SetRecipeImgErrMsg(`File ${file.name} is not an image.`)
            return false;
        }
        else{
          
            var reader = new FileReader();
            reader.onloadend = function() {
                SetRecipeImg(reader.result)
                SetChangedImgStatus(true)
                
              }
              reader.readAsDataURL(file);

        }

    }

    const UpdateRecipe = async() => {

          ShowLoader()
        const response = await axiosJWT.post('/import-recipe/update-import-recipe-url',
        {
            recipe_image: RecipeImg,
            recipe_name:RecipeName,
            recipe_category:RecipeCategory,
            recipe_time:RecipeTime,
            recipe_servings:RecipeServings,
            recipe_instructions:RecipeInstructions,
            recipe_ingredients:RecipeIngredients,
            recipe_calories:RecipeCalories,
            recipe_proteins:RecipeProteins,
            recipe_fats:RecipeFats,
            recipe_carbs:RecipeCarbs,
            recipe_comments:RecipeComments,
            recipe_source_url:RecipeUrl,
            recipe_img_update:ChangedImgStatus,
            recipe_uid:Recipeuid
            

        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(response.status===200)
        {
            CloseImportRecipeSection()
            HideLoader()
        }





    }

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }

    useEffect(() => {
        window.scrollTo(0, 0)

        LoadData(props.RecipeEditData)

    }, [props])
    return (

        <div>
                    {loader === true && <div id="semiTransparenDivLoader"></div>}
            <div className="importurlsection">
                <div className="closediv">

                    <i className="fa fa-times" aria-hidden="true" onClick={CloseImportRecipeSection}></i>
                </div>

            </div>
            <div className="recipe_overview_section">

                <div className="input_row">
                    <span className="title">Recipe Image</span>
                    <input type={'file'} accept="image/*" onChange={SetBlogImage} />
                    <img className="preview_recipe_img" src={RecipeImg} />
                    {
                        RecipeImgErr === true &&
                        <span className="err">{RecipeImgErrMsg}</span>
                    }
                </div>

                <div className="input_row">
                    <span className="title">Recipe Name</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeName} onChange={(e) => { SetRecipeName(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Category</span>
                    <select className="select_drop" defaultValue={RecipeCategory} onChange={(e) => { SetRecipeCategory(e.target.value) }} value={RecipeCategory} >
                        <option value={'Breakfast'}>Breakfast</option>
                        <option value={'Lunch'}>Lunch</option>
                        <option value={'Dinner'}>Dinner</option>
                        <option value={'Snacks'}>Snacks</option>
                    </select>
                    {/* <input type={'text'} className={'input_text'} defaultValue={RecipeCategory} onChange={(e) => { SetRecipeCategory(e.target.value) }} /> */}
                </div>

                <div className="input_row">
                    <span className="title">Preparation time(Total minutes)</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeTime} onChange={(e) => { SetRecipeTime(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Servings</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeServings} onChange={(e) => { SetRecipeServings(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Preparation/Cooking Instructions</span>
                    <textarea className="input_textarea" rows={'8'} placeholder={'e.g. 1. heat oven, 2.  boil water, 3. cook for 25 minutes, etc'} defaultValue={RecipeInstructions} onChange={(e) => { SetRecipeInstructions(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Recipe Ingredients</span>
                    <textarea className="input_textarea" rows={'12'} placeholder={'e.g. 1. heat oven, 2.  boil water, 3. cook for 25 minutes, etc'} defaultValue={RecipeIngredients} onChange={(e) => { SetRecipeIngredients(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Calories</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeCalories} onChange={(e) => { SetRecipeCalories(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Proteins</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeProteins} onChange={(e) => { SetRecipeProteins(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Fats</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeFats} onChange={(e) => { SetRecipeFats(e.target.value) }} />
                </div>

                <div className="input_row">
                    <span className="title">Carbs</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeCarbs} onChange={(e) => { SetRecipeCarbs(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Additional comments</span>
                    <textarea className="input_textarea" rows={'6'} placeholder={'e.g. you can replace cheese with butter if necessary'} defaultValue={RecipeComments} onChange={(e) => { SetRecipeComments(e.target.value) }} />
                </div>
                <div className="input_row">
                    <span className="title">Source URL</span>
                    <input type={'text'} className={'input_text'} defaultValue={RecipeUrl} onChange={(e) => { SetRecipeUrl(e.target.value) }} />
                </div>



            </div>
            <div className="recipe_action_section">

                <button className="save_recipe_button" onClick={UpdateRecipe}>Update Recipe</button>

            </div>

        </div>

    )
}
export default EditImportedRecipe
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const VitalHeartRate = (props) => {
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess, SetsaveAddSuccess] = useState(false)
    const [HeartRate,SetHeartRate]=useState('')
    const [HeartRateErr,SetHeartRateErr]=useState(false)
    const [TagType,SetTagType]=useState('')
    const [TagTypeErr,SetTagTypeErr]=useState(false)

    const SaveVitalHearRate=async(e)=>{
        
        const action=e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowVitalHeartRatefield(false)
            props.SetVitalOption(true)
            return false
        }
        SetTimeErr(false)
        SetDateErr(false)
        SetHeartRateErr(false)
        SetTagTypeErr(false)
        var err=false;
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(HeartRate==='')
        {
            err=true;
            SetHeartRateErr(true)
        }
        if(TagType==='')
        {
            err=true;
            SetTagTypeErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }
            const response = await axiosJWT.post('add-data/add-vitals',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Vital Heart Rate',
                'heart_rate':HeartRate,
                'rate_type':TagType,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowVitalHeartRatefield(false)
                    props.SetVitalOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }

        }

    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }


    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Vitals > Heart rate'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                    />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Heart Rate(bpm)</div>
                <div className="field">
                    <input type={'number'} className="raw_input" onChange={(e) => { SetHeartRate(e.target.value) }} {...(HeartRateErr === true ? { id: 'err_border' } : {})} defaultValue={HeartRate} />
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Tags</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetTagType(e.target.value) }} defaultValue={TagType} {...(TagTypeErr === true ? { id: 'err_border' } : {})}  >
                        <option value="">Select tag</option>
                        <option value="Resting">Resting</option>
                        <option value="Before Workout">Before workout</option>
                        <option value="During Workout">During workout</option>
                        <option value="After Workout">After workout</option>
                        <option value="Post Workout">Post workout</option>
                  
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>
            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveVitalHearRate(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveVitalHearRate(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveVitalHearRate(e) }} >
                    {'Save'}
                </Button>

           </div>
            {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }

        </div>
    )

}
export default VitalHeartRate
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import QuizForm from "./Quiz/QuizForm";

const QuizPlanPopup = () => {

    const [QuizCheck, SetQuizCheck] = useState(true);
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const navigate = useNavigate();
    const [ShowQ, SetShowQ] = useState(false);

    const CheckQuiz = async () => {

        const GetQuizDetails = await axiosJWT.get("/user",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (GetQuizDetails.status === 200) {
            if (GetQuizDetails.data.client_infos !== null) {
                if (GetQuizDetails.data.client_infos[0].is_quiz !== undefined) {
                    if (GetQuizDetails.data.client_infos[0].is_quiz === 1) {
                        SetQuizCheck(false);
                    }
                }
            }
        }
    }

    const Navigate = () => {
        // navigate("/client/add-quiz", { replace: true });
        SetQuizCheck(true);
        SetShowQ(true);
    }

    useEffect(() => {
        CheckQuiz();
    }, []);

    return (
        <div>
            {
                QuizCheck === false && <div id="overlay">

                    <Modal show={true} className="confirm_delete premim_plan_upgrade">
                        <Modal.Header>
                            <Modal.Title>{'Take a Quiz'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please take a time and fill quiz for better experience
                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button className="modal_action_button float_right" variant="primary" onClick={Navigate} >
                                Proceed
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }

            <Modal show={ShowQ} className="confirm_delete premim_plan_upgrade">
                <Modal.Header>
                    <Modal.Title>{'Quiz Form'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main_container_quiz">
                        <div className="top_logo">
                            <img src={''} />
                        </div>
                        <div>
                            <QuizForm />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default QuizPlanPopup
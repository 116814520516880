import React, { useEffect, useState } from "react";
import Datepicker from 'react-date-picker';

const BasicInfo = (props) => { 

    const [DobValue,SetDobValue]=useState(new Date());

    const [DummyHeightFt,SetDummyHeightFt]=useState('');
    const [DummyHeightIn,SetDummyHeightIn]=useState('');
    const [DummyWeight,SetDummyWeight]=useState('');
    const [DummyTWeight,SetDummyTWeight]=useState('');


    const ChangedEndDate=(date)=>{
        props.SetDob(date);
        SetDobValue(date);
    }

    const Setvalue=(e)=>{

        const Target=e.target.getAttribute('data-target');
       
        if(Target==='height_ft')
        {
           props.SetHeightFt(e.target.value);
        }
        else if(Target==='height_in')
        {
            props.SetHeightIn(e.target.value);
        }
        else if (Target==='height_we')
        {
            props.SetWeightData(e.target.value);
        }
        else if(Target==='height_twe')
        {
            props.SetTargetWeightData(e.target.value);
        }
    }

    useEffect(()=>{

        if(props.HeightFt)
        {
          SetDummyHeightFt(props.HeightFt);
        }
        if(props.HeightIn)
        {
            SetDummyHeightIn(props.HeightIn);
        }
        if(props.WeightData)
        {
            SetDummyWeight(props.WeightData);
        }
        if(props.TargetWeightData)
        {
            SetDummyTWeight(props.TargetWeightData);
        }
        if(props.Dob)
        {
            SetDobValue(props.Dob);
        }
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(true);
        }
    },[]);

    

    return (
        <div className="personal">
            <div className="heading_row">
               Imperial (U.S)
            </div>
            <div className="inputs_row">
                <div className="full">
                    <div className="dob">
                    <label className="label required">DOB:</label>
                    <Datepicker
                        value={DobValue}
                        onChange={ChangedEndDate.bind(this)}
                        format="MM-dd-yyyy"
                        className={'date_picker_payment_coach'}                     
                         />
                    </div>                  
                </div>

                <div className="full">
                    <div className="">
                    <label className="label required">Height(ft):</label>
                    <input type={'number'} defaultValue={DummyHeightFt}  data-target="height_ft" onChange={(e)=>{Setvalue(e)}}/>
                    </div>                    
                </div>

                <div className="full">
                <div className="">
                    <label className="label required" >Height(inch):</label>
                    <input type={'number'} data-target="height_in" defaultValue={DummyHeightIn}  onChange={(e)=>{Setvalue(e)}}/>
                    </div>
                </div>               

                <div className="full">
                    <div className="">
                    <label className="label required" >Weight(lbs):</label>
                    <input type={'number'} data-target="height_we" defaultValue={DummyWeight}  onChange={(e)=>{Setvalue(e)}}/>
                    </div>
                </div>

                <div className="full">
                <div className=""> 
                    <label className="label required" >Target weight(lbs):</label>
                    <input type={'number'} style={{position:'absolute'}} data-target="height_twe"  onChange={(e)=>{Setvalue(e)}} defaultValue={DummyTWeight}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BasicInfo
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RecipeOverView from "./RecipeOverView";


const ImportRecipeUrl = (props) => {

    console.log(props)
    var [tabpage, setTabMenu] = useState('overview');
    const [RecipeData, SetRecipeData] = useState()
    const [RecipeUrl, SetRecipeUrl] = useState()

    const CloseImportRecipeSection = () => {
        props.SetImportUrlShow(false)
    }
    const ChangeImportRecipeTab = (e) => {
        const tab_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        setTabMenu(tab_for);

    }

    const ClearSearchValue = () => {
        props.SetSearchvalue('')
    }

    useEffect(() => {
        SetRecipeData(props.ImportRecipeUrlData)
        SetRecipeUrl(props.ImportUrlValue)
    }, [props])

    return (
        <div className="importurlsection">
            <div className="closediv">

                <i className="fa fa-times" aria-hidden="true" onClick={CloseImportRecipeSection}></i>
            </div>
            <div>

                {(tabpage === 'overview') ? <RecipeOverView RecipeData={RecipeData} RecipeUrl={RecipeUrl} CloseImportRecipeSection={CloseImportRecipeSection} ClearSearchValue={ClearSearchValue} /> : null}
            </div>
        </div>
    )
}
export default ImportRecipeUrl
import React, { useEffect, useState, useRef } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";


const CoachEarnings=(props)=>{


    const [TotalEarnings, SetTotalEarnings] = useState(0)
    const [AmountOnhold, SetAmountOnhold] = useState(0)
    const [StripeWithDrawBalance, SetStripeWithDrawBalance] = useState(0)
    const [EarningsCurrentMonth, SetEarningsCurrentMonth] = useState(0)
    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const d = new Date();

    const EarningsInfo=async(NutritionId)=>{


        const response = await axiosJWT.get('payments/get-payments-info?coach_id='+NutritionId+'', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {


            if (response.data.TotalEarnings[0]) {
                if (response.data.TotalEarnings[0].billing_amount !== undefined) {
                    SetTotalEarnings((response.data.TotalEarnings[0].billing_amount).toFixed(2))
                }
            }



           if(response.data.AmountOnhold!==undefined)
           {
            SetAmountOnhold(response.data.AmountOnhold)
           }
          
            SetStripeWithDrawBalance(response.data.WithDrawbleBalance)

            if (response.data.NutritionistBillingPayment.length > 0) {


                if (response.data.NutritionistBillingPayment[0].billing_amount !== undefined) {
                    SetEarningsCurrentMonth((response.data.NutritionistBillingPayment[0].billing_amount).toFixed(2))
                }

            }
            // SetWithdrawableBalance(response.data.amount_can_be_withdrwan)
        })

        

  
    }

    useEffect(()=>{
        if(props.ClientId)
        {
            EarningsInfo(props.ClientId)
        }

    },[props])


    return(
        <div className="admin_coach_dashboard">
            <div className="top_row_earnings">
                <div className="items">
                    <div className="label">Total earnings</div>
                    <div className="amount">{'$ '+TotalEarnings}</div>
                </div>
                <div className="items">
                    <div className="label">Earnings in {monthNames[d.getMonth()]}</div>
                    <div className="amount">{'$ '+EarningsCurrentMonth}</div>
                </div>
                <div className="items">
                    <div className="label">Amount on hold(stripe)</div>
                    <div className="amount">{'$ '+AmountOnhold}</div>
                </div>
                <div className="items">
                    <div className="label">Withdrawable balance(stripe)</div>
                    <div className="amount">{'$ '+StripeWithDrawBalance}</div>
                </div>
            </div>
        </div>
    )
}
export default CoachEarnings
import React, { useEffect } from "react";

const Affected = (props) => {


    const CheckPhysical = (e) => {

        var tabLink = document.querySelectorAll(".option_div")

        tabLink.forEach(box => {

            box.classList.remove('active');
        });
        e.target.closest('.option_div').classList.add("active");
        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");
        if (category === "") {
            props.DisableNextButton();
          } else {
            props.SetAffectedData(category);
            props.EnableNextButton();
          }
    }

    useEffect(() => {
        setTimeout(() => {
    
          if (props.AffectedData) {
            if (props.AffectedData !== "") {
             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.AffectedData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{
             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading" style={{ maxWidth: '100%' }}>
                Are you affected by the following?</span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target='Excessive Thirst' onClick={CheckPhysical}><p>Excessive Thirst</p></div>
                <div className="option_div" data-target="Frequent urination" onClick={CheckPhysical}><p>Frequent urination</p></div>
                <div className="option_div" data-target="Blurry vision" onClick={CheckPhysical}><p>Blurry vision</p></div>
                <div className="option_div" data-target="None" onClick={CheckPhysical}><p>None</p></div>
            </div>

        </div>
    )
}
export default Affected
import React, { useEffect, useState } from "react";

const SelfReportedMedsCM = (props) => {

 
  const [SelfReportedMeds, SetSelfReportedMeds] = useState("");
 

  useEffect(() => {
    if (props.SetShowSubmitButton) {
      props.SetShowSubmitButton(false);
    }

    
    if (props.SelfReportedMedsPc) {
        SetSelfReportedMeds(props.SelfReportedMedsPc);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.SetSelfReportedMedsPc(SelfReportedMeds);
   

      var Err = 0;
      if (SelfReportedMeds === "") {
        Err = 1;
      } 
      if (Err == 0) {
        props.EnableNextButton();
      } else {
        props.DisableNextButton();
      }
    }, 1);
  }, [SelfReportedMeds]);

  useEffect(()=>{

   
    props.SetPreviousMenu('allergies')
  
    props.SetNextMenu('theseapply')
     
  },[])

  return (
    <div className="daily_activity">
      <div className="head_row"><h6>{props.SelfReportedMedsPcLabel}</h6>       <span style={{color:'red'}}>*</span></div>
        <div className="input_row">
          <div className="textarea_row">
            <textarea
            rows={6}
              defaultValue={SelfReportedMeds}
              value={SelfReportedMeds}
              onChange={(e) => {
                SetSelfReportedMeds(e.target.value);
              }}
              placeholder="Leave a description"
            ></textarea>
          </div>
        </div>
    
    </div>
  );
};
export default SelfReportedMedsCM;

import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import DummyrecipeImg from '../../../images/pexels-photo-1640777.jpeg'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RecipePreview from "./RecipePreview";
import EditImportedRecipe from "./EditRecipe";
const ImportedRecipeList = (props) => {

    const axiosJWT = axios.create();
    const token = localStorage.getItem("token");
    const [PageNumber, SetPageNumber] = useState(1);
    const [PerPage, SetPerPage] = useState(50)
    const [TotalRecord, SetTotalRecord] = useState(0);
    const [RecipeList, SetRecipeList] = useState([])
    const [DeleteRecipeId, SetDeleteRecipeId] = useState('')
    const [showsdeleterecipe, Setshowsdeleterecipe] = useState(false)
    const [SearchValue, SetSearchValue] = useState('')
    const handleClosesdeleterecipe = () => {
        Setshowsdeleterecipe(false)
    }
    const [RecipePreviewShow, SetRecipePreviewShow] = useState(false)
    const [RecipePreviewData, SetRecipePreviewData] = useState([])

    const [RecipeEditShow, SetRecipeEditShow] = useState(false)
    const [RecipeEditData, SetRecipeEditData] = useState([])
    
    const [ShowRecipeList,SetShowRecipeList]=useState(true)

    const HandleCloseEditRecipe=async()=>{



        ShowLoader()

        SetRecipeEditShow(false)
        SetShowRecipeList(true)
        await RecipeListData(PageNumber)
        HideLoader()
    }

    const RecipeListData = async (pagenumber, search = '') => {
        SetPageNumber(pagenumber)


        const response = await axiosJWT.get('import-recipe/list-imported-recipe-url?limit=' + PerPage + '&offset=' + pagenumber + '&search=' + search + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (response.data !== null) {
            SetTotalRecord(response.data.count)
            if (response.data.count > 0) {
                SetRecipeList(response.data.list)

            }
            else {
                SetRecipeList([])
            }

        }
        else {
            SetRecipeList([])
        }

    }

    const changePageNumber = async (pagenumber) => {

        RecipeListData(pagenumber, SearchValue);
    }

    const DeleteRecipe = async (e) => {
        const action = e.target.getAttribute('data-action')
        if (action === 'trigger') {
            const RecipeId = e.target.getAttribute('data-recipeid')
            SetDeleteRecipeId(RecipeId)
            Setshowsdeleterecipe(true)
        }
        if (action === 'confirm') {
            const response = await axiosJWT.post('/import-recipe/delete-imported-recipe-url',
                {
                    id: DeleteRecipeId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.status === 200) {
                handleClosesdeleterecipe()
                RecipeListData(PageNumber)
            }
        }



    }

    const ViewRecipe = async (e) => {

        const RecipeId = e.target.getAttribute('data-id')

        const response = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {
            SetRecipePreviewData(response.data.recipe)
            SetRecipePreviewShow(true)
        }


    }

    const EditRecipe=async(e)=>{
        const RecipeId = e.target.getAttribute('data-id')
        const response = await axiosJWT.get("/import-recipe/detail-imported-recipe-url?id=" + RecipeId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 200) {
            SetRecipeEditData(response.data.recipe)
            SetRecipeEditShow(true)
            SetShowRecipeList(false)

        }
        
    }

    useEffect(() => {

        console.log(props.Searchvalue)

        if (props.Searchvalue !== '') {

            RecipeListData(1, props.Searchvalue)
            SetSearchValue(props.Searchvalue)
        }
        else {
            RecipeListData(1)
            SetSearchValue('')
        }


    }, [props])

    const [loader, SetloaderShow] = useState(false)
    const ShowLoader = () => { SetloaderShow(true) }
    const HideLoader = () => { SetloaderShow(false) }


    return (

        <div>
              {loader === true && <div id="semiTransparenDivLoader"></div>}
            {ShowRecipeList===true ?
        <div className="imported_recipe_list">
            <div className="table_section">
                <Table className='table is-striped is-fullwidth'>
                    <Thead className='thead'>
                        <Tr className="trow">
                            <Th className='row_name'>Recipe uid</Th>
                            <Th className='row_name'>Recipe name</Th>
                            <Th className='row_name'>Recipe image</Th>
                            <Th className='row_name'>Recipe Prep.time</Th>
                            <Th className='row_name'>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='tbody'>


                        {RecipeList.length > 0 && RecipeList.map((Recipes, index) => (
                            <Tr className='trow'>
                                <Td>{Recipes.recipe_uid}</Td>
                                <Td>{Recipes.recipe_name}</Td>

                                {Recipes.recipe_image !== '' && <Td><img className="td_recipe_img" src={ Recipes.recipe_image} /></Td>}

                                {Recipes.recipe_image === '' && <Td></Td>}



                                <Td>{Recipes.recipe_time !== '' && Recipes.recipe_time + ' mins'}</Td>

                                <Td><i className="fa fa-eye import_recipe_list_aciton_i" aria-hidden="true" data-id={Recipes.recipe_uid} onClick={(e) => { ViewRecipe(e) }}></i>
                                    <i className="fa fa-edit import_recipe_list_aciton_i" aria-hidden="true" data-id={Recipes.recipe_uid} onClick={(e)=>{EditRecipe(e)}}></i>
                                    <i className="fa fa-trash import_recipe_list_aciton_i" data-recipeid={Recipes.recipe_uid} data-action="trigger" aria-hidden="true" onClick={(e) => { DeleteRecipe(e) }}></i>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

            </div>
            <div>
                {TotalRecord ?
                    <Pagination
                        activePage={PageNumber}
                        itemsCountPerPage={PerPage}
                        totalItemsCount={TotalRecord}
                        onChange={changePageNumber.bind(this)}
                        prevPageText="<"
                        nextPageText=">"
                        hideFirstLastPages={true}
                        linkClassPrev="pagination_prev"
                        linkClassNext="pagination_next"
                        linkClass="pagenumbers grayfont"
                    />
                    : null}
            </div>

            <Modal show={showsdeleterecipe} onHide={handleClosesdeleterecipe} className="coachservice_delete confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    Are you sure want to delete this recipe?<br />
                    <br />


                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button className="modal_close_button" variant="secondary" onClick={handleClosesdeleterecipe}>
                        Cancel
                    </Button>
                    <Button className="modal_action_button float_right" data-action="confirm" variant="primary" onClick={(e) => { DeleteRecipe(e) }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

           
            </div> : null }

            {
                RecipePreviewShow === true ? <RecipePreview RecipePreviewShow={RecipePreviewShow} SetRecipePreviewShow={SetRecipePreviewShow} RecipePreviewData={RecipePreviewData} /> : null
            }
            {
                RecipeEditShow === true ? <EditImportedRecipe RecipeEditData={RecipeEditData} HandleCloseEditRecipe={HandleCloseEditRecipe} /> : null
            }

        </div>
    )
}
export default ImportedRecipeList
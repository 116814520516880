import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const MetabolismKetoneUrine = (props) => {

    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')
    
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess,SetsaveAddSuccess]=useState(false)
    const [BloodDailyLabel,SetBloodDailyLabel]=useState('Ketone-Urine')
    const [BloodDailyValue, SetBloodDailyValue] = useState('0')
    const [BloodDailyErr, SetBloodDailyErr] = useState('')
    const [BloodWakeUp, SetBloodWakeUp] = useState(false)
    const [BloodFasting, SetBloodFasting] = useState(false)
    const [BloodMealType, SetBloodMealType] = useState('')
    const [BloodExercise, SetBloodExercise] = useState('')
    const [BloodMedication, SetBloodMedication] = useState('')
    const [BloodEveBed, SetBloodEveBed] = useState(false)
    const [BloodRandomRead, SetBloodRandomRead] = useState(false)


    const ChangedCheckboxBloodDaily = (e) => {
        var Checkboytype = e.target.getAttribute('data-id')

        if (Checkboytype === 'wake_up') {
            if (e.target.checked === true) {
                SetBloodWakeUp(true)
            }
            else {
                SetBloodWakeUp(false)
            }

        }
        else if (Checkboytype === 'fasting') {
            if (e.target.checked === true) {
                SetBloodFasting(true)
            }
            else {
                SetBloodFasting(false)
            }
        }
        else if (Checkboytype === 'evening_bedtime') {
            if (e.target.checked === true) {
                SetBloodEveBed(true)
            }
            else {
                SetBloodEveBed(false)
            }
        }
        else if (Checkboytype === 'random_reading') {
            if (e.target.checked === true) {
                SetBloodRandomRead(true)
            }
            else {
                SetBloodRandomRead(false)
            }
        }
      
    }

    const SaveBloodDaily = async (e) => {
        const Action = e.target.getAttribute('data-action')

        if(Action==='cancel')
        {
            props.SetShowMetaKetoUrineField(false)
            props.SetMetabolismOption(true)
            return false
        }
        SetBloodDailyErr(false)
        SetDateErr(false)
        SetTimeErr(false)
       

            var err = false;

            if (BloodDailyValue === '' || BloodDailyValue === null) {
                err = true
                SetBloodDailyErr(true)
            }

            if (AddDataDate === null) {
                err = true
                SetDateErr(true)
            }
            if (TImePicker === null) {
                err = true
                SetTimeErr(true)
            }

            if (err === false) {
                var passDate = ''
                if (AddDataDate != null) {

                    passDate = ChangeDatePickerDate(AddDataDate)


                }
                const response = await axiosJWT.post('add-data/add-metabolism',
                    {

                        'date': passDate,
                        'time': TImePicker,
                        'type': 'Metabolism Ketones Urine',
                        'value': BloodDailyValue,
                        'wakeup': BloodWakeUp,
                        'fasting': BloodFasting,
                        'meal_timing': BloodMealType,
                        'exercise': BloodExercise,
                        'medication': BloodMedication,
                        'eve_night': BloodEveBed,
                        'random_reading': BloodRandomRead,
                        'client_id':IsClientid,
                        'notes': Notes
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if (response.status === 200) {
                        if(Action==='save')
                        {
                            props.SetShowMetaKetoUrineField(false)
                            props.SetMetabolismOption(true)
                      
                        }
                        else if(Action==='save_add')
                        {
                            SetsaveAddSuccess(true)
                            setTimeout(() => {
                                SetsaveAddSuccess(false)   
                            }, 1000);
                        }
                        
                    }

            }



    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }
    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    return (
        <div className="physical_activity">
 <h6 className="heading_popup">{'Metabolism > Ketones-Urine'}</h6>
            <div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>
            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

            <div className="field_top_row">
                <div className="label">{BloodDailyLabel}:</div>
                <div className="field">
                    
                    <select className="raw_select" onChange={(e) => { SetBloodDailyValue(e.target.value) }} defaultValue={BloodDailyValue} {...(BloodDailyErr === true ? { id: 'err_border' } : {})}>
                                    <option value="0">0(Negative)</option>
                                    <option value="5">5(Trace)</option>
                                    <option value="15">15(Small)</option>
                                    <option value="40">40(Moderate)</option>
                                    <option value="80">80(High)</option>
                                    <option value="160">160(Very High)</option>
                    </select>
                </div>
            </div>

            <div className="field_top_row">
              
                <div className="field checkbox_field_in_line">

                    <div className="checkbox">
                    <input type="checkbox" name="wake_up" data-id='wake_up' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={BloodWakeUp} />
                <label className="checkbox_add_data_label">Wake Up</label>
                    </div>

                    <div className="checkbox">
                    <input type="checkbox" name="fasting" data-id='fasting' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={BloodFasting} />
                <label className="checkbox_add_data_label">Fasting(No food for atleast 8 hours)</label>
                    </div>
           
                 </div>
          

            </div>

            <div className="field_top_row">
                <div className="label">Meal Timing</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetBloodMealType(e.target.value) }} defaultValue={BloodMealType}>
                    <option value="">Select Meal Timing</option>
                                    <option value="before">Before</option>
                                    <option value="30 mins">30 mins</option>
                                    <option value="1 hr">1 hr</option>
                                    <option value="2 hr">2 hr</option>
                                    <option value="3 hr">3 hr</option>
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Exercise</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetBloodExercise(e.target.value) }} defaultValue={BloodExercise}>
                    <option value="">Select Exercise</option>
                                    <option value="before">Before</option>
                                    <option value="30 mins">30 mins</option>
                                    <option value="1 hr">1 hr</option>
                                    <option value="2 hr">2 hr</option>
                                    <option value="3 hr">3 hr</option>
                    </select>
                </div>
            </div>

            <div className="field_top_row">
                <div className="label">Medication / Supplment</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetBloodMedication(e.target.value) }} defaultValue={BloodMedication}>
                    <option value="">Select Medication / Supplment</option>
                                    <option value="before">Before</option>
                                    <option value="30 mins">30 mins</option>
                                    <option value="1 hr">1 hr</option>
                                    <option value="2 hr">2 hr</option>
                                    <option value="3 hr">3 hr</option>
                    </select>
                </div>
            </div>

            <div className="field_top_row">
              
              <div className="field checkbox_field_in_line">

                  <div className="checkbox">
                  <input type="checkbox" name="evening_bedtime" data-id='evening_bedtime' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={BloodEveBed} />
              <label className="checkbox_add_data_label">Evening/Bedtime</label>
                  </div>

                  <div className="checkbox">
                  <input type="checkbox" name="random_reading" data-id='random_reading' onChange={(e) => { ChangedCheckboxBloodDaily(e) }} defaultChecked={BloodRandomRead} />
              <label className="checkbox_add_data_label">Random Reading</label>
                  </div>
         
               </div>
        

          </div>

          <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>


            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveBloodDaily(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveBloodDaily(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveBloodDaily(e) }} >
                    {'Save'}
                </Button>

           </div>

           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }


        </div>
    )
}
export default MetabolismKetoneUrine
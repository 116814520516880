/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import female_icon from '../../images/female.png';
import male_icon from '../../images/male.png';
import moment from 'moment';
import Select from 'react-select';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Datepicker from 'react-date-picker';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AdminClientDashboard from './AdminClientDashboard';
import AdminCoachDashboard from './AdminCoachDashboard';
import { Country, State, City }  from 'country-state-city';

const ManageUsers = () => {

    const side_nav = localStorage.getItem("side_nav");
    const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
    const navigate = useNavigate();

    var [users, setUsers] = useState([]);
    const [SpecializedInlist, SetSpecializedInlist] = useState([]);
    var [userDeletePopup, setuserDeletePopup] = useState({ open: false, id: null });
    var [userAddCoachPopup, setAddCoachPopup] = useState({ open: false });
    var [userAddClientPopup, setAddClientPopup] = useState({ open: false });
    var [userEditPopup, setuserEditPopup] = useState({ open: false, id: null, first_name: null, last_name: null, gender: null, email: null, password: null, role: null, isBan: null, user_timezone: null, phone: null, user_address: null, postalCode: null, user_country: null, user_state: null, user_city: null });

    const [user_edit_first_name, setFirst_name] = useState('');
    const [user_edit_last_name, setLast_name] = useState('');
    const [user_edit_email, setEmail] = useState('');
    const [user_edit_password, setPassword] = useState('');
    const [user_edit_role, setRole] = useState('');
    const [user_edit_isBan, setBan] = useState('');
    const [user_edit_gender, setGender] = useState('');
    const [user_edit_timezone, setuser_timezone] = useState('');
    const [user_edit_phone, setPhone] = useState('');
    const [user_edit_country, setCountry] = useState('');
    const [user_edit_state, setState] = useState('');
    const [user_edit_city, setCity] = useState('');
    const [cityList, setcityList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);

    const [activePage, setactivepage] = useState(0);
    const [countPerPage, setcountPerPage] = useState(10);
    const [usersCount, setUsersCount] = useState(0);
    const [paginationFor, setPagination] = useState('allusers');

    const [TimeZone, setTimeZone] = useState('');
    const [timezonesList, settimezonesList] = useState('');

    const FirstNameRefCoach = useRef(null);
    const LastNameRefCoach = useRef(null);
    const EmailRefCoach = useRef(null);
    const PassWordRefCoach = useRef(null);
    const GenderRefCoach = useRef(null);
    const TimeZoneRefCoach = useRef(null);
    const PhoneRefCoach = useRef(null);
    const AddressRefCoach = useRef(null);
    const PostalRefCoach = useRef(null);
    const CountryRefCoach = useRef(null);
    const StateRefCoach = useRef(null);
    const CityRefCoach = useRef(null);

    const FirstNameRefClient = useRef(null);
    const LastNameRefClient = useRef(null);
    const EmailRefClient = useRef(null);
    const PassWordRefClient = useRef(null);
    const GenderRefClient = useRef(null);
    const TimeZoneRefClient = useRef(null);
    const PhoneRefClient = useRef(null);
    const AddressRefClient = useRef(null);
    const PostalRefClient = useRef(null);
    const CountryRefClient = useRef(null);
    const StateRefClient = useRef(null);
    const CityRefClient = useRef(null);

    const EditFnameRef = useRef(null);
    const EditLnameRef = useRef(null);
    const EditEmailRef = useRef(null);
    const EditPassRef = useRef(null);
    const EditRoleRef = useRef(null);
    const EditGenderRef = useRef(null);
    const EditTimeZoneRef = useRef(null);
    const EditPhoneRef = useRef(null);
    const EditAddressRef = useRef(null);
    const EditPostalRef = useRef(null);
    const EditCountryRef = useRef(null);
    const EditStateRef = useRef(null);
    const EditCityRef = useRef(null);

    //validations

    const [CoachFNameErr, SetCoachFNameErr] = useState(false);
    const [CoachFNameMsg, SetCoachFNameMsg] = useState('');

    const [CoachLNameErr, SetCoachLNameErr] = useState(false);
    const [CoachLNameMsg, SetCoachLNameMsg] = useState('');

    const [CoachEmailErr, SetCoachEmailErr] = useState(false);
    const [CoachEmailMsg, SetCoachEmailMsg] = useState('');

    const [CoachPassErr, SetCoachPassErr] = useState(false);
    const [CoachPassMsg, SetCoachPassMsg] = useState('');

    const [CoachGenderErr, SetCoachGenderErr] = useState(false);
    const [CoachGenderMsg, SetCoachGenderMsg] = useState('');

    const [CoachAddressErr, SetCoachAddressErr] = useState(false);
    const [CoachCountryErr, SetCoachCountryErr] = useState(false);
    const [CoachStateErr, SetCoachStateErr] = useState(false);
    const [CoachCityErr, SetCoachCityErr] = useState(false);

    const [CoachPostalErr, SetCoachPostalErr] = useState(false);
    const [CoachPostalMsg, SetCoachPostalMsg] = useState('');

    const [CoachPhoneErr, SetCoachPhoneErr] = useState(false);
    const [CoachPhoneMsg, SetCoachPhoneMsg] = useState('');

    const [ClientFNameErr, SetClientFNameErr] = useState(false);
    const [ClientFNameMsg, SetClientFNameMsg] = useState('');

    const [ClientLNameErr, SetClientLNameErr] = useState(false);
    const [ClientLNameMsg, SetClientLNameMsg] = useState('');

    const [ClientEmailErr, SetClientEmailErr] = useState(false);
    const [ClientEmailMsg, SetClientEmailMsg] = useState('');

    const [ClientPassErr, SetClientPassErr] = useState(false);
    const [ClientPassMsg, SetClientPassMsg] = useState('');

    const [ClientGenderErr, SetClientGenderErr] = useState(false);
    const [ClientGenderMsg, SetClientGenderMsg] = useState('');

    const [ClientAddressErr, SetClientAddressErr] = useState(false);
    const [ClientCountryErr, SetClientCountryErr] = useState(false);
    const [ClientStateErr, SetClientStateErr] = useState(false);
    const [ClientCityErr, SetClientCityErr] = useState(false);

    const [ClientPostalErr, SetClientPostalErr] = useState(false);
    const [ClientPostalMsg, SetClientPostalMsg] = useState('');

    const [ClientPhoneErr, SetClientPhoneErr] = useState(false);
    const [ClientPhoneMsg, SetClientPhoneMsg] = useState('');

    const [EditFNameErr, SetEditFNameErr] = useState(false);
    const [EditFNameMsg, SetEditFNameMsg] = useState('');

    const [EditLNameErr, SetEditLNameErr] = useState(false);
    const [EditLNameMsg, SetEditLNameMsg] = useState('');

    const [EditEmailErr, SetEditEmailErr] = useState(false);
    const [EditEmailMsg, SetEditEmailMsg] = useState('');

    const [EditPassErr, SetEditPassErr] = useState(false);
    const [EditPassMsg, SetEditPassMsg] = useState('');

    const [EditRoleErr, SetEditRoleErr] = useState(false);
    const [EditRoleMsg, SetEditRoleMsg] = useState('');

    const [EditGenderErr, SetEditGenderErr] = useState(false);
    const [EditGenderMsg, SetEditGenderMsg] = useState('');

    const [EditAddressErr, SetEditAddressErr] = useState(false);
    const [EditCountryErr, SetEditCountryErr] = useState(false);
    const [EditStateErr, SetEditStateErr] = useState(false);
    const [EditCityErr, SetEditCityErr] = useState(false);

    const [EditPostalErr, SetEditPostalErr] = useState(false);
    const [EditPostalMsg, SetEditPostalMsg] = useState('');

    const [EditPhoneErr, SetEditPhoneErr] = useState(false);
    const [EditPhoneMsg, SetEditPhoneMsg] = useState('');

    const [CommissionvalueErr, SetComissionvalueErr] = useState(false);
    const [CommissionvalueMsg, SetComissionvalueMsg] = useState('');

    const [MinPayErr, SetMinPayErr] = useState(false);
    const [MinPayMsg, SetMinPayMsg] = useState('');

    const [MaxComErr, SetMaxComErr] = useState(false);
    const [MaxComMsg, SetMaxComMsg] = useState('');

    const [formErr, setformErr] = useState(false);
    const [formErrMsg, setformErrMsg] = useState('');

    const [TimeZoneErr, SetTimeZoneErr] = useState(false);
    const [TimeZoneMsg, SetTimeZoneMsg] = useState('');

    const [AllowedClients, SetAllowedClients] = useState(0);
    const [ExpiryDate, SetExpiryDate] = useState('');
    const [SpecializedIn, SetSpecializedIn] = useState('');
    const [CoachBio, SetCoachBio] = useState('');
    const [ExpiryDateErr, SetExpiryDateErr] = useState(false);
    const [CoachBioErr, SetCoachBioErr] = useState(false);
    const [ShowCoachEditPlan, SetShowCoachEditPlan] = useState(false);

    const [Address, SetAddress] = useState('');
    const [Postal, SetPostal] = useState('');

    const [clientConnectShow, setclientConnectShow] = useState(false);
    const clientConnectClose = () => {setclientConnectShow(false)}
    const [connectClientId, setconnectClientId] = useState('');
    const [coachOptions, setcoachOptions] = useState([]);
    const [existConnectedCoachList, setexistConnectedCoachList] = useState([]);
    const [selectedOptionsCoach, setselectedOptionsCoach] = useState([]);
    const [clientConnectErr, setclientConnectErr] = useState(false);   

    const GetSpecilizedInlistFetch = async () => {
        const response = await axiosJWT.get("/coach-cat/list/active", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.activelist !== null) {
            SetSpecializedInlist(response.data.activelist);
        }
    }

    useEffect(() => {
        document.title = 'Admin-Manage users';
        getUsers();
        GetSpecilizedInlistFetch();
    }, []);


    const first_name = localStorage.getItem("first_name");
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");

    const axiosJWT = axios.create();

    const getUsers = async (pagenumber) => {

        const moment = require('moment-timezone');
        const timeZones = moment.tz.names();

        var timezone_list = [];
        timeZones.forEach(element => {
            timezone_list.push({ label: element, value: element });
        });

        settimezonesList(timezone_list);

        /*if(paginationFor === '')
        var user_url = 'allusers'
        else*/
        var user_url = paginationFor;

        //check if page number is passed else assign it
        if (pagenumber)
            pagenumber = pagenumber;
        else {
            pagenumber = 1;
            setactivepage(1);
        }

        //get starting and ending range to each page
        if (pagenumber !== 1) {
            var starting_limit = ((pagenumber - 1) * countPerPage);
            var ending_limit = countPerPage;
        }
        else {
            var starting_limit = 0;
            var ending_limit = countPerPage;
        }

        const response = await axiosJWT.get("/" + user_url + "?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
      

        setUsersCount(response.data.UsersCount);
        setUsers(response.data.Users);

    
    }


    const user_ban = async (e) => {
        try {
            var isBan = e.target.value;
            const userId = e.target.getAttribute("data-item");

            if (isBan === 'true')
                var updateBan = 0;
            else
                var updateBan = 1;

            const response = await axiosJWT.put('/user/' + userId,
                {
                    isBan: updateBan
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response)
                getUsers();

        } catch (error) {
            console.log(error);
        }
    }

    const headsupenable = async (e) => {
        try {
            var huenable = e.target.value;
            const userId = e.target.getAttribute("data-item");

            if (huenable === 'true')
                var update_huenable = 0;
            else
                var update_huenable = 1;

            const response = await axiosJWT.put('/user/' + userId,
                {
                    headsup_enable: update_huenable
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response)
                getUsers();

        } catch (error) {
            console.log(error);
        }
    }


    const changeUsers = async (e) => {

        const list_for = e.target.getAttribute("data-item");

        const alltabs = document.querySelectorAll(".tabmenu a");
        alltabs.forEach((element) => element.classList.remove('active'));

        e.target.setAttribute("class", "active");

        var starting_limit = 0;
        var ending_limit = countPerPage;

        const response = await axiosJWT.get("/" + list_for + "?offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setPagination(list_for);
        setactivepage(1);

        setUsersCount(response.data.UsersCount);
        setUsers(response.data.Users);
    }

    const user_add = async (e) => {
        try {
            const datamodal = e.target.getAttribute("data-modal");

            if (datamodal === 'add_coach')
                var user_role = "Nutrition";
            if (datamodal === 'add_client')
                var user_role = "Client";

            var err = false;

            SetTimeZoneErr(false);

            //validaitions
            if (user_role === 'Nutrition') {

                SetCoachFNameErr(false);
                SetCoachLNameErr(false);
                SetCoachEmailErr(false);
                SetCoachPassErr(false);
                SetCoachGenderErr(false);
                SetCoachAddressErr(false);
                SetCoachPostalErr(false);
                SetCoachCountryErr(false);
                SetCoachStateErr(false);
                SetCoachCityErr(false);                
                SetExpiryDateErr(false);
                SetCoachPhoneErr(false);
                SetCoachBioErr(false);

                FirstNameRefCoach.current.classList.remove('err_border');
                LastNameRefCoach.current.classList.remove('err_border');
                EmailRefCoach.current.classList.remove('err_border');
                PassWordRefCoach.current.classList.remove('err_border');                
                PhoneRefCoach.current.classList.remove('err_border');
                AddressRefCoach.current.classList.remove('err_border');
                PostalRefCoach.current.classList.remove('err_border');
                // CountryRefCoach.current.classList.remove('err_border');
                // StateRefCoach.current.classList.remove('err_border');
                // CityRefCoach.current.classList.remove('err_border');

                if (user_edit_first_name === '') {
                    SetCoachFNameMsg('*Please enter first name');
                    SetCoachFNameErr(true);
                    FirstNameRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_last_name === '') {
                    SetCoachLNameMsg('*Please enter last name');
                    SetCoachLNameErr(true);
                    LastNameRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_email === '') {
                    SetCoachEmailMsg('*Please enter email address');
                    SetCoachEmailErr(true);
                    EmailRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_email !== '') {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_edit_email) === false) {
                        SetCoachEmailMsg('*Please enter valid email address');
                        SetCoachEmailErr(true);
                        EmailRefCoach.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (user_edit_password === '') {
                    SetCoachPassMsg('*Please enter password');
                    SetCoachPassErr(true);
                    PassWordRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_password !== '') {
                    if (user_edit_password.length < 8) {
                        SetCoachPassMsg('Password: Please enter minimum 8 characters');
                        SetCoachPassErr(true);
                        PassWordRefCoach.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (user_edit_gender === '') {
                    SetCoachGenderMsg('*Please select gender');
                    SetCoachGenderErr(true);
                    err = true;
                }
                if (Address === '') {
                    SetCoachAddressErr(true);
                    AddressRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (Postal === '') {
                    SetCoachPostalMsg('*Please enter postal code');
                    SetCoachPostalErr(true);
                    PostalRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_phone === '') {
                    SetCoachPhoneMsg('*Please enter phone number');
                    SetCoachPhoneErr(true);
                    PhoneRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_phone !== '') {
                    if (isPossiblePhoneNumber(user_edit_phone) === false) {
                        SetCoachPhoneMsg('Please enter valid Phone Number.!');
                        SetCoachPhoneErr(true);
                        PhoneRefCoach.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (CoachBio === '') {
                    SetCoachBioErr(true);                    
                    err = true;
                }
                if (user_edit_country === '' || user_edit_country === null) {
                    SetCoachCountryErr(true);
                    // CountryRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_state === '' || user_edit_state === null) {
                    SetCoachStateErr(true);
                    // StateRefCoach.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_city === '' || user_edit_city === null) {
                    SetCoachCityErr(true);
                    // CityRefCoach.current.classList.add('err_border');
                    err = true;
                }
            }


            if (user_role === 'Client') {
                SetClientFNameErr(false);
                SetClientLNameErr(false);
                SetClientEmailErr(false);
                SetClientPassErr(false);
                SetClientGenderErr(false);
                SetClientAddressErr(false);
                SetClientPostalErr(false);
                SetClientCountryErr(false);
                SetClientStateErr(false);
                SetClientCityErr(false);
                SetClientPhoneErr(false);

                FirstNameRefClient.current.classList.remove('err_border');
                LastNameRefClient.current.classList.remove('err_border');
                EmailRefClient.current.classList.remove('err_border');
                PassWordRefClient.current.classList.remove('err_border');
                PhoneRefClient.current.classList.remove('err_border');
                AddressRefClient.current.classList.remove('err_border');
                PostalRefClient.current.classList.remove('err_border');
                // CountryRefClient.current.classList.remove('err_border');
                // StateRefClient.current.classList.remove('err_border');
                // CityRefClient.current.classList.remove('err_border');

                if (user_edit_first_name === '') {
                    SetClientFNameMsg('*Please enter first name');
                    SetClientFNameErr(true);
                    FirstNameRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_last_name === '') {
                    SetClientLNameMsg('*Please enter last name');
                    SetClientLNameErr(true);
                    LastNameRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_email === '') {
                    SetClientEmailMsg('*Please enter email address');
                    SetClientEmailErr(true);
                    EmailRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_email !== '') {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_edit_email) === false) {
                        SetClientEmailMsg('*Please enter valid email address');
                        SetClientEmailErr(true);
                        EmailRefClient.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (user_edit_password === '') {
                    SetClientPassMsg('*Please enter password');
                    SetClientPassErr(true);
                    PassWordRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_password !== '') {
                    if (user_edit_password.length < 8) {
                        SetClientPassMsg('Password: Please enter minimum 8 characters');
                        SetClientPassErr(true);
                        PassWordRefClient.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (user_edit_gender === '') {
                    SetClientGenderMsg('*Please select gender');
                    SetClientGenderErr(true);
                    // GenderRefClient.current.focus()
                    err = true;
                }
                if (Address === '') {
                    SetClientAddressErr(true);
                    AddressRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (Postal === '') {
                    SetClientPostalMsg('*Please enter postal code');
                    SetClientPostalErr(true);
                    err = true;
                }
                if (user_edit_phone === '') {
                    SetClientPhoneMsg('*Please enter phone number');
                    SetClientPhoneErr(true);
                    PhoneRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_phone !== '') {
                    if (user_edit_phone.length < 5) {
                        SetClientPhoneMsg('Phone number should be minimum 5 digits.!');
                        SetClientPhoneErr(true);
                        PhoneRefClient.current.classList.add('err_border');
                        err = true;
                    }
                }
                if (user_edit_country === '' || user_edit_country === null) {
                    SetClientCountryErr(true);
                    // CountryRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_state === '' || user_edit_state === null) {
                    SetClientStateErr(true);
                    // StateRefClient.current.classList.add('err_border');
                    err = true;
                }
                if (user_edit_city === '' || user_edit_city === null) {
                    SetClientCityErr(true);
                    // CityRefClient.current.classList.add('err_border');
                    err = true;
                }
            }

            if (!user_edit_timezone) {
                SetTimeZoneMsg('*Please select timezone');
                SetTimeZoneErr(true);
                if (user_role === 'Client') {
                    //  TimeZoneRefClient.current.classList.add('err_border')
                }
                else if (user_role === 'Nutrition') {
                    // TimeZoneRefCoach.current.classList.add('err_border')
                }
                err = true;
            }

            if (AllowedClients !== '') {
                if (AllowedClients !== 0) {
                    if (ExpiryDate === '' || ExpiryDate === null) {
                        SetExpiryDateErr(true);
                        err = true;
                    }
                }
            }

            if (err === true) {
                return false;
            }

            if (user_edit_first_name !== '' && user_edit_last_name !== '' && user_edit_gender !== '' && user_edit_email !== '' && user_edit_password !== '' && user_role !== '' && user_edit_timezone.value !== '' && user_edit_phone !== '' && Address !== '' && Postal !== '' && user_edit_country !== '' && user_edit_state !== '' && user_edit_city !== '') {

                loading_icon('show');
                await axiosJWT.post('/user/',
                    {
                        first_name: user_edit_first_name,
                        last_name: user_edit_last_name,
                        gender: user_edit_gender,
                        email: user_edit_email,
                        phone: user_edit_phone,
                        password: user_edit_password,
                        role: user_role,
                        user_timezone: user_edit_timezone.value,
                        address: Address,
                        postal: Postal,
                        user_country: JSON.stringify(user_edit_country),
                        user_state: JSON.stringify(user_edit_state),
                        user_city: JSON.stringify(user_edit_city),
                        allowed_clients: AllowedClients,
                        specialized_in: SpecializedIn,
                        expiry_date: ChangeDatePickerDate(ExpiryDate),
                        coach_bio: CoachBio
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((response) => {
                        modalClose();
                        setformErr(false);
                        setformErrMsg('');
                        getUsers();
                        loading_icon('hide');
                    }).catch((error) => {
                        setformErr(true);
                        setformErrMsg(error.response.data.message);
                        loading_icon('hide');
                    });
            }
            else {
                loading_icon('hide');
                setformErr(true);
                setformErrMsg("some required fields are missing!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const searchUsers = async (event) => {

        /*if(paginationFor === '')
        var user_url = 'allusers'
        else*/
        var user_url = paginationFor;

        var starting_limit = 0;
        var ending_limit = countPerPage;

        const response = await axiosJWT.get("/" + user_url + "?search=" + event + "&offset=" + starting_limit + "&limit=" + ending_limit, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setactivepage(1);

        setUsersCount(response.data.UsersCount);
        setUsers(response.data.Users);
    }


    const user_delete = async (e) => {
        try {
            modalClose();

            const userId = e.target.getAttribute("data-item");

            const response = await axiosJWT.delete('/user/' + userId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response)
                getUsers();

        } catch (error) {
            console.log(error);
        }
    }

    const user_edit = async (e) => {
        try {
            e.preventDefault();
            var err = false;

            SetEditFNameErr(false);
            SetEditLNameErr(false);
            SetEditEmailErr(false);
            SetEditPassErr(false);
            SetEditGenderErr(false);
            SetTimeZoneErr(false);
            SetEditRoleErr(false);
            SetEditCountryErr(false);
            SetEditStateErr(false);
            SetEditCityErr(false);
            SetEditAddressErr(false);
            SetEditPostalErr(false);
            SetEditPhoneErr(false);
            SetCoachBioErr(false)

            EditFnameRef.current.classList.remove('err_border');
            EditLnameRef.current.classList.remove('err_border');
            EditEmailRef.current.classList.remove('err_border');
            EditRoleRef.current.classList.remove('err_border');
            EditPassRef.current.classList.remove('err_border');
            EditAddressRef.current.classList.remove('err_border');
            EditPostalRef.current.classList.remove('err_border');
            EditPhoneRef.current.classList.remove('err_border');
            // EditTimeZoneRef.current.classList.remove('err_border')


            if (user_edit_first_name === '') {
                SetEditFNameMsg('*Please enter first name');
                SetEditFNameErr(true);
                EditFnameRef.current.classList.add('err_border');
                err = true;
            }
            if (user_edit_last_name === '') {
                SetEditLNameMsg('*Please enter last name');
                SetEditLNameErr(true);
                EditLnameRef.current.classList.add('err_border');
                err = true;
            }
            if (user_edit_email === '') {
                SetEditEmailMsg('*Please enter email address');
                SetEditEmailErr(true);
                EditEmailRef.current.classList.add('err_border');
                err = true;
            }
            if (user_edit_email !== '') {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user_edit_email) === false) {
                    SetEditEmailMsg('*Please enter valid email address');
                    SetEditEmailErr(true);
                    EditEmailRef.current.classList.add('err_border');
                    err = true;
                }
            }
            if (user_edit_role === '') {
                SetEditRoleErr(true);
                SetEditRoleMsg('*Please select role');
                EditRoleRef.current.classList.add('err_border');
                err = true;
            }
            if (user_edit_password !== '') {
                if (user_edit_password.length < 8) {
                    SetEditPassMsg('Password: Please enter minimum 8 characters');
                    SetEditPassErr(true);
                    EditPassRef.current.classList.add('err_border');
                    err = true;
                }
            }
            if (user_edit_gender === '') {
                SetEditGenderMsg('*Please select gender');
                SetEditGenderErr(true);
                // EditGenderRef.current.focus()
                err = true;
            }
            if (Address === '' || Address === null) {
                SetEditAddressErr(true);
                EditAddressRef.current.classList.add('err_border');
                err = true;
            }
            if (Postal === '' || Postal === null) {                
                EditPostalRef.current.classList.add('err_border');
                SetEditPostalMsg('*Please enter postal code');
                SetEditPostalErr(true);
                err = true;
            }
            if (!user_edit_timezone) {
                SetTimeZoneMsg('*Please select timezone');
                SetTimeZoneErr(true);
                // EditTimeZoneRef.current.classList.add('err_border')
                err = true
            }
            if (!user_edit_phone) {
                SetEditPhoneMsg('*Please enter phone number');
                SetEditPhoneErr(true);
                EditPhoneRef.current.classList.add('err_border');
                err = true;
            }
            if (AllowedClients !== '') {
                if (parseFloat(AllowedClients) !== 0) {
                    if (ExpiryDate === '' || ExpiryDate === null) {
                        SetExpiryDateErr(true);
                        err = true;
                    }
                    else {
                        err = false;
                    }
                }
            }
            if (user_edit_role === 'Nutrition') {
                if (CoachBio === '') {
                    SetCoachBioErr(true)
                    err = true;
                }
            }
            if (user_edit_country === '' || user_edit_country === null) {
                SetEditCountryErr(true);
                err = true;
            }
            if (user_edit_state === '' || user_edit_state === null) {
                SetEditStateErr(true);
                // StateRefClient.current.classList.add('err_border');
                err = true;
            }
            if (user_edit_city === '' || user_edit_city === null) {
                SetEditCityErr(true);
                err = true;
            }

            if (err == true) {
                return false;
            }

            loading_icon('show');

            const userId = e.target.getAttribute("data-item");

            if (AllowedClients !== '') {
                if (parseFloat(AllowedClients) > 0) {
                    await axiosJWT.put('/user/' + userId,
                        {
                            allowed_clients: AllowedClients,
                            expiry_date: ChangeDatePickerDate(ExpiryDate)
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }

            if (SpecializedIn !== '') {
                await axiosJWT.put('/user/' + userId,
                    {
                        specialized_in: SpecializedIn
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            if (CoachBio !== '') {
                await axiosJWT.put('/user/' + userId,
                    {
                        coach_bio: CoachBio
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            if (user_edit_first_name !== userEditPopup.first_name) {
                await axiosJWT.put('/user/' + userId,
                    {
                        first_name: user_edit_first_name
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_last_name !== userEditPopup.last_name) {
                await axiosJWT.put('/user/' + userId,
                    {
                        last_name: user_edit_last_name
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_gender !== userEditPopup.gender) {
                await axiosJWT.put('/user/' + userId,
                    {
                        gender: user_edit_gender
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_email !== userEditPopup.email) {
                await axiosJWT.put('/user/' + userId,
                    {
                        email: user_edit_email
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_password) {
                await axiosJWT.put('/user/' + userId,
                    {
                        password: user_edit_password
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_role !== userEditPopup.role) {
                await axiosJWT.put('/user/' + userId,
                    {
                        role: user_edit_role
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (user_edit_isBan !== userEditPopup.isBan) {
                await axiosJWT.put('/user/' + userId,
                    {
                        isBan: user_edit_isBan
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            if (user_edit_timezone.value !== undefined) {
                if (user_edit_timezone.value !== userEditPopup.user_timezone) {

                    await axiosJWT.put('/user/' + userId,
                        {
                            user_timezone: user_edit_timezone.value
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }

            if (user_edit_phone !== userEditPopup.phone) {
                await axiosJWT.put('/user/' + userId,
                    {
                        phone: user_edit_phone
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            if (userEditPopup.user_country)
            {
                if(user_edit_country.value !== userEditPopup.user_country.value) 
                {
                    await axiosJWT.put('/user/' + userId,
                    {
                        user_country: user_edit_country
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                    );
                }
            }
            else
            {
                if(user_edit_country !== '')
                {
                    await axiosJWT.put('/user/' + userId,
                    {
                        user_country: user_edit_country
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
            }

            if (userEditPopup.user_state)
            {
                if (user_edit_state.value !== userEditPopup.user_state.value) {
                    await axiosJWT.put('/user/' + userId,
                        {
                            user_state: user_edit_state
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }
            else
            {
                if (user_edit_state.value !== '') {
                    await axiosJWT.put('/user/' + userId,
                        {
                            user_state: user_edit_state
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }

            if (userEditPopup.user_city)
            {
                if (user_edit_city.value !== userEditPopup.user_city.value) {
                    await axiosJWT.put('/user/' + userId,
                        {
                            user_city: user_edit_city
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }
            else
            {
                if (user_edit_city.value !== '') {
                    await axiosJWT.put('/user/' + userId,
                        {
                            user_city: user_edit_city
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );
                }
            }

            if (Address !== userEditPopup.user_address) {
            await axiosJWT.put('/user/' + userId,
                    {
                        address: Address
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            if (Postal !== userEditPopup.postalCode) {
                await axiosJWT.put('/user/' + userId,
                    {
                        postal: Postal
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            getUsers();
            modalClose();
            loading_icon('hide');
        } catch (error) {
            console.log(error);
        }
    }

    const modalOpen = async (e) => {
        const userId = e.target.getAttribute("data-item");
        const datamodal = e.target.getAttribute("data-modal");

        setformErr(false);
        setformErrMsg('');
        SetTimeZoneErr(false);

        if (datamodal === 'delete_confirm')
            setuserDeletePopup({ open: true, id: userId });

        if (datamodal === 'user_edit') {

            load_countries();

            SetEditFNameErr(false);
            SetEditLNameErr(false);
            SetEditEmailErr(false);
            SetEditPassErr(false);
            SetEditGenderErr(false);
            SetEditCountryErr(false);
            SetEditStateErr(false);
            SetEditCityErr(false);
            SetEditAddressErr(false);
            SetEditPostalErr(false);
            SetShowCoachEditPlan(false);
            SetAllowedClients(0);
            SetExpiryDate('');
            SetCoachBio('');
            SetCoachBioErr(false);
            SetEditPhoneErr(false);

            const response = await axiosJWT.get("/user/" + userId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log("mi: ")
            console.log(response.data)

            if (response.data.role) {
                if (response.data.role === 'Nutrition') {
                    SetShowCoachEditPlan(true);
                    SetAllowedClients(response.data.nutritionist_infos[0].allowed_clients);
                    SetSpecializedIn(response.data.nutritionist_infos[0].specialized_in);
                    SetCoachBio(response.data.nutritionist_infos[0].coach_bio);

                    if (response.data.nutritionist_infos[0].user_plan_expiry_date !== null && response.data.nutritionist_infos[0].user_plan_expiry_date !== '') {
                        SetExpiryDate(new Date(timetoUtcClientAppointment(response.data.nutritionist_infos[0].user_plan_expiry_date, ClientTimeZoneDefault)));
                    }
                }
            }

            setuserEditPopup({ open: true, id: userId, first_name: response.data.first_name, last_name: response.data.last_name, gender: response.data.gender, email: response.data.email, password: response.data.password, role: response.data.role, isBan: response.data.isBan, user_timezone: response.data.user_timezone, phone: response.data.phone, user_address: response.data.user_address, postalCode: response.data.postalCode, user_country: response.data.user_country, user_state: response.data.user_state, user_city: response.data.user_city  });

            setFirst_name(response.data.first_name);
            setLast_name(response.data.last_name);
            setGender(response.data.gender);
            setEmail(response.data.email);
            setRole(response.data.role);
            setBan(response.data.isBan);
            setuser_timezone(response.data.user_timezone);
            SetAddress(response.data.user_address);
            SetPostal(response.data.postalCode);
            setPhone(response.data.phone);

            if(response.data.user_country !== '' && response.data.user_country !== null){    

                setCountry(response.data.user_country);

                var state_list = State.getAllStates();
                var state_list_filtered = state_list.filter((state)=>state.countryCode===response.data.user_country.value);
    
                var state_list_array = [];
    
                state_list_filtered.forEach(element => {
                    state_list_array.push({"label":element.name,"value":element.isoCode});
                });
                setstateList(state_list_array);   
            }

            if(response.data.user_state !== '' && response.data.user_state !== null){                
    
                setState(response.data.user_state);

                var city_list = City.getAllCities();
    
                var city_list_filtered = city_list.filter((city)=>city.countryCode===response.data.user_country.value && city.stateCode === response.data.user_state.value);
    
                var city_list_array = [];
    
                city_list_filtered.forEach(element => {
                    city_list_array.push({"label":element.name,"value":element.name});
                });
    
                setcityList(city_list_array);
            }

            if(response.data.user_city !== '' && response.data.user_city !== null){  
                setCity(response.data.user_city);
            }
        }

        if (datamodal === 'add_coach') {

            load_countries();

            SetCoachFNameErr(false);
            SetCoachLNameErr(false);
            SetCoachEmailErr(false);
            SetCoachPassErr(false);
            SetCoachGenderErr(false);
            SetCoachAddressErr(false);
            SetCoachPostalErr(false);
            SetCoachCountryErr(false);
            SetCoachStateErr(false);
            SetCoachCityErr(false);
            SetCoachPhoneErr(false);
            SetCoachBioErr(false)
            SetAddress('');
            SetPostal('');
            setCountry('');
            setState('');
            setCity('');
            SetAllowedClients(0);
            SetExpiryDate('');
            SetCoachBio('')
            setAddCoachPopup({ open: true });
        }

        if (datamodal === 'add_client') {

            load_countries();

            SetClientFNameErr(false);
            SetClientLNameErr(false);
            SetClientEmailErr(false);
            SetClientPassErr(false);
            SetClientGenderErr(false);
            SetClientAddressErr(false);
            SetClientPostalErr(false);
            SetClientCountryErr(false);
            SetClientStateErr(false);
            SetClientCityErr(false);
            SetClientPhoneErr(false);
            SetAddress('');
            SetPostal('');
            setCountry('');
            setState('');
            setCity('');
            setAddClientPopup({ open: true });            
        }        
    }

    const modalClose = async () => {
        //const datamodal  = e.target.getAttribute("data-modal");
        //if(datamodal == 'delete_confirm')

        setuserDeletePopup({ open: false, id: null });

        setuserEditPopup({ open: false, id: null, first_name: null, last_name: null, gender: null, email: null, password: null, role: null, isBan: null, user_timezone: null, phone: null, user_address: null, postalCode: null, user_country: null, user_state: null, user_city: null });

        setFirst_name('');
        setLast_name('');
        setEmail('');
        setPassword('');
        setRole('');
        setBan('');
        setGender('');
        setuser_timezone('');
        setPhone('');
        SetSpecializedIn('');
        SetAddress('');
        SetPostal('');        
        setCountry('');
        setState('');
        setCity('');
        setcountryList([]);
        setstateList([]);
        setcityList([]);

        setAddCoachPopup({ open: false });
        setAddClientPopup({ open: false });
    }

    const changePageNumber = async (pagenumber) => {

        //var pagination_tab = e.target.getAttribute("data-item");

        setactivepage(pagenumber);

        getUsers(pagenumber);

        /*if(paginationFor == 'allusers')
        getUsers(pagenumber);
        else
        changeUsers(pagenumber);*/
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const [CommissionStatus, SetCommissionStatus] = useState('');
    const [CommissionLevel, SetCommissionLevel] = useState('');
    const [CommissionType, SetCommissionType] = useState('');
    const [CommissionPayout, SetCommissionPayout] = useState('');
    const [CommissionVal, SetCommissionVal] = useState('');
    const [CommissionMin, SetCommissionMin] = useState('');
    const [CommissionMax, SetCommissionMax] = useState('');
    const [CommissionUpdate, SetCommissionUpdate] = useState('');
    const [isupdate, Setisupdate] = useState('');

    const add_commission_expert = async (e) => {
        SetComissionvalueErr(false);
        SetMinPayErr(false);
        SetMaxComErr(false);

        var EditId = e.target.getAttribute("data-item");
        const response = await axiosJWT.get('/manage-commissions?commission_name=expert&expert_id=' + EditId + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.length > 0) {
                if (response.data[0].commission_status === false) {
                    SetCommissionStatus('0');
                }
                else if (response.data[0].commission_status === true) {
                    SetCommissionStatus('1');
                }

                SetCommissionLevel(response.data[0].commission_name);
                SetCommissionType(response.data[0].commission_type);
                SetCommissionPayout(response.data[0].commission_payout);
                SetCommissionVal(response.data[0].commission_value);
                SetCommissionMin(response.data[0].commission_min_amount);
                SetCommissionMax(response.data[0].commission_max_value);
                SetCommissionUpdate(EditId);
                Setisupdate('1');
                setShow(true);
            }
            else {
                setShow(true);
                SetCommissionStatus('1');
                SetCommissionLevel('expert');
                SetCommissionType('fixed');
                SetCommissionPayout('fixed');
                SetCommissionVal('');
                SetCommissionMin('');
                SetCommissionMax('');
                SetCommissionUpdate(EditId);
                Setisupdate('0');
            }
        });
    }

    const SaveCommission = async (e) => {

        var EditId = e.target.getAttribute("data-update");
        var update = e.target.getAttribute("data-isupdate");
        var err = false;

        SetComissionvalueErr(false);
        SetMinPayErr(false);
        SetMaxComErr(false);

        if (CommissionVal === '') {
            SetComissionvalueMsg('Please enter commission value');
            SetComissionvalueErr(true);
            err = true;
        }
        if (CommissionMin === '') {
            SetMinPayMsg('Please enter minimum payment amount');
            SetMinPayErr(true);
            err = true;
        }
        if (CommissionMax === '') {
            SetMaxComMsg('Please enter maximum commision value');
            SetMaxComErr(true);
            err = true;
        }
        if (CommissionVal !== '') {
            const Numreg = new RegExp('^[0-9]+$');
            if (!Numreg.test(CommissionVal)) {
                SetComissionvalueErr(true);
                SetComissionvalueMsg('Please enter round off value(Commiss.value)');
                err = true;
            }
        }
        if (CommissionMin !== '') {
            const Numreg = new RegExp('^[0-9]+$');
            if (!Numreg.test(CommissionMin)) {
                SetMinPayErr(true);
                SetMinPayMsg('Please enter round off value(Min.amo)');
                err = true;
            }
        }
        if (CommissionMax !== '') {
            const Numreg = new RegExp('^[0-9]+$');
            if (!Numreg.test(CommissionMax)) {
                SetMaxComErr(true);
                SetMaxComMsg('Please enter round off value(Max.com)');
                err = true;
            }
        }
        if (err === true) {
            return false;
        }

        if (update === '0') {
            const response = await axiosJWT.post('/manage-commissions',
                {
                    commission_status: CommissionStatus,
                    commission_name: CommissionLevel,
                    commission_type: CommissionType,
                    commission_payout: CommissionPayout,
                    commission_value: CommissionVal,
                    commission_min_amount: CommissionMin,
                    commission_max_value: CommissionMax,
                    user_expert_id: EditId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                setShow(false);
            }
        }
        else if (update === '1') {
            const response = await axiosJWT.post('/manage-commissions/update',
                {
                    commission_status: CommissionStatus,
                    commission_name: CommissionLevel,
                    commission_type: CommissionType,
                    commission_payout: CommissionPayout,
                    commission_value: CommissionVal,
                    commission_min_amount: CommissionMin,
                    commission_max_value: CommissionMax,
                    user_expert_id: EditId
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                setShow(false);
            }
        }
    }

    const loading_icon = async (display) => {
        if (display === 'show')
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:block");
        else
            document.getElementById("semiTransparenDivLoader").setAttribute("style", "display:none");
    }

    const ChangeDatePickerDate = (pass) => {
        if (pass !== null && pass !== '') {
            var passDate = '';
            var yyyy = pass.getFullYear().toString();
            var mm = (pass.getMonth() + 1).toString();
            var dd = pass.getDate().toString();
            passDate = yyyy + '-' + mm + '-' + dd;
            return passDate;
        }
        else {
            return '';
        }
    }

    function timetoUtcClientAppointment(passdate, timezone) {
        var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss');

        var cutoffString = pushstartime; // in utc
        var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
        var displayCutoff = utcCutoff.clone().tz(timezone);

        var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss');
        stardates = moment(stardates).format('MMM DD, yyyy  hh:mm a');
        return stardates;
    }

    const NavigateCoachCat = () => {
        navigate("/admin/coach-cat", { replace: true });
    }

    const [pshow, Setpshow] = useState(false);
    const phandleClose = () => {
        Setpshow(false);
    }
    const DropdownChangeClient = (e) => {
        SetSelectedOptionsClient(e);
    }    

    const [clientoptions, Setclientoptions] = useState([]);
    const [DefaultSelectedClientMembers, SetDefaultSelectedClientMembers] = useState([]);
    const [SelectedOptionsClient, SetSelectedOptionsClient] = useState([]);
    const [ClientErr, SetClientErr] = useState(false);
    const [NutritionRelationId, SetNutritionRelationId] = useState('');

    const ProceedPermission = async () => {

        var UserId = SelectedOptionsClient.map(s => s.value);
        let ids = UserId.toString();

       var response = await axiosJWT.post('/add-coach-client-relation/',
            {
                nutrition_id: NutritionRelationId,
                client_id: ids
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if(response.status === 200)
        Setpshow(false);
    }

    const TriggerRelationPopup = async (e) => {
        const NutritionId = e.target.getAttribute('data-item');
        SetNutritionRelationId(NutritionId);
        await SetMyClients(NutritionId);
        await SetAllClientOptions();
        Setpshow(true);
    }

    const SetAllClientOptions = async () => {
        try {
            var ClientObj = [];
            const AllClients = await axiosJWT.get("/allclient", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (AllClients.status === 200) {
                if (AllClients.data.Users !== null) {
                    AllClients.data.Users.forEach((data, index) => {
                        var obj = {};
                        obj["value"] = data.id;
                        obj["label"] = data.first_name+" "+data.last_name+" ("+data.email+" )";
                        ClientObj.push(obj);
                    });
                }
            }
            Setclientoptions(ClientObj);
        } catch (err) {
            console.log(err);
        }
    }

    const SetMyClients = async (UserId) => {
        var DarrayObj = [];

        const MyClients = await axiosJWT.get("/nutrition/allclient?id=" + UserId + "", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (MyClients.data !== null) {

            MyClients.data.forEach((data, index) => {
                var obj = {};
                obj["value"] = data.id;
                obj["label"] = data.first_name+" "+data.last_name+" ("+data.email+" )";

                DarrayObj.push(obj);
            });
        }

        SetDefaultSelectedClientMembers(DarrayObj);
        SetSelectedOptionsClient(DarrayObj);
    }

    const CoachRelationPopup = async (e) => {
        const client_id = e.target.getAttribute("data-item");

        setconnectClientId(client_id);

        var CoachObj = [];
        
        const AllCoach = await axiosJWT.get("/allnutrition", {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        if(AllCoach.status === 200)
        {
            var all_coach = AllCoach.data.Users;
            if (AllCoach.data.UsersCount > 0) {
                all_coach.forEach((data, index) => {
                    var obj = {};
                    obj["value"] = data.id;
                    obj["label"] = data.first_name+" "+data.last_name+" ("+data.email+" )";
                    CoachObj.push(obj);
                });
            }
        }
        
        setcoachOptions(CoachObj);


        const Exist_Connected_Coach_List = await axiosJWT.get("/mysubscribedcoaches?id="+client_id,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        if(Exist_Connected_Coach_List.status === 200)
        {
            var exist_coach = [];
            var exist_coaches_list = Exist_Connected_Coach_List.data.Users;

            if(exist_coaches_list.length > 0)
            {
                exist_coaches_list.forEach((data, index) => {
                    var obj = {};
                    obj["value"] = data.id;
                    obj["label"] = data.first_name+" "+data.last_name+" ("+data.email+")";
                    exist_coach.push(obj);
                });
            }
        }

        setexistConnectedCoachList(exist_coach);

        setclientConnectShow(true);
    }

    const DropdownChangeConnectedCoach = (e) => {
        setselectedOptionsCoach(e);
    }

    const updateClientConnectedList = async () => {
        var selected_coach_list = selectedOptionsCoach.map(s => s.value);
        var exist_coach_list = existConnectedCoachList.map(s => s.value);

        if(selected_coach_list.length > 0)
        {
            var list_mismatch = false;
            if(selected_coach_list.length === exist_coach_list.length)
            {
                selected_coach_list.forEach(element => {
                    if(!exist_coach_list.includes(element))
                    {
                        list_mismatch = true;
                    }
                });

                if(list_mismatch === true)
                {
                    console.log('length match with value not matching');

                    var coach_ids = selected_coach_list.toString();

                    var result = await axiosJWT.post("/add-client-coach-relation",
                    {
                        client_id: connectClientId,
                        nutrition_id: coach_ids                        
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });       

                    if(result.status === 200)
                    {
                        setclientConnectShow(false);
                    }
                }
                else
                {
                    console.log("Nothing changed.!");
                }
            }
            else
            {
                console.log('length mismatch');
                var coach_ids = selected_coach_list.toString();

                var result = await axiosJWT.post("/add-client-coach-relation",
                {
                    client_id: connectClientId,
                    nutrition_id: coach_ids                        
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });       

                if(result.status === 200)
                {
                    setclientConnectShow(false);
                }
            }
        }
        else
        {
            console.log("Nothing changed.!");
        }
    }

    const ShowClientDashboard=(e)=>{
        const role = e.target.closest(".navigateclientdashboard").getAttribute("data-role");
        const Userid = e.target.closest(".navigateclientdashboard").getAttribute("data-id");
        const UseridGlp = e.target.closest(".navigateclientdashboard").getAttribute("data-glp");
     
        SetClickedUserId(Userid)
        SetClickedUserIdGlp(UseridGlp)
        SetShowClientDashboardTab(false)
        SetShowCoachDashboardTab(false)
     
        if(role==='Client')
        {
            if(e.target.classList.contains('no_target')===false)
            {
                SetShowClientDashboardTab(true)
                SetShowManageUsersTab(false)
            }
            
        }
        else if(role==='Nutrition')
        {
            if(e.target.classList.contains('no_target')===false)
            {
                SetShowCoachDashboardTab(true)
                SetShowManageUsersTab(false)
            } 
        }
 
    }

    const ShowClientDashboardTrigger=(userid='')=>{
        SetClickedUserId(userid)
        SetShowCoachDashboardTab(false)
        SetShowClientDashboardTab(true)
        SetShowManageUsersTab(false)
    }

    

    const [ShowManageUsersTab,SetShowManageUsersTab]=useState(true)
    const [ShowClientDashboardTab,SetShowClientDashboardTab]=useState(false)
    const [ShowCoachDashboardTab,SetShowCoachDashboardTab]=useState(false)
    const [ClickedUserId,SetClickedUserId]=useState('')
    const [ClickedUserIdGlp,SetClickedUserIdGlp]=useState('')

    const HideDashboardTab=()=>{
        SetShowClientDashboardTab(false)
        SetShowCoachDashboardTab(false)
        SetShowManageUsersTab(true)
    }

    const [ClientSubscriptionPopupshow,SetClientSubscriptionPopupshow]=useState(false)
    const HandlCloseClientSubscriptionPopupshow=()=>{
        SetClientSubscriptionPopupshow(false)
    }

    const [CoachSubscriptionPopupshow,SetCoachSubscriptionPopupshow]=useState(false)
    const HandlCloseCoachSubscriptionPopupshow=()=>{
        SetCoachSubscriptionPopupshow(false)
    }



    const [showfail,Setshowfail]=useState(false)
    const CloseFailPopup=()=>{
        Setshowfail(false)
    }
    const [FailedTitle,SetFailedTitle]=useState('')
    const [FailedMsg,SetFailedMsg]=useState('')

    const [showConfirmAssign,SetshowConfirmAssign]=useState(false)
    const CloseConfirmAssign=()=>{
        SetshowConfirmAssign(false)
    }
    const [ConfirmaAssignTitle,SetConfirmaAssignTitle]=useState('')
    const [ConfirmAssignMsg,SetConfirmAssignMsg]=useState('')
    const [ConfirmAssignTarget,SetConfirmAssignTarget]=useState('')
    

    const [ClientPlansList,SetClientPlansList]=useState([])
    const [ClientIdPlanUpgrade,SetClientIdPlanUpgrade]=useState('')

    const [CoachPlanList,SetCoachPlanList]=useState([])
    const [CoachIdPlanUpgrade,SetCoachIdPlanUpgrade]=useState('')

    const GetActiveClientPlanList=async()=>{

        const response = await axiosJWT.get("client-plan/active-list", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
      
          if (response.data.List != null) {
            console.log('response.data.List',response.data.List)
            SetClientPlansList(response.data.List);
          }

    }

    const GetActiveCoachPlanList=async()=>{

        const PlanList = await axiosJWT.get('/service-plan?type=active', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        if (PlanList.data !== null) {
            SetCoachPlanList(PlanList.data.AllCoachPlans);
        }

    }
   
    //client plan check
    const [ClientSubcribedId,SetClientSubcribedId]=useState('')
    const [ClientSubscriptionEndDate,SetClientSubscriptionEndDate]=useState('')
    const [IsPlanLifeTimeClient,SetIsPlanLifeTimeClient]=useState(false)

    //coach plan check

    const [CoachSubscribedId,SetCoachSubscribedId]=useState('')
    const [CoachSubscriptionEndDate,SetCoachSubscriptionEndDate]=useState('')
    const [CoachSubscriptionStatus,SetCoachSubscriptionStatus]=useState('')


    const UpgradePlan=(e)=>{
     const Target=e.target.getAttribute('data-category')
     const UserId=e.target.getAttribute('data-id')
 
   
     if(Target==='client')
     {
        SetClientSubcribedId('')
        SetClientIdPlanUpgrade(UserId)
        GetClientDetails(UserId)
        GetActiveClientPlanList()
        SetClientSubscriptionPopupshow(true)
     }
     if(Target==='coach')
     {
        SetCoachIdPlanUpgrade(UserId)
        SetCoachSubscribedId('')
        SetCoachSubscriptionEndDate('')
        SetCoachSubscriptionStatus(false)
        GetCoachDetails(UserId)
        GetActiveCoachPlanList()
        SetCoachSubscriptionPopupshow(true)
     }
    }

    const GetCoachDetails=async(UserId='')=>{

        const response = await axiosJWT.get("/user/" + UserId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (
   
            response.data.nutritionist_infos[0] &&
            response.data.nutritionist_infos[0] !== null &&
            response.data.nutritionist_infos[0] !== "" &&
            response.data.nutritionist_infos[0].user_plan_id !== null &&
            response.data.nutritionist_infos[0].user_plan_id !== ""
          ) {
            SetCoachSubscribedId(response.data.nutritionist_infos[0].user_plan_id)
     
          }

          if (
   
            response.data.nutritionist_infos[0] &&
            response.data.nutritionist_infos[0] !== null &&
            response.data.nutritionist_infos[0] !== "" &&
            response.data.nutritionist_infos[0].user_plan_expiry_date !== null &&
            response.data.nutritionist_infos[0].user_plan_expiry_date !== ""
          ) {
       
            SetCoachSubscriptionEndDate(response.data.nutritionist_infos[0].user_plan_expiry_date)
     
          }

          if (
   
            response.data.nutritionist_infos[0] &&
            response.data.nutritionist_infos[0] !== null &&
            response.data.nutritionist_infos[0] !== "" &&
            response.data.nutritionist_infos[0].user_plan_status !== null &&
            response.data.nutritionist_infos[0].user_plan_status !== ""
          ) {
            SetCoachSubscriptionStatus(response.data.nutritionist_infos[0].user_plan_status)
            }

   

    }

    const GetClientDetails=async(UserId='')=>{
        SetIsPlanLifeTimeClient(false);
    const response = await axiosJWT.get("/user/" + UserId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
   });

   if (
   
    response.data.client_infos[0] &&
    response.data.client_infos[0] !== null &&
    response.data.client_infos[0] !== "" &&
    response.data.client_infos[0].subscription_plan !== ""
  ) {
   SetClientSubcribedId(response.data.client_infos[0].subscription_plan)
   SetClientSubscriptionEndDate(response.data.client_infos[0].subscription_e_date)
  }

  if(response.data.client_infos && response.data.client_infos[0] &&  response.data.client_infos[0].client_plan  && response.data.client_infos[0].client_plan!==null && response.data.client_infos[0].client_plan.plan_type==='unlimited')
  {
   SetIsPlanLifeTimeClient(true);
  }

      
    }



    const ConfirmAssignPlan=async(e)=>{
        const target=e.target.getAttribute('data-target')

        if(target==='client')
        {

            loading_icon('show');

        try{

            var result = await axiosJWT.post("/payments/update-client-plan",
            {
                plan_id: SelectedClientPlanId,
                client_id:ClientIdPlanUpgrade
                           
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if(result.status===200)
            {
             
                SetshowConfirmAssign(false)
                Setshowfail(true)
                SetFailedTitle('Plan assign success')
                SetFailedMsg(result.data.msg)
                getUsers();
                loading_icon('hide');

            }

        }catch(err)
        {
     
            SetshowConfirmAssign(false)
            Setshowfail(true)
            SetFailedTitle('Plan assign failed')
            SetFailedMsg(err.response.data.msg)
            loading_icon('hide');
         
        }

        }
        if(target==='coach')
        {
            loading_icon('show');
             try{

                var result = await axiosJWT.post("/payments/update-coach-plan",
                {
                    plan_id: SelectedCoachPlanId,
                    coach_id:CoachIdPlanUpgrade
                               
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                
            if(result.status===200)
            {
             
                SetshowConfirmAssign(false)
                Setshowfail(true)
                SetFailedTitle('Plan assign success')
                SetFailedMsg(result.data.msg)
                getUsers();
                loading_icon('hide');

            }

             }catch(err)
             {

                SetshowConfirmAssign(false)
                Setshowfail(true)
                SetFailedTitle('Plan assign failed')
                SetFailedMsg(err.response.data.msg)
                loading_icon('hide');

             }
           


        }
      
    }

    const [SelectedClientPlanId,SetSelectedClientPlanId]=useState('')
    const [SelectedCoachPlanId,SetSelectedCoachPlanId]=useState('')

    const ClientPlanUpgrade=async(e)=>{
        const PlanId=e.target.getAttribute('data-service_id');
        SetSelectedClientPlanId(PlanId)
        SetConfirmAssignMsg('Are you sure want to assign this plan to client?')
        SetConfirmaAssignTitle('Client Plan Update')
        SetConfirmAssignTarget('client')
        SetClientSubscriptionPopupshow(false)
        SetshowConfirmAssign(true)
      

    }

    const CoachPlanUpgrade=(e)=>{
        const PlanId=e.target.getAttribute('data-service_id');
        SetSelectedCoachPlanId(PlanId)
        SetConfirmAssignMsg('Are you sure want to assign this plan to coach?')
        SetConfirmaAssignTitle('Coach Plan Update')
        SetConfirmAssignTarget('coach')
        SetCoachSubscriptionPopupshow(false)
        SetshowConfirmAssign(true)
        



    }

    const FormatTags = (string) => {
        const myArray = string.split(",");
    
        return myArray;
      };

      const CheckClientButtonRender=(planid)=>{

        if(parseFloat(planid) !== parseFloat(ClientSubcribedId))
        {
            return (
                <button className="btn btn-primary keto_link_button" data-service_id={planid}  onClick={(e) => { ClientPlanUpgrade(e) }}>Assign plan</button>
            )
        }
        else{
       
            const currentTime=moment();
            const subscribeddate=moment(ClientSubscriptionEndDate);
           
            if(moment(subscribeddate)>=currentTime)
            {

                if(IsPlanLifeTimeClient===false)
                {

                    return  (<div>
                        <p className='err'>Current Plan expires on {format(parseISO(ClientSubscriptionEndDate), `PP`)}</p>
         
                    </div>)

                }
                else{
                 return(<div>
                        <p className='err'>Plan Ends in :Lifetime</p>
         
                    </div>)
                }
               
            }
            else{
                return(
                    <div>
                        <p className='err'>Current Plan expired!</p>
              <button className="btn btn-primary keto_link_button" data-service_id={planid}  onClick={(e) => { ClientPlanUpgrade(e) }}>Assign plan</button>
                    </div>
      
                )
               
            }
        }

      }

      const CheckCoachButtonRender=(planid)=>{

        if(parseFloat(planid)!==parseFloat(CoachSubscribedId))
        {
            return (
                <button className="btn btn-primary keto_link_button" data-service_id={planid}  onClick={(e) => { CoachPlanUpgrade(e) }}>Assign plan</button>
            )
         
        }
        if(parseFloat(planid) ===parseFloat(CoachSubscribedId))
        {
           

            if(CoachSubscriptionStatus==='' || CoachSubscriptionStatus===null  || CoachSubscriptionStatus===false)
            {
                return (<div>
                <p className='err'>Subscription Not renewed!</p>
      <button className="btn btn-primary keto_link_button" data-service_id={planid}  onClick={(e) => { CoachPlanUpgrade(e) }}>Assign plan</button>
            </div>)
            }
            else if(CoachSubscriptionStatus===true)
            {
              

                const currentTime=moment();
              const subscribeddate=moment(CoachSubscriptionEndDate);
             
              if(moment(subscribeddate)>=currentTime)
            {
               return (<div>
               <p className='err'>Current Subscription cycle expires on {format(parseISO(CoachSubscriptionEndDate), `PP`)}</p>

                    </div>)
            }
            else{

                return (<div>
                    <p className='err'>Subscription Not renewed!</p>
          <button className="btn btn-primary keto_link_button" data-service_id={planid}  onClick={(e) => { CoachPlanUpgrade(e) }}>Assign plan</button>
                </div>)

            }

            }
            
        }
        

    }


    const load_countries = async () => {
        var countries_list = Country.getAllCountries();
        var countries_list_array = [];
        countries_list.forEach(element => {
            countries_list_array.push({"label":element.name,"value":element.isoCode});
        });
        setcountryList(countries_list_array);
    }

    const filterstate = async (e) => {  

        setCountry(e);

        var state_list = State.getAllStates();

        var state_list_filtered = state_list.filter((state)=>state.countryCode===e.value);

        var state_list_array = [];

        state_list_filtered.forEach(element => {
            state_list_array.push({"label":element.name,"value":element.isoCode});
        });

        if(state_list_array.length > 0)
        {
            setstateList(state_list_array);   
            setState('');    
            setCity('');
            setcityList([]);
        }
        else
        {
            setState(e);
            setCity(e);
            setstateList([]);
            setcityList([]);
        }
    }

    const filtercity = async (e) => { 
 
        setState(e);

        var city_list = City.getAllCities();

        var city_list_filtered = city_list.filter((city)=>city.countryCode===user_edit_country.value && city.stateCode === e.value);

        var city_list_array = [];

        city_list_filtered.forEach(element => {
            city_list_array.push({"label":element.name,"value":element.name});
        });

        
        if(city_list_array.length > 0)
        {
            setcityList(city_list_array);
            setCity('');
        }
        else
        {
            setCity(e);
            setcityList([]);
        }
    }

    const CheckWithingsDetails=async(e)=>{

        SetShowWithingsDetails(false)
        var UserId=e.target.getAttribute('data-item')
     
        const response = await axiosJWT.get("/user/" + UserId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log('chekkkadresss',response)

        if(response.data.withings_device_deliver_init===null || response.data.withings_device_deliver_init==='' || response.data.withings_device_deliver_init===false)
        {
            SetShowWithingsDetails(false) 
        }
        else if(response.data.withings_device_deliver_init===true){
            SetShowWithingsDetails(true)

            
        }

        if(response.data.withings_user_id)
        {
            if(response.data.withings_user_id!==null && response.data.withings_user_id!=='')
            {
                SetWithingsDeviceCustomerId(response.data.withings_user_id)
            }
        }
        if(response.data.withings_device_order_id)
        {
            if(response.data.withings_device_order_id!==null && response.data.withings_device_order_id!=='')
            {
                SetUserWithingsDeviceOrderId(response.data.withings_device_order_id)
            }
        }
        if(response.data.withings_device_order_date)
        {
            if(response.data.withings_device_order_date!==null && response.data.withings_device_order_date!=='')
            {
            
                const OrderedDate=moment.unix(response.data.withings_device_order_date).format('MMM DD, yyyy');
                SetWithingsDeviceOrderDate(OrderedDate)
            }
        }
        if(response.data.withings_device_order_address)
        {
            if(response.data.withings_device_order_address!==null && response.data.withings_device_order_address!=='')
            {
                SetWithingsDeviceDeliveryAddress(response.data.withings_device_order_address)
            }
        }

     



        SetshowWithingsOrder(true)

    }

    
    const [ShowWithingsDetails,SetShowWithingsDetails]=useState(false)
    const [WithingsDeviceOrderId,SetUserWithingsDeviceOrderId]=useState('')
    const [WithingsDeviceCustomerId,SetWithingsDeviceCustomerId]=useState('')
    const [WithingsDeviceOrderDate,SetWithingsDeviceOrderDate]=useState('')
    const [WithingsDeviceDeliveryAddress,SetWithingsDeviceDeliveryAddress]=useState('')
    const [showWithingsOrder,SetshowWithingsOrder]=useState(false)
    const CloseWithingsOrder=()=>{
        SetshowWithingsOrder(false)
    }




    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            {
                ShowManageUsersTab===true &&  <section className="dashboard">
                <div className="adminmanageusers container">
                    <div className='menubox'>
                        <div className='tabmenu'>
                            <a className="active" onClick={changeUsers} data-item="allusers">All</a>
                            <a className="" onClick={changeUsers} data-item="allnutrition">Coach</a>
                            <a className="" onClick={changeUsers} data-item="allclient">Client</a>
                        </div>
                        <div className='rightmenu'>
                            <input type="text" placeholder="&#xF002; Search" onChange={event => searchUsers(event.target.value)} />

                            {paginationFor === 'allusers' || paginationFor === 'allclient' ?
                                <button data-modal="add_client" onClick={modalOpen}>Add Client</button>
                                : null}
                            {paginationFor === 'allusers' || paginationFor === 'allnutrition' ?
                                <button data-modal="add_coach" onClick={modalOpen}>Add Coach</button>
                                : null}
                        </div>
                    </div>
                    <Table className="table is-striped is-fullwidth">
                        <Thead className="thead">
                            <Tr>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Joined</Th>
                                <Th>User Type</Th>
                                <Th>GLP Eligible</Th>
                                <Th>Headsup Enable</Th>
                                <Th>Active Plan</Th>
                                <Th>Ban</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">
                            {users.map((user, index) => (
                                <Tr className='trow navigateclientdashboard' key={user.id} data-role={user.role}  onClick={(e)=>{ShowClientDashboard(e)}} data-id={user.id} data-glp={user.is_glp_user} >
                                    <Td>
                                        <img className='table_profile_img' src={user.user_profile_photo ?  user.user_profile_photo : (user.gender === 'MALE' ? male_icon : female_icon)} />{user.first_name} {user.last_name}
                                    </Td>
                                    <Td className="color_grey">
                                        <i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{user.email}
                                    </Td>
                                    <Td className="color_grey">
                                        <i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(user.createdAt), `PP`)}
                                    </Td>
                                    <Td>
                                        <p className={user.role === 'Nutrition' ? 'orangebox' : 'purplebox'}>{user.role === 'Nutrition' ? 'Coach' : 'Client'}</p>
                                    </Td>
                                    <Td>
                                        {
                                            (user.is_glp_user===true) ? <p>Yes</p> : ((user.role==='Client')? <p>No</p> : <p>-</p>)
                                        }
                                    
                                    </Td>
                                    <Td>
                                        {
                                            user.role==='Nutrition' ? <input type="checkbox" data-item={user.id} onChange={headsupenable} value={user.headsup_enable} checked={user.headsup_enable} className="no_target" /> : null 
                                        }                                    
                                    </Td>
                                    <Td>
                                        {user.role === 'Nutrition' ? (user.nutritionist_infos[0].service_plan && user.nutritionist_infos[0].user_plan_status ? <a className='plan_link_manage_users no_target' data-id={user.id} data-category="coach" onClick={(e)=>{UpgradePlan(e)}}>{user.nutritionist_infos[0].service_plan.service_plan_name}</a> : <a className='plan_link_manage_users no_target' data-id={user.id} data-category="coach" onClick={(e)=>{UpgradePlan(e)}}>Upgrade plan</a>) : (user.client_infos[0].subscription_plan && moment(user.client_infos[0].subscription_e_date) >= moment() ? <a  className='plan_link_manage_users no_target' data-category="client" data-id={user.id} onClick={(e)=>{UpgradePlan(e)}}>{user.client_infos[0].client_plan.plan_name}</a>: <a className='plan_link_manage_users no_target' data-category="client" data-id={user.id}  onClick={(e)=>{UpgradePlan(e)}}>Upgrade plan</a>)}                                        
                                    </Td>
                                    <Td>
                                        <input type="checkbox" data-item={user.id} onChange={user_ban} value={user.isBan} checked={user.isBan} className="no_target" />
                                    </Td>
                                    <Td>
                                        <i className="fa fa-edit td_icon action no_target" data-modal="user_edit" data-item={user.id} onClick={modalOpen}></i>
                                        {user.role === 'Nutrition' && 
                                        <i className="fa fa-plus td_icon action add_commission_expert no_target" aria-hidden="true" data-item={user.id} onClick={(e) => { add_commission_expert(e) }}></i>
                                        }
                                        {user.role === 'Nutrition' && 
                                        <i className="fa fa-user td_icon action add_commission_expert no_target" aria-hidden="true" data-item={user.id} onClick={(e) => { TriggerRelationPopup(e) }}></i>}
                                        {user.role === 'Client' && 
                                        <i className="fa fa-user td_icon action add_commission_expert no_target" aria-hidden="true" data-item={user.id} onClick={(e) => { CoachRelationPopup(e) }}></i>}
                                          {user.role === 'Client' && 
                                      <i class='fas fa-weight-hanging action td_icon weight_icon_withings no_target' aria-hidden="true" data-item={user.id} onClick={(e) => { CheckWithingsDetails(e) }}></i>}
                                        <i className="fa fa-trash td_icon action no_target" aria-hidden="true" data-modal="delete_confirm" data-item={user.id} onClick={modalOpen}></i>                                        
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    {usersCount ?
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={countPerPage}
                            totalItemsCount={usersCount}
                            onChange={changePageNumber.bind(this)}
                            prevPageText="<"
                            nextPageText=">"
                            hideFirstLastPages={true}
                            linkClassPrev="pagination_prev"
                            linkClassNext="pagination_next"
                            linkClass="pagenumbers color_grey"
                        />
                        : null}

                    <div style={{ clear: 'both' }}></div>
                </div>
            </section>
            }
            
            { 
            ShowClientDashboardTab===true && 
                <AdminClientDashboard HideDashboardTab={HideDashboardTab} ClickedUserId={ClickedUserId} ClickedUserIdGlp={ClickedUserIdGlp}  />
            }
            {
                ShowCoachDashboardTab===true && 
                <AdminCoachDashboard HideDashboardTab={HideDashboardTab} ClickedUserId={ClickedUserId} ShowClientDashboardTrigger={ShowClientDashboardTrigger}/>
            }
           

            <Modal show={userDeletePopup.open} onHide={modalClose} className='commission_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want delete the user?
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='close_button' onClick={modalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-item={userDeletePopup.id} onClick={(e) => { user_delete(e) }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={userEditPopup.open} onHide={modalClose} className='admin_manageusers_user_edit_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>

                    {formErr === true &&
                        <div className='form_err'>{formErrMsg}</div>
                    }

                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>First Name:</label>
                            <input type="text" name="first_name" defaultValue={user_edit_first_name} ref={EditFnameRef} onChange={(e) => setFirst_name(e.target.value)} />
                            <div className='err'>
                                {EditFNameErr === true &&
                                    <span>{EditFNameMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Last Name:</label><input type="text" name="last_name" defaultValue={user_edit_last_name} ref={EditLnameRef} onChange={(e) => setLast_name(e.target.value)} />
                            <div className='err'>
                                {EditLNameErr === true &&
                                    <span>  {EditLNameMsg}</span>
                                }
                            </div>
                        </div>
                    </div>                    
                    <div className='row'>                        
                        <div className='col'>
                            <label className='inner_popup_label required'>Gender:</label><input type="radio" value="MALE" name="gender" onChange={(e) => setGender(e.target.value)} defaultChecked={user_edit_gender === 'MALE'} ref={EditGenderRef} /> Male
                            <input style={{ "marginLeft": "10px" }} type="radio" value="FEMALE" name="gender" onChange={(e) => setGender(e.target.value)} defaultChecked={user_edit_gender === 'FEMALE'} ref={EditGenderRef} /> Female
                            <div className='err'>
                                {EditGenderErr === true &&
                                    <span>  {EditGenderMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Country:</label>
                            <Select
                                    options={countryList}
                                    onChange={filterstate}
                                    value={user_edit_country}
                                    ref={EditCountryRef}
                            />
                            <div className='err'>
                                {EditCountryErr === true &&
                                    <span>*Please select country</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>State:</label>
                            <Select
                                    options={stateList}
                                    onChange={filtercity}
                                    value={user_edit_state}
                                    ref={EditStateRef}
                            />
                            <div className='err'>
                                {EditStateErr === true &&
                                    <span>*Please select state</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>City:</label>
                            <Select
                                    options={cityList}
                                    onChange={setCity}
                                    value={user_edit_city}
                                    ref={EditCityRef}
                            />
                            <div className='err'>
                                {EditCityErr === true &&
                                    <span>*Please select city</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Address:</label>
                            <input onChange={(e) => { SetAddress(e.target.value) }} defaultValue={Address} ref={EditAddressRef}/>
                            <div className='err'>
                                {EditAddressErr === true &&
                                    <span>*Please enter address</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Postal Code:</label>
                            <input onChange={(e) => { SetPostal(e.target.value) }} defaultValue={Postal}  {...(EditPostalErr === true ? { id: 'err_border' } : {})} ref={EditPostalRef}/><br />
                            <div className='err'>
                                {EditPostalErr === true &&
                                    <span>  {EditPostalMsg}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Email:</label>
                            <input type="text" name="email" defaultValue={user_edit_email} onChange={(e) => setEmail(e.target.value)} ref={EditEmailRef} />
                            <div className='err'>
                                {EditEmailErr === true &&
                                    <span style={{ paddingLeft: '26px' }}>{EditEmailMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Phone:</label>
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={user_edit_phone}
                                onChange={setPhone}
                                ref={EditPhoneRef} />

                            <div className='err'>
                                {EditPhoneErr === true &&
                                    <span>  {EditPhoneMsg}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Role:</label>
                            <select className='role' disabled={true} name="role" ref={EditRoleRef} value={user_edit_role} onChange={(e) => setRole(e.target.value)}><option value="">Select Role</option><option value="Nutrition">Coach</option><option value="Client">Client</option></select>
                            <div className='err'>
                                {EditRoleErr === true &&
                                    <span style={{ paddingLeft: '26px' }}>{EditRoleMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Timezone:</label>
                            <Select
                                defaultValue={{ value: user_edit_timezone, label: user_edit_timezone }}
                                options={timezonesList}
                                onChange={setuser_timezone}
                                className="useredit_timezone"
                                ref={EditTimeZoneRef}
                            />
                            <div className='err'>
                                {TimeZoneErr === true &&
                                    <span>{TimeZoneMsg}</span>
                                }
                            </div>
                        </div>                        
                    </div>

                    {ShowCoachEditPlan === true &&
                        <div>
                            <div className='row'>
                                <div className='col'>
                                    <label className='inner_popup_label'>Allowed Clients:</label>
                                    <input onChange={(e) => { SetAllowedClients(e.target.value) }} defaultValue={AllowedClients} type={'number'} />
                                </div>
                                <div className='col'>
                                    <label className='inner_popup_label'>Expiry Date:</label>
                                    <Datepicker
                                        value={ExpiryDate}
                                        onChange={SetExpiryDate}
                                        format="MM-dd-yyyy"
                                        className={'date_picker_payment_coach'}
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <label className='inner_popup_label'>Specalized in: <span className='add_spl_span' onClick={NavigateCoachCat}>(Add)</span></label>
                                    <select className='specialized_in' defaultValue={SpecializedIn} onChange={(e) => { SetSpecializedIn(e.target.value) }} value={SpecializedIn}>
                                    <option value={''}>{'Select value'}</option>
                                        {SpecializedInlist.map((list, index) => (
                                            <option value={list.category_name}>{list.category_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Password:</label>
                            <input type="text" name="password" defaultValue='' onChange={(e) => setPassword(e.target.value)} ref={EditPassRef} />
                            <div className='err'>
                                {EditPassErr === true &&
                                    <span>  {EditPassMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label'>Ban:</label>
                            <input className='ban' type="checkbox" name="isBan" defaultChecked={user_edit_isBan} onChange={(e) => setBan(e.target.checked)} />
                        </div>
                    </div>
                    {ExpiryDateErr === true && <span className="err">Please select expiry date</span>}

                    {ShowCoachEditPlan === true &&
                        <div className='row coach_bio_row'>
                            <div className='col'>
                                <label className='inner_popup_label required'>Bio:</label>
                                <textarea rows={'5'} defaultValue={CoachBio}
                                    onChange={(e) => { SetCoachBio(e.target.value) }}  {...(CoachBioErr === true ? { id: 'err_border' } : {})}></textarea>
                            </div>
                            <div className='err'>
                                {CoachBioErr === true &&
                                    <span>{'Please enter coach bio'}</span>
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='close_button' onClick={modalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-item={userEditPopup.id} onClick={(e) => { user_edit(e) }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={userAddCoachPopup.open} onHide={modalClose} className='admin_manageusers_add_coach_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Coach</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
                    {formErr === true &&
                        <div className='form_err'>{formErrMsg}</div>
                    }

                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>First Name:</label>
                            <input type="text" className='input' name="first_name" onChange={(e) => setFirst_name(e.target.value)} ref={FirstNameRefCoach} />
                            <div className='err'>
                                {CoachFNameErr === true &&
                                    <span>{CoachFNameMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Last Name:</label>
                            <input type="text" name="last_name" onChange={(e) => setLast_name(e.target.value)} ref={LastNameRefCoach} />
                            <div className='err'>
                                {CoachLNameErr === true &&
                                    <span>{CoachLNameMsg}</span>
                                }
                            </div>
                        </div>
                    </div>                    
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Gender:</label>
                            <input type="radio" value="MALE" name="gender" onChange={(e) => setGender(e.target.value)} ref={GenderRefCoach} /> Male
                            <input style={{ "marginLeft": "10px" }} type="radio" value="FEMALE" name="gender" onChange={(e) => setGender(e.target.value)} /> Female
                            <div className='err'>
                                {CoachGenderErr === true &&
                                    <span>{CoachGenderMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Country:</label>
                            <Select
                                    options={countryList}
                                    onChange={filterstate}
                                    value={user_edit_country}
                                    ref={CountryRefCoach}
                            />
                            <div className='err'>
                                {CoachCountryErr === true &&
                                    <span>*Please select country</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>State:</label>
                            <Select
                                    options={stateList}
                                    onChange={filtercity}
                                    value={user_edit_state}
                                    ref={StateRefCoach}
                            />
                            <div className='err'>
                                {CoachStateErr === true &&
                                    <span>*Please select state</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required' >City:</label>
                            <Select
                                    options={cityList}
                                    onChange={setCity}
                                    value={user_edit_city}
                                    ref={CityRefCoach}
                            />
                            <div className='err'>
                                {CoachCityErr === true &&
                                    <span>*Please select city</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Address:</label>
                            <input onChange={(e) => { SetAddress(e.target.value) }} ref={AddressRefCoach}/>
                            <div className='err'>
                                {CoachAddressErr === true &&
                                    <span>*Please enter address</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required' >Postal Code:</label>
                            <input onChange={(e) => { SetPostal(e.target.value) }} {...(CoachPostalErr === true ? { id: 'err_border' } : {})} ref={PostalRefCoach}/>
                            <div className='err'>
                                {CoachPostalErr === true &&
                                    <span>{CoachPostalMsg}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Email:</label>
                            <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} ref={EmailRefCoach} />
                            <div className='err'>
                                {CoachEmailErr === true &&
                                    <span>{CoachEmailMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Phone:</label>
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={user_edit_phone}
                                onChange={setPhone}
                                ref={PhoneRefCoach} />

                            <div className='err'>
                                {CoachPhoneErr === true &&
                                    <span>{CoachPhoneMsg}</span>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Timezone:</label>
                            <Select
                                options={timezonesList}
                                onChange={setuser_timezone}
                                className="coachadd_timezone"
                                ref={TimeZoneRefCoach}
                            />
                            <div className='err'>
                                {TimeZoneErr === true &&
                                    <span>{TimeZoneMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label'>Specalized in:<span className='add_spl_span' onClick={NavigateCoachCat}>(Add)</span></label>
                            <select className='specialized_in' defaultValue={SpecializedIn} onChange={(e) => { SetSpecializedIn(e.target.value) }}>
                            <option value={''}>{'Select value'}</option>
                                {SpecializedInlist.map((list, index) => (
                                    <option value={list.category_name}>{list.category_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label'>Allowed Clients:</label>
                            <input onChange={(e) => { SetAllowedClients(e.target.value) }} defaultValue={AllowedClients} type={'number'} />
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label'>Expiry Date:</label>
                            <Datepicker
                                value={ExpiryDate}
                                onChange={SetExpiryDate}
                                format="MM-dd-yyyy"
                                className={'date_picker_payment_coach'}
                                minDate={new Date()}
                            />
                        </div>
                        {ExpiryDateErr === true && <span className="err">Please select expiry date</span>}
                    </div>
                    <div className='row'>
                        <div className='row'>
                            <div className='col'>
                                <label className='inner_popup_label required'>Password:</label>
                                <input type="text" name="password" onChange={(e) => setPassword(e.target.value)} ref={PassWordRefCoach} />
                                <div className='err'>
                                    {CoachPassErr === true &&
                                        <span>{CoachPassMsg}</span>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='row coach_bio_row'>
                            <div className='col'>
                                <label className='inner_popup_label required'>Bio:</label>
                                <textarea rows={'5'} defaultValue={CoachBio}
                                    onChange={(e) => { SetCoachBio(e.target.value) }}  {...(CoachBioErr === true ? { id: 'err_border' } : {})}></textarea>
                            </div>
                            <div className='err'>
                                {CoachBioErr === true &&
                                    <span>{'Please enter coach bio'}</span>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='close_button' onClick={modalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-modal="add_coach" onClick={(e) => { user_add(e) }}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>            

            <Modal show={userAddClientPopup.open} onHide={modalClose} className='admin_manageusers_add_client_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
                    {formErr === true &&
                        <div className='form_err'>{formErrMsg}</div>
                    }

                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>First Name:</label>
                            <input type="text" name="first_name" onChange={(e) => setFirst_name(e.target.value)} ref={FirstNameRefClient} />
                            <div className='err'>
                                {ClientFNameErr === true &&
                                    <span>{ClientFNameMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Last Name:</label>
                            <input type="text" name="last_name" onChange={(e) => setLast_name(e.target.value)} ref={LastNameRefClient} />
                            <div className='err'>
                                {ClientLNameErr === true &&
                                    <span>  {ClientLNameMsg}</span>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Gender:</label>
                            <input type="radio" value="MALE" name="gender" onChange={(e) => setGender(e.target.value)} ref={GenderRefClient} /> Male
                            <input style={{ "marginLeft": "10px" }} type="radio" value="FEMALE" name="gender" onChange={(e) => setGender(e.target.value)} ref={GenderRefClient} /> Female
                            <div className='err'>
                                {ClientGenderErr === true &&
                                    <span>{ClientGenderMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Country:</label>
                            <Select
                                    options={countryList}
                                    onChange={filterstate}
                                    value={user_edit_country}
                                    ref={CountryRefClient}
                            />
                            <div className='err'>
                                {ClientCountryErr === true &&
                                    <span>*Please select country</span>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>State:</label>
                            <Select
                                    options={stateList}
                                    onChange={filtercity}
                                    value={user_edit_state}
                                    ref={StateRefClient}
                            />
                            <div className='err'>
                                {ClientStateErr === true &&
                                    <span>*Please select state</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required' >City:</label>
                            <Select
                                    options={cityList}
                                    onChange={setCity}
                                    value={user_edit_city}
                                    ref={CityRefClient}
                            />
                            <div className='err'>
                                {ClientCityErr === true &&
                                    <span>*Please select city</span>
                                }
                            </div>
                        </div>
                    </div>
                   
                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Address:</label>
                            <input onChange={(e) => { SetAddress(e.target.value) }} ref={AddressRefClient}/>
                            <div className='err'>
                                {ClientAddressErr === true &&
                                    <span>*Please enter address</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required' >Postal Code:</label>
                            <input onChange={(e) => { SetPostal(e.target.value) }} {...(ClientPostalErr === true ? { id: 'err_border' } : {})} ref={PostalRefClient}/>
                            <div className='err'>
                                {ClientPostalErr === true &&
                                    <span>{ClientPostalMsg}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Email:</label>
                            <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} ref={EmailRefClient} />
                            <div className='err'>
                                {ClientEmailErr === true &&
                                    <span>{ClientEmailMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Phone:</label>
                            <PhoneInput
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={user_edit_phone}
                                onChange={setPhone}
                                ref={PhoneRefClient} />

                            <div className='err'>
                                {ClientPhoneErr === true &&
                                    <span>  {ClientPhoneMsg}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <label className='inner_popup_label required'>Timezone:</label>
                            <Select
                                options={timezonesList}
                                onChange={setuser_timezone}
                                className="clientadd_timezone"
                                ref={TimeZoneRefClient}
                            />
                            <div className='err'>
                                {TimeZoneErr === true &&
                                    <span>{TimeZoneMsg}</span>
                                }
                            </div>
                        </div>
                        <div className='col'>
                            <label className='inner_popup_label required'>Password:</label>
                            <input type="text" name="password" onChange={(e) => setPassword(e.target.value)} ref={PassWordRefClient} />
                            <div className='err'>
                                {ClientPassErr === true &&
                                    <span>  {ClientPassMsg}</span>
                                }
                            </div>
                        </div>                        
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='close_button' onClick={modalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-modal="add_client" onClick={(e) => { user_add(e) }}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
            

            <Modal show={show} onHide={handleClose} className='commission_popup'>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>use commissions to configure your commission rates to the experts for using the platform.commission rates are applied against each individual payment</p>
                    <p>1.Commissions can be on 2 levels:Marketplace (common for all experts) or expert (To manage individual expert's commission,Go to Manage Users {'->'} commission)</p>
                    <p>2.Commission can be of 2 types:Fixed or Percentage and can be handled on the platform or offline</p>
                    <div className="row section_1_commission">
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Commission Status</label><br />
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionStatus} onChange={(e) => { SetCommissionStatus(e.target.value) }}>
                                <option value="1">Enable</option>
                                <option value="0">Disable</option>
                            </select>
                        </div>
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Commission Level</label><br />
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionLevel} onChange={(e) => { SetCommissionLevel(e.target.value) }}>
                                <option value="expert">Expert</option>
                            </select>
                        </div>
                    </div>
                    <div className="row section_1_commission section_2_commission">
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Select Commission Type</label><br />
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionType} onChange={(e) => { SetCommissionType(e.target.value) }}>
                                <option value="fixed">Fixed</option>
                                <option value="percentage">Percentage</option>
                            </select>
                        </div>
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Select Payout Method</label><br />
                            <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select' style={{ float: 'left', marginTop: '6px', width: '100%' }} defaultValue={CommissionPayout} onChange={(e) => { SetCommissionPayout(e.target.value) }}>
                                <option value="fixed">Fixed</option>
                                <option value="percentage">Percentage</option>
                            </select>
                        </div>
                    </div>
                    <div className="row section_1_commission section_2_commission">
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Commission Value</label><br />
                            <input type='number' className='commission_inner_input' defaultValue={CommissionVal} onChange={(e) => { SetCommissionVal(e.target.value) }} {...(CommissionvalueErr === true ? { id: 'err_border' } : {})}></input>
                        </div>
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Min Payment Amount</label><br />
                            <input type='number' className='commission_inner_input' defaultValue={CommissionMin} onChange={(e) => { SetCommissionMin(e.target.value) }} {...(MinPayErr === true ? { id: 'err_border' } : {})}></input>
                        </div>
                        <div className="col">
                            <label className='commission_inner_popup_label required'>Max.Com value</label><br />
                            <input type='number' className='commission_inner_input' defaultValue={CommissionMax} onChange={(e) => { SetCommissionMax(e.target.value) }} {...(MaxComErr === true ? { id: 'err_border' } : {})} ></input>
                        </div>
                    </div>

                    <div className='row fnameerr_admin_coach fnameerr_admin_coach_comision err'>
                        <div className='col1'>
                            {CommissionvalueErr === true &&
                                <span >{CommissionvalueMsg}</span>
                            }
                        </div>

                        <div className='col2'>
                            {MinPayErr === true &&
                                <span>  {MinPayMsg}</span>
                            }
                        </div>

                        <div className='col2'>
                            {MaxComErr === true &&
                                <span>  {MaxComMsg}</span>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='display_block'>
                    <Button variant="secondary" className='close_button_commission' onClick={handleClose}>
                        Back
                    </Button>
                    <Button variant="primary" className='save_button_commission float_right' data-isupdate={isupdate} data-update={CommissionUpdate} onClick={(e) => { SaveCommission(e) }}>
                        Save Commission
                    </Button>
                </Modal.Footer>
            </Modal>

            

            <Modal
                show={clientConnectShow}
                onHide={clientConnectClose}
                className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Client - Connected Coach List</Modal.Title>
                </Modal.Header>
                <Modal.Body className="allow_permission">
                    <div className="select_members">
                        <label className="label">Select Coach</label>
                        <Select
                            options={coachOptions}
                            isMulti
                            onChange={(e) => {
                                DropdownChangeConnectedCoach(e);
                            }}
                            defaultValue={existConnectedCoachList}
                            {...(clientConnectErr === true ? { id: "err_border" } : {})}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button
                        variant="secondary"
                        className="close_button_commission"
                        onClick={clientConnectClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="save_button_commission float_right"
                        onClick={updateClientConnectedList}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={pshow}
                onHide={phandleClose}
                className="commission_popup add_coach_service_category_popup delete_specialized_in_pop"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Coach - Connected Client List</Modal.Title>
                </Modal.Header>
                <Modal.Body className="allow_permission">
                    <div className="select_members">
                        <label className="label">Select Clients</label>
                        <Select
                            options={clientoptions}
                            isMulti
                            onChange={(e) => {
                                DropdownChangeClient(e);
                            }}
                            defaultValue={DefaultSelectedClientMembers}
                            {...(ClientErr === true ? { id: "err_border" } : {})}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button
                        variant="secondary"
                        className="close_button_commission"
                        onClick={phandleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="save_button_commission float_right"
                        onClick={ProceedPermission}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ClientSubscriptionPopupshow} onHide={HandlCloseClientSubscriptionPopupshow} className='commission_popup add_billing_popup show_packages_popup buy_service_popup_client'>
          <Modal.Header closeButton>
            <Modal.Title>{'Client Plans'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
            <div className="row session_package_list">
              {ClientPlansList.length > 0 && ClientPlansList.map((service, index) => (
                <div  key={index} data-id={service.id} className={parseFloat(ClientSubcribedId) === parseFloat(service.id) ? "plan_col active" : "plan_col"}>
                  <p className="p_tag_label_top_subs">{service.plan_name}</p>
                  <div className="amount_row">
                    <span className="amount_value">${service.plan_price}</span>
                  </div>
                  <div className="plan_info">
                    <ul className="list-unstyled mb-4">
                    {FormatTags(service.plan_content).map((plans, index) => (
                      <li>{plans}</li>
                    ))}                      
                    </ul>
                  </div>
                  <div className="low_level_button_subscriptions">
                    {
                        CheckClientButtonRender(service.id)                        
                    }                 
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>


        <Modal show={CoachSubscriptionPopupshow} onHide={HandlCloseCoachSubscriptionPopupshow} className='commission_popup add_billing_popup show_packages_popup buy_service_popup_client'>
          <Modal.Header closeButton>
            <Modal.Title>{'Coach Plans'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
            <div className="row session_package_list">
              {CoachPlanList.length > 0 && CoachPlanList.map((service, index) => (
                <div  key={index} data-id={service.id} className={parseFloat(CoachSubscribedId) === parseFloat(service.id) ? "plan_col active" : "plan_col"}>
                  <p className="p_tag_label_top_subs">{service.service_plan_name}</p>
                  <div className="amount_row">
                    <span className="amount_value">${service.service_plan_amount}</span>
                  </div>
                  <div className="plan_info">
                    <ul className="list-unstyled mb-4">
                    {/* {FormatTags(service.plan_content).map((plans, index) => (
                      <li>{plans}</li>
                    ))} */}

    {(parseInt(service.service_plan_duration) === 12) &&
                                                    <li>Billing: ${service.service_plan_amount} / Yearly</li>
                                                }
                                                    {
                                                        (parseInt(service.service_plan_duration) === 1) &&
                                                        <li>Billing: ${service.service_plan_amount} / Monthly</li>
                                                    }
                                                    {
                                                (parseInt(service.service_plan_duration) !== 1 && parseInt(service.service_plan_duration)!==12) &&
                                                <li>Billing: ${service.service_plan_amount} /every {service.service_plan_duration} Months</li>
                                            }

<li>Allowed Clients : {service.service_plan_allowed_users}</li>
                                            <li>Manage Users</li>
                                            <li>Meal Plan Suggestions</li>
                                            <li>Online Consultation</li>
                      
                    </ul>
                  </div>
                  <div className="low_level_button_subscriptions">
                    {

                        CheckCoachButtonRender(service.id)
                        
                    }
                 
                  </div>
                </div>
              ))}
            </div>
            
            
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showfail}
          onHide={CloseFailPopup}
          className="confirm_delete"
        >
          <Modal.Header closeButton>
            <Modal.Title>{FailedTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{FailedMsg}</Modal.Body>
          <Modal.Footer className="display_block">
            <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseFailPopup}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showConfirmAssign}
          onHide={CloseConfirmAssign}
          className="confirm_delete"
        >
                 <div style={{ display: "none" }} id="semiTransparenDivLoader"></div>
          <Modal.Header closeButton>
            <Modal.Title>{ConfirmaAssignTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{ConfirmAssignMsg}</Modal.Body>
          <Modal.Footer className="display_block">
          <Button
              variant="primary"
              className="modal_action_button"
              onClick={ConfirmAssignPlan}
              data-target={ConfirmAssignTarget}
            >
              Proceed
            </Button>
          <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseConfirmAssign}
            >
              Close
            </Button>
           
          </Modal.Footer>
        </Modal>

{/* Withings Order Details */}

        <Modal
          show={showWithingsOrder}
          onHide={CloseWithingsOrder}
          className="confirm_delete"
        >
      
          <Modal.Header closeButton>
            <Modal.Title>{'Withings Device Order Details'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {
                ShowWithingsDetails===true ?    <div className='overall_withings_admin_div'>
                <div className='overall_withings_admin_row'>
                    <div className='overall_withings_admin_col1'>Customer Ref id:</div>
                    <div className='overall_withings_admin_col2'>{WithingsDeviceCustomerId}</div>
                </div>
                <div className='overall_withings_admin_row'>
                    <div className='overall_withings_admin_col1'>Device order id:</div>
                    <div className='overall_withings_admin_col2'>{WithingsDeviceOrderId}</div>
                </div>
                <div className='overall_withings_admin_row'>
                    <div className='overall_withings_admin_col1'>Device ordered date:</div>
                    <div className='overall_withings_admin_col2'>{WithingsDeviceOrderDate}</div>
                    
                </div>
                <div className='overall_withings_admin_row'>
                    <div className='overall_withings_admin_col1'>Device delivery address:</div>
                    <div className='overall_withings_admin_col2'>{WithingsDeviceDeliveryAddress}</div>
                    
                </div>
            </div> : <div className='overall_withings_admin_div'>Withings device order details for this client is not available</div>
            }
        
            
            </Modal.Body>
          <Modal.Footer className="display_block">
          <Button
              variant="primary"
              className="modal_action_button float_right"
              onClick={CloseWithingsOrder}
            >
              Close
            </Button>
           
          </Modal.Footer>
        </Modal>

    
        </div >
    )
}

export default ManageUsers
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Pagination from "react-js-pagination";
import { format, parseISO } from 'date-fns'
import moment from 'moment-timezone';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const AdminManageAppointment = () => {



  const axiosJWT = axios.create();

  const side_nav = localStorage.getItem("side_nav");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");

  const [Pagenumber, SetPagenumber] = useState(0)
  const [Perpage, SetPerpage] = useState(10)
  const [Totalrecord, SetTotalrecord] = useState(0)
  const [ListOfAppointments, SetListOfAppointments] = useState([])

  const GetAllAppointments = async (pagenumber) => {
    SetPagenumber(pagenumber)

    const response = await axiosJWT.get('/appointment/all-appointments?offset=' + pagenumber + '&limit=' + Perpage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    console.log('appppp',response)



    SetListOfAppointments(response.data.FinalArray)
    SetTotalrecord(response.data.TotalRecord)


  }

  const changePageNumber = (pagenumber) => {

    GetAllAppointments(pagenumber)
  }

  useEffect(() => {
    document.title = 'Admin-Manage appointment';
    GetAllAppointments(1)
  }, [])

  function timetoUtcClientAppointment(passdate) {


    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss')

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss')
    return stardates



  }


  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className="dashboard">
        <div className="container">
          <div className="row admin_appointment_head_row">
            <span>Manage Appointments</span>
          </div>
          <div className="row upcoming_appointment_table_row">


            <Table className="table is-striped is-fullwidth upcoming_appointment_table">
              <Thead className="thead">
                <Tr className="trow">
                  <Th scope="col">Listing</Th>
                  <Th scope="col">Coach</Th>
                  <Th scope="col">Coach Email</Th>
                  <Th scope="col">Subscription</Th>
                  <Th scope="col">Client</Th>
                  <Th scope="col">Client Email</Th>
                  { /*<th scope="col">Purpose</th>*/}
                  <Th scope="col">Booking Date {'&'} time</Th>
                  <Th>status</Th>
                </Tr>
              </Thead>
              <Tbody className="tbody manager_appointment_list_admin">

                {ListOfAppointments.length > 0 && ListOfAppointments.map((list, index) => (

                  

                  <Tr className="trow">
                    <Td><span className="desing_yellow">{list.appointment.booked_by}</span></Td>
                    <Td>{list.coach!==null && list.coach.first_name + ' ' + list.coach.last_name}</Td>
                    <Td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{list.coach!==null && list.coach.email}</Td>
                    <Td>.</Td>
                    <Td>{list.client!==null && list.client.first_name + ' ' + list.client.last_name}</Td>
                    <Td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>
                    <span>{list.client!==null && list.client.email}</span></Td>
                    {/*<td ><span className="desing_back">{list.appointment.meeting_description}</span></td>*/}
                    <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(timetoUtcClientAppointment(list.appointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</Td>
                    <Td>

                      { list.appointment.meeting_status === 0 && 'Not completed'}
                      { list.appointment.meeting_status === 1 && 'completed'}
                      { list.appointment.meeting_status === 3 && 'Rescheduled'}
                      { list.appointment.meeting_status === 2 && 'Cancelled'}
                     
                    </Td>
                  </Tr>

                ))}
              </Tbody>
            </Table>
          </div>
          <div className="row" style={{ float: "right", marginTop: "20px" }}>

            {Totalrecord ?
            <Pagination
              activePage={Pagenumber}
              itemsCountPerPage={Perpage}
              totalItemsCount={Totalrecord}
              onChange={changePageNumber.bind(this)}
              prevPageText="<"
              nextPageText=">"
              hideFirstLastPages={true}
              linkClassPrev="pagination_prev"
              linkClassNext="pagination_next"
              linkClass="pagenumbers grayfont"
            />
            : null }

          </div>
        </div>
      </section>
    </div>

  )
}

export default AdminManageAppointment
import React, { useEffect, useState } from "react";
import TimePicker from 'react-time-picker';
import Datepicker from 'react-date-picker';
import axios from "axios";
import Button from 'react-bootstrap/Button';

const NutritionBristol=(props)=>{
    useEffect(()=>{
        SetIsClientid(props.IsClientid)
    },[props])
    const [IsClientid,SetIsClientid]=useState('')

    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();
    const [AddDataDate, SetAddDataDate] = useState(new Date())
    const [DateErr, SetDateErr] = useState(false)
    const [TImePicker, SetTimePicker] = useState(formatAMPM(new Date()));
    const [TimeErr, SetTimeErr] = useState(false)
    const [Notes, SetNotes] = useState('')
    const [saveAddSuccess,SetsaveAddSuccess]=useState(false)
    const [SystolicValue,SetSystolicValue]=useState('')
    const [SystolicErr,SetSystolicErr]=useState(false)


    const SaveCardioPressure=async(e)=>{

        const action = e.target.getAttribute('data-action')
        if(action==='cancel')
        {
            props.SetShowNutritionBristolField(false)
            props.SetNutritionOption(true)
            return false
        }

        var err=false;
        SetSystolicErr(false)
     
      

        if(SystolicValue==='')
        {
            err=true;
            SetSystolicErr(true)
        }
      
        if (AddDataDate === null) {
            err = true
            SetDateErr(true)
        }
        if (TImePicker === null) {
            err = true
            SetTimeErr(true)
        }
        if(err===false)
        {
            var passDate = ''
            if (AddDataDate != null) {

                passDate = ChangeDatePickerDate(AddDataDate)


            }

            const response = await axiosJWT.post('add-data/add-nutrition',
            {

                'date': passDate,
                'time': TImePicker,
                'type': 'Bristol Score',
                'bristol_score':SystolicValue,
                'client_id':IsClientid,
                'notes': Notes
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {

                if(action==='save')
                {
                    props.SetShowNutritionBristolField(false)
                    props.SetNutritionOption(true)
              
                }
                else if(action==='save_add')
                {
                    SetsaveAddSuccess(true)
                    setTimeout(() => {
                        SetsaveAddSuccess(false)   
                    }, 1000);
                }
                
            }
           
        }



    }

    const ChangeDatePickerDate = (pass) => {
        var passDate = ''
        var yyyy = pass.getFullYear().toString();
        var mm = (pass.getMonth() + 1).toString();
        var dd = pass.getDate().toString();
        passDate = yyyy + '-' + mm + '-' + dd
        return passDate
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var strTime = hours + ':' + minutes;
        return strTime;
    }



    return (

        <div className="physical_activity">
<h6 className="heading_popup">{'Nutrition > Bristol score'}</h6>
<div className="field_top_row">
                <div className="label">Date</div>
                <div className="field">
                    <Datepicker
                        value={AddDataDate}
                        onChange={SetAddDataDate}
                        format="MM-dd-yyyy"
                         />
                </div>

                {DateErr === true && <span className="err">Please select date</span>}

            </div>

            <div className="field_top_row">
                <div className="label">Time</div>
                <div className="field">
                    <TimePicker onChange={SetTimePicker} value={TImePicker} />
                </div>
                {TimeErr === true && <span className="err">Please select time</span>}

            </div>

         

            <div className="field_top_row">
                <div className="label">Bristol Score</div>
                <div className="field">
                    <select className="raw_select" onChange={(e) => { SetSystolicValue(e.target.value) }} {...(SystolicErr === true ? { id: 'err_border' } : {})} defaultValue={SystolicValue} >
                        <option value="">Select type</option>
                        <option value="1">Type 1 Seperate Hard Lumps</option>
                        <option value="2">Type 2 Numby and sausage Like</option>
                        <option value="3">Type 3 Sausage shape with cracks in surface</option>
                        <option value="4">Type 4 Like a sausage or snake,smooth and soft</option>
                        <option value="5">Type 5 Soft blobs with clear cut edges</option>
                        <option value="6">Type 6 Mushy with ragged edges</option>
                        <option value="7">Type 7 Liquid with no solid pieces</option>
                 
                    </select>
                </div>
            </div>
          



        

            <div className="field_top_row">
                <div className="label">Notes(Optional)</div>
                <div className="field">
                    <textarea className="text_area_row" rows="8" cols="35" onChange={(e) => { SetNotes(e.target.value) }} defaultValue={Notes} placeholder='Type Here...'></textarea>
                </div>


            </div>

            <div className="button_row">
                <Button variant="primary" className='modal_action_button float_right' data-action="cancel" onClick={(e) => { SaveCardioPressure(e) }} >
                    {'Cancel'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save_add" onClick={(e) => { SaveCardioPressure(e) }}  >
                    {'Save & Add'}
                </Button>
                <Button variant="primary" className='modal_action_button float_right' data-action="save" onClick={(e) => { SaveCardioPressure(e) }} >
                    {'Save'}
                </Button>

           </div>

           {
                saveAddSuccess===true && 
                <div className="field_top_row">
                <div className="label">Data Saved!</div>
                </div>
            }


            </div>
    )
}
export default NutritionBristol
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import female_icon from '../../images/female.png'
import moment from 'moment'
import Select from 'react-select'
import male_icon from '../../images/male.png'
import { format, parseISO } from 'date-fns'
import Pagination from "react-js-pagination";
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ManageUsersByManager = () => {


    const side_nav = localStorage.getItem("side_nav");
    const moment = require('moment-timezone');
    const timeZones = moment.tz.names();

    const axiosJWT = axios.create();
    const [users, Setusers] = useState([])
    const [usersCount, SetusersCount] = useState(0)
    const [activePage, SetactivePage] = useState(1)
    const [countPerPage, SetcountPerPage] = useState(10)
    const token = localStorage.getItem("token");
    const [showsedit, Setshowsedit] = useState(false)
    const [timezonesList, settimezonesList] = useState('');
    const [MaleChecked, SetMaleChecked] = useState(false)
    const [FemaleChecked, SetFemaleChecked] = useState(false)
    const [InitialEmailAddress, SetInitialEmailAddress] = useState('')
    const [showsd, Setshowsd] = useState(false)

    const [FnameErr, SetFnameErr] = useState(false)
    const [FnameErrMsg, SetFnameErrMsg] = useState('')

    const [LnameErr, SetLnameErr] = useState(false)
    const [LnameErrMsg, SetLnameErrMsg] = useState('')

    const [EmailErr, SetEmailErr] = useState(false)
    const [EmailErrMsg, SetEmailErrMsg] = useState('')

    const [EditAccess, SetEditAccess] = useState(false)
    const [DeleteAccess, SetDeleteAccess] = useState(false)

    const [Address,SetAddress]=useState('')
    const [Postal,SetPostal]=useState('')

    const handleClosesd=()=>{
        Setshowsd(false)
    }
    const [DeleteUserId, SetDeleteUserId] = useState('')
    var timezone_list = [];
    timeZones.forEach(element => {
        timezone_list.push({ label: element, value: element });
    });

    const [user_edit_timezone, setuser_timezone] = useState('');
    const handleClosesedit = () => {
        Setshowsedit(false)
    }
    const UpdateUser = async () => {


        var Err = false
        SetFnameErr(false)
        SetLnameErr(false)
        SetEmailErr(false)





        if (EditFirstName === '') {
            SetFnameErr(true)
            SetFnameErrMsg('Please enter first name')

            Err = true
        }
        if (EditLastName === '') {
            SetLnameErr(true)
            SetLnameErrMsg('Please enter last name')
            Err = true
        }
        if (EditEmailAddress === '') {
            SetEmailErr(true)
            SetEmailErrMsg('Please enter email address')
            Err = true
        }
        if (EditEmailAddress !== '') {

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EditEmailAddress) === false) {
                SetEmailErr(true)
                SetEmailErrMsg('Please enter valid email address')
                Err = true
            }

        }





        if (Err === false) {

            if (EditFirstName !== '') {

                await axiosJWT.put('/user/' + EditUserId,
                    {
                        first_name: EditFirstName,

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }
            if (EditLastName !== '') {
                await axiosJWT.put('/user/' + EditUserId,
                    {
                        last_name: EditLastName,

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

            }
            if (EditEmailAddress !== InitialEmailAddress) {

                try {

                    await axiosJWT.put('/user/' + EditUserId,
                        {
                            email: EditEmailAddress,

                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    );

                } catch (error) {
                    SetEmailErr(true)
                    SetEmailErrMsg(error.response.data.message)
                    return false
                }


            }
            if (EditGenderValue !== '') {
                await axiosJWT.put('/user/' + EditUserId,
                    {
                        gender: EditGenderValue,

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

            }
            if (user_edit_timezone !== null && user_edit_timezone !== '') {
                await axiosJWT.put('/user/' + EditUserId,
                    {
                        user_timezone: user_edit_timezone,

                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

            }

            if (EditPassword !== '') {
                await axiosJWT.put('/user/' + EditUserId,
                    {
                        password: EditPassword
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
            }

            await axiosJWT.put('/user/' + EditUserId,
            {
                postal: Postal,
                address:Address
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
       Setshowsedit(false)
       GetMyexperts(activePage)

        }











    }

    const [EditFirstName, SetEditFirstName] = useState('')
    const [EditLastName, SetEditLastName] = useState('')
    const [EditEmailAddress, SetEditEmailAddress] = useState('')
    const [EditGenderValue, SetEditGenderValue] = useState('')
    const [EditClientTimezone, SetEditClientTimezone] = useState('')
    const [EditPassword, SetEditPassword] = useState('')
    const [EditUserId, SetEditUserId] = useState('')
    const [AllowPage, SetAllowPage] = useState(false)

    const modalOpen = async (e) => {
        SetMaleChecked(false)
        SetFemaleChecked(false)

        var UserId = e.target.getAttribute('data-item')
        SetEditUserId(UserId)

        SetFnameErr(false)
        SetLnameErr(false)
        SetEmailErr(false)


        const response = await axiosJWT.get("/user/" + UserId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response !== null) {
            if (response.data !== null) {
                SetEditFirstName(response.data.first_name)
                SetEditLastName(response.data.last_name)
                SetEditEmailAddress(response.data.email)
                SetInitialEmailAddress(response.data.email)
                setuser_timezone(response.data.user_timezone)
                SetAddress(response.data.user_address)
                SetPostal(response.data.postalCode)
                if(response.data.gender==='MALE')
                {
                    SetMaleChecked(true)
                    SetEditGenderValue(response.data.gender)
                }
                if (response.data.gender === 'FEMALE') {
                    SetFemaleChecked(true)
                    SetEditGenderValue(response.data.gender)
                }
            }
        }



        Setshowsedit(true)
    }

    const user_ban = async (e) => {



        try {
            var isBan = e.target.value;
            const userId = e.target.getAttribute("data-item");

            if (isBan === 'true')
                var updateBan = 0;
            else
                var updateBan = 1;

            const response = await axiosJWT.put('/user/' + userId,
                {
                    isBan: updateBan
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response) {
                GetMyexperts(activePage)
            }


        } catch (error) {
            console.log(error);
        }


    }

    const add_commission_expert = (e) => {

    }

    const changePageNumber = (pagenumber) => {
        GetMyexperts(pagenumber)


    }

    const GetMyexperts = async (pagenumber) => {
        SetactivePage(pagenumber)

        const response = await axiosJWT.get('/manager-access/get-my-team-experts?limit=' + countPerPage + '&offset=' + pagenumber + '', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (response.data !== null) {
            if (response.data.ListofExperts !== undefined) {
                Setusers(response.data.ListofExperts)
            }
        }

        SetusersCount(response.data.TotalTeamMembers)




    }
    const changeusertimzone = (e) => {
        setuser_timezone(e.value)


    }
    const Deleteopen = (e) => {

        var UserId = e.target.getAttribute('data-item')
        SetDeleteUserId(UserId)
        Setshowsd(true)


    }
    const DeleteUser = async () => {

        const response = await axiosJWT.delete('/user/' + DeleteUserId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response) {
            GetMyexperts(activePage)
            Setshowsd(false)
        }



    }

    const GetAccessDetails = async () => {

        const response = await axiosJWT.get('/managing-team/get-access-details', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data !== null) {
            var ManageUserresult = response.data.filter(obj => {
                return obj.page === 'manage_users'
            })

            if (ManageUserresult[0].write === true) {
                SetEditAccess(true)
            }
            if (ManageUserresult[0].delete === true) {
                SetDeleteAccess(true)
            }



            if (ManageUserresult[0].read === true) {
                SetAllowPage(true)
            }
            else {
                SetAccesspop(true)
                SetAllowPage(false)
            }
        }
        else {
            SetAccesspop(true)
            SetAllowPage(false)
        }


    }

    const [Accesspop, SetAccesspop] = useState(false)
    const handleClosesdAccess = () => {
        SetAccesspop(false)
    }


    useEffect(() => {
        GetMyexperts(1)
        settimezonesList(timezone_list);
        GetAccessDetails()
    }, [])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            {AllowPage === false ?

                <Modal show={Accesspop} onHide={handleClosesdAccess} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Access Denied</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You dont have permission to view this page</Modal.Body>
                    <Modal.Footer className="display_block">
                        <Button variant="secondary" onClick={handleClosesdAccess}>
                            Okay
                        </Button>

                    </Modal.Footer>
                </Modal>

                : null}
            {AllowPage === true ?
                <section className="dashboard">

                    <div className="container">

                        <div className="adminmanageusers">

                            <div className='menubox'>
                                <div className='tabmenu'>
                                    <a className="active" data-item="allcoaches">All Coaches</a>

                                </div>

                            </div>

                            <Table className="table is-striped is-fullwidth">
                                <Thead className="thead">
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Email</Th>
                                        <Th>Joined</Th>
                                        <Th>User Type</Th>
                                        <Th>Ban</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody className="tbody">
                                    {users.map((user, index) => (
                                        <Tr className='trow' key={user.id}>
                                            <Td><img className='table_profile_img' src={user.user.user_profile_photo ?  user.user.user_profile_photo : (user.user.gender === 'MALE' ? male_icon : female_icon)} />{user.user.first_name} {user.user.last_name}</Td>
                                            <Td className="color_grey"><i className="fa fa-envelope-o td_icon" aria-hidden="true"></i>{user.user.email}</Td>
                                            <Td className="color_grey"><i className="fa fa-calendar td_icon" aria-hidden="true"></i>{format(parseISO(user.user.createdAt), `PP`)}</Td>
                                            <Td><span className={user.user.role === 'Nutrition' ? 'orangebox' : 'purplebox'}>{user.user.role === 'Nutrition' ? 'Coach' : 'Client'}</span></Td>
                                            <Td>
                                                {EditAccess === true &&
                                                    <input type="checkbox" data-item={user.user.id} onChange={(e) => { user_ban(e) }} value={user.user.isBan} defaultChecked={user.user.isBan} />
                                                }
                                            </Td>
                                            <Td>
                                                {EditAccess === true &&
                                                    <i className="fa fa-edit td_icon action" data-modal="user_edit" data-item={user.user.id} onClick={(e) => { modalOpen(e) }}></i>}
                                                {DeleteAccess === true &&
                                                    <i className="fa fa-trash td_icon action" aria-hidden="true" data-modal="delete_confirm" data-item={user.user.id} onClick={(e) => { Deleteopen(e) }}></i>}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            {usersCount ?
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={countPerPage}
                                    totalItemsCount={usersCount}
                                    onChange={changePageNumber.bind(this)}
                                    prevPageText="<"
                                    nextPageText=">"
                                    hideFirstLastPages={true}
                                    linkClassPrev="pagination_prev"
                                    linkClassNext="pagination_next"
                                    linkClass="pagenumbers color_grey"
                                />
                                : null}
                        </div>

                    </div>

                    <Modal show={showsedit} onHide={handleClosesedit} className="manager_edit_coach confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Update User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                            <div className="row">
                                <div><label className='commission_inner_popup_label required'>First Name</label></div>
                                <div><input type="text" className="add_category_inner_input" defaultValue={EditFirstName} onChange={(e) => { SetEditFirstName(e.target.value) }}  {...(FnameErr===true ? {id: 'err_border'} : {})}></input></div>
                            </div>
                            {FnameErr === true &&
                                <div className='row err promotion_err_name'>
                                    {FnameErrMsg}
                                </div>
                            }
                            <div className="row">
                            <div><label className='commission_inner_popup_label required'>Last Name</label></div>
                            <div><input type="text" className="add_category_inner_input" defaultValue={EditLastName} onChange={(e) => { SetEditLastName(e.target.value) }} {...(LnameErr===true ? {id: 'err_border'} : {})}></input></div>
                            </div>
                            {LnameErr === true &&
                                <div className='row err promotion_err_name'>
                                    {LnameErrMsg}
                                </div>
                            }
                            <div className="row">
                            <div><label className='commission_inner_popup_label required'>Email address</label></div>
                            <div><input type="text" className="add_category_inner_input" defaultValue={EditEmailAddress} onChange={(e) => { SetEditEmailAddress(e.target.value) }} {...(EmailErr===true ? {id: 'err_border'} : {})}></input></div>
                            </div>
                            {EmailErr === true &&
                                <div className='row err promotion_err_name'>
                                    {EmailErrMsg}
                                </div>
                            }
                            <div className="row">
                            <div><label className='commission_inner_popup_label'>Password</label></div>
                            <div><input type="text" className="add_category_inner_input" onChange={(e) => { SetEditPassword(e.target.value) }}></input></div>
                            </div>
                            <div className="row radio_row_gender_manager">
                            <div><label className='commission_inner_popup_label required'>Gender</label></div>
                            <div><input type="radio" className="radio_edit_user_manager" value="MALE" name="gender" defaultChecked={MaleChecked} onChange={(e) => { SetEditGenderValue(e.target.value) }} />
                                <span className="radio_edit_user_manager_span">Male</span>
                                <input type="radio" className="radio_edit_user_manager" style={{ marginLeft: '0px' }} value="FEMALE" name="gender" defaultChecked={FemaleChecked} onChange={(e) => { SetEditGenderValue(e.target.value) }} />
                                <span className="radio_edit_user_manager_span">Female</span></div>

                            </div>

                            <div className="row">
                 <label className='commission_inner_popup_label'>Address</label><br />
                 <input type="text" className="add_category_inner_input edit_address_manager" defaultValue={Address}  onChange={(e)=>{SetAddress(e.target.value)}}></input>
                 </div>

                 <div className="row">
                 <label className='commission_inner_popup_label'>Postal Code</label><br />
                 <input type="text" className="add_category_inner_input edit_address_manager"  onChange={(e)=>{SetPostal(e.target.value)}} defaultValue={Postal}></input>
                 </div>

                            <div className="row">
                                <label className='commission_inner_popup_label required'>Timezone:</label>
                                <br />
                                <Select
                                    defaultValue={{ value: user_edit_timezone, label: user_edit_timezone }}
                                    options={timezonesList}
                                    onChange={(e) => { changeusertimzone(e) }}
                                    className="clientadd_timezone"
                                />


                            </div>


                        </Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleClosesedit}>
                                Cancel
                            </Button>
                            <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { UpdateUser() }}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showsd} onHide={handleClosesd} className="confirm_delete">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete this User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure want to delete this user account?</Modal.Body>
                        <Modal.Footer className="display_block">
                            <Button variant="secondary" onClick={handleClosesd}>
                                Cancel
                            </Button>
                            <Button variant="primary" className="bootstrp_color_btn float_right" onClick={(e) => { DeleteUser() }}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </section>
                : null}
        </div>
    )
}

export default ManageUsersByManager
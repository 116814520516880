import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Select from 'react-select';
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import Dropdown from 'react-multilevel-dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { format } from 'date-fns';

const LabTest = (props) => {

    const client_id = props.data;

    var componentRef = useRef();

    const e = document.getElementById('root');
    e.addEventListener('click', function handleClick(event) {
        if (event.target.classList[0] !== 'exportbtn') {
            if (enableExport === true)
                setenableExport(false);
        }
    });

    const side_nav = localStorage.getItem("side_nav");
    const token = localStorage.getItem("token");
    const user_timezone = localStorage.getItem("user_timezone");

    const axiosJWT = axios.create();
    const navigate = useNavigate();

    const [show, setshow] = useState(false);
    const hide = () => setshow(false);

    const [editshow, seteditshow] = useState(false);
    const edithide = () => seteditshow(false);

    const [labtestLibrary, setlabtestLibrary] = useState([]);
    var [newtestList, setnewtestList] = useState([]);
    var [addedtestList, setaddedtestList] = useState([]);
    var [inputsList, setinputsList] = useState([]);
    var [selectedallList, setselectedallList] = useState([]);

    const [category_options, setcategory_options] = useState([]);
    const [subcategory_options, setsubcategory_options] = useState([]);
    const [table_options, settable_options] = useState([]);
    const [groupedData, setgroupedData] = useState([]);

    const [testcategory_options, settestcategory_options] = useState([]);

    const [fastingoptions, setfastingoptions] = useState([]);
    const [fasting, setfasting] = useState('');
    const [testDate, settestDate] = useState(new Date());
    const [testTime, settestTime] = useState('');
    const [testLocation, settestLocation] = useState('');
    const [userData, setuserData] = useState([]);
    const [userDataOptions, setuserDataOptions] = useState([]);
    const [selectedData, setselectedData] = useState('');

    const [dataCategory, setdataCategory] = useState('');
    const [datasubCategory, setdatasubCategory] = useState('');
    const [alldateColm, setalldateColm] = useState('');
    const [dateColm, setdateColm] = useState('');
    const [dataColm, setdataColm] = useState('');
    const [dateList, setdateList] = useState('');
    const [headerColm, setheaderColm] = useState('');
    const [selectedCategory, setselectedCategory] = useState('');
    const [selectedsubCategory, setselectedsubCategory] = useState('');
    const [Error, setError] = useState(false);
    const [ErrorMsg, setErrorMsg] = useState('');
    const [fastingError, setfastingError] = useState(false);
    const [locationError, setlocationError] = useState(false);
    const [addtestError, setaddtestError] = useState(false);


    const [showDelete, setshowDelete] = useState(false);
    const closeDelete = () => setshowDelete(false);
    const [deletetestInfo, setdeletetestInfo] = useState('');
    const [dataTestId, setdataTestId] = useState('');

    const [edittestId, setedittestId] = useState('');
    const [edittestDate, setedittestDate] = useState('');
    const [edittestTime, setedittestTime] = useState('');
    const [edittestFasting, setedittestFasting] = useState('');
    const [edittestLocation, setedittestLocation] = useState('');
    const [editvalueList, seteditvalueList] = useState([]);
    const [editinputsList, seteditinputsList] = useState([]);

    const [enableExport, setenableExport] = useState(false);
    var [exportDate, setexportDate] = useState([]);
    const [exportCSV, setexportCSV] = useState([]);
    const [exportCSVFilename, setexportCSVFilename] = useState('');

    const [userInfo, setuserInfo] = useState('');
    const [testSearch, settestSearch] = useState('');

    useEffect(() => {
        load_labtest();
    }, []);


    const load_labtest = async () => {

        var fasting_options = [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }, { label: 'Unknown', value: 'unknown' }];
        setfastingoptions(fasting_options);

        var user_details = await axiosJWT.get("/user/" + client_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        setuserInfo(user_details.data);

        var lab_library = await axiosJWT.get("/labtest/library",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        console.log("mi: lab test")
        console.log(lab_library)

        setlabtestLibrary(lab_library.data.lab_library);

        const lab_test_library = lab_library.data.lab_library;

        const test_category_options = [];
        test_category_options.push({ label: 'All', value: 0 });
        lab_test_library.map(data => {
            if (!test_category_options.find((x) => x.value === data.cat_id))
                test_category_options.push({ label: data.labtest_cat.cat_label, value: data.cat_id });
        });        

        settestcategory_options(test_category_options);

        var lab_data = await axiosJWT.get("/labtest/data/" + client_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        // console.log("mi1");
        // console.log(lab_data);

        var inputs_list = [];

        var lab_data_array = lab_data.data;

        var data_category = [];
        var all_date = [];
        var date_colm = {};
        var data_colm = {};
        var header_colm = {};
        lab_data_array.forEach(element => {
            var test_inputs = JSON.parse(element.test_inputs);

            test_inputs.forEach(inputs_element => {
                var temp_data = lab_test_library.find((x) => x.id === parseInt(inputs_element.id));

                inputs_list.push(temp_data);

                if (!data_category.find(x => x === temp_data.cat_id))
                    data_category.push(temp_data.cat_id);

                if (date_colm[temp_data.cat_id] === undefined)
                    date_colm[temp_data.cat_id] = [];


                var convert_unix = moment(element.test_date_time).unix();
                date_colm[temp_data.cat_id].push(convert_unix);

                if (!all_date.find(x => x === convert_unix))
                    all_date.push(convert_unix);

                if (data_colm[temp_data.id] === undefined)
                    data_colm[temp_data.id] = {};

                if (data_colm[temp_data.id][convert_unix] === undefined)
                    data_colm[temp_data.id][convert_unix] = [];

                data_colm[temp_data.id][convert_unix].push(inputs_element.value);

                if (header_colm['fast'] === undefined)
                    header_colm['fast'] = {};

                if (header_colm['location'] === undefined)
                    header_colm['location'] = {};

                if (header_colm['id'] === undefined)
                    header_colm['id'] = {};

                header_colm['fast'][convert_unix] = element.have_fast;
                header_colm['location'][convert_unix] = element.location;
                header_colm['id'][convert_unix] = element.id;
            });
        });



        // console.log("mi3");
        // console.log(inputs_list)
        // console.log(date_colm)
        // console.log(data_colm)
        // console.log(header_colm)

        if (inputs_list.length > 0) {
            var test_output_grouped = {};
            var user_data_subcat = {};

            inputs_list.forEach(element => {
                if (test_output_grouped[element.sub_cat_id] === undefined)
                    test_output_grouped[element.sub_cat_id] = [];


                if (!test_output_grouped[element.sub_cat_id].find(data => data.id === element.id))
                    test_output_grouped[element.sub_cat_id].push(element);

                if (user_data_subcat[element.cat_id] === undefined)
                    user_data_subcat[element.cat_id] = [];

                if (!user_data_subcat[element.cat_id].find(x => x === element.sub_cat_id))
                    user_data_subcat[element.cat_id].push(element.sub_cat_id);
            });

            // console.log("mi4");
            // console.log(test_output_grouped);
            // console.log(user_data_subcat);

            setuserData(test_output_grouped);
            setdataCategory(data_category);
            setdatasubCategory(user_data_subcat);
            setalldateColm(all_date);
            setdateColm(date_colm);
            setdataColm(data_colm);
            setheaderColm(header_colm);
            setdateList(all_date);
            setselectedCategory(data_category);
            setselectedsubCategory(user_data_subcat);
        }
    }

    const load_user_data = async (cat_id) => {
        if (cat_id === 0) {
            //   console.log(alldateColm);

            setdateList(alldateColm);
            setselectedCategory(dataCategory);
            setselectedsubCategory(datasubCategory);
        }
        else {
            var date_list = [];
            (dateColm[cat_id]).map((unixdate) => (
                date_list.push(unixdate)
            ));

            setdateList(date_list);

            var cat_list = [];
            cat_list.push(cat_id);
            setselectedCategory(cat_list);

            // var sub_cat_list = [];
            // (datasubCategory[cat_id]).map((sub_cat_id)=>(
            // sub_cat_list.push(sub_cat_id)
            // ));
            // setselectedsubCategory(sub_cat_list);
        }
    }

    const addBiomarkers = async () => {

        var test_data = [];
        const select_added_test = document.querySelectorAll(".add_new_tests_inputs div input");
        select_added_test.forEach((element) => {
            // element.setAttribute("data-checked","true")
            var test_id = element.getAttribute("data-id");
            var test_value = element.value;
            // console.log(test_id+" "+test_value);

            test_data.push({ id: test_id, value: test_value });
        });

        // console.log(testDate+" "+testTime+" "+fasting.value+" "+testLocation);
        // console.log(test_data)

     
        var IntestDate=moment(testDate).format('YYYY-MM-DD')
        var test_date_time_convert = moment(IntestDate + "  " + testTime).format('YYYY-MM-DD HH:mm:ss');
        var test_date_time = moment.tz(test_date_time_convert, 'YYYY-MM-DD HH:mm:ss', user_timezone);
      
     
        var test_date_time_utc = test_date_time.utc().format('YYYY-MM-DD HH:mm:ss');

        if (test_date_time_utc !== '' && fasting.value !== '' && testLocation !== '' && test_data !== '') {
            try {
                const response = await axiosJWT.post("/labtest/data",
                    {
                        client_id: client_id,
                        date_time: test_date_time_utc,
                        fasting: fasting.value,
                        location: testLocation,
                        inputs: test_data
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response.status === 200) {
                    setshow(false);
                    load_labtest();
                    setnewtestList('');
                    setError(false);
                    // console.log(response);
                }
            }
            catch (error) {
                setError(true);
                setErrorMsg(error.response.data.message);
            }
        }
        else {
            if (!fasting)
                setfastingError(true);

            if (testLocation === '')
                setlocationError(true);

            if (test_data.length === 0)
                setaddtestError(true);

            setError(true);
            setErrorMsg("*All fields are required.!");
        }
    }

    const addNewTest = async (e) => {
        var test_id = parseInt(e.target.getAttribute("data-id"));
        var test_checked = e.target.getAttribute("data-checked");
        var test_category = parseInt(e.target.getAttribute("data-category"));
        var test_subcategory = parseInt(e.target.getAttribute("data-subcategory"));
        //var test_table = e.target.getAttribute("data-subcategory");
        var test_field = e.target.getAttribute("data-item");

        // console.log(test_checked+" "+test_category+" "+test_subcategory+" "+test_field)

        if (test_checked === 'false') {
            e.target.setAttribute("data-checked", "true");
            e.target.setAttribute("checked", "true");
            //e.target.parentNode.classList.add('active');
            //e.target.setAttribute("disabled","true"); 

            if (test_field === 'select_all') {
                const select_all_test = document.querySelectorAll(".sc_" + test_subcategory + " li input");
                select_all_test.forEach((element) => element.setAttribute("data-checked", "true"));
                select_all_test.forEach((element) => element.setAttribute("checked", "true"));
                //select_all_test.forEach((element) => element.setAttribute("disabled","true"));

                var test_list = labtestLibrary.filter(data => data.sub_cat_id === test_subcategory);
                var temp_list = [];

                if (newtestList && newtestList.length > 0) {
                    newtestList.forEach(element => {
                        //temp_list.push({id: element.id});
                        temp_list.push(element);
                    });
                }

                test_list.forEach(element => {
                    // var data = {
                    //     id: element.id,
                    //     category: element.category_value,
                    //     table: element.tablename_value,
                    //     field: element.testname_value
                    //    };

                    if (!temp_list.find(item => item.id === element.id))
                        temp_list.push(element);
                });

                setselectedallList([...selectedallList, test_subcategory]);

                setnewtestList(temp_list);

                // var x = document.getElementsByClassName("sc_"+test_subcategory+"_checkbox");
                // for(var i=0; i<=x.length; i++) {
                // x[i].checked = true;
                // }   
            }
            else {
                var data = labtestLibrary.find((x) => x.id === test_id);
                // var data = {
                //     id: test_id,
                //     category: test_category,
                //     table: test_table,
                //     field: test_field
                // };

                setnewtestList([...newtestList, data]);

                var added_test_count = newtestList.filter(tests => tests.sub_cat_id === test_subcategory);
                var test_libary_count = labtestLibrary.filter(data => data.sub_cat_id === test_subcategory);

                if (added_test_count.length === test_libary_count.length)
                    setselectedallList([...selectedallList, test_subcategory]);
            }
        }
        if (test_checked === 'true') {
            e.target.setAttribute("data-checked", "false");
            e.target.setAttribute("checked", "false");
            //e.target.parentNode.classList.remove('active');
            //e.target.setAttribute("disabled","false");

            if (test_field === 'select_all') {
                const select_all_test = document.querySelectorAll(".sc_" + test_subcategory + " li input");
                select_all_test.forEach((element) => element.setAttribute("data-checked", "false"));
                select_all_test.forEach((element) => element.setAttribute("checked", "false"));
                //select_all_test.forEach((element) => element.setAttribute("disabled","false"));


                //var test_list = labtestLibrary.filter(data=>data.testname_value === test_table);

                var temp_list = [];

                newtestList.forEach(element => {
                    if (element.sub_cat_id !== test_subcategory) {
                        temp_list.push(element);
                    }
                });

                // newtestList.forEach(element => {
                //     if(!test_list.includes(element.id))
                //     temp_list.push({id: element.id});
                // });               

                setnewtestList(temp_list);

                // console.log(temp_list.length)

                var newselected_all_list = selectedallList.filter(tests => tests !== test_subcategory);
                setselectedallList(newselected_all_list);

                // var x = document.getElementsByClassName("sc_"+test_subcategory+"_checkbox");
                // for(var i=0; i<=x.length; i++) {
                // x[i].checked = false;
                // }  
            }
            else {
                var temp_list = newtestList.filter(tests => tests.id !== test_id);
                setnewtestList(temp_list);

                var newselected_all_list = selectedallList.filter(tests => tests !== test_subcategory);
                setselectedallList(newselected_all_list);
            }
        }

        // console.log(newtestList.length);                
    }

    const deleteTest = async (e) => {
        var test_id = parseInt(e.target.getAttribute("data-id"));

        var option_find = newtestList.find(x => x.id === test_id);
        var newselected_all_list = selectedallList.filter(tests => tests !== option_find.testname_value);
        setselectedallList(newselected_all_list);

        var temp_list = newtestList.filter(tests => tests.id !== test_id);
        setnewtestList(temp_list);
    }

    const openBioMarkers = () => {

        load_select_list();

        // var current_date = moment().clone().tz(user_timezone).format('MM/DD/YYYY');
        // settestDate(current_date);
        var current_time = moment().clone().tz(user_timezone).format('h:mm A');
        settestTime(current_time);

        setshow(true);
    }

    const load_select_list = async () => {

        const uniqueCategories = [];
        labtestLibrary.map(data => {
            if (!uniqueCategories.find((x) => x.value === data.cat_id))
                uniqueCategories.push({ label: data.labtest_cat.cat_label, value: data.cat_id, is_option: data.labtest_cat.is_option });
        });

        const uniquesubCategories = {};
        labtestLibrary.map(data => {
            if (uniquesubCategories[data.cat_id] === undefined)
                uniquesubCategories[data.cat_id] = [];

            if (!uniquesubCategories[data.cat_id].find((x) => x.value === data.sub_cat_id))
                uniquesubCategories[data.cat_id].push({ label: data.labtest_sub_cat.sub_cat_label, value: data.sub_cat_id });
        });

        // console.log(uniquesubCategories);

        const uniqueTables = [];
        labtestLibrary.map(data => {
            if (!uniqueTables.find((x) => x.value === data.testname_value))
                uniqueTables.push({ label: data.testname_label, value: data.testname_value });
        });

        // console.log(uniqueTables);

        setcategory_options(uniqueCategories);
        setsubcategory_options(uniquesubCategories);
        settable_options(uniqueTables);

        var grouped = {}; // final object 
        for (var i = 0, len = labtestLibrary.length, p; i < len; i++) { // faster than .forEach
            p = labtestLibrary[i];

            if (grouped[p.cat_id] === undefined) // twice faster then hasOwnProperty
                grouped[p.cat_id] = {};

            if (grouped[p.cat_id][p.sub_cat_id] === undefined)
                grouped[p.cat_id][p.sub_cat_id] = [];

            grouped[p.cat_id][p.sub_cat_id].push(p); // your groupby is HERE xD
        }

        setgroupedData(grouped);
    }

    const deleteTestData = async (e) => {
        var data_id = e.target.getAttribute("data-id");

        var response = await axiosJWT.delete("/labtest/data/" + data_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            setshowDelete(false);
            load_labtest();
            // console.log(response)
        }

        // console.log(data_id)
    }

    function unix_to_usertime(unixtime, format) {

        if (format === 'date') {
            var unix_to_date = moment.unix(unixtime).format('YYYY-MM-DD HH:mm:ss');
            var unix_to_utc_date = moment.utc(unix_to_date, 'YYYY-MM-DD HH:mm:ss');
            var date_val = unix_to_utc_date.clone().tz(user_timezone).format('MMM Do, YYYY');

            return date_val;
        }
        else if (format === 'editdate') {
            var unix_to_date = moment.unix(unixtime).format('YYYY-MM-DD HH:mm:ss');
            var unix_to_utc_date = moment.utc(unix_to_date, 'YYYY-MM-DD HH:mm:ss');
            var date_val = unix_to_utc_date.clone().tz(user_timezone).format('MM/DD/YYYY');

            // Parse the date string using Moment.js
var dateObject = moment(date_val, "MM/DD/YYYY");

var jsDateObject = dateObject.toDate();


          
            return jsDateObject;
        }
        else {
            var unix_to_date = moment.unix(unixtime).format('YYYY-MM-DD HH:mm:ss');
            var unix_to_utc_date = moment.utc(unix_to_date, 'YYYY-MM-DD HH:mm:ss');
            var time_val = unix_to_utc_date.clone().tz(user_timezone).format('h:mm A');

            return time_val;
        }
    }

    const setfastVal = (e) => {
        //console.log(e);
        setfasting(e);
        setfastingError(false);
    }

    const deleteDataAsk = async (e) => {
        var data_id = e.target.getAttribute("data-id");
        var data_time = e.target.getAttribute("data-time");

        setdeletetestInfo(unix_to_usertime(data_time, 'date') + " " + unix_to_usertime(data_time, 'time'));
        setdataTestId(data_id);

        setshowDelete(true);
        // console.log(unixtime)
    }

    const editTestOpen = async (e) => {
        var data_id = e.target.getAttribute("data-id");
        var data_time = e.target.getAttribute("data-time");

        var response = await axiosJWT.get("/labtest/testdata/" + data_id,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        if (response) {
            var test_data = response.data;

            // console.log(unix_to_usertime(data_time,'editdate')+" "+unix_to_usertime(data_time,'time'));

            setedittestId(data_id);
            setedittestDate(unix_to_usertime(data_time, 'editdate'));
            setedittestTime(unix_to_usertime(data_time, 'time'));

            var test_fast = fastingoptions.find((data) => data.value === test_data.have_fast);

            setedittestFasting(test_fast);
            setedittestLocation(test_data.location);

            var inputs_list = [];

            var test_inputs = JSON.parse(test_data.test_inputs);

            test_inputs.forEach(inputs_element => {
                var temp_data = labtestLibrary.find((x) => x.id === parseInt(inputs_element.id));
                inputs_list.push(temp_data);
            });

            load_select_list();
            seteditvalueList(test_inputs);
            //seteditinputsList(inputs_list);    

            setnewtestList(inputs_list);
            seteditshow(true);
        }

        // console.log(test_inputs)
        // console.log(inputs_list)
    }

    const editTestData = async (e) => {

        var test_data_id = e.target.getAttribute("data-item");

        // console.log(test_data_id);

        var test_data = [];
        const select_added_test = document.querySelectorAll(".add_new_tests_inputs div input");
        select_added_test.forEach((element) => {
            // element.setAttribute("data-checked","true")
            var test_id = element.getAttribute("data-id");
            var test_value = element.value;
            // console.log(test_id+" "+test_value);

            test_data.push({ id: test_id, value: test_value });
        });

        // console.log(edittestDate+" "+edittestTime+" "+edittestFasting.value+" "+edittestLocation);
        // console.log(test_data)

        var IntestDate=moment(edittestDate).format('YYYY-MM-DD')
   

        var test_date_time_convert = moment(IntestDate + "  " + edittestTime).format('YYYY-MM-DD HH:mm:ss');
        var test_date_time = moment.tz(test_date_time_convert, 'YYYY-MM-DD HH:mm:ss', user_timezone);
        var test_date_time_utc = test_date_time.utc().format('YYYY-MM-DD HH:mm:ss');

        if (test_date_time_utc !== '' && edittestFasting.value !== '' && edittestLocation !== '' && test_data !== '') {
            try {
                const response = await axiosJWT.put("/labtest/data/" + test_data_id,
                    {
                        date_time: test_date_time_utc,
                        fasting: edittestFasting.value,
                        location: edittestLocation,
                        inputs: test_data
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                if (response.status === 200) {
                    seteditshow(false);
                    load_labtest();
                    setnewtestList('');
                    setError(false);
                    // console.log(response);
                }
            }
            catch (error) {
                setError(true);
                setErrorMsg(error.response.data.message);
            }
        }
        else {
            if (!edittestFasting)
                setfastingError(true);

            if (edittestLocation === '')
                setlocationError(true);

            if (test_data.length === 0)
                setaddtestError(true);

            setError(true);
            setErrorMsg("*All fields are required.!");
        }
    }

    function geteditinputValue(id) {
        var data = editvalueList.find((data) => parseInt(data.id) === id);

        if (data)
            return data.value;
        else
            return null;

        //console.log(data);
    }

    const exportData = () => {

        var exports_data = [];

        var header_date = [];
        var draw_time = [];
        var did_fast = [];
        var location = [];

        header_date.push("My Biomarkers", "Low Risk", "Moderate Risk", "High Risk");
        draw_time.push("Draw time", "-", "-", "-");
        did_fast.push("Did you fast for this draw?", "-", "-", "-");
        location.push("Location", "-", "-", "-");

        exportDate.forEach(element => {
            header_date.push(unix_to_usertime(element, 'editdate'));
            draw_time.push(unix_to_usertime(element, 'time'));
            did_fast.push(headerColm['fast'][element]);
            location.push(headerColm['location'][element]);
        });

        exports_data.push(header_date);
        exports_data.push(draw_time);
        exports_data.push(did_fast);
        exports_data.push(location);

        selectedCategory.forEach(cat_id => {
            selectedsubCategory[cat_id].forEach(sub_cat_id => {
                (userData[sub_cat_id]).forEach((data, data_index) => {
                    var temp_data = [];

                    if (data_index === 0)
                        exports_data.push([data.labtest_sub_cat.sub_cat_label]);
                    temp_data.push(data.testname_label + "(" + data.units + ")", data.low, data.moderate, data.high);

                    exportDate.forEach(element => {
                        temp_data.push(dataColm[data.id][element]);
                    });

                    exports_data.push(temp_data);
                });
            });
        });


        setexportCSV(exports_data);
        setexportCSVFilename(userInfo.first_name + "_" + userInfo.last_name + "_lab_test_" + "_" + format(new Date(), 'yyyy_MM_dd_t'));
    }

    const exportEnable = () => {
        setenableExport(!enableExport);
    }

    // const e = document.getElementById('labtest_tab');
    // e.addEventListener('click', function handleClick(event) {
    //     setenableExport(false);
    // });

    const adddateExport = (e) => {
        var date_val = e.target.getAttribute("data-val");
        var check_val = e.target.checked;

        if (check_val === true)
            setexportDate([...exportDate, date_val]);
        if (check_val === false) {
            var removed_exportDate = exportDate.filter((item) => item !== date_val);
            setexportDate(removed_exportDate);
        }
    }

    const searchTests = () => {
        console.log(testSearch)

        const uniqueLibrary = [];
        labtestLibrary.map(data => {
           if(data.testname_value.toLowerCase().includes(testSearch.toLowerCase()))
           uniqueLibrary.push(data);
        });

        console.log("mi t");
        console.log(uniqueLibrary);

        const uniqueCategories = [];
        uniqueLibrary.map(data => {
            if (!uniqueCategories.find((x) => x.value === data.cat_id))
                uniqueCategories.push({ label: data.labtest_cat.cat_label, value: data.cat_id, is_option: data.labtest_cat.is_option });
        });

        const uniquesubCategories = {};
        uniqueLibrary.map(data => {
            if (uniquesubCategories[data.cat_id] === undefined)
                uniquesubCategories[data.cat_id] = [];

            if (!uniquesubCategories[data.cat_id].find((x) => x.value === data.sub_cat_id))
                uniquesubCategories[data.cat_id].push({ label: data.labtest_sub_cat.sub_cat_label, value: data.sub_cat_id });
        });

        // console.log(uniquesubCategories);

        const uniqueTables = [];
        uniqueLibrary.map(data => {
            if (!uniqueTables.find((x) => x.value === data.testname_value))
                uniqueTables.push({ label: data.testname_label, value: data.testname_value });
        });

        console.log(uniqueTables);

        setcategory_options(uniqueCategories);
        setsubcategory_options(uniquesubCategories);
        settable_options(uniqueTables);

        var grouped = {}; // final object 
        for (var i = 0, len = uniqueLibrary.length, p; i < len; i++) { // faster than .forEach
            p = uniqueLibrary[i];

            if (grouped[p.cat_id] === undefined) // twice faster then hasOwnProperty
                grouped[p.cat_id] = {};

            if (grouped[p.cat_id][p.sub_cat_id] === undefined)
                grouped[p.cat_id][p.sub_cat_id] = [];

            grouped[p.cat_id][p.sub_cat_id].push(p); // your groupby is HERE xD
        }

        setgroupedData(grouped);
    }

    const loadnewaddtest = async () => {
        load_select_list();
        setaddtestError(false);
    }
 
    return (
        <div id='labtest_tab' className='labtest_tab'>
            <div className='title_block'>
                <div className='title_left'>
                    <div className="title">
                        <h3>Lab Test</h3>
                    </div>
                    <div className="testselect">
                        <Select
                            options={testcategory_options}
                            onChange={(e) => load_user_data(e.value)}
                        />
                    </div>
                </div>
                <div className='title_right'>
                    <div className='action_buttons'>
                        <Button className='green_button btn' onClick={openBioMarkers}>Add BioMarkers</Button>
                        <Button className='exportbtn green_button btn export' onClick={exportEnable} disabled={exportDate.length === 0 ? true : false}>Export</Button>
                        <ul className={enableExport === true ? "export_enable" : "export_disable"}>
                            <CSVLink data={exportCSV} filename={exportCSVFilename}><li><a onClick={exportData}>CSV File</a></li></CSVLink>
                            <ReactToPrint
                                trigger={() => <li><a >PDF File</a></li>}
                                content={() => componentRef.current}
                            />
                        </ul>
                    </div>
                </div>
            </div>

            {exportDate && exportDate.length > 0 ?
                <div style={{ display: "none" }}>
                    <table className="table" ref={componentRef} >
                        <thead className="thead">
                            <tr className="hdr">
                                <th>My Biomarkers</th>
                                <th>Low Risk</th>
                                <th>Moderate Risk</th>
                                <th>High Risk</th>
                                {
                                    exportDate.map((element, d_index) => (
                                        <th className="date_section" key={d_index}>
                                            {unix_to_usertime(element, 'date')}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="tbody">
                            <tr className="hdr">
                                <td className="test_title">Draw time</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                {
                                    exportDate.map((element, d_index) => (
                                        <td className="date_section" key={d_index}>
                                            {unix_to_usertime(element, 'time')}
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr className="hdr">
                                <td className="test_title">Did you fast for this draw?</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                {
                                    exportDate.map((element, d_index) => (
                                        <td key={d_index}>
                                            {headerColm && headerColm['fast'][element]}
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr className="hdr">
                                <td className="test_title">Location</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                {
                                    exportDate.map((element, d_index) => (
                                        <td key={d_index}>
                                            {headerColm && headerColm['location'][element]}
                                        </td>
                                    ))
                                }
                            </tr>
                            {selectedCategory.length > 0 ?
                                selectedCategory.map((cat_id) => (
                                    (selectedsubCategory[cat_id]).map((sub_cat_id) => (
                                        (userData[sub_cat_id]).map((data, data_index) => (
                                            <>
                                                {data_index === 0 ? <tr>{data.labtest_sub_cat.sub_cat_label}</tr> : null}
                                                <tr key={data_index}>
                                                    <td>
                                                        {data.testname_label}{"(" + data.units + ")"}
                                                    </td>
                                                    <td>{data.low}</td>
                                                    <td>{data.moderate}</td>
                                                    <td>{data.high}</td>
                                                    {
                                                        exportDate.map((unixdate, d_index) => (
                                                            <td key={d_index}>
                                                                {dataColm && dataColm[data.id][unixdate]}
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            </>
                                        ))
                                    ))
                                )) : null
                            }
                        </tbody>
                    </table>
                </div>
                : null
            }

            {dateList && dateList.length > 0 ?
                <div className="labtest_data_table">
                    <table className="table">
                        <thead className="thead">
                            <tr className="hdr">
                                <th className="test_title"> <h5>My Biomarkers</h5></th>
                            </tr>
                        </thead>
                        <tbody className="tbody">
                            <tr className="hdr">
                                <td className="test_title">Draw time</td>
                            </tr>
                            <tr className="hdr">
                                <td className="test_title">Did you fast for this draw?</td>
                            </tr>
                            <tr className="hdr">
                                <td className="test_title">Location</td>
                            </tr>
                            {/* </tbody>
                </table>  */}
                            {selectedCategory.length > 0 ?
                                selectedCategory.map((cat_id) => (
                                    (selectedsubCategory[cat_id]).map((sub_cat_id) => (
                                        (userData[sub_cat_id]).map((data, data_index) => (
                                            <>
                                                {/* {data_index === 0 ? <tr className="data"><h5>{data.labtest_sub_cat.sub_cat_label}</h5></tr> : null } */}
                                                {data_index === 0 ? <tr className="data"><h5>{data.labtest_sub_cat.sub_cat_label}</h5></tr> : null}
                                                <tr key={data_index} className="data">
                                                    <td className="test_title">
                                                        <InputGroup className="unit_label"><label>{data.testname_label}</label><InputGroup.Text>{data.units}</InputGroup.Text></InputGroup>
                                                        {/* <div><label>{data.testname_label}</label> {data.units}</div> */}
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    ))
                                )) : null
                            }
                        </tbody>
                    </table>
                    <div className="scroll">
                        <table className="table">
                            <thead className="thead">
                                <tr className="hdr">
                                    <th>Low Risk</th>
                                    <th>Moderate Risk</th>
                                    <th>High Risk</th>
                                    {
                                        dateList.map((element, d_index) => (
                                            <th className="date_section" key={d_index}>
                                                {unix_to_usertime(element, 'date')}<br />
                                                <input className="export_checkbox" type="checkbox" data-val={element} onClick={adddateExport} />
                                                <i className="fa fa-edit td_icon action" data-id={headerColm['id'][element]} data-time={element} onClick={editTestOpen} alt="edit"></i>
                                                <i className="fa fa-trash td_icon action" data-id={headerColm['id'][element]} data-time={element} onClick={(e) => deleteDataAsk(e)} alt="delete"></i>
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                <tr className="hdr">
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    {
                                        dateList.map((element, d_index) => (
                                            <td className="date_section" key={d_index}>
                                                {unix_to_usertime(element, 'time')}<br />
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr className="hdr">
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    {
                                        dateList.map((element, d_index) => (
                                            <td key={d_index}>
                                                {headerColm && headerColm['fast'][element]}
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr className="hdr">
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    <td><div className="hidetext">.</div></td>
                                    {
                                        dateList.map((element, d_index) => (
                                            <td key={d_index}>
                                                {headerColm && headerColm['location'][element]}
                                            </td>
                                        ))
                                    }
                                </tr>
                                {selectedCategory.length > 0 ?
                                    selectedCategory.map((cat_id) => (
                                        (selectedsubCategory[cat_id]).map((sub_cat_id) => (
                                            (userData[sub_cat_id]).map((data, data_index) => (
                                                <>
                                                    {data_index === 0 ? <tr className="empty_data"></tr> : null}
                                                    <tr key={data_index} className="data">
                                                        <td>{data.low}</td>
                                                        <td>{data.moderate}</td>
                                                        <td>{data.high}</td>
                                                        {
                                                            dateList.map((unixdate, d_index) => (
                                                                <td key={d_index}>
                                                                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>{dataColm && dataColm[data.id][unixdate]}</div>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                </>
                                            ))
                                        ))
                                    )) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="labtest_sample_table">

                    <Table className="table">
                        <Thead className="thead">
                            <Tr>
                                <Th><h5>My Biomarkers</h5></Th>
                                <Th>Low Risk</Th>
                                <Th>Moderate Risk</Th>
                                <Th>High Risk</Th>
                                <Th>
                                    {moment().clone().tz(user_timezone).format('MMM Do, YYYY')}
                                    <i className="fa fa-edit td_icon action" onClick={() => setshow(true)}></i>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">
                            <Tr>
                                <Td>Draw time</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>{moment().clone().tz(user_timezone).format('h:mm A')}</Td>
                            </Tr>
                            <Tr>
                                <Td>Did you fast for this draw?</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>Yes</Td>
                            </Tr>
                            <Tr>
                                <Td>Location</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <h5>Vital Signs and Body Measurements</h5>
                    <Table className="table is-striped is-fullwidth">
                        <Thead className="thead">
                            <Tr>
                                <Th>.</Th>
                                <Th>Low Risk</Th>
                                <Th>Moderate Risk</Th>
                                <Th>High Risk</Th>
                                <Th>
                                    {moment().clone().tz(user_timezone).format('MMM Do, YYYY')}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody className="tbody">
                            <Tr>
                                <Td>Max heart rate</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Min heart rate (bpm)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Pulse (bpm)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Systolic Blood Pressue (mmHg)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Diastolic Blood Pressue (bpm)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Temperature (Celsius)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Height</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Weight</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Body Mass Index (unites)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Waist Circumference</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Bosy Category</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                            <Tr>
                                <Td>Hip Circumference (inches)</Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td><div className="hidetext">.</div></Td>
                                <Td>example</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </div>
            }

            <Modal show={show} onHide={hide} className="coach_users_labtest_addbiomarkers_popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add BioMarkers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className="inner_popup_label required">Date</label><br />
                            <DatePicker
                                onChange={settestDate}
                                value={testDate}
                                clearIcon={null}
                                className="datepicker"
                            />
                        </div>
                        <div className="col">
                            <label className="inner_popup_label required">Time</label><br />
                            <TimePicker
                                onChange={settestTime}
                                value={testTime}
                                clearIcon={null}
                                clockIcon={false}
                                className="timepicker"
                                onClick={() => setError(false)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="inner_popup_label required">Did you have to fast</label><br />
                            <Select
                                options={fastingoptions}
                                className={fastingError === true ? "fastingoptions err_border" : "fastingoptions"}
                                onChange={setfastVal}
                            />
                        </div>
                        <div className="col">
                            <label className="inner_popup_label required">Location</label><br />
                            <input type="text" className={locationError === true ? "inner_popup_input err_border" : "inner_popup_input"} onChange={event => settestLocation(event.target.value)} onClick={() => setlocationError(false)} />
                        </div>
                    </div>
                    {newtestList && newtestList.length > 0 ?
                        <div className="row add_new_tests_inputs">
                            {newtestList.map((data, index) => (
                                <InputGroup className="mb-3" key={index}><label>{data.testname_label}</label><input type="text" data-id={data.id} /><InputGroup.Text>{data.units}</InputGroup.Text><i className="fa fa-trash" data-id={data.id} onClick={(e) => { deleteTest(e) }}></i></InputGroup>
                            ))}
                        </div>
                        : null
                    }
                    <div className="row add_new_tests">
                        <label className="inner_popup_label required">Add new tests</label><br />
                        <Dropdown
                            title='Select Test'
                            position='right'
                            className={addtestError === true ? 'add_new_test_btn err_border' : 'add_new_test_btn'}
                            onClick={() => loadnewaddtest()}
                        >
                            <div className="addtest_search"> <input type="text" onChange={(e)=>settestSearch(e.target.value)} /><i className="fa fa-search" aria-hidden="true" onClick={searchTests}/></div>
                            {
                                category_options.map((category, category_index) => (
                                    <Dropdown.Item key={category_index}>
                                        {category.label}
                                        {category.is_option === false ?
                                            <Dropdown.Submenu
                                                position='right' className="submenu">
                                                {
                                                    (subcategory_options[category.value]).map((sub_category, sub_category_index) => (
                                                        (groupedData[category.value][sub_category.value] ?
                                                            <Dropdown.Item key={sub_category_index}>
                                                                {sub_category.label + " (" + (groupedData[category.value][sub_category.value]).length + ")"}
                                                                <Dropdown.Submenu
                                                                    position='right' className={"submenu sc_" + sub_category.value}>
                                                                    <Dropdown.Item>
                                                                        <div className={selectedallList && selectedallList.find(item => item === sub_category.value) ? "checkbox active" : "checkbox"}>
                                                                            <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item="select_all" data-checked="false" onChange={addNewTest} disabled={selectedallList && selectedallList.find(item => item === sub_category.value) ? true : false} />Select All
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    {(groupedData[category.value][sub_category.value]).map((tests, tests_index) => (
                                                                        <Dropdown.Item key={tests_index}>
                                                                            <div className={newtestList && newtestList.find(item => item.id === tests.id) ? "checkbox active" : "checkbox"} >
                                                                                <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item={tests.testname_value} data-checked="false" data-id={tests.id} onChange={addNewTest} disabled={newtestList && newtestList.find(item => item.id === tests.id) ? true : false} />{tests.testname_label}
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    ))
                                                                    }
                                                                </Dropdown.Submenu>
                                                            </Dropdown.Item>
                                                            : null)
                                                    ))
                                                }
                                            </Dropdown.Submenu>
                                            :
                                            (subcategory_options[category.value]).map((sub_category, sub_category_index) => (                                           
                                                <Dropdown.Submenu
                                                                    position='right' className={"submenu sc_" + sub_category.value}>
                                                                    <Dropdown.Item>
                                                                        <div className={selectedallList && selectedallList.find(item => item === sub_category.value) ? "checkbox active" : "checkbox"}>
                                                                            <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item="select_all" data-checked="false" onChange={addNewTest} disabled={selectedallList && selectedallList.find(item => item === sub_category.value) ? true : false} />Select All
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    {(groupedData[category.value][sub_category.value]).map((tests, tests_index) => (
                                                                        <Dropdown.Item key={tests_index}>
                                                                            <div className={newtestList && newtestList.find(item => item.id === tests.id) ? "checkbox active" : "checkbox"} >
                                                                                <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item={tests.testname_value} data-checked="false" data-id={tests.id} onChange={addNewTest} disabled={newtestList && newtestList.find(item => item.id === tests.id) ? true : false} />{tests.testname_label}
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    ))
                                                                    }
                                                                </Dropdown.Submenu>
                                            ))                                            
                                        }                                     
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown>
                        {!newtestList || newtestList.length === 0 ?
                            <label className="inner_popup_label text-center">No tests added.!</label>
                            : null}
                    </div>
                    {Error === true ?
                        <div className="err">{ErrorMsg}</div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className='close_button' onClick={hide}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-item='' onClick={addBiomarkers}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={closeDelete} className="confirm_delete">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete - <span style={{ color: "#226920", fontWeight: "500" }}>{deletetestInfo}</span> dated lab test data?
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className="btn-secondary close_button" onClick={closeDelete}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="btn-primary save_button" data-id={dataTestId}
                        onClick={(e) => deleteTestData(e)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editshow} onHide={edithide} className="coach_users_labtest_addbiomarkers_popup">
                <Modal.Header closeButton>
                    <Modal.Title>Edit BioMarkers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className="inner_popup_label required">Date</label><br />
                            <DatePicker
                                onChange={setedittestDate}
                                value={edittestDate}
                                clearIcon={null}
                                className="datepicker"
                            />
                        </div>
                        <div className="col">
                            <label className="inner_popup_label required">Time</label><br />
                            <TimePicker
                                onChange={setedittestTime}
                                value={edittestTime}
                                clearIcon={null}
                                clockIcon={false}
                                className="timepicker"
                                onClick={() => setError(false)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="inner_popup_label required">Did you have to fast</label><br />
                            <Select
                                options={fastingoptions}
                                className={fastingError === true ? "fastingoptions err_border" : "fastingoptions"}
                                onChange={setedittestFasting}
                                defaultValue={{ label: edittestFasting.label, value: edittestFasting.value }}
                            />
                        </div>
                        <div className="col">
                            <label className="inner_popup_label required">Location</label><br />
                            <input type="text" className={locationError === true ? "inner_popup_input err_border" : "inner_popup_input"} onChange={event => setedittestLocation(event.target.value)} onClick={() => setlocationError(false)} value={edittestLocation} />
                        </div>
                    </div>
                    {newtestList && newtestList.length > 0 ?
                        <div className="row add_new_tests_inputs">
                            {newtestList.map((data, index) => (
                                <InputGroup className="mb-3" key={index}><label>{data.testname_label}</label><input type="text" data-id={data.id} value={geteditinputValue(data.id)} /><InputGroup.Text>{data.units}</InputGroup.Text><i className="fa fa-trash" data-id={data.id} onClick={(e) => { deleteTest(e) }}></i></InputGroup>
                            ))}
                        </div>
                        : null
                    }
                    <div className="row add_new_tests">
                        <label className="inner_popup_label required">Add new tests</label><br />
                        <Dropdown
                            title='Select Test'
                            position='right'
                            className={addtestError === true ? 'add_new_test_btn err_border' : 'add_new_test_btn'}
                            onClick={() => loadnewaddtest()}
                        >
                            <div className="addtest_search"> <input type="text" onChange={(e)=>settestSearch(e.target.value)} /><i className="fa fa-search" aria-hidden="true" onClick={searchTests}/></div>
                            {
                                category_options.map((category, category_index) => (
                                    <Dropdown.Item key={category_index}>
                                        {category.label}
                                        <Dropdown.Submenu
                                            position='right' className="submenu">
                                            {
                                                (subcategory_options[category.value]).map((sub_category, sub_category_index) => (
                                                    (groupedData[category.value][sub_category.value] ?
                                                        <Dropdown.Item key={sub_category_index}>
                                                            {sub_category.label + " (" + (groupedData[category.value][sub_category.value]).length + ")"}
                                                            <Dropdown.Submenu
                                                                position='right' className={"submenu sc_" + sub_category.value}>
                                                                <Dropdown.Item>
                                                                    <div className={selectedallList && selectedallList.find(item => item === sub_category.value) ? "checkbox active" : "checkbox"}>
                                                                        <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item="select_all" data-checked="false" onChange={addNewTest} disabled={selectedallList && selectedallList.find(item => item === sub_category.value) ? true : false} />Select All
                                                                    </div>
                                                                </Dropdown.Item>
                                                                {(groupedData[category.value][sub_category.value]).map((tests, tests_index) => (
                                                                    <Dropdown.Item key={tests_index}>
                                                                        <div className={newtestList && newtestList.find(item => item.id === tests.id) ? "checkbox active" : "checkbox"} >
                                                                            <input type='checkbox' className={"sc_" + sub_category.value + "_checkbox checkbox"} data-category={category.value} data-subcategory={sub_category.value} data-item={tests.testname_value} data-checked="false" data-id={tests.id} onChange={addNewTest} disabled={newtestList && newtestList.find(item => item.id === tests.id) ? true : false} />{tests.testname_label}
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                ))
                                                                }
                                                            </Dropdown.Submenu>
                                                        </Dropdown.Item>
                                                        : null)
                                                ))
                                            }
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown>
                        {!newtestList || newtestList.length === 0 ?
                            <label className="inner_popup_label text-center">No tests added.!</label>
                            : null}
                    </div>
                    {Error === true ?
                        <div className="err">{ErrorMsg}</div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer className="display_block">
                    <Button variant="secondary" className='close_button' onClick={edithide}>
                        Cancel
                    </Button>
                    <Button variant="primary" className='save_button' data-item={edittestId} onClick={editTestData}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default LabTest
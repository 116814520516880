import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const SetGoalPopupComp = (props) => {
  const [assignshow, setassignshow] = useState(props.GoalPopup);
  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [ClientId, SetClientId] = useState(props.client_id);
  const handleassignClose = () => {
    setassignshow(false);
    props.SetGoalPopup(false);
  };

  useEffect(() => {
    if (props.ClientProfileData) {
      console.log('propssssssss',props.ClientProfileData)
      if (props.ClientProfileData.coach_client_relations) {
        if (props.ClientProfileData.coach_client_relations[0].daily_calories) {
        
          if (
            props.ClientProfileData.coach_client_relations[0].daily_calories !=
              null &&
            props.ClientProfileData.coach_client_relations[0].daily_calories !=
              ""
          ) {
            SetCalories(
              props.ClientProfileData.coach_client_relations[0].daily_calories
            );
          }
        }

        if (props.ClientProfileData.coach_client_relations[0].daily_carb) {
  
          if (
            props.ClientProfileData.coach_client_relations[0].daily_carb !=
              null &&
            props.ClientProfileData.coach_client_relations[0].daily_carb != ""
          ) {
            SetCarbs(
              props.ClientProfileData.coach_client_relations[0].daily_carb
            );
          }
        }

        if (props.ClientProfileData.coach_client_relations[0].daily_protein) {
          if (
            props.ClientProfileData.coach_client_relations[0].daily_protein !=
              null &&
            props.ClientProfileData.coach_client_relations[0].daily_protein !=
              ""
          ) {
            SetProteins(
              props.ClientProfileData.coach_client_relations[0].daily_protein
            );
          }
        }

        if (props.ClientProfileData.coach_client_relations[0].daily_fat) {
          if (
            props.ClientProfileData.coach_client_relations[0].daily_fat !=
              null &&
            props.ClientProfileData.coach_client_relations[0].daily_fat != ""
          ) {
            SetFats(
              props.ClientProfileData.coach_client_relations[0].daily_fat
            );
          }
        }
      }
    }

    if (props.GoalPopup) {
      setassignshow(props.GoalPopup);
    }
    if (props.client_id) {
      SetClientId(props.client_id);
    }
  },[props.GoalPopup]);

  const [Calories, SetCalories] = useState("");
  const [Carbs, SetCarbs] = useState("");
  const [Proteins, SetProteins] = useState("");
  const [Fats, SetFats] = useState("");

  const [CaloriesErr, SetCaloriesErr] = useState(false);
  const [CarbsErr, SetCarbsErr] = useState(false);
  const [ProteinsErr, SetProteinsErr] = useState(false);
  const [FatsErr, SetFatsErr] = useState(false);

  const SetGoalUser = async () => {
    var Err = false;
    SetCaloriesErr(false);
    SetCarbsErr(false);
    SetProteinsErr(false);
    SetFatsErr(false);

    if (Calories === "") {
      SetCaloriesErr(true);
      Err = true;
    }
    if (Carbs === "") {
      SetCarbsErr(true);
      Err = true;
    }
    if (Proteins === "") {
      SetProteinsErr(true);
      Err = true;
    }
    if (Fats === "") {
      SetFatsErr(true);
      Err = true;
    }
    if (Err === false) {
      const response = await axiosJWT.post(
        "/profile-details/set-goal-coach",
        {
          calories: Calories,
          carbs: Carbs,
          proteins: Proteins,
          fats: Fats,
          client_id: ClientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        props.load_profile(ClientId)
        setassignshow(false);
        props.SetGoalPopup(false);
      }

      console.log(response);
    }
  };

  return (
    <div>
      <Modal
        show={assignshow}
        onHide={handleassignClose}
        className="coach_plan_modal_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Set goal to this user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="withings_input_row">
            <div className="withings_label_div required">Calories (Kcal):</div>
            <div className="withings_input_div">
              <input
                defaultValue={Calories}
                value={Calories}
                onChange={(e) => {
                  SetCalories(e.target.value);
                }}
                type={"number"}
                placeholder="Please enter calorie value"
                {...(CaloriesErr === true ? { id: "err_border" } : {})}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Carbs (gm):</div>
            <div className="withings_input_div">
              <input
                defaultValue={Carbs}
                value={Carbs}
                onChange={(e) => {
                  SetCarbs(e.target.value);
                }}
                type={"number"}
                placeholder="Please enter carb value"
                {...(CarbsErr === true ? { id: "err_border" } : {})}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Proteins (gm):</div>
            <div className="withings_input_div">
              <input
                defaultValue={Proteins}
                value={Proteins}
                onChange={(e) => {
                  SetProteins(e.target.value);
                }}
                type={"number"}
                placeholder="Please enter protein value"
                {...(ProteinsErr === true ? { id: "err_border" } : {})}
              />
            </div>
          </div>

          <div className="withings_input_row">
            <div className="withings_label_div required">Fats (gm):</div>
            <div className="withings_input_div">
              <input
                defaultValue={Fats}
                value={Fats}
                onChange={(e) => {
                  SetFats(e.target.value);
                }}
                type={"number"}
                placeholder="Please enter fat value"
                {...(FatsErr === true ? { id: "err_border" } : {})}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            variant="secondary"
            className="modal_close_button"
            onClick={handleassignClose}
          >
            Close
          </Button>

          <Button
            variant="primary"
            className="modal_action_button float_right"
            onClick={SetGoalUser}
          >
            Set
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SetGoalPopupComp;

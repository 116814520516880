import React, { useEffect,useState } from "react";
import axios from "axios";
import { format } from 'date-fns'

const PersonalInfoData=(props)=>{



    const [profileData, setprofileData] = useState(props.profileData);
    const token = localStorage.getItem("token");
    const axiosJWT = axios.create();

  

   
    

    return (
        <div className="info_details_progress">


        <div className='topcontent'>
            <h5 className="h5_report">Personal Info:</h5>
            <div className='user_info report_user_info' data-item={profileData.id}>
                <div className='header_box'>
                    <div className='input_fields'>
                        <div className='row personal_info_report_row'>
                            <span className="top_span">
                                <label className='inner_label'>First Name:  </label>
                                <span className='input_value'>{profileData.first_name}</span>
                            </span>
                            <span className="top_span">
                                <label className='inner_label'>Last Name:  </label>
                                <span className='input_value'>{profileData.first_name}</span>
                            </span>
                            <span className="top_span">
                                <label className='inner_label' style={{ marginRight: '43px' }}>Gender:  </label>
                                <span className='input_value'>{profileData.gender}</span>
                            </span>
                            <span className="top_span">
                                <label className='inner_label' style={{ marginRight: '38px' }}>Address:  </label>
                                <span className='input_value'>{profileData.user_address}</span>
                            </span>
                            <span className="top_span">
                                <label className='inner_label' style={{ marginRight: '13px' }}>Postal code:  </label>
                                <span className='input_value'>{profileData.postalCode}</span>
                            </span>

                        </div>
                    </div>

                </div>
            </div>



            <div style={{ "clear": "both" }} />
        </div>


        <div className='topcontent additional_info_report_div'>
            <h5 className="h5_report">Additional Info:</h5>
            <div className='user_info additional_info_row' data-item={profileData.id}>
                <div className='header_box'>
                    <div className='input_fields'>
                        <div className='row'>
                            <div className='col'>
                                <label className='inner_label'>Age</label><br />
                                <div className='input_value'> {profileData.user_dob && profileData.user_dob != "0000-00-00" ? (format(new Date(), 'yyyy')) - (format(new Date(profileData.user_dob), 'yyyy')): null} Years</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Height</label><br />
                                <div className='input_value'>{profileData.client_infos[0].height}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " ft" : " cm"}</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Weight</label><br />
                                <div className='input_value'>{profileData.client_infos[0].weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Goal Weight</label><br />
                                <div className='input_value'>{profileData.client_infos[0].target_weight}{(profileData.client_infos[0].measure_type).toLowerCase() === 'imperial' ? " lbs" : " kg"}</div>
                            </div>
                        </div>
                    </div>
                    <div className='input_fields'>
                        <div className='row'>
                            <div className='col'>
                                <label className='inner_label'>Activity Level</label><br />
                                <div className='input_value'>{profileData.client_infos[0].activity}</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Daily Calories</label><br />
                                <div className='input_value'>{profileData.client_infos[0].daily_calories}</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Water Intake</label><br />
                                <div className='input_value'>{profileData.client_infos[0].water_intake}</div>
                            </div>
                            <div className='col'>
                                <label className='inner_label'>Protein-Fats-Carbs</label><br />
                                <div className='input_value'>{profileData.client_infos[0].daily_protein}-{profileData.client_infos[0].daily_fat}-{profileData.client_infos[0].daily_carb}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div style={{ "clear": "both" }} />
        </div>





    </div>
    )

}
export default PersonalInfoData
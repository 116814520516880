import React, { useEffect } from "react";

const Digestive = (props) => {
    const CheckPhysical = (e) => {
        var tabLink = document.querySelectorAll(".option_div");

        const category = e.target
        .closest(".option_div")
        .getAttribute("data-target");

        tabLink.forEach(box => {
            box.classList.remove('active');
        });

        e.target.closest('.option_div').classList.add("active");

        if (category === "") {
            props.DisableNextButton();
        } 
        else {
            props.SetDigestiveData(category);
            props.EnableNextButton();
        }
    }

    useEffect(() => {
        setTimeout(() => {    
          if (props.DigestiveData) {
            if (props.DigestiveData !== "") {             
              const el1 = document.querySelector(
                '.option_div[data-target="' + props.DigestiveData + '"]'
              );
              el1.classList.add("active");
              props.EnableNextButton();
            }
            else{             
                props.DisableNextButton(); 
            }
          }
          else{
            props.DisableNextButton(); 
          }
        }, 1);
        if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false);
        }
      }, []);

    return (
        <div className="daily_activity">
            <div className="head_row">
                <span className="heading">
                    How often do you experience digestive upsets?
                </span>
            </div>
            {/* <div className="sub_head_row">
        <span className="heading">How Physically active are you?</span> 
        </div> */}
            <div className="options_row">
                <div className="option_div" data-target="Never" onClick={CheckPhysical}><p>Never</p></div>
                <div className="option_div" data-target="A couple times per month" onClick={CheckPhysical}><p>A couple times per month</p></div>
                <div className="option_div" data-target="Twice a week or more" onClick={CheckPhysical}><p>Twice a week or more</p></div>
                <div className="option_div" data-target="Daily" onClick={CheckPhysical}><p>Daily</p></div>
            </div>
        </div>
    )
}
export default Digestive
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const ExerciseDiary = (props) => {

  const token = localStorage.getItem("token");
  const axiosJWT = axios.create();
  const [showAdd, SetshowAdd] = useState(false);
  const [showAddm,SetshowAddm]=useState(false);
  const [SubmitErrMsg,SetSubmitErrMsg]=useState('')
  const handleClosesAdd = () => {
    SetshowAdd(false);
    props.CloseExerciseDiary();
  };

  const handleShowAdd = () => {
    SetshowAdd(true);
 };
  const handleClosesAddm=()=>{
    SetshowAddm(false)
  }
  const handleShowAddm=()=>{
     SetshowAddm(true)
  }
  const FinalAddButton = async () => {

    var err=false;
    SetExerciseActivityErr(false)
    SetExerciseActivityErrMsg('')
    SetWeightErr(false)
    SetWeightErrMsg('')
    SetMinutesErr(false)
    SetMinutesErrMsg('')
    SetSubmitErrMsg('')



    if(ExerciseActivity==='')
    {
        SetExerciseActivityErr(true) 
        SetExerciseActivityErrMsg('Please select exercise activity')
        err=true;
    }
    
    if(Minutes==='')
    {
        SetMinutesErr(true) 
        SetMinutesErrMsg('Please enter minutes')
        err=true;
    }
    if(err===false)
    {
        var elt = document.getElementsByClassName('exercise_activity_drop')[0];
        const dateTimeInParts = props.startDate.toISOString().split("T");
        const date = dateTimeInParts[0];

        try{

          const response = await axiosJWT.post('/progress-diary/exercise-diary',
          {
              'activity': ExerciseActivity,
              'activity_name':elt.options[elt.selectedIndex].text,
              'total_mins':Minutes,
              'Weight':Weight,
              'ddate': date
  
  
          },
          {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
  
          if(response.status==200)
          {
              props.GetExerciseDiaryData(date)
              props.GetProgressDiaryChart('week')
              handleClosesAdd()
  
          }
        }catch(err)
        {
          SetSubmitErrMsg(err.response.data.msg)
          console.log(err)
        }

     

    }

   
 
  };

  const [ExerciseActivity,SetExerciseActivity]=useState('')
  const [ExerciseActivityErr,SetExerciseActivityErr]=useState(false)
  const [ExerciseActivityErrMsg,SetExerciseActivityErrMsg]=useState('')

  const [Weight,SetWeight]=useState(0)
  const [WeightErr,SetWeightErr]=useState(false)
  const [WeightErrMsg,SetWeightErrMsg]=useState('')

  const [Minutes,SetMinutes]=useState(0)
  const [MinutesErr,SetMinutesErr]=useState(false)
  const [MinutesErrMsg,SetMinutesErrMsg]=useState('')



  useEffect(()=>{
  
    if(props.ExerciseDiaryShow===true)
    {
        SetshowAdd(true)
    }
   
  },[])
 


  return (
    <div className="">
      <Modal
        show={showAdd}
        onHide={handleClosesAdd}
        className="progressdiary_input_fields confirm_delete"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Exercise Tracking"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row exercise_diary_row">
            <div className="col">
              <label className="commission_inner_popup_label">
                {"Exercise activity : "}<span className="met_chart" onClick={handleShowAddm}>(view MET chart)</span>
              </label>
              <br />
              <select
                className="drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart commission_inner_popup_select commmission_status_drop exercise_activity_drop"
                style={{ float: "left", marginTop: "6px", width: "100%" }}
                defaultValue={ExerciseActivity} onChange={(e)=>{SetExerciseActivity(e.target.value)}}
                {...(ExerciseActivityErr===true ? {id: 'err_border'} : {})}
              >
                <option value="">Select exercise activity</option>
                <option value="1.3">Sitting at a desk</option>
                <option value="1.5">Sitting, playing cards</option>
                <option value="1.8">Standing at a desk</option>
                <option value="2.0">Strolling at a slow pace</option>
                <option value="2.2">Washing dishes</option>
                <option value="2.5">Hatha yoga</option>
                <option value="2.5">Fishing (sitting)</option>
                <option value="3.5">Housework (cleaning, sweeping)</option>
                <option value="3.5">
                  Weight training (lighter weights)
                </option>
                <option value="4.3">Golf (walking, pulling clubs)</option>
                <option value="5">Brisk walking (3.5–4 mph)</option>
                <option value="5">Weight training (heavier weights)</option>
                <option value="5">
                  Yard work (mowing, moderate effort)
                </option>
                <option value="6">Swimming laps (leisurely pace)</option>
                <option value="6.3">
                  Walking at very brisk pace (4.5 mph)
                </option>
                <option value="8">Bicycling 12–14 mph (flat terrain)</option>
                <option value="8">Circuit training (minimal rest)</option>
                <option value="8">Singles tennis</option>
                <option value="8.5">Shoveling, digging ditches</option>
                <option value="10">Competitive soccer</option>
                <option value="11.5">Running (7 mph)</option>
              </select>
            </div>
            <span>{ExerciseActivityErrMsg}</span>
          </div>

        

          <div className="row">
            <div className="col">
              <label className="commission_inner_popup_label">
                {"Total minutes"}
              </label>
              <br />
              <input
                type="number"
                className="add_category_inner_input total_session"
                defaultValue={Minutes} onChange={(e)=>{SetMinutes(e.target.value)}}
                {...(MinutesErr===true ? {id: 'err_border'} : {})}
              />
            </div>
            <span>{MinutesErrMsg}</span>
          </div>
          <span className="err">{SubmitErrMsg}</span>
        </Modal.Body>
        <Modal.Footer className="display_block">
          <Button
            className="modal_close_button"
            variant="secondary"
            onClick={handleClosesAdd}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="modal_action_button add_button_progress float_right"
            onClick={(e) => {
              FinalAddButton(e);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showAddm}
        onHide={handleClosesAddm}
        className="progressdiary_input_fields confirm_delete"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"MET chart"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table className='table is-striped is-fullwidth'>
                                        <Thead className='thead'>
                                            <Tr className="trow">
                                                <Th className='row_name'>Exercise activity</Th>
                                                <Th className='row_name'>MET value</Th>
                                            
                                            </Tr>
                                        </Thead>
                                        <Tbody className="tbody">
                                          
                                                <Tr className="trow">
                                                    <Td>Sitting at a desk</Td>
                                                    <Td>1.3</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Sitting, playing cards</Td>
                                                    <Td>1.5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Standing at a desk</Td>
                                                    <Td>1.8</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Strolling at a slow pace</Td>
                                                    <Td>2.0</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Washing dishes</Td>
                                                    <Td>2.2</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Hatha yoga</Td>
                                                    <Td>2.5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Fishing (sitting)</Td>
                                                    <Td>2.5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Housework (cleaning, sweeping)</Td>
                                                    <Td>3.5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Weight training (lighter weights)</Td>
                                                    <Td>3.5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Golf (walking, pulling clubs)</Td>
                                                    <Td>4.3</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Brisk walking (3.5–4 mph)</Td>
                                                    <Td>5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Weight training (heavier weights)</Td>
                                                    <Td>5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Yard work (mowing, moderate effort)</Td>
                                                    <Td>5</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Swimming laps (leisurely pace)</Td>
                                                    <Td>6</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Walking at very brisk pace (4.5 mph)</Td>
                                                    <Td>6.3</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Bicycling 12–14 mph (flat terrain)</Td>
                                                    <Td>8</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Circuit training (minimal rest)</Td>
                                                    <Td>8</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Singles tennis</Td>
                                                    <Td>8</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Shoveling, digging ditches</Td>
                                                    <Td>8</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Competitive soccer</Td>
                                                    <Td>10</Td>
                                                </Tr>
                                                <Tr className="trow">
                                                    <Td>Running (7 mph)</Td>
                                                    <Td>11.5</Td>
                                                </Tr>
                                        </Tbody>
                                    </Table>
        </Modal.Body>
        <Modal.Footer className="display_block">
          
          <Button
            variant="primary"
            className="modal_action_button add_button_progress float_right"
            onClick={handleClosesAddm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ExerciseDiary;

import React, { useEffect } from "react";
import ChickenIcon from "../../../images/Chicken-Icon.png";
import PorkIcon from "../../../images/Pork-Icon.png";
import BeefIcon from "../../../images/Beef-Icon.png";
import FishIcon from "../../../images/Fish-Icon.png";
import BaconIcon from "../../../images/Bacon-Icon.png";

const Meat = (props) => {

  var MeatArray=[];

  const CheckImage = (e) => {
    var el = e.target;
    var closestParent = el.closest(".options");

    const ClickedId = closestParent.getAttribute("data-target");
    const ClosetestImgDiv = el.closest(".img_div");

    if(ClickedId!=='No-Meat')
    {
      const index = MeatArray.indexOf('No-Meat');

      if (index > -1) { // only splice array when item is found
        MeatArray.splice(index, 1); // 2nd parameter means remove one item only
      }

      const el1 = document.querySelector(
        '.meat .options_div_parent .options_div .options[data-target="No-Meat"] .img_div'
      );

      el1.classList.remove("active");
      
      if (ClosetestImgDiv.classList.contains("active")) {
        var filteredArray = MeatArray.filter(function(e) { return e !== ClickedId });
        MeatArray=filteredArray;
        ClosetestImgDiv.classList.remove("active");
      }
      else 
      {
        MeatArray.push(ClickedId);      
        ClosetestImgDiv.classList.add("active");
      }
    }
    else{
      MeatArray=[];
      MeatArray.push('No-Meat')

      var elList = document.querySelectorAll('.meat .options_div_parent .options_div .options .img_div');
  
      elList.forEach(el => el.classList.remove("active"));

      const el1 = document.querySelector(
        '.meat .options_div_parent .options_div .options[data-target="No-Meat"] .img_div'
      );

      el1.classList.add("active");
    }
  
    if(MeatArray.length>0)
    {
      props.EnableNextButton();
      props.SetMeatData(MeatArray);
    }
    else
    {
      props.DisableNextButton();
    }   
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.MeatData) {
   
        if (props.MeatData.length !== 0) {
          MeatArray=props.MeatData;

          props.MeatData.forEach(element => {
            const el1 = document.querySelector(
              '.meat .options_div_parent .options_div .options[data-target="'+element+'"] .img_div'
            );
            el1.classList.add("active");            
          });

          props.EnableNextButton();       
        } else {        
          props.DisableNextButton();
        }
      }
    }, 1);
    if(props.SetShowSubmitButton)
        {
            props.SetShowSubmitButton(false)
        }
  }, [props]);

  return (
    <div className="meat">
      <div className="head_row">
        <span className="heading">Meat</span>
      </div>
      <div className="sub_head_row">
        <span className="heading">
          Please Select Which Meat You Would Like To Be Included
        </span>
      </div>
      <div className="options_div_parent">
        <div className="options_div">
          <div className="options" data-target={"Chicken"} onClick={CheckImage}>
            <div className="img_div">
              <img src={ChickenIcon} />
              <p className="title">Chicken</p>
            </div>
          </div>
          <div className="options" data-target={"Pork"} onClick={CheckImage}>
            <div className="img_div">
              <img src={PorkIcon} />
              <p className="title">Pork</p>
            </div>
          </div>
          <div className="options" data-target={"Beef"} onClick={CheckImage}>
            <div className="img_div">
              <img src={BeefIcon} />
              <p className="title">Beef</p>
            </div>
          </div>
          <div className="options" data-target={"Fish"} onClick={CheckImage}>
            <div className="img_div">
              <img src={FishIcon} />
              <p className="title">Fish</p>
            </div>
          </div>
          <div className="options" data-target={"Bacon"} onClick={CheckImage}>
            <div className="img_div">
              <img src={BaconIcon} />
              <p className="title">Bacon</p>
            </div>
          </div>
          <div className="options" data-target={"No-Meat"} onClick={CheckImage}>
            <div className="img_div">
              <img src={""} />
              <p className="title">No Meat</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Meat;

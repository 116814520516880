import React, { useEffect, useState } from "react";


const DocumentViewer = (props) => {

 

      const [PreviewUrl,SetPreviewUrl]=useState('')
      const [ShowreactDocViewer,SetShowreactDocViewer]=useState(false)
      const [ShowGoogleDocViewer,SetShowGoogleDocViewer]=useState(false)
      const docs = [
        { uri: PreviewUrl }
    
      ];

      const BackToDirectoryList=()=>{
        SetShowreactDocViewer(false)
        props.SetShowDocumentViewer(false)
      }

  useEffect(() => {


    if(props.DocumentPreviewPath)
    {
        SetPreviewUrl(props.DocumentPreviewPath)
        // SetShowreactDocViewer(true)
        SetShowGoogleDocViewer(true)
    }
    
  }, [props]);

  return (
    <div>
    <div className="head_row document_preview">
            <button onClick={BackToDirectoryList}>Back</button>
         
          </div>
        

          {
            ShowGoogleDocViewer===true && 

            <iframe
            src={"https://docs.google.com/gview?url=" + PreviewUrl + "&embedded=true"}
            title="file"
            width="100%"
            height="1000"
          ></iframe>

          
          }
    
    </div>
  );
};
export default DocumentViewer;

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
 

const QuizSuccesForm=(props)=>{

    const [ShowPopup,SetShowPopup]=useState(false)

    const ClosePromoFailPopup=()=>{
        SetShowPopup(false)
    }

    const OpenPromoFailPopup=()=>{
        SetShowPopup(true)
    }

    useEffect(()=>{

        if(props.ShowSuccessQuizForm===true)
        {
            SetShowPopup(true)
            props.SetShowSuccessQuizForm(false)
        }

    },[props])
    


    return (
        <div>

<Modal show={ShowPopup} onHide={ClosePromoFailPopup} className="confirm_delete">
                    <Modal.Header closeButton>
                        <Modal.Title>Quiz Submitted!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        You have successfully submitted the quiz data

                    </Modal.Body>
                    <Modal.Footer className="display_block">

                        <Button variant="primary" className="modal_action_button float_right" onClick={ClosePromoFailPopup}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

        </div>
    )
}
export default QuizSuccesForm
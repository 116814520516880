/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import axios from 'axios';
import Select from 'react-select'

const PaymentMethods = () => {

    const side_nav = localStorage.getItem("side_nav");

    const currencies = [
        { value: 'USD', label: 'USD $' }
    ]

    const [paymentmethods, setPaymentMethods] = useState('');

    const token = localStorage.getItem("token");

    const axiosJWT = axios.create();

    const getPaymentMethods = async (currency) => {

        const response = await axiosJWT.get("/payments/paymentmethods?currency=" + currency, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setPaymentMethods(response.data);

    
    }

    /*const add_gateway = () => {
        const response = await axiosJWT.post("/payments/paymentmethods/", 
            {
                gateway: gateway,
                description: description,
                testkeys: testkeys,
                livekeys: livekeys,
                isLive: isLive,
                isEnable: isEnable,
                isUSD: isUSD
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }*/

    const getCurrency = (e) => {
        getPaymentMethods(e.value);
    }

    const enableGateway = async (e) => {

        const gatewayId = e.target.getAttribute("data-item");
        const isEnable = e.target.getAttribute("data-value");

     

        if (isEnable === "true") {
            document.getElementById("gatewayenable_" + gatewayId).setAttribute("class", "fa fa-toggle-off");
            document.getElementById("gatewayenable_" + gatewayId).setAttribute("data-value", false);
            var enable = 0;
        }
        else {
            document.getElementById("gatewayenable_" + gatewayId).setAttribute("class", "fa fa-toggle-on");
            document.getElementById("gatewayenable_" + gatewayId).setAttribute("data-value", true);
            var enable = 1;
        }

        await axiosJWT.put("/payments/paymentmethods/" + gatewayId,
            {
                isEnable: enable
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    useEffect(()=>{
        document.title = 'Admin-Payment methods';
    },[])

    return (
        <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
            <section className="dashboard">
                <div className="adminpaymentmethods container">
                    <h4>Payment Methods</h4> <br></br>
                    <p style={{ "font-weight": "500", "font-size": "14px" }}>Select Currencies</p>
                    <Select options={currencies} onChange={getCurrency} />

                    {
                        paymentmethods ? paymentmethods.map((methods, index) => (
                            <div className='paymentgateway'>
                                <div className='first_column'>
                                    <div className='logo'><img src={methods.logopath ? process.env.REACT_APP_BACKEND_URL + "/images/paymentgateways/" + methods.logopath : null} alt={methods.gateway} /></div>
                                </div>
                                <div className='second_column'>
                                    <div className='title'>{methods.title}</div>
                                    <div className='description grayfont'>{methods.description}</div>
                                </div>
                                <div className='third_column'>
                                    <span id={`gatewayenable_${methods.id}`} className={methods.isEnable === false ? "fa fa-toggle-off" : "fa fa-toggle-on"} data-value={methods.isEnable} data-item={methods.id} onClick={enableGateway} />
                                </div>
                            </div>
                        )) : null
                    }

                    <div style={{ clear: 'both' }}></div>
                </div>
            </section>



        </div>
    )
}

export default PaymentMethods

import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import moment from 'moment-timezone';
import female_icon from '../../../images/female.png';
import male_icon from '../../../images/male.png';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import AdminChart from '../AdminChartComponent';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const AdminAnalytics = () => {

  const side_nav = localStorage.getItem("side_nav");
  const axiosJWT = axios.create();
  const token = localStorage.getItem("token");
  const [ShowRealTime, SetShowRealTime] = useState(true);
  const [ShowUserTab, SetShowUserTab] = useState(false);
  const [ShowAnalyticsTab, SetShowAnalyticsTab] = useState(false);

  const [RealTimeConsulation, SetRealTimeConsulation] = useState([]);
  const ClientTimeZoneDefault = localStorage.getItem("user_timezone");
  const [ClientActiveUsers, SetClientActiveUsers] = useState([]);
  const [CoachActiveUsers, SetCoachActiveUsers] = useState([]);
  const [LiveCustomerSignUps, SetLiveCustomerSignUps] = useState([]);
  const [LiveCoachSignUps, SetLiveCoachSignUps] = useState([]);
  const [startDate, SetstartDate] = useState(false);
  const [ListOfUsers, SetListOfUsers] = useState([]);
  const [PerPage, SetPerPage] = useState(50);
  const [TotalRecordUsersList, SetTotalRecordUsersList] = useState(0);
  const [Pagenumber, SetPagenumber] = useState(1);

  const [FilterName, SetFilterName] = useState('');
  const [FilterRole, SetFilterRole] = useState('');
  const [FilterDate, SetFilterDate] = useState('');
  const [FilterStatus, SetFilterStatus] = useState('');
  const [Chartlabels, SetNewChartlabels] = useState([]);
  const [ChartDatas, SetNewChartDatas] = useState([]);

  const [RevenueCoachChartlabels, SetRevenueCoachChartlabels] = useState([]);
  const [RevenueCoachChartDatas, SetRevenueCoachChartDatas] = useState([]);

  const [RevenueClientChartlabels, SetRevenueClientChartlabels] = useState([]);
  const [RevenueClientChartDatas, SetRevenueClientChartDatas] = useState([]);

  const [RevenueCommissionChartlabels, SetRevenueCommissionChartlabels] = useState([]);
  const [RevenueCommissionChartDatas, SetRevenueCommissionChartDatas] = useState([]);

  const [RevenueOverAllChartlabels, SetRevenueOverallChartlabels] = useState([]);
  const [RevenueOverAllChartDatas, SetRevenueOverAllChartDatas] = useState([]);
  const datepicker1Ref = useRef(null);

  const [CurrentTabUsers, SetCurrentTabUsers] = useState('all');

  function handleClickDatepickerIcon1() {
    const datepickerElement = datepicker1Ref.current;
    datepickerElement.setFocus(true);
  }

  function timetoUtcClientAppointment(passdate) {

    var pushstartime = moment(passdate.trim()).format('YYYY-MM-DD HH:mm:ss');

    var cutoffString = pushstartime; // in utc
    var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(ClientTimeZoneDefault);

    var stardates = displayCutoff.format('YYYY-MM-DD HH:mm:ss');

    return stardates;
  }

  const changeUsers = async (e) => {
    SetShowRealTime(false);
    SetShowUserTab(false);
    SetShowAnalyticsTab(false);
    const list_for = e.target.getAttribute("data-item");

    const alltabs = document.querySelectorAll(".tabmenu a");
    alltabs.forEach((element) => element.classList.remove('active'));

    e.target.setAttribute("class", "active");

    if (list_for === 'real_time') {
      SetShowRealTime(true);
      GetLiveConsulation();
      CustomersLive();
    }
    if (list_for === 'users') {
      SetShowUserTab(true);
      GetUsersChart('all', 'this_week');
      FilterUserLists(1);
    }
    if (list_for === 'transaction') {
      SetShowAnalyticsTab(true);
      await RevenueChart('coach_subscription', 'this_week');
      await RevenueChart('client_subscription', 'this_week');
      await RevenueChart('over_all', 'this_week');
    }
  }

  const GetLiveConsulation = async () => {

    const response = await axiosJWT.get('/appointment/all-appointments?range=live', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    SetRealTimeConsulation(response.data.FinalArray);
  }

  const CustomersLive = async () => {

    const response = await axiosJWT.get('/admin-analytics/real-time-analytics', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetClientActiveUsers(response.data.clientActive);
    SetCoachActiveUsers(response.data.coachActive);
    SetLiveCustomerSignUps(response.data.LiveCustomerSignUps);
    SetLiveCoachSignUps(response.data.LiveCoachSignUps);
  }

  const ChangedDate = async (date) => {
    var n = date.toUTCString();

    SetstartDate(date);
    SetFilterDate(n);
  }

  const FilterUserLists = async (pagenumber) => {

    SetPagenumber(pagenumber);

    const response = await axiosJWT.get('/admin-analytics/filter-users?name=' + FilterName + '&role=' + FilterRole + '&date=' + FilterDate + '&status=' + FilterStatus + '&offset=' + pagenumber + '&limit=' + PerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetListOfUsers(response.data.AllUSers);
    SetTotalRecordUsersList(response.data.count);
  }

  const changePageNumber = (pagenumber) => {
    FilterUserLists(pagenumber);
  }

  const ResetFilterUserList = async () => {
    SetFilterName('');
    SetFilterDate('');
    SetstartDate('');
    SetFilterRole('');
    SetFilterStatus('');

    document.getElementById('filter_name').value = '';
    document.getElementById('filter_role').value = '';
    document.getElementById('filter_status').value = '';
    SetPagenumber(1);

    const response = await axiosJWT.get('/admin-analytics/filter-users?name=&role=&date=&status=&offset=1' + '&limit=' + PerPage + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    SetListOfUsers(response.data.AllUSers);
    SetTotalRecordUsersList(response.data.count);
  }

  const EarningsChart = async (e) => {

    var tabLink = document.querySelectorAll(".tab_show");

    var selectElement = document.querySelector('.earnings_chart');
    var output = selectElement.options[selectElement.selectedIndex].value;

    tabLink.forEach(box => {
      box.classList.remove('active');
    });

    SetCurrentTabUsers(e.target.innerText);
    GetUsersChart(e.target.innerText, output);

    e.target.classList.add('active');
  }

  const EarningsDropChange = async (e) => {
    GetUsersChart(CurrentTabUsers, e.target.value);
  }

  const GetUsersChart = async (type, range) => {
    var Usertype = type.toLowerCase();
    var Daterange = range.toLowerCase();

    const response = await axiosJWT.get('/admin-analytics/users-chart?type=' + Usertype + '&range=' + Daterange + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (Daterange === 'this_week' || Daterange === 'last_week') {

      SetNewChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);

      var Data = [];
      response.data.FinalArray.forEach((value, index, array) => {
        Data.push(value.count);
      });

      SetNewChartDatas(Data);
    }

    if (Daterange === 'this_month' || Daterange === 'last_month') {

      var DatePush = [];
      var DataPush = [];

      response.data.FinalArray.forEach((value, index, array) => {
        DataPush.push(value.count);
        DatePush.push(value.date);
      });

      SetNewChartlabels(DatePush);
      SetNewChartDatas(DataPush);
    }

    if (Daterange === 'this_year') {
      SetNewChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      var Data = [];
      response.data.FinalArray.forEach((value, index, array) => {
        Data.push(value.count);
      });

      SetNewChartDatas(Data);
    }
  }

  const RevenueChart = async (type, range) => {
    const response = await axiosJWT.get('/admin-analytics/revenue-chart?type=' + type + '&range=' + range + '', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (range === 'this_week' || range === 'last_week') {
      if (type === 'coach_subscription') {
        SetRevenueCoachChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);
      }
      if (type === 'client_subscription') {
        SetRevenueClientChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);
      }
      if (type === 'over_all') {
        SetRevenueOverallChartlabels(['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);
      }

      var Data = [];
      response.data.FinalArray.forEach((value, index, array) => {        
        if (value.amount.length > 0) {
          var FinalAmount=0;
          value.amount.forEach((value, index, array) => {
            FinalAmount=parseFloat(value.amount)+parseFloat(FinalAmount);
          });

          Data.push(parseFloat(FinalAmount));
        }
        else {
          Data.push(0);
        }
      });

      if (type === 'coach_subscription') {
        SetRevenueCoachChartDatas(Data);
      }
      if (type === 'client_subscription') {
        SetRevenueClientChartDatas(Data);
      }
      if (type === 'over_all') {
        SetRevenueOverAllChartDatas(Data);
      }
    }

    if (range === 'this_month' || range === 'last_month') {
      var DatePush = [];
      var DataPush = [];

      response.data.FinalArray.forEach((value, index, array) => {
        if (value.amount.length > 0) {
          var FinalAmount=0;
            value.amount.forEach((value, index, array) => {
             FinalAmount=parseFloat(value.amount)+parseFloat(FinalAmount)         
            });
            DataPush.push(parseFloat(FinalAmount));
        }
        else {
          DataPush.push(0);
        }

        DatePush.push(value.date);
      });

      if (type === 'coach_subscription') {
        SetRevenueCoachChartlabels(DatePush);
        SetRevenueCoachChartDatas(DataPush);
      }
      if (type === 'client_subscription') {
        SetRevenueClientChartlabels(DatePush);
        SetRevenueClientChartDatas(DataPush);
      }
      if (type === 'over_all') {
        SetRevenueOverallChartlabels(DatePush);
        SetRevenueOverAllChartDatas(DataPush);
      }
    }

    if (range === 'this_year') {
      if (type === 'coach_subscription') {
        SetRevenueCoachChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }
      if (type === 'client_subscription') {
        SetRevenueClientChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }
      if (type === 'over_all') {
        SetRevenueOverallChartlabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }

      var Data = [];
      response.data.FinalArray.forEach((value, index, array) => {

        if (value.amount.length > 0) {
          var amountFinal = 0;
          value.amount.forEach((value, index, array) => {
            amountFinal += parseInt(value.amount)
          });
          Data.push(amountFinal);
        }
        else {
          Data.push(0);
        }
      });

      if (type === 'coach_subscription') {
        SetRevenueCoachChartDatas(Data);
      }
      if (type === 'client_subscription') {
        SetRevenueClientChartDatas(Data);
      }
      if (type === 'over_all') {
        SetRevenueOverAllChartDatas(Data);
      }
    }
  }

  const RevenueCoachSubscription = (value) => {
    RevenueChart('coach_subscription', value);
  }

  const RevenueClientSubscription = (value) => {
    RevenueChart('client_subscription', value);
  }

  const RevenueOverAll = (value) => {
    RevenueChart('over_all', value);
  }

  const FilterUserListsTrigger = () => {
    FilterUserLists(1);
  }

  useEffect(() => {
    document.title = 'Admin-Analytics';
    GetLiveConsulation();
    CustomersLive();
    GetLiverevenue();
  }, []);

  const [LiveRevenueList, SetLiveRevenueList] = useState([]);
  const GetLiverevenue = async () => {

    const response = await axiosJWT.get('/admin-analytics/live-revenue', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.data != null) {
      if (response.data.LivePayments.length > 0) {
        SetLiveRevenueList(response.data.LivePayments);
      }
    }
  }

  function formatString(string)
  {
    let result = string.replace("_", " ");
    result= result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }


  return (
    <div id="container-adjusts" className={side_nav === "true" ? "Container-enlarge" : "Container-shrink"}>
      <section className='dashboard'>
        <div className="admin_analytics container">
          <div className="row top_row_analytics_div">
            <span className="analytics_top_label">FitPal Analytics</span>
            <p className="analytics_top_description">Here, You can will able to view the live consulations going on between coach and client ,live customers,live experts,live customer signups ,live expert signups,live revenue and so on</p>
          </div>
          <div className='menubox'>
            <div className='tabmenu'>
              <a className="active" onClick={changeUsers} data-item="real_time">Real time</a>
              <a className="" onClick={changeUsers} data-item="transaction">Transaction {'&'} Revenue</a>
              <a className="" onClick={changeUsers} data-item="users">Users</a>
              <a className="" onClick={changeUsers} data-item="advance">Advance analytics</a>
            </div>
          </div>
          {ShowRealTime === true &&
            <div className="row">
              <div className="row card_top_row">
                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span>Live Consultations (Current Day)</span>
                  </div>

                  <div className="row admin_table_div_analytics">
                    {RealTimeConsulation.length > 0 && RealTimeConsulation.map((liveconsult, index) => (
                      <div className="list_live_consulation">
                        <span>Coach: {liveconsult.coach.first_name + ' ' + liveconsult.coach.last_name}</span>
                        <span>Client: {liveconsult.client.first_name + ' ' + liveconsult.client.last_name}</span>
                        <span>Meeting title: {liveconsult.appointment.meeting_description}</span>
                        <span>Started Time: {format(parseISO(timetoUtcClientAppointment(liveconsult.appointment.start_time)), `MMM dd, yyyy  hh:mm a`)}</span>
                        <span>End Time: {format(parseISO(timetoUtcClientAppointment(liveconsult.appointment.end_time)), `MMM dd, yyyy  hh:mm a`)}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span> Customers Live</span>
                  </div>
                  <div className="admin_table_div admin_table_div_analytics">
                    <table className='admin_table'>
                      <thead>
                        <tr>
                          <th className='row_name'>Name</th>
                          <th className='row_name'>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ClientActiveUsers.length > 0 && ClientActiveUsers.map((user, index) => (
                          <tr>
                            <td>
                              <span className='c-avatar'>
                                <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                  (user.logged_in_status === '1')
                                    ? <span className='c-avatar__status'></span>
                                    : null
                                }
                                <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                              </span>
                            </td>
                            <td className='table_name_val'>{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span> Experts Live</span>
                  </div>
                  <div className="admin_table_div admin_table_div_analytics">
                    <table className='admin_table'>
                      <thead>
                        <tr>
                          <th className='row_name'>Name</th>
                          <th className='row_name'>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CoachActiveUsers.length > 0 && CoachActiveUsers.map((user, index) => (
                          <tr>
                            <td>
                              <span className='c-avatar'>
                                <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                  (user.logged_in_status === '1')
                                    ? <span className='c-avatar__status'></span>
                                    : null
                                }
                                <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                              </span>
                            </td>
                            <td className='table_name_val'>{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row card_top_row">
                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span>  Live customer signups</span>
                  </div>

                  <div className="admin_table_div admin_table_div_analytics">
                    <table className='admin_table'>
                      <thead>
                        <tr>
                          <th className='row_name'>Name</th>
                          <th className='row_name'>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LiveCustomerSignUps.length > 0 && LiveCustomerSignUps.map((user, index) => (
                          <tr>
                            <td>
                              <span className='c-avatar'>
                                <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ?  user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                  (user.logged_in_status === '1')
                                    ? <span className='c-avatar__status'></span>
                                    : null
                                }
                                <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                              </span>
                            </td>
                            <td className='table_name_val'>{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span>  Live revenue (Current Day)</span>
                  </div>

                  <div className="admin_table_div admin_table_div_analytics">
                    <table className='admin_table'>
                      <thead>
                        <tr>
                          <th className='row_name'>Revenue By</th>
                          <th className='row_name payment_th_td_center'>Payment Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LiveRevenueList.length > 0 && LiveRevenueList.map((revenue, index) => (
                          <tr>                           
                            <td>{formatString(revenue.payment_category)}</td>
                            <td className="payment_th_td_center">+{revenue.payment_amount} $</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                
                <div className="col card_view_analytics">
                  <div className="row top_row_head_analytics">
                    <span>Live expert signups</span>
                  </div>

                  <div className="admin_table_div admin_table_div_analytics">
                    <table className='admin_table'>
                      <thead>
                        <tr>
                          <th className='row_name'>Name</th>
                          <th className='row_name'>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LiveCoachSignUps.length > 0 && LiveCoachSignUps.map((user, index) => (
                          <tr>
                            <td>
                              <span className='c-avatar'>
                                <img className='table_profile_img' src={user.hasOwnProperty("user_profile_photo") && user.user_profile_photo !== '' && user.user_profile_photo !== null ? user.user_profile_photo : (user.gender === "MALE" ? male_icon : female_icon)} alt="BigCo Inc. logo" />   {
                                  (user.logged_in_status === '1')
                                    ? <span className='c-avatar__status'></span>
                                    : null
                                }
                                <span className='name_val'>{user.first_name + ' ' + user.last_name}</span>
                              </span>
                            </td>
                            <td className='table_name_val'>{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            ShowUserTab === true &&

            <div className="row usertab">
              <div className="row chart_row_analytics">
                <div className="row chart_row_analytics_top_head">
                  Users Onboarded
                </div>
                <div className='wholesection'>
                  <div className="section1  Tob_button">
                    <div className='earning_tab'>
                      <span className='active pointer_eff tab_show' onClick={EarningsChart}>All</span>
                      <span className='pointer_eff tab_show' onClick={EarningsChart}>Coach</span>
                      <span onClick={EarningsChart} className='pointer_eff tab_show'>Client</span>
                    </div>

                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={EarningsDropChange}>
                      <option value="this_week">This Week</option>
                      <option value="last_week">Last Week</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_year">This Year</option>
                    </select>
                  </div>

                  <div className="users_chart">
                    <AdminChart Chartlabels={Chartlabels} ChartDatas={ChartDatas} datasetpass={'Users onboarded'} />
                  </div>
                </div>
              </div>

              <div className="row filter_row_analytics">
                <div className="col">
                  <input type="text" className='commission_inner_input' id="filter_name" placeholder="By Name ,email" onChange={(e) => { SetFilterName(e.target.value) }} />
                </div>
                <div className="col">
                  <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin' onChange={(e) => { SetFilterRole(e.target.value) }} id="filter_role"   >
                    <option value="">Select Role</option>
                    <option value="Nutrition">Coach</option>
                    <option value="Client">Client</option>
                  </select>
                </div>
                <div className="col date_filter_analytics_col" style={{ display: 'inherit' }}>
                  <label style={{ fontSize: '12px', paddingRight: '9px' }}>Joined date</label>
                  <span className="calendar_icon_div analytics_fif">
                    <i className="fa fa-calendar fif_calendar_icon" onClick={() => handleClickDatepickerIcon1()} aria-hidden="true"></i>
                  </span>
                  <DatePicker selected={startDate} ref={datepicker1Ref} dateFormat="MMMM d, yyyy" onKeyDown={(e) => {
                    e.preventDefault();
                  }} onChange={(date) => ChangedDate(date)} />
                </div>
                <div className="col">
                  <select className='drop_calender main_drop_date btn drpbtn dropdown-toggle color_grey extra_css_admin' onChange={(e) => { SetFilterStatus(e.target.value) }} defaultValue={FilterStatus} id="filter_status">
                    <option value="">Select status</option>
                    <option value="1">Banned</option>
                    <option value="0">Not Banned</option>
                  </select>
                </div>

                <div className="col">
                  <button className='filter_analytics_button' onClick={FilterUserListsTrigger}>Filter</button>
                  <button className='filter_analytics_button' onClick={ResetFilterUserList} style={{ right: '78px' }}>Reset</button>
                </div>
              </div>

              <div className="row upcoming_appointment_table_row">
                <Table className="table is-striped is-fullwidth upcoming_appointment_table">
                  <Thead className="thead">
                    <Tr className="trow">
                      <Th scope="col">First Name</Th>
                      <Th scope="col">Last Name</Th>
                      <Th scope="col">Email</Th>
                      <Th scope="col">Role</Th>
                      <Th scope="col">Joined Date</Th>
                      <Th scope="col">Is Banned</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="tbody">
                    {ListOfUsers.length > 0 && ListOfUsers.map((user, index) => (
                      <Tr className="trow">
                        <Td>{user.first_name}</Td>
                        <Td>{user.last_name}</Td>
                        <Td >{user.email}</Td>
                        <Td>{user.role === 'Nutrition' ? 'Coach' : user.role}</Td>
                        <Td>{format(parseISO(timetoUtcClientAppointment(user.createdAt)), `MMM dd, yyyy  hh:mm a`)}</Td>
                        <Td>{user.isBan === 1 ? 'Yes' : 'No'}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <div className="row">
                  <div>
                    <Pagination
                      activePage={Pagenumber}
                      itemsCountPerPage={PerPage}
                      totalItemsCount={TotalRecordUsersList}
                      onChange={changePageNumber.bind(this)}
                      prevPageText="<"
                      nextPageText=">"
                      hideFirstLastPages={true}
                      linkClassPrev="pagination_prev"
                      linkClassNext="pagination_next"
                      linkClass="pagenumbers grayfont"
                    />
                  </div>
                </div>
              </div>
            </div>
          }

          {ShowAnalyticsTab === true &&
            <div className="row">
              <div className="row">
                <div className="col">
                  <div className="top_heading_row_revenue">
                    <span className="revenue_chart_heading">Revenue By coach</span>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { RevenueCoachSubscription(e.target.value) }}>
                      <option value="this_week">This Week</option>
                      <option value="last_week">Last Week</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_year">This Year</option>
                    </select>
                  </div>

                  <div className="revenue_chart_div">
                    <AdminChart Chartlabels={RevenueCoachChartlabels} ChartDatas={RevenueCoachChartDatas} datasetpass={'Revenue by coach'} />
                  </div>
                </div>

                <div className="col">
                  <div className="top_heading_row_revenue">
                    <span className="revenue_chart_heading">Revenue By Client</span>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { RevenueClientSubscription(e.target.value) }}>
                      <option value="this_week">This Week</option>
                      <option value="last_week">Last Week</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_year">This Year</option>
                    </select>
                  </div>

                  <div className="revenue_chart_div">
                    <AdminChart Chartlabels={RevenueClientChartlabels} ChartDatas={RevenueClientChartDatas} datasetpass={'Revenue by client'} />
                  </div>
                </div>
              </div>

              <div className="row second_row_revenue_analytics">

                {/* <div className="col">
                  <div className="top_heading_row_revenue">
                  <span className="revenue_chart_heading">Revenue By commissions</span>
                  <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={EarningsDropChange}>
                      
                      <option value="this_week">This Week</option>
                      <option value="last_week">Last Week</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_year">This Year</option>
                    </select>
                  </div>
  
                <div className="revenue_chart_div">
                <AdminChart Chartlabels={RevenueCommissionChartlabels} ChartDatas={RevenueCommissionChartDatas}  datasetpass={'Revenue by commissions'} />
                </div>
                </div> */}
                <div className="col">
                  <div className="top_heading_row_revenue">
                    <span className="revenue_chart_heading">OverAll revenue</span>
                    <select className='drop_calender btn drpbtn dropdown-toggle color_grey extra_css_admin earnings_chart' style={{ float: 'right', fontWeight: '600' }} onChange={(e) => { RevenueOverAll(e.target.value) }}>
                      <option value="this_week">This Week</option>
                      <option value="last_week">Last Week</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_year">This Year</option>
                    </select>
                  </div>

                  <div className="revenue_chart_div">
                    <AdminChart Chartlabels={RevenueOverAllChartlabels} ChartDatas={RevenueOverAllChartDatas} datasetpass={'Overall revenue'} />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </div>
  )
}

export default AdminAnalytics